import { PartialBy } from "./general";

export const paymentMethodTypes = ["CreditCard", "VenmoAccount", "PayPalAccount"] as const;

export type PaymentMethodType = typeof paymentMethodTypes[number];

interface BasePaymentMethod<T extends PaymentMethodType, D extends Record<any, any>> {
	default: boolean;
	token: string;
	type: T;
	details: D;
}

export type CardPaymentMethod = BasePaymentMethod<
	"CreditCard",
	{
		cardType: string;
		expirationMonth: string;
		expirationYear: string;
		lastFour: string;
	}
>;

export type PayPalPaymentMethod = BasePaymentMethod<
	"PayPalAccount",
	{
		email: string;
	}
>;

export type VenmoPaymentMethod = BasePaymentMethod<
	"VenmoAccount",
	{
		username: string;
	}
>;

export type PaymentMethodWithToken = CardPaymentMethod | PayPalPaymentMethod | VenmoPaymentMethod;

export type PaymentMethod = PartialBy<PaymentMethodWithToken, "token" | "default">;

export type TransactionFeeType = "FLAT_FEE" | "FLAT_FEE_PER_TICKET" | "DECIMAL";

export interface TransactionFeeRule {
	type: TransactionFeeType;
	value: number;
}

// Wallet is not treated as a paymentMethodType since it doesn't have a token and other properties a paymentMethodType meets
export type TransactionFeeRules = Record<PaymentMethodType | "PromoWallet", TransactionFeeRule>;
