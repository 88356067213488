import React, { SetStateAction, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";
import { useLogin } from "../../../services/hooks/useLogin";
import { postEmailRegister, postGuestCheckout, postPreRegister } from "../../../services/session";
import { didLogIn } from "../../../store/reducers/sessionReducer";
import { InternalRoutes } from "../../Link";
import { getAppColor } from "../../util/appColors";
import { passwordsMatch, required, validEmail, validPassword, validPhoneNumber } from "../../util/optimized/finalFormUtil";
import { Button } from "../NewButton/Button";
import { getTypographyStyles, Typography } from "../Typography/Typography";
import { isLeft } from "fp-ts/lib/These";
import { Config } from "../../../Config";
import { logIn } from "../../../services/actions/sessionService";
import { ForgotPasswordModal } from "../ForgotPasswordModal/ForgotPasswordModal";
import { FormApi } from "final-form";
import { useObjectMergeReducer } from "../../hooks";
import { Dialog } from "../Dialog/Dialog";
import { breakpoints } from "../../util/breakpoints";
import lock from "../../resource/images/lock-account.png";
import { Checkbox } from "../Checkbox/Checkbox";
import { useQueryStringState } from "../../hooks/useQueryStringState";
import { validEmailRegex } from "../../util/optimized/regexes";
import { useToastRemoval } from "../../hooks/useToastRemoval";
import { Browser } from "../../util/UserAgent";
import google_icon_white from "../../resource/assets/third-party-logos/google_icon_white.svg";
import facebook_icon_white from "../../resource/assets/third-party-logos/facebook_icon_white.png";
import { StyledRFFInput } from "../Input/Input";
import { getUserProvider, postUserProvider } from "../../../services/user";
import { useCart } from "../../../services/hooks";
import axios, { Canceler } from "axios";

interface EmailLoginData {
	email?: string;
	password?: string;
	rememberMe: boolean;
}

interface RegisterState {
	email?: string;
	firstName?: string;
	lastName?: string;
	mobileNumber?: string;
	password?: string;
	confirmPassword?: string;
}

export type AccountFormType = "login" | "register" | "guest";

export type AccountFormRef = {
	activeForm: AccountFormType;
	setActiveForm: React.Dispatch<React.SetStateAction<AccountFormType>>;
};

interface State {
	guest: {
		email?: string;
	};
	login: {
		email?: string;
		rememberMe?: boolean;
	};
	register: {
		rememberMe?: boolean;
	};
}

type SSOButtonProvider = "Google" | "Facebook";

const ssoButtonsLoading = new Set<SSOButtonProvider>();

const basePath = "/contact";

type SubmitButton = React.ReactElement<React.ComponentProps<typeof Button>>;

const AccountFormContext = React.createContext<
	| {
			state: State;
			setState: React.Dispatch<Partial<State>>;
			toggleRememberMe: (stateKey: keyof Omit<State, "guest">) => void;
			activeForm: AccountFormType;
			setActiveForm: React.Dispatch<SetStateAction<AccountFormType>>;
			submitButton: SubmitButton;
			children?: React.ReactNode;
			ssoButtonsLoading: boolean;
			setSSOButtonsLoading: React.Dispatch<SetStateAction<boolean>>;
	  }
	| undefined
>(undefined);

const getRedirectURI = () => window.location.origin + window.location.pathname;

const defaultSubmitButton = <Button size="large">Continue</Button>;

export const AccountForm = React.forwardRef<
	AccountFormRef,
	React.PropsWithChildren<
		{
			submitButton?: SubmitButton;
			defaultForm?: AccountFormType;
		} & StyledComponentProps
	>
>(({ defaultForm = "register", submitButton = defaultSubmitButton, children, ...props }, ref) => {
	const [activeForm, setActiveForm] = useState<AccountFormType>(defaultForm);

	useImperativeHandle(
		ref,
		() => {
			return {
				activeForm,
				setActiveForm,
			};
		},
		[activeForm]
	);

	const rememberedEmail = useSelector(st => st.persistent.session.rememberedEmail);

	const initialState = {
		guest: {
			email: "",
		},
		register: {
			rememberMe: false,
		},
		login: {
			email: rememberedEmail,
			rememberMe: rememberedEmail !== undefined,
		},
	};

	const [state, setState] = useObjectMergeReducer<State>(initialState);

	const toggleRememberMe = (stateKey: keyof Omit<State, "guest">) => setState({ [stateKey]: { ...state[stateKey], rememberMe: !state[stateKey].rememberMe } });

	useEffect(() => {
		toast.remove();
	}, [activeForm]);

	useToastRemoval();

	useEffect(() => {
		if (activeForm !== "login") {
			setState(initialState);
		}
	}, [activeForm]);

	useToastRemoval();

	const [queryStringState] = useQueryStringState();

	const dispatch = useDispatch();

	const history = useHistory();

	useEffect(() => {
		if (queryStringState.code) {
			const provider = queryStringState.prompt ? "google" : "facebook";
			postUserProvider(provider, history.location.search, getRedirectURI())
				.then(result => {
					const data = result.data;
					dispatch(didLogIn(provider, data.token.accessToken, data.user.email, false));
				})
				.catch(error => {
					toast.error(
						(
							{
								409: "An account with this email address already exists.",
							} as Record<number, string>
						)[error?.error?.code] || "Something went wrong, please try again."
					);
				})
				.finally();
			history.replace({
				search: "",
			});
		}
	}, [queryStringState.code]);

	const [ssoButtonsLoading, setSSOButtonsLoading] = useState(true);

	return (
		<AccountFormContext.Provider
			value={{
				state,
				setState,
				toggleRememberMe,
				activeForm,
				setActiveForm,
				submitButton,
				children,
				ssoButtonsLoading,
				setSSOButtonsLoading,
			}}
		>
			<div className={props.className}>
				{
					{
						guest: <GuestForm />,
						register: <RegisterForm />,
						login: <LoginForm />,
					}[activeForm]
				}
				<div
					css={`
						width: 100%;
					`}
				>
					<div
						css={`
							margin: 18px 0;
							display: flex;
							align-items: center;
						`}
					>
						<SSOSeparator />
						<Typography
							type="bodyNormal"
							css={`
								text-align: center;
								display: flex;
								justify-content: center;
								margin: 0 16px;
							`}
						>
							or
						</Typography>
						<SSOSeparator />
					</div>
					<div
						css={`
							display: flex;
							gap: 12px 20px;
							flex-direction: column;
							& > * {
								flex: 1;
							}
						`}
					>
						<SSOButton provider="Google" />
						<SSOButton provider="Facebook" />
					</div>
				</div>
			</div>
		</AccountFormContext.Provider>
	);
});

const GuestForm = (props: StyledComponentProps) => {
	const accountForm = useContext(AccountFormContext);
	const remoteIp = useLogin();
	const dispatch = useDispatch();
	const { cart } = useCart();
	const history = useHistory();
	const [loginDialogOpen, setLoginDialogOpen] = useState(false);
	return (
		<div className={props.className}>
			<Dialog
				open={loginDialogOpen}
				onClose={() => setLoginDialogOpen(false)}
				type="information"
				css={`
					z-index: 10;
					${Dialog.Wrapper} {
						flex-direction: column;
					}
				`}
			>
				<LoginHeaderContainer>
					<div
						css={`
							margin-bottom: 20px;
							display: flex;
							flex-direction: column;
							align-items: flex-start;
						`}
					>
						<span
							css={`
								${getTypographyStyles("extraLargeSpecialBody")};
								margin-bottom: 5px;
							`}
						>
							Welcome Back
						</span>
						<span
							css={`
								${getTypographyStyles("bodySmall")};
								margin-bottom: 5px;
								text-align: left;
							`}
						>
							It looks like you have an account. Please log in to continue
						</span>
					</div>
					<TextLink
						type="bodySmall"
						fontWeight={500}
						onClick={() => {
							setLoginDialogOpen(false);
							history.replace(InternalRoutes.Checkout + basePath);
						}}
					>
						{" "}
						&#60; Checkout as a guest
					</TextLink>
				</LoginHeaderContainer>
				<LoginForm displayDefaultSubmitButton />
			</Dialog>
			<Form
				onSubmit={async (values, form) => {
					const { email } = values;
					await new Promise<void>((resolve, reject) => {
						window.grecaptcha.ready(async () => {
							await window.grecaptcha.execute(Config.getRecaptchaSiteKey(), { action: "submit" }).then(async (captchaToken: string) => {
								try {
									await postGuestCheckout({
										email: email || "",
										remoteIp,
										captchaToken,
										cartSessionId: cart?.sessionId,
										refUrl: history.location.pathname.slice(1), //Slice to remove the first slash char
									}).then(result => {
										dispatch(didLogIn("email", result.data.token.accessToken, result.data.user.email));
									});
									resolve();
								} catch (err: any) {
									switch (true) {
										case err?.error?.suggest:
											toast.error(err.error.message);
											form.getState().errors.email = `Did you mean: ${err.error.suggest}`;
											break;
										case err?.error?.code == "23505":
											accountForm?.setState({ guest: { email }, login: { email: undefined, rememberMe: false } });
											setLoginDialogOpen(true);
											break;
										case err?.error?.message:
											toast.error(err.error.message);
											break;
										default:
											toast.error("There was an error processing your request");
											break;
									}
									reject();
								}
							});
						});
					});
				}}
				render={form => (
					<form onSubmit={form.handleSubmit}>
						<div>
							<Field name="email" component={StyledRFFInput} type="email" placeholder="Email Address" validate={value => required(value) || validEmail(value)} autoComplete="username" />
							<SubmitButtonAndTerms buttonDisabled={form.invalid || form.submitting} />
						</div>
					</form>
				)}
			/>
		</div>
	);
};

const RegisterForm = (props: StyledComponentProps) => {
	const remoteIp = useLogin();
	const dispatch = useDispatch();

	const accountForm = useContext(AccountFormContext);

	const [email, setEmail] = useState("");

	const [registerActiveStep, setRegisterActiveStep] = useState(0);
	const [mobileFieldChromeDisabled, setMobileFieldChromeDisabled] = useState(false);

	const [initialRegisterStepAttempts, setInitialRegisterStepAttempts] = useState<
		{
			email: string;
			firstName: string;
			lastName: string;
		}[]
	>([]);

	const [queryStringState] = useQueryStringState();

	useEffect(() => {
		if (queryStringState.registerEmail) {
			setEmail(queryStringState.registerEmail as string);
		}
	}, [queryStringState.registerEmail]);

	return (
		<div className={props.className}>
			<Form
				onSubmit={async (values: RegisterState, form: FormApi<RegisterState, RegisterState>) => {
					const { email, password, firstName, lastName, mobileNumber } = values;

					await new Promise<void>((resolve, reject) => {
						window.grecaptcha.ready(async () => {
							await window.grecaptcha.execute(Config.getRecaptchaSiteKey(), { action: "submit" }).then(async (captchaToken: string) => {
								try {
									if (registerActiveStep === 0) {
										if (!initialRegisterStepAttempts.find(({ email, firstName, lastName }) => email === values.email && firstName === values.firstName && lastName === values.lastName)) {
											await postPreRegister({
												email: email || "",
												firstName: firstName || "",
												lastName: lastName || "",
												remoteIp,
												captchaToken,
											});

											setInitialRegisterStepAttempts(prev => [
												...prev,
												{
													email: email || "",
													firstName: firstName || "",
													lastName: lastName || "",
												},
											]);
										}
										setRegisterActiveStep(registerActiveStep + 1);
									}
									if (registerActiveStep === 1) {
										await postEmailRegister({
											email: email || "",
											password: password || "",
											firstName: firstName || "",
											lastName: lastName || "",
											phone: mobileNumber || "",
											remoteIp,
											captchaToken,
										}).then(result => {
											dispatch(didLogIn("email", result.data.token.accessToken, result.data.user.email, accountForm?.state.register.rememberMe));
										});
									}
									resolve();
								} catch (err: any) {
									switch (true) {
										case err?.error?.suggest:
											toast.error(err.error.message);
											form.getState().errors.email = `Did you mean: ${err.error.suggest}`;
											break;
										case err?.error?.code == "23505":
											toast.error(
												<div
													css={`
														display: inline-block;
													`}
												>
													It looks like that email already exists. Did you want to&nbsp;
													<LoginLink onClick={() => accountForm?.setActiveForm("login")}>log in</LoginLink>?
												</div>
											);
											break;
										case err?.error?.code == "23506":
											toast.error(
												<div
													css={`
														display: inline-block;
													`}
												>
													Looks like this email is tied to an order, please follow the steps sent to your email to sign up.
												</div>
											);
											break;
										case err?.error?.message:
											toast.error(err.error.message);
											break;
										default:
											toast.error("There was an error processing your request");
											break;
									}
									reject();
								}
							});
						});
					});
				}}
				render={form => (
					<form onSubmit={form.handleSubmit}>
						<div>
							<fieldset disabled={registerActiveStep === 1} style={registerActiveStep === 1 ? { display: "none" } : {}}>
								<Field name="email" defaultValue={email} component={StyledRFFInput} type="email" placeholder="Email Address" validate={value => required(value) || validEmail(value)} autoComplete="username" />
								<SmallInputsContainer>
									<SmallInputWrapper>
										<Field name="firstName" component={StyledRFFInput} type="text" placeholder="First Name" validate={value => required(value)} />
									</SmallInputWrapper>
									<SmallInputWrapper>
										<Field name="lastName" component={StyledRFFInput} type="text" placeholder="Last Name" validate={value => required(value)} />
									</SmallInputWrapper>
								</SmallInputsContainer>
							</fieldset>
							{registerActiveStep === 1 && (
								<>
									<div
										css={`
											display: flex;
											flex-direction: column;
											flex-wrap: wrap;
											align-items: flex-start;
											padding: 10px;
											margin-bottom: 20px;
											background-color: ${getAppColor("lightGrey")};
										`}
									>
										<Typography
											type="bodySmall"
											css={`
												text-align: left;
											`}
										>
											{`${form.values.firstName} ${form.values.lastName}, ${form.values.email}`}
										</Typography>
										<Typography
											type="bodySmall"
											fontWeight={500}
											onClick={() => setRegisterActiveStep(0)}
											css={`
												text-decoration: underline;
												cursor: pointer;
												margin-top: 5px;
											`}
										>
											Change
										</Typography>
									</div>

									{/* In Chrome, mobile number field must be disabled when password suggestions are being given in order to prevent Chrome from treating this field as an email input */}
									<fieldset disabled={mobileFieldChromeDisabled && Browser.Chrome}>
										<Field name="mobileNumber" id="mobileNumber" component={StyledRFFInput} type="tel" placeholder="Mobile Number" validate={value => required(value) || validPhoneNumber(value)} autoComplete="tel" />
									</fieldset>
									<Field
										name="password"
										component={StyledRFFInput}
										type="password"
										placeholder="Password"
										validate={value => required(value) || validPassword(value)}
										untouchedMessage="Min 8 characters including one number and letter"
										autoComplete="new-password"
										onFocus={() => {
											if (Browser.Chrome) {
												setMobileFieldChromeDisabled(true);
											}
										}}
										onBlur={() => {
											if (Browser.Chrome) {
												setMobileFieldChromeDisabled(false);
											}
										}}
									/>
									<Field name="confirmPassword" component={StyledRFFInput} type="password" placeholder="Confirm Password" validate={(value, allValues: RegisterState) => required(value) || passwordsMatch(allValues.password, value)} autoComplete="new-password" />
									<RememberMeCheckboxContainer
										css={`
											margin-bottom: 20px;
										`}
									>
										<Checkbox checked={accountForm?.state.register.rememberMe} onChange={() => accountForm?.toggleRememberMe("register")} id="rememberMeRegister" label={<Typography type="bodySmall">Remember Me</Typography>} />
									</RememberMeCheckboxContainer>
								</>
							)}
							<SubmitButtonAndTerms buttonDisabled={form.invalid || form.submitting} />
						</div>
					</form>
				)}
			/>
		</div>
	);
};

const LoginForm = (
	props: StyledComponentProps & {
		displayDefaultSubmitButton?: boolean;
	}
) => {
	const dispatch = useDispatch();
	const remoteIp = useLogin();
	const history = useHistory();

	const accountForm = useContext(AccountFormContext);

	const [showVerificationModal, setShowVerificationModal] = useState(false);
	const [disableLoginButton, setDisableLoginButton] = useState(false);
	const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

	const [queryStringState] = useQueryStringState<{
		restore?: string;
		email?: string;
	}>();

	useEffect(() => {
		if (queryStringState.restore === "true" && queryStringState.email && validEmailRegex.test(queryStringState.email)) {
			setShowForgotPasswordModal(true);
		}
	}, []);

	useEffect(() => {
		let newPath = basePath;
		if (accountForm?.activeForm === "login") {
			newPath += "/login";
		}
		if (showForgotPasswordModal) {
			newPath += "/forgot-password";
		}
	}, [showForgotPasswordModal, accountForm?.activeForm]);

	useEffect(() => {
		if (disableLoginButton) {
			setTimeout(() => {
				setDisableLoginButton(false);
			}, 2000);
		}
	}, [disableLoginButton]);

	return (
		<div className={props.className}>
			<Dialog
				open={showVerificationModal}
				type="information"
				fullscreenOnMobile
				headerChildren={
					<DialogHeaderContainer>
						<Typography type="largeSpecialBody">Account Verification</Typography>
					</DialogHeaderContainer>
				}
			>
				<MobileWrapper>
					<Lock src={lock} alt="Lock" />
					<Typography type="heading1">Verify your account</Typography>
					<NotificationText type="bodyMedium" color="dark" colorType="dark">
						We have sent you an email to
						<Mail>{accountForm?.state.login.email}</Mail>
						to confirm that you are the owner of the account
					</NotificationText>
				</MobileWrapper>
			</Dialog>
			<Form
				onSubmit={async (values: Required<EmailLoginData>) => {
					accountForm?.setState({ login: { ...accountForm?.state.login, email: values.email } });

					await new Promise((resolve, reject) => {
						window.grecaptcha.ready(async () => {
							await window.grecaptcha.execute(Config.getRecaptchaSiteKey(), { action: "submit" }).then(async (token: string) => {
								const res = await dispatch(logIn(values.email, values.password, !!accountForm?.state.login.rememberMe, token, remoteIp));
								if (isLeft(res)) {
									switch (res.left.type) {
										case "SERVER_ERROR":
											toast.error(res.left.errorMessage);
											break;
										case "SERVER_ERRORS":
											const errorMessage = res.left.errorMessages.join(", ");
											if (errorMessage === "The user must be challenged") {
												setShowVerificationModal(true);
											} else {
												setDisableLoginButton(true);
												toast.error(errorMessage);
											}
											break;
										case "SERVER_UNSUCCESS_REQUEST":
											res.left.statusCode !== 429
												? toast.error("The provided credentials are incorrect")
												: toast.error("Your account has been temporarily locked out, please attempt to login later");
											break;
										default:
											res.left.statusCode !== 429
												? toast.error("There was an error processing your request")
												: toast.error("There was a network issue completing your request. Please reach out to customer service for assistance");
											break;
									}
									reject();
								}
							});
						});
					});
				}}
				render={form => (
					<form onSubmit={form.handleSubmit}>
						<div>
							<LoginFieldsContainer>
								<Field name="email" component={StyledRFFInput} type="email" placeholder="Email Address" validate={value => required(value) || validEmail(value)} initialValue={accountForm?.state.login.email || accountForm?.state.guest.email} />
								<Field name="password" component={StyledRFFInput} type="password" placeholder="Password" validate={value => required(value) || validPassword(value)} />
							</LoginFieldsContainer>
							<div
								css={`
									display: flex;
									flex-wrap: wrap;
									justify-content: space-between;
									margin-bottom: 20px;
									align-items: center;
								`}
							>
								<Checkbox checked={accountForm?.state.login.rememberMe} onChange={() => accountForm?.toggleRememberMe("login")} id="rememberMe" label={<Typography type="bodySmall">Remember Me</Typography>} />
								<TextLink
									type="bodySmall"
									fontWeight={400}
									onClick={() => {
										setShowForgotPasswordModal(true);
									}}
								>
									Forgot Password?
								</TextLink>
							</div>
							<SubmitButtonAndTerms buttonDisabled={form.invalid || form.submitting || disableLoginButton} displayDefaultSubmitButton={props.displayDefaultSubmitButton} />
						</div>
					</form>
				)}
			/>
			{showForgotPasswordModal && (
				<ForgotPasswordModal
					forcedReset={queryStringState.restore === "true"}
					onDidClose={() => {
						setShowForgotPasswordModal(false);
					}}
				/>
			)}
		</div>
	);
};

const SubmitButtonAndTerms = ({ buttonDisabled, displayDefaultSubmitButton }: { buttonDisabled: boolean; displayDefaultSubmitButton?: boolean }) => {
	useToastRemoval();

	const accountFormContext = useContext(AccountFormContext);

	return (
		<>
			{accountFormContext?.submitButton && (
				<ButtonContainer>
					{accountFormContext?.children}
					<div
						css={`
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							width: 100%;
						`}
					>
						{React.cloneElement(displayDefaultSubmitButton ? defaultSubmitButton : accountFormContext?.submitButton, {
							type: "submit",
							disabled: buttonDisabled,
						})}
						<Typography
							type="bodyTiny"
							css={`
								margin-top: 20px;
								margin-bottom: 20px;
								text-align: left;
								a {
									font-weight: 500;
								}
							`}
						>
							By choosing continue you agree to our{" "}
							<Link to={InternalRoutes.Terms} target="_blank" rel="noopener noreferrer">
								Terms of Use
							</Link>{" "}
							and{" "}
							<Link to={InternalRoutes.Privacy} target="_blank" rel="noopener noreferrer">
								Privacy Policy
							</Link>
							.
						</Typography>
					</div>
				</ButtonContainer>
			)}
		</>
	);
};

const SSOButton = (
	props: React.PropsWithChildren<{
		provider: SSOButtonProvider;
		loading?: boolean;
	}>
) => {
	const getUserProviderCanceller = useRef<Canceler | undefined>();

	const accountFormContext = useContext(AccountFormContext);

	const [href, setHref] = useState<string | null>(null);

	useEffect(() => {
		if (getUserProviderCanceller.current && ssoButtonsLoading.has(props.provider)) {
			getUserProviderCanceller.current();
			getUserProviderCanceller.current = undefined;
		}
		accountFormContext?.setSSOButtonsLoading(true);
		ssoButtonsLoading.add(props.provider);
		getUserProvider(
			props.provider,
			getRedirectURI(),
			new axios.CancelToken(canceller => {
				getUserProviderCanceller.current = canceller;
			})
		)
			.then(result => {
				setHref(result.data.url);
			})
			.finally(() => {
				ssoButtonsLoading.delete(props.provider);
				if (!ssoButtonsLoading.size) {
					accountFormContext?.setSSOButtonsLoading(false);
				}
			});
	}, [window.location.pathname]);

	return (
		<Button
			customColor={
				{
					Google: {
						main: "#e6382f",
					},
					Facebook: {
						main: "#3b59a6",
					},
				}[props.provider]
			}
			typographyType="bodyNormal"
			css={`
				* {
					font-weight: 500;
				}
			`}
			onClick={() => {
				if (href) {
					window.open(href, "_self");
				}
			}}
			loading={href === null || accountFormContext?.ssoButtonsLoading}
		>
			<img
				src={
					{
						Google: google_icon_white,
						Facebook: facebook_icon_white,
					}[props.provider]
				}
				alt={`${props.provider} Icon`}
				css={`
					margin-right: 6px;
					height: 18px;
				`}
			/>
			{props.provider}
		</Button>
	);
};

const SSOSeparator = styled.div`
	background: ${getAppColor("darkGrey", "light")};
	height: 1px;
	flex: 1;
`;

const TextLink = styled(Typography)<{ onClick: React.MouseEventHandler<HTMLElement> }>`
	text-decoration: underline;
	cursor: pointer;
	margin-bottom: auto;
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
`;

const SmallInputsContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

const SmallInputWrapper = styled.div`
	width: 48%;
`;

export const DialogHeaderContainer = styled.div`
	display: flex;
	overflow: hidden;
	justify-content: center;
`;

const MobileWrapper = styled.div`
	align-self: center;
	background: ${getAppColor("light", "main")};
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const Lock = styled.img`
	width: 67px;
	height: 83px;
	margin-top: 39px;
	margin-bottom: 31px;

	@media (max-width: ${breakpoints.mobile}) {
		margin-top: 0;
	}
`;

const NotificationText = styled(Typography)`
	margin: 24px 0;
	padding: 0 32px;
	max-width: 557px;
	text-align: center;

	@media (max-width: ${breakpoints.mobile}) {
		width: min(335px, calc(100vw - 40px));
		padding: 0 20px;
	}
`;

const Mail = styled.b`
	font-weight: bold;
	margin: 0 5px;
	word-wrap: break-word;

	@media (max-width: ${breakpoints.tablet}) {
		display: block;
	}
`;

const RememberMeCheckboxContainer = styled.div`
	flex-basis: 100%;
`;

const LoginFieldsContainer = styled.div``;

const LoginLink = styled.span`
	text-decoration: underline;
	cursor: pointer;
`;

const LoginHeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;
