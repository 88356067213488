import axios from "axios";
import { Config } from "../Config";
import { detectExpiredSessionFromMessage } from "./detectExpiredSession";
import { store } from "../store/store";

const headers = {headers: {
	"Content-Type": "application/json",
}}
export const apiBaseUrl = (subdomain?: string) => {
	return subdomain ? `https://${subdomain}.${Config.getServiceEnv()}.sitickets.com/api` : Config.getServiceUrl("/api");
};

export const apiClient = axios.create({
	baseURL: apiBaseUrl(),
	...headers
});

apiClient.interceptors.request.use(
	config => {
		const sessionToken = store.getState().persistent.session.sessionToken;
		config.headers.Authorization = sessionToken ? `Bearer ${sessionToken}` : `Basic ${window.btoa(`projecttix:@SItix123`)}`;
		return config;
	},
	error => Promise.reject(error)
);

apiClient.interceptors.response.use(
	response => response.data,
	async error => {
		detectExpiredSessionFromMessage(error.response?.data?.message);
		return Promise.reject(error.response?.data);
	}
);
