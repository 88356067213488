import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAppColor } from "../../../util/appColors";
import { faArrowRightArrowLeft, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { TicketTransaction } from "../../../../model/optimizedModel/myAccount";
import { Checkbox } from "../../Checkbox/Checkbox";
import { Input } from "../../Input/Input";
import { Button } from "../../Button/Button";
import { validEmail } from "../../../util/optimized/finalFormUtil";
import { TopSectionText } from "./TopSectionText";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styled from "styled-components/macro";
import { BoxOfficeTicketInfo } from "../../../../model/optimizedModel/ticket";

export type TicketModalTransferProps = {
	ticketTransaction: Pick<TicketTransaction, "ticketGroups">;
	transferUIState: "initial" | "sending" | "transferred";
	onClickTransfer: (transferFormData: { email: string; ticketIds: number[] }) => void;
};

interface TransferableTickets {
	ticket: BoxOfficeTicketInfo;
	section: string;
}

export const TicketModalTransfer: React.FC<TicketModalTransferProps> = ({ ticketTransaction, transferUIState, onClickTransfer }) => {
	const [email, setEmail] = useState("");
	const [selectedTicketIds, setSelectedTicketIds] = useState<number[]>([]);
	const [transferableTickets, setTransferableTickets] = useState<TransferableTickets[]>([]);
	const {
		ticketGroups
	} = ticketTransaction;

	useEffect(()=>{
		const filteredTickets:Array<TransferableTickets> = [];
		ticketGroups.forEach(ticketGroup=>{
			const tickets = ticketGroup.tickets?.filter(ticket=>ticket.qrCode).map(ticket=>({ ticket, section: ticketGroup.section}));
			filteredTickets.push(...tickets||[]);
		})
		setTransferableTickets(filteredTickets);
	},[ticketGroups]);

	const showForm = transferUIState === "initial" || transferUIState === "sending";
	const disableTransferButton = !!validEmail(email) || !selectedTicketIds.length || transferUIState === "sending";

	const handleClickTransfer = () => {
		onClickTransfer({ email, ticketIds: selectedTicketIds });
	};

	return (
		<div
			css={`
				display: flex;
				flex-direction: column;
				width: 100%;
				max-width: 425px;
			`}
		>
			{showForm && (
				<>
					<div
						css={`
							border-bottom: 2px solid white;
							width: 100%;
						`}
					>
						<div
							css={`
								display: flex;
								flex-direction: row;
								justify-content: center;
								align-items: center;
							`}
						>
							<FontAwesomeIcon
								icon={faArrowRightArrowLeft}
								color={getAppColor("light")}
								css={`
									margin-right: 1rem;
								`}
							/>
							<TopSectionText type="bodyLarge" color="light" fontWeight={500} width={"initial"}>
								Transfer Tickets
							</TopSectionText>
						</div>
					</div>

					<TopSectionText type="bodyNormal" color="light" style={{ marginTop: "1rem" }}>
						Once you complete this transfer, these tickets will not be accessible from your account.
					</TopSectionText>

					<TopSectionText type="bodyLarge" color="light">
						How many tickets do you want to transfer?
					</TopSectionText>

					{transferableTickets && (
						<div
							css={`
								display: flex;
								flex-flow: column;
								width: 100%;
								margin-left: 1rem;
							`}
						>
							{transferableTickets.map(({ticket, section}) => (
								<Checkbox
									key={ticket.ticketId}
									label={<>{section} Ticket ID {ticket.ticketId}</>}
									onChange={event => {
										if (event.target.checked) {
											setSelectedTicketIds(ticketIds => ticketIds.concat(ticket.ticketId));
										} else {
											setSelectedTicketIds(ticketIds => ticketIds.filter(ticketId => ticketId !== ticket.ticketId));
										}
									}}
									checked={selectedTicketIds.includes(ticket.ticketId)}
									labelStyle={{
										color: getAppColor("light"),
									}}
									checkboxStyle={{
										background: getAppColor("light"),
									}}
								/>
							))}
							<Checkbox
								label={<>Select All</>}
								labelStyle={{
									color: getAppColor("light"),
								}}
								checkboxStyle={{
									background: getAppColor("light"),
								}}
								onChange={event => {
									if (event.target.checked) {
										setSelectedTicketIds(transferableTickets.map(({ticket}) => ticket.ticketId));
									} else {
										setSelectedTicketIds([]);
									}
								}}
							/>
						</div>
					)}

					<TopSectionText type="bodyLarge" color="light" style={{ wordBreak: "break-word", textOverflow: "revert" }}>
						Who do you want to transfer these tickets to?
					</TopSectionText>

					<Input name="email" type="email" placeholder="Email Address" value={email} error={validEmail(email)} onChange={event => setEmail(event.target.value)} />

					<Button disabled={disableTransferButton} style={{ width: "100%" }} onClick={handleClickTransfer}>
						Complete Transfer
					</Button>

					<TopSectionText type="bodySmall" color="light" style={{ textAlign: "center" }}>
						<a href="/terms">By completing this action you agree to our terms of use</a>
					</TopSectionText>
				</>
			)}

			{transferUIState === "transferred" && (
				<>
					<TopSectionText type="bodyLarge" color="light">
						Success!
					</TopSectionText>
					<TopSectionText type="bodyLarge" color="light">
						Your tickets have been transferred to {email}
					</TopSectionText>
					<FontAwesomeIcon icon={faCheckCircle} color={getAppColor("light")} size="10x" style={{ margin: "1rem 0" }} />
					<TopSectionText type="bodySmall" color="light">
						You will receive an email shortly from us with the details of this transfer.
					</TopSectionText>
				</>
			)}
		</div>
	);
};
