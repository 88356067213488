import React from "react";
import { maxContentWidthStyles } from "../util/maxContentWidth";
import { MetaDecorator, MetaTagData } from "../components/SEOMetadecorator/MetaDecorator";
import styled from "styled-components/macro";
import { mediaQueries } from "../util/mediaQueries";
import { CommonPage } from "../components";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { LandingPageButton } from "../components/LandingPage/LandingPageButton";
import { isMobileApp } from "../util/isMobileApp";
import { InternalRoutes } from "../Link";
import formula1Hero from "../resource/image/f1-hero.svg";
import formula1HeroMobile from "../resource/image/f1-hero-mobile.svg";
import f1PremiumParkingPass from "../resource/image/f1-premium-parking-pass.svg";
import f1DedicatedPickup from "../resource/image/f1-dedicated-pickup.svg";
import f1PersonalConcierge from "../resource/image/f1-personal-concierge.svg";
import f1PersonalizedWelcomeGift from "../resource/image/f1-personalized-welcome-gift.svg";
import f1VipConcertEntry from "../resource/image/f1-vip-concert-entry.svg";
import f1ClubPackage from "../resource/image/f1-club-package.svg";
import f1ClubPackageMobile from "../resource/image/f1-club-package-mobile.svg";

const mobileScreenWidth = "750px";

export const Formula1Route = () => {
	const isMobile = useMediaQuery("(max-width: " + mobileScreenWidth + ")");

	return (
		<CommonPage background="#fff">
			<MetaDecorator title={MetaTagData.Formula1.title} description={MetaTagData.Formula1.description} />

			<HeaderContainer>
				<Header>
					<HeaderTitleContainer>
						<HeaderTitle>Formula 1 Aramco United States Grand Prix</HeaderTitle>
						<HeaderText>
							<strong>Get ready for a spectacle of speed and sound:</strong> Sports Illustrated, the most iconic brand in sports, hosts Club SI at the 2021 Formula 1 Aramco United States Grand Prix on <strong>October 22-24, 2021!</strong>
						</HeaderText>
						<ResponsiveLandingPageButton forwardedAs="a" href={InternalRoutes.Formula1Event}>
							Purchase Now
						</ResponsiveLandingPageButton>
					</HeaderTitleContainer>
				</Header>
			</HeaderContainer>

			<MainContent>
				<ContentRow>
					<ContentBox margin={isMobile ? "0px" : "0 20px 0 0"}>
						<ContentTitle margin="0 0 16px">Club SI brings an unforgettable experience</ContentTitle>
						<ContentSeparator />
					</ContentBox>
				</ContentRow>

				<ContentRow margin={isMobile ? "0px" : "24px 0 0"}>
					<ContentBox margin={isMobile ? "0px" : "0 20px 0 0"}>
						<ContentText>The race weekend experience is packed with pulse-pounding racing action featuring the world's fastest drivers.</ContentText>
						<ContentText margin="16px 0 0">
							Experience the Formula 1 USGP from one of COTA's Premium Hospitality options:&nbsp;
							<strong>Club SI brings guests an unforgettable experience directly off Turn 12 with a 360-degree view of the track for the most high-performance experience.</strong>
						</ContentText>
					</ContentBox>
					<ContentBox margin={isMobile ? "16px 0 0" : "0 20px 0 0"}>
						<ContentText>
							Club SI’s two-story hospitality lounge is equipped with a downstairs enclosed climate-controlled &nbsp; banquet offering gourmet cuisine and a myriad of branded partner integrations. On the main floor race fans are immersed in Sports Illustrated’s Club SI with &nbsp;
							<strong>dynamic tv walls, live music, photo opportunities and countless buzzworthy moments.</strong>
						</ContentText>
					</ContentBox>
				</ContentRow>

				<ContentRow margin="60px 0 0" border={isMobile ? "none" : "1px solid #000000"} direction="column-reverse">
					<ContentBox padding={isMobile ? "0px" : "32px 24px"}>
						<ContentTitle margin={isMobile ? "24px 0 14px" : "0 0 30px"}>Club SI Package includes</ContentTitle>
						<ItemList>
							<ItemListWithIcon direction="row">
								<img src={f1PremiumParkingPass} alt="Premium Parking Pass" />
								<ItemText>Premium Parking Pass for every 4 tickets purchased</ItemText>
							</ItemListWithIcon>

							<ItemListWithIcon direction="row">
								<img src={f1DedicatedPickup} alt="Dedicated Pickup" />
								<ItemText>Dedicated Pickup & Drop-off</ItemText>
							</ItemListWithIcon>

							<ItemListWithIcon direction="row">
								<img src={f1PersonalConcierge} alt="Personal concierge" />
								<ItemText>Personal concierge for event day requests</ItemText>
							</ItemListWithIcon>

							<ItemListWithIcon direction="row">
								<img src={f1PersonalizedWelcomeGift} alt="Personalized welcome gift" />
								<ItemText>Personalized welcome gift</ItemText>
							</ItemListWithIcon>

							<ItemListWithIcon direction="row">
								<img src={f1VipConcertEntry} alt="VIP concert entry" />
								<ItemText>VIP concert entry with priority access to upgrades</ItemText>
							</ItemListWithIcon>
						</ItemList>

						<ResponsiveLandingPageButton border={isMobile ? "none" : "solid 1px #000"}>Sold Out</ResponsiveLandingPageButton>
					</ContentBox>

					<ContentBox
						padding="20px"
						backgroundImage={isMobile ? f1ClubPackageMobile : f1ClubPackage}
						style={{
							backgroundPositionX: "center",
							minHeight: "240px",
						}}
					/>
				</ContentRow>
			</MainContent>
		</CommonPage>
	);
};

const ResponsiveLandingPageButton = styled(LandingPageButton)<{ border?: string }>`
	border: ${props => props.border || "none"};
	@media ${mediaQueries.max500} {
		width: 100%;
	}
`;

const ContentRow = styled.div<{ border?: string; margin?: string; direction?: string }>`
	display: flex;
	margin: ${props => props.margin || "60px 0 0"};
	border: ${props => props.border || "none"};
	@media (max-width: ${mobileScreenWidth}) {
		flex-direction: ${props => props.direction || "column"};
	}
`;

const ContentBox = styled.div<{
	align?: string;
	background?: string;
	backgroundImage?: string;
	padding?: string;
	margin?: string;
	width?: string;
}>`
	width: ${props => props.width || "50%"};
	text-align: ${props => props.align || "left"};
	background: ${props => props.background || "#fff"};
	padding: ${props => props.padding || "0px"};
	margin: ${props => props.margin || "0px"};
	background-image: ${props => (props.backgroundImage ? `url("${props.backgroundImage}")` : "none")};
	background-size: ${props => (props.backgroundImage ? "cover" : "unset")};
	@media (max-width: ${mobileScreenWidth}) {
		width: auto;
	}
`;

const ContentTitle = styled.h2<{ size?: string; margin?: string }>`
	font-family: solano-gothic-pro-mvb, sans-serif;
	font-size: 40px;
	line-height: 44px;
	color: #000;
	text-transform: uppercase;
	margin: ${props => props.margin || "0 0 24px"};
	@media (max-width: ${mobileScreenWidth}) {
		font-size: ${props => (props.size === "small" ? "24px" : "30px")};
		line-height: ${props => (props.size === "small" ? "26px" : "38px")};
	}
`;

const MainContent = styled.div`
	${maxContentWidthStyles};
	margin-bottom: ${isMobileApp ? "60px" : "20px"};
`;

const ContentText = styled.p<{
	size?: string;
	margin?: string;
	padding?: string;
}>`
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
	color: #000;
	margin: ${props => props.margin || "0px"};
	padding: ${props => props.padding || "0px"};
	font-size: 16px;
	line-height: 24px;
	${props =>
		props.size !== "small" &&
		`
    @media (min-width: ${mobileScreenWidth}) {
      font-size: 18px;
      line-height: 32px;
    }
  `}
`;

const ItemList = styled.div<{ padding?: string }>`
	div:first-child {
		margin-top: 0px;
	}
	margin-bottom: 24px;
	padding: ${props => props.padding || "0px"};
`;

const ItemListWithIcon = styled.div<{ direction?: string }>`
	display: flex;
	align-items: center;
	flex-direction: ${props => props.direction || "column"};
	margin-top: 20px;
	${props =>
		props.direction === "row" &&
		`
    & > span {
      margin-left: 10px;
    }
  `}
	@media (max-width: ${mobileScreenWidth}) {
		margin-top: 15px;
	}
	& > img {
		width: 28px;
	}
`;

const ItemText = styled.span`
	font-family: Montserrat;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #000;
	@media (max-width: ${mobileScreenWidth}) {
		font-size: 16px;
		line-height: 24px;
		margin-top: 10px;
	}
`;

const HeaderContainer = styled.div`
	background-image: url(${formula1Hero});
	background-position: bottom right;
	background-size: auto;
	background-repeat: no-repeat;
	background-color: #000;
	@media (max-width: ${mobileScreenWidth}) {
		background-image: url(${formula1HeroMobile});
		background-position: bottom right;
		background-size: cover;
	}
`;

const Header = styled.div`
	${maxContentWidthStyles};
	display: flex;
	align-items: center;
	height: 542px;
	flex-direction: row;
	@media (max-width: ${mobileScreenWidth}) {
		height: auto;
		flex-direction: column-reverse;
		padding-bottom: 130px;
	}
`;

const HeaderTitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	@media (min-width: ${mobileScreenWidth}) {
		width: 50%;
	}
	@media (max-width: ${mobileScreenWidth}) {
		padding: 30px 0px;
	}
`;

const HeaderTitle = styled.h1`
	font-family: solano-gothic-pro-mvb, sans-serif;
	font-size: 60px;
	line-height: 66px;
	color: #fff;
	text-transform: uppercase;
	text-align: left;
	margin-bottom: 16px;
	@media (max-width: ${mobileScreenWidth}) {
		margin-top: 30px;
		margin-bottom: 24px;
		font-size: 40px;
		line-height: 44px;
	}
`;

const HeaderText = styled.div`
	font-family: "Montserrat", sans-serif;
	font-size: 18px;
	line-height: 32px;
	color: #fff;
	text-align: left;
	margin-bottom: 24px;
`;

const ContentSeparator = styled.div`
	height: 0px;
	border: 0px;
	border-top: 4px solid #ea2224;
	box-shadow: none;
	width: 54px;
	@media (max-width: ${mobileScreenWidth}) {
		display: none;
	}
`;
