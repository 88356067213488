import styled, { css, keyframes } from "styled-components/macro";
import { mediaQueries } from "../../util/mediaQueries";
import { ModalPopup } from "../ModalPopup/ModalPopup";
import eye from "../../resource/image/eye.svg";
import { maxContentWidthStyles } from "../../util/maxContentWidth";

//#region Map
export const PanelContainer = styled.div`
	position: relative;
	flex: 1;
	width: 426px;
	border-right: 1px solid lightgray;
	@media ${mediaQueries.max900} {
		width: unset;
		min-width: 300px;
		border-right: none;
	}
`;

export const Panel = styled.div<{ height: number }>`
	position: absolute;
	background: #fafafa;
	z-index: 1;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	height: ${props => props.height}%;
	transition: height 0.35s ease;
	@media ${mediaQueries.max900} {
		border-top: 1px solid lightgray;
	}
`;

export const PanelBody = styled.div`
	flex: 1;
	overflow-y: scroll;
	${maxContentWidthStyles}
	width: 100%;
`;

export const PanelHeader = styled.div`
	height: auto;
	display: flex;
	flex-direction: column;
	background: #fff;
	border-bottom: 1px solid lightgray;
	z-index: 3;
	${maxContentWidthStyles}
	width: 100%;
`;

export const ToggleMapContainer = styled.div`
	display: none;
	@media ${mediaQueries.max900} {
		display: initial;
	}
`;
export const ToggleMapButton = styled.div`
	background: #f5f5f5;
	padding: 8px 15px;
	border-radius: 100px;
	border: 1px solid lightgray;
	color: black;
	margin: 15px 0 0 0;
	float: right;
	cursor: pointer;
	font-size: 12px;
`;

export const Filters = {
	Container: styled.div`
		display: flex;
		padding: 1rem 0;
		align-items: center;
	`,
	Field: styled.div`
		flex: 1;
	`,
	Spacer: styled.div`
		width: 0.5rem;
	`,
};

const fadeIn = keyframes`
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
`;

const PictureInPictureStyles = css`
	position: absolute;
	z-index: 1000;
	left: 20px;
	bottom: 20px;
	background: white;
	height: 100%;
	width: 100%;
	transform: scale(0.25);
	transform-origin: bottom left;
	box-shadow: 0px 4px 16px rgb(0 0 0 / 30%);
	border: 8px solid white;
	border-radius: 44px;
	cursor: pointer;
	transition: src 0.3s ease-in-out;
	& > * {
		pointer-events: none;
	}
`;

const MaximizedStyles = css`
	width: 100% !important;
	height: 100% !important;
	position: absolute !important;
	top: 0 !important;
	bottom: 0 !important;
	left: 0 !important;
	right: 0 !important;
	animation: ${fadeIn} ease 0.3s;
`;

export const TimeoutImage = styled.img`
	position: absolute;
	background: white;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	object-fit: cover;
	object-position: center;
`;

export const MapContainer = styled.div<{ visible: boolean }>`
	display: block;
	width: 100%;
	z-index: 0;
	position: relative;
	flex: ${props => (props.visible ? "1" : "0")};
	transition: flex 0.75s;
`;

export const Map = styled.div<{ maximized: boolean }>`
	.sea-map-branding-link,
	.venue-map-zoom-controls,
	.venue-map-reset {
		margin: 10px;
	}

	@media ${mediaQueries.max900} {
		.venue-map-reset {
			margin: 0;
			right: 0px;
			bottom: 8px;
		}
	}
	
	.cm-reset:before {
		margin: 0.1em;
	}

	& >.maps-legend.Sea-Legend {
		display: none !important;
	}

	&.no-map .sea-map-inner>img {
		transform: none !important;
		object-fit: cover;
		width: 100%;
		height 100%;
  	}
  	overflow:hidden;

  ${props => (props.maximized ? MaximizedStyles : PictureInPictureStyles)}

  .legend-submit-btn {
    display: none;
  }
  .sea-map-branding-link {
    img {
      //TODO: This is a hack to get the Seatics logo displayed on the mobile apps. Look into a better way to do this.
      content: url(https://mapwidget3.seatics.com/Images/seatics.svg);
    }
  }
`;

export const Layout = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;

	@media ${mediaQueries.max900} {
		flex-direction: column-reverse;
		${MapContainer} {
			flex: 1;
		}
	}
`;

//#endregion

//#region Tickets

export const TicketCard = styled.div<{ selected: boolean }>`
	position: relative;
	overflow: hidden;
	background: ${props => (props.selected ? "white" : "#e2e4e7")};
	border: 2px solid #fff;
	border-radius: 20px;
	box-shadow: 2px 2px 7px 1px #ccc;
	margin: 1rem 0;
	cursor: pointer;
`;

export const TicketContent = styled.div`
	display: flex;
	flex-direction: column;
	color: black;
`;

export const TicketBody = styled.div`
	display: flex;
	color: #555555;
	flex-direction: row;
	justify-content: space-between;
`;

export const TicketHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export const TicketSectionTitle = styled.div`
	margin: 1rem;
	text: 16px;
`;

export const ViewFromSeatButton = styled.img.attrs(() => ({
	src: eye,
	alt: "vfs",
}))`
	width: 16px;
	height: 16px;
`;

export const Title = styled.div`
	color: #11284b;
	line-height: normal;
	font-weight: bold;
	font-size: 16px;
	text-align: left;
	flex: 1;
`;

export const Subtitle = styled.div`
	color: #555555;
	line-height: normal;
	font-size: 16px;
	text-align: left;
`;

export const Text = styled.div`
	color: #767977;
	line-height: normal;
	font-size: 14px;
	text-align: left;
`;

export const Price = styled.div`
	color: #11284b;
	line-height: normal;
	font-size: 14px;
	text-align: right;
	strong {
		font-weight: bold;
		font-size: 18px;
	}
`;

//#endregion

//#region View From Seat

export const ViewFromSeatModalPopup = styled(ModalPopup)`
	width: 800px;
	max-width: 100vw;
	overflow: hidden;
	/*Modal body selector*/
	& > :nth-child(2) {
		padding: 0px;
		overflow-y: initial;
	}
`;

export const ViewFromSeatImage = styled.img`
	width: 100%;
	height: 100%;
`;

//#endregion

const ToogleButtonStyles = css`
	position: absolute;
	z-index: 1000;
	right: 5px;
	bottom: 8px;
`;

export const HideMapButton = styled.button`
	${ToogleButtonStyles}
	background: rgba(255, 255, 255, 0.9);
	border-radius: 3px 3px 0px 0px;
	box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
	color: rgba(114, 111, 111, 1);
	font-family: Montserrat;
	font-size: 13px;
	width: 100px;
	display: flex;
	border: 1px solid;
	padding: 10px 0px;
	justify-content: center;
	border: 1px solid rgba(250, 245, 245);
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	line-height: 16px;
	align-items: center;
	text-align: center;
	align-self: flex-end;
`;

export const ShowMapButton = styled.button<{ visible: boolean }>`
	background: #e5e5e5;
	border-radius: 3px 3px 0px 0px;
	box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
	color: rgba(114, 111, 111, 1);
	font-family: Montserrat;
	font-size: 17px;
	display: flex;
	border: 1px solid;
	padding: 9px 0px;
	justify-content: center;
	border: 1px solid #e5e5e5;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	line-height: 16px;
	align-items: center;
	text-align: center;

	opacity: ${props => props.visible ? "1" : "0"};
	transition: opacity 0.35s;
`;
