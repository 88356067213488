import React from "react";
import { StyledComponentProps, InlineStylesProps } from "../../../model/optimizedModel/styles";

export const PayPalPayLaterMessage = React.memo(
	(
		props: {
			price: number;
			logoType?: "primary" | "alternative" | "inline" | "none";
			textAlign?: "left" | "center" | "right";
		} & StyledComponentProps &
			InlineStylesProps
	) => {
		return <div className={props.className} data-pp-message data-pp-placement="product" data-pp-style-logo-type={props.logoType} data-pp-style-text-size="13" data-pp-amount={props.price} data-pp-style-text-align={props.textAlign} style={props.style} />;
	}
);
