import React, { useState } from "react";
import styled from "styled-components/macro";
import { InternalRoutes, Link } from "../../Link";
import { mediaQueries } from "../../util/mediaQueries";
import { CollapseArrow } from "../CollapseArrow/CollapseArrow";
import { Expandable } from "../Expandable/Expandable";
import { flexGap } from "../../util/optimized/styles";
import { shortFormatPrice } from "../../util/formatPrice";
import { maxContentWidthPadding } from "../../util/maxContentWidth";
import { getAppColor } from "../../util/appColors";

const arrowColor = "#767977";

const collapseArrowProps = {
	weight: 2,
	size: 11,
	color: arrowColor,
};

export const FuturePanel = <T, S>(props: {
	items: T[];
	titleKey: keyof T;
	additionalContent?: (data: T) => React.ReactNode;
	chooseItemName: string;
	subItemsKey: keyof T;
	renderSubItem: (
		subItem: S,
		item: T
	) => {
		name: string;
		price: number | string | null;
		teamSlug: string;
		eventSlug: string;
	};
}) => {
	const [selection, setSelection] = useState<number | null>(null);

	return (
		<>
			{props.items.map((data, index) => {
				const selected = selection === index;
				return (
					<TeamContainer key={index} isSelected={selected} onClick={() => setSelection(selection !== index ? index : null)}>
						<TeamItem>
							<TextContainer>
								<Title>{data[props.titleKey]}</Title>
								{props.additionalContent && props.additionalContent(data)}
							</TextContainer>
							<ArrowButton isSelected={selected}>
								<span>Choose {props.chooseItemName}</span>
								<StyledCollapseArrow expanded={selected} {...collapseArrowProps} />
							</ArrowButton>
						</TeamItem>
						<Expandable expanded={selected}>
							<TeamsContainer>
								{(data[props.subItemsKey] as unknown as S[]).map((subItem, index) => {
									const subItemData = props.renderSubItem(subItem, data);
									return (
										<Link key={index} to={InternalRoutes.TeamFutureSelected(subItemData.teamSlug, subItemData.eventSlug)}>
											<GameRow>
												<GameContainer>
													<GameName>{subItemData.name}</GameName>
													<GamePrice>
														<span>From</span> <strong>{typeof subItemData.price === "number" ? `${shortFormatPrice(subItemData.price)}` : "-"}</strong>
													</GamePrice>
													<GameRowArrow {...collapseArrowProps} />
												</GameContainer>
											</GameRow>
										</Link>
									);
								})}
							</TeamsContainer>
						</Expandable>
					</TeamContainer>
				);
			})}
		</>
	);
};

const mobileMediaQuery = mediaQueries.max670;

const borderWidth = "1px";

const StyledCollapseArrow = styled(CollapseArrow)`
	margin-left: 9px;
	@media ${mobileMediaQuery} {
		margin-left: 16px;
	}
`;

const GameRowArrow = styled(CollapseArrow)`
	transform: rotate(270deg);
	margin-left: 11px;
	@media ${mobileMediaQuery} {
		margin-left: 7px;
	}
`;

export const TeamContainer = styled.div<{ isSelected: boolean }>`
	width: 100%;
	display: table;
	background: ${getAppColor("light")};
	border: ${borderWidth} solid lightgray;
	padding: 0 26px;
	box-sizing: border-box;
	cursor: pointer;
	@media ${mobileMediaQuery} {
		padding: 0;
		border-width: ${borderWidth} 0px ${borderWidth} 0px;
	}
	&:not(:last-child) {
		border-bottom: none;
	}
	&:hover {
		background-color: ${getAppColor("lightGrey")};
	}
`;

const TeamItem = styled.div`
	display: flex;
	align-items: center;
	margin: 25px 0px;
	@media ${mobileMediaQuery} {
		margin: 19px 0;
		padding: 0 ${maxContentWidthPadding}px;
	}
`;

const teamsContainerHorizontalMargin = 10;

const TextContainer = styled.div`
	vertical-align: middle;
	text-align: left;
	flex: 1;
	font-weight: bold;
`;

const Title = styled.div`
	font-weight: bold;
	color: ${getAppColor("dark")};
	font-size: 17px;
	margin-right: 20px;
`;

const ArrowButton = styled.div<{ isSelected: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${arrowColor};
	font-size: 15px;
	font-weight: 500;
	cursor: pointer;
	& > span {
		color: #555555;
	}
	@media ${mobileMediaQuery} {
		& > span {
			display: none;
		}
	}
`;

const TeamsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	${flexGap(["0px", teamsContainerHorizontalMargin + "px"])}
	padding-bottom: 26px;
	& > a {
		box-sizing: border-box;
		flex-basis: calc(50% - ${teamsContainerHorizontalMargin * 2}px);
		margin-bottom: -1px;
		//TODO: Try to fins a better way to make each row the same height instead of hard-coding the height value.
		height: 64px;
		@media ${mobileMediaQuery} {
			flex-basis: 100%;
			height: 58px;
		}
	}
	@media ${mobileMediaQuery} {
		padding-bottom: 0;
	}
`;

const GameRow = styled.div`
	height: 100%;
	width: 100%;
	cursor: pointer;
	background: ${getAppColor("light")};
	border: ${borderWidth} solid lightgray;
	color: ${getAppColor("dark")};
	padding: 16px 19px;
	box-sizing: border-box;
	font-weight: bold;
	font-size: 15px;
	display: flex;
	align-items: center;
	@media ${mobileMediaQuery} {
		padding: 13px ${maxContentWidthPadding};
		border-width: ${borderWidth} 0px ${borderWidth} 0px;
	}
	&:hover {
		color: ${getAppColor("light")};
		background-color: ${getAppColor("dark")};
	}
`;

const GameContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

const GameName = styled.div`
	flex: 1;
	text-align: left;
	padding-right: 10px;
`;

const GamePrice = styled.div`
	text-align: right;
	font-weight: 500;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	span {
		font-size: 12px;
	}
	strong {
		font-size: 18px;
	}
`;
