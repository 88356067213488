import React from "react";
import { Spinner } from "../Loader/Spinner";
import styled from "styled-components/macro";

export const MyAccountItemsList = <T,>(props: { items: T[] | undefined; renderItem: (item: T) => JSX.Element | undefined; itemsName: string; loading: boolean }) => {
	return (
		<>
			{!props.loading ? (
				props.items?.length ? (
					props.items.map((item, index) => {
						const element = props.renderItem(item);
						if (element) {
							return React.cloneElement(element, {
								key: index,
							});
						} else {
							return null;
						}
					})
				) : (
					<NoItems>No {props.itemsName} found.</NoItems>
				)
			) : (
				<Spinner />
			)}
		</>
	);
};

const NoItems = styled.span`
	font-weight: 500;
	padding-top: 30px;
	padding-bottom: 25px;
	font-size: 18px;
	display: block;
	color: #000;
`;
