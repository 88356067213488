import venmo_badge from "../resource/img/sitix/logos/venmo_badge.svg";
import pp_cc_mark from "../resource/img/sitix/logos/pp_cc_mark.jpg";
import amex_logo from "../resource/img/sitix/logos/amex_logo.svg";
import discover_logo from "../resource/img/sitix/logos/discover_logo.svg";
import JCB_logo from "../resource/img/sitix/logos/JCB_logo.svg";
import mc_logo from "../resource/img/sitix/logos/mc_logo.svg";
import visa_logo from "../resource/img/sitix/logos/visa_logo.svg";
import venmo_logo_blue from "../resource/img/sitix/logos/venmo_logo_blue.svg";
import venmo_logo_white from "../resource/img/sitix/logos/venmo_logo_white.svg";
import paypal_logo from "../resource/img/sitix/logos/paypal_logo.svg";
import payment_card_white from "../resource/assets/payment_card_white.svg";
import payment_card_black from "../resource/assets/payment_card_black.svg";
import { PaymentMethodType } from "../../model/optimizedModel/braintree";

//TODO: Consider renaming this file to paymentMethodBrandAssets or something simmilar

//TODO: Figure out a way to get consistent cardType names from braintree
export const paymentMethodBadges: {
	[key: string]: string;
} = {
	//TODO: Find a better way to type the PaymentMethodType values
	["VenmoAccount" as PaymentMethodType]: venmo_badge,
	["PayPalAccount" as PaymentMethodType]: pp_cc_mark,
	Discover: discover_logo,
	DISCOVER: discover_logo,
	"American Express": amex_logo,
	AMERICAN_EXPRESS: amex_logo,
	JCB: JCB_logo,
	MasterCard: mc_logo,
	MASTERCARD: mc_logo,
	Mastercard: mc_logo,
	Visa: visa_logo,
	VISA: visa_logo,
};

type PaymentMethodBrandAssets = Required<Record<PaymentMethodType, string>>;

export const lightPaymentMethodLogos: PaymentMethodBrandAssets = {
	PayPalAccount: paypal_logo,
	VenmoAccount: venmo_logo_white,
	CreditCard: payment_card_white,
};

export const darkPaymentMethodLogos: PaymentMethodBrandAssets = {
	PayPalAccount: paypal_logo,
	VenmoAccount: venmo_logo_blue,
	CreditCard: payment_card_black,
};
