import { useCallback, useEffect, useState } from "react";
import { searchService, SearchResponse, venueSearchService, performerSearchService, categorySearchService, SearchItem } from "../../../services/actions/mainSearchService";
import { SearchState } from "./SearchState";
import { useDispatch, useSelector } from "react-redux";
import { isRight } from "fp-ts/lib/These";
import { useServiceMonitor } from "../../hooks";
import debounce from "lodash/debounce";
import { CategorySearchResponse, PerformerSearchResponse, VenueSearchResponse } from "../../../services/types/MainSearchResponseCodec";
import { useAutoUpdateState } from "../../hooks/useAutoUpdateState";
import _ from "lodash";

interface MainSearchConfig {
	debounceSearchMs: number;
	minCharactersSearch: number;
}

/**
 * Current search flow and components are extremely complex.
 * We should consider a refactor. Anyway this hook looks good, except for the setSearchState.
 * I changed setSearchState to optional, so we can reuse this to share search logic.
 */
export const useMainSearch = (setSearchState: ((value: SearchState) => void) | null, config: MainSearchConfig) => {
	const [searchText, setSearchText] = useState("");
	const [searchFocused, setSearchFocused] = useState(false);
	const [events, setEvents] = useState<SearchResponse | null>(null);
	const [venues, setVenues] = useState<VenueSearchResponse | null>(null);
	const [performers, setPerformers] = useState<PerformerSearchResponse | null>(null);
	const [categories, setCategories] = useState<CategorySearchResponse | null>(null);

	const { recentEventSearches, recentGenericSearches } = useSelector(state => state.persistent.search);
	const categoriesState = useSelector(state => state.persistent.categories);

	const popularSubcategories = useAutoUpdateState(
		() =>
			categoriesState.categories.map(cat => {
				return {
					name: cat.name,
					url: `/category/${cat.slug}`,
				};
			}),
		[categoriesState]
	);

	const paraisoEvent =  {
		name : "Miami Swim Week",
		occursAt: new Date('2023-06-08T21:00:00'),
		occursAtDateEnd: new Date('2023-06-11T23:00:00'),
		url: '/Paraiso',
		venue: {
			city: 'Miami',
			state: 'FL',
			location: 'PARAISO Tent, 2100 Collins Ave',
			name: '',
		},
	}

	const checkForParaiso = (text: string) =>{
		return paraisoEvent.name.toLowerCase().includes(text.toLowerCase());
	}

	const availableRecentEventSearches = useAutoUpdateState(() => {
		return recentEventSearches.filter(searchItem => {
			let occursAt = searchItem.occursAt;
			if (typeof searchItem.occursAt === "string") {
				occursAt = new Date(searchItem.occursAt);
			}

			return occursAt.getTime() > new Date().getTime();
		});
	}, [recentEventSearches]);

	const isLoading = useServiceMonitor(["getMainSearch"]);

	const dispatch = useDispatch();

	const debounceSearch = useCallback(
		debounce(async (text: string) => {
			const events = await dispatch(searchService(text, "event"));
			const venues = await dispatch(venueSearchService(text));
			const performers = await dispatch(performerSearchService(text));
			const categories = await dispatch(categorySearchService(text));
			const showParaiso = checkForParaiso(text);

			let eventsToSet;
			if (isRight(events))  {
				eventsToSet = events.right;
			}
			
			if (showParaiso){
				eventsToSet = eventsToSet ? [...eventsToSet, paraisoEvent] : [paraisoEvent];
			}
			
			if (eventsToSet){
				setEvents(eventsToSet);
			}

			if (isRight(venues)) {
				setVenues(venues.right);
			}

			if (isRight(performers)) {
				setPerformers(performers.right);
			}

			if (isRight(categories)) {
				setCategories(categories.right);
			}
		}, config.debounceSearchMs),
		[]
	);

	useEffect(() => {
		if (!searchText) {
			setEvents(null);
			setVenues(null);
			setPerformers(null);
			setCategories(null);
		}

		if (searchText.length >= config.minCharactersSearch) {
			debounceSearch(searchText);
		}
	}, [searchText]);

	const hasSearchResults = useAutoUpdateState(() => {
		return !!(events?.length || venues?.length || performers?.length || categories?.length);
	});

	const shouldShowResult = useAutoUpdateState(() => {
		return searchText.length >= config.minCharactersSearch && hasSearchResults;
	}, [searchText, hasSearchResults, config.minCharactersSearch]);

	return {
		isLoading,
		events,
		venues,
		performers,
		categories,
		popularSubcategories,
		searchText,
		shouldShowResult,
		hasSearchResults,
		setSearchText,
		searchFocused,
		setSearchFocused,
		recentEventSearches: availableRecentEventSearches,
		recentGenericSearches,
	};
};
