import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useBraintree = () => {
	const braintreeState = useSelector(state => state.persistent.braintree);
	const initiallyDisplayedPaymentMethods = 3;
	const [braintreeLoading, setBraintreeLoading] = useState<boolean>();
	const [paymentMethodsListExpandable, setPaymentMethodsListExpandable] = useState<boolean>();

	useEffect(() => {
		const paymentMethodsLength = braintreeState.paymentMethods?.length;
		setPaymentMethodsListExpandable(!!(paymentMethodsLength && paymentMethodsLength > initiallyDisplayedPaymentMethods));
	}, [braintreeState.paymentMethods]);

	useEffect(() => {
		setBraintreeLoading(!braintreeState.clientAuth || !braintreeState.paymentMethods);
	}, [braintreeState.paymentMethods, braintreeState.clientAuth]);

	return {
		braintreeState,
		initiallyDisplayedPaymentMethods,
		braintreeLoading,
		paymentMethodsListExpandable,
	};
};
