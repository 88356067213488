import { Reducer } from "redux";
import { Bet } from "../../model/Bet";
import { ServiceCallError } from "../middleware/serviceCallMiddleware";

interface BetsState {
	error: ServiceCallError | null;
	featuredLoaded: boolean;
	featuredBets: Bet[];
}

const defaultState: BetsState = {
	error: null,
	featuredLoaded: false,
	featuredBets: [],
};

export const betsReducer: Reducer<BetsState, BetsActions> = (state: BetsState = defaultState, action: BetsActions): BetsState => {
	switch (action.type) {
		case "UPDATE_FEATURED_BETS":
			return { ...state, error: null, featuredLoaded: true, featuredBets: action.bets };
		case "UPDATE_BETS_ERROR":
			return { ...state, error: action.error };
		default:
			return state;
	}
};

export type BetsActions =
	| {
			type: "UPDATE_FEATURED_BETS";
			bets: Bet[];
	  }
	| {
			type: "UPDATE_BETS_ERROR";
			error: ServiceCallError;
	  };
