import React, { useState } from "react";
import styled from "styled-components/macro";
import SwipeableViews from "react-swipeable-views";
import { maxContentWidthStyles } from "../../util/maxContentWidth";
import { AboutUsCompanyData } from "./AboutUsData";
import { AboutUsCompanyItem } from "./AboutUsCompanyItem";

export const AboutUsCompanySlider = () => {
	const [step, setStep] = useState(0);
	return (
		<AboutUsCompanySliderWrapper>
			<SwipeableViews index={step} animateHeight onChangeIndex={e => setStep(e)}>
				{AboutUsCompanyData.map((data, index) => {
					return <AboutUsCompanyItem key={index} {...data} />;
				})}
			</SwipeableViews>
			<StepActions step={step} />
		</AboutUsCompanySliderWrapper>
	);
};

interface StepperProps {
	step: number;
}

const Stepper = (props: StepperProps) => {
	return (
		<StepperHeaderContainer>
			{AboutUsCompanyData.map((data, index) => {
				return <StepperStep active={props.step === index} />;
			})}
		</StepperHeaderContainer>
	);
};

export const StepActions = (props: StepperProps) => {
	return (
		<Action>
			<Stepper step={props.step} />
		</Action>
	);
};

const AboutUsCompanySliderWrapper = styled.div`
	${maxContentWidthStyles}
	position: relative;
	padding-top: 0px;
`;

const StepperHeaderContainer = styled.div`
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	div:not(:last-child) {
		margin-right: 12px;
	}
`;

const StepperStep = styled.div<{ active: boolean }>`
	height: 12px;
	width: 12px;
	border-radius: 12px;
	background-color: ${props => (props.active ? "#ED1B24" : "#9C9C9C")};
`;

const Action = styled.div`
	justify-content: center;
	display: flex;
	align-items: center;
	margin-top: 10px;
	width: 50%;
	margin-left: 25%;
	bottom: 25px;
`;
