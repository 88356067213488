import { DependencyList, useEffect, useState } from "react";

export const useAutoUpdateState = <T>(factory: () => T, deps?: DependencyList): T => {
	const [state, setState] = useState(factory());

	useEffect(() => {
		setState(factory());
	}, deps);

	return state;
};
