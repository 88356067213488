import React, { useState, useEffect } from "react";
import { HomeAsset } from "../../../model/Asset";
import { getAssetsHome } from "../../../services/assets";
import { HomeCarousel } from "./HomeCarousel";

export const GlobalAssetCarousel = (
	props: React.PropsWithChildren<{
		customProps?: Partial<HomeAsset>;
	}>
) => {
	const [globalAsset, setGlobalAsset] = useState<HomeAsset>();

	useEffect(() => {
		getAssetsHome().then(result => {
			setGlobalAsset(result.data[0]);
		});
	}, []);

	let assets: HomeAsset[] = typeof globalAsset === "undefined" ? [] : [{ ...globalAsset, ...props.customProps }];

	return <HomeCarousel assets={assets}>{props.children}</HomeCarousel>;
};
