import * as t from "io-ts";

//
// Codecs
//
const NulleableString = t.union([t.string, t.null, t.undefined]);

const TeamFutureCodec = t.interface({
	team_id: t.number,
	id: t.number,
	provider: NulleableString,
	league: NulleableString,
	team_name: NulleableString,
	title: NulleableString,
	type: NulleableString,
	cutoff_date: NulleableString,
	price: NulleableString,
});

const BiggestFavoriteCodec = t.interface({
	id: t.number,
	provider: t.string,
	league: t.string,
	event_date: t.string,
	game_id: t.number,
	home_team: t.string,
	away_team: t.string,
	spread: NulleableString,
	over_under: NulleableString,
	money_line_1: NulleableString,
	money_line_2: NulleableString,
	price: NulleableString,
	home_team_initials: NulleableString,
	away_team_initials: NulleableString,
});

const EligiblePlayersPropsCodec = t.interface({
	id: t.number,
	title: t.string,
	type: t.string,
	league: t.string,
	provider: t.string,
	cutoff_date: NulleableString,
	created_at: NulleableString,
	updated_at: NulleableString,
	price: NulleableString,
});

const ElegiblePlayerCodec = t.interface({
	id: t.number,
	first_name: NulleableString,
	last_name: NulleableString,
	player_id: t.number,
	team_name: NulleableString,
	team_id: t.number,
	//sport_id: NulleableString,
	selected: t.boolean,
	date: NulleableString,
	prop: EligiblePlayersPropsCodec,
});

const GamePropsCodec = t.interface({
	id: t.number,
	league: t.string,
	event_date: t.string,
	game_id: t.number,
	home_team: t.string,
	away_team: t.string,
	provider: NulleableString,
	spread: NulleableString,
	over_under: NulleableString,
	money_line_1: NulleableString,
	money_line_2: NulleableString,
	url: NulleableString,
});

const TeamFuturePropsCodec = t.interface({
	id: t.number,
	team_name: NulleableString,
	team_id: t.number,
	title: NulleableString,
	type: NulleableString,
	provider: NulleableString,
	price: NulleableString,
	value: NulleableString,
	url: NulleableString,
});

const PlayerPropsCodec = t.interface({
	id: t.number,
	league: t.string,
	first_name: NulleableString,
	last_name: NulleableString,
	team_id: t.number,
	player_id: t.number,
	title: t.string,
	type: NulleableString,
	provider: NulleableString,
	price: NulleableString,
	value: NulleableString,
	url: NulleableString,
	team_name: NulleableString,
});

//known bug in api, returns empty arrays instead of null or empty objects
const EmptyField = t.union([t.array(t.unknown), t.null]);

//
// Response Codecs
//
export const MBBiggestFavoritesResponseCodec = t.array(BiggestFavoriteCodec);

export const MBTeamFuturesResponseCodec = t.array(TeamFutureCodec);

export const ElegiblePlayersResponseCodec = t.array(ElegiblePlayerCodec);

export const BetSubscriptionResponseCodec = t.boolean;

export const EventBetsResponseCodec = t.strict({
	game: t.union([GamePropsCodec, EmptyField]),
	teamFuture1: t.union([TeamFuturePropsCodec, EmptyField]),
	teamFuture2: t.union([TeamFuturePropsCodec, EmptyField]),
	playerProp1: t.union([PlayerPropsCodec, EmptyField]),
	playerProp2: t.union([PlayerPropsCodec, EmptyField]),
});

//
// Types
//
export type ElegiblePlayer = t.TypeOf<typeof ElegiblePlayerCodec>;

export type ElegiblePlayersResponse = t.TypeOf<typeof ElegiblePlayersResponseCodec>;

export type EventBetsResponse = t.TypeOf<typeof EventBetsResponseCodec>;

export type GameBet = t.TypeOf<typeof GamePropsCodec>;

export type TeamFutureBet = t.TypeOf<typeof TeamFuturePropsCodec>;

export type PlayerPropBet = t.TypeOf<typeof PlayerPropsCodec>;

export type BetSubscriptionResponse = t.TypeOf<typeof BetSubscriptionResponseCodec>;
