import { useEffect, useState } from "react";

type ScriptStatus = "pending" | "error" | "ready";

export function useScript(source: string, ready: boolean = true) {
	// Keep track of request status
	const [state, setState] = useState<ScriptStatus>("pending");

	useEffect(() => {
		// wait for dependencies
		if (!ready) {
			return;
		}

		let script: HTMLScriptElement | null = document.querySelector(`script[src="${source}"]`);
		let scriptOwner = !script;

		// Script already added, get current state
		// and subscribe to future states
		if (script) {
			setState(script.getAttribute("data-status") as ScriptStatus);
		}
		//Load a new script
		else {
			script = document.createElement("script");
			script.src = source;
			script.async = true;
			script.setAttribute("data-status", "pending");
			document.body.appendChild(script);
		}

		//Event listeners
		const onLoad = () => {
			script && scriptOwner && script.setAttribute("data-status", "ready");
			setState("ready");
		};
		const onError = ({ message }: ErrorEvent) => {
			console.error(message);
			script && scriptOwner && script.setAttribute("data-status", "error");
			setState("error");
		};

		script.addEventListener("load", onLoad);
		script.addEventListener("error", onError);

		return () => {
			if (script) {
				script.removeEventListener("load", onLoad);
				script.removeEventListener("error", onError);
			}
		};
	}, [source, ready]);

	return state === "ready";
}
