import { PaymentMethod } from "./braintree";
import { FutureEvent, FutureZone } from "./future";
import { ShippingAddress } from "./general";
import { DeliveryMethod, TicketGroup, TicketEvent } from "./ticket";

export enum TicketTransactionOrderStatus {
	Active = 1,
	Failed = 2,
	Cancelled = 3,
	Unconfirmed = 4,
	CancelledByChargeback = 5,
}

export enum TicketTransactionDeliveryStatus {
	Unshipped = 1,
	Shipped = 2,
	Delivered = 3,
	NotAvailable = 4,
}

export enum TicketTransactionEventStatus {
	Active = 2,
	Cancelled = 3,
	Rescheduled = 4,
	Postponed = 5,
	Past = 6,
}

//TODO: Take id out of this interface and put it into BaseTransaction. This id will stay as the order id for tickets and futures orders, btut will be mapped to futureInventoryId for futures listings and sales.
export interface MyAccountTransactionOrderInfo {
	id: number;
	item_id?: number;
	created: string;
	total?: number;
	tax?: number;
	ticketPriceWithoutDiscount?: number;
	discountCode?: string;
	discountAmount?: number;
}

interface BaseTransaction {
	order: MyAccountTransactionOrderInfo;
	quantity: number;
}

export interface TicketTransaction extends BaseTransaction {
	paymentMethod: PaymentMethod;
	deliveryMethod: DeliveryMethod;
	transactionFee: number;
	tax: number;
	ticketGroup: Omit<TicketGroup, "deliveryOptions">;
	ticketGroups: Omit<TicketGroup, "deliveryOptions">[];
	event: Omit<TicketEvent, "basePrice">;
	deliveryStatus: TicketTransactionDeliveryStatus | null;
	orderStatus: TicketTransactionOrderStatus;
	eventStatus: TicketTransactionEventStatus;
	shippingAddress: ShippingAddress | null;
	email: string | null;
	//TODO: Type this once the type is found
	fedexTrackingNumber: unknown | null;
	phone: string | null;
	//TODO: Rename to pdfEndpoint
	pdfUrl: string | null;
	//TODO: Ask backend dev to remove pdfUrls
	//pdfUrls: string | null,
	expectedShipDate: string | null;
	mobileTransferLink: string | null;
	promoWalletFundsUsed: number;
	source: string;
	isTransfer: boolean;
	acceptTransfer: (transferId: number) => void;
	hasPendingTransfer: boolean;
}

export enum FuturesTransactionMarketStatus {
	Remaining = 1,
	Succeeded = 2,
	Closed = 3,
}

export enum FuturesTransactionFulfillmentStatus {
	NotAvailable = 1,
	Unfulfilled = 2,
	Fulfilled = 3,
}

export interface FuturesTransaction extends BaseTransaction {
	event: Omit<FutureEvent, "sportsTitle">;
	zone: FutureZone;
	marketPrice: number | null;
	marketStatus: FuturesTransactionMarketStatus;
	fulfillmentStatus: FuturesTransactionFulfillmentStatus;
	//TODO: This property might be moved into zone.
	futureInventoryId: number;
}

export interface FuturesOrder extends FuturesTransaction {}

export interface FuturesListing extends FuturesTransaction {
	dateListed: string;
	listingPrice: number;
}

export interface FuturesSale extends Omit<FuturesListing, "dateListed"> {
	dateSold: string;
}
