import React from "react";
import styled from "styled-components/macro";
import { CommonPage } from "../components";
import { getAppColor } from "../util/appColors";
import { FontFamily } from "../components/Typography/Typography";
import { Config } from "../../Config";

const dotBackground = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/predraftShowCase/dot-background.png`);
const asset1 = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/predraftShowCase/asset-1.png`);

export const PredraftShowCaseRoute = () => {
	return (
		<CommonPage isFooterHidden={true}>
			<PageBackground>
				<h1
					css={`
						text-transform: uppercase;
						padding-top: 2rem;
						font-size: clamp(24px, 8vw, 26px);
						line-height: 30px;
						font-weight: bold;
						font-family: ${FontFamily.Industry};
					`}
				>
					Enjoy this
					<span
						css={`
							color: #ce3a3d;
						`}
					>
						{" "}
						SI Fan Cover{" "}
					</span>
					from SI Tickets!
				</h1>
				<div>
					<img
						src={asset1}
						alt="fan cover preview"
						css="
							width: 100%;
							max-width: 500px;
						"
					/>
				</div>
			</PageBackground>
			<div
				css={`
					background: black;
					color: ${getAppColor("light")};
				`}
			>
				<div
					css={`
						font-family: ${FontFamily.Poppins};
						font-size: clamp(18px, 6vw, 20px);
						font-weight: bold;
						padding-top: 20px;
						text-transform: uppercase;
					`}
				>
					<p
						css={`
							color: #ce3a3d;
						`}
					>
						<a href="https://sitickets.com/login?promoCode=PROSPECT5&newUser=false">Click Here</a>
					</p>
					<p
						css={`
							padding-top: 5px;
						`}
					>
						For $5 off at SI Tickets
					</p>
				</div>

				<p
					css={`
						padding-top: 20px;
						padding-bottom: 20px;
						font-size: clamp(16px, 4vw, 18px);
						font-weight: 500;
					`}
				>
					Link Valid for One Week Post-Event
				</p>
			</div>
		</CommonPage>
	);
};

const PageBackground = styled.div`
	background-image: url(${dotBackground});
	background-size: cover;
	color: ${getAppColor("light")};
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	align-item: center;
`;
