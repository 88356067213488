import React from "react";
import styled from "styled-components/macro";
import { useIsMobileWeb } from "../../hooks/useIsMobileWeb";
import { CollapsibleCard } from "../CollapsibleCard/CollapsibleCard";
import { mediaQueries } from "../../util/mediaQueries";
import { AboutUsCompanyData, AboutUsMeetTheTeamData } from "./AboutUsData";
import { AboutUsCompanySlider } from "./AboutUsCompanySlider";
import { AboutUsCompanyItem } from "./AboutUsCompanyItem";
import { FontFamily } from "../Typography/Typography";
import { getAppColor } from "../../util/appColors";

export const Company = () => {
	const isMobile = useIsMobileWeb();
	return isMobile ? (
		<AboutUsCompanySlider />
	) : (
		<>
			{AboutUsCompanyData.map((data, index) => {
				return <AboutUsCompanyItem key={index} {...data} />;
			})}
		</>
	);
};

export const MeetTheTeam = () => {
	return (
		<MeetTheTeamContainer>
			{AboutUsMeetTheTeamData.map(data => {
				return (
					<CollapsibleCard
						header={
							<h2>
								<Title>{data.name}</Title>
								<TitleSeparator />
								<Subtitle>{data.position}</Subtitle>
							</h2>
						}
					>
						{data.description}
					</CollapsibleCard>
				);
			})}
		</MeetTheTeamContainer>
	);
};

const MeetTheTeamContainer = styled.div`
	background: white;
	@media ${mediaQueries.max670} {
		margin-top: -20px;
	}
`;

const Title = styled.span`
	font-family: SolanoGothicMVB-Bd, sans-serif;
	font-size: 30px;
	line-height: 30px;
	font-weight: 700;
	text-transform: uppercase;
	margin-right: 32px;
	color: #000000;
	width: 170px;
	min-width: 170px;
	@media ${mediaQueries.max670} {
		font-size: 24px;
	}
`;

const TitleSeparator = styled.span`
	display: block;
	border-left: 1px solid ${getAppColor("darkGrey", "dark")};
	margin-right: 32px;
	height: 42px;
	@media ${mediaQueries.max670} {
		display: none;
	}
`;

const Subtitle = styled.span`
	display: block;
	font-family: ${FontFamily.Solano};
	font-size: 26px;
	line-height: 20px;
	font-weight: 600;
	text-transform: uppercase;
	color: ${getAppColor("darkGrey", "dark")};
	@media ${mediaQueries.max670} {
		font-size: 22px;
		line-height: 18px;
	}
`;
