import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { NavigationItem } from "../../../model/Navigation";
import { Input } from "../Input/Input";
import { NavLinks } from "../NavLinks/NavLinks";
import { usePopper } from "react-popper";
import { useMainSearch } from "../Search/useMainSearch";
import { MainSearchResults } from "../Search/MainSearchResults";
import lens_icon from "../../resource/img/icons/lens_icon.svg";

const MAIN_SEARCH_CONFIG = {
	debounceSearchMs: 200,
	minCharactersSearch: 3,
};

export const SecondaryNav = (props: { className?: string; items: NavigationItem[]; hideSearch?: boolean }) => (
	<SecondaryNavContainer className={props.className} tabIndex={-1} centerItems={true}>
		<div>
			<NavLinks items={props.items} />
		</div>
		{props.items.length > 0 && !props.hideSearch && <NavSearch hide={props.hideSearch} />}
	</SecondaryNavContainer>
);

//TODO: Consider moving to it's own file.
export const NavSearch = (props: { hide?: boolean }) => {
	const { events, venues, performers, categories, shouldShowResult, searchText, setSearchText, popularSubcategories, recentGenericSearches, recentEventSearches } = useMainSearch(null, MAIN_SEARCH_CONFIG);
	const [focused, setFocused] = useState(false);
	const [show, setShow] = useState(false);
	const referenceRef = useRef<any>(null);
	const popperRef = useRef<any>(null);
	const hide = typeof props.hide === "undefined" ? false : props.hide;

	const { styles, attributes } = usePopper(referenceRef.current, popperRef.current, {
		placement: "bottom-start",
		modifiers: [
			{
				name: "offset",
				enabled: true,
				options: {
					offset: [0, 10],
				},
			},
		],
	});

	const wrapperStyles = {
		maxWidth: "500px",
		width: "100%",
	};

	const [mainSearchVisible, setMainSearchVisible] = useState(false);
	const [initialSearchVisible, setInitialSearchVisible] = useState(false);
	const [initialSearchHasBeenDisplayed, setInitialSearchHasBeenDisplayed] = useState(false);

	useEffect(() => {
		if (focused || show) {
			switch (true) {
				case shouldShowResult:
					setMainSearchVisible(true);
					break;
				case !searchText.length && !initialSearchHasBeenDisplayed:
					setInitialSearchVisible(true);
					setInitialSearchHasBeenDisplayed(true);
					break;
				case !!searchText.length:
					setInitialSearchVisible(false);
					break;
			}
		} else {
			setMainSearchVisible(false);
			setInitialSearchVisible(false);
			setInitialSearchHasBeenDisplayed(false);
		}
	}, [focused, show, searchText, shouldShowResult]);

	if (hide) {
		return null;
	}

	return (
		<>
			<Input ref={referenceRef} clearable={focused} value={searchText} placeholder={"Search by team, artist, event or venue"} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)} onClear={() => setSearchText("")} onChange={e => setSearchText(e.target.value)} leftIcon={<img src={lens_icon} alt="search icon" />} style={{ border: `2px solid ${focused ? "#5F6368" : "#E5E5E5"}` }} containerClassName="search-container" className="search-input" />
			<div ref={popperRef} style={{ ...styles.popper, ...wrapperStyles }} {...attributes.popper} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
				<MenuContainer style={styles.offset} visible={!!initialSearchVisible}>
					<StyledMainSearchResults popularSubcategories={popularSubcategories} recentEventSearches={recentEventSearches} recentGenericSearches={recentGenericSearches} showSuggestions={false} />
				</MenuContainer>
				<MenuContainer style={styles.offset} visible={mainSearchVisible}>
					<StyledMainSearchResults events={events} performers={performers} categories={categories} venues={venues} showSuggestions={false} />
				</MenuContainer>
			</div>
		</>
	);
};

const SecondaryNavContainer = styled.div<{ centerItems?: boolean }>`
	text-align: left;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: ${props => (props.centerItems ? "center" : "space-between")};
	padding: 0 40px;
	.search-input {
		border-radius: 0px;
		font-weight: 500;
		font-size: 14px;
		font-family: Montserrat;
		color: #000000;
		[placeholder] {
			color: #5f6368;
			font-weight: 700;
		}
	}
	.search-container {
		flex: 1;
		margin-left: 40px;
		margin-bottom: 0px;
		max-width: 500px;
	}
`;

const StyledMainSearchResults = styled(MainSearchResults)`
	width: 100%;
	max-height: 80vh;
	overflow-y: auto;
`;

const MenuContainer = styled.div<{ visible: boolean }>`
	display: ${props => (props.visible ? "flex" : "none")};
	overflow: hidden;
	flex-direction: row;
	background: #ffffff;
	box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.15);
	color: #707372;
`;
