import React from "react";
import { ExternalRoutes } from "../../Link";
import mobile_app from "../../resource/img/sitix/mobile_app/mobile_app_mockup.png";
import appstore_button from "../../resource/img/sitix/mobile_app/appstore_button.svg";
import googleplaystore_button from "../../resource/img/sitix/mobile_app/googleplaystore_button.svg";
import styled, { css } from "styled-components/macro";
import { Link } from "../../Link";
import { mediaQueries } from "../../util/mediaQueries";
import { maxContentWidthStyles } from "../../util/maxContentWidth";

export const GetApp = () => (
	<GetAppContainer>
		<div>
			<MobileAppScreenshot src={mobile_app} alt="Get mobile app" />
			<Content>
				<SITixPromoText>
					<h1>Be At The Greatest Events At The Best Price</h1>
					<h3>With best-in-class features with tickets to everything, we have you covered.</h3>
				</SITixPromoText>
				<AppStoresRow>
					<GetAppButton to={ExternalRoutes.AppAppleStore}>
						<img src={appstore_button} alt="App Store" />
					</GetAppButton>
					<GetAppButton to={ExternalRoutes.AppAndroidStore}>
						<img src={googleplaystore_button} alt="Google Play Store" />
					</GetAppButton>
				</AppStoresRow>
			</Content>
		</div>
	</GetAppContainer>
);

const Content = styled.div`
	margin: auto 0;
`;
const mobileWidth = 670;

const mobile = `@media ${mediaQueries.max670}`;

const createResponsiveMargin = (pixels: number[], direction?: string) => {
	let fullMarginProp = "margin";
	if (direction) {
		fullMarginProp += `-${direction}`;
	}
	const vws = pixels.map(element => (element / mobileWidth) * 100 + "vw");
	const formatList = (array: any[]) => array.join(" ");
	const formattedVws = formatList(vws);
	const finalPixels = pixels.map(element => element + "px");
	const formattedPixels = formatList(finalPixels);
	const output = `    
    ${fullMarginProp}: ${formattedPixels};
    ${mobile} {
      ${fullMarginProp}: ${formattedVws};
    }
  `;
	return css`
		${output}
	`;
};

const MobileAppScreenshot = styled.img`
	max-height: 300px;
	${createResponsiveMargin([34], "right")}
	max-width: 30%;
`;

const GetAppContainer = styled.div`
	display: flex;
	justify-content: center;
	background-color: #11284b;
	background-image: url(${require("../../resource/img/sitix/mobile_app/sitix_blue_bckground.png")});
	background-repeat: no-repeat;
	background-size: cover;

	& > div {
		display: flex;
		align-items: flex-start;
		${maxContentWidthStyles}
		${createResponsiveMargin([41, 0])}
    justify-content: center;
		${mobile} {
			align-items: center;
		}
		& > div {
			max-width: 61%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}

	@media all and (display-mode: standalone) {
		display: none;
	}
`;

const SITixPromoText = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	${createResponsiveMargin([18], "bottom")}
	h1 {
		font-size: 2rem;
		line-height: normal;
		letter-spacing: 1px;
		${createResponsiveMargin([4], "bottom")}
		color: #fff;
		font-family: "SolanoGothicMVB-Bd" !important;
	}
	h3 {
		font-size: 0.975rem;
		line-height: normal;
		color: #fff;
	}
`;

const AppStoresRow = styled.div`
	display: flex;
	max-width: 74%;
	a:first-child {
		${createResponsiveMargin([15], "right")}
	}
	img {
		max-height: 40px;
		max-width: 100%;
		height: max-content;
	}
`;

const GetAppButton = styled(Link)`
	display: block;
`;
