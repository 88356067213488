import * as t from "io-ts";
import { Trend } from "../../model/Trend";
import { MetadataCodec } from "./MetadataResponseCodec";
import { DateTime } from "luxon";

export const TrendsFromAPI = t.interface({
	metadata: MetadataCodec,
	events: t.array(
		t.strict({
			event_id: t.number,
			event_name: t.string,
			event_slug: t.string,
			event_image: t.union([t.string, t.undefined]),
			occurs_at: t.string,
			venue_name: t.string,
			venue_slug: t.string,
		})
	),
});

export const TrendsDecoder = new t.Type<Trend[], Trend[], t.OutputOf<typeof TrendsFromAPI>>(
	"TrendsDecoder",
	(u: unknown): u is Trend[] => TrendsFromAPI.is(u),
	input =>
		t.success(
			input.events.map(i => ({
				id: i.event_id,
				name: i.event_name,
				slug: i.event_slug,
				image: i.event_image,
				date: DateTime.fromISO(i.occurs_at).toJSDate(),
				venue: i.venue_name,
				venueSlug: i.venue_slug,
			}))
		),
	t.identity
);

export type TrendsResponse = t.OutputOf<typeof TrendsDecoder>;
