import { APIClientResponse } from "./types/APIClientResponse";
import { apiClient } from "./apiClient";
import { UserAddress, Address, User, UserNotificationSettings } from "../model/User";
import { FuturesListing, FuturesOrder, FuturesSale, TicketTransaction } from "../model/optimizedModel/myAccount";

export function putMyAccountMe(fname: string, lname: string): APIClientResponse<User> {
	return apiClient.put("my-account/me", {
		fname,
		lname,
	});
}

export function putMyAccountSettings(value: boolean, key: string) {
	return apiClient.put("my-account/me/settings/" + key, {
		value,
	});
}

export function postFuturesListForSale(futureInventoryId: number, quantity: number, price: number): APIClientResponse<boolean> {
	return apiClient.post("futures/set-user-inventory-for-sale", {
		futureInventoryId,
		quantity,
		price,
	});
}

//TODO: Consider improving this type
export function postFuturesEditPrice(inventoryId: number, price: number): APIClientResponse<any> {
	return apiClient.post("futures/edit-price", {
		inventoryId,
		price,
	});
}

export function postMyAccountMyOrdersCancelListing(inventoryId: number): APIClientResponse<boolean> {
	return apiClient.post("my-account/my-orders-cancel-listing", {
		//TODO: Change to camelCase.
		inventory_id: inventoryId,
	});
}

export function putMyAccountPrimaryShippingAddress(id: number) {
	return apiClient.put("my-account/me/address/shipping/" + id);
}

export function putMyAccountFavorites(value: boolean) {
	return apiClient.put("my-account/me/settings/favorites", {
		value,
	});
}

export function putMyAccountDiscover(value: boolean) {
	return apiClient.put("my-account/me/settings/discover", {
		value,
	});
}

export function postMyAccountTicketsAccessed(orderId: string) {
	return apiClient.post("my-account/tickets-accessed", {
		orderId,
	});
}

export function getMyAccountTickets(): APIClientResponse<TicketTransaction[]> {
	return apiClient.get("my-account/tickets");
}

export function getMyAccountSettings(): APIClientResponse<UserNotificationSettings> {
	return apiClient.get("my-account/me/settings");
}

export function getMyAccountFuturesOrders(): APIClientResponse<FuturesOrder[]> {
	return apiClient.get("my-account/futures/orders");
}

export function getMyAccountFuturesListings(): APIClientResponse<FuturesListing[]> {
	return apiClient.get("my-account/futures/listings");
}

export function getMyAccountFuturesSold(): APIClientResponse<FuturesSale[]> {
	return apiClient.get("my-account/futures/sold");
}

export function postMyAccountAddress(address: Address): APIClientResponse<UserAddress> {
	return apiClient.post("my-account/me/address", address);
}

export function postMyAccountTicketsTransfer(payload: { email: string; ticketIds: number[] }): APIClientResponse<Record<string, unknown>> {
	return apiClient.post("my-account/tickets-transfer", payload);
}


export function postAcceptTicketsTransfer(transferId: number): APIClientResponse<Record<string, unknown>> {
	return apiClient.post(`my-account/transfer-accept/${transferId}`);
}

