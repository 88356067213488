import React from "react";
import { CoverAssets } from "../../../services/types/AssetResponse";
import { Config } from "../../../Config";
import styled from "styled-components/macro";
import { breakpoints } from "../../util/breakpoints";
import { FontFamily, getTypographyStyles } from "../Typography/Typography";
import { getAppColor } from "../../util/appColors";
import { maxContentWidthPadding, maxContentWidthStyles } from "../../util/maxContentWidth";
import { SkeletonWrapper } from "../SkeletonWrapper/SkeletonWrapper";
import { SkeletonProps } from "../../../model/optimizedModel/skeleton";
import tao_logo from "../../resource/assets/si-draft-party/tao_logo.png";
import hakkasan_logo from "../../resource/assets/si-draft-party/hakkasan_logo.png";
import si_draft_party_logo from "../../resource/assets/si-draft-party/si_draft_party_logo.png";
import si_draft_party_logo_by_si_tix from "../../resource/assets/si-draft-party/si_draft_party_logo_by_si_tix.png";
import { useSelector } from "react-redux";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";

const smallHeroBreakpoint = breakpoints.mobile;

//TODO: Overhaul the CoverAssets endpoint schema/structure & improve the title & heroImage functionalities in the below component.
export const Hero = (
	props: SkeletonProps<{
		assets: CoverAssets;
	}> & {
		performer?: string;
		tabs?: {
			title: string,
			onSelect: (index: number) => void,
		}[],
		selectedTab?: number,
	} & StyledComponentProps
) => {
	const smallHeroDisplayed = useMediaQuery(`(max-width: ${smallHeroBreakpoint})`);

	const title = props.assets?.main_headline_1;
	const heroImage = !props.assets?.banner_image ? undefined : props.assets.banner_image.startsWith("/uploads") ? Config.getResourceUrl(props.assets.banner_image) : props.assets.banner_image.startsWith("http") ? props.assets.banner_image : Config.getCDNUrl(props.assets.banner_image);
	const navbarState = useSelector(state => state.transient.navbar);
	return (
		<SkeletonWrapper loading={props.loading} className={props.className}>
			<HeroContainer
				style={
					props.assets?.primary_color !== "#ffffff"
						? {
								backgroundColor: props.assets?.primary_color,
						  }
						: undefined
				}
			>
				{props.performer === "si-draft-party" && (
					<div
						css={`
							position: absolute;
							left: 0;
							right: 0;
							bottom: 0;
							margin: auto;
							top: 0;
							box-sizing: border-box;
							display: flex;
							flex-direction: column;
							overflow: auto;
							align-items: center;
							padding-bottom: 16px;
							justify-content: space-between;
						`}
						style={{
							paddingTop: navbarState.height,
						}}
					>
						<div
							css={`
								display: flex;
								overflow: auto;
								justify-content: space-around;
								margin-bottom: 17px;
								width: 100%;
								align-items: center;
								& > img {
									max-height: 100%;
									max-width: 100%;
								}
							`}
						>
							<img src={tao_logo} alt="TAO logo" />
							<img src={hakkasan_logo} alt="Hakkasan logo" />
						</div>
						<img
							src={smallHeroDisplayed ? si_draft_party_logo : si_draft_party_logo_by_si_tix}
							alt="Sports Illustrated Draft Party logo"
							css={`
								max-height: 33%;
							`}
						/>
					</div>
				)}
				<HeroImage
					css={`
						${heroImage &&
						`
						${props.performer === "si-draft-party" && "background-position: center"};
						background-image: url(${heroImage});
					`}
					`}
				>
					<HeroContent>
						{
							title !== undefined && (
								<Title 
									titleColor={(title.style as React.CSSProperties).color || getAppColor("light")}
								>
									{title.text}
								</Title>
							)
						}
						{
							props.tabs && (
								<div
									css={`
										position: absolute;
										left: ${maxContentWidthPadding}px;
										bottom: 8px;
										color: white;
										font-family: ${FontFamily.Poppins};
										font-weight: 600;
										font-size: 16px;
										text-transform: uppercase;
										@media (max-width: ${breakpoints.mobile}) {
											font-size: 14px;
										}
										& > *:not(:last-child) {
											margin-right: 30px;
											@media (max-width: ${breakpoints.mobile}) {
												margin-right: 14px;
											}
										}
									`}
								>
									{
										props.tabs.map((tab, index) => {
											return (
												<span
													onClick={() => {
														tab.onSelect(index);
													}}
													key={index}
													css={`
														cursor: pointer;
														user-select: none;
														border-bottom: 4px solid transparent;
														text-align: center;
														${props.selectedTab === index && `
															border-color: white;
														`}
													`}
												>
													{tab.title}
												</span>
											);
										})
									}
								</div>
							)
						}
					</HeroContent>
				</HeroImage>
			</HeroContainer>
		</SkeletonWrapper>
	);
};

const HeroImage = styled.div`
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top;
	height: 100%;
	@media (max-width: ${smallHeroBreakpoint}) {
		background-position: right -40px top;
	}
`;

const HeroContent = styled.div`
	${maxContentWidthStyles}
	height: 100%;
	display: flex;
	padding-bottom: 45px;
	position: relative;
	@media (max-width: ${smallHeroBreakpoint}) {
		padding-bottom: 32px;
	}
`;

const Title = styled.h1<{ titleColor?: string }>`
	${getTypographyStyles("display1")}
	font-weight: 700;
	margin-top: auto;
	text-align: left;
	color: ${props => props.titleColor};
	${props => props.titleColor && `color: ${props.titleColor}`}
`;

const HeroContainer = styled.div`
	//TODO: Add functionality to shrink height for the mobile app navbar.
	height: 392px;
	@media (max-width: ${smallHeroBreakpoint}) {
		height: 225px;
	}
`;
