import React from "react";
import styled from "styled-components/macro";
import { useIsMobileWeb } from "../../hooks/useIsMobileWeb";
import { isMobileApp } from "../../util/isMobileApp";
import sportsIllustrated from "../../resource/image/sports-illustrated-horizontal.svg";
import { InternalRoutes, Link } from "../../Link";
import { MobileStoreButton } from "../MobileStoreButtons/MobileStoreButton";
import { SocialLink } from "../SocialLink/SocialLink";

export const MyAccountFooter = () => {
	const isMobileWeb = useIsMobileWeb();
	const isMobile = isMobileWeb || isMobileApp;

	if (isMobile) {
		return (
			<MyAccountFooterContainer>
				{isMobileWeb && !isMobileApp && (
					<>
						<div className="download-app-text">Download the App</div>
						<div className="download-app-links">
							<MobileStoreButton mobileStore="appStore" />
							<div className="spacer" />
							<MobileStoreButton mobileStore="googlePlay" />
						</div>
					</>
				)}
				<div className="follow-us">Follow us on</div>
				<img src={sportsIllustrated} alt="Sports Illustrated" />
				<SocialContainer>
					<SocialLink platform="twitter" color="dark" />
					<SocialLink platform="facebook" color="dark" />
					<SocialLink platform="instagram" color="dark" />
				</SocialContainer>
				<div className="legal">
					<Link to={InternalRoutes.TermsOfUse}>Terms Of Use</Link>
					<Link to={InternalRoutes.PrivacyPolicy}>Privacy Policy</Link>
				</div>
				<div className="copyright">
					Copyright © 2021 SI Tickets <span>All Rights Reserved</span>
				</div>
			</MyAccountFooterContainer>
		);
	}
	return null;
};

const SocialContainer = styled.div`
	margin-top: 23px;
	display: flex;
	width: 100%;
	justify-content: center;

	& > :not(:last-child) {
		margin-right: 10px;
	}
`;

const MyAccountFooterContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	margin-top: 30px;

	font-family: Montserrat;
	color: black;
	text-align: center;

	.download-app-text {
		font-size: 16px;
		font-weight: 700;
		line-height: 20px;
		text-align: center;
		text-transform: uppercase;
	}

	.download-app-links {
		display: flex;
		margin-top: 20px;
		margin-bottom: 42px;
		a {
			width: auto;
		}
		img {
			height: 38px;
			width: auto;
		}
	}
	.spacer {
		width: 12px;
	}

	.follow-us {
		text-transform: uppercase;
		font-size: 13px;
		font-weight: 700;
		line-height: 17px;
	}

	.legal {
		display: flex;
		font-size: 14px;
		font-weight: 500;
		line-height: 17px;
		letter-spacing: 0.02em;
		margin-top: 26px;

		& > a {
			padding: 0 30px;
		}

		& > a:last-child {
			border-left: 1px solid black;
		}
	}

	.copyright {
		font-size: 13px;
		font-style: normal;
		font-weight: 500;
		line-height: 17px;
		margin-top: 27px;
		max-width: 232px;
		margin-bottom: 192px;
		span {
			display: inline-block;
		}
	}

	img {
		margin-top: 6px;
		max-width: 200px;
		width: 100%;
		height: auto;
	}
`;
