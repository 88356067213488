import React from "react";
import { TicketTransaction } from "../../../../model/optimizedModel/myAccount";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styled from "styled-components/macro";
import { WalletSection } from "./WalletSection";
import { breakpoints } from "../../../util/breakpoints";

export type SuperTicketModalProps = {
	ticketTransaction: Pick<TicketTransaction, "ticketGroup">;
};

const isImage = (url: string) => {
	return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
};

const getExtensionOfUrl = (url: string) => {
	return "video/" + url.substring(url.lastIndexOf(".") + 1);
};

export const SuperTicketModal: React.FC<SuperTicketModalProps> = ({ ticketTransaction }) => {
	return (
		<>
			{!ticketTransaction.ticketGroup?.superTicketContent?.length ? (
				<div css="color:white; max-width:450px; padding: 25px; line-height: 2rem; font-size: 1.5rem">There’s no SuperTicket content yet. Keep checking back and be on the lookout for notifications!</div>
			) : (
				<div
					css={`
						display: flex;
						flex-direction: column;
						width: 100%;
						justify-content: space-between;
					`}
				>
					<div>
						{ticketTransaction.ticketGroup?.superTicketContent?.map(superTicket => (
							<div css="margin-top: 20px" key={superTicket.url}>
								{isImage(superTicket.url) ? (
									<img src={superTicket.url} alt="super ticket" width="100%" height="100%" />
								) : (
									<video width="100%" height="100%" controls>
										<source src={superTicket.url} type={getExtensionOfUrl(superTicket.url)} />
										Your browser does not support the video tag.
									</video>
								)}
							</div>
						))}
					</div>
					<div
						css={`
							margin-top: 30px;

							@media (max-width: ${breakpoints.mobile}) {
								padding-bottom: 25px;
							}
						`}
					>
						<WalletSection disclaimer="Transfer of Super Ticket™ NFT to personal wallet will be available in June." />
					</div>
				</div>
			)}
		</>
	);
};
