import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useCart } from "../../services/hooks/useCart";

export const useElite24EventTracking = () => {
	const history = useHistory();
	const eventUrlsToTrack = ["/event/ua-next-elite-24"];
	const { cart } = useCart();

	const trackEvent = (
		facebookScript: HTMLScriptElement, 
		facebookNoScript: HTMLElement, 
		facebookNoScriptImg: HTMLImageElement,
		eventScript: HTMLScriptElement, 
		eventNoScriptImg: HTMLImageElement, 
		eventNoScript: HTMLElement
	) => {
		facebookScript.textContent = `
			  !function(f,b,e,v,n,t,s)
			  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
			  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
			  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
			  n.queue=[];t=b.createElement(e);t.async=!0;
			  t.src=v;s=b.getElementsByTagName(e)[0];
			  s.parentNode.insertBefore(t,s)}(window, document,'script',
			  'https://connect.facebook.net/en_US/fbevents.js');
			  fbq('init', '450735625346180');
			  fbq('track', 'PageView');
			`;

		facebookNoScriptImg.src = "https://www.facebook.com/tr?id=450735625346180&ev=PageView&noscript=1";
		facebookNoScriptImg.width = 1;
		facebookNoScriptImg.height = 1;
		facebookNoScriptImg.style.display = "none";

		eventScript.textContent = `
			  gtag('event', 'conversion', {
				'allow_custom_scripts': true,
				'send_to': 'DC-8538166/elite0/elite0+standard'
			  });
			`;

		eventNoScriptImg.src = "https://ad.doubleclick.net/ddm/activity/src=8538166;type=elite0;cat=elite0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?";
		eventNoScriptImg.width = 1;
		eventNoScriptImg.height = 1;
		eventNoScriptImg.alt = "";

		document.head.appendChild(facebookScript);
		facebookNoScript.appendChild(facebookNoScriptImg);
		document.head.appendChild(facebookNoScript);
		document.head.appendChild(eventScript);
		eventNoScript.appendChild(eventNoScriptImg);
		document.head.appendChild(eventNoScript);
	};

	useEffect(() => {
		if (eventUrlsToTrack.includes(history.location.pathname)) {
			const facebookScript: HTMLScriptElement = document.createElement("script");
			const facebookNoScript: HTMLElement = document.createElement("noscript");
			const facebookNoScriptImg: HTMLImageElement = document.createElement("img");
			const eventScript: HTMLScriptElement = document.createElement("script");
			const eventNoScriptImg: HTMLImageElement = document.createElement("img");
			const eventNoScript: HTMLElement = document.createElement("noscript");
			trackEvent(facebookScript, facebookNoScript, facebookNoScriptImg, eventScript, eventNoScriptImg, eventNoScript);
			// Remove elements when the components that uses the hook unmounts
			return () => {
				document.head.removeChild(facebookScript);
				facebookNoScript.removeChild(facebookNoScriptImg);
				document.head.removeChild(facebookNoScript);
				document.head.removeChild(eventScript);
				eventNoScript.removeChild(eventNoScriptImg);
				document.head.removeChild(eventNoScript);
			};
		}
	}, [history.location.pathname, cart]);
};
