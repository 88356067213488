import { apiClient } from "./apiClient";
import { APIClientResponse } from "./types/APIClientResponse";
import { HomeAsset, HomeAssets } from "../model/Asset";

export function getAssetsHome(): APIClientResponse<HomeAsset[]> {
	return apiClient.get("assets/home");
}

export const getHomeAssets = (params?: { preview?: boolean }): APIClientResponse<HomeAssets> => {
	return apiClient.get("home", {
		params,
	});
};
