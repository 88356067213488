import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { getAppColor } from "../../../util/appColors";
import { FontFamily } from "../../Typography/Typography";

export const ButtonLink = ({ href, children, disabled, styles }: { href: string; children: React.ReactNode; disabled?: boolean; styles?: React.CSSProperties }) => {
	return (
		<div
			css={
				disabled
					? `
  pointer-events: none;
    cursor: default;`
					: undefined
			}
			style={styles}
		>
			<StyledLink to={href}>{children}</StyledLink>
		</div>
	);
};

const StyledLink = styled(Link)`
	background-color: #d94e41;
	color: ${getAppColor("light")};
	border-radius: 20px;
	font-size: 13px;
	font-weight: 600;
	width: 150px;
	border: none;
	cursor: pointer;
	letter-spacing: 2px;
	text-transform: uppercase;
	font-family: ${FontFamily.Industry};
	height: 30px;
	max-width: 150px;
	opacity: 0.85;
	display: flex;
	justify-content: center;
	align-items: center;

	text-transform: uppercase;
	&:hover {
		opacity: 0.6;
	}
`;
