import React, { useState } from "react";
import { postUserResetPassword } from "../../../services/user";
import { required, validEmail } from "../../util/optimized/finalFormUtil";
import { RFFInput } from "../Input/Input";
import { ModalPosition } from "../Modal";
import { ModalCloseButton } from "../Modal/ModalCloseButton";
import styled from "styled-components/macro";
import { useIsMobileWeb } from "../../hooks/useIsMobileWeb";
import { isMobileApp } from "../../util/isMobileApp";
import { Form, Field } from "react-final-form";
import { CheckmarkIcon, ErrorIcon } from "react-hot-toast";
import { ModalPopup } from "../ModalPopup/ModalPopup";
import { Button } from "../NewButton/Button";
import { Spinner } from "../Loader/Spinner";
import { useRemoteIp } from "../../../services/hooks/useRemoteIp";

const PasswordResetOutcome = ({ message, success }: { message: string; success: boolean }) => {
	const isMobile = useIsMobileWeb() || isMobileApp;
	return (
		<ResetOutcomeContainer isMobile={isMobile}>
			<div>{success ? <CheckmarkIcon /> : <ErrorIcon />}</div>
			<ResetOutcomeMessage isMobile={isMobile}>{message}</ResetOutcomeMessage>
		</ResetOutcomeContainer>
	);
};

export const ForgotPasswordModal = (props: { onDidClose: () => void; forcedReset: boolean }) => {
	const [email, setEmail] = useState(null);
	const [successfulReset, setSuccessfulReset] = useState(false);
	const [message, setMessage] = useState("");
	const remoteIp = useRemoteIp();

	return (
		<ModalPopup
			onDidClose={() => {
				props.onDidClose();
				setEmail(null);
			}}
			rightHeaderElements={(onClose: any) => <ModalCloseButton onClick={onClose} />}
			centerHeaderElements={props.forcedReset ? "Password Reset" : "Forgot Password"}
			position={ModalPosition.Center}
		>
			{props.forcedReset ? (
				<ForgotPasswordForm>
					<ForgotPasswordMessage>We’ve sent a password reset link to your email address on file. Please check your inbox to reset your password.</ForgotPasswordMessage>
					<BottomRow>
						<ButtonContainer>
							<Button type="button" onClick={props.onDidClose}>
								Close
							</Button>
						</ButtonContainer>
					</BottomRow>
				</ForgotPasswordForm>
			) : (
				<Form
					onSubmit={async values => {
						const email = values.email;
						await postUserResetPassword(email, props.forcedReset, remoteIp)
							.then(() => {
								setEmail(email);
								setSuccessfulReset(true);
								setMessage(`Password reset link has been sent to ${email}.`);
							})
							.catch(result => {
								setEmail(email);
								setSuccessfulReset(false);
								setMessage(result.errors[0]);
							});
					}}
					render={form => {
						return (
							<ForgotPasswordForm onSubmit={form.handleSubmit}>
								<ForgotPasswordMessage>Please enter the email address associated with your account. We'll send a link for you to reset your password.</ForgotPasswordMessage>
								<Field<string> name="email" disabled={form.submitting} type="email" placeholder="Email Address" validate={value => required(value) || validEmail(value)} component={RFFInput} />
								<BottomRow>
									{message && <PasswordResetOutcome message={message} success={successfulReset} />}
									{!successfulReset && (
										<ButtonContainer>
											{form.submitting && <StyledSpinner />}
											<Button type="submit" disabled={form.invalid}>
												Submit
											</Button>
										</ButtonContainer>
									)}
								</BottomRow>
							</ForgotPasswordForm>
						);
					}}
				/>
			)}
		</ModalPopup>
	);
};

const ButtonContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
	align-items: center;
`;

const BottomRow = styled.div`
	display: flex;
`;

const ForgotPasswordForm = styled.form`
	margin-top: 15px;
	max-width: 425px;
	width: 80vw;
	min-width: 33vw;
	display: flex;
	flex-direction: column;
`;

const ResetOutcomeContainer = styled.div<{ isMobile: boolean }>`
	display: flex;
	width: 100%;
	justify-content: flex-start;
	align-items: center;
`;

const ResetOutcomeMessage = styled.div<{ isMobile: boolean }>`
	font-size: ${props => (props.isMobile ? "11px" : "14px")};
	margin-left: 7px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const ForgotPasswordMessage = styled.span`
	font-weight: 500;
	font-size: 14px;
	margin-bottom: 12px;
	text-align: left;
	line-height: 1.3;
`;

const StyledSpinner = styled(Spinner)`
	margin: 0 10px;
`;
