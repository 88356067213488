import React, { useEffect, useState } from "react";
import { EventCubeTicket } from "../../../model/Cart";
import styled from "styled-components/macro";
import { getAppColor } from "../../util/appColors";
import { getTypographyStyles } from "../Typography/Typography";
import { shortFormatPrice } from "../../util/formatPrice";
import upArrow from "../../resource/image/up-arrow.png";
import downArrow from "../../resource/image/down-arrow.png";
import { breakpoints } from "../../util/breakpoints";
import { SantizedHTML } from "../SantizedHTML/SantizedHTML";

export const EventCubeTicketGroup = (props: { ticket: EventCubeTicket; onIncrementQty: () => void; onDecrementQty: () => void; quantity: number; disabled: boolean; incrementDisabled: boolean }) => {
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (props.disabled) {
			setOpen(false);
		}
	}, [props.disabled]);

	return (
		<>
			<Ticket>
				<TicketDetail disabled={props.disabled}>
					<TicketTitle>
						<SantizedHTML htmlString={props.ticket.title} />
						<CollapsibleContainer
							onClick={() => {
								setOpen(!open);
							}}
						>
							More details
							<ArrowIcon src={open ? upArrow : downArrow} alt="Collapsible icon" />
						</CollapsibleContainer>
					</TicketTitle>
					<TicketPriceContainer>
						{props.ticket.price === 0 ? (
							<TicketPrice> FREE </TicketPrice>
						) : (
							<>
								<TicketPrice>{shortFormatPrice(props.ticket.price)}</TicketPrice>
								<TicketPriceLabel>/ea</TicketPriceLabel>
							</>
						)}
					</TicketPriceContainer>
				</TicketDetail>
				<QuantityContainer disabled={props.disabled}>
					<QuantitySection>
						<QuantityLabel>Qty</QuantityLabel>
						<SelectedQuantity>{props.quantity}</SelectedQuantity>
					</QuantitySection>
				</QuantityContainer>
				<QuantityControlsContainer>
					<QuantityControl disabled={props.disabled || props.incrementDisabled} color={getAppColor("light", "main")} background={getAppColor("dark", "main")} onClick={props.onIncrementQty}>
						+
					</QuantityControl>
					<QuantityControl disabled={props.disabled} background={getAppColor("darkGrey", "light")} color={getAppColor("darkGrey", "main")} onClick={props.onDecrementQty}>
						-
					</QuantityControl>
				</QuantityControlsContainer>
			</Ticket>
			{open && props.ticket.description && (
				<MoreDetails>
					{" "}
					<SantizedHTML htmlString={props.ticket.description} />{" "}
				</MoreDetails>
			)}
		</>
	);
};

const Ticket = styled.div`
	cursor: pointer;
	border-bottom: 1px solid ${getAppColor("lightGrey", "dark")};
	background: ${getAppColor("light", "main")};
	display: flex;
`;

const TicketDetail = styled.div<{ disabled: boolean }>`
	padding: 20px 17px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 2;

	${props =>
		props.disabled &&
		`
	pointer-events: none;
	opacity: 0.4;
	`}
`;

const TicketTitle = styled.div`
	${getTypographyStyles("bodyMedium", { fontWeight: 600 })};

	@media (max-width: ${breakpoints.mobile}) {
		${getTypographyStyles("bodySmall", { fontWeight: 600 })};
	}
`;

const TicketPrice = styled.div`
	${getTypographyStyles("bodyMedium", { fontWeight: 600 })};
	font-size: 28px;

	@media (max-width: ${breakpoints.mobile}) {
		font-size: 21px;
	}
`;

const TicketPriceLabel = styled.div`
	display: flex;
	align-items: center;
	${getTypographyStyles("bodyMedium", { fontWeight: 300 })};

	@media (max-width: ${breakpoints.mobile}) {
		${getTypographyStyles("bodyTiny", { fontWeight: 300 })};
	}
`;

const TicketPriceContainer = styled.div`
	display: flex;
`;

const QuantityContainer = styled.div<{ disabled: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: center;

	${props =>
		props.disabled &&
		`
	pointer-events: none;
	opacity: 0.4;
	`}
`;

const QuantitySection = styled.div`
	border-left: 1px solid ${getAppColor("lightGrey", "dark")};
	padding: 0 26px;
`;

const QuantityLabel = styled.div`
	${getTypographyStyles("bodyNormal", { fontWeight: 600 })};

	@media (max-width: ${breakpoints.mobile}) {
		${getTypographyStyles("bodyTiny", { fontWeight: 600 })};
	}
`;

const SelectedQuantity = styled.div`
	${getTypographyStyles("bodyLarge", { fontWeight: 600 })};
	text-align: center;
	font-size: 28px;
	margin-top: 6px;

	@media (max-width: ${breakpoints.mobile}) {
		font-size: 21px;
	}
`;

const QuantityControlsContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const QuantityControl = styled.div<{
	background: string;
	color: string;
	disabled: boolean;
}>`
	${getTypographyStyles("bodyLarge", { fontWeight: 600 })};
	cursor: pointer;
	background: ${props => props.background};
	color: ${props => props.color};
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	flex: 1;
	padding: 4px 6px;

	${props =>
		props.disabled &&
		`
	pointer-events: none;
	background: ${getAppColor("darkGrey", "light")};
						color: ${getAppColor("darkGrey", "main")};
	`}

	@media (max-width: ${breakpoints.mobile}) {
		font-size: 24px;
		padding: 4px 9.5px;
	}
`;

const MoreDetails = styled.div`
	${getTypographyStyles("bodySmall")};
	background: ${getAppColor("darkGrey", "light")};
	padding: 8px 17px;
`;

const CollapsibleContainer = styled.div`
	${getTypographyStyles("bodySmall", {
		fontWeight: 600,
		color: "darkGrey",
		colorType: "main",
	})};
	width: 110px;

	@media (max-width: ${breakpoints.mobile}) {
		${getTypographyStyles("bodyTiny", {
			fontWeight: 600,
			color: "darkGrey",
			colorType: "main",
		})}
		width: 95px;
	}
`;

const ArrowIcon = styled.img`
	margin-left: 5px;
`;
