export const CategoriesMetaData = {
	"ncaa-football": {
		title: "College Football Tickets | NCAA Football Teams and Schedule Info on SI Tickets",
		description: "Buy College Football tickets for the NCAA Football season and postseason games on SI Tickets. Pay with Venmo for the best prices.",
	},

	"ncaa-basketball": {
		title: "College Basketball Tickets | NCAA Basketball Teams and Schedule info on SI Tickets",
		description: "Buy College Basketball tickets for the NCAA Basketball season and tournaments on SI Tickets. Pay with Venmo for the best prices.",
	},

	nfl: {
		title: "Buy NFL Tickets | NFL Teams and Schedule Info on SI Tickets",
		description: "Buy Football tickets for the season and playoffs on SI Tickets. Pay with Venmo for the best prices.",
	},

	nba: {
		title: "Buy NBA Tickets | NBA Teams and Schedule Info on SI Tickets",
		description: "Buy Basketball tickets for the season and playoffs on SI Tickets. Pay with Venmo for the best prices.",
	},

	nhl: {
		title: "NHL Tickets | NHL Teams and Schedule Info on SI Tickets",
		description: "Buy Hockey tickets for the season and playoffs on SI Tickets. Pay with Venmo for the best prices.",
	},

	mlb: {
		title: "MLB Tickets | MLB Teams and Schedule Info on SI Tickets",
		description: "Buy Baseball tickets for the season and postseason on SI Tickets. Pay with Venmo for the best prices.",
	},

	sports: {
		title: "SI Tickets: Buy Sports Events Tickets from Sports Illustrated",
		description: "Buy tickets for upcoming sports events including baseball, basketball, football. Pay with Venmo for the best prices. Find the best ticket deals with SI Tickets.",
	},

	concerts: {
		title: "SI Tickets: Buy Music Concert Tickets ",
		description: "Buy tickets for concerts, music festivals and more. Pay with Venmo for the best prices. Find tour schedules and concert venue details at SI Tickets. ",
	},

	theater: {
		title: "SI Tickets: Buy Theater Concert Tickets ",
		description: "Buy tickets for upcoming Broadway shows, musicals and theater events. Pay with Venmo for the best prices. Find your seat location, venue details at SI Tickets. ",
	},

	virtual: {
		title: "SI Tickets: Buy Virtual Event Tickets",
		description: "Buy tickets online with SI Tickets for the best prices on upcoming virtual events around the world. Pay with Venmo for the best prices",
	},
};

export const FuturesCategoriesMetaData = {
	"college-football": {
		title: "Buy College Football Championship Tickets | NCAA Football Teams and Schedule on SI Tickets",
		description: "Buy College Football tickets for the NCAA Football season and postseason on SI Tickets",
	},

	"college-basketball": {
		title: "Buy College Basketball Championship Tickets | NCAA Basketball Teams and Schedule on SI Tickets",
		description: "Buy College Basketball tickets for the NCAA Basketball season and postseason on SI Tickets",
	},

	nfl: {
		title: "Buy NFL Playoffs Tickets | Teams and Schedule on SI Tickets",
		description: "Buy Football tickets for the NFL season and playoffs on SI Tickets. Pay with Venmo for the best prices",
	},

	nba: {
		title: "Buy NBA Playoffs Tickets | Teams and Schedule on SI Tickets",
		description: "Buy Basketball tickets for the NBA season and playoffs on SI Tickets. Pay with Venmo for the best prices",
	},

	nhl: {
		title: "Buy NHL Playoffs Tickets | Teams and Schedule on SI Tickets",
		description: "Buy Hockey tickets for the NHL season and playoffs on SI Tickets. Pay with Venmo for the best prices",
	},

	mlb: {
		title: "Buy MLB Postseason Tickets | Teams and Schedule on SI Tickets",
		description: "Buy Baseball tickets for the MBL season and postseason on SI Tickets. Pay with Venmo for the best prices",
	},
};

export const SEOCategories = ["virtual", "theater", "concerts", "sports", "mlb", "nhl", "nba", "nfl", "ncaa-football", "ncaa-basketball"];

export const SEOFuturesCategories = ["mlb", "nhl", "nba", "nfl", "college-football", "college-basketball"];
