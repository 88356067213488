import React from "react";
import { useDispatch } from "react-redux";
import { useObjectMergeReducer, useServiceMonitor } from "../../hooks";
import { resetPassword } from "../../../services/actions/sessionService";
import { ResetForm, LoginError, LoginSpinner } from "./styles";
import { isLeft } from "fp-ts/lib/These";
import styled from "styled-components/macro";
import { useHistory } from "react-router";
import { InternalRoutes } from "../../Link";
import { useRemoteIp } from "../../../services/hooks/useRemoteIp";
import { parseSearch } from "../../util/parseSearch";
import { Button } from "../NewButton/Button";
import { Field, Form } from "react-final-form";
import { passwordsMatch, required, validPassword } from "../../util/optimized/finalFormUtil";
import { StyledRFFInput } from "../Input/Input";

interface PasswordChangeState {
	password?: string;
	confirmPassword?: string;
}

interface ResetPasswordErrors {
	general?: string;
	password?: string;
	confirmPassword?: string;
}

interface PasswordResetSearchParams {
	user?: string;
	email: string;
}

interface PasswordResetFormProps {
	token: string;
	email: string;
}

export const PasswordResetForm = (props: PasswordResetFormProps) => {
	const navigation = useHistory();
	const dispatch = useDispatch();
	const loading = useServiceMonitor(["change-password"]);
	const history = useHistory();
	const searchParams = parseSearch<PasswordResetSearchParams>(history.location.search);

	const [errors, setErrors] = useObjectMergeReducer<ResetPasswordErrors>({});

	const remoteIp = useRemoteIp();

	return (
		<Form
			onSubmit={async values => {
				const { password, confirmPassword } = values;
				const res = await dispatch(
					resetPassword({
						password: password,
						passwordConfirmation: confirmPassword,
						token: props.token,
						email: props.email,
						remoteIp,
					})
				);

				if (isLeft(res)) {
					switch (res.left.type) {
						case "SERVER_ERROR":
							setErrors({ general: res.left.errorMessage });
							break;
						case "SERVER_ERRORS":
							setErrors({ general: res.left.errorMessages.join(", ") });
							break;
						default:
							setErrors({ general: "There was an error processing your request" });
							break;
					}
				} else {
					if (res.right === false) {
						setErrors({ general: "Password reset link has expired." });
					} else {
						navigation.push(InternalRoutes.MyAccount());
					}
				}
			}}
			render={form => {
				return (
					<ResetForm method="POST" onSubmit={form.handleSubmit} noValidate={true}>
						<Title>{searchParams.user === "guest_user" ? "Sign Up" : "Reset Password"}</Title>

						<Field name="password" component={StyledRFFInput} type="password" placeholder="Password" validate={value => required(value) || validPassword(value)} showHidePasswordIcon />

						<Field name="confirmPassword" component={StyledRFFInput} type="password" placeholder="Confirm Password" validate={(value, allValues: PasswordChangeState) => required(value) || passwordsMatch(allValues.password, value)} showHidePasswordIcon />
						<LoginError>{errors.general}</LoginError>

						<Note>Note: Your password must be minumum eight characters long and contain at least one special character.</Note>
						{loading ? (
							<LoginSpinner />
						) : (
							<LoginButton type="submit" disabled={form.invalid || form.submitting}>
								Confirm
							</LoginButton>
						)}
					</ResetForm>
				);
			}}
		/>
	);
};

const Note = styled.p`
	margin-top: 20px;
	margin-bottom: 10px;
	font-size: 14px;
`;

const Title = styled.p`
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: center;
	color: #555555;
	margin-bottom: 20px;
`;

const LoginButton = styled(Button)`
	height: 50px;
`;
