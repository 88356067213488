import { DataCollector } from "braintree-web";
import { BraintreeActions } from "../reducers/braintreeReducer";
import { PaymentMethodWithToken } from "../../model/optimizedModel/braintree";

export const setBraintreeClientAuth = (clientAuth: string): BraintreeActions => ({
	type: "SET_BRAINTREE_CLIENT_AUTH",
	clientAuth,
});

export const setBraintreeDataCollector = (dataCollector: DataCollector): BraintreeActions => ({
	type: "SET_BRAINTREE_DATA_COLLECTOR",
	dataCollector,
});

export const setBraintreePaymentMethods = (paymentMethods: PaymentMethodWithToken[]): BraintreeActions => ({
	type: "SET_BRAINTREE_PAYMENT_METHODS",
	paymentMethods,
});

export const addBraintreePaymentMethod = (paymentMethod: PaymentMethodWithToken): BraintreeActions => ({
	type: "ADD_BRAINTREE_PAYMENT_METHOD",
	paymentMethod,
});

export const removeBraintreePaymentMethod = (index: number): BraintreeActions => ({
	type: "REMOVE_BRAINTREE_PAYMENT_METHOD",
	index,
});

export const setBraintreeVenmoSupported = (venmoSupported: boolean): BraintreeActions => ({
	type: "SET_BRAINTREE_VENMO_SUPPORTED",
	venmoSupported,
});

export const resetBraintreeState: BraintreeActions = {
	type: "RESET_BRAINTREE_STATE",
};

export const setBraintreeVenmoDeepLinkUrl = (venmoDeepLinkUrl: string): BraintreeActions => ({
	type: "SET_BRAINTREE_VENMO_DEEP_LINK_URL",
	venmoDeepLinkUrl,
});
