import React from "react";
import styled, { css } from "styled-components/macro";
import { InlineStylesProps, StyledComponentProps, InlineCSSObjectProps } from "../../../model/optimizedModel/styles";
import { BaseCosmeticInvoiceProps } from "../../../model/optimizedModel/invoice";
import { getTypographyStyles } from "../Typography/Typography";

export interface InvoiceItemProps extends BaseCosmeticInvoiceProps, StyledComponentProps, InlineStylesProps, InlineCSSObjectProps {
	//TODO: Determine if value should really be optional.
	value?: React.ReactNode | undefined;
	title: React.ReactNode;
	subtitle?: React.ReactNode;
	topContent?: React.ReactNode;
	bottomContent?: React.ReactNode;
	subtitleWhitespaceNowrap?: boolean;
}

export const InvoiceItem = ({ title, subtitle, value, topContent, bottomContent, css, subtitleWhitespaceNowrap, ...wrapperProps }: InvoiceItemProps) => {
	return (
		<>
			{topContent}
			<Wrapper
				{...wrapperProps}
				//TODO: Figure out why the css prop cannot be spread into this component with wrapperProps.
				css={css}
			>
				<Left>
					<Title>{title}</Title>
					{
						//TODO: Consider adding logic to remove/not use span if subtitle is JSX.
						subtitle && (
							<Subtitle
								style={
									subtitleWhitespaceNowrap
										? {
												whiteSpace: "nowrap",
										  }
										: undefined
								}
							>
								{subtitle}
							</Subtitle>
						)
					}
				</Left>
				{value !== undefined && <Value>{value}</Value>}
			</Wrapper>
			{bottomContent}
		</>
	);
};

const Title = styled.span`
	font-size: inherit;
	& > img {
		vertical-align: middle;
	}
`;

const Left = styled.span`
	overflow: inherit;
	display: flex;
	flex-direction: column;
	text-align: left;
`;

const Subtitle = styled.span`
	font-size: 13px;
	padding-top: 3px;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const Value = styled.span`
	font-size: inherit;
	margin-left: 24px;
`;

const getWrapperBorder = (position: "top" | "bottom") => {
	return css`
    border-${position}: 1px solid lightgray;
    padding-${position}: 10px;
  `;
};

const Wrapper = styled.div<BaseCosmeticInvoiceProps>`
	display: flex;
	overflow: hidden;
	justify-content: space-between;
	align-items: center;
	${getTypographyStyles("bodySmall", {
		fontWeight: 400,
	})};
	${props => (props.borderTop ? getWrapperBorder("top") : "")}
	${props => (props.borderBottom ? getWrapperBorder("bottom") : "")}
`;
