import React from "react";
import styled from "styled-components";
import { PasswordResetForm } from "./PasswordReset";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";

interface ResetPanelProps extends StyledComponentProps {
	token: string;
	email: string;
}

export const ResetPanel = (props: ResetPanelProps) => {
	return (
		<PanelContainer className={props.className}>
			<Content>
				<PasswordResetForm token={props.token} email={props.email} />
			</Content>
		</PanelContainer>
	);
};

const Content = styled.div`
	padding: 20px 40px;
	@media (max-width: 700px) {
		padding: 20px 20px;
	}
`;
const PanelContainer = styled.div`
	border: 1px solid lightgrey !important;
	padding-bottom: 20px;
`;
