import { Reducer } from "redux";
import { Cart, Discount } from "../../model/Cart";
import cloneDeep from "lodash/cloneDeep";

type CartAction =
	| {
			type: "DELETE_CART_TICKET";
	  }
	| {
			type: "DELETE_CART_FUTURE";
			futureInventoryId: string;
	  }
	| {
			type: "SET_CART_DISCOUNT";
			discount?: Discount;
	  }
	| {
			type: "SET_CART";
			cart: Cart | null | undefined;
	  };

export const cartReducer: Reducer<Cart | null | undefined, CartAction> = (state: Cart | null = null, action: CartAction) => {
	switch (action.type) {
		case "SET_CART":
			return action.cart;
		case "DELETE_CART_TICKET":
			return state
				? {
						...state,
						tickets: [],
						shippingFee: 0,
						source: ""
				  }
				: state;
		case "DELETE_CART_FUTURE":
			if (state) {
				const futures = cloneDeep(state.futures);
				delete futures[action.futureInventoryId];
				return {
					...state,
					futures,
				};
			}
			return state;
		case "SET_CART_DISCOUNT":
			console.log("test");
			return state
				? {
						...state,
						discount: action.discount,
				  }
				: state;

		default:
			return state;
	}
};

export const setCart = (cart: Cart): CartAction => ({
	type: "SET_CART",
	cart,
});

export const deleteCartTicket = (): CartAction => ({
	type: "DELETE_CART_TICKET",
});

export const setCartDiscount = (discount?: Discount): CartAction => ({
	type: "SET_CART_DISCOUNT",
	discount,
});

export const deleteCartFuture = (futureInventoryId: string): CartAction => ({
	type: "DELETE_CART_FUTURE",
	futureInventoryId,
});

export const clearCart = (): CartAction => ({
	type: "SET_CART",
	cart: null,
});
