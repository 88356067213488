import { useHistory, useRouteMatch } from "react-router-dom";
import queryString, { ParseOptions, StringifiableRecord } from "query-string";
import { useAutoUpdateState } from "./useAutoUpdateState";

export const useQueryStringState = <T extends StringifiableRecord>(parseOptions?: ParseOptions): [T, (query: T) => void] => {
	const match = useRouteMatch();

	const history = useHistory();

	//TODO: Consider useMemo for this function.
	const updateQueryStringState = (query: T) => {
		const urlObject = {
			url: match.path + history.location.search,
			query,
		};
		const to = queryString.stringifyUrl(urlObject);
		history.replace(to);
	};

	const queryStringState = useAutoUpdateState(() => {
		const value = queryString.parse(history.location.search, parseOptions);

		return value as T;
	}, [history.location.search]);

	return [queryStringState, updateQueryStringState];
};
