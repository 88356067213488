import { useEffect } from "react";
import { useRemoteIp } from "../../services/hooks/useRemoteIp";
import { useQueryStringState } from "./useQueryStringState";
import { validEmailRegex } from "../util/optimized/regexes";
import { useDispatch } from "react-redux";
import { didLogOut } from "../../store/reducers/sessionReducer";
import { postUserResetPassword } from "../../services/user";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { InternalRoutes } from "../Link";

export const useLoginRoute = (props: RouteComponentProps<{}, any, { from?: Location }>) => {
	const remoteIp = useRemoteIp();
	const dispatch = useDispatch();
	const hasSessionToken = useSelector(st => st.persistent.session.sessionToken !== undefined);
	const from = props.location.state?.from;

	const [queryStringState] = useQueryStringState<{
		restore?: string;
		email?: string;
		redirectUrl?: string;
	}>({
		decode: false,
	});

	const handleForgotPassword = async () => {
		await postUserResetPassword(queryStringState.email ? queryStringState.email : "", queryStringState.restore === "true", remoteIp);
	};

	useEffect(() => {
		if (queryStringState.restore === "true" && queryStringState.email && validEmailRegex.test(queryStringState.email) && remoteIp) {
			handleForgotPassword();
			dispatch(didLogOut());
		}
	}, [remoteIp]);

	useEffect(() => {
		if (hasSessionToken && !queryStringState.restore) {
			if (from?.pathname) {
				props.history.replace(from.pathname);
			} else {
				let route = queryStringState.redirectUrl ?? InternalRoutes.MyAccount();
				props.history.replace(route);
			}
		}
	}, [hasSessionToken, from, queryStringState.redirectUrl]);
};
