import styled from "styled-components/macro";
import { mediaQueries } from "../../util/mediaQueries";

export const LocationSubTitle = styled.span<{ empty: boolean }>`
	display: flex;
	flex-direction: row-reverse !important;
	cursor: pointer;
	border-left: 1px solid grey;
	margin-left: 12px;
	padding-left: 12px;
	color: black !important;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-decoration: ${props => (props.empty ? "underline" : "")};
	font-weight: 600 !important;
	img.location {
		height: 14px;
		width: auto;
		align-self: center;
		margin-right: 4px;
	}
	img.arrow {
		height: 10px;
		width: auto;
		align-self: center;
		margin-left: 4px;
	}

	font-size: 18px !important;
	@media ${mediaQueries.max670} {
		font-size: 13px !important;
	}
`;
