import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { CollapseArrow } from "../CollapseArrow/CollapseArrow";
import { breakpoints } from "../../util/breakpoints";
import { getAppColor } from "../../util/appColors";
import smoothscroll from "smoothscroll-polyfill";
import { InlineCSSProps, StyledComponentProps } from "../../../model/optimizedModel/styles";
import { CarouselArrow } from "./CarouselArrow";
import { useAutoUpdateState } from "../../hooks/useAutoUpdateState";

export const CarouselRow = ({
	rowsAmountPreference = 1,
	...props
}: {
	items?: JSX.Element[];
	customLeftControl?: JSX.Element;
	customRightControl?: JSX.Element;
	disableScrolling?: boolean;
	itemHeight?: number;
	rowsAmountPreference?: number;
	itemGap?: [number, number?];
	thumbnailsDisplayedPerRow?: number;
	inlineButtons?: boolean;
	showButtonsOnMobile?: boolean;
} & StyledComponentProps &
	InlineCSSProps) => {
	const [scrollAmount, setScrollAmount] = useState(0);
	const [showLeftControl, setShowLeftControl] = useState(false);
	const [showRightControl, setShowRightControl] = useState(false);

	const itemColumnGap = useAutoUpdateState(() => props.itemGap?.[1] || props.itemGap?.[0] || 0, [props.itemGap?.[0], props.itemGap?.[1]]);

	const onScroll = () => {
		if (carouselContentRef.current) {
			const contentScrollWidth = carouselContentRef.current?.scrollWidth;
			const contentClientWidth = carouselContentRef.current?.clientWidth;
			const contentScrollLeft = carouselContentRef.current?.scrollLeft;
			setShowLeftControl(contentScrollLeft > 0);
			setShowRightControl(contentScrollWidth - contentClientWidth > Math.ceil(contentScrollLeft));
		}
	};

	const goNext = () => {
		carouselContentRef.current?.scrollBy({
			left: scrollAmount,
			behavior: "smooth",
		});
	};

	const goPrevious = () => {
		carouselContentRef.current?.scrollBy({
			left: -scrollAmount,
			behavior: "smooth",
		});
	};

	const carouselItemRef = useRef<HTMLDivElement>(null);
	const carouselContentRef = useRef<HTMLDivElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (carouselItemRef.current) {
			setScrollAmount(carouselItemRef.current.offsetWidth + itemColumnGap);

			if (carouselContentRef.current) {
				carouselContentRef.current?.addEventListener("scroll", () => {
					onScroll();
				});
				return () => {
					carouselContentRef.current?.removeEventListener("scroll", onScroll);
				};
			}
		}
	}, [carouselItemRef.current?.offsetWidth]);

	useEffect(() => {
		smoothscroll.polyfill();
	}, []);

	useEffect(() => {
		onScroll();
	}, [props.items]);

	let leftControl = props.customLeftControl ? props.customLeftControl : <StyledCarouselArrow direction="left" weight={3} size={9} inlineButtons={props.inlineButtons} />;

	let rightControl = props.customRightControl ? props.customRightControl : <StyledCarouselArrow direction="right" weight={3} size={9} inlineButtons={props.inlineButtons} />;

	const [maximumAllowedRows, setMaximumAllowedRows] = useState(rowsAmountPreference);

	useEffect(() => {
		if (props.items?.length && props.thumbnailsDisplayedPerRow) {
			setMaximumAllowedRows(Math.floor(props.items?.length / props.thumbnailsDisplayedPerRow) || 1);
		}
	}, [props.items]);

	return (
		<CarouselContainer ref={containerRef} className={props.className} css={props.css} style={{ display: props.inlineButtons ? "inline-flex" : "" }}>
			{showLeftControl && !props.disableScrolling && <CarouselControl direction="left" onClick={goPrevious} controlToRender={leftControl}
																																			inlineButtons={props.inlineButtons} showButtonsOnMobile={props.showButtonsOnMobile} />}

			<ContentWrapper
				style={{
					overflowX: props.disableScrolling ? "hidden" : "auto",
				}}
				ref={carouselContentRef}
			>
				<CarouselContent
					style={{
						gridTemplateRows: `repeat(${maximumAllowedRows >= rowsAmountPreference ? rowsAmountPreference : maximumAllowedRows}, 1fr)`,
						gridGap: !!props.itemGap?.length ? `${props.itemGap[0]}px ${itemColumnGap}px` : undefined,
					}}
					className="content"
				>
					{props.items?.map((item, index) => {
						return (
							<div key={index} ref={carouselItemRef} className="carouselItem">
								{item}
							</div>
						);
					})}
				</CarouselContent>
			</ContentWrapper>
			{showRightControl && !props.disableScrolling && <CarouselControl direction="right" onClick={goNext} controlToRender={rightControl}
																																			 inlineButtons={props.inlineButtons} showButtonsOnMobile={props.showButtonsOnMobile} />}
		</CarouselContainer>
	);
};

const CarouselControl = ({
													 top = "50%",
													 ...props
												 }: {
	direction: "left" | "right";
	onClick: () => void;
	controlToRender: JSX.Element;
	inlineButtons?: boolean;
	showButtonsOnMobile?: boolean;
} & Pick<React.CSSProperties, "top">) => {
	return (
		<ControlContainer
			style={{
				[props.direction]: props.inlineButtons ? null : 30,
				position: props.inlineButtons ? "relative" : "absolute",
				top,
				height: props.inlineButtons ? "100%" : ""
			}}
			onClick={props.onClick}
			showButtonsOnMobile={props.showButtonsOnMobile}
		>
			{props.controlToRender}
		</ControlContainer>
	);
};

const ControlContainer = styled.div<{showButtonsOnMobile?: boolean}>`
  position: absolute;
  z-index: 1;
  transform: translateY(-50%);
  cursor: pointer;

  @media (hover: none) and (pointer: coarse) {
    display: ${props => props.showButtonsOnMobile ? "" : "none"};
  }
`;

const CarouselContainer = styled.div`
	width: 100%;
	position: relative;
	z-index: 0;
`;

const ContentWrapper = styled.div`
	display: flex;
	width: 100%;
	position: relative;
	& {
		scrollbar-width: none;
		-ms-overflow-style: none;
	}
	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
`;

const CarouselContent = styled.div`
	transition: all 250ms linear;
	display: grid;
	grid-auto-flow: column dense;
`;

const StyledCarouselArrow = styled(CarouselArrow)`
  svg {
    color: ${getAppColor("primary")};
  }
  width: 24px;
  height: ${props => props.inlineButtons ? "100%" : "30px"};
  border-radius: ${props => props.inlineButtons ? "0px" : "24px"};
  background-color: ${getAppColor("light")};
  border: ${props => props.inlineButtons ? "" : `1px solid ${getAppColor("lightGrey", "dark")}`};
  border-left: ${props => props.inlineButtons && props.direction === "right" ? `1px solid ${getAppColor("darkGrey")}` : ""};
  border-right: ${props => props.inlineButtons && props.direction === "left" ? `1px solid ${getAppColor("darkGrey")}` : ""};
  opacity: 0.95;
`;
