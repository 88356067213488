import axios from "axios";
import { store } from "../store/store";
import { getAndDispatchBraintreeClientToken } from "./braintree";

const braintreeGraphQLClient = axios.create({
	baseURL: window._env_?.APP_ENV === "prod" ? "https://payments.braintree-api.com/graphql" : "https://payments.sandbox.braintree-api.com/graphql",
	method: "POST",
	headers: {
		"Braintree-Version": "2021-02-26",
		"Content-Type": "application/json",
	},
});

//TODO: DRY the bearer interceptors setup?
braintreeGraphQLClient.interceptors.request.use(
	config => {
		//TODO: Memoize the authFingerprint in the routes.tsx token setup?
		const clientAuth = store.getState().persistent.braintree.clientAuth;
		if (clientAuth) {
			const decodedClientAuth = JSON.parse(atob(clientAuth));
			const authorizationFingerprint = decodedClientAuth.authorizationFingerprint;
			config.headers.Authorization = `Bearer ${authorizationFingerprint}`;
		}
		return config;
	},
	error => Promise.reject(error)
);

export const braintreeGraphQLRequest = async <T = any>(
	query: string
): Promise<
	any & {
		data: {
			data: T;
			extensions: object;
		};
	}
> => {
	const response = await braintreeGraphQLClient.request({
		data: {
			query,
		},
	});

	if (response?.data?.errors?.length) {
		const errors = response.data.errors;
		const refreshToken = errors.some((error: any) => {
			return error.extensions.errorClass === "AUTHENTICATION";
		});
		if (refreshToken) {
			await getAndDispatchBraintreeClientToken();
			return braintreeGraphQLClient.request({
				data: {
					query,
				},
			});
		}
	}
	return response;
};
