import { css } from "styled-components";
import { maxContentWidthValue } from "./maxContentWidth";

export const responsiveSize = (size: number) => {
	const maxContentWidthVW = maxContentWidthValue / 100;
	const responsiveFontSize = size / maxContentWidthVW + "vw";
	const output = "min(" + responsiveFontSize + ", " + size + "px) !important";
	return output;
};

export const responsiveFontSize = (fontSize: number) => {
	const output = responsiveSize(fontSize);
	return "font-size: " + output + ";";
};

const aspectRatio = 514 / 1930;
export const responsiveBannerHeight = aspectRatio * 100;
export const responsiveBannerMaxHeight = maxContentWidthValue * aspectRatio;

export const responsiveBannerSize = css`
	height: ${responsiveBannerHeight}vw;
	max-height: ${responsiveBannerMaxHeight}px;
`;

export const responsiveFontSizeFromString = (fontSize?: string) => {
	return responsiveFontSize(fontSize ? Number(fontSize.replace(/\D/g, "")) : 0);
};
