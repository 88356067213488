import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components/macro";
import { mediaQueries, popupMaxWidth } from "../../util/mediaQueries";
import { useModalRouteContext } from "../../util/ModalRouteContext";
import { Modal } from "../Modal";
import { ModalReactNode, OnCloseFunction } from "../../../model/Modal";
import { ModalPosition } from "../Modal/ModalPosition";

const MAX_MODAL_HEIGHT = "80%";

interface ModalPopupProps {
	animationLength?: number;
	backgroundColor?: string;
	backdropColor?: string;
	children: ModalReactNode;
	footer?: React.ReactNode;
	position?: ModalPosition;
	onDidClose?: () => void;
	leftHeaderElements?: ModalReactNode;
	centerHeaderElements?: ModalReactNode;
	rightHeaderElements?: ModalReactNode;
	className?: string;
	styleModal?: boolean;
	fullscreen?: boolean;
	hideLogo?: boolean | true;
	hideHeader?: boolean | false;
}

export const defaultModalAnimationLength = 0.3;

export const ModalPopup = ({ animationLength = defaultModalAnimationLength, backdropColor, backgroundColor, children, footer, onDidClose, leftHeaderElements, centerHeaderElements, rightHeaderElements, position, className, styleModal, fullscreen, hideLogo, hideHeader }: ModalPopupProps) => {
	const [show, setShow] = useState(false);

	useEffect(() => {
		setShow(true);
	}, [setShow]);

	const { closeAllModals } = useModalRouteContext();

	const onClose = () => {
		setShow(false);
		setTimeout(onDidClose ?? closeAllModals, animationLength * 1000);
	};

	const renderModalReactNode = (modalReactNode: ModalReactNode): ModalReactNode => {
		return typeof modalReactNode === "function" ? (modalReactNode as OnCloseFunction)(onClose) : modalReactNode;
	};

	return (
		<Modal show={show} animationLength={animationLength} backdropColor={backdropColor} onClose={onClose} position={position} className={styleModal ? className : ""}>
			<ModalContainer show={show} fullscreen={fullscreen} animationLength={animationLength} backgroundColor={backgroundColor} position={position} onClick={event => event.stopPropagation()} className={styleModal ? "" : className}>
				{!hideHeader && (
					<ModalHeader>
						<ModalHeaderLeft>{renderModalReactNode(leftHeaderElements)}</ModalHeaderLeft>
						<ModalHeaderCenter>{renderModalReactNode(centerHeaderElements)}</ModalHeaderCenter>
						<ModalHeaderRight>{renderModalReactNode(rightHeaderElements)}</ModalHeaderRight>
					</ModalHeader>
				)}
				<ModalWrapper id="modal-wrapper">{renderModalReactNode(children)}</ModalWrapper>
				{footer}
			</ModalContainer>
		</Modal>
	);
};

const ModalHeaderLeft = styled.div`
	display: inline-block;
`;

const ModalHeaderRight = styled.div`
	display: inline-block;
`;

const ModalHeaderCenter = styled.div`
	font-family: "Montserrat";
	position: absolute;
	left: 0;
	right: 0;
	z-index: 0;
	max-width: min(45vw, 147px);
	margin: 0 auto;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
	&,
	& > * {
		max-height: 28px;
	}
`;

const ModalHeader = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: bold;
	font-size: 16px;
	color: #000;
	border-bottom: 1px solid #ddd;
	background: #ffffff;
	& > *:not(${ModalHeaderCenter}) {
		z-index: 1;
	}
`;

const ModalContainerPadding = "15px";

const ModalContainer = styled.div.attrs(props => ({ className: props.className }))<{
	animationLength: number;
	backgroundColor?: string;
	position?: ModalPosition;
	show: boolean;
	fullscreen?: boolean;
}>`
	max-height: ${props => (props.fullscreen ? "100%" : MAX_MODAL_HEIGHT)};
	background-color: ${props => props.backgroundColor ?? "#fff"};

	@media (max-width: 960px) and (min-width: ${popupMaxWidth}px) {
		max-width: ${props => !props.fullscreen && "80vw"};
	}
	@media ${mediaQueries.popupMax} {
		max-width: 100vw;
	}
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: stretch;

	& > div:first-of-type {
		padding: ${ModalContainerPadding} ${ModalContainerPadding} 10px ${ModalContainerPadding};
	}

	animation: ${props => (props.show ? raise : lower)} ${props => props.animationLength}s linear;

	${props =>
		props.position === ModalPosition.Bottom
			? css`
					border-radius: ${props.fullscreen ? "0" : "15px 15px 0 0"};
			  `
			: props.position === ModalPosition.Center
			? ""
			: css`
					@media ${mediaQueries.popupMax} {
						border-radius: ${props.fullscreen ? "0" : "15px 15px 0 0"};
					}
			  `}

	${props =>
		props.fullscreen &&
		`
		width: 100vw;
	`}
`;

const ModalWrapper = styled.div`
	overflow-y: auto;
	padding: 0 ${ModalContainerPadding} ${ModalContainerPadding} ${ModalContainerPadding};
`;

const raise = keyframes`
from {
        max-height: 0%;
}

to {
        max-height: ${MAX_MODAL_HEIGHT};
}
`;

const lower = keyframes`
from {
        max-height: ${MAX_MODAL_HEIGHT};
}

to {
        max-height: 0%;
}
`;
