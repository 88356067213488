import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { ModalPopup } from "../ModalPopup/ModalPopup";
import { ModalCloseButton } from "../Modal/ModalCloseButton";
import { Button } from "../Button/Button";
import { useCheckout } from "../../../services/hooks/useCheckout";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";
import { getAppColor } from "../../util/appColors";
import { Typography } from "../Typography/Typography";
import { useCart } from "../../../services/hooks/useCart";

interface TicketTimerProps extends StyledComponentProps {
	size?: "normal" | "small";
	onExpiredModalClosed?: () => void;
}

export const TicketTimer = ({ size = "normal", ...props }: TicketTimerProps) => {
	const { remainingTime, timerLimit } = useCheckout();
	const { cart } = useCart();
	const [showTimer, setShowTimer] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const formatDisplayedTime = (time: number) => {
		const minutes = Math.trunc(time / 60);
		const seconds = Math.trunc(time % 60);

		const minutesString = minutes < 10 ? `0${minutes}` : minutes.toString();
		const secondsString = seconds < 10 ? `0${seconds}` : seconds.toString();

		return `${minutesString}:${secondsString}`;
	};

	useEffect(() => {
		setShowModal(!cart && !remainingTime);
		setShowTimer(!!cart && remainingTime > 0 && remainingTime < timerLimit);
	}, [cart, remainingTime]);

	if (showTimer) {
		return (
			<TicketTimerContainer className={props.className}>
				<Typography
					type={
						{
							normal: "bodySmall",
							small: "bodyTiny",
						}[size]
					}
					color="inherit"
					css={`
						text-transform: uppercase;
					`}
				>
					Time Left
				</Typography>
				<Typography type="heading1" color="inherit">
					{formatDisplayedTime(remainingTime)}
				</Typography>
			</TicketTimerContainer>
		);
	}

	if (showModal) {
		return (
			<ModalPopupTimer>
				<ModalPopup
					onDidClose={() => {
						if (props.onExpiredModalClosed) {
							props.onExpiredModalClosed();
						}
					}}
					className="ModalPopupTimer"
					rightHeaderElements={(onClose: any) => <ModalCloseButton onClick={onClose} />}
				>
					{onDidClose => {
						return (
							<ModalBodyWrapper>
								<ModalTitle size={size || ""}>
									{/*TODO: Dynamic value*/}
									The 15 minute checkout
									<br />
									timer expired.
								</ModalTitle>
								<ModalText>Sorry but we had to clear your cart.</ModalText>
								<ButtonWrapper size={size || ""}>
									<Button
										onClick={() => {
											onDidClose();
										}}
									>
										Close
									</Button>
								</ButtonWrapper>
							</ModalBodyWrapper>
						);
					}}
				</ModalPopup>
			</ModalPopupTimer>
		);
	}

	return null;
};

const TicketTimerContainer = styled.div`
	color: ${getAppColor("darkGrey", "dark")};
	font-weight: bold;
	& * {
		line-height: 0.9;
	}
`;

const ModalTitle = styled.h2<{ size?: string }>`
	font-size: 24px;
	margin: ${props => (props.size == "small" ? "20px" : "30px 100px")};
	font-weight: bold;
	color: #000;
	line-height: 30px;
	text-align: center;
`;

const ModalText = styled.p`
	font-size: 16px;
	color: #000;
	margin-bottom: 30px;
	text-align: center;
	font-weight: normal;
`;

const ModalPopupTimer = styled.div`
	.ModalPopupTimer #sitix-modal-header {
		display: inline-block;
	}
`;

const ModalBodyWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 15px;
`;

const ButtonWrapper = styled.div<{
	size?: string;
}>`
	button {
		font-size: 16px;
	}
`;
