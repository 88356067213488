import React, { useEffect, useState } from "react";
import { Route, RouteComponentProps, Switch, useHistory, useLocation } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { CommonPage } from "../components";
import { InternalRoutes } from "../Link";
import { maxContentWidthPadding, maxContentWidthStyles } from "../util/maxContentWidth";
import { MetaDecorator, MetaTagData } from "../components/SEOMetadecorator/MetaDecorator";
import { ErrorToaster } from "../components/ErrorToaster/ErrorToaster";
import { useIsMobileWeb } from "../hooks/useIsMobileWeb";
import { isMobileApp } from "../util/isMobileApp";
import { Config } from "../../Config";
import { apiClient } from "../../services/apiClient";
import { toast } from "react-hot-toast";
import { TicketTransaction } from "../../model/optimizedModel/myAccount";
import { OrderFromQRResponse } from "../../model/OrderFromQR";
import { TicketModalView, TicketModalViewProps } from "../components/MyAccount/TicketModal/TicketModalView";
import { postAcceptTicketsTransfer } from "../../services/myAccount";
import { getAppColor } from "../util/appColors";
import { Spinner } from "../components/Loader/Spinner";
import { TicketGroup } from "../../model/optimizedModel/ticket";

interface ShowMyTicketsRouteParams {
  qr: string;
}

export const ShowMyTicketsRoute = (props: RouteComponentProps<ShowMyTicketsRouteParams>) => {
  const qrCode = props.match.params.qr;
  const isMobileWeb = useIsMobileWeb();
  const isMobile = isMobileWeb || isMobileApp;
  const [ticketTransaction, setTicketTransaction] = useState<Pick<TicketTransaction, "event" | "ticketGroup" | "ticketGroups"> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const searchParams = new URLSearchParams(useLocation().search);

  const acceptTransfer = (transferId: number) => {
		postAcceptTicketsTransfer(+transferId).then(result => {
			searchParams.delete("transferId")
			toast.success("Transfer Accepted")
			fetchOrderByQR()
			//this is stupid we need to fix these components because they do not render properly
			window.location.reload();
		}).catch(error => {
			if (typeof error === "string") {
				toast.error(error);
			}
		})
	}

  useEffect(() => {	
		const transferId = searchParams.get("transferId");
		if(transferId && !isNaN(+transferId)){
			acceptTransfer(+transferId)
		}
		fetchOrderByQR()
	}, []);

  const fetchOrderByQR = async (): Promise<void> => {
    setLoading(true)
    const res = await apiClient.get<OrderFromQRResponse>(`tickets/qr/${qrCode}`, {
      baseURL: Config.getBoxOfficeUrl()
    }).catch(e => {
      toast.error(e?.error ?? "Could not retrieve your order.")
      setLoading(false)
      return
    })
    if (!res) {
      toast.error("Could not retrieve your order.")
      setLoading(false)
      return
    }
    const order: OrderFromQRResponse = res.data;
    const ticketGroups: Omit<TicketGroup, "deliveryOptions">[] = order.ticketGroups.map(t => {
      return {
        id: t.id,
        notes: t.notes,
        price: t.price,
        row: t.row,
        section: t.section,
        tickets: t.tickets,
        tierImage: t.tierImage,
        tierDescription: t.tierDescription,
        assignedSeating: t.assignedSeating,
      }
    })
    const transaction: TicketModalViewProps = {
      ticketTransaction: {
        event: {
          name: order.event.name,
          eventId: order.event.id,
          slug: order.event.slug,
          childrenEventsSlugs: order.event.childrenEventsSlugs,
          venue: {
            name: order.event.venue.name,
            address: {
              address1: order.event.venue.address1,
              city: order.event.venue.city,
              state: order.event.venue.state,
              zip: order.event.venue.zip
            }
          },
          performers: order.event.performers,
          occursAt: order.event.occursAt
        },
        //todo allow this to be an array
        ticketGroups,
        ticketGroup: ticketGroups[0]
      }
    }
    setTicketTransaction(transaction.ticketTransaction)
    setLoading(false)
  }

  return (
    <CommonPage isFooterHidden={isMobile} hideHeader={isMobile}>
      <MetaDecorator title={MetaTagData.MyAccount.title} description={MetaTagData.MyAccount.description} />
      <Content>
        <ErrorToaster />
        {loading && <Spinner color={getAppColor("primary")}/>}
        {ticketTransaction &&
          <TicketModalView ticketTransaction={ticketTransaction} />
        }
      </Content >
    </CommonPage >
  );
};

const Content = styled.div`
  ${maxContentWidthStyles}
  padding-top: ${maxContentWidthPadding}px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background: ${getAppColor("dark")};
  padding-bottom: ${maxContentWidthPadding}px;
`;
