import { useEffect } from "react";
import { Category } from "../../model/Category";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, getSubcategories } from "../actions/categoriesService";

/**
 * Returns a list of root categories
 */
export function useCategories(): Category[] {
	const dispatch = useDispatch();
	const { categories } = useSelector(state => state.persistent.categories);

	useEffect(() => {
		dispatch(getCategories());
	}, []);

	return categories;
}

/**
 * Returns sub-categories of a specific parent category
 */
export function useSubcategories(parent: string | null): Category[] {
	const dispatch = useDispatch();
	const { subcategories } = useSelector(state => state.persistent.categories);

	useEffect(() => {
		if (parent) dispatch(getSubcategories(parent));
	}, [parent]);

	if (!parent || !(parent in subcategories)) {
		return [];
	} else {
		return subcategories[parent];
	}
}
