import React from "react";
import styled from "styled-components/macro";
import shield_guarantee_red from "../../../../resource/img/sitix/funnel/shield_guarantee_red.svg";
import { ModalPopup } from "../../../ModalPopup/ModalPopup";
import { ModalCloseButton } from "../../../Modal/ModalCloseButton";

interface EventProtectionPopupProps {
	onDidClose: () => void;
}

export const EventProtectionPopup = ({ onDidClose }: EventProtectionPopupProps) => {
	return (
		<ModalPopup onDidClose={onDidClose} backgroundColor="#f7f7f7" rightHeaderElements={(onClose: any) => <ModalCloseButton onClick={onClose} />}>
			<ProtectionContent>
				<div>
					<img src={shield_guarantee_red} alt="" height={50} />
					<ProtectionTitle>
						SI Tickets Guarantees that Event <br /> Tickets will be:
					</ProtectionTitle>
					<ProtectionItemList>
						<ProtectionItemDiv>
							<strong>• Valid for Entry </strong> &nbsp;to your event{" "}
						</ProtectionItemDiv>
						<ProtectionItemDiv>
							<strong>• Delivered in Time </strong> &nbsp;for your event{" "}
						</ProtectionItemDiv>
						<ProtectionItemDiv>
							<strong>• Comparable or Better </strong> &nbsp;than ordered{" "}
						</ProtectionItemDiv>
						<ProtectionItemDiv>
							<strong>• Consecutive </strong> &nbsp;unless otherwise noted{" "}
						</ProtectionItemDiv>
					</ProtectionItemList>
				</div>
				{/*
				<ButtonLink newTab to={ExternalRoutes.HelpCenter}>Learn More</ButtonLink>
        */}
			</ProtectionContent>
		</ModalPopup>
	);
};

const internalPadding = 20;

const protectionItemMargin = 10;

const bottomAreaPadding = internalPadding + protectionItemMargin + "px";

const ProtectionContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	img {
		margin-bottom: 3px;
	}
	padding: 0 ${internalPadding}px ${2 * internalPadding}px ${internalPadding}px;
	& > div {
		align-self: stretch;
		display: flex;
		flex-direction: column;
		//border-bottom: 1px solid #ddd;
		justify-content: center;
		align-items: center;
		//padding-bottom: ${bottomAreaPadding};
		padding-top: ${internalPadding}px;
		//margin-bottom: ${bottomAreaPadding};
	}
`;
//padding: 0 25px;
const ProtectionTitle = styled.div`
	color: #000;
	font-size: 20px;
	line-height: 25px;
	font-weight: bold;
	margin-bottom: 30px;
	white-space: nowrap;
`;

const ProtectionItemList = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const ProtectionItemDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	&:not(:last-of-type) {
		margin-bottom: ${protectionItemMargin}px;
	}
	img {
		margin-right: 10px;
	}
`;
