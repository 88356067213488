import React from "react";
import styled from "styled-components/macro";
import { ExternalRoutes, Link } from "../../Link";
import appstore_button from "../../resource/img/sitix/mobile_app/appstore.svg";
import googleplaystore_button from "../../resource/img/sitix/mobile_app/googleplaystore.svg";
import appstore_button_big from "../../resource/img/sitix/mobile_app/appStoreBig.png";
import googleplaystore_button_big from "../../resource/img/sitix/mobile_app/googlePlayStoreBig.png";

const appStoreButton = {
	big: appstore_button_big,
	small: appstore_button,
};

const googlePlayStoreButton = {
	big: googleplaystore_button_big,
	small: googleplaystore_button,
};

export const MobileStoreButton = (props: { mobileStore: "appStore" | "googlePlay"; size?: "small" | "big" }) => {
	return (
		<GetAppButton style={{ width: props.size === "big" ? "151px" : "96px" }} to={props.mobileStore === "appStore" ? ExternalRoutes.AppAppleStore : ExternalRoutes.AppAndroidStore} target="_blank">
			<img src={props.mobileStore === "appStore" ? appStoreButton[props.size ? props.size : "small"] : googlePlayStoreButton[props.size ? props.size : "small"]} alt={props.mobileStore === "appStore" ? "App Store" : "Google Play Store"} />
		</GetAppButton>
	);
};

const GetAppButton = styled(Link)`
	display: block;
`;
