import React from "react";
import Carousel from "react-grid-carousel";
import { CommonPage } from "../components";
import { breakpoints } from "../util/breakpoints";
import { useMediaQuery } from "../hooks/useMediaQuery";
import styled from "styled-components/macro";
import { FontFamily } from "../components/Typography/Typography";
import { MainEventsCards } from "../components/SiTixProspects/MainEventsCards/MainEventsCards";
import EventCard from "../components/SiTixProspects/EventCard/EventCard";
import Item from "../components/SiTixProspects/Carousel/Item";
import { ButtonLink } from "../components/SiTixProspects/ButtonLink/ButtonLink";
import { Config } from "../../Config";

const headerLogo = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/siProspectsLP/header-logo-lockup.png`);
const heroBg = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/siProspectsLP/hero-background.png`);
const carousel1 = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/siProspectsLP/carousel-1.png`);
const carousel2 = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/siProspectsLP/carousel-2.png`);
const carousel3 = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/siProspectsLP/carousel-3.png`);
const carousel4 = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/siProspectsLP/carousel-4.png`);
const carousel5 = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/siProspectsLP/carousel-5.png`);
const propspectsCheer = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/siProspectsLP/prospects-cheer.png`);
const propspectsFootball = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/siProspectsLP/prospects-football.png`);

const hotSummer = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/siProspectsLP/hot-summer-classic-wide.png`);
const battleofthebridge = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/siProspectsLP/battle-of-the-bridge.png`);

const cheerChamp = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/siProspectsLP/cheer-championship.png`);
const cheerFameChamp = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/siProspectsLP/cheer-fame-championships.png`);
const footballCheerNationalChamp = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/siProspectsLP/football-cheer-national-championships.png`);
const gridironClassicLou = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/siProspectsLP/louisiana-gridiron-classic.png`);
const gridironClassicGe = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/siProspectsLP/georgia-gridiron-classic.png`);
const gridironClassicTx = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/siProspectsLP/texas-classic-new-image.png`);
const pylnorth = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/siProspectsLP/northeast-conference-championships.png`);
const pylsouth = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/siProspectsLP/southwest-conference-championships.png`);
const southeastchamp = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/siProspectsLP/southeast-conference-championships.png`);
const miatlanticchamp = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/siProspectsLP/mid-atlantic-conference-championships.png`);

const Detail = styled.div`
	position: absolute;
	bottom: 0;
	color: #fff;
	padding: 15px;
	width: 100%;
	box-sizing: border-box;
	background: linear-gradient(0deg, black, transparent);
	padding-top: 120px;
	border-radius: 8px;
`;

const Title = styled.div`
	font-size: 20px;
	font-weight: bold;
`;
const Comment = styled.div`
	font-size: 16px;
	font-weight: bold;
`;

const ItemInside = styled.div`
	position: relative;
	height: 205px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: 8px;
`;

const Index = styled.div`
	position: absolute;
	color: #ffffffc9;
	padding: 2px 8px;
	font-size: 12px;
	border-radius: 20px;
	top: 5px;
	right: 5px;
`;

const EventsCarouselContainer = styled.div`
	display: flex;
	justify-content: center;
`;

const ourLeagues = [
	{
		subtitle: "Football",
		title: "Prospects",
		link: "sport/prospects-football",
		image: propspectsFootball,
	},
	{
		subtitle: "Cheer",
		title: "Prospects",
		link: "category/prospects-cheer",
		image: propspectsCheer,
	},
];

const carouselEvents = [
	{
		dateLocation: "TBD",
		title: "Hot Summer Classic",
		description: "The Hot Summer Classic powered by Prospects Youth League (PYL) in partnership with Prospects by SI Football, The Pinnacle National Championships & Rival Sports Gear is the best of ALL 3 worlds with tournament brackets for Flag, 7v7, & Tackle Football!",
		link: "#",
		imageCarousel: carousel1,
		linkText: "TBD",
	},
	{
		dateLocation: "July 28-30 - Arlington, TX",
		title: "Texas Gridiron Classic",
		description: "The Texas Gridiron Classic powered by Prospects Youth League in partnership with Prospects by Sports Illustrated is a national ranking tournament. “The Lone Star Showdown” will include tournaments for Flag and Tackle Football, as part of the Pinnacle Preseason National Champ Series located at the Harold Patterson Sports Center.",
		link: "venue/harold-patterson-park",
		imageCarousel: carousel3,
		linkText: "Get Tickets",
	},
	{
		dateLocation: "August 4-6 - New Orleans, LA",
		title: "Louisiana Gridiron Classic",
		description: "The Louisiana Gridiron Classic is a national ranking tournament for Youth Tackle Football. “The Battle ‘N Da Boot” will consist of teams 6U to 12U. The tournament will be held at Joe Brown Stadium in New Orleans. The championship game winner will earn a bid in the Pinnacle National Championships by Sports Illustrated.",
		link: "#",
		imageCarousel: carousel4,
		linkText: "Coming soon",
	},
	{
		dateLocation: "August 11-13 - Savannah, GA",
		title: "Georgia Gridiron Classic",
		description: "The Georgia Gridiron Classic is a tackle football tournament as part of The Prospects By Sports Illustrated Classic Series. “The Deep South Clash” is a national qualifier for the PSI Pinnacle Nationals Super Regional, including teams 6U - 12U. Games will be held at the Jennifer Ross Soccer Complex in Savannah, Georgia.",
		link: "#",
		imageCarousel: carousel5,
		linkText: "Coming soon",
	},
];

const upcomingEvents = [
	{
		dateLocation: "TBD",
		title: "Hot Summer Classic",
		description: "The Hot Summer Classic powered by Prospects Youth League (PYL) in partnership with Prospects by SI Football, The Pinnacle National Championships & Rival Sports Gear is the best of ALL 3 worlds with tournament brackets for Flag, 7v7, & Tackle Football!",
		link: "#",
		image: hotSummer,
		button: true,
	},
	{
		dateLocation: "TBD",
		title: "Battle of the Bridge Youth Football Showcase",
		description: "The Battle of the Bridge Youth Football Showcase in Jacksonville, Florida will be one for the ages!! The debate for who reigns supreme in youth football in Duval Country will finally be settled at the pristine Terry Parker High School. Whether a team is from the North, South, East or West side of the River City, youth football fans will finally witness some of the top talent battle it out on the gridiron!!",
		link: "event/battle-of-the-bridge",
		image: battleofthebridge,
		button: true,
	},
	{
		dateLocation: "July 28-30 - Arlington, TX",
		title: "Texas Gridiron Classic",
		description: "The Texas Gridiron Classic powered by Prospects Youth League in partnership with Prospects by Sports Illustrated is a national ranking tournament. “The Lone Star Showdown” will include tournaments for Flag and Tackle Football, as part of the Pinnacle Preseason National Champ Series located at the Harold Patterson Sports Center. Fans who purchase advance tickets will be rewarded with a free, randomly selected NFT trading card featuring an elite group of the nation's top youth football players courtesy of Panini America, the world's largest sports and entertainment collectibles company. The limited edition PSI Elite Five Rated Prospect NFT collectibles set will feature the name, image and likeness of five of the country's top rising stars.",
		link: "venue/harold-patterson-park",
		image: gridironClassicTx,
		button: true,
		linkText: "Get Tickets",
	},
	{
		dateLocation: "August 4-6 - New Orleans, LA",
		title: "Louisiana Gridiron Classic",
		description: "The Louisiana Gridiron Classic is a national ranking tournament for Youth Tackle Football. “The Battle ‘N Da Boot” will consist of teams 6U to 12U. The tournament will be held at Joe Brown Stadium in New Orleans. The championship game winner will earn a bid in the Pinnacle National Championships by Sports Illustrated.",
		link: "#",
		image: gridironClassicLou,
		button: true,
	},
	{
		dateLocation: "August 11-13 - Savannah, GA",
		title: "Georgia Gridiron Classic",
		description: "The Georgia Gridiron Classic is a tackle football tournament as part of The Prospects By Sports Illustrated Classic Series. “The Deep South Clash” is a national qualifier for the PSI Pinnacle Nationals Super Regional, including teams 6U - 12U. Games will be held at the Jennifer Ross Soccer Complex in Savannah, Georgia.",
		link: "#",
		image: gridironClassicGe,
		button: true,
	},
	{
		dateLocation: "October 7 - DFW, TX",
		title: "The Lone Star Cheer Championships",
		description: "The Lone Star Cheer Championship is one of the 2023 Pinnacle Cheer National Competitions. Winners will receive a bid for The Pinnacle Cheer Nationals in Arlington, TX in December.",
		link: "#",
		image: cheerChamp,
		button: true,
	},
	{
		dateLocation: "October 14 - Atlanta, GA",
		title: "The Cheer Fame Championships",
		description: "The Cheer Fame Championship is one of the 2023 Pinnacle Cheer National Competitions. Winners will receive a bid for The Pinnacle Cheer Nationals in Arlington, TX in December.",
		link: "#",
		image: cheerFameChamp,
		button: true,
	},
	{
		dateLocation: "Date & Location TBA",
		title: "PYL Northeast Conference Championships",
		description: "Details Coming Soon!",
		link: "#",
		image: pylnorth,
		button: true,
	},
	{
		dateLocation: "Date & Location TBA",
		title: "PYL Southwest Conference Championships",
		description: "Details Coming Soon!",
		link: "#",
		image: pylsouth,
		button: true,
	},
	{
		dateLocation: "Date & Location TBA",
		title: "PYL Southeast Conference Championships",
		description: "Details Coming Soon!",
		link: "#",
		image: southeastchamp,
		button: true,
	},
	{
		dateLocation: "Date & Location TBA",
		title: "PYL Mid-Atlantic Conference Championships",
		description: "Details Coming Soon!",
		link: "#",
		image: miatlanticchamp,
		button: true,
	},
	{
		dateLocation: "December 14-17 - Arlington, TX",
		title: "The Pinnacle Football & Cheer National Championships",
		description: "Teams that win their Conference Championship will be sent to The PSI Pinnacle National Championships in Arlington, Texas, which is the ONLY National Championship with exposure to over 30 millions fans and over 2000 Youth Football teams. There will be both flag and tackle championships as well as a cheerleading championship. The biggest youth championship events will be held in Arlington at the Harold Patterson Sports Complex.",
		link: "#",
		image: footballCheerNationalChamp,
		button: true,
	},
];

export const SITixProspectsRoute = () => {
	const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);

	return (
		<CommonPage>
			<div
				css={`
					background: url(${heroBg});
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					min-height: 65vh;
					max-height: 700px;
					display: flex;
					justify-content: center;
					align-items: center;

					@media (max-width: ${breakpoints.tablet}) {
						min-height: 30vh;
						max-height: 300px;
					}
					@media (max-width: ${breakpoints.mobile}) {
						min-height: 30vh;
						max-height: 300px;
					}
				`}
			>
				<img
					src={headerLogo}
					alt="logo"
					css={`
						max-width: 60%;

						@media (max-width: ${breakpoints.tablet}) {
							max-width: 90%;
						}
						@media (max-width: ${breakpoints.mobile}) {
							max-width: 90%;
						}
					`}
				/>
			</div>

			<SectionEventsContainer>
				<MultiCardContainer>
					<h1
						css={`
							text-transform: uppercase;
							font-size: 70px;
							font-family: ${FontFamily.Solano};
							font-weight: 700;
							margin-bottom: 1em;
							text-align: center;
							color: rgb(0, 0, 0);
							letter-spacing: 5px;

							@media (max-width: ${breakpoints.tablet}) {
								font-size: 50px;
							}
							@media (max-width: ${breakpoints.mobile}) {
								margin-top: 0.5em;
								margin-bottom: 0.5em;
								font-size: 50px;
							}
						`}
					>
						Our Leagues
					</h1>
					<div
						css={`
							align-items: center;
							justify-content: center;
							display: flex;

							@media (max-width: ${breakpoints.tablet}) {
								flex-direction: column;
							}
							@media (max-width: ${breakpoints.mobile}) {
								flex-direction: column;
							}
						`}
					>
						{ourLeagues.map(item => {
							return <MainEventsCards image={item.image} key={`ourLeagues_${item.title}_${item.subtitle}`} link={item.link} title={item.title} subtitle={item.subtitle} />;
						})}
					</div>
				</MultiCardContainer>
				<MultiCardContainer>
					<h1
						css={`
							text-transform: uppercase;
							font-size: 70px;
							font-family: ${FontFamily.Solano};
							font-weight: 700;
							margin-bottom: 1em;
							text-align: center;
							color: rgb(0, 0, 0);
							letter-spacing: 5px;

							@media (max-width: ${breakpoints.tablet}) {
								font-size: 40px;
							}
							@media (max-width: ${breakpoints.mobile}) {
								margin-top: 0.5em;
								margin-bottom: 0.5em;
								font-size: 40px;
								letter-spacing: 0px;
							}
						`}
					>
						Upcoming Events
					</h1>

					{isMobile ? (
						<Carousel hideArrow={true}>
							{carouselEvents.map(
								event =>
									event.dateLocation !== "TBD" &&
									event.title !== "Hot Summer Classic" && (
										<Carousel.Item key={`mobile_carousel_${event.title}`}>
											<ItemInside
												css={`
													background-image: url(${event.imageCarousel});
												`}
											>
												<Index>
													<ButtonLink
														css={`
															align-items: center;
															justify-content: center;
															display: flex;
														`}
														href={event.link}
													>
														{event.linkText}
													</ButtonLink>
												</Index>
												<Detail>
													<Title>{event.title}</Title>
													<Comment>{event.dateLocation}</Comment>
												</Detail>
											</ItemInside>
										</Carousel.Item>
									)
							)}
						</Carousel>
					) : (
						<EventsCarouselContainer>
							<Carousel cols={3} rows={1} gap={60} loop hideArrow={true}>
								{carouselEvents.map(
									event =>
										event.dateLocation !== "TBD" &&
										event.title !== "Hot Summer Classic" && (
											<Carousel.Item key={`desktop_carousel_${event.title}`}>
												<Item image={event.imageCarousel} dateLocation={event.dateLocation} title={event.title} link={event.link} linkText={event.linkText} />
											</Carousel.Item>
										)
								)}
							</Carousel>
						</EventsCarouselContainer>
					)}
				</MultiCardContainer>

				<MultiCardContainer>
					<div
						css={`
							align-items: center;
							justify-content: center;
							display: flex;
							flex-direction: column;
						`}
					>
						{upcomingEvents.map((item, index) => item.dateLocation !== "TBD" && item.title !== "Hot Summer Classic" && <EventCard linkText={item.linkText} button={item.button} image={item.image} key={`upcoming_${item.title}`} link={item.link} title={item.title} dateLocation={item.dateLocation} text={item.description} index={index} />)}
					</div>
				</MultiCardContainer>
			</SectionEventsContainer>
		</CommonPage>
	);
};

const MultiCardContainer = styled.div`
	@media (max-width: ${breakpoints.large}) {
		display: grid;
	}
`;

export const SectionEventsContainer = styled.div`
	margin-top: 80px;
	align-items: center;
	align-content: center;
	justify-content: center;

	@media (max-width: ${breakpoints.large}) {
		margin-top: 0;
	}

	@media (max-width: ${breakpoints.large}) {
		& > * {
			border-bottom: none;
		}
	}
`;
