import { client, paypalCheckout } from "braintree-web";
import { TokenizePayload } from "../../../model/Checkout";
import { getAndDispatchBraintreeClientToken, postBraintreePaymentMethodAndUpdate } from "../../../services/braintree";
import { useBraintree } from "../../../services/hooks/useBraintree";
import React, { useEffect, useState } from "react";
import { PaymentMethodWithToken } from "../../../model/optimizedModel/braintree";
import styled from "styled-components/macro";
import { Spinner } from "../Loader/Spinner";
import { useAutoUpdateState } from "../../hooks/useAutoUpdateState";
import { InlineStylesProps } from "../../../model/optimizedModel/styles";
import { isMobileApp } from "../../util/isMobileApp";
import { FullScreenLoader } from "../Loader";
import { useUser } from "../../../services/hooks/useUser";

export const PayPalButton = (
	props: {
		onComponentReady?: () => void;
	} & (
		| {
				flow: "vault";
				paymentMethodAdded?: (payload: PaymentMethodWithToken) => void;
		  }
		| {
				flow: "checkoutWithVault";
				amount: number;
				paymentTokenized?: (payload: TokenizePayload) => void;
		  }
	) &
		InlineStylesProps
) => {
	const { braintreeState } = useBraintree();

	const [addingPaymentMethod, setAddingPaymentMethod] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(true);
	const [validBraintreetoken, setValidBrainTreetoken] = useState(true);

	const showButton = useAutoUpdateState(() => {
		const value = !braintreeState.hasPayPal || props.flow !== "vault";
		return value;
	}, [braintreeState.hasPayPal, props.flow]);

	const componentReady = () => {
		setButtonLoading(false);
		if (props.onComponentReady) {
			props.onComponentReady();
		}
	};

	const user = useUser();

	const authorizePayment = () => {
		const authorization = braintreeState.clientAuth;
		if (authorization) {
			client
				.create({
					authorization,
				})
				.then(clientInstance => {
					paypalCheckout
						.create({
							authorization,
							//Note: The below conditional is used to disable the Edit F/I button in the mobile app or if the user is in guest checkout.
							//@ts-ignore
							autoSetDataUserIdToken: !isMobileApp && !!user,
						})
						.then(paypalCheckoutInstance => {
							const intent = props.flow === "checkoutWithVault" ? "authorize" : "tokenize";
							const currency = "USD";
							return (
								paypalCheckoutInstance
									//@ts-ignore
									.loadPayPalSDK({
										//TODO: Get prod client-id
										//"client-id": "AWpGjdoMdgH4rLA-KW-5VuiNzlUX4W5WcNXjhQKfEm6kh2pH74MIqLt1-F2jGbILq4Un8qEfuLdUGpQ7",
										intent,
										vault: true,
										components: "buttons,messages",
										...(props.flow === "checkoutWithVault" && {
											currency,
											dataAttributes: {
												amount: props.amount && props.amount.toFixed(2),
											},
										}),
									})
									.then(() => {
										const paypal = window.paypal;
										return (
											paypal
												//@ts-ignore
												.Buttons({
													//@ts-ignore
													fundingSource: paypal.FUNDING.PAYPAL,
													[props.flow === "vault" ? "createBillingAgreement" : "createOrder"]: () => {
														return paypalCheckoutInstance.createPayment({
															//@ts-ignore
															flow: props.flow === "checkoutWithVault" ? "checkout" : "vault",
															//@ts-ignore
															intent,
															...(props.flow === "checkoutWithVault" && {
																amount: props.amount,
																currency,
																requestBillingAgreement: true,
															}),
														});
													},
													//@ts-ignore
													onApprove: data => {
														setAddingPaymentMethod(true);
														paypalCheckoutInstance
															.tokenizePayment(data)
															.then(payload => {
																const tokenizePayload = payload as TokenizePayload;
																if (props.flow === "vault") {
																	postBraintreePaymentMethodAndUpdate(tokenizePayload).then(result => {
																		setAddingPaymentMethod(false);
																		if (result && props.paymentMethodAdded) {
																			props.paymentMethodAdded(result);
																		}
																	});
																} else {
																	setAddingPaymentMethod(false);
																	if (payload && props.paymentTokenized) {
																		props.paymentTokenized(tokenizePayload);
																	}
																}
															})
															.catch(error => {
																console.error(error);
															});
													},
													onInit: componentReady,
													onError: (error: any) => {
														console.error(error);
													},
												})
												.render("#paypal-button")
										);
									})
							);
						})
						.catch(error => {
							console.error(error);
						});
				})
				.catch(error => {
					if (error?.code === "CLIENT_AUTHORIZATION_INVALID") {
						setValidBrainTreetoken(false);
					}
				});
		}
	};

	useEffect(() => {
		const retrieveToken = async () => {
			await getAndDispatchBraintreeClientToken();
			authorizePayment();
			setValidBrainTreetoken(true);
		};

		if (!validBraintreetoken) {
			retrieveToken();
		}
	}, [validBraintreetoken]);

	useEffect(() => {
		if (showButton) {
			const authorization = braintreeState.clientAuth;
			if (authorization) {
				authorizePayment();
			}
		} else {
			componentReady();
		}
	}, [showButton]);

	return (
		<>
			{showButton && (
				<>
					<FullScreenLoader show={addingPaymentMethod} />
					{buttonLoading && <Spinner />}
					<PayPalButtonDiv
						id="paypal-button"
						style={{
							...(buttonLoading && {
								display: "none",
							}),
							...props.style,
						}}
					/>
				</>
			)}
		</>
	);
};

const PayPalButtonDiv = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
`;
