import React, { useState } from "react";
import { maxContentWidthStyles } from "../util/maxContentWidth";
import { MetaDecorator, MetaTagData } from "../components/SEOMetadecorator/MetaDecorator";
import styled from "styled-components/macro";
import sitixChampionship from "../resource/image/sitix-championship.svg";
import sitixChampionshipMobile from "../resource/image/sitix-championship-mobile.svg";
import venmoDeluxeHotel from "../resource/image/venmo-deluxe-hotel.svg";
import venmoVipSuite from "../resource/image/venmo-vip-suite.svg";
import { CommonPage } from "../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faCheck, faFootballBall, faHotel, faGlassCheers, faTicketAlt, faStar } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { Form, Field } from "react-final-form";
import { required, validEmail } from "../util/optimized/finalFormUtil";
import toast, { Toaster } from "react-hot-toast";
import { useToastRemoval } from "../hooks/useToastRemoval";
import { InternalRoutes } from "../Link";
import { ContentRow, ContentBox, ContentBoxFeedback, ContentText, ItemList, ItemListWithIcon, ItemText, ItemTextStrong, Header, SmallInputWrapper, TermsLink, Warning, ButtonContainer, StyledRFFInput, FormContainer, SmallInputsContainer, ResponsiveLandingPageButton } from "./GolfClassicRoute";
import { postSubscribeSITixChampionship } from "../../services/sitixChampionship";
import { HeaderHashAnchor } from "../components/HeaderHashAnchor/HeaderHashAnchor";
import banner_content from "../resource/assets/si-tix-national-championship/si_tix_national_championship_banner_content.png";
import { FontFamily } from "../components/Typography/Typography";
import { breakpoints } from "../util/breakpoints";
import { Redirect } from "react-router-dom";

const mobileScreenWidth = "750px";

const venmoColor = "#008CFF";

const legalHref = `https://sitickets.com${InternalRoutes.SITixChampionshipLegal}`;

const useIsMobile = () => useMediaQuery(`(max-width: ${mobileScreenWidth})`);

const FontAwesomePageIcon = (props: Pick<React.ComponentProps<typeof FontAwesomeIcon>, "icon">) => {
	const isMobile = useIsMobile();

	return <FontAwesomeIcon {...props} color={venmoColor} size={isMobile ? "2x" : "lg"} />;
};

const CenteredFontAwesomePageIcon = (props: React.ComponentProps<typeof FontAwesomePageIcon>) => {
	return (
		<CenteredFontAwesomePageIconWrapper>
			<FontAwesomePageIcon {...props} />
		</CenteredFontAwesomePageIconWrapper>
	);
};

export const SITixChampionshipRoute = () => {
	const isMobile = useIsMobile();
	const [showFormFeedback, setShowFormFeedback] = useState(false);

	useToastRemoval();

	return <Redirect to={InternalRoutes.Home} />;

	return (
		<CommonPage background="#fff">
			<MetaDecorator title={MetaTagData.SITixChampionship.title} description={MetaTagData.SITixChampionship.description} />

			<HeaderContainer>
				<Header>
					<HeaderContent>
						<img src={banner_content} alt="banner content" />
						<HeaderDisclosure>No purch nec. Ends 12/10/21. US only, except residents of RI. 18+. See official rules. Venmo is only providing prizing for this promotion, but is not an official sponsor</HeaderDisclosure>
						<HeaderButton forwardedAs="a" href="#form">
							Enter Contest
						</HeaderButton>
					</HeaderContent>
				</Header>
			</HeaderContainer>

			<MainContent>
				<HeaderHashAnchor id="form" />

				{showFormFeedback ? (
					<StyledContentRow>
						<StyledContentBoxFeedback width="100%" background="#fafafa" padding={isMobile ? "20px 0px" : "50px"} align="center">
							<FontAwesomeIcon icon={faCheck} color={venmoColor} size="2x" />
							<ContentTitleFeedback>Thank you for entering the contest!</ContentTitleFeedback>
							<ContentText size="small" margin="0 0 16px" padding={isMobile ? "0px" : "0px 15%"}>
								Don’t forget to check your email for confirmation of your entry into the contest!
							</ContentText>
							<FormSuccessBottomContent>
								<StyledTermsLink href={legalHref} target="_blank">
									Terms and conditions
								</StyledTermsLink>
								<p>for the contest.</p>
							</FormSuccessBottomContent>
						</StyledContentBoxFeedback>
					</StyledContentRow>
				) : (
					<StyledContentRow>
						<ContentBox align="center" background="#fafafa" padding={isMobile ? "20px" : "40px 20px"}>
							<FormContentTitle margin="0 0 16px">Free to Enter!</FormContentTitle>
							<FormContent size="small">Simply enter your email for a chance to win VIP Suite tickets for the 2022 CFP National Championship</FormContent>
							<br />
							<ContentText size="small">*Venmo is only providing prizing for this promotion, but is not an official sponsor.</ContentText>
						</ContentBox>
						<ContentBox
							background="#fafafa"
							padding="40px 20px"
							style={
								isMobile
									? {
											paddingTop: 0,
									  }
									: undefined
							}
						>
							<Toaster position="bottom-center" />
							<Form
								onSubmit={async (values: { firstName: string; lastName: string; email: string }) => {
									await postSubscribeSITixChampionship(values.email, values.firstName, values.lastName)
										.then(() => {
											setShowFormFeedback(true);
										})
										.catch(() => {
											toast.error("There was an error processing your request.");
										});
								}}
								initialValues={{ firstName: "", lastName: "", email: "" }}
								render={form => (
									<form onSubmit={form.handleSubmit}>
										<FormContainer>
											<SmallInputsContainer>
												<SmallInputWrapper>
													<Field name="firstName" component={StyledRFFInput} type="text" placeholder="First Name" validate={value => required(value)} />
												</SmallInputWrapper>
												<SmallInputWrapper>
													<Field name="lastName" component={StyledRFFInput} type="text" placeholder="Last Name" validate={value => required(value)} />
												</SmallInputWrapper>
											</SmallInputsContainer>
											<Field name="email" component={StyledRFFInput} type="email" placeholder="Your Email" validate={value => required(value) || validEmail(value)} />
											<Warning>
												By clicking Enter contest, you are agreeing to the
												<StyledTermsLink href={legalHref} target="_blank">
													Terms and conditions
												</StyledTermsLink>
												for the contest.
											</Warning>
											<ButtonContainer>
												<StyledResponsiveLandingPageButton type="submit" disabled={form.invalid} loading={form.submitting}>
													Enter contest
												</StyledResponsiveLandingPageButton>
											</ButtonContainer>
										</FormContainer>
									</form>
								)}
							/>
						</ContentBox>
					</StyledContentRow>
				)}

				<StyledContentRow>
					<ContentBox width="100%" align="center" background="#fafafa" padding="20px 0">
						<RightContentTitle margin="0 0 15px">8 Winners will receive</RightContentTitle>
						<Separator />
						<ItemList padding="0px 20px">
							<ItemListWithIcon>
								<FontAwesomePageIcon icon={faFootballBall} />
								<StyledItemText>
									<h2>2 VIP Suite Tickets</h2>
									<h3>2022 National championship game January 10, 2022</h3>
								</StyledItemText>
							</ItemListWithIcon>
							<ItemListWithIcon>
								<FontAwesomePageIcon icon={faHotel} />
								<StyledItemText>
									<h2>2 Night Deluxe Hotel Accommodations</h2>
								</StyledItemText>
							</ItemListWithIcon>
							<ItemListWithIcon>
								<FontAwesomePageIcon icon={faGlassCheers} />
								<StyledItemText>
									<h4>Complimentary Food, Beverages, prize packs and more at the game!</h4>
								</StyledItemText>
							</ItemListWithIcon>
						</ItemList>
					</ContentBox>
				</StyledContentRow>
				<StyledContentRow border="1px solid #000000">
					<ContentBox
						padding="20px"
						backgroundImage={venmoVipSuite}
						style={{
							backgroundPositionX: "center",
						}}
					/>
					<ContentBox padding={isMobile ? "20px" : "40px"}>
						<LeftContentTitle size="small" margin="0 0 12px">
							The VIP Suite
						</LeftContentTitle>
						<StyledContentText size="small" margin="0 0 16px 0">
							Suites at Lucas Oil Stadium are equipped with top amenities and offer the best view to enjoy the game:
						</StyledContentText>
						<ItemList>
							<ItemListWithIcon direction="row">
								<CenteredFontAwesomePageIcon icon={faTicketAlt} />
								<ItemTextStrong>Luxury suite with private access</ItemTextStrong>
							</ItemListWithIcon>
							<ItemListWithIcon direction="row">
								<CenteredFontAwesomePageIcon icon={faGlassCheers} />
								<ItemTextStrong>Snacks, Starters, Entrees, and Sweets included along with generous selection of beer, wine and spirits</ItemTextStrong>
							</ItemListWithIcon>
							<ItemListWithIcon direction="row">
								<CenteredFontAwesomePageIcon icon={faMapMarkerAlt} />
								<ItemTextStrong>Lucas Oil Stadium, Indianapolis, IN</ItemTextStrong>
							</ItemListWithIcon>
						</ItemList>
					</ContentBox>
				</StyledContentRow>

				<StyledContentRow border="1px solid #000000" reverse>
					<ContentBox
						padding="20px"
						backgroundImage={venmoDeluxeHotel}
						style={{
							backgroundPositionX: "center",
						}}
					/>
					<ContentBox padding={isMobile ? "20px" : "40px"}>
						<LeftContentTitle size="small" margin="0 0 12px">
							Deluxe hotel accommodations
						</LeftContentTitle>
						<StyledContentText size="small" margin="0 0 16px 0">
							Located minutes from Lucas Oil Stadium in downtown Indianapolis, this venue is a top choice for fans to relax at before and after the game:
						</StyledContentText>
						<ItemList>
							<ItemListWithIcon direction="row">
								<CenteredFontAwesomePageIcon icon={faHotel} />
								<ItemTextStrong>2 Nights included for You + 1 Guest</ItemTextStrong>
							</ItemListWithIcon>
							<ItemListWithIcon direction="row">
								<CenteredFontAwesomePageIcon icon={faStar} />
								<ItemTextStrong>Luxury amenities</ItemTextStrong>
							</ItemListWithIcon>
							<ItemListWithIcon direction="row">
								<CenteredFontAwesomePageIcon icon={faMapMarkerAlt} />
								<ItemTextStrong>Sheraton Indianapolis City Centre Hotel</ItemTextStrong>
							</ItemListWithIcon>
						</ItemList>
					</ContentBox>
				</StyledContentRow>
				<StyledContentText size="small" margin="20px 0 0 0">
					*Suite and hotel accomodations provided by Venmo
				</StyledContentText>
				<StyledContentText size="small" margin="60px 0 0 0">
					NO PURCHASE NECESSARY TO ENTER OR WIN. SI Tickets National Championship is open to residents of the U.S, except residents of RI and must be 18 years of age or older. Begins 12:00:01 a.m. ET 10/7/21, ends 11:59:59 p.m. ET 12/10/21. Visit
					<BottomLink href={legalHref} target="_blank">
						sitickets.com/sitix-championship/legal
					</BottomLink>
					for free entry details and Official Rules, which govern. Sponsor: SI Tickets, New York, NY
				</StyledContentText>
			</MainContent>
		</CommonPage>
	);
};

const HeaderContent = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 27px 0;
	box-sizing: border-box;
	& > img {
		min-height: 0;
		max-width: 100%;
	}
	align-items: center;
	max-height: 500px;
	@media (min-width: ${mobileScreenWidth}) {
		max-height: initial;
		width: 50%;
	}
`;

const StyledContentBoxFeedback = styled(ContentBoxFeedback)`
	padding: 20px;
`;

const BottomLink = styled.a`
	font-weight: 700;
	margin: 0 4px;
	&:hover {
		text-decoration: underline;
	}
`;

const HeaderButton = styled.a`
	max-width: 300px;
	background: linear-gradient(90deg, rgba(251, 185, 103, 1) 0%, rgba(220, 169, 113, 1) 22%, rgba(251, 211, 143, 1) 40%, rgba(255, 189, 105, 1) 53%, rgba(254, 233, 170, 1) 71%, rgba(255, 190, 106, 1) 95%);
	color: #001d35;
	width: 100%;
	font-family: ${FontFamily.Solano};
	text-transform: uppercase;
	font-size: 26px;
	border-radius: 0;
	padding: 12px 0;
`;

const StyledResponsiveLandingPageButton = styled(ResponsiveLandingPageButton)`
	background: ${venmoColor};
`;

const FormSuccessBottomContent = styled.div`
	display: flex;
	& > p {
		font-size: 14px;
	}
`;

const MainContent = styled.div`
	${maxContentWidthStyles};
	margin-bottom: 20px;
`;

const LeftContentTitle = styled.h2<{ size?: string; margin?: string }>`
	font-family: Solano-gothic-pro-mvb, sans-serif;
	font-size: 50px;
	font-weight: 700;
	line-height: 55px;
	color: #000;
	text-transform: uppercase;
	margin: ${props => props.margin || "0 0 24px"};
	@media (max-width: ${mobileScreenWidth}) {
		font-size: ${props => (props.size === "small" ? "24px" : "30px")};
		line-height: ${props => (props.size === "small" ? "26px" : "33px")};
	}
`;

const RightContentTitle = styled.h2<{ size?: string; margin?: string }>`
	font-family: Solano-gothic-pro-mvb, sans-serif;
	font-size: 42px;
	font-weight: 700;
	color: #000;
	text-transform: uppercase;
	margin: ${props => props.margin || "0 0 24px"};
	@media (max-width: ${mobileScreenWidth}) {
		font-size: ${props => (props.size === "small" ? "24px" : "30px")};
		line-height: ${props => (props.size === "small" ? "26px" : "33px")};
	}
`;

const ContentTitleFeedback = styled(LeftContentTitle)`
	max-width: 420px;
	margin: 16px 0px;
	font-size: 40px;
	font-weight: 700;
	line-height: 44px;
	text-align: center;
`;

const StyledContentText = styled(ContentText)`
	text-align: left;
`;

const Separator = styled.hr`
	@media (max-width: ${mobileScreenWidth}) {
		border-bottom: 4px solid #000000;
		margin: auto;
		width: 80px;
		height: 0px;
		background: none;
		margin-bottom: 24px;
	}
`;

const FormContentTitle = styled(LeftContentTitle)`
	@media (min-width: ${mobileScreenWidth}) {
		font-size: 52px;
	}
`;

const FormContent = styled(ContentText)`
	@media (min-width: ${mobileScreenWidth}) {
		font-size: 18px;
	}
`;

const StyledItemText = styled(ItemText)`
	& > h2 {
		font-weight: 700;
	}

	& > h3 {
		font-weight: 500;
		text-transform: uppercase;
		font-size: 18px;
		text-align: center;

		@media (max-width: ${mobileScreenWidth}) {
			font-size: 16px;
			line-height: 32px;
			margin-top: 10px;
		}
	}

	& > h4 {
		font-weight: 500;
		font-size: 20px;
		text-align: center;

		@media (max-width: ${mobileScreenWidth}) {
			font-size: 16px;
			line-height: 34px;
			margin-top: 10px;
		}
	}
`;

const HeaderContainer = styled.div`
	background-image: url(${sitixChampionship});
	background-position: bottom left;
	background-size: cover;
	background-repeat: no-repeat;
	background-color: #000;
	z-index: 1;
	position: relative;

	@media (max-width: ${mobileScreenWidth}) {
		background-image: url(${sitixChampionshipMobile});
		&::after {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			background-image: linear-gradient(186.3deg, #002340 59.98%, rgba(0, 35, 64, 0) 92.48%), linear-gradient(100.7deg, #002340 37.93%, rgba(0, 35, 64, 0) 72.51%);
			top: 0;
			left: 0;
			opacity: 0.8;
			z-index: -1;
		}
	}
`;

const CenteredFontAwesomePageIconWrapper = styled.div`
	display: flex;
	justify-content: center;
	min-width: 30px;
`;

const StyledTermsLink = styled(TermsLink)`
	margin: 0 5px;
`;

const StyledContentRow = styled(ContentRow)<{ reverse?: boolean }>`
	@media (min-width: ${mobileScreenWidth}) {
		flex-direction: ${props => props.reverse && "row-reverse"};
	}
`;

const HeaderDisclosure = styled.h3`
	margin-top: 19px;
	font-size: 12px;
	margin-bottom: 30px;
	line-height: 1.4;
	font-family: "Montserrat", sans-serif;
	color: #fff;
	font-weight: 500;
	@media (max-width: ${breakpoints.mobile}) {
		font-size: 10px;
	}
`;
