import { ServiceCallAction, ServiceCallActionParams } from "../../store/middleware/serviceCallMiddleware";
import { EventsResponse, EventsCodec } from "../types/EventsResponse";
import { updateDiscoverEvents, updateDiscoverFailure } from "../../store/actions/discoverActions";
import { Location } from "../../model/Location";

export const getFeaturedDiscoverEvents = (location: Location | null, page: number = 1, pageSize: number = 10): ServiceCallAction<EventsResponse> => ({
	method: "GET",
	endpoint: `events/discover`,
	param: {
		...(location && {
			latitude: location.latitude,
			longitude: location.longitude,
		}),
		page_number: page.toString(),
		page_size: pageSize.toString(),
	},
	serviceKey: "getFeaturedDiscoverEvents",
	responseDecoder: EventsCodec,
	type: "SERVICE_CALL",
	onSuccess: ({ data }) => updateDiscoverEvents(data),
	onFailure: updateDiscoverFailure,
});

export const getDiscoverEvents = (page: number | null, category: string | null, from: string | null, to: string | null, distance: string | null, location: Location | null, pageSize: number | null): ServiceCallAction<EventsResponse> => {
	let params: ServiceCallActionParams = {
		...(page && { page_number: page }),
		...(pageSize && { page_size: pageSize }),
		...(category && { category_slug: category }),
		...(from && { date_from: from }),
		...(to && { date_to: to }),
		...(distance && { distance }),
		...(location && {
			latitude: location.latitude,
			longitude: location.longitude,
		}),
	};
	return {
		method: "GET",
		param: params,
		endpoint: "events/discover",
		serviceKey: "getDiscoverEvents",
		responseDecoder: EventsCodec,
		type: "SERVICE_CALL",
	};
};

export const getSuggestedEvents = (): ServiceCallAction<EventsResponse> => {
	let params: ServiceCallActionParams = {
		page: 1,
		pageSize: 5,
	};
	return {
		method: "GET",
		param: params,
		endpoint: "events/discover",
		serviceKey: "getSuggestedEvents",
		responseDecoder: EventsCodec,
		type: "SERVICE_CALL",
	};
};
