import { Discount } from "../../model/Cart";
import { CreditCardInfo } from "../../model/Checkout";
import { PaymentMethodType, PaymentMethodWithToken } from "../../model/optimizedModel/braintree";
import { CheckoutActions } from "../reducers/checkoutReducer";

export const setCheckoutPaymentMethod = (paymentMethod: PaymentMethodWithToken): CheckoutActions => {
	let creditCardInfo: CreditCardInfo | undefined;
	if (paymentMethod.type === "CreditCard") {
		const paymentMethodDetails = paymentMethod.details;
		creditCardInfo = {
			lastFour: paymentMethodDetails.lastFour,
			cardType: paymentMethodDetails.cardType,
		};
	}
	return {
		type: "SET_CHECKOUT_PAYMENT_METHOD",
		paymentMethod,
		creditCardInfo,
	};
};

export const setCheckoutNewPaymentMethodType = (paymentMethodType: PaymentMethodType): CheckoutActions => {
	return {
		type: "SET_CHECKOUT_NEW_PAYMENT_METHOD_TYPE",
		paymentMethodType,
	};
};

export const setCheckoutShippingAddressIndex = (shippingAddressIndex: number): CheckoutActions => ({
	type: "SET_CHECKOUT_SHIPPING_ADDRESS_INDEX",
	shippingAddressIndex,
});

export const setCheckoutAgreedToTermsAndPrivacy = (agreedToTermsAndPrivacy: boolean): CheckoutActions => ({
	type: "SET_CHECKOUT_AGREED_TO_TERMS_AND_PRIVACY",
	agreedToTermsAndPrivacy,
});

export const setCheckoutActiveStep = (activeStep: number): CheckoutActions => ({
	type: "SET_CHECKOUT_ACTIVE_STEP",
	activeStep,
});

export const setCheckoutStepValidity = (step: number, valid: boolean): CheckoutActions => ({
	type: "SET_CHECKOUT_STEP_VALIDITY",
	valid,
	step,
});

export const resetCheckoutState: CheckoutActions = {
	type: "RESET_CHECKOUT_STATE",
};

export const setCheckoutTimer = (time?: string): CheckoutActions => ({
	type: "SET_CHECKOUT_TIMER",
	time,
});

export const setCheckoutWalletFundsUsed = (walletFundsUsed: number): CheckoutActions => ({
	type: "SET_WALLET_FUNDS_USED",
	walletFundsUsed,
});

export const resetCheckoutPaymentMethod = (): CheckoutActions => ({
	type: "RESET_PAYMENT_METHOD_TYPE",
});

export const setCheckoutPhoneNumber = (phoneNumber: string): CheckoutActions => ({
	type: "SET_CHECKOUT_PHONE_NUMBER",
	phoneNumber,
});

export const setCheckoutDiscount = (discount?: Discount): CheckoutActions => ({
	type: "SET_CHECKOUT_DISCOUNT",
	discount,
});

export const setCheckoutTotalTax = (totalTax: number | null): CheckoutActions => ({
	type: "SET_CHECKOUT_TOTAL_TAX",
	totalTax,
});

