import { Reducer } from "redux";
import { DisabledRoute } from "../../model/Status";

const defaultState: DisabledRoute[] = [];

export const siteStatusReducer: Reducer<DisabledRoute[], SiteStatusActions> = (state: DisabledRoute[] = defaultState, action: SiteStatusActions): DisabledRoute[] => {
	switch (action.type) {
		case "UPDATE_SITE_STATUS":
			return action.payload;
		default:
			return state;
	}
};

export type SiteStatusActions = {
	type: "UPDATE_SITE_STATUS";
	payload: DisabledRoute[];
};
