import * as t from "io-ts";

export const VenueCodec = t.interface({
	id: t.number,
	slug: t.string,
	name: t.string,
	city: t.string,
});

export type Venue = t.OutputOf<typeof VenueCodec>;
