import React from "react";
import { Field, FormRenderProps } from "react-final-form";
import styled, { CSSObject } from "styled-components/macro";
import { SkeletonProps } from "../../../model/optimizedModel/skeleton";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";
import { getAppColor } from "../../util/appColors";
import { countries } from "../../util/geographicLocations";
import { required, isNotAPOBox } from "../../util/optimized/finalFormUtil";
import { RFFGooglePlacesAutocomplete, GooglePlacesAutocomplete } from "../GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import { RFFInput, Input } from "../NewBets/Input";
import { RFFSelect } from "../Select/Select";
import { TypographyType } from "../Typography/Typography";

export const BaseAddressForm = ({
	countryMenuPortalPosition = "absolute",
	...props
}: {
	form: FormRenderProps;
	fieldTypographyType: TypographyType;
	countryMenuPortalPosition?: "fixed" | "absolute";
} & StyledComponentProps &
	SkeletonProps) => {
	return (
		<div
			css={`
				display: flex;
				flex-direction: column;
			`}
			className={props.className}
		>
			<Field<string>
				//@ts-ignore
				component={RFFGooglePlacesAutocomplete}
				name="address1"
				placeholder="Street Address"
				fields={["address_components"]}
				options={{
					types: ["address"],
				}}
				typographyType={props.fieldTypographyType}
				as={Input}
				loading={props.loading}
				validate={value => required(value) || isNotAPOBox(value)}
				onPlaceChanged={(autocomplete: Parameters<Required<React.ComponentProps<typeof GooglePlacesAutocomplete>>["onPlaceChanged"]>[0]) => {
					const place = autocomplete.getPlace();
					const formApi = props.form.form;
					formApi.batch(() => {
						let address1 = "";
						place.address_components?.forEach(component => {
							const type = component.types[0];
							switch (type) {
								case "street_number":
									address1 = component.long_name;
									break;
								case "route":
									if (address1) {
										address1 += " ";
									} else {
										address1 = "";
									}
									address1 += component.long_name;
									break;
								case "locality":
									formApi.change("city", component.long_name);
									break;
								case "administrative_area_level_1":
									formApi.change("state", component.long_name);
									break;
								case "country":
									formApi.change("country", component.long_name);
									break;
								case "postal_code":
									formApi.change("zip", component.long_name);
									break;
							}
						});
						formApi.change("address1", address1);
					});
				}}
				onKeyPress={(event: KeyboardEvent) => {
					//The below logic disables form submission when an autocomplete result is selected via the Enter key.
					if (event.key === "Enter") {
						event.preventDefault();
					}
				}}
			/>
			<Field<string> component={RFFInput} typographyType={props.fieldTypographyType} name="address2" placeholder="Apartment, building, suite (optional)" loading={props.loading} validate={value => isNotAPOBox(value)} />
			<Row>
				<Field<string> component={RFFInput} typographyType={props.fieldTypographyType} name="city" placeholder="City" validate={value => required(value)} loading={props.loading} />
				<Field<string> component={RFFInput} typographyType={props.fieldTypographyType} name="zip" placeholder="ZIP/Postal Code" validate={value => required(value)} loading={props.loading} />
			</Row>
			<Row>
				<Field<string> component={RFFInput} typographyType={props.fieldTypographyType} name="state" placeholder="State/Province" validate={value => required(value)} loading={props.loading} />
				<Field<string>
					component={RFFSelect}
					items={countries}
					isClearable={false}
					name="country"
					placeholder="Country"
					validate={value => required(value)}
					loading={props.loading}
					typographyType={props.fieldTypographyType}
					style={{
						background: getAppColor("lightGrey", "subtle"),
						borderColor: `${getAppColor("lightGrey", "dark")} !important`,
					}}
					menuPortalTarget={document.body}
					styles={{
						menuPortal: (provided: CSSObject) => ({
							...provided,
							position: countryMenuPortalPosition,
							zIndex: 10000,
						}),
						menuList: (provided: CSSObject) => ({
							...provided,
							maxHeight: "15vh",
						}),
						control: (provided: CSSObject) => ({
							...provided,
							borderRadius: "5px",
							height: "42px",
						})
					}}
				/>
			</Row>
		</div>
	);
};

const Row = styled.div`
	display: flex;
	width: 100%;
	& > * {
		flex: 1;
		&:not(:last-child) {
			margin-right: 10px;
		}
	}
`;
