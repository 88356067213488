import { TicketSortOptions } from "./types";

export const MAP_CONFIG = {
	vfsUrl: "https://vfs.seatics.com",
	mapWidth: 500,
	mapHeight: 500,
	enableSectionInfoPopups: true,
	hoverEnabled: true,
};

export const TICKET_SORT_OPTIONS: {
	value: TicketSortOptions;
	label: string;
}[] = [
	{ value: TicketSortOptions.PriceAsc, label: "Low - High" },
	{ value: TicketSortOptions.PriceDesc, label: "High - Low" },
	{ value: TicketSortOptions.SectionAsc, label: "Near - Far" },
	{ value: TicketSortOptions.SectionDesc, label: "Far - Near" },
];

export const NO_MAP_TICKET_SORT_OPTIONS: {
	value: TicketSortOptions;
	label: string;
}[] = [
	{ value: TicketSortOptions.PriceAsc, label: "Low - High" },
	{ value: TicketSortOptions.PriceDesc, label: "High - Low" },
];

export const TICKET_QUANTITY_OPTIONS = [
	{ value: "0", label: "Any" },
	{ value: "1", label: "1" },
	{ value: "2", label: "2" },
	{ value: "3", label: "3" },
	{ value: "4", label: "4" },
	{ value: "5", label: "5" },
	{ value: "6", label: "6+" },
];

export const DEPENDENCIES = {
	MapWidgetStylesheet: "https://mapwidget3.seatics.com/styles",
	MapWidget: "https://mapwidget3.seatics.com/api/framework",
};

export const DISTANCE_FILTER_OPTIONS = [
	{ value: null, label: "Distance" },
	{ value: "5", label: "5 miles" },
	{ value: "10", label: "10 miles" },
	{ value: "25", label: "25 miles" },
	{ value: "50", label: "50 miles" },
	{ value: "10000", label: "+100 miles" },
];

export const HOME_AWAY_FILTER_OPTIONS = [
	{ value: null, label: "Home/Away" },
	{ value: "home", label: "Home" },
	{ value: "away", label: "Away" },
];