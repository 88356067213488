const baseAppColors = {
	primary: {
		main: "#e92224",
		dark: "#b81c1e",
		light: "#fd5e61",
		subtle: "#ffdbdb",
	},
	error: {
		main: "#ff0000",
		dark: "#c70000",
		light: "#ff5c5c",
		subtle: "#ffb1b1",
	},
	darkGrey: {
		main: "#a7a7a7",
		dark: "#555555",
		light: "#c9c9c9",
		text: "#707070",
	},
	lightGrey: {
		main: "#f1f1f1",
		dark: "#e5e5e5",
		light: "#f8f8f8",
		subtle: "#fafafa",
		text: "#000000",
	},
	success: {
		main: "#219653",
		dark: "#186f3d",
		light: "#37c774",
		subtle: "#a1ffc9",
	},
	venmo: {
		main: "#3d95ce",
		dark: "#1c74ae",
	},
	purple: {
		main: "#7b28ce",
		dark: "#4d0099",
		light: "#bd7be2",
		subtle: "#e6d7f5",
	},
};

const appColorsValue = {
	...baseAppColors,
	dark: {
		main: "#000000",
		light: baseAppColors.darkGrey.dark,
		text: "#ffffff",
	},
	light: {
		main: "#ffffff",
		dark: baseAppColors.lightGrey.subtle,
		text: "#000000",
	},
	disabled: {
		main: baseAppColors.darkGrey.main + "a3",
		text: "#000000a6",
	},
};

export interface AppColorObject {
	main: string;
	dark?: string;
	light?: string;
	subtle?: string;
	text?: string;
}

//do not import on other files
export const appColors = appColorsValue as Record<string, AppColorObject>;

export type AppColorKey = keyof typeof appColorsValue;

export type AppColorType = keyof AppColorObject;

//Note: The reason why appColors is exported as "appColorsValue as Record<string, AppColor>" is to be able to properly extract valid keys for the AppColorKey type via appColorsValue (which is not typed as a Record) and to extract valid keys from AppColorObject in AppColorType while type checking the property values of appColors.

export const getAppColor = (color: AppColorKey, colorType?: AppColorType) => {
	const appColorObject = appColors[color];
	if (colorType) {
		if (colorType === "text") {
			return getAppColorTextColor(color);
		} else {
			const targetedAppColor = appColorObject[colorType];
			if (targetedAppColor) {
				return targetedAppColor;
			}
		}
	}
	return appColorObject.main;
};

export const hex2rgba = (hex: string, alpha: number = 1) => {
	const [r, g, b] = (hex.match(/\w\w/g) || []).map(x => parseInt(x, 16));
	return `rgba(${r},${g},${b},${alpha})`;
};

export const getAppColorTextColor = (color: AppColorKey) => {
	const appColor = appColors[color];
	if ("text" in appColor && appColor.text) {
		return appColor.text;
	}
	return "#ffffff";
};
