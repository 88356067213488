import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { CommonPage } from "../components";
import { breakpoints } from "../util/breakpoints";
import { Config } from "../../Config";
import styled from "styled-components/macro";
import { getAppColor } from "../util/appColors";

const sectionBg = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/SoccerexLP/Soccerex-Image3.png`);
const headerLogo = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/SoccerexLP/Soccerex-Image1.png`);
const eventNumbers = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/SoccerexLP/Soccerex-Image5.png`);
const soccerexPhoto = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/SoccerexLP/Soccerex-Image4.png`);
const soccerexVideo = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/SoccerexLP/Miami-Promo-V4-Compressed.mp4`);

const SoccerExRoute = () => {
	const videoRef = useRef<HTMLVideoElement | null>(null);
	const [videoPlaying, setVideoPlaying] = React.useState(false);

	const handlePlayButtonClick = () => {
		setVideoPlaying(true);
		videoRef.current?.play();
	};

	return (
		<CommonPage>
			<HeaderContainer>
				<video
					ref={videoRef}
					src={soccerexVideo}
					loop
					playsInline
					style={{
						position: "absolute",
						zIndex: -1,
						display: "block",
						width: "100%",
						height: "100%",
						objectFit: "cover",
					}}
				/>

				{!videoPlaying && (
					<PlayButton onClick={handlePlayButtonClick}>
						<PlayIcon />
					</PlayButton>
				)}
				{videoPlaying ? null : (
					<>
						<img
							src={headerLogo}
							alt="logo"
							css={`
          max-width: 30%;
          display: block;
          @media (max-width: ${breakpoints.tablet}) {
            max-width: 60%;
          }
          @media (max-width: ${breakpoints.mobile}) {
            max-width: 60%;
          }
        `}
						/>
						<HeaderTitle>
							November 14, 2023 – November 15, 2023
							<br />
							Mana Wynwood Convention Center <br />
							Miami, Florida
						</HeaderTitle>
					</>
				)}

			</HeaderContainer>


			<SectionEventsContainer>
				<Text>
					Join us for an unparalleled experience at the most anticipated football (soccer) business event of the year! Soccerex cordially invites industry professionals from across the globe to converge at the <Bold>Mana Wynwood Convention Center in Miami</Bold> this November.
					<br />
					<br /> As we proudly return for the fourth addition, we are thrilled to host you in the vibrant and dynamic Magic City, Soccerex's adopted home.
				</Text>

				<StyledCard>
					<StyledCardImg src={soccerexPhoto} />
					<StyledCardBody>
						<StyledCardTitle>Soccerex Industry Conference</StyledCardTitle>
						<CardText>Unlock an extraordinary 2-day experience* with access to the main exhibitor hall; immerse yourself in captivating live conference content across 2 stages and seize the opportunity to network with esteemed football (soccer) executives throughout the event and Soccerex’s Social Evening. *VIP option available.</CardText>
						<StyledButton to="event/soccerex-miami-2023">BUY TICKETS</StyledButton>
					</StyledCardBody>
				</StyledCard>

				<div
					css={`
							background: rgba(28, 10, 68, 0.8);
						`}
				>
					<img
						src={eventNumbers}
						alt="logo"
						css={`
								max-width: 100%;
							`}
					/>
				</div>
			</SectionEventsContainer>
		</CommonPage>
	);
};

export const Bold = styled.span`
	font-weight: 700;
`;

export const HeaderContainer = styled.div`
	position: relative;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 80vh;
	max-height: 800px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media (max-width: ${breakpoints.tablet}) {
		min-height: 30vh;
		max-height: 300px;
	}
	@media (max-width: ${breakpoints.mobile}) {
		min-height: 30vh;
		max-height: 300px;
	}
`;

export const HeaderTitle = styled.h1`
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 38px;
	line-height: 46px;
	text-align: center;
	color: #ffffff;
	margin-top: 1em;

    @media (max-width: ${breakpoints.tablet}) {
        font-size: 20px;
        line-height: 18px;
	}
	@media (max-width: ${breakpoints.mobile}) {
        font-size: 18px;
        line-height: 18px;
	}
`;

export const SectionEventsContainer = styled.div`
	padding: 4em 8em;
	height: 100%;
	align-items: center;
	align-content: center;
	justify-content: center;
	background: url(${sectionBg});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	@media (max-width: ${breakpoints.large}) {
		margin-top: 0;
	}

	@media (max-width: ${breakpoints.large}) {
		& > * {
			border-bottom: none;
		}
	}

    @media (max-width: ${breakpoints.tablet}) {
        padding: 2em 1em;
	}
	@media (max-width: ${breakpoints.mobile}) {
        padding: 3em 1em;
	}
`;

export const Text = styled.p`
	ont-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 46px;
	text-align: center;
	letter-spacing: 0.135em;
	color: #ffffff;

    @media (max-width: ${breakpoints.tablet}) {
        font-size: 20px;
        line-height: 18px;
	}
	@media (max-width: ${breakpoints.mobile}) {
        font-size: 18px;
        line-height: 18px;
	}
`;

const StyledCard = styled.div`
	width: 100%;
	height: 100%;
	margin-top: 3em;
	margin-bottom: 3em;
	text-align: center;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media (max-width: ${breakpoints.tablet}) {
	    width: 100%;
	}
	@media (max-width: ${breakpoints.mobile}) {
		width: 100%;
	}
`;

const StyledCardImg = styled.img`
	width: 100%;
	height: 50%;
	object-fit: cover;
	padding: 0;
	margin: 0;
`;

const StyledCardBody = styled.div`
	background-color: #1c0a44;
	color: #ffffff;
	font-family: "Montserrat", sans-serif;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: -3px;
	padding: 2em 10em;
	position: relative;
	height: 40%;

    @media (max-width: ${breakpoints.tablet}) {
        padding: 2em;
	}
	@media (max-width: ${breakpoints.mobile}) {
        padding: 2em;
	}
`;

const StyledCardTitle = styled.h3`
	text-transform: uppercase;
	font-size: 62px;
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
	margin-bottom: 15px;
	color: #1cb3d4;
	line-height: 76px;
	text-align: center;
	letter-spacing: 0.075em;
	padding-top: 20px;

    @media (max-width: ${breakpoints.tablet}) {
        font-size: 30px;
        line-height: 30px;
	}
	@media (max-width: ${breakpoints.mobile}) {
        font-size: 30px;
        line-height: 30px;
	}
`;

const CardText = styled.p`
	font-size: 23px;
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
	color: #ffffff;
	margin-bottom: 15px;
	font-weight: 500;
	line-height: 32px;

    @media (max-width: ${breakpoints.tablet}) {
        font-size: 18px;
        line-height: 25px;
	}
	@media (max-width: ${breakpoints.mobile}) {
        font-size: 18px;
        line-height: 25px;
	}
`;

const StyledButton = styled(Link)`
	background: #1cb3d4;
	border-radius: 5px;
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
	font-size: 26px;
	color: ${getAppColor("light")};

	width: 278px;
	border: none;
	cursor: pointer;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	height: 68px;
	display: flex;
	justify-content: center;
	align-items: center;

    margin-top: 1em;
    margin-bottom: 1em;

    @media (max-width: ${breakpoints.tablet}) {
        width: 200px;
        height: 40px;
        font-size: 18px;
        line-height: 25px;
	}
	@media (max-width: ${breakpoints.mobile}) {
        width: 200px;
        height: 40px;
        font-size: 18px;
        line-height: 25px;
	}

	text-transform: uppercase;
	&:hover {
		opacity: 0.6;
	}
`;

const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
`;

const PlayIcon = styled.div`
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 25px solid white;
`;

export default SoccerExRoute;
