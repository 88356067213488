import * as t from "io-ts";
import { DateTimeCodec } from "./util/DateTimeCodec";
import { RelabelCodec } from "./util/RelabelCodec";

const SearchItemCodec = t.strict({
	name: t.string,
	url: t.string,
});

const EventSearchCodec = t.intersection([
	SearchItemCodec,
	RelabelCodec("occurs_at", "occursAt", DateTimeCodec),
	t.strict({
		venue: t.strict({
			name: t.string,
			location: t.union([t.string, t.null]),
			city: t.union([t.string, t.null]),
			state: t.union([t.string, t.null]),
		}),
	}),
]);

export const MainSearchResponseCodec = t.strict({
	time_local_search: t.number,
	performers: t.array(SearchItemCodec),
	events: t.array(EventSearchCodec),
	venues: t.array(SearchItemCodec),
	reservations: t.array(SearchItemCodec),
	//team_futures: t.array(SearchItemCodec)
});

export const SearchResponseCodec = t.array(EventSearchCodec);

export const CategorySearchResponseCodec = t.array(SearchItemCodec);

export const PerformerSearchResponseCodec = t.array(
	t.strict({
		name: t.string,
		url: t.string,
		count: t.number,
	})
);

export const VenueSearchResponseCodec = t.array(
	t.strict({
		name: t.string,
		url: t.string,
	})
);

export type SearchItem = t.TypeOf<typeof SearchItemCodec>;

export type EventSearchItem = t.TypeOf<typeof EventSearchCodec>;

export type MainSearchResponse = t.TypeOf<typeof MainSearchResponseCodec>;
export type SearchResponse = t.TypeOf<typeof SearchResponseCodec>;
export type CategorySearchResponse = t.TypeOf<typeof CategorySearchResponseCodec>;
export type PerformerSearchResponse = t.TypeOf<typeof PerformerSearchResponseCodec>;
export type VenueSearchResponse = t.TypeOf<typeof VenueSearchResponseCodec>;
