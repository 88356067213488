import { BetOddsLabelType } from "../BetOddsLabel";

export const getGameSpreadOddsMessage = (spread: number, oddsType: BetOddsLabelType = "bestOdds") => {
	if (spread) {
		const points = Math.abs(spread);
		const value = `Win${
			{
				bestOdds: " by at Least",
				worstOdds: ", or Lose by Less Than",
			}[oddsType]
		} ${points} Point${points !== 1 ? "s" : ""}`;
		return value;
	} else {
		return "Win the Game";
	}
};

export const getGameSpreadOdds = (spread: number, isHomeTeam: boolean) => {
	if (spread) {
		const value = isHomeTeam ? spread : -spread;
		return value > 0 ? `+${value}` : `${value}`;
	} else {
		return "Even";
	}
};
