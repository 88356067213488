import React from "react";
import styled from "styled-components/macro";
import { InlineStylesProps, StyledComponentProps } from "../../../model/optimizedModel/styles";
import { Typography } from "../Typography/Typography";

export type BetOddsLabelType = "bestOdds" | "worstOdds";

export const BetOddsLabel = ({
	type,
	customContent,
	...spreadableProps
}: {
	type: BetOddsLabelType;
	customContent?: React.ReactNode;
} & StyledComponentProps &
	InlineStylesProps) => {
	return (
		<>
			{customContent ? (
				<SpreadMessage {...spreadableProps} type="extraLargeSpecialBody">
					{customContent}
				</SpreadMessage>
			) : (
				<BetOddsMessage {...spreadableProps} type="normalSpecialBody" highlight color={type === "bestOdds" ? "success" : "primary"}>
					{type === "bestOdds" ? "Favorite" : "Underdog"}
				</BetOddsMessage>
			)}
		</>
	);
};

const SpreadMessage = styled(Typography)`
	overflow: hidden;
`;

const BetOddsMessage = styled(SpreadMessage)`
	padding: 2px;
	text-align: center;
	//TODO: Try to find a dynamic way to set the width instead of hardcoding the below width.
	min-width: 67px;
`;
