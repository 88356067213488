import React, { useEffect, useState } from "react";
import { SeaticsTicketGroup } from "../Seatics/types";
import { Nulleable, Optional } from "../../../model/Utils";
import { PanelModal, VFSImage, VFSHeader } from "./styles";
import styled from "styled-components/macro";
import arrow from "../../resource/image/ticket-group-arrow.svg";
import zoomImage from "../../resource/image/zoom-in-selector.png";
import { maxContentWidthPadding } from "../../util/maxContentWidth";
import { formatPrice, shortFormatPrice } from "../../util/formatPrice";
import { useTicketInvoiceOutput } from "../Invoices/TicketInvoice";
import { useSelector } from "react-redux";
import { priceDoesNotExceedVenmoTransactionLimit, siThePartyEventId } from "../../util/general";
import si_the_party_background from "../../resource/assets/seatics-override-images/si-the-party/background.jpg";
import { getTotalTicketPrice } from "../../util/prices";
import { getTransactionFee } from "../../../presentation/util/prices";
import { EventTopMessage } from "../Event/EventTopMessage";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { getAppColor } from "../../util/appColors";
import { FontFamily, getTypographyStyles, Typography } from "../Typography/Typography";
import { Select, selectControlStyles, SelectItem, selectOptionStyles } from "../Select/Select";
import { Button } from "../NewButton/Button";
import { breakpoints } from "../../util/breakpoints";
import { TicketPurchaseInfo } from "./TicketPurchaseInfo";
import { EventData } from "../../../model/EventPage";
import { getHumanReadableDate } from "../../util/getHumanReadableDate";
import calendarIcon from "../../resource/img/icons/calendar-check.svg";
import locationIcon from "../../resource/img/icons/location-solid.svg";
import notesIcon from "../../resource/img/icons/notes.svg";
import qrIcon from "../../resource/img/icons/qr-code.svg";
import mobileIcon from "../../resource/img/icons/mobile-transfer.svg";
import fedexIcon from "../../resource/img/icons/FedEx.svg";
import ticketIcon from "../../resource/img/icons/ticket.svg";
import barCodeIcon from "../../resource/img/icons/barCode.svg";
import shieldCheckIcon from "../../resource/img/icons/shield-check.svg";
import { getTicketDeliveryType, getExpectedShipByDate } from "./TicketUtils";
import { useAutoUpdateState } from "../../hooks/useAutoUpdateState";
import { useHistory } from "react-router-dom";
import { Config } from "../../../Config";

const vfsPlaceholder = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/events/vfs-placeholder.png`);

const ticketDeliveryInfo = {
	FedEx: {
		icon: fedexIcon,
		title: "FedEX Delivery",
		description: "Tickets will ship via FedEx. An indirect signature may be required.",
	},
	"E-Ticket": {
		icon: barCodeIcon,
		title: "E-Ticket Delivery",
		description: "E-Tickets will be available to download (PDF).",
	},
	"Mobile Transfer": {
		icon: mobileIcon,
		title: "Mobile Transfer Delivery",
		description: `Tickets will be transferred to the email address on your order. 
                  A smartphone with Android or IOS will be required to access your mobile tickets`,
	},
	"Mobile PDF": {
		icon: qrIcon,
		title: "Mobile PDF Delivery",
		description: `Tickets will be available to download (PDF).`,
	},
	"Mobile AXS": {
		icon: mobileIcon,
		title: "Mobile AXS Delivery",
		description: `Tickets will be transferred to the email address on your order. 
                  You will need to create an account with the Issuer of the tickets (a 3rd party) in order to access your Mobile Tickets`,
	},
	"Will Call": {
		icon: ticketIcon,
		title: "Will Call",
		description: "Tickets will be available for pickup at the venues box office on the day of the event. A photo ID is required.",
	},
	"Guest List": {
		icon: ticketIcon,
		title: "Guest List",
		description: "Customers will gain access to the event by providing their full name and a Photo ID at the Venue.",
	},
};

interface TicketViewProps {
	selectedTicket: Nulleable<SeaticsTicketGroup>;
	selectedQuantity: Nulleable<number>;
	onCheckout: (quantity: number) => void;
	onTicketViewClose: () => void;
	eventData: Nulleable<EventData>;
	onVfsImageClick: () => void;
	showTopMessage: boolean;
	vfsDisabled?: boolean;
}

export const TicketView = (props: TicketViewProps) => {
	const [open, setOpen] = useState(false);
	const [quantity, setQuantity] = useState<number>(0);
	const [quantityRange, setQuantityRange] = useState<number[]>([]);
	const [showVfsImage, setShowVfsImage] = useState(true);
	const [sitixFee, setSitixFee] = useState(0);
	const [displayVenmoTransactionFee, setDisplayVenmoTransactionFee] = useState(false);

	const [deliveryType, setDeliveryType] = useState<keyof typeof ticketDeliveryInfo>("FedEx");
	const [expectedShipByDate, setExpectedShipByDate] = useState<Nulleable<string>>("");

	const history = useHistory();

	const formatVenue = (venueStreetAddress: Optional<string>, venueCity?: Optional<string>, venueState?: Optional<string>) => {
		let readableVenue = "";
		if (!venueStreetAddress) {
			return readableVenue; //If we don't have the location we don't show anything
		}

		readableVenue += venueStreetAddress;
		if (!venueCity) {
			return readableVenue;
		}
		readableVenue += `, ${venueCity}`;

		if (!venueState) {
			return readableVenue;
		}

		return `${readableVenue}, ${venueState}`;
	};

	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	useEffect(() => {
		if (props.selectedTicket) {
			const quantityRange = props.selectedTicket?.tgClientData.tgSplits.sort((a: number, b: number) => a - b);
			setQuantityRange(quantityRange);
			setQuantity(quantityRange[0]);
			setDeliveryType(getTicketDeliveryType(props.selectedTicket) as keyof typeof ticketDeliveryInfo);
			setExpectedShipByDate(getExpectedShipByDate(props.selectedTicket?.tgClientData.expectedShipByDate));
		}
		setShowVfsImage(true);
		setOpen(!!props.selectedTicket);
	}, [props.selectedTicket]);

	const vfsURL = useAutoUpdateState(() => {
		if (props.vfsDisabled) return undefined;
		return props.selectedTicket?.section?.vfsUrl ? props.selectedTicket?.section?.vfsUrl : undefined;
	}, [props.vfsDisabled, props.selectedTicket?.section?.vfsUrl]);

	const ticketInvoiceOutput = useTicketInvoiceOutput({
		price: props.selectedTicket?.tgPrice || 0,
		quantity: quantity,
		serviceFeeRate: props.selectedTicket?.tgClientData.tgServiceFeeRate,
		tax: (props.selectedTicket?.tgClientData.tgTaxRate || 0) * (props.selectedTicket?.tgPrice || 0) * quantity,
	});

	useEffect(() => {
		if (props.selectedTicket) {
			const quantityRange = props.selectedTicket?.tgClientData.tgSplits.sort((a: number, b: number) => a - b);
			setQuantityRange(quantityRange);
			setQuantity(props.selectedQuantity ? props.selectedQuantity : quantityRange[0]);
		}
	}, [props.selectedTicket]);

	const { eventData } = useSelector(state => state.transient.event);

	const event = useSelector(state => state.transient.event);

	useEffect(() => {
		/*
      The following code gets which is the lowest transaction fee among the three payment methods that we have now (Venmo, Paypal and credit card) 
      and links that transaction fee with the method that offers it. The reason why we have four non-nested iterations 
      (Object.entries, map, filter and reduce) is because, since we are working only with 3 payment methods, we believe the complexity shouldn't be too bad, 
      even if a couple more methods are added in the future
    */
		if (event.eventData?.transactionFeeRules && quantity && props.selectedTicket?.tgPrice && ticketInvoiceOutput) {
			const sitixTransactionFee = Object.entries(event.eventData.transactionFeeRules)
				.map(([paymentMethod, transactionFeeRule]) => {
					return {
						type: paymentMethod,
						value: getTransactionFee(transactionFeeRule, props.selectedTicket?.tgPrice, quantity, props.eventData?.source, props.selectedTicket?.tiers),
					};
				})
				.filter(transactionFee => {
					return (
						transactionFee.type !== "VenmoAccount" ||
						priceDoesNotExceedVenmoTransactionLimit(
							getTotalTicketPrice({
								...ticketInvoiceOutput,
								transactionFee: transactionFee.value,
							})
						)
					);
				})
				.reduce((firstTransactionFee, secondTransactionFee) => {
					return firstTransactionFee.value < secondTransactionFee.value ? firstTransactionFee : secondTransactionFee;
				});
			setSitixFee(sitixTransactionFee.value);
			setDisplayVenmoTransactionFee(sitixTransactionFee.type === "VenmoAccount");
		}
	}, [event.eventData?.transactionFeeRules, quantity, props.selectedTicket?.tgPrice, ticketInvoiceOutput]);

	const displayFaceValueMessage = useAutoUpdateState(() => event.eventData?.venueState === "NY" || event.eventData?.venueState === "New York", [event.eventData?.venueState]);

	if (open && props.selectedTicket) {
		return (
			<>
				<PanelModal>
					{props.showTopMessage && <>{isMobile && <EventTopMessage message="" />}</>}
					<VFSHeader
						showTopMessage={props.showTopMessage}
						style={
							eventData?.id === siThePartyEventId
								? {
										backgroundImage: `url(${si_the_party_background})`,
										backgroundRepeat: "no-repeat",
										backgroundPosition: "center",
										backgroundSize: "cover",
								  }
								: undefined
						}
					>
						{showVfsImage ? (
							<VFSImage
								src={vfsURL || vfsPlaceholder}
								alt="VFS"
								onError={() => {
									setShowVfsImage(false);
								}}
								style={
									eventData?.id === siThePartyEventId
										? {
												objectFit: "initial",
												width: "auto",
												margin: "auto",
												maxWidth: "100%",
												height: "auto",
												background: "initial",
												maxHeight: 400,
												boxSizing: "border-box",
												padding: maxContentWidthPadding,
										  }
										: undefined
								}
							/>
						) : (
							<EmptyVfsImageSeparator />
						)}
						<ControlsContainer>
							<ControlContainer
								onClick={() => {
									setOpen(false);
									props.onTicketViewClose();
									history.go(-1);
								}}
							>
								<BackArrow src={arrow} alt="Back button" />
							</ControlContainer>
							{!isMobile && !!vfsURL && (
								<ControlContainer onClick={props.onVfsImageClick}>
									<img src={zoomImage} alt="Zoom in" />
								</ControlContainer>
							)}
						</ControlsContainer>
					</VFSHeader>

					<TicketInfoUpperSection showTopMessage={props.showTopMessage}>
						<SeatInfo>
							<div
								css={`
									display: flex;
									flex-direction: column;
									& > div {
										align-items: center;
										display: flex;
										justify-content: space-between;
									}
								`}
							>
								<div>
									<TicketSection>{props.selectedTicket.getSectionDisplayName()}</TicketSection>
									<Typography type="bodyLarge" fontWeight={400}>
										{shortFormatPrice(props.selectedTicket.tgPrice)} <span>ea.</span>
									</Typography>
								</div>
								<div>
									<Typography type="bodyNormal">{`Row ${props.selectedTicket.getRowDisplayName()}`}</Typography>
								</div>
							</div>
							<Divider />
							<CheckoutRow>
								<SelectContainer>
									<Select
										isClearable={false}
										styles={{
											indicatorSeparator: provided => ({
												...provided,
												display: "none",
											}),
											control: provided => ({
												...provided,
												...selectControlStyles,
												borderRadius: "4px",
												height: "50px",
											}),
											singleValue: provided => ({
												...provided,
												color: getAppColor("dark"),
												fontWeight: 500,
												fontSize: 16,
												marginLeft: 55,
												"@media (max-width: 500px)": {
													marginLeft: 47,
												},
											}),
											option: (provided, state) => ({
												...provided,
												color: state.isSelected ? getAppColor("light") : getAppColor("dark"),
												...(state.isSelected && {
													background: getAppColor("dark"),
												}),
												...selectOptionStyles,
												fontSize: 16,
												textAlign: "center",
											}),
										}}
										items={quantityRange.map(item => {
											return { value: item, label: `QTY: ${item}` };
										})}
										placeholder="Quantity"
										value={quantity}
										onChange={(value: SelectItem["value"]) => {
											if (value) {
												setQuantity(Number(value));
											}
										}}
									/>
								</SelectContainer>
								<StyledButton
									onClick={clickEvent => {
										clickEvent.preventDefault();
										clickEvent.stopPropagation();
										props.onCheckout(quantity);
									}}
									style={{ height: "50px", fontSize: 20 }}
								>
									Checkout
								</StyledButton>
							</CheckoutRow>
						</SeatInfo>
					</TicketInfoUpperSection>
					<PanelContent>
						{props.selectedTicket && quantity && (
							<>
								{props.eventData && (
									<>

										<TicketPurchaseInfo icon={calendarIcon} title={props.eventData.name} content={getHumanReadableDate(props.eventData.date)} />

										<TicketPurchaseInfo icon={locationIcon} title={props.eventData.venueName || ""} content={formatVenue(props.eventData.venueStreetAddress, props.eventData.venueCity, props.eventData.venueState)} />

										<TicketPurchaseInfo icon={ticketDeliveryInfo[deliveryType].icon} title={ticketDeliveryInfo[deliveryType].title} content={ticketDeliveryInfo[deliveryType].description} additionalData={!!expectedShipByDate ? ` Expected to ship by ${expectedShipByDate}` : undefined} />

										<TicketPurchaseInfo icon={shieldCheckIcon} title="SI Tickets Fan Guarantee" content="Every Ticket is protected. In case of event cancellation, we'll make it up for you" />
										{(props.selectedTicket?.tgNotes || displayFaceValueMessage) && <TicketPurchaseInfo icon={notesIcon} title="Seller Notes" content={
											<>
												{props.selectedTicket?.tgNotes}{displayFaceValueMessage && <>{props.selectedTicket?.tgNotes && <br />}<strong> {!!props.selectedTicket.tgFaceValue ? `Face Value: ${formatPrice(props.selectedTicket.tgFaceValue)}` : "Face Value Not Provided By Seller."}</strong></>}
											</> 
										} />}
									</>
								)}
							</>
						)}
					</PanelContent>
				</PanelModal>
			</>
		);
	}
	return null;
};

const PanelContent = styled.div`
	padding: 19px ${maxContentWidthPadding}px;
	line-height: normal;
	font-weight: 500;
	color: black;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	overflow-y: auto;
	min-height: 100%;
`;

const EmptyVfsImageSeparator = styled.div`
	height: 40px;
`;

const Divider = styled.div`
	border-bottom: 1px solid lightgray;
	margin: 12px 0;

	@media (${breakpoints.mobile}) {
		margin: 14.5px 0;
	}
`;

const TicketInfoUpperSection = styled.div<{
	showTopMessage: boolean;
}>`
	display: flex;
	background: ${getAppColor("lightGrey")};

	@media (max-width: ${breakpoints.tablet}) {
		${props =>
			props.showTopMessage &&
			`
		margin-top: 30px;
		`}
	}
`;

const SeatInfo = styled.div`
	display: flex;
	flex-direction: column;
	padding: 18px 20px 12px 20px;
	width: 100%;
	box-sizing: border-box;
`;

const TicketSection = styled.div`
	${getTypographyStyles("leadParagraph", {
		color: "dark",
		fontWeight: 600,
	})};
	font-family: ${FontFamily.Poppins};
	line-height: initial;
	max-width: 320px;

	@media (max-width: ${breakpoints.mobile}) {
		${getTypographyStyles("bodyLarge", {
			color: "dark",
		})};
		max-width: 260px;
	}

	@media (max-width: ${breakpoints.extraSmall}) {
		max-width: 200px;
	}
`;

const CheckoutRow = styled.div`
	display: flex;
	width: 100%;
`;

const SelectContainer = styled.div`
	width: 175px;
	margin-right: 15px;

	@media (max-width: ${breakpoints.extraSmall}) {
		width: 132px;
	}
`;

const SavingsIndicator = styled.div`
	display: flex;
	flex-direction: column;
`;

const SavingsIndicatorContent = styled.div`
	cursor: pointer;
	border-radius: 5px;
	${getTypographyStyles("bodyNormal", {
		color: "light",
		fontWeight: 600,
	})};

	text-transform: uppercase;
	background: #34b369; //I had to add this hash even if it's out of our design system as a quick change in the requirements. Later we have to decide i we want to add it to our design system
	padding: 0 5px;
	text-align: center;
`;

const StyledButton = styled(Button)`
	width: 268px;

	@media (max-width: ${breakpoints.tablet}) {
		width: 204px;
	}

	@media (max-width: ${breakpoints.extraSmall}) {
		width: 150px;
	}
`;

const ControlContainer = styled.div`
	background: ${getAppColor("lightGrey")};
	border-radius: 100%;
	width: 46px;
	height: 46px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

const controlPadding = "7px";
const ControlsContainer = styled.div`
	position: absolute;
	top: 15px;
	left: ${controlPadding};
	width: calc(100% - 2 * ${controlPadding});
	display: flex;
	justify-content: space-between;
`;

const BackArrow = styled.img`
	transform: scaleX(-1);
`;
