import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components/macro";
import { useModalRouteContext } from "../../util/ModalRouteContext";
import { breakpoints } from "../../util/breakpoints";
import { InlineCSSProps, StyledComponentProps } from "../../../model/optimizedModel/styles";
import { getAppColor } from "../../util/appColors";
import close_button_black from "../../resource/image/dialog-close-black.svg";
import close_button_white from "../../resource/image/dialog-close-white.svg";
import { maxContentWidthPadding } from "../../util/maxContentWidth";

type DialogType = "utility" | "information";

type OverlayState = "open" | "closing" | "closed" | "opening";

export const dialogOpenAnimationMS = 300;

const closeButtonSize = "14px";

//TODO: Consider removing "internal open state" functionality in order to match React's "controlled components" design pattern.
export const Dialog = (
	props: React.PropsWithChildren<
		{
			type: DialogType;
			onClose?: () => void;
			headerChildren?: React.ReactNode;
			fullscreenOnMobile?: boolean;
			open: boolean;
			disableCloseControls?: boolean;
		} & StyledComponentProps &
			InlineCSSProps
	>
) => {
	const [open, setOpen] = useState(false);
	const propState = open ? "open" : "closed";
	const [state, setState] = useState<OverlayState>(propState);

	useEffect(() => {
		setOpen(true);
	}, [setOpen]);

	useEffect(() => {
		if (!open) return;

		const initialScrollX = window.scrollX;
		const initialScrollY = window.scrollY;
		const initialTop = document.body.style.top;
		const initialPosition = document.body.style.position;

		document.body.style.top = `-${initialScrollY}px`;
		document.body.style.position = "fixed";
		document.body.classList.add("fixed-pac-container");
		return () => {
			document.body.style.position = initialPosition;
			document.body.style.top = initialTop;
			document.body.classList.remove("fixed-pac-container");
			window.scrollTo(initialScrollX, initialScrollY);
		};
	}, [open]);

	useEffect(() => {
		if (propState === state) return;
		setState(open ? "opening" : "closing");
		const handle = setTimeout(() => {
			setState(open ? "open" : "closed");
		}, dialogOpenAnimationMS);
		return () => clearTimeout(handle);
	}, [open, propState, state]);

	const { closeAllModals } = useModalRouteContext();

	const onClose = () => {
		if (!props.disableCloseControls) {
			setOpen(false);
			setTimeout(props.onClose ?? closeAllModals, dialogOpenAnimationMS);
		}
	};

	useEffect(() => {
		setOpen(props.open);
	}, [props.open]);

	if (state === "closed") {
		return null;
	}

	return (
		<Overlay
			onClick={onClose}
			state={state}
			css={
				props.fullscreenOnMobile
					? `
				@media (max-width: ${breakpoints.mobile}) {
					justify-content: flex-end;
					align-items: stretch;
				}
      `
					: ``
			}
		>
			<Container
				onClick={event => event.stopPropagation()}
				css={`
					${props.css}
					/* This style fixes the issue with the Google Autocomplete results/popup being mispositioned after scrolling on the page (SIT-552) */
					.pac-container {
						position: inherit !important;
					}
					@media (max-width: ${breakpoints.mobile}) {
						${props.fullscreenOnMobile
							? `
							max-width: 100vw;
							min-height: 100%;
							border: none;
						`
							: `
							min-width: 88vw;
							max-height: 80vh;
						`}
					}
				`}
				className={props.className}
			>
				<Header
					style={{
						...(props.type === "utility" && {
							borderBottom: `border-bottom: 1px solid ${getAppColor("lightGrey", "dark")}`,
						}),
						...(!props.disableCloseControls && {
							minHeight: closeButtonSize,
						}),
						...(!props.disableCloseControls || props.headerChildren
							? {
									padding: `${verticalDialogSpacing + verticalDialogSpacingUnit} 0`,
							  }
							: {
									paddingBottom: verticalDialogSpacing + verticalDialogSpacingUnit,
							  }),
					}}
				>
					{props.headerChildren && <HeaderChildren>{props.headerChildren}</HeaderChildren>}
					{!props.disableCloseControls && <CloseButton className="close-button" src={props.type === "utility" ? close_button_black : close_button_white} onClick={onClose} />}
				</Header>
				<Wrapper>{props.children}</Wrapper>
			</Container>
		</Overlay>
	);
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const Overlay = styled.div<{
	state: OverlayState;
}>`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 300 !important;
	display: flex;
	flex-direction: column-reverse;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	background-color: rgba(0, 0, 0, 0.8);
	${props =>
		props.state === "closing"
			? css`
					animation: ${fadeOut} ${dialogOpenAnimationMS}ms linear;
			  `
			: props.state === "opening"
			? css`
					animation: ${fadeIn} ${dialogOpenAnimationMS}ms linear;
			  `
			: ""}
`;

const HeaderChildren = styled.div`
	width: 100%;
	overflow: auto;
`;

const Header = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: bold;
	font-size: 16px;
	color: #000;
`;

const CloseButton = styled.img`
	width: ${closeButtonSize};
	height: ${closeButtonSize};
	position: absolute;
	right: 0;
	cursor: pointer;
`;

export const verticalDialogSpacing = maxContentWidthPadding;
export const verticalDialogSpacingUnit = "px";

export const bottomDialogSpacing = "28";
export const bottomDialogSpacingUnit = "px";

const Container = styled.div`
	background: #ffffff;
	display: flex;
	flex-direction: column;
	max-height: 92vh;
	max-width: 80vw;
	border: solid 1px #fff;
	overflow-y: auto;
	padding: 0 ${verticalDialogSpacing + verticalDialogSpacingUnit};
	padding-bottom: ${bottomDialogSpacing + bottomDialogSpacingUnit};
	box-sizing: border-box;
	overflow-x: hidden;
`;

const Wrapper = styled.div`
	display: flex;
	flex: 1;
	box-sizing: border-box;
`;
Dialog.Wrapper = Wrapper;
