import React, { useCallback, useEffect, useRef, useState } from "react";
import * as Consts from "./consts";

import { SeaticsAPI, SeaticsLegendItem, SeaticsTicketGroup, SeaticsTicketSegment, TicketSortOptions } from "./types";

import { MapContainer, Map, HideMapButton, ShowMapButton, TimeoutImage } from "./styles";

//Scoped version of bootstrap
import "../../util/jQuery";
import "../../resource/css/bootstrap-scope.css";
import "bootstrap/dist/js/bootstrap.js";
import { TicketResponse } from "../../../services/types/TicketResponse";
import { EventData, EventSearchParams } from "../../../model/EventPage";
import { Nulleable } from "../../../model/Utils";
import { EventVenueInfoResponse } from "../../../services/types/EventVenueInfoResponse";
import { Config } from "../../../Config";
import hide_map_icon from "../../resource/images/hide-map.svg";
import show_map_icon from "../../resource/images/show-map.svg";
import { useIsMobileWeb } from "../../hooks/useIsMobileWeb";
import { tgTransactionFeeRate } from "../../util/tgTransactionFeeRate";
import conservation_game from "../../resource/assets/seatics-override-images/conservation_game.png";
import on_any_sunday from "../../resource/assets/seatics-override-images/on_any_sunday.png";
import styled from "styled-components/macro";
import { eventPageMobileMediaQuery } from "../EventPage/styles";
import shaqs_fun_house from "../../resource/assets/seatics-override-images/shaqs_fun_house.png";
import { siThePartyEventId } from "../../util/general";
import { useAutoUpdateState } from "../../hooks/useAutoUpdateState";
import si_the_party from "../../resource/assets/seatics-override-images/si-the-party/map2.jpg";
import { InlineStylesProps, StyledComponentProps } from "../../../model/optimizedModel/styles";
import si_the_party_background from "../../resource/assets/seatics-override-images/si-the-party/background.jpg";
import vanyersports_x_si_tix_ufc from "../../resource/assets/seatics-override-images/vanyersports_x_si_tix_ufc.png";
import dj_sof from "../../resource/assets/seatics-override-images/dj_sof.png";

const tailgate_one = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/events/si-the-party/tailgate_one.png`);
const tailgate_two = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/events/si-the-party/tailgate_two.png`);
const tailgate_three = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/events/si-the-party/tailgate_three.png`);
const tailgate_four = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/events/si-the-party/tailgate_four.png`);
const tailgate_five = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/events/si-the-party/tailgate_five.png`);
const tailgate_six = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/events/si-the-party/tailgate_six.png`);
const tailgate_seven = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/events/si-the-party/tailgate_seven.png`);
const usa_vs_mexico = "https://cdn.sitickets.com/staging/images/events/usa-vs-mexico-national-arena-soccer.png";
declare global {
	interface Window {
		Seatics: SeaticsAPI;
	}
}

interface SeaticsMapProps extends InlineStylesProps {
	currentEvent: string;
	filters: EventSearchParams;
	eventData: Nulleable<EventData>;
	tickets: Nulleable<TicketResponse>;
	mapData: Nulleable<EventVenueInfoResponse>;
	loadedEventSlug: Nulleable<string>;
	selectedTicket: Nulleable<SeaticsTicketGroup>;
	highlightedTicket: Nulleable<SeaticsTicketGroup>;
	selectedSection: Nulleable<SeaticsLegendItem>;
	seaticsTimeout: boolean;
	onMapClicked?: () => void;
	onEventLoaded: (event: string) => void;
	onUpdateVisibleTickets: (tickets: SeaticsTicketSegment[]) => void;
	onUpdateLegendItems: (tickets: SeaticsLegendItem[]) => void;
	highlightedSection: Nulleable<SeaticsLegendItem>;
	showMap: boolean;
	setShowMap: (hideMap: boolean) => void;
	setShowMapClicked: (hideMap: boolean) => void;
}

const CustomMapImage = (
	props: {
		src: string;
		noShadow?: boolean;
	} & StyledComponentProps
) => {
	return (
		<div
			css={`
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				& > img {
					max-width: 87%;
					max-height: 87%;
					margin-bottom: 30px;
					${!props.noShadow &&
					`
						box-shadow: 0px 3px 13px -6px black;
					`}
					@media ${eventPageMobileMediaQuery} {
						margin-bottom: initial;
					}
				}
			`}
			className={props.className}
		>
			<img src={props.src} alt="Map" />
		</div>
	);
};

/**
 * Seatics MapWidget component
 */
export const SeaticsMap2 = React.memo((props: SeaticsMapProps) => {
	const { eventData, tickets, selectedTicket, highlightedTicket, loadedEventSlug, seaticsTimeout, highlightedSection } = props;
	const mapData = useAutoUpdateState(() => {
		if (eventData?.id === siThePartyEventId && props.mapData?.length) {
			return [props.mapData[0]];
		}
		return props.mapData;
	}, [props.mapData, eventData?.id]);
	const containerRef = useRef<HTMLDivElement>(null);
	const showToggleMapButtons = useIsMobileWeb(901);

	const currentEventRef = useRef<string | undefined>();
	const loadedEventRef = useRef<string | null>();

	currentEventRef.current = props.currentEvent;
	loadedEventRef.current = props.loadedEventSlug;

	const notifyEventLoaded = useCallback(() => {
		if (currentEventRef.current && currentEventRef.current !== loadedEventRef.current) {
			props.onEventLoaded(currentEventRef.current);
			props.onUpdateLegendItems(window.Seatics.MapComponent.getAvailableLegendItems());
		}
	}, [props.onEventLoaded, props.onUpdateLegendItems]);

	// Map building functions
	const presentationInterface = {
		updateTicketsList: (tickets: SeaticsTicketSegment[]) => {
			notifyEventLoaded();
			props.onUpdateVisibleTickets(tickets);
		},
	};

	const mapTickets = (tickets: TicketResponse) => {
		return tickets.map(ticket => ({
			tgUserSec: ticket.tgUserSec || "",
			tgUserRow: ticket.tgUserRow || "",
			tgQty: ticket.tgQty,
			tgPrice: ticket.tgPrice || 0,
			tgID: ticket.tgID,
			tgNotes: ticket.tgNotes,
			tgClientData: {
				tgTransactionFeeRate: ticket.tgTransactionFeeRate === undefined ? tgTransactionFeeRate : ticket.tgTransactionFeeRate,
				tgDeliveryMethods: ticket.tgDeliveryMethods,
				tgSplits: ticket.tgSplits,
				expectedShipByDate: ticket.expectedShipByDate,
				tgTaxRate: ticket.tgTaxRate,
				tgServiceFeeRate: ticket.tgServiceFeeRate,
			},
			tgSplitRuleId: ticket.tgSplits,
			tgDeliveryOptions: ticket.tgDeliveryOptions,
			tgFaceValue: ticket.tgFaceValue,
		}));
	};

	const clearPreviousMap = () => {
		var innerDivs = document.getElementsByClassName("sea-map-inner");
		if (innerDivs.length > 0) {
			while (innerDivs[0]) {
				const innerDiv = innerDivs[0];
				innerDiv?.parentNode?.removeChild(innerDiv);
			}
		}
	};
	// Load map effect
	useEffect(() => {
		if (eventData && tickets && mapData && props.currentEvent === eventData?.slug) {
			//Clear previous map markup to fix issue in Safari
			clearPreviousMap();

			window.Seatics.MapComponent.create({
				...Consts.MAP_CONFIG,
				container: window.jQuery(containerRef.current),
				imgSrc: mapData[0]?.mapImage,
				mapName: mapData[0]?.mapName,
				mapData: mapData[1],
				tickets: mapTickets(tickets),
				presentationInterface,
			});

			configureCustomFilters();
			window.Seatics.MapComponent.onTicketListDrawn();
			window.Seatics.MapComponent.setFilterOptions(getSeaticFiltersFromCustom(props.filters));
			window.Seatics.config.noMapImageUrl = Config.getResourceUrl(eventData.noMapImageUrl);
		}
	}, [props.currentEvent, eventData, tickets, mapData]);

	// Filters update effect
	useEffect(() => {
		if (loadedEventSlug) {
			window.Seatics.MapComponent.setFilterOptions(getSeaticFiltersFromCustom(props.filters));
		}
	}, [props.filters, loadedEventSlug]);

	useEffect(() => {
		if (props.selectedSection) {
			window.Seatics.MapComponent.toggleSelectionByLegendItem(props.selectedSection);
		}
	}, [props.selectedSection]);

	// Highlighted ticket effect
	useEffect(() => {
		if (loadedEventSlug && highlightedTicket) {
			window.Seatics.MapComponent.highlightTicket(highlightedTicket);
		}
		if (loadedEventSlug && !highlightedTicket) {
			window.Seatics.MapComponent.removeHighlight();
		}
	}, [highlightedTicket, loadedEventSlug]);

	// Highlighted section effect
	useEffect(() => {
		if (loadedEventSlug && highlightedSection) {
			window.Seatics.MapComponent.highlightLegendItem(highlightedSection);
		}
		if (loadedEventSlug && !highlightedSection) {
			window.Seatics.MapComponent.removeHighlight();
		}
	}, [highlightedSection, loadedEventSlug]);

	// Seatics Timeout
	useEffect(() => {
		if (seaticsTimeout && tickets) {
			props.onUpdateVisibleTickets(getTicketSegmentsFromRawTickets(tickets));
		}
	}, [seaticsTimeout, tickets]);

	//Selectors
	const noMapFound = Array.isArray(mapData) && (mapData[0] === null || !mapData[0].mapImage);
	return (
		<>
			<MapContainer visible={props.showMap} style={props.style}>
				{
					//Note: This switch statement is implemented in order to display a "custom map" for the "Conservation Game" event (the event has an id of 202238). If the event id is 202238 then it displays the custom map elements, otherwise it displays the normal Seatics map.
					(() => {
						switch (eventData?.id) {
							case 812902:
								return <CustomMapImage src={usa_vs_mexico} />;
							case 661459:
								return <CustomMapImage src={dj_sof} />;
							case 202238:
							case 209086:
								return <CustomMapImage src={conservation_game} />;
							case 223913:
								return <CustomMapImage src={on_any_sunday} />;
							case 327675:
								return <CustomMapImage src={shaqs_fun_house} />;
							case 467188:
								return <CustomMapImage src={vanyersports_x_si_tix_ufc} />;
							case 516480:
								return <CustomMapImage src={tailgate_one} noShadow />;
							case 526403:
							case 535408:
							case 535410:
							case 535413:
							case 535420:
							case 535421:
							case 515790:
							case 526402:
								return <CustomMapImage src={tailgate_two} noShadow />;
							case 515792:
							case 526400:
							case 526405:
							case 535412:
							case 535417:
							case 535419:
							case 535424:
								return <CustomMapImage src={tailgate_three} noShadow />;
							case 515788:
							case 526401:
							case 526406:
							case 535409:
							case 535411:
							case 535415:
							case 535418:
							case 535425:
								return <CustomMapImage src={tailgate_four} noShadow />;
							case 529950:
								return <CustomMapImage src={tailgate_five} noShadow />;
							case 515785:
								return <CustomMapImage src={tailgate_five} noShadow />;
							case 526404:
							case 535407:
							case 535414:
							case 535416:
							case 535422:
							case 516481:
							case 535423:
								return <CustomMapImage src={tailgate_six} noShadow />;
							case 655002:
							case 671523:
								return <CustomMapImage src={tailgate_seven} noShadow />;
							default:
								return (
									<>
										<Map className={"bootstrap-scope" + (noMapFound ? " no-map" : "")} ref={eventData?.id === siThePartyEventId ? undefined : containerRef} maximized onClick={props.onMapClicked && props.onMapClicked}>
											{eventData?.id === siThePartyEventId && (
												<CustomMapImage
													css={`
														background: #fafafa;
														@media ${eventPageMobileMediaQuery} {
															& > img {
																max-width: 100% !important;
																max-height: 100% !important;
																box-shadow: none !important;
															}
														}
													`}
													src={si_the_party}
												/>
											)}
										</Map>
										{seaticsTimeout && eventData?.noMapImageUrl && <TimeoutImage src={Config.getResourceUrl(eventData.noMapImageUrl)} alt="Preview not available" />}
										{showToggleMapButtons && props.showMap && (
											<HideMapButton onClick={() => props.setShowMap(false)}>
												<img src={hide_map_icon} alt="" style={{ marginRight: "10px" }} />
												Hide Map
											</HideMapButton>
										)}
									</>
								);
						}
					})()
				}
			</MapContainer>
			{showToggleMapButtons && (
				<ShowMapButton
					visible={!props.showMap}
					onClick={() => {
						props.setShowMap(true);
						props.setShowMapClicked(true);
					}}
				>
					<img src={show_map_icon} alt="" style={{ marginRight: "10px" }} />
					Show Map
				</ShowMapButton>
			)}
		</>
	);
});

const configureCustomFilters = () => {
	window.Seatics.FilterOptionExtensions.copy = function (fromFilterObj: any, toFilterObj: any) {
		toFilterObj.deliveryMethod = fromFilterObj.deliveryMethod || [];
	};
	window.Seatics.FilterOptionExtensions.filter = function (filterObj: any, ticketGrp: any) {
		if (Array.isArray(filterObj.deliveryMethod)) {
			return filterObj.deliveryMethod.length === 0 || filterObj.deliveryMethod.some((method: any) => ticketGrp.tgClientData.tgDeliveryMethods?.includes(method));
		} else if (typeof filterObj.deliveryMethod === "string") {
			return ticketGrp.tgClientData.tgDeliveryMethods?.includes(filterObj.deliveryMethod);
		}
		return true;
	};
};

/**
 * Adapts custom filters to seatics filters object
 */
const getSeaticFiltersFromCustom = (filters: EventSearchParams) => {
	let options = new window.Seatics.FilterOptions();

	//above this value minQuantity is used
	const HigherValue = Math.max(...Consts.TICKET_QUANTITY_OPTIONS.map(ticket => parseInt(ticket.value)), 0);

	if (filters.quantity) {
		if (filters.quantity < HigherValue) {
			options.quantity = filters.quantity;
		} else {
			options.minQuantity = filters.quantity;
		}
	}
	if (filters.minPrice != null && !isNaN(filters.minPrice as any)) {
		options.minPrice = filters.minPrice;
	}

	if (filters.maxPrice != null && !isNaN(filters.maxPrice as any)) {
		options.maxPrice = filters.maxPrice;
	}

	(options as any).deliveryMethod = filters.delivery;

	switch (filters.sort) {
		case TicketSortOptions.SectionAsc:
			options.sortType = window.Seatics.SortOptions.SectionAsc;
			break;
		case TicketSortOptions.SectionDesc:
			options.sortType = window.Seatics.SortOptions.SectionDesc;
			break;
		case TicketSortOptions.PriceDesc:
			options.sortType = window.Seatics.SortOptions.PriceDesc;
			break;
		case TicketSortOptions.PriceAsc:
		default:
			options.sortType = window.Seatics.SortOptions.PriceAsc;
			break;
	}

	return options;
};

const getTicketSegmentsFromRawTickets = (tickets: TicketResponse): SeaticsTicketSegment[] => {
	let ticketGroups: SeaticsTicketGroup[] = [];

	if (!tickets) {
		return [];
	}

	ticketGroups = tickets.map(
		ticket =>
			({
				tgID: ticket.tgID,
				tgColor: "",
				tgCanonSec: "",
				getSectionDisplayName: () => "",
				getRowDisplayName: () => "",
				splits: ticket.tgSplits,
				tgQty: ticket.tgQty,
				tgPrice: ticket.tgPrice,
				tgTransactionFeeRate: 0.15,
				tgNotes: ticket.tgNotes,
				tgDlvOpt: {
					email: false,
					instantDownload: false,
					localPickup: false,
					mobileEntry: false,
					paperless: false,
				},
				tgClientData: {
					tgDeliveryMethods: ticket.tgDeliveryMethods,
					tgSplits: ticket.tgSplits,
					expectedShipByDate: ticket.expectedShipByDate,
				},
			} as any)
	);

	return [
		{
			type: 1,
			tickets: ticketGroups,
		},
	];
};
