import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Button } from "../NewButton/Button";
import lock_icon from "../../resource/img/icons/lock_icon.svg";
import { useCheckout } from "../../../services/hooks/useCheckout";
import { postCartCheckout } from "../../../services/cart";
import { useCart } from "../../../services/hooks";
import { useBraintree } from "../../../services/hooks/useBraintree";
import { Spinner } from "../Loader/Spinner";
import { useHistory } from "react-router-dom";
import { InternalRoutes } from "../../Link";
import toast, { Toaster } from "react-hot-toast";
import { useToastRemoval } from "../../hooks/useToastRemoval";
import { PayPalButton } from "./PayPalButton";
import { getAndDispatchBraintreeClientToken, getAndDispatchBraintreePaymentMethods } from "../../../services/braintree";
import { getTypographyStyles, Typography } from "../Typography/Typography";
import { getAppColor } from "../../util/appColors";
import { FullScreenLoader } from "../Loader";
import { useUser } from "../../../services/hooks/useUser";
import { store } from "../../../store/store";
import { updateUserPhoneNumber } from "../../../store/reducers/userReducer";
import { useDispatch } from "react-redux";
import { postPushlyPurchase } from "../../util/pushlyService";

declare global {
	interface Window {
		RISKX: any;
	}
}

export const PlaceOrderButton = ({ guestFormValid, paidInFullWithWallet }: { guestFormValid?: boolean, paidInFullWithWallet: boolean}) => {
	const checkoutState = useCheckout();
	const { cart, ticket, cartPrices, futures, delivery, cartIsFree } = useCart();
	const { braintreeState } = useBraintree();
	const history = useHistory();
	const dispatch = useDispatch();

	useToastRemoval();
	const [loading, setLoading] = useState(false);

	const user = useUser();

	//TODO: Consider finding a way to reuse the types for the below params from postCartCheckout.
	const placeOrder = async (walletFundsUsed: number, paymentKeyType?: "token" | "nonce" | null, paymentKey?: string | null, discountCode?: string) => {
		setLoading(true);
		const state = store.getState();
		const event = state.transient.event;
		const guest = state.persistent.guest; //guest fname and lname state must be obtained this way rather than through a hook because for paypal it will otherwise be undefined upon calling this function
		if (cart && braintreeState.clientAuth && cartPrices && (cartPrices.PromoWallet || cartIsFree)) {
			let totalPrice = checkoutState.discount?.newTotal ? checkoutState.discount.newTotal : cartPrices[checkoutState.walletFundsUsed ? "PromoWallet" : checkoutState.selectedPaymentMethodOption?.type!] || 0;
			const totalTax = checkoutState.totalTax?checkoutState.totalTax:0;
			// Add tax amount in total if Promo Wallet Selected and totalTax value is set in checkoutState
			if (checkoutState.walletFundsUsed > 0) {
				totalPrice = totalPrice + totalTax;
			}
			
			postCartCheckout(
				{
					sessionId: cart.sessionId,
					promoWalletFundsUsed: walletFundsUsed,
					totalPrice,
					totalTax
				},
				{
					paymentKeyType,
					ticket,
					futures,
					deliveryMethod: delivery?.method,
					paymentKey,
					firstName: guest?.fname,
					lastName: guest?.lname,
					discountCode,
				}
			)
				?.then(result => {
					if (user && !user.phone && checkoutState.phoneNumber) {
						dispatch(updateUserPhoneNumber(checkoutState.phoneNumber));
					}
					setLoading(false);
					if (paymentKeyType === "nonce") {
						getAndDispatchBraintreeClientToken();
						getAndDispatchBraintreePaymentMethods();
					}
					if (event && event.eventData && ticket) {
						postPushlyPurchase(ticket, event, result.data.orderId, totalPrice);
					}
					history.push(InternalRoutes.CheckoutThanks(String(result.data.orderId)));
				})
				.catch(error => {
					toast.error(error.error?.message, {
						duration: 5000,
					});
					setLoading(false);
					return;
				});
		} else {
			setLoading(false);
		}
	};

	const [disabled, setDisabled] = useState(true);

	useEffect(() => {
		setDisabled(
			(guestFormValid === false || !checkoutState.agreedToTermsAndPrivacy || (!cartIsFree && !checkoutState.paymentMethod && checkoutState.newPaymentMethodType !== "PayPalAccount") && !paidInFullWithWallet));
	}, [checkoutState.agreedToTermsAndPrivacy, checkoutState.paymentMethod, paidInFullWithWallet, guestFormValid]);

	return (
		<>
			<Toaster position="bottom-center" />
			<FullScreenLoader show={loading} />
			{checkoutState.paymentMethod?.type === "PayPalAccount" || checkoutState.newPaymentMethodType === "PayPalAccount" ? (
				<>
					<CallToAction>Place Order:</CallToAction>
					{cartPrices?.PayPalAccount && (
						<div
							style={{ width: "100%" }}
							onClick={() => {
								if (disabled) toast.error("Please fill out all required fields.");
							}}
						>
							<PayPalButton
								style={{
									...(disabled && {
										filter: "grayscale(100%)",
										opacity: 0.75,
										pointerEvents: "none",
									}),
								}}
								flow="checkoutWithVault"
								amount={cartPrices.PayPalAccount}
								paymentTokenized={tokenizePayload => {
									placeOrder(checkoutState.walletFundsUsed, "nonce", tokenizePayload.nonce, checkoutState.discount?.code);
								}}
							/>
						</div>
					)}
				</>
			) : (
				<div
					css={`
						max-width: 300px;
						width: 100%;
					`}
					onClick={() => {
						if (disabled) toast.error("Please fill out all required fields.");
					}}
				>
					<StandardButton
						size="large"
						css={`
							visibility: ${loading ? "hidden" : "initial"};
							& > * {
								display: flex;
								align-items: center;
								justify-content: center;
							}
						`}
						disabled={disabled || loading}
						onClick={() => {
							if (checkoutState.paymentMethod?.token && !paidInFullWithWallet) {
								placeOrder(checkoutState.walletFundsUsed, "token", checkoutState.paymentMethod.token, checkoutState.discount?.code);
							} else if (paidInFullWithWallet || cartIsFree) {
								placeOrder(checkoutState.walletFundsUsed, null, null, checkoutState.discount?.code);
							}
						}}
					>
						{loading ? (
							<Spinner />
						) : (
							<>
								<img
									src={lock_icon}
									alt="Secure Lock Icon"
									css={`
										max-height: 16px;
										margin-right: 10px;
										margin-bottom: 2px;
									`}
								/>
								<Typography
									type="bodyNormal"
									fontWeight={700}
									css={`
										color: ${getAppColor("light")} !important;
										text-transform: none;
									`}
								>
									Place Order
								</Typography>
							</>
						)}
					</StandardButton>
				</div>
			)}
			<Disclaimer>All sales are final. All orders are in USD. Tickets may be above or below face value. 100% Refund if Event is Cancelled.</Disclaimer>
		</>
	);
};

export const Disclaimer = styled.span`
	${getTypographyStyles("bodySmall")}
	margin-top: 18px;
`;

const StandardButton = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const CallToAction = styled.span`
	font-weight: bold;
	font-size: 16px;
	margin-bottom: 10px;
`;
