import React from "react";
import styled from "styled-components/macro";
import { InlineStylesProps } from "../../../model/optimizedModel/styles";
import selectArrowGray from "../../resource/image/back-arrow-dark.svg";
import info_icon from "../../resource/img/icons/info_icon.svg";
import { maxContentWidthStyles } from "../../util/maxContentWidth";
import { listingsPageMobileWidth } from "../../util/listingsPageMobileWidth";
import { EventTopMessage } from "../Event/EventTopMessage";

interface EventCheckoutHeaderProps {
	children: React.ReactNode;
	showPageInfo?: boolean;
	onBack: () => void;
	onPageInfoClick?: () => void;
	showTopMessage?: boolean;
}
export const EventCheckoutHeader = ({ children, onBack, showPageInfo, onPageInfoClick, showTopMessage, ...spreadableProps }: EventCheckoutHeaderProps & InlineStylesProps) => {
	const onInfoClick = () => {
		onPageInfoClick && onPageInfoClick();
	};

	return (
		<EventMobileHeaderWrapper showMessage={showTopMessage} {...spreadableProps}>
			{showTopMessage && <EventTopMessage message="COVID-19 Health & Safety Information" />}
			<EventMobileHeader showMessage={showTopMessage}>
				<PageHeaderBackContainer onClick={onBack}>
					<PageHeaderBack />
				</PageHeaderBackContainer>
				{children}
				{showPageInfo && <InfoIcon onClick={onInfoClick} />}
			</EventMobileHeader>
		</EventMobileHeaderWrapper>
	);
};

const mobileHeaderMessageHeight = 30;
export const mobileHeaderHeight = 50;
export const eventMobileHeaderHeight = mobileHeaderMessageHeight + mobileHeaderHeight;

const EventMobileHeaderWrapper = styled.div<{ showMessage?: boolean }>`
	height: ${props => (props.showMessage ? eventMobileHeaderHeight : mobileHeaderHeight)}px;
	@media (min-width: ${listingsPageMobileWidth}) {
		display: none;
	}
`;

const EventMobileHeader = styled.div<{ showMessage?: boolean }>`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 2;
	box-shadow: 0 2px 5px -3px #000;
	${maxContentWidthStyles}
	height: ${props => (props.showMessage ? eventMobileHeaderHeight : mobileHeaderHeight)}px;
	padding-top: ${props => (props.showMessage ? mobileHeaderMessageHeight : 0)}px;
	position: fixed;
	background: #ffffff;
`;

const PageHeaderBackContainer = styled.div`
	cursor: pointer;
	align-self: stretch;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const PageHeaderBack = styled.img.attrs(() => ({
	src: selectArrowGray,
}))`
	height: 17px;
	width: auto;
`;

const InfoIcon = styled.img.attrs(() => ({
	src: info_icon,
	alt: "",
}))`
	height: 17px;
	width: auto;
	cursor: pointer;
`;
