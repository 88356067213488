import React from "react";
import { useDispatch } from "react-redux";
import { didLogOut } from "../../../store/reducers/sessionReducer";
import { LogoutButton } from "./styles";

export const LogoutPanel = (props: { className?: string }) => {
	const dispatch = useDispatch();

	return (
		<LogoutButton 
			onClick={() => {
				dispatch(didLogOut());
			}} 
			{...props}
		>
			Log Out
		</LogoutButton>
	);
};
