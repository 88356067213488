import { either } from "fp-ts/lib/Either";
import * as t from "io-ts";

export const StringifiedNumberCodec = new t.Type<number, string, unknown>(
	"StringifiedNumberCodec",
	(u: unknown): u is number => typeof u === "number",
	(i, c) =>
		either.chain(t.string.validate(i, c), n => {
			const value = Number(n);
			if (isNaN(value)) {
				return t.failure(i, c, `Not a number: ${i}`);
			} else {
				return t.success(value);
			}
		}),
	a => a.toString()
);

export const StringifiedArrayCodec = new t.Type<number[], string, unknown>(
	"StringifiedArrayCodec",
	(u: unknown): u is number[] => Array.isArray(u),
	(i, c) =>
		either.chain(t.string.validate(i, c), n => {
			const array = JSON.parse(n);
			if (Array.isArray(array)) {
				return t.success(array);
			} else {
				return t.failure(i, c, `Not an array of numbers: ${i}`);
			}
		}),
	a => a.toString()
);

export const NulleableStringifiedNumberCodec = t.union([StringifiedNumberCodec, t.null]);
