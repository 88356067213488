import React from "react";
import styled from "styled-components/macro";
import { BaseButton, BaseButtonProps } from "./BaseButton";

const buttonPaddingSizes = {
	large: "18px 64px",
	normal: "10px 36px",
	small: "6px 16px",
} as const;

export const Button = ({
	size = "normal",
	style,
	...baseButtonProps
}: {
	size?: keyof typeof buttonPaddingSizes;
} & BaseButtonProps) => {
	return (
		<StyledBaseButton
			{...baseButtonProps}
			style={{
				padding: buttonPaddingSizes[size],
				...style,
			}}
		/>
	);
};

const StyledBaseButton = styled(BaseButton)`
	& > * {
		white-space: nowrap;
	}
`;
