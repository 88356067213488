import React, { CSSProperties } from "react";
import styled, { css } from "styled-components/macro";
import { Link } from "../../Link";
import { mediaQueries } from "../../util/mediaQueries";
import missing_image from "../../resource/images/original/missing.svg";
import remind_me from "../../resource/images/remind-me.png";
import { useHistory } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { useMemoState } from "../../hooks/useMemoState";

declare global {
	interface Window {
		dataLayer: any;
		hj: any;
	}
}

export type FeaturedItemData = {
	image: string | null;
	title: string | undefined;
	titleLink: string;
	subtitle: string | undefined;
	subtitleLink?: string;
	body?: JSX.Element | string;
	bodyLink?: string;
	selected?: boolean;
};

export interface FeaturedItemProps {
	selected?: boolean;
	data?: FeaturedItemData;
	useFallback?: boolean;
	notAvailable?: boolean;
	onReminderClick?: (eventId: number) => void;
	altTitleLink?: string;
	body?: JSX.Element;
	image?: string;
	id?: number;
	itemText?: any;
	rounded?: boolean;
	containerTitle?: string;
	onItemClick?: (item: string, containerTitle: string) => void;
	notSelectedOverlay?: boolean;
	hideTitle?: boolean | false;
}

export const FeaturedItemBase = ({ altTitleLink, data, useFallback, notAvailable, onReminderClick, body, image, id, itemText, containerTitle, rounded, onItemClick, selected, notSelectedOverlay, hideTitle }: FeaturedItemProps) => {
	const reminderClick = (e: any) => {
		if (onReminderClick) {
			if (notAvailable && id) {
				e.preventDefault();
				onReminderClick(id);
			}
		}
		if (onItemClick) onItemClick(data?.title ? data?.title : "", containerTitle ? containerTitle : "");
	};

	const replaceOutOfFrameTime = (date: JSX.Element | string | undefined) => {
		if (date && typeof date == "string") {
			if (date.includes("3:30 AM")) {
				return date.replace("3:30 AM", "TBD").replace("3:30", "TBD");
			}
			if (date.includes("0:00") && !date.includes("10:00") && !date.includes("20:00")) {
				return date.replace("0:00 AM", "TBD").replace("0:00", "TBD");
			}
			if (date.includes("12:00 AM")) {
				return date.replace("12:00 AM", "TBD");
			}
		}
		return date;
	};

	return (
		<ItemContainer>
			<Link to={data?.titleLink ? data?.titleLink : altTitleLink ? altTitleLink : ""} onClick={reminderClick}>
				<ItemImage source={data?.image ? data?.image : image ? image : ""} useFallback={useFallback} remindMeOverlay={notAvailable} selected={data?.selected || selected} rounded={rounded} notSelectedOverlay={notSelectedOverlay}>
					{itemText && <ItemText>{itemText}</ItemText>}
				</ItemImage>
			</Link>
			{data?.title && !hideTitle && (
				<ItemTitle to={data?.titleLink ? data?.titleLink : altTitleLink ? altTitleLink : ""} onClick={reminderClick}>
					{data?.title}
				</ItemTitle>
			)}
			{data?.subtitle && (
				<ItemSubtitle to={data.subtitleLink ? data.subtitleLink : data?.titleLink ? data?.titleLink : altTitleLink ? altTitleLink : ""} onClick={notAvailable ? reminderClick : () => null}>
					{data?.subtitle}
				</ItemSubtitle>
			)}
			{(data?.body ?? body) && (
				<ItemSubtitle to={data?.bodyLink ? data?.bodyLink : data?.titleLink ? data?.titleLink : altTitleLink ? altTitleLink : ""} onClick={notAvailable ? reminderClick : () => null}>
					{replaceOutOfFrameTime(data?.body) ?? body}
				</ItemSubtitle>
			)}
		</ItemContainer>
	);
};

export const FeaturedActionItem = (props: { title: string; link: string; background: string }) => (
	<ItemContainer>
		<Link to={props.link}>
			<ItemImageContainer style={{ background: props.background }} selected={false} rounded={false}>
				<ActionItemText>{props.title}</ActionItemText>
			</ItemImageContainer>
		</Link>
	</ItemContainer>
);

export const FeaturedItem = (props: FeaturedItemProps) => {
	const history = useHistory();

	const onItemClick = (item: string, containerTitle: string) => {
		let featuredItem = item ? item : "";
		const featuredItemType = containerTitle ? containerTitle : "";
		let featuredItemLocation = "";

		switch (history.location.pathname) {
			case "/":
				featuredItemLocation = "Home Page";
				break;
			case "/sport/sports":
				featuredItemLocation = "Sports Category";
				break;
			case "/category/concerts":
				featuredItemLocation = "Concerts Category";
				break;
			case "/category/theater":
				featuredItemLocation = "Theater Category";
				break;
			case "/futures":
				featuredItemLocation = "Futures Category";
				break;
			case "/early-access":
				featuredItemLocation = "Early Access Category";
				break;
			case "/popular":
				featuredItemLocation = "Popular";
				featuredItem = "View All";
				break;
			case "/discover":
				featuredItemLocation = "Discover";
				featuredItem = "View All";
				break;
			case "/category/virtual-experience":
				featuredItemLocation = "Virtual Experience Category";
				featuredItem = "View All";
				break;
			default:
				featuredItemLocation = "Bet Category";
		}
		mixpanel.track("Featured Item Click", {
			"Featured Item": featuredItem,
			"Featured Item Type": featuredItemType,
			"Featured Item Location": featuredItemLocation,
		});

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "GAE",
			eC: `Featured Item Clicks - ${featuredItemLocation}`,
			eA: featuredItemType,
			eL: featuredItem,
		});

		window.hj =
			window.hj ||
			function () {
				(window.hj.q = window.hj.q || []).push(arguments);
			};
		window.hj("tagRecording", ["Featured Item Clicks", "Featured Item Clicks - Home Page"]);
	};

	return <FeaturedItemBase onItemClick={onItemClick} {...props} />;
};

const itemContainerMaxWidth = 230;

const itemContainerWidth = (width: number) => css`
	min-width: min(${width}%, ${itemContainerMaxWidth}px);
	max-width: min(${width}%, ${itemContainerMaxWidth}px);
`;

const ItemContainer = styled.div`
	display: inline-block;
	box-sizing: border-box;
	vertical-align: top;

	a {
		/*Prevent black overlay on iOS*/
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	${itemContainerWidth(25)}
	padding-right:16px;
	box-sizing: content-box;

	@media ${mediaQueries.max670} {
		${itemContainerWidth(44)}
	}
`;

export const itemImageOverlayOpacity = "0";

const ItemImage = (props: { source: string; remindMeOverlay?: boolean; useFallback?: boolean; children?: JSX.Element; selected?: boolean; rounded?: boolean; notSelectedOverlay?: boolean }) => {
	let backgroundImages = [missing_image];
	if (!props.useFallback) {
		backgroundImages.pop();
	}
	backgroundImages.push(props.source);
	if (props.remindMeOverlay) {
		backgroundImages.unshift(remind_me);
	}
	const showNotSelectedOverlay = useMemoState(() => {
		const value = !props.selected && props.notSelectedOverlay;
		return value;
	}, [props.selected, props.notSelectedOverlay]);
	backgroundImages = backgroundImages.map(image => `url(${image})`);
	const backgroundImage = backgroundImages.join(", ");
	const style: CSSProperties = {};
	if (props.children || showNotSelectedOverlay) {
		style.background = `linear-gradient(rgba(0, 0, 0, ${itemImageOverlayOpacity}), rgba(0, 0, 0, ${showNotSelectedOverlay ? 1 : itemImageOverlayOpacity})), url("${props.source}")`;
	} else {
		style.backgroundImage = backgroundImage;
	}
	let roundedBorders = typeof props.rounded === "undefined" ? true : props.rounded;

	return (
		<ItemImageContainer style={style} selected={props.selected} rounded={roundedBorders}>
			{props.children}
		</ItemImageContainer>
	);
};

const itemImageHeight = 56.62;

export const ItemImageContainer = styled.div<{ selected?: boolean; rounded?: boolean }>`
	background-size: cover !important;
	background-position: center !important;
	background-repeat: no-repeat !important;
	width: 100%;
	padding-top: min(${itemImageHeight}%, ${itemImageHeight * 0.01 * itemContainerMaxWidth}px);
	height: auto;
	border-radius: ${props => (props.rounded ? "15px" : "0")};
	position: relative;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.26);
	transition: 0.2s ease box-shadow;
	&:hover {
		box-shadow: ${props => (props.selected ? "0px 5px 10px 0px rgba(0, 0, 0, 0.5)" : "0px 5px 10px 0px rgba(0, 0, 0, 0.26)")};
	}
	${props => (props.selected ? "box-shadow:0px 5px 10px 0px rgba(0, 0, 0, 0.5);border: 3px solid #e92224;" : "")}
`;

export const ItemText = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	font-family: Montserrat, sans-serif;
	font-weight: bold;
	text-align: left;
	white-space: break-spaces;
	color: #fff;
	padding: 11.2% 7%;
	font-size: 1.08rem;
	line-height: 117%;
	text-overflow: ellipsis;
	overflow: hidden;
	@media ${mediaQueries.max670} {
		font-size: 3vw;
	}
`;

const ItemTitle = styled(Link)`
	display: block;
	text-align: left;
	font-family: Montserrat;
	font-size: 1rem;
	line-height: normal;
	color: #12284b;
	font-weight: bold;
	margin-top: 0.875rem;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	@media ${mediaQueries.max480} {
		font-size: 13px;
	}
`;

const ItemSubtitle = styled(Link)`
	display: block;
	text-align: left;
	margin-top: 0.0625rem;
	font-size: 0.9rem;
	line-height: normal;
	color: black;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-weight: 500;
	@media ${mediaQueries.max480} {
		font-size: 11px;
		margin-top: 1px;
	}
	& > b {
		font-weight: bold;
	}
`;

const ActionItemText = styled.p`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 700;
	letter-spacing: 0em;
	text-transform: uppercase;
	color: white;

	font-size: 21px;
	@media ${mediaQueries.max480} {
		font-size: 16px;
	}
`;
