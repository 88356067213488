import React, { useState } from "react";
import { CommonPage } from "../components";
import { maxContentWidthStyles } from "../util/maxContentWidth";
import { mediaQueries } from "../util/mediaQueries";
import styled from "styled-components/macro";
import { RouteComponentProps } from "react-router";
import { MetaDecorator, MetaTagData } from "../components/SEOMetadecorator/MetaDecorator";
import { CollapseBehavior, EducationalRow } from "../components/Futures/EducationalPanel";
import { Nulleable } from "../../model/Utils";

const fullwidth = false;
const collapseBehavior: CollapseBehavior = "header";

export const PrivacyRoute = (props: RouteComponentProps) => {
	const [selected, setSelection] = useState<Nulleable<string>>(null);
	const sectionProps = { selected, setSelection, fullwidth, collapseBehavior };

	return (
		<CommonPage isSitixLogoSelected={true} background="#fff">
			<MetaDecorator title={MetaTagData.Privacy.title} description={MetaTagData.Privacy.description} />
			<PageContainer>
				<Section>
					<Title>Privacy Policy</Title>
					<Date>Effective as of December 12, 2022</Date>
					<Text>SI Tickets is committed to your privacy. This Privacy Policy explains our collection, use, disclosure, retention, and protection of your personal information.</Text>

					<EducationalSectionContainer fullwidth={fullwidth}>
						<EducationalRow title="1.	Scope" {...sectionProps}>
							<ItemContent>
								<ItemText>This Privacy Policy applies to any SI Tickets website, application, service, or tool (collectively "Services") where this Privacy Policy is referenced, regardless of how you access or use them, including through mobile devices. This Privacy Policy describes the type of information we may collect from you or that you may provide when you visit the SI Tickets’ website or make use of any SI Tickets’ services.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemText>This Privacy Policy also applies to the provision of SI Tickets services through any SI Tickets partner's website, application, service, or tool where it is referenced and/or where your listings and their content may be published or advertised in accordance with the terms of this Privacy Policy.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemText>This Privacy Policy does not apply to information which may be collected by any third party, including, without limitation: SI Tickets’ partner’s websites, applications, services, or tools, where such information is collected solely by a third party and not disclosed to or used by SI Tickets; third party websites, applications or content (including advertising) that may link to or be accessible from or on the SI Tickets’ website or Services.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemText>SI Tickets may make changes to this Privacy Policy, and may apply any changes to information previously collected, as permitted by law. When SI Tickets makes material changes to this Policy, we will notify you by posting the updated Policy on our website, and we will update the effective date at the top of this Policy prior to the change becoming effective. By continuing to use our website after being notified of material changes to this Policy, you agree that the terms of this Policy as of the effective date will apply to information previously collected or collected in the future.</ItemText>
							</ItemContent>
						</EducationalRow>

						<EducationalRow title="2. Information We Collect" {...sectionProps}>
							<ItemContent>
								<ItemText>
									<strong>Personal Information</strong> <br />
									Personal information is information that can be used to identify you personally, such as name, postal address, e-mail address, telephone number, and social security number or tax identification number. We collect personal information from you and any devices or equipment you use when you: use our Services, register for an account with us or register for an account with one of our partners information to us through a web form or for the purposes of a promotion or drawing, update or add information to your account, participate in a community board discussion chat, or when you otherwise correspond with us. Some of this personal information is necessary to use our services [and to enter into our “Terms of Use” (defined as the Terms of Use associated with the Services a user is accessing)] The provision of other personal information is voluntary, but may facilitate use of our Services and/or optimize your experience using our Services. <br /> <br />
									We may also collect personal information from other sources, as described below. Personal information that You Provide to Us When You Use Our Services or Register for an Account. <br /> <br />
									<UnorderedList>
										<li>Identifying information such as your name, addresses, telephone numbers, email addresses, business seller information, or tax identification number when you register for an account with us.</li>
										<li>Information you provide us when creating a profile on SI Tickets, which may include a profile picture and access to your camera and/or photo library (if you choose to enable this service on your computer or device); events you will be attending or have attended based on your purchases on SI Tickets (“Orders”); your favorite artists, shows, teams, venues, genres or any other event-related interests based on your scanned music library(ies) (if you choose to enable this service on your computer or device) and anything you identify as a favorite on SI Tickets (collectively, your “Favorites”); your SI Tickets viewing history of events or artists; your friends’ identity and contact information based on the contacts in your phone’s address book (if you choose to enable this service on your computer or device) and your friend list on any linked social media or payment accounts.</li>
										<li>Information about transactions you are involved in, including buying or selling information which you provide during a transaction, or other transaction-based content that you generate or that is connected to your account.</li>
										<li>Other content that you generate, or that is connected to your account (such as viewing tickets, adding tickets to your cart or attempting checkout).</li>
										<li>Financial information (such as credit card, debit card, or PayPal or bank account information) in connection with a transaction.</li>
										<li>Postage, shipping, billing and other information used to purchase or send an item, which may include information required to clear customs (such as Tax ID or other identification numbers) and relevant postage information (such as tracking numbers and tracking updates).</li>
										<li>Demographic information and information about your interests, which may include age, gender, and favorites, where you provide that information to us while using our services.</li>
										<li>You may also provide us other information through use of web forms, by updating or adding information to your account, through your participation in community discussions, chats, dispute resolution, or referral services, or when you otherwise communicate with us.</li>
										<li>Recordings of telephone communications, provided that consent has been obtained for recording in accordance with applicable laws;</li>
										<li>Additional information which we are required or authorized by applicable national laws to collect and process in order to authenticate or identify you or to verify the information we have collected.</li>
									</UnorderedList>
								</ItemText>
							</ItemContent>

							<ItemContent>
								<ItemText>
									<strong>Personal Information We Collect Automatically When You Use Our Services or Register for an Account</strong> <br />
									Information about your interaction with and use of our Services, your advertising preferences, and your communications with us. We receive this information from your computer or other devices (including mobile devices) you use when you access our Services. This information may include the following:
									<UnorderedList>
										<li>Location information, including location information from your mobile device. You can control or disable the use of location services on your mobile device in the device's settings menu.</li>
										<li>Information about your equipment, devices and internet connection. This may include statistics on your page views, traffic to and from the sites, referral URL, ad data, your IP address, your browsing history, web log information, Device ID or unique identifier, device type, ID for advertising, and unique device token.</li>
									</UnorderedList>
								</ItemText>
							</ItemContent>

							<ItemContent>
								<ItemText>
									<strong>Personal Information We Collect Automatically Using Cookies and Similar Technologies</strong> <br />
									<UnorderedList>
										<li>We use cookies, web beacons, unique identifiers, and similar technologies to collect information about the pages you view, the links you click, and other actions you take when using our Services, within our advertising or email content.</li>
										<li>For more information about our use of these technologies, and how to control them, see Cookies, Web Beacons and Similar Technologies (alternatively referred to as Cookie Notice).</li>
									</UnorderedList>
								</ItemText>
							</ItemContent>

							<ItemContent>
								<ItemText>
									<strong>Personal Information Collected From Other Sources</strong> <br />
									<UnorderedList>
										<li>We supplement the personal information we collect directly with information collected from third parties and add it to your account information. For example, we collect and use demographic and other information that is publicly available in an applicable jurisdiction, or additional contact information as allowed by applicable national laws.</li>
										<li>We allow you to share information with social media sites, or use social media sites to create your account or to connect your account with the respective social media site. Those social media sites may give us automatic access to certain personal information retained by them about you (e.g., content viewed by you, content liked by you, and information about the advertisements you have been shown or have clicked on, etc.). If you provide us with access to any site with video content, then you agree that we can share your video viewing with, or obtain information about your video viewing from, third-party social media sites for at least two years or until you withdraw authorization or connection to the social media site. You control the personal information you allow us to have access to through the privacy settings on the applicable social media site and the permissions you give us when you grant us access to the personal information retained by the respective social media site about you. By associating an account managed by a social media site with your account and authorizing us to have access to this information, you agree that we can collect, use and retain the information provided by these social media sites in accordance with this Privacy Policy. We may also use plug-ins or other technologies from various social media sites. If you click on a link provided via a social media plug in, you are voluntarily establishing a connection with that respective social media site.</li>
										<li>If you give us personal information about someone else, you must do so only with that person’s authorization. You agree to inform them how we collect, use, disclose, and retain their personal information according to our Privacy Policy.</li>
									</UnorderedList>
								</ItemText>
							</ItemContent>

							<ItemContent>
								<ItemText>
									<strong>How We Use Your Personal Information</strong>
									<br />
									<br />
									We use your personal information to provide and improve our Services, provide you with a personalized experience on our Site, contact you about your account and our Services, provide you customer service, provide you with personalized advertising and marketing, and to detect, prevent, mitigate and investigate fraudulent or illegal activities. We use the personal information we collect from you for a range of different business purposes and according to different legal bases of processing. The following is a summary of how and according to which legal bases we use your personal information. We use your personal information to fulfill a contract with you and provide you with our Services, to comply with our legal obligations, protect your vital interest, or as may be required for the public good. This includes:
									<UnorderedList>
										<li>To provide payment processing and account management, operate, measure and improve our Services, keep our Services safe, secure and operational, and customize site content that includes items and services that you may like in response to actions that you take.</li>
										<li>
											To contact you regarding your account, to troubleshoot problems with your account, to resolve a dispute, to collect fees or monies owed or as otherwise necessary to provide you customer service.
											<UnorderedList>
												<li>When contacting you for such purposes as outlined above, we may contact you via email, telephone, SMS/text messages, postal mail, and via mobile push notifications.</li>
												<li>When contacting you via telephone, to ensure efficiency, we may use autodialed or pre-recorded calls and text messages as described in our Terms of Use and as authorized by applicable law. Message and data rates may apply.</li>
											</UnorderedList>
										</li>
										<li>To provide other services requested by you as described when we collect the information.</li>
										<li>We use general location information to provide you with location-based services (such as advertising, search results, and other personalized content).</li>
										<li>To prevent, detect, mitigate, and investigate fraud, security breaches or other potentially prohibited or illegal activities and/or otherwise comply with regulatory requirements.</li>
										<li>To enforce our Terms of Use, this Privacy Policy, or other policies.</li>
									</UnorderedList>
								</ItemText>
							</ItemContent>

							<ItemContent>
								<ItemText>
									We use your personal information to pursue our legitimate interests where your rights and freedoms do not outweigh these interests. We have implemented controls to balance our interests with your rights. This includes to:
									<UnorderedList>
										<li>Personalize, measure, improve our Services and develop new Services.</li>

										<li>Personalize, measure, and improve our content and advertising based on your advertising customization preferences.</li>

										<li>Contact you via email or postal mail to offer you coupons, discounts and special promotions, poll your opinions through surveys or questionnaires and inform you about our Services, as authorized by applicable law.</li>

										<li>Contact you about public policy matters, or other current events, related to your ability to use our Services. This could include an invitation to join a petition, letter writing, call or other sort of public policy related campaigns.</li>

										<li>Deliver targeted marketing, service updates, and promotional offers based on your communication preferences.</li>

										<li>Measure the performance of our email marketing campaigns (e.g. by analyzing open and click rates).</li>

										<li>Measure a sellers' performance.</li>

										<li>Monitor and improve the information security of our site and mobile applications.</li>
									</UnorderedList>
								</ItemText>
							</ItemContent>

							<ItemContent>
								<ItemText>
									With your consent, we may use your personal information to:
									<UnorderedList>
										<li>Provide you with marketing, notifications or price alerts based on your preferences via telephone calls, email, SMS or text.</li>

										<li>Provide you with marketing notifications as it relates to other SI Branded Products & Services.</li>

										<li>Provide you with marketing from third parties.</li>

										<li>Customize third party advertising you might see on third party websites.</li>

										<li>Use your precise geo-location to provide location based services.</li>

										<li>Use your sensitive personal information to facilitate transactions in certain categories.</li>
									</UnorderedList>
									<br />
									<strong>You have the right to withdraw your consent at any time.</strong>
									<br />
									We may use technologies considered automated decision making or profiling. We will not make automated-decisions about you that would significantly affect you, unless such a decision is necessary as part of a contract we have with you, we have your consent, or we are required by law to use such technology.
									<br />
									<br />
									<strong>Your Choices About How We Use Your Personal Information</strong>
									<br />
									You have choices about how we use your personal information to communicate with you, to send you marketing information, how we provide you with customized and relevant advertising, and whether you want to stay signed into your account.
									<br />
									<br />
									<strong>Marketing</strong>
									<br />
									If you do not wish to receive marketing communications from us, you can unsubscribe via the link in an email you received, We do not sell, rent, or otherwise disclose your personal information to third parties for their marketing and advertising purposes without your consent.
									<br />
									<br />
									<strong>Advertising</strong>
									<br />
									If you do not wish to participate in our advertising personalization programs, you can opt-out by following the directions provided within the applicable advertisement, or through the programs described in Cookies, Web Beacons and Similar Technologies. The effect of an opt-out will be to stop personalized advertising, but it will still allow the collection of personal information as otherwise described in this Privacy Policy. We do not allow third parties to track or collect your personal information on our sites for their own advertising purposes, without your consent.
									<br />
									<br />
									This Site is affiliated with Freestar for the purposes of placing advertising on the Site, and Freestar will collect and use certain data for advertising purposes. To learn more about Freestar's data usage, click here: <a href=" https://freestar.com/privacy-policy/" target="_blank"> https://freestar.com/privacy-policy/.</a>
									<br />
									<br />
									<strong>Signed Into Your Account</strong>
									<br />
									You or any other user of the computer/browser where you signed in on will be able to view and access your account and take certain specific actions during this signed in period without any further authorization. The specific actions and account activities that you or any other user of this computer/browser may take include:
									<UnorderedList>
										<li>View a Listing</li>

										<li>Check out or add items to your cart</li>

										<li>Add favorites</li>

										<li>View your SI Tickets Account pages</li>

										<li>View or edit Account Details</li>

										<li>Access Digital Tickets uploaded in My Account</li>
									</UnorderedList>
									You can typically end your signed in session by either signing out and/or clearing your cookies. If you have certain browser privacy settings enabled, simply closing your browser may also end your signed in session. If you are using a public or shared computer, you should sign out and/or clear your cookies when you are done using our Services to protect your account and your personal information.
									<br /> <br />
									<strong>Accessing and Correcting Your Information</strong>
									<br />
									You have certain rights to access, correct, or request deletion of your personal information, or restrict our usage of your personal information, as required by applicable law. We also take steps to ensure that the personal information we collect is accurate and up to date.
									<UnorderedList>
										<li>You have the right to know what personal information we maintain about you. Upon request, we will provide you with a copy of your personal information in a structured, commonly used and machine-readable format.</li>

										<li>If your personal information is incorrect or incomplete, you have the right to request we update it.</li>

										<li>You can also request that we delete or restrict how we use your personal information, but this right is determined by applicable law and may impact your access to some of our Services.</li>
									</UnorderedList>
									<br />
									<strong>Access, correction, and deletion of your personal information</strong>
									<br />
									You can access, review and modify most of your personal information by signing into your account. Please update your personal information immediately if it changes or is inaccurate. Keep in mind, once you make a public posting, you may not be able to change or remove it. <br />
									We will honor any statutory right you might have to access, modify or erase your personal information. To request access and to find out whether any fees may apply, if permitted by applicable national laws, please contact us via EMAIL at <a href="mailto:support@sitickets.com">support@sitickets.com</a>. Where you have a statutory right to request access or request the modification or erasure of your personal information, we can still withhold that access or decline to modify or erase your personal information in some cases in accordance with applicable national laws. <br />
									If you request that we stop processing some or all of your personal information or you withdraw (where applicable) your consent for our use or disclosure of your personal information for purposes set out in this Privacy Policy, we might not be able to provide you all of the Services and customer support offered to our users and authorized under this Privacy Policy and our Terms of Use. <br />
									Upon your request, we will close your account and remove your personal information from view as soon as reasonably possible, based on your account activity and in accordance with applicable national laws.
									<br />
									<br />
									<strong>How We Share Your Personal Information </strong>
									<br />
									We may disclose your personal information with our affiliate companies for their use to send marketing notifications about SI Branded Products & Services or to third parties as may be required for us to provide you access to our Services, to comply with our legal obligations, to enforce our Terms of Use, to facilitate our marketing and advertising activities, or to prevent, detect, mitigate, and investigate fraudulent or illegal activities related to our Services. We attempt to minimize the amount of personal information we disclose to what is directly relevant and necessary to accomplish the specified purpose. We do not sell, rent, or otherwise disclose your personal information to third parties for their marketing and advertising purposes without your consent.
									<br />
									<br />
									<strong>We may disclose your personal information to the following parties for the following purposes:</strong>
									<br />
									Our affiliate companies for their use to send marketing notifications about SI Branded Products & Services, based on our corporate legitimate interests, who may use it to:
									<UnorderedList>
										<li>Provide joint content and services (like registration, transactions, and customer support)</li>

										<li>Help detect, investigate, mitigate and prevent potentially fraudulent and illegal acts, violations of our Terms of Use, and data security breaches</li>

										<li>Personalize advertising that is shown to you</li>

										<li>Improve their products, sites, applications, services, tools, and marketing communications</li>

										<li>To send you marketing communications, provided you have consented to receive such communications from them or if otherwise permitted by the law</li>
									</UnorderedList>
									<br />
									<strong>SI Tickets Business Partners</strong>
									<br />
									<UnorderedList>
										<li>We may share certain identification data, and other personal information about your transaction that may include personal data, with business partners when such disclosure is deemed necessary in order for SI Tickets to offer you or for you to be able to use the Services. For example, we may share this kind of information with partner teams, venues, artists, leagues or other third parties involved in the event for which you sell or buy a ticket.</li>

										<li>With your consent, we may share your personal data with business partners that request it in order to provide you with a better experience when using our Services or the services of the business partner (including their independent marketing purposes subject to their privacy policies).</li>
									</UnorderedList>
									<br />
									<strong>Service Providers and financial institutions partners as follows:</strong>
									<br />
									<UnorderedList>
										<li>Third party service providers who help us to provide our Services, payment processing services, assist us in providing customized advertising, to assist us with the prevention, detection, mitigation, and investigation of potentially illegal acts, violations of our Terms of Use, fraud and/or security breaches, bill collection, affiliate and rewards programs, co-branded credit cards and other business operations.</li>

										<li>Third party financial institutions with whom we partner to offer financial products to you, for them to provide joint content and services (such as, registration, transactions and customer support). These third party financial institution partners will use your personal information to send you marketing communications only if you have requested their services.</li>

										<li>Third party shipping providers (e.g., DHL, UPS, USPS, etc.) with whom we share delivery address, contact information and shipment tracking information for the purposes of facilitating the delivery of items purchased and other delivery related communications.</li>

										<li>Third party providers of websites, applications, services and tools that we cooperate with so that they can publish or advertise your listings and their content on their websites or in their applications, services and tools. If we transfer personal information along with the content of your listings to third party providers, this will be solely on the basis of an agreement limiting use by the third party provider of such personal information to processing necessary to fulfill their contract with us and obligating the third party provider to take security measures with regard to such data. Third party providers are not permitted to sell, lease or in any other way transfer the personal information included in your listings to third parties.</li>
									</UnorderedList>
									<br />
									<strong>Law enforcement, legal proceedings, and as authorized by law</strong>
									<br />
									<UnorderedList>
										<li>To comply with our legal requirements, enforce our Terms of Use, respond to claims that a listing or other content violates the rights of others, or protect anyone's rights, property or safety.</li>
										<li>To law enforcement or governmental agencies, or authorized third-parties, in response to a verified request or legal process relating to a criminal investigation or alleged or suspected illegal activity or any other activity that exposes us, you, or any other of our users to legal liability. We will only disclose information we deem relevant to the investigation or inquiry, such as name, city, state, postcode, telephone number, email address, User ID history, IP address, fraud complaints, and listing and sales and purchase history.</li>
										<li>To credit agencies or bureaus as authorized by applicable national laws (e.g. information on late or missed payments or other defaults on your account that may be reflected in your credit report or file).</li>

										<li>To third parties involved in a legal proceeding, if they provide us with a subpoena, court order or substantially similar legal basis, or we otherwise believe in good faith that the disclosure of information is necessary to prevent imminent physical harm or financial loss or to report suspected illegal activity.</li>

										<li>To third parties in response to a legitimate and verified legal complaint.</li>
									</UnorderedList>
									<br />
									<strong>Other SI Tickets users as authorized by you or your use of our Services</strong>
									<br />
									<UnorderedList>
										<li>When necessary, sellers may receive information about you necessary to complete the transaction, to provide box office services or to ensure you access to the venue, such as your name, email address, contact details, shipping and billing address, or other information. Buyers may see information about the seller in certain cases, e.g. where included as a return address on the ticket shipment.</li>
										<li>The seller or buyer receiving your information may only use it for purposes related to the transaction. Unless you have consented to receive marketing from them, they should not contact you for marketing purposes.</li>
									</UnorderedList>
									<br />
									<strong>Other SI Tickets users as authorized by you or your use of our Services</strong>
									<br />
									<UnorderedList>
										<li>To prevent, detect, mitigate, and investigate potentially illegal acts, fraud and/or security breaches, and to assess and manage risk, including to alert you if fraudulent activities have been detected on your SI Tickets or PayPal accounts</li>
										<li>To provide customer services, including to help service your account or resolve disputes (e.g., billing or transactional disputes)</li>
										<li>To facilitate the processing of payment cards when you pay within our Services with a payment card and we use PayPal to process your payment</li>
										<li>To facilitate shipping and related services for purchases you made using PayPal</li>
									</UnorderedList>
									<br />
									<strong>Change of Ownership</strong>
									<br />
									If we are subject to a merger or acquisition with/by another company, we may share information with them. Should such an event occur, we will require that the new combined entity follow this Privacy Policy with respect to your personal information. If we intend to handle your personal information for any purposes not covered in this Privacy Policy, you will receive prior notification of the processing of your personal information for the new purposes.
									<br />
									<br />
									<strong>How Long We Keep Your Personal Information</strong>
									<br />
									We retain your personal information for as long as necessary to provide the Services you have requested, or for other essential purposes such as complying with our legal obligations, resolving disputes, and enforcing our policies. <br />
									Our specific retention times for personal information are documented. How long we retain personal information can vary significantly based on context of the Services we provide and on our legal obligations. <br />
									The following factors are used in determining the retention period:
									<UnorderedList>
										<li>How long the personal information needed to provide our Services, including for the purpose of maintaining and improving the performance of our products, keeping our systems secure, and maintaining appropriate business and financial records.</li>

										<li>The sensitivity of the personal information. More sensitive personal information may be retained for a shorter time period where possible.</li>

										<li>The scope of your consent to retain your personal information. We will retain data in accordance with your consent.</li>

										<li>Legal, contractual, or other similar obligation to retain your personal information, such as mandatory data retention laws in applicable jurisdictions, government orders to preserve data relevant to an investigation, or personal information retained for the purposes of litigation.</li>
									</UnorderedList>
									After it is no longer necessary for us to retain your personal information, we will dispose of it in a secure manner according to our data retention and deletion policies.
								</ItemText>
							</ItemContent>
						</EducationalRow>

						<EducationalRow title="3. Cookies and Similar Technologies" {...sectionProps}>
							<ItemContent>
								<ItemText>
									When you visit or interact with our sites, services, applications, tools or messaging, we or our authorized service providers may use cookies, web beacons and other similar technologies for storing information to help provide you with a better, faster and safer experience, and for advertising purposes. You can read our Cookie Notice . for more information. <br /> <br />
									<strong>How We Protect Your Personal Information</strong> <br /> <br />
									We protect your personal information using technical and administrative security measures to reduce the risks of loss, misuse, unauthorized access, disclosure and alteration. Some of the safeguards we use are firewalls and data encryption, physical access controls to our data centers, and information access authorization controls. For more information about staying safe while buying and selling online, or to report an issue with your account please visit our Security Center. <br />
									Your data controller for the Services is as follows:
									<UnorderedList>
										<li>If you reside within the United States your data controller is SI Tickets.</li>
									</UnorderedList>
									Your data controller is responsible for the collection, use, disclosure, retention and protection of your personal information in accordance with this Privacy Policy, as well as any applicable national laws. <br />
									Your data controller may transfer data to affiliates of SI Tickets as described in this Privacy Policy. We may process and retain your personal information on our servers in the U.S. and elsewhere in the world where our data centers are located. If you are located within the European Union, SI Tickets has adopted appropriate safeguards to ensure that your data is processed in accordance with applicable legal requirements. You can request further information about these safeguards by contacting us through the Contact Us section below. <br />
									Where we have a legal obligation to do so, we have appointed data protection officers (DPOs) to be responsible for the privacy program at each of the respective data controllers. You can contact any of our DPOs via the Contact Us information detailed below in this Privacy Policy. <br /> <br />
									<strong>Other Important Privacy Information</strong> <br /> <br />
									This section describes some additional privacy information related to your use of our Services that you may find important. Your responsibilities over transactional information you receive through SI Tickets <br />
									For certain transactions, we may provide you with the personal information of the other user (such as their name, email address, contact details and transaction data) to complete the transaction or enable entry to a venue. Independent from us, you are the controller of such data and we encourage you to inform the other user about your privacy practices and respect their privacy. In all cases, you must comply with the applicable privacy laws, and must give the other user a chance to remove them from your database and them a chance to review what information you have collected about them. <br />
									You may use the personal information that you have access to only for SI Tickets transaction-related purposes, or for other services offered through SI Tickets, and for purposes expressly consented by the user to whom the information relates. Using personal information of other users that you have access to for any other purpose constitutes a violation of our Terms of Use. <br /> <br />
									<strong>Unwanted or threatening email</strong> <br /> <br />
									We do not tolerate abuse of our Services. You do not have permission to add other users to your mailing list (email or postal), call, or send him/her text messages for commercial purposes, even if this user purchased something from you, unless the user has given his/her explicit consent. Sending unwanted or threatening email and text messages is against our Terms of Use. To report SI Tickets-related spam or spoof emails please forward the email to <a href="mailto:support@sitickets.com">support@sitickets.com</a>
									<br /> <br />
									<strong>Communication tools </strong>
									<br /> <br />
									We may scan messages automatically and check for spam, viruses, phishing and other malicious activity, illegal or prohibited content or violations of our Terms of Use, this Privacy Policy or our other policies. <br />
									<br />
									<strong>Children's privacy </strong>
									<br />
									<br />
									Our websites are general audience websites and not intended for children. We do not knowingly collect personal information from users deemed to be children under their respective national laws. <br />
									<br />
									<strong>Third-party privacy practices</strong> <br />
									<br />
									This Privacy Policy addresses only our use and handling of personal information we collect from you in connection with providing you our Services. If you disclose your information to a third party, or visit a third-party website via a link from our Services, their Privacy Policys and practices will apply to any personal information you provide to them or they collect from you. <br /> <br />
									We cannot guarantee the privacy or security of your personal information once you provide it to a third party and we encourage you to evaluate the privacy and security policies of your trading partner before entering into a transaction and choosing to share your personal information. This is true even where the third parties to whom you disclose personal information are bidders, buyers or sellers on our site. <br /> <br />
									CALIFORNIA CONSUMER PRIVACY ACT <br />
									PRIVACY POLICY <br />
									Pursuant to the California Consumer Privacy Act of 2018 (“CCPA”), SI Ticketsis providing the following details regarding the categories of Personal Information that we collect, use, and disclose about California residents. This CCPA Privacy Policy does not apply to our job applicants, employees, contractors, owners, directors, or officers where the Personal Information we collect about those individuals relates to their current, former, or potential role with us. Under the CCPA, “Personal Information” is information that identifies, relates to, or could reasonably be linked with a particular California resident or household.
									<br />
									<br />
									<strong>No Sale of Personal Information</strong>
									<br />
									We do not sell Personal Information and have not sold Personal Information within the preceding 12 months, as “sale” is defined in the CCPA. We do not sell the Personal Information of minors under age 16. <br />
									<br />
									<strong>Collection and Disclosstrongre of Personal Information</strong>
									<br />
									The following chart details which categories of Personal Information we plan to collect, as well as the categories of Personal Information we have collected and disclosed for our operational business purposes in the preceding 12 months. We collect this Personal Information from you and your devices, as well as from our corporate affiliates, publicly available databases, other SI Tickets customers, credit agencies (as allowed by applicable laws), and social media sites. <br /> <br />
									<Table>
										<TableRow>
											<TableHeader>Categories of Personal Information</TableHeader>
											<TableHeader>Disclosed to Which Categories of Third Parties for Operational Business Purposes</TableHeader>
										</TableRow>
										<TableRow>
											<TableCell>Identifiers, such as name, contact information, IP address and other online identifiers</TableCell>
											<TableCell>
												<ul>
													<li>Corporate affiliates</li>
													<li>Third-party service providers, who assist us for example with payment processing, marketing and advertising, shipping, fraud prevention, rewards programs and information technology support.</li>
													<li>Business partners, such as partner teams, venues, artists, and sports leagues, to provide our event services</li>
													<li>Credit agencies, as allowed by applicable law</li>
													<li>Legal authorities</li>
													<li>PayPal, Inc., to provide our services, facilitate your purchases and prevent fraud</li>
													<li>Financial institutions with whom we partner to offer financial products to you</li>

													<li>Other SI Tickets users as authorized by you or your use of our services</li>
												</ul>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>Personal information, as defined in the California customer records law, such as government-issued ID number (e.g., your tax identification number when you register for an account with us), and financial information such as payment card number</TableCell>
											<TableCell>
												<ul>
													<li>Corporate affiliates</li>
													<li>Third-party service providers, who assist us for example with payment processing, marketing and advertising, shipping, fraud prevention, rewards programs and information technology support;</li>
													<li>Credit agencies, as allowed by applicable law</li>
													<li>Legal authorities</li>
													<li>Payment processors, to provide our services, facilitate your purchases and prevent fraud</li>
													<li>Financial institutions with whom we partner to offer financial products to you</li>
												</ul>
											</TableCell>
										</TableRow>

										<TableRow>
											<TableCell>Characteristics of protected classifications under California or federal law, such as age and gender</TableCell>
											<TableCell>
												<ul>
													<li>Corporate affiliates</li>
													<li>Third-party service providers, who assist us for example with payment processing, marketing and advertising, shipping, fraud prevention, rewards programs and information technology support</li>
													<li>Business partners, such as partner teams, venues, artists, and sports leagues, to provide our event services</li>
													<li>Credit agencies, as allowed by applicable law</li>
												</ul>
											</TableCell>
										</TableRow>

										<TableRow>
											<TableCell>Commercial information, such as transaction information and purchase history</TableCell>
											<TableCell>
												<ul>
													<li>Corporate affiliates</li>
													<li>Third-party service providers, who assist us for example with payment processing, marketing and advertising, shipping, fraud prevention, rewards programs and information technology support</li>
													<li>Business partners, such as partner teams, venues, artists, and sports leagues, to provide our event services</li>
													<li>Credit agencies, as allowed by applicable law</li>
													<li>Legal authorities</li>
													<li>PayPal, Inc., to provide our services, facilitate your purchases and prevent fraud</li>
													<li>Financial institutions with whom we partner to offer financial products to you</li>
													<li>Other SI Tickets users as authorized by you or your use of our services</li>
												</ul>
											</TableCell>
										</TableRow>

										<TableRow>
											<TableCell>Internet or network activity information, such as browsing history and interactions with our website Geolocation data, such as device location and approximate location derived from IP address.</TableCell>
											<TableCell>
												<ul>
													<li>Corporate affiliates</li>
													<li>Third-party service providers, who assist us for example with payment processing, marketing and advertising, shipping, fraud prevention, rewards programs and information technology support</li>
													<li>Business partners, such as partner teams, venues, artists, and sports leagues, to provide our event services</li>
													<li>PayPal, Inc., to provide our services, facilitate your purchases and prevent fraud</li>
												</ul>
											</TableCell>
										</TableRow>

										<TableRow>
											<TableCell>Geolocation data, such as device location and approximate location derived from IP address.</TableCell>
											<TableCell>
												<ul>
													<li>Corporate affiliates</li>
													<li>Third-party service providers, who assist us for example with payment processing, marketing and advertising, shipping, fraud prevention, rewards programs and information technology support</li>
												</ul>
											</TableCell>
										</TableRow>

										<TableRow>
											<TableCell>Audio, electronic, visual, similar information, such as photographs and call recordings</TableCell>
											<TableCell>
												<ul>
													<li>Corporate affiliates</li>
													<li>Third-party service providers, who assist us for example with payment processing, marketing and advertising, shipping, fraud prevention, rewards programs and information technology support</li>
													<li>Other SI Tickets users as authorized by you or your use of our services</li>
												</ul>
											</TableCell>
										</TableRow>

										<TableRow>
											<TableCell>Inferences drawn from any of the Personal Information listed above to create a profile about, for example, an individual’s preferences and characteristics</TableCell>
											<TableCell>
												<ul>
													<li>Corporate affiliates</li>
													<li>Third-party service providers, who assist us for example with payment processing, marketing and advertising, shipping, fraud prevention, rewards programs and information technology support</li>
												</ul>
											</TableCell>
										</TableRow>
									</Table>
									<br />
									<strong>Use of Personal Information</strong>
									<br />
									We use Personal Information to operate, manage, and maintain our business, to provide our products and services, and to accomplish our business purposes and objectives, including, for example, to: develop, improve, repair, and maintain our products and services; personalize, advertise, and market our products and services; conduct research, analytics, and data analysis; maintain our facilities and infrastructure; undertake quality and safety assurance measures; conduct risk and security control and monitoring; detect and prevent fraud; perform identity verification; perform accounting, audit, and other internal functions, such as internal investigations; comply with law, legal process, and internal policies; maintain records; and exercise and defend legal claims.
									<br /> <br />
									<strong>Rights and Requests</strong>
									<p>If you are a California resident, you have the right to make the following requests:</p>
									<IndentInText>
										<p>
											<strong>(1) Request to Know</strong>
										</p>
										<p>You may request that we disclose to you the following information covering the 12 months preceding your request:</p>
										<IndentInText>
											<ul>
												<li>The categories of Personal Information we collected about you and the categories of sources from which we collected such Personal Information.</li>
												<li>The specific pieces of Personal Information we collected about you.</li>
												<li>The business or commercial purpose for collecting Personal Information about you.</li>
												<li>The categories of Personal Information about you that we otherwise shared or disclosed, and the categories of third parties with whom we shared or to whom we disclosed such Personal Information (if applicable).</li>
											</ul>
										</IndentInText>
									</IndentInText>
									<IndentInText>
										<p>
											<strong>(2) Request to Delete</strong>
										</p>
										<p>
											You may request that we delete Personal Information we collected from you.
											<br />
											To make a Request to Know or a Request to Delete, you may contact us via <a href="tel:6464537400">646-453-7400</a> or <a href="mailto:support@sitickets.com">support@sitickets.com</a>. Registered SI Tickets users may contact us through their account or by visiting our Help Center.
											<br />
											We will verify and respond to your request consistent with applicable law, taking into account the type and sensitivity of the Personal Information subject to the request. We may need to request additional Personal Information from you, such as email address, state of residency, or mailing address, in order to verify your identity and protect against fraudulent requests. If you maintain a password-protected account with us, we may verify your identity through our existing authentication practices for your account and require you to re-authenticate yourself before disclosing or deleting your Personal Information. If you make a Request to Delete, we may ask you to confirm your request before we delete your Personal Information.
											<br />
											You have the right to be free from unlawful discrimination for exercising your rights under the CCPA. You have the right to be informed, at or before the point of collection, of the categories of Personal Information to be collected from you and the purposes for which that Personal Information will be used.
										</p>
									</IndentInText>
									<br />
									<p>
										<strong>Authorized Agents</strong>
									</p>
									<p>If you want to make a request as an authorized agent on behalf of a California resident, you may use the submission methods noted above. As part of our verification process, we may request that you provide, as applicable:</p>
									<IndentInText>
										<ul>
											<li>Proof of your registration with the California Secretary of State to conduct business in California.</li>
											<li>A power of attorney from the California resident pursuant to Probate Code sections 4000-4465.</li>
											<li>Written permission that the California resident has authorized you to make a request on the resident’s behalf. This permission must be signed (via physical or e-signature) by the California resident.</li>
										</ul>
									</IndentInText>
									<p>If you are making a Request to Know or a Request to Delete on behalf of a California resident and have not provided us with a power of attorney from the resident pursuant to Probate Code sections 4000-4465, we may also require the resident to:</p>
									<IndentInText>
										<ul>
											<li>Provide you with a written permission signed by the resident to make the Request to Know or Request to Delete on the resident’s behalf.</li>
											<li>Verify the resident’s own identity directly with us.</li>
											<li>Directly confirm with us that the resident provided you permission to submit the Request to Know or Request to Delete.</li>
										</ul>
									</IndentInText>
									<br />
									<p>
										<strong>Contact Us</strong>
									</p>
									<p>
										Please contact us at <a href="mailto:support@sitickets.com">support@sitickets.com</a> if you have any questions regarding our privacy policies or practices.
										<br />
										If you have a question or a complaint about this Privacy Policy or our information handling practices, you can reach the Global Privacy Office in writing at: SI Tickets Inc, 234 10th Avenue, #20273, New York, NY 10011-9993 Your right to file complaints with a data protection supervisory authority remains unaffected.
									</p>
									<br />
									<p>
										<strong>Mobile Privacy Policy </strong>
									</p>
									<p>
										This Mobile Privacy Policy summarizes your privacy rights regarding our collection, use, sharing and protection of your personal information. It applies to any SI Tickets Mobile Application Service (App) where this Mobile Privacy Policy is referenced. BY INSTALLING AND USING THIS APP YOU EXPRESSLY ACKNOWLEDGE AND AGREE TO OUR COLLECTION, USE, SHARING, AND PROTECTION OF YOUR PERSONAL INFORMATION AS SUMMARIZED BELOW AND AS FULLY DESCRIBED IN OUR USER PRIVACY POLICY.
										<br />
										In order to maximize the benefits of this App, please opt-in or modify the collection settings in your phone's privacy settings (where applicable). If you have a question or complaint about this Privacy Policy or our information handling practices, please contact us.
									</p>
									<br />
									<p>
										<strong>Collection, Use, Sharing and Protection of Your Personal Information</strong>
									</p>
									<br />
									<p>
										<Underline>Collection</Underline>
									</p>
									<p>
										With your authorization, we may access precise location information from your mobile device to provide you a customized experience.
										<br />
										With your authorization, we may access your mobile device contact information to help you manage your communication with others.
										<br />
										With your authorization, we may access calendar information from your mobile device to help you schedule reminders.
										<br />
										With your authorization, we may access the camera or photo library on your mobile device so that you can do things like list an item.
										<br />
										When you connect your SI Tickets account with a third party social networking or sign-in service, we may create and authenticate your account or connect your account with other features and services. You control the personal information we have access to through (a) the privacy settings in the App and (b) the permissions you express on the device used to grant us access to your personal information retained within, and collected by, the App.
										<br />
										With your authorization, we may access your mobile device’s music library and music preference information to offer you a customized experience.
										<br />
										We may use your information to serve you customized advertising if you allow us. You can opt-out using your device’s setting.
									</p>
									<br />
									<p>
										<Underline>Sharing</Underline>
									</p>
									<p>
										Only with your consent will we share your information with third-parties to use for their own marketing purposes.
										<br />
										We share your information with service providers under contract who help us operate our business and who process your information according to our instruction.
										<br />
										We share your information with law enforcement or authorized third parties, to investigate illegal activity or violations of our Terms of Use.
									</p>
									<br />
									<p>
										<Underline>Protection</Underline>
									</p>
									<p>
										We protect your information using technical and administrative security measures designed to reduce the risks of loss or misuse.
										<br />
										Cookies, Web Beacons and Similar Technologies.
									</p>
									<br />
									<p>
										<Underline>Summary</Underline>
									</p>
									<p>
										When you visit or interact with our sites, services, applications, tools or messaging, we or our authorized service providers may use cookies, web beacons and other similar technologies for storing information to help provide you with a better, faster and safer experience, and for advertising purposes.
										<br />
										This page is designed to help you understand more about these technologies and our use of them on our sites and in our services, applications and tools. Below is a summary of a few key things you should know about our use of such technologies. You can review the contents of our Cookie Notice below.
										<br />
										Our cookies and similar technologies have different functions. They are either necessary for the functioning of our services, help us improve our performance, give you extra functionalities or help us to serve you relevant and targeted ads. We use cookies and similar technologies that only remain on your device for as long as you keep your browser active (session) and cookies and similar technologies that remain on your device for a longer period (persistent). You're free to block, delete or disable these technologies if your device allows this. You can manage your cookies and your cookie preferences in your browser or device settings.
										<br />
										<br />
										Where possible, security measures are set in place to prevent unauthorized access to our cookies and similar technologies. A unique identifier ensures that only we and/or our authorized service providers have access to cookie data.
										<br />
										Service providers are companies that help us with various aspects of our business, such as site operations, services, applications, advertisements and tools. We use some authorized service providers to help us to serve you relevant ads on our services and other places on the internet. These service providers may also place cookies on your device via our services (third party cookies). They may also collect information that helps them identify your device, such as IP-address, or other unique or device identifiers.
									</p>
									<br />
									<p>
										<Underline>Cookie Notice</Underline>
									</p>
									<p>
										<strong>Cookies, Web Beacons, And Similar Technologies</strong>
									</p>
									<p>
										We use technologies that allow us to collect certain pieces of information whenever you visit or interact with our sites, services, applications, messaging, tools, and to recognize you across devices. Information collected using these technologies includes data about the pages you view, the links you click, and other actions you take on our Services, within our advertising or e-mail content.
										<br />
										These technologies, which include cookies, web beacons, unique identifiers, and similar technologies and other similar technologies we use may change from time to time.
									</p>
									<IndentInText>
										<ul>
											<li>
												Cookies are small text files placed in the memory of your browser or device when you visit a website or view a message. Cookies allow a website to recognize particular device or browser and remember details about your previous use of the website.
												<IndentInText>
													<ul>
														<li>
															Types of cookies include:
															<IndentInText>
																<ul>
																	<li>Session cookies, which expire at the end of your browser session and allow us to link your actions during that particular browser session.</li>
																	<li>Persistent cookies, which are stored on your device between browser sessions, allow us to remember your preferences or actions across multiple sites.</li>
																	<li>First-party cookies, which are set by the site you are visiting.</li>
																	<li>Third-party cookies, which are set by a third party site separate from the site you are visiting.</li>
																</ul>
															</IndentInText>
														</li>
														<li>You can disable or remove cookies using tools that are available in most commercial browsers. If you use different browsers, your cookie preferences will need to be set separately in each.</li>
													</ul>
												</IndentInText>
											</li>
											<li>Web beacons are small graphic images (also known as "pixel tags" or "clear GIFs" and “single-pixel GIFS”) typically work in conjunction with cookies to identify our users and user behavior, such as counting users who have visited a certain page or opened an email, and for other related website statistics.</li>
											<li>
												Similar technologies include any technology that stores information in your browser or device utilizing local shared objects or local storage, such as flash cookies, HTML 5 cookies, and other web application software methods. These technologies can operate across all of your browsers, and in some instances may not be fully managed by your browser and may require management directly through your installed applications or device.
												<br />
												We may use the terms "cookies" or "similar technologies" interchangeably in our policies to refer to all technologies that we may use to store data in your browser or device or that collect information or help us identify you in the manner described above.
											</li>
										</ul>
									</IndentInText>
									<br />
									<p>
										<Underline>Your Choice and Our Use of Cookies Web Beacons and Similar Technologies</Underline>
									</p>
									<p>
										We offer certain site features, services, applications, and tools that are available only through the use of these technologies. You are always free to block, delete, or disable these technologies if your browser, installed application, or device so permits. However, if you decline cookies or other similar technologies, you may not be able to take advantage of certain site features, services, applications, or tools. You may also be required to re-enter your password more frequently during your browsing session. For more information on how you can block, delete, or disable these technologies, please review your browser or device settings.
										<br />
										Generally, these technologies allow our sites, services, applications, and tools to store relevant information in your browser or device and later read that information in order to identify you to our servers or internal systems. Where applicable, we protect our cookies and other similar technologies to help ensure that only we and/or our authorized service providers can interpret them by assigning them a unique identifier that is designed for interpretation only by us. We do not store any of your personal information on any of our cookies or other similar technologies.
										<br />
										Any personal information that we collect and store through use of these technologies is first obtained through notice and consent: We obtain your consent by providing you with transparent notice of use of the technologies and, to the extent described herein providing you with the opportunity to make a choice to disable these technologies as set forth above.
										<br />
										Our uses of such technologies fall into the following general categories:
										<IndentInText>
											<ul>
												<li>Operationally Necessary. We may use cookies, web beacons, or other similar technologies that are necessary to the operation of our sites, services, applications, and tools. This includes technologies that allow you access to our sites, services, applications, and tools, that are required to identify irregular site behavior, prevent fraudulent activity and improve security, or that allow you to make use of our functions such as shopping carts, saved search, or similar functions.</li>
												<li>Performance-Related. We may use cookies, web beacons, or other similar technologies to assess the performance of our websites, applications, services, and tools, including as part of our analytic practices to help us understand how our visitors use our websites, determine if you have interacted with our messaging, determine whether you have viewed an item or link, or to improve our website content, applications, services, or tools.</li>
												<li>Functionality-Related. We may use cookies, web beacons, or other similar technologies that allow us to offer you enhanced functionality when accessing or using our sites, services, applications, or tools. This may include identifying you when you sign into our sites or keeping track of your specified preferences, interests, or past items viewed so that we may enhance the presentation of content on our sites.</li>
											</ul>
										</IndentInText>
									</p>
									<p>Advertising or Targeting-Related. We may use first-party or third-party cookies and web beacons to deliver content, including ads relevant to your interests, on our sites or on third party sites. This includes using technologies to understand the usefulness to you of the advertisements and content that has been delivered to you, such as whether you have clicked on an advertisement.</p>
									<br />
									<p>
										To opt-out of the use of third-party advertising-related cookies and web beacons, see the section Ad Networks and Exchanges authorized by third-parties below. Opting out of our first-party advertising-related cookies, or out of third-party advertising-related cookies and web beacons as described below, does not necessarily mean that you will not see our advertising - only that this advertising will not be targeted to you through the use of first-party or third-party cookies, web beacons or related technologies.
										<br />
										If you would like to opt-out of all other types of technologies we employ on our sites, services, applications, or tools, you may do so by blocking, deleting, or disabling them as your browser or device permits.
									</p>
									<br />
									<p>
										<Underline>Use of These Technologies by Authorized Third-Party Service Providers</Underline>
									</p>
									<p>
										We may work with third-party companies, commonly known as service providers, who are authorized to place third-party cookies, web beacons, or similar technologies for storing information on our sites or in our services, applications, and tools with our permission. These service providers help us to provide you with a better, faster, and safer experience.
										<br />
										These service providers may use these technologies to help us deliver our own content and advertising, and compile anonymous site metrics and analytics. These service providers are subject to confidentiality agreements with us and other legal restrictions on their use or collection of any personal information. Third-party cookies are covered by the third parties' privacy policy.
										<br />
										You may encounter cookies and tracking technologies from our third-party service providers, including but not limited to (1) Facebook to provide advertising services through Facebook’s advertising and marketing platforms (e.g., Audience Network, Website Custom Audiences) and other social networking plug-ins or marketing tools, and (2) Google to provide advertising services through Google’s advertising and marketing platforms (e.g., DoubleClick for Publishers, Google DoubleClick AdX Service, Adwords). Facebook and Google may use the personal information that they have collected about you on our sites pursuant to their own privacy policies (
										<a href="https://www.facebook.com/privacy/explanation" target="_blank" rel="noopener noreferrer">
											Facebook Data Policy
										</a>{" "}
										and&nbsp;
										<a href="https://www.google.com/intl/en/policies/privacy" target="_blank" rel="noopener noreferrer">
											Google Privacy Policy
										</a>
										). We do not permit any of these service providers to collect any of your personal information on our sites or in our services, applications, or tools for their own purposes. These service providers are subject to confidentiality agreements with us and other legal restrictions on their use or collection of any personal information. Third-party cookies are covered by the third parties' privacy policy.
										<br />
										With the exception of the use of such technologies by our service providers or other authorized third parties, we do not permit any third-party content on sites (such as item listings, member-to-member communications, classified listings, comments, reviews, etc.) to include or utilize any cookies, web beacons, local storage, or similar technologies for tracking purposes or to collect your personal information. If you believe a listing or other third-party content might be collecting personal information or using tracking technologies on one of our sites, please report it to <a href="mailto:support@sitickets.com">support@sitickets.com</a>.
									</p>
									<br />
									<p>
										<Underline>Ad Networks and Exchanges Operated by Authorized Third Parties</Underline>
									</p>
									<p>
										We may use third parties, such as advertising networks and exchanges, to allow us to serve you advertisements. These third-party ad networks and exchange providers may use third-party cookies, web beacons, or similar technologies to collect information. They may also collect your device identifier, IP address, or identifier for advertising (IDFA). The information that these third parties collect may be used to assist us in providing you with more relevant advertising that we serve on our sites or elsewhere on the web, and as described above. Third-party cookies are covered by the third parties' privacy policy.
										<br />
										For more information on third-party advertising-related cookies and how to opt-out of them, please visit one of the following third-party websites:
										<IndentInText>
											<ul>
												<li>Your Online Choices</li>
												<li>The Self-Regulatory Program for Online Behavioral Advertising (available in English only)</li>
												<li>Network Advertising Initiative Consumer Opt-Out (available in English only)</li>
											</ul>
										</IndentInText>
									</p>
									<br />
									<p>
										<Underline>Do Not Track</Underline>
									</p>
									<p>California law requires us to let you know how we respond to web browser Do Not Track (“DNT”) signals. Because there currently isn't an industry or legal standard for recognizing or honoring DNT signals, we don't respond to them at this time. We await the result of work by the privacy community and industry to determine when such a response is appropriate and what form it should take.</p>
								</ItemText>
							</ItemContent>
						</EducationalRow>
					</EducationalSectionContainer>
				</Section>
			</PageContainer>
		</CommonPage>
	);
};

const Table = styled.div`
	display: flex;
	flex-direction: column;
`;

const TableRow = styled.div`
	display: flex;
	div:first-child {
		width: 30%;
		border-right: none;
	}
	div:last-child {
		width: 70%;
	}
	@media ${mediaQueries.max480} {
		div:first-child {
			width: 50%;
		}
	}
`;

const TableHeader = styled.div`
	background: #f9f9f9;
	border: 1px solid #555555;
	padding: 14px 24px;
	flex-grow: 1;
	text-align: center;
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	@media ${mediaQueries.max480} {
		font-size: 13px;
		padding: 13px;
	}
`;

const TableCell = styled.div`
	border: 1px solid #555555;
	border-top: none;
	padding: 13px 24px;
	flex-grow: 1;
	text-align: left;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	width: 50%;
	@media ${mediaQueries.max480} {
		font-size: 13px;
		padding: 13px;
	}
`;

const Section = styled.div`
	margin-top: 24px;
`;

const PageContainer = styled.div`
	${maxContentWidthStyles}
	padding: 40px 15px 24px;
	text-align: left;
	color: #000;
`;

const Title = styled.h1`
	font-size: 40px;
	font-weight: 700;
	font-family: SolanoGothicMVB-Bd, sans-serif;
	text-transform: uppercase;
`;

const Text = styled.p<{ uppercase?: boolean }>`
	font-family: Montserrat;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 25px;
	font-weight: 500;
	text-transform: ${props => props.uppercase && "uppercase"};
	a {
		text-decoration: underline;
		color: #e92224;
	}
`;

const ItemContent = styled.div`
	display: flex;
	margin-bottom: 20px;
	&:last-child {
		margin-bottom: 0px;
	}
`;

const ItemText = styled.div`
	width: 100%;
	font-family: Montserrat;
	font-size: 16px;
	line-height: 24px;
	margin-right: 8px;
	font-weight: 500;
	ul {
		padding-left: 30px;
		li {
			margin-top: 5px;
		}
	}
	p {
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0px;
		}
	}
	ol {
		padding-left: 30px;
		li {
			margin-top: 5px;
			list-style: decimal;
		}
	}
	a {
		text-decoration: underline;
		color: #e92224;
	}
	@media ${mediaQueries.max480} {
		ul {
			padding-left: 15px;
		}
	}
`;

const IndentInText = styled.div`
	padding-left: 30px;
	@media ${mediaQueries.max480} {
		padding-left: 15px;
	}
`;

const Underline = styled.span`
	text-decoration: underline;
`;

const UnorderedList = styled.ul`
	display: block !important;
	list-style-type: disc;
	margin-top: 0;
	margin-bottom: 1em;
	margin-left: 0;
	margin-right: 0;
	padding-left: 40px;
	margin-top: 11px;
`;

const EducationalSectionContainer = styled.div<{ fullwidth?: boolean }>`
	margin-top: ${props => (props.fullwidth ? "0px" : "24px")};
`;

const Date = styled.p`
	font-family: Montserrat;
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	margin-top: 24px;
	margin-bottom: 10px;
`;
