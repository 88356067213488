import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { Switch, useLocation, useRouteMatch, useHistory, Route as PublicRoute, Redirect } from "react-router-dom";
import { InternalRoutes } from "./Link";
import { popupMaxWidth } from "./util/mediaQueries";
import { ScrollToTop } from "./util/ScrollToTop";
import { ModalRouteContextProvider } from "./util/ModalRouteContext";
import { Home } from "./routes/Home";
import { CartRoute } from "./routes/CartRoute";
import { CheckoutRoute } from "./routes/CheckoutRoute";
import { EventRoute2 } from "./routes/EventRoute";
import { Category } from "./routes/Category";
import { NotFound } from "./routes/NotFound";
import { Test } from "./routes/Test";
import { BetsRoute } from "./routes/BetsRoute";
import { Performer } from "./routes/Performer";
import { Team } from "./routes/Team";
import { SearchRoute } from "./routes/SearchRoute";
import { TeamFutureSelected } from "./routes/TeamFutureSelected";
import { FuturesRoute } from "./routes/FuturesRoute";
import { Discover } from "./routes/Discover";
import { MyAccountRoute } from "./routes/MyAccountRoute";
import { useDispatch, useSelector } from "react-redux";
import { IsMobileContext } from "./util/IsMobileContext";
import { PropsWithAuth, withAuthentication } from "../services/hoc/withAuthentication";
import { LoginRoute } from "./routes/LoginRoute";
import { getAndDispatchBraintreePaymentMethods, getAndDispatchBraintreeClientToken } from "../services/braintree";
import { setBraintreeDataCollector, resetBraintreeState, setBraintreeVenmoSupported, setBraintreeVenmoDeepLinkUrl } from "../store/actions/braintreeActions";
import { client, dataCollector, venmo } from "braintree-web";
import { getMyUser } from "../services/user";
import { updateUser } from "../store/reducers/userReducer";
import { useBraintree } from "../services/hooks/useBraintree";
import { resetCheckoutState } from "../store/actions/checkoutActions";
import { useUser } from "../services/hooks/useUser";
import { useCheckout } from "../services/hooks/useCheckout";
import { PopularRoute } from "./routes/PopularRoute";
/* import { EarlyAccess } from "./routes/EarlyAccess";*/
import { VenueRoute } from "./routes/VenueRoute";
import { CheckoutThanks } from "./routes/CheckoutThanks";
import { useWindowResizeEffect } from "./hooks/useWindowResizeEffect";
import { SearchResults } from "./routes/SearchResults";
import { Config } from "../Config";
import { AboutRoute } from "./routes/AboutRoute";
import { FanGuaranteeRoute } from "./routes/FanGuaranteeRoute";
import { VenmoRoute } from "./routes/VenmoRoute";
import { MobileNavigation } from "./components/MobileNavigation";
import { PasswordResetRoute } from "./routes/PasswordResetRoute";
import { TermsAndConditionsModalRoute, PrivacyPolicyModalRoute } from "./routes/LegalModalRoute";
import { flow } from "lodash";
import { withMaintenanceRedirection } from "./hocs/withMaintenanceRedirection";
import { MaintenanceRoute, MaintenanceModalRoute } from "./routes/MaintenanceRoute";
import { GolfClassicRoute } from "./routes/GolfClassicRoute";
import { Formula1Route } from "./routes/Formula1Route";
import { SITixChampionshipRoute } from "./routes/SITixChampionshipRoute";
import { SITixChampionshipLegalRoute } from "./routes/SITixChampionshipRouteLegal";
import { TermsRoute } from "./routes/TermsRoute";
import { PrivacyRoute } from "./routes/PrivacyRoute";
import { TestHome } from "./routes/TestHome";
import { ZenDeskChat } from "./components/ZenDeskChat/ZenDeskChat";
import { useCart } from "../services/hooks";
import { clearCart } from "../store/reducers/cartReducer";
import { updateGuest } from "../store/reducers/guestReducer";
import { useGuest } from "../services/hooks/useGuest";
import { didLogOut } from "../store/reducers/sessionReducer";
import { setLocation } from "../store/reducers/locationReducer";
import { getGeolocation } from "../services/location";
import { getAllCategories } from "../services/actions/categoriesService";
import { updateCategories } from "../store/actions/categoryAction";
import { MASLRoute } from "./routes/MASLRoute";
import { Perfect10Route } from "./routes/Perfect10Route";
import { useScript } from "./hooks";
import { pushly } from "./util/pushlyService";
import { useAvailablePromos } from "../services/hooks/useAvailablePromos";
import { postRedeemWalletPromo } from "../services/promo";
import { clearWalletPromo } from "../store/reducers/promoReducer";
import toast from "react-hot-toast";
import { ReebokRoute } from "./routes/ReebokMLBRoute";
import { CoversRoute } from "./routes/CoversRoute";
import { SuperBowlLVIIRoute } from "./routes/SuperBowlLVII";
import { SwimRoute } from "./routes/SwimRoute";
import { PredraftShowCaseRoute } from "./routes/PredraftShowCase";
import { SITixProspectsRoute } from "./routes/SITixProspectsRoute";
import { APPUSARoute } from "./routes/APPUSARoute";
import { GrandPrixRoute } from "./routes/GrandPrixRoute";
import SoccerExRoute from "./routes/SoccerExRoute";
import { ShowMyTicketsRoute } from "./routes/ShowMyTicketsRoute";

// TODO: Put this somewhere more explicit
const useGlobalEffects = (location: Switch["props"]["location"]) => {
	// load pushly sdk
	const pushlyLoaded = useScript(`https://cdn.p-n.io/pushly-sdk.min.js?domain_key=${Config.getDomainKey()}`);

	const captchaBadgeIsHidden = useSelector(state => state.transient.captchaBadge.hidden);

	const captchaBadge = document.getElementsByClassName("grecaptcha-badge")?.[0] as HTMLElement | undefined;

	useEffect(() => {
		if (pushlyLoaded) {
			pushly("load", {
				domainKey: Config.getDomainKey(),
			});
		}
	}, [pushlyLoaded]);

	useEffect(() => {
		if (captchaBadge) {
			const desiredCaptchaBadgeVisiblity = captchaBadgeIsHidden ? "hidden" : "initial";
			if (captchaBadge.style.visibility !== desiredCaptchaBadgeVisiblity) {
				captchaBadge.style.visibility = desiredCaptchaBadgeVisiblity;
			}
		}
	}, [captchaBadge, captchaBadgeIsHidden]);

	const { braintreeState } = useBraintree();
	const user = useUser();
	const guest = useGuest();
	const checkoutState = useCheckout();

	const sessionToken = useSelector(st => st.persistent.session.sessionToken);
	const locationState = useSelector(state => state.persistent.location);
	const dispatch = useDispatch();

	const onCheckoutRoute = !!useRouteMatch(InternalRoutes.Checkout);
	const onCheckoutThanksRoute = !!useRouteMatch(InternalRoutes.CheckoutThanks(":orderId"));

	const { cart } = useCart();

	useEffect(() => {
		if (cart?.tickets.find(ticket => ticket.transactionFeeRules === undefined)) {
			dispatch(clearCart());
		}
		if (!onCheckoutRoute) {
			//TODO: Consider finding logic to only dispatch this if the checkoutState is not the default value.
			dispatch(resetCheckoutState);
		}
	}, []);

	useEffect(() => {
		if (!locationState) {
			getGeolocation().then(res => {
				dispatch(
					setLocation({
						name: res.data.name,
						latitude: res.data.latitude,
						longitude: res.data.longitude,
					})
				);
			});
		}
	}, []);

	useEffect(() => {
		if (guest && !onCheckoutRoute && !onCheckoutThanksRoute) {
			dispatch(didLogOut());
		}
	}, [guest, onCheckoutRoute, onCheckoutThanksRoute]);

	const promos = useAvailablePromos();

	useEffect(() => {
		if (user && promos.walletPromo) {
			postRedeemWalletPromo(user.email, promos.walletPromo?.promoCode, promos.walletPromo.referrerUrl, promos.walletPromo.uId)
				.then(response => {
					toast.success(response.data.message);
					dispatch(clearWalletPromo());
				})
				.catch(error => {
					toast.error(error?.errors);
					if (error.status === 422) {
						dispatch(clearWalletPromo());
					}
				});
		}
	}, [user, promos]);

	useEffect(() => {
		if (sessionToken) {
			if (!user) {
				getMyUser().then(result => {
					const newUser = result.data;
					if (newUser.user_type === "full_user") {
						dispatch(updateUser(newUser));
					} else if (newUser.user_type === "full_guest_user") {
						newUser.addresses = []; // TODO: Request backend to not store addresses for guests rather than using this hack.
						dispatch(updateGuest(newUser));
					}

					if (!braintreeState.clientAuth) {
						getAndDispatchBraintreeClientToken().then(token => {
							//TODO: Consider finding a method to wait untill the client token is finished dispatching and then accessing it from the store isntead.
							if (token) {
								client
									.create({
										authorization: token,
									})
									.then(clientInstance => {
										console.log(navigator.userAgent);
										//@ts-ignore
										console.log(window.popupBridge);

										let venmoSupported: boolean;

										//@ts-ignore
										if (window.popupBridge) {
											console.log("popupBridge exists");
											let deepLinkReturnUrl;
											let platformOS = Config.getPlatformOS();
											console.log(platformOS);
											if (platformOS === "ios") {
												console.log("iOS Webview");
												deepLinkReturnUrl = "com.braintreepayments.ios.venmo-webview://popupbridgev1/venmo";
											} else if (platformOS === "android") {
												console.log("Android Webview");
												//@ts-ignore
												deepLinkReturnUrl = window.popupBridge.getReturnUrlPrefix();
											}
											if (deepLinkReturnUrl) {
												console.log(deepLinkReturnUrl);
												venmoSupported = true;
												dispatch(setBraintreeVenmoDeepLinkUrl(deepLinkReturnUrl));
											}
										}

										venmo
											.create({
												client: clientInstance,
												//@ts-ignore
												allowDesktop: true,
												deepLinkReturnUrl: braintreeState.venmoDeepLinkUrl,
											})
											.then(venmoInstance => {
												if (!venmoSupported) {
													venmoSupported = venmoInstance.isBrowserSupported();
												}
												venmoSupported = Config.getDisabledPaymentMethods().includes("VenmoAccount") && venmoSupported;
												dispatch(setBraintreeVenmoSupported(venmoSupported));
											})
											.catch(error => {
												console.error(error);
											});

										dataCollector
											.create({
												client: clientInstance,
												paypal: true,
											})
											.then(dataCollectorInstance => {
												dispatch(setBraintreeDataCollector(dataCollectorInstance));
											})
											.catch(error => {
												console.error(error);
											});
									})
									.catch(error => {
										console.error(error);
									});
							}
						});
					}
				});
			}
		} else {
			if (user) {
				dispatch(updateUser(null));
			}
			if (guest) {
				dispatch(updateGuest(null));
			}
			if (braintreeState) {
				dispatch(resetBraintreeState);
			}
			if (checkoutState) {
				dispatch(resetCheckoutState);
			}
		}
	}, [sessionToken]);

	useEffect(() => {
		getAllCategories().then(response => {
			dispatch(updateCategories(response.data));
		});
	}, []);
};

type MainRoutesProps = PropsWithAuth<{
	location: Switch["props"]["location"];
}>;

const MainRoutes = ({ authenticated, location }: MainRoutesProps) => {
	useGlobalEffects(location);

	return (
		<>
			<ScrollToTop pathname={location?.pathname} />
			<MobileNavigation />
			<ZenDeskChat />
			<Switch location={location}>
				<PublicRoute path={InternalRoutes.SuperBowlLVII} component={SuperBowlLVIIRoute} />
				<PublicRoute path={InternalRoutes.Cart} component={CartRoute} />
				<PublicRoute path={InternalRoutes.Checkout} component={CheckoutRoute} />
				<PublicRoute path={InternalRoutes.Bets} component={BetsRoute} />
				<PublicRoute path={InternalRoutes.Event(":event")} component={EventRoute2} />
				<PublicRoute path={InternalRoutes.Category(":category")} component={Category} />
				<PublicRoute path={InternalRoutes.Sports(":category")} component={Category} />
				<PublicRoute path={InternalRoutes.Performer(":performer")} component={Performer} />
				<PublicRoute path={InternalRoutes.Discover()} component={Discover} />
				<PublicRoute path={InternalRoutes.Popular()} component={PopularRoute} />
				{/*
        <PublicRoute path={InternalRoutes.EarlyAccess()} component={EarlyAccess} />
          */}
				{/*<PublicRoute path={InternalRoutes.Careers} component={CareersRoute} />*/}
				<PublicRoute path={InternalRoutes.Team(":team")} component={Team} />
				<PublicRoute path={InternalRoutes.TeamFutureSelected(":team", ":event")} component={TeamFutureSelected} />
				<PublicRoute path={InternalRoutes.GolfClassic} component={GolfClassicRoute} />
				<PublicRoute path={InternalRoutes.SITixChampionshipLegal} component={SITixChampionshipLegalRoute} />
				<PublicRoute path={InternalRoutes.SITixChampionship} component={SITixChampionshipRoute} />
				<PublicRoute path={InternalRoutes.Formula1} component={Formula1Route} />
				<PublicRoute path={InternalRoutes.CheckoutThanks(":orderId")} component={CheckoutThanks} />
				<PublicRoute path={InternalRoutes.Venue(":venue")} component={VenueRoute} />
				<PublicRoute path={InternalRoutes.Futures()} component={FuturesRoute} />
				<PublicRoute path={InternalRoutes.Search} component={SearchRoute} />
				<PublicRoute path={InternalRoutes.SearchResults()} component={SearchResults} />
				<PublicRoute path={InternalRoutes.Test} component={Test} />
				<PublicRoute path={InternalRoutes.About()} component={AboutRoute} />
				<PublicRoute path={InternalRoutes.FanGuarantee} component={FanGuaranteeRoute} />
				<PublicRoute path={InternalRoutes.Venmo} component={VenmoRoute} />
				<PublicRoute path={InternalRoutes.Terms} component={TermsRoute} />
				<PublicRoute path={InternalRoutes.Privacy} component={PrivacyRoute} />
				<PublicRoute path={InternalRoutes.MyAccount()} component={MyAccountRoute} />
				<PublicRoute path={InternalRoutes.PasswordReset(":token")} component={PasswordResetRoute} />
				<PublicRoute path={InternalRoutes.Login} component={LoginRoute} />
				<PublicRoute path={InternalRoutes.TestHome} exact={true} component={TestHome} />
				<PublicRoute path={InternalRoutes.Home} exact={true} component={Home} />
				<PublicRoute path={InternalRoutes.Maintenance()} component={MaintenanceRoute} />
				<PublicRoute path={InternalRoutes.Tailgates} component={() => <Redirect to={InternalRoutes.Performer("si-tickets-tailgate-by-gameday-hospitality")} />} />

				<PublicRoute path={InternalRoutes.Perfect10} component={Perfect10Route} />
				{/*TODO: The below route should be removed once this promotion is over */}
				<PublicRoute path={"/mcqueen"} component={() => <Redirect to={InternalRoutes.Event("virtual-experiences-on-any-sunday")} />} />
				<PublicRoute path={InternalRoutes.MASL} component={MASLRoute} />
				<PublicRoute path={InternalRoutes.Reebok} component={ReebokRoute} />
				<PublicRoute path={InternalRoutes.ClubSI} component={GrandPrixRoute} />
				<PublicRoute path={InternalRoutes.SoccerEx} component={SoccerExRoute} />
				<PublicRoute path={InternalRoutes.Covers} component={CoversRoute} />
				<PublicRoute path={InternalRoutes.APPUSA} component={APPUSARoute} />
				<PublicRoute path={InternalRoutes.Swim} component={SwimRoute} />
				<PublicRoute path={InternalRoutes.Siprospects} component={SITixProspectsRoute} />
				<PublicRoute path={InternalRoutes.PredraftShowcase} component={PredraftShowCaseRoute} />
				<PublicRoute path={InternalRoutes.ShowMyTickets(":qr")} component={ShowMyTicketsRoute} />

				<PublicRoute component={NotFound} />
			</Switch>
		</>
	);
};

const modalRoutes = [InternalRoutes.TermsOfUse, InternalRoutes.PrivacyPolicy, InternalRoutes.Maintenance()];

type ModalRoutesProps = PropsWithAuth<{
	location: Switch["props"]["location"];
}>;

const ModalRoutes = ({ location, authenticated }: ModalRoutesProps) => {
	return (
		<Switch location={location}>
			<PublicRoute path={InternalRoutes.TermsOfUse} component={TermsAndConditionsModalRoute} />
			<PublicRoute path={InternalRoutes.PrivacyPolicy} component={PrivacyPolicyModalRoute} />
			<PublicRoute path={InternalRoutes.Maintenance()} component={MaintenanceModalRoute} />
		</Switch>
	);
};

const AppRoutes = (props: PropsWithAuth) => {
	const location = useLocation();
	const history = useHistory();

	const [previousLocation, setPreviousLocation] = useState<typeof location>({
		hash: "",
		key: "default",
		pathname: "/",
		search: "",
		state: null,
	});

	const isModal = !!useRouteMatch(modalRoutes);
	useEffect(() => {
		if (isModal) return;
		setPreviousLocation(location);
	}, [isModal, location, setPreviousLocation]);

	const closeAllModals = useCallback(() => {
		history.push(previousLocation);
	}, [history, previousLocation]);

	const [isMobile, setIsMobile] = useState<boolean>(false);

	const [isTablet, setIsTablet] = useState(false);

	const updateScreenState = () => {
		const setDesiredState = (state: boolean, setState: Dispatch<SetStateAction<boolean>>, width: number) => {
			const newState = window.innerWidth <= width;
			if (newState !== state) {
				setState(newState);
			}
		};
		setDesiredState(isMobile, setIsMobile, popupMaxWidth);
		setDesiredState(isTablet, setIsTablet, 1024);
	};

	useEffect(() => {
		updateScreenState();
	}, []);

	useWindowResizeEffect(updateScreenState, [isMobile, isTablet]);

	return (
		<IsMobileContext.Provider value={isMobile}>
			<ModalRouteContextProvider value={{ closeAllModals }}>
				{isTablet && isModal ? (
					<>
						<MainRoutes location={previousLocation} authenticated={props.authenticated} />
						<ModalRoutes location={location} authenticated={props.authenticated} />
					</>
				) : (
					<>
						<MainRoutes location={location} authenticated={props.authenticated} />
					</>
				)}
			</ModalRouteContextProvider>
		</IsMobileContext.Provider>
	);
};

const enhancedRoutes = flow(withAuthentication, withMaintenanceRedirection);
export const Routes = enhancedRoutes(AppRoutes);
