/** Colors from the marketing guidelines */
export const marketingColors = {
	red: "#E92224",
	orange: "#FF7500",
	yellow: "#DBE442",

	black: "#000000",
	gray: "#707372",
	lightGray: "#C1C6C8",
	white: "FFFFFF",
};

export const colors = {
	...marketingColors,

	link: "#0075ff",

	error: "#FF004E",
	errorTransparent: "rgba(255, 0, 78, 0.07)",

	redTransparent: "#E9222418",
	orangeTransparent: "#FF750018",
};
