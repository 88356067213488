import React from "react";
import { TicketGroup, TicketEvent } from "../../../model/optimizedModel/ticket";
import { PartialBy } from "../../../model/optimizedModel/general";
import { ItemContentDisplay, BaseContentDisplayProps, AdditionalItemContentDisplayInfo } from "./ItemContentDisplay";
import { renderAddress } from "../../util/address";
import { Tier } from "../../../model/Cart";
import { useSelector } from "react-redux";

export interface BaseTicketContentDisplayProps extends PartialBy<Omit<TicketEvent, "basePrice" | "performers">, "occursAt"> {
	deliveryMethodTitle?: string;
	availableQuantity?: number;
}

export const TicketContentDisplay = ({
	//TODO: Add slug back.
	//slug,
	tiersInfo,
	name,
	venue,
	occursAt,
	endDate,
	section,
	row,
	deliveryMethodTitle,
	additionalInfo,
	availableQuantity,
	...spreadableProps
}: BaseTicketContentDisplayProps & Partial<Pick<TicketGroup, "section" | "row">> & BaseContentDisplayProps & { tiersInfo?: Tier[] }) => {
	const source = useSelector(state=>state.persistent.cart?.source);

	const ticketInfo = () => (
		<div>
			{tiersInfo?.map(tier => (
				<div>
					QTY: {tier.quantity} - {tier.section}
				</div>
			))}
			<div>Delivery Method: {deliveryMethodTitle} </div>
		</div>
	);

	return (
		<ItemContentDisplay
			title={{
				//slug,
				name,
			}}
			location={{
				name: venue.name + ", " + renderAddress(venue.address),
				//slug: venue.slug,
			}}
			date={occursAt}
			endDate={endDate}
			displayHTML={source === "boxoffice" ? ticketInfo() : undefined}
			additionalInfo={(() => {
				const availableQty: AdditionalItemContentDisplayInfo[] = spreadableProps.quantity !== availableQuantity ? [
					{
						title: "Available QTY",
						content: availableQuantity,
					},
				] : [];
				const arr: AdditionalItemContentDisplayInfo[] = [
					{
						title: row ? "Section" : undefined,
						content: section,
					},
					{
						title: "Row",
						content: row,
					},
					{
						title: "Delivery Method",
						content: deliveryMethodTitle,
					},
				];
				return additionalInfo ? availableQty.concat(arr).concat(additionalInfo) : availableQty.concat(arr);
			})()}
			{...spreadableProps}
		/>
	);
};
