import React, { useEffect, useMemo, useState } from "react";
import { Nulleable, Optional } from "../../../model/Utils";
import { BackArrow, FiltersHeader, PanelModal, Title } from "./styles";
import arrow from "../../resource/image/ticket-group-arrow.svg";
import { EventSearchParams } from "../../../model/EventPage";
import { MultiSelectItem } from "../Filters/MultiSelectFilter";
import * as Consts from "../Seatics/consts";
import { TicketResponse } from "../../../services/types/TicketResponse";
import styled from "styled-components/macro";
import { PriceInput } from "../Filters/styles";
import Select from "react-select";
import resetButton from "../../resource/image/reset-button.svg";
import { EventVenueInfoResponse } from "../../../services/types/EventVenueInfoResponse";
import { css } from "styled-components";
import { Button } from "../NewButton/Button";

interface TicketFilterProps {
	filters: EventSearchParams;
	tickets: Nulleable<TicketResponse>;
	deliveryMethods: Nulleable<any>;
	mapData: Nulleable<EventVenueInfoResponse>;
	open: boolean;
	onClose: () => void;
	onFilterChange?: (filters: EventSearchParams) => void;
}

export const TicketFilter = (props: TicketFilterProps) => {
	const [minPrice, setMinPrice] = useState<Optional<number>>();
	const [maxPrice, setMaxPrice] = useState<Optional<number>>();
	const [quantity, setQuantity] = useState<MultiSelectItem>();
	const [sort, setSortBy] = useState<MultiSelectItem>();
	const [delivery, setDelivery] = useState<string[]>([]);

	const priceRange = useMemo(() => {
		return getPriceLimits(props.tickets);
	}, [props.tickets]);

	useEffect(() => {
		!minPrice && setMinPrice(props.filters.minPrice || priceRange.min);
		!maxPrice && setMaxPrice(props.filters.maxPrice || priceRange.max);
	}, [priceRange, props.filters]);

	useEffect(() => {
		setMinPrice(props.filters.minPrice || priceRange.min);
		setMaxPrice(props.filters.maxPrice || priceRange.max);
	}, [priceRange]);

	const sortOptions = useMemo(() => {
		const noMapFound = Array.isArray(props.mapData) && (props.mapData[0] === null || !props.mapData[0].mapImage);
		return noMapFound ? Consts.NO_MAP_TICKET_SORT_OPTIONS : Consts.TICKET_SORT_OPTIONS;
	}, [props.mapData]);

	useEffect(() => {
		const sort = sortOptions.find(x => x.value === props.filters.sort);
		setSortBy(sort ? sort : sortOptions[0]);
		const quantity = Consts.TICKET_QUANTITY_OPTIONS.find(x => x.value === props.filters.quantity?.toString());
		setQuantity(quantity ? quantity : Consts.TICKET_QUANTITY_OPTIONS[0]);
	}, [sortOptions, props.filters.sort]);

	useEffect(() => {
		let filters: any = [];
		if (typeof props.filters.delivery === "string") {
			filters = [props.filters.delivery];
		}
		if (Array.isArray(props.filters.delivery)) {
			filters = props.filters.delivery;
		}
		setDelivery(filters);
	}, [props.filters.delivery]);

	const availableDeliveryMethods = useMemo(() => {
		return getAvailableDeliveryMethods(props.tickets, props.deliveryMethods);
	}, [props.tickets, props.deliveryMethods]);

	const onKeyPress = function validate(event: any) {
		let key;
		if (event.type === "paste") {
			key = event.clipboardData.getData("text/plain");
		} else {
			key = String.fromCharCode(event.keyCode || event.which);
		}
		const regex = /[0-9]|\./;
		if (!regex.test(key)) {
			event.returnValue = false;
			if (event.preventDefault) event.preventDefault();
		}
	};

	const onMinPriceChange = (e: any) => {
		const nextValue = parseInt(e.target.value.replace("$", ""));
		setMinPrice(nextValue);
	};
	const onMaxPriceChange = (e: any) => {
		const nextValue = parseInt(e.target.value.replace("$", ""));
		setMaxPrice(nextValue);
	};

	const validateMinPrice = () => {
		if (!minPrice || !maxPrice || minPrice > maxPrice || minPrice > priceRange.max) {
			setMinPrice(priceRange.min);
		}
	};

	const validateMaxPrice = () => {
		if (!maxPrice || !minPrice || maxPrice < priceRange.min || maxPrice < minPrice || maxPrice > priceRange.max) {
			setMaxPrice(priceRange.max);
		}
	};

	const toggleDeliveryTag = (key: string) => {
		if (delivery.includes(key)) {
			setDelivery(delivery.filter(x => x !== key));
		} else {
			setDelivery([...delivery, key]);
		}
	};

	const applyFilters = () => {
		const filters: EventSearchParams = {
			sort: sort?.value === Consts.TICKET_SORT_OPTIONS[0].value ? null : (sort?.value as any),
			quantity: quantity?.value === Consts.TICKET_QUANTITY_OPTIONS[0].value ? null : (quantity?.value as any),
			delivery: delivery.length === 0 ? null : (delivery as any),
			minPrice: minPrice === priceRange.min ? null : (minPrice as any),
			maxPrice: maxPrice === priceRange.max ? null : (maxPrice as any),
			performer: undefined,
		};
		props.onFilterChange && props.onFilterChange(filters);
		props.onClose();
	};

	const clearFilters = () => {
		const filters: EventSearchParams = {
			sort: null,
			quantity: null,
			delivery: null,
			minPrice: null,
			maxPrice: null,
			performer: undefined,
		};
		setSortBy(sortOptions[0]);
		setQuantity(Consts.TICKET_QUANTITY_OPTIONS[0]);
		setDelivery([]);
		setMinPrice(null);
		setMaxPrice(null);
		props.onFilterChange && props.onFilterChange(filters);
	};

	const baseNumberInputProps = {
		type: "number",
		pattern: "*",
	};

	const baseSelectProps = {
		placeholder: "",
		isMulti: false,
		isSearchable: false,
		blurInputOnSelect: false,
		captureMenuScroll: true,
		isClearable: false,
		closeMenuOnSelect: true,
		hideSelectedOptions: false,
		backspaceRemovesValue: false,
		allowSelectAll: true,
		styles: selectStyles,
	};

	if (props.open) {
		return (
			<PanelModal>
				<div style={{ padding: 20 }}>
					<FiltersHeader>
						<BackArrow src={arrow} alt="arrow" onClick={props.onClose} mobileVisible={true} />
						<Title></Title>
						<ClearAll onClick={clearFilters}>
							Reset
							<img src={resetButton} style={{ marginLeft: 10 }} alt="Reset Button" />
						</ClearAll>
					</FiltersHeader>
					<FilterRow>
						<FilterFieldW40>
							<label>Quantity</label>
							<Select text={quantity?.label} defaultValue={quantity} value={quantity} options={Consts.TICKET_QUANTITY_OPTIONS} onChange={e => setQuantity(e as any)} {...baseSelectProps} />
						</FilterFieldW40>
						<FilterFieldW60>
							<label>Sort By</label>
							<Select text={sort?.label} defaultValue={sort} value={sort} options={sortOptions} onChange={e => setSortBy(e as any)} {...baseSelectProps} />
						</FilterFieldW60>
					</FilterRow>
					<FilterRow>
						<FilterField>
							<label>Price Range</label>
							<PriceFilterRow>
								<PriceFilterValue>
									<label>Min</label>
									<PriceInput id="minPrice" name="minPrice" value={minPrice || ""} onBlur={validateMinPrice} onKeyPress={onKeyPress} onChange={onMinPriceChange} {...baseNumberInputProps} />
								</PriceFilterValue>
								<PriceDivider />
								<PriceFilterValue>
									<label>Max</label>
									<PriceInput id="maxPrice" name="maxPrice" value={maxPrice || ""} onBlur={validateMaxPrice} onKeyPress={onKeyPress} onChange={onMaxPriceChange} {...baseNumberInputProps} />
								</PriceFilterValue>
							</PriceFilterRow>
						</FilterField>
					</FilterRow>
					<FilterRow>
						<FilterField>
							<label>Delivery</label>
							<div>
								{availableDeliveryMethods.map((method, index) => {
									const active = delivery.includes(method.value);
									return (
										<DeliveryTag key={index} active={active} onClick={() => toggleDeliveryTag(method.value)}>
											<DeliveryInner active={active}>{method.label}</DeliveryInner>
										</DeliveryTag>
									);
								})}
							</div>
						</FilterField>
					</FilterRow>
					<Divider />
					<ApplyFiltersWrapper>
						<StyledButton onClick={applyFilters}>Apply Filters</StyledButton>
					</ApplyFiltersWrapper>
				</div>
			</PanelModal>
		);
	}
	return null;
};

/**
 * Custom components
 */
export const selectStyles: any = {
	input: (provided: any) => {
		return {
			...provided,
			height: 27,
			fontSize: 16,
			fontWeight: 500,
		};
	},
	valueContainer: () => ({
		height: 46,
		paddingLeft: 6,
		fontSize: 16,
		borderRadius: 0,
	}),
	control: (provided: any) => ({
		...provided,
		borderRadius: 3,
		fontSize: 16,
		border: "1px solid #767977",
	}),
	singleValue: (provided: any) => ({
		...provided,
		overflow: "unset",
		fontSize: 16,
		fontWeight: 500,
	}),
	menu: (provided: any) => ({
		...provided,
		fontSize: 16,
	}),
	option: (provided: any, state: any) => ({
		...provided,
		fontWeight: 500,
		background: state.isSelected ? "#12284C" : "#FFFFFFF",
		":hover, :active": {
			background: "#12284C",
			color: "#FFFFFF",
		},
	}),
};

const getAvailableDeliveryMethods = (tickets: Nulleable<TicketResponse>, methods: any) => {
	if (!tickets || !methods) {
		return [];
	}
	let deliveryMethods: {
		label: string;
		value: string;
	}[] = [];
	//@ts-ignore
	methods.forEach(method => {
		if (tickets.some((ticket: any) => ticket.tgDeliveryMethods.includes(method.delivery_method)))
			deliveryMethods.push({
				label: method.delivery_method,
				value: method.delivery_method,
			});
	});
	return deliveryMethods;
};

const getPriceLimits = (tickets: TicketResponse | null) => {
	let min = 0,
		max = 0;
	if (!!tickets) {
		let prices: number[] = [];
		tickets.forEach(ticket => {
			const numericPrice = parseFloat(ticket.tgPrice as any);
			if (!isNaN(numericPrice)) {
				prices.push(numericPrice);
			}
		});
		min = Math.min(...prices);
		max = Math.max(...prices);
	}
	return { min, max };
};

const DeliveryTag = styled.div<{ active: boolean }>`
	display: inline-block;
	border: 1px solid #767977;
	box-sizing: border-box;
	border-radius: 3px;
	margin-bottom: 6px;
	margin-right: 6px;
	cursor: pointer;
	${props => {
		if (props.active) {
			return `background-color: #767977;
                    color: #fff;`;
		}
		return "";
	}}
`;

const DeliveryInner = styled.div<{ active: boolean }>`
	padding: 0px 24px;
	display: flex;
	font-size: 14px;
	height: 48px;
	align-items: center;
	font-weight: 500;
`;

const PriceFilterRow = styled.div`
	display: flex;
	margin-bottom: 10px;
	align-items: center;
`;

const ClearAll = styled.div`
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 16px;
	display: flex;
	align-items: center;
	letter-spacing: 0.02em;
	cursor: pointer;
	justify-content: flex-end;
`;

const FilterRow = styled.div`
	display: flex;
	margin-bottom: 20px;
`;

const labelStyles = css`
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0.02em;
	text-align: left;
	text-transform: uppercase;
	margin-bottom: 10px;
`;

const PriceFilterValue = styled.div`
	display: block;
	width: 50%;
	flex-direction: column;
	& > label {
		${labelStyles}
		margin-bottom: 0px;
	}
`;

const Divider = styled.div`
	width: 100%;
	border-bottom: 1px solid lightgray;
	margin-bottom: 20px;
`;

const PriceDivider = styled.div`
	width: 16px;
	border-bottom: 1px solid lightgray;
	align-self: flex-end;
	margin-bottom: 22px;
	margin-left: 5px;
	margin-right: 5px;
`;

const FilterField = styled.div`
	color: black;
	display: flex;
	flex-direction: column;
	flex: 1;
	margin: 0 10px;
	& > label {
		${labelStyles}
	}
`;

const FilterFieldW40 = styled.div`
	flex-direction: column;
	margin: 0 10px;
	width: 40%;
	& > label {
		${labelStyles}
		display: block;
		color: black;
	}
`;

const FilterFieldW60 = styled.div`
	flex-direction: column;
	margin: 0 10px;
	width: 60%;
	& > label {
		${labelStyles}
		display: block;
		color: black;
	}
	& .singleValue {
		font-size: 16px !important;
		overflow: unset !important;
	}
`;

const ApplyFiltersWrapper = styled.div`
	width: 100%;
	display: block;
	text-align: center;
	margin-bottom: 20px button {
		width: 150px;
		height: 40px;
		font-size: 16px;
	}
`;

const StyledButton = styled(Button)`
	height: 50px;
`;
