import { Tier } from "../../../model/Cart";
import { Optional } from "../../../model/Utils";

/**
 * Seatics API exposed by MapWidget.
 */
export interface SeaticsAPI {
	MapComponent: SeaticsMap;
	FilterOptions: SeaticsFilterOptions;
	SortOptions: SeaticsSortOptions;
	DeliveryType: SeaticsDeliveryType;
	config: SeaticsConfig;
	FilterOptionExtensions: any;
	TrackingEvents: SeaticsTrackingEvents;
	MapPresentationInterface: SeaticsMapPresentationInterface;
}

export interface SeaticsMapPresentationInterface {
	appendLegentHtml: () => void;
	onMapLoaded: () => void;
	mapSelectionMade: () => void;
}

/**
 * Segment of grouped tickets.
 */
export interface SeaticsTicketSegment {
	type: number;
	/**
	 * Title of the segment. Empty for standard event ticket type.
	 */
	title?: string;
	/**
	 * Array of ticketGroup
	 */
	tickets: SeaticsTicketGroup[];
}

export interface SeaticsTicketGroup {
	tiers: Tier[];
	tgUserRow: string;
	tgUserSec: string;
	tgID: number;
	tgColor: string;
	tgCanonSec: string;
	getSectionDisplayName: () => string;
	getRowDisplayName: () => string;
	splits: number[];
	tgQty: number;
	tgPrice: number;
	tgNotes?: string;
	tgFaceValue?: number;
	tgSource?: string;
	primaryTicketingGroupId?: number | null;
	tgDlvOpt: {
		email: boolean;
		instantDownload: boolean;
		localPickup: boolean;
		mobileEntry: boolean;
		paperless: boolean;
	};
	tgClientData: {
		tgDeliveryMethods: string[];
		tgSplits: number[];
		expectedShipByDate: Optional<string>;
		tgTransactionFeeRate: number;
		tgTaxRate?: number;
		tgServiceFeeRate?: number;
	};
	section: {
		id?: string;
		vfs: string;
		vfsUrl: string;
		vfsUrlLarge: string;
		visible: boolean;
		level?: SeaticsLevel;
	};
}

export interface SeaticsLevel {
	id?: string;
	color: string;
	lighterColor?: string;
}

export interface SeaticsLegendItem {
	color: string;
	getFromPrice: () => number;
	hasTickets: () => number;
	id: number;
	isSelected: boolean;
	isZone: boolean;
	name: string;
}

/**
 * Seatics Map Component interface.
 */
export interface SeaticsMap {
	create: (args: {
		imgSrc?: any;
		tickets: any[];
		mapData?: any;
		vfsUrl: string;
		container: any;
		mapWidth: number;
		mapHeight: number;
		mapName: any;
		enableSectionInfoPopups: boolean;
		presentationInterface: {
			updateTicketsList: (data: SeaticsTicketSegment[]) => void;
			getMapViewport?: Function;
		};
	}) => void;
	clear: () => void;
	addTicketData: (data: any[]) => void;
	onTicketListDrawn: () => void;
	removeHighlight: () => void;
	highlightTicket: (id: SeaticsTicketGroup | null) => void;
	highlightLegendItem: (id: SeaticsLegendItem | {}) => void;
	setFilterOptions: (options: SeaticsFilterOptions) => void;
	getFilterOptions: () => SeaticsFilterOptions;
	getAvailableLegendItems: () => SeaticsLegendItem[];
	toggleSelectionByLegendItem: (item: SeaticsLegendItem) => void;
}

export interface SeaticsFilterOptions {
	/**
	 * Creates a new SeaticsFilterOptions object
	 */
	new (): SeaticsFilterOptions;
	/**
	 * Filter Seatics ticket groups that don't have this quantity available
	 */
	quantity?: number;
	/*
	 * Filter Seatics ticket groups with total ticket counts below this leve
	 */
	minQuantity?: number;
	/**
	 * How the ticket list should be ordered
	 */
	sortType: SeaticsSortOptions["PriceAsc"] | SeaticsSortOptions["PriceDesc"] | SeaticsSortOptions["SectionAsc"] | SeaticsSortOptions["SectionDesc"];
	/**
	 * Filter by special delivery types
	 */
	deliveryTypeFilters: SeaticsDeliveryType[];
	/**
	 * Filter out tickets with a price below this value
	 */
	minPrice: number;
	/**
	 * Filter out tickets with a price above this value
	 */
	maxPrice: number;
}

export interface SeaticsSortOptions {
	/*
	 * price low to high
	 */
	PriceAsc: any;
	/**
	 * (price high to low
	 */
	PriceDesc: any;

	/**
	 * Section near to far
	 */
	SectionAsc: any;
	/**
	 * section far to near
	 */
	SectionDesc: any;
}

/**
 * Seatics Ticket delivery types
 */
export interface SeaticsDeliveryType {
	ETickets: any;
	InstantDownload: any;
	LocalPickup: any;
	MobileEntry: any;
}

export interface SeaticsConfig {
	noMapImageUrl?: string | null;
}

export interface SeaticsTrackingEvents {
	registerEventListener: (eventType: (type: string, data: any) => void) => void;
}

export enum TicketDeliveryType {
	ETicket = "E-Ticket",
	Mobile = "Mobile",
	FedEx = "FedEx",
	AXSMobileTicket = "AXS Mobile Ticket",
	LocalPickup = "Local Pickup",
	WillCall = "Will Call",
	GuestList = "Guest List",
}

export enum TicketSortOptions {
	PriceAsc = "PriceAsc",
	PriceDesc = "PriceDesc",
	SectionAsc = "SectionAsc",
	SectionDesc = "SectionDesc",
}
