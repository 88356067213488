import React, { useState } from "react";
import { getAppColor } from "../../util/appColors";
import styled from "styled-components/macro";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";
import { Transition } from "react-transition-group";

const dots = [1, 2, 3, 4];

export const FourDotLoader = (props: StyledComponentProps) => {
	const [showDots, setShowDots] = useState(true);

	return (
		<div
			className={props.className}
			css={`
				display: flex;
			`}
		>
			{dots.map((item, index) => {
				return (
					<Transition
						timeout={{
							enter: 250 * index,
						}}
						in={showDots}
						appear
						onEntered={() => {
							if (index + 1 === dots.length) {
								setTimeout(() => {
									setShowDots(false);
									setTimeout(() => {
										setShowDots(true);
									}, 350);
								}, 350);
							}
						}}
					>
						{state => {
							return (
								<Dot
									key={index}
									style={{
										opacity: state === "entered" ? 1 : 0,
									}}
								/>
							);
						}}
					</Transition>
				);
			})}
		</div>
	);
};

const dotSize = "10px";

const Dot = styled.div`
	width: ${dotSize};
	height: ${dotSize};
	border-radius: 100%;
	background: ${getAppColor("primary")};
	transition: opacity 150ms ease-in;
	&:not(:last-child) {
		margin-right: 20px;
	}
`;
