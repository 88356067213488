import { useEffect, useState, useDebugValue } from "react";

// https://developer.zendesk.com/api-reference/widget/core/
const displayZendeskChatWidget = (displayWidget: boolean) => {
	if (typeof zE !== "undefined") {
		zE("webWidget", displayWidget ? "show" : "hide");
		if (!displayWidget) {
			zE("webWidget", "close");
		}
	}
};

export const useShowZendeskChatWidget = (showWidgetByDefault?: boolean) => {
	useDebugValue("useShowZendeskChatWidget");

	const [showZendeskChatWidget, setShowZendeskChatWidget] = useState(showWidgetByDefault === undefined ? false : showWidgetByDefault);

	useEffect(() => {
		if (showZendeskChatWidget) {
			displayZendeskChatWidget(true);
		} else {
			displayZendeskChatWidget(false);
		}
		return () => {
			if (!showZendeskChatWidget) {
				displayZendeskChatWidget(true);
			}
		};
	}, [showZendeskChatWidget, typeof zE]);

	return {
		showZendeskChatWidget,
		setShowZendeskChatWidget,
	};
};
