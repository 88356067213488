import React from "react";
import styled from "styled-components/macro";

export const ArrowControl = (props: { src: string; direction: "left" | "right"; onClick: () => void; alt: string, disabled?: boolean }) => {
	return <CarouselControl {...props} />;
};

const CarouselControl = styled.img<{ direction: "left" | "right", disabled?: boolean }>`
	${props =>
		props.direction === "left"
			? `
    transform: rotate(180deg);
	left: 0;`
			: `
	right: 0;
  `}

	${props =>
		props.disabled
		&& `
	pointer-events: none;
    opacity: 0.4;
	`}

	padding: 0 21px;
	position: absolute;
	top: 45%;
	cursor: pointer;
`;
