import styled, { css } from "styled-components/macro";
import DayPicker from "react-day-picker";
import { mediaQueries } from "../../util/mediaQueries";

/**
 * Modal
 */
export const DateRangeModalHeader = styled.div`
	height: 50px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	border-bottom: 1px solid #ddd;
	box-shadow: 0px 3px 3px -3px #c9c9c9;
`;

export const DateRangeModalHeaderText = styled.span`
	font-weight: bold;
	font-size: 16px;
	color: black;
`;

export const DateRangeModalContent = styled.div`
	padding: 0 10px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
`;

export const QuantityModalContent = styled.div`
	padding: 15px 10px 0 10px;
`;

export const PriceModalContent = styled.div`
	padding: 15px 0 0 0;
	.rheostat {
		margin: 15px;
	}
`;

export const CloseButtonImage = styled.img`
	float: right;

	&:hover {
		cursor: pointer;
	}
`;

export const OptionButton = styled.div<{ selected?: boolean }>`
	text-align: center;
	padding: 8px 5px;
	min-width: 240px;
	max-width: 300px;
	margin: 10px auto;
	border-radius: 10px;
	cursor: pointer;
	user-select: none;
	font-family: "Avenir", sans-serif;
	font-weight: 600;
	font-size: 16px;
	color: ${props => (props.selected ? "white" : "#3d3d3d")};
	border: ${props => (props.selected ? "1px solid #3d3d3d" : "1px solid #3d3d3d")};
	background-color: ${props => (props.selected ? "#3d3d3d" : "initial")};
`;

/**
 * Filter components
 */

export const filterButtonStyles = css`
	background: transparent;
	color: black;
	border: 1px solid black;
	font-family: "Montserrat";
	font-weight: 500;
	border-radius: 11px;
	font-size: 11px !important;

	&:disabled,
	&[disabled] {
		cursor: default;
		color: #1a1a1a;
	}
`;

export const FilterButton = styled.button<{
	ref?: any;
	active?: boolean;
}>`
	display: flex;
	box-sizing: border-box;
	outline: 0;
	cursor: pointer;
	appearance: none;
	width: 100%;
	text-align: center;
	text-align-last: center;
	overflow: hidden;
	white-space: nowrap;
	justify-content: center;

	${filterButtonStyles}

	padding: 0 0.5rem;
	line-height: 2.5;
`;

export const StyledDayPicker = styled(DayPicker)`
	&
	 .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
		background-color: #E5E5E5; !important;
		color: #000000;
	}
	& .DayPicker-Day {
		border-radius: 0 !important;
	}
	& .DayPicker-Day--start:not(.DayPicker-Day--outside), .DayPicker-Day--end:not(.DayPicker-Day--outside) {
		background-color: rgba(18, 40, 76, 1) !important;
	}
	& .DayPicker-wrapper {
		padding-bottom: 0;
	}
	& .DayPicker-Month {
		padding: 0 1px;
		margin: 1em 2.5em;
	}
	&
	 .DayPicker-Day--today {
		color: rgba(18, 40, 76, 1);
	}
  	font-size: 18px;
	border-bottom: 1px solid #E5E5E5;

	@media(${mediaQueries.max500}) {
		border-bottom: none;
	}
	padding: 0 20px;
`;

export const StyledSelect = styled.div`
	width: 100%;
	color: black;
	display: flex;
	flex-direction: column;
	& > label {
		font-family: Montserrat;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 20px;
		letter-spacing: 0.02em;
		text-align: left;
		text-transform: uppercase;
		margin-bottom: 10px;
	}
`;

/**
 * Price filter
 */

export const PriceInput = styled.input`
	position: relative;
	padding-left: 18px;
	border-radius: 3px;
	border: 1px solid #767977;
	height: 48px;
	font-family: Montserrat;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: center;
`;

export const PriceContainer = styled.div`
	position: relative;
	&:before {
		content: "$";
		position: absolute;
		left: 10px;
		top: 50%;
		transform: translateY(-50%);
		color: #555;
		z-index: 1;
		font-size: 16px;
		font-family: "Avenir", sans-serif;
	}
`;

export const PriceLabel = styled.label`
	text-align: center;
	display: block;
	font-family: Montserrat;
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
`;

export const PriceDash = styled.div`
	margin: 0 5px;
	display: block;
	font-family: Montserrat;
	font-size: 16px;
	font-weight: 700;
`;

export const MinLabel = styled.div.attrs(() => ({
	children: "Min. Price",
}))`
	margin-top: 16px;
	float: left;
	font-size: 10px;
	line-height: 1;
	margin-left: -10px;
`;

export const MaxLabel = styled.div.attrs(() => ({
	children: "Max. Price",
}))`
	margin-top: 16px;
	float: right;
	font-size: 10px;
	line-height: 1;
	margin-right: -10px;
`;

export const ProgressDot = styled.div<{ position: "left" | "right" }>`
	${props => (props.position === "left" ? "left:0;" : "right:0;")}
	position: absolute;
	top: -3px;
	width: 7px;
	height: 7px;
	background: #e5e5e5;
	border: 1px solid #e5e5e5;
	border-radius: 100%;
`;

export const FilterTitle = styled.div`
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	letter-spacing: 0.02em;
	color: #000000;
	align-self: flex-start;
	margin-bottom: 5px;
	text-transform: uppercase;
`;
