import React, { useEffect, useState } from "react";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";
import { ModalPosition } from "./ModalPosition";
import { ModalOverlay, ModalOverlayState } from "./styles";

export interface ModalProps {
	/** Fade in/out animation duration in seconds */
	animationLength?: number;

	/** Position in the screen. Defaults to Bottom for mobile, Center for desktop */
	position?: ModalPosition;

	backdropColor?: string;
	onClose: () => void;
	onVisibilityChange?: (visible: boolean) => void;
	show?: boolean;
	children?: React.ReactNode;
}

export const Modal = ({ animationLength = 0.3, backdropColor = "rgba(0, 0, 0, 0.8)", onClose, onVisibilityChange, show = true, position, children, className }: ModalProps & StyledComponentProps) => {
	const propState = show ? ModalOverlayState.Visible : ModalOverlayState.Hidden;
	const [state, setState] = useState(propState);

	useEffect(() => {
		if (!show) return;

		const initialScrollX = window.scrollX;
		const initialScrollY = window.scrollY;
		const initialTop = document.body.style.top;
		const initialPosition = document.body.style.position;

		document.body.style.top = `-${initialScrollY}px`;
		document.body.style.position = "fixed";
		return () => {
			document.body.style.position = initialPosition;
			document.body.style.top = initialTop;
			window.scrollTo(initialScrollX, initialScrollY);
		};
	}, [show]);

	useEffect(() => {
		if (propState === state) return;

		setState(show ? ModalOverlayState.Showing : ModalOverlayState.Hiding);
		const handle = setTimeout(() => {
			setState(show ? ModalOverlayState.Visible : ModalOverlayState.Hidden);
			onVisibilityChange?.(show);
		}, animationLength * 1000);
		return () => clearTimeout(handle);
	}, [show, propState, state, onVisibilityChange]);

	if (state === ModalOverlayState.Hidden) {
		return null;
	}

	return (
		<ModalOverlay animationLength={animationLength} backdropColor={backdropColor} onClick={onClose} position={position} state={state} className={className}>
			{children}
		</ModalOverlay>
	);
};
