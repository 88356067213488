import React from "react";
import { ButtonLink } from "../ButtonLink/ButtonLink";
import styled from "styled-components/macro";
import { FontFamily } from "../../Typography/Typography";
import { breakpoints } from "../../../util/breakpoints";

export const MainEventsCards = ({ image, title, subtitle, link }: { image: string; title: string; subtitle: string; link: string }) => {
	return (
		<CardWrapper>
			<CardImage src={image} alt={title} />
			<CardTitle>{title}</CardTitle>
			<CardSubtitle>{subtitle}</CardSubtitle>
			<ButtonLink href={link}>Get Tickets</ButtonLink>
		</CardWrapper>
	);
};

const CardWrapper = styled.div`
	width: 50%;
	background-color: #012e40;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 1.5em;
	padding-bottom: 1.5em;

	@media (max-width: ${breakpoints.tablet}) {
		width: 80%;
		margin: 1em;
	}
	@media (max-width: ${breakpoints.mobile}) {
		width: 80%;
		margin: 1em;
	}
`;

const CardImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

const CardTitle = styled.h2`
	text-transform: uppercase;
	font-size: 30px;
	color: #f2f2f2;
	font-family: ${FontFamily.Solano};
	margin-top: 0.5em;
`;

const CardSubtitle = styled.h2`
	text-transform: uppercase;
	font-size: 70px;
	line-height: 50px;
	font-family: ${FontFamily.Solano};
	font-weight: 700;
	margin-bottom: 0.5em;
	color: #f2f2f2;
`;
