import { LoadingScreenData, LoadingScreenDataActions } from "../reducers/loadingScreenDataReducer";

export const setLoadingScreenData = (data: LoadingScreenData): LoadingScreenDataActions => {
	return {
		type: "SET_LOADING_SCREEN_DATA",
		data,
	};
};

export const resetLoadingScreenData = (): LoadingScreenDataActions => {
	return {
		type: "RESET_LOADING_SCREEN_DATA",
	};
};
