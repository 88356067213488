import React from "react";
import { CommonPage } from "../components";
import { RouteComponentProps } from "react-router-dom";
import { parseSearch } from "../util/parseSearch";
import { useSelector } from "react-redux";
import { getDisabledRoute } from "../hocs/withMaintenanceRedirection";
import { useHeaderHeight } from "../hooks/useHeaderHeight";
import { Button } from "../components/Button/Button";
import { maxContentWidthStyles } from "../util/maxContentWidth";
import { Redirect } from "react-router-dom";
import { ModalPopup } from "../components/ModalPopup/ModalPopup";
import { ModalBackButton } from "../components/Modal/ModalBackButton";
import maintenance_background from "../resource/image/maintenance-background.png";
import styled from "styled-components/macro";

const PageHeight = "100vh";
const PageWidth = "100vw";

interface LoginSearchParams {
	from?: string;
}

interface MaintenancePageProps extends RouteComponentProps {
	onBackButtonClick: () => void;
}

export const MaintenanceRoute = (props: RouteComponentProps) => {
	const headerHeight = useHeaderHeight();

	const onBackButtonClick = () => {
		props.history.go(-1);
	};

	return (
		<CommonPage background="#ffffff">
			<PageContainer style={{ minHeight: `calc(${PageHeight} - ${headerHeight}px)` }}>
				<MaintenanceRoutePage {...{ ...props, onBackButtonClick }} />
			</PageContainer>
		</CommonPage>
	);
};

export const MaintenanceModalRoute = (props: RouteComponentProps) => {
	const onBackButtonClick = () => {
		props.history.go(-1);
	};

	return (
		<StyledModalPopup fullscreen hideLogo backgroundColor="#ffffff" leftHeaderElements={<ModalBackButton onClick={onBackButtonClick} />}>
			<MaintenanceRoutePage {...{ ...props, onBackButtonClick }} />
		</StyledModalPopup>
	);
};

const MaintenanceRoutePage = (props: MaintenancePageProps) => {
	const { from } = parseSearch<LoginSearchParams>(props.location.search);
	const disabledRoutes = useSelector(state => state.persistent.siteStatus);

	// Back to disabled page if it comes back to live
	const disabledRoute = getDisabledRoute(disabledRoutes, from);
	if (!disabledRoute) {
		return <Redirect to={from} />;
	}

	return (
		<Content>
			<h2> {disabledRoute.message1 || "We are experiencing high demand and need a moment"}</h2>
			<p> {disabledRoute.message2 || "Our team is on it so please check back shortly"}</p>
			<Button onClick={props.onBackButtonClick}>Back to previous screen</Button>
		</Content>
	);
};

const StyledModalPopup = styled(ModalPopup)`
	height: ${PageHeight};
	width: ${PageWidth};
	max-width: ${PageWidth};

	& > div:last-child {
		height: 80%;
	}

	h2 {
		font-family: "SolanoGothicMVB-Bd";
		font-size: 30px;
		line-height: 34px;
		letter-spacing: -0.02em;
		text-transform: uppercase;
	}

	p {
		font-family: Montserrat;
		font-size: 22px;
		font-weight: 500;
		line-height: 28px;
		margin-top: 20px;
	}

	button {
		margin-top: 32px;
	}
`;

const PageContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-image: url(${maintenance_background});
	background-size: cover;
	background-repeat: no-repeat;

	h2 {
		font-family: "SolanoGothicMVB-Bd";
		font-size: 60px;
		line-height: 68px;
		letter-spacing: -0.02em;
		text-transform: uppercase;
	}

	p {
		font-family: Montserrat;
		font-size: 30px;
		font-weight: 500;
		line-height: 36px;
		margin-top: 20px;
	}

	button {
		margin-top: 66px;
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: black;
	text-align: center;
	height: 100%;
	${maxContentWidthStyles}
`;
