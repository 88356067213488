import parsePhoneNumber from "libphonenumber-js/max";

const defaultCountryCode = "US";

export const renderPhoneNumber = (phoneNumber: string) => {
	const parsedPhoneNumber = parsePhoneNumber(phoneNumber, defaultCountryCode);
	if (parsedPhoneNumber) {
		return parsedPhoneNumber.country === defaultCountryCode ? parsedPhoneNumber.formatNational() : parsedPhoneNumber.formatInternational();
	}
};
