import React from "react";
import { mediaQueries } from "../../util/mediaQueries";
import styled from "styled-components/macro";
import { FanGuaranteeData } from "./FanGuaranteeData";

export interface FanGuaranteeCardProps {
	icon: string;
	title: string;
	description: React.ReactNode;
}

export const FanGuaranteeCards = () => {
	return FanGuaranteeData.map((data, index) => {
		return <FanGuaranteeCard key={index} {...data} />;
	});
};

const FanGuaranteeCard = (props: FanGuaranteeCardProps) => {
	return (
		<Card>
			<Icon src={props.icon} />
			<CardContent>
				<Title>{props.title}</Title>
				<Description>{props.description}</Description>
			</CardContent>
		</Card>
	);
};

const Card = styled.div`
	border: solid 1px #767977;
	padding: 30px;
	background: #fff;
	margin-top: 25px;
	@media ${mediaQueries.max670} {
		margin-top: 0px;
		padding: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;

const Icon = styled.img`
	float: left;
	margin-top: -10px;
	@media ${mediaQueries.max670} {
		float: none;
		margin-top: 0px;
		width: 60px;
		margin-bottom: 24px;
	}
`;

const CardContent = styled.div`
	padding-left: 80px;
	@media ${mediaQueries.max670} {
		padding-left: 0px;
		text-align: left;
	}
`;

const Title = styled.h2`
	font-family: "SolanoGothicMVB-Bd" !important;
	font-weight: 700;
	font-size: 30px;
	text-transform: uppercase;
	text-align: left;
	margin-bottom: 16px;
	color: #000;
	@media ${mediaQueries.max670} {
		font-size: 24px;
		line-height: 30px;
	}
`;

const Description = styled.p`
	font-family: "Montserrat", sans-serif;
	text-align: left;
	font-size: 18px;
	line-height: 24px;
	font-weight: 500;
	color: #000;
	margin-top: 10px;
	@media ${mediaQueries.max670} {
		font-size: 16px;
		line-height: 22px;
	}
`;
