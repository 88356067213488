import { Address } from "../../model/User";

//TODO: Rename to formatAddress
export function renderAddress(address?: Address | null) {
	if (address) {
		let value = "";
		const addressKeyOrder: (keyof Address)[] = ["address1", "address2", "city", "zip", "state", "country"];
		addressKeyOrder.forEach((key, index) => {
			const newValue = address[key];
			if (newValue) {
				value += newValue;
				if (index !== addressKeyOrder.length - 1) {
					value += ", ";
				}
			}
		});
		return value;
	}
}
