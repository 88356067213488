import { Reducer } from "redux";
import { User, UserAddress } from "../../model/User";
import { SessionAction } from "./sessionReducer";

export const guestReducer: Reducer<User | null, GuestActions | SessionAction> = (state = null, action) => {
	switch (action.type) {
		case "SET_GUEST":
			return action.guest;
		case "ADD_GUEST_ADDRESS":
			if (state) {
				const addresses = state?.addresses;
				state?.addresses.push(action.address);
				return {
					...state,
					addresses,
				};
			}
			return null;
		case "SET_GUEST_NAME":
			if (state) {
				return {
					...state,
					fname: action.name.fname,
					lname: action.name.lname,
				};
			}
			return null;
		default:
			return state;
	}
};

export const updateGuest = (guest: User | null): GuestActions => ({
	type: "SET_GUEST",
	guest,
});

export const addGuestAddress = (address: UserAddress): GuestActions => ({
	type: "ADD_GUEST_ADDRESS",
	address,
});

export const updateGuestName = (name: Pick<User, "fname" | "lname">): GuestActions => ({
	type: "SET_GUEST_NAME",
	name,
});

export type GuestActions =
	| {
			type: "SET_GUEST";
			guest: User | null;
	  }
	| {
			type: "ADD_GUEST_ADDRESS";
			address: UserAddress;
	  }
	| {
			type: "SET_GUEST_NAME";
			name: Pick<User, "fname" | "lname">;
	  };
