import React from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { CommonPage } from "../components/CommonPage";
import { MetaDecorator, MetaTagData } from "../components/SEOMetadecorator/MetaDecorator";

interface NotFoundProps {
	title: string;
	message: string;
}

export const NotFoundView = (props: NotFoundProps) => (
	<NotFoundBody>
		<div>
			<Title>{props.title}</Title>
			<Message>{props.message}</Message>
		</div>
	</NotFoundBody>
);

export const NotFound = (props: RouteComponentProps<{}, any, Partial<NotFoundProps>>) => (
	<CommonPage>
		<MetaDecorator title={MetaTagData.NotFound.title} description={MetaTagData.NotFound.description} />
		<NotFoundView title={props.location.state?.title ?? "404"} message={props.location.state?.message ?? "The requested page was not found on this server"} />
	</CommonPage>
);

const NotFoundBody = styled.div`
	height: 50vh;
	background-color: #fafafa;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const Title = styled.p`
	font-size: 3rem;
	font-weight: 600;
	color: #767977;
	line-height: 2;
`;

const Message = styled.p`
	font-size: 1rem;
	color: #767977;
`;
