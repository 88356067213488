import { useMemo, useDebugValue, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useMemoState } from "../../presentation/hooks/useMemoState";
import { useAutoUpdateState } from "../../presentation/hooks/useAutoUpdateState";
import { PaymentMethodType } from "../../model/optimizedModel/braintree";
import { getTotalTicketPrice, getTransactionFee } from "../../presentation/util/prices";
import { useTicketInvoiceOutput } from "../../presentation/components/Invoices/TicketInvoice";

export const useCart = () => {
	useDebugValue("useCart");

	const cart = useSelector(st => st.persistent.cart);

	const delivery = useMemo(() => {
		return cart?.tickets && cart?.tickets[0] ? cart?.tickets[0].delivery : undefined;
	}, [cart?.tickets]);

	const optionsArray = useMemo(() => {
		return delivery?.options ? Object.values(delivery.options) : [];
	}, [delivery]);

	const multipleDeliveryOptions = useMemo(() => {
		return optionsArray.length > 1;
	}, [optionsArray]);

	const multipleItemsInCart = useMemoState(() => {
		if (cart) {
			const value = cart.tickets.length + Object.values(cart.futures).length > 1;
			return value;
		}
	}, [cart?.tickets, cart?.futures]);

	const ticket = useMemoState(() => {
		const value = cart?.tickets[0];
		return value;
	}, [cart?.tickets]);

	const cartFuturesKeys = useMemoState(() => {
		if (cart) {
			const value = Object.keys(cart.futures);
			return value;
		}
	}, [cart?.futures]);

	const futures = useAutoUpdateState(() => {
		if (cart) {
			return cartFuturesKeys?.map(key => cart?.futures[key]);
		}
	}, [cartFuturesKeys, cart?.futures]);

	const totalReservationPrice = useMemoState(() => {
		//TODO: Consider implementing similar logic from the previous implementation of the logic since it might perform better.
		let value = 0;
		if (cart?.futures) {
			cartFuturesKeys?.forEach(key => {
				const future = cart.futures[key];
				value += future.zone.reservationPrice * future.quantity;
			});
		}
		return value;
	}, [cartFuturesKeys]);

	const {
		subtotal: ticketSubtotal,
		calculatedServiceFee,
	} = useTicketInvoiceOutput({
		tiersInfo: cart?.tickets[0]?.tiers,
		price: ticket?.retailPrice || 0,
		quantity: ticket?.quantity || 0,
		serviceFeeRate: cart?.serviceFeeRate,
	});

	const transactionFees = useAutoUpdateState(() => {
		if (cart?.tickets && cart.tickets.length > 0) {
			return {
				VenmoAccount: getTransactionFee(cart?.tickets[0].transactionFeeRules?.VenmoAccount, ticket?.retailPrice, ticket?.quantity, cart.source, ticket?.tiers),
				PayPalAccount: getTransactionFee(cart?.tickets[0].transactionFeeRules?.PayPalAccount, ticket?.retailPrice, ticket?.quantity, cart.source, ticket?.tiers),
				CreditCard: getTransactionFee(cart?.tickets[0].transactionFeeRules?.CreditCard, ticket?.retailPrice, ticket?.quantity, cart.source, ticket?.tiers),
				PromoWallet: getTransactionFee(cart?.tickets[0].transactionFeeRules?.PromoWallet, ticket?.retailPrice, ticket?.quantity, cart.source, ticket?.tiers),
			};
		}
		return undefined;
	}, [cart?.tickets]);

	const cartPrices = useAutoUpdateState<Record<PaymentMethodType | "PromoWallet", number> | undefined>(() => {
		const getTotalCartPrice = (transactionFee: number | undefined) =>
			getTotalTicketPrice({
				subtotal: ticketSubtotal,
				deliveryFee: cart?.shippingFee,
				serviceFee: cart?.serviceFee ? cart.serviceFee : calculatedServiceFee,
				transactionFee,
			}) + totalReservationPrice;
		if (cart) {
			return {
				VenmoAccount: getTotalCartPrice(transactionFees?.VenmoAccount),
				PayPalAccount: getTotalCartPrice(transactionFees?.PayPalAccount),
				CreditCard: getTotalCartPrice(transactionFees?.CreditCard),
				PromoWallet: getTotalCartPrice(transactionFees?.PromoWallet),
			};
		}
		return undefined;
	}, [ticketSubtotal, cart, calculatedServiceFee, transactionFees, totalReservationPrice]);

	const flatRatePrice = useAutoUpdateState(() => {
		if (cartPrices) {
			const priceArray = Object.values(cartPrices);
			return priceArray.every(price => priceArray[0] === price) ? priceArray[0] : undefined;
		}
		return undefined;
	}, [cartPrices]);

	const isCombinedCart = useAutoUpdateState(() => !!ticket && !!cartFuturesKeys?.length, [ticket, cartFuturesKeys]);

	const cartIsFree = useAutoUpdateState(() => {
		return !cartPrices?.VenmoAccount && !cartPrices?.PayPalAccount && !cartPrices?.CreditCard && !cartPrices?.PromoWallet;
	}, []);

	return {
		cart,
		cartId: cart?.cartId,
		multipleDeliveryOptions,
		optionsArray,
		delivery,
		multipleItemsInCart,
		ticket,
		cartFuturesKeys,
		futures,
		totalReservationPrice,
		cartPrices,
		flatRatePrice,
		transactionFees,
		isCombinedCart,
		ticketSubtotal,
		cartIsFree,
	};
};
