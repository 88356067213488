import { DataCollector } from "braintree-web";
import { Reducer } from "redux";
import { PaymentMethodWithToken } from "../../model/optimizedModel/braintree";

export interface BraintreeState {
	clientAuth?: string;
	dataCollector?: DataCollector;
	paymentMethods?: PaymentMethodWithToken[];
	venmoSupported?: boolean;
	venmoDeepLinkUrl?: string;
	hasPayPal?: boolean;
}

export const defaultBraintreeState: BraintreeState = {};

const isPayPalInPaymentMethods = (paymentMethods: PaymentMethodWithToken[]) => {
	const value = !!paymentMethods.find(paymentMethod => {
		return paymentMethod.type === "PayPalAccount";
	});
	return value;
};

export const braintreeReducer: Reducer<BraintreeState, BraintreeActions> = (state: BraintreeState = defaultBraintreeState, action: BraintreeActions) => {
	switch (action.type) {
		case "SET_BRAINTREE_CLIENT_AUTH":
			return {
				...state,
				clientAuth: action.clientAuth,
			};
		case "SET_BRAINTREE_DATA_COLLECTOR":
			return {
				...state,
				dataCollector: action.dataCollector,
			};
		case "SET_BRAINTREE_PAYMENT_METHODS":
			const paymentMethods = action.paymentMethods;
			return {
				...state,
				paymentMethods,
				hasPayPal: isPayPalInPaymentMethods(paymentMethods),
			};
		case "ADD_BRAINTREE_PAYMENT_METHOD":
			const updatedPaymentMethods = (() => {
				const arr = state.paymentMethods ? state.paymentMethods : [];
				const value = [...arr, action.paymentMethod];
				return value;
			})();
			return {
				...state,
				paymentMethods: updatedPaymentMethods,
				hasPayPal: isPayPalInPaymentMethods(updatedPaymentMethods),
			};
		case "REMOVE_BRAINTREE_PAYMENT_METHOD":
			const arr = state.paymentMethods;
			arr?.splice(action.index, 1);
			return {
				...state,
				paymentMethods: arr,
				...(arr && {
					hasPayPal: isPayPalInPaymentMethods(arr),
				}),
			};
		case "SET_BRAINTREE_VENMO_SUPPORTED":
			return {
				...state,
				venmoSupported: action.venmoSupported,
			};
		case "SET_BRAINTREE_VENMO_DEEP_LINK_URL":
			return {
				...state,
				venmoDeepLinkUrl: action.venmoDeepLinkUrl,
			};
		case "RESET_BRAINTREE_STATE":
			return defaultBraintreeState;
		default:
			return state;
	}
};

export type BraintreeActions =
	| {
			type: "SET_BRAINTREE_CLIENT_AUTH";
			clientAuth: string;
	  }
	| {
			type: "SET_BRAINTREE_DATA_COLLECTOR";
			dataCollector: DataCollector;
	  }
	| {
			type: "SET_BRAINTREE_PAYMENT_METHODS";
			paymentMethods: PaymentMethodWithToken[];
	  }
	| {
			type: "SET_BRAINTREE_VENMO_SUPPORTED";
			venmoSupported: boolean;
	  }
	| {
			type: "RESET_BRAINTREE_STATE";
	  }
	| {
			type: "SET_BRAINTREE_VENMO_DEEP_LINK_URL";
			venmoDeepLinkUrl: string;
	  }
	| {
			type: "ADD_BRAINTREE_PAYMENT_METHOD";
			paymentMethod: PaymentMethodWithToken;
	  }
	| {
			type: "REMOVE_BRAINTREE_PAYMENT_METHOD";
			index: number;
	  };
