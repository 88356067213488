import * as t from "io-ts";
import { MetadataCodec } from "./MetadataResponseCodec";
import { RelabelCodec } from "./util/RelabelCodec";
import { DateTimeCodec } from "./util/DateTimeCodec";
import { StringifiedNumberCodec } from "./util/StringifiedNumberCodec";

const PerformerCodec = t.intersection([
	RelabelCodec("performer_name", "name", t.string),
	RelabelCodec("performer_id", "id", t.number),
	RelabelCodec("performer_slug", "slug", t.string)], "");

const VenueCodec = t.intersection([RelabelCodec("venue_name", "venueName", t.string), RelabelCodec("venue_state", "venueState", t.union([t.string, t.null])), RelabelCodec("venue_city", "venueCity", t.union([t.string, t.null])), RelabelCodec("venue_zip", "venueZip", t.union([t.string, t.null, t.undefined])), RelabelCodec("venue_id", "venueId", t.number)]);

const RegistrationFormFieldsCodec = t.intersection([
	t.strict(
		{
			id: t.number,
			label: t.string,
			options: t.string,
		},
		""
	),
	RelabelCodec("field_type", "fieldType", t.string)], "");
const RegistrationFormCodec = t.intersection([RelabelCodec("id", "id", t.number), RelabelCodec("form_type", "formType", t.union([t.string, t.null, t.undefined])), RelabelCodec("register_form_title", "title", t.string), RelabelCodec("registration_form_fields", "fields",  t.array(t.union([RegistrationFormFieldsCodec, t.unknown, t.undefined, t.null])))]);

export const CommonEventCodec = t.intersection(
	[
		RelabelCodec("event_id", "id", t.number),
		RelabelCodec("event_name", "name", t.string),
		RelabelCodec("event_slug", "slug", t.string),
		RelabelCodec("event_image", "image", t.string),
		t.intersection(
			[
				VenueCodec,
				t.intersection([
					RelabelCodec("occurs_at", "date", t.union([DateTimeCodec, t.undefined])),
					RelabelCodec("end_date", "endDate", t.union([DateTimeCodec, t.null, t.undefined])),
					RelabelCodec("long_description", "longDescription", t.union([t.string, t.null, t.undefined])),
					RelabelCodec("registration_form", "registrationForm", t.union([RegistrationFormCodec, t.null, t.undefined])),
					t.strict(
						{
							performers: t.array(PerformerCodec),
							primary: t.union([t.boolean, t.null, t.undefined]),
						},
						""
					),
				])
			],
			""
		),
	],
	""
);

const EventVenueCodec = t.intersection([RelabelCodec("venue_location", "venueLocation", t.union([t.string, t.undefined])), RelabelCodec("venue_slug", "venueSlug", t.string),  RelabelCodec("venue_street_address", "venueStreetAddress", t.union([t.string, t.undefined]))]);

export const EventCodec = t.intersection([CommonEventCodec, EventVenueCodec, RelabelCodec("end_date", "endTime", t.union([DateTimeCodec, t.null, t.undefined])), RelabelCodec("noMapImageUrl", "noMapImageUrl", t.union([t.string, t.null, t.undefined])), t.intersection([RelabelCodec("ticket_group_price", "ticketGroupPrice", t.union([StringifiedNumberCodec, t.null, t.undefined])), RelabelCodec("sport_id", "sportId", t.union([t.number, t.null, t.undefined])), RelabelCodec("home_venue_id", "homeVenueId", t.union([t.number, t.null, t.undefined])), RelabelCodec("description", "description", t.union([t.string, t.null, t.undefined]))])], "");

export const TransactionFeeRulesCodec = t.strict({
	VenmoAccount: t.strict({
		type: t.union([t.literal("FLAT_FEE"), t.literal("FLAT_FEE_PER_TICKET"), t.literal("DECIMAL")]),
		value: t.number,
	}),
	CreditCard: t.strict({
		type: t.union([t.literal("FLAT_FEE"), t.literal("FLAT_FEE_PER_TICKET"), t.literal("DECIMAL")]),
		value: t.number,
	}),
	PayPalAccount: t.strict({
		type: t.union([t.literal("FLAT_FEE"), t.literal("FLAT_FEE_PER_TICKET"), t.literal("DECIMAL")]),
		value: t.number,
	}),
	PromoWallet: t.strict({
		type: t.union([t.literal("FLAT_FEE"), t.literal("FLAT_FEE_PER_TICKET"), t.literal("DECIMAL")]),
		value: t.number,
	}),
});

const RelatedEventVenueCodec = t.intersection([RelabelCodec("venue_city", "venueCity", t.string), RelabelCodec("venue_zip", "venueZip", t.union([t.string, t.undefined])), RelabelCodec("venue_state", "venueState", t.union([t.string, t.null])), RelabelCodec("venue_name", "venueName", t.string)]);

const PrimaryEventCodec = t.intersection([RelabelCodec("primary_event_id", "primaryEventId", t.union([t.number, t.null, t.undefined])), RelabelCodec("source", "source", t.union([t.string, t.null, t.undefined])), RelabelCodec("main_event_related", "mainEventRelated", t.union([t.intersection([RelabelCodec("event_name", "eventName", t.string), RelabelCodec("event_slug", "eventSlug", t.string), RelabelCodec("occurs_at", "occursAt", t.string), RelatedEventVenueCodec]), t.null, t.undefined]))]);

export const SeaticsEventCodec = t.intersection([EventCodec, RelabelCodec("tn_event_id", "tnEventId", t.union([t.number, t.null])), RelabelCodec("delivery_method_note_parents", "deliveryMethods", t.any), RelabelCodec("transaction_fee_rules", "transactionFeeRules", TransactionFeeRulesCodec), PrimaryEventCodec], "");

export const EventsCodec = t.intersection(
	[
		t.strict(
			{
				metadata: MetadataCodec,
			},
			""
		),
		RelabelCodec("events", "data", t.array(EventCodec)),
	],
	""
);

export type EventResponse = t.TypeOf<typeof EventCodec>;

export type SeaticsEventResponse = t.TypeOf<typeof SeaticsEventCodec>;

export type EventsResponse = t.TypeOf<typeof EventsCodec>;
