import React from "react";
import styled from "styled-components/macro";
import { FuturePanel } from "./FuturePanel";
import { getHumanReadableDate } from "../../util/getHumanReadableDate";

interface GameTeam {
	teamSlug: string;
	teamTitle: string;
	from: number | null;
}

interface Game {
	name: string;
	eventSlug: string;
	venue: string;
	venueLocation: string;
	date: Date | null;
	teams: GameTeam[];
}
interface GameFuturePanelProps {
	games: Game[];
}

export const GameFuturePanel = (props: GameFuturePanelProps) => {
	return (
		<FuturePanel<Game, GameTeam>
			chooseItemName="Team"
			items={props.games}
			subItemsKey="teams"
			titleKey="name"
			renderSubItem={(data, parentItem) => {
				return {
					name: data.teamTitle,
					price: data.from,
					teamSlug: data.teamSlug,
					eventSlug: parentItem.eventSlug,
				};
			}}
			additionalContent={data => {
				return (
					<>
						{/*
            <Text>
              <strong>{"Venue: "}</strong> {data.venue}, {data.venueLocation}
            </Text>
            */}
						<Text>
							<strong>{"Date: "}</strong>
							{data.date
								? getHumanReadableDate(data.date, {
										hideTime: true,
								  })
								: "TBD"}
						</Text>
					</>
				);
			}}
		/>
	);
};

const Text = styled.p`
	font-weight: normal;
	font-size: 13px;
	color: #5f6368;
	font-weight: 500;
	margin-top: 3px;
	line-height: normal;
	&:first-child {
		margin-top: 6px;
	}
`;
