export const removeTimezoneFromDateString = (dateString: string) => {
	let value = dateString;
	if (value.length < 12) {
		const date = new Date(dateString);
		value = date.toISOString();
	}
	const lastChar = value[value.length - 1];
	if (lastChar === "Z") {
		//TODO: Consider seeing if there's a better way to remove the last character or remove the "Z" suffix
		value = value.slice(0, -1);
	}
	return value;
};

//TODO: Consider seeing if the stringType implementation can be improved
export const toBrowserLocaleString = (date: Date, options?: Intl.NumberFormatOptions | Intl.DateTimeFormatOptions, stringType?: "date" | "time") => {
	//TODO: Improve type.
	let functionKey: keyof Date = "toLocaleString";
	if (stringType) {
		functionKey = stringType === "date" ? "toLocaleDateString" : "toLocaleTimeString";
	}
	const value = date[functionKey]([], options);
	return value;
};
