import React, { useEffect, useState } from "react";
import { Card } from "../Card/Card";
import { getTypographyStyles, Typography } from "../Typography/Typography";
import styled from "styled-components/macro";
import { Button } from "../NewButton/Button";
import { getAppColor } from "../../util/appColors";
import { PartialBy } from "../../../model/optimizedModel/general";
import { shortFormatPrice } from "../../util/formatPrice";
import { Input, RFFInput } from "./Input";
import { BetHomeTeamAndAwayTeam } from "./BetHomeTeamAndAwayTeam";
import { RadioButton } from "../RadioButton/RadioButton";
import { PrimaryBorder } from "../PrimaryBorder/PrimaryBorder";
import { BetEntity } from "./BetEntity";
import { flexGap } from "../../util/optimized/styles";
import { BetDate } from "./BetDate";
import { betTeamComparisonSymbol } from "./util/betConsts";
import { useAutoUpdateState } from "../../hooks/useAutoUpdateState";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";
import { bottomDialogSpacing, bottomDialogSpacingUnit, Dialog, verticalDialogSpacing, verticalDialogSpacingUnit } from "../Dialog/Dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { BetDialogDisclaimer } from "../BetDialogDisclaimer/BetDialogDisclaimer";
import { breakpoints } from "../../util/breakpoints";
import { Field, Form, FormRenderProps } from "react-final-form";
import { required, validEmail } from "../../util/optimized/finalFormUtil";
import { postBetsSubmitEmail } from "../../../services/bets";
import { ReactComponent as GreenCircularCheckmark } from "../../resource/assets/green_circular_checkmark.svg";
import { getGameSpreadOddsMessage } from "./util/getGameSpreadOddsMessage";
import { capitalizeString } from "../../util/general";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { InternalRoutes } from "../../Link";
import { ErrorToaster } from "../ErrorToaster/ErrorToaster";
import { useHiddenZendeskChatWidgetBreakpoint } from "../../hooks/useHiddenZendeskChatWidgetBreakpoint";
import { useSelector } from "react-redux";
import { useToastRemoval } from "../../hooks/useToastRemoval";
import { FormApi } from "final-form";

//TODO: See if any of the below types in this file can extend any prop APIs from the new bet components.
//TODO: See which types are only used once.
//TODO: See if type names can be improved.
//TODO: Remove ts-ignores.

type BetSportsbook = {
	logo: string;
	name: string;
	incentive: string;
	shortIncentive?: string;
};

//TODO: Change the maximum "win" value to the highest possible 32 bit number.
const maxBetAmount = 999999;

const getBettingCalculatorFieldValue = (value: number) => (value ? shortFormatPrice(value) : "$");

const getNumberValueFromOnChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => Number(event.target.value.replace(/[^\d.]/g, ""));

//TODO: See if passing in the empty object can be avoided.
const BetCTADialogStep = (props: React.PropsWithChildren<{}>) => {
	return (
		<>
			<span>
				<BetCTADialogStepFontAwesomeIcon icon={faCheck} />
				{props.children}
			</span>
			<br />
		</>
	);
};

const BetCTADialogPriceDisplay = (props: { title: string; price: number }) => {
	return (
		<BetCTADialogPrice>
			<BetCTADialogPriceDisplayTitle>{props.title}</BetCTADialogPriceDisplayTitle>
			<Typography type="specialBodyHeader">{shortFormatPrice(props.price)}</Typography>
		</BetCTADialogPrice>
	);
};

type BaseBetMetadataProps = Pick<Parameters<typeof postBetsSubmitEmail>[0], "type" | "selectedTeamId" | "selectedPlayerId" | "selectedOverUnderOption">;

//TODO: Replace unneeded CSS props with styled components once finalized.

//TODO: Maybe add skeletons with "sub-skeletons"
//TODO: Add email popup.
const BaseBet = (
	props: React.PropsWithChildren<
		{
			bettingCalculatorMultiplier: number;
			sportsbook: BetSportsbook;
			ctaDialogDetails: string;
			id: string;
			ctaDialogContent: React.ReactNode;
			submittedCTADialogContent?: React.ReactNode;
		} & BaseBetMetadataProps
	>
) => {
	const [betAmount, setBetAmount] = useState(25);

	const getWinAmount = () => {
		const value = betAmount * props.bettingCalculatorMultiplier;
		return value;
	};

	const [winAmount, setWinAmount] = useState(getWinAmount());

	const updateWinAmount = () => {
		setWinAmount(getWinAmount());
	};

	useEffect(() => {
		updateWinAmount();
	}, [betAmount, props.bettingCalculatorMultiplier]);

	const [showCTADialog, setShowCTADialog] = useState(false);

	const sportsbookLogoImgProps = useAutoUpdateState(() => {
		const value = {
			src: props.sportsbook.logo,
			alt: props.sportsbook.name,
		};
		return value;
	}, [props.sportsbook.logo, props.sportsbook.name]);

	const [showSubmittedCTADialogUI, setShowSubmittedCTADialogUI] = useState<boolean>();

	useEffect(() => {
		if (showSubmittedCTADialogUI && !showCTADialog) {
			setShowSubmittedCTADialogUI(false);
		}
	}, [showCTADialog]);

	const showDialogDetailsAsALabel = useAutoUpdateState(() => !showSubmittedCTADialogUI, [showSubmittedCTADialogUI]);

	const location = useSelector(state => state.persistent.location);

	//TODO: Once query-string logic is added for the bet popup this logic will need to work when directly accessing a email popup from the site URL.
	useHiddenZendeskChatWidgetBreakpoint(breakpoints.tablet, !showCTADialog);

	useToastRemoval();
	return (
		<>
			{/*TODO: Consider making the BetCTADialog it's own react component for re-render reasons*/}
			<BetCTADialog
				type="information"
				open={showCTADialog}
				onClose={() => {
					setShowCTADialog(false);
				}}
				fullscreenOnMobile
			>
				{/*TODO: If this dialog is turned into it's own component then integrate with "useToastRemoval".*/}
				<ErrorToaster />
				<BetCTADialogContentWrapper>
					{showSubmittedCTADialogUI ? (
						<TopSubmittedCTADialogContent>
							<SubmittedBetCTADialogFontAwesomeIcon icon={faCheck} />
							<Typography type="extraLargeSpecialBody" color="light">
								Your email will be in your inbox shortly.
								<br />
								When you're ready, place your bet.
							</Typography>
						</TopSubmittedCTADialogContent>
					) : (
						<TopBetCTADialogContent>
							<BetCTADialogTitle>{props.sportsbook.shortIncentive || props.sportsbook.incentive}</BetCTADialogTitle>
							<BetCTADialogSteps>
								<BetCTADialogStep>Enter your email</BetCTADialogStep>
								<BetCTADialogStep>Get the Offer</BetCTADialogStep>
								<BetCTADialogStep>Bet When You're Ready</BetCTADialogStep>
							</BetCTADialogSteps>
							<Form
								onSubmit={async (values, form: FormApi<any, any>) => {
									await postBetsSubmitEmail({
										email: values.email,
										betId: props.id,
										betAmount,
										winAmount,
										type: props.type,
										details: props.ctaDialogDetails,
										selectedTeamId: props.selectedTeamId,
										selectedPlayerId: props.selectedPlayerId,
										selectedOverUnderOption: props.selectedOverUnderOption,
										latitude: location?.latitude,
										longitude: location?.longitude,
									})
										.then(() => {
											setShowSubmittedCTADialogUI(true);
										})
										.catch(err => {
											//TODO: Confirm that we actually want this code. If so then the full toast integration needs to be completed (Toast component & useToastRemoval).
											if (err.error.suggest) {
												toast.error(err.error.message);
												form.getState().errors.email = `Did you mean: ${err.error.suggest}`;
											} else if (err.error.message) {
												toast.error(err.error.message);
											} else {
												toast.error("There was an error processing your request");
											}
										});
								}}
								render={form => (
									<form onSubmit={form.handleSubmit}>
										<Field<string> name="email" disabled={form.submitting} type="email" validate={value => required(value) || validEmail(value)} component={BetCTADialogRFFInput} placeholder="Enter Email" />
										{/*TODO: Consider adding a loading state to the Button component (like ButtonWithSpinner), maybe it can be based off the concept used for the Golf Classic landing page (button becomes disabled and a spinner appears inside the button)?*/}
										<BetCTADialogSubmitButton color="primary" type="submit" disabled={form.invalid}>
											Submit Email
										</BetCTADialogSubmitButton>
									</form>
								)}
							/>
						</TopBetCTADialogContent>
					)}
					<BetCTADialogFullWidthContent>
						<BetCTADialogBetDisplay>
							<BetCTADialogContent>{props.ctaDialogContent}</BetCTADialogContent>
							<Typography
								type="largeSpecialBody"
								highlight={showDialogDetailsAsALabel}
								color={showDialogDetailsAsALabel ? "success" : undefined}
								css={
									showDialogDetailsAsALabel
										? `
		  margin-top: 4px;
		  & > * {
		    padding: 4px 9px;
		  }
		`
										: undefined
								}
							>
								{props.ctaDialogDetails}
							</Typography>
							{showSubmittedCTADialogUI ? (
								<SubmittedBetCTADialogContentWrapper>
									<Typography type="largeSpecialBody">Let’s Get You in the Game!</Typography>
									{!!props.submittedCTADialogContent && <SubmittedBetCTADialogContent>{props.submittedCTADialogContent}</SubmittedBetCTADialogContent>}
								</SubmittedBetCTADialogContentWrapper>
							) : (
								<>
									<BetCTADialogPrices>
										<BetCTADialogPriceDisplay title="Bet" price={betAmount} />
										<BetCTADialogPriceDisplay title="Win" price={winAmount} />
									</BetCTADialogPrices>
									<Typography type="bodySmall" color="darkGrey" colorType="dark">
										Offer available to new {props.sportsbook.name} users
									</Typography>
									<BetCTADialogSportsbookLogo {...sportsbookLogoImgProps} />
								</>
							)}
						</BetCTADialogBetDisplay>
						{!showSubmittedCTADialogUI && <BetDialogDisclaimer />}
					</BetCTADialogFullWidthContent>
				</BetCTADialogContentWrapper>
			</BetCTADialog>
			<BetCard>
				{props.children}
				<Sportsbook>
					<Separator />
					<SportsbookDisplay>
						<SportsbookMessage>Best payout from</SportsbookMessage>
						<BetSportsbookDisplayLogo
							onClick={() => {
								setShowCTADialog(true);
							}}
							{...sportsbookLogoImgProps} />
					</SportsbookDisplay>
					<Separator />
				</Sportsbook>
				<BetInfo>
					<BettingCalculator>
						<BettingCalculatorInput
							sideLabel="Bet"
							value={getBettingCalculatorFieldValue(betAmount)}
							onChange={event => {
								const value = getNumberValueFromOnChangeEvent(event);
								if (value <= maxBetAmount) {
									setBetAmount(value);
								}
							}}
							onBlur={() => {
								if (!betAmount) {
									setBetAmount(1);
								}
							}}
						/>
						<BettingCalculatorInput
							sideLabel="Win"
							value={getBettingCalculatorFieldValue(winAmount)}
							onChange={event => {
								const value = getNumberValueFromOnChangeEvent(event);
								if (value) {
									const newBetAmount = value / props.bettingCalculatorMultiplier;
									if (newBetAmount <= maxBetAmount) {
										if (betAmount !== newBetAmount) {
											setBetAmount(newBetAmount);
										} else if (!winAmount) {
											updateWinAmount();
										}
									}
								} else {
									setWinAmount(value);
								}
							}}
							onBlur={() => {
								if (!winAmount) {
									updateWinAmount();
								}
							}}
						/>
					</BettingCalculator>
					<Incentive>{props.sportsbook.incentive}</Incentive>
					<Button
						onClick={() => {
							setShowCTADialog(true);
						}}
					>
						Get The Offer
					</Button>
				</BetInfo>
			</BetCard>
		</>
	);
};

const PerformerLinkButton = (
	props: {
		slug: string;
		name: string;
	} & Pick<React.ComponentProps<typeof Button>, "color">
) => {
	return (
		<Button
			color={props.color || "primary"}
			//TODO: There's a bug here with having click back twice to get back to GITG after clicking on this button. Try to fix this issue.
			//@ts-ignore
			forwardedAs={Link}
			to={InternalRoutes.Performer(props.slug)}
		>
			Buy {props.name} Tickets
		</Button>
	);
};

const multiTeamBetTeamTypes = ["homeTeam", "awayTeam"] as const;

type MultiTeamBetTeamKey = typeof multiTeamBetTeamTypes[number];

type BaseMultiTeamBetTeam = {
	name: string;
	performerSlug: string;
};

const MultiTeamBetPerformerLinks = (props: Partial<Record<MultiTeamBetTeamKey, BaseMultiTeamBetTeam>>) => {
	//TODO: Consider finding a way to avoid repeating the below || statement logic for both homeTeam & awayTeam.
	return props.homeTeam?.performerSlug || props.awayTeam?.performerSlug ? (
		<MultiTeamBetPerformerLinksWrapper>
			{multiTeamBetTeamTypes.map((key, index) => {
				const team = props[key];
				if (team?.performerSlug) {
					return (
						<PerformerLinkButton
							key={index}
							//TODO: This logic will display a black button for an even-numbered index even if it's the only button displayed. Consider improving the logic to only display a black button if it's the second button that's displayed.
							color={index % 2 ? "dark" : undefined}
							name={team.name}
							slug={team.performerSlug}
						/>
					);
				}
				return null;
			})}
		</MultiTeamBetPerformerLinksWrapper>
	) : null;
};

type GameSpreadBetProps = {
	eventDate: string;
	sportsbook: BetSportsbook;
	spread?: number;
	betId: string;
} & BaseBetMetadataProps &
	Record<
		MultiTeamBetTeamKey,
		{
			odds: number;
			id: string;
		} & (
			| {
				initials?: string;
			}
			| {
				location?: string;
			}
		) &
		BaseMultiTeamBetTeam
	>;

const GameSpreadBet = (props: GameSpreadBetProps) => {
	const getTeamOddsType = (team: MultiTeamBetTeamKey) => {
		if (props.spread === 0) {
			return "bestOdds";
		}
		if (props.spread !== undefined) {
			if (team === (props.spread > 0 ? "awayTeam" : "homeTeam")) {
				return "bestOdds";
			} else {
				return "worstOdds";
			}
		} else {
			if (props.homeTeam.odds === props.awayTeam.odds) {
				return;
			}
			if (props[team].odds < props[team === "homeTeam" ? "awayTeam" : "homeTeam"].odds) {
				return "bestOdds";
			} else {
				return "worstOdds";
			}
		}
	};

	const [selectedTeam, setSelectedTeam] = useState<MultiTeamBetTeamKey>(getTeamOddsType("homeTeam") === "bestOdds" ? "homeTeam" : "awayTeam");

	const getTeamProps = (team: MultiTeamBetTeamKey) => {
		const value: React.ComponentProps<typeof BetHomeTeamAndAwayTeam>[MultiTeamBetTeamKey] = {
			...props[team],
			icon: (
				<RadioButton
					checked={selectedTeam === team}
					onChange={() => {
						setSelectedTeam(team);
					}}
				/>
			),
			oddsType: getTeamOddsType(team),
		};
		return value;
	};

	const bettingCalculatorMultiplier = useAutoUpdateState(() => {
		if (selectedTeam) {
			const value = props[selectedTeam].odds;
			return value;
		}
	}, [selectedTeam]);
	return (
		<>
			{bettingCalculatorMultiplier !== undefined && (
				<BaseBet
					sportsbook={props.sportsbook}
					bettingCalculatorMultiplier={bettingCalculatorMultiplier}
					id={props.betId}
					type={props.type}
					submittedCTADialogContent={<MultiTeamBetPerformerLinks {...props} />}
					ctaDialogDetails={selectedTeam !== undefined ? (props.spread === undefined ? `${props[selectedTeam].name} to Win` : `${props[selectedTeam].name} to ${getGameSpreadOddsMessage(props.spread, getTeamOddsType(selectedTeam))}`) : ""}
					ctaDialogContent={
						<BetHomeTeamAndAwayTeam
							//TODO: Consider implementing "prop getter" for homeTeam & awayTeam in order to reuse logic/data.
							homeTeam={{
								...props.homeTeam,
								icon: selectedTeam === "homeTeam" ? <GreenCircularCheckmark /> : null,
							}}
							awayTeam={{
								...props.awayTeam,
								icon: selectedTeam === "awayTeam" ? <GreenCircularCheckmark /> : null,
							}}
							eventDate={props.eventDate}
						/>
					}
					selectedTeamId={props[selectedTeam].id}
				>
					<BetHomeTeamAndAwayTeam homeTeam={getTeamProps("homeTeam")} awayTeam={getTeamProps("awayTeam")} eventDate={props.eventDate} spread={props.spread} />
				</BaseBet>
			)}
		</>
	);
};

const BetOptionContainer = React.forwardRef<
	HTMLDivElement,
	React.PropsWithChildren<{
		radioButtonPosition?: "left" | "right";
	}> &
	StyledComponentProps &
	Pick<React.ComponentProps<typeof RadioButton>, "onChange" | "checked">
>(({ radioButtonPosition = "left", children, className, ...radioButtonProps }, ref) => {
	return (
		<BetOptionContainerWrapper
			className={className}
			style={
				radioButtonPosition === "right"
					? {
						flexDirection: "row-reverse",
					}
					: undefined
			}
			// Note: The below "as any" is used here due to strange type definitions. Consider finding a way to remove it in the future if possible.
			ref={ref as any}
		>
			<RadioButton
				{...radioButtonProps}
				style={{
					[`margin${radioButtonPosition === "right" ? "Left" : "Right"}`]: 16,
				}}
			/>
			{children}
		</BetOptionContainerWrapper>
	);
});

type FutureBetOption = {
	initials?: string;
	name: string;
	odds: number;
	betId: string;
	sportsbook: BetSportsbook;
	performerSlug?: string;
	performerName?: string;
};

type BaseFutureBetProps = {
	title: string;
};

//TODO: Implement the "load more" functionality for this component.
const FutureBet = (
	props: {
		options: FutureBetOption[];
	} & BaseBetMetadataProps &
		BaseFutureBetProps
) => {
	const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
	const selectedOption = useAutoUpdateState(() => {
		const value = props.options[selectedOptionIndex];
		return value;
	}, [props.options, selectedOptionIndex]);

	const displayedSportsbook = useAutoUpdateState(() => {
		const value = selectedOption.sportsbook;
		return value;
	}, [selectedOption]);

	const bettingCalculatorMultiplier = useAutoUpdateState(() => {
		const value = selectedOption.odds;
		return value;
	}, [selectedOption]);

	return (
		<BaseBet
			sportsbook={displayedSportsbook}
			bettingCalculatorMultiplier={bettingCalculatorMultiplier}
			//TODO: Consider DRYing the "selectedOption" value
			id={selectedOption.betId}
			type={props.type}
			ctaDialogDetails={selectedOption.name}
			ctaDialogContent={<Typography type="extraLargeSpecialBody">{props.title}</Typography>}
			submittedCTADialogContent={!!selectedOption.performerSlug && <PerformerLinkButton slug={selectedOption.performerSlug} name={selectedOption.performerName ?? selectedOption.name} />}
		>
			<FutureBetContent>
				<Typography type="extraLargeSpecialBody">{props.title}</Typography>
				<FutureBetTitleBorder />
				<FutureBetOptions>
					{props.options?.map((option, index) => {
						//TODO: These elements expect the array of options to be pre-sorted from bestOdds to worstOdds in order to accurately classify which item has the bestOdds/worstOdds. Consider adding logic to figure out the exact options that actually have the bestOdds or worstOdds.
						return (
							<BetOptionContainer
								key={index}
								checked={selectedOptionIndex === index}
								onChange={() => {
									setSelectedOptionIndex(index);
								}}
							>
								<BetEntity
									{...option}
									oddsType={(() => {
										switch (index) {
											case 0:
												return "bestOdds";
											case props.options!.length - 1:
												return "worstOdds";
											default:
												break;
										}
									})()}
								/>
							</BetOptionContainer>
						);
					})}
				</FutureBetOptions>
			</FutureBetContent>
		</BaseBet>
	);
};

type BaseOverUnderBetData = {
	sportsbook: BetSportsbook;
	overUnder: number;
	overOdds: number;
	underOdds: number;
	betId: string;
	units?: string;
};

const overUnderBetOptions = ["over", "under"] as const;

type OverUnderBetTitleProps = {
	title: React.ReactNode;
	subtitle?: React.ReactNode;
};

const OverUnderBetTitle = (props: OverUnderBetTitleProps) => {
	return (
		<OverUnderBetTitleContainer>
			<Typography type="heading2">{props.title}</Typography>
			<BetTitleBorder />
			{props.subtitle && <OverUnderBetSubtitle>{props.subtitle}</OverUnderBetSubtitle>}
		</OverUnderBetTitleContainer>
	);
};

const OverUnderBet = (props: OverUnderBetTitleProps & BaseOverUnderBetData & BaseBetMetadataProps & Pick<React.ComponentProps<typeof BaseBet>, "submittedCTADialogContent">) => {
	const [selectedOption, setSelectedOption] = useState<typeof overUnderBetOptions[number]>("over");

	const sharedOverUnderBetTitleProps = useAutoUpdateState(() => {
		const value = {
			title: props.title,
			subtitle: props.subtitle,
		};
		return value;
	}, [props.title, props.subtitle]);

	return (
		<BaseBet
			sportsbook={props.sportsbook}
			//TODO: See if the backend object schema defintion response can be updated/improved in order to better serve the functionality on the below line.
			bettingCalculatorMultiplier={props[selectedOption === "over" ? "overOdds" : "underOdds"]}
			id={props.betId}
			type={props.type}
			ctaDialogDetails={`${capitalizeString(selectedOption)} ${props.overUnder}${!!props.units ? ` ${capitalizeString(props.units)}` : ""}`}
			ctaDialogContent={<OverUnderBetTitle {...sharedOverUnderBetTitleProps} />}
			submittedCTADialogContent={props.submittedCTADialogContent}
		>
			<OverUnderBetContent>
				<OverUnderBetTitle {...sharedOverUnderBetTitleProps} />
				{/*Note: This div is wrapped around OverUnderBetOptions in order to prevent conflicts with OverUnderBetContent's flexGap and OverUnderBetOptions's margin.*/}
				<div>
					<OverUnderBetOptions>
						{overUnderBetOptions.map((option, index) => {
							return (
								<BetOptionContainer
									key={index}
									onChange={() => {
										setSelectedOption(option);
									}}
									checked={selectedOption === option}
								>
									<OverUnderBetOptionContent>
										<Typography type="bodyNormal">{option}</Typography>
										<Typography type="heading1">{props.overUnder}</Typography>
										{props.units && <Typography type="bodySmall">{props.units}</Typography>}
									</OverUnderBetOptionContent>
								</BetOptionContainer>
							);
						})}
					</OverUnderBetOptions>
				</div>
			</OverUnderBetContent>
		</BaseBet>
	);
};

const TeamEventBetTitle = (
	props: React.PropsWithChildren<
		{
			title: string;
		} & StyledComponentProps
	>
) => {
	return (
		<TeamEventBetTitleContainer className={props.className}>
			<Typography type="heading2">{props.title}</Typography>
			<BetTitleBorder />
		</TeamEventBetTitleContainer>
	);
};

const teamEventBetOptions = ["yes", "no"] as const;

type TeamEventBetProps = {
	happeningOdds: number;
	notHappeningOdds: number;
	sportsbook: BetSportsbook;
	teamName: string;
	performerSlug?: string;
	betId: string;
	action: string;
};

const TeamEventBet = (props: TeamEventBetProps & BaseBetMetadataProps) => {
	const [selectedOption, setSelectedOption] = useState<typeof teamEventBetOptions[number]>("yes");

	const title = useAutoUpdateState(() => props.teamName, [props.teamName]);

	return (
		<BaseBet
			sportsbook={props.sportsbook}
			//TODO: See if the backend object schema defintion response can be updated/improved in order to better serve the functionality on the below line.
			bettingCalculatorMultiplier={selectedOption === "yes" ? props.happeningOdds : props.notHappeningOdds}
			id={props.betId}
			type={props.type}
			ctaDialogDetails={`${props.action} (${capitalizeString(selectedOption)})`}
			ctaDialogContent={<TeamEventBetTitle title={title} />}
			submittedCTADialogContent={!!props.performerSlug && <PerformerLinkButton name={props.teamName} slug={props.performerSlug} />}
		>
			<TeamEventBetContent>
				<MainTeamEventBetTitle title={title} />
				<TeamEventBetOptions>
					{teamEventBetOptions.map((option, index) => {
						return (
							<React.Fragment key={index}>
								{index > 0 && <TeamEventBetOptionSeparator>Or</TeamEventBetOptionSeparator>}
								<TeamEventBetOptionContainer
									key={index}
									onChange={() => {
										setSelectedOption(option);
									}}
									checked={selectedOption === option}
									radioButtonPosition={index === teamEventBetOptions.length - 1 ? "right" : "left"}
								>
									<Typography type="heading1">{option}</Typography>
								</TeamEventBetOptionContainer>
							</React.Fragment>
						);
					})}
				</TeamEventBetOptions>
			</TeamEventBetContent>
		</BaseBet>
	);
};

type GameOverUnderBetData = BaseOverUnderBetData & {
	eventDate?: string;
} & Record<MultiTeamBetTeamKey, BaseMultiTeamBetTeam>;

export const Bet = (
	props:
		| ({
			type: "game";
		} & Omit<GameSpreadBetProps, "spread">)
		| ({
			type: "gameSpread";
		} & GameSpreadBetProps)
		| ({
			type: "playerFuture";
			players: Omit<FutureBetOption, "initials">[];
		} & BaseFutureBetProps)
		| ({
			type: "teamFuture";
			teams: Omit<FutureBetOption, "performerName">[];
		} & BaseFutureBetProps)
		| ({
			type: "gameOverUnder";
		} & GameOverUnderBetData)
		| ({
			type: "playerOverUnder";
			performerSlug?: string;
			playerName: string;
		} & PartialBy<GameOverUnderBetData, typeof multiTeamBetTeamTypes[number]>)
		| ({
			type: "teamOverUnder";
			performerSlug?: string;
			teamName: string;
		} & BaseOverUnderBetData)
		| ({
			type: "teamEvent";
		} & TeamEventBetProps)
) => {
	switch (props.type) {
		case "game":
		case "gameSpread":
			//TODO: Remove logic to hide bets with odds of 0 once the backend API is updated or consider building this logic directly into the GameSpreadBet component (same thing for over under bets & teamEvent bets).
			return !!props.homeTeam.odds && !!props.awayTeam.odds && <GameSpreadBet {...props} />;
		case "playerFuture":
		case "teamFuture":
			return (
				<FutureBet
					{...props}
					options={
						(() => {
							if ("players" in props) {
								return props.players;
							} else if ("teams" in props) {
								return props.teams;
							}
						})()!
					}
				/>
			);
		case "gameOverUnder":
		case "playerOverUnder":
		case "teamOverUnder":
			return !!props.overUnder ? (
				<OverUnderBet
					{...props}
					title={(() => {
						switch (props.type) {
							case "gameOverUnder":
								//TODO: Implement the correct font size & alignment for betTeamComparisonSymbol based off the mockup.
								return `${props.awayTeam.name} ${betTeamComparisonSymbol} ${props.homeTeam.name}`;
							case "playerOverUnder":
								return props.playerName;
							case "teamOverUnder":
								return props.teamName;
						}
					})()}
					subtitle={(() => {
						switch (props.type) {
							case "gameOverUnder":
							case "playerOverUnder":
								let subtitle = props.eventDate === undefined ? undefined : <BetDate date={props.eventDate} />;
								if (props.type === "playerOverUnder" && props.homeTeam && props.awayTeam) {
									subtitle = (
										<>
											{props.awayTeam.name} {betTeamComparisonSymbol} {props.homeTeam.name}
											{subtitle !== undefined && (
												<>
													<br />
													{subtitle}
												</>
											)}
										</>
									);
								}
								return subtitle;
							default:
								break;
						}
					})()}
					submittedCTADialogContent={(() => {
						switch (props.type) {
							case "gameOverUnder":
							case "playerOverUnder":
								return <MultiTeamBetPerformerLinks {...props} />;
							case "teamOverUnder":
								if (props.performerSlug) {
									return <PerformerLinkButton name={props.teamName} slug={props.performerSlug} />;
								}
						}
					})()}
				/>
			) : null;
		case "teamEvent":
			return !!props.happeningOdds && !!props.notHappeningOdds && <TeamEventBet {...props} />;
		default:
			//TODO: Consider improving how this looks from a user-facing perspective.
			return (
				<>
					Error: Invalid bet type provided. <br />
				</>
			);
	}
};

const MainTeamEventBetTitle = styled(TeamEventBetTitle)`
	margin-bottom: 24px;
`;

const MultiTeamBetPerformerLinksWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	${flexGap(["6px", "9px"])}
	& > * {
		flex: 1;
	}
`;

const BetCTADialogContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: -${bottomDialogSpacing + bottomDialogSpacingUnit};
`;

const SubmittedBetCTADialogContent = styled.div`
	margin-top: 10px;
`;

const BetCTADialogSubmitButton = styled(Button)`
	margin-bottom: 22px;
	width: 100%;
`;

const BetCTADialogRFFInput = styled(RFFInput)`
	margin-top: 22px;
`;

const BetCTADialogSteps = styled.h3`
	${getTypographyStyles("heading2", {
	color: "light",
})}
	line-height: 1.24;
`;

const BetCTADialogContent = styled.div`
	margin-top: 20px;
	margin-bottom: 12px;
`;

const BetCTADialogTitle = styled.h1`
	${getTypographyStyles("display1", {
	color: "light",
})}
	margin-bottom: 4px;
`;

const TeamEventBetTitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const BetCTADialogPrices = styled.div`
	display: flex;
	background: ${getAppColor("lightGrey", "subtle")};
	margin-bottom: 12px;
	& > * {
		flex: 1;
		width: 0;
		&:not(:last-child) {
			border-right: 1px solid ${getAppColor("lightGrey", "dark")};
		}
	}
`;

const SubmittedBetCTADialogFontAwesomeIcon = styled(FontAwesomeIcon)`
	color: ${getAppColor("primary")};
	height: 43px;
	width: 100% !important;
	margin-bottom: 16px;
`;

const SubmittedBetCTADialogContentWrapper = styled.div`
	padding-bottom: 35px;
	padding-top: 17px;
	margin-top: 17px;
	border-top: 1px solid ${getAppColor("lightGrey", "dark")};
`;

const BetCTADialogStepFontAwesomeIcon = styled(FontAwesomeIcon)`
	color: ${getAppColor("primary")};
	max-width: 20px;
	margin-right: 8px;
`;

const TopBetCTADialogContent = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
`;

const TopSubmittedCTADialogContent = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 36px;
`;

const BetCTADialogFullWidthContent = styled.div`
	margin: 0 -${verticalDialogSpacing}${verticalDialogSpacingUnit};
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const BetCTADialogBetDisplay = styled.div`
	background: ${getAppColor("light")};
	padding: 0 ${verticalDialogSpacing}${verticalDialogSpacingUnit};
	display: flex;
	flex-direction: column;
	flex: 1;
`;

const BetCTADialog = styled(Dialog)`
	background: ${getAppColor("dark")};
	width: ${breakpoints.mobile};
`;

const TeamEventBetOptions = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
`;

const OverUnderBetOptionContent = styled.div`
	display: flex;
	text-transform: uppercase;
	flex-direction: column;
	align-items: center;
	margin: 0 8px;
`;

const BetCTADialogPriceDisplayTitle = styled.span`
	${getTypographyStyles("normalSpecialBody")}
	margin-right: 8px;
`;

const BetCTADialogPrice = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 21px 0;
`;

const TeamEventBetOptionSeparator = styled.span`
	${getTypographyStyles("bodyNormal", {
	fontWeight: 700,
})}
	text-transform: uppercase;
	margin: 0 42px;
	@media (max-width: ${breakpoints.mobile}) {
		margin: 0 5vw;
	}
`;

const TeamEventBetContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const BetOptionContainerWrapper = styled.div`
	background: ${getAppColor("lightGrey", "subtle")};
	border: 1px solid ${getAppColor("darkGrey", "light")};
	display: flex;
	align-items: center;
	padding: 23px 20px;
	box-sizing: border-box;
`;

//TODO: See if this needs to be it's own const.
const overUnderBetOptionsFlexGap = 4.5;

const OverUnderBetOptions = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	${flexGap(`${overUnderBetOptionsFlexGap}px`)}
	& > * {
		min-width: 161px;
	}
`;

const OverUnderBetSubtitle = styled.div`
	margin-top: 5px;
	${getTypographyStyles("bodySmall")}
	text-align: center;
`;

const OverUnderBetContent = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-evenly;
	${flexGap(["5.5px", "23px"])}
`;

const OverUnderBetTitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const BetTitleBorder = styled(PrimaryBorder)`
	width: 106px;
	margin-top: 7px;
`;

const FutureBetContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const FutureBetTitleBorder = styled(BetTitleBorder)`
	margin-bottom: 22px;
`;

const FutureBetOptions = styled.div`
	display: grid;
	//TODO: Revist the usage of "300px".
	//TODO: See if the minmax(min(x, x)) line can be optimized/improved.
	grid-template-columns: repeat(auto-fill, minmax(min(300px, 100%), 1fr));
	grid-auto-rows: 1fr;
	width: 100%;
	grid-gap: 16px 24px;
`;

const BetCard = styled(Card)`
	//TODO: Consider finding a better way to do this if possible.
	&:not(:first-of-type) {
		border-top: none;
	}
`;

const BettingCalculator = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	& > *:first-child {
		margin-right: 24px;
	}
`;

const bettingCalculatorInputTypographyType = "extraLargeSpecialBody";

const BettingCalculatorInput = styled(
	({
		sideLabel,
		...inputProps
	}: Omit<React.ComponentProps<typeof Input>, "noSubtextSpacing" | "textSize" | "typographyType"> & {
		sideLabel?: React.ReactNode;
	}) => {
		return (
			<div
				css={`
					display: flex;
					align-items: center;
				`}
			>
				{sideLabel !== undefined && (
					<span
						css={`
							margin-right: 8px;
							${getTypographyStyles(bettingCalculatorInputTypographyType)}
						`}
					>
						{sideLabel}
					</span>
				)}
				<Input {...inputProps} typographyType={bettingCalculatorInputTypographyType} noSubtextSpacing />
			</div>
		);
	}
)`
	input {
		width: 101px;
		text-align: center;
	}
`;

const SportsbookMessage = styled.span`
	white-space: nowrap;
	margin-right: 8px;
	${getTypographyStyles("bodyNormal", {
	fontWeight: "bold",
})}
`;

const columnLayoutBreakpoint = "600px";

const BetCTADialogSportsbookLogo = styled.img`
	height: 33px;
	margin-bottom: 20px;
	margin-top: 11px;
	align-self: center;
`;

const BetSportsbookDisplayLogo = styled.img`
	height: 30px;
	cursor: pointer;
	@media (max-width: ${columnLayoutBreakpoint}) {
		height: 27px;
	}
`;

const Separator = styled.hr`
	background: ${getAppColor("lightGrey", "dark")};
`;

const sportsbookSeparatorDesktopMargin = "11px";

const SportsbookDisplay = styled.div`
	display: flex;
	align-items: center;
	@media (max-width: ${columnLayoutBreakpoint}) {
		margin-bottom: 23px;
	}
`;

const Sportsbook = styled.div`
	display: flex;
	align-items: center;
	@media (min-width: ${columnLayoutBreakpoint}) {
		margin-bottom: 18px;
		margin-top: 26px;
	}
	@media (max-width: ${columnLayoutBreakpoint}) {
		flex-direction: column;
	}
	& > ${Separator} {
		&:first-of-type {
			@media (min-width: ${columnLayoutBreakpoint}) {
				margin-right: ${sportsbookSeparatorDesktopMargin};
			}
			@media (max-width: ${columnLayoutBreakpoint}) {
				margin-bottom: 20px;
				margin-top: 24px;
			}
		}
		&:last-of-type {
			margin-left: ${sportsbookSeparatorDesktopMargin};
			@media (max-width: ${columnLayoutBreakpoint}) {
				display: none;
			}
		}
	}
`;

const Incentive = styled.span`
	text-align: center;
	@media (min-width: ${columnLayoutBreakpoint}) {
		padding: 0 27px;
		margin-left: auto;
	}
	@media (max-width: ${columnLayoutBreakpoint}) {
		margin: 16px 0;
	}
	${getTypographyStyles("bodyNormal", {
	fontWeight: "bold",
})}
`;

const BetInfo = styled.div`
	display: flex;
	align-items: center;
	@media (max-width: ${columnLayoutBreakpoint}) {
		flex-direction: column;
		align-items: stretch;
	}
`;

const TeamEventBetOptionContainer = styled(BetOptionContainer)`
	flex: 1;
	justify-content: center;
`;
