import { Reducer } from "redux";

export interface LoadingScreenData {
	text?: string;
	textSecondLine?: string;
	hideBanner?: boolean;
}

export const loadingScreenDataReducer: Reducer<LoadingScreenData, LoadingScreenDataActions> = (state: LoadingScreenData = {}, action: LoadingScreenDataActions) => {
	switch (action.type) {
		case "SET_LOADING_SCREEN_DATA":
			return action.data;
		case "RESET_LOADING_SCREEN_DATA":
			return {};
		default:
			return state;
	}
};

export type LoadingScreenDataActions =
	| {
			type: "SET_LOADING_SCREEN_DATA";
			data: LoadingScreenData;
	  }
	| {
			type: "RESET_LOADING_SCREEN_DATA";
	  };
