import styled from "styled-components/macro";
import React from "react";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";

interface CardProps {
	disableBoxShadow?: boolean;
}

//TODO: Update this component and the styled components to use the new Typography component and appColors.
export const Card = React.forwardRef<
	HTMLDivElement,
	React.PropsWithChildren<
		{
			titleContent?: React.ReactNode;
		} & StyledComponentProps &
			CardProps &
			React.HTMLAttributes<HTMLDivElement>
	>
>(({ titleContent, children, ...cardProps }, ref) => {
	return (
		<Wrapper ref={ref} {...cardProps}>
			{titleContent && <Title>{titleContent}</Title>}
			<Content>{children}</Content>
		</Wrapper>
	);
});

export const verticalCardPadding = "16px";

//TODO: Consider somehow integrating with the Badge styled component since as of now the background and border is the same (the only difference is that the border is only on the bottom)
const Title = styled.div`
	border-bottom: 1px solid lightgray;
	padding: 9px ${verticalCardPadding};
	color: black;
	background: #f5f5f5;
	font-weight: 500;
	text-align: left;
	font-size: 14px;
	white-space: nowrap;
`;

const Wrapper = styled.div<
	CardProps & {
		ref?: any;
	}
>`
	background: #ffffff;
	border: 1px solid lightgray;
	overflow: hidden;
`;

const Content = styled.div`
	padding: ${verticalCardPadding};
`;
