import React, { InputHTMLAttributes, useState } from "react";
import styled, { css } from "styled-components/macro";
import { FieldRenderProps } from "react-final-form";
import clearIcon from "../../resource/image/close-icon.svg";
import Cleave from "cleave.js/react";
import { CleaveOptions } from "cleave.js/options";
import { useMemoState } from "../../hooks/useMemoState";
import { getAppColor } from "../../util/appColors";
import { getTypographyStyles } from "../Typography/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	error?: string;
	leftIcon?: React.ReactElement;
	clearable?: boolean;
	onClear?: () => void;
	cleaveOptions?: CleaveOptions;
	containerStyle?: any;
	containerClassName?: string;
	untouchedMessage?: string;
	showHidePasswordIcon?: boolean;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(({ error, leftIcon, clearable, onClear, cleaveOptions, containerStyle, containerClassName, untouchedMessage, showHidePasswordIcon, ...inputProps }, ref: any) => {
	const [touched, setTouched] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const errorVisible = useMemoState(() => {
		const value = !!error && touched;
		return value;
	}, [error, touched]);

	return (
		<Container leftIcon={!!leftIcon} clearable={!!clearable} style={containerStyle} className={errorVisible ? `${containerClassName} error` : containerClassName}>
			{leftIcon && <LeftIcon>{leftIcon}</LeftIcon>}
			{cleaveOptions ? (
				<Cleave
					{...inputProps}
					options={cleaveOptions}
					htmlRef={htmlRef => {
						ref = htmlRef;
					}}
					//TODO: See if there's a way to DRY onBlur for <Cleave /> and <input />. Some strange state stuff was happening when attempting to do so before.
					onBlur={event => {
						setTouched(true);
						if (inputProps.onBlur) {
							inputProps.onBlur(event);
						}
					}}
				/>
			) : (
				<input
					ref={ref}
					{...inputProps}
					type={showHidePasswordIcon ? (showPassword ? "text" : "password") : inputProps.type}
					onBlur={event => {
						setTouched(true);
						if (inputProps.onBlur) {
							inputProps.onBlur(event);
						}
					}}
				/>
			)}
			{showHidePasswordIcon && inputProps.value && (
				<StyledIcon
					icon={showPassword ? faEyeSlash : faEye}
					onClick={() => {
						setShowPassword(!showPassword);
					}}
				/>
			)}
			{clearable && (
				<Clear onMouseDown={onClear}>
					<CloseButtonContainer>
						<img src={clearIcon} alt="" />
					</CloseButtonContainer>
				</Clear>
			)}
			<BottomMessage visible={!errorVisible && !touched}>{untouchedMessage}</BottomMessage>
			<InputError visible={errorVisible}>{error}</InputError>
		</Container>
	);
});

export const RFFInput = ({ input, meta, ...rest }: FieldRenderProps<string>) => {
	return <Input {...input} {...rest} error={meta.error} />;
};

export const StyledRFFInput = styled(RFFInput)`
	border: 1px solid ${getAppColor("darkGrey", "light")};
	border-radius: 0;
	height: 50px;
	padding-left: 10px !important;
	-webkit-appearance: none;
	background: ${getAppColor("light")};
	${getTypographyStyles("bodyNormal")};
	&:hover {
		box-shadow: none;
	}
`;

const CloseButtonContainer = styled.div`
	align-self: center;
`;

const bottomMarginValue = 23;
const bottomMargin = bottomMarginValue + "px";

export const InputTransitionStyles = css({
	"transition-duration": "0.2s",
	"transition-timing-function": "ease",
});

const errorLeft = "10px";

const LeftIcon = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	padding: 0 11px;
	display: flex;
	align-items: center;
`;

const Clear = styled.div`
	color: #e92224 !important;
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: auto;
	display: flex;
	padding: 0 15px;
	cursor: pointer;
`;

const BottomMessage = styled.span<{
	visible?: boolean;
}>`
	${getTypographyStyles("bodyTiny")}
	color: ${getAppColor("darkGrey", "dark")} !important;
	margin: 0 !important;
	position: absolute;
	bottom: -10px;
	left: ${errorLeft};
	opacity: 0;
	transition-property: bottom, opacity;
	white-space: nowrap;
	max-width: calc(100% - ${errorLeft});
	overflow: hidden;
	text-overflow: ellipsis;
	${InputTransitionStyles}
	${props =>
		props.visible &&
		`
    bottom: -${bottomMarginValue * 0.826}px;
    opacity: 1;
  `}
`;

export const InputError = styled(BottomMessage)`
	color: #e92224 !important;
`;

export const InputErrorStyles = {
	color: "#e92224",
	"border-color": "#e92224",
};

export const Container = styled.div<{ leftIcon?: boolean; clearable?: boolean }>`
	position: relative;
	margin-bottom: ${bottomMargin};
	input {
		margin: 0;
		border-radius: 5px;
		width: 100%;
		transition-property: border-color, color;
		padding-left: ${props => (props.leftIcon ? "30px" : "0")};
		padding-right: ${props => (props.clearable ? "30px" : "0")};
		${InputTransitionStyles}
	}
	[placeholder] {
		text-overflow: ellipsis;
		padding-left: ${props => (props.leftIcon ? "30px" : "8px")};
	}
	::-webkit-input-placeholder {
		text-overflow: ellipsis;
	}
	::-moz-placeholder {
		text-overflow: ellipsis;
	}
	:-ms-input-placeholder {
		text-overflow: ellipsis;
	}
	:-moz-placeholder {
		text-overflow: ellipsis;
	}
	&.error {
		input {
			${InputErrorStyles}
		}
	}
`;

const StyledIcon = styled(FontAwesomeIcon)`
	font-size: 16px;
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	padding: 0 11px;
	display: flex;
	align-items: center;
	cursor: pointer;
`;
