import React from "react";
import { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import { css } from "styled-components/macro";

interface ButtonAppearanceObject {
	color: string;
	background: string;
	borderColor?: string;
	cursor: string;
}

export const buttonStandardAppearances: {
	[key: string]: ButtonAppearanceObject;
} = {
	Green: {
		color: "#ffffff",
		background: "#13a538",
		cursor: "pointer",
	},
	Blue: {
		color: "#fff",
		background: "#12284b",
		cursor: "pointer",
	},
	Red: {
		color: "#fff",
		background: "#e92224",
		cursor: "pointer",
	},
	Disabled: {
		color: "#fff",
		background: "#ccc",
		cursor: "no-drop",
	},
	WhiteGray: {
		color: "#707372",
		background: "#fff",
		borderColor: "lightgrey",
		cursor: "pointer",
	},
	Facebook: {
		color: "#707372",
		borderColor: "lightgrey",
		background: "#fff",
		cursor: "pointer",
	},
	Google: {
		color: "#707372",
		background: "#fff",
		borderColor: "lightgrey",
		cursor: "pointer",
	},
	Apple: {
		color: "white",
		background: "black",
		borderColor: "lightgrey",
		cursor: "pointer",
	},
};

export type ButtonAppearance = ButtonAppearanceObject | string;

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
	initialAppearance?: ButtonAppearance;
	hoverAppearance?: ButtonAppearance;
	disabledAppearance?: ButtonAppearance;
};

export const Button = ({ initialAppearance = "Blue", hoverAppearance, disabledAppearance = "Disabled", ...rest }: ButtonProps) => {
	const resolve = (appearance: ButtonAppearance): ButtonAppearanceObject => {
		return typeof appearance === "string" ? buttonStandardAppearances[appearance] : appearance;
	};

	const effectiveInitial = resolve(initialAppearance);
	const effectiveHover = hoverAppearance
		? resolve(hoverAppearance)
		: {
				...effectiveInitial,
				background: effectiveInitial?.background,
		  };

	return <ButtonView initialAppearance={effectiveInitial} hoverAppearance={effectiveHover} disabledAppearance={resolve(disabledAppearance)} {...rest} />;
};

export const SmallButton = styled(Button)`
	width: initial !important;
	height: initial !important;
	padding: 6px 10px;
	font-size: 12px !important;
	border-radius: 4px !important;
	font-weight: bold !important;
	white-space: nowrap;
	svg {
		margin-right: 7px;
	}
`;

const ButtonView = styled.button<{
	initialAppearance: ButtonAppearanceObject;
	hoverAppearance: ButtonAppearanceObject;
	disabledAppearance: ButtonAppearanceObject;
}>`
	width: 300px;
	max-width: 100%;
	height: 50px;
	border-radius: 4px;
	border: 0 !important;
	outline: 0 !important;
	user-select: none;

	background: ${props => props.initialAppearance.background};
	color: ${props => props.initialAppearance.color};

  border ${props => (props.initialAppearance.borderColor ? "1px solid " + props.initialAppearance.borderColor + " !important" : "none")};

	font-family: Montserrat, sans-serif;
	text-align: center;
	font-size: 16px;
	font-weight: 700;
	text-decoration: none;

	&:hover,
	&:focus,
	&:active {
    cursor: ${props => props.hoverAppearance.cursor};
		outline: 0 !important;
	}

	&:disabled {
		color: ${props => props.disabledAppearance.color};
		background: ${props => props.disabledAppearance.background};
		cursor: ${props => props.disabledAppearance.cursor};
		outline: 0 !important;
		border: 0 !important;
	}
`;

export const MediumButtonStyles = css`
	font-size: 16px !important;
	padding: 10px 25px;
`;
