import React from "react";
import { InvoiceItem, InvoiceItemProps } from "../Invoices/InvoiceItem";
import { darkPaymentMethodLogos, lightPaymentMethodLogos, paymentMethodBadges } from "../../util/paymentMethodLogos";
import { formatPrice } from "../../util/formatPrice";
import { PartialBy } from "../../../model/optimizedModel/general";
import { PaymentMethod, PaymentMethodType } from "../../../model/optimizedModel/braintree";
import { useMemoState } from "../../hooks/useMemoState";
import styled, { css } from "styled-components/macro";
import { renderPaymentMethod } from "../../../services/braintree";
import { horizontalPaddingObject } from "../../util/optimized/styles";
import { appColors } from "../../util/appColors";

interface StyledInvoiceItemProps {
	solidBackground?: boolean;
}

type PaymentMethodStylesRecord = Required<Record<PaymentMethodType, React.CSSProperties>>;

const paymentMethodLogoStyles: PaymentMethodStylesRecord = {
	PayPalAccount: {
		height: 22,
	},
	VenmoAccount: {
		height: 16,
	},
	CreditCard: {
		height: 19,
		marginRight: 4,
	},
};

const solidPaymentMethodBackgroundStyles: PaymentMethodStylesRecord = {
	PayPalAccount: {
		background: "#ffc439",
		color: "#000000",
	},
	VenmoAccount: {
		background: appColors.venmo.main,
		color: "#ffffff",
	},
	CreditCard: {
		background: "#ffffff",
		color: "#000000",
		border: "1px solid lightgrey",
		borderTop: "none",
	},
};

const privateRenderPaymentMethod = (paymentMethod: PaymentMethod) => {
	return renderPaymentMethod(paymentMethod, {
		hideName: true,
		hideParentheses: true,
	});
};

export const PaymentMethodPriceInvoiceItem = ({
	paymentMethod,
	solidBackground,
	callToAction,
	price,
	subtitle,
	subtitleWhitespaceNowrap,
	...invoiceItemProps
}: {
	paymentMethod: PartialBy<PaymentMethod, "details">;
	callToAction?: boolean;
	price?: number;
} & Omit<InvoiceItemProps, "title" | "value"> &
	StyledInvoiceItemProps) => {
	const paymentMethodDetailsSubtitle = useMemoState(() => {
		if (paymentMethod.details !== undefined && paymentMethod.type !== "CreditCard") {
			//TODO: Consider finding a way to remove the type cast.
			return privateRenderPaymentMethod(paymentMethod as PaymentMethod);
		}
	}, [paymentMethod]);

	return (
		<StyledInvoiceItem
			{...invoiceItemProps}
			title={(() => {
				const paymentMethodLogos = solidBackground ? lightPaymentMethodLogos : darkPaymentMethodLogos;
				//TODO: Remove ts-ignore.
				//@ts-ignore
				let paymentMethodLogoSrc = paymentMethod.type === "CreditCard" && paymentMethod.details ? paymentMethodBadges[paymentMethod.details.cardType] : paymentMethodLogos[paymentMethod.type];
				let value = (
					<img
						src={paymentMethodLogoSrc}
						//@ts-ignore
						alt={(paymentMethod.details?.cardType || paymentMethod.type) + " Logo"}
						style={paymentMethodLogoStyles[paymentMethod.type]}
					/>
				);
				if (paymentMethod.type === "CreditCard") {
					value = (
						<>
							{value} {paymentMethod.details ? privateRenderPaymentMethod(paymentMethod as PaymentMethod) : "Credit Card"}
						</>
					);
				}
				if (callToAction) {
					value = <>Pay with&nbsp;{value}</>;
				}
				return value;
			})()}
			value={price && formatPrice(price)}
			subtitle={paymentMethodDetailsSubtitle || subtitle}
			subtitleWhitespaceNowrap={!!paymentMethodDetailsSubtitle || subtitleWhitespaceNowrap}
			style={{
				...invoiceItemProps.style,
				...(solidBackground && {
					...horizontalPaddingObject("10px"),
					...solidPaymentMethodBackgroundStyles[paymentMethod.type],
				}),
			}}
			solidBackground={solidBackground}
		/>
	);
};

const StyledInvoiceItem = styled(InvoiceItem)<StyledInvoiceItemProps>`
	font-weight: 500;

	${props =>
		props.solidBackground &&
		css`
			font-weight: 600;
			//TODO: Consider finding a better way to target nested components (this targets the Subtitle component in InvoiceItem).
			& > span:last-child {
				font-size: 18px;
			}
		`}
`;
