import React from "react";
import twitter_social_icon from "../../../presentation/resource/img/icons/twitter.svg";
import instagram_social_icon from "../../../presentation/resource/img/icons/instagram.svg";
import facebook_social_icon from "../../../presentation/resource/img/icons/facebook.svg";
import facebook_social_icon_dark from "../../../presentation/resource/img/icons/facebook_dark.svg";
import twitter_social_icon_dark from "../../../presentation/resource/img/icons/twitter_dark.svg";
import instagram_social_icon_dark from "../../../presentation/resource/img/icons/instagram_dark.svg";
import { ExternalRoutes } from "../../Link";

const socialIcons = {
	facebook: {
		light: facebook_social_icon,
		dark: facebook_social_icon_dark,
	},
	instagram: {
		light: instagram_social_icon,
		dark: instagram_social_icon_dark,
	},
	twitter: {
		light: twitter_social_icon,
		dark: twitter_social_icon_dark,
	},
};

export const SocialLink = (props: { platform: keyof typeof socialIcons; color: "dark" | "light" }) => {
	return (
		<a href={ExternalRoutes[props.platform]} target="_blank" rel="noopener noreferrer">
			<img src={socialIcons[props.platform][props.color]} alt={props.platform} />
		</a>
	);
};
