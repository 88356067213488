import { apiClient } from "./apiClient";
import { APIClientResponse } from "./types/APIClientResponse";

export function postNewsletterSubscribe(email: string): APIClientResponse<{
	data: string;
}> {
	return apiClient.post("subscribe", {
		email,
	});
}
