import { RefObject, useEffect } from "react";
import { ResizeObserver as ResizePolyfill, ResizeObserverEntry } from "@juggle/resize-observer";
export { ResizeObserverEntry };

const ResizeObserver: typeof ResizePolyfill = (window as any).ResizeObserver || ResizePolyfill;

export const useResizeWatch = (target: RefObject<Element>, onResize: (entry: ResizeObserverEntry) => void) => {
	useEffect(() => {
		const current = target.current;
		if (current === null) return;
		const ro = new ResizeObserver(entries => entries.forEach(onResize));
		ro.observe(current);
		return () => ro.unobserve(current);
	}, [target, onResize]);
};

export const useElementResizeWatch = (target: Element, onResize: (entry: ResizeObserverEntry) => void) => useResizeWatch({ current: target }, onResize);
