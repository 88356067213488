import React, { InputHTMLAttributes } from "react";
import styled from "styled-components/macro";
import { Button, ButtonProps } from "../Button/Button";
import { Spinner } from "../Loader/Spinner";
import checkmark_green from "../../resource/img/icons/checkmark_green2.svg";
import { Checkbox } from "pretty-checkbox-react";
import {getAppColor} from "../../util/appColors";

/**
 * Common
 */
export const LoginForm = styled.form`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	margin: 20px 5px;
`;

export const ResetForm = styled.form`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	margin: 0px 5px;
`;

export const LoginError = styled.span`
	color: #d9534f;
	margin-bottom: 10px;
`;

export const PasswordInfo = styled.span`
	text-align: left;
	padding: 5px 0 0 5px;
	font-size: 12px;
`;

export const LoginTextInput = styled.input<{ checked?: boolean }>`
	border: 0;
	background-position-x: calc(100% - 35px);
	background-repeat: no-repeat;
	background-image: ${props => (props.checked ? "url(" + checkmark_green + ")" : "unset")};
	border-radius: unset;
	border: solid 1px #dcdcdc;
	font-family: "Montserrat";
	color: #000;
	font-size: 16px;
`;

/**
 * Inputs
 */
export const LabelledInput =
	(type: string) =>
		({ children, style, className, ...props }: InputHTMLAttributes<HTMLInputElement>) => {
			return (
				<LabelledInputLabel style={style} className={className}>
					{type === "checkbox" ? <Checkbox checked={props.checked} onChange={props.onChange} variant="thick" color="primary-o"></Checkbox> : <LabelledInputInput type={type} {...props} />}

					<div>{children}</div>
				</LabelledInputLabel>
			);
		};

export const LabelledInputInput = styled.input`
	margin: 4px 10px;
`;

export const LabelledCheckbox = LabelledInput("checkbox");

export const LabelledInputLabel = styled.label`
	display: flex;
	flex-direction: row;
	align-items: start;
	justify-content: start;
	margin: 5px 0;
	font-weight: 500;
`;

export const LoginSpinner = styled(Spinner)`
	margin: 25px auto;
`;

export const LoginButton = styled(Button)`
	margin-bottom: 20px;
	width: 100%;
	font-size: 18px;
`;

export const LogoutButton = styled(Button)`
	background-color: ${getAppColor("dark")};
	font-size: 18px;
`;

interface SocialLoginButtonProps extends ButtonProps {
	imageSource: string;
}

/**
 * Buttons
 */
export const SocialLoginButton = ({ imageSource, children, ...rest }: SocialLoginButtonProps) => (
	<SocialLoginButtonView {...rest}>
		<SocialLoginImage src={imageSource} />
		{children}
	</SocialLoginButtonView>
);

const SocialLoginButtonView = styled(Button)`
	width: 100%;
	padding: 0 10px;
	margin: 10px;
	font-weight: normal;
	strong {
		font-weight: bold;
	}
`;

const SocialLoginImage = styled.img`
	vertical-align: middle;
	margin-right: 10px;
	width: 20px;
	height: 20px;
`;
