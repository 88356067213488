import React from "react";
import { SeaticsTicketGroup, SeaticsTicketSegment } from "../Seatics/types";
import { TicketSectionTitle } from "./styles";
import { TicketGroupRow } from "./TicketGroup";
import { Nulleable } from "../../../model/Utils";
import { FreeStarAd } from "../FreeStarAd";
import styled from "styled-components/macro";

interface TicketSegmentProps {
	segment: SeaticsTicketSegment;
	ticketHover: (ticket: Nulleable<SeaticsTicketGroup>) => void;
	ticketClick: (ticket: SeaticsTicketGroup) => void;
	panelWidth: number;
	panelTop: number;
	vfsDisabled?: boolean;
}

const FREESTAR_ADS_AMOUNT = 6;
const AD_INTERVAL = 8;
export const TicketSegment = (props: TicketSegmentProps) => {
	return (
		<div>
			{props.segment.title && <TicketSectionTitle>{overrideTitle(props.segment.title)}</TicketSectionTitle>}
			{props.segment.tickets.map((ticket, index) => {
				const showAdd = !((index + 1) % AD_INTERVAL) && !props.segment.title;
				const adNumber = ((Math.trunc((index + 1) / AD_INTERVAL) + AD_INTERVAL) % FREESTAR_ADS_AMOUNT) + 1;
				const slotId = Math.trunc(index / (AD_INTERVAL * FREESTAR_ADS_AMOUNT)) + 1;
				return (
					<>
						<TicketGroupRow key={ticket.tgID} ticket={ticket} ticketClick={props.ticketClick} ticketHover={props.ticketHover} panelWidth={props.panelWidth} panelTop={props.panelTop} vfsDisabled={props.vfsDisabled} />
						{showAdd && (
							<div
								css={`
									display: flex;
									justify-content: center;
								`}
							>
								<FreeStarAd placement={`sitickets.com_tickets_${adNumber}`} slotId={`sitickets.com_tickets_${adNumber}_${slotId}`} />
							</div>
						)}
					</>
				);
			})}
		</div>
	);
};

const overrideTitle = (title: string) => (title === "Other Offers" ? "Event Packages & More" : title);
