import React, { useEffect } from "react";
import { Route, RouteComponentProps, Switch, useHistory, useLocation } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { CommonPage } from "../components";
import { Profile } from "../components/MyAccount/Profile";
import { Support } from "../components/MyAccount/Support";
import { Tickets } from "../components/MyAccount/Tickets";
import { FuturesOrders } from "../components/MyAccount/Futures/FuturesOrders";
import { FuturesSold } from "../components/MyAccount/Futures/FuturesSold";
import { FuturesListings } from "../components/MyAccount/Futures/FuturesListings";
import { InternalRoutes } from "../Link";
import { maxContentWidthStyles } from "../util/maxContentWidth";
import { MetaDecorator, MetaTagData } from "../components/SEOMetadecorator/MetaDecorator";
import { ErrorToaster } from "../components/ErrorToaster/ErrorToaster";
import { useIsMobileWeb } from "../hooks/useIsMobileWeb";
import { isMobileApp } from "../util/isMobileApp";
import { MyAccountRoot } from "../components/MyAccount/MyAccountRoot";
import { MobileHeader } from "../components/MyAccount/MyAccountHeader";
import { MyAccountFooter } from "../components/MyAccount/MyAccountFooter";
import { useSelector } from "react-redux";
import si_tickets_logo from "../resource/assets/si_tickets_logo_unstacked_cropped.svg";
import {getAndDispatchBraintreePaymentMethods} from "../../services/braintree";

const getPath = (path: string) => InternalRoutes.MyAccount(path);

export const MyAccountRoutes = {
	Profile: getPath("profile"),
	Tickets: getPath("tickets"),
	Futures: getPath("reservations"),
	Support: getPath("support"),
	FuturesOrders: getPath("reservations/orders"),
	FuturesSold: getPath("reservations/sold"),
	FuturesListings: getPath("reservations/listings"),
};

export const MyAccountRoute = (props: RouteComponentProps) => {
	const userHasToken = useSelector(st => st.persistent.session.sessionToken !== undefined);
	const isMobileWeb = useIsMobileWeb();
	const isMobile = isMobileWeb || isMobileApp;
	const loginButtonHidden = userHasToken;

	const onBackClick = () => {
		props.history.replace(InternalRoutes.Home);
	};

	const showingRootScreen = props.location.pathname === InternalRoutes.MyAccount();

	useEffect(() => {
		if (!showingRootScreen && !userHasToken) {
			props.history.push(InternalRoutes.MyAccount());
		}
	}, [showingRootScreen, userHasToken]);

	useEffect(() => {
		getAndDispatchBraintreePaymentMethods();
	}, [])

	return (
		<CommonPage background="#fafafa" hideHeader={isMobile} isFooterHidden={isMobile}>
			<MetaDecorator title={MetaTagData.MyAccount.title} description={MetaTagData.MyAccount.description} />
			<Content>
				<ErrorToaster />
				{showingRootScreen && <MobileHeader title="SI Tickets" image={si_tickets_logo} onBackClick={onBackClick} borderless={loginButtonHidden} />}
				<Switch>
					<Route path={MyAccountRoutes.Profile} component={Profile} />
					<Route path={MyAccountRoutes.Tickets} component={Tickets} />
					<Route path={MyAccountRoutes.FuturesOrders} component={FuturesOrders} />
					<Route path={MyAccountRoutes.FuturesSold} component={FuturesSold} />
					<Route path={MyAccountRoutes.FuturesListings} component={FuturesListings} />
					<Route path={MyAccountRoutes.Futures} component={Futures} />
					<Route path={MyAccountRoutes.Support} component={Support} />
					<Route component={MyAccountRoot} />
				</Switch>
				{isMobile && showingRootScreen && <MyAccountFooter />}
			</Content>
		</CommonPage>
	);
};

const mobileWidth = "880px";
const contentTopBottomPaddingValue = 37;

const Futures = () => {
	const history = useHistory();
	const location = useLocation();
	if (location.pathname === MyAccountRoutes.Futures + "/" || location.pathname === MyAccountRoutes.Futures) {
		history.replace(MyAccountRoutes.FuturesOrders);
	}
	return <></>;
};

const Content = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	${!isMobileApp &&
	css`
		@media (min-width: ${mobileWidth}) {
			${maxContentWidthStyles}
			padding-top: ${contentTopBottomPaddingValue}px;
		}
	`}
	@media (max-width: ${mobileWidth}) {
		padding-top: 0;
	}
`;
