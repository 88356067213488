import React from "react";
import { CommonPage } from "../components";
import { maxContentWidthStyles } from "../util/maxContentWidth";
import { mediaQueries } from "../util/mediaQueries";
import styled from "styled-components/macro";
import { MetaDecorator, MetaTagData } from "../components/SEOMetadecorator/MetaDecorator";
import { Button } from "../components/Button/Button";
import { isMobileApp } from "../util/isMobileApp";
import { useIsMobileWeb } from "../hooks/useIsMobileWeb";
import { FanGuaranteeSlider } from "../components/FanGuarantee/FanGuaranteeSlider";
import { ExternalRoutes, InternalRoutes, Link } from "../Link";
import { FanGuaranteeCards } from "../components/FanGuarantee/FanGuaranteeCards";
import fanGuaranteeBg from "../resource/image/fan-guarantee-background.svg";
import fanGuaranteeTitleBg from "../resource/image/fan-guarantee-title-background.svg";

export const FanGuaranteeRoute = () => {
	const isMobile = useIsMobileWeb() || isMobileApp;
	return (
		<CommonPage isSitixLogoSelected>
			<MetaDecorator {...MetaTagData.FanGuarantee} />
			<PageTitle>SI Tickets Fan Guarantee</PageTitle>

			<PageBackground>{isMobile ? <FanGuaranteeSlider /> : <PageContainer>{FanGuaranteeCards()}</PageContainer>}</PageBackground>

			<TermsContainer>
				<h2>Terms</h2>
				<p>
					To receive the benefits of the Fan Guarantee, you must comply with the SI Tickets policies, guidance and emails we send you regarding your order, including any procedures that may include timelines by which SI Tickets requires notification of the issue. SI Tickets will investigate all claims under the Guarantee and determine resolutions on a case-by-case basis. Such decisions are final. Click{" "}
					<Link target="_blank" rel="noopener noreferrer" to={InternalRoutes.Terms}>
						here
					</Link>{" "}
					to view our full Terms and Conditions.
				</p>
				<CallToAction>
					<p>Need additional assistance?</p>
					<StyledButton forwardedAs="a" target="_blank" rel="noopener noreferrer" href={ExternalRoutes.ContactSupport}>
						Contact Us
					</StyledButton>
				</CallToAction>
			</TermsContainer>
		</CommonPage>
	);
};

const PageTitle = styled.div`
	position: relative;
	background-image: url("${fanGuaranteeTitleBg}");
	background-repeat: no-repeat;
	background-size: cover;
	font-family: "SolanoGothicMVB-Bd" !important;
	color: #fff;
	font-size: 80px;
	font-weight: 700;
	padding: 96px 0px;
	text-transform: uppercase;
	@media ${mediaQueries.max900} {
		font-size: 60px;
		line-height: 70px;
		padding: 40px 0px;
	}
	@media ${mediaQueries.max670} {
		font-size: 40px;
		line-height: 45px;
		padding: 18px 0px;
	}
`;

const PageBackground = styled.div`
	position: relative;
	background-image: url("${fanGuaranteeBg}");
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: contain;
	padding-bottom: 25px;
`;

const PageContainer = styled.div`
	${maxContentWidthStyles}
	position: relative;
	padding-bottom: 20px;
`;

const TermsContainer = styled.div`
	${maxContentWidthStyles}
	padding: 30px 20px 10px;
	text-align: left;
	color: #000;
	@media ${mediaQueries.max670} {
		margin: 0px;
	}
	h2 {
		font-family: "SolanoGothicMVB-Bd" !important;
		font-weight: 700;
		font-size: 40px;
		line-height: 45px;
		margin-bottom: 5px;
		text-transform: uppercase;
		@media ${mediaQueries.max670} {
			font-size: 30px;
		}
	}
	p {
		font-family: "Montserrat", sans-serif;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		a {
			text-decoration: underline;
			color: #0075ff;
		}
	}
`;

const CallToAction = styled.div`
	display: flex;
	background: #f4f4f4;
	justify-content: center;
	margin-top: 30px;
	padding: 20px;
	@media ${mediaQueries.max670} {
		flex-direction: column;
		align-items: center;
		padding-bottom: 30px;
		margin-bottom: -50px;
		width: 100%;
		margin-left: -20px;
		margin-bottom: ${isMobileApp ? "-10px" : "-50px"};
	}
	p {
		font-family: "SolanoGothicMVB-Bd" !important;
		font-size: 28px;
		line-height: 32px;
		font-weight: 700;
		text-transform: uppercase;
		margin-top: 7px;
		color: #000;
		@media ${mediaQueries.max670} {
			font-size: 30px;
			line-height: 34px;
			margin-bottom: 24px;
		}
		@media (max-width: 340px) {
			font-size: 28px;
		}
	}
`;

const StyledButton = styled(Button)`
	width: 170px;
	margin-left: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	@media ${mediaQueries.max670} {
		margin-left: 0px;
		width: 300px;
	}
`;
