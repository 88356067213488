import React, { useState } from "react";
import styled from "styled-components/macro";
import SwipeableViews from "react-swipeable-views";
import { FanGuaranteeData } from "./FanGuaranteeData";
import { FanGuaranteeCards } from "./FanGuaranteeCards";
import { maxContentWidthStyles } from "../../util/maxContentWidth";

export const FanGuaranteeSlider = () => {
	const [step, setStep] = useState(0);
	return (
		<FanGuaranteeSliderWrapper>
			<SwipeableViews index={step} animateHeight={false} onChangeIndex={e => setStep(e)}>
				{FanGuaranteeCards()}
			</SwipeableViews>
			<StepActions step={step} />
		</FanGuaranteeSliderWrapper>
	);
};

interface StepperProps {
	step: number;
}

const Stepper = (props: StepperProps) => {
	return (
		<StepperHeaderContainer>
			{FanGuaranteeData.map((data, index) => {
				return <StepperStep active={props.step === index} />;
			})}
		</StepperHeaderContainer>
	);
};

export const StepActions = (props: StepperProps) => {
	return (
		<Action>
			<Stepper step={props.step} />
		</Action>
	);
};

const FanGuaranteeSliderWrapper = styled.div`
	${maxContentWidthStyles}
	position: relative;
	padding-top: 20px;
`;

const StepperHeaderContainer = styled.div`
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	div:not(:last-child) {
		margin-right: 12px;
	}
`;

const StepperStep = styled.div<{ active: boolean }>`
	height: 12px;
	width: 12px;
	border-radius: 12px;
	background-color: ${props => (props.active ? "#ED1B24" : "#9C9C9C")};
`;

const Action = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
	width: 50%;
	margin-left: 25%;
	bottom: 25px;
`;
