import React from "react";
import { TicketTransaction } from "../../../../model/optimizedModel/myAccount";
import styled from "styled-components/macro";
import { getAppColor } from "../../../util/appColors";
import { maxContentWidth } from "../../../util/maxContentWidth";
import { breakpoints } from "../../../util/breakpoints";
import { getTypographyStyles } from "../../Typography/Typography";
import { FontFamily } from "../../Typography/Typography";
import { formatDateToUTC } from "../../../util/dateFormat";
import { CollapsibleCard } from "../../CollapsibleCard/CollapsibleCard";
import { SantizedHTML } from "../../SantizedHTML/SantizedHTML";

export const MyAccountBOItem = (props: { orderData: Pick<TicketTransaction, "event">; ticketControls?: React.ReactNode; eventDetails: React.ReactNode; orderDetails: React.ReactNode }) => {
	return (
		<div
			css={`
				background: ${getAppColor("light")};
				display: flex;
				flex-direction: column;
				margin-bottom: 24px;
				border-radius: 10px;
			`}
		>
			{props.orderData.event.cover_photo && (
				<div
					css={`
						position: relative;
						min-height: 203px;
					`}
				>
					<img
						src={props.orderData.event.cover_photo}
						alt="Event Placeholder"
						css={`
							max-width: ${maxContentWidth};
							width: 100%;
							object-fit: cover;
							object-position: top;
							max-height: 360px;
							border-radius: 10px;
						`}
					/>

					<Shade />
					<div
						css={`
							display: flex;
							flex-direction: column;
							position: absolute;
							bottom: 0;
							padding: 10px;
						`}
					>
						<EventTitle>{props.orderData.event.name}</EventTitle>
						<TicketItemText>{props.orderData.event.venue.name}</TicketItemText>
						<TicketItemText>{formatDateToUTC(new Date(props.orderData.event.occursAt), "LLL d t")}</TicketItemText>
					</div>
				</div>
			)}
			{props.ticketControls}

			<div
				css={`
					margin-top: 15px;
					padding: 0 15px;
				`}
			>
				<div>
					<CollapsibleCard
						header={
							<TicketItemText
								css={`
									color: ${getAppColor("dark")};
									font-weight: 600;
								`}
							>
								Event Details
							</TicketItemText>
						}
						controlColor={getAppColor("darkGrey", "main")}
						containerStyles={`border: none; border-bottom:1px solid #D3D3D3;`}
						contentStyles={`
							font-family: ${FontFamily.Poppins};
							padding: 16px 0px;
						`}
					>
						{props.orderDetails}
					</CollapsibleCard>
				</div>
				<div>
					<CollapsibleCard
						header={
							<TicketItemText
								css={`
									color: ${getAppColor("dark")};
									font-weight: 600;
								`}
							>
								Order Details
							</TicketItemText>
						}
						controlColor={getAppColor("darkGrey", "main")}
						containerStyles={`
						border: none; 
						border-bottom:1px solid #D3D3D3;
						
						@media(max-width: ${breakpoints.tablet}) {
							border-bottom: none;
						}`}
						contentStyles={`
							font-family: ${FontFamily.Poppins};
							padding: 16px 0px;
						`}
					>
						{props.eventDetails}
					</CollapsibleCard>
				</div>
			</div>
		</div>
	);
};

const BaseText = styled.span`
	font-family: ${FontFamily.Poppins};
	text-align: left;
`;

const EventTitle = styled(BaseText)`
	${getTypographyStyles("bodyMedium", { color: "light", colorType: "main", fontWeight: 700 })};
`;

const TicketItemText = styled(BaseText)`
	${getTypographyStyles("bodySmall", { color: "light", colorType: "main", fontWeight: 500 })};
`;

const Shade = styled.div`
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 117.61%);
	border-radius: 10px;
`;
