import * as t from "io-ts";

export const PopularPerformerCodec = t.strict({
	id: t.number,
	name: t.string,
	slug: t.string,
	popularity_score: t.string,
	category_id: t.union([t.null, t.number]),
	parent_category_id: t.union([t.null, t.number]),
	image: t.string,
});

export const PopularPerformersCodec = t.array(PopularPerformerCodec);

export type PopularPerformer = t.TypeOf<typeof PopularPerformerCodec>;
export type PopularPerformersResponse = t.TypeOf<typeof PopularPerformersCodec>;
