export type NavigationItem = {
	id: number;
	order: number;
	name: string;
	url?: string;
	children?: NavigationItem[];
};

/**
 * Recursively exclude items containing the specified term
 */
export const excludeItems = (items: NavigationItem[], term: string) => {
	const notUndefined = (item?: NavigationItem) => {
		return typeof item !== "undefined";
	};
	const noGameLanguage = (item: NavigationItem) => {
		if (item.name === term) {
			return undefined;
		}
		if (item.children?.length) {
			item.children = excludeItems(item.children, term);
		}
		return item;
	};
	return items.map(noGameLanguage).filter(notUndefined) as any;
};
