import React, { useState } from "react";
import styled from "styled-components/macro";
import { EventProtectionPopup } from "../Checkout/Modal/Payment/EventProtectionPopup";
import shield_guarantee from "../../resource/img/sitix/funnel/shield_guarantee.svg";
import shield_red from "../../resource/img/sitix/funnel/shield_guarantee_red.svg";

export enum ShieldColor {
	Red,
	Black,
}

export const ShieldIcon = (props: { color?: ShieldColor; className?: string }) => {
	const [showPopup, setShowPopup] = useState(false);
	return (
		<>
			{showPopup ? <EventProtectionPopup onDidClose={() => setShowPopup(false)} /> : null}
			<Image
				className={props.className}
				style={
					props.color === ShieldColor.Red
						? {
								height: 39,
						  }
						: {}
				}
				src={props.color === undefined || props.color === ShieldColor.Black ? shield_guarantee : shield_red}
				alt=""
				onClick={() => setShowPopup(true)}
			/>
		</>
	);
};

const Image = styled.img`
	float: right;
	height: 56px;
	width: auto;
	cursor: pointer;
`;
