import { PresaleEvent } from "./model/Event";

export const home_featured = {
	trends: [
		{
			title: "The Killers",
			subtitle: "Get tickets to see The Killers near you!",
			titleLink: "/performer/the-killers",
			image: require("./presentation/resource/img/sitix/hardcode/trending_1.jpg"),
			body: "10-10-2020",
		},
		{
			title: "Andrea Bocelli",
			subtitle: "Don't miss your chance to see Andrea Bocelli live!",
			titleLink: "/performer/andrea-bocelli",
			image: require("./presentation/resource/img/sitix/hardcode/trending_2.jpg"),
			body: "10-10-2020",
		},
		{
			title: "Christina Aguilera",
			subtitle: "The Xperience - See Christina in Vegas!",
			titleLink: "/performer/christina-aguilera",
			image: require("./presentation/resource/img/sitix/hardcode/trending_3.jpg"),
			body: "10-10-2020",
		},
		{
			title: "Kane Brown TEST",
			subtitle: "World Wide Beautiful Tour!",
			titleLink: "/performer/kane-brown",
			image: require("./presentation/resource/img/sitix/hardcode/trending_4.jpg"),
			body: "10-10-2020",
		},
	],
	early: [
		{
			id: 0,
			name: "New York Yankees",
			performers: [],
			vanueSlug: "",
			venueId: 0,
			venueSlug: "",
			venueLocation: "",
			venueName: "Yankee Stadium",
			featuredUntil: new Date("2020-12-10"),
			onSaleDate: new Date("2021-01-10"),
			preSaleDate: new Date("2020-04-10"),
			slug: "event/new-york-yankees",
			image: require("./presentation/resource/img/sitix/hardcode/early_1.jpg"),
			primary: false,
		},
		{
			id: 1,
			name: "Houston Dynamo",
			performers: [],
			venueSlug: "",
			venueId: 0,
			venueLocation: "",
			venueName: "BBVA Stadium",
			featuredUntil: new Date("2020-12-10"),
			onSaleDate: new Date("2021-01-10"),
			preSaleDate: new Date("2020-12-10"),
			slug: "event/houston-dynamo",
			image: require("./presentation/resource/img/sitix/hardcode/early_2.jpg"),
			primary: false,
		},
		{
			id: 2,
			name: "Houston Texans",
			performers: [],
			venueSlug: "",
			venueId: 0,
			venueLocation: "",
			venueName: "NRG Stadium",
			featuredUntil: new Date("2020-12-10"),
			onSaleDate: new Date("2021-01-10"),
			preSaleDate: new Date("2020-12-10"),
			slug: "event/houston-texans",
			image: require("./presentation/resource/img/sitix/hardcode/early_3.jpg"),
		},
		{
			id: 3,
			name: "Moulin Rouge! The Musical",
			venueId: 0,
			venueSlug: "",
			venueLocation: "",
			venueName: "James M. Nederlander Theater",
			featuredUntil: new Date("2020-12-10"),
			onSaleDate: new Date("2021-01-10"),
			preSaleDate: new Date("2020-12-10"),
			slug: "event/moulin-rouge",
			image: require("./presentation/resource/img/sitix/hardcode/early_4.jpg"),
		},
	] as PresaleEvent[],
	sibets: [
		{
			id: 1,
			slug: "/sibets/sibets_1",
			image: require("./presentation/resource/img/sitix/hardcode/sibets_1.png"),
		},
		{
			id: 2,
			slug: "/sibets/sibets_2",
			image: require("./presentation/resource/img/sitix/hardcode/sibets_2.png"),
		},
		{
			id: 3,
			slug: "/sibets/sibets_3",
			image: require("./presentation/resource/img/sitix/hardcode/sibets_3.png"),
		},
		{
			id: 4,
			slug: "/sibets/sibets_4",
			image: require("./presentation/resource/img/sitix/hardcode/sibets_4.png"),
		},
	],
	discover: [
		{
			title: "Boston Red Sox at New York Mets",
			subtitle: "Citi Field, Queens, NY",
			body: "Tue, Apr 27 2021 at TBD",
			titleLink: "/event/boston-red-sox-at-new-york-mets",
			image: require("./presentation/resource/img/sitix/hardcode/discover_1.jpg"),
		},
		{
			title: "Detroit Tigers at New York Yankees",
			subtitle: "Yankee Stadium, Bronx, NY",
			body: "May 2, 2021 at TBD",
			titleLink: "/event/detroit-tigers-at-new-york-yankees",
			image: require("./presentation/resource/img/sitix/hardcode/discover_2.jpg"),
		},
		{
			title: "Billy Joel",
			subtitle: "Madison Square Garden, New York, NY",
			body: "Fri, Apr 9 2022 at TBD",
			titleLink: "/event/billy-joel-rescheduled-from-08-03-2020",
			image: require("./presentation/resource/img/sitix/hardcode/discover_3.jpg"),
		},
		{
			title: "Last Podcast On The Left",
			subtitle: "Beacon Theater - NY, New York, NY",
			body: "Tue, Jun 8 2021 at TBD",
			titleLink: "/event/last-podcast-on-the-left",
			image: require("./presentation/resource/img/sitix/hardcode/discover_4.jpg"),
		},
	],
	exclusive: [
		{
			title: "Super Bowl Party",
			subtitle: "",
			body: "",
			titleLink: "/event/super-bowl-pary",
			image: require("./presentation/resource/img/sitix/hardcode/exclusive_1.jpg"),
		},
		{
			title: "Shaq vs. Gronk Party",
			subtitle: "",
			body: "",
			titleLink: "/event/shaq-vs-gronk-party",
			image: require("./presentation/resource/img/sitix/hardcode/exclusive_2.jpg"),
		},
		{
			title: "Monaco Grand Prix Party",
			subtitle: "",
			body: "",
			titleLink: "/event/monaco-grand-prix-party",
			image: require("./presentation/resource/img/sitix/hardcode/exclusive_3.jpg"),
		},
		{
			title: "Swimsuit Party",
			subtitle: "",
			body: "",
			titleLink: "/event/swimsuit-party",
			image: require("./presentation/resource/img/sitix/hardcode/exclusive_4.jpg"),
		},
	],
};

export const home_offer = {
	image: require("./presentation/resource/img/modal/sitix_offer_modal.png"),
	to: "#",
	delay: 5000,
};

export const gaEvents = {
	id: 263627,
	name: "General Admittance Event",
	slug: "ga-event-page",
	image: "/api/assets/performers/james-taylor/thumbnail_image",
	date: new Date(1655938800000),
	venueLocation: "South Beach",
	venueName: "Thompson Boling Arena",
	venueState: "TN",
	venueCity: "Knoxville",
	venueId: 209,
	venueSlug: "thompson-boling-arena",
	noMapImageUrl: "/api/assets/categories/sports/no_map_image_url",
	ticketGroupPrice: "500",
	tickets: [
		{
			tgID: 5146800,
			tgQty: 13,
			tgUserSec: "116",
			tgUserRow: "19",
			tgPrice: "20.00",
			tgSplits: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13],
			tgMark: "0",
			tgType: "1",
			tgDeliveryMethods: ["Mobile Transfer"],
			tgNotes: "Orders for these tickets will be accepted and fulfilled. Scan your tickets from your mobile phone for this event.",
			expectedShipByDate: "2022-06-20",
			tgServiceFeeRate: null,
			tgTransactionFeeRate: 0.2,
			tgTaxRate: null,
			tgDeliveryOptions: "LP",
		},
		{
			tgID: 5146793,
			tgQty: 19,
			tgUserSec: "110",
			tgUserRow: "B",
			tgPrice: "30.00",
			tgSplits: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 19],
			tgMark: "0",
			tgType: "1",
			tgDeliveryMethods: ["Mobile Transfer"],
			tgNotes: "Orders for these tickets will be accepted and fulfilled. Scan your tickets from your mobile phone for this event.",
			expectedShipByDate: "2022-06-20",
			tgServiceFeeRate: null,
			tgTransactionFeeRate: 0.2,
			tgTaxRate: null,
			tgDeliveryOptions: "LP",
		},
		{
			tgID: 5146795,
			tgQty: 11,
			tgUserSec: "110",
			tgUserRow: "B",
			tgPrice: "75.00",
			tgSplits: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
			tgMark: "0",
			tgType: "1",
			tgDeliveryMethods: ["Mobile Transfer"],
			tgNotes: "Orders for these tickets will be accepted and fulfilled. Scan your tickets from your mobile phone for this event.",
			expectedShipByDate: "2022-06-20",
			tgServiceFeeRate: null,
			tgTransactionFeeRate: 0.2,
			tgTaxRate: null,
			tgDeliveryOptions: "LP",
		},
	],
};
