import React from "react";
import { shortFormatPrice, formatPrice } from "../../util/formatPrice";
import { InvoiceItem, InvoiceItemProps } from "../Invoices/InvoiceItem";
import { PartialBy } from "../../../model/optimizedModel/general";
import styled from "styled-components";
import { Typography } from "../Typography/Typography";

export const TotalInvoiceItem = ({
	total,
	priceFormat = "standard",
	title = "Total",
	css,
	...invoiceItemProps
}: PartialBy<Omit<InvoiceItemProps, "value">, "title"> & {
	total: number;
	priceFormat?: "short" | "standard";
}) => {
	return (
		<InvoiceItem
			{...invoiceItemProps}
			title={title}
			value={
				<TotalContainer>
					<Typography type="bodyNormal" fontWeight={600}>
						{total === 0 ? "Free" : (priceFormat === "standard" ? formatPrice : shortFormatPrice)(total)}
					</Typography>
				</TotalContainer>
			}
			css={{
				fontWeight: 700,
				fontSize: 18,
				...css,
			}}
		/>
	);
};

const TotalContainer = styled.div`
	display: flex;
`;
