import React from "react";
import styled from "styled-components/macro";
import { CommonPage } from "../components";
import { ResetPanel } from "../components/Login/ResetPanel";
import { RouteComponentProps } from "react-router";
import { parseSearch } from "../util/parseSearch";
import { MetaDecorator, MetaTagData } from "../components/SEOMetadecorator/MetaDecorator";

interface PasswordResetParams {
	token: string;
}

interface PasswordResetSearchParams {
	email: string;
}

export const PasswordResetRoute = (props: RouteComponentProps<PasswordResetParams>) => {
	const searchParams = parseSearch<PasswordResetSearchParams>(props.location.search);

	return (
		<CommonPage background="#f7f7f7">
			<MetaDecorator title={MetaTagData.PasswordReset.title} description={MetaTagData.PasswordReset.description} />
			<ResetPanelContainer>
				<ResetPanel token={props.match.params.token} email={searchParams.email} />
			</ResetPanelContainer>
		</CommonPage>
	);
};

const ResetPanelContainer = styled.div`
	width: 100%;
	max-width: 700px;
	box-sizing: border-box;
	margin: 0 auto;
	@media (min-width: 700px) {
		padding: 3rem;
	}
`;
