import { ServiceCallAction } from "../../store/middleware/serviceCallMiddleware";
import { ConferencesCodec, ConferencesResponse } from "../types/ConferencesResponse";

export const getConferences = (sportId: number): ServiceCallAction<ConferencesResponse> => ({
	method: "GET",
	param: { sportId },
	endpoint: "conferences",
	serviceKey: "getConferences",
	responseDecoder: ConferencesCodec,
	type: "SERVICE_CALL",
});
