import { EventCubeTicket } from "../../model/Cart";
import { EventData } from "../../model/EventPage";
import { Nulleable } from "../../model/Utils";
import { SeaticsLegendItem, SeaticsTicketGroup, SeaticsTicketSegment } from "../../presentation/components/Seatics/types";
import { EventVenueInfoResponse } from "../../services/types/EventVenueInfoResponse";
import { EventBetsResponse } from "../../services/types/MetaBetsResponseCodec";
import { TicketResponse } from "../../services/types/TicketResponse";
import { EventActions } from "../reducers/eventReducer";

export const setEventData = (data: EventData): EventActions => ({
	type: "SET_EVENT_DATA",
	data,
});

export const setTicketsData = (data: TicketResponse): EventActions => ({
	type: "SET_TICKETS_DATA",
	data,
});

export const setVisibleTickets = (data: SeaticsTicketSegment[]): EventActions => ({
	type: "SET_VISIBLE_TICKETS",
	data,
});

export const setLegendItems = (data: SeaticsLegendItem[]): EventActions => ({
	type: "SET_LEGEND_ITEMS",
	data,
});

export const setSection = (data: SeaticsLegendItem): EventActions => ({
	type: "SET_SECTION",
	data,
});

export const setSeaticsData = (data: EventVenueInfoResponse): EventActions => ({
	type: "SET_SEATICS_DATA",
	data,
});

export const setLoadingStatus = (status: boolean): EventActions => ({
	type: "SET_LOADING",
	status,
});

export const setSeaticsTimeout = (status: boolean): EventActions => ({
	type: "SET_SEATICS_TIMEOUT",
	status,
});

export const setLoadedEvent = (eventSlug: string): EventActions => ({
	type: "SET_LOADED_EVENT",
	eventSlug,
});

export const setRequestError = (status: boolean): EventActions => ({
	type: "SET_REQUEST_ERROR",
	status,
});

export const setShowReplacementModal = (status: boolean, quantity: number): EventActions => ({
	type: "SET_REPLACEMENT_MODAL_VISIBILITY",
	status,
	quantity,
});

export const setShowEventInfo = (visible: boolean): EventActions => ({
	type: "SET_EVENT_INFO_VISIBILITY",
	visible,
});

export const setShowEventFilter = (visible: boolean): EventActions => ({
	type: "SET_EVENT_FILTER_VISIBILITY",
	visible,
});

export const setUnavailableError = (message: Nulleable<string>): EventActions => ({
	type: "SET_UNAVAILABLE",
	message,
});

export const setSelectedTickets = (ticket: SeaticsTicketGroup | null): EventActions => ({
	type: "SET_SELECTED_TICKET",
	ticket,
});

export const setSelectedBOTickets = (ticket: SeaticsTicketGroup | null): EventActions => ({
	type: "SET_SELECTED_BO_TICKET",
	ticket,
});

export const removeSelectedBOTicket = (ticketGroupId: number): EventActions => ({
	type: "REMOVE_SELECTED_BO_TICKET",
	ticketGroupId,
});

export const setSelectedEventCubeTicket = (ticket: EventCubeTicket | null): EventActions => ({
	type: "SET_SELECTED_EVENT_CUBE_TICKET",
	ticket,
});

export const setHighlightedTicket = (ticket: Nulleable<SeaticsTicketGroup>): EventActions => ({
	type: "SET_HIGHLIGHTED_TICKET",
	ticket,
});

export const setHighlightedSection = (section: Nulleable<SeaticsLegendItem>): EventActions => ({
	type: "SET_HIGHLIGHTED_SECTION",
	section,
});

export const setEventBets = (eventBets: EventBetsResponse | null): EventActions => ({
	type: "SET_EVENT_BETS",
	data: eventBets,
});

export const resetAll = (): EventActions => ({
	type: "RESET_ALL",
});
