import React from "react";
import styled from "styled-components/macro";
import { CommonPage } from "../components";
import { MetaDecorator, MetaTagData } from "../components/SEOMetadecorator/MetaDecorator";
import { FontFamily } from "../components/Typography/Typography";
import { useNewNavbar } from "../hooks/navbar";
import { breakpoints } from "../util/breakpoints";
import { maxContentWidthStyles, maxContentWidthValue } from "../util/maxContentWidth";
import hero_bg from "../resource/assets/masl-lp/hero_bg.svg";
import hero_logo from "../resource/assets/masl-lp/hero_logo.png";
import { ReactComponent as SIIcon } from "../resource/assets/masl-lp/si_icon.svg";
import { ReactComponent as GiftIcon } from "../resource/assets/masl-lp/gift_icon.svg";
import { ReactComponent as TicketIcon } from "../resource/assets/masl-lp/ticket_icon.svg";
import { ReactComponent as TrophyIcon } from "../resource/assets/masl-lp/trophy_icon.svg";
import { ReactComponent as WhistleIcon } from "../resource/assets/masl-lp/whistle_icon.svg";
import { ReactComponent as SoccerIcon } from "../resource/assets/masl-lp/soccer_icon.svg";
import metal_frame from "../resource/assets/masl-lp/metal_frame.svg";
import ambush from "../resource/assets/masl-lp/team-cards/Ambush.png";
import blast from "../resource/assets/masl-lp/team-cards/Blast.png";
import comets from "../resource/assets/masl-lp/team-cards/Comets.png";
import flash from "../resource/assets/masl-lp/team-cards/Flash.png";
import heat from "../resource/assets/masl-lp/team-cards/Heat.png";
import outlaws from "../resource/assets/masl-lp/team-cards/Outlaws.png";
import savage from "../resource/assets/masl-lp/team-cards/Savage.png";
import sidekicks from "../resource/assets/masl-lp/team-cards/Sidekicks.png";
import sockers from "../resource/assets/masl-lp/team-cards/Sockers.png";
import stars from "../resource/assets/masl-lp/team-cards/Stars.png";
import strykers from "../resource/assets/masl-lp/team-cards/Strykers.png";
import tropics from "../resource/assets/masl-lp/team-cards/Tropics.png";
import ucfc from "../resource/assets/masl-lp/team-cards/UCFC.png";
import wave from "../resource/assets/masl-lp/team-cards/Wave.png";
import { getAppColor } from "../util/appColors";
import { Link } from "react-router-dom";
import { InternalRoutes } from "../Link";
import { ParallelogramAccent } from "../components/Accents/ParallelogramAccent";

const metalBorder = (size: number) => `
	border: ${size}px solid;
	border-image: url(${metal_frame});
	border-image-slice: ${size} fill;
`;

export const MASLRoute = () => {
	useNewNavbar();

	return (
		<CommonPage
			css={`
				${CommonPage.FooterContainer} {
					margin-top: 0;
				}
			`}
		>
			<MetaDecorator 
				{...MetaTagData.MASL}
			/>
			<div
				css={`
					& > * {
						border-bottom: 5px solid ${getAppColor("primary")};
						padding: 48px 0;
					}
				`}
			>
				<div
					css={`
						background-image: url(${hero_bg});
						min-height: 13vh;
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;
						display: flex;
						align-items: center;
						justify-content: center;
						position: relative;
						& > *:first-child, & > *:last-child {
							height: 150px;
						}
					`}
				>
					<ParallelogramAccent
						position="left"
						css={`
							position: absolute;
							left: 0;
							top: 28px;
						`}
					/>
					<div
						css={`
							${maxContentWidthStyles}
							z-index: 1;
							width: 100%;
						`}
					>
						<img 
							src={hero_logo}
							alt="SI Tickets | Official Ticket Partner of the MASL"
							css={`
								max-width: 100%;
    						max-height: 300px;
							`}
						/>
					</div>
					<ParallelogramAccent
						position="right"
						css={`
							position: absolute;
							right: 0;
							bottom: 28px;
						`}
					/>
				</div>
				<div
					css={`
						position: relative; 
					`}
				>
					<ParallelogramAccent
						position="left"
						css={`
							position: absolute;
							left: 0;
							top: 0;
							bottom: 0;
							margin: auto;
							height: 79%;
							@media (max-width: ${maxContentWidthValue + 140}px) {
								display: none;
							}
						`}
					/>
					<div
						css={`
							${maxContentWidthStyles}
							display: grid;
    					grid-template-columns: 1fr 1fr;
							text-align: left;
							gap: 28px 46px;
							align-items: center;
							@media (max-width: ${breakpoints.tablet}) {
								grid-template-columns: initial;
							}
						`}
					>		
						<div
							css={`
								${metalBorder(9)}
								@media (max-width: 936px) {
									${metalBorder(7)}
								}
								@media (max-width: ${breakpoints.tablet}) {
									${metalBorder(14)}
								}
								@media (max-width: 690px) {
									${metalBorder(11)}
								}
								@media (max-width: 552px) {
									${metalBorder(9)}
								}
								@media (max-width: 460px) {
									${metalBorder(7)}
								}
							`}
						>
							<div
								css={`
									position: relative;
									width: 100%;
									height: 0;
									padding-bottom: 56.26%;
									& > iframe {
										position: absolute;
										width: 100%;
										height: 100%;
										left: 0;
										top: 0;
										bottom: 0;
										right: 0;
									}
								`}
							>
								<iframe 
									src="https://www.youtube.com/embed/qzo7HEpwuAM"
									//@ts-ignore
									allowfullscreen
								/>
							</div>
						</div>
						<div>
							<Header
								css={`
									margin-bottom: 12px;
								`}
							>
								Get your tickets to see the best indoor soccer in the world
							</Header>
							<Paragraph>
								Catch the fast-paced, fiercely competitive end-to-end action featuring teams coast-to-coast in the U.S. and Mexico. You don’t want to miss the non-stop dazzling plays, magnificent goals and sensational goalkeeping that only the arena league can deliver.
							</Paragraph>
						</div>
					</div>
				</div>
				<div
					css={`
						background-image: url(${hero_bg});
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;
					`}
				>
					<div
						css={`
							${maxContentWidthStyles};
							display: flex;
							justify-content: center;
							overflow: hidden;
    					flex-direction: column;
							align-items: center;
						`}
					>
						<Header
							css={`
								color: white;
								text-transform: uppercase;
								border-radius: 3px;
								margin-bottom: 32px;
								white-space: nowrap;
							`}
						>
							Get tickets to see your team
						</Header>
						<div
							css={`
								justify-content: center;
								width: 100%;
								gap: 15px;
								display: flex;
								flex-wrap: wrap;
								& > * {
									flex: 1;
									min-width: 192px;
									max-width: 0;
									@media (max-width: ${breakpoints.mobile}) {
										min-width: 150px;
									}
								}
							`}
						>
							<TeamThumbnail 
								team="flash"
							/>
							<TeamThumbnail 
								team="comets"
							/>
							<TeamThumbnail 
								team="heat"
							/>
							<TeamThumbnail 
								team="stars"
							/>
							<TeamThumbnail 
								team="strykers"
							/>
							<TeamThumbnail 
								team="sidekicks"
							/>
							<TeamThumbnail 
								team="wave"
							/>
							<TeamThumbnail 
								team="tropics"
							/>
							<TeamThumbnail 
								team="sockers"
							/>
							<TeamThumbnail 
								team="ambush"
							/>
							<TeamThumbnail 
								team="savage"
							/>
							<TeamThumbnail 
								team="outlaws"
							/>
							<TeamThumbnail 
								team="blast"
							/>
							<TeamThumbnail 
								team="ucfc"
							/>
						</div>
					</div>
				</div>
				<div
					css={`
						position: relative;
					`}
				>
					<ParallelogramAccent
						position="left"
						css={`
							position: absolute;
							left: 0;
							top: 0;
							bottom: 0;
							margin: auto;
							z-index: -1;
							height: 270px;
							@media (max-width: ${maxContentWidthValue + 140}px) {
								display: none;
							}
						`}
					/>
					<div
						css={`
							${maxContentWidthStyles}
							z-index: 1;
						`}
					>
						<Header>
							Get out to a game today and experience greatness
						</Header>
						<Paragraph
							css={`
								margin-top: 8px;
								margin-bottom: 33px;
							`}
						>
							In-match Activations may Feature:	
						</Paragraph>
						<div
							css={`
								gap: 20px 24px;
								display: inline-grid;
								grid-template-columns: repeat(2, minmax(0, 1fr));
								@media (max-width: ${breakpoints.mobile}) {
									display: flex;
    							flex-direction: column;
								}
							`}
						>
							<IconCard 
								icon={SIIcon}
								title="Custom SI Covers"
							/>
							<IconCard 
								icon={GiftIcon}
								title="Giveaways"
							/>
							<IconCard 
								icon={TicketIcon}
								title="Ticket Upgrades"
							/>
							<IconCard 
								icon={SoccerIcon}
								title="Golden Penalty Kick"
							/>
							<IconCard 
								icon={WhistleIcon}
								title="Ceremonial Ball Drop"
							/>
							<IconCard 
								icon={TrophyIcon}
								title="MASL Championship Tickets"
							/>
						</div>
					</div>
					<ParallelogramAccent
						position="right"
						css={`
							position: absolute;
							right: 0;
							bottom: 25px;
							margin: auto;
							z-index: -1;
							height: 140px;
							@media (max-width: ${maxContentWidthValue + 140}px) {
								display: none;
							}
						`}
					/>
				</div>
			</div>
		</CommonPage>
	);
};

const TeamThumbnail = (props: {
	team: "ambush" | "blast" | "comets" | "flash" | "heat" | "outlaws" | "savage" | "sidekicks" | "sockers" | "stars" | "strykers" | "tropics" | "ucfc" | "wave",
}) => {
	return (
		<Link
			to={InternalRoutes.Performer({
				ambush: "st-louis-ambush",
				blast: "baltimore-blast",
				comets: "kansas-city-comets",
				flash: "monterrey-flash-1",
				heat: "harrisburg-heat",
				outlaws: "mesquite-outlaws-1",
				savage: "chihuahua-savage",
				sidekicks: "dallas-sidekicks",
				sockers: "san-diego-sockers",
				stars: "tacoma-stars",
				strykers: "empire-strykers",
				tropics: "florida-tropics-sc",
				ucfc: "utica-city-fc-1",
				wave: "milwaukee-wave",
			}[props.team])}
		>
			<img 
				src={{
					ambush: ambush,
					blast: blast,
					comets: comets,
					flash: flash,
					heat: heat,
					outlaws: outlaws,
					savage: savage,
					sidekicks: sidekicks,
					sockers: sockers,
					stars: stars,
					strykers: strykers,
					tropics: tropics,
					ucfc: ucfc,
					wave: wave,
				}[props.team]}
				css={`
					width: 100%;
				`}
			/>
		</Link>
	);
};

const IconCard = (props: {
	icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
	title: string,
}) => {
	return (
		<div
			css={`
				background: #f8f9f8;
				padding: 32px;
				display: flex;
				flex-direction: column;
				align-items: center;
				& > svg {
					height: 66px;
					fill: ${getAppColor("primary")};
				}
			`}
		>
			<props.icon />
			<Header
				css={`
					margin-top: 12px;	
					white-space: nowrap;
					@media (max-width: ${breakpoints.tablet}) {
						font-size: 24px;
					}
				`}
			>
				{props.title}
			</Header>
		</div>
	);
};
 
const Header = styled.h2`
	color: black;
	font-family: ${FontFamily.Solano};
	font-size: 36px;
	@media (max-width: ${breakpoints.mobile}) {
		font-size: 30px;
	}
`;

const Paragraph = styled.p`
	color: black;
	font-family: ${FontFamily.Poppins};
	font-size: 14px;
	font-weight: 500;
	line-height: 1.4;
`;