import React, { useState } from "react";
import { FuturesListing } from "../../../../model/optimizedModel/myAccount";
import { toBrowserLocaleString } from "../../../util/optimized/dates";
import { SmallButton, MediumButtonStyles } from "../../Button/Button";
import { MyAccountMarketFuture } from "./MyAccountMarketFuture";
import { formatPrice } from "../../../util/formatPrice";
import { FuturesListingFormModal } from "./FuturesListingFormModal";
import { defaultModalAnimationLength, ModalPopup } from "../../ModalPopup/ModalPopup";
import { ModalCloseButton } from "../../Modal/ModalCloseButton";
import { ModalPosition } from "../../Modal";
import { MediumButtonWithSpinner } from "../../Button/ButtonWithSpinner";
import { postMyAccountMyOrdersCancelListing } from "../../../../services/myAccount";
import toast from "react-hot-toast";
import styled from "styled-components/macro";

export const MyAccountFutureListing = ({ futureListing, ...props }: { futureListing: FuturesListing; onCancel?: () => void }) => {
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [cancelModalOpen, setCancelModalOpen] = useState(false);
	const [listingPrice, setListingPrice] = useState(futureListing.listingPrice);
	const [cancelListingLoading, setCancelListingLoading] = useState(false);

	return (
		<>
			<FuturesListingFormModal
				modalOpen={editModalOpen}
				setModalOpen={setEditModalOpen}
				futureInventoryId={futureListing.futureInventoryId}
				eventTitle={futureListing.event.title}
				edit
				currentPrice={listingPrice}
				onSuccess={values => {
					setListingPrice(values.price);
				}}
			/>
			{
				//TODO: Re-write for optimized frontend
				cancelModalOpen && (
					<ModalPopup onDidClose={() => setCancelModalOpen(false)} rightHeaderElements={(onClose: any) => <ModalCloseButton onClick={onClose} />} centerHeaderElements={"Cancel Listing"} position={ModalPosition.Center}>
						{onClose => {
							return (
								<CancelModal>
									<p>Are you sure you want to cancel your listing for "{futureListing.event.title}"?</p>
									<div>
										<MediumButtonWithSpinner
											loading={cancelListingLoading}
											onClick={() => {
												setCancelListingLoading(true);
												postMyAccountMyOrdersCancelListing(futureListing.futureInventoryId)
													.then(() => {
														toast.success('Cancelled "' + futureListing.event.title + '".');
														onClose();
														if (props.onCancel) {
															setTimeout(() => {
																//The second if statement is here due to TS error.
																if (props.onCancel) {
																	props.onCancel();
																}
															}, [defaultModalAnimationLength * 1000 + 50]);
														}
													})
													.catch(error => {
														toast.error(error.errors);
													})
													.finally(() => {
														setCancelListingLoading(false);
													});
											}}
										>
											Confirm
										</MediumButtonWithSpinner>
										<MediumWhiteButton
											onClick={() => {
												onClose();
											}}
										>
											Cancel
										</MediumWhiteButton>
									</div>
								</CancelModal>
							);
						}}
					</ModalPopup>
				)
			}
			<MyAccountMarketFuture<FuturesListing>
				future={{
					...futureListing,
					listingPrice,
				}}
				additionalTitleAndContentGridItems={[
					{
						key: "marketPrice",
						title: "Market Price",
						content: formatPrice(futureListing.marketPrice),
					},
					{
						key: "dateListed",
						title: "Date Listed",
						content: toBrowserLocaleString(
							new Date(futureListing.dateListed),
							{
								//@ts-ignore TODO: TS considers this to be an invalid property when it's not, consider looking into a solution
								dateStyle: "short",
							},
							"date"
						),
					},
				]}
				//TODO: Add proper functionality to these controls.
				controls={
					<>
						<SmallButton
							onClick={() => {
								setEditModalOpen(true);
							}}
						>
							Edit Listing
						</SmallButton>
						<SmallButton
							onClick={() => {
								setCancelModalOpen(true);
							}}
						>
							Cancel Listing
						</SmallButton>
					</>
				}
			/>
		</>
	);
};

//TODO: Reuse the "Confirm/Cancel" modal styles from the profile page updates once they're merged in
const CancelModal = styled.div`
	max-width: 80vw;
	padding: 10px;
	p {
		color: black;
		font-weight: 500;
		font-size: 16px;
		margin-top: 10px;
		max-width: 350px;
		text-align: left;
		line-height: normal;
	}
	& > div {
		display: flex;
		margin-top: 25px;
		& > *:not(:first-child) {
			margin-left: 14px;
		}
	}
`;

const MediumWhiteButton = styled(SmallButton)`
	background: #fff;
	color: #000;
	border: solid 1px #767977 !important;
	${MediumButtonStyles}
`;
