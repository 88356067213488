import { ServiceCallAction } from "../../store/middleware/serviceCallMiddleware";
import { Venue, VenueCodec } from "../types/VenueResponse";
import { APIClientResponse } from "../types/APIClientResponse";
import { apiClient } from "../apiClient";

export const getVenueService = (venue: string): ServiceCallAction<Venue> => ({
	method: "GET",
	param: {
		venue_slug: venue,
	},
	endpoint: "venue",
	serviceKey: "getVenue",
	responseDecoder: VenueCodec,
	type: "SERVICE_CALL",
});

export const getVenueBioService = (venue: string): APIClientResponse<{ bio: string }> => {
	return apiClient.get(`assets/venues/${venue}`);
};
