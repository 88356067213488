import { Reducer } from "redux";

type AuthProvider = "email" | "google" | "facebook" | "apple";
interface SessionState {
	sessionToken?: string;
	rememberedEmail?: string;
	provider?: AuthProvider;
}

export type SessionAction =
	| {
			type: "DID_LOG_IN";
			provider: AuthProvider;
			sessionToken: string;
			email: string;
			rememberEmail?: boolean;
	  }
	| {
			type: "DID_LOG_OUT";
			reason?: string;
	  };

export const sessionReducer: Reducer<SessionState, SessionAction> = (state: SessionState = {}, action: SessionAction): SessionState => {
	switch (action.type) {
		case "DID_LOG_IN":
			return {
				sessionToken: action.sessionToken,
				provider: action.provider,
				...(action.rememberEmail ? { rememberedEmail: action.email } : undefined),
			};
		case "DID_LOG_OUT":
			return {
				rememberedEmail: state.rememberedEmail,
			};
		default:
			return state;
	}
};

export const didLogIn = (provider: AuthProvider, sessionToken: string, email: string, rememberEmail?: boolean): SessionAction => ({
	type: "DID_LOG_IN",
	sessionToken,
	email,
	provider,
	rememberEmail,
});

export const didLogOut = (reason?: string): SessionAction => ({
	type: "DID_LOG_OUT",
	reason,
});