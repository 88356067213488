import React, { useEffect, useMemo, useState, useRef } from "react";
import { Nulleable } from "../../../model/Utils";
import { EventCubeTicket } from "../../../model/Cart";
import styled from "styled-components/macro";
import { getAppColor } from "../../util/appColors";
import { getTypographyStyles } from "../Typography/Typography";
import clearIcon from "../../resource/image/clear-icon.png";
import { EventCubeTicketGroup } from "./EventCubeTicketGroup";
import { Button } from "../NewButton/Button";
import { breakpoints } from "../../util/breakpoints";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { TicketResponse } from "../../../services/types/TicketResponse";
import { SeaticsTicketGroup } from "../Seatics/types";
import { EventCubeCard } from "../Event/EventCubeCard";
import { EventData } from "../../../model/EventPage";
import { DateTime } from "luxon";
import { EventRegistrationModal } from "../EventPage/EventRegistrationModal";
import { EventRegistrationModalSoccerex } from "../EventPage/EventRegistrationModalSoccerex";
import { useTicketListScroll } from "./hooks/useTicketListScroll";
import { useDispatch } from "react-redux";
import { removeSelectedBOTicket } from "../../../store/actions/eventPageActions";

interface EventCubeTicketListProps {
	tickets: Nulleable<TicketResponse>;
	onCheckout: (quantity: number) => void;
	onTicketClick: (ticket: SeaticsTicketGroup) => void;
	event: Nulleable<EventData>;
	hideRelatedEvent?: boolean;
	onCheckoutBoxOffice: (ticketList: { quantity: number; tierId: number | null }[]) => void;
}

const FREE_TICKET_LIMIT = 10;

export const EventCubeTicketList = React.memo((props: EventCubeTicketListProps) => {
	const eventCubeTickets: (EventCubeTicket & Pick<SeaticsTicketGroup, "tgClientData" | "tgID">)[] = useMemo(() => {
		if (props.tickets) {
			return props.tickets
				.filter(ticket => !!ticket.tgQty && ticket.tgQty > 0)
				.map(
					ticket =>
						({
							id: ticket.tgID,
							primaryId: ticket.primaryTicketingGroupId,
							title: ticket.tgUserSec,
							description: ticket.tgNotes,
							price: ticket.tgPrice,
							available: ticket.tgQty,
							source: ticket.tgSource,
							section: ticket.tgUserSec,
							...ticket,
						} as any) // Unfortunately this any is required as some fields from the ticket response are defined as number/string/undefined, when in reallity they are always numners. Check getTicketSegmentsFromRawTickets from Map.tsx to see a similar use
				);
		}
		return [];
	}, [props.tickets]);
	const [ticketQuantities, setTicketQuantities] = useState<number[]>([]);
	const [countIndex, setCountIndex] = useState(0);
	const [showRegistration, setShowRegistration] = useState(false);
	const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
	const dispatch = useDispatch();
	const clearQuantities = () => {
		setTicketQuantities(Array(eventCubeTickets?.length).fill(0));
	};

	const incrementQty = (index: number, ticket: EventCubeTicket) => {
		if (index === countIndex || !ticketQuantities[countIndex] || ticket.source === "boxoffice") {
			const newQuantities = [...ticketQuantities];
			if (newQuantities[index] < ticket.available) {
				newQuantities[index] += 1;
			}
			if (newQuantities[index] === 1 && !!eventCubeTickets) {
				props.onTicketClick({
					...eventCubeTickets[index],
				} as any);
			}
			setTicketQuantities(newQuantities);
			setCountIndex(index);
		}
	};

	const decrementQty = (index: number, ticket: EventCubeTicket) => {
		const newQuantities = [...ticketQuantities];
		if (newQuantities[index] > 0) {
			newQuantities[index] -= 1;
		}
		if (newQuantities[index] === 0 && ticket?.source === "boxoffice") {
			dispatch(removeSelectedBOTicket(ticket.id));
		}
		setTicketQuantities(newQuantities);
	};

	const isIncrementDisabled = (ticket: EventCubeTicket, index: number) => {
		if (ticket?.source === "boxoffice" && ticket.price === 0 && ticketQuantities[index] >= FREE_TICKET_LIMIT) {
			return true;
		}

		return false;
	};

	const tickets = useMemo(
		() =>
			eventCubeTickets &&
			eventCubeTickets.map((ticket, index) => {
				return (
					<EventCubeTicketGroup
						key={index}
						onIncrementQty={() => {
							incrementQty(index, ticket);
						}}
						onDecrementQty={() => decrementQty(index, ticket)}
						ticket={ticket}
						quantity={ticketQuantities[index]}
						incrementDisabled={isIncrementDisabled(ticket, index)}
						disabled={false}
					/>
				);
			}),
		[eventCubeTickets, ticketQuantities, countIndex]
	);

	const calculateOrderTotal = () => {
		const total = eventCubeTickets && ticketQuantities.reduce((partial, qty, index) => (qty !== 0 ? partial + eventCubeTickets[index]?.price * qty : partial), 0);

		return total === 0 ? "FREE" : `$${total}`;
	};

	useEffect(() => {
		if (props.tickets) {
			clearQuantities();
		}
	}, [props.tickets]);

	const handleCheckout = () => {
		if (props.tickets && props.tickets[0].tgSource === "boxoffice") {
			const ticketList: { quantity: number; tierId: number }[] = [];

			ticketQuantities.map((qty, index) => {
				if (props.tickets && props.tickets[index].tgID) {
					qty !== 0 && ticketList.push({ quantity: qty, tierId: Number(props.tickets[index].tgID) });
				}
			});
			props.onCheckoutBoxOffice(ticketList);
		} else {
			props.onCheckout(ticketQuantities[countIndex]);
		}
	};

	const { orderSummaryRef, scrollableContainerRef, ticketsContainerRef } = useTicketListScroll(ticketQuantities, countIndex);
	return (
		<Panel>
			<ListingHeader>
				<TicketsTabIndicator>Tickets</TicketsTabIndicator>
				<ClearQty
					onClick={() => {
						clearQuantities();
					}}
				>
					<ClearIcon src={clearIcon} alt="Clear" />
					<ClearLabel>Clear</ClearLabel>
				</ClearQty>
			</ListingHeader>
			<ScrollableContainer ref={scrollableContainerRef}>
				<TicketList ref={ticketsContainerRef}>{tickets}</TicketList>
				{ticketQuantities.some(ticketQuantity => ticketQuantity > 0) && (
					<OrderSummaryContainer>
						<OrderSummaryTitle>Order Summary</OrderSummaryTitle>
						{ticketQuantities.map((qty, index) => {
							return (
								qty !== 0 && (
									<OrderSummaryItemContainer>
										<OrderSummaryLabel>{eventCubeTickets[index]?.section} qty:</OrderSummaryLabel>
										<OrderSummaryValue>{qty}</OrderSummaryValue>
									</OrderSummaryItemContainer>
								)
							);
						})}
						<OrderSummaryDetails>
							<OrderSummaryItemContainer ref={orderSummaryRef}>
								<OrderSummaryLabel>Total:</OrderSummaryLabel>
								<OrderSummaryValue>{calculateOrderTotal()}</OrderSummaryValue>
							</OrderSummaryItemContainer>
						</OrderSummaryDetails>
						<Button
							onClick={clickEvent => {
								clickEvent.preventDefault();
								clickEvent.stopPropagation();
								if (!props.event?.registrationForm) {
									localStorage.removeItem("registration");
									localStorage.removeItem("registrationSoccerex");
									handleCheckout();
								} else {
									setShowRegistration(true);
								}
							}}
							style={{
								height: isMobile ? "52px" : "70px",
								fontSize: isMobile ? 24 : 28,
								fontWeight: 700,
							}}
						>
							Checkout
						</Button>
					</OrderSummaryContainer>
				)}

				{props.event?.registrationForm && (
					<>
						{props.event.registrationForm.formType == "soccerex" ? (
							<EventRegistrationModalSoccerex
								title={props.event?.registrationForm?.title}
								onCancel={() => setShowRegistration(false)}
								show={showRegistration}
								ticketQuantity={ticketQuantities[countIndex]}
								eventId={props.event?.primaryEventId || null}
								onSubmit={() => {
									setShowRegistration(false);
									handleCheckout()
								}}
							/>
						) : (
							<EventRegistrationModal
								title={props.event?.registrationForm?.title}
								fields={props.event?.registrationForm?.fields}
								onCancel={() => setShowRegistration(false)}
								show={showRegistration}
								ticketQuantity={ticketQuantities[countIndex]}
								onSubmit={() => {
									setShowRegistration(false);
									handleCheckout()
								}}
							/>
						)}
					</>
				)}

				{!props.hideRelatedEvent && (
					<>
						<RelatedEventHeader>Main Event Tickets:</RelatedEventHeader>
						<EventCubeCard date={DateTime.fromJSDate(new Date(props?.event?.mainEventRelated?.occursAt || ""))} event={props.event} />
					</>
				)}
			</ScrollableContainer>
		</Panel>
	);
});

const panelPadding = "14px";
const Panel = styled.div`
	padding-top: ${panelPadding};
	background: ${getAppColor("lightGrey", "light")};
	position: relative;
	width: 100%;
	max-height: calc(100% - ${panelPadding});
	display: flex;
	flex-direction: column;
`;

const ListingHeader = styled.div`
	margin-left: 13px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const ClearQty = styled.div`
	display: flex;
	margin-right: 16px;
	cursor: pointer;
`;

const ClearLabel = styled.div`
	text-transform: uppercase;
	margin-left: 5px;
	${getTypographyStyles("bodyNormal", { fontWeight: 900, color: "darkGrey", colorType: "dark" })};

	@media (max-width: ${breakpoints.mobile}) {
		margin-top: 3px;
		${getTypographyStyles("bodyTiny", { fontWeight: 900, color: "darkGrey", colorType: "dark" })};
	}
`;

const ClearIcon = styled.img`
	margin-bottom: 9px;
	height: 20px;
`;

const TicketsTabIndicator = styled.div`
	${getTypographyStyles("bodyNormal", { fontWeight: 700 })};
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 13px 20px;
	background: ${getAppColor("light", "main")};
	border-radius: 8px 8px 0px 0px;
	text-transform: uppercase;

	@media (max-width: ${breakpoints.mobile}) {
		${getTypographyStyles("bodySmall", { fontWeight: 700 })};
	}
`;

const ScrollableContainer = styled.div`
	overflow-y: auto;
	position: relative;
	max-height: 600px;
`;

const TicketList = styled.div`
	display: flex;
	flex-direction: column;
	overflow-y: auto;
`;

const OrderSummaryContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 18px 17px 25px 21px;
	background: ${getAppColor("lightGrey", "main")};
	position: sticky;
	bottom: 0;
`;

const OrderSummaryTitle = styled.div`
	${getTypographyStyles("heading3", { fontWeight: 700 })};
	font-size: 28px;
	padding-bottom: 10px;

	@media (max-width: ${breakpoints.mobile}) {
		font-size: 21px;
	}
`;

const OrderSummaryDetails = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 15px;
`;

const OrderSummaryItemContainer = styled.div`
	display: flex;
`;

const OrderSummaryLabel = styled.div`
	${getTypographyStyles("bodyMedium", { fontWeight: 600 })};

	@media (max-width: ${breakpoints.mobile}) {
		${getTypographyStyles("bodySmall", { fontWeight: 600 })};
	}
`;

const OrderSummaryValue = styled(OrderSummaryLabel)`
	color: ${getAppColor("darkGrey", "dark")};
	margin-left: 5px;
	margin-bottom: 12px;
`;

const verticalPadding = "8px";
const RelatedEventHeader = styled.div`
	display: flex;
	align-items: center;
	background: ${getAppColor("darkGrey", "dark")};
	${getTypographyStyles("heading3", { fontWeight: 700, color: "light", colorType: "main" })};
	font-size: 28px;
	padding: ${verticalPadding} 25px ${verticalPadding};
	height: calc(48px - ${verticalPadding});

	@media (max-width: ${breakpoints.mobile}) {
		justify-content: center;
		font-size: 18px;
		padding-left: none;
		height: calc(35px - ${verticalPadding});
	}
`;
