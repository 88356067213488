import React from "react";
// The next line is required because freestar library doesn't provide types for it to work with typescript
//@ts-ignore
import FreestarAdSlot from "@freestar/pubfig-adslot-react-component";

/*
	List of posible placements (provided by FreeStar):

	"sitickets.com_tickets_1"
	"sitickets.com_tickets_2"
	"sitickets.com_tickets_3" 
	"sitickets.com_tickets_4" 
	"sitickets.com_tickets_5" 
	"sitickets.com_tickets_6" 


	"sitickets.com_concerts_rightrail1" 
	"sitickets.com_concerts_rightrail2" 


	"sitickets.com_sports_rightrail1",
	"sitickets.com_sports_rightrail2"

	Based on this: 

		1) 1 <= props.AdNumber <= 6 in case the placement follows the structure sitickets.com_tickets
		
		2) 1 <= props.AdNumber <= 2 in case the placement follows the structure sitickets.com_tickets sitickets.com_concerts_rightrail or sitickets.com_sports_rightrail1

*/

export const FreeStarAd = (props: { placement: string; slotId?: string }) => {
	return <FreestarAdSlot publisher="sitickets-com" placementName={props.placement} slotId={props.slotId} />;
};
