import React from "react";
import styled from "styled-components/macro";
import { getAppColor } from "../../util/appColors";
import { FontFamily } from "../Typography/Typography";
import { EventContent, ColumnBody, PostponedSection, EventCardSideColumn, EventCardTitle, EventCardSubTitle } from "./styles";
import { getTypographyStyles } from "../Typography/Typography";
import { DateTime } from "luxon";
import { Nulleable, Optional } from "../../../model/Utils";
import { breakpoints } from "../../util/breakpoints";
import { EventData } from "../../../model/EventPage";
import { InternalRoutes } from "../../Link";

export const EventCubeCard = (props: { date?: Optional<DateTime>; children?: JSX.Element | ((date: Optional<DateTime>) => JSX.Element); event: Nulleable<EventData> }) => {
	const getDisplayedYear = (year: number) => {
		const THRESHOLD = 5;
		const yearDisplayThreshold = new Date().getFullYear() + THRESHOLD;
		return year < yearDisplayThreshold ? year : "TBD";
	};

	const replaceOutOfFrameTime = (date: string) => {
		date = date.startsWith("3:30") ? "TBD" : date;
		date = date.startsWith("0:00") ? "TBD" : date;
		return date;
	};

	return (
		<a href={InternalRoutes.Event(props.event?.mainEventRelated?.eventSlug || "")} target="_self">
			<EventCardContainer>
				<EventContent>
					{props.date && getDisplayedYear(props.date.year) !== "TBD" ? (
						<EventCardSideColumn>
							<p>{props.date.weekdayShort}</p>
							<p>
								{props.date.monthShort.toUpperCase()} {props.date.day}
							</p>
							<p>{getDisplayedYear(props.date.year)}</p>
							<p>{replaceOutOfFrameTime(props.date.toLocaleString(DateTime.TIME_SIMPLE))}</p>
						</EventCardSideColumn>
					) : (
						<PostponedSection>
							<div>Postponed</div>
							<div>Date</div>
							<div>TBD</div>
						</PostponedSection>
					)}
					<ColumnBody>
						<StyledEventCardTitle>{props?.event?.mainEventRelated?.eventName}</StyledEventCardTitle>
						<StyledEventCardSubTitle>
							{props.event?.mainEventRelated?.venueName},&nbsp;
							{props.event?.mainEventRelated?.venueCity},&nbsp;
							{props.event?.mainEventRelated?.venueState}
						</StyledEventCardSubTitle>
					</ColumnBody>
				</EventContent>
			</EventCardContainer>
		</a>
	);
};

const EventCardContainer = styled.div`
	background-color: ${getAppColor("light")};
	border-bottom: 1px solid ${getAppColor("lightGrey")};
	font-family: ${FontFamily.Poppins};
	cursor: pointer;
	position: relative;
	&:hover {
		background-color: ${getAppColor("lightGrey")};
		& button {
			background-color: ${getAppColor("dark")};
			&:not(:disabled):hover {
				background-color: ${getAppColor("dark")};
			}
		}
	}
	& button {
		background-color: ${getAppColor("darkGrey", "dark")};
	}
	a {
		color: ${getAppColor("dark")};
	}
`;

const StyledEventCardTitle = styled(EventCardTitle)`
	${getTypographyStyles("bodyNormal", { fontWeight: 700 })};
`;

const StyledEventCardSubTitle = styled(EventCardSubTitle)`
	${getTypographyStyles("bodySmall", { fontWeight: 300 })};

	@media (max-width: ${breakpoints.mobile}) {
		${getTypographyStyles("bodyTiny", { fontWeight: 300 })};
	}
`;
