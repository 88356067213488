import * as t from "io-ts";

export const BetsDecoder = t.array(
	t.strict({
		id: t.number,
		slug: t.string,
		image: t.string,
	})
);

export type BetsResponse = t.OutputOf<typeof BetsDecoder>;
