import React, { FC, useEffect, useMemo, useState } from "react";
import { Dialog } from "../Dialog/Dialog";
import styled from "styled-components";
import { Input, InputError, InputErrorStyles } from "../Input/Input";
import { FontFamily, Typography } from "../Typography/Typography";
import { SelectItem } from "../Select/Select";
import { breakpoints } from "../../util/breakpoints";
import { appColors } from "../../util/appColors";
import Select from "react-select";
import close_button_black from "../../resource/image/dialog-close-black.svg";

interface EventRegistrationProps {
	title: string;
	show: boolean;
	ticketQuantity: number;
	eventId: number|null;
	onSubmit: () => void;
	onCancel: () => void;
}

export const EventRegistrationModalSoccerex: FC<EventRegistrationProps> = ({ title, onSubmit, show, ticketQuantity, onCancel }) => {
	const [formValues, setFormValues] = useState<any[]>([]);
	const [errors, setErrors] = useState<Array<{ [key: string]: string }>>([]);
	const [touched, setTouched] = useState<Array<{ [key: string]: boolean }>>([]);
	const [valid, setValid] = useState(false);


	const fieldsSoccerex = [
		{
			"id": "salutation",
			"label": "Salutation",
			"options": "{\"values\":[\"Mr\",\"Miss\", \"Mrs\", \"Other\"], \"required\": true}",
			"fieldType": "dropdown"
		},
		{
			"id": "first_name",
			"label": "First Name",
			"options": "{\"required\": true}",
			"fieldType": "input"
		},
		{
			"id": "last_name",
			"label": "Last Name",
			"options": "{\"required\": true}",
			"fieldType": "input"
		},
		{
			"id": "organization",
			"label": "Organization",
			"options": "{\"required\": true}",
			"fieldType": "input"
		},
		{
			"id": "job_title",
			"label": "Job title ",
			"options": "{\"required\": true}",
			"fieldType": "input"
		},
		{
			"id": "email_address",
			"label": "Email Address",
			"options": "{\"required\": true}",
			"fieldType": "input"
		},
		{
			"id": "phone_number",
			"label": "Phone Number",
			"field_type": "input",
			"options": null,
		},
		{
			"id": "areas_of_interest",
			"label": "What are your areas of interest?",
			"field_type": "input",
			"options": null,
		},
		{
			"id": "reason_for_attending",
			"label": "What is your reason for attending this event?",
			"options": "{\"required\": true}",
			"fieldType": "input"
		}
	]
	useEffect(() => {
		setValid(!errors.length);
	}, [errors]);

	useEffect(() => {
		validateForm();
	}, [ticketQuantity, formValues]);

	const validateForm = () => {
		let errors: Array<{ [key: string]: string }> = [];
		for (let index = 0; index < ticketQuantity; index++) {
			fieldsSoccerex.forEach(field => {
				const optionsData = JSON.parse(field.options || "{}");
				if (optionsData.required && (!formValues[index] || !formValues[index][field.id.toString()])) {
					if (!errors[index]) {
						errors[index] = {};
					}
					//@ts-ignore
					errors[index][field.id.toString()] = "This field is required";
				}
			});
		}
		setErrors(errors);
	};

	const updateState = (event: React.ChangeEvent<HTMLInputElement>, index: number, field: string) => {
		if (event) {
			const newData = [...formValues];
			if (!newData[index]) {
				newData[index] = {};
			}
			newData[index][field] = event.target.value;
			setFormValues(newData);
		}
	};

	const updateDropdownState = (field: string, value: string | number | null, index: number) => {

		const newData = {
			...formValues[index],
		};
		//@ts-ignore
		newData[field] = value;

		const newFormValues = [...formValues];
		newFormValues[index] = newData;
		setFormValues(newFormValues);
	};

	const getOptions = (options: string) => {
		const optionsData = JSON.parse(options || "{}");
		return optionsData.values ? optionsData.values.map((v: string) => ({ value: v, label: v })) : [];
	};

	const getDropdownValue = (index: number, key: string) => {
		if (!!formValues[index] && !!formValues[index][key]) {
			const val = formValues[index][key]
			return {label: val, value: val};
		}
		return null;
	};

	const saveFormFields = () => {
		if (valid) {
			localStorage.setItem("registration", JSON.stringify(formValues));
			onSubmit();
		}
	};

	const updateTouched = (index: number, field: string) => {
		const newTouched = [...touched];
		if (!newTouched[index]) {
			newTouched[index] = {};
		}
		newTouched[index][field] = true;
		setTouched(newTouched);
	};

	useEffect(() => {
		setFormValues(new Array(ticketQuantity).fill(null).map(()=>({})));
	}, [ticketQuantity, show]);

	return (
		<Dialog
			open={show}
			type="utility"
			onClose={onCancel}
			fullscreenOnMobile
			headerChildren={<FormTitle>{title}<CloseButton className="close-button" src={close_button_black} onClick={onCancel} /></FormTitle>}
			disableCloseControls={true}
			css={`
				border-radius: 15px;
			`}
		>
			<MultiForm>
				{Array.from({ length: ticketQuantity }, (_, index) => (
					<FormContainer key={index} visible>
						<Typography type="bodyMedium" style={{marginBottom: 10}}>Attendee {index + 1}</Typography>

						{fieldsSoccerex.map((field, fieldIndex) => {
							const errorDisplay = !!errors[index] && !!errors[index][field.id.toString()] && !!touched[index] && !!touched[index][field.id.toString()];
							const errorDisplayInput = !!errors[index] && !!errors[index][field.id.toString()];
							return (
								<>
									{field.fieldType === "dropdown" ? (
										<Row key={fieldIndex}>
											<Label>{field.label}</Label>
											<SelectContainer className={!!errors[index] && !!errors[index][field.id.toString()] && !!touched[index] && !!touched[index][field.id.toString()] ? "error" : ""}>
												<StyledSelect
													classNamePrefix="select"
													options={getOptions(field.options)}
													onChange={(item: SelectItem) => {
														updateDropdownState(field.id.toString(), item?.value, index);
													}}
													onBlur={() => {
														validateForm();
														updateTouched(index, field.id.toString());
													}}
													value={getDropdownValue(index, field.id.toString())}
													placeholder={field.label}
													isMulti={false}
													isSearchable
													blurInputOnSelect={false}
													captureMenuScroll
													isClearable
													closeMenuOnSelect
													menuPlacement="bottom"
													maxMenuHeight={150}
												/>
												<InputError visible={errorDisplay}>{errorDisplay ? errors[index][field.id.toString()] : ""}</InputError>
											</SelectContainer>
										</Row>
									) : (
										<Row key={fieldIndex}>
											<Label>{field.label}</Label>
											<InputContainer>
												<Input id={index + "_" + field.id.toString()} containerStyle={{ marginBottom: "24px" }} style={{ fontWeight: 400 }} onChange={e => updateState(e, index, field.id.toString())} placeholder={field.label} onBlur={validateForm} error={errorDisplayInput ? errors[index][field.id.toString()] : ""} />
											</InputContainer>
										</Row>
									)}
								</>
							);
						})}
					</FormContainer>
				))}
				<SubmitButtonContainer>
					<SubmitButton onClick={saveFormFields} disabled={!valid}>
						SUBMIT
					</SubmitButton>
				</SubmitButtonContainer>
			</MultiForm>
		</Dialog>
	);
};

const MultiForm = styled.div`
	display: block;
	padding: 0px 20px 20px 20px;
	min-width: 500px;
	@media (max-width: ${breakpoints.mobile}) {
		min-width: 100%;
		padding: 0;
		padding-top: 15px;
	}
`;

const FormContainer = styled.div<{ visible: boolean }>`
	${props => !props.visible && `display: none;`}
`;

const FormTitle = styled.h1`
	font-size: 16px;
	font-weight: 600;
	padding: 0 18px;
	margin: 15px 0 5px;
	font-family: ${FontFamily.Poppins};
	@media (max-width: ${breakpoints.mobile}) {
		padding: 0;
	}
`;

const Row = styled.div`
	display: block;
	font-family: ${FontFamily.Poppins};
`;

const Label = styled.div`
	display: block;
	padding: 0px;
	font-size: 12px;
	font-weight: 400;
	margin-bottom: 8px;
`;

const SelectContainer = styled.div`
	position: relative;
	width: 100%;
	height: 40px;
	margin-bottom: 24px;
	&.error {
		.select__control {
			${InputErrorStyles}
		}
	}
`;

const InputContainer = styled.div`
	position: relative;
`;

const SubmitButtonContainer = styled.div`
	display: flex;
	justify-content: center;
`;

const SubmitButton = styled.button`
	position: relative;
	width: auto;
	padding: 14px 50px;
	background: rgb(233, 34, 36);
	margin-top: 20px;
	color: #ffffff;
	border: 0;
	border-radius: 5px;
	cursor: pointer;
	text-align: center;
	min-width: 200px;
	font-size: 18px;
	&:hover {
		background: rgb(255, 55, 60);
	}
	&:disabled {
		color: rgba(0, 0, 0, 0.65);
		background: rgba(167, 167, 167, 0.64);
		cursor: not-allowed;
	}
`;

const StyledSelect = styled(Select)`
	.select__placeholder {
		font-family: "Avenir", sans-serif;
		font-size: 16px;
	}
	.select__input {
		height: 27px;
	}
	.select__value-container {
		width: calc(100% - 100px);
		padding-left: 6px;
		border-radius: 0px;
	}
	.select__control {
		border-radius: 5px;
		box-shadow: none;
		border: 1px solid #ddd;
		cursor: pointer;
		&:hover,
		&:active {
			border-color: #ddd;
		}
	}
	.select__single-value {
		font-family: "Avenir", sans-serif;
		font-size: 16px;
		font-weight: 400;
		height: 18px;
		top: 52%;
	}
	.select__menu {
		border: solid 1px ${appColors.lightGrey.dark};
		border-radius: 0px;
		margin-top: 0px;
		background: ${appColors.lightGrey.subtle};
		box-shadow: none;
	}
	.select__option {
		font-family: "Avenir", sans-serif;
		font-size: 16px;
		margin-bottom: 2px;
		cursor: pointer;
		color: ${appColors.dark.light};
		background: ${appColors.lightGrey.subtle};
		&--is-selected {
			color: ${appColors.light.main};
			background: ${appColors.dark.main};
		}
		&:active {
			background: ${appColors.dark.main};
			color: ${appColors.light.main};
		}
		@media (hover: hover) {
			&:hover {
				background: ${appColors.dark.main};
				color: ${appColors.light.main};
			}
		}
	}
`;
const CloseButton = styled.img`
	width: "14px";
	height: "14px";
	position: absolute;
	right: 0;
	cursor: pointer;
`;