import styled from "styled-components/macro";
import { getAppColor } from "../../util/appColors";

export const primaryBorderHeight = "4px";

export const primaryBorderColor = getAppColor("primary");

export const primaryBorderStyle = "solid";

export const primaryBorderValue = `${primaryBorderHeight} ${primaryBorderStyle} ${primaryBorderColor}`;

export const PrimaryBorder = styled.hr`
	background: ${primaryBorderColor};
	height: ${primaryBorderHeight};
`;
