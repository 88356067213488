import { SeaticsEvent, SeaticsMapConfig } from "../../model/Seatics";
import { EventVenueInfoCodec, EventVenueInfoResponse } from "../types/EventVenueInfoResponse";
import { JsonpServiceCall } from "../../presentation/hooks/useJsonp";
import { Config } from "../../Config";

export const getSeaticsEventAndVenue = (params: SeaticsEvent & SeaticsMapConfig): JsonpServiceCall<EventVenueInfoResponse> => ({
	param: {
		venue: params.venue,
		eventId: params.tnEventId ? params.tnEventId : null,
		eventName: params.eventName,
		dateTime: params.dateTime,
		websiteConfigId: params.websiteConfigId,
		consumerKey: params.consumerKey,
	},
	endpoint: `${Config.getSeaticsAPI()}/maps/v3/EventAndVenueInfo`,
	responseDecoder: EventVenueInfoCodec,
});
