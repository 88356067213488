import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";
import { GooglePlacesAutocomplete } from "../GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import { Input, InputError, InputErrorStyles } from "../Input/Input";
import { Address } from "../../../model/User";
import { countries } from "../../util/geographicLocations";
import Select from "react-select";
import { appColors } from "../../util/appColors";
import { SelectItem } from "../Filters/SelectFilter";
import { useAutoUpdateState } from "../../hooks/useAutoUpdateState";
import { validPOBoxRegex } from "../../util/optimized/regexes";

interface Errors {
	[key: string]: string | undefined;
}

const isPOBox = (str?: string | null) => {
	return str ? validPOBoxRegex.test(str) : false;
};

export interface AddressFormState {
	valid: boolean;
	data: Address;
	errors: Errors;
}

const handleNull = (input?: string | number | readonly string[] | null) => {
	const value = input || input === "" ? input : undefined;
	return value;
};

export const AddressForm = (props: { onChange?: (state: AddressFormState) => void }) => {
	const errorMap: Errors = {};

	const [errors, setErrors] = useState<Errors>({});

	const [data, setData] = useState<Address>({
		address1: "",
		address2: "",
		city: "",
		zip: "",
		state: "",
		country: "",
	});

	const setError = (key: string, error: string, condition = true) => {
		const errorAlreadySet = errorMap[key] === error;
		if (condition) {
			if (!errorAlreadySet) {
				errorMap[key] = error;
			}
		} else if (errorAlreadySet) {
			errorMap[key] = undefined;
		}
	};

	const updateState = (event?: React.ChangeEvent<HTMLInputElement>) => {
		const newData = {
			...data,
		};
		if (event) {
			//@ts-ignore
			newData[event.target.id] = event.target.value;
		}
		setData(newData);
	};

	const updateCountry = (value?: string | null) => {
		if (value) {
			setCountryValue({
				label: value,
				value: value,
			});
		} else {
			setCountryValue(null);
		}
		const newData = {
			...data,
		};
		newData["country"] = value ? value : "";
		setData(newData);
	};

	useEffect(() => {
		setError("address1", "This value is required.", !data.address1);
		setError("city", "This value is required.", !data.city);
		setError("zip", "This value is required.", !data.zip);
		setError("state", "This value is required.", !data.state);
		setError("country", "This value is required.", !data.country);
		setError("address1", "Tickets cannot be delivered to a P.O. Box", isPOBox(data.address1));
		setError("address2", "Tickets cannot be delivered to a P.O. Box", isPOBox(data.address2));
		setErrors(errorMap);
		emitOnChange();
	}, [data]);

	const valid = useMemo(() => {
		const newValid = !Object.values(errors).length;
		return newValid;
	}, [errors]);

	useEffect(() => {
		emitOnChange();
	}, [valid]);

	const emitOnChange = () => {
		if (props.onChange) {
			props.onChange({
				data: data,
				errors: errors,
				valid: valid,
			});
		}
	};

	const [countryTouched, setCountryTouched] = useState(false);
	const [countryValue, setCountryValue] = useState<SelectItem | null>();

	const countryErrorVisible = useAutoUpdateState(() => {
		const value = !!errors.country && countryTouched;
		return value;
	}, [errors.country, countryTouched]);

	return (
		<>
			<GooglePlacesAutocomplete
				onChange={updateState}
				value={data.address1}
				error={errors.address1}
				id="address1"
				options={{
					types: ["address"],
				}}
				fields={["address_components"]}
				placeholder="Address 1"
				onPlaceChanged={autocomplete => {
					const place = autocomplete.getPlace();
					const newData = {
						...data,
					};
					place.address_components?.forEach(component => {
						const type = component.types[0];
						switch (type) {
							case "street_number":
								newData.address1 = component.long_name;
								break;
							case "route":
								if (newData.address1) {
									newData.address1 += " ";
								} else {
									newData.address1 = "";
								}
								newData.address1 += component.long_name;
								break;
							case "locality":
								newData.city = component.long_name;
								break;
							case "administrative_area_level_1":
								newData.state = component.long_name;
								break;
							case "country":
								newData.country = component.long_name;
								setCountryValue({ label: newData.country, value: newData.country });
								break;
							case "postal_code":
								newData.zip = component.long_name;
								break;
						}
					});
					setData(newData);
				}}
			/>
			<Input id="address2" placeholder="Address 2" onChange={updateState} value={handleNull(data.address2)} error={errors.address2} />
			<Row>
				<Input id="city" placeholder="City" onChange={updateState} value={handleNull(data.city)} error={errors.city} />
				<Input id="zip" placeholder="ZIP/Postal Code" onChange={updateState} value={handleNull(data.zip)} error={errors.zip} />
			</Row>
			<Row>
				<Input id="state" placeholder="State/Province" onChange={updateState} value={handleNull(data.state)} error={errors.state} />

				<SelectContainer className={countryErrorVisible ? "error" : ""}>
					<StyledSelect
						classNamePrefix="select"
						options={countries}
						onChange={(item: SelectItem) => {
							setCountryTouched(true);
							updateCountry(item?.value);
						}}
						onBlur={(item: SelectItem) => {
							setCountryTouched(true);
						}}
						value={countryValue}
						placeholder="Country"
						isMulti={false}
						isSearchable
						blurInputOnSelect={false}
						captureMenuScroll
						isClearable
						closeMenuOnSelect
						menuPlacement="top"
						maxMenuHeight={150}
					/>

					<InputError visible={countryErrorVisible}>{errors.country}</InputError>
				</SelectContainer>
			</Row>
		</>
	);
};

export const StyledSelect = styled(Select)`
	.select__placeholder {
		font-family: "Avenir", sans-serif;
		font-size: 16px;
	}
	.select__input {
		height: 27px;
	}
	.select__value-container {
		width: calc(100% - 100px);
		padding-left: 6px;
		border-radius: 0px;
	}
	.select__control {
		border-radius: 11px;
		box-shadow: none;
		border: 1px solid #ddd;
		cursor: pointer;
		&:hover,
		&:active {
			border-color: #ddd;
		}
	}
	.select__single-value {
		font-family: "Avenir", sans-serif;
		font-size: 16px;
		font-weight: 500;
		height: 18px;
		top: 52%;
	}
	.select__menu {
		border: solid 1px ${appColors.lightGrey.dark};
		border-radius: 0px;
		margin-top: 0px;
		background: ${appColors.lightGrey.subtle};
		box-shadow: none;
	}
	.select__option {
		font-family: "Avenir", sans-serif;
		font-size: 16px;
		margin-bottom: 2px;
		cursor: pointer;
		color: ${appColors.dark.light};
		background: ${appColors.lightGrey.subtle};
		&--is-selected {
			color: ${appColors.light.main};
			background: ${appColors.dark.main};
		}
		&:active {
			background: ${appColors.dark.main};
			color: ${appColors.light.main};
		}
		@media (hover: hover) {
			&:hover {
				background: ${appColors.dark.main};
				color: ${appColors.light.main};
			}
		}
	}
`;

const Row = styled.div`
	display: flex;
	& > div {
		&:not(:last-child) {
			margin-right: 10px;
		}
	}
`;

const SelectContainer = styled.div`
	position: relative;
	width: 204px;
	height: 40px;
	&.error {
		.select__control {
			${InputErrorStyles}
		}
	}
`;
