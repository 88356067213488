import React, { useState, useRef, useEffect, useMemo } from "react";
import styled from "styled-components/macro";
import { TicketGroup } from "../../../model/optimizedModel/ticket";
import { getAppColor } from "../../util/appColors";
import { CollapseArrow } from "../CollapseArrow/CollapseArrow";
import { TicketInvoiceInput } from "../Invoices/TicketInvoice";
import { Discount, TicketEvent } from "../../../model/Cart";
import { Ticket } from "../../../model/Cart";
import { Typography } from "../Typography/Typography";
import { formatPrice } from "../../util/formatPrice";
import { CheckoutState } from "../../../store/reducers/checkoutReducer";
import { TicketInvoice } from "../Invoices/TicketInvoice";
import { InvoiceComponentItem } from "../Invoices/Invoice";
import { FutureInvoice } from "../Invoices/FutureInvoice";
import { useCheckout } from "../../../services/hooks/useCheckout";

export const CollapsibleInvoice = (props: Omit<TicketGroup, "deliveryOptions" | "notes" | "id"> & TicketInvoiceInput & Omit<TicketEvent, "id" | "category_id" | "category"> & {
	venue: {
		name: string,
		city: string,
		country: string,
		state: string,
	},
} & Pick<CheckoutState, "walletFundsUsed"> & { total?: number; invoiceType: "reservation" | "ticket"; totalSeparator?: boolean; hidePriceDetails?: boolean; }) => {
	const [expanded, setExpanded] = useState(false);
	const [maxHeight, setMaxHeight] = useState(0);
	const [minHeight, setMinHeight] = useState(0);

	const feesRowRef = useRef<HTMLDivElement>(null);
	const venueRowRef = useRef<HTMLDivElement>(null);
	const eventRowRef = useRef<HTMLDivElement>(null);

	const { discount } = useCheckout();
	const eventId = props.eventId
	useEffect(() => {
		if (feesRowRef.current && venueRowRef.current && eventRowRef.current) {
			setMaxHeight(feesRowRef.current.clientHeight + venueRowRef.current.clientHeight + eventRowRef.current.clientHeight);
			setMinHeight(eventRowRef.current.clientHeight);
		}
	}, [feesRowRef.current?.clientHeight, venueRowRef.current, eventRowRef.current, props.walletFundsUsed, props.total, props.tax, props.transactionFeeRule, props.deliveryFee, props.price]);

	const totalPriceToDisplay = useMemo(() => {
		if (props.total && discount?.newTotal) {
			//const discountApplied = discount?.newTotal ? props.total + props.walletFundsUsed - discount.newTotal : 0;
			return discount.newTotal - props.walletFundsUsed;
		}

		return props.total;
	}, [props.total, discount, props.walletFundsUsed]);

	const additionalItems = useMemo(() => {
		const additionalItems: InvoiceComponentItem[] = [];
		if (props.walletFundsUsed > 0) {
			additionalItems.push({
				key: "promotionalWalletFunds",
				value: formatPrice(-props.walletFundsUsed),
				title: "Wallet Credit Applied",
				borderTop: true,
				borderBottom: !props.totalSeparator && (!discount || discount?.type === "PROMO_FEE"),
			});
		}

		if (!!discount && discount.type === "FROM_TOTAL") {
			additionalItems.push({
				key: "discountCode",
				value: formatPrice(-discount.amount),
				title: (
					<Typography type="bodySmall">
						Discount code <strong>{discount.code}</strong>
					</Typography>
				),
				borderTop: !props.walletFundsUsed,
				borderBottom: !props.totalSeparator,
			});
		}
		if (!props.hidePriceDetails && totalPriceToDisplay !== undefined) {
			additionalItems.push({
				key: "total",
				value: <TotalItem discount={discount} totalPriceToDisplay={totalPriceToDisplay} walletFundsUsed={props.walletFundsUsed} />,
				title: "Total",
				borderTop: props.totalSeparator,
				css: {
					fontWeight: 600,
					fontSize: "16px",
					paddingBottom: "5px",
				},
			});
		}
		return additionalItems;
	}, [props.total, props.walletFundsUsed, discount]);

	const tierContent = () => {
		return props.tiersInfo?.map(tier => (
			<div>
				QTY: {tier.quantity} - {`Section ${tier.section} , Row ${tier.row}`}
			</div>
		));
	};

	return (
		<Container expanded={expanded} maxHeight={maxHeight} minHeight={minHeight}>
			<RowContainer expanded={expanded}>
				<Row ref={eventRowRef}>
					<StyledTypography type="bodyNormal" fontWeight={500}>
						{props.name}
					</StyledTypography>
					{props.occursAt && (
						<StyledTypography type="bodySmall" fontWeight={300}>
							{props.occursAt}
						</StyledTypography>
					)}
					{(!props.hidePriceDetails && totalPriceToDisplay !== undefined) &&
						!props.totalSeparator && ( // We still want to display $0.00 when props.total is undefined so i check specifically for this
							<TotalItem discount={discount} totalPriceToDisplay={totalPriceToDisplay} walletFundsUsed={props.walletFundsUsed} />
						)}
				</Row>
				<Row 
					ref={venueRowRef}
				>
					<StyledTypography type="bodySmall" fontWeight={300}>
						{props.invoiceType === "ticket" ? tierContent() : props.section}
					</StyledTypography>
					<StyledTypography type="bodySmall" fontWeight={300}>
						{`${props.venue.name}, ${props.venue.city}, ${props.venue.state}, ${props.venue.country}`}
					</StyledTypography>
				</Row>

				<Row 
					ref={feesRowRef}
					style={props.hidePriceDetails ? {
						padding: 0,
					} : undefined}
				>
					{
						!props.hidePriceDetails && (
							<div
								css={`
									width: 100%;
								`}
							>
								{props.invoiceType === "ticket" ? <TicketInvoice eventId={eventId} price={props.price} quantity={props.quantity} transactionFeeRule={props.transactionFeeRule} deliveryFee={props.deliveryFee} tax={props.tax} additionalItems={additionalItems} serviceFee={props.serviceFee}/> : <FutureInvoice reservationPrice={props.price} quantity={props.quantity} additionalItems={additionalItems} />}
							</div>
						)
					}
				</Row>
			</RowContainer>
			<StyledCollapseArrow size={8} weight={2} color={getAppColor("darkGrey")} expanded={expanded} onClick={() => setExpanded(!expanded)} />
		</Container>
	);
};

const TotalItem = (props: { discount?: Discount; totalPriceToDisplay: number; walletFundsUsed: number }) => {
	return (
		<TotalContainer>
			<StyledTypography type="bodyNormal" fontWeight={600}>
				{formatPrice(props.totalPriceToDisplay)}
			</StyledTypography>
		</TotalContainer>
	);
};

const Container = styled.div<{ expanded: boolean; minHeight: number; maxHeight: number }>`
	display: flex;
	justify-content: space-between;
	background-color: ${getAppColor("light")};
	border: 1px solid ${getAppColor("lightGrey", "dark")};
	height: ${props => (props.expanded ? `${props.maxHeight}px` : `${props.minHeight}px`)};
	transition: height 0.3s ease-out;
	overflow: hidden;
`;

const StyledCollapseArrow = styled(CollapseArrow)`
	width: 20px;
	height: 20px;
	position: absolute;
	right: 10px;
	margin-top: 10px;
`;

const RowContainer = styled.div<{ expanded: boolean }>`
	display: flex;
	flex-direction: column;
	width: 100%;

	${props =>
		props.expanded &&
		`	& > *:not(:last-child) {
		border-bottom: 1px solid ${getAppColor("lightGrey", "dark")};
	}`}
`;

const Row = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 8px 15px;
`;

const StyledTypography = styled(Typography)`
	margin-bottom: 5px;
	text-align: left;
`;

const TotalContainer = styled.div`
	display: flex;
`;
