import React from "react";
import { FuturesSale } from "../../../../model/optimizedModel/myAccount";
import { toBrowserLocaleString } from "../../../util/optimized/dates";
import { MyAccountMarketFuture } from "./MyAccountMarketFuture";

export const MyAccountFutureSale = (props: FuturesSale) => {
	return (
		<MyAccountMarketFuture<FuturesSale>
			future={{
				...props,
				//Note: Setting order as undefined will not be needed once backend object schema changes are implemented.
				order: undefined,
			}}
			//TODO: Consider making the date content in this component and in MyAccountFutureListing more DRY.
			additionalTitleAndContentGridItems={[
				{
					key: "dateSold",
					title: "Date Sold",
					content: toBrowserLocaleString(
						new Date(props.dateSold),
						{
							//@ts-ignore TODO: TS considers this to be an invalid property when it's not, consider looking into a solution
							dateStyle: "short",
						},
						"date"
					),
				},
			]}
			titleAndContentGridConfigOverride={{
				override: {
					reservationPrice: {
						title: "Price Paid",
						subtitle: "",
					},
					listingPrice: {
						title: "Price Sold",
					},
				},
				omit: ["ticketPrice"],
			}}
		/>
	);
};
