import styled from "styled-components/macro";

export const ModalButton = styled.button`
	display: flex;
	font-size: 18px !important;
	border-radius: 4px !important;
	font-weight: 700 !important;
	width: 300px;
	height: 50px;
	text-decoration: none;
	font-family: "Montserrat", sans-serif;
	background-color: #12284b;
	border-color: #12284b;
	color: #fff;
	padding: 15px 35px;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	cursor: pointer;
`;

export const ModalButtonContainter = styled.div`
	display: flex;
	justify-content: center;
`;
