import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { CommonPage, Events } from "../components";
import { useDataService } from "../hooks/useServiceCall";
import { getVenueEvents } from "../../services/actions/eventsService";
import { getVenueService } from "../../services/actions/venueService";
import { isRight } from "fp-ts/lib/These";
import { useObjectMergeReducer, useServiceMonitor } from "../hooks";
import { Event } from "../../model/Event";
import { useDispatch } from "react-redux";
import { GlobalAssetCarousel } from "../components/HomeCarousel/GlobalAssetCarousel";
import { NormalEventItem, SportEventItem } from "../components/Event/Event";
import heroImage from "../resource/image/venue-hero-banner.png";
import { MetaDecorator, getVenueTagTitle, getVenueTagDescription } from "../components/SEOMetadecorator/MetaDecorator";
import { getVenueBioService } from "../../services/actions/venueService";
import toast from "react-hot-toast";
import { useToastRemoval } from "../hooks/useToastRemoval";
import { PageBio } from "../components/PageBio/PageBio";
import { FullScreenLoader } from "../components/Loader";
import { Config } from "../../Config";

const EVENTS_PAGE_SIZE = 20;

interface VenueRouteParams {
	venue: string;
}

interface VenueState {
	page: number;
	total: number;
	events: Event[];
}

export const VenueRoute = (props: RouteComponentProps<VenueRouteParams>) => {
	const venue = props.match.params.venue;
	const dispatch = useDispatch();
	useToastRemoval();

	const loading = useServiceMonitor(["getVenue", "getGlobalAssets"]);
	const loadingEvents = useServiceMonitor(["getVenueEvents"]);
	const [bio, setBio] = useState("");

	useEffect(() => {
		if (bio) {
			setBio("");
		}
		getVenueBioService(venue)
			.then(result => {
				setBio(result.data.bio);
			})
			.catch(error => {
				if (typeof error === "string") {
					toast.error(error);
				}
			});
	}, [venue]);

	useToastRemoval();

	const { result: venueData } = useDataService(getVenueService, [venue], {});

	const [state, setState] = useObjectMergeReducer<VenueState>({
		page: 1,
		total: 0,
		events: [],
	});

	//reset events
	useEffect(() => {
		setState({ events: [], total: 0 });
		loadEvents(true);
	}, [venue]);

	//update events
	const loadEvents = async (reset?: boolean) => {
		const page = reset ? 1 : ++state.page;
		const result = await dispatch(getVenueEvents(venue, page, EVENTS_PAGE_SIZE));

		const events = isRight(result) ? result.right.data : [];
		const total = isRight(result) ? result.right.metadata.totalRecords : 0;

		setState({ page, total, events: reset ? events : [...state.events, ...events] });
	};

	const venueName = venueData?.name || "";

	let adAssets = {
		/**TODO: connect service when ready */
	} as any;

	//We set a hardcoded image for certain events
	const [venueImage, setVenueImage] = useState(heroImage);
	useEffect(() => {
		const specialVenues = ["dwight-davis-tennis-center", "tennis-and-pickleball-club-at-newport-beach", "club-pickleball-usa"];

		switch (true) {
			case venue && specialVenues.includes(venue):
				setVenueImage(Config.getCDNUrl(`/${Config.getCDNFolder()}/images/venues/pickleball.png`));
				break;
			case venue === "harold-patterson-park":
				setVenueImage(Config.getCDNUrl(`/${Config.getCDNFolder()}/images/venues/harold-patterson-park.png`));
				break;
			default:
				setVenueImage(heroImage);
				break;
		}
	}, [venue]);

	return (
		<CommonPage isSitixLogoSelected={true}>
			<FullScreenLoader show={loading} />
			<MetaDecorator title={getVenueTagTitle(venueData?.name)} description={getVenueTagDescription(venueData?.name)} />
			<GlobalAssetCarousel
				customProps={{
					background: venueImage,
					backgroundIsLocal: true,
					headline1: {
						text: venueName,
						style: {
							fontSize: "40px",
							fontWeight: 600,
							textAlign: "left",
							color: "white",
						},
					},
					subHeadline1: {
						text: "Tickets",
						style: {
							fontFamily: "SolanoGothicMVB-Bd",
							fontSize: "28px",
							textAlign: "left",
							color: "white",
						},
					},
				}}
			/>

			<Events
				title={""}
				events={state.events}
				ads={adAssets}
				onLoadMore={loadEvents}
				loading={loadingEvents}
				total={state.total}
				render={event =>
					typeof event.sportId === "number" ? (
						// @ts-ignore
						<SportEventItem key={event.id} event={event} />
					) : (
						// @ts-ignore
						<NormalEventItem key={event.id} event={event} />
					)
				}
			/>

			<PageBio htmlString={bio} />
		</CommonPage>
	);
};
