import { APIClientResponse } from "./types/APIClientResponse";
import { apiClient } from "./apiClient";

export function getSiTailgatesEvents(data?: {
	source?: string,
}): APIClientResponse<{
	metadata: {
		page_number: number;
		page_size: number;
		total_records: number;
	};
	events: {
		event_id: number;
		event_name: string;
		event_slug: string;
		occurs_at: string;
		event_image: string;
		venue_id: number;
		venue_name: string;
		venue_slug: string;
	}[];
}> {

	return apiClient.get("/events/performers", {
		params: {
			"performer_slug": "si-tailgates",
			source: data?.source,
		},
	});
}
