import React from "react";
import styled from "styled-components/macro";
import { maxContentWidthStyles } from "../../util/maxContentWidth";
import { mediaQueries } from "../../util/mediaQueries";
import { getAppColor } from "../../util/appColors";
import { Typography } from "../Typography/Typography";

export const Disclaimer = () => {
	return (
		<DisclaimerContainer>
			<DisclaimerInner>
				<DisclaimerContent type="bodyTiny" color="light">
					SI Tickets is 100% for entertainment purposes only and does not involve real money betting. This site is intended for adult users only. If you or someone you know has a gambling problem and wants help, call{" "}
					<strong>
						<Link href={"tel:1800gambler"} target={"_blank"}>
							1-800 GAMBLER
						</Link>
					</strong>{" "}
					or visit{" "}
					<strong>
						<Link href={"https://800gambler.org"} target={"_blank"}>
							800gambler.org
						</Link>
					</strong>
				</DisclaimerContent>
			</DisclaimerInner>
		</DisclaimerContainer>
	);
};

const DisclaimerInner = styled.div`
	${maxContentWidthStyles}
	display:flex;
	align-items: center;
	padding: 20px 0;
	width: 100%;

	@media ${mediaQueries.max1000} {
		padding-left: 20px;
		padding-right: 20px;
	}

	@media ${mediaQueries.max900} {
		flex-direction: column;
	}
`;

const Link = styled.a`
	text-decoration: none !important;
	& > *:hover {
		box-shadow: 5px 5px 7px 1px #ccc;
		cursor: pointer;
	}
`;

const DisclaimerContainer = styled.div`
	display: flex;
	margin: auto;
	background: ${getAppColor("dark", "main")};
	width: 100%;
`;

const DisclaimerContent = styled(Typography)`
	text-align: left;
`;
