import React, { useState } from "react";
import styled from "styled-components/macro";
import { maxContentWidthStyles, maxContentWidthPadding } from "../../util/maxContentWidth";
import { RoundedButton } from "../RoundedButton/RoundedButton";
import { SantizedHTML } from "../SantizedHTML/SantizedHTML";
import { Expandable } from "../Expandable/Expandable";
import { isMobileApp } from "../../util/isMobileApp";
import { FontFamily } from "../Typography/Typography";

export const PageBio = (props: { htmlString: string }) => {
	const [expanded, setExpanded] = useState(false);

	return props.htmlString ? (
		<>
			<StyledExpandable expanded={expanded} minHeight={initialHeight}>
				{!expanded && <Overlay />}
				<SantizedHTML htmlString={props.htmlString} />
			</StyledExpandable>
			{!expanded && (
				<KeepReadingContainer>
					<RoundedButton
						onClick={() => {
							setExpanded(true);
						}}
						text="Keep Reading"
					/>
				</KeepReadingContainer>
			)}
		</>
	) : null;
};

const initialHeight = 150;
const mobileAppMarginBottom = "24px";

const StyledExpandable = styled(Expandable)`
	${maxContentWidthStyles}
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #000;
	overflow: hidden;
	transition: max-height 700ms ease-in-out;
	margin-top: 30px;
	margin-bottom: ${isMobileApp ? mobileAppMarginBottom : "0px"};
	& * {
		font-family: ${FontFamily.Poppins} !important;
		text-align: left;
	}
`;

const rgbOverlayBackground = "255, 255, 255";
const Overlay = styled.div`
	width: calc(100% - ${2 * maxContentWidthPadding}px);
	background: linear-gradient(0deg, rgba(${rgbOverlayBackground}, 1) 0%, rgba(${rgbOverlayBackground}, 0) 100%);
	display: block;
	position: absolute;
	height: ${initialHeight}px;
	z-index: 1;
`;

const KeepReadingContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: -20px;
	z-index: 1;
	margin-bottom: ${isMobileApp ? mobileAppMarginBottom : "-16px"};
`;
