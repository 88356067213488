import styled from "styled-components/macro";
import { maxContentWidthValue } from "../../util/maxContentWidth";
import { responsiveBannerSize, responsiveBannerHeight, responsiveBannerMaxHeight, responsiveFontSizeFromString } from "../../util/responsiveBanner";
import { mediaQueries } from "../../util/mediaQueries";
import { Link } from "../../Link";
import { Config } from "../../../Config";

export const Container = styled.div`
	${responsiveBannerSize}
	width: 100%;
	box-sizing: border-box;
	position: relative;
	margin-bottom: 20px;
`;

export const Overlay = styled.div`
	margin: 0 auto;
	${responsiveBannerSize}
	max-width: ${maxContentWidthValue}px;
	box-sizing: border-box;
	position: relative;
`;

export const StyledText = styled.div<{
	fontSize?: string;
}>`
	${props => responsiveFontSizeFromString(props.fontSize)}
	z-index: 1;
	pointer-events: none;
`;

export const StyledLink = styled(Link).attrs(props => ({
	to: props.to || "#",
}))`
	text-decoration: none;
	pointer-events: auto;
	line-height: normal;
`;

export const Image = styled.img.attrs<{ src?: string | null }>(props => ({
	src: props.src ? Config.getResourceUrl(props.src) : undefined,
}))<{ hasLink?: boolean }>`
	position: absolute;
	object-fit: contain;
	display: none;
	&[src] {
		display: block;
	}
	&:hover {
		${props => props.hasLink && "cursor: pointer;"}
	}
`;

const hexToRGB = (hex?: string) => {
	if (hex) {
		let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? parseInt(result[1], 16) + ", " + parseInt(result[2], 16) + ", " + parseInt(result[3], 16) : null;
	}
	return;
};

export const Banner = styled.div<{
	src?: string | null;
	overlay?: string;
}>`
	background: ${props => {
		const rgb = hexToRGB(props.overlay) || "0, 0, 0";
		const opacity = props.overlay ? 0.5 : 0;
		const rgba = "rgba(" + rgb + ", " + opacity + ")";
		return "linear-gradient(" + rgba + ", " + rgba + '), url("' + props.src + '")';
	}};
	width: 100%;
	height: 100%;
	position: absolute;
	background-size: cover;
	background-repeat: no-repeat;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
`;

export const Images = {
	Image1: styled(Image)`
		top: 0;
		left: 0;
		width: 29.27%;
		height: 100%;
	`,
	Image2: styled(Image)`
		left: 0;
		margin-left: 29.27%;
		width: 42.48%;
		height: 100%;
	`,
	Image3: styled(Image)`
		top: 0;
		right: 0;
		width: 28.25%;
		height: 50%;
	`,
	Image4: styled(Image)`
		bottom: 0;
		right: 0;
		width: 28.25%;
		height: 50%;
	`,
	Image5: styled(Image)`
		top: 0;
		left: 0;
		width: 21.5%;
		height: 100%;
	`,
	Image6: styled(Image)`
		top: 0;
		right: 0;
		margin-right: 28.76%;
		width: 42.48%;
		height: 50%;
	`,
	Image7: styled(Image)`
		top: 0;
		right: 0;
		width: 21.5%;
		height: 100%;
	`,
};

export const MainHeadlineContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: auto;
	min-height: 50%;
	padding-left: 1rem;
	padding-right: 1rem;
	overflow: hidden;
`;

export const SecondaryHeadlineContainer = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: row;
	height: auto;
	min-height: 50%;
	padding-left: 0.7rem;
	padding-right: 1rem;
	pointer-events: none;
`;

export const SecondaryHeadline = styled.div`
	flex: 2;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const PerformerHeadline = styled.div<{ isTablet: boolean }>`
	flex: ${props => (props.isTablet ? "6" : "20")};
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const Children = styled.div`
	flex: 6;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;

	align-self: flex-end;
	height: ${responsiveBannerHeight / 2}vw;
	max-height: ${responsiveBannerMaxHeight / 2}px;

	& > * {
		pointer-events: all;
	}

	@media ${mediaQueries.max500} {
		display: none;
	}
`;
