export const countries = [
	{ value: "United States", label: "United States" },
	{ value: "Afghanistan", label: "Afghanistan" },
	{ value: "Albania", label: "Albania" },
	{ value: "Algeria", label: "Algeria" },
	{ value: "American Samoa", label: "American Samoa" },
	{ value: "Andorra", label: "Andorra" },
	{ value: "Angola", label: "Angola" },
	{ value: "Anguilla", label: "Anguilla" },
	{ value: "Antarctica", label: "Antarctica" },
	{ value: "Antigua & Barbuda", label: "Antigua & Barbuda" },
	{ value: "Argentina", label: "Argentina" },
	{ value: "Armenia", label: "Armenia" },
	{ value: "Aruba", label: "Aruba" },
	{ value: "Ascension Island", label: "Ascension Island" },
	{ value: "Australia", label: "Australia" },
	{ value: "Austria", label: "Austria" },
	{ value: "Azerbaijan", label: "Azerbaijan" },
	{ value: "Bahamas", label: "Bahamas" },
	{ value: "Bahrain", label: "Bahrain" },
	{ value: "Bangladesh", label: "Bangladesh" },
	{ value: "Barbados", label: "Barbados" },
	{ value: "Belarus", label: "Belarus" },
	{ value: "Belgium", label: "Belgium" },
	{ value: "Belize", label: "Belize" },
	{ value: "Benin", label: "Benin" },
	{ value: "Bermuda", label: "Bermuda" },
	{ value: "Bhutan", label: "Bhutan" },
	{ value: "Bolivia", label: "Bolivia" },
	{ value: "Bosnia & Herzegovina", label: "Bosnia & Herzegovina" },
	{ value: "Botswana", label: "Botswana" },
	{ value: "Bouvet Island", label: "Bouvet Island" },
	{ value: "Brazil", label: "Brazil" },
	{ value: "British Indian Ocean Territory", label: "British Indian Ocean Territory" },
	{ value: "British Virgin Islands", label: "British Virgin Islands" },
	{ value: "Brunei", label: "Brunei" },
	{ value: "Bulgaria", label: "Bulgaria" },
	{ value: "Burkina Faso", label: "Burkina Faso" },
	{ value: "Burundi", label: "Burundi" },
	{ value: "Cambodia", label: "Cambodia" },
	{ value: "Cameroon", label: "Cameroon" },
	{ value: "Canada", label: "Canada" },
	{ value: "Canary Islands", label: "Canary Islands" },
	{ value: "Cape Verde", label: "Cape Verde" },
	{ value: "Caribbean Netherlands", label: "Caribbean Netherlands" },
	{ value: "Cayman Islands", label: "Cayman Islands" },
	{ value: "Central African Republic", label: "Central African Republic" },
	{ value: "Ceuta & Melilla", label: "Ceuta & Melilla" },
	{ value: "Chad", label: "Chad" },
	{ value: "Chile", label: "Chile" },
	{ value: "China", label: "China" },
	{ value: "Christmas Island", label: "Christmas Island" },
	{ value: "Clipperton Island", label: "Clipperton Island" },
	{ value: "Cocos (Keeling) Islands", label: "Cocos (Keeling) Islands" },
	{ value: "Colombia", label: "Colombia" },
	{ value: "Comoros", label: "Comoros" },
	{ value: "Congo - Brazzaville", label: "Congo - Brazzaville" },
	{ value: "Congo - Kinshasa", label: "Congo - Kinshasa" },
	{ value: "Cook Islands", label: "Cook Islands" },
	{ value: "Costa Rica", label: "Costa Rica" },
	{ value: "Croatia", label: "Croatia" },
	{ value: "Cuba", label: "Cuba" },
	{ value: "Curaçao", label: "Curaçao" },
	{ value: "Cyprus", label: "Cyprus" },
	{ value: "Czechia", label: "Czechia" },
	{ value: "Côte d’Ivoire", label: "Côte d’Ivoire" },
	{ value: "Denmark", label: "Denmark" },
	{ value: "Diego Garcia", label: "Diego Garcia" },
	{ value: "Djibouti", label: "Djibouti" },
	{ value: "Dominica", label: "Dominica" },
	{ value: "Dominican Republic", label: "Dominican Republic" },
	{ value: "Ecuador", label: "Ecuador" },
	{ value: "Egypt", label: "Egypt" },
	{ value: "El Salvador", label: "El Salvador" },
	{ value: "Equatorial Guinea", label: "Equatorial Guinea" },
	{ value: "Eritrea", label: "Eritrea" },
	{ value: "Estonia", label: "Estonia" },
	{ value: "Eswatini", label: "Eswatini" },
	{ value: "Ethiopia", label: "Ethiopia" },
	{ value: "Falkland Islands (Islas Malvinas)", label: "Falkland Islands (Islas Malvinas)" },
	{ value: "Faroe Islands", label: "Faroe Islands" },
	{ value: "Fiji", label: "Fiji" },
	{ value: "Finland", label: "Finland" },
	{ value: "France", label: "France" },
	{ value: "French Guiana", label: "French Guiana" },
	{ value: "French Polynesia", label: "French Polynesia" },
	{ value: "French Southern Territories", label: "French Southern Territories" },
	{ value: "Gabon", label: "Gabon" },
	{ value: "Gambia", label: "Gambia" },
	{ value: "Georgia", label: "Georgia" },
	{ value: "Germany", label: "Germany" },
	{ value: "Ghana", label: "Ghana" },
	{ value: "Gibraltar", label: "Gibraltar" },
	{ value: "Greece", label: "Greece" },
	{ value: "Greenland", label: "Greenland" },
	{ value: "Grenada", label: "Grenada" },
	{ value: "Guadeloupe", label: "Guadeloupe" },
	{ value: "Guam", label: "Guam" },
	{ value: "Guatemala", label: "Guatemala" },
	{ value: "Guernsey", label: "Guernsey" },
	{ value: "Guinea", label: "Guinea" },
	{ value: "Guinea-Bissau", label: "Guinea-Bissau" },
	{ value: "Guyana", label: "Guyana" },
	{ value: "Haiti", label: "Haiti" },
	{ value: "Heard & McDonald Islands", label: "Heard & McDonald Islands" },
	{ value: "Honduras", label: "Honduras" },
	{ value: "Hong Kong", label: "Hong Kong" },
	{ value: "Hungary", label: "Hungary" },
	{ value: "Iceland", label: "Iceland" },
	{ value: "India", label: "India" },
	{ value: "Indonesia", label: "Indonesia" },
	{ value: "Iran", label: "Iran" },
	{ value: "Iraq", label: "Iraq" },
	{ value: "Ireland", label: "Ireland" },
	{ value: "Isle of Man", label: "Isle of Man" },
	{ value: "Israel", label: "Israel" },
	{ value: "Italy", label: "Italy" },
	{ value: "Jamaica", label: "Jamaica" },
	{ value: "Japan", label: "Japan" },
	{ value: "Jersey", label: "Jersey" },
	{ value: "Jordan", label: "Jordan" },
	{ value: "Kazakhstan", label: "Kazakhstan" },
	{ value: "Kenya", label: "Kenya" },
	{ value: "Kiribati", label: "Kiribati" },
	{ value: "Kosovo", label: "Kosovo" },
	{ value: "Kuwait", label: "Kuwait" },
	{ value: "Kyrgyzstan", label: "Kyrgyzstan" },
	{ value: "Laos", label: "Laos" },
	{ value: "Latvia", label: "Latvia" },
	{ value: "Lebanon", label: "Lebanon" },
	{ value: "Lesotho", label: "Lesotho" },
	{ value: "Liberia", label: "Liberia" },
	{ value: "Libya", label: "Libya" },
	{ value: "Liechtenstein", label: "Liechtenstein" },
	{ value: "Lithuania", label: "Lithuania" },
	{ value: "Luxembourg", label: "Luxembourg" },
	{ value: "Macao", label: "Macao" },
	{ value: "Madagascar", label: "Madagascar" },
	{ value: "Malawi", label: "Malawi" },
	{ value: "Malaysia", label: "Malaysia" },
	{ value: "Maldives", label: "Maldives" },
	{ value: "Mali", label: "Mali" },
	{ value: "Malta", label: "Malta" },
	{ value: "Marshall Islands", label: "Marshall Islands" },
	{ value: "Martinique", label: "Martinique" },
	{ value: "Mauritania", label: "Mauritania" },
	{ value: "Mauritius", label: "Mauritius" },
	{ value: "Mayotte", label: "Mayotte" },
	{ value: "Mexico", label: "Mexico" },
	{ value: "Micronesia", label: "Micronesia" },
	{ value: "Moldova", label: "Moldova" },
	{ value: "Monaco", label: "Monaco" },
	{ value: "Mongolia", label: "Mongolia" },
	{ value: "Montenegro", label: "Montenegro" },
	{ value: "Montserrat", label: "Montserrat" },
	{ value: "Morocco", label: "Morocco" },
	{ value: "Mozambique", label: "Mozambique" },
	{ value: "Myanmar (Burma)", label: "Myanmar (Burma)" },
	{ value: "Namibia", label: "Namibia" },
	{ value: "Nauru", label: "Nauru" },
	{ value: "Nepal", label: "Nepal" },
	{ value: "Netherlands", label: "Netherlands" },
	{ value: "New Caledonia", label: "New Caledonia" },
	{ value: "New Zealand", label: "New Zealand" },
	{ value: "Nicaragua", label: "Nicaragua" },
	{ value: "Niger", label: "Niger" },
	{ value: "Nigeria", label: "Nigeria" },
	{ value: "Niue", label: "Niue" },
	{ value: "Norfolk Island", label: "Norfolk Island" },
	{ value: "North Korea", label: "North Korea" },
	{ value: "North Macedonia", label: "North Macedonia" },
	{ value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
	{ value: "Norway", label: "Norway" },
	{ value: "Oman", label: "Oman" },
	{ value: "Pakistan", label: "Pakistan" },
	{ value: "Palau", label: "Palau" },
	{ value: "Palestine", label: "Palestine" },
	{ value: "Panama", label: "Panama" },
	{ value: "Papua New Guinea", label: "Papua New Guinea" },
	{ value: "Paraguay", label: "Paraguay" },
	{ value: "Peru", label: "Peru" },
	{ value: "Philippines", label: "Philippines" },
	{ value: "Pitcairn Islands", label: "Pitcairn Islands" },
	{ value: "Poland", label: "Poland" },
	{ value: "Portugal", label: "Portugal" },
	{ value: "Puerto Rico", label: "Puerto Rico" },
	{ value: "Qatar", label: "Qatar" },
	{ value: "Romania", label: "Romania" },
	{ value: "Russia", label: "Russia" },
	{ value: "Rwanda", label: "Rwanda" },
	{ value: "Réunion", label: "Réunion" },
	{ value: "Samoa", label: "Samoa" },
	{ value: "San Marino", label: "San Marino" },
	{ value: "Saudi Arabia", label: "Saudi Arabia" },
	{ value: "Senegal", label: "Senegal" },
	{ value: "Serbia", label: "Serbia" },
	{ value: "Seychelles", label: "Seychelles" },
	{ value: "Sierra Leone", label: "Sierra Leone" },
	{ value: "Singapore", label: "Singapore" },
	{ value: "Sint Maarten", label: "Sint Maarten" },
	{ value: "Slovakia", label: "Slovakia" },
	{ value: "Slovenia", label: "Slovenia" },
	{ value: "Solomon Islands", label: "Solomon Islands" },
	{ value: "Somalia", label: "Somalia" },
	{ value: "South Africa", label: "South Africa" },
	{ value: "South Georgia & South Sandwich Islands", label: "South Georgia & South Sandwich Islands" },
	{ value: "South Korea", label: "South Korea" },
	{ value: "South Sudan", label: "South Sudan" },
	{ value: "Spain", label: "Spain" },
	{ value: "Sri Lanka", label: "Sri Lanka" },
	{ value: "St. Barthélemy", label: "St. Barthélemy" },
	{ value: "St. Helena", label: "St. Helena" },
	{ value: "St. Kitts & Nevis", label: "St. Kitts & Nevis" },
	{ value: "St. Lucia", label: "St. Lucia" },
	{ value: "St. Martin", label: "St. Martin" },
	{ value: "St. Pierre & Miquelon", label: "St. Pierre & Miquelon" },
	{ value: "St. Vincent & Grenadines", label: "St. Vincent & Grenadines" },
	{ value: "Sudan", label: "Sudan" },
	{ value: "Suriname", label: "Suriname" },
	{ value: "Svalbard & Jan Mayen", label: "Svalbard & Jan Mayen" },
	{ value: "Sweden", label: "Sweden" },
	{ value: "Switzerland", label: "Switzerland" },
	{ value: "Syria", label: "Syria" },
	{ value: "São Tomé & Príncipe", label: "São Tomé & Príncipe" },
	{ value: "Taiwan", label: "Taiwan" },
	{ value: "Tajikistan", label: "Tajikistan" },
	{ value: "Tanzania", label: "Tanzania" },
	{ value: "Thailand", label: "Thailand" },
	{ value: "Timor-Leste", label: "Timor-Leste" },
	{ value: "Togo", label: "Togo" },
	{ value: "Tokelau", label: "Tokelau" },
	{ value: "Tonga", label: "Tonga" },
	{ value: "Trinidad & Tobago", label: "Trinidad & Tobago" },
	{ value: "Tristan da Cunha", label: "Tristan da Cunha" },
	{ value: "Tunisia", label: "Tunisia" },
	{ value: "Turkey", label: "Turkey" },
	{ value: "Turkmenistan", label: "Turkmenistan" },
	{ value: "Turks & Caicos Islands", label: "Turks & Caicos Islands" },
	{ value: "Tuvalu", label: "Tuvalu" },
	{ value: "U.S. Outlying Islands", label: "U.S. Outlying Islands" },
	{ value: "U.S. Virgin Islands", label: "U.S. Virgin Islands" },
	{ value: "Uganda", label: "Uganda" },
	{ value: "Ukraine", label: "Ukraine" },
	{ value: "United Arab Emirates", label: "United Arab Emirates" },
	{ value: "United Kingdom", label: "United Kingdom" },
	{ value: "Uruguay", label: "Uruguay" },
	{ value: "Uzbekistan", label: "Uzbekistan" },
	{ value: "Vanuatu", label: "Vanuatu" },
	{ value: "Vatican City", label: "Vatican City" },
	{ value: "Venezuela", label: "Venezuela" },
	{ value: "Vietnam", label: "Vietnam" },
	{ value: "Wallis & Futuna", label: "Wallis & Futuna" },
	{ value: "Western Sahara", label: "Western Sahara" },
	{ value: "Yemen", label: "Yemen" },
	{ value: "Zambia", label: "Zambia" },
	{ value: "Zimbabwe", label: "Zimbabwe" },
	{ value: "Åland Islands", label: "Åland Islands" },
];

export const states = [
	{ value: "Alabama", label: "Alabama" },
	{ value: "Alaska", label: "Alaska" },
	{ value: "Arizona", label: "Arizona" },
	{ value: "Arkansas", label: "Arkansas" },
	{ value: "California", label: "California" },
	{ value: "Colorado", label: "Colorado" },
	{ value: "Connecticut", label: "Connecticut" },
	{ value: "Delaware", label: "Delaware" },
	{ value: "Florida", label: "Florida" },
	{ value: "Georgia", label: "Georgia" },
	{ value: "Hawaii", label: "Hawaii" },
	{ value: "Idaho", label: "Idaho" },
	{ value: "Ilinois", label: "Ilinois" },
	{ value: "Indiana", label: "Indiana" },
	{ value: "Iowa", label: "Iowa" },
	{ value: "Kansas", label: "Kansas" },
	{ value: "Kentucky", label: "Kentucky" },
	{ value: "Lousiana", label: "Lousiana" },
	{ value: "Maine", label: "Maine" },
	{ value: "Marryland", label: "Marryland" },
	{ value: "Massachusettes", label: "Massachusettes" },
	{ value: "Michigan", label: "Michigan" },
	{ value: "Minnesota", label: "Minnesota" },
	{ value: "Mississippi", label: "Mississippi" },
	{ value: "Missouri", label: "Missouri" },
	{ value: "Montana", label: "Montana" },
	{ value: "Nebraska", label: "Nebraska" },
	{ value: "Nevada", label: "Nevada" },
	{ value: "New Hamshire", label: "New Hamshire" },
	{ value: "New Jersey", label: "New Jersey" },
	{ value: "New Mexico", label: "New Mexico" },
	{ value: "New York", label: "New York" },
	{ value: "North Carolina", label: "North Carolina" },
	{ value: "North Dakota", label: "North Dakota" },
	{ value: "Ohio", label: "Ohio" },
	{ value: "Oklahoma", label: "Oklahoma" },
	{ value: "Oregon", label: "Oregon" },
	{ value: "Pennsylvania", label: "Pennsylvania" },
	{ value: "Rhode Island", label: "Rhode Island" },
	{ value: "South Carolina", label: "South Carolina" },
	{ value: "South Dakota", label: "South Dakota" },
	{ value: "Tennessee", label: "Tennessee" },
	{ value: "Texas", label: "Texas" },
	{ value: "Utah", label: "Utah" },
	{ value: "Vermont", label: "Vermont" },
	{ value: "Virginia", label: "Virginia" },
	{ value: "Washington", label: "Washington" },
	{ value: "West Virginia", label: "West Virginia" },
	{ value: "Wisconson", label: "Wisconson" },
	{ value: "Wyoming", label: "Wyoming" },
	{ value: "District of Columbia", label: "District of Columbia" },
];
