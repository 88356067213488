import { client, dataCollector } from "braintree-web";
import { Config } from "../Config";
import { Cart, CartFuture, Ticket, TicketDeliveryOption, Discount } from "../model/Cart";
import { Address } from "../model/User";
import { Platform } from "../presentation/util/UserAgent";
import { store } from "../store/store";
import { apiClient } from "./apiClient";
import { APIClientResponse } from "./types/APIClientResponse";
import { deleteCartTicket, deleteCartFuture } from "../store/reducers/cartReducer";
import { setCheckoutTimer } from "../store/actions/checkoutActions";
import { PaymentMethodType, TransactionFeeRule } from "../model/optimizedModel/braintree";
import { PromiseType } from "../model/optimizedModel/general";

function sessionId(): string | undefined {
	return store.getState().persistent.cart?.sessionId;
}

function getTransactionOrigin() {
	switch (Config.getPlatformOS()) {
		case "ios":
			return "mobile_app_ios";
		case "android":
			return "mobile_app_android";
		case "web":
			return Platform.IOS || Platform.Android ? "mobile_web" : "desktop_web";
	}
}

export function postCartTaxquote(data: {
	ticket?: {
		price: number;
		quantity: number;
		shippingFee: number;
		transactionFee: number;
		taxCode: string;
		eventName: string;
		venue: {
			name: string;
			city: string;
			country: string;
			state: string;
			zip: string;
			street_address: string;
			latitude: string;
			longitude: string;
		};
	};
	reservations?: {
		price: number;
		quantity: number;
		eventName: string;
		venue: {
			name: string;
			city: string;
			country: string;
			state: string;
			zip: string;
			street_address: string;
			latitude: string;
			longitude: string;
		};
	}[];
}): APIClientResponse<{
	totalTax: number;
}> {
	return apiClient.post("cart/taxquote", data);
}

export function getCheckoutThanks(orderId: string): Promise<any> {
	return apiClient.get("checkout_thanks/" + orderId);
}

export async function deleteCartItem(
	cartItemId: string,
	data:
		| {
				isTicket: true;
		  }
		| {
				futureInventoryId: string;
		  }
) {
	return apiClient
		.delete("cart/item", {
			data: {
				sessionId: sessionId(),
				cartItemIds: cartItemId,
				isTicket: "isTicket" in data,
			},
		})
		.then(() => {
			if ("futureInventoryId" in data) {
				store.dispatch(deleteCartFuture(data.futureInventoryId));
			} else {
				store.dispatch(deleteCartTicket());
				store.dispatch(setCheckoutTimer(undefined));
			}
		});
}

export function renderCartDeliveryMethod(option: TicketDeliveryOption): string {
	return option.name + " ($" + option.price + ")";
}

export function postCartShippingMethod(
	deliveryOptionId: string | number,
): APIClientResponse<{
	cart: Cart;
}> {
	return apiClient.post("cart/shipping-method", {
		sessionId: sessionId(),
		deliveryOptionId,
	});
}

export function postCartShippingAddress(address: Address): APIClientResponse<{
	cart: Cart;
}> {
	const id = sessionId();
	return apiClient.post("cart/shipping-address", {
		sessionId: id,
		address,
		refUrl: "checkout/delivery/add-new-address",
	});
}

export function getCart(sessionId?: string) {
	return apiClient.get("cart", {
		params: {
			sessionId,
		},
	});
}

export function postCartFuture(
	tiersId: number,
	quantity: number,
	sessionId?: string
): APIClientResponse<{
	cart: Cart;
}> {
	return apiClient.post("cart/future", {
		futureInventoryId: tiersId,
		futureInventoryQuantity: quantity,
		sessionId,
	});
}

export function postCartCheckout(
	basePayload: {
		sessionId: string;
		promoWalletFundsUsed: number;
		totalPrice: number;
		totalTax: number;
	},
	data: {
		ticket?: Ticket;
		futures?: CartFuture[];
		paymentKeyType?: "token" | "nonce" | null;
		paymentKey?: string | null;
		deliveryMethod?: string;
		paymentMethodType?: PaymentMethodType;
		firstName?: string;
		lastName?: string;
		discountCode?: string;
	}
):
	| APIClientResponse<{
			orderId: number;
			result: boolean;
	  }>
	| undefined {
	const creditCardInfo = store.getState().persistent.checkout.creditCardInfo;
	const userSessionId = window.RISKX?.getCartId();
	const braintreeState = store.getState().persistent.braintree;

	if (braintreeState.clientAuth) {
		return client
			.create({
				authorization: braintreeState.clientAuth,
			})
			.then(clientInstance => {
				return dataCollector.create({
					client: clientInstance,
				});
			})
			.then(dataCollectorInstance => {
				console.log(dataCollectorInstance);
				//@ts-ignore
				return dataCollectorInstance.getDeviceData({}, true);
			})
			.then(deviceData => {
				const { paymentKeyType, paymentKey, firstName, lastName } = data;
				const regForm = localStorage.getItem("registration");
				return apiClient.post("cart/checkout", {
					...basePayload,
					cardType: creditCardInfo?.cardType,
					cardLast4Digits: creditCardInfo?.lastFour,
					userSessionId,
					transactionOrigin: getTransactionOrigin(),
					deviceData,
					fName: firstName,
					lName: lastName,
					[paymentKeyType && paymentKeyType === "token" ? "paymentToken" : "paymentNonce"]: paymentKey,
					phone: store.getState().persistent.checkout.phoneNumber,
					discountCode: data.discountCode,
					registration: regForm ? JSON.parse(regForm) : null,
				});
			})
			.then(result => {
				localStorage.removeItem("registration");
				return result as unknown as PromiseType<
					APIClientResponse<{
						orderId: number;
						result: boolean;
					}>
				>;
			});
	}
}

export function removeTicketFromCart(eventId: number, ticketGroupId: number, sessionId: string) {
	return postCartTicket(eventId, ticketGroupId, 0, sessionId);
}

export function removeFutureFromCart(eventTeamId: number, zoneId: number, sessionId: string) {
	return postCartTicket(eventTeamId, zoneId, 0, sessionId);
}

export function postCartTicket(
	eventId: number,
	ticketGroupId: number,
	quantity: number,
	sessionId: string | undefined,
	source?: string,
	price?: string | number | null,
	refUrl?: string
): APIClientResponse<{
	cart: Cart;
}> {
	return apiClient.post("cart/ticket", {
		event_id: eventId,
		ticket_group_id: ticketGroupId,
		source,
		quantity,
		sessionId,
		flatDeal: 10,
		price,
		refUrl,
	});
}

export function postBOCartTicket(
	masterEventId: number,
	sessionId: string | undefined,
	ticketList: {
		ticketGroupId?: number;
		quantity?: number;
		price?: string | number | null;
		refUrl?: string;
	}[],
	source?: string
): APIClientResponse<{
	cart: Cart;
}> {
	return apiClient.post(`cart/ticket`, {
		event_id: masterEventId,
		source,
		sessionId,
		flatDeal: 10,
		tickets: ticketList.map(ticket => ({
			ticket_group_id: ticket.ticketGroupId,
			quantity: ticket.quantity,
			price: ticket.price,
			refUrl: ticket.refUrl,
		})),
	});
}

export const postDiscountCode = (
	email: string,
	discountCode: string,
	price: number,
	cartId: number,
	transactionFee?: TransactionFeeRule
): APIClientResponse<{
	discount: Discount;
}> => {
	return apiClient.post("discount/apply", {
		email,
		totalPrice: price,
		discountCode,
		cartId,
		transactionFee,
	});
};
