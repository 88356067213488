import React from "react";
import { Toaster } from "react-hot-toast";

export const ErrorToaster = () => {
	return (
		<Toaster
			position="top-center"
			toastOptions={{
				duration: 9000,
				style: {
					textAlign: "left",
					fontWeight: 500,
				},
			}}
		/>
	);
};
