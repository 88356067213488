import React from "react";
import { Event } from "../../../model/Event";
import { InternalRoutes, Link } from "../../Link";
import { DateTime } from "luxon";
import styled from "styled-components/macro";
import { EventContent, ColumnBody, EventTable, MobileLayout, EventCardSideColumn, PostponedSection, EventCardSubTitle, EventCardTitle, NewEventCardSideColumn, NewEventCardTitle, NewEventCardSubTitle } from "./styles";
import { getAppColor } from "../../util/appColors";
import { FontFamily } from "../Typography/Typography";
import { breakpoints } from "../../util/breakpoints";
import { Button } from "../NewButton/Button";
import eventArrow from "../../resource/image/event-arrow.svg";
import eventArrowRed from "../../resource/image/event-arrow-red.svg";
import { useAutoUpdateState } from "../../hooks/useAutoUpdateState";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";
import locationPinIcon from "../../resource/assets/icons/locationPin.svg";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { SantizedHTML } from "../SantizedHTML/SantizedHTML";

const BaseEventItem = (
	props: {
		event: Event;
		to: string;
		children?: JSX.Element | ((date: DateTime | null) => JSX.Element);
		forceMobileDesign?: boolean;
		target?: string;
		newDesign?: boolean;
	} & StyledComponentProps
) => {
	const event = props.event;
	const date = event.date ? DateTime.fromJSDate(event.date) : null;
	const rescheduledIndicator = "(Rescheduled";
	const rescheduledIndex = event.name.indexOf(rescheduledIndicator);
	//TODO: Logic that manually removed Rescheduled content from event titles. Remove?
	if (rescheduledIndex >= 0) {
		const rescheduledLastIndex = event.name.indexOf(")", rescheduledIndex) + 1;
		const fullRescheduledMessage = event.name.substring(rescheduledIndex, rescheduledLastIndex);
		const eventNameWithoutRescheduledMessage = event.name.replace(fullRescheduledMessage, "");
		const formattedEventName = eventNameWithoutRescheduledMessage.replace(/\s{2,}/g, " ");
		event.name = formattedEventName;
	}

	const replaceOutOfFrameTime = (date: string) => {
		date = date.startsWith("3:30") ? "TBD" : date;
		date = date.startsWith("0:00") ? "TBD" : date;
		return date;
	};

	const getDisplayedYear = (year: number) => {
		const THRESHOLD = 5;
		const yearDisplayThreshold = new Date().getFullYear() + THRESHOLD;
		return year < yearDisplayThreshold ? year : "TBD";
	};

	const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);

	const eventContent = (
		<EventContent newDesign={props.newDesign}>
			{date && getDisplayedYear(date.year) !== "TBD" ? (
				!props.newDesign ? (
					<EventCardSideColumn forceMobileDesign={props.forceMobileDesign}>
						<p>{date.weekdayShort}</p>
						<p>
							{date.monthShort.toUpperCase()} {date.day}
						</p>
						<p>{getDisplayedYear(date.year)}</p>
						<p>{replaceOutOfFrameTime(date.toLocaleString(DateTime.TIME_SIMPLE))}</p>
					</EventCardSideColumn>
				) : (
					<NewEventCardSideColumn forceMobileDesign={props.forceMobileDesign}>
						<p>
							{date.monthShort.toUpperCase()} {date.day}
						</p>
						<p>
							{date.weekdayShort} {replaceOutOfFrameTime(date.toLocaleString(DateTime.TIME_SIMPLE))}
						</p>
					</NewEventCardSideColumn>
				)
			) : (
				<PostponedSection forceMobileDesign={props.forceMobileDesign}>
					<div>Postponed</div>
					<div>Date</div>
					<div>TBD</div>
				</PostponedSection>
			)}
			<ColumnBody
				newDesign={props.newDesign}
			>
				{!props.newDesign ? <EventCardTitle forceMobileDesign={props.forceMobileDesign}><SantizedHTML htmlString={event.name} /> </EventCardTitle> : <NewEventCardTitle forceMobileDesign={props.forceMobileDesign}><SantizedHTML htmlString={event.name} /> </NewEventCardTitle>}
				{!props.newDesign ? <EventCardSubTitle forceMobileDesign={props.forceMobileDesign}>{typeof props.children === "function" ? props.children(date) : props.children}</EventCardSubTitle> : <NewEventCardSubTitle forceMobileDesign={props.forceMobileDesign}>{typeof props.children === "function" ? props.children(date) : props.children}</NewEventCardSubTitle>}
			</ColumnBody>
			{!props.newDesign ? (
				<StyledButton typographyType="bodySmall" variant="solid" forceMobileDesign={props.forceMobileDesign} size="small">
					<strong>See tickets</strong>
					<EventArrow src={eventArrow} alt="See tickets" />
				</StyledButton>
			) : (
				<>
					{
						!isMobile && (
							<StyledButton variant="solid" size="small" newDesign={props.newDesign}>
								{event.ticketGroupPrice && event.ticketGroupPrice > 0 ? `from $${event.ticketGroupPrice}` : "Get Tickets"}
								<EventArrow src={eventArrowRed} alt="See tickets" />
							</StyledButton>
						)
					}
				</>
			)}
		</EventContent>
	);

	return (
		<Link to={props.to} target={props.target}>
			{!props.newDesign ? <EventCard className={props.className}>{eventContent}</EventCard> : <NewEventCard className={props.className}>{eventContent}</NewEventCard>}
		</Link>
	);
};

export const EventItem = (
	props: {
		event: Event;
		performer?: string;
		forceMobileDesign?: boolean;
		target?: string;
		newDesign?: boolean;
	} & StyledComponentProps
) => {
	const venueContent = useAutoUpdateState(() => {
		let value = "";
		const { venueCity, venueState } = props.event;
		if (!!venueCity) {
			value = venueCity;
		}
		if (venueState !== undefined) {
			value += value.length ? `, ${venueState}` : venueState;
		}
		return value;
	}, [props.event.venueCity, props.event.venueState]);

	const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);

	return (
		<BaseEventItem event={props.event} to={InternalRoutes.Event(props.event.slug, props.performer ? `?performer=${props.performer}` : undefined)} className={props.className} forceMobileDesign={props.forceMobileDesign} target={props.target} newDesign={props.newDesign}>
			<MobileLayout>
				<span
					css={`
						white-space: nowrap;
						width: 100%;
						overflow: hidden;
						text-overflow: ellipsis;
						text-align: left;
					`}
				>
					{props.event.venueName}, {venueContent}
				</span>
			</MobileLayout>
		</BaseEventItem>
	);
};

const EventCard = styled.div`
	background-color: ${getAppColor("light")};
	border-bottom: 10px solid ${getAppColor("lightGrey")};
	font-family: ${FontFamily.Poppins};
	cursor: pointer;
	position: relative;
	&:hover {
		background-color: ${getAppColor("lightGrey")};
		& button {
			background-color: ${getAppColor("dark")};
			&:not(:disabled):hover {
				background-color: ${getAppColor("dark")};
			}
		}
	}
	& button {
		background-color: ${getAppColor("darkGrey", "dark")};
	}
	a {
		color: ${getAppColor("dark")};
	}
`;
const NewEventCard = styled.div`
	background-color: ${getAppColor("light")};
	height: 100%;
	font-family: ${FontFamily.Poppins};
	cursor: pointer;
	position: relative;
	border: 1px solid ${getAppColor("darkGrey", "light")};
	border-radius: 6px;
	&:hover {
		border: 1px solid ${getAppColor("dark")};
	}
`;

const EventArrow = styled.img`
	margin-left: 10px;
`;

const StyledButton = styled(Button)<{
	forceMobileDesign?: boolean;
	newDesign?: boolean;
}>`
	& > button {
		display: flex;
		align-items: center;
		${props =>
			props.newDesign &&
			`
				justify-content: space-between;
		`}
	}
	@media (max-width: ${props => (props.forceMobileDesign ? "100vw" : breakpoints.mobile)}) {
		${props =>
			!props.newDesign &&
			`
		display: none;
		`}
	}
`;

EventTable.Table = styled.div`
	text-align: left;
	display: table;
	& > ${EventTable.Row}:first-of-type ${EventTable.Cell} {
		padding-bottom: 1px;
	}
`;
