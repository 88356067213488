import React from "react";
import { FuturesListing, FuturesSale } from "../../../../model/optimizedModel/myAccount";
import { formatPrice } from "../../../util/formatPrice";
import { BaseMyAccountFuture, BaseMyAccountFutureProps } from "./BaseMyAccountFuture";
import { useMemoState } from "../../../hooks/useMemoState";
import { TitleAndContentGridItem } from "../../TitleAndContentGrid/TitleAndContentGrid";

export const MyAccountMarketFuture = <T extends FuturesListing | FuturesSale>({ additionalTitleAndContentGridItems, ...spreadableProps }: Omit<BaseMyAccountFutureProps<T>, "id">) => {
	//TODO: Consider making a hook called useAdditionalTitleAndContentGridItems that can be reused between this component and BaseMyAccountFuture.
	const titleAndContentGridItems = useMemoState(() => {
		let value: TitleAndContentGridItem[] = [
			{
				key: "listingPrice",
				title: "Listing Price",
				content: formatPrice(spreadableProps.future.listingPrice),
			},
			{
				key: "totalReturn",
				title: "Total Return",
				content: formatPrice(spreadableProps.future.listingPrice - spreadableProps.future.zone.reservationPrice),
			},
		];
		if (additionalTitleAndContentGridItems) {
			value = [...value, ...additionalTitleAndContentGridItems];
		}
		return value;
	}, [additionalTitleAndContentGridItems]);

	return <BaseMyAccountFuture<T> {...spreadableProps} id={spreadableProps.future.futureInventoryId} additionalTitleAndContentGridItems={titleAndContentGridItems} />;
};
