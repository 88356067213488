import React, { useEffect, useMemo, useState } from "react";
import { DateRangeFilter, getDateFilterText } from "../../components/Filters";
import { MultiSelectItem } from "../../components/Filters/MultiSelectFilter";
import { DISTANCE_FILTER_OPTIONS } from "../../components/Seatics/consts";
import { useSubcategories } from "../../../services/hooks";
import { Category } from "../../../model/Category";
import { Location } from "../../../model/Location";
import { SingleFilterButton } from "../Filters/SingleFilterButton";
import { useMediaQuery } from "../../hooks/useMediaQuery";
//TODO: Replace the following import with breakpoints, once the GITG content is merged into qa
import { mediaQueries } from "../../util/mediaQueries";
import { SelectFilter, SelectItem } from "../Filters/SelectFilter";
import { MultiFilterContainer } from "../Filters/MultiFilterContainer";
import { useHistory } from "react-router";
import { InternalRoutes } from "../../Link";
import { DateRange } from "../Filters/DateRangeFilter/DateRangeFilter";

export const DEFAULT_DISTANCE = DISTANCE_FILTER_OPTIONS[0];
const DEFAULT_SUBCATEGORY = {
	label: "Subcategory",
	value: "all",
};

export interface DiscoverFilterState {
	category: string | null;
	subcategory: string | null;
	from: string | null;
	to: string | null;
	distance: string | null;
}

export const DiscoverFilters = (props: { location: Location | null; filters: DiscoverFilterState; onFilterChange: (field: keyof DiscoverFilterState, value: string | null) => void; onDateChange: (date: DateRange) => void }) => {
	// Filter effects
	const concertCategories = useSubcategories("concerts");
	const sportsCategories = useSubcategories("sports");
	const theaterCategories = useSubcategories("theater");

	// TODO: find a better way to map this
	const categoryToOption = (collection: Category[]) => (): MultiSelectItem[] => {
		return collection.map(category => ({
			label: category.name,
			value: category.slug,
		}));
	};

	const concertCategoryOptions = useMemo(categoryToOption(concertCategories), [concertCategories]);
	const sportsCategoryOptions = useMemo(categoryToOption(sportsCategories), [sportsCategories]);
	const theaterCategoryOptions = useMemo(categoryToOption(theaterCategories), [theaterCategories]);

	const CATEGORY_OPTIONS = [
		{ label: "All Events", value: null, children: [] },
		{ label: "Concerts", value: "concerts", children: concertCategoryOptions },
		{ label: "Sports", value: "sports", children: sportsCategoryOptions },
		{ label: "Theater", value: "theater", children: theaterCategoryOptions },
	];

	const selectedDistance = useMemo(() => {
		const distanceOption = DISTANCE_FILTER_OPTIONS.find(x => x.value == props.filters.distance);
		return distanceOption ? distanceOption : DEFAULT_DISTANCE;
	}, [props.filters.distance]);

	const selectedCategory = useMemo(() => {
		const categoryOption = CATEGORY_OPTIONS.find(x => x.value === props.filters.category);
		return categoryOption ? categoryOption : CATEGORY_OPTIONS[0];
	}, [props.filters.category]);

	const selectedSubcategory = useMemo(() => {
		const subCategoryOption = selectedCategory.children.find(option => option.value === props.filters.subcategory);
		return subCategoryOption ? subCategoryOption : DEFAULT_SUBCATEGORY;
	}, [props.filters.subcategory, selectedCategory]);

	const isMobile = useMediaQuery(mediaQueries.max500);
	const [filterState, setFilterState] = useState<DiscoverFilterState>(props.filters);

	useEffect(() => {
		setFilterState(props.filters);
	}, [props.filters]);

	useEffect(() => {
		if (!props.location) {
			props.onFilterChange("distance", DISTANCE_FILTER_OPTIONS[0].value);
		} else {
			props.onFilterChange("distance", DISTANCE_FILTER_OPTIONS[4].value);
		}
	}, [props.location]);

	const history = useHistory();

	return (
		<MultiFilterContainer
			filters={[<DateRangeFilter from={props.filters.from} to={props.filters.to} onChange={props.onDateChange} renderAs={props => <SingleFilterButton placeholder="All Dates" text={getDateFilterText(filterState.from, filterState.to)} title={isMobile && "Date"} {...props} />} />, <SelectFilter isDisabled={!props.location} value={selectedDistance} items={DISTANCE_FILTER_OPTIONS} onChange={(item: SelectItem) => props.onFilterChange("distance", item?.value)} title={isMobile ? "Distance" : ""} />, <SelectFilter isDisabled={false} value={selectedCategory} items={CATEGORY_OPTIONS} onChange={(item: SelectItem) => props.onFilterChange("category", item?.value)} title={isMobile ? "Category" : ""} />, <SelectFilter isDisabled={!selectedCategory.value} value={selectedSubcategory} items={selectedCategory.children} onChange={(item: SelectItem) => props.onFilterChange("subcategory", item?.value)} title={isMobile ? "Subcategory" : ""} />]}
			searchParams={props.filters}
			onReset={() => {
				history.push(InternalRoutes.Discover());
			}}
		/>
	);
};
