import { ServiceCallAction } from "../../store/middleware/serviceCallMiddleware";
import { CoverAssetsCodec, CoverAssets, GlobalAssetsDecoder, GlobalAssetsResponse } from "../types/AssetResponse";

export const getPerformerAssets = (performerSlug: string): ServiceCallAction<CoverAssets> => ({
	method: "GET",
	endpoint: `assets/performers/${performerSlug}`,
	serviceKey: "getPerformerAssets",
	responseDecoder: CoverAssetsCodec,
	type: "SERVICE_CALL",
});

export const getCategoryAssets = (categorySlug: string): ServiceCallAction<CoverAssets> => ({
	method: "GET",
	endpoint: `assets/categories/${categorySlug}`,
	serviceKey: "getPerformerAssets",
	responseDecoder: CoverAssetsCodec,
	type: "SERVICE_CALL",
});

export const getGlobalAssets = (): ServiceCallAction<GlobalAssetsResponse> => ({
	method: "GET",
	endpoint: `assets/globals`,
	serviceKey: "getGlobalAssets",
	responseDecoder: GlobalAssetsDecoder,
	type: "SERVICE_CALL",
});
