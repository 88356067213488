import { ServiceCallAction } from "../../store/middleware/serviceCallMiddleware";
import { GolfSubscriptionCodec, GolfSubscriptionResponse } from "../types/GolfSubscriptionCodec";

export const golfSuscriptionService = (email: string, firstName?: string, lastName?: string): ServiceCallAction<GolfSubscriptionResponse> => ({
	method: "POST",
	endpoint: "subscribe/golf-classic",
	body: { firstName, lastName, email },
	serviceKey: "postSubscribe",
	responseDecoder: GolfSubscriptionCodec,
	type: "SERVICE_CALL",
});
