import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getSiteStatus } from "../../services/siteStatus";
import { updateSiteStatus } from "../../store/actions/siteStatusActions";
import { Redirect } from "react-router-dom";
import { InternalRoutes } from "../Link";
import { DisabledRoute } from "../../model/Status";
import { Optional } from "../../model/Utils";

const SITE_STATUS_UPDATE_INTERVAL_MS = 1000 * 60;

/**
 * HOC to detect navigation availability, and redirect to maintenance page if needed.
 * @param Component routes
 */

export function withMaintenanceRedirection(Child: React.ComponentType<RouteComponentProps>) {
	return withRouter((props: RouteComponentProps): React.ReactElement<RouteComponentProps> => {
		const dispatch = useDispatch();
		const refreshSiteStatus = useCallback(async () => {
			const siteStatus = await getSiteStatus();
			if (siteStatus.success) {
				dispatch(updateSiteStatus(siteStatus.data));
			}
		}, [dispatch]);

		useEffect(() => {
			//first time
			refreshSiteStatus();

			//Update interval
			const intervalId = setInterval(async () => {
				refreshSiteStatus();
			}, SITE_STATUS_UPDATE_INTERVAL_MS);

			return () => {
				clearInterval(intervalId);
			};
		}, [updateSiteStatus]);

		const disabledRoutes = useSelector(state => state.persistent.siteStatus);
		const currentRouteDisabled = isRouteDisabled(disabledRoutes, props.location.pathname + props.location.search);

		if (currentRouteDisabled) {
			return <Redirect to={InternalRoutes.Maintenance(props.location.pathname + props.location.search)} />;
		}

		return <Child {...props} />;
	});
}

const isRouteDisabled = (disabledRoutes: DisabledRoute[], route: string) => {
	return disabledRoutes.some(disabledRoute => !disabledRoute.enabled && route.startsWith(disabledRoute.route));
};

export const getDisabledRoute = (disabledRoutes: DisabledRoute[], route: Optional<string>) => {
	if (route) {
		return disabledRoutes.find(disabledRoute => !disabledRoute.enabled && route.startsWith(disabledRoute.route));
	}
	return null;
};
