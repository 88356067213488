import React, { useState } from "react";
import { Spinner } from "../Loader/Spinner";
import styled from "styled-components/macro";
import { mediaQueries } from "../../util/mediaQueries";
import { getAppColor } from "../../util/appColors";
import { Typography } from "../Typography/Typography";
import { breakpoints } from "../../util/breakpoints";
import { Button } from "../NewButton/Button";
import { Form, Field } from "react-final-form";
import { RFFInput } from "../Input/Input";
import { required, validEmail } from "../../util/optimized/finalFormUtil";
import { InternalRoutes } from "../../Link";
import { maxContentWidth, maxContentWidthPadding } from "../../util/maxContentWidth";
import { FontFamily } from "../Typography/Typography";
import { postNewsletterSubscribe } from "../../../services/newsletter";
import toast, { Toaster } from "react-hot-toast";
import { useToastRemoval } from "../../hooks/useToastRemoval";
import { FormApi } from "final-form";

type NewsLetterState = {
	email: string;
};

export const Newsletter = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [showNewsletter, setShowNewsletter] = useState(true);

	const onSubmit = async (values: NewsLetterState, form: FormApi<NewsLetterState, NewsLetterState>) => {
		setIsLoading(true);
		await postNewsletterSubscribe(values.email)
			.then(() => {
				setShowNewsletter(!showNewsletter);
			})
			.catch(err => {
				if (err.error.suggest) {
					toast.error(err.error.message);
					form.getState().errors.email = `Did you mean: ${err.error.suggest}`;
				} else if (err.error.message) {
					toast.error(err.error.message);
				} else {
					toast.error("There was an error processing your request");
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useToastRemoval();

	return (
		<>
			{showNewsletter ? (
				<NewsletterContainer>
					<Toaster position="top-center" />
					<NewsletterInner>
						<JoinMailingListText>Join our Mailing list for exclusive access and promotions</JoinMailingListText>
						<Form
							onSubmit={onSubmit}
							initialValues={{ email: "" }}
							render={form => (
								<form onSubmit={form.handleSubmit}>
									<FormWrapper>
										<FormContainer>
											<Field name="email" component={StyledRFFInput} type="email" placeholder="Enter Your Email" validate={value => required(value) || validEmail(value)} />
											{isLoading ? (
												<SpinnerContainer>
													<Spinner />
												</SpinnerContainer>
											) : (
												<SubscribeButton color="primary" type="submit" name="subscribe">
													Subscribe
												</SubscribeButton>
											)}
										</FormContainer>
										<MarketingConsentText type="bodyTiny">
											By clicking subscribe you agree to SI Tickets&nbsp;
											<a href={InternalRoutes.Terms} target="_blank" rel="noopener noreferrer">
												Terms of Use
											</a>
											&nbsp; and&nbsp;
											<a href={InternalRoutes.Privacy} target="_blank" rel="noopener noreferrer">
												Privacy Policy.
											</a>
										</MarketingConsentText>
									</FormWrapper>
								</form>
							)}
						/>
					</NewsletterInner>
				</NewsletterContainer>
			) : (
				<SuccessfulSubscription>Thanks for submitting your email! Look for great events in your inbox soon.</SuccessfulSubscription>
			)}
		</>
	);
};

export const SuccessfulSubscription = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	margin: 0 auto;
	background-color: #050f1a;
	color: #fff;
	font-family: "SolanoGothicMVB-Bd" !important;
	font-size: 2rem;
	padding: 50px ${maxContentWidthPadding}px;
	box-sizing: border-box;
`;

const JoinMailingListText = styled.p`
	font-family: SolanoGothicMVB-Bd !important;
	font-style: normal;
	font-weight: 700;
	font-size: 40px;
	line-height: 100%;
	text-align: left;
	text-transform: uppercase;
	color: ${getAppColor("dark", "main")};
	max-width: 30ch;
	margin-right: 35px;

	@media ${mediaQueries.max1000} {
		margin-bottom: 10px;
		margin-right: initial;
	}

	@media (max-width: ${breakpoints.mobile}) {
		max-width: 24ch;
		font-size: 30px;
	}
`;

export const SpinnerContainer = styled.div`
	width: 161px;
	-webkit-appearance: none;
	background: ${getAppColor("primary")};
	height: 50px;
	display: flex;
	align-items: center;

	@media ${mediaQueries.max500} {
		width: 100% !important;
		max-width: initial;
	}
`;

const FormContainer = styled.div`
	display: flex;
	width: calc(300px + 15vw);
	input {
		margin-right: 20px;
	}

	@media (max-width: ${breakpoints.tablet}) {
		width: 100%;
		flex-direction: column;
		margin-right: initial;
	}
`;

const MarketingConsentText = styled(Typography)`
	text-align: left;
	max-width: 500px;

	@media (max-width: ${breakpoints.tablet}) {
		margin-top: 20px;
	}
`;

const NewsletterContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	background-color: ${getAppColor("lightGrey", "light")};
`;

const NewsletterInner = styled.div`
	${maxContentWidth};
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 28px 0 !important;
	@media ${mediaQueries.max1000} {
		flex-direction: column;
		padding: initial;
		align-items: stretch;
		padding: 28px 20px !important;
	}
`;

const SubscribeButton = styled(Button)`
	width: 161px;
	height: 50px;
	font-size: 20px;

	& > button {
		padding: 0 !important;
	}

	@media (max-width: ${breakpoints.tablet}) {
		width: 100% !important;
		max-width: initial;
	}

	@media (max-width: ${breakpoints.mobile}) {
		font-size: 18px;
	}
`;

const StyledRFFInput = styled(RFFInput)`
	border: 1px solid ${getAppColor("lightGrey", "dark")};
	border-radius: 3px;
	height: 50px;
	padding-left: 10px !important;
	-webkit-appearance: none;
	background: ${getAppColor("light")};
	font-family: ${FontFamily.Poppins};
	margin-right: 40px;
	width: 326px !important;
	max-width: 30vw;
	font-size: 18px;

	&:hover {
		box-shadow: none;
	}

	@media (max-width: ${breakpoints.tablet}) {
		width: 100% !important;
		max-width: initial;
	}
`;

const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	@media (max-width: ${breakpoints.tablet}) {
		align-items: center;
	}
`;
