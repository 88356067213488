import React, { useState } from "react";
import { CitySearch } from "../Search/LocationSearch";
import { SearchState } from "../Search/SearchState";
import { renderLocation } from "../../../model/Location";
import localEventsLocation from "../../resource/image/local-events-location.svg";
import penIcon from "../../resource/assets/icons/pen_icon.svg";
import styled from "styled-components/macro";
import { getTypographyStyles } from "../Typography/Typography";
import { getAppColor } from "../../util/appColors";
import { breakpoints } from "../../util/breakpoints";
import { useSelector } from "react-redux";

export const LocationPicker = ({ newDesign }: { newDesign?: boolean }) => {
	const [showLocationSelector, setShowLocationSelector] = useState(false);
	const location = useSelector(state => state.persistent.location);

	return (
		<>
			<LocationPickerContainer>
				{newDesign ? (
					<div
						css={`
							display: flex;
							align-items: flex-end;
						`}
					>
						<NewLocationSubtitle>{location ? renderLocation(location) : "Add Your Location"}</NewLocationSubtitle>
						<PenIcon src={penIcon} alt="Pen" onClick={() => setShowLocationSelector(true)} />
					</div>
				) : (
					<>
						<Pin src={localEventsLocation} alt="Pin" />
						<LocationSubtitle onClick={() => setShowLocationSelector(true)}>{location ? renderLocation(location) : "Add Your Location"}</LocationSubtitle>
					</>
				)}
			</LocationPickerContainer>
			{showLocationSelector && (
				<CitySearch
					openDialog={showLocationSelector}
					location={location}
					searchState={SearchState.Location}
					setSearchState={next => {
						if (next === SearchState.None) {
							setShowLocationSelector(false);
						}
					}}
				/>
			)}
		</>
	);
};

const LocationSubtitle = styled.div`
	${getTypographyStyles("heading2", {
		color: "primary",
	})}
	display: flex;
	align-items: center;
	cursor: pointer;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: ${getAppColor("primary")};
	border-bottom: 3px solid ${getAppColor("primary")};
`;

const NewLocationSubtitle = styled.div`
	${getTypographyStyles("bodyNormal", {
		color: "darkGrey",
		colorType: "dark",
		fontWeight: 600,
	})};
`;
LocationPicker.NewLocationSubtitle = NewLocationSubtitle;

const Pin = styled.img`
	height: 29px;
	width: 14px;
	align-self: center;
	padding-left: 16px;
	border-left: 1px solid ${getAppColor("dark")};
	margin-right: 16px;

	@media (max-width: ${breakpoints.tablet}) {
		padding-left: 8px;
		margin-right: 8px;
	}

	@media (max-width: ${breakpoints.mobile}) {
		border-left: none;
		flex-direction: column;
		padding-left: 0;
		margin-right: 8px;
	}
`;

const LocationPickerContainer = styled.div`
	display: flex;
`;

const PenIcon = styled.img`
	height: 31px;
	width: 15px;
	margin-left: 10px;
	cursor: pointer;
`;
