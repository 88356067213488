import React from "react";
import styled from "styled-components/macro";
import { getAppColor } from "../../util/appColors";
import closeButton from "../../resource/image/dialog-close-white.svg";

export const VfsImageWindow = (props: { src?: string; onClose: () => void }) => {
	return (
		<>
			<Overlay />
			<Content>
				<ImageContainer>
					<CloseButtonContainer onClick={props.onClose}>
						<img src={closeButton} alt="Close Button" />
					</CloseButtonContainer>
					<VfsImage src={props.src} alt="vfs image" />
				</ImageContainer>
			</Content>
		</>
	);
};

const FullScreenContainer = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
`;

const Overlay = styled(FullScreenContainer)`
	background: ${getAppColor("dark")};
	opacity: 0.7;
	//Once we replace the navigation component the following z-index might not be required. It's just added to be higher than the one from the legacy bar
	z-index: 110;
`;

const Content = styled(FullScreenContainer)`
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 111;
`;

const ImageContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 58vw;
`;

const borderSize = "10px";
const VfsImage = styled.img`
	width: 100%;
	height: 100%;
	border: ${borderSize} solid ${getAppColor("dark")};
`;

const CloseButtonContainer = styled.div`
	width: calc(100% + ${borderSize});
	display: flex;
	justify-content: flex-end;
	cursor: pointer;
	color: ${getAppColor("light")};
	margin-bottom: 5px;
`;
