import React from "react";
import { CommonPage } from "../components";
import styled from "styled-components/macro";
import { getAppColor } from "../util/appColors";
import { FontFamily } from "../components/Typography/Typography";
import { breakpoints } from "../util/breakpoints";
import { Config } from "../../Config";

const grandPrixFolder = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/grandprixLP`);
const laptopBreakpoint = "1220px";

export const GrandPrixRoute = () => {
	return (
		<CommonPage background="black">
			<PageContainer>
				<Section>
					<TopLogo src={`${grandPrixFolder}/Logo.png`} alt="logo" />
					<HeroImage src={`${grandPrixFolder}/Main-Image.png`} alt="main-image" />
					<ImageSubtitle>
						This unmatched luxury sporting event will light up the streets of Las Vegas on <BoldText>November 16-18</BoldText>
					</ImageSubtitle>
				</Section>
				<Section>
					<CLUBSiLogo src={`${grandPrixFolder}/Club-SI3.png`} alt="logo club si" />

					<Subtitle>
						NEXT TO FLAMINGO LAS VEGAS HOTEL & CASINO <br />
						NOVEMBER 16-18 <br />
						PREMIUM ALL INCLUSIVE TICKETS
					</Subtitle>

					<p css={`font-weight: bold; text-align:left; color: #fff;font-size: 20px;`}>Schedule of Club SI Events:</p>

					<TermText>
						Sports Illustrated’s Club SI, an immersive three-day experience, provides unmatched views of the high-speed action down Las Vegas Boulevard. The ticket includes a full slate of programming with highlights ranging from celebrity guest appearances to popular DJ performances, panels and more. Hospitality offerings include two exclusive VIP areas, passed hors d’oeuvres, food stations, and premium cocktails.
					</TermText>

					<DayTitle>Thursday, Nov. 16: Legends Panel</DayTitle>
					<TermText>
						Kicking-off Race Weekend, the Club SI Legends Panel will feature top personalities in conversation about the intersection of sports and entertainment. Fans will have the opportunity to interact and ask live questions to panel members.
					</TermText>

					<DayTitle>Friday, Nov. 17: Sports Illustrated Swimsuit Party</DayTitle>
					<TermText>
						Catch Friday night’s race alongside top talent featured in the 2023 Swimsuit Issue as they host the Sports Illustrated Swimsuit Party at Club SI. Festivities will include special meet-and-greets, Sports Illustrated Swimsuit-inspired cocktails, interactive photo moments and much more.
					</TermText>

					<DayTitle>Saturday, Nov. 18: The Big Race</DayTitle>
					<TermText>
						Celebrate the inaugural FORMULA 1 HEINEKEN SILVER LAS VEGAS GRAND PRIX at Club SI with celebrity hosts. Fans can watch cars race down the iconic Las Vegas Strip at over 200 mph while enjoying food, drinks and fun with fan activations, including a photo booth with printouts to commemorate the incredible night.
					</TermText>

					<p css={`font-weight: bold; text-align:left; color: #fff;font-size: 20px;`}>Ticket permits access to Sports Illustrated's Club SI only.</p>
				</Section>
				<Section
					css={`
						border: #e10600 solid 1px;
						padding: 1em 5em;

						@media (max-width: ${breakpoints.tablet}) {
							padding-top: 2em;
						}
					`}
				>
					<TwoColumnDiv>
						<LeftColumn
							css={`
								align-self: left;
								text-align: left;
							`}
						>
							<StyledList>
								<ListItem>3-day ticket, Thursday - Saturday</ListItem>
								<ListItem>Access to Club SI, a shared hospitality space</ListItem>
								<ListItem>All-inclusive food and beverage menu, including beer, wine and spirits</ListItem>
							</StyledList>
							<ButtonWrapper href="https://sitickets.com/event/sports-illustrated-club-si-formula-1-las-vegas">
								<ButtonCTA>Buy Tickets</ButtonCTA>
							</ButtonWrapper>
						</LeftColumn>
						<RightColumn>
							<StyledImage src={`${grandPrixFolder}/Club-SI-Logo.png`} alt="clubsi" />
						</RightColumn>
					</TwoColumnDiv>

					<TwoColumnDiv>
						<LeftColumn
							css={`
								align-self: left;
								text-align: left;
							`}
						>
							<HeaderImage src={`${grandPrixFolder}/Club-SI1.png`} alt="header" />
						</LeftColumn>
						<RightColumn>
							<HeaderImage src={`${grandPrixFolder}/Club-SI2.png`} alt="header" />
						</RightColumn>
					</TwoColumnDiv>
					<ButtonWrapper href="mailto:vip@sitickets.com">
						<ButtonCTA>Inquire about vip experiences</ButtonCTA>
					</ButtonWrapper>
				</Section>
			</PageContainer>
		</CommonPage>
	);
};

const ButtonWrapper = styled.a`
	text-decoration: none;
	cursor: pointer;
	text-align: left;
`;

const TopLogo = styled.img`
	max-height: 71px;
	max-width: 425px;
	margin-bottom: 55px;
	align-self: center;

	@media (max-width: ${breakpoints.tablet}) {
		max-width: 400px;
		margin-bottom: 45px;
	}

	@media (max-width: ${breakpoints.mobile}) {
		max-width: 299px;
		height: auto;
		margin-bottom: 28px;
	}
`;

const StyledImage = styled.img`
	max-width: 1068px;
	margin-bottom: 20px;
	
	@media (max-width: ${breakpoints.tablet}) {
		max-width: 400px;
		margin-bottom: 45px;
	}

	@media (max-width: ${breakpoints.mobile}) {
		max-width: 299px;
		height: auto;
		margin-bottom: 28px;
	}
`;

const HeroImage = styled.img`
	max-height: 630px;
	max-width: 1068px;
	margin-bottom: 20px;
	align-self: center;

	@media (max-width: ${breakpoints.tablet}) {
		max-width: 400px;
		margin-bottom: 45px;
	}

	@media (max-width: ${breakpoints.mobile}) {
		max-width: 299px;
		height: 204px;
		margin-bottom: 28px;
	}
`;

const ImageSubtitle = styled.h1`
	font-size: 25px;
	font-family: ${FontFamily.Formula};
	tracking: 36px;
	line-height: 38px;
	width: 750px;
	color: ${getAppColor("light")};
	text-align: center;
	align-self: center;

	@media (max-width: ${breakpoints.tablet}) {
		font-size: 28px;
		line-height: 28px;
		text-align: center;
	}

	@media (max-width: ${breakpoints.mobile}) {
		width: 337px;
		font-size: 18px;
		line-height: 18px;
	}
`;

const BoldText = styled.span`
	font-weight: 900;
`;

const Title = styled.h2`
	font-weight: 900;
	font-size: 64px;
	font-family: ${FontFamily.Formula};
	color: #e10600;
	text-align: left;

	@media (max-width: ${breakpoints.tablet}) {
		font-size: 24px;	
		text-align: center;
	}

	@media (max-width: ${breakpoints.mobile}) {
		font-size: 24px;
		margin-top: 2em;
	}
`;

const DayTitle = styled.h3`
	font-size: 16px;
	font-weight: 700;
	font-family: ${FontFamily.Formula};
	tracking: 0px;
	color: #d8d8d8;
	text-align: left;
	align-self: left;

	@media (max-width: ${breakpoints.tablet}) {
		font-size: 28px;
		line-height: 28px;
		margin-bottom: 10px;
	}

	@media (max-width: ${breakpoints.mobile}) {
		font-size: 18px;
		line-height: 18px;
		margin-bottom: 10px;	
	}
`;

const Subtitle = styled.h3`
	font-size: 20px;
	font-weight: 700;
	font-family: ${FontFamily.Formula};
	tracking: 0px;
	font-style: bold;
	color: #d8d8d8;
	text-align: left;
	align-self: left;
	margin-top: 10px;
	margin-bottom: 20px;

	@media (max-width: ${breakpoints.tablet}) {
		font-size: 28px;
		line-height: 28px;
		text-align: center;
	}

	@media (max-width: ${breakpoints.mobile}) {
		max-width: 337px;
		font-size: 18px;
		line-height: 18px;
	}
`;

const TermText = styled.p`
	font-size: 16px;
	font-family: ${FontFamily.Titilium};
	tracking: 0px;
	color: #ffffff;
	line-height: 25px;
	text-align: left;
	align-self: left;
	margin-bottom: 10px;

	@media (max-width: ${breakpoints.tablet}) {
		font-size: 16px;
		text-align: left;
	}

	@media (max-width: ${breakpoints.mobile}) {
		font-size: 16px;
	}
`;

const Section = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 40px;

	@media (max-width: ${breakpoints.tablet}) {
		align-items: center;
		margin-bottom: 50px;
	}

	@media (max-width: ${breakpoints.mobile}) {
		margin-bottom: 28px;
	}
`;

const ButtonVip = styled.img`
	width: 375px;

	@media (max-width: ${breakpoints.tablet}) {
		width: 300px;
	}

	@media (max-width: ${breakpoints.mobile}) {
		width: 300px;
	}
`;

const ButtonCTA = styled.button`
  background: #e11400; 
  color: #ffffff;
  font-size: 14px;	
  border: 2px solid #000;
  text-transform: uppercase;
  font-family: ${FontFamily.Formula};
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-out;  
  width: 375px;
  height: 40px;
  
  &:hover {
	opacity: 0.8;
  }

  @media (max-width: ${breakpoints.tablet}) {
	width: 100%;
	max-width: 300px;
}

@media (max-width: ${breakpoints.mobile}) {
	width: 300px;
}
`;

const CLUBSiLogo = styled.img`
	max-height: 350px;
	max-width: 512px;

	@media (max-width: 1350px) {
		max-width: 400px;
	}

	@media (max-width: ${breakpoints.tablet}) {
		max-width: 400px;
		margin-bottom: 20px;
	}

	@media (max-width: ${breakpoints.mobile}) {
		max-width: 299px;
		margin-bottom: 20px;
	}
`;

const HeaderImage = styled.img`
	max-height: 350px;
	max-width: 512px;
	margin-bottom: 40px;

	@media (max-width: 1350px) {
		max-width: 400px;
	}

	@media (max-width: ${breakpoints.tablet}) {
		max-width: 400px;
		margin-bottom: 30px;
	}

	@media (max-width: ${breakpoints.mobile}) {
		max-width: 299px;
		margin-bottom: 20px;
	}
`;

const PageContainer = styled.div`
	position: relative;
	padding: 50px 80px 0;
	display: flex;
	flex-direction: column;
	z-index: 0;

	@media (max-width: ${laptopBreakpoint}) {
		padding: 80px 80px 0px 80px;
	}

	@media (max-width: ${breakpoints.tablet}) {
		padding: 60px 20px 0;
	}

	@media (max-width: ${breakpoints.mobile}) {
		padding: 60px 19px 0;
	}
`;

const BackgroundImageTop = styled.img`
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	z-index: -1;
`;

const BackgroundImageBottom = styled.img`
	position: absolute;
	width: 100%;
	top: 50vh;
	left: 0;
	z-index: -1;
`;

const StyledList = styled.ul`
	list-style-image: url("${grandPrixFolder}/arrows.png");
	text-align: left;
	font-size: 17px;
	font-family: ${FontFamily.Titilium};
	line-height: 25px;
	color: #ffffff;

	@media (max-width: ${breakpoints.tablet}) {
		padding: 0 20px;
	}
`;

const ListItem = styled.li`
	margin-bottom: 12px;
`;

const TwoColumnDiv = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px;

	@media (max-width: ${breakpoints.tablet}) {
		grid-template-columns: 1fr;
		gap: 0;
	}

	@media (max-width: ${breakpoints.mobile}) {
		grid-template-columns: 1fr;
		gap: 0;
	}
`;

const LeftColumn = styled.div`
	padding: 20px;

	@media (max-width: ${breakpoints.tablet}) {
		text-align: center;
		padding-top: 5px;
	}
`;

const RightColumn = styled.div`
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: ${breakpoints.tablet}) {
		padding-top: 5px;
	}
`;
