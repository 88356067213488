import React, { useCallback, useState } from "react";
import styled, { keyframes } from "styled-components/macro";
import { ReactComponent as Arrow } from "../../resource/image/ticket-group-arrow.svg";
import { BackArrow } from "../BackArrow/BackArrow";
import { maxContentWidthPadding } from "../../util/maxContentWidth";
import { CommonPage } from "../CommonPage";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { NewSeats, NewSeatsMobile, NewSeatsProps } from "../Event/NewSeatsHeader";
import { useMemoState } from "../../hooks/useMemoState";
import { EventCheckoutHeader, eventMobileHeaderHeight, mobileHeaderHeight } from "../Event/EventCheckoutHeader";
import { useHistory } from "react-router-dom";
import { SmallButton } from "../Button/Button";
import { shortFormatPrice } from "../../util/formatPrice";
import { isMobileApp } from "../../util/isMobileApp";
import { EducationalHeader, EducationalSection } from "../Futures/EducationalPanel";
import { listingsPageMobileWidth } from "../../util/listingsPageMobileWidth";
import { useHeaderHeight } from "../../hooks/useHeaderHeight";
import { getAppColor } from "../../util/appColors";
import { FullScreenLoader } from "../Loader";

interface Item<T> {
	title: string;
	subtitle: React.ReactNode;
	priceSubtitle?: string;
	price: number;
	data: T;
	//TODO: Consider finding a better way to do this.
	defaultQuantity: number;
	additonalItemContent?: React.ReactNode;
}

type ItemAction<T> = (item: Item<T>) => void;

type UnpinTopPanelContentOption = "mobile" | "desktop";

type UnpinTopPanelContent = Set<UnpinTopPanelContentOption>;

type AddToCartProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

interface SelectedItemLayoutData {
	title: React.ReactNode;
	subtitle?: React.ReactNode;
	additionalInfo?: React.ReactNode;
	price: number;
	bottomContent?: React.ReactNode | ((AddToCartButton: React.FC<AddToCartProps>) => React.ReactNode);
	quantityOptions: number[];
	topMobileContent?: React.ReactNode;
}

const selectedItemZIndex = 2;

//TODO: Consider taking the quantity handling logic meant for futures outside of this component.
export const ListingsPage = <T,>(
	props: React.PropsWithChildren<{
		map?: React.ReactNode;
		topPanelContent?: React.ReactNode;
		items: Item<T>[] | undefined;
		onItemMouseOver?: ItemAction<T>;
		onItemClick?: ItemAction<T>;
		loading?: boolean | undefined;
		newSeatsProps?: NewSeatsProps;
		hideTopMobileMessage?: boolean;
		unpinTopPanelContent?: UnpinTopPanelContentOption[];
		onAddToCartClick?: (item: T, selectedQuantity: number) => void;
		selectedItemLayoutData: SelectedItemLayoutData | ((item: T, selectedQuantity: number) => SelectedItemLayoutData);
		onPageInfoClick?: () => void;
		showEducational?: boolean;
		onHideEducationalClick?: () => void;
		fullscreenMobileSelectedItem?: boolean;
	}>
) => {
	const history = useHistory();

	const [selectedQuantity, setSelectedQuantity] = useState<number | null>();

	const [selectedItem, setSelectedItem] = useState<Item<T> | null>();
	const [ticketAddedToCart, setTicketAddedToCart] = useState(false);

	const isMobile = useMediaQuery("(max-width: " + listingsPageMobileWidth + ")");

	const headerHeight = useHeaderHeight();

	const unpinTopPanelContent = useMemoState(() => {
		const value: UnpinTopPanelContent = new Set(props.unpinTopPanelContent);
		return value;
	}, [props.unpinTopPanelContent]);

	const newSeatsProps = useMemoState(() => {
		if (props.newSeatsProps) {
			const value: NewSeatsProps = {
				...props.newSeatsProps,
				location: props.newSeatsProps.location ?? "TBD",
				venue: props.newSeatsProps.venue || "",
			};
			return value;
		}
	}, [props.newSeatsProps]);

	const clearSelectedItem = useCallback(() => {
		setSelectedItem(null);
		setSelectedQuantity(null);
	}, [setSelectedItem, setSelectedQuantity]);

	return (
		<CommonPage isFooterHidden navOverridesMaxContentWidth hideAllNavigation={false} center={false} hideHeader={isMobile} headerChildren={newSeatsProps && <NewSeats {...newSeatsProps} />}>
			<Layout>
				<FullScreenLoader show={!!props.loading} />
				{props.children}
				{isMobile && (
					<>
						{
							//TODO: Consider adding a message const that can be reused to add this message.
							!props.hideTopMobileMessage && <MobileTopMessage>$10 Flat Service Charge. 100% Refund if event is cancelled</MobileTopMessage>
						}
						{/*TODO: Consider putting EventCheckoutHeader directly in this file once the TG listings page uses this component (ListingsPage). It looks like this will be the only instance of this component once TG listings is integrated with ListingsPage.*/}
						<EventCheckoutHeader
							onBack={() => {
								if (selectedItem) {
									clearSelectedItem();
								} else if (props.showEducational) {
									props.onHideEducationalClick && props.onHideEducationalClick();
								} else if (ticketAddedToCart) {
									history.go(-2);
								} else {
									history.go(-1);
								}
							}}
							onPageInfoClick={props.onPageInfoClick}
							showPageInfo={!!props.onPageInfoClick}
							style={
								props.fullscreenMobileSelectedItem
									? {}
									: {
											zIndex: selectedItemZIndex + 1,
									  }
							}
						>
							{newSeatsProps && <NewSeatsMobile {...newSeatsProps} />}
						</EventCheckoutHeader>
					</>
				)}
				<Content>
					<Panel>
						{props.showEducational && (
							<SelectedItem
								style={{
									...(isMobileApp &&
										isMobile && {
											bottom: headerHeight,
										}),
									...(!props.fullscreenMobileSelectedItem &&
										isMobile && {
											top: props.hideTopMobileMessage ? mobileHeaderHeight : eventMobileHeaderHeight,
										}),
								}}
							>
								{!isMobile && (
									<>
										<SimpleBackArrowButtonWrapper
											onClick={() => {
												props.onHideEducationalClick && props.onHideEducationalClick();
											}}
										>
											<BackArrow />
										</SimpleBackArrowButtonWrapper>
										<EducationalHeader />
									</>
								)}
								<EducationalSection fullwidth={true} />
							</SelectedItem>
						)}
						{selectedItem &&
							!props.showEducational &&
							(() => {
								let selectedItemLayoutData = props.selectedItemLayoutData;
								if (selectedQuantity === undefined || selectedQuantity === null) {
									setSelectedQuantity(selectedItem.defaultQuantity);
								}
								if (typeof selectedItemLayoutData === "function") {
									selectedItemLayoutData = selectedItemLayoutData(selectedItem.data, selectedQuantity!);
								}
								const lastQuantitiyOption = selectedItemLayoutData.quantityOptions[selectedItemLayoutData.quantityOptions.length - 1];
								if (selectedQuantity! > lastQuantitiyOption) {
									setSelectedQuantity(lastQuantitiyOption);
								}
								const showTopMobileContent = isMobile && selectedItemLayoutData.topMobileContent;
								const hasQuantityOptions = !!selectedItemLayoutData.quantityOptions.length;
								return (
									<SelectedItem
										style={{
											...(isMobileApp &&
												isMobile && {
													bottom: headerHeight,
												}),
											...(!props.fullscreenMobileSelectedItem &&
												isMobile && {
													top: props.hideTopMobileMessage ? mobileHeaderHeight : eventMobileHeaderHeight,
												}),
										}}
									>
										{showTopMobileContent && (
											<div>
												{props.fullscreenMobileSelectedItem && (
													<BackArrowButtonWrapper
														onClick={() => {
															clearSelectedItem();
														}}
													>
														<BackArrow />
													</BackArrowButtonWrapper>
												)}
												{selectedItemLayoutData.topMobileContent}
											</div>
										)}
										<SelectedItemWrapper>
											<SelectedItemHeader>
												{!showTopMobileContent && (
													<SelectedItemHeaderBackArrow
														onClick={() => {
															clearSelectedItem();
														}}
													/>
												)}
												<SelectedItemTitleWrapper>
													<SelectedItemTitle>{selectedItemLayoutData.title}</SelectedItemTitle>
													{selectedItemLayoutData.subtitle && <SelectedItemSubtitle>{selectedItemLayoutData.subtitle}</SelectedItemSubtitle>}
												</SelectedItemTitleWrapper>
												<SelectedItemPrice>{shortFormatPrice(selectedItemLayoutData.price)}/ea</SelectedItemPrice>
											</SelectedItemHeader>
											{selectedItemLayoutData.additionalInfo}
											<SelectedItemQuantity>
												<label htmlFor="quantity">Quantity:</label>
												<select
													id="quantity"
													disabled={!hasQuantityOptions}
													value={selectedQuantity!}
													onChange={event => {
														setSelectedQuantity(Number(event.target.value));
													}}
												>
													{hasQuantityOptions ? (
														selectedItemLayoutData.quantityOptions.map((quantity, index) => {
															return (
																<option key={index} value={quantity}>
																	{quantity}
																</option>
															);
														})
													) : (
														<option value={selectedQuantity!}>{selectedQuantity}</option>
													)}
												</select>
											</SelectedItemQuantity>
											{selectedItemLayoutData.bottomContent !== undefined && (
												<SelectedItemBottomContent>
													{typeof selectedItemLayoutData.bottomContent === "function"
														? selectedItemLayoutData.bottomContent((addToCartProps: AddToCartProps) => {
																return (
																	<AddToCart
																		{...addToCartProps}
																		onClick={event => {
																			setTicketAddedToCart(true);
																			if (addToCartProps.onClick) {
																				addToCartProps.onClick(event);
																			}
																			if (props.onAddToCartClick && selectedQuantity) {
																				//TODO: Consider implementing an improved parameter structure.
																				props.onAddToCartClick(selectedItem.data, selectedQuantity);
																			}
																		}}
																	>
																		Add To Cart
																	</AddToCart>
																);
														  })
														: selectedItemLayoutData.bottomContent}
												</SelectedItemBottomContent>
											)}
										</SelectedItemWrapper>
									</SelectedItem>
								);
							})()}
						<PanelContent unpinTopPanelContent={unpinTopPanelContent}>
							{props.topPanelContent && <TopPanelContent>{props.topPanelContent}</TopPanelContent>}
							<PanelBody
								style={{
									...(isMobile &&
										isMobileApp && {
											paddingBottom: headerHeight,
										}),
								}}
								unpinTopPanelContent={unpinTopPanelContent}
							>
								<PanelBodyContent>
									{props.items?.map((item, index) => {
										return (
											<ListingItem
												key={index}
												onClick={() => {
													setSelectedItem(item);
													if (props.onItemClick) {
														props.onItemClick(item);
													}
												}}
												onMouseOver={() => {
													if (props.onItemMouseOver) {
														props.onItemMouseOver(item);
													}
												}}
											>
												<ItemContent>
													<ItemInfo>
														<ItemTitle>{item.title}</ItemTitle>
														<ItemSubtitle>{item.subtitle}</ItemSubtitle>
													</ItemInfo>
													{item.additonalItemContent}
													<ItemPrice>
														{!!item.priceSubtitle && <ItemPriceSubtitle>{item.priceSubtitle}</ItemPriceSubtitle>}
														<ItemPriceContent>
															<strong>{shortFormatPrice(item.price)}</strong>/ea
														</ItemPriceContent>
													</ItemPrice>
												</ItemContent>
												<Arrow />
											</ListingItem>
										);
									})}
								</PanelBodyContent>
							</PanelBody>
						</PanelContent>
					</Panel>
					{props.map && <MapContainer>{props.map}</MapContainer>}
				</Content>
			</Layout>
		</CommonPage>
	);
};

const fadeInRTL = keyframes`
  from {
    opacity: 0;
    transform: translateX(-20%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const SelectedItemPrice = styled.span`
	font-weight: bold;
	font-size: 18px;
`;

const SelectedItemHeaderBackArrow = styled(BackArrow)`
	margin: auto 0;
	margin-right: 20px;
`;

const backArrowButtonWrapperSpacing = maxContentWidthPadding * 0.5 + "px";

const BackArrowButtonWrapper = styled.button`
	left: ${backArrowButtonWrapperSpacing};
	top: ${backArrowButtonWrapperSpacing};
	cursor: pointer;
	padding: 15px;
	border: 1px solid lightgray;
	height: 35px;
	width: 35px;
	background: #fafafa;
	display: flex;
	padding: 0;
	align-items: center;
	justify-content: center;
	border-radius: 1000px;
	outline: none;
	position: absolute;
`;

const SimpleBackArrowButtonWrapper = styled(BackArrowButtonWrapper)`
	background: transparent;
	border: none;
`;

const SelectedItemBottomContent = styled.div`
	border-top: 1px solid lightgray;
	padding-top: 13px;
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const SelectedItem = styled.div`
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: ${selectedItemZIndex};
	box-sizing: border-box;
	overflow-y: auto;
	animation: ${fadeInRTL} 250ms linear;
	background-color: ${getAppColor("light")};
	position: absolute;
	@media (max-width: ${listingsPageMobileWidth}) {
		position: fixed;
	}
`;

const AddToCart = styled(SmallButton)`
	width: 100% !important;
	height: 50px !important;
	text-transform: uppercase;
	font-size: 18px !important;
`;

const SelectedItemWrapper = styled.div`
	padding: 19px ${maxContentWidthPadding}px;
	color: ${getAppColor("dark")};
	font-weight: 500;
	font-size: 15px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	line-height: normal;
`;

const SelectedItemTitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding-right: 45px;
	margin-right: auto;
`;

const SelectedItemTitle = styled.h1`
	font-weight: bold;
	font-size: 16px;
`;

const ItemSubtitle = styled.span`
	font-size: 14px;
	margin-top: 1px;
	font-weight: 500;
`;

const SelectedItemSubtitle = styled(ItemSubtitle)`
	margin-top: 2px;
`;

const SelectedItemQuantity = styled.div`
	display: flex;
	align-items: center;
	label {
		cursor: initial;
		font-weight: bold;
		margin-right: 10px;
	}
	select {
		margin: 0;
		padding: 10px 50px 10px 10px;
		&:disabled {
			cursor: initial;
		}
	}
`;

const SelectedItemHeader = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	margin-bottom: 13px;
`;

const MapContainer = styled.div`
	width: 100%;
	z-index: 0;
	flex: 1;
	position: relative;
	@media (max-width: ${listingsPageMobileWidth}) {
		border-bottom: 1px solid lightgray;
	}
`;

//TODO: Consider making this more DRY with the "real" Venmo message.
const MobileTopMessage = styled.div`
	width: 100%;
	overflow: hidden;
	font-size: 0.8125rem;
	text-align: center;
	padding: 0.5rem 0;
	color: ${getAppColor("light")};
	background-color: #0a1529;
`;

const Layout = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`;

const ItemPrice = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

const ItemPriceSubtitle = styled.span`
	font-size: 14px;
	white-space: nowrap;
	text-align: right;
	margin-right: 10px;
`;

const ItemPriceContent = styled.span`
	line-height: normal;
	font-size: 14px;
	text-align: right;
	strong {
		font-size: 18px;
	}
`;

const ItemInfo = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
	margin-right: 32px;
`;

const ItemTitle = styled.span`
	font-size: 16px;
	font-weight: bold;
`;

const ListingItem = styled.div`
	text-align: left;
	color: ${getAppColor("dark")};
	padding: 14px ${maxContentWidthPadding}px;
	line-height: normal;
	background: ${getAppColor("light")};
	border-bottom: 1px solid lightgray;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-weight: 500;
	&:hover {
		background-color: ${getAppColor("lightGrey")};
	}
`;

const ItemContent = styled.div`
	margin-right: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	line-height: normal;
	align-items: stretch;
`;

const Content = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	@media (max-width: ${listingsPageMobileWidth}) {
		flex-direction: column-reverse;
	}
`;

export const PanelContent = styled.div<{
	unpinTopPanelContent: UnpinTopPanelContent | undefined;
}>`
	position: absolute;
	z-index: 1;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	height: 100%;
	transition: height 0.35s ease;
	${props => {
		if (props.unpinTopPanelContent?.has("desktop")) {
			return `
        overflow-y: scroll;
      `;
		}
	}}
`;

const Panel = styled.div`
	position: relative;
	flex: 1;
	width: 100%;
	@media (min-width: ${listingsPageMobileWidth}) {
		max-width: 420px;
		border-right: 1px solid lightgray;
	}
`;

const TopPanelContent = styled.div`
	height: auto;
	display: flex;
	flex-direction: row;
	background: #f5f5f5;
	border-bottom: 1px solid lightgray;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	min-height: fit-content;
`;

const PanelBodyContent = styled.div`
	display: grid;
	grid-auto-rows: 1fr;
`;

const PanelBody = styled.div<{
	unpinTopPanelContent: UnpinTopPanelContent | undefined;
}>`
	flex: 1;
	width: 100%;
	background: #fafafa;
	${props => {
		if (!props.unpinTopPanelContent?.has("desktop")) {
			return `
        overflow-y: scroll;
      `;
		}
	}}
	${props => {
		if (props.unpinTopPanelContent?.has("mobile") && !props.unpinTopPanelContent.has("desktop")) {
			return `
        @media (max-width: ${listingsPageMobileWidth}) {
          overflow-y: initial;
        }
      `;
		}
	}}
`;
