export const firstLetterToUpperCase = (str: string) => {
	if (str) {
		let splitStr = str.toLowerCase().split(" ");
		for (let i = 0; i < splitStr.length; ++i) {
			splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
		}
		return splitStr.join(" ");
	}
	return str;
};

export const removeHyphens = (str: string) => {
	if (str) {
		return str.split("-").join(" ");
	}
	return str;
};
