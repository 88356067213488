import React, { ChangeEvent, RefAttributes, useEffect, useRef, useState } from "react";
import { Input } from "../Input/Input";
import { FieldRenderProps } from "react-final-form";

let autocomplete: google.maps.places.Autocomplete;

export const GooglePlacesAutocomplete = ({
	as = Input,
	completeQuery,
	onPlaceChanged,
	fields,
	...props
}: {
	options?: Omit<google.maps.places.AutocompleteOptions, "fields">;
	placeholder?: string;
	// Note: The below 'fields' prop is required in order to avoid unnesscarry billing charges for unneeded fields.
	fields: string[];
	onPlaceChanged?: (autocomplete: google.maps.places.Autocomplete, query?: string) => void;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	onClear?: () => void;
	error?: string;
	value?: string | null;
	completeQuery?: boolean;
	id?: string;
	disabled?: boolean;
	//TODO: Remove/deprecate this prop when this component has fully integrated with the new Input component.
	as?: React.FC<RefAttributes<HTMLInputElement> & React.InputHTMLAttributes<HTMLInputElement>>;
}) => {
	const [query, setQuery] = useState<string | undefined>("");
	const autoCompleteRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (autoCompleteRef !== null) {
			autocomplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current as HTMLInputElement, props.options);
			autocomplete.setFields(fields);
			autocomplete.addListener("place_changed", () => {
				if (completeQuery) {
					setQuery(autoCompleteRef.current?.value);
				}
				if (onPlaceChanged) {
					onPlaceChanged(autocomplete, autoCompleteRef.current?.value);
				}
			});
		}
	}, []);

	useEffect(() => {
		if (props.value) {
			setQuery(props.value);
		} else {
			setQuery("");
		}
	}, [props.value]);

	const onFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
		// Hack to disable chrome autofill, none of the official methods work.
		event.target.autocomplete = props.options?.types?.includes("address") ? "no" : "off";
	};

	return React.createElement(as, {
		...props,
		ref: autoCompleteRef,
		id: props.id,
		onFocus: onFocus,
		disabled: props.disabled,
		onChange: event => {
			setQuery(event.target.value);
			if (props.onChange) {
				props.onChange(event);
			}
		},
		placeholder: props.placeholder,
		//Note: This name is added to disable Safari address autofill.
		name: "search",
		value: query,
		//TODO: Remove the ts-ignore for the error prop once this component has fully switched over to the new Input component. Remove the clearable & onClear props once this component has fully switched over to the new Input component & the LocationSearch UX has been improved.
		//@ts-ignore
		error: props.error,
		//@ts-ignore
		clearable: !!query,
		onClear: () => {
			setQuery("");
			if (props.onClear) {
				props.onClear();
			}
		},
	});
};

export const RFFGooglePlacesAutocomplete = ({ input, meta, ...props }: React.ComponentProps<typeof GooglePlacesAutocomplete> & FieldRenderProps<string>) => {
	return <GooglePlacesAutocomplete {...props} {...input} error={meta.error} />;
};
