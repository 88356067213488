import React, { useEffect, useRef, useState, useCallback } from "react";
import styled, { css } from "styled-components/macro";
import { Featured } from "../components/Featured/Featured";
import { useSubcategories } from "../../services/hooks";
import { CommonPage } from "../components";
import { GameFuturePanel } from "../components/Futures/GameFuturePanel";
import { TeamFuturePanel } from "../components/Futures/TeamFuturePanel";
import { mediaQueries } from "../util/mediaQueries";
import { maxContentWidthStyles, maxContentWidthPadding } from "../util/maxContentWidth";
import { getFuturesByConference } from "../../services/actions/futuresService";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { parseSearch } from "../util/parseSearch";
import { Config } from "../../Config";
import { InternalRoutes } from "../Link";
import queryString from "query-string";
import { getConferences } from "../../services/actions/conferencesService";
import { useDispatch } from "react-redux";
import { Conference } from "../../services/types/ConferencesResponse";
import { isRight } from "fp-ts/lib/These";
import { History } from "history";
import { FutureToggle } from "../components/Futures/Toggle";
import { useServiceMonitor } from "../hooks";
import { FutureEvent } from "../../services/types/FuturesResponse";
import nbaComingSoon from "../resource/image/nba-futures-coming-soon.png";
import ncaabComingSoon from "../resource/image/ncaab-futures-coming-soon.png";
import nhlComingSoon from "../resource/image/nhl-futures-coming-soon.png";
import ncaafComingSoon from "../resource/image/ncaaf-futures-coming-soon.png";
import mlbComingSoon from "../resource/image/mlb-futures-comming-soon.png";
import { MetaDecorator, getFuturesCategoryTagTitle, getFuturesCategoryTagDescription } from "../components/SEOMetadecorator/MetaDecorator";
import { isMobileApp } from "../util/isMobileApp";
import { EducationalSection } from "../components/Futures/EducationalPanel";
import go_up_arrow from "../resource/image/go-up-arrow.svg";
import { RoundedButton } from "../components/RoundedButton/RoundedButton";
import { ItemImageContainer, ItemText, itemImageOverlayOpacity } from "../components/Featured/FeaturedItem";
import { FullScreenLoader } from "../components/Loader";
import reservationsHeroDesktop from "../resource/images/reservationsHeroDesktop.png";
import reservationsHeroMobile from "../resource/images/reservationsHeroMobile.png";
import { breakpoints } from "../util/breakpoints";
import { useMediaQuery } from "../hooks/useMediaQuery";

const heroMobileWidth = "900px";

const NCAAF_TOP_25_CONFERENCE = 5;
const NCAAB_TOP_25_CONFERENCE = 7;
const SOCCER_PREMIER_LEAGUE_CONFERENCE = 79;

//Defaults conferences
const DEFAULT_CONFERENCE = {
	ncaaf: NCAAF_TOP_25_CONFERENCE,
	ncaab: NCAAB_TOP_25_CONFERENCE,
	soccer: SOCCER_PREMIER_LEAGUE_CONFERENCE,
};

enum FutureType {
	Team = "team",
	Game = "game",
}

interface FutureSearchParams {
	sport: keyof typeof comingSoonBannerImage | null;
	conference: number | null;
}

const backgroundColorRGBValue = "246, 246, 246";

//TO DO: Implement a feature together with the admin panel to actually avoid these duplicate keys
const comingSoonBannerImage = {
	nba: nbaComingSoon,
	ncaab: ncaabComingSoon,
	nhl: nhlComingSoon,
	ncaaf: ncaafComingSoon,
	"college-football": ncaafComingSoon,
	"college-basketball": ncaabComingSoon,
	mlb: mlbComingSoon,
};

export const FuturesRoute = (props: RouteComponentProps) => {
	const searchParams = parseSearch<FutureSearchParams>(props.location.search);

	const history = useHistory();
	const dispatch = useDispatch();
	const loading = useServiceMonitor(["getConferences", "getFuturesByConference"]);
	const [selection, setSelection] = useState<FutureType>(FutureType.Team);
	const [confererences, setConferences] = useState<Conference[]>([]);
	const [futures, setFutures] = useState<FutureEvent[]>([]);
	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	const categories = useSubcategories("futures").map(cat => ({
		...cat,
		image: Config.getResourceUrl(cat.image ?? ""),
		selected: cat.slug === searchParams.sport,
		sportSlug: cat.slug,
		slug: InternalRoutes.Futures("?" + queryString.stringify({ sport: cat.slug })),
	}));

	// update conferences on sport change
	useEffect(() => {
		const selectedSport = categories.find(category => category.sportSlug === searchParams.sport);
		if (!selectedSport) {
			return;
		}

		setFutures([]);
		dispatch(getConferences(selectedSport.id)).then(conferences => {
			if (isRight(conferences)) {
				setConferences(conferences.right);
			} else {
				console.log("Error fetching conferences", conferences);
			}
		});
	}, [searchParams.sport]);

	// update events on conference change
	useEffect(() => {
		dispatch(getFuturesByConference(searchParams.conference, searchParams.sport)).then(futures => {
			if (isRight(futures)) {
				setFutures(futures.right.data);
			} else {
				console.log("Error fetching futures", futures);
			}
		});
	}, [searchParams.conference, searchParams.sport]);

	// Set default conference
	useEffect(() => setDefaultConference(history, searchParams), [searchParams.sport, searchParams.conference]);

	//Apply filters
	let conferenceItems = confererences.sort(sortConferences(searchParams)).map(filterConferences(searchParams));

	const teams = getTeamFutures(futures);
	const games = getGameFutures(futures);

	const empty = (selection === FutureType.Game && games.length === 0) || (selection === FutureType.Team && teams.length === 0);

	const comingSoonBanner = searchParams.sport ? comingSoonBannerImage[searchParams.sport] : undefined;

	const getSportQueryParamValue = () => {
		const QUERY_PARAM_LENGTH = 6;
		return props.location.search.substring(props.location.search.indexOf("sport=") + QUERY_PARAM_LENGTH);
	};

	const titleRef = useRef<any>();
	const onLearnMoreClick = useCallback(() => {
		let top = titleRef.current?.getBoundingClientRect().top;
		top -= isMobileApp ? 20 : 150;
		window.scrollTo({ top: top, left: 0, behavior: "smooth" });
	}, []);

	const scrollToTop = () => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	};

	return (
		<CommonPage background={`rgb(${backgroundColorRGBValue})`}>
			<MetaDecorator title={getFuturesCategoryTagTitle(getSportQueryParamValue())} description={getFuturesCategoryTagDescription(getSportQueryParamValue())} />
			<HeroImage src={isMobile ? reservationsHeroMobile : reservationsHeroDesktop} alt="Reservations Hero" onClick={onLearnMoreClick} />
			<ThumbnailsSection>
				<CategoryFeatured
					rounded={false}
					title="Sport"
					items={categories}
					backgroundColor={backgroundColorRGBValue}
					notSelectedOverlay
					renderItem={(item, index) => {
						return (
							<ItemContainer
								key={index}
								onClick={() => {
									history.replace(item.slug);
								}}
							>
								<ItemImageContainer
									style={{
										background: `linear-gradient(rgba(0, 0, 0, ${itemImageOverlayOpacity}), rgba(0, 0, 0, ${!item.selected ? 1 : itemImageOverlayOpacity})), url("${item.image}")`,
										cursor: "pointer",
									}}
									selected={item.selected}
								>
									<ItemText>{item.name && item.name.toUpperCase()}</ItemText>
								</ItemImageContainer>
							</ItemContainer>
						);
					}}
				/>
				{!empty && (
					<StyledFeatured
						rounded={false}
						title="Conference"
						hideThumbnailText
						items={conferenceItems}
						backgroundColor={backgroundColorRGBValue}
						renderItem={(item, index) => {
							return (
								<ItemContainer
									onClick={() => {
										history.replace(item.slug);
									}}
								>
									<ItemImageContainer
										style={{
											background: `url("${item.image}")`,
											cursor: "pointer",
										}}
										selected={item.selected}
									/>
								</ItemContainer>
							);
						}}
					/>
				)}
			</ThumbnailsSection>
			<FullScreenLoader show={loading} />
			<FuturesSection>
				<FutureBody>
					<FutureContent>
						{empty && comingSoonBanner ? (
							<ComingSoonBanner src={comingSoonBanner} alt="coming soon" />
						) : (
							<>
								<ButtonContainer>
									<span>Sort:</span>
									<ButtonContainerContent>
										<FutureToggle value={selection} type={FutureType.Team} text={"By Team"} setSelection={setSelection} />
										<FutureToggle value={selection} type={FutureType.Game} text={"By Game"} setSelection={setSelection} />
									</ButtonContainerContent>
								</ButtonContainer>
								{selection === FutureType.Game && <GameFuturePanel games={games} />}
								{selection === FutureType.Team && <TeamFuturePanel teams={teams} />}
							</>
						)}
					</FutureContent>
				</FutureBody>
			</FuturesSection>
			<EducationSection>
				<EducationBody>
					<h2 ref={titleRef as any}>Learn more about postseason reservations</h2>
					<EducationalSection />
				</EducationBody>

				<BackToTopContainer>
					<RoundedButton onClick={scrollToTop} icon={go_up_arrow} text="Back To Top" />
				</BackToTopContainer>
			</EducationSection>
		</CommonPage>
	);
};

const BackToTopContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	margin-bottom: 30px;
`;

/**
 * Filter Conferences.
 * Prepare links to filter/unfilter by conference.
 * Don't allow user to unfilter sports with default conference.
 */
const filterConferences = (searchParams: FutureSearchParams) => (conf: Conference) => {
	let conferenceFilter: number | null = conf.id;

	if (conferenceFilter === searchParams.conference) {
		if (searchParams.sport && !(searchParams.sport in DEFAULT_CONFERENCE)) {
			conferenceFilter = null;
		}
	}

	return {
		name: conf.title,
		image: Config.getResourceUrl(conf.image ?? ""),
		selected: conf.id === searchParams.conference,
		slug: InternalRoutes.Futures("?" + queryString.stringify({ ...searchParams, conference: conferenceFilter })),
	};
};

/**
 * 	Sort conferences: TOP 25 always appears first for NCAAB/NCAAF.
 */
const sortConferences = (searchParams: FutureSearchParams) => (a: Conference, b: Conference) => {
	const first = (c: Conference) => {
		return (searchParams.sport === "ncaaf" && c.id === NCAAF_TOP_25_CONFERENCE) || (searchParams.sport === "ncaab" && c.id === NCAAB_TOP_25_CONFERENCE);
	};

	return first(a) ? -1 : first(b) ? 1 : 0;
};

const setDefaultConference = (history: History, searchParams: FutureSearchParams) => {
	if (searchParams.sport && !searchParams.conference) {
		if (searchParams.sport in DEFAULT_CONFERENCE) {
			type DefaultConference = keyof typeof DEFAULT_CONFERENCE;
			history.replace(
				InternalRoutes.Futures(
					`?${queryString.stringify({
						...searchParams,
						conference: DEFAULT_CONFERENCE[searchParams.sport as DefaultConference],
					})}`
				)
			);
		}
	}
};

const getTeamFutures = (futures: FutureEvent[]) => {
	if (futures === null) {
		return [];
	}
	let teams = new Map();
	futures.forEach(future => {
		const collection = teams.get(future.teamSlug);

		if (!collection) {
			teams.set(future.teamSlug, {
				teamTitle: future.teamTitle,
				teamSlug: future.teamSlug,
				games: [
					{
						name: future.name,
						eventSlug: future.slug,
						venue: future.venueName,
						date: future.date,
						from: future.price,
						sortOrder: future.sortOrder,
					},
				],
			});
		} else {
			collection.games.push({
				name: future.name,
				eventSlug: future.slug,
				venue: future.venueName,
				date: future.date,
				from: future.price,
				sortOrder: future.sortOrder,
			});
		}
	});

	// Games order ascending sort
	const teamsSortedGames = Array.from(teams.values()).map(team => {
		team.games = team.games.sort((a: any, b: any) => a.sortOrder - b.sortOrder);
		return team;
	});

	// Teams sorted alphabetically
	return teamsSortedGames.sort((a: any, b: any) => a.teamTitle.localeCompare(b.teamTitle));
};

const getGameFutures = (futures: FutureEvent[]) => {
	if (futures === null) {
		return [];
	}
	let games = new Map();
	futures.forEach(future => {
		const collection = games.get(future.slug);

		if (!collection) {
			games.set(future.slug, {
				name: future.name,
				eventSlug: future.slug,
				venue: future.venueName,
				venueLocation: future.venueLocation,
				date: future.date,
				sortOrder: future.sortOrder,
				teams: [
					{
						teamSlug: future.teamSlug,
						teamTitle: future.teamTitle,
						from: future.price,
					},
				],
			});
		} else {
			collection.teams.push({
				teamSlug: future.teamSlug,
				teamTitle: future.teamTitle,
				from: future.price,
			});
		}
	});

	// Teams sorted by price (high->low)
	const gamesSortedTeams = Array.from(games.values()).map(game => {
		game.teams = game.teams.sort((a: any, b: any) => b.from - a.from);
		return game;
	});

	// Games order ascending sort
	return gamesSortedTeams.sort((a: any, b: any) => {
		return a.sortOrder - b.sortOrder;
	});
};

const ThumbnailsSection = styled.div`
	background-color: #fff;
	width: 100%;
`;
const FuturesSection = styled.div`
	background-color: #fff;
	width: 100%;
`;
const EducationSection = styled.div`
	background-color: rgb(${backgroundColorRGBValue});
	width: 100%;
`;
const ButtonContainerContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const StyledFeatured = styled(Featured)`
	box-shadow: none;
`;
const CategoryFeatured = styled(StyledFeatured)`
	margin-top: 20px;
	@media (max-width: ${heroMobileWidth}) {
		margin-top: 15px;
	}
`;
const EducationBody = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	${maxContentWidthStyles}
	margin-left: auto;
	margin-right: auto;
	margin-top: 40px;
	margin-bottom: 25px;

	@media ${mediaQueries.max670} {
		& > div:last-child {
			margin-top: 4px;
			margin-bottom: 0;
		}
		padding: 0;
		h2 {
			padding: 0 ${maxContentWidthPadding}px;
			margin-bottom: 14px;
		}
	}

	h2 {
		color: #000;
		font-family: SolanoGothicMVB-Bd;
		font-size: 40px;
		font-weight: 700;
		text-align: left;
		text-transform: uppercase;
		@media (max-width: ${heroMobileWidth}) {
			font-size: 30px;
		}
	}
`;

const FutureBody = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-top: 40px;
	margin-bottom: 65px;
	${maxContentWidthStyles}
	margin: 0 auto;
	margin-bottom: 65px;
	@media ${mediaQueries.max670} {
		& > div:last-child {
			margin-top: 4px;
			margin-bottom: 0;
		}
		flex-direction: column-reverse;
		margin-top: 6px;
		margin-bottom: 0px;
		padding: 0;
	}
`;

const FutureContent = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	@media ${mediaQueries.max670} {
		padding: 0;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	align-items: flex-start;
	margin-bottom: 9px;
	margin-top: 23px;
	justify-content: flex-start;
	font-weight: 600;
	font-size: 17px;
	padding-bottom: 5px;
	cursor: pointer;
	line-height: normal;
	color: #000;
	& > span {
		margin-right: 10px;
	}
	@media ${mediaQueries.max670} {
		margin-bottom: 14px;
		margin-top: 5px;
		padding: 0 ${maxContentWidthPadding}px;
	}
`;

const ComingSoonBanner = styled.img`
	width: 100%;
	height: auto;
	margin-bottom: 20px;
`;

const itemContainerMaxWidth = 230;

const itemContainerWidth = (width: number) => css`
	min-width: min(${width}%, ${itemContainerMaxWidth}px);
	max-width: min(${width}%, ${itemContainerMaxWidth}px);
`;

const ItemContainer = styled.div`
	display: inline-block;
	box-sizing: border-box;
	vertical-align: top;
	${itemContainerWidth(25)}
	padding-right: 16px;
	box-sizing: content-box;
	@media ${mediaQueries.max670} {
		${itemContainerWidth(44)}
	}
`;

const HeroImage = styled.img`
	cursor: pointer;
	max-height: 400px;
`;
