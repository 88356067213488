import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components/macro";
import { mediaQueries } from "../../util/mediaQueries";
import { CollapseArrow } from "../CollapseArrow/CollapseArrow";
import { Expandable } from "../Expandable/Expandable";
import { maxContentWidthPadding } from "../../util/maxContentWidth";
import { Nulleable } from "../../../model/Utils";
import { getAppColor } from "../../util/appColors";

const arrowColor = "#767977";

const collapseArrowProps = {
	weight: 2,
	size: 11,
	color: arrowColor,
};

export type CollapseBehavior = "header" | "default";

export const EducationalSection = ({ fullwidth }: { fullwidth?: boolean }) => {
	const [selected, setSelection] = useState<Nulleable<string>>(null);
	const props = { selected, setSelection, fullwidth };
	const helpCenterRoute = "https://support.sitickets.com/hc/en-us";

	return (
		<EducationalSectionContainer fullwidth={fullwidth}>
			<EducationalRow title="How It Works" {...props}>
				<Subtitle> Pricing </Subtitle>
				<BodyText> Reserve postseason tickets year-round for a fraction of normal ticket prices. </BodyText>
				<UnorderedList>
					<ListItem>
						<strong>Reservation Fee:</strong> The price you pay to reserve tickets to the game.
					</ListItem>
					<ListItem>
						<strong>Ticket Price:</strong> If your team makes the game, pay the ticket price to get your tickets.
					</ListItem>
					<ListItem>Ticket Purchases are subject to an additional Transaction Fee.</ListItem>
					<ListItem> The reservation fee is non-refundable</ListItem>
				</UnorderedList>
				<Subtitle> Resale Market </Subtitle>
				<UnorderedList>
					<ListItem> Market prices change throughout the season based on how likely a team is to make the game. The resale market allows you the option to sell your reservation on SI Tickets - for a profit. </ListItem>
				</UnorderedList>
			</EducationalRow>
			<EducationalRow title="An Example" {...props}>
				<OrderedList>
					<ListItem>
						{" "}
						<strong>Reserve one (1) Atlanta Braves ticket for the NLCS Home Game 2</strong> in the Upper Level Outfield for $34 today.
					</ListItem>
					<ListItem>
						{" "}
						<strong>When the Braves clinch the NLCS berth,</strong> you can purchase the ticket for $50.
					</ListItem>
					<ListItem>
						{" "}
						<strong>In total, you pay only $84 for a NLCS ticket,</strong> that would cost more than $200 the week of the game.
					</ListItem>
				</OrderedList>
			</EducationalRow>
			<EducationalRow title="Get Your Tickets" {...props}>
				<OrderedList>
					<ListItem>
						{" "}
						Within 24 hours of a team clinching the game, <strong>you’ll receive an email to purchase your tickets.</strong>
					</ListItem>
					<ListItem>
						{" "}
						You will have a period of time (e.g. 48 hours) to make your decision: <strong>purchase the tickets or let them go.</strong>
					</ListItem>
					<ListItem>
						{" "}
						We require a decision within the time period to ensure all customers receive their tickets before the game is scheduled to start.
						<strong>Customers who do not purchase by the deadline forfeit their right to buy tickets for the event.</strong>
					</ListItem>
				</OrderedList>
			</EducationalRow>
			<EducationalRow title="Ticket Delivery" {...props}>
				<OrderedList>
					<ListItem>Once both teams are determined, SI Tickets will begin distributing tickets to customers.</ListItem>
					<ListItem>Tickets are guaranteed to be located within the seating zone selected and delivered at least 24 hours prior to the start of the game.</ListItem>
					<ListItem>SI Tickets guarantees up to two (2) tickets to be seated next to one another. The exact location of the tickets will be provided once the ticket(s) have been emailed or shipped.</ListItem>
				</OrderedList>
			</EducationalRow>
			<EducationalRow title="Need More Help?" {...props}>
				<BodyText>
					For more info on postseason ticket reservations, chat with our&nbsp;<Link href={helpCenterRoute}>Customer Support Team.</Link>
				</BodyText>
			</EducationalRow>
		</EducationalSectionContainer>
	);
};

export const EducationalRow = (props: { title: string; children: React.ReactNode; selected: Nulleable<string>; fullwidth?: boolean; collapseBehavior?: CollapseBehavior; setSelection: (index: Nulleable<string>) => void }) => {
	const selected = props.selected === props.title;

	const collapseOnBodyClickAllowed = useMemo<boolean>(() => {
		return props.collapseBehavior !== "header";
	}, [props.collapseBehavior]);

	const onItemClick = useCallback(() => {
		props.setSelection(props.selected !== props.title ? props.title : null);
	}, [props.setSelection, props.selected, props.title]);

	const onBodyClick = () => {
		if (collapseOnBodyClickAllowed) {
			onItemClick();
		}
	};

	return (
		<EducationalRowContainer key={props.title} isSelected={selected} fullwidth={props.fullwidth} onClick={onBodyClick}>
			<ItemContainer onClick={onItemClick}>
				<EducationalItem>
					<TextContainer>
						<Title>{props.title}</Title>
					</TextContainer>
					<ArrowButton isSelected={selected}>
						<StyledCollapseArrow expanded={selected} {...collapseArrowProps} />
					</ArrowButton>
				</EducationalItem>
			</ItemContainer>
			<StyledExpandable expanded={selected} fullwidth={props.fullwidth}>
				<Content fullwidth={props.fullwidth}>{props.children}</Content>
			</StyledExpandable>
		</EducationalRowContainer>
	);
};

export const EducationalHeader = () => <EducationalHeaderContainer>Back to Listings</EducationalHeaderContainer>;

const EducationalHeaderContainer = styled.div`
	font-family: Montserrat;
	font-size: 18px;
	font-weight: 700;
	line-height: 22px;
	text-align: center;
	padding: 18px 61px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Link = styled.a`
	font-size: 16px !important;
	font-family: Montserrat !important;
	text-decoration: underline;
	font-weight: bold;
	color: #e02000;
`;

const BodyText = styled.div`
	font-size: 16px !important;
	font-family: Montserrat !important;
	padding-bottom: 14px;
	font-weight: 400;
	line-height: 20px;
	@media (max-width: 670px) {
		padding-left: 20px;
		padding-right: 20px;
	}
`;

const ListItem = styled.li`
	font-size: 16px !important;
	font-family: Montserrat !important;
	color: ${getAppColor("dark")} !important;
	line-height: 24px !important;
	overflow-wrap: break-word;
	font-weight: 400;
`;

const OrderedList = styled.ol`
	display: block !important;
	list-style-type: decimal !important;
	margin-top: 0 !important;
	margin-bottom: 1em !important;
	margin-left: 0 !important;
	margin-right: 0 !important;
	padding-left: 40px !important;
	${ListItem}:not(:first-child) {
		margin-top: 11px;
	}
`;

const UnorderedList = styled.ul`
	display: block !important;
	list-style-type: disc;
	margin-top: 0;
	margin-bottom: 1 em;
	margin-left: 0;
	margin-right: 0;
	padding-left: 40px;
	${ListItem}:not(:first-child) {
		margin-top: 11px;
	}
`;

const mobileMediaQuery = mediaQueries.max670;

const borderWidth = "1px";

const StyledExpandable = styled(Expandable)<{ fullwidth?: boolean }>`
	border-top: ${props => (props.fullwidth ? "none" : "1px solid #E2E4E7")};
	margin: 0 26px;
	@media ${mobileMediaQuery} {
		margin: 0;
	}
`;

const EducationalSectionContainer = styled.div<{ fullwidth?: boolean }>`
	margin-top: ${props => (props.fullwidth ? "0px" : "24px")};
`;

const StyledCollapseArrow = styled(CollapseArrow)`
	margin-left: 9px;
	@media ${mobileMediaQuery} {
		margin-left: 16px;
	}
`;

const ItemContainer = styled.div`
	cursor: pointer;
	padding: 0 26px;
	@media ${mobileMediaQuery} {
		padding: 0;
	}
`;

const Subtitle = styled.h2`
	font-size: 16px !important;
	font-family: Montserrat !important;
	text-transform: none !important;
	line-height: 24px !important;
	padding-bottom: 6px !important;
	color: ${getAppColor("dark")};
	font-weight: 700;
`;

const EducationalRowContainer = styled.div<{ isSelected: boolean; fullwidth?: boolean }>`
	width: 100%;
	display: table;
	background: ${getAppColor("light")};
	${props => {
		if (props.fullwidth) {
			return ` border-top: ${borderWidth} solid lightgray;border-bottom: ${borderWidth} solid lightgray;`;
		} else {
			return ` border: ${borderWidth} solid lightgray;`;
		}
	}}
	box-sizing: border-box;
	font-size: 16px;
	${Subtitle}:not(:first-child) {
		margin-top: 14px;
	}
	@media ${mobileMediaQuery} {
		border-width: ${borderWidth} 0px ${borderWidth} 0px;
	}
	&:not(:last-child) {
		border-bottom: none;
	}
	&:hover {
		background-color: ${props => (props.isSelected ? getAppColor("light") : getAppColor("lightGrey"))};
	}
`;

const EducationalItem = styled.div`
	display: flex;
	align-items: center;
	margin: 25px 0px;

	@media ${mobileMediaQuery} {
		margin: 19px 0;
		padding: 0 ${maxContentWidthPadding}px;
	}
`;

const TextContainer = styled.div`
	vertical-align: middle;
	text-align: left;
	flex: 1;
	font-weight: bold;
`;

const Title = styled.div`
	font-weight: bold;
	color: ${getAppColor("dark")};
	font-size: 18px;
	margin-right: 20px;
`;

const ArrowButton = styled.div<{ isSelected: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${arrowColor};
	font-size: 15px;
	font-weight: 500;
	cursor: pointer;
	& > span {
		color: #555555;
	}
	@media ${mobileMediaQuery} {
		& > span {
			display: none;
		}
	}
`;

const Content = styled.div<{ fullwidth?: boolean }>`
	display: flex;
	flex-direction: column;
	${props => (props.fullwidth ? "padding:0px 20px 14px 20px;" : "padding: 26px 14px;")}
	text-align:left;
`;
