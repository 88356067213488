import React, { InputHTMLAttributes, useEffect, useImperativeHandle, useRef, useState } from "react";
import { FieldRenderProps } from "react-final-form";
import styled from "styled-components/macro"; //For some reason this line is avoiding a global style sheet to override the styles for this Input
import { SkeletonProps } from "../../../model/optimizedModel/skeleton";
import { useAutoUpdateState } from "../../hooks/useAutoUpdateState";
import { getAppColor } from "../../util/appColors";
import { SkeletonWrapper } from "../SkeletonWrapper/SkeletonWrapper";
import { getTypographyStyles, TypographyType } from "../Typography/Typography";

const inputBackground = getAppColor("lightGrey", "subtle");

const inputBorderWidth = "1px";

const inputBorderStyle = "solid";

const inputBorderColor = getAppColor("lightGrey", "dark");

const inputPadding = "10px";

const errorTransitionDuration = 200;

export const Input = React.forwardRef<
	HTMLInputElement,
	InputHTMLAttributes<HTMLInputElement> & {
		sideLabel?: React.ReactNode;
		typographyType?: TypographyType;
		forwardedAs?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
		rightChildren?: React.ReactNode;
		error?: string;
		noSubtextSpacing?: boolean;
		enableCursorPositionCorrection?: boolean;
	} & SkeletonProps
>(({ sideLabel, className, typographyType = "normalSpecialBody", loading, rightChildren, error, onBlur, noSubtextSpacing, onChange, enableCursorPositionCorrection, ...inputProps }, ref: any) => {
	const [blurred, setBlurred] = useState(false);

	const displayError = useAutoUpdateState(() => !!error && blurred, [blurred, error]);

	const oldSelectionStartRef = useRef<number | null>(null);

	const oldInputValueLengthRef = useRef<number | null>(null);

	const inputRef = useRef<HTMLInputElement | null>(null);

	useImperativeHandle(ref, () => inputRef.current);

	useEffect(() => {
		if (enableCursorPositionCorrection && inputRef.current) {
			const { selectionStart, value } = inputRef.current;
			if (oldInputValueLengthRef.current !== null && oldSelectionStartRef.current !== null && selectionStart !== null && selectionStart === value.length && oldSelectionStartRef.current !== selectionStart && value.length < oldInputValueLengthRef.current) {
				inputRef.current.setSelectionRange(oldSelectionStartRef.current, oldSelectionStartRef.current);
			}
			oldInputValueLengthRef.current = value.length;
		}
	}, [inputRef.current?.value?.length, enableCursorPositionCorrection]);

	return (
		<div
			css={`
				display: flex;
				flex-direction: column;
				width: 100%;
			`}
			className={className}
		>
			<div
				css={`
					display: flex;
					align-items: center;
					position: relative;
					z-index: 1;
				`}
			>
				<SkeletonWrapper
					loading={loading}
					css={`
						width: 100%;
					`}
				>
					<input
						{...inputProps}
						onChange={event => {
							oldSelectionStartRef.current = event.target.selectionStart;
							if (onChange) {
								onChange(event);
							}
						}}
						ref={inputRef}
						css={`
							${getTypographyStyles(typographyType, {
								color: "dark",
							})}
							text-transform: none;
							transition-duration: ${errorTransitionDuration}ms;
							transition-property: border-color;
							border-radius: initial;
							margin: initial;
							background: ${inputBackground};
							border: ${inputBorderWidth} ${inputBorderStyle} ${inputBorderColor};
							padding: ${inputPadding};
							border-radius: 5px;
						`}
						style={
							displayError
								? {
										borderColor: getAppColor("error"),
								  }
								: undefined
						}
						className="input"
						onBlur={event => {
							setBlurred(true);
							if (!!onBlur) {
								onBlur(event);
							}
						}}
					/>
				</SkeletonWrapper>
				{rightChildren !== undefined && (
					<div
						css={`
							position: absolute;
							right: calc(${inputBorderWidth} + ${inputPadding});
							background: ${inputBackground};
							box-sizing: border-box;
							height: calc(100% - (${inputBorderWidth} * 2));
							display: flex;
							align-items: center;
							justify-content: flex-end;
							top: 0;
							bottom: 0;
							margin: auto 0;
						`}
					>
						{rightChildren}
					</div>
				)}
			</div>
			<div
				css={`
					text-align: left;
					${getTypographyStyles("bodyTiny")}
					position: relative;
				`}
				style={
					noSubtextSpacing
						? {
								height: 0,
						  }
						: {
								paddingTop: 0.5,
								marginBottom: 2,
						  }
				}
			>
				{/*Zero width space character (in order to keep div height when the main error message is not displayed)*/}
				&#8203;
				<span
					css={`
						position: absolute;
						transition-duration: ${errorTransitionDuration}ms;
						transition-timing-function: ease;
						transition-property: top, opacity;
						z-index: 0;
						top: -50%;
						opacity: 0;
						color: ${getAppColor("error")};
						left: 0;
					`}
					style={
						displayError
							? {
									top: 0,
									opacity: 1,
							  }
							: undefined
					}
				>
					{error}
				</span>
			</div>
		</div>
	);
});

export const RFFInput = ({ input, meta, ...rest }: FieldRenderProps<string>) => {
	return <Input {...input} {...rest} error={meta.error} />;
};
