import styled from "styled-components/macro";
import { ReactComponent as TrashIcon } from "../../resource/assets/trash_icon.svg";

export const DeleteIconButton = styled(TrashIcon)`
	fill: #555555;
	cursor: pointer;
	//TODO: Implement improved animation with MUI in the optimized frontend
	transition: opacity 0.1s ease 0s;
	width: 20px;
	&:hover {
		//TODO: Consider trying diffrent looks for the hover look
		opacity: 0.6;
	}
`;
