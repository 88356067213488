import { ModalPopup } from "../ModalPopup/ModalPopup";
import { ModalCloseButton } from "../Modal/ModalCloseButton";
import React from "react";
import styled from "styled-components/macro";
import { Button } from "../Button/Button";
import { mediaQueries } from "../../util/mediaQueries";

export const DeleteConfirmationPopUp = ({ onDidClose, onConfirmation }: { onDidClose: () => void; onConfirmation: () => void }) => {
	return (
		<ModalPopup onDidClose={onDidClose} rightHeaderElements={onClose => <ModalCloseButton onClick={onClose} />}>
			{onClose => (
				<>
					<Text>
						Are you sure you want to delete this <DesktopBr />
						item from the cart?
					</Text>
					<ButtonsContainer>
						<Button onClick={onClose} initialAppearance="WhiteGray">
							Cancel
						</Button>
						<Button onClick={onConfirmation}>Confirm</Button>
					</ButtonsContainer>
				</>
			)}
		</ModalPopup>
	);
};

const ButtonsContainer = styled.div`
	display: flex;
	padding-bottom: 20px;

	button:first-child {
		margin-right: 24px;
	}

	@media ${mediaQueries.max480} {
		flex-direction: column-reverse;
		align-items: center;

		button:first-child {
			margin-right: 0;
			margin-top: 24px;
		}
	}
`;

const Text = styled.div`
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 157%;
	text-align: center;
	align-self: center;
	padding: 26px 31px 29px 31px;
	color: #000000;

	@media ${mediaQueries.max480} {
		font-size: 18px;
	}
`;

const DesktopBr = styled.br`
	@media ${mediaQueries.max670} {
		display: none;
	}
`;
