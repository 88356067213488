import React from "react";
import styled from "styled-components/macro";
import { FilterTitle } from "./styles";

export const SingleFilterButton = ({ text, title, onClick, placeholder }: { placeholder?: string | null; component?: React.ReactNode; title?: string | null; onClick: () => void; text?: string | null }) => {
	return (
		<Container>
			<FilterTitle>{title}</FilterTitle>
			<Content onClick={onClick} text={text}>
				<DateRange text={text}>{text ? text : placeholder}</DateRange>
			</Content>
		</Container>
	);
};

const Content = styled.div<{ text?: string | null }>`
	border: 1px solid #555555;
	height: 42px;
	box-sizing: border-box;
	border-radius: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
	${props =>
		props.text &&
		`
        background: #12284C;
    `}
`;

const DateRange = styled.div<{ text?: string | null }>`
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #555555;

	${props =>
		props.text &&
		`
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;        
        text-align: center;
        color: #FFFFFF;  
    `}
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	cursor: pointer;
`;
