import { Reducer } from "redux";

type WalletPromo = {
	newUser: boolean;
	promoCode: string;
	referrerUrl: string;
	uId?: string;
};

interface PromoState {
	walletPromo: WalletPromo | null;
}

const defaultState: PromoState = {
	walletPromo: null,
};

export type PromoAction =
	| {
			type: "SET_WALLET_PROMO";
			walletPromo: WalletPromo;
	  }
	| {
			type: "CLEAR_WALLET_PROMO";
	  };

export const promoReducer: Reducer<PromoState, PromoAction> = (state: PromoState = defaultState, action: PromoAction): PromoState => {
	switch (action.type) {
		case "SET_WALLET_PROMO":
			return { ...state, walletPromo: action.walletPromo };
		case "CLEAR_WALLET_PROMO":
			return { ...state, walletPromo: null };
		default:
			return state;
	}
};

export const setWalletPromo = (walletPromo: WalletPromo): PromoAction => ({
	type: "SET_WALLET_PROMO",
	walletPromo,
});

export const clearWalletPromo = (): PromoAction => ({
	type: "CLEAR_WALLET_PROMO",
});
