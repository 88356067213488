import React from "react";
import styled from "styled-components/macro";
import resetButton from "../../resource/image/reset-button.svg";

export const ResetButton = ({ text, onClick }: { text: string; onClick: () => void }) => {
	return (
		<ResetButtonContainer onClick={onClick}>
			{text && text}
			<img src={resetButton} alt="" />
		</ResetButtonContainer>
	);
};

const ResetButtonContainer = styled.div`
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 18px;
	display: flex;
	align-items: center;
	letter-spacing: 0.02em;
	font-variant: small-caps;
	text-transform: uppercase;
	color: #333333;
	cursor: pointer;
	img {
		margin-left: 15px;
		height: 22px;
		width: 22px;
	}
`;
