import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { InternalRoutes } from "../../Link";
import { compare } from "../MobileNavigation/Back";
import { useLocation } from "react-router";
import { store } from "../../../store/store";
import { setZenDeskAsLoaded } from "../../../store/reducers/zenDeskReducer";
import { useShowZendeskChatWidget } from "../../hooks/useShowZendeskChatWidget";
import { isMobileApp } from "../../util/isMobileApp";

const routesToShowZenDesk = [InternalRoutes.MyAccount("*/*"), InternalRoutes.Bets, InternalRoutes.Futures(""), InternalRoutes.Cart, InternalRoutes.Checkout];

export const ZenDeskChat = () => {
	const { pathname } = useLocation();

	const { showZendeskChatWidget, setShowZendeskChatWidget } = useShowZendeskChatWidget();

	const zenDeskLoaded = useSelector(state => state.transient.zenDesk.loaded);
	useEffect(() => {
		const displayWidget =
			!isMobileApp &&
			routesToShowZenDesk.some(route => {
				return compare(pathname, route);
			});
		if (displayWidget !== showZendeskChatWidget) {
			setShowZendeskChatWidget(displayWidget);
			if (displayWidget && !zenDeskLoaded) {
				store.dispatch(setZenDeskAsLoaded());
			}
		}
	}, [pathname, isMobileApp]);

	if (showZendeskChatWidget || zenDeskLoaded) {
		return (
			<Helmet>
				<script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=93413c91-8d64-425c-a214-777d7bb40e93"></script>
				<script type="text/javascript" src="/zESettings.js" />
			</Helmet>
		);
	} else {
		return <></>;
	}
};
