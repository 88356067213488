import React from "react";
import styled, { css } from "styled-components/macro";
import { BaseCosmeticInvoiceProps } from "../../../model/optimizedModel/invoice";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";
import { useMemoState } from "../../hooks/useMemoState";
import { InvoiceItem, InvoiceItemProps } from "./InvoiceItem";

interface ContentProps {
	bottomMargin?: boolean;
	topMargin?: boolean;
}

//TODO: Consider finding a better way to infer different component prop types and make sure thet they're based off of InvoiceItemProps.
//TODO: Consider only accepting components for T.
export type InvoiceComponentItem<T extends Partial<InvoiceItemProps> = InvoiceItemProps> = {
	component?: React.FC<T>;
	key: string;
} & T;

export interface BaseInvoiceProps<T extends Partial<InvoiceItemProps> = InvoiceItemProps> extends StyledComponentProps, BaseCosmeticInvoiceProps, ContentProps {
	//TODO: See if there's a way to only allow valid keys for pick and omit.
	pick?: string[];
	omit?: string[];
	//TODO: Consider finding a way to only accept valid keys.
	override?: Record<string | number, Omit<Partial<InvoiceComponentItem<T>>, "key">>;
	//TODO: Reconsider implementing the 'additionalItems' functionality. The 'omit' and 'pick' props might work just fine if the invoice components are architected in a certain way.
	additionalItems?: InvoiceComponentItem<T>[];
}

const useMemoStateSet = (set?: any[]) => {
	return useMemoState(() => {
		const value = new Set(set);
		return value;
	}, [set]);
};

export const Invoice = <T extends Partial<InvoiceItemProps> = InvoiceItemProps>({
	items,
	additionalItems,
	pick,
	omit,
	borderBottom,
	borderTop,
	override,
	...contentProps
}: {
	items: InvoiceComponentItem<T>[];
} & BaseInvoiceProps<T>) => {
	const pickSet = useMemoStateSet(pick);
	const omitSet = useMemoStateSet(omit);
	const displayedItems = useMemoState(() => {
		const allItems = additionalItems ? items.concat(additionalItems) : items;
		//TODO: displayedItems was implemented in order to apply borderBottom and borderTop to the correct first and last items that are displayed. Consider finding a better way to accomplish this instead of using .filter or any kind of "loop method".
		const value = allItems.filter(item => {
			//TODO: Consider seeing if this conditional logic can be optimized.
			return (!pickSet.size && !omitSet.size) || (pickSet.size && pickSet.has(item.key)) || (omitSet.size && !omitSet.has(item.key));
		});
		return value;
	}, [pickSet, omitSet, items, additionalItems]);

	return (
		<Content {...contentProps}>
			{displayedItems.map((item, index) => {
				const overrideProps = override && {
					...override[item.key],
					...override[index],
				};
				const { component, ...props } = item;
				//TODO: Consider finding a way to use createElement or cloneElement instead.
				const Component = component || InvoiceItem;
				//TODO: Remove ts-ignore
				return (
					<>
						{/*@ts-ignore*/}
						<Component {...props} {...overrideProps} borderTop={(index === 0 && borderTop) || props.borderTop || overrideProps?.borderTop} borderBottom={(index === displayedItems.length - 1 && borderBottom) || props.borderBottom || overrideProps?.borderBottom} />
					</>
				);
			})}
		</Content>
	);
};

const invoiceItemSpacing = "12px";

const getContentMargin = (position: "bottom" | "top") => {
	const value = css`
    margin-${position}: ${invoiceItemSpacing};
  `;
	return value;
};

export const Content = styled.div<ContentProps>`
	font-weight: 500;
	font-size: 15px;
	color: #000000;
	text-align: left;
	display: flex;
	flex-direction: column;
	${props => (props.bottomMargin ? getContentMargin("bottom") : "")}
	${props => (props.topMargin ? getContentMargin("top") : "")}
  & > *:not(:last-child) {
		margin-bottom: ${invoiceItemSpacing};
	}
`;
