import React from "react";

interface ErrorBoundaryProps {
	failureView: JSX.Element | ((clearError: () => void) => JSX.Element);
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, { hasError: boolean }> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = {
			hasError: false,
		};
	}

	static getDerivedStateFromError(error: unknown) {
		return { hasError: true };
	}

	componentDidCatch(error: unknown, errorInfo: unknown) {
		this.setState({ hasError: true });
	}

	render() {
		if (!this.state.hasError) {
			return this.props.children;
		} else if (typeof this.props.failureView === "function") {
			return this.props.failureView(() => this.setState({ hasError: false }));
		} else {
			return this.props.failureView;
		}
	}
}
