import React, { HTMLAttributes, useEffect, useContext } from "react";
import styled from "styled-components/macro";
import { SearchState } from "./SearchState";
import { MainSearchResults } from "./MainSearchResults";
import { MainSearchBar } from "./MainSearchBar";
import { useMainSearch } from "./useMainSearch";
import { HomeSearchSelectedContext } from "../../routes/Home";
import { borderRadius } from "./styles";
import { isMobileApp } from "../../util/isMobileApp";
import glass_icon from "../../resource/img/icons/glass_icon.svg";
import { useSelector } from "react-redux";
import { useIsMobileWeb } from "../../hooks/useIsMobileWeb";
import { LocationPicker } from "../LocationPicker";
import { breakpoints } from "../../util/breakpoints";

const MAIN_SEARCH_CONFIG = {
	debounceSearchMs: 200,
	minCharactersSearch: 3,
};

interface MainSearchProps extends HTMLAttributes<HTMLDivElement> {
	searchState: SearchState;
	setSearchState: (next: SearchState) => void;
	allowCustomLocations?: boolean;
	hideLocation?: boolean;
	placeholder?: string;
	fullWidthResults?: boolean;
}

export const MainSearch = ({ searchState, setSearchState, allowCustomLocations, placeholder, hideLocation, children, fullWidthResults, ...rest }: React.PropsWithChildren<MainSearchProps>) => {
	const { isLoading, events, venues, performers, categories, popularSubcategories, searchText, setSearchText, hasSearchResults, searchFocused, setSearchFocused, recentGenericSearches, recentEventSearches } = useMainSearch(setSearchState, MAIN_SEARCH_CONFIG);

	const { homeSearchSelected, setHomeSearchSelected } = useContext(HomeSearchSelectedContext);

	const location = useSelector(state => state.persistent.location);
	const isMobileWeb = useIsMobileWeb();

	const isMobile = isMobileWeb || isMobileApp;

	useEffect(() => {
		if ((!isMobileWeb && !isMobileApp) || searchState !== SearchState.Main) return;

		const initialScrollX = window.scrollX;
		const initialScrollY = window.scrollY;
		const initialTop = document.body.style.top;
		const initialPosition = document.body.style.position;

		const scrollAmount = window.innerHeight * 0.13;

		document.body.style.top = `-${initialScrollY}px`;
		document.body.style.position = "fixed";
		return () => {
			document.body.style.position = initialPosition;
			document.body.style.top = initialTop;

			if (searchState !== SearchState.Main) {
				window.scrollTo(initialScrollX, initialScrollY + scrollAmount);
			} else {
				window.scrollTo(initialScrollX, initialScrollY - scrollAmount);
			}
		};
	}, [searchState]);

	useEffect(() => {
		if (searchState !== SearchState.None) {
			if (!homeSearchSelected) {
				setHomeSearchSelected(true);
			}
		} else if (homeSearchSelected) {
			setHomeSearchSelected(false);
		}
	}, [searchState]);

	useEffect(() => {
		if (!homeSearchSelected && searchState !== SearchState.None) {
			setSearchState(SearchState.None);
		} else if (homeSearchSelected && searchState !== SearchState.Location) {
			setSearchState(SearchState.Main);
		}
	}, [homeSearchSelected]);

	useEffect(() => {
		if (searchFocused) {
			searchText.length === 0 ? setSearchState(SearchState.Initial) : setSearchState(SearchState.Main);
		}
	}, [searchFocused, searchText]);

	const onIconClick = () => {
		switch (searchState) {
			case SearchState.Main:
				setSearchState(SearchState.None);
				setSearchText("");
				break;
			case SearchState.Initial:
				setSearchState(SearchState.None);
				break;
			default:
				setSearchState(SearchState.Initial);
				break;
		}
	};

	useEffect(() => {
		if (!searchText) {
			setSearchState(SearchState.None);
		} else {
			setSearchState(SearchState.Main);
		}
	}, [searchText]);

	return (
		<>
			<SearchContainer>
				<div className={rest.className} onClick={rest.onClick}>
					<StyledMainSearchBar
						searchState={searchState}
						isLoading={searchText.length > 0 && isLoading}
						searchText={searchText}
						placeholder={placeholder}
						hasSearchResults={hasSearchResults}
						setSearchText={setSearchText}
						onFocus={() => {
							setSearchFocused(true);
							setSearchState(searchText.length > 0 ? SearchState.Main : SearchState.None);
						}}
						onBlur={() => setSearchFocused(false)}
					>
						<LensSearchButton showGlassIcon onClick={onIconClick}>
							<LensSearchButtonPin alt="Search Icon" src={glass_icon} />
						</LensSearchButton>
					</StyledMainSearchBar>
					{isMobile && !hideLocation && <StyledLocationPicker text="Choose Location" location={location} />}
					{(searchState === SearchState.Main || searchState === SearchState.Initial) && (
						<MainSearchResultsOuterWrapper fullWidthResults={fullWidthResults}>
							<MainSearchResultsInnerWrapper>
								<StyledMainSearchResults
									{...(searchState === SearchState.Main
										? {
												showSuggestions: false,
												events,
												performers,
												venues,
												categories,
												fullWidthResults,
										  }
										: searchState === SearchState.Initial
										? {
												popularSubcategories,
												recentGenericSearches,
												recentEventSearches,
												showSuggestions: false,
										  }
										: {})}
								/>
							</MainSearchResultsInnerWrapper>
						</MainSearchResultsOuterWrapper>
					)}
				</div>
			</SearchContainer>
		</>
	);
};

const StyledMainSearchBar = styled(MainSearchBar)`
	box-shadow: 0px 3px 7px -4px black;
`;

const StyledLocationPicker = styled(LocationPicker)`
	font-size: 18px !important;
	border-left: none;
	padding-left: 0;
	align-items: center;
	justify-content: center;
	margin: 40px 0 20px 0;
	min-height: fit-content;
	overflow: initial;
`;

const MainSearchResultsOuterWrapper = styled.div<{
	fullWidthResults?: boolean;
}>`
	max-width: 100vw;
	${props =>
		props.fullWidthResults
			? `
    overflow-y: scroll;
  `
			: `
    height: 0;
  `}/*
  position: absolute;
  max-width: 90%;
 */
`;

const MainSearchResultsInnerWrapper = styled.div`
	border-radius: 0 0 4px 4px;
	overflow: hidden;
`;

const StyledMainSearchResults = styled(MainSearchResults)<{
	fullWidthResults?: boolean;
}>`
	background: #fff;
	${props =>
		props.fullWidthResults
			? `
		width: 100vw;
    max-width: 100%;
	`
			: `
    max-height: 45vh;
    //TODO: DRY this max-width with the homepage mobile width.
    @media (max-width: 767px) {
      max-height: 56vh;
    }
  `}
`;

const SearchContainer = styled.div`
	background: transparent;
	display: flex;
	flex-direction: column;
`;

const LensSearchButton = styled.div<{ showGlassIcon: boolean }>`
	border-radius: ${props => (props.showGlassIcon ? "0" : `0 ${borderRadius} ${borderRadius} 0`)};
	background: ${props => (props.showGlassIcon ? "transparent" : "#e92224")};
	padding: 0 25px;
	display: flex;

	@media (max-width: ${breakpoints.mobile}) {
		padding: 0 15px;
	}
`;

const LensSearchButtonPin = styled.img`
	height: 25px;
	width: 25px;

	@media (max-width: ${breakpoints.mobile}) {
		height: 16px;
		width: 16px;
	}
`;
