import React from "react";
import styled from "styled-components/macro";
import { MyAccountPage } from "../MyAccountPage";
import { NavLink } from "react-router-dom";
import { MyAccountRoutes } from "../../../routes/MyAccountRoute";

export const MyAccountFuturesPage = (
	props: React.PropsWithChildren<{
		mobileTitle: string;
	}>
) => {
	return (
		<MyAccountPage
			mobileTitle={props.mobileTitle}
			columns={[
				<Container>
					<Chips>
						<Chip to={MyAccountRoutes.FuturesOrders}>Orders</Chip>
						<Chip to={MyAccountRoutes.FuturesListings}>Listings</Chip>
						<Chip to={MyAccountRoutes.FuturesSold}>Sold</Chip>
					</Chips>
					<Futures>{props.children}</Futures>
				</Container>,
			]}
		/>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const Chips = styled.div`
	margin-bottom: 25px;
	display: flex;
	width: 100%;
	justify-content: center;
`;

const Chip = styled(NavLink)`
	outline: none;
	padding: 10px 12px;
	font-family: "Montserrat";
	border: 1px solid lightgray;
	font-size: 16px;
	font-weight: 500;
	color: black;
	width: 33%;
	//MUI Palette grey.200
	//MUI Transition
	transition: background 300ms cubic-bezier(0.4, 0, 0.2, 1);
	&.active,
	&:hover {
		//MUI Palette grey.300
		background: #12284b;
		color: #fff;
		border: 1px solid #12284b;
	}
	&:not(:last-child) {
		margin-right: 12px;
	}
`;

const Futures = styled.div`
	width: 100%;
`;
