import { updateCategories, updateSubcategories } from "../../store/actions/categoryAction";
import { ServiceCallAction } from "../../store/middleware/serviceCallMiddleware";
import { apiClient } from "../apiClient";
import { APIClientResponse } from "../types/APIClientResponse";
import { CategoriesCodec, CategoriesResponse } from "../types/CategoriesResponseCodec";

export const getCategories = (): ServiceCallAction<CategoriesResponse> => ({
	method: "GET",
	endpoint: `categories/all`,
	serviceKey: "getCategories",
	responseDecoder: CategoriesCodec,
	type: "SERVICE_CALL",
	onSuccess: updateCategories,
});

export const getSubcategories = (parent: string): ServiceCallAction<CategoriesResponse> => ({
	method: "GET",
	endpoint: `categories/${parent}`,
	serviceKey: "getSubCategories",
	responseDecoder: CategoriesCodec,
	type: "SERVICE_CALL",
	onSuccess: categories => updateSubcategories(parent, categories),
});

export const getCategoryBioService = (slug: string): APIClientResponse<{ bio: string }> => {
	return apiClient.get(`assets/bio/category/${slug}`);
};

export const getAllCategories = (): APIClientResponse<
	{
		id: number;
		name: string;
		slug: string;
		order: number;
		image?: string | null;
	}[]
> => {
	return apiClient.get("categories/all");
};
