import { useRef, useEffect, useState, Ref, useMemo } from "react";
import { ResizeObserverEntry, useResizeWatch } from "./useResizeWatch";
import debounce from "lodash/debounce";

const DEBOUNCE_SCROLL_MS = 200;

export type Overflow = "left" | "right" | "both" | "none";

export type OverflowRegister<T> = {
	register: Ref<T>;
	overflow: Overflow;
};

interface OverflowData {
	offsetWidth: number;
	scrollWidth: number;
	scrollLeft: number;
}

/**
 * Returns the overflow status of an HTMLElement.
 */
export function useOverflowListener<T extends HTMLElement>(): OverflowRegister<T> {
	const [overflowData, setOverflowData] = useState<OverflowData>();
	const register = useRef<T>(null);
	const { current } = register;

	const overflow: Overflow = useMemo(() => {
		if (typeof overflowData === "undefined") {
			return "none";
		} else {
			let { offsetWidth, scrollWidth, scrollLeft } = overflowData;
			let nextOverflow: Overflow = scrollLeft > 0 ? "left" : "none";

			if (scrollLeft + offsetWidth < scrollWidth) {
				nextOverflow = nextOverflow === "left" ? "both" : "right";
			}
			return nextOverflow;
		}
	}, [overflowData]);

	//overflow after user scroll
	useEffect(() => {
		if (!current) {
			return;
		}
		const handleScroll = debounce(() => {
			setOverflowData({
				offsetWidth: current.offsetWidth,
				scrollWidth: current.scrollWidth,
				scrollLeft: current.scrollLeft,
			});
		}, DEBOUNCE_SCROLL_MS);

		current.addEventListener("scroll", handleScroll);
		return () => {
			current.removeEventListener("scroll", handleScroll);
		};
	}, [current]);

	//overflow after resize
	useResizeWatch(register, (element: ResizeObserverEntry) => {
		const divElement = element.target as HTMLDivElement;
		//prevent re-render
		if (!overflowData || overflowData.offsetWidth !== divElement.offsetWidth || overflowData.scrollWidth !== divElement.scrollWidth || overflowData.scrollLeft !== divElement.scrollLeft) {
			setOverflowData({
				offsetWidth: divElement.offsetWidth,
				scrollWidth: divElement.scrollWidth,
				scrollLeft: divElement.scrollLeft,
			});
		}
	});

	return { register, overflow };
}
