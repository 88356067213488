import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

//TODO: Consider finding a better way to do this.
if (window._env_?.APP_ENV === "prod") {
	console.log = () => {};
	console.warn = () => {};
	console.error = () => {};
}

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);
