import styled from "styled-components/macro";

export const Spinner = styled.div<{
	color?: string;
}>`
	margin: 0px auto;
	font-size: 5px;
	position: relative;
	text-indent: -9999em;
	${props => {
		const solidBorderColor = props.color || "#0a1529";
		return `
      border: 0.7em solid ${solidBorderColor + "18"};
      border-left-color: ${solidBorderColor};
    `;
	}}
	transform: translateZ(0);
	animation: load8 0.3s infinite linear;
	&,
	&:after {
		border-radius: 50%;
		width: 5em;
		height: 5em;
	}
`;
