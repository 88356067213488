import React, { ReactElement, useContext, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components/macro";
import { CommonPage } from "../components";
import { maxContentWidthPadding, maxContentWidthStyles } from "../util/maxContentWidth";
import { ShieldIcon, ShieldColor } from "../components/ShieldIcon/ShieldIcon";
import { useHistory } from "react-router-dom";
import { MetaDecorator, MetaTagData } from "../components/SEOMetadecorator/MetaDecorator";
import { TicketTimer } from "../components/Checkout/TicketTimer";
import { useCheckout } from "../../services/hooks/useCheckout";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { BackArrow } from "../components/BackArrow/BackArrow";
import { resetCheckoutState, setCheckoutActiveStep, setCheckoutDiscount, setCheckoutNewPaymentMethodType, setCheckoutPaymentMethod, setCheckoutPhoneNumber, setCheckoutShippingAddressIndex, setCheckoutStepValidity, setCheckoutTotalTax, setCheckoutWalletFundsUsed } from "../../store/actions/checkoutActions";
import { useDispatch, useSelector } from "react-redux";
import { InternalRoutes } from "../Link";
import { getMyAccountSettings } from "../../services/myAccount";
import { ReactComponent as SITicketsLogo } from "../resource/assets/si_tickets_logo_unstacked_cropped.svg";
import { getAppColor, getAppColorTextColor } from "../util/appColors";
import { useAutoUpdateState } from "../hooks/useAutoUpdateState";
import { useCart } from "../../services/hooks";
import { useUser } from "../../services/hooks/useUser";
import { CartItems } from "../components/Cart/CartItems";
import { PaymentMethodPriceInvoiceItem } from "../components/PaymentMethodPriceInvoiceItem/PaymentMethodPriceInvoiceItem";
import { useBraintree } from "../../services/hooks/useBraintree";
import { priceDoesNotExceedVenmoTransactionLimit, venmoTransactionLimit } from "../util/general";
import { PaymentMethodType, paymentMethodTypes, PaymentMethodWithToken } from "../../model/optimizedModel/braintree";
import { StyledComponentProps } from "../../model/optimizedModel/styles";
import { useToastRemoval } from "../hooks/useToastRemoval";
import { PayPalPayLaterMessage } from "../components/PayPalPayLaterMessage/PayPalPayLaterMessage";
import { formatPrice, shortFormatPrice } from "../util/formatPrice";
import { Dialog, verticalDialogSpacing, verticalDialogSpacingUnit, dialogOpenAnimationMS } from "../components/Dialog/Dialog";
import { Button } from "../components/NewButton/Button";
import { Typography, getTypographyStyles, FontFamily } from "../components/Typography/Typography";
import venmo_logo_blue from "../resource/img/sitix/logos/venmo_logo_blue.svg";
import { VenmoButton } from "../components/Checkout/VenmoButton";
import { Toaster } from "react-hot-toast";
import { breakpoints } from "../util/breakpoints";
import { Disclaimer, PlaceOrderButton } from "../components/Checkout/PlaceOrderButton";
import { postCartTaxquote, postCartShippingAddress, postDiscountCode, renderCartDeliveryMethod } from "../../services/cart";
import { renderAddress } from "../util/address";
import { CheckoutTermsAndConditionsCheckbox } from "../components/Checkout/CheckoutTermsAndConditionsCheckbox";
import { DeliveryNote } from "../components/Checkout/DeliveryNote";
import { DeliveryInfo } from "../components/Checkout/DeliveryInfo";
import { setCart } from "../../store/reducers/cartReducer";
import { ReactComponent as CheckmarkIcon } from "../resource/assets/icons/checkmark_icon.svg";
import { updatePromotionalWallet } from "../../store/reducers/userReducer";
import { getWalletInfo } from "../../services/user";
import { NewCreditCardForm } from "../components/Forms/NewCreditCardForm";
import { darkPaymentMethodLogos } from "../util/paymentMethodLogos";
import { RadioButton } from "../components/RadioButton/RadioButton";
import { TotalInvoiceItem } from "../components/Invoices/TotalInvoiceItem";
import { SITixWalletBadge } from "../components/SITixWalletBadge/SITixWalletBadge";
import { PaymentMethodDisplay } from "../components/MyAccount/Optimized/PaymentMethodDisplay";
import { Checkbox } from "../components/Checkbox/Checkbox";
import { AccountForm, AccountFormRef, AccountFormType } from "../components/Forms/AccountForm";
import { Browser } from "../util/UserAgent";
import { useGuest } from "../../services/hooks/useGuest";
import { Field, Form } from "react-final-form";
import { checkWhiteSpace, combineValidators, required, validPhoneNumber } from "../util/optimized/finalFormUtil";
import { FullScreenLoader } from "../components/Loader";
import { Spinner } from "../components/Loader/Spinner";
import { StyledRFFInput } from "../components/Input/Input";
import { updateGuestName } from "../../store/reducers/guestReducer";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCheckoutPaymentStep } from "../../services/hooks/useCheckoutPaymentStep";
import { CollapsibleInvoice } from "../components/CollapsibleInvoice/CollapsibleInvoice";
import { getHumanReadableDate } from "../util/getHumanReadableDate";
import { removeTimezoneFromDateString } from "../util/optimized/dates";
import { InvoiceComponentItem } from "../components/Invoices/Invoice";
import { Config } from "../../Config";
import { getAndDispatchBraintreePaymentMethods } from "../../services/braintree";
import { getTotalTicketPrice, getTransactionFee } from "../util/prices";
import { saveRegistrationData } from "../../services/event";

const amountOfRoutesToGoBack = 2;

const mobileUIBreakpoint = breakpoints.tablet;

const useDisplayMobileUI = () => useMediaQuery(`(max-width: ${mobileUIBreakpoint})`);

type CheckoutStep = {
	component: JSX.Element;
	title: string;
	disableSelection?: boolean;
};

const CheckoutRouteContext = React.createContext<{
	steps?: CheckoutStep[];
	invoiceTotal?: number;
	paidInFullWithWallet: boolean;
	hidePriceDetails?: boolean;
}>({paidInFullWithWallet: false});

const CheckoutTicketTimer = (props: Omit<React.ComponentProps<typeof TicketTimer>, "limit" | "date" | "onExpiredModalClosed">) => {
	const history = useHistory();
	return (
		<TicketTimer
			{...props}
			onExpiredModalClosed={() => {
				//TODO: Remove ts-ignore
				//@ts-ignore
				if (history.length <= amountOfRoutesToGoBack) {
					history.push(InternalRoutes.Home);
				} else {
					history.go(-amountOfRoutesToGoBack);
				}
			}}
		/>
	);
};

const useStepperControls = () => {
	const dispatch = useDispatch();
	const { activeStep, stepsValid } = useCheckout();

	return {
		goToNextStep: () => {
			dispatch(setCheckoutActiveStep(activeStep + 1));
		},
		setActiveStepIsValid: (isValid: boolean) => {
			if (stepsValid[activeStep] !== isValid) {
				dispatch(setCheckoutStepValidity(activeStep, isValid));
			}
		},
	};
};

const ContactStepContext = React.createContext<Partial<AccountFormRef> | undefined | null>(undefined);

const AccountFormRadioButton = (props: { id: Omit<AccountFormType, "register">; label: string }) => {
	const contactStepContext = useContext(ContactStepContext);

	return (
		<div
			css={`
				display: flex;
				align-items: center;
			`}
		>
			<RadioButton
				id={props.id as string}
				checked={contactStepContext?.activeForm === props.id}
				onChange={() => {
					if (contactStepContext?.setActiveForm) {
						//@ts-ignore
						contactStepContext?.setActiveForm(props.id);
					}
				}}
			/>
			<label
				htmlFor={props.id as string}
				css={`
					color: black;
					font-family: ${FontFamily.Poppins};
					line-height: 1;
					font-size: 16px;
					font-weight: 500;
					margin-left: 5px;
				`}
			>
				{props.label}
			</label>
		</div>
	);
};

const ContactStep = () => {
	const user = useUser();
	const guest = useGuest();
	const history = useHistory();

	const { setActiveStepIsValid, goToNextStep } = useStepperControls();

	useEffect(() => {
		if (user || guest) {
			setActiveStepIsValid(true);
			goToNextStep();
		}
	}, [user, guest]);

	useEffect(() => {
		history.replace(InternalRoutes.Checkout + "/contact");
	}, []);
	useToastRemoval();

	let setActiveAccountForm = useRef<React.Dispatch<React.SetStateAction<AccountFormType>> | undefined | null>(null);

	const [activeForm, setActiveForm] = useState<AccountFormType>();

	return user || guest ? null : (
		<ContactStepContext.Provider
			value={{
				activeForm,
				setActiveForm: setActiveAccountForm.current || undefined,
			}}
		>
			<div
				css={`
					display: flex;
					margin-bottom: 24px;
					& > *:not(:last-child) {
						margin-right: 49px;
					}
				`}
			>
				<AccountFormRadioButton id="guest" label="Guest" />
				<AccountFormRadioButton id="login" label="Returning" />
			</div>
			<AccountForm
				defaultForm="guest"
				ref={ref => {
					setActiveForm(ref?.activeForm);
					setActiveAccountForm.current = ref?.setActiveForm;
				}}
				submitButton={
					<Button
						typographyType="bodyLarge"
						css={`
							width: 100%;
							& > button {
								font-weight: 600;
								text-transform: uppercase;
								& path {
									color: ${getAppColorTextColor("disabled")} !important;
								}
								&:not(:disabled) path {
									color: ${getAppColor("primary")} !important;
								}
							}
						`}
					>
						<div
							css={`
								display: flex;
								align-items: center;
								position: relative;
								width: 100%;
								justify-content: center;
							`}
						>
							{activeForm === "login" ? "Sign In" : "Continue"}
							<FontAwesomeIcon
								icon={faChevronRight}
								css={`
									position: absolute;
									right: -15px;
								`}
							/>
						</div>
					</Button>
				}
			/>
			<Toaster
				position="bottom-center"
				toastOptions={{
					error: {
						style: {
							position: "absolute",
							bottom: "10vh",
							fontWeight: 500,
						},
					},
				}}
			/>
		</ContactStepContext.Provider>
	);
};

const inactiveStepColor = "darkGrey";

const Stepper = (props: StyledComponentProps) => {
	const { steps } = useContext(CheckoutRouteContext);

	const { activeStep, stepsValid } = useCheckout();

	const dispatch = useDispatch();

	return steps?.length ? (
		<div
			css={`
				display: flex;
				justify-content: space-between;
			`}
			className={props.className}
		>
			{steps.map((step, index) => {
				const isNotLastStep = steps.length !== index + 1;

				const stepsValidValues = Object.values(stepsValid);

				let disabled = false;

				if (!stepsValidValues.length) {
					disabled = true;
				} else {
					for (let i = index - 1; i >= 0; i--) {
						if (!stepsValidValues[i]) {
							disabled = true;
							break;
						}
					}
				}

				const color = activeStep !== index && disabled ? inactiveStepColor : "dark";

				return (
					<div
						css={`
							display: flex;
							${isNotLastStep &&
							`
		  flex-grow: 1;
		  &::after {
		    content: "";
		    width: 100%;
		    background: ${getAppColor(activeStep > index ? color : inactiveStepColor)};
		    height: 2px;
		    margin-top: 15px;
		  }
		`}
						`}
						key={index}
					>
						<div
							css={`
								display: flex;
								flex-direction: column;
								align-items: center;
								user-select: none;
								cursor: ${!disabled && !step.disableSelection && activeStep !== index ? "pointer" : "default"};
							`}
							onClick={() => {
								if (!disabled && !step.disableSelection) {
									dispatch(setCheckoutActiveStep(index));
								}
							}}
						>
							<div
								css={`
									border-radius: 100%;
									width: 29px;
									height: 29px;
									display: flex;
									justify-content: center;
									align-items: center;
									border: 1px solid ${getAppColor(color)};
									color: ${getAppColor(color)};
									margin-bottom: 2px;
									${activeStep >= index &&
									`
		      background: ${getAppColor(color)};
		      color: ${getAppColor(color, "text")};
		    `}
								`}
							>
								{stepsValid[index] && activeStep > index ? (
									<CheckmarkIcon
										css={`
											fill: ${getAppColor(color, "text")};
											width: 60%;
										`}
									/>
								) : (
									<Typography type="bodySmall" color="inherit">
										{index + 1}
									</Typography>
								)}
							</div>
							<Typography type="bodyTiny" color={color} fontWeight={500}>
								{step.title}
							</Typography>
						</div>
					</div>
				);
			})}
		</div>
	) : null;
};

const Items = (props: StyledComponentProps) => {
	const { walletFundsUsed, discount, totalTax } = useCheckout();
	const { invoiceTotal, hidePriceDetails } = useContext(CheckoutRouteContext);
	const { isCombinedCart, cartFuturesKeys, cartIsFree } = useCart();

	const additionalItems = useMemo(() => {
		const additionalItems: InvoiceComponentItem[] = [];
		if (!isCombinedCart) {
			if (walletFundsUsed) {
				additionalItems.push({
					key: "promotionalWalletFunds",
					value: formatPrice(-walletFundsUsed),
					title: "Wallet Credit Applied",
					borderTop: true,
					borderBottom: false,
				});
			}

			if (!!discount && discount.type === "FROM_TOTAL") {
				additionalItems.push({
					key: "discountCode",
					value: formatPrice(-discount.amount),
					title: (
						<Typography type="bodySmall">
							Discount code <strong>{discount.code}</strong>
						</Typography>
					),
					borderTop: !walletFundsUsed,
					borderBottom: false,
				});
			}
		}

		return additionalItems;
	}, [walletFundsUsed, discount]);

	const displayMobileUI = useDisplayMobileUI();

	return (
		<div
			className={props.className}
			css={`
				display: flex;
				flex-direction: column;
				text-align: left;
			`}
		>
			<CartItems
				splitFeesSection={isCombinedCart || (cartFuturesKeys?.length || 0) > 1}
				hideDelete
				hideInvoice={hidePriceDetails || cartIsFree}
				additionalFutureInvoiceItems={
					walletFundsUsed && !isCombinedCart
						? [
								{
									key: "promotionalWalletFunds",
									value: formatPrice(-walletFundsUsed),
									title: "Wallet Credit Applied",
									borderTop: true,
								},
							]
						: undefined
				}
				additionalTicketInvoiceItems={additionalItems}
			/>
			{
				!hidePriceDetails && (
					<>
						{!discount || (!!discount?.newTotal && discount?.newTotal - walletFundsUsed > 0) ? (
							<TotalInvoiceItem
								title={isCombinedCart ? "Total Price" : "Total"}
								css={{
									padding: "18px 0",
									borderBottomWidth: displayMobileUI ? 1 : undefined,
									borderColor: getAppColor("darkGrey", "light"),
									borderStyle: "solid",
									borderTopWidth: 1,
								}}
								total={(!!discount?.newTotal ? discount?.newTotal - walletFundsUsed || 0 : invoiceTotal || 0) + (totalTax || 0)}
							/>
						) : (
							<div
								css={`
									height: 20px;
								`}
							/>
						)}
					</>
				)
			}
		</div>
	);
};

const siTixLogoWidth = "150px";

export const CheckoutRoute = () => {
	const { cart, multipleDeliveryOptions, delivery, cartPrices, flatRatePrice, ticket, transactionFees, cartFuturesKeys, ticketSubtotal, cartIsFree} = useCart();

	const user = useUser();

	const guest = useGuest();

	useEffect(() => {
		if (user) {
			getWalletInfo().then(result => {
				dispatch(updatePromotionalWallet(result.data.wallet.promotionalWallet));
			});
		}
		if (!cart) {
			history.push(InternalRoutes.Home);
		}
		return () => {
			dispatch(resetCheckoutState);
		};
	}, [user?.id]);

	const checkoutState = useCheckout();

	const displayMobileUI = useDisplayMobileUI();

	const history = useHistory();

	const dispatch = useDispatch();

	const [checkingUserSession, setCheckingUserSession] = useState(true);

	const hasSessionToken = useSelector(st => st.persistent.session.sessionToken !== undefined);

	const [paidInFullWithWallet, setPaidInFullWithWallet] = useState(false);

	useEffect(() => {
		if (cartPrices?.PromoWallet) {
			const discountedAmount = checkoutState.discount?.newTotal ? checkoutState.discount.newTotal : cartPrices.PromoWallet;
			setPaidInFullWithWallet(checkoutState.walletFundsUsed >= discountedAmount);
		}
	}, [cartPrices?.PromoWallet, checkoutState.walletFundsUsed]);

	//Note: This below logic was added in order to detect if a user account session is expired. Our axios API client will log the user out if the getMyAccountSettings call returns an unauthenticated error.
	useEffect(() => {
		if (hasSessionToken) {
			setCheckingUserSession(true);
			getMyAccountSettings().finally(() => {
				setCheckingUserSession(false);
			});
		} else {
			setCheckingUserSession(false);
		}
	}, []);

	const showDeliveryStep = useRef(false);

	useEffect(() => {
		showDeliveryStep.current = multipleDeliveryOptions || delivery?.method === "FedEx";
	}, [multipleDeliveryOptions, delivery?.method]);

	const steps = useAutoUpdateState(() => {
		const arr: CheckoutStep[] = [
			{
				component: <ContactStep />,
				title: "Contact",
				disableSelection: true,
			},
		];
		if (showDeliveryStep.current) {
			arr.push({
				component: <DeliveryStep />,
				title: "Delivery",
			});
		}
		if (!cartIsFree) {
			arr.push({
				component: <PaymentStep />,
				title: "Payment",
			});
		}
		arr.push({
			component: <ConfirmStep />,
			title: "Confirm",
		});
		return arr;
	}, [showDeliveryStep.current]);

	const { activeStep, selectedPaymentMethodOption, walletFundsUsed } = useCheckout();

	const invoiceTotal = useAutoUpdateState(() => {
		if (steps && activeStep + 2 >= steps.length) {
			if (flatRatePrice) {
				return flatRatePrice - walletFundsUsed;
			} else if (cartPrices && selectedPaymentMethodOption) {
				return cartPrices[walletFundsUsed ? "PromoWallet" : selectedPaymentMethodOption.type] - walletFundsUsed;
			}
		} else if (selectedPaymentMethodOption && cartPrices && activeStep + 1 === steps?.length) {
			return cartPrices[walletFundsUsed ? "PromoWallet" : selectedPaymentMethodOption.type] - walletFundsUsed;
		}
		return undefined;
	}, [flatRatePrice, selectedPaymentMethodOption, cartPrices, walletFundsUsed, steps?.length, activeStep]);

	useEffect(() => {
		if (checkoutState.shippingAddressIndex !== -1) {
			const address = (user || guest)?.addresses[checkoutState.shippingAddressIndex as number];
			if (address) {
				postCartShippingAddress(address).then(result => {
					dispatch(setCart(result.data.cart));
				});
			}
		}
	}, [checkoutState.shippingAddressIndex]);

	useEffect(() => {
		const primaryShippingAddressIndex = user?.addresses.findIndex(address => address.id === user.primaryShippingAddressID);
		if (delivery?.method === "FedEx" && (checkoutState.shippingAddressIndex === -1 || primaryShippingAddressIndex === checkoutState.shippingAddressIndex) && primaryShippingAddressIndex !== undefined) {
			dispatch(setCheckoutShippingAddressIndex(primaryShippingAddressIndex));
		}
	}, [user]);

	const hidePriceDetails = useAutoUpdateState(() => activeStep !== ((steps?.length || 0) - 1), [steps?.length, activeStep]);
	const walletFunds = useSelector(st => st.persistent.user?.promotionalWalletCredit) || 0;
	
	useEffect(() => {
		dispatch(setCheckoutTotalTax(null));
		if (!!cart && activeStep === (showDeliveryStep.current ? 3 : 2)) {
			let reservationAmount = 0;
			//@ts-ignore
			const reservations = cart.futures === [] ? [] : cartFuturesKeys?.map(key => {
				const future = cart.futures[key];
				const {
					quantity,
				} = future;
				reservationAmount += future.zone.reservationPrice * quantity;
				return {
					price: future.zone.reservationPrice * quantity,
					quantity,
					eventName: future.event.title,
					venue: {
						...future.event.stadium.address,
						name: future.event.stadium.title,
					},
				};
			});
			
			let taxQuoteTicket
			if (!!ticket && !!transactionFees && !!selectedPaymentMethodOption && !!cartPrices) {
				const {
					quantity,
					retailPrice,
				} = ticket;
				const discount = checkoutState.discount;
				const transactionFeeRaw =  checkoutState.discount?.type === "PROMO_FEE"?  checkoutState.discount.newtransactionFee.value: ticket?.transactionFeeRules[checkoutState.walletFundsUsed ? "PromoWallet" : checkoutState.selectedPaymentMethodOption?.type || "CreditCard"].value;
				const transactionFeeType = checkoutState.discount?.type === "PROMO_FEE"?  checkoutState.discount.newtransactionFee.type: ticket?.transactionFeeRules[checkoutState.walletFundsUsed ? "PromoWallet" : checkoutState.selectedPaymentMethodOption?.type || "CreditCard"].type;
				
				let ticketSubTotal = 0;
				let quantityAll = 0;
				if (cart?.source === "boxoffice"){
					cart?.tickets[0]?.tiers.forEach(tier => {
						ticketSubTotal += (tier.retailPrice * tier.quantity);
						quantityAll += tier.quantity;
					});
				} else {
					ticketSubTotal = retailPrice * quantity;
					quantityAll = quantity;
				}

				const finalPrice = checkoutState.discount?.type === "FROM_TOTAL" ? checkoutState.discount.ticketTotal : ticketSubTotal;


				const transactionFee = transactionFeeType === "DECIMAL" ? transactionFeeRaw * finalPrice : transactionFeeRaw;
				taxQuoteTicket = {
					price: !!discount ? discount.newPrice - reservationAmount : ticketSubtotal,
					quantity: quantityAll,
					shippingFee: Number(delivery?.options[delivery?.selected].price),
					transactionFee,
					taxCode: ticket?.event.category.taxcode,
					eventName: ticket?.event.name,
					venue: ticket?.venue,
				};
			}
			if(cart.tax){
				dispatch(setCheckoutTotalTax(cart.tax));
			}
			if (!cart.tax && (taxQuoteTicket || reservations?.length)) {
				postCartTaxquote({
					ticket: taxQuoteTicket,
					reservations,
				}).then(result => {
					dispatch(setCheckoutTotalTax(result.data.totalTax));
					
					// Recalculate walletFundsUsed if Promo Wallet Checked
					if (cartPrices && checkoutState.walletFundsUsed > 0) {
						const walletFundsUsed = walletFunds >= cartPrices.PromoWallet + result.data.totalTax ? cartPrices.PromoWallet + result.data.totalTax : walletFunds;
						const discountAmount = checkoutState.discount?.newTotal ? cartPrices.PromoWallet - checkoutState?.discount?.newTotal : 0;
						dispatch(setCheckoutWalletFundsUsed(walletFundsUsed + discountAmount < cartPrices.PromoWallet ? walletFundsUsed : walletFundsUsed - discountAmount));
					}
				});
			}
		}
	}, [activeStep]);

	return (
		<CommonPage
			hideAllNavigation
			hideHeader={displayMobileUI}
			hideMobileAppNav={displayMobileUI}
			isFooterHidden
			headerChildren={
				<div
					css={`
						display: flex;
						width: 100%;
						align-items: center;
						justify-content: flex-end;
					`}
				>
					<CheckoutTicketTimer
						css={`
							margin-right: 12px;
						`}
					/>
					<ShieldIcon color={ShieldColor.Black} />
				</div>
			}
		>
			<FullScreenLoader show={checkingUserSession} />
			<MetaDecorator title={MetaTagData.Checkout.title} description={MetaTagData.Checkout.description} />
			<CheckoutRouteContext.Provider
				value={{
					steps,
					invoiceTotal,
					paidInFullWithWallet,
					hidePriceDetails,
				}}
			>
				{displayMobileUI && (
					<div
						css={`
							display: flex;
							flex-direction: column;
							background: ${getAppColor("light")};
							padding: 14px ${maxContentWidthPadding}px;
						`}
					>
						<div
							css={`
								display: flex;
								align-items: center;
								margin-bottom: 19px;
								& > * {
									&:first-child,
									&:last-child {
										flex: 1;
									}
								}
							`}
						>
							<div
								css={`
									display: flex;
									justify-content: flex-start;
								`}
							>
								<BackArrowWrapper
									onClick={() => {
										if (checkoutState.activeStep > 1) {
											dispatch(setCheckoutActiveStep(checkoutState.activeStep - 1));
										} else {
											if (!!document.referrer) {
												// This logic checks if the user came from a social sign in flow, if they did then they're brought back 3 pages in order to avoid redirecting them back to the external/third party social sign in flow.
												history.go(-3);
											} else {
												// Browser and history action checked in order to fix issue in which the back button doesn't work on Chrome IOS after being redirected to the page from the venmo app
												//@ts-ignore
												Browser.ChromeIOS && history.action === "POP" && history.length === 1 ? history.replace(InternalRoutes.Home) : history.go(-1);
											}
										}
									}}
								>
									<BackArrow
										css={`
											&,
											* {
												stroke: ${getAppColor("darkGrey", "dark")};
											}
										`}
									/>
								</BackArrowWrapper>
							</div>
							<SITicketsLogo
								css={`
									max-width: ${siTixLogoWidth};
									width: ${siTixLogoWidth};
								`}
							/>
							<div
								css={`
									display: flex;
									justify-content: flex-end;
								`}
							>
								<CheckoutTicketTimer size="small" />
							</div>
						</div>
						<Stepper />
					</div>
				)}
				<div
					css={`
						display: flex;
						${maxContentWidthStyles}
						margin-bottom: 85px;
						align-items: flex-start;
						width: 100%;
						margin-top: 25px;
						@media (max-width: ${mobileUIBreakpoint}) {
							margin-top: 7px;
						}
					`}
				>
					<div
						css={`
							width: 100%;
						`}
					>
						{!displayMobileUI && (
							<Stepper
								css={`
									margin-bottom: 25px;
								`}
							/>
						)}
						{!checkingUserSession && steps[checkoutState.activeStep]?.component}
					</div>
					{!displayMobileUI && (
						<div
							css={`
								width: 66%;
								margin-left: 36px;
								background: ${getAppColor("light")};
								border: 1px solid ${getAppColor("darkGrey", "light")};
								padding: 0 16px;
								padding-top: 10px;
							`}
						>
							<Items />
						</div>
					)}
				</div>
			</CheckoutRouteContext.Provider>
		</CommonPage>
	);
};

const PaymentStepContext = React.createContext<
	| {
			totalExceedsVenmoTransactionLimit: boolean;
			cartPrices: ReturnType<typeof useCart>["cartPrices"];
			isPaymentMethodTypeDisabled: (paymentMethodType: PaymentMethodType) => boolean;
	  }
	| undefined
>(undefined);

const PaymentStepPaymentMethodOption = (props: Omit<React.ComponentProps<typeof PaymentMethodPriceInvoiceItem>, keyof StyledComponentProps | "price">) => {
	const dispatch = useDispatch();

	useToastRemoval();

	const billingInfoContext = useContext(PaymentStepContext);
	const { selectedPaymentMethod, isRealPaymentMethod, disabledPaymentMethod, price, paymentMethodIsSelected } = useCheckoutPaymentStep(props.paymentMethod, billingInfoContext);

	return price === undefined ? null : (
		<PaymentStepPaymentMethodOptionWrapper>
			<PaymentStepPaymentMethodOptionLabel
				style={
					disabledPaymentMethod
						? {
								pointerEvents: "none",
								opacity: 0.5,
						  }
						: undefined
				}
				checked={paymentMethodIsSelected}
				disabled={disabledPaymentMethod}
			>
				<RadioButton
					checked={paymentMethodIsSelected}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						if (event.target.checked) {
							if (isRealPaymentMethod) {
								dispatch(setCheckoutPaymentMethod(selectedPaymentMethod as PaymentMethodWithToken));
							} else {
								dispatch(setCheckoutNewPaymentMethodType(selectedPaymentMethod.type));
							}
						}
					}}
					disabled={disabledPaymentMethod}
					css={`
						margin-right: 13px;
					`}
				/>
				<PaymentMethodPriceInvoiceItem
					{...props}
					css={{
						"span[class*='Subtitle']": {
							"font-weight": "400",
						},
					}}
				/>
			</PaymentStepPaymentMethodOptionLabel>
			{selectedPaymentMethod.type === "PayPalAccount" && (
				<PayPalPayLaterMessage
					price={price}
					logoType="none"
					textAlign="center"
					css={`
						margin-top: 10px;
						margin-bottom: -3px;
					`}
				/>
			)}
			{selectedPaymentMethod.type === "VenmoAccount" && billingInfoContext?.totalExceedsVenmoTransactionLimit && <PaymentStepPaymentMethodOptionVenmoDisclaimer>Venmo restricts transactions over {shortFormatPrice(venmoTransactionLimit)}, please select PayPal or a Credit Card to complete the transaction.</PaymentStepPaymentMethodOptionVenmoDisclaimer>}
		</PaymentStepPaymentMethodOptionWrapper>
	);
};

const PaymentMethodButton = (props: Omit<React.ComponentProps<typeof PaymentMethodPriceInvoiceItem>, keyof StyledComponentProps | "price">) => {
	const dispatch = useDispatch();
	useToastRemoval();
	const billingInfoContext = useContext(PaymentStepContext);
	const { forceCreditCardSelection, selectedPaymentMethod, isRealPaymentMethod, disabledPaymentMethod } = useCheckoutPaymentStep(props.paymentMethod, billingInfoContext);
	const checkoutState = useCheckout();

	return (
		<PaymentMethodSelector
			selected={checkoutState.paymentMethod?.type === props.paymentMethod.type || checkoutState.newPaymentMethodType === props.paymentMethod.type || forceCreditCardSelection}
			disabled={disabledPaymentMethod}
			onClick={() => {
				if (!disabledPaymentMethod) {
					if (isRealPaymentMethod) {
						dispatch(setCheckoutPaymentMethod(selectedPaymentMethod as PaymentMethodWithToken));
					} else {
						dispatch(setCheckoutNewPaymentMethodType(selectedPaymentMethod.type));
					}
				}
			}}
		>
			<img src={darkPaymentMethodLogos[props.paymentMethod.type]} alt="Payment method logo" />
			{props.paymentMethod.type === "CreditCard" && (
				<Typography
					type="bodyExtraTiny"
					css={`
						margin-left: 9px;
						font-weight: 600;
						white-space: nowrap;
					`}
				>
					Credit Card
				</Typography>
			)}
		</PaymentMethodSelector>
	);
};

const PaymentStep = () => {
	const checkoutState = useCheckout();

	const {
		hidePriceDetails,
	} = useContext(CheckoutRouteContext);

	const [showCreditCardForm, setShowCreditCardForm] = useState(false);
	const cardFormRef = useRef<HTMLDivElement>();

	const history = useHistory();
	const displayMobileUI = useDisplayMobileUI();
	const { braintreeState } = useBraintree();

	useEffect(() => {
		if (cardFormRef.current) {
			if (showCreditCardForm) {
				window.scrollTo({ top: cardFormRef.current?.getBoundingClientRect().top, left: 0, behavior: "smooth" });
			} else {
				window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
			}
		}
	}, [showCreditCardForm, cardFormRef.current]);

	useEffect(() => {
		if(braintreeState?.clientAuth){
			getAndDispatchBraintreePaymentMethods();
		}
	}, [braintreeState.clientAuth]);

	const dispatch = useDispatch();
	const walletFunds = useSelector(st => st.persistent.user?.promotionalWalletCredit) || 0;

	const noPaymentMethodSelected = useAutoUpdateState(() => {
		return !checkoutState.paymentMethod;
	}, [checkoutState.paymentMethod]);

	const { cart, ticket, cartFuturesKeys, cartPrices } = useCart();

	const [walletSelected, setWalletSelected] = useState(false);
	const [walletDebt, setWalletDebt] = useState(0);

	useEffect(() => {
		if (cartPrices) {
			const discountAmount = checkoutState.discount?.newTotal ? cartPrices?.PromoWallet - checkoutState.discount.newTotal : 0;
			const debt = cartPrices.PromoWallet - walletFunds - discountAmount;
			setWalletDebt(debt > 0 ? debt : 0);
		}
	}, [walletFunds, cartPrices?.PromoWallet, checkoutState.discount]);

	const { setActiveStepIsValid, goToNextStep } = useStepperControls();
	const { invoiceTotal, paidInFullWithWallet } = useContext(CheckoutRouteContext);

	const continueDisabled = useAutoUpdateState(() => {
		const value = !paidInFullWithWallet && noPaymentMethodSelected && checkoutState.newPaymentMethodType !== "PayPalAccount" && (!walletSelected || !!walletDebt);
		return value;
	}, [noPaymentMethodSelected, checkoutState.newPaymentMethodType, walletDebt, walletSelected, paidInFullWithWallet]);

	const reservationTotal = useAutoUpdateState(() => {
		let futurePrices = 0;
		if(cart && cartFuturesKeys && cartFuturesKeys.length > 0){
			cartFuturesKeys.forEach((key: string) => {
				futurePrices += cart.futures[key].zone.reservationPrice;
			})
		}
		return {
			price: futurePrices,
			quantity: cartFuturesKeys?cartFuturesKeys.length:0
		}
	}, [cart?.futures]);

	useEffect(() => {
		setActiveStepIsValid(!continueDisabled);
	}, [continueDisabled]);

	useEffect(() => {
		if (cartPrices && walletSelected) {
			const walletFundsUsed = walletFunds >= cartPrices.PromoWallet ? cartPrices.PromoWallet : walletFunds;

			const discountAmount = checkoutState.discount?.newTotal ? cartPrices.PromoWallet - checkoutState?.discount?.newTotal : 0;
			dispatch(setCheckoutWalletFundsUsed(walletFundsUsed + discountAmount < cartPrices.PromoWallet ? walletFundsUsed : walletFundsUsed - discountAmount));
		} else {
			dispatch(setCheckoutWalletFundsUsed(0));
		}
	}, [walletSelected, cartPrices?.PromoWallet, checkoutState.discount]);

	const totalExceedsVenmoTransactionLimit = useAutoUpdateState(() => {
		if (cartPrices) {
			return !priceDoesNotExceedVenmoTransactionLimit(cartPrices.VenmoAccount - checkoutState.walletFundsUsed);
		}
		return false;
	}, [cartPrices?.VenmoAccount, checkoutState.walletFundsUsed]);
	const isPaymentMethodTypeDisabled = (paymentMethodType: PaymentMethodType) => (paymentMethodType === "VenmoAccount" && totalExceedsVenmoTransactionLimit) || (walletSelected && !walletDebt) || Config.getDisabledPaymentMethods().includes(paymentMethodType);

	const paymentMethodAdded = (
		paymentMethod: PaymentMethodWithToken,
		options?: {
			preventGoToNextStep?: boolean;
		}
	) => {
		if (!isPaymentMethodTypeDisabled(paymentMethod.type)) {
			dispatch(setCheckoutPaymentMethod(paymentMethod));
			if (paymentMethod.type === "VenmoAccount") {
				setShowVenmoVerificationDialog(true);
			} else if (!options?.preventGoToNextStep) {
				if (!checkoutState.stepsValid[checkoutState.activeStep]) {
					setActiveStepIsValid(true);
				}
				goToNextStep();
			}
		}
	};

	const [showVenmoVerificationDialog, setShowVenmoVerificationDialog] = useState(false);

	const onVenmoVerificationDialogClose = () => {
		goToNextStep();
	};

	const guest = useGuest();
	const user = useUser();

	const paymentMethods = useAutoUpdateState(() => {
		if (!guest) {
			return braintreeState.paymentMethods;
		}
	}, [braintreeState.paymentMethods, !guest]);

	useEffect(() => {
		// Forces selection of credit card as the first option
		dispatch(setCheckoutNewPaymentMethodType("CreditCard"));
	}, []);

	const hasCreditCard = useMemo(() => {
		return !!paymentMethods?.some(paymentMethod => paymentMethod.type === "CreditCard");
	}, [paymentMethods]);

	useEffect(() => {
		setShowCreditCardForm(!paidInFullWithWallet && (false || !hasCreditCard) && checkoutState.newPaymentMethodType === "CreditCard");
	}, [checkoutState.newPaymentMethodType, hasCreditCard, paymentMethods, paidInFullWithWallet]);

	useEffect(() => {
		history.replace(InternalRoutes.Checkout + "/payment");
		dispatch(setCheckoutDiscount(undefined));
	}, []);

	const [discountCodeFormStatus, setDiscountCodeFormStatus] = useState<"hidden" | "visible" | "success" | "error">("hidden");
	const [discountErrorMessage, setDiscountErrorMessage] = useState("");

	const transactionFeeRule = useMemo(() => {
		return !!checkoutState.selectedPaymentMethodOption || checkoutState.walletFundsUsed ? ticket?.transactionFeeRules[checkoutState.walletFundsUsed ? "PromoWallet" : checkoutState.selectedPaymentMethodOption?.type || "CreditCard"] : undefined;
	}, [checkoutState.selectedPaymentMethodOption, checkoutState.walletFundsUsed, ticket?.transactionFeeRules]);

	const handleDiscountCode = async (values: { discountCode: string }) => {
		const email = guest?.email || user?.email;

		if (cartPrices && invoiceTotal && cart && email) {
			try {
				const result = await postDiscountCode(email, values.discountCode, invoiceTotal, cart.cartId, transactionFeeRule || ticket?.transactionFeeRules["CreditCard"]);
				const ticketQuantity = cart.quantity - reservationTotal.quantity;

				let ticketSubTotal = 0;
				if (cart?.source === "boxoffice"){
					cart?.tickets[0]?.tiers.forEach(tier => {
						ticketSubTotal += (tier.retailPrice * tier.quantity);
					});
				} else {
					ticketSubTotal = cart?.tickets[0].retailPrice * ticketQuantity;
				}
				
				const originalSubtotal = ticketSubTotal + reservationTotal.price;
				// Since the BE cannot tell if the order has a shipping fee nor a service fee, i have to calculate the new total of the order here
				const totalCalculationParams = {
					subtotal: result.data.discount.type === "FROM_TOTAL" ? result.data.discount.newPrice : originalSubtotal,
					transactionFee: getTransactionFee(result.data.discount.type === "PROMO_FEE"?  result.data.discount.newtransactionFee: transactionFeeRule || ticket?.transactionFeeRules["CreditCard"], cart?.tickets[0].retailPrice, ticketQuantity, cart?.source, ticket?.tiers),
					serviceFee: (cart?.serviceFeeRate || 0) * (cart?.subtotal || 0),
					deliveryFee: cart?.shippingFee,
				};
				const ticketTotal = result.data.discount.newPrice - reservationTotal.price;
				dispatch(setCheckoutDiscount({ ...result.data.discount, ticketTotal, code: values.discountCode, amount: originalSubtotal - result.data.discount.newPrice, originalSubtotal: originalSubtotal, newTotal: getTotalTicketPrice(totalCalculationParams) }));
				setDiscountCodeFormStatus("success");
				setDiscountErrorMessage("");
			} catch (error: any) {
				setDiscountCodeFormStatus("error");
				setDiscountErrorMessage(error.errors || "The discount code entered is invalid.");
			}
		}
	};
	useEffect(() => {
		// I don't add checkotuState.discount to the dependency array because this only should be called when the selected payment method changes
		if (!!checkoutState.discount) {
			handleDiscountCode({ discountCode: checkoutState.discount.code });
		}
	}, [checkoutState.selectedPaymentMethodOption, checkoutState.walletFundsUsed]);

	const discountCodeFormStates = useMemo(() => {
		return {
			hidden: (
				<DiscountCodeSectionMesssage
					onClick={() => {
						setDiscountCodeFormStatus("visible");
					}}
					type="bodySmall"
					color="darkGrey"
					colorType="dark"
					fontWeight={400}
					css={`
						@media (min-width: ${breakpoints.tablet}) {
							padding-bottom: 20px;
						}
					`}
				>
					Apply Discount Code
				</DiscountCodeSectionMesssage>
			),
			visible: (
				<Form
					onSubmit={handleDiscountCode}
					render={form => {
						return (
							<DiscountCodeFormContainer onSubmit={form.handleSubmit}>
								<Field<string> name="discountCode" disabled={form.submitting} placeholder="Discount Code" validate={value => required(value)} component={DiscountCodeInput} />
								<DiscountCodeSubmit
									css={`
										margin-left: 15px;
									`}
									type="submit"
									disabled={form.invalid}
								>
									Apply
								</DiscountCodeSubmit>
							</DiscountCodeFormContainer>
						);
					}}
				/>
			),
			error: (
				<Typography type="bodySmall" color="error" fontWeight={400}>
					{`${discountErrorMessage}  `} 
					<a
						onClick={() => {
							setDiscountCodeFormStatus("visible");
						}}
						style={{ color: getAppColor("darkGrey", "dark"), cursor: "pointer" }}
					>
						Try another one
					</a>
				</Typography>
			),
			success: (
				<Typography type="bodySmall" color="success" colorType="dark" fontWeight={400}>
					Discount code <strong>{checkoutState?.discount?.code}</strong> successfully applied
				</Typography>
			),
		};
	}, [checkoutState?.discount, invoiceTotal, discountErrorMessage]);

	const promotionalCreditToDisplay = useMemo(() => {
		if (walletDebt > 0) {
			return walletFunds;
		}

		if (cartPrices?.PromoWallet) {
			const priceWithWallet = cartPrices.PromoWallet;
			const discountAmount = checkoutState.discount?.newTotal ? priceWithWallet - checkoutState.discount.newTotal : 0;

			return priceWithWallet >= discountAmount ? priceWithWallet - discountAmount : priceWithWallet;
		}
		return 0;
	}, [walletDebt, walletFunds, cartPrices?.PromoWallet, checkoutState?.discount]);

	return (
		<PaymentStepContext.Provider
			value={{
				totalExceedsVenmoTransactionLimit,
				cartPrices,
				isPaymentMethodTypeDisabled,
			}}
		>
			{displayMobileUI && cart && (
				<CollapsibleRowsContainer>
					{ticket && (
						<CollapsibleInvoice
							serviceFee={cart.serviceFee}
							eventId={ticket.event.id}
							tiersInfo={ticket.tiers}
							quantity={ticket.quantity}
							transactionFeeRule={transactionFeeRule}
							deliveryFee={cart?.shippingFee || 0}//TAXVALUE
							tax={checkoutState.totalTax}
							serviceFeeRate={cart?.serviceFeeRate || 0}
							price={ticket.retailPrice}
							section={ticket.section}
							row={ticket.row}
							name={ticket.event.name}
							occursAt={getHumanReadableDate(new Date(removeTimezoneFromDateString(ticket.event.occursAt)))}
							walletFundsUsed={!cartFuturesKeys?.length ? checkoutState.walletFundsUsed : 0}
							total={!cartFuturesKeys?.length ? invoiceTotal : undefined}
							invoiceType="ticket"
							hidePriceDetails={hidePriceDetails}
							venue={{
								name: ticket.venue.name,
								city: ticket.venue.city,
								country: ticket.venue.country,
								state: ticket.venue.state,
							}}
						/>
					)}
					{cartFuturesKeys?.reduce((result, key, index) => {
						const cartFuture = cart.futures[key];
						if (cartFuture) {
							const { cartItemId, ...future } = cartFuture;

							result.push(
								<React.Fragment key={index}>
									{future && (
										<CollapsibleInvoice
											tax={checkoutState.totalTax}
											serviceFee={cart.serviceFee}
											eventId={future.event.eventId}
											quantity={future.quantity}
											transactionFeeRule={undefined}
											deliveryFee={0}
											serviceFeeRate={cart?.serviceFeeRate || 0}
											price={future.zone.reservationPrice}
											section={future.zone.title}
											row={""} //There's no row field in reservations but this field is required
											name={future.event.title}
											occursAt={future.event.date ? getHumanReadableDate(new Date(removeTimezoneFromDateString(future.event.date))) : "TBD"}
											invoiceType="reservation"
											walletFundsUsed={index === cartFuturesKeys.length - 1 ? checkoutState.walletFundsUsed : 0}
											total={!ticket && cartFuturesKeys.length === 1 ? future.quantity * future.zone.reservationPrice - checkoutState.walletFundsUsed : index === cartFuturesKeys.length - 1 ? invoiceTotal : undefined}
											totalSeparator={!!ticket || cartFuturesKeys.length > 1}
											hidePriceDetails={hidePriceDetails}
											venue={{
												name: future.event.stadium.title,
												city: future.event.stadium?.address?.city || "",
												country: future.event.stadium?.address?.country || "",
												state: future.event.stadium?.address?.state || "",
											}}
										/>
									)}
								</React.Fragment>
							);
						}
						return result;
					}, [] as React.ReactNode[])}
				</CollapsibleRowsContainer>
			)}
			<Content>
				<Dialog
					open={showVenmoVerificationDialog}
					onClose={() => {
						if (showVenmoVerificationDialog) {
							setShowVenmoVerificationDialog(false);
						}
						onVenmoVerificationDialogClose();
					}}
					type="information"
					headerChildren={
						<VenmoVerificationDialogHeaderChildren>
							<VenmoVerificationDialogLogo src={venmo_logo_blue} />
						</VenmoVerificationDialogHeaderChildren>
					}
				>
					<VenmoVerificationDialogContent>
						<Typography type="bodyNormal">
							Venmo requires you to <strong>verify your identity</strong> prior to completing purchases above $300/week.
							<hr />
							Please make sure you have verified your identity in the Venmo app before you continue:
							<br />
							<strong>
								<ol>
									<li>Open Venmo. Select the gear icon on the top right of your profile tab.</li>
									<li>Scroll down and select “Identity Verification”. Complete the process.</li>
									<li>Once verified, return to our checkout and complete your purchase.</li>
								</ol>
							</strong>
						</Typography>
						<Button
							color="venmo"
							onClick={() => {
								setTimeout(() => {
									onVenmoVerificationDialogClose();
								}, dialogOpenAnimationMS);
								setShowVenmoVerificationDialog(false);
							}}
						>
							Continue
						</Button>
					</VenmoVerificationDialogContent>
				</Dialog>
				{!paymentMethods && !guest ? (
					<Spinner />
				) : (
					<>
						{!!ticket && !walletSelected && (
							<DiscountCodeFormSection formVisible={discountCodeFormStatus === "visible"}>
								{discountCodeFormStates[discountCodeFormStatus]}
								{cart?.source === "boxoffice" &&
									<PromotionalCreditsSectionMesssage
										type="bodySmall"
										color="darkGrey"
										colorType="dark"
										fontWeight={400}
									>
										Promotional Credits cannot be applied to this order.
									</PromotionalCreditsSectionMesssage>
								}
							</DiscountCodeFormSection>
						)}
						<PaymentTitle>{!paidInFullWithWallet ? "Select payment method" : "No credit card needed"}</PaymentTitle>
						{walletFunds > 0 && !checkoutState.discount && cart?.source !== "boxoffice" && (
							<>
								<SITixWalletBadge />
								<div
									css={`
										margin: 12px 0 25px;
										padding-bottom: 25px;
										border-bottom: 1px solid ${getAppColor("darkGrey", "light")};

										@media (max-width: ${breakpoints.mobile}) {
											margin-top: 0;
										}
									`}
								>
									{cartPrices && (
										<Checkbox
											checked={walletSelected}
											onChange={() => {
												setWalletSelected(!walletSelected);
											}}
											label={
												<WalletInfo>
													Apply <strong>{formatPrice(promotionalCreditToDisplay)}</strong> Promotional Credit to this order
												</WalletInfo>
											}
										/>
									)}
								</div>
							</>
						)}

						{walletDebt > 0 && walletSelected && (
							<PartialDebtText>
								Please select a payment method to pay the remaining <strong>{formatPrice(walletDebt)}</strong>
							</PartialDebtText>
						)}
						{!paidInFullWithWallet &&
							<PaymentMethodsSelectorsContainer>
								{paymentMethodTypes.map((paymentMethodType, index) => {
									return <PaymentMethodButton key={index} paymentMethod={paymentMethods?.find(paymentMethod => paymentMethod.type === paymentMethodType) || { type: paymentMethodType }} />;
								})}
							</PaymentMethodsSelectorsContainer>
						}

						{totalExceedsVenmoTransactionLimit && <PaymentStepPaymentMethodOptionVenmoDisclaimer>Venmo restricts transactions over {shortFormatPrice(venmoTransactionLimit)}, please select PayPal or a Credit Card to complete the transaction.</PaymentStepPaymentMethodOptionVenmoDisclaimer>}
						{!paidInFullWithWallet && (checkoutState.paymentMethod?.type === "CreditCard" || checkoutState.newPaymentMethodType === "CreditCard") && (
							<>
								{(() => {
									const arr: ReactElement<React.ComponentProps<typeof PaymentStepPaymentMethodOption>>[] = [];

									paymentMethods?.map(paymentMethod => paymentMethod.type).includes("CreditCard") && paymentMethods?.forEach((paymentMethod, paymentMethodIndex) => paymentMethod.type === "CreditCard" && arr.push(<PaymentStepPaymentMethodOption key={paymentMethodIndex} paymentMethod={paymentMethod} />));

									return arr;
								})()}
								{hasCreditCard && (
									<StyledToggle>
										<AddCardButton
											onClick={() => {
												setShowCreditCardForm(!showCreditCardForm);
											}}
											type="button"
										>
											<div>+</div>
											<img src={darkPaymentMethodLogos.CreditCard} alt="Add Card Logo" />
											<span>Add Card</span>
										</AddCardButton>
									</StyledToggle>
								)}
								<Collapsible visible={showCreditCardForm} ref={cardFormRef as any}>
									<NewCreditCardForm
										onCardAdded={paymentMethodAdded}
										customButton={(disabled: boolean) => {
											return (
												<Button type="submit" disabled={disabled} loading={false} size="large">
													Continue
												</Button>
											);
										}}
									/>
								</Collapsible>
							</>
						)}
					</>
				)}
				<div
					css={`
						margin: 0 auto;
						margin-top: 32px;
						display: flex;
						flex-direction: column;
						align-items: center;
					`}
				>
					<Toaster
						toastOptions={{
							error: {
								style: {
									position: "absolute",
									top: "8vh",
									textAlign: "left",
									fontWeight: 500,
								},
							},
						}}
					/>

					{!paidInFullWithWallet && checkoutState.newPaymentMethodType && checkoutState.newPaymentMethodType !== "CreditCard" ? (
						{
							VenmoAccount: (
								<VenmoButton
									//TODO: Consider making the types for the "Add Payment Method" components more DRY.
									paymentMethodAdded={paymentMethodAdded}
								/>
							),
							PayPalAccount: (
								<Button
									disabled={continueDisabled}
									onClick={() => {
										if ((checkoutState.paymentMethod?.type || checkoutState.newPaymentMethodType) === "VenmoAccount") {
											setShowVenmoVerificationDialog(true);
										} else {
											goToNextStep();
										}
									}}
									size="large"
								>
									Continue
								</Button>
							),
						}[checkoutState.newPaymentMethodType]
					) : (
						<>
							{!showCreditCardForm && (
								<Button
									disabled={continueDisabled}
									onClick={() => {
										if ((checkoutState.paymentMethod?.type || checkoutState.newPaymentMethodType) === "VenmoAccount") {
											setShowVenmoVerificationDialog(true);
										} else {
											goToNextStep();
										}
									}}
									size="large"
								>
									Continue
								</Button>
							)}
						</>
					)}
					<Disclaimer>You will have a chance to review your order before it is placed.</Disclaimer>
				</div>
			</Content>
		</PaymentStepContext.Provider>
	);
};

const SelectedPaymentMethodBadge = () => {
	const checkoutState = useCheckout();
	return (
		<>
			{checkoutState.selectedPaymentMethodOption !== undefined && (
				<PlaceOrderItem
					title="Payment Method"
					value={
						<PaymentMethodDisplay
							paymentMethod={checkoutState.selectedPaymentMethodOption}
							hideName
							hideParentheses
							css={`
								width: 100%;
							`}
						/>
					}
				/>
			)}
		</>
	);
};

const ConfirmStep = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { delivery, multipleDeliveryOptions } = useCart();

	const selectedDeliveryMethod = useAutoUpdateState(() => {
		if (delivery) {
			const value = delivery.options[delivery?.selected];
			return value;
		}
	}, [delivery]);

	const checkoutState = useCheckout();

	const user = useUser();
	const guest = useGuest();

	useEffect(() => {
		history.replace(InternalRoutes.Checkout + "/confirm");
	}, []);

	const displayMobileUI = useDisplayMobileUI();
	const { paidInFullWithWallet } = useContext(CheckoutRouteContext);


	return (
		<Form
			onSubmit={async () => {}}
			render={form => (
				<form onSubmit={form.handleSubmit}>
					<Content
						css={`
							color: black;
						`}
					>
						{displayMobileUI && (
							<Items
								css={`
									margin-bottom: 25px;
								`}
							/>
						)}
						<div
							css={`
								input {
									border: solid 1px #000;
								}
							`}
						>
							{guest && (
								<>
									<SmallInputsContainer
										onBlur={() => {
											dispatch(
												updateGuestName({
													fname: form.values.firstName,
													lname: form.values.lastName,
												})
											);
										}}
									>
										<SmallInputWrapper>
											<Field name="firstName" component={StyledRFFInput} type="text" placeholder="First Name*" validate={combineValidators(required, checkWhiteSpace)} />
										</SmallInputWrapper>
										<SmallInputWrapper>
											<Field name="lastName" component={StyledRFFInput} type="text" placeholder="Last Name*" validate={combineValidators(required, checkWhiteSpace)} />
										</SmallInputWrapper>
									</SmallInputsContainer>
								</>
							)}
							{(!user || !user.phone) && (
								<Field
									name="phone"
									component={StyledRFFInput}
									placeholder="Mobile Number*"
									validate={value => required(value) || validPhoneNumber(value)}
									type="text"
									pattern="\d*"
									onBlur={() => {
										dispatch(setCheckoutPhoneNumber(form.values.phone));
									}}
								/>
							)}
							{!paidInFullWithWallet && <SelectedPaymentMethodBadge /> }
							<PlaceOrderItem title="Email" value={user?.email || guest?.email} />
							<PlaceOrderItem title="Delivery" value={selectedDeliveryMethod && renderCartDeliveryMethod(selectedDeliveryMethod)} />
							<PlaceOrderItem title="Delivery Address" value={renderAddress((user || guest)?.addresses[checkoutState.shippingAddressIndex])} />
						</div>
						<PlaceOrderContainer
							css={`
								margin-top: 8px;
							`}
						>
							{!multipleDeliveryOptions && delivery?.method !== "FedEx" && (
								<DeliveryNote
									style={{
										marginBottom: 17,
										marginTop: -3,
									}}
								/>
							)}
							<StyledCheckoutTermsAndConditionsCheckbox />

							<div
								css={`
									margin: 0 auto;
									margin-top: 16px;
									display: flex;
									flex-direction: column;
									align-items: center;
								`}
							>
								<PlaceOrderButton guestFormValid={form.valid} paidInFullWithWallet={paidInFullWithWallet}/>
							</div>
						</PlaceOrderContainer>
					</Content>
				</form>
			)}
		/>
	);
};

const DeliveryStep = () => {
	const checkoutState = useCheckout();

	const { delivery } = useCart();

	const { setActiveStepIsValid, goToNextStep } = useStepperControls();

	useEffect(() => {
		if (checkoutState.shippingAddressIndex !== -1) {
			setActiveStepIsValid(true);
		}
	}, [checkoutState.shippingAddressIndex]);

	return (
		<Content
			css={`
				h1 {
					margin-bottom: 8px;
				}
			`}
		>
			<DeliveryInfo />
			<DeliveryNote
				style={{
					marginTop: 9,
				}}
			/>
			<Button
				disabled={checkoutState.shippingAddressIndex === -1 || delivery?.method !== "FedEx"}
				onClick={() => {
					goToNextStep();
				}}
				size="large"
				css={`
					margin: 32px auto 0 auto;
				`}
			>
				Continue
			</Button>
		</Content>
	);
};

const PlaceOrderItem = (props: { title: string; value: React.ReactNode | undefined }) => {
	return (
		<>
			{props.value !== undefined && (
				<PlaceOrderItemContainer>
					<strong>{props.title}</strong>
					<span>{props.value}</span>
				</PlaceOrderItemContainer>
			)}
		</>
	);
};

const AddCardButton = styled.button`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 25px;
	align-items: center;
	background-color: transparent;
	border: none;

	&:hover {
		cursor: pointer;
	}

	img {
		margin: 0 10px;
	}

	span {
		${getTypographyStyles("bodyNormal", {
			color: "dark",
		})};
	}

	div {
		height: 35px;
		width: 35px;
		border: 1px solid ${getAppColor("darkGrey")};
		border-radius: 50%;
		background-color: ${getAppColor("light")};
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 30px;
		color: ${getAppColor("darkGrey")};
		font-weight: 500;
	}
`;

const placeOrderContentFontSize = "13px";

const placeOrderItemContainerTitleWidth = "81px";

const StyledCheckoutTermsAndConditionsCheckbox = styled(CheckoutTermsAndConditionsCheckbox)`
	${getTypographyStyles("bodySmall")}
	margin: 0 auto;
`;

const PlaceOrderContainer = styled.div`
	text-align: left;
	display: flex;
	flex-direction: column;
`;

const PlaceOrderItemContainer = styled.div`
	display: flex;
	font-size: ${placeOrderContentFontSize};
	align-items: stretch;
	text-align: left;
	overflow: auto;
	background-color: ${getAppColor("lightGrey")};
	${getTypographyStyles("bodySmall")};
	margin-bottom: 15px;
	padding: 5px 10px;
	& > strong {
		min-width: ${placeOrderItemContainerTitleWidth};
		max-width: ${placeOrderItemContainerTitleWidth};
	}
	& > * {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	& > span {
		font-weight: 400;
		width: 100%;
		padding: 14px 0;
		overflow: auto;
	}
`;

const Content = styled.div`
	max-width: 100%;
	width: 100%;
	text-align: left;
`;

const PaymentStepPaymentMethodOptionVenmoDisclaimer = styled.p`
	font-weight: 500 !important;
	font-size: 12px !important;
	margin-top: 5px !important;
	line-height: 1.45;
	color: #2c2e2f;
	margin-bottom: 10px;
`;

const PaymentStepPaymentMethodOptionLabel = styled.label<{ checked: boolean; disabled?: boolean }>`
	display: flex;
	font-size: 15px;
	font-weight: 500;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	border: ${props => (props.checked ? `2px solid ${getAppColor("dark")}` : `1px solid ${getAppColor("darkGrey", "light")}`)};
	margin-left: ${props => (props.checked ? "-1px" : "0px")};
	height: 53px;
	padding: 0 13px;
	box-sizing: border-box;
	border-radius: 3px;

	&:hover {
		${props =>
			!props.disabled &&
			`
      border: 2px solid ${getAppColor("dark")};
      margin-left: -1px;
    `}
	}
`;

const PaymentStepPaymentMethodOptionWrapper = styled.div`
	&:not(:last-child) {
		margin-bottom: 12px;
	}
`;

const VenmoVerificationDialogHeaderChildren = styled.div`
	width: 100%;
	display: flex;
`;

const VenmoVerificationDialogContent = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
	ol,
	hr {
		margin: 18px 0;
	}
	hr {
		background: ${getAppColor("lightGrey", "dark")};
	}
	p {
		display: initial !important;
	}
	ol {
		list-style: decimal;
		padding-left: ${verticalDialogSpacing + verticalDialogSpacingUnit};
	}
	@media (min-width: ${breakpoints.mobile}) {
		width: 486px;
	}
`;

const VenmoVerificationDialogLogo = styled.img`
	height: 26px;
	margin: auto;
`;

const WalletInfo = styled.div`
	${getTypographyStyles("bodyNormal")};
	text-align: left;

	@media (max-width: ${breakpoints.mobile}) {
		width: 280px;
		margin-left: 10px;
	}
`;

const PartialDebtText = styled.div`
	${getTypographyStyles("bodyNormal")};
	text-align: center;
	margin-bottom: 30px;
	padding: 0 20px;

	strong {
		diplay: inline-block;
		margin-right: 5px;
	}
`;

const BackArrowWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	padding-right: 15px;
`;

const SmallInputsContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

const SmallInputWrapper = styled.div`
	width: 48%;
`;

const PaymentTitle = styled.div`
	${getTypographyStyles("bodyMedium", {
		fontWeight: 500,
	})};
	text-align: center;
	margin-bottom: 10px;
	width: 100%;
	line-height: 42px;
`;

const PaymentMethodSelector = styled.div<{ selected?: boolean; disabled?: boolean }>`
	border: 1px solid ${getAppColor("darkGrey", "light")};
	border-radius: 3px;
	height: 45px;
	width: 117px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	@media (max-width: ${breakpoints.mobile}) {
		padding: 0 7px;
	}

	${props =>
		props.selected &&
		`
	border: 1px solid ${getAppColor("dark")};
	
	`}

	${props =>
		props.disabled &&
		`
	cursor: default;
	opacity: 0.5;

	
	`}

	span {
		${getTypographyStyles("bodyExtraTiny", {
			fontWeight: 600,
		})};
		margin-left: 9px;
	}

	img {
		max-width: 87px;
	}

	${props =>
		!props.disabled &&
		`:hover {
		border: 1px solid ${getAppColor("dark")};
	}`}
`;

const PaymentMethodsSelectorsContainer = styled.div`
	display: flex;
	justify-content: center;
	padding-bottom: 20px;

	div:not(:last-child) {
		margin-right: 22px;

		@media (max-width: ${breakpoints.mobile}) {
			margin-right: 7px;
		}
	}
`;

const Collapsible = styled.div<{ visible: boolean }>`
	width: 100%;
	height: auto;
	max-height: ${props => (props.visible ? "999px" : "0px")};
	overflow: ${props => (props.visible ? "visible" : "hidden")};
	margin-bottom: 20px;
	transition: max-height 0.35s ease-out;
`;

const StyledToggle = styled.div`
	margin-bottom: 22px;
	@media (max-width: ${breakpoints.mobile}) {
		margin-bottom: 12px;
	}
`;

const CollapsibleRowsContainer = styled.div`
	margin: 5px -20px 10px; // Required so the child component ignores the parent width
`;

const DiscountCodeFormSection = styled(CollapsibleRowsContainer)<{ formVisible: boolean }>`
	border-bottom: 1px solid ${getAppColor("lightGrey", "dark")};
	display: flex;
	flex-direction: column;
	${props => (props.formVisible ? "padding: 11px 15px 0px" : "padding: 0px 15px 11px")};

	margin-top: 0;

	@media (min-width: ${breakpoints.tablet}) {
		margin: 10px 0px 20px;
		padding-left: 0;
	}
`;

const DiscountCodeSectionMesssage = styled(Typography)`
	text-decoration: underline;
	cursor: pointer;
`;

const PromotionalCreditsSectionMesssage = styled(Typography)`
	text-decoration: none;
`;

const DiscountCodeInput = styled(StyledRFFInput)`
	max-width: 244px;
	height: 50px;
`;

const DiscountCodeSubmit = styled(Button)`
	width: 132px;
	height: 50px;
	margin-bottom: 23px;

	button {
		font-family: ${FontFamily.Poppins};
		text-transform: none;
		${getTypographyStyles("bodyNormal", { fontWeight: 600, color: "light" })}
	}
`;

const DiscountCodeFormContainer = styled.form`
	display: flex;
`;
