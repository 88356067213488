import { right } from "fp-ts/lib/Either";

import { FakeService } from "./FakeService";
import { home_featured } from "../../data";

export const fakeBetsService: FakeService = (params, body) => {
	return right({
		success: true,
		status: 200,
		data: home_featured.sibets,
	});
};
