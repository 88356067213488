import { venmo, VenmoTokenizePayload } from "braintree-web";
import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useBraintree } from "../../../services/hooks/useBraintree";
import blueVenmoButton from "../../resource/img/blue_venmo_button.svg";
import blueVenmoButtonActive from "../../resource/img/blue_venmo_button_active.svg";
import { getAndDispatchBraintreeClientToken, postBraintreePaymentMethodAndUpdate } from "../../../services/braintree";
import { TokenizePayload } from "../../../model/Checkout";
import { PaymentMethodWithToken } from "../../../model/optimizedModel/braintree";
import toast from "react-hot-toast";

export const VenmoButton = (props: { paymentMethodAdded?: (payload: PaymentMethodWithToken) => void }) => {
	const { braintreeState } = useBraintree();
	const [validBraintreeToken, setValidBrainTreeToken] = useState(true);

	const tokenize = () => {
		venmo
			.create({
				authorization: braintreeState.clientAuth,
				//@ts-ignore
				allowDesktop: true,
				deepLinkReturnUrl: braintreeState.venmoDeepLinkUrl,
			})
			.then(venmoInstance => {
				return venmoInstance.tokenize();
			})
			.then(async (tokenizePayload: VenmoTokenizePayload) => {
				postBraintreePaymentMethodAndUpdate(tokenizePayload as TokenizePayload).then(result => {
					if (result && props.paymentMethodAdded) {
						props.paymentMethodAdded(result);
					}
				});
			})
			.catch(error => {
				if (error?.code === "CLIENT_AUTHORIZATION_INVALID") {
					setValidBrainTreeToken(false);
				}
				console.error(error);
			});
	};

	useEffect(() => {
		const retrieveToken = async () => {
			await getAndDispatchBraintreeClientToken();
			tokenize();
			setValidBrainTreeToken(true);
		};

		if (!validBraintreeToken) {
			retrieveToken();
		}
	}, [validBraintreeToken]);

	return (
		<>
			<StyledButton
				onClick={() => {
					//TODO: Remove the ts-ignores below.
					//@ts-ignore
					console.log(window.popupBridge);
					//@ts-ignore
					console.log(window.popupBridge?.onComplete);
					//@ts-ignore
					if (window.popupBridge && !window.popupBridge.onComplete) {
						//@ts-ignore
						window.popupBridge.onComplete = (error, payload) => {
							console.log("onComplete:");
							console.log("payload:");
							console.log(payload);
							console.log("error:");
							console.log(error);
							if (error) {
								console.error(error);
								return error;
							}
							window.location.hash = payload.hash;
						};
					}

					if (!braintreeState.paymentMethods?.find(method => method.type === "VenmoAccount")) {
						tokenize();
					} else {
						toast.error("You already have a Venmo account saved to your SI Tickets account. Please remove your existing Venmo account in order to add a new one.");
					}
				}}
				onMouseEnter={event => {
					event.currentTarget.src = blueVenmoButtonActive;
				}}
				onMouseLeave={event => {
					event.currentTarget.src = blueVenmoButton;
				}}
				src={blueVenmoButton}
				alt="Venmo"
			/>
		</>
	);
};

const StyledButton = styled.img`
	max-width: 100%;
	height: auto;
	cursor: pointer;
`;
