import { CategoriesActions } from "../reducers/categoryReducer";
import { Category } from "../../model/Category";

export const updateCategories = (categories: Category[]): CategoriesActions => ({
	type: "UPDATE_CATEGORIES",
	categories,
});

export const updateSubcategories = (parent: string, categories: Category[]): CategoriesActions => ({
	type: "UPDATE_SUBCATEGORIES",
	parent,
	categories,
});
