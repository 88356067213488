import React from "react";
import { CommonPage } from "../components";
import { useNewNavbar } from "../hooks/navbar";
import styled from "styled-components/macro";
import { getTypographyStyles, Typography, FontFamily } from "../components/Typography/Typography";
import { maxContentWidthPadding, maxContentWidthStyles, maxContentWidthValue } from "../util/maxContentWidth";
import { BaseButton, BaseButtonProps } from "../components/NewButton/BaseButton";
import { appColors } from "../util/appColors";
import state_farm_stadium from "../resource/assets/super-bowl-lvii-lp/state_farm_stadium.png";
import { ReactComponent as MusicIcon } from "../resource/assets/super-bowl-lvii-lp/music_icon.svg";
import { ReactComponent as TicketsIcon } from "../resource/assets/super-bowl-lvii-lp/tickets_icon.svg";
import { ReactComponent as NoFeesIcon } from "../resource/assets/super-bowl-lvii-lp/no_fees_icon.svg";
import { ReactComponent as DollarIcon } from "../resource/assets/super-bowl-lvii-lp/dollar_icon.svg";
import { InternalRoutes } from "../Link";
import { Link } from "react-router-dom";
import { Config } from "../../Config";

const lpYellow = "#f5c945";

const lpPurple = "#5D2F4E";

const Paragraph = styled.p`
	${getTypographyStyles("bodyNormal")}
	color: white;
	&:not(:last-child) {
		margin-bottom: 17px;
	}
`;

const horizontalSectionPadding = "58px";

const mobileHorizontalSectionPadding = "27px";

const firstSectionBreakpoint = "975px";

const secondSectionBreakpoint = "685px";

const siThePartyLink = "si-the-party-the-chainsmokers-and-machine-gun-kelly";

const theBigGameLink = InternalRoutes.Event("super-bowl-lvii-2");

const superbowlFolder = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/superbowlLP`);

const LPButton = ({
	color = "yellow",
	...baseButtonProps
}: Omit<BaseButtonProps, "variant" | "typographyType" | "color" | "customColor"> & {
	color?: "yellow" | "purple" | "black";
}) => {
	return (
		<BaseButton
			{...baseButtonProps}
			customColor={
				{
					yellow: {
						main: lpYellow,
						text: "black",
						light: "#f2da8c",
					},
					purple: {
						main: lpPurple,
						text: lpYellow,
						light: "#7d3f69",
					},
					black: {
						...appColors.dark,
						text: lpYellow,
					},
				}[color]
			}
			css={`
				& > * {
					padding: 8px 0;
					white-space: nowrap;
					margin-top: 11px;
					width: 180px;
				}
				&:not(:last-child) {
					margin-bottom: 16px;
				}
			`}
		/>
	);
};

export const SuperBowlLVIIRoute = () => {
	useNewNavbar();
	return (
		<CommonPage
			css={`
				${CommonPage.FooterContainer} {
					margin-top: 0;
				}
			`}
		>
			<div
				css={`
					background: black;
					font-family: ${FontFamily.Solano};
					text-align: left;
					h2 {
						font-size: 36px;
						color: #e16d80;
						margin-bottom: 12px;
					}
					small {
						font-size: smaller;
					}
				`}
			>
				<div
					css={`
						background-image: url(${superbowlFolder}/hero.png);
						background-size: cover;
						background-position-x: center;
						background-position-y: bottom;
						background-repeat: no-repeat;
						display: flex;
						justify-content: center;
						padding-bottom: max(25vw, 198px);
					`}
				>
					<div
						css={`
							display: flex;
							max-width: 540px;
							margin-top: max(24px, 5vw);
							padding: 0 ${maxContentWidthPadding}px;
							flex-direction: column;
							text-align: center;
							align-items: center;
							width: 100%;
							box-sizing: border-box;
							${Paragraph} {
								color: black;
							}
						`}
					>
						<img
							src={`${superbowlFolder}/hero_title.png`}
							alt="The Big Game Weekend"
							css={`
								min-height: 0;
								min-width: 0;
								max-width: 100%;
							`}
						/>
						<Paragraph
							css={`
								font-weight: 500;
							`}
						>
							Be there for the moment you've been waiting for all season. SI Tickets is your way "in" to the biggest weekend of the year.
						</Paragraph>
						<div
							css={`
								display: flex;
								gap: 12px;
								flex-wrap: wrap;
								justify-content: center;
								& > * {
									&,
									& > * {
										margin: 0 !important;
									}
								}
							`}
						>
							<LPButton
								color="purple"
								//@ts-ignore
								forwardedAs={Link}
								to={InternalRoutes.Event(siThePartyLink)}
							>
								SI The Party Tickets
							</LPButton>
							<LPButton
								color="black"
								//@ts-ignore
								forwardedAs={Link}
								to={theBigGameLink}
							>
								The Big Game Tickets
							</LPButton>
						</div>
					</div>
				</div>
				<div
					css={`
						background: linear-gradient(180deg, black, ${lpPurple});
						padding: ${horizontalSectionPadding} 0;
						padding-top: 0;
						@media only screen and (max-width: ${firstSectionBreakpoint}) {
							padding: ${mobileHorizontalSectionPadding} 0;
						}
					`}
				>
					<div
						css={`
							${maxContentWidthStyles}
							display: flex;
							align-items: flex-start;
							gap: 30px 76px;
							@media only screen and (max-width: ${firstSectionBreakpoint}) {
								flex-direction: column;
								align-items: center;
								& > * {
									width: 100% !important;
								}
							}
						`}
					>
						<img
							src={`${superbowlFolder}/performers.png`}
							alt="Sports Illustrated The Party&nbsp;&nbsp;|&nbsp;&nbsp;Performances By The Chainsmokers & Machine Gun Kelly"
							css={`
								min-width: 0;
								min-height: 0;
								width: 50%;
								@media only screen and (max-width: 975px) {
									max-width: 364px;
								}
							`}
						/>
						<div
							css={`
								display: flex;
								flex-direction: column;
								align-items: flex-start;
							`}
						>
							<h2>Sports Illustrated: The Party</h2>
							<Subtitle>Talking Stick Resort&nbsp;&nbsp;|&nbsp;&nbsp;Saturday, February 11, 2023</Subtitle>
							<IconItem Icon={MusicIcon}>Performers include The Chainsmokers, Machine Gun Kelly, & more!</IconItem>
							<IconItem Icon={TicketsIcon}>Premium All-Inclusive Tickets</IconItem>
							<div
								css={`
									border: 2px solid ${lpYellow};
									border-radius: 15px;
									background: rgb(0 0 0 / 30%);
									padding: 14px 23px;
									margin-top: 9px;
									margin-bottom: 12px;
									width: 100%;
									box-sizing: border-box;
									&,
									& * {
										color: ${lpYellow} !important;
									}
								`}
							>
								<Paragraph
									css={`
										display: flex;
										flex-direction: column;
									`}
								>
									<ul
										css={`
											padding-left: 0.95em;
											li:not(:last-child) {
												margin-bottom: 4px;
											}
										`}
									>
										<li>Access to Sports Illustrated The Party from 9PM-2AM</li>
										<li>
											Includes Complimentary Drink Package (Spirits, Beer, Soft Drinks, and more)<sup>*</sup>
										</li>
										<li>Complimentary Gourmet Bites from Arizona's Best Restaurants</li>
										<li>Must be 21+ to attend the event</li>
									</ul>
									<small
										css={`
											margin-top: 20px;
										`}
									>
										<sup>*</sup>Limited drinks per customer
									</small>
								</Paragraph>
							</div>
							<IconItem Icon={NoFeesIcon}>No Fees at Checkout</IconItem>
							<LPButton
								//@ts-ignore
								forwardedAs={Link}
								to={InternalRoutes.Event(siThePartyLink)}
							>
								Buy Tickets
							</LPButton>
							<Paragraph
								css={`
									font-weight: 500;
									a {
										color: ${lpYellow};
									}
								`}
							>
								For VIP Table sales contact us at <a href="mailto:vip@sitickets.com">vip@sitickets.com</a>
							</Paragraph>
						</div>
					</div>
				</div>
				<div
					css={`
						display: flex;
						justify-content: space-between;
						align-items: stretch;
						@media only screen and (max-width: ${secondSectionBreakpoint}) {
							flex-direction: column;
						}
					`}
				>
					<div
						css={`
							width: 50%;
							@media only screen and (max-width: ${secondSectionBreakpoint}) {
								width: initial;
							}
						`}
					>
						<div
							css={`
								padding: ${horizontalSectionPadding} ${maxContentWidthPadding}px;
								max-width: ${maxContentWidthValue / 2}px;
								margin-left: auto;
								box-sizing: border-box;
								@media only screen and (max-width: ${firstSectionBreakpoint}) {
									padding: ${mobileHorizontalSectionPadding} ${maxContentWidthPadding}px;
								}
								@media only screen and (max-width: ${secondSectionBreakpoint}) {
									max-width: initial;
								}
							`}
						>
							<div
								css={`
									display: flex;
									flex-direction: column;
									align-items: flex-start;
								`}
							>
								<h2>Big Game Tickets</h2>
								<Subtitle>State Farm Stadium&nbsp;&nbsp;|&nbsp;&nbsp;Sunday, February 12, 2023</Subtitle>
								<Paragraph>SI Tickets wants to get you into the biggest game of the year.</Paragraph>
								<IconItem Icon={TicketsIcon}>Exclusive Seating Options</IconItem>
								<IconItem Icon={DollarIcon}>Best Price</IconItem>
								<IconItem Icon={NoFeesIcon}>No Fees at Checkout</IconItem>
								<LPButton
									//@ts-ignore
									forwardedAs={Link}
									to={theBigGameLink}
								>
									Buy Tickets
								</LPButton>
							</div>
						</div>
					</div>
					<img
						src={state_farm_stadium}
						alt="State Farm Stadium"
						css={`
							max-width: 50%;
							object-fit: cover;
							object-position: left;
							max-height: 450px;
							@media only screen and (max-width: ${secondSectionBreakpoint}) {
								width: 100%;
								max-height: initial;
								max-width: initial;
							}
						`}
					/>
				</div>
			</div>
		</CommonPage>
	);
};

const IconItem = (
	props: React.PropsWithChildren<{
		Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
	}>
) => {
	return (
		<Paragraph
			css={`
				display: flex;
				align-items: flex-start;
				margin-bottom: 8px !important;
			`}
		>
			<div
				css={`
					margin-top: 4px;
					margin-right: 13px;
					display: flex;
					align-items: center;
					justify-content: center;
					svg {
						fill: ${lpYellow};
					}
					&,
					& svg {
						max-height: 19px;
						max-width: 17px;
					}
				`}
			>
				<props.Icon />
			</div>
			{props.children}
		</Paragraph>
	);
};

const Subtitle = styled(Paragraph)`
	color: ${lpYellow} !important;
	font-weight: bold;
	margin-bottom: 23px;
`;
