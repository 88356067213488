import { Reducer } from "redux";
import { isMobileApp } from "../../presentation/util/isMobileApp";

export const baseHeaderHeight = isMobileApp ? 53 : 80;

export const headerHeightReducer: Reducer<number, HeaderHeightActions> = (state: number = baseHeaderHeight, action: HeaderHeightActions) => {
	switch (action.type) {
		case "SET_HEADER_HEIGHT":
			return action.value;
		default:
			return state;
	}
};

export type HeaderHeightActions = {
	type: "SET_HEADER_HEIGHT";
	value: number;
};
