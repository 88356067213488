import React from "react";
import { BaseTicketContentDisplayProps, TicketContentDisplay } from "../../ContentDisplays/TicketContentDisplay";
import { TicketGroup } from "../../../../model/optimizedModel/ticket";
import { CartItem } from "./CartItem";
import { BaseCartItemProps } from "../../../../model/optimizedModel/cartItem";
import { TicketInvoiceInput } from "../../Invoices/TicketInvoice";
import { Ticket } from "../../../../model/Cart";
import { TicketInvoice } from "../../Invoices/TicketInvoice";
import { toBrowserLocaleString, removeTimezoneFromDateString } from "../../../util/optimized/dates";
import { siThePartyEventId } from "../../../util/general";

//TODO: Re-reiview prop types
//TODO Consider figuring out a way to spread other spreadable props.
export const CartTicket = ({
	tiersInfo,
	additionalTicketInvoiceItems,
	transactionFeeRule,
	deliveryFee,
	notes,
	onUpdate,
	hideDelete,
	expandedByDefault,
	expectedShipDate,
	onDeletePressed,
	tax,
	serviceFeeRate,
	serviceFee,
	eventId,
	hideInvoice,
	...contentDisplayProps
}: BaseCartItemProps &
	Omit<TicketGroup, "deliveryOptions" | "id"> &
	TicketInvoiceInput &
	BaseTicketContentDisplayProps &
	Partial<Pick<Ticket, "expectedShipDate">> & {
		eventId?: number;
	}) => {
	return (
		<CartItem
			notes={notes}
			itemInfo={
				<TicketContentDisplay
					{...contentDisplayProps}
					tiersInfo={tiersInfo}
					eventId={eventId}
					additionalInfo={
						expectedShipDate
							? [
									{
										title: "Tickets expected to ship by",
										content: toBrowserLocaleString(
											new Date(removeTimezoneFromDateString(expectedShipDate)),
											{
												//@ts-ignore
												dateStyle: "short",
											},
											"date"
										),
									},
							  ]
							: undefined
					}
				/>
			}
			invoice={
				<TicketInvoice
					tiersInfo={tiersInfo}
					eventId={eventId} 
					onUpdate={onUpdate}
					quantity={contentDisplayProps.quantity}
					deliveryFee={deliveryFee}
					price={contentDisplayProps.price}
					transactionFeeRule={transactionFeeRule}
					tax={tax}
					serviceFee={serviceFee}
					serviceFeeRate={serviceFeeRate}
					additionalItems={additionalTicketInvoiceItems}
					override={
						eventId === siThePartyEventId
							? {
									transactionFee: {
										title: "Processing Fee",
									},
							  }
							: undefined
					}
				/>
			}
			onDeletePressed={onDeletePressed}
			hideDelete={hideDelete}
			expandedByDefault={expandedByDefault}
			hideInvoice={hideInvoice}
		/>
	);
};
