import React from "react";
import styled, { keyframes } from "styled-components";

import logo from "../resource/image/logo.svg";

const InConstructionBody = styled.header`
	background-color: #282c34;
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
`;

const rotate = keyframes`
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
`;

const Image = styled.img`
	height: 100px;
	pointer-events: none;
	@media (prefers-reduced-motion: no-preference) {
		& {
			animation: ${rotate} infinite 20s linear;
		}
	}
`;

export const InConstruction = () => (
	<InConstructionBody>
		<Image src={logo} alt="logo" />
		<p>This is a work in progress!</p>
	</InConstructionBody>
);
