import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { isMobileApp } from "../../util/isMobileApp";
import styled from "styled-components/macro";
import arrowMidGray from "../../resource/image/arrow-mid-gray.svg";
import arrowMidGrayReverse from "../../resource/image/arrow-mid-gray-reverse.svg";
import { mobileLayoutMediaQuery } from "../../util/mobileMediaQuery";
import { NavigationItem } from "../../../model/Navigation";
import { ModalCloseButton } from "../Modal/ModalCloseButton";
import { mediaQueries } from "../../util/mediaQueries";
import sitix_logo from "../../resource/img/sitix/logos/SitixLogo_NOTag.svg";

interface MobileMenuProps {
	isOpen: boolean;
	user: {} | null;
	style: CSSProperties;
	navigationTree: NavigationItem[];
	onClose: () => void;
}

const defaultLevel = ["Main Menu"];

export const MobileMenu = (props: MobileMenuProps) => {
	const [level, setLevel] = useState(defaultLevel);

	const currentLevel = level.length - 1;
	const levelText = level[currentLevel];

	// Try to hardcode some items, if it fails (e.g.: api data changed), it uses the default tree
	const updatedNavigationTree = useMemo(() => {
		try {
			return completeNavigationTree(props.navigationTree);
		} catch {
			return props.navigationTree;
		}
	}, [props.navigationTree]);

	const firstLevel = updatedNavigationTree.find(item => item.name === level[1])?.children || [];
	const secondLevel = firstLevel.find(item => item.name === level[2])?.children || [];

	useEffect(() => {
		if (props.isOpen === false) {
			setLevel(defaultLevel);
		}
	}, [props.isOpen]);

	const onClick = (toLevel: string) => {
		setLevel([...level, toLevel]);
	};

	const getCurrentLevelUrl = (): string => {
		if (currentLevel > 1) {
			const url = firstLevel.find(item => item.name === levelText)?.url;
			return url ? url : "";
		} else {
			const url = updatedNavigationTree.find(item => item.name === levelText)?.url;
			return url ? url : "";
		}
	};

	const onBack = () => {
		level.length > 1 && setLevel(level.slice(0, -1));
	};

	return (
		<MenuContainer style={props.style} isOpen={props.isOpen} tabIndex={-1} reverse={isMobileApp}>
			<OverlayContainer>
				<MenuBody visible={currentLevel === 0}>
					{updatedNavigationTree.sort(sortItems).map(item => (
						<MenuItem key={item.id} {...{ ...item, onClick, currentLevel }} />
					))}
				</MenuBody>
				<Level active={currentLevel >= 1} index={currentLevel}>
					{firstLevel.sort(sortItems).map(item => (
						<MenuItem key={item.id} {...{ ...item, onClick }} />
					))}
				</Level>
				<Level active={currentLevel >= 2} index={currentLevel}>
					{secondLevel.sort(sortItems).map(item => (
						<MenuItem key={item.id} {...{ ...item, onClick }} />
					))}
				</Level>
			</OverlayContainer>
			{currentLevel === 0 && isMobileApp && (
				<MobileRootHeader>
					<HeaderContent>
						<LogoImage src={sitix_logo} alt="SI Tickets" />
					</HeaderContent>
					<ModalCloseButton onClick={props.onClose} />
				</MobileRootHeader>
			)}
			{level.length > 1 && (
				<MenuHeader allowBack={level.length > 1} reverse={isMobileApp}>
					{level.length > 1 && (
						<div style={{ width: "16px" }} onClick={onBack}>
							<img src={arrowMidGrayReverse} alt="" />
						</div>
					)}
					{level.length === 1 ? "Subscribe for exclusive tickets" : <CategoryLink category={levelText} url={getCurrentLevelUrl()} />}
				</MenuHeader>
			)}
		</MenuContainer>
	);
};

interface MenuItemProps extends NavigationItem {
	onClick: (name: string) => void;
	currentLevel?: number;
}

const MenuItem = (props: MenuItemProps) => {
	const hasChildren = props.children && props.children.length > 0;
	const root = typeof props.currentLevel === "number" && props.currentLevel === 0;

	const onClick = (e: any) => {
		if (hasChildren) {
			e.preventDefault();
			props.onClick(props.name);
		}
	};

	return (
		<StyledNavLink to={props.url || ""} onClick={onClick}>
			<span>{props.name}</span>
			{(hasChildren || root) && <img src={arrowMidGray} alt="" />}
		</StyledNavLink>
	);
};

const sortItems = (a: NavigationItem, b: NavigationItem) => {
	return a.order - b.order;
};

// TODO: consider to update API to define mobile menu links
const localEventsItem = {
	id: 1000,
	name: "Local Events",
	parent_id: 0,
	url: "/discover",
	created_at: "2021-06-07T20:18:11.000000Z",
	order: 4,
};

const SPORTS_NAME = "Sports";

const completeNavigationTree = (tree: NavigationItem[]): NavigationItem[] => {
	if (tree.length === 0) {
		return tree;
	}

	let modifiedTree: NavigationItem[] = [...tree];
	let sportsItems = tree.find(item => item.name === SPORTS_NAME);

	// I tried to write safe code, checking if sports is included on admin panel collection
	if (typeof sportsItems !== "undefined") {
		const newSportsItems: NavigationItem = {
			...sportsItems,
			children: sportsItems.children?.map(item => ({ ...item })),
		};

		// Exclude sports from tree
		modifiedTree = modifiedTree.filter(item => item.name !== SPORTS_NAME);

		return [newSportsItems, ...modifiedTree, localEventsItem];
	}

	// Just add local items
	return [...modifiedTree, localEventsItem];
};

const mobileMenuContainerWidth = "100vw";
const backArrowAVerticalPadding = "16px";

const Level = styled.div<{ active: boolean; index: number }>`
	position: absolute;
	top: 0;
	left: 0;
	z-index: ${props => props.index + 102};
	width: 100%;
	height: 100%;
	background: white;
	opacity: 0;
	visibility: hidden;
	transition: 0.3s linear;
	transform: translateX(-100%);
	overflow-y: auto;
	a {
		padding-left: ${backArrowAVerticalPadding};
		padding-right: ${backArrowAVerticalPadding};
	}
	${props => {
		if (props.active)
			return `  opacity: 1;
                visibility: visible;
                transform: translateX(0);`;
		return ``;
	}}
`;

const StyledNavLink = styled(NavLink)`
	display: block;
	color: black;
	user-select: none;

	font-family: Montserrat;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	text-transform: capitalize;
	text-align: left;

	display: flex;
	align-items: center;
	justify-content: space-between;

	padding: 18px 27px 18px 32px;
	border-bottom: 1px solid #e5e5e5;

	&:hover,
	&.active {
		color: #e84232;
		cursor: pointer;
	}
	img {
		height: 14px;
		width: auto;
	}
`;

const MobileRootHeader = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	background: #ffffff;
	padding: 12px 15px;
	border-bottom: 1px solid #e5e5e5;
	z-index: 110;
`;

const MenuHeader = styled.div<{ reverse: boolean; allowBack: boolean }>`
	display: flex;
	align-items: center;
	font-family: Montserrat;
	font-size: 22px;
	font-weight: 600;
	line-height: 16px;
	text-align: left;
	color: #767977;
	cursor: pointer;
	background: #ffffff;
	padding: 12px 15px;
	border-bottom: 1px solid #e5e5e5;
	text-align: ${props => (props.allowBack ? "left" : "center")};
	z-index: 110;
`;

const OverlayContainer = styled.div`
	flex: 1;
	position: relative;
	overflow: auto;
`;

const MenuBody = styled.div<{ visible: boolean }>`
	display: ${props => (props.visible ? "block" : "none")};
	text-align: left;
`;

const MenuContainer = styled.div<{ isOpen: boolean; reverse: boolean }>`
	background-color: #ffffff;
	position: fixed;
	right: ${props => (props.isOpen ? 0 : -280)}px;
	visibility: ${props => (props.isOpen ? "initial" : "hidden")};
	bottom: 0px;
	overflow-y: auto;
	border: none;
	z-index: 102;
	transition: all 0.3s ease-in-out;
	display: flex;
	flex-direction: column-reverse;
	width: max-content;
	max-width: 250px;
	min-width: 250px;
	@media ${mediaQueries.max500} {
		max-width: ${mobileMenuContainerWidth};
		min-width: ${mobileMenuContainerWidth};
	}
	${props =>
		mobileLayoutMediaQuery(`
    left: ${props.isOpen ? 0 : -280}px;
  `)}
`;

interface CategoryLinkProps {
	category: string;
	url: string;
}

const CategoryLink = (props: CategoryLinkProps) => {
	return <CategoryTextContainer>{props.category}</CategoryTextContainer>;
};

const CategoryTextContainer = styled.div`
	width: 88%;
	text-align: center;
	font-size: 18px;
	color: #5f6368;
	font-weight: 600;
	line-height: 22px;
`;

const HeaderContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 28px;
	width: 100%;
`;

const LogoImage = styled.img`
	height: 25px;
`;
