import styled from "styled-components";
import { Link } from "../../Link";

export const PaginationContainer = styled.div`
	padding: 1rem 0;
	border-radius: 0.25rem;
	list-style: none;
	display: flex;
	padding-left: 0;
	justify-content: center;

	& *:not(:first-child) {
		border-left: 0;
	}
`;

const PageLink = styled(Link)<{ disabled: boolean }>`
	display: block;
	line-height: 1.25;
	border: 1px solid #dee2e6;
	padding: 0.5rem 0.75rem;
	user-select: none;
	text-decoration: none;
	border-color: #dee2e6;
	${props => (props.disabled ? "color: #555;cursor:default;pointer-events: none;" : "color: #3490dc;cursor: pointer;")}

	&:hover {
		${props => (props.disabled ? "" : "background-color: #e9ecef;color: #1d68a7;")}
	}
`;

export const Back = styled(PageLink)`
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
`;

export const Forward = styled(PageLink)`
	border-top-right-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
`;

export const Page = styled(PageLink)``;

export const Ellipsis = styled.div<{ show: boolean }>`
	display: ${props => (props.show ? "block" : "none")};
	line-height: 1.25;
	border: 1px solid #dee2e6;
	padding: 0.5rem 0.75rem;
	user-select: none;
	text-decoration: none;
	border-color: #dee2e6;
`;
