import React from "react";
import styled from "styled-components/macro";
import { TitleAndContent } from "../../TitleAndContent/TitleAndContent";
import { BaseCartItemsProps } from "../CartItems";
import { DeleteIconButton } from "../../DeleteIconButton/DeleteIconButton";
import { InlineStylesProps } from "../../../../model/optimizedModel/styles";
import { useAutoUpdateState } from "../../../hooks/useAutoUpdateState";
import { getAppColor } from "../../../util/appColors";

export interface BaseCartItemComponentProps {
	onDeletePressed?: () => void;
}

const bottomSectionSpacing = "16px";

//TODO: Consider turning this into HOC?
export const CartItem = (
	props: {
		notes?: React.ReactNode | null;
		itemInfo: React.ReactNode;
		invoice: React.ReactNode;
	} & BaseCartItemsProps &
		InlineStylesProps &
		BaseCartItemComponentProps
) => {
	const showDeleteButton = useAutoUpdateState(() => {
		const value = props.onDeletePressed && !props.hideDelete;
		return value;
	}, [props.onDeletePressed, props.hideDelete]);

	return (
		<div
			style={props.style}
			css={`
				&:not(:last-child) {
					border-bottom: 1px solid ${getAppColor("darkGrey", "light")};
				}
			`}
		>
			<TopRow>
				<ItemInfoWrapper>{props.itemInfo}</ItemInfoWrapper>
				{showDeleteButton && (
					<Controls>
						{showDeleteButton && (
							<DeleteIconButton
								onClick={() => {
									//Note: This if statement is here because typescript throws an error which states that onDeletePressed might be undefined even though this element will not be rendered if it's undefined.
									if (props.onDeletePressed) {
										props.onDeletePressed();
									}
								}}
							/>
						)}
					</Controls>
				)}
			</TopRow>
			<div
				css={`
					& > * {
						margin-bottom: ${bottomSectionSpacing};
					}
				`}
			>
				<TitleAndContent title="Notes" content={props.notes} />
				{!props.hideInvoice && props.invoice}
			</div>
		</div>
	);
};

const ItemInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const TopRow = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: ${bottomSectionSpacing};
`;

const Controls = styled.div`
	margin-left: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
