import { useReducer } from "react";

/**
 * A variant of `useReducer` that resembles class component's `setState`:
 * Changes given values on update, keeps the rest.
 */
export function useObjectMergeReducer<T>(initialValue: T) {
	return useReducer(
		(state: T, update: Partial<T>): T => {
			return {
				...state,
				...update,
			};
		},
		initialValue,
		e => e
	);
}
