import React, { useState } from "react";
import { FuturesOrder, FuturesTransactionMarketStatus } from "../../../../model/optimizedModel/myAccount";
import { formatPrice } from "../../../util/formatPrice";
import { SmallButton } from "../../Button/Button";
import styled from "styled-components/macro";
import { BaseMyAccountFuture } from "./BaseMyAccountFuture";
import { FuturesListingFormModal } from "./FuturesListingFormModal";
import { flexGap } from "../../../util/optimized/styles";

export const MyAccountFutureOrder = (props: FuturesOrder) => {
	const [modalOpen, setModalOpen] = useState(false);

	return (
		<>
			<FuturesListingFormModal modalOpen={modalOpen} setModalOpen={setModalOpen} maxQuantity={props.quantity} futureInventoryId={props.futureInventoryId} eventTitle={props.event.title} />
			<BaseMyAccountFuture<FuturesOrder>
				future={props}
				id={props.order.id}
				controls={
					props.marketStatus === FuturesTransactionMarketStatus.Remaining && (
						<Controls>
							<div>
								<SmallButton
									onClick={() => {
										setModalOpen(true);
									}}
								>
									List for Sale
								</SmallButton>
								{!!props.marketPrice && <MarketPrice>Current Reservation Price: {formatPrice(props.marketPrice)}</MarketPrice>}
							</div>
						</Controls>
					)
				}
				status={(() => {
					switch (props.marketStatus) {
						case FuturesTransactionMarketStatus.Succeeded:
							return "You're going!";
						case FuturesTransactionMarketStatus.Closed:
							return "Expired";
					}
				})()}
				statusColor={(() => {
					switch (props.marketStatus) {
						//TODO: DRY colors.
						case FuturesTransactionMarketStatus.Succeeded:
							return "#0c842b";
						case FuturesTransactionMarketStatus.Closed:
							return "#e92224";
					}
				})()}
			/>
		</>
	);
};

const Controls = styled.div`
	display: flex;
	& > div {
		display: flex;
		flex-wrap: wrap;
		${flexGap(["4px", "6px"])}
	}
`;

//MUI Typography body2/subtitle2
const MarketPrice = styled.span`
	font-weight: bold;
	font-size: 14px;
	color: black;
	line-height: normal;
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;
