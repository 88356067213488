import { createStore, combineReducers, applyMiddleware, Reducer, CombinedState } from "redux";
import { createMigrate, persistReducer, persistStore } from "redux-persist";
import localStorage from "redux-persist/es/storage";

import { composeEnhancers } from "./enhancer/devToolsEnhancer";
import { serviceCallMiddleware } from "./middleware/serviceCallMiddleware";

import { sessionReducer } from "./reducers/sessionReducer";
import { locationReducer } from "./reducers/locationReducer";
import { categoriesReducer } from "./reducers/categoryReducer";
import { discoverReducer } from "./reducers/discoverReducer";
import { cartReducer } from "./reducers/cartReducer";
import { betsReducer } from "./reducers/betsReducer";
import { presaleReducer } from "./reducers/presaleReducer";
import { userReducer } from "./reducers/userReducer";
import { migrations } from "./migrations/migrations";
import { serviceCallReducer } from "./reducers/serviceCallReducer";
import { trendsReducer } from "./reducers/trendsReducer";
import { braintreeReducer } from "./reducers/braintreeReducer";
import { checkoutReducer } from "./reducers/checkoutReducer";
import { headerHeightReducer } from "./reducers/headerHeightReducer";
import { loadingScreenDataReducer } from "./reducers/loadingScreenDataReducer";
import { eventReducer } from "./reducers/eventReducer";
import { siteStatusReducer } from "./reducers/siteStatusReducer";
import { zenDeskReducer } from "./reducers/zenDeskReducer";
import { navbarReducer } from "./reducers/navbarReducer";
import { captchaBadgeReducer } from "./reducers/captchaBadgeReducer";
import { guestReducer } from "./reducers/guestReducer";
import { searchReducer } from "./reducers/searchReducer";
import { promoReducer } from "./reducers/promoReducer";

declare module "react-redux" {
	interface DefaultRootState extends StoreContent {}
}

type StateOf<T> = T extends Reducer<CombinedState<infer C>, infer A> ? C : never;

const transientReducer = combineReducers({
	event: eventReducer,
	discover: discoverReducer,
	bets: betsReducer,
	presale: presaleReducer,
	serviceCalls: serviceCallReducer,
	trends: trendsReducer,
	headerHeight: headerHeightReducer,
	loadingScreenData: loadingScreenDataReducer,
	zenDesk: zenDeskReducer,
	navbar: navbarReducer,
	captchaBadge: captchaBadgeReducer,
	promo: promoReducer,
});

const persistentReducer = persistReducer(
	{
		key: "root",
		keyPrefix: "",
		version: 1,
		storage: localStorage,
		migrate: createMigrate(migrations, { debug: false }),
	},
	combineReducers({
		cart: cartReducer,
		checkout: checkoutReducer,
		categories: categoriesReducer,
		location: locationReducer,
		session: sessionReducer,
		user: userReducer,
		guest: guestReducer,
		search: searchReducer,
		braintree: braintreeReducer,
		siteStatus: siteStatusReducer,
	})
);

const storeReducer = combineReducers({
	transient: transientReducer,
	persistent: persistentReducer,
});

const enhancers = composeEnhancers(applyMiddleware(serviceCallMiddleware));

export const store = createStore(storeReducer, undefined, enhancers);

export type StoreContent = StateOf<typeof storeReducer>;

export const persistor = persistStore(store);
