import { DateTime } from "luxon";
import { Optional } from "../../../model/Utils";
import { SeaticsTicketGroup } from "../Seatics/types";

export const hasTicketDeliveryType = (ticket: SeaticsTicketGroup) => Array.isArray(ticket.tgClientData.tgDeliveryMethods) && ticket.tgClientData.tgDeliveryMethods.length > 0;

export const getTicketDeliveryType = (ticket: SeaticsTicketGroup) => {
	if (hasTicketDeliveryType(ticket)) {
		return ticket.tgClientData.tgDeliveryMethods.join(", ");
	}
	return "--";
};

export const getExpectedShipByDate = (str: Optional<string>) => {
	if (str) {
		let date = DateTime.fromFormat(str, "yyyy-MM-dd'T'HH:mm:ss'.000000Z'");

		// try alternative date and datetime formats
		if (!date.isValid) {
			date = DateTime.fromFormat(str, "yyyy-MM-dd'T'HH:mm:ss'Z'");
		}
		if (!date.isValid) {
			date = DateTime.fromFormat(str, "yyyy-MM-dd HH:mm:ss'+00'");
		}
		if (!date.isValid) {
			date = DateTime.fromFormat(str, "yyyy-MM-dd");
		}

		if (date.isValid) {
			return date.toLocaleString({ day: "numeric", month: "short" });
		}
	}
	return null;
};
