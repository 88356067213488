import React, { useEffect } from "react";
import { Transition, TransitionStatus } from "react-transition-group";
import styled from "styled-components";
import ReactDOM from "react-dom";

export const overlayTransitionDuration = 300;
export const overlayTransitionTimingFunction = "ease-in-out";

//TODO: Integrate this component within the Dialog component.
export const FrozenPageScrollOverlay = ({ opacity = 0.8, ...props }: Pick<React.ComponentProps<typeof Transition>, "in" | "children"> & Pick<React.DOMAttributes<HTMLElement>, "onClick"> & Pick<React.CSSProperties, "zIndex" | "opacity">) => {
	useEffect(() => {
		if (!props.in) {
			return;
		}
		const initialScrollX = window.scrollX;
		const initialScrollY = window.scrollY;
		const initialTop = document.body.style.top;
		const initialPosition = document.body.style.position;
		document.body.style.top = `-${initialScrollY}px`;
		document.body.style.position = "fixed";
		return () => {
			document.body.style.position = initialPosition;
			document.body.style.top = initialTop;
			window.scrollTo(initialScrollX, initialScrollY);
		};
	}, [props.in]);

	return (
		<Transition
			timeout={{
				exit: overlayTransitionDuration,
			}}
			in={props.in}
			mountOnEnter
			unmountOnExit
		>
			{transitionState => {
				return ReactDOM.createPortal(
					<>
						<Overlay
							style={{
								zIndex: props.zIndex,
								backgroundColor: `rgba(0, 0, 0, ${opacity})`,
								...(
									{
										entered: {
											opacity: 1,
										},
									} as Partial<Record<TransitionStatus, React.CSSProperties>>
								)[transitionState],
							}}
							onClick={props.onClick}
						></Overlay>
						{props.children !== undefined && typeof props.children === "function" ? props.children(transitionState) : props.children}
					</>,
					document.getElementById("root")!
				);
			}}
		</Transition>
	);
};

const Overlay = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	box-sizing: border-box;
	opacity: 0;
	transition-property: opacity;
	transition-duration: ${overlayTransitionDuration}ms;
	transition-timing-function: ${overlayTransitionTimingFunction};
`;
