import { Reducer } from "redux";
import { Location } from "../../model/Location";

interface LocationAction {
	type: "SET_LOCATION";
	location: Location | null;
}

export const locationReducer: Reducer<Location | null, LocationAction> = (state: Location | null = null, action: LocationAction) => {
	switch (action.type) {
		case "SET_LOCATION":
			return action.location;
		default:
			return state;
	}
};

export const setLocation = (location: Location | null): LocationAction => ({
	type: "SET_LOCATION",
	location,
});
