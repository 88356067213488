import React, { useContext, useEffect, useState } from "react";
import { CommonPage } from "../components";
import { FontFamily, getTypographyStyles, Typography } from "../components/Typography/Typography";
import styled from "styled-components/macro";
import { maxContentWidthStyles, maxContentWidthValue, maxContentWidth } from "../util/maxContentWidth";
import { getAppColor } from "../util/appColors";
import { Tabs } from "../components/Tabs/Tabs";
import { ChipCarouselRow } from "../components/ChipCarouselRow/ChipCarouselRow";
import { ThumbnailCarouselRow } from "../components/Thumbnail/ThumbnailCarouselRow";
import { breakpoints } from "../util/breakpoints";
import { getBetsSports, getBetsSportCategories, getBetsSportCategorySubcategories, getBets, getBetsMetadata } from "../../services/bets";
import { PromiseType } from "../../model/optimizedModel/general";
import { Select } from "../components/Select/Select";
import { Bet } from "../components/NewBets/Bet";
import { isMobileApp } from "../util/isMobileApp";
import { useHeaderHeight } from "../hooks/useHeaderHeight";
import { Button } from "../components/NewButton/Button";
import { ReactComponent as FiltersDialogButtonIcon } from "../resource/assets/filter_icon_red.svg";
import { useQueryStringState } from "../hooks/useQueryStringState";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { Dialog } from "../components/Dialog/Dialog";
import { useAutoUpdateState } from "../hooks/useAutoUpdateState";
import { SkeletonWrapper } from "../components/SkeletonWrapper/SkeletonWrapper";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { fullPageWidthCarouselRowStyles } from "../components/CarouselRow/consts";
import { PageBio } from "../components/PageBio/PageBio";
import { useSelector } from "react-redux";
import { getGoogleMapsPlaceComponent } from "../../services/location";
import { useLocation } from "react-router-dom";
import { Spinner } from "../components/Loader/Spinner";

type SportCategories = PromiseType<ReturnType<typeof getBetsSportCategories>>["data"];

type SportCategorySubcategories = PromiseType<ReturnType<typeof getBetsSportCategorySubcategories>>["data"];

type BetsFilters = {
	key: string;
	title: string;
	options: {
		value: string;
		label: string;
	}[];
}[];

let savedSportCategories: Record<string, SportCategories> = {};

let savedSportCategorySubcategories: Record<string, Record<string, SportCategorySubcategories>> = {};

//TODO: See if there's a better way to type this.
type BetsRouteQueryString = {
	sport?: string;
	category?: string;
	subcategory?: string;
} & Record<string, any>;

//TODO: Remove ts-ignores

//TODO: A lot of the option data/default selection logic is very simillar, consider finding a way to reuse this logic.

//TODO: Organize code by the order of when declared items are needed

const BetsRouteContext = React.createContext<{
	loadingRouteData?: Set<string>;
	displayedFilters?: BetsFilters;
	showDesktopFilters?: boolean;
}>({});

const keysReadyToBeShown = new Set<string>();

const loadingDependentsMap: Record<string, string[]> = {
	filters: ["sports", "categories", "subcategories", "bets"],
	sports: ["categories", "subcategories", "bets"],
	categories: ["subcategories", "bets"],
	subcategories: ["bets"],
};

const desktopFiltersBreakpoint = "965px";

const BetsRouteFilters = () => {
	const { loadingRouteData, displayedFilters, showDesktopFilters } = useContext(BetsRouteContext);

	const [queryStringState, updateQueryStringState] = useQueryStringState<BetsRouteQueryString>();

	//TODO: "loadingRouteData" might be able to be replaced with the "loadingRouteBetsData" const instead of getting it via context. Test this replacement & remove loadingRouteData from the BetsRouteContext if it works.
	const filtersAreLoading = useAutoUpdateState(() => loadingRouteData?.has("filters") || !displayedFilters?.length, [loadingRouteData?.has("filters"), !displayedFilters?.length]);

	return (
		<BetsRouteFiltersContainer>
			{(filtersAreLoading ? Array.from(Array(1)) : displayedFilters)?.map((item, index) => {
				if (item?.key !== "sportbooks" && item?.key !== "sportsbooks") {
					//TODO: This needs to be replaced with a flag returned by the displayedFilters to determine if a filter is a multi-select filter.
					const isMultiSelect = item?.key === "sportbooks" || item?.key === "sportsbooks";
					return (
						<Select
							key={index}
							title={item?.title}
							items={item?.options}
							//TODO: Improve placeholder logic (ex: item.type, this might require backend changes)
							placeholder={"All " + item?.title}
							value={queryStringState[item?.key]}
							//@ts-ignore
							onChange={value => {
								updateQueryStringState({
									[item?.key]: value,
								});
							}}
							multiple={isMultiSelect}
							loading={filtersAreLoading}
							titleLoading={filtersAreLoading}
							menuPortalTarget={showDesktopFilters ? undefined : document.body}
							menuShouldScrollIntoView
							styles={{
								menuPortal: provided => ({
									...provided,
									position: "fixed",
									zIndex: 10000,
								}),
								menuList: provided => ({
									...provided,
									[`@media (max-width: ${desktopFiltersBreakpoint})`]: {
										maxHeight: "25vh",
									},
								}),
							}}
						/>
					);
				}
				return null;
			})}
		</BetsRouteFiltersContainer>
	);
};

//TODO: Consider componentizing more elements (ex: mobile filters) for performance & slight orginization reasons.

//TODO: Add some spacing when updating bets in order to prevent issue with the black footer quickly "flashing" on the screen.

let loadingBetsRouteData = new Set<string>();

//TODO: The page does not seem to load when entering by going back.

let betsRouteDisplayedFiltersUpdateRecentlyTriggered = false;

const geocoder = new google.maps.Geocoder();

let initialQueryString: string | undefined;

export const BetsRoute = () => {
	const [displayedFilters, setDisplayedFilters] = useState<BetsFilters>();

	const [{ sport: selectedSport, category: selectedCategory, subcategory: selectedSubcategory, ...filtersState }, updateQueryStringState] = useQueryStringState<BetsRouteQueryString>();

	const [displayedSports, setDisplayedSports] = useState<React.ComponentProps<typeof ThumbnailCarouselRow>["thumbnails"]>();

	//Note: These state variables are used in order to force a component re-render, this is why "dataLoading" is not used. This is 100% a hack.
	const [dataLoading, setDataLoading] = useState<boolean>();

	const [pageHasLoadedOnce, setPageHasLoadedOnce] = useState(false);

	const setIsLoading = (key: keyof typeof loadingDependentsMap, isLoading: boolean) => {
		if (isLoading) {
			if (!loadingBetsRouteData.has(key)) {
				loadingBetsRouteData.add(key);
				//TODO: See which is faster, forEach or spread in the 2nd OG (lol) version of setIsLoading below
				loadingDependentsMap[key]?.forEach(dependentKey => {
					loadingBetsRouteData.add(dependentKey);
				});
				setDataLoading(true);
			}
		} else if (loadingBetsRouteData.has(key) && !keysReadyToBeShown.has(key)) {
			keysReadyToBeShown.add(key);
			if (keysReadyToBeShown.size === loadingBetsRouteData.size) {
				keysReadyToBeShown.clear();
				loadingBetsRouteData.clear();
				setDataLoading(false);
				if (!pageHasLoadedOnce) {
					setPageHasLoadedOnce(true);
				}
			}
		}
	};

	const [bettingStates, setBettingStates] = useState<Record<string, string>>();

	const location = useSelector(state => state.persistent.location);

	const reactRouterLocation = useLocation();

	useEffect(() => {
		if (initialQueryString === undefined) {
			initialQueryString = reactRouterLocation.search;
		}
		setIsLoading("bettingStates", true);
		setIsLoading("filters", true);
		getBetsMetadata()
			.then(result => {
				setDisplayedFilters(result.filters);
				setBettingStates(result.bettingStates);
			})
			.finally(() => {
				setIsLoading("filters", false);
				setIsLoading("bettingStates", false);
			});
		setIsLoading("sports", true);
		getBetsSports()
			.then(result => {
				setDisplayedSports(result.data);
			})
			.finally(() => {
				setIsLoading("sports", false);
			});
		return () => {
			initialQueryString = undefined;
			savedSportCategories = {};
			savedSportCategorySubcategories = {};
			setPageHasLoadedOnce(false);
		};
	}, []);

	useEffect(() => {
		if (location && !initialQueryString && bettingStates && !pageHasLoadedOnce) {
			geocoder.geocode(
				{
					location: {
						lat: location.latitude,
						lng: location.longitude,
					},
				},
				places => {
					const [place] = places;
					const placeStateName = getGoogleMapsPlaceComponent(place, ["administrative_area_level_1"])?.long_name;
					const placeStateId = bettingStates && placeStateName && bettingStates[placeStateName];
					if (placeStateId) {
						updateQueryStringState({
							state: placeStateId,
						});
					}
				}
			);
		}
	}, [location, bettingStates]);

	useEffect(() => {
		if (displayedSports !== undefined && (selectedSport === undefined || !displayedSports?.find(sport => sport.slug === selectedSport))) {
			updateQueryStringState({
				sport: displayedSports[0].slug,
			});
		}
	}, [displayedSports]);

	const [displayedCategories, setDisplayedCategories] = useState<SportCategories>();

	useEffect(() => {
		if (selectedSport !== undefined) {
			if (savedSportCategories[selectedSport] === undefined) {
				setIsLoading("categories", true);
				getBetsSportCategories(selectedSport)
					.then(result => {
						savedSportCategories[selectedSport] = result.data;
						setDisplayedCategories(savedSportCategories[selectedSport]);
					})
					.finally(() => {
						setIsLoading("categories", false);
					});
			} else {
				setDisplayedCategories(savedSportCategories[selectedSport]);
			}
		}
	}, [selectedSport]);

	const [displayedSubcategories, setDisplayedSubcategories] = useState<SportCategorySubcategories>();

	const updateDisplayedSubcategories = () => {
		if (selectedSport !== undefined && selectedCategory !== undefined) {
			if (savedSportCategorySubcategories[selectedSport] === undefined) {
				savedSportCategorySubcategories[selectedSport] = {};
			}
			if (savedSportCategorySubcategories[selectedSport][selectedCategory] === undefined) {
				setIsLoading("subcategories", true);
				getBetsSportCategorySubcategories(selectedSport, selectedCategory)
					.then(result => {
						//TODO: Consider adding logic to only "save" a result/response if it"s not undefined here and for other areas that "save/cache" data.
						savedSportCategorySubcategories[selectedSport][selectedCategory] = result.data;
						setDisplayedSubcategories(savedSportCategorySubcategories[selectedSport][selectedCategory]);
					})
					.finally(() => {
						setIsLoading("subcategories", false);
					});
			} else {
				setDisplayedSubcategories(savedSportCategorySubcategories[selectedSport][selectedCategory]);
			}
		}
	};

	useEffect(() => {
		if (displayedCategories !== undefined && (pageHasLoadedOnce || selectedCategory === undefined || !displayedCategories?.find(category => category.slug === selectedCategory))) {
			if (selectedCategory === displayedCategories[0].slug) {
				updateDisplayedSubcategories();
			} else {
				updateQueryStringState({
					category: displayedCategories[0].slug,
				});
			}
		}
	}, [displayedCategories]);

	useEffect(() => {
		updateDisplayedSubcategories();
	}, [selectedCategory]);

	useEffect(() => {
		if (displayedSubcategories !== undefined && (pageHasLoadedOnce || selectedSubcategory === undefined || !displayedSubcategories.find(subcategory => subcategory.slug === selectedSubcategory))) {
			updateQueryStringState({
				subcategory: displayedSubcategories[0].slug,
			});
		}
	}, [displayedSubcategories]);

	const retrieveBets = (pageNumber?: number) => {
		if (selectedSport !== undefined && selectedSubcategory !== undefined) {
			return getBets(selectedSport, selectedSubcategory, {
				pageNumber,
				...filtersState,
			});
		}
	};

	//TODO: Update this logic to work with invalid query string params.
	useEffect(() => {
		if (betsRouteDisplayedFiltersUpdateRecentlyTriggered) {
			betsRouteDisplayedFiltersUpdateRecentlyTriggered = false;
			if (pageHasLoadedOnce) {
				for (const key in filtersState) {
					if (filtersState[key]) {
						return;
					}
				}
			}
		}
		if (selectedSport !== undefined && selectedSubcategory !== undefined) {
			setIsLoading("bets", true);
			setBetPagesLoaded(0);
			setDisplayedBetsTotalPages(undefined);
			if (displayedBets !== undefined) {
				setDisplayedBets(undefined);
			}
			retrieveBets()!
				.then(result => {
					setDisplayedBets(result.bets);
					setDisplayedBetsTotalPages(result.metadata?.totalPages);
					setBetPagesLoaded(1);
				})
				.finally(() => {
					setIsLoading("bets", false);
				});
		}
		//TODO: See if there's a more performant way of detecting updated filtersState.
	}, [selectedSubcategory, JSON.stringify(filtersState)]);

	const [filtersDialogOpen, setFiltersDialogOpen] = useState(false);

	const showDesktopFilters = !useMediaQuery(`(max-width: ${desktopFiltersBreakpoint})`);

	useEffect(() => {
		if (showDesktopFilters && filtersDialogOpen) {
			setFiltersDialogOpen(false);
		}
	}, [showDesktopFilters]);

	const [displayedBets, setDisplayedBets] = useState<PromiseType<ReturnType<typeof getBets>>["bets"]>();

	const [displayedBetsTotalPages, setDisplayedBetsTotalPages] = useState<number>();

	const [betPagesLoaded, setBetPagesLoaded] = useState(0);

	const headerHeight = useHeaderHeight();

	const hasNextPage = useAutoUpdateState(() => {
		const value = betPagesLoaded < (displayedBetsTotalPages || 0);
		return value;
	}, [betPagesLoaded, displayedBetsTotalPages]);

	const displayedBetsSkeletonRef = useInfiniteScroll<HTMLDivElement>({
		loading: loadingBetsRouteData.has("bets") || !displayedBets,
		hasNextPage,
		onLoadMore: () => {
			if (selectedSport !== undefined && selectedSubcategory !== undefined && displayedBets !== undefined && !loadingBetsRouteData.has("bets")) {
				setIsLoading("bets", true);
				retrieveBets(betPagesLoaded + 1)!
					.then(result => {
						setDisplayedBets(displayedBets.concat(result.bets));
						setBetPagesLoaded(betPagesLoaded + 1);
					})
					.finally(() => {
						setIsLoading("bets", false);
					});
			}
		},
	});

	return (
		<CommonPage>
			<PageWrapper>
				<BetsRouteContext.Provider
					value={{
						displayedFilters,
						loadingRouteData: loadingBetsRouteData,
						showDesktopFilters,
					}}
				>
					{/*
	    <Button
	      //TODO: Fix
	      //@ts-ignore
	      forwardedAs="a"
	    >
	      Link
	    </Button>
	  */}
					<HeroContainer>
						<HeroContent>
							<HeroTitle type="display1" color="light">
								Get in the Game.
							</HeroTitle>
							<HeroSubtitle type="bodyLarge" color="light">
								See which Sportsbook offers you the best payouts for thousands of bets.
							</HeroSubtitle>
							{["Choose a Bet", "See the Sportsbook Offer", "Enter Email and Get the Offer", "When You’re Ready Place Your Bet"].map((step, index) => {
								return (
									<HeroStep key={index}>
										<span>{index + 1}.</span>
										{step}
									</HeroStep>
								);
							})}
						</HeroContent>
					</HeroContainer>
					<Wrapper>
						<StyledThumbnailCarouselRow
							loading={loadingBetsRouteData?.has("sports") || !displayedSports}
							thumbnails={displayedSports}
							selectedSlug={selectedSport}
							onSelect={slug => {
								updateQueryStringState({
									sport: slug,
								});
							}}
						/>
						<ContentContainer>
							<Content>
								{/*@ts-ignore*/}
								<Tabs
									loading={loadingBetsRouteData?.has("categories") || !displayedCategories}
									tabs={displayedCategories}
									selectedSlug={selectedCategory}
									onTabSelect={slug => {
										updateQueryStringState({
											category: slug,
										});
									}}
								/>
								<StyledChipCarouselRow
									loading={loadingBetsRouteData?.has("subcategories") || !displayedSubcategories}
									items={displayedSubcategories}
									selectedSlug={selectedSubcategory}
									onSelect={slug => {
										updateQueryStringState({
											subcategory: slug,
										});
									}}
								/>
								<Bets>
									{/*TODO: Hide/remove the side borders for mobile spacing*/}
									{(!loadingBetsRouteData.has("bets") || loadingBetsRouteData.size < 2) &&
										(displayedBets?.length
											? displayedBets.map((bet, index) => {
													return (
														<React.Fragment key={index}>
															{/*@ts-ignore*/}
															<Bet type={bet?.type} {...bet?.data} />
														</React.Fragment>
													);
											  })
											: !loadingBetsRouteData.size && pageHasLoadedOnce && <Typography type="bodyNormal">No bets found.</Typography>)}
									{(!!dataLoading || hasNextPage || !pageHasLoadedOnce) && <div ref={displayedBetsSkeletonRef} />}
									{
										/*TODO: Remove the spinner (can revert the commit that it was added in) & fix "scroll issues" with the Skeleton*/
										(loadingBetsRouteData.has("bets") || hasNextPage) &&
											(loadingBetsRouteData.size < 2 && displayedBets ? (
												<div
													css={`
														margin-top: 16px;
													`}
												>
													<Spinner />
												</div>
											) : (
												<DisplayedBetsSkeleton />
											))
									}
								</Bets>
							</Content>
							{showDesktopFilters ? (
								<DesktopFilters>
									<DesktopFiltersControls
										style={{
											top: 25 + (isMobileApp ? 0 : headerHeight),
										}}
									>
										<BetsRouteFilters />
									</DesktopFiltersControls>
								</DesktopFilters>
							) : (
								<>
									<FiltersDialogButtonWrapper>
										<FiltersDialogButton
											color="light"
											variant="outline"
											size="small"
											onClick={() => {
												setFiltersDialogOpen(true);
											}}
											//TODO: Make the below filters count dynamic.
										>
											Filters{/* (0)*/}
											<StyledFiltersDialogButtonIcon />
										</FiltersDialogButton>
									</FiltersDialogButtonWrapper>
									<FiltersDialog
										open={filtersDialogOpen}
										onClose={() => {
											setFiltersDialogOpen(false);
										}}
										type="utility"
									>
										<FiltersDialogContent>
											<BetsRouteFilters />
											<ApplyFiltersButton
												color="primary"
												onClick={() => {
													setFiltersDialogOpen(false);
												}}
												//TODO: Consider adding logic to consider this button to be loading when the filters are loading. Same thing for the reset button maybe?
											>
												Close
											</ApplyFiltersButton>
										</FiltersDialogContent>
									</FiltersDialog>
								</>
							)}
						</ContentContainer>
					</Wrapper>
				</BetsRouteContext.Provider>
			</PageWrapper>

			<PageBio
				htmlString={`
          <p>Get in the Game provides you with the best live bets with the best payouts for all major sports. We are connected with all the best sportsbooks to bring you the most up-to-date odds and payouts across the sports world under one roof. You can find bets for MLB, NFL, NHL, NBA, College Football, College Basketball and Premier League Soccer. Currently, you can see bets from sportsbooks such as SI Sportsbook, DraftKings, BetMGM, PointsBet, SugarHouse and BetRivers.</p> 
          <br />
          <p>If you live in a state where sports betting is legal, you can filter by your state to only show sportsbooks that are available in your location. Our current state offerings are Colorado, Connecticut, Illinois, Indiana, Iowa, Michigan, New Jersey, New York, Pennsylvania, Tennessee, Virginia and West Virginia.</p>
          <br />
          <p>We understand that sports betting can be complicated for a new bettor, and not everyone knows the difference between a spread, an over/under and a moneyline, or what the difference between a player prop and a team future are. Get in The Game will simplify the betting experience for anyone looking to learn more about sports betting.</p>
          <br />
          <p>Try out our betting calculator to find what your payout would be for a bet that matches your budget. If you find a bet that piques your interest, simply click on “Get the Offer,” enter your email, and we’ll send the information on how to place the bet directly to you to use whenever you’re ready.</p>
        `}
			/>
		</CommonPage>
	);
};

const FiltersDialogContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const FiltersDialog = styled(Dialog)`
	width: 60vw;
`;

const StyledFiltersDialogButtonIcon = styled(FiltersDialogButtonIcon)`
	margin-left: 8px;
`;

const BetsRouteFiltersContainer = styled.div`
	& > *:not(:last-child) {
		margin-bottom: 20px;
	}
`;

const DisplayedBetsSkeleton = styled(React.forwardRef<HTMLDivElement>((props, ref) => <SkeletonWrapper {...props} ref={ref} loading />))`
	height: 150vh;
`;

const ApplyFiltersButton = styled(Button)`
	width: 100%;
	margin: 0 auto;
	margin-top: 24px;
	@media (min-width: ${breakpoints.mobile}) {
		max-width: 335px;
	}
`;

const FiltersDialogButton = styled(Button)`
	position: sticky;
	z-index: 2;
	display: flex;
	align-items: center;
	pointer-events: initial;
	bottom: 20px;
	@media (min-width: ${desktopFiltersBreakpoint}) {
		display: none;
	}
`;

const FiltersDialogButtonWrapper = styled.div`
	position: absolute;
	height: calc(100% + 30px);
	display: flex;
	align-items: flex-end;
	pointer-events: none;
`;

const HeroContainer = styled.div`
	background-color: ${getAppColor("dark")};
	padding: 60px 0px;
	@media (max-width: ${breakpoints.mobile}) {
		padding: 66px 0px;
	}
`;

const HeroContent = styled.div`
	${maxContentWidthStyles};
	display: flex;
	flex-direction: column;
	text-align: left;
`;

const HeroTitle = styled(Typography)`
	margin-bottom: 16px;
`;

const HeroSubtitle = styled(Typography)`
	margin-bottom: 32px;
`;

const HeroStep = styled.p`
	display: flex;
	${getTypographyStyles("leadParagraph", {
		color: "light",
	})}
	&:not(:last-child) {
		margin-bottom: 12px;
	}
	& > span {
		color: ${getAppColor("primary")};
		min-width: 30px;
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow: auto;
`;

const DesktopFilters = styled.div`
	width: 275px;
	border-left: 2px solid ${getAppColor("lightGrey", "subtle")};
	margin-left: 21px;
	padding-left: 31px;
	z-index: 10;
`;

const DesktopFiltersControls = styled.div`
	display: flex;
	flex-direction: column;
	position: sticky;
`;

const ContentContainer = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	width: 100%;
	${maxContentWidthStyles}
	@media (max-width: ${desktopFiltersBreakpoint}) {
		position: relative;
		padding: 0;
	}
`;

const StyledThumbnailCarouselRow = styled(ThumbnailCarouselRow)`
	@media (min-width: ${maxContentWidthValue + 1}px) {
		//TODO: Consider maybe adding omit/pick functionality to the maxContentWidthStyles instead of having to override the styles as done below.
		${maxContentWidthStyles}
	}
	@media (max-width: ${maxContentWidth}) {
		${fullPageWidthCarouselRowStyles}
	}
	@media (min-width: ${desktopFiltersBreakpoint}) {
		margin: 60px auto;
	}
	@media (max-width: ${desktopFiltersBreakpoint}) {
		margin: 40px 0;
	}
`;

const StyledChipCarouselRow = styled(ChipCarouselRow)`
	margin-top: 18px;
	@media (max-width: ${desktopFiltersBreakpoint}) {
		${fullPageWidthCarouselRowStyles}
	}
`;

const Bets = styled.div`
  margin-top: 27px;
  // Note: The below style is needed for the (hopefully) temporary spinner component. Consider removing the below style once the spinner is no longer present.
  overflow-y: hidden;
  @media (max-width: ${desktopFiltersBreakpoint}) {
    margin: {maxContentWidthPadding};
  }
`;

const PageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 150vh;
	font-family: ${FontFamily.Poppins};
	//TODO: See if there's any other way to accomplish the functionality in the below line.
	-webkit-font-smoothing: antialiased;
`;

/*
const StyledThumbnailCarouselRow = styled(ThumbnailCarouselRow)`
  margin: 60px 0;
  .content {
    padding: 0 max(calc((100vw - ${maxContentWidthValue - maxContentWidthPadding * 2}px) * 0.5), ${maxContentWidthPadding}px);
  }
`;
const Wrapper = styled.div`
  all: initial;
  ${maxContentWidthStyles}
  width: 100%;
  font-family: ${FontFamily.Poppins};
  //TODO: See if there's any other way to accomplish the functionality in the below line.
  -webkit-font-smoothing: antialiased;
  /*
  *::selection {
    background: ${getAppColor("primary")};
  }
   */
/*
`;
*/
