import { css } from "styled-components";

export const maxContentWidthPadding = 20;
export const maxContentWidthValue = 1060;
export const maxContentWidth = maxContentWidthValue + "px";
export const maxContentWidthStyles = css`
	max-width: ${maxContentWidth};
	padding: 0 ${maxContentWidthPadding}px;
	margin: 0 auto;
	box-sizing: border-box;
`;
