import { useState, useEffect } from "react";

const MOBILE_WIDTH = 768;

const getWidth = () => {
	return window.innerWidth;
};

export const useIsMobileWeb = (screenWidth?: number) => {
	const [width, setWidth] = useState(getWidth());

	useEffect(() => {
		const resizeListener = () => {
			setWidth(getWidth());
		};
		window.addEventListener("resize", resizeListener);

		return () => {
			window.removeEventListener("resize", resizeListener);
		};
	}, []);

	return screenWidth ? width < screenWidth : width < MOBILE_WIDTH;
};
