import { Reducer } from "redux";

interface ZenDeskState {
	loaded: boolean;
}

const defaultState: ZenDeskState = {
	loaded: false,
};

export const zenDeskReducer: Reducer<ZenDeskState, ZenDeskAction> = (state: ZenDeskState = defaultState, action: ZenDeskAction): ZenDeskState => {
	switch (action.type) {
		case "SET_ZENDESK_LOADED":
			return { ...state, loaded: true };
		default:
			return state;
	}
};

type ZenDeskAction = {
	type: "SET_ZENDESK_LOADED";
};

export const setZenDeskAsLoaded = (): ZenDeskAction => ({
	type: "SET_ZENDESK_LOADED",
});
