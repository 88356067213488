import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SeaticsLegendItem, SeaticsTicketGroup, SeaticsTicketSegment } from "../Seatics/types";
import { EventRouteParams, EventSearchParams } from "../../../model/EventPage";
import { loadEventData as loadEvent, processCheckout, processCheckoutBoxOffice } from "../../../services/eventPage";
import { SeaticsMap2 } from "../Seatics/Map";
import { setHighlightedTicket, setLoadedEvent, setSelectedTickets, setVisibleTickets, setShowEventInfo, setShowEventFilter, setShowReplacementModal, setLegendItems, setSection, setSeaticsTimeout, setHighlightedSection, setSelectedBOTickets } from "../../../store/actions/eventPageActions";
import { useDeepMemo } from "../../hooks/useDeepMemo";
import { Layout, PanelContainer, Content, eventPageMobileMediaQuery, PrimaryEventImage, EventDescriptionContainer, PrimaryEventImageContainer } from "./styles";
import { TicketList } from "./TicketList";
import { TicketView } from "./TicketView";
import { EventMobileHeader } from "./EventMobileHeader";
import { EventInfoPopup } from "../Event/EventInfoPopup";
import { TicketFilter } from "./TicketFilter";
import { UnavailableTicketsModal } from "./UnavailableTickets";
import { CommonPage } from "../CommonPage";
import { resetLoadingScreenData, setLoadingScreenData } from "../../../store/actions/loadingScreenDataActions";
import { MetaDecorator, getEventTagTitle, getEventTagDescription } from "../SEOMetadecorator/MetaDecorator";
import { useScript, useHubSpotTracker } from "../../hooks";
import { Redirect, useHistory } from "react-router-dom";
import { InternalRoutes } from "../../Link";
import { siThePartyEventId } from "../../util/general";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { FullScreenLoader } from "../Loader";
import { VfsImageWindow } from "./VfsImageWindow";
import { breakpoints } from "../../util/breakpoints";
import { EventCubeTicketList } from "../GAPage/EventCubeTicketList";
import { Config } from "../../../Config";
import { Nulleable } from "../../../model/Utils";
import { Typography } from "../Typography/Typography";
import { formatDate } from "../../util/dateFormat";
import { useAutoUpdateState } from "../../hooks/useAutoUpdateState";
import styled from "styled-components/macro";
import { useHeaderHeight } from "../../hooks/useHeaderHeight";
import { SantizedHTML } from "../SantizedHTML/SantizedHTML";
import { wrapImagesWithOverflow } from "../../util/wrapImagesWithOverflow";
import { useEventDateCheck } from "./hooks/useEventDateCheck";

interface EventContainerProps {
	params: EventRouteParams;
	searchParams: EventSearchParams;
	onCheckoutRequest: () => void;
	onEventNotFound: () => void;
	onRequestError: () => void;
	onReloadPage: () => void;
	onFiltersUpdate?: (filters: EventSearchParams) => void;
}

const vfsDisabled = true;

export const EventContainer = (props: EventContainerProps) => {
	const dispatch = useDispatch();
	const history = useHistory();

	useHubSpotTracker({ timeout: 30, page: "event", slug: props.params.event });

	/**
	 * Load MapWidget3 before any operation
	 */
	const frameworkLoaded = useScript("https://mapwidget3.seatics.com/api/framework");

	useEffect(() => {
		if (frameworkLoaded) {
			loadEvent(props.params.event).catch(props.onRequestError);
		}
	}, [props.params.event, frameworkLoaded]);

	const event = useSelector(state => state.transient.event);

	const filters = useDeepMemo(props.searchParams);

	const isPrimaryBoxOfficeEvent = useAutoUpdateState(() => event.eventData?.source === "boxoffice", [event.eventData?.source]);

	const isPrimaryEvent = useMemo(() => {
		if (isPrimaryBoxOfficeEvent) {
			return true;
		}
		if (event.tickets && event.tickets?.length > 0) {
			return event.tickets?.every(ticket => ticket.tgSource === "eventcube");
		}
		return false;
	}, [isPrimaryBoxOfficeEvent, props.params.event, event.tickets]);

	// Map events
	const onUpdateVisibleTickets = useCallback(
		(tickets: SeaticsTicketSegment[]) => {
			dispatch(setVisibleTickets(tickets));
		},
		[dispatch]
	);

	const onUpdateLegendItems = useCallback(
		(items: SeaticsLegendItem[]) => {
			dispatch(setLegendItems(items));
		},
		[dispatch]
	);

	const onEventLoaded = useCallback(
		(eventSlug: string) => {
			dispatch(setSeaticsTimeout(false));
			dispatch(setLoadedEvent(eventSlug));
		},
		[dispatch]
	);

	// Section events
	const onSectionHover = useCallback(
		(section: Nulleable<SeaticsLegendItem>) => {
			dispatch(setHighlightedSection(section));
		},
		[dispatch]
	);

	// Ticket list events
	const onTicketClick = useCallback(
		async (ticket: SeaticsTicketGroup) => {
			history.push(InternalRoutes.Event(props.params.event) + `/${ticket.tgID.toString()}` + `/${ticket.section?.vfsUrl ? "vfs" : "novfs"}`);

			await dispatch(setSelectedTickets(null));

			if (event?.selectedBOTickets && event?.selectedBOTickets[0].tgID !== ticket.tgID) {
				await dispatch(setSelectedBOTickets(null));
			}

			if (ticket.tgSource === "boxoffice") {
				dispatch(setSelectedBOTickets(ticket));
			} else {
				dispatch(setSelectedTickets(ticket));
			}
		},
		[dispatch]
	);

	const onTicketHover = useCallback(
		(ticket: Nulleable<SeaticsTicketGroup>) => {
			dispatch(setHighlightedTicket(ticket));
		},
		[dispatch]
	);

	const onOpenFilter = useCallback(() => {
		dispatch(setShowEventFilter(true));
	}, [dispatch]);

	const onSelectSection = useCallback(
		(section: SeaticsLegendItem) => {
			dispatch(setSection(section));
		},
		[dispatch]
	);

	const onCloseFilter = useCallback(() => {
		dispatch(setShowEventFilter(false));
	}, [dispatch]);

	const onCheckout = useCallback(
		async (quantity: number) => {
			await processCheckout(quantity).then(result => result && props.onCheckoutRequest());
		},
		[props.onCheckoutRequest, event.mainEventRelated]
	);


	const onCheckoutBoxOffice = useCallback(
		async ticketList => {
			await processCheckoutBoxOffice(ticketList).then(result => result && props.onCheckoutRequest());
		},
		[props.onCheckoutRequest, event.mainEventRelated]
	);

	// Header events
	const onShowEventInfo = useCallback(() => {
		dispatch(setShowEventInfo(!event.eventInfoVisible));
	}, [dispatch, event.eventInfoVisible]);

	const [vfsOpen, setVfsOpen] = useState(false);
	const [showMap, setShowMap] = useState(true);
	const [showMapClicked, setShowMapClicked] = useState(false);
	const isMobile = useMediaQuery(eventPageMobileMediaQuery);
	useEffect(() => {
		setVfsOpen(false);
	}, [event.selectedTicket]);

	useEffect(() => {
		history.replace(InternalRoutes.Event(props.params.event));
		dispatch(
			setLoadingScreenData({
				text: "Finding you the best tickets",
				textSecondLine: "from all of our sellers...",
			})
		);
		return () => {
			dispatch(resetLoadingScreenData());
		};
	}, []);

	useEffect(() => {
		if (isPrimaryEvent && event.eventData) {
			onEventLoaded(event.eventData?.slug);
		}
	}, [event?.eventData, isPrimaryEvent]);

	useEffect(() => {
		if (!!event.unavailableStatus) {
			history.push(`${history.location.pathname}/just-missed-out`);
		}
	}, [event.unavailableStatus]);

	const headerHeight = useHeaderHeight();
	const eventIsOld = useEventDateCheck(event.eventData?.date, event.eventData?.endTime);

	if (eventIsOld) {
		return <Redirect to={InternalRoutes.NotFound} />;
	}

	return (
		<CommonPage isFooterHidden={true} hideAllNavigation={false} hideHeader={window.matchMedia(`(max-width: ${breakpoints.tablet})`).matches} navOverridesMaxContentWidth={true} center={false} hideMobileAppNav>
			<MetaDecorator title={getEventTagTitle(event.eventData?.name, event.eventData?.venueCity, event.eventData?.venueState)} description={getEventTagDescription(event.eventData?.name)} />
			{!!event.selectedTicket && !isMobile && vfsOpen && (
				<VfsImageWindow
					src={event.selectedTicket?.section?.vfsUrl}
					onClose={() => {
						setVfsOpen(false);
					}}
				/>
			)}
			<Layout>
				<FullScreenLoader show={event.loading} />
				<EventInfoPopup show={event.eventInfoVisible} eventData={event.eventData} onClose={onShowEventInfo} />
				<UnavailableTicketsModal visible={event.unavailableStatus} message={event.unavailableMessage} onClose={props.onReloadPage} />
				<EventMobileHeader
					eventData={event.eventData}
					onShowEventInfo={onShowEventInfo}
					onBackClick={() => {
						if (history.action === "POP") {
							history.push(event.eventData?.id === siThePartyEventId ? InternalRoutes.BigGameWeekend : InternalRoutes.Home);
						} else {
							history.go(-1);
						}
					}}
				/>

				<Content>
					<PanelContainer>
						{isPrimaryEvent ? (
							<>
								{!!event.eventData && isPrimaryBoxOfficeEvent && (
									<div
										css={`
											padding: 17px;
											background: #f8f8f8;
										`}
									>
										<Typography type="heading2">
											<SantizedHTML htmlString={event.eventData.name} />{" "}
										</Typography>
										<Typography
											type="bodyNormal"
											css={`
												margin-top: 2px;
											`}
										>
											<SantizedHTML htmlString={event.eventData.description ?? ""} />
										</Typography>
										<Typography
											type="bodySmall"
											css={`
												margin-top: 7px;
											`}
										>
											{!!event.eventData.date && !event.eventData.endDate && formatDate(event.eventData.date, "ccc, LLL d y 'at' t")}
											{!!event.eventData.date && !!event.eventData.endDate  && 
												`${formatDate(event.eventData.date, "ccc, LLL d y t")}`
											}
											<br />
											{event.eventData.venueName}, {event.eventData.venueStreetAddress}, {event.eventData.venueCity}, {event.eventData.venueState}, {event.eventData.venueZip}
										</Typography>
										{isMobile && event?.eventData?.longDescription && (
											<EventDescriptionContainer>
												<SantizedHTML htmlString={wrapImagesWithOverflow(event.eventData.longDescription)} />
											</EventDescriptionContainer>
										)}
									</div>
								)}
								<EventCubeTicketList onCheckoutBoxOffice={onCheckoutBoxOffice} tickets={event.tickets}  onCheckout={onCheckout} onTicketClick={onTicketClick} event={event.eventData} hideRelatedEvent={isPrimaryBoxOfficeEvent} />
							</>
						) : (
							<>
								<TicketList filters={filters} enableFilters={!event.seaticsTimeout} legendItems={event.legendItems} visibleTickets={event.visibleTickets} onFilterClick={onOpenFilter} onSelectSection={onSelectSection} onTicketClick={onTicketClick} onTicketHover={onTicketHover} onSectionHover={onSectionHover} selectedSection={event.selectedSection} eventData={event.eventData} showMap={showMap} setShowMap={setShowMap} showMapClicked={showMapClicked} vfsDisabled={vfsDisabled} />
								<TicketView
									eventData={event.eventData}
									selectedQuantity={filters.quantity}
									selectedTicket={event.selectedTicket}
									onCheckout={onCheckout}
									onVfsImageClick={() => {
										setVfsOpen(true);
									}}
									onTicketViewClose={() => {
										setVfsOpen(false);
									}}
									showTopMessage={false}
									vfsDisabled={vfsDisabled}
								/>
								<TicketFilter filters={filters} mapData={event.mapData} open={event.eventFilterVisible} deliveryMethods={event.eventData?.deliveryMethods} tickets={event.tickets} onFilterChange={props?.onFiltersUpdate} onClose={onCloseFilter} />
							</>
						)}
					</PanelContainer>
					{isPrimaryEvent ? (
						<div
							css={`
								overflow-y: scroll;
								${!isMobile &&
								`
								flex-grow: 1;
								max-height: calc(100vh - ${headerHeight}px);`}
							`}
						>
							<PrimaryEventImageContainer
								css={`
									max-height: calc(75vh - ${headerHeight}px);
								`}
							>
								<PrimaryEventImage src={event?.eventData?.image ? event.eventData.image : undefined} alt="Event image" />
							</PrimaryEventImageContainer>
							{!isMobile && event?.eventData?.longDescription && (
								<EventDescriptionContainer>
									<SantizedHTML htmlString={wrapImagesWithOverflow(event.eventData.longDescription)} />
								</EventDescriptionContainer>
							)}
						</div>
					) : (
						<SeaticsMap2
							style={
								isMobile && event.eventData?.id === siThePartyEventId
									? {
											display: "none",
									  }
									: undefined
							}
							filters={filters}
							currentEvent={props.params.event}
							eventData={event.eventData}
							tickets={event.tickets}
							mapData={event.mapData}
							selectedSection={event.selectedSection}
							seaticsTimeout={event.seaticsTimeout}
							loadedEventSlug={event.loadedEventSlug}
							selectedTicket={event.selectedTicket}
							highlightedTicket={event.highlightedTicket}
							onEventLoaded={onEventLoaded}
							onUpdateVisibleTickets={onUpdateVisibleTickets}
							onUpdateLegendItems={onUpdateLegendItems}
							highlightedSection={event.highlightedSection}
							showMap={showMap}
							setShowMap={setShowMap}
							setShowMapClicked={setShowMapClicked}
						/>
					)}
				</Content>
			</Layout>
		</CommonPage>
	);
};
