import React, { useDebugValue } from "react";
import { formatPrice } from "../../util/formatPrice";
import { Invoice, InvoiceComponentItem } from "./Invoice";
import { BaseInvoiceComponentProps } from "../../../model/optimizedModel/invoice";
import { BaseInvoiceHookInput } from "../../../model/optimizedModel/invoice";
import { useMemoState } from "../../hooks/useMemoState";
import { useInvoiceOutputUpdate } from "./hooks/useInvoiceOutputUpdate";
import { useAutoUpdateState } from "../../hooks/useAutoUpdateState";
import { TransactionFeeRule } from "../../../model/optimizedModel/braintree";
import { getTransactionFee } from "../../util/prices";
import { useCheckout } from "../../../services/hooks/useCheckout";
import { Typography } from "../Typography/Typography";
import styled from "styled-components/macro";
import { Tier } from "../../../model/Cart";
import { useSelector } from "react-redux";

export type TicketInvoiceInput = BaseInvoiceHookInput & {
	tiersInfo?: Tier[];
	quantity: number;
	price: number;
	deliveryFee?: number;
	serviceFee?: number;
	serviceFeeRate?: number;
	tax?: number | null;
	transactionFeeRule?: TransactionFeeRule;
	eventId?: number | null;
};

export const useTicketInvoiceOutput = (input: TicketInvoiceInput) => {
	const source = useSelector(state=>state.persistent.cart?.source);

	useDebugValue("useTicketInvoiceOutput");
	const subtotal = useMemoState(() => {
		let total : number;
		if (source === 'boxoffice' && input.tiersInfo){
			total = input.tiersInfo.reduce((accumulator, tierObj) => {
				let quantity = tierObj.quantity;
				let price = tierObj.retailPrice;
				let subtotal = quantity * price;
				return accumulator + subtotal;
			}, 0);
		} else {
			total = input.price * input.quantity;
		}
		
		return total ?? 0;
	}, [input.tiersInfo, input.price, input.quantity]);

	const transactionFee = useAutoUpdateState(() => {
		const value = getTransactionFee(input.transactionFeeRule, input.price, input.quantity, source, input.tiersInfo);
		
		return value;
	}, [input.transactionFeeRule, input.tiersInfo, input.price, input.quantity]);

	const serviceFee = useAutoUpdateState(() => {
		if (input.serviceFeeRate) {
			return input.serviceFeeRate * subtotal;
		}
		return 0;
	}, [input.serviceFeeRate, subtotal]);

	//TODO: Consider putting all of the below code into it's own hook.
	const output = useAutoUpdateState(() => {
		const value = {
			subtotal,
			transactionFee,
			calculatedServiceFee: serviceFee,
		};
		return value;
	}, [subtotal, transactionFee, serviceFee]);

	useInvoiceOutputUpdate(output, input.onUpdate);

	return output;
};

export const TicketInvoice = ({ eventId, price, tiersInfo, transactionFeeRule, deliveryFee, quantity, onUpdate, tax, serviceFeeRate, serviceFee, ...invoiceProps }: TicketInvoiceInput & BaseInvoiceComponentProps) => {
	const {
		subtotal,
		transactionFee: displayedTransactionFee,
		calculatedServiceFee,
	} = useTicketInvoiceOutput({
		price,
		tiersInfo,
		deliveryFee,
		transactionFeeRule,
		quantity,
		onUpdate,
		tax,
		serviceFeeRate,
	});
	const source = useSelector(state=>state.persistent.cart?.source);


	const { activeStep, discount } = useCheckout();

	return (
		<Invoice
			{...invoiceProps}
			items={(() => {
				const arr: InvoiceComponentItem[] = [
					{
						key: "subtotal",
						title: `Ticket Subtotal${source !== "boxoffice" && subtotal ? "(" + quantity + " x " + formatPrice(!!discount && discount.type == "FROM_TOTAL" ? discount.newPrice / quantity : price) + ")" : ""}`,
						value: subtotal ? (
							!!discount && discount.type === "FROM_TOTAL" ? (
								<div style={{ display: "flex" }}>
									<OldPrice type="bodyNormal" fontWeight={600}>
										{formatPrice(subtotal)}
									</OldPrice>
									{discount && !!subtotal && (
										<Typography type="bodyNormal" color="success" colorType="dark" fontWeight={600}>
											{formatPrice(discount.ticketTotal)}
										</Typography>
									)}
								</div>
							) : (
								<div>{formatPrice(subtotal)}</div>
							)
						) : (
							<>Free</>
						),
						css: {
							fontSize: 15,
							fontWeight: 500,
						},
					},
				];
				if (transactionFeeRule && displayedTransactionFee) {
					arr.push({
						key: "transactionFee",
						value: !!discount?.newtransactionFee ? (
							<Typography type="bodyNormal" color={discount?.type === "PROMO_FEE" ? "success" : "dark"} colorType="dark" fontWeight={discount?.type === "PROMO_FEE" ? 600 : 400}>
								{formatPrice(getTransactionFee(discount.newtransactionFee, discount.newPrice / quantity, quantity))}
							</Typography>
						) : (
							formatPrice(displayedTransactionFee)
						),
						title:
							!!discount && discount.type === "PROMO_FEE" ? (
								<Typography type="bodyNormal" color="success" colorType="dark" fontWeight={600}>
									{`${discount.name} Deal`}
								</Typography>
							) : (
								"Transaction Fee"
							),
					});
				}
				if (serviceFee) {
					arr.push({
						key: "serviceFee",
						value: formatPrice(serviceFee),
						title: `CC Service Fee`,
					});
				}
				if (calculatedServiceFee && !serviceFee) {
					arr.push({
						key: "serviceFee",
						value: formatPrice(calculatedServiceFee),
						title: `Service Fee`,
					});
				}
				if (deliveryFee) {
					arr.push({
						key: "deliveryFee",
						title: "Delivery Fee",
						value: formatPrice(deliveryFee),
					});
				}

				if (!!tax) {
					arr.push({
						key: "tax",
						value: formatPrice(tax),
						title: "Tax",
					});
				}
				return arr;
			})()}
		/>
	);
};

const OldPrice = styled(Typography)`
	margin-right: 5px;

	p {
		text-align: left;
		text-decoration: line-through;
	}
`;
