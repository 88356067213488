import React from "react";
import Helmet from "react-helmet";
import { CategoriesMetaData, FuturesCategoriesMetaData, SEOCategories, SEOFuturesCategories } from "./CategoriesMetaData";
import { getPerformerTitle, getPerformerDescription } from "./PerformersMetaData";
import { firstLetterToUpperCase } from "../../util/stringFormat";

interface MetaDecoratorProps {
	title: string;
	description: string;
	preventCrawl?: boolean;
}

export const MetaDecorator = (props: MetaDecoratorProps) => {
	return (
		<Helmet>
			<title>{props.title}</title>
			<meta name="description" content={props.description} />
			{props.preventCrawl && <meta name="robots" content="noindex,nofollow" />}
		</Helmet>
	);
};

/*-------------- Category level SEO methods ------------*/

export const getCategoryTagTitle = (category: string) => {
	let key: keyof typeof CategoriesMetaData = category as any;

	if (SEOCategories.includes(key)) {
		return CategoriesMetaData[key].title;
	}
	return MetaTagData.Home.title;
};

export const getCategoryTagDescription = (category: string) => {
	let key: keyof typeof CategoriesMetaData = category as any;

	if (SEOCategories.includes(key)) {
		return CategoriesMetaData[key].description;
	}
	return MetaTagData.Home.description;
};
/* ----------------------------------------------------- */

/*-------------- Futures level SEO methods ------------*/

export const getFuturesCategoryTagTitle = (category: string) => {
	let key: keyof typeof FuturesCategoriesMetaData = category as any;

	if (SEOFuturesCategories.includes(key)) {
		return FuturesCategoriesMetaData[key].title;
	}
	return MetaTagData.Futures.title;
};

export const getFuturesCategoryTagDescription = (category: string) => {
	let key: keyof typeof FuturesCategoriesMetaData = category as any;

	if (SEOFuturesCategories.includes(key)) {
		return FuturesCategoriesMetaData[key].description;
	}
	return MetaTagData.Futures.description;
};

export const getFutureEventTagTitle = (event?: string, location?: string | null) => {
	let title;
	if (event) {
		title = `SI Tickets | Buy Tickets for ${event}`;

		if (location) {
			return ` ${title} - ${location}`;
		} else {
			return title;
		}
	}
	return MetaTagData.Home.description;
};

export const getFutureEventTagDescription = (event?: string) => {
	if (event) {
		return `Ticket listings, seating chart, and best ticket deals for ${event}`;
	}
	return MetaTagData.Home.description;
};

/* ----------------------------------------------------- */

/*-------------- Performer level SEO methods ------------*/

export const getPerformerTagTitle = (performer: string) => {
	const parsedPerformer = firstLetterToUpperCase(performer.replaceAll("-", " "));
	return getPerformerTitle(parsedPerformer);
};

export const getPerformerTagDescription = (performer: string) => {
	const parsedPerformer = firstLetterToUpperCase(performer.replaceAll("-", " "));
	return getPerformerDescription(parsedPerformer);
};

/* ----------------------------------------------------- */

/*-------------- Event level SEO methods ------------*/
export const getEventTagTitle = (event?: string, city?: string | null, state?: string | null) => {
	if (event && city && state) {
		return `SI Tickets | Buy  Tickets for ${event} - ${city}, ${state}`;
	}
	return MetaTagData.Home.title;
};

export const getEventTagDescription = (event?: string) => {
	if (event) {
		return `Ticket listings, seating chart, and best ticket deals for ${event}`;
	}
	return MetaTagData.Home.description;
};
/* ----------------------------------------------------- */

/*-------------- Venue level SEO methods ------------*/
export const getVenueTagTitle = (venue: string | undefined) => {
	if (venue) {
		return `${venue} Tickets & Seating Chart | SI Tickets`;
	}

	return MetaTagData.Home.description;
};

export const getVenueTagDescription = (venue: string | undefined) => {
	if (venue) {
		return `Check ${venue} tickets, events and information.  View the ${venue} maps and seating charts.`;
	}

	return MetaTagData.Home.description;
};
/* ----------------------------------------------------- */

export const MetaTagData = {
	OtherSportsSubCategoriesTitle: (category: string) => `${category.toUpperCase()} Tickets | ${category.toUpperCase()} Teams and Schedule Info on SI Tickets`,
	OtherSportsSubCategoriesDescription: (category: string, specialText: string) => `${category.toUpperCase()} tickets for the season and ${specialText} on SI Tickets. Pay with Venmo for the best prices.`,
	Cart: {
		title: "Cart",
		description: "Cart Description",
	},

	Checkout: {
		title: "Checkout",
		description: "Checkout Description",
	},

	SIBets: {
		title: "SI Tickets: Sports betting picks from the Biggest Sportsbooks",
		description: "Get sports betting advice online, top picks, and the best payouts from the biggest sportsbooks at SI Tickets.",
	},

	Event: {
		title: "Event",
		description: "Event Description",
	},

	Performer: {
		title: " <CATEGORY> Tickets | Buy <PERFORMER> Tickets at the best price",
		description: "Get the best prices and seats for <EVENT> with <PERFORMER>",
	},

	Discover: {
		title: "SI Tickets: Find Tickets to Events Near Me",
		description: "Buy tickets online with SI Tickets for local events near me. Pay with Venmo for the best prices.",
	},

	Popular: {
		title: "Popular",
		description: "Popular Description",
	},

	OurTeam: {
		title: "Our Team",
		description: "Our Team Description",
	},

	Search: {
		title: "Search",
		description: "Search Description",
	},

	SearchResults: {
		title: "Search Results",
		description: "Search Results Description",
	},

	About: {
		title: "About us",
		description: "About Description",
	},

	Venmo: {
		title: "Venmo",
		description: "Venmo Description",
	},

	Terms: {
		title: "SI Tickets Terms Of Use",
		description: "Terms Of Use Description",
	},

	Privacy: {
		title: "SI Tickets Privacy Policy",
		description: "Privacy Policy Description",
	},

	SITixChampionshipLegal: {
		title: "SITIX National Championship Legal",
		description: "SITIX National Championship Legal Description",
	},

	MyAccount: {
		title: "My Account",
		description: "My Account Description",
	},

	PasswordReset: {
		title: "Password Reset",
		description: "Password Reset Description",
	},

	Login: {
		title: "Log in",
		description: "Log in Description",
	},

	Home: {
		title: "SI Tickets: Buy Tickets to Sports, Concerts, and Theater Events",
		description: "Buy tickets online for sports, concerts, theater, and other events near you. Get the best ticket deals by paying with Venmo for the best prices.",
	},

	NotFound: {
		title: "Not Found",
		description: "Not Found Description",
	},

	PrivacyPolicy: {
		title: "Privacy Policy",
		description: "Privacy Policy Description",
	},

	Profile: {
		title: "Profile",
		description: "Profile Description",
	},

	Tickets: {
		title: "Tickets",
		description: "Tickets Description",
	},

	CheckoutThanks: {
		title: "Checkout Thanks",
		description: " Checkout Thanks Description",
	},

	TeamFutures: {
		title: "Team Futures",
		description: " Team Futures Description",
	},

	Futures: {
		title: "SI Tickets: Reserve Postseason and Championships Tickets ",
		description: "Reserve postseason, playoffs and championships tickets all year long. Pay with Venmo for the best prices .",
	},

	FanGuarantee: {
		title: "SI Tickets: Fan Guarantee ",
		description: "Fan Guarantee description .",
	},

	GolfClassic: {
		title: "2022 Golf Classic",
		description: "Enter for a chance to win a VIP experience at the 2022 Golf Classic.",
	},

	Formula1: {
		title: "Formula 1 Aramco United States Grand Prix",
		description: "Get ready for a spectacle of speed and sound. Sports Illustrated, the most iconic brand in sports, hosts Club SI at the 2021 Formula 1 Aramco United States Grand Prix on October 22-24, 2021!",
	},

	SITixChampionship: {
		title: "SI Tickets National Championship Contest",
		description: "Simply enter your email for a chance to win tickets to Venmo’s VIP Suite at Lucas Oil Stadium in Indianapolis for the 2022 College Football National Championship on January 10th!.",
	},

	BigGameWeekend: {
		title: "The Big Game Weekend",
		description: "SI Tickets wants to get you into the biggest game & events of the year",
	},
	JcPenney10kGiveaway: {
		title: "JCPenney 10K Giveaway",
		description: "Enter your email for a chance to win up to 10K in tickets to events of your choice, anywhere in the world.",
	},
	MASL: {
		title: "SI Tickets | Official Ticket Partner of the MASL",
		description: "Catch the fast-paced, fiercely competitive end-to-end action featuring teams coast-to-coast in the U.S. and Mexico. You don’t want to miss the non-stop dazzling plays, magnificent goals and sensational goalkeeping that only the arena league can deliver.",
	},
	Covers: {
		title: "SI Fan Cover",
		description: "Celebrate the moment. Get your own SI fan cover from SI Tickets by Sports Illustrated, the fan first ticketing platform.",
	},
};
