import React from "react";
import { ModalPopup } from "../ModalPopup/ModalPopup";
import { ModalCloseButton } from "../Modal/ModalCloseButton";
import styled from "styled-components/macro";
import { ModalButton, ModalButtonContainter } from "../../util/ModalButton";

export const FuturesTermsModal = (props: { onDidClose: () => void; displayHotelTerms?: boolean }) => {
	return (
		<ModalPopup onDidClose={props.onDidClose} backgroundColor="#fff" centerHeaderElements="Details" rightHeaderElements={onClose => <ModalCloseButton onClick={onClose} />}>
			{onClose => (
				<>
					<List>
						<li>Pay the Reservation Price Today to Reserve Your {props.displayHotelTerms ? "Hotel Room" : "Event Tickets"}</li>
						<li>When your Team Makes the Game, Pay the Listed {props.displayHotelTerms ? "Hotel" : "Ticket"} Price</li>
						<li>Each Reservation Purchased Entitles you to One (1) {props.displayHotelTerms ? "Hotel Room" : "Event Tickets"}</li>
						<li>You Can List & Sell Your {props.displayHotelTerms && "Hotel "}Reservation(s) on SI Tickets until the Market Closes</li>
						<li>You are Reserving {props.displayHotelTerms ? "Hotel Room at a Venue Near the Event" : "one or more Event Ticket(s) for a Specific Team, Game and Seating Zone"}</li>
						<li>The Reservation Price is Non-Refundable</li>
					</List>
					<ModalButtonContainter>
						<ModalButton onClick={onClose}>Got It</ModalButton>
					</ModalButtonContainter>
				</>
			)}
		</ModalPopup>
	);
};

const List = styled.ul`
  padding: 15px 30px;
  max-width: 350px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  font-weight: 500;
  color: #000;
  margin-top: 10px;
  li {
    margin-top 5px;
  }
`;
