import styled from "styled-components/macro";
import { mediaQueries } from "../../util/mediaQueries";
import { getAppColor } from "../../util/appColors";
import { getTypographyStyles } from "../Typography/Typography";
import { breakpoints } from "../../util/breakpoints";

export const Title = styled.span`
	display: block;
	color: ${getAppColor("dark")};
	line-height: normal;
	font-weight: bold;
	text-align: left;
	font-size: 1rem;
`;

export const EventTitle = styled.span`
	display: block;
	color: ${getAppColor("dark")};
	line-height: normal;
	font-weight: bold;
	text-align: left;
	font-size: 1rem;
	@media ${mediaQueries.max670} {
		font-weight: 600;
		font-size: 14px;
	}

	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
`;

export const EventCard = styled.div<{ gradient?: boolean }>`
	background-color: ${getAppColor("light")};
	border: 1px solid #dcdcdc;
	margin-bottom: -2px;
	font-family: "Montserrat", sans-serif;
	cursor: pointer;
	position: relative;
	&:hover {
		background-color: ${getAppColor("lightGrey")};
	}
	a {
		color: ${getAppColor("dark")};
	}
`;

export const eventContentLeftRightPadding = "0.85rem";
export const eventContentTopBottomPadding = "0.625rem";

export const EventContent = styled.div<{ gradient?: boolean; newDesign?: boolean }>`
	display: flex;
	padding: ${eventContentTopBottomPadding} ${eventContentLeftRightPadding};
	align-items: center;
	justify-content: center;
	${props => props.newDesign && `
		height: 100%;
		box-sizing: border-box;
	`}
`;

export const ColumnPrice = styled.div`
	@media only screen and ${mediaQueries.max670} {
		display: none;
	}
`;

const columnBodyBorder = (borderless?: boolean) => (borderless ? "none" : "1px solid #E5E5E5");

const columnPadding = "1rem";

export const ColumnBody = styled.div<{
	borderless?: boolean;
	newDesign?: boolean;
}>`
	flex: 3;
	padding-left: ${columnPadding};
	margin-left: ${eventContentLeftRightPadding};
	border-left: ${props => columnBodyBorder(props.borderless)};
	border-right: none;
	overflow: hidden;
	${props => props.newDesign && `
		height: 100%;
		display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
	`}
	&.future {
		border-left: none;
		padding-left: 0;
		& > ${Title} {
			margin-right: ${eventContentLeftRightPadding};
		}
	}
`;

export const sideColumnWidthMobile = "5.875rem";
export const sideColumnWidth = "4.875rem";

export const SideColumn = styled.div`
	text-align: center;
	font-weight: normal;
	color: ${getAppColor("dark")};
	min-width: ${sideColumnWidth};
	max-width: ${sideColumnWidth};
	font-size: 1rem;
	white-space: nowrap;
	line-height: normal;
	p {
		&:last-child {
			font-size: 0.9rem;
		}
		&:nth-child(1) {
			text-transform: capitalize;
			font-size: 1em;
		}
		&:nth-child(2) {
			color: ${getAppColor("dark")};
			font-weight: bold;
			font-size: 1.1rem;
		}
		&:nth-child(3):not(:last-child) {
			color: ${getAppColor("dark")};
			font-size: 1rem;
		}
	}
`;

export const ColumnBets = styled.div`
	@media only screen and ${mediaQueries.max670} {
		display: none;
	}
`;

export const MobileLayout = styled.div`
	margin-top: 3px;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
	& > div:first-of-type {
		@media screen and ${mediaQueries.max670} {
			margin-bottom: auto;
		}
	}
`;

/**
 * Event table
 */
export const EventTable: any = {
	Row: styled.div`
		display: table-row;
	`,
	Cell: styled.div<{ align?: "left" | "right" }>`
		display: table-cell;
		text-align: ${props => props.align ?? "left"};
		color: #000;
	`,
	Header: styled.div`
		font-weight: bold;
		display: table-cell;
		color: ${getAppColor("dark")};
		padding-right: 10px;
	`,
};

EventTable.Table = styled.div`
	text-align: left;
	display: table;
	font-size: 1rem;
	line-height: normal;
	& > ${EventTable.Row}:first-of-type ${EventTable.Cell} {
		padding-bottom: 1px;
	}
`;

const cornerBadgeWidthHeight = "50px";

const mobileCornerBadgeWidthHeight = "1.75rem";

export const CornerBadge = styled.div`
	position: absolute;
	right: 0px;
	bottom: 0px;
	&,
	img {
		width: ${cornerBadgeWidthHeight};
		height: ${cornerBadgeWidthHeight};
	}
	@media only screen and ${mediaQueries.max670} {
		&,
		img {
			width: ${mobileCornerBadgeWidthHeight};
			height: ${mobileCornerBadgeWidthHeight};
		}
	}
`;

export const StyledBetSmall = styled.div`
	margin: 0;
	padding: 0;
	margin-top: 6px;
	display: flex;
	color: ${getAppColor("dark")};
`;

export const Arrow = styled.img`
	margin: 0px ${eventContentLeftRightPadding};
`;

export const MobileText = styled.div`
	display: none;
	@media ${mediaQueries.max750} {
		display: block;
	}
`;

export const DesktopText = styled.div`
	display: block;
	@media ${mediaQueries.max750} {
		display: none;
	}
`;

export const PostponedSection = styled.div<{
	forceMobileDesign?: boolean;
}>`
	${getTypographyStyles("bodySmall")};
	width: ${sideColumnWidth};
	div:nth-child(1) {
		text-transform: capitalize;
		font-weight: bold;
	}

	@media (max-width: ${props => (props.forceMobileDesign ? "100vw" : breakpoints.mobile)}) {
		${getTypographyStyles("bodyTiny")};
		width: ${sideColumnWidthMobile};
	}
`;

export const EventCardSideColumn = styled.div<{
	forceMobileDesign?: boolean;
}>`
	${getTypographyStyles("bodySmall")};
	text-align: center;
	font-weight: normal;
	width: ${sideColumnWidth};
	white-space: nowrap;

	@media (max-width: ${props => (props.forceMobileDesign ? "100vw" : breakpoints.mobile)}) {
		font-size: 12px;
		width: ${sideColumnWidthMobile};
	}
	p {
		margin-bottom: -3px;
		&:last-child {
			color: ${getAppColor("darkGrey", "dark")};
		}
		&:nth-child(1) {
			margin-top: -5px;
			text-transform: capitalize;
			color: ${getAppColor("darkGrey", "dark")};
		}
		&:nth-child(2) {
			font-weight: bold;
		}
		&:nth-child(3):not(:last-child) {
			font-weight: bold;
		}
	}
`;

export const NewEventCardSideColumn = styled.div<{
	forceMobileDesign?: boolean;
}>`
	text-align: center;
	font-weight: normal;
	width: ${sideColumnWidth};
	white-space: nowrap;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	p {
		&:nth-child(1) {
			${getTypographyStyles("bodyNormal", { fontWeight: 600 })};
			text-transform: capitalize;
		}
		&:nth-child(2) {
			${getTypographyStyles("bodyTiny", {
				color: "darkGrey",
				colorType: "dark",
			})};
		}
	}
	@media (max-width: ${props => (props.forceMobileDesign ? "100vw" : breakpoints.mobile)}) {
		width: ${sideColumnWidthMobile};
		p {
			&:nth-child(1) {
				${getTypographyStyles("bodySmall", { fontWeight: 600 })};
				text-transform: capitalize;
			}
			&:nth-child(2) {
				${getTypographyStyles("bodyExtraTiny", {
					color: "darkGrey",
					colorType: "dark",
				})};
			}
		}
	}
`;

export const EventCardTitle = styled.span<{
	forceMobileDesign?: boolean;
}>`
	${getTypographyStyles("bodyMedium", {
		fontWeight: 700,
	})};
	text-align: left;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	width: calc(100% - 1rem);
	@media (max-width: ${props => (props.forceMobileDesign ? "100vw" : breakpoints.mobile)}) {
		font-size: 14px;
		line-height: 20px;
	}
`;

export const NewEventCardTitle = styled.span<{
	forceMobileDesign?: boolean;
}>`
	${getTypographyStyles("bodyNormal", {
		fontWeight: 600,
	})};
	text-align: left;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	width: calc(100% - 1rem);
	@media (max-width: ${props => (props.forceMobileDesign ? "100vw" : breakpoints.mobile)}) {
		${getTypographyStyles("bodySmall")};
	}
`;

export const EventCardSubTitle = styled.div<{
	forceMobileDesign?: boolean;
}>`
	${getTypographyStyles("bodyNormal")};
	width: calc(100% - 1rem);
	@media (max-width: ${props => (props.forceMobileDesign ? "100vw" : breakpoints.mobile)}) {
		font-size: 12px;
		line-height: 18px;
	}
`;

export const NewEventCardSubTitle = styled.div<{
	forceMobileDesign?: boolean;
}>`
	${getTypographyStyles("bodySmall")};
	width: calc(100% - 1rem);
	@media (max-width: ${props => (props.forceMobileDesign ? "100vw" : breakpoints.mobile)}) {
		${getTypographyStyles("bodyTiny")};
	}
`;
