import React from "react";
import styled from "styled-components/macro";
import { Config } from "../../../Config";
import { maxContentWidthStyles } from "../../util/maxContentWidth";
import { SingleAd } from "./SingleAd";

export const MultiAd = (props: {
	title?: string;
	items?: {
		url?: string;
		image?: string;
	}[];
	loading?: boolean;
}) => {
	return (
		<MultiAdContainer>
			{props.items?.map((ad, index) => {
				return <SingleAd key={index} {...ad} title={props.title} loading={props.loading} image={Config.getCDNUrl(ad.image)} />;
			})}
		</MultiAdContainer>
	);
};

const MultiAdContainer = styled.div`
	display: flex;
	${maxContentWidthStyles};
	padding: initial;

	& > div {
		&:not(:last-child) {
			margin-right: 16px;
		}
	}
`;
