import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

const containerTransitionLength = 225;

export const Expandable = (
	props: React.PropsWithChildren<{
		expanded?: boolean;
		className?: string;
		minHeight?: number;
	}>
) => {
	const content = useRef<HTMLDivElement>(null);

	const minHeight = props.minHeight ? props.minHeight : 0;
	const [maxHeight, setMaxHeight] = useState(!!props.expanded ? "initial" : minHeight);
	useEffect(() => {
		const contentHeight = content.current?.clientHeight;
		if (contentHeight !== undefined) {
			setMaxHeight(contentHeight);
			if (props.expanded) {
				setTimeout(() => {
					setMaxHeight("initial");
				}, [containerTransitionLength]);
			} else {
				setTimeout(() => {
					setMaxHeight(minHeight);
					//Note: This 50 MS timeout is added to prevent React from merging this state update and the first state update together. It's a pretty hacky implementation. Consider implementing a better approach.
				}, [50]);
			}
		}
	}, [props.expanded]);

	return (
		<Container
			style={{
				maxHeight,
			}}
		>
			<Content ref={content} className={props.className}>
				{props.children}
			</Content>
		</Container>
	);
};

const Content = styled.div`
	display: flow-root;
`;

const Container = styled.div`
	transition: max-height ${containerTransitionLength}ms ease-in-out;
	overflow: hidden;
	max-height: 0;
`;
