import React from "react";
import styled from "styled-components/macro";
import { SkeletonProps } from "../../../model/optimizedModel/skeleton";
import { useAutoUpdateState } from "../../hooks/useAutoUpdateState";
import { AppColorKey, AppColorObject, appColors, getAppColorTextColor } from "../../util/appColors";
import { selectableTransitionDuration, selectableTransitionTimingFunction } from "../../util/transitions";
import { SkeletonWrapper } from "../SkeletonWrapper/SkeletonWrapper";
import { getTypographyStyles, TypographyType } from "../Typography/Typography";

export type BaseButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
	variant?: "solid" | "outline";
	typographyType?: TypographyType;
	color?: Exclude<AppColorKey, "disabled">;
	customColor?: AppColorObject; 
} & SkeletonProps;

export const BaseButton = ({ style, color = "dark", variant = "solid", typographyType = "normalSpecialBody", loading, className, customColor, ...restProps }: BaseButtonProps) => {

	const textColor = useAutoUpdateState(() => customColor?.text || getAppColorTextColor(color), [color, customColor?.text]);

	//TODO: Integrate with getAppColor here (requires logic update in the JSX portion of this component).
	const appColor = useAutoUpdateState(() => customColor || appColors[color], [color, customColor]);

	return (
		<SkeletonWrapper loading={loading} className={className}>
			<StyledButton
				{...restProps}
				css={`
					background: ${appColor.main};
					&:not(:disabled):hover {
						background: ${(() => {
							//TODO: Consider finding a method to reuse the logic to return light or dark and the logic to access the properties alongside the 'getAppColorTextColor' function in util/appColors.ts.
							if ("light" in appColor && appColor.light) {
								return appColor.light;
							} else if ("dark" in appColor && appColor.dark) {
								return appColor.dark;
							}
						})()};
					}
					${getTypographyStyles(typographyType)}
					color: ${textColor};
					display: flex;
					align-items: center;
					justify-content: center;
				`}
				style={{
					...style,
					...(variant === "outline" && {
						border: "1px solid " + textColor,
					}),
				}}
			/>
		</SkeletonWrapper>
	);
};

//TODO: Look into fixing border sizing issues.
export const StyledButton = styled.button`
	border: 0;
	outline: none;
	margin: 0;
	box-sizing: border-box;
	display: block;
	text-align: center;
	cursor: pointer;
	transition: all ${selectableTransitionTimingFunction} ${selectableTransitionDuration};
	user-select: none;
	width: 100%;
	height: 100%;
	border-radius: 5px;
	&:disabled {
		color: ${getAppColorTextColor("disabled")};
		background: ${appColors.disabled.main};
		cursor: not-allowed;
	}
	* {
		//TODO: Consider looking into a strategy to remove the '!important'.
		color: inherit !important;
	}
`;
