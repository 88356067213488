import React from "react";
import { TotalInvoiceItem } from "./TotalInvoiceItem";
import { maxContentWidthPadding } from "../../util/maxContentWidth";
import { horizontalPaddingObject, verticalPaddingObject } from "../../util/optimized/styles";
import { getAppColor } from "../../util/appColors";

export const FutureTotalInvoiceItem = ({ priceFormat = "short", css, ...invoiceItemProps }: Omit<React.ComponentProps<typeof TotalInvoiceItem>, "title">) => {
	return (
		<TotalInvoiceItem
			{...invoiceItemProps}
			title="Due Today"
			priceFormat={priceFormat}
			css={{
				color: getAppColor("light"),
				overflow: "initial",
				...horizontalPaddingObject(17),
				...verticalPaddingObject(maxContentWidthPadding),
				background: getAppColor("dark"),
				...css,
			}}
		/>
	);
};
