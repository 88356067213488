import React from "react";
import { useHistory } from "react-router-dom";
import { LinkProps } from "../../Link";
import { Button, ButtonProps } from "./Button";

interface ButtonLinkProps extends ButtonProps {
	to: LinkProps["to"];
	newTab?: boolean;
}

export const ButtonLink = ({ to, ...rest }: ButtonLinkProps) => {
	const history = useHistory();
	return (
		<Button
			onClick={event => {
				if (!rest.newTab) {
					event.preventDefault();
					history.push(to);
				} else {
					window.open(to, "_blank");
				}
			}}
			{...rest}
		/>
	);
};
