import React from "react";
import { getTypographyStyles } from "../Typography/Typography";
import styled from "styled-components/macro";

export const HeaderTitle = ({
	mainText,
	secondaryText,
	theme = "primary",
}: (
	| { mainText: string | JSX.Element; secondaryText: string | JSX.Element }
	| {
			mainText?: string | JSX.Element;
			secondaryText: string | JSX.Element;
	  }
	| {
			mainText: string | JSX.Element;
			secondaryText?: string | JSX.Element;
	  }
) & {
	theme?: "primary" | "secondary";
}) => {
	return (
		<div
			css={`
				span {
					margin-right: 4px;
					margin-bottom: 10px;
				}
			`}
		>
			<span
				css={`
					${getTypographyStyles(
						"heading2",
						theme === "primary"
							? {
									color: "darkGrey",
									colorType: "dark",
							  }
							: {
									color: "dark",
							  }
					)};
					font-size: 25px;
					line-height: 27px;
				`}
			>
				{mainText}
			</span>

			<span
				css={`
					${getTypographyStyles(
						"bodyNormal",
						theme === "primary"
							? {
									color: "dark",
									fontWeight: 600,
							  }
							: {
									color: "darkGrey",
									colorType: "dark",
									fontWeight: 600,
							  }
					)};
					text-transform: lowercase;
				`}
			>
				{secondaryText}
			</span>
		</div>
	);
};
