import React, { useCallback, useEffect, useState } from "react";
import { FuturesListing } from "../../../../model/optimizedModel/myAccount";
import { getMyAccountFuturesListings } from "../../../../services/myAccount";
import { MyAccountItemsList } from "../MyAccountItemsList";
import { MyAccountFutureListing } from "../Optimized/MyAccountFutureListing";
import { MyAccountFuturesPage } from "./MyAccountFuturesPage";
import toast from "react-hot-toast";
import { useToastRemoval } from "../../../hooks/useToastRemoval";

export const FuturesListings = () => {
	const [futures, setFutures] = useState<FuturesListing[]>();
	const [loading, setLoading] = useState(true);
	const retrieveFuturesListings = useCallback(() => {
		setLoading(true);
		getMyAccountFuturesListings()
			.then(result => {
				setFutures(result.data);
			})
			.catch(error => {
				if (typeof error === "string") {
					toast.error(error);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	}, [getMyAccountFuturesListings, setFutures, setLoading]);

	useEffect(() => {
		retrieveFuturesListings();
	}, []);

	useToastRemoval();

	return (
		<MyAccountFuturesPage mobileTitle="Reservations Listings">
			<MyAccountItemsList<FuturesListing>
				items={futures}
				itemsName="listings"
				loading={loading}
				renderItem={props => {
					return (
						<MyAccountFutureListing
							futureListing={props}
							onCancel={() => {
								retrieveFuturesListings();
							}}
						/>
					);
				}}
			/>
		</MyAccountFuturesPage>
	);
};
