import { Action, AnyAction, Dispatch, MiddlewareAPI } from "redux";
import * as t from "io-ts";
import { StoreContent } from "../store";
import { Either, isRight, left, right } from "fp-ts/lib/Either";
import { ResponseContainerCodec } from "../../services/types/ResponseContainerCodec";
import { fakes } from "../../services/fakes/fakes";
import { HTTPMethod, JSONObject } from "../../services/types/util/BasicTypes";
import { printError } from "../../services/types/util/printError";
import { Config } from "../../Config";
import { detectExpiredSessionFromMessage } from "../../services/detectExpiredSession";

export type ServiceCallActionParams = Record<string, string | number | null>;
export interface ServiceCallAction<T> extends Action {
	method: HTTPMethod;
	endpoint: string;

	body?: JSONObject;
	param?: ServiceCallActionParams;

	serviceKey: string;
	onStart?: () => Action;
	responseDecoder: t.Decoder<any, T>;
	onSuccess?: (result: T) => Action;
	onFailure?: (error: ServiceCallError) => Action;
}

export type ServiceCallError = { type: "FETCH_ERROR"; error: any; statusCode?: number } | { type: "PARSE_ERROR"; error: string; statusCode?: number } | { type: "SERVER_ERROR"; errorCode: number; errorMessage: string; statusCode?: number } | { type: "SERVER_ERRORS"; errorMessages: string[]; statusCode?: number } | { type: "SERVER_UNSUCCESS_REQUEST"; statusCode?: number };

const sleep = async (time: number) => new Promise(resolve => setTimeout(resolve, time));

async function performServiceCall<E, T>(apiHost: string, authToken: string, action: ServiceCallAction<T>): Promise<Either<ServiceCallError, { parsed: T }>> {
	let status = undefined;
	try {
		const requestHeaders = new Headers();
		requestHeaders.set("Content-Type", "application/json");
		if (authToken && !action.endpoint.startsWith("http")) {
			requestHeaders.set("Authorization", authToken);
		}

		let request: RequestInit = {
			headers: requestHeaders,
			method: action.method,
			body: action.body ? JSON.stringify(action.body) : undefined,
		};

		const encodeParameter = (parameter: any) => (typeof parameter === "string" ? encodeURIComponent(parameter) : parameter);

		const fakeService = fakes.get(action.method, action.endpoint);
		const response = await (async () => {
			if (fakeService) {
				await sleep(1000);
				return fakeService(action.param, action.body, action.endpoint);
			} else {
				const params = Object.entries(action.param || {})
					.map(([name, value]) => `${name}=${encodeParameter(value)}`)
					.join("&");

				const base = action.endpoint.startsWith("http") ? `${action.endpoint}` : `${apiHost}/${action.endpoint}`;
				const uri = params ? `${base}?${params}` : `${base}`;

				const rawResponse = await fetch(uri, request);
				status = rawResponse.status;
				const rawData = await rawResponse.json();

				return ResponseContainerCodec.decode(rawData);
			}
		})();

		if (!isRight(response)) {
			return left({
				type: "PARSE_ERROR",
				error: printError(response.left),
			});
		}

		//@ts-ignore
		detectExpiredSessionFromMessage(response.right.message);
		if ("error" in response.right) {
			return left({
				type: "SERVER_ERROR",
				errorCode: response.right.error.code,
				errorMessage: response.right.error.message,
				statusCode: response.right.status,
			});
		} else if ("errors" in response.right) {
			return left({
				type: "SERVER_ERRORS",
				errorMessages: response.right.errors,
				statusCode: response.right.status,
			});
		} else if (response.right.success === false) {
			return left({
				type: "SERVER_UNSUCCESS_REQUEST",
				statusCode: response.right.status,
			});
		}

		const parsed = action.responseDecoder.decode(response.right.data);
		if (!isRight(parsed)) {
			return left({
				type: "PARSE_ERROR",
				error: printError(parsed.left),
				data: response.right.data,
			});
		}

		return right({ parsed: parsed.right });
	} catch (error) {
		return left({
			type: "FETCH_ERROR",
			error,
			statusCode: status
		});
	}
}

export const serviceCallMiddleware =
	(store: MiddlewareAPI<Dispatch, StoreContent>) =>
	(next: Dispatch<AnyAction>) =>
	async <T>(action: ServiceCallAction<T>) => {
		if (action.type !== "SERVICE_CALL") {
			return next(action as any);
		}

		if (action.onStart) {
			store.dispatch(action.onStart());
		}
		store.dispatch({
			type: "SERVICE_CALL_START",
			serviceKey: action.serviceKey,
		});

		const sessionToken = store.getState().persistent.session.sessionToken;

		const result = await performServiceCall(Config.getServiceUrl("/api"), sessionToken ? `Bearer ${sessionToken}` : `Basic ${window.btoa(`projecttix:@SItix123`)}`, action);

		store.dispatch({
			type: "SERVICE_CALL_END",
			serviceKey: action.serviceKey,
		});

		if (isRight(result)) {
			if (action.onSuccess) {
				store.dispatch(action.onSuccess(result.right.parsed));
			}
			return right(result.right.parsed);
		} else {
			if (process.env.NODE_ENV !== "production") {
				console.log(result.left);
			}
			if (action.onFailure) {
				store.dispatch(action.onFailure(result.left));
			}
			return result;
		}
	};

// Overload to add type info to dispatch calls
declare module "redux" {
	export interface Dispatch<A extends Action> {
		<T>(action: ServiceCallAction<T>): Promise<Either<ServiceCallError, T>>;
	}
}
