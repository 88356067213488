import React, { Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components/macro";
import { SmallButton } from "../Button/Button";
import { MyAccountCard } from "./MyAccountCard";
import { MyAccountPage } from "./MyAccountPage";
import { store } from "../../../store/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import parsePhoneNumber, { parsePhoneNumberFromString } from "libphonenumber-js/max";
import { ModalPopup } from "../ModalPopup/ModalPopup";
import { ModalCloseButton } from "../Modal/ModalCloseButton";
import { ModalPosition } from "../Modal";
import { Spinner } from "../Loader/Spinner";
import { putMyAccountMe, putMyAccountPrimaryShippingAddress } from "../../../services/myAccount";
import { NewAddressModal } from "../NewAddressModal/NewAddressModal";
import { useUser } from "../../../services/hooks/useUser";
import { renderAddress } from "../../util/address";
import { CollapseArrow } from "../CollapseArrow/CollapseArrow";
import { Expandable } from "../Expandable/Expandable";
import { UserAddress } from "../../../model/User";
import { AddPaymentMethod } from "../AddPaymentMethod/AddPaymentMethod";
import { ButtonWithSpinner } from "../Button/ButtonWithSpinner";
import { useDispatch, useSelector } from "react-redux";
import { setPrimaryShippingAddressId, updateUser, updateUserPhoneNumber, removeUserAddress, updatePromotionalWallet } from "../../../store/reducers/userReducer";
import { useBraintree } from "../../../services/hooks/useBraintree";
import { useMemoState } from "../../hooks/useMemoState";
import { DeleteIconButton } from "../DeleteIconButton/DeleteIconButton";
import { PaymentMethodDisplay } from "./Optimized/PaymentMethodDisplay";
import { PaymentMethodWithToken } from "../../../model/optimizedModel/braintree";
import { deleteBraintreePaymentMethod, renderPaymentMethod, getAndDispatchBraintreeClientToken } from "../../../services/braintree";
import { removeBraintreePaymentMethod } from "../../../store/actions/braintreeActions";
import toast from "react-hot-toast";
import { useToastRemoval } from "../../hooks/useToastRemoval";
import { Field, Form } from "react-final-form";
import { RFFInput } from "../Input/Input";
import { required } from "../../util/optimized/finalFormUtil";
import { deleteUserAddress, putUserPhone } from "../../../services/user";
import { renderPhoneNumber } from "../../util/renderPhoneNumber";
import { formatPrice } from "../../util/formatPrice";
import { getWalletInfo } from "../../../services/user";
import {Config} from "../../../Config";

const border = "1px solid #707372";

const bottomPaymentMethodMargin = "14px";

const UserInfoItemElement = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;

	p {
		display: flex;
		flex-wrap: wrap;
	}

	div {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: min(80vw, 300px);
	}

	&:not(:last-of-type) {
		margin-bottom: 10px;
	}
`;

const EditButton = styled.button`
	padding: 0 !important;
	background: none;
	outline: none;
	border: none;
	cursor: pointer;
	margin-left: 7px;
`;

const getUser = () => store.getState().persistent.user;

interface UserInfoItemModalProps {
	modalOpen?: boolean;
	setModalOpen?: Dispatch<SetStateAction<boolean>>;
	onClose?: () => void;
}

const UserInfoModalPopup = styled(ModalPopup)`
	max-width: 85vw;
	min-width: 335px;
`;

const UserInfoItem = (
	props: React.PropsWithChildren<{
		name: string;
		modalContent: ReactElement;
		modalHeader: string;
		isEditable?: boolean | true;
	}>
) => {
	const [modalOpen, setModalOpen] = useState(false);

	return (
		<>
			{modalOpen && (
				<UserInfoModalPopup onDidClose={() => setModalOpen(false)} rightHeaderElements={(onClose: any) => <ModalCloseButton onClick={onClose} />} centerHeaderElements={props.modalHeader} position={ModalPosition.Center}>
					{onClose => {
						return React.cloneElement(props.modalContent, {
							modalOpen,
							setModalOpen,
							onClose,
						});
					}}
				</UserInfoModalPopup>
			)}
			{props.children && (
				<UserInfoItemElement>
					<p>
						<strong>{props.name}:&nbsp;</strong>
						<div>{props.children}</div>
					</p>
					{props.isEditable && (
						<EditButton
							onClick={() => {
								setModalOpen(true);
							}}
						>
							<FontAwesomeIcon icon={faPen} />
						</EditButton>
					)}
				</UserInfoItemElement>
			)}
		</>
	);
};

const ChangeNameContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	& > div:first-child {
		display: flex;
		margin: 15px 0;
		& > input {
			margin: 0 !important;
			&:first-child {
				margin-right: 14px !important;
			}
		}
	}
`;

const ChangeName = (props: UserInfoItemModalProps) => {
	const user = getUser();
	const dispatch = useDispatch();

	const [formState, setFormState] = useState({
		firstName: user?.fname,
		lastName: user?.lname,
	});

	const [loading, setLoading] = useState(false);

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const target = event.target;
		setFormState({
			...formState,
			[target.name]: target.value,
		});
	};

	const save = () => {
		if (formState.firstName && formState.lastName) {
			setLoading(true);
			putMyAccountMe(formState.firstName, formState.lastName).then(result => {
				dispatch(
					updateUser({
						...user,
						...result.data,
					})
				);
				setLoading(false);
				if (props.setModalOpen) {
					props.setModalOpen(false);
				}
			});
		}
	};

	const initialFormStateValue = useMemo(() => JSON.stringify(formState), []);

	const [saveDisabled, setSaveDisabled] = useState<boolean>();

	useEffect(() => {
		setSaveDisabled(initialFormStateValue === JSON.stringify(formState));
	}, [formState]);

	return (
		<ChangeNameContainer>
			<div>
				<input name="firstName" value={formState.firstName} placeholder="First Name" onChange={handleInputChange} />
				<input name="lastName" value={formState.lastName} placeholder="Last Name" onChange={handleInputChange} />
			</div>
			<ButtonWithSpinner onClick={save} loading={loading} disabled={saveDisabled}>
				Save
			</ButtonWithSpinner>
		</ChangeNameContainer>
	);
};

const ChangeEmail = (props: UserInfoItemModalProps) => {
	return <p>ChangeEmail</p>;
};

const ChangePhone = (props: UserInfoItemModalProps) => {
	const user = useUser();
	const dispatch = useDispatch();

	return (
		<Form
			onSubmit={async values => {
				const parsedPhoneNumber = parsePhoneNumber(values.phoneNumber, "US");
				if (parsedPhoneNumber) {
					const phoneNumber = parsedPhoneNumber.country === "US" ? parsedPhoneNumber.number.substring(2) : parsedPhoneNumber.number;
					await putUserPhone(phoneNumber)
						.then(() => {
							const parsedPhoneNumber = parsePhoneNumber(values.phoneNumber, "US");
							if (parsedPhoneNumber) {
								toast.success('Updated phone number to "' + parsedPhoneNumber.formatNational() + '".');
								dispatch(updateUserPhoneNumber(phoneNumber));
								if (props.onClose) {
									props.onClose();
								}
							}
						})
						.catch(error => {
							console.error(error?.data);
						});
				} else {
					toast.error("Error: Invalid phone number provided.");
				}
			}}
			render={form => {
				return (
					<ChangePhoneForm onSubmit={form.handleSubmit}>
						<Field<string>
							name="phoneNumber"
							placeholder="Phone Number"
							component={RFFInput}
							initialValue={user?.phone}
							validate={(value = "") => {
								const phoneNumber = parsePhoneNumberFromString(value, "US");
								return required(value) || (!phoneNumber?.isValid() && "Please enter a valid phone number.");
							}}
							type="tel"
							autoComplete="tel"
						/>
						<ButtonWithSpinner type="submit" loading={form.submitting} disabled={form.invalid}>
							Submit
						</ButtonWithSpinner>
					</ChangePhoneForm>
				);
			}}
		/>
	);
};

const ChangePhoneForm = styled.form`
	margin-top: 14px;
	display: flex;
	flex-direction: column;
`;

const commonCardLayoutButtonMarginValue = 25;

const commonCardLayoutButtonMargin = commonCardLayoutButtonMarginValue + "px";

const ViewMoreButton = (props: { onClick: () => void; expanded?: boolean }) => {
	return <CollapseArrow onClick={props.onClick} size={9} weight={2} expanded={props.expanded} />;
};

const Loading = (
	props: React.PropsWithChildren<{
		noItemsMessage?: string;
		noItems?: boolean;
		loading?: boolean;
	}>
) => {
	return <>{props.loading ? <Spinner /> : <>{props.noItems && props.noItemsMessage ? <span>{props.noItemsMessage}</span> : <>{props.children}</>}</>}</>;
};

//TODO: Consider making the delete logic in this component and in PaymentMethodsCard more DRY.
const AddressCard = () => {
	const [modalOpen, setModalOpen] = useState(false);
	const user = useUser();
	const [expanded, setExpanded] = useState(false);
	const dispatch = useDispatch();
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [targetedShippingAddressToDelete, setTargetedShippingAddressToDelete] = useState<number>();

	const userAddresses = useMemo(() => {
		const array = user?.addresses.slice().reverse();
		const primaryAddressIndex = array?.findIndex(item => item.id === user?.primaryShippingAddressID);
		if (primaryAddressIndex) {
			const splicedPrimaryAddress = array?.splice(primaryAddressIndex, 1)[0];
			if (splicedPrimaryAddress) {
				array?.unshift(splicedPrimaryAddress);
			}
		}
		return array;
	}, [user?.addresses.length]);

	const initiallyVisibleAddressesAmount = 2;

	const [initiallyVisibleAddresses, setInitiallyVisibleAddresses] = useState<UserAddress[]>();
	const [hiddenAddresses, setHiddenAddresses] = useState<UserAddress[]>();

	useEffect(() => {
		setInitiallyVisibleAddresses(userAddresses?.slice(0, initiallyVisibleAddressesAmount));
		setHiddenAddresses(userAddresses?.slice(initiallyVisibleAddressesAmount, userAddresses.length));
	}, [userAddresses]);

	const renderExpandableComponents = useMemoState(() => {
		return !!hiddenAddresses?.length;
	}, [hiddenAddresses]);

	const displayAddresses = useCallback(
		(addresses: UserAddress[] | undefined) => {
			return addresses?.map((address, index) => {
				return (
					<AddressContainer key={index}>
						<p>{renderAddress(address)}</p>
						<input
							checked={user?.primaryShippingAddressID === address.id}
							type="radio"
							onChange={() => {
								dispatch(setPrimaryShippingAddressId(address.id));
								putMyAccountPrimaryShippingAddress(address.id);
							}}
						/>
						<StyledDeleteIconButton
							onClick={() => {
								//TODO: Consider looking into a method without using findIndex or any other slow method.
								const index = user?.addresses?.findIndex(item => item.id === address.id);
								setTargetedShippingAddressToDelete(index);
								setDeleteModalOpen(true);
							}}
						/>
					</AddressContainer>
				);
			});
		},
		[user, renderAddress]
	);

	const [loading, setLoading] = useState(!user);

	useEffect(() => {
		setLoading(!user);
	}, [user]);

	const userHasAddresses = useMemoState(() => {
		const value = !loading && !!userAddresses?.length;
		return value;
	}, [loading, userAddresses?.length]);

	return (
		<>
			{modalOpen && (
				<NewAddressModal
					addressAdded={() => {
						setModalOpen(false);
					}}
					onDidClose={() => {
						setModalOpen(false);
					}}
				/>
			)}
			{deleteModalOpen && (
				<ModalPopup onDidClose={() => setDeleteModalOpen(false)} rightHeaderElements={(onClose: any) => <ModalCloseButton onClick={onClose} />} position={ModalPosition.Center}>
					{onClose => {
						if (user?.addresses && targetedShippingAddressToDelete !== undefined) {
							const address = user.addresses[targetedShippingAddressToDelete];
							const itemName = renderAddress(address) as string;
							return (
								<ConfirmDelete
									itemName={itemName}
									onClose={onClose}
									onConfirm={async () => {
										return deleteUserAddress(address.id).then(() => {
											return dispatch(removeUserAddress(targetedShippingAddressToDelete));
										});
									}}
								/>
							);
						}
					}}
				</ModalPopup>
			)}
			<MyAccountCard className="addresses">
				<Addresses
					style={
						!userAddresses?.length
							? {
									marginTop: "initial",
							  }
							: {}
					}
				>
					<AddressesHeader
						style={
							userHasAddresses
								? {
										borderBottom: border,
								  }
								: {}
						}
					>
						<h1>Shipping Addresses</h1>
						{userHasAddresses && <p>Primary</p>}
					</AddressesHeader>
					<Loading loading={loading} noItems={!userAddresses?.length} noItemsMessage="No addresses added.">
						<div>{displayAddresses(initiallyVisibleAddresses)}</div>
						{renderExpandableComponents && <Expandable expanded={expanded}>{displayAddresses(hiddenAddresses)}</Expandable>}
					</Loading>
					{!loading && (
						<ButtonContainer>
							<SmallButton
								onClick={() => {
									setModalOpen(true);
								}}
							>
								Add New Address
							</SmallButton>
							{renderExpandableComponents && (
								<ViewMoreButton
									onClick={() => {
										setExpanded(!expanded);
									}}
									expanded={expanded}
								/>
							)}
						</ButtonContainer>
					)}
				</Addresses>
			</MyAccountCard>
		</>
	);
};

const deleteIconButtonWidthValue = 17;

const primarySelectionColumnLeftMarginValue = 20;

const addressContainerSpacing = "11px";

const AddressesHeader = styled.div`
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: ${addressContainerSpacing};
	h1 {
		margin-right: 44px;
	}
	p {
		margin-right: ${primarySelectionColumnLeftMarginValue + deleteIconButtonWidthValue}px;
	}
`;

const StyledDeleteIconButton = styled(DeleteIconButton)`
	width: ${deleteIconButtonWidthValue}px;
	min-width: ${deleteIconButtonWidthValue}px;
`;

//TODO: Consider finding a better way to match the "Primary" title instead of adding 23.5
const addressContainerInputVerticalSpacingValue = 23.5;

const AddressContainer = styled.div`
	&:first-child {
		padding-top: ${addressContainerSpacing};
	}
	border-bottom: ${border};
	display: flex;
	align-items: center;
	padding-bottom: ${addressContainerSpacing};
	&:not(:last-child) {
		margin-bottom: ${addressContainerSpacing};
	}
	p {
		max-width: 250px;
		margin-right: auto;
	}
	input {
		transform: initial;
		margin-left: ${addressContainerInputVerticalSpacingValue}px;
		margin-right: ${primarySelectionColumnLeftMarginValue + addressContainerInputVerticalSpacingValue}px;
	}
`;

const AddPaymentMethodContainer = styled.div`
	margin-top: 15px;
`;

const ConfirmDelete = (props: { onClose: () => void; itemName: string; onConfirm: () => Promise<any> }) => {
	const [loading, setLoading] = useState<boolean>();

	const [itemName, setItemName] = useState<string>();
	//TODO: Consider finding a better way to keep the itemName the same instead of using this method
	useEffect(() => {
		if (!itemName) {
			setItemName(props.itemName);
		}
	}, [props.itemName]);

	return (
		<ConfirmDeleteContent>
			<p>Are you sure you want to remove "{itemName}"?</p>
			<div>
				<ButtonWithSpinner
					loading={loading}
					onClick={() => {
						setLoading(true);
						props
							.onConfirm()
							.then(() => {
								//TODO: Add proper message
								toast.success('Removed "' + itemName + '"');
								props.onClose();
							})
							.catch(error => {
								toast.error(error.error);
							})
							.finally(() => {
								setLoading(false);
							});
					}}
				>
					Confirm
				</ButtonWithSpinner>
				<SmallButton
					onClick={() => {
						props.onClose();
					}}
				>
					Cancel
				</SmallButton>
			</div>
		</ConfirmDeleteContent>
	);
};

const ConfirmDeleteContent = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 11px;
	max-width: 85vw;
	p {
		margin-bottom: 17px;
		font-size: 15px;
		font-family: "Montserrat";
		font-weight: 500;
		color: black;
		max-width: 399px;
		text-align: left;
		line-height: normal;
	}
	div {
		display: flex;
		align-items: center;
		& > *:not(:last-child) {
			margin-right: 13px;
		}
	}
`;

const PaymentMethodsCard = () => {
	const [expanded, setExpanded] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [targetedPaymentMethodToDelete, setTargetedPaymentMethodToDelete] = useState<number>();
	const { braintreeState, braintreeLoading } = useBraintree();
	const dispatch = useDispatch();

	const displayPaymentMethods = useCallback((paymentMethods: PaymentMethodWithToken[] | undefined, indexOffset?: number) => {
		return paymentMethods?.map((paymentMethod, index) => {
			return (
				<PaymentMethod key={index}>
					<PaymentMethodDisplay paymentMethod={paymentMethod} />
					<StyledDeleteIconButton
						onClick={() => {
							//TODO: Consider finding a better way to fix the index issue instead of using indexOffset.
							setTargetedPaymentMethodToDelete(index + (indexOffset || 0));
							setDeleteModalOpen(true);
						}}
					/>
				</PaymentMethod>
			);
		});
	}, []);

	const initiallyVisiblePaymentMethodsAmount = 3;

	const initiallyVisiblePaymentMethods = useMemoState(() => {
		const value = braintreeState.paymentMethods?.slice(0, initiallyVisiblePaymentMethodsAmount);
		return value;
	}, [braintreeState.paymentMethods?.length]);

	const hiddenPaymentMethods = useMemoState(() => {
		const value = braintreeState.paymentMethods?.slice(initiallyVisiblePaymentMethodsAmount);
		return value;
	}, [braintreeState.paymentMethods?.length]);

	const expandable = useMemoState(() => {
		const length = braintreeState.paymentMethods?.length;
		const value = !!length && length > initiallyVisiblePaymentMethodsAmount;
		return value;
	}, [braintreeState.paymentMethods?.length]);

	return (
		<MyAccountCard header="Payment Methods">
			{modalOpen && (
				<ModalPopup onDidClose={() => setModalOpen(false)} rightHeaderElements={(onClose: any) => <ModalCloseButton onClick={onClose} />} centerHeaderElements="Payment Method" position={ModalPosition.Center}>
					<AddPaymentMethodContainer>
						<AddPaymentMethod
							hiddenPaymentMethodTypes={Config.getDisabledPaymentMethods()}
							paymentMethodAdded={() => {
								setModalOpen(false);
							}}
						/>
					</AddPaymentMethodContainer>
				</ModalPopup>
			)}
			{deleteModalOpen && (
				<ModalPopup onDidClose={() => setDeleteModalOpen(false)} rightHeaderElements={(onClose: any) => <ModalCloseButton onClick={onClose} />} position={ModalPosition.Center}>
					{onClose => {
						if (braintreeState.paymentMethods && targetedPaymentMethodToDelete !== undefined) {
							const paymentMethod = braintreeState.paymentMethods[targetedPaymentMethodToDelete];
							const itemName = renderPaymentMethod(paymentMethod) as string;
							return (
								<ConfirmDelete
									itemName={itemName}
									onClose={onClose}
									onConfirm={async () => {
										return deleteBraintreePaymentMethod(paymentMethod.token).then(() => {
											getAndDispatchBraintreeClientToken();
											return dispatch(removeBraintreePaymentMethod(targetedPaymentMethodToDelete));
										});
									}}
								/>
							);
						}
					}}
				</ModalPopup>
			)}
			<PaymentMethodsContainer>
				<Loading noItemsMessage="No payment methods added." noItems={braintreeState.paymentMethods?.length === 0} loading={braintreeLoading}>
					{/*TODO: Consider making this expandable logic + the expandable logic for Shipping Addresses more DRY.*/}
					<PaymentMethods>{displayPaymentMethods(initiallyVisiblePaymentMethods)}</PaymentMethods>
					{expandable && (
						<Expandable expanded={expanded}>
							<PaymentMethods
								style={{
									marginTop: bottomPaymentMethodMargin,
								}}
							>
								{displayPaymentMethods(hiddenPaymentMethods, initiallyVisiblePaymentMethodsAmount)}
							</PaymentMethods>
						</Expandable>
					)}
				</Loading>
				{!braintreeLoading && (
					<ButtonContainer>
						<SmallButton
							onClick={() => {
								setModalOpen(true);
							}}
						>
							Add Payment Method
						</SmallButton>
						{expandable && (
							<ViewMoreButton
								expanded={expanded}
								onClick={() => {
									setExpanded(!expanded);
								}}
							/>
						)}
					</ButtonContainer>
				)}
			</PaymentMethodsContainer>
		</MyAccountCard>
	);
};

export const Profile = () => {
	const user = getUser();
	const fullName = user?.fname + " " + user?.lname;
	const dispatch = useDispatch();
	const walletFunds = useSelector(state => state.persistent.user?.promotionalWalletCredit);

	const phoneNumber = useMemoState(() => {
		if (user?.phone) {
			const value = renderPhoneNumber(user?.phone);
			return value;
		}
	}, [user?.phone]);

	const userEmail = user?.email;

	useEffect(() => {
		getWalletInfo().then(result => {
			dispatch(updatePromotionalWallet(result.data.wallet.promotionalWallet));
		});
	}, []);

	useToastRemoval();

	return (
		<ProfileWrapper>
			<MyAccountPage
				mobileTitle={"Profile"}
				columns={[
					<>
						<MyAccountCard header="User Information">
							<Loading loading={!user}>
								<UserInfo>
									<UserInfoItem name="Email" modalHeader="Change User Email" modalContent={<ChangeEmail />} isEditable={false}>
										{userEmail}
									</UserInfoItem>
									<UserInfoItem name="Name" modalHeader="Name" modalContent={<ChangeName />} isEditable={true}>
										{fullName}
									</UserInfoItem>
									<UserInfoItem name="Phone Number" modalHeader="Phone Number" modalContent={<ChangePhone />} isEditable={true}>
										{phoneNumber}
									</UserInfoItem>
								</UserInfo>
							</Loading>
						</MyAccountCard>
						<AddressCard />
					</>,
					<>
						<PaymentMethodsCard />
						<MyAccountCard header="Balances">
							<ItemRow>
								<p>
									<strong>Promotional Wallet</strong>:&nbsp;
									{formatPrice(walletFunds)}
								</p>
							</ItemRow>
						</MyAccountCard>
					</>,
				]}
			/>
		</ProfileWrapper>
	);
};

const ProfileWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const UserInfo = styled.div`
	display: flex;
	flex-direction: column;
`;

const PaymentMethod = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const CommonCardLayout = css`
	width: 100%;
	display: flex;
	flex-direction: column;
	button {
		margin-left: auto;
		margin-top: ${commonCardLayoutButtonMargin};
	}
`;

const Addresses = styled.div`
	${CommonCardLayout}
`;

const PaymentMethodsContainer = styled.div`
	${CommonCardLayout}
`;

const PaymentMethods = styled.div`
	& > *:not(:last-child) {
		margin-bottom: ${bottomPaymentMethodMargin};
	}
`;

const ButtonContainer = styled.div`
	margin-top: ${commonCardLayoutButtonMargin};
	display: flex;
	justify-content: space-between;
	button {
		margin: 0;
		display: flex;
		align-items: center;
		&:not(:last-of-type) {
			margin-right: 10px;
		}
		& > div {
			margin-left: 5px;
		}
		span {
			background-color: #ffffff;
		}
	}
`;

const ItemRow = styled.div`
	display: flex;
`;
