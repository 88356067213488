import React from "react";
import "./presentation/resource/css/sitix.css";
import "./presentation/resource/css/pretty-checkbox.min.css";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./presentation/Routes";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { SessionTracking } from "./presentation/util/SessionTracking";
import { Navbar } from "./presentation/components/Navbar/Navbar";
import { isMobileApp } from "./presentation/util/isMobileApp";

function App() {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<BrowserRouter>
					{!isMobileApp && <Navbar />}
					<SessionTracking />
					<Routes />
				</BrowserRouter>
			</PersistGate>
		</Provider>
	);
}

export default App;
