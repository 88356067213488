import styled from "styled-components";

export const OfferModal = styled.div`
	img {
		height: auto;
		width: 95%;
		margin: auto;
		display: table;
	}
`;

export const ModalContainer = styled.div`
	display: block;
	width: 95%;
	position: absolute;
	z-index: 20;
	top: 77px;
	right: 0;
	left: 0;
	margin: 0 auto;

	background: transparent;
	border: none;
	max-width: 500px;
`;

export const ModalClose = styled.div`
	background: #ff004e;
	color: #ffffff;
	padding: 7px 11px;
	font-size: 17px;
	font-weight: bold;
	position: absolute;
	right: 10px;
	top: 10px;

	&:hover {
		cursor: pointer;
		opacity: 0.9;
	}
`;
