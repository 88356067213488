import React from "react";
import styled from "styled-components/macro";
import { Config } from "../../../Config";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";
import { mediaQueries } from "../../util/mediaQueries";
import { FreeStarAd } from "../FreeStarAd";

export interface AdProps {
	url?: string | null;
	link?: string | null;
}

export interface VerticalAdProps extends AdProps {
	sticky?: boolean;
}

export const FreeStarVerticalAd = (props: { placement: string; slotId?: string }) => {
	return (
		<VerticalAdSticky sticky clickable>
			<FreeStarAd {...props} />
		</VerticalAdSticky>
	);
};

export const VerticalAd = (props: VerticalAdProps) => (
	<VerticalAdSticky sticky={true} clickable={!!props.link}>
		<a href={props.link || undefined} target={"_blank"} rel="noopener noreferrer">
			<VerticalAdImage src={props.url || undefined} />
		</a>
	</VerticalAdSticky>
);

export const HorizontalAd = (props: AdProps & StyledComponentProps) =>
	props.link ? (
		<HorizontalAdImageLink href={props.link} target={"_blank"} rel="noopener noreferrer" className={props.className}>
			<HorizontalAdImage src={props.url || undefined} />
		</HorizontalAdImageLink>
	) : (
		<HorizontalAdImage src={props.url || undefined} />
	);

export const VerticalAdFrame = styled.div`
	position: sticky;
	top: calc(120px + 0.83rem);
	height: auto;
	display: flex;
	flex-direction: column;
`;

export const VerticalAdContainer = styled.div`
	display: block;
	flex: 0.2;
	min-width: 140px;
	position: relative;
	margin-left: 29px;
	@media ${mediaQueries.max750} {
		display: none;
	}
	margin-top: 10px;
`;

const VerticalAdSticky = styled.div<{ sticky?: boolean; clickable?: boolean }>`
	${props => (props.sticky ? "position: sticky;" : "")}
	${props =>
		props.clickable
			? `
    & img:hover {
      box-shadow: 5px 5px 7px 1px #ccc;
      cursor: pointer;
    }
	margin-bottom: 10px;	
  `
			: ""}
`;

const VerticalAdImage = styled.img.attrs(props => ({
	src: props.src ? Config.getResourceUrl(props.src) : undefined,
}))`
	width: 100%;
	height: auto;

	margin-bottom: 0.83rem;
	/* box-shadow: 2px 2px 7px 1px #ccc; */

	display: none;
	&[src] {
		display: block;
	}
`;

const HorizontalAdImageLink = styled.a`
	& > *:hover {
		box-shadow: 5px 5px 7px 1px #ccc;
		cursor: pointer;
	}
`;

const HorizontalAdImage = styled.img.attrs(props => ({
	src: props.src ? Config.getResourceUrl(props.src) : undefined,
}))`
	width: 100%;
	max-width: 100%;
	height: auto;
	margin-top: 16px;
	margin-bottom: 14px;

	display: none;
	&[src] {
		display: block;
	}
`;

export const StickyFooterContainer = styled.div`
	max-height: 400px;
	bottom: 20px;
	position: -webkit-sticky; // Required by Safari
	position: sticky;
	z-index: 2;
	display: flex;
	justify-content: center;
`;
