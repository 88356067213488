import { isRight } from "fp-ts/lib/These";
import * as t from "io-ts";
import jsonp from "jsonp";
import QueryString from "query-string";

export interface JsonpServiceCall<T> {
	endpoint: string;
	param?: Record<string, string | number | null>;
	responseDecoder: t.Decoder<any, T>;
}

export async function getJsonp<T>(call: JsonpServiceCall<T>): Promise<T> {
	const params = QueryString.stringify(call.param || {});

	return new Promise((resolve, reject) => {
		jsonp(`${call.endpoint}?${params}`, {}, (err, data) => {
			if (err) {
				reject(err);
			}
			const parsed = call.responseDecoder.decode(data);

			if (isRight(parsed)) {
				resolve(parsed.right);
			} else {
				reject(parsed.left);
			}
		});
	});
}
