import React, { useMemo } from "react";
import styled, { css } from "styled-components/macro";
import { TitleAndContentProps, TitleAndContent } from "../TitleAndContent/TitleAndContent";
import { FlexGap, flexGap } from "../../util/optimized/styles";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";

export interface TitleAndContentGridItem extends TitleAndContentProps {
	key: string;
}

interface ContentProps {
	maxChildrenWidth?: string | undefined;
	//TODO: Consider making both properties not optional or change the defaults
	flexGap?: FlexGap;
}

export interface TitleAndContentGridConfigProps extends ContentProps {
	omit?: string[];
	override?: Record<string, Partial<TitleAndContentProps>>;
}

//TODO: Consider adding error handling/a custom error messsage if a key is used more then once in the items array. Right now React will just say that you shouldn't use the same key for the itemects in the DOM.
export const TitleAndContentGrid = ({
	items,
	className,
	omit,
	override,
	...contentProps
}: {
	items: TitleAndContentGridItem[];
} & TitleAndContentGridConfigProps &
	StyledComponentProps) => {
	//TODO: See if useMemoState should be used here instead.
	//TODO: Consider using useMemoStateSet from the Invoice component.
	const omitSet = useMemo(() => {
		const value = new Set(omit);
		return value;
	}, [omit]);
	return (
		<div className={className}>
			<Content {...contentProps}>
				{items.map(item => {
					if (!omitSet.has(item.key)) {
						const overrideData = override && override[item.key];
						let props = item;
						if (overrideData) {
							props = {
								...item,
								...overrideData,
							};
						}
						return <TitleAndContent {...props} />;
					}
					return null;
				})}
			</Content>
		</div>
	);
};

const Content = styled.div<ContentProps>`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	${props => flexGap(props.flexGap || ["9px", "5px"])}
	& > * {
		max-width: ${props => props.maxChildrenWidth || "max-content"};
		${props =>
			props.maxChildrenWidth &&
			css`
				width: ${props.maxChildrenWidth};
			`}
		flex-grow: 1;
	}
`;
