import React, { useState } from "react";
import { ModalPopup } from "../ModalPopup/ModalPopup";
import { AddressForm, AddressFormState } from "../AddressForm/AddressForm";
import { ModalCloseButton } from "../Modal/ModalCloseButton";
import { ButtonWithSpinner } from "../Button/ButtonWithSpinner";
import styled from "styled-components/macro";
import { postMyAccountAddress } from "../../../services/myAccount";
import { useDispatch } from "react-redux";
import { addUserAddress } from "../../../store/reducers/userReducer";
import { useHiddenZendeskChatWidgetBreakpoint } from "../../hooks/useHiddenZendeskChatWidgetBreakpoint";
import { popupMaxWidth } from "../../util/mediaQueries";
import { useUser } from "../../../services/hooks/useUser";
import { addGuestAddress } from "../../../store/reducers/guestReducer";

export const NewAddressModal = (props: { onDidClose?: () => void; addressAdded?: () => void }) => {
	const dispatch = useDispatch();

	const [addressFormState, setAddressFormState] = useState<AddressFormState | undefined>();

	const [loading, setLoading] = useState(false);

	const user = useUser();

	useHiddenZendeskChatWidgetBreakpoint(popupMaxWidth + "px");

	return (
		<ModalPopup onDidClose={props.onDidClose} rightHeaderElements={(onClose: any) => <ModalCloseButton onClick={onClose} />} centerHeaderElements="New Address">
			<Container>
				<AddressForm
					onChange={state => {
						setAddressFormState(state);
					}}
				/>
				<ButtonWithSpinner
					disabled={!addressFormState?.valid}
					loading={loading}
					onClick={() => {
						const data = addressFormState?.data;
						if (data) {
							setLoading(true);
							postMyAccountAddress(data).then(result => {
								const address = result.data;
								user ? dispatch(addUserAddress(address)) : dispatch(addGuestAddress(address));
								setLoading(false);
								if (props.addressAdded) {
									props.addressAdded();
								}
							});
						}
					}}
				>
					Add Address
				</ButtonWithSpinner>
			</Container>
		</ModalPopup>
	);
};

const Container = styled.div`
	padding-top: 15px;
	& > div:last-of-type {
		margin-left: auto;
		max-width: fit-content;
		button {
			margin-top: 0;
		}
	}
`;
