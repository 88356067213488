import React from "react";
import styled from "styled-components/macro";
import { useIsMobileWeb } from "../../hooks/useIsMobileWeb";
import { isMobileApp } from "../../util/isMobileApp";
import arrowMidGrayReverse from "../../resource/image/arrow-mid-gray-reverse.svg";

interface MobileHeaderProps {
	title: string;
	image?: string;
	borderless?: boolean;
	onBackClick: () => void;
}

export const MobileHeader = (props: MobileHeaderProps) => {
	const isMobileWeb = useIsMobileWeb();
	const isMobile = isMobileWeb || isMobileApp;

	if (isMobile) {
		return (
			<MobileHeaderContainer borderless={props.borderless}>
				<BackLink onClick={props.onBackClick} src={arrowMidGrayReverse} alt="Back" />
				{props.image ? <HeaderImage src={props.image} width={150} alt="{props.title}" /> : <span>{props.title}</span>}
			</MobileHeaderContainer>
		);
	}
	return null;
};

const MobileHeaderHeight = "54px";
const MobileBackButtonPadding = "20px";

const MobileHeaderContainer = styled.div<{ borderless?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	height: ${MobileHeaderHeight};
	position: relative;
	border-bottom: ${props => (props.borderless ? "none" : "1px solid #E5E5E5")};
	background: white;
	span {
		font-family: Montserrat;
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: 22px;
		color: #12284c;
	}
`;

const BackLink = styled.img`
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	width: 10px;
	height: ${MobileHeaderHeight};
	padding-left: ${MobileBackButtonPadding};
	padding-right: ${MobileBackButtonPadding};
`;

const HeaderImage = styled.img`
	height: 60px;
`;
