import React, { useEffect, useState } from "react";

type ContainerProps = { children?: React.ReactElement | React.ReactElement[] };
type LayoutProps<T> = React.PropsWithChildren<{ breakpoint: keyof T | "default" }>;

export const MOBILE_BREAKPOINT_PX = 768;

/**
 * Helper to define which elements should be rendered at different screen sizes.
 * @param breakpoints  points where the rendered layout responds according to the screen width
 */
export const createResponsiveLayout = <T extends { [bp: string]: number }>(breakpoints: T) => ({
	/**
	 *  The container wraps specific layouts and defines an active one.
	 */
	Container: (props: ContainerProps) => {
		let activeLayout: React.ReactElement<LayoutProps<T>> | undefined;
		let defaultLayout: React.ReactElement<LayoutProps<T>> | undefined;

		const width = useClientWidth();

		if (props.children) {
			React.Children.forEach(props.children, (child: React.ReactElement<LayoutProps<T>>) => {
				if (child.props.breakpoint === "default") {
					defaultLayout = child;
				} else if (width < breakpoints[child.props.breakpoint]) {
					activeLayout = child;
				}
			});
		}

		if (!props.children || !defaultLayout) {
			throw new Error("Default layout must be provided");
		}

		return activeLayout || defaultLayout;
	},
	Layout: ({ children }: LayoutProps<T>): any => children,
});

// hook that listen the current width of the browser window
// we don't need resizeObserver here
const useClientWidth = () => {
	const getClientWidht = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	const [width, setWidth] = useState(getClientWidht());

	useEffect(() => {
		const resize = () => setWidth(getClientWidht());
		window.addEventListener("resize", resize);
		return () => {
			window.removeEventListener("resize", resize);
		};
	}, []);
	return width;
};
