import { useEffect } from "react";
import { useMediaQuery } from "./useMediaQuery";
import { useShowZendeskChatWidget } from "./useShowZendeskChatWidget";

export const useHiddenZendeskChatWidgetBreakpoint = (breakpoint: string, disableWidgetHiding?: boolean) => {
	const { showZendeskChatWidget, setShowZendeskChatWidget } = useShowZendeskChatWidget();

	const breakpointActive = useMediaQuery(`(max-width: ${breakpoint})`);

	useEffect(() => {
		if (!disableWidgetHiding && breakpointActive) {
			if (showZendeskChatWidget) {
				setShowZendeskChatWidget(false);
			}
		} else if (!showZendeskChatWidget) {
			setShowZendeskChatWidget(true);
		}
	}, [disableWidgetHiding, breakpointActive]);
};
