import React from "react";
import styled from "styled-components/macro";
import { RouteComponentProps } from "react-router-dom";
import { CommonPage, Events } from "../components";
import { NavigationPaging } from "../components/Pagination";
import { parseSearch } from "../util/parseSearch";
import { InternalRoutes } from "../Link";
import { mediaQueries } from "../util/mediaQueries";
import { useDataService } from "../hooks/useServiceCall";
import { Search } from "../components/Search/Search";
import { maxContentWidthStyles, maxContentWidthValue } from "../util/maxContentWidth";
import { searchEvents } from "../../services/actions/eventsService";
import { useServiceMonitor } from "../hooks";
import selectArrowGray from "../resource/img/selectArrowGray.svg";
import { MetaDecorator, MetaTagData } from "../components/SEOMetadecorator/MetaDecorator";
import { FontFamily } from "../components/Typography/Typography";
import { FullScreenLoader } from "../components/Loader";

interface SearchParams {
	page: number | null;
	query: string | null;
}

export const SearchResults = (props: RouteComponentProps) => {
	const { page, query } = parseSearch<SearchParams>(props.location.search);

	const loading = useServiceMonitor(["searchEvents"]);

	const { result: events } = useDataService(searchEvents, [query, page], {});

	let pages = events ? events.metadata.totalRecords / events.metadata.pageSize : 0;

	return (
		<CommonPage isSitixLogoSelected={true}>
			<MetaDecorator title={MetaTagData.SearchResults.title} description={MetaTagData.SearchResults.description} />
			<FullScreenLoader show={loading} />

			<SearchContainer>
				<StyledSearch />
			</SearchContainer>

			<SearchResultsLayout>
				<SearchReusultsContent>
					<ResultsFor>Search results for “{query}”</ResultsFor>
					<Events title={""} events={events?.data || []} />
					<NavigationPaging page={page} pages={pages} route={InternalRoutes.SearchResults()} />
				</SearchReusultsContent>
				<CategorizedResults>
					{renderCategory("Performer")}
					{renderCategory("Futures")}
					{renderCategory("Venue")}
				</CategorizedResults>
			</SearchResultsLayout>
		</CommonPage>
	);
};

const renderCategory = (searchCategory: string) => (
	<SearchCategoryContainer>
		<SearchCategoryTitle>{searchCategory}</SearchCategoryTitle>
		<SearchCategoryContent>
			<SearchCategoryItem>
				<SearchCategoryItemImage />
				<SearchCategoryItemContent>
					<p className="title">Title1</p>
					<p className="subtitle">Subtitle1</p>
				</SearchCategoryItemContent>
			</SearchCategoryItem>
			<SearchCategoryItem>
				<SearchCategoryItemImage />
				<SearchCategoryItemContent>
					<p className="title">Title1</p>
					<p className="subtitle">Subtitle2</p>
				</SearchCategoryItemContent>
			</SearchCategoryItem>
			<ShowMore onClick={() => {}}>
				Show more
				<ArrowImage alt="" src={selectArrowGray} />
			</ShowMore>
		</SearchCategoryContent>
	</SearchCategoryContainer>
);

const StyledSearch = styled(Search)`
	width: ${maxContentWidthValue * 0.66}px;
	max-width: 66vw;
	margin-left: auto;
	margin-right: auto;
	background: #fff;
`;

const SearchResultsLayout = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	${maxContentWidthStyles}

	@media ${mediaQueries.max670} {
		flex-direction: column;
	}

	padding-top: 18px;
	padding-bottom: 18px;
`;

const SearchReusultsContent = styled.div`
	flex: 1;
	padding: 1rem;
	@media only screen and ${mediaQueries.max480} {
		padding: 1rem 0;
	}
	padding-left: 0;
`;

const SearchContainer = styled.div`
	${maxContentWidthStyles}
	width: 100%;
	padding: 40px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0 4px 6px -6px #222;
`;

const ResultsFor = styled.h2`
	width: 100%;
	color: #3c3c3b;
	text-align: left;
	font-family: ${FontFamily.Poppins};
	font-size: 26px;
	font-weight: bold;
	font-style: normal;
	letter-spacing: 1.5px;
	line-height: normal;
`;

const CategorizedResults = styled.h2`
	flex: 0.5;
	height: 200px;
`;

const SearchCategoryContainer = styled.div`
	/*algo*/
`;
const SearchCategoryTitle = styled.h2`
	background-color: #e2e4e7;
	padding: 5px 20px;

	font-style: normal;
	letter-spacing: normal;
	line-height: normal;
	text-transform: uppercase;
	text-align: left;
	color: #12284c;
	font-family: Montserrat;
	font-size: 17px;
	font-weight: bold;
	font-style: normal;
	box-sizing: border-box;
`;
const SearchCategoryContent = styled.div`
	padding: 15px 20px;
`;
const SearchCategoryItem = styled.div`
	display: flex;
	flex-direction: row;
	&:not(:last-child) {
		padding-bottom: 15px;
	}
`;

const SearchCategoryItemImage = styled.img`
	flex: 1;
	hieght: auto;
	border-radius: 11px;
`;
const SearchCategoryItemContent = styled.div`
	flex: 2;
	padding-left: 15px;
	text-align: left;

	font-family: ${FontFamily.Poppins};
	font-style: normal;
	line-height: normal;
	font-size: 16px;

	.title {
		color: #12284b;
		font-weight: bold;
	}
	.subtitle {
		color: #707372;
		font-weight: 300;
	}
`;

export const SearchViewMore = styled.div`
	z-index: 3;
	float: right;
	font-size: 0.8rem;
	border-radius: 35px;
	color: #555555 !important;
	transition: 0.2s cubic-bezier(0.22, 0.61, 0.36, 1) box-shadow;
	box-shadow: 2px 2px 7px 0px #ccc;
	background: #e2e4e7;
	user-select: none;
	cursor: pointer;
	border: 2px solid #fff;
	padding: 0.45rem 0.85rem;
	&:hover {
		box-shadow: 4px 4px 7px 0px #ccc;
	}
`;

const ShowMore = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	color: #707372;
	font-family: Montserrat;
	font-size: 14px;
	font-style: normal;

	margin-left: auto;
	margin-right: 0;

	position: relative;
	padding: 8px 0px;
	padding-right: 8px;
	width: 120px;

	border-radius: 1.25rem;
	background-color: white;
	border-color: #888;
	box-shadow: 3px 4px 3px 0px #8888;

	&:hover {
		cursor: pointer;
	}
`;

const ArrowImage = styled.img.attrs({
	height: 10,
	width: 10,
})`
	position: absolute;
	right: 1rem;
`;
