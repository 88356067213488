import { Reducer } from "redux";
import { EventCubeTicket } from "../../model/Cart";
import { EventData } from "../../model/EventPage";
import { Nulleable } from "../../model/Utils";
import { SeaticsLegendItem, SeaticsTicketGroup, SeaticsTicketSegment } from "../../presentation/components/Seatics/types";
import { EventVenueInfoResponse } from "../../services/types/EventVenueInfoResponse";
import { EventBetsResponse } from "../../services/types/MetaBetsResponseCodec";
import { TicketResponse } from "../../services/types/TicketResponse";

export interface EventState {
	eventData: Nulleable<EventData>;
	tickets: Nulleable<TicketResponse>;
	mapData: Nulleable<EventVenueInfoResponse>;
	visibleTickets: Nulleable<SeaticsTicketSegment[]>;
	legendItems: Nulleable<SeaticsLegendItem[]>;
	selectedSection: Nulleable<SeaticsLegendItem>;
	eventInfoVisible: boolean;
	replacementModalVisible: boolean;
	replacementQuantity: number;
	eventFilterVisible: boolean;
	requestError: Nulleable<boolean>;
	unavailableStatus: Nulleable<boolean>;
	unavailableMessage: Nulleable<string>;
	loading: boolean;
	loadedEventSlug: Nulleable<string>;
	selectedTicket: Nulleable<SeaticsTicketGroup>;
	selectedBOTickets: Nulleable<SeaticsTicketGroup[]>;
	selectedEventCubeTicket: Nulleable<EventCubeTicket>;
	highlightedTicket: Nulleable<SeaticsTicketGroup>;
	highlightedSection: Nulleable<SeaticsLegendItem>;
	eventBets: Nulleable<EventBetsResponse>;
	seaticsTimeout: boolean;
	mainEventRelated: Nulleable<{
		eventName: string;
		eventSlug: string;
		venueCity: string;
		venueState: string;
		venueName: string;
		occursAt: string;
	}>;
}

const defaultState: EventState = {
	eventData: null,
	tickets: null,
	visibleTickets: null,
	legendItems: null,
	selectedSection: null,
	mapData: null,
	eventInfoVisible: false,
	requestError: null,
	loading: true,
	loadedEventSlug: null,
	selectedTicket: null,
	selectedBOTickets: null,
	selectedEventCubeTicket: null,
	highlightedTicket: null,
	highlightedSection: null,
	eventFilterVisible: false,
	unavailableStatus: null,
	unavailableMessage: null,
	replacementModalVisible: false,
	replacementQuantity: 0,
	eventBets: null,
	seaticsTimeout: false,
	mainEventRelated: null,
};

export const eventReducer: Reducer<EventState, EventActions> = (state: EventState = defaultState, action: EventActions): EventState => {
	switch (action.type) {
		case "SET_EVENT_INFO_VISIBILITY":
			return { ...state, eventInfoVisible: action.visible };
		case "SET_EVENT_FILTER_VISIBILITY":
			return { ...state, eventFilterVisible: action.visible };
		case "SET_REPLACEMENT_MODAL_VISIBILITY":
			return { ...state, replacementModalVisible: action.status, replacementQuantity: action.quantity };
		case "SET_LOADING":
			return { ...state, loading: action.status };

		case "SET_EVENT_DATA":
			return {
				...state,
				eventData: action.data,
				mainEventRelated: action.data.mainEventRelated || null,
			};
		case "SET_TICKETS_DATA":
			return {
				...state,
				tickets: action.data,
			};
		case "SET_SEATICS_DATA":
			return {
				...state,
				mapData: action.data,
			};

		case "SET_VISIBLE_TICKETS":
			return {
				...state,
				visibleTickets: action.data,
			};
		case "SET_LEGEND_ITEMS":
			return {
				...state,
				legendItems: action.data,
			};
		case "SET_SECTION":
			return {
				...state,
				selectedSection: action.data,
				legendItems: [
					...(state.legendItems || []).filter(item => item.id !== action.data.id),
					{
						...action.data,
						isSelected: !action.data.isSelected,
					},
				],
			};
		case "SET_REQUEST_ERROR":
			return {
				...state,
				requestError: action.status,
			};
		case "SET_LOADED_EVENT":
			return {
				...state,
				loading: false,
				loadedEventSlug: action.eventSlug,
			};
		case "SET_SELECTED_TICKET":
			return {
				...state,
				selectedTicket: action.ticket,
			};
		case "REMOVE_SELECTED_BO_TICKET":
			return {
				...state,
				selectedBOTickets: state.selectedBOTickets?.filter(ticket=>ticket.tgID!==action.ticketGroupId) ?? null
			}
		case "SET_SELECTED_BO_TICKET":
			if (!action.ticket) {
				return {
					...state,
					selectedTicket: action.ticket,
				};
			}

			let tickets = [action.ticket];

			if (state.selectedBOTickets) {
				tickets.push(...state.selectedBOTickets);
			}

			return {
				...state,
				selectedBOTickets: tickets,
			};
		case "SET_SELECTED_EVENT_CUBE_TICKET":
			return {
				...state,
				selectedEventCubeTicket: action.ticket,
			};
		case "SET_HIGHLIGHTED_TICKET":
			return {
				...state,
				highlightedTicket: action.ticket,
			};
		case "SET_HIGHLIGHTED_SECTION":
			return {
				...state,
				highlightedSection: action.section,
			};
		case "SET_UNAVAILABLE":
			return {
				...state,
				unavailableStatus: true,
				unavailableMessage: action.message,
			};
		case "SET_EVENT_BETS":
			return {
				...state,
				eventBets: action.data,
			};
		case "SET_SEATICS_TIMEOUT":
			return {
				...state,
				seaticsTimeout: action.status,
			};
		case "RESET_ALL":
			return defaultState;
		default:
			return state;
	}
};

export type EventActions =
	| {
			type: "SET_EVENT_DATA";
			data: EventData;
	  }
	| {
			type: "SET_TICKETS_DATA";
			data: TicketResponse;
	  }
	| {
			type: "SET_SEATICS_DATA";
			data: EventVenueInfoResponse;
	  }
	| {
			type: "SET_VISIBLE_TICKETS";
			data: SeaticsTicketSegment[];
	  }
	| {
			type: "SET_LEGEND_ITEMS";
			data: SeaticsLegendItem[];
	  }
	| {
			type: "SET_SECTION";
			data: SeaticsLegendItem;
	  }
	| {
			type: "SET_REQUEST_ERROR";
			status: boolean;
	  }
	| {
			type: "SET_REPLACEMENT_MODAL_VISIBILITY";
			status: boolean;
			quantity: number;
	  }
	| {
			type: "SET_EVENT_INFO_VISIBILITY";
			visible: boolean;
	  }
	| {
			type: "SET_EVENT_FILTER_VISIBILITY";
			visible: boolean;
	  }
	| {
			type: "SET_LOADING";
			status: boolean;
	  }
	| {
			type: "SET_LOADED_EVENT";
			eventSlug: string;
	  }
	| {
			type: "SET_SELECTED_TICKET";
			ticket: SeaticsTicketGroup | null;
	  }
	| {
			type: "SET_SELECTED_BO_TICKET";
			ticket: SeaticsTicketGroup | null;
	  }
	| {
			type: "REMOVE_SELECTED_BO_TICKET";
			ticketGroupId: number;
	  }
	| {
			type: "SET_SELECTED_EVENT_CUBE_TICKET";
			ticket: EventCubeTicket | null;
	  }
	| {
			type: "SET_HIGHLIGHTED_TICKET";
			ticket: Nulleable<SeaticsTicketGroup>;
	  }
	| {
			type: "SET_HIGHLIGHTED_SECTION";
			section: Nulleable<SeaticsLegendItem>;
	  }
	| {
			type: "SET_UNAVAILABLE";
			message: string | null;
	  }
	| {
			type: "SET_EVENT_BETS";
			data: EventBetsResponse | null;
	  }
	| {
			type: "SET_SEATICS_TIMEOUT";
			status: boolean;
	  }
	| {
			type: "RESET_ALL";
	  };
