import React from "react";
import styled from "styled-components";
import { useCart } from "../../../services/hooks";
import { getTypographyStyles } from "../Typography/Typography";
import { DeliveryMethodSelection } from "./DeliveryMethodSelection";
import { ShippingInfo } from "./ShippingInfo";

export const DeliveryInfo = () => {
	const { delivery } = useCart();

	return (
		<Çontainer>
			<Title>Select Shipping Method</Title>
			{delivery?.method === "FedEx" && <ShippingInfo />}
			<DeliveryMethodSelection />
		</Çontainer>
	);
};

const Çontainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const Title = styled.span`
	margin-bottom: 20px;
	text-transform: uppercase;
	text-align: left;
	width: 100%;

	${getTypographyStyles("bodySmall", {
		color: "dark",
		colorType: "dark",
		fontWeight: "bold",
	})};
`;
