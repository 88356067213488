import React from "react";
import queryString from "query-string";
import { RouteComponentProps } from "react-router-dom";
import { InternalRoutes } from "../Link";
import { parseSearch } from "../util/parseSearch";
import { EventRouteParams, EventSearchParams } from "../../model/EventPage";
import { EventContainer } from "../components/EventPage/EventContainer";
import { useElite24EventTracking } from "../hooks/useElite24EventTracking";
/**
 * Let's keep only the route handling on this component
 */
export const EventRoute2 = (props: RouteComponentProps<EventRouteParams>) => {
	const params = props.match.params;
	const searchParams = parseSearch<EventSearchParams>(props.location.search);

	const onFiltersUpdate = (filters: EventSearchParams) => {
		let newFilters: any = {};

		Object.keys(filters).forEach((filterKey: any) => {
			let key: keyof EventSearchParams = filterKey;
			if (filters[key] !== null && filters[key] !== undefined) {
				newFilters[key] = filters[key];
			}
		});
		let nextParams = queryString.stringify(newFilters);
		props.history.replace(InternalRoutes.Event(params.event, `?${nextParams}`));
	};

	const onCheckoutRequest = () => {
		props.history.push(InternalRoutes.Checkout);
	};

	const onEventNotFound = () => {
		props.history.replace(InternalRoutes.NotFound, {
			title: "Not Found",
			message: "The requested event was not found on this site",
		});
	};

	const onRequestError = () => {
		props.history.replace(InternalRoutes.Error, {
			title: "Oops",
			message: "An error has occurred trying to process your request.",
		});
	};

	const onReloadPage = () => {
		props.history.go(0);
	};

	useElite24EventTracking();
	return (
		<>
			<EventContainer
				{...{
					params,
					searchParams,
					onFiltersUpdate,
					onCheckoutRequest,
					onEventNotFound,
					onRequestError,
					onReloadPage,
				}}
			/>
		</>
	);
};
