import { apiClient, apiBaseUrl } from "./apiClient";
import { APIClientResponse } from "./types/APIClientResponse";
import { TicketResponse } from "./types/TicketResponse";

export function getTicketData(eventId?: number): APIClientResponse<TicketResponse> {
	return apiClient.get(`ticketlist/${eventId}`, {
		baseURL: apiBaseUrl("livetickets"),
	});
}

export function saveRegistrationData(formValues: Array<{[key: string]: string}>, eventId: number, formId: number): APIClientResponse<TicketResponse> {
	return apiClient.post("/cart/event-registration", {
		formValues,
		eventId,
		formId
	});
}