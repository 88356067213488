import { useRef } from "react";
import isEqual from "lodash/isEqual";

export function useDeepMemo<T>(nextValue: T) {
	const value = useRef<T>(nextValue);

	if (!isEqual(value, nextValue)) {
		value.current = nextValue;
	}

	return value.current;
}
