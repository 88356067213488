import React, { useEffect, useState } from "react";
import { Link } from "../../Link";
import { Modal } from "../Modal";
import { ModalContainer, ModalClose, OfferModal } from "./styles";

export interface OfferProps {
	to: string;
	image: string;
	delay: number;
}

/**
 * Offer Modal that is displayed on top of the current page.
 */
export const Offer = (props: OfferProps) => {
	const [show, setShow] = useState(false);
	const [removed, setRemoved] = useState(false);
	const close = () => setShow(false);

	useEffect(() => {
		if (removed) return;

		const timeout = setTimeout(() => {
			// setShow(true);
		}, props.delay);
		return () => clearTimeout(timeout);
	}, [removed, props.delay, setShow]);

	const onVisibilityChange = (isVisible: boolean) => {
		if (isVisible) return;
		setRemoved(true);
	};

	if (removed) {
		return null;
	}

	return (
		<Modal onClose={close} onVisibilityChange={onVisibilityChange} show={show}>
			<ModalContainer>
				<ModalClose onClick={close}>x</ModalClose>
				<OfferModal>
					<Link onClick={close} to={props.to}>
						<img src={props.image} alt="Home Offer" />
					</Link>
				</OfferModal>
			</ModalContainer>
		</Modal>
	);
};
