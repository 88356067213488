import React, { useRef, useEffect } from "react";
import { Typography, getTypographyStyles } from "../Typography/Typography";
import styled from "styled-components/macro";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";
import { PrimaryBorder } from "../PrimaryBorder/PrimaryBorder";
import { SkeletonWrapper } from "../SkeletonWrapper/SkeletonWrapper";
import { SkeletonProps } from "../../../model/optimizedModel/skeleton";
import { useAutoUpdateState } from "../../hooks/useAutoUpdateState";
import { getAppColor } from "../../util/appColors";
import eventArrowRed from "../../resource/image/event-arrow-red.svg";
import { Button } from "../NewButton/Button";
import { mediaQueries } from "../../util/mediaQueries";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { breakpoints } from "../../util/breakpoints";

export const NewEventThumbnail = ({
	aspectRatio = [6, 11],
	...props
}: {
	selected?: boolean;
	onClick?: () => void;
	title?: string;
	subtitle?: string;
	name?: string;
	titleLoading?: boolean;
	subtitleLoading?: boolean;
	setImageHeight?: React.Dispatch<React.SetStateAction<number>>;
	aspectRatio?: [number, number];
	imageOverlayText?: string;
	ticketGroupPrice?: string;
} & StyledComponentProps &
	SkeletonProps<
		{
			background: string;
		},
		"backgroundLoading"
	>) => {
	const displayTitleElement = useAutoUpdateState(() => props.title !== undefined || props.titleLoading, [props.title, props.titleLoading]);

	const displaySubtitleElement = useAutoUpdateState(() => props.subtitle !== undefined || props.subtitleLoading, [props.subtitle, props.subtitleLoading]);

	const thumbnailRef = useRef<HTMLDivElement>(null);

	const isMobile = useMediaQuery(`max-width: ${breakpoints.mobile}`);

	const onResize = () => {
		if (thumbnailRef.current && props.setImageHeight) {
			props.setImageHeight(thumbnailRef.current.clientHeight);
		}
	};

	useEffect(() => {
		window.addEventListener("resize", onResize);
		onResize();
		return () => {
			window.removeEventListener("resize", onResize);
		};
	}, []);

	return (
		<ThumbnailContainer className={props.className} onClick={props.onClick}>
			<SkeletonWrapper
				loading={props.backgroundLoading}
				style={{
					...((displayTitleElement || displaySubtitleElement) && {
						marginBottom: 8,
					}),
					zIndex: 0,
				}}
			>
				<ThumbnailContent
					style={{
						...(props.name !== undefined &&
							!props.selected && {
								background: "linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, #000000 100%)",
							}),
					}}
				>
					<AspectRatioWrapper
						style={{
							paddingBottom: 100 * (aspectRatio[0] / aspectRatio[1]) + "%",
						}}
					>
						<ImageWrapper
							ref={thumbnailRef}
							css={`
								${props.backgroundLoading &&
								`
								background-color: ${getAppColor("lightGrey", "dark")};
								& > * {
									display: none;
								}
								`}
							`}
						>
							<ThumbImage src={props.background} alt="Thumbnail" />
							{props.selected && <SelectedBorder />}
							<div
								css={`
									${getTypographyStyles("smallSpecialBody", {
										color: "dark",
										highlight: true,
									})}
									position: absolute;
									bottom: 7px;
									left: 7px;
									padding: 1px 7px;
									border-radius: 6px;
								`}
							>
								{props.imageOverlayText}
							</div>
						</ImageWrapper>
					</AspectRatioWrapper>
					{props.name !== undefined && <ThumbText>{props.name}</ThumbText>}
				</ThumbnailContent>
			</SkeletonWrapper>
			<DescriptionContent>
				{displayTitleElement && (
					<Title type="bodySmall" fontWeight={600} loading={props.titleLoading}>
						{props.title}
					</Title>
				)}
				<BottomContent>
					<div
						css={`
							min-width: 0;
						`}
					>
						{displaySubtitleElement && (
							<Subtitle 
								loading={props.subtitleLoading}
								type="bodyTiny"
								color="dark"
								colorType="light"
							>
								{props.subtitle}
							</Subtitle>
						)}
					</div>
				</BottomContent>
				<SkeletonWrapper loading={props.backgroundLoading} style={{ marginTop: "auto" }}>
					<StyledButton variant="solid" size="small">
						{props.ticketGroupPrice && parseInt(props.ticketGroupPrice) > 0 ? (
							<div>
								<span
									css={`
										@media ${mediaQueries.max900} {
											display: none;
										}
									`}
								>
									Tickets
								</span>{" "}
								<span>
									<strong>from ${props.ticketGroupPrice}</strong>
								</span>
							</div>
						) : (
							<span>
								<strong>Get Tickets</strong>
							</span>
						)}
						<EventArrow src={eventArrowRed} alt="Get tickets" />
					</StyledButton>
				</SkeletonWrapper>
			</DescriptionContent>
		</ThumbnailContainer>
	);
};

const SelectedBorder = styled(PrimaryBorder)`
	position: absolute;
	width: 100%;
	bottom: 0;
`;

const ThumbnailContainer = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid ${getAppColor("darkGrey", "light")};
	border-radius: 6px;
	height: 100%;
`;

const ThumbnailContent = styled.div`
	position: relative;
	cursor: pointer;
	&:hover {
		background: none !important;
	}
`;

const AspectRatioWrapper = styled.div`
	width: 100%;
	height: 0;
	position: relative;
`;

const ImageWrapper = styled.div`
	position: absolute;
	z-index: -1;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`;

const ThumbImage = styled.img`
	display: black;
	width: 100%;
	height: 100%;
	background-color: ${getAppColor("lightGrey", "dark")};

	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
`;

const ThumbText = styled.span`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	padding: 0px 21px;
	box-sizing: border-box;
	${getTypographyStyles("heading1", {
		color: "light",
	})}
	font-size: 157% !important;
	line-height: 1.1 !important;
`;

const Title = styled(Typography)`
	z-index: 0;
	text-align: left;
	* {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		white-space: initial;
	}
`;

const Subtitle = styled(Typography)`
	z-index: 0;
	text-align: left;
	* {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

const DescriptionContent = styled.div`
	padding: 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const BottomContent = styled.div`
	display: flex;
	align-items: flex-start;
	margin-bottom: 18px;
`;

const EventArrow = styled.img`
	right: 16px;
	position: absolute;
`;

const StyledButton = styled(Button)`
	display: flex;
	& > * {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 6px;
		text-transform: uppercase;
	}
`;
