import { useEffect, useRef } from "react";
import mixpanel from "mixpanel-browser";
import { Platform } from "../util/UserAgent";
import { Config } from "../../Config";
import { useUser } from "../../services/hooks/useUser";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { useGuest } from "../../services/hooks/useGuest";

declare global {
	interface Window {
		dataLayer: any;
		hj: any;
	}
}

const capitalizeProvider = (provider: string) => {
	return provider.charAt(0).toUpperCase() + provider.slice(1);
};

export const SessionTracking = () => {
	const user = useUser();
	const guest = useGuest();
	const session = useSelector(state => state.persistent.session);
	const userCache = useRef<number | null>(null);
	const history = useHistory();

	const trackPageView = () => {
		const path = history.location.pathname;
		const params = history.location.search;

		mixpanel.track("Pageview", {
			"Page Path": path.concat(params),
		});
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "GAPW",
			"pPath'": path.concat(params),
		});
	};

	const trackSignIn = (userId: number, userType: "FullUser" | "Guest") => {
		const signInMethod = session.provider === "email" ? "e-mail" : session.provider;
		mixpanel.identify(userId.toString());
		mixpanel.track("Sign-In", {
			"Sign-In Type": capitalizeProvider(signInMethod || ""),
		});

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "GAE",
			eC: "Membership",
			eA: "Sign-In",
			eL: signInMethod === "e-mail" ? userType : capitalizeProvider(signInMethod || ""),
		});
	};

	// riskified beacon
	useEffect(() => {
		mixpanel.init(Config.getMixPanelKey());
		const platform = Platform.IOS || Platform.Android ? "App" : "Web";

		mixpanel.register({
			Platform: platform,
		});

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			Platform: platform,
		});

		trackPageView();
		//generate user_session_id
		let userSessionId = window.sessionStorage.getItem("user_session_id");
		if (userSessionId === null) {
			userSessionId = uuid();
			window.sessionStorage.setItem("user_session_id", userSessionId);
			const sessionCreatedAt = new Date().toISOString();
			window.sessionStorage.setItem("created_at", sessionCreatedAt);
		}

		const riskifiedBeaconLoad = () => {
			var store_domain = Config.getRiskifiedStoreDomain();
			var session_id = userSessionId;
			var url = ("https:" === document.location.protocol ? "https://" : "http://") + "beacon.riskified.com?shop=" + store_domain + "&sid=" + session_id;
			var s = document.createElement("script");
			s.type = "text/javascript";
			s.async = true;
			s.src = url;
			var x = document.getElementsByTagName("script")[0];
			x.parentNode?.insertBefore(s, x);
		};

		if (document.readyState === "complete") {
			riskifiedBeaconLoad();
		} else {
			window.addEventListener("load", riskifiedBeaconLoad, false);
			return () => window.removeEventListener("load", riskifiedBeaconLoad);
		}
	}, []);

	useEffect(() => {
		if (user?.id) {
			trackSignIn(user.id, "FullUser");
		}

		if (guest?.id) {
			trackSignIn(guest.id, "Guest");
		}
	}, [session.sessionToken, user, guest]);

	useEffect(() => {
		if (session.sessionToken && user && user.id !== userCache.current) {
			const ticketOrderCount = user.ticket_order_count;
			const reservationOrderCount = user.reservation_order_count;
			const purchaseCount = ticketOrderCount && reservationOrderCount ? ticketOrderCount + reservationOrderCount : 0;
			mixpanel.people.set({
				$email: user.email,
				$first_name: user.fname,
				$last_name: user.lname,
				"Sign-Up Date": user.created_at,
				"User ID": user.id,
				"Sign-Up Type": session.provider,
				"Purchase Count": purchaseCount,
			});

			window.dataLayer = window.dataLayer || [];

			window.dataLayer.push({
				event: "GAI",
				email: user.email,
				first_name: user.fname,
				last_name: user.lname,
				"Sign-Up Date": user.created_at.split("T")[0],
				"User ID": user.id,
				"Sign-Up Type": session.provider,
				"Purchase Count": purchaseCount,
			});

			window.hj =
				window.hj ||
				function () {
					(window.hj.q = window.hj.q || []).push(arguments);
				};

			window.hj("identify", user.id, {
				first_name: user.fname,
				last_name: user.lname,
				"Sign-Up Date": user.created_at.split("T")[0],
				"User ID": user.id,
				"Sign-Up Type": capitalizeProvider(session.provider || ""),
				"Purchase Count": purchaseCount,
			});
			userCache.current = user.id;
		}
	}, [user, session]);

	useEffect(() => {
		history.listen(() => {
			trackPageView();
		});
	}, [history]);

	return null;
};
