import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CommonPage, Events } from "../components";
import { RouteComponentProps } from "react-router-dom";
import { parseSearch } from "../util/parseSearch";
import { InternalRoutes } from "../Link";
import { useDataService } from "../hooks/useServiceCall";
//import { DateRangeFilter, SelectFilter } from "../components/Filters";
//import { DateRange } from "../components/Filters/DateRangeFilter";
import { maxContentWidthStyles } from "../util/maxContentWidth";
import { useDispatch, useSelector } from "react-redux";
import { getPopularEvents } from "../../services/actions/eventsService";
import { getPopularPerformers } from "../../services/actions/popularService";
import { Featured } from "../components/Featured/Featured";
import { FeaturedItem } from "../components/Featured/FeaturedItem";
import { PopularPerformer } from "../../services/types/PopularResponseCodec";
import { Config } from "../../Config";
import { useObjectMergeReducer, useServiceMonitor } from "../hooks";
import { VerticalAd, VerticalAdContainer } from "../components/Advertisement/Advertisement";
import { isRight } from "fp-ts/lib/These";
import { Event } from "../../model/Event";
import { GlobalAssetCarousel } from "../components/HomeCarousel/GlobalAssetCarousel";
import { MetaDecorator, MetaTagData } from "../components/SEOMetadecorator/MetaDecorator";
import { ThumbnailCarouselRow } from "../components/Thumbnail/ThumbnailCarouselRow";
import { LocationPickerHeader } from "../components/LocationPickerHeader/LocationPickerHeader";
import { FullScreenLoader } from "../components/Loader";

//Page sizes
const EVENTS_PAGE_SIZE = 10;
const PERFORMERS_PAGE_SIZE = 20;

//Distances
const PERFORMER_DISTANCE_MILES = null;

interface PopularSearchParams {
	category: string | null;
	subcategory: string | null;
	from: string | null;
	to: string | null;
	distance: string | null;
}

interface PopularState {
	page: number;
	total: number;
	events: Event[];
}

export const PopularRoute = (props: RouteComponentProps) => {
	const searchParams = parseSearch<PopularSearchParams>(props.location.search);

	const dispatch = useDispatch();

	const location = useSelector(state => state.persistent.location);
	const loadingPerformers = useServiceMonitor(["getPopularPerformers"]);
	const loadingEvents = useServiceMonitor(["getPopularEvents"]);

	/**
	 * Popular performers
	 */
	const { result: popularConcerts } = useDataService(getPopularPerformers, ["concerts", location, PERFORMER_DISTANCE_MILES, PERFORMERS_PAGE_SIZE], {});

	const { result: popularSports } = useDataService(getPopularPerformers, ["sports", location, PERFORMER_DISTANCE_MILES, PERFORMERS_PAGE_SIZE], {});

	const { result: popularTheater } = useDataService(getPopularPerformers, ["theater", location, PERFORMER_DISTANCE_MILES, PERFORMERS_PAGE_SIZE], {});

	const renderPerformer = (item: PopularPerformer, index: number, title: string) => (
		<FeaturedItem
			altTitleLink={item.name}
			key={index}
			data={{
				title: item.name,
				subtitle: "",
				titleLink: InternalRoutes.Performer(item.slug),
				image: item.image ? Config.getResourceUrl(item.image) : null,
			}}
			containerTitle={title}
		/>
	);

	const [state, setState] = useObjectMergeReducer<PopularState>({
		page: 0,
		total: 0,
		events: [],
	});

	//reset events
	useEffect(() => {
		setState({ events: [], total: 0 });
		loadEvents(true);
	}, [location, searchParams.category, searchParams.subcategory, searchParams.distance, searchParams.from, searchParams.to]);

	//update events
	const loadEvents = async (reset?: boolean) => {
		const page = reset ? 1 : ++state.page;
		const result = await dispatch(getPopularEvents(page, searchParams.subcategory || searchParams.category, searchParams.from, searchParams.to, searchParams.distance, location, EVENTS_PAGE_SIZE));
		const events = isRight(result) ? result.right.data : [];
		const total = isRight(result) ? result.right.metadata.totalRecords : 0;

		setState({ page, total, events: reset ? events : [...state.events, ...events] });
	};

	let adAssets = {
		/**TODO: connect service when ready */
	} as any;

	const [test, setTest] = useState(false);

	return (
		<CommonPage isSitixLogoSelected={true}>
			<MetaDecorator title={MetaTagData.Popular.title} description={MetaTagData.Popular.description} />

			<GlobalAssetCarousel
				customProps={{
					headline1: {
						text: "Our Top Picks",
						style: {
							fontSize: "40px",
							fontWeight: 600,
							textAlign: "left",
							color: "white",
						},
					},
				}}
			></GlobalAssetCarousel>
			<Featured title="concerts" items={popularConcerts || []} renderItem={renderPerformer} />
			<Featured title="Sports" items={popularSports || []} renderItem={renderPerformer} />
			<Featured title="Theater" items={popularTheater || []} renderItem={renderPerformer} />
			<FullScreenLoader show={loadingPerformers} />
			<Filters.Container>
				{/*<Filters.Field>
					<SelectFilter
						value={searchParams.category}
						name="category"
						defaultText="Category"
						items={categories}
						onChange={onSelectChange}
					/>
				</Filters.Field>
				<Filters.Spacer />
				<Filters.Field>
					<SelectFilter
						value={searchParams.subcategory}
						name="subcategory"
						defaultText="Subcategory"
						items={subcategories}
						onChange={onSelectChange}
					/>
				</Filters.Field>
				<Filters.Spacer />
				<Filters.Field>
					<DateRangeFilter from={searchParams.from} to={searchParams.to} onChange={onDateChange} />
				</Filters.Field>
				<Filters.Spacer />
				<Filters.Field>
					<SelectFilter
						value={searchParams.distance}
						name="distance"
						defaultText="Distance"
						items={POPULAR_EVENTS_DISTANCE_OPTIONS}
						onChange={onSelectChange}
					/>
				</Filters.Field>*/}
			</Filters.Container>
			<button
				onClick={() => {
					setTest(!test);
				}}
			>
				SWITCH
			</button>

			<Wrapper>
				<LocationPickerHeader headerTitle="Trending Events" showLocationPicker viewAllLink="https://www.google.com" headerTitleLoading={test} viewAllLinkLoading={test} displayNoEventsFoundMessage customNoEventsFoundMessage="No local events were found, showing events in all locations:" />
				<ThumbnailCarouselRow
					loading={test}
					titlesAreLoading={test}
					subtitlesAreLoading={test}
					secondarySubtitlesAreLoading={test}
					selectedSlug="2"
					thumbnails={[
						{
							background: "https://via.placeholder.com/500",
							title: "Miami Dolphins Season Tickets",
							subtitle: "AT&T Stadium",
							secondarySubtitle: "Wed. Sep 15, 2021 8:00 PM",
							slug: "1",
						},
						{
							background: "https://via.placeholder.com/500",
							title: "Miami Dolphins Season Tickets",
							subtitle: "AT&T Stadium",
							secondarySubtitle: "Wed. Sep 15, 2021 8:00 PM",
							slug: "1",
						},
						{
							background: "https://via.placeholder.com/500",
							title: "Miami Dolphins Season Tickets",
							subtitle: "AT&T Stadium",
							secondarySubtitle: "Wed. Sep 15, 2021 8:00 PM",
							slug: "1",
						},
						{
							background: "https://via.placeholder.com/500",
							title: "Miami Dolphins Season Tickets",
							subtitle: "AT&T Stadium",
							secondarySubtitle: "Wed. Sep 15, 2021 8:00 PM",
							slug: "1",
						},
						{
							background: "https://via.placeholder.com/500",
							title: "Miami Dolphins Season Tickets",
							subtitle: "AT&T Stadium",
							secondarySubtitle: "Wed. Sep 15, 2021 8:00 PM",
							slug: "1",
						},
						{
							background: "https://via.placeholder.com/500",
							title: "Miami Dolphins Season Tickets",
							subtitle: "AT&T Stadium",
							secondarySubtitle: "Wed. Sep 15, 2021 8:00 PM",
							slug: "1",
						},
						{
							background: "https://via.placeholder.com/500",
							title: "Miami Dolphins Season Tickets",
							subtitle: "AT&T Stadium",
							secondarySubtitle: "Wed. Sep 15, 2021 8:00 PM",
							slug: "1",
						},
						{
							background: "https://via.placeholder.com/500",
							title: "Miami Dolphins Season Tickets",
							subtitle: "AT&T Stadium",
							secondarySubtitle: "Wed. Sep 15, 2021 8:00 PM",
							slug: "1",
						},
						{
							background: "https://via.placeholder.com/500",
							title: "Miami Dolphins Season Tickets",
							subtitle: "AT&T Stadium",
							secondarySubtitle: "Wed. Sep 15, 2021 8:00 PM",
							slug: "1",
						},
					]}
				/>

				<div style={{ marginBottom: "50px" }} />

				<LocationPickerHeader headerTitle="Trending Events" showLocationPicker viewAllLink="https://www.google.com" headerTitleLoading={test} viewAllLinkLoading={test} displayNoEventsFoundMessage={false} />
				<ThumbnailCarouselRow
					loading={test}
					titlesAreLoading={test}
					subtitlesAreLoading={test}
					secondarySubtitlesAreLoading={test}
					selectedSlug="2"
					thumbnails={[
						{
							background: "https://via.placeholder.com/500",
							title: "Miami Dolphins Season Tickets",
							subtitle: "AT&T Stadium",
							secondarySubtitle: "Wed. Sep 15, 2021 8:00 PM",
							slug: "1",
						},
						{
							background: "https://via.placeholder.com/500",
							title: "Miami Dolphins Season Tickets",
							subtitle: "AT&T Stadium",
							secondarySubtitle: "Wed. Sep 15, 2021 8:00 PM",
							slug: "1",
						},
						{
							background: "https://via.placeholder.com/500",
							title: "Miami Dolphins Season Tickets",
							subtitle: "AT&T Stadium",
							secondarySubtitle: "Wed. Sep 15, 2021 8:00 PM",
							slug: "1",
						},
						{
							background: "https://via.placeholder.com/500",
							title: "Miami Dolphins Season Tickets",
							subtitle: "AT&T Stadium",
							secondarySubtitle: "Wed. Sep 15, 2021 8:00 PM",
							slug: "1",
						},
						{
							background: "https://via.placeholder.com/500",
							title: "Miami Dolphins Season Tickets",
							subtitle: "AT&T Stadium",
							secondarySubtitle: "Wed. Sep 15, 2021 8:00 PM",
							slug: "1",
						},
						{
							background: "https://via.placeholder.com/500",
							title: "Miami Dolphins Season Tickets",
							subtitle: "AT&T Stadium",
							secondarySubtitle: "Wed. Sep 15, 2021 8:00 PM",
							slug: "1",
						},
						{
							background: "https://via.placeholder.com/500",
							title: "Miami Dolphins Season Tickets",
							subtitle: "AT&T Stadium",
							secondarySubtitle: "Wed. Sep 15, 2021 8:00 PM",
							slug: "1",
						},
						{
							background: "https://via.placeholder.com/500",
							title: "Miami Dolphins Season Tickets",
							subtitle: "AT&T Stadium",
							secondarySubtitle: "Wed. Sep 15, 2021 8:00 PM",
							slug: "1",
						},
						{
							background: "https://via.placeholder.com/500",
							title: "Miami Dolphins Season Tickets",
							subtitle: "AT&T Stadium",
							secondarySubtitle: "Wed. Sep 15, 2021 8:00 PM",
							slug: "1",
						},
					]}
				/>
			</Wrapper>
			{!!adAssets?.vertical_adv_image_1?.url ||
				(!!adAssets?.vertical_adv_image_2?.url && (
					<Events title="" events={state.events} loading={loadingEvents} emptyMessage="No events found. Use the filters to discover events." onLoadMore={loadEvents} total={state.total} ads={adAssets}>
						<VerticalAdContainer>
							<VerticalAd sticky={!adAssets.vertical_adv_image_2?.url} {...adAssets.vertical_adv_image_1} />
							<VerticalAd sticky={!!adAssets.vertical_adv_image_2?.url} {...adAssets.vertical_adv_image_2} />
						</VerticalAdContainer>
					</Events>
				))}
		</CommonPage>
	);
};

export const Filters = {
	Container: styled.div`
		${maxContentWidthStyles}
		display: flex;
		padding: 1rem 0.5rem;
		align-items: center;
	`,
	Field: styled.div`
		flex: 1;
		min-width: 0;
		& > * {
			width: 100%;
		}
	`,
	Spacer: styled.div`
		width: 0.5rem;
	`,
};

const horizontalPadding = "20px";
const Wrapper = styled.div`
	padding: 0 ${horizontalPadding};
	margin: 30px auto 0;
	max-width: min(1060px, calc(100vw - 2 * ${horizontalPadding}));
`;
