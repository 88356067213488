import { APIClientResponse } from "./types/APIClientResponse";
import { apiClient } from "./apiClient";

export function getEventsPopular(
	category_slug?: string,
	latitude?: number,
	longitude?: number,
	page_size?: number
): APIClientResponse<
	{
		category_id: number;
		created_at: string;
		id: number;
		image: string;
		name: "string";
		parent_category_id: 16;
		popularity_score: string;
		slug: string;
		updated_at: string;
	}[]
> {
	return apiClient.get("/events/popular", {
		params: {
			page_number: 1,
			page_size: page_size || 10,
			popularity_top: 0.1,
			category_slug,
			latitude,
			longitude,
		},
	});
}
