import React, { useRef, useState } from "react";
import { SeaticsTicketGroup } from "../Seatics/types";
import { TicketContent, TicketRow, TicketPrice, Title, Spacer, Subtitle, AltBackArrow, ViewFromSeatContainer, ViewFromSeatPreview } from "./styles";
import { getTicketQuantity } from "../../util/formatQuantity";
import arrow from "../../resource/image/ticket-group-arrow.svg";
import { shortFormatPrice } from "../../util/formatPrice";
import { useAutoUpdateState } from "../../hooks/useAutoUpdateState";
import { useSelector } from "react-redux";
import { siThePartyEventId } from "../../util/general";
import si_the_party_map from "../../resource/assets/seatics-override-images/si-the-party/map.png";
import { getTicketDeliveryType, hasTicketDeliveryType } from "./TicketUtils";
import { Nulleable } from "../../../model/Utils";

interface TicketGroupRowProps {
	ticket: SeaticsTicketGroup;
	ticketHover: (ticket: Nulleable<SeaticsTicketGroup>) => void;
	ticketClick: (ticket: SeaticsTicketGroup) => void;
	panelWidth: number;
	panelTop: number;
	vfsDisabled?: boolean;
}

export const TicketGroupRow = (props: TicketGroupRowProps) => {
	const { eventData } = useSelector(state => state.transient.event);
	const arrowRef = useRef<HTMLDivElement>(null);
	const [hoverId, setHoverId] = useState<number | null>(null);

	const onTicketHover = (ticket: Nulleable<SeaticsTicketGroup>) => {
		setHoverId(ticket && ticket.tgID);
		props.ticketHover(ticket);
	};

	const ticket = useAutoUpdateState(() => {
		if (eventData?.id === siThePartyEventId) {
			const { tgUserSec } = props.ticket;
			return {
				...props.ticket,
				section: {
					vfsUrl: si_the_party_map,
					vfsUrlLarge: si_the_party_map,
					visible: true,
					vfs: tgUserSec,
					level: {
						color: "#ffffff",
					},
				},
				getSectionDisplayName: () => tgUserSec,
				getRowDisplayName: () => props.ticket.tgUserRow,
			};
		}
		return props.ticket;
	}, [props.ticket, eventData?.id]);

	const quantityRange = ticket.tgClientData.tgSplits.sort((a: number, b: number) => a - b);
	const vfsUrl = ticket?.section?.vfsUrl ? ticket?.section?.vfsUrl : undefined;
	const vfsHeight = 126;
	const arrowOffset = arrowRef?.current?.getBoundingClientRect() || {
		height: 0,
		top: 0,
	};

	const calculateTop = () => {
		return arrowOffset.top + arrowOffset.height / 2 - props.panelTop - vfsHeight / 2 - 2;
	};

	return (
		<TicketRow onClick={() => props.ticketClick(ticket)} onMouseOver={() => onTicketHover(ticket)} onMouseLeave={() => onTicketHover(null)} leftBorderColor={eventData?.id === siThePartyEventId ? undefined : props.ticket.section?.level?.color}>
			<TicketContent>
				<div>
					<Title>{ticket.getSectionDisplayName()}</Title>
					<Subtitle>
						{`Row: ${ticket.getRowDisplayName()} `}
						<span>&nbsp; | &nbsp;</span>
						{getTicketQuantity(quantityRange)}
					</Subtitle>
				</div>
				<Spacer />
				<TicketPrice>
					{ticket.tgPrice === 0 ? (
						<>Free</>
					) : (
						<>
							<strong>{shortFormatPrice(ticket.tgPrice)}</strong>
							/ea
						</>
					)}
				</TicketPrice>
				<div ref={arrowRef}>
					<AltBackArrow src={arrow} alt="arrow" />
				</div>
				{!props.vfsDisabled && hoverId && hoverId === ticket.tgID && vfsUrl && (
					<ViewFromSeatContainer style={{ left: props.panelWidth, top: calculateTop() }} height={vfsHeight}>
						<ViewFromSeatPreview src={vfsUrl} />
					</ViewFromSeatContainer>
				)}
			</TicketContent>
		</TicketRow>
	);
};
