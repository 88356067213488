import { Config } from "../../Config";
import { ServiceCallAction } from "../../store/middleware/serviceCallMiddleware";
import { didLogIn } from "../../store/reducers/sessionReducer";
import { ResetPasswordResponse, ResetPasswordResponseCodec } from "../types/RegisterResponseCodec";
import { SessionResponse, SessionResponseCodec } from "../types/SessionResponseCodec";

export const logIn = (email: string, password: string, rememberMe: boolean, token: string, remoteIp: string): ServiceCallAction<SessionResponse> => ({
	method: "POST",
	endpoint: "user/token",
	body: {
		email,
		password,
		deviceName: Config.getPlatformOS(),
		captchaToken: token,
		remoteIp,
		sessionCreatedAt: window.sessionStorage.getItem("created_at"),
		sessionId: window.sessionStorage.getItem("user_session_id"),
	},
	serviceKey: "logIn",
	responseDecoder: SessionResponseCodec,
	type: "SERVICE_CALL",
	onSuccess: response => didLogIn("email", response.accessToken, email, rememberMe),
});

export const resetPassword = (params: { password: string; passwordConfirmation: string; token: string; email: string; remoteIp: string; user?: string }): ServiceCallAction<ResetPasswordResponse> => ({
	method: "POST",
	endpoint: "user/change-password",
	body: {
		password: params.password,
		password_confirmation: params.passwordConfirmation,
		token: params.token,
		email: params.email,
		remoteIp: params.remoteIp,
		sessionCreatedAt: window.sessionStorage.getItem("created_at"),
		sessionId: window.sessionStorage.getItem("user_session_id"),
		deviceName: Config.getPlatformOS(),
		user: params.user,
	},
	serviceKey: "change-password",
	responseDecoder: ResetPasswordResponseCodec,
	type: "SERVICE_CALL",
});
