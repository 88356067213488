import React, { useLayoutEffect, useRef, useState } from "react";
import { CommonPage } from "../components";
import { maxContentWidthStyles } from "../util/maxContentWidth";
import { mediaQueries } from "../util/mediaQueries";
import styled, { css } from "styled-components/macro";
import { Redirect, RouteComponentProps } from "react-router";
import { MetaDecorator, MetaTagData } from "../components/SEOMetadecorator/MetaDecorator";
import { CollapseBehavior, EducationalRow } from "../components/Futures/EducationalPanel";
import { Nulleable } from "../../model/Utils";
import { InternalRoutes } from "../Link";

const fullwidth = false;
const collapseBehavior: CollapseBehavior = "header";

export const SITixChampionshipLegalRoute = (props: RouteComponentProps) => {
	const termsAndConditions = useRef<any | null>(null);
	const privacyPolicy = useRef<any | null>();

	const [selected, setSelection] = useState<Nulleable<string>>(null);
	const sectionProps = { selected, setSelection, fullwidth, collapseBehavior };

	useLayoutEffect(() => {
		if (props.location.hash === "#terms-and-conditions") {
			termsAndConditions.current?.scrollIntoView();
		} else if (props.location.hash === "#privacy-policy") {
			privacyPolicy.current?.scrollIntoView();
		}
	});

	return <Redirect to={InternalRoutes.Home} />;

	return (
		<CommonPage isSitixLogoSelected={true} background="#fff">
			<MetaDecorator title={MetaTagData.SITixChampionshipLegal.title} description={MetaTagData.SITixChampionshipLegal.description} />
			<PageContainer>
				<Section ref={termsAndConditions}>
					<Title>SI Tickets National Championship Official Rules (“Official Rules”).</Title>
					<br /> <br />
					<Strong>NO PURCHASE IS NECESSARY TO ENTER OR WIN THIS CONTEST. A PURCHASE OR PAYMENT OF ANY KIND WILL NOT INCREASE YOUR CHANCES OF WINNING.</Strong>
					<EducationalSectionContainer fullwidth={fullwidth}>
						<EducationalRow title="1. Eligibility" {...sectionProps}>
							<ItemContent>
								<ItemText>This contest (“Contest”) is open only to legal residents of the United States and the District of Columbia except for Rhode Island who are eighteen (18) years of age or older at the time of entry. Void where prohibited by law. Employees (and their immediate family or household members) of Sponsor (as defined in Section 17 of these Official Rules) and its current and future parent, affiliates, subsidiaries, divisions, and related companies, licensees, suppliers, printers and advertising, promotional and judging agencies or any other company or individual involved with the design, production, execution or distribution of the Contest are not eligible. "Immediate family members" shall mean parents, stepparents, children, stepchildren, siblings, stepsiblings, or spouses, regardless of where they live. "Household members" shall mean people who share the same residence at least three (3) months a year, whether related or not. The Contest is subject to all applicable federal, state and local laws, codes, statutes and regulations. Participation constitutes each entrant’s (each, an “Entrant”) full, unconditional and irrevocable agreement to be bound by these Official Rules and the decisions of the Sponsor, whose decisions shall be binding and final in all respects relating to this Contest. Winning a Grand Prize (as hereinafter defined) is contingent upon fulfilling all requirements set forth herein.</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="2. Contest entry period" {...sectionProps}>
							<ItemContent>
								<ItemText>
									The term of the Contest begins at <b>12:00 a.m., Eastern Standard Time</b> (“EST”) on <strong>October 7, 2021</strong> and ends at <strong>11:59 p.m. EST</strong> on
									<strong> December 10, 2021</strong> (“Contest Term”). Within the Contest Term, there is one (1) entry period (the “Contest Entry Period”). There will be (1) random Drawing with a total of eight (8) winners for this Contest as outlined in the “Contest Entry Period & Drawing Schedule” below. Entries must be received during the Contest Entry Period to be eligible for the Contest Entry Period’s Drawing and Grand Prize. Non-winning entries for a Contest Entry Period will not roll over to any subsequent Contest Entry Period, if any. Eastern Standard Time shall control for all purposes of this Contest.
									<br />
									<br />
									<table>
										<tbody>
											<Row>
												<Cell width="60">
													<p>
														<strong>Entry Period #</strong>
													</p>
												</Cell>
												<Cell width="158">
													<p>
														<strong>Entry Period</strong>
													</p>
													<p>
														<strong>Start Date and Time:</strong>
													</p>
												</Cell>
												<Cell width="162">
													<p>
														<strong>Entry Period</strong>
													</p>
													<p>
														<strong>End Date and Time:</strong>
													</p>
												</Cell>
												<Cell width="172">
													<p>
														<strong>
															Entry Period Drawing Date and Time:
															<br /> On or about
														</strong>
													</p>
												</Cell>
												<Cell width="72">
													<p>
														<strong># of Grand Prize(s) per Entry Period</strong>
													</p>
												</Cell>
											</Row>
											<Row>
												<Cell width="60">
													<p>1</p>
												</Cell>
												<Cell width="158">
													<p>10/7/21 @ 12:00 a.m. EST</p>
												</Cell>
												<Cell width="162">
													<p>12/10/21 @ 11:59 p.m. EST</p>
												</Cell>
												<Cell width="172">
													<p>12/17/21 @ 12:00 p.m. EST</p>
												</Cell>
												<Cell width="72">
													<p>8</p>
												</Cell>
											</Row>
										</tbody>
									</table>
								</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="3. How to enter" {...sectionProps}>
							<ItemContent>
								<ItemText>
									NO PURCHASE NECESSARY. To enter the Contest, you must: register for the contest on the SI Tickets National Championship Hosted by Venmo site and provide your first and last name, email address, and any other information reasonably requested at the following URL: &nbsp;
									<a href="http://www.sitickets.com/sitix-championship">www.sitickets.com/sitix-championship</a>
									&nbsp; (the “Content”) during the Contest Entry Period (each, an “Entry”). There is a limit of one (1) Entry per person during the Contest Entry Period. In order to be eligible for the Contest Entry Period’s Drawing and Grand Prize, your Entry must comply with all terms and conditions of the Contest, including with respect to the applicable Contest Entry Period. By participating, you are indicating that you have read, understand and fully agree to these Official Rules. Sponsor is not responsible for lost, late, incomplete, invalid, or misdirected submissions, all of which will be disqualified. Entries must be manually keystroked by Entrant; use of programmed, robotic or like means of entry (including third party contest services) is prohibited. Sponsor will not acknowledge receipt of any Entry.Venmo is only providing prizing for this promotion, but is not an official sponsor.
								</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="4. Terms of submission" {...sectionProps}>
							<ItemContent>
								<ItemText>By participating, you agree that Sponsor has the unrestricted right to use the Content contained in any such Entry, if Sponsor so chooses in its sole discretion, commercially or non-commercially, including the worldwide right to publish, display, exhibit and exploit such Content in any media now in existence or hereafter devised for any and all advertising and publicity purposes, all without additional review, approval, consent and/or compensation. By participating, you represent and warrant to Sponsor that (a) you have not copied or used other works in your Content without permission or right to do so; (b) no person, other than you has any right to, title or interest in any part of your Content; (c) your Content will not infringe or violate any rights of any third party or entity, including, without limitation, trade secret, copyright and trademark, defamation, privacy and publicity, false light, idea misappropriation or any contractual rights; and (d) you have all the rights, licenses, permissions and consents necessary to submit the comment in accordance with these Official Rules. You understand that Sponsor reserves the right to disqualify any Content at any time for any reason at its sole and absolute discretion. BY PARTICIPATING, THE ENTRANT REPRESENTS AND WARRANTS TO SPONSOR THAT THE ENTRANT HAS ALL RIGHT, TITLE AND INTEREST NECESSARY TO GRANT THE SPONSOR THE WORLDWIDE, ABSOLUTE, IRREVOCABLE AND UNRESTRICTED RIGHT AND LICENSE TO ADAPT, PUBLISH, USE, REUSE, EDIT, AND/OR MODIFY THE CONTENT CONTAINED IN THE ENTRY IN ANY WAY AND POST THE CONTENT ON THE INTERNET OR USE THE CONTENT IN ANY OTHER WAY AND AGREES TO INDEMNIFY, DEFEND AND HOLD SPONSOR HARMLESS FROM ANY AND ALL CLAIMS TO THE CONTRARY.</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="5. Selection of winner" {...sectionProps}>
							<ItemContent>
								<ItemText>
									On or about <strong>12:00 p.m. EST</strong> on <strong>December 17, 2021</strong>, Sponsor will select eight (8) entries from the Contest Entry Period for the Grand Prize described below in one (1) random Drawing of all valid Entries during the Contest Entry Period. For the avoidance of doubt, there will be (1) random Drawing with a total of eight (8) winners for this Contest. Exact timing of the Grand Prize selection will be at the sole and absolute discretion of Sponsor. There is a limit of eight (8) Grand Prize during the Contest Entry Period. The selected Entrant will be contacted at such Entrant’s email address by the Sponsor. The potential Grand Prize winner(s) must follow the Grand Prize claim instructions provided as part of the notification and any subsequent claim instructions provided by Sponsor or the Grand Prize will be forfeited in its entirety. Sponsor’s decisions as to the administration and operation of the Contest and the selection of the potential Grand Prize winner is final and binding in all matters related to the Contest. Grand Prize winner(s) will be notified within approximately one (1) business day after the Drawing. Failure to respond to the initial notification within one (1) business day will result in disqualification. Odds of winning the Grand Prize depend on the number of eligible Entries received during the Contest Entry Period.
								</ItemText>
							</ItemContent>
						</EducationalRow>
					</EducationalSectionContainer>
					<br />
					<br />
					<SectionContainer>
						<strong>6. THE POTENTIAL CONTEST GRAND PRIZE WINNER(S) IS/ARE SUBJECT TO VERIFICATION BY SPONSOR WHOSE DECISIONS ARE FINAL AND BINDING IN ALL MATTERS RELATED TO THE CONTEST.</strong>
					</SectionContainer>
					<br />
					<br />
					<EducationalSectionContainer fullwidth={fullwidth}>
						<EducationalRow title="7. Verification of potential grand prize winner(s)" {...sectionProps}>
							<ItemContent>
								<ItemText>The potential Grand Prize winner(s) must continue to comply with all terms and conditions of these Official Rules and winning is contingent upon fulfilling all requirements. The potential Grand Prize winner(s) may be required to sign and return to Sponsor, within five (5) days of the date notice is sent, an affidavit of eligibility, and liability/publicity release (except where prohibited) in order to claim the Grand Prize, if applicable. If any potential Grand Prize winner cannot be contacted, fails to sign and return the affidavit of eligibility and liability/publicity release within the required time period (if applicable), or if the Grand Prize or Grand Prize notification is returned as undeliverable, the potential Grand Prize winner forfeits the Grand Prize. Grand Prize winner(s) will be a selection made by Sponsor in its sole discretion.</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="8. Grand prize and approximate retail value" {...sectionProps}>
							<ItemContent>
								<ItemText>Eight (8) Grand Prizes will be awarded. Each Grand Prize winner will be awarded (a) two tickets in the Venmo suite at the College Football National Championship at Lucas Oil Stadium, Indianapolis on January 10, 2022 and (b) two (2) nights stay at Sheraton Indianapolis City Center Hotel from January 9, 2022 checking out on January 11, 2022. (collectively, the “Grand Prize”). There is a limit of one (1) Grand Prize per person during the Contest Entry Period. No cash equivalent for any Grand Prize will be awarded. The Grand Prize is non-transferable, and no substitution will be made except as provided herein at the Sponsor’s sole and absolute discretion. Sponsor reserves the right to substitute the listed Grand Prize for one (1) of equal or greater value for any reason or no reason whatsoever. The winner is responsible for all fees associated with Grand Prize receipt and/or use by the winner. All federal, state, and local tax liabilities are the sole and absolute responsibility of a winner.</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="9. Odds of winning" {...sectionProps}>
							<ItemContent>
								<ItemText>Odds of winning the Grand Prize for any given Contest Entry Period depend on the total number of eligible Entries received during the Contest Entry Period.</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="10. Entry conditions and release" {...sectionProps}>
							<ItemContent>
								<ItemText>By participating, each Entrant fully and unconditionally agrees to: (a) comply with and be bound by these Official Rules and the decisions of the Sponsor which are binding and final in any and all matters relating to this Contest; (b) release and hold harmless the Sponsor and their respective parent, subsidiary, and affiliated companies, PayPal, Inc. and any other organizations responsible for sponsoring, fulfilling, administering, advertising or promoting the Contest, and all of their respective past, present and future officers, directors, shareholders, employees, agents, representatives and licensees (collectively, the “Released Parties”) from and against any and all claims, expenses, losses, damages and liability, including but not limited to negligence, damages of any kind to persons, animals and property, including but not limited to invasion of privacy (under appropriation, intrusion, public disclosure of private facts, false light in the public eye or other legal theory), defamation, slander, libel, violation of right of publicity, infringement of trademark, copyright or other intellectual property rights, property damages, or death or personal injury arising out of or relating to a participant’s Entry, creation of an Entry or submission of an Entry, participation in the Contest, acceptance or use or misuse of the Grand Prize (including any travel or activity related thereto) and/or the broadcast, exploitation or use of Entry; (c) indemnify, defend and hold harmless the Sponsor from and against any and all claims, expenses, losses, damages and liabilities (including reasonable attorney’s fees) arising out of or relating to an Entrant’s participation in the Contest and/or Entrant’s acceptance, use or misuse of the Grand Prize; and (d) consent as required by applicable law to Sponsor’s use of information collected for purposes of operating the Contest and for statistical purposes.</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="11. General conditions" {...sectionProps}>
							<ItemContent>
								<ItemText>Sponsor reserves the right to cancel, suspend and/or modify the Contest, or any part of it, if any fraud, technical failures or any other factor beyond Sponsor’s reasonable control that impairs the integrity or proper functioning of the Contest, as determined by Sponsor in its sole and absolute discretion. Sponsor reserves the right to cancel the Contest if too few Entries are received. Sponsor reserves the right in its sole and absolute discretion to disqualify any Entrant acting in violation of these Official Rules or found tampering with the Entry process or with the operation of the Contest or any other promotion or in an unsportsmanlike or disruptive manner. Any attempt by any person to deliberately undermine the legitimate operation of the Contest may be a violation of criminal and civil law, and, should such an attempt be made, Sponsor hereby expressly reserves the right to seek damages from any such person to the fullest extent permitted by law. Sponsor’s failure to enforce any term of these Official Rules shall not constitute a waiver of that provision. In the event the Contest is terminated early, a winner will be selected from all eligible Entries received at the time of termination. Notice of such action by Sponsor will be posted on the @sportsillustratedtix Instagram page located at: https://www.instagram.com/sportsillustratedtix/.</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="12. Limitations of liability" {...sectionProps}>
							<ItemContent>
								<ItemText>The Released Parties are not responsible for: (a) any incorrect or inaccurate information, whether caused by Entrant(s), printing errors or by any of the equipment or programming associated with or utilized in the Contest; (b) technical failures of any kind, including, but not limited to malfunctions, interruptions, or disconnections in phone lines or network hardware or software; (c) unauthorized human intervention in any part of the Entry process or the Contest; (d) technical or human error which may occur in the administration of the Contest or the processing of Entries; or (e) any injury or damage to persons, animals, or property which may be caused, directly or indirectly, in whole or in part, from Entrant’s participation in the Contest or receipt or misuse of any Grand Prize. If for any reason an Entrant’s Entry is confirmed to have been erroneously deleted, lost, or otherwise destroyed or corrupted, Entrant’s sole remedy is another Entry in the Contest. No more than the stated number of Grand Prize(s) will be awarded.</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="13. Publicity" {...sectionProps}>
							<ItemContent>
								<ItemText>Except where prohibited by law, participation in the Contest constitutes participants consent to Sponsor and its agents’ use of winners’ names, images, likeness, photograph, voice, opinions and/or hometown and other indicia of his/her persona for advertising/publicity/trade or promotional purposes in any and all forms of media, worldwide, without notice, review, approval, or further payment or consideration.</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="14. Disputes" {...sectionProps}>
							<ItemContent>
								<ItemText>Entrant agrees that: (a) any and all disputes, claims and causes of action arising out of or connected with this Contest, or any prizes (including the Grand Prize) awarded, other than those concerning the administration of the Contest or the determination of winners, shall be resolved individually, without resort to any form of class action; (b) any and all disputes, claims and causes of action arising out of or connected with this Contest, or any prizes (including the Grand Prize) awarded, shall be resolved exclusively by the United States District Court or the appropriate New York State Court located in the New York County, New York; (c) any and all claims, judgments and awards shall be limited to actual out-of-pocket costs incurred, including costs associated with entering this Contest, but in no event attorneys’ fees; and (d) under no circumstances will Entrant be permitted to obtain awards for, and Entrant hereby waives all rights to claim punitive, incidental and consequential damages and any other damages, other than for actual out-of-pocket expenses, and any and all rights to have damages multiplied or otherwise increased. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATIONS OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE MAY NOT APPLY TO YOU. All issues and questions concerning the construction, validity, interpretation and enforceability of these Official Rules, or the rights and obligations of the Entrant and Sponsor in connection with the Contest, shall be governed by, and construed in accordance with, the laws of the State of New York, without giving effect to any choice of law or conflict of law rules (whether of the State of New York or any other jurisdiction), which would cause the application of the laws of any jurisdiction other than the State of New York.</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="15. Winner’s list" {...sectionProps}>
							<ItemContent>
								<ItemText>
									For an Official Winner’s List (available on or after
									<strong> January 10, 2022</strong>) or a copy of these Official Rules, send a self-addressed, stamped envelope to: “<strong>SI Tickets National Championship Hosted by Venmo</strong>” Contest - WINNERS - or - OFFICIAL RULES (please specify), SI Tickets, Inc, 234 10th Avenue, #20273, New York, New York 10011, United States. All requests must be received by
									<strong> January 10, 2021</strong>. Official Rules also available at: &nbsp;
									<a href="http://www.sitickets.com/sitix-championship/legal">www.sitickets.com/sitix-championship/legal</a>. Vermont residents may omit postage.
								</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="16. Entrant's personal information" {...sectionProps}>
							<ItemContent>
								<ItemText>Sponsor may collect non-public personally identifiable information in connection with the operation of the Contest. Information collected on-line from Entrants is subject to the Sponsor’s Privacy Policy, which is available at https://sitickets.com/legal. Sponsor is not responsible for disclosures made by any third party.</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="17. Sponsor" {...sectionProps}>
							<ItemContent>
								<ItemText>SI Tickets, Inc, 234 10th Avenue, #20273, New York, New York 10011, United States</ItemText>
							</ItemContent>
						</EducationalRow>
					</EducationalSectionContainer>
				</Section>
			</PageContainer>
		</CommonPage>
	);
};

const textFontSize = css`
	font-size: 16px;
	line-height: 24px;
	@media ${mediaQueries.max480} {
		font-size: 14px;
		line-height: 20px;
	}
`;

const Section = styled.div`
	margin-top: 24px;
`;

const PageContainer = styled.div`
	${maxContentWidthStyles}
	padding: 40px 15px 24px;
	text-align: left;
	color: #000;
`;

const Title = styled.h1`
	font-size: 40px;
	font-weight: 700;
	font-family: SolanoGothicMVB-Bd, sans-serif;
	text-transform: uppercase;
`;

const Strong = styled.span`
	font-weight: 700;
`;

const ItemContent = styled.div`
	display: flex;
	margin-bottom: 20px;
	&:last-child {
		margin-bottom: 0px;
	}
`;

const ItemText = styled.div`
	width: 100%;
	font-family: Montserrat;
	margin-right: 8px;
	font-weight: 500;
	${textFontSize}
	ul {
		padding-left: 20px;
		li {
			margin-top: 5px;
		}
	}
	p {
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0px;
		}
	}
	ol {
		padding-left: 30px;
		li {
			margin-top: 5px;
			list-style: decimal;
		}
	}
	a {
		text-decoration: underline;
		color: #e92224;
	}
`;

const EducationalSectionContainer = styled.div<{ fullwidth?: boolean }>`
	margin-top: ${props => (props.fullwidth ? "0px" : "24px")};
`;

const Cell = styled.td`
	border-left: 1px solid #000;
	border-right: 1px solid #000;
	font-size: 12px;
	padding-left: 5px;
`;

const Row = styled.tr`
	border-top: 1px solid #000;
	border-bottom: 1px solid #000;
`;

const SectionContainer = styled.p`
	padding: 0 26px;
	font-size: 16px;
`;
