/**
 * The user's location, as a name and a coordinate.
 */
export type Location = {
	name: string;
	latitude: number;
	longitude: number;
};

export const renderLocation = (location: Location): string => {
	return location.name;
};
