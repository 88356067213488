import React, { useState } from "react";
import { maxContentWidthStyles } from "../util/maxContentWidth";
import { MetaDecorator, MetaTagData } from "../components/SEOMetadecorator/MetaDecorator";
import styled from "styled-components/macro";
import { mediaQueries } from "../util/mediaQueries";
import golfClassic from "../resource/image/golf-classic.svg";
import golfClassicHero from "../resource/image/golf-classic-hero.svg";
import golfClassicHeroMobile from "../resource/image/golf-classic-hero-mobile.svg";
import golfClassicPlayer from "../resource/image/golf-classic-player.png";
import golfClassicPlayerMobile from "../resource/image/golf-classic-player-mobile.png";
import { CommonPage } from "../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCocktail, faGift, faGolfBall, faMapMarkerAlt, faMusic, faStar, faCheck } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { Form, Field } from "react-final-form";
import { RFFInput } from "../components/Input/Input";
import { required, validEmail } from "../util/optimized/finalFormUtil";
import { golfSuscriptionService } from "../../services/actions/golfSubscriptionService";
import { useDispatch } from "react-redux";
import { isRight } from "fp-ts/lib/These";
import toast, { Toaster } from "react-hot-toast";
import { ModalCloseButton } from "../components/Modal/ModalCloseButton";
import { ModalPopup } from "../components/ModalPopup/ModalPopup";
import { Button } from "../components/Button/Button";
import { useToastRemoval } from "../hooks/useToastRemoval";
import { LandingPageButton } from "../components/LandingPage/LandingPageButton";
import { HeaderHashAnchor } from "../components/HeaderHashAnchor/HeaderHashAnchor";
import { FontFamily } from "../components/Typography/Typography";
import { InternalRoutes } from "../Link";

const mobileScreenWidth = "750px";

const upfrontTerms = "The contest will run from August 2nd to September 2nd, 2021. You must sign up during this date range to be eligible.";

export const GolfClassicRoute = () => {
	const isMobile = useMediaQuery("(max-width: " + mobileScreenWidth + ")");
	const [showFormFeedback, setShowFormFeedback] = useState(false);
	const dispatch = useDispatch();
	const onSubmit = async (values: { firstName: string; lastName: string; email: string }) => {
		const result = await dispatch(golfSuscriptionService(values.email, values.firstName, values.lastName));

		if (isRight(result)) {
			setShowFormFeedback(true);
		} else {
			toast.error("There was an error processing your request.");
		}
	};

	useToastRemoval();

	return (
		<CommonPage background="#fff">
			<MetaDecorator title={MetaTagData.GolfClassic.title} description={MetaTagData.GolfClassic.description} />

			<HeaderContainer>
				<Header>
					<HeaderTitleContainer>
						<HeaderTitle>Enter for a chance to win a VIP experience at the 2022 Golf Classic</HeaderTitle>
						<ResponsiveLandingPageButton forwardedAs="a" href="#form">
							Enter Contest
						</ResponsiveLandingPageButton>
					</HeaderTitleContainer>
					<HeaderImage>
						<img src={golfClassic} alt="Golf Classic" />
					</HeaderImage>
				</Header>
			</HeaderContainer>

			<MainContent>
				<ContentRow>
					<ContentBox padding="20px 0" margin={isMobile ? "0px" : "0 20px 0 0"}>
						<ContentTitle>Event Overview</ContentTitle>
						<ContentText>The Annual Golf Classic was created in 2004 to provide an outlet in which socialites, executives, and entertainment industry personalities alike could compete with one another outside of the office. This lifestyle event was founded with the goal of providing its participants with additional opportunities to network while enjoying their universal passion: golf.</ContentText>
					</ContentBox>
					<ContentBox align="center" background="#fafafa" padding="20px 0" margin={isMobile ? "20px 0 0" : "0 0 0 20px"}>
						<ContentTitle margin="0 0 15px">Contest Winner will receive</ContentTitle>
						<Separator />
						<ItemList padding="0px 20px">
							<ItemListWithIcon>
								<FontAwesomeIcon icon={faGolfBall} color="#E92224" size={isMobile ? "2x" : "lg"} />
								<ItemText>Foursome at the 2022 Golf Classic</ItemText>
							</ItemListWithIcon>
							<ItemListWithIcon>
								<FontAwesomeIcon icon={faStar} color="#E92224" size={isMobile ? "2x" : "lg"} />
								<ItemText>VIP Event Experience</ItemText>
							</ItemListWithIcon>
							<ItemListWithIcon>
								<FontAwesomeIcon icon={faGift} color="#E92224" size={isMobile ? "2x" : "lg"} />
								<ItemText>Exclusive Golf Classic Care Package</ItemText>
							</ItemListWithIcon>
						</ItemList>
					</ContentBox>
				</ContentRow>

				<ContentRow border="1px solid #000000">
					<ContentBox
						padding="20px"
						backgroundImage={isMobile ? golfClassicPlayerMobile : golfClassicPlayer}
						style={{
							backgroundPositionX: "center",
						}}
					/>
					<ContentBox padding={isMobile ? "20px" : "40px"}>
						<ContentTitle size="small" margin="0 0 12px">
							The Golf Classic
						</ContentTitle>
						<ContentText size="small" margin="0 0 16px 0">
							The event provides a well-rounded lifestyle experience with an opportunity to experience:
						</ContentText>
						<ItemList>
							<ItemListWithIcon direction="row">
								<FontAwesomeIcon icon={faCocktail} color="#E92224" size={isMobile ? "2x" : "lg"} />
								<ItemTextStrong>Great Food & Bar</ItemTextStrong>
							</ItemListWithIcon>
							<ItemListWithIcon direction="row">
								<FontAwesomeIcon icon={faMusic} color="#E92224" size={isMobile ? "2x" : "lg"} />
								<ItemTextStrong>Entertainment & Amazing Outdoors</ItemTextStrong>
							</ItemListWithIcon>
							<ItemListWithIcon direction="row">
								<FontAwesomeIcon icon={faMapMarkerAlt} color="#E92224" size={isMobile ? "2x" : "lg"} />
								<ItemTextStrong>Calabasas Country Club, 4515 Park Entrada, CA </ItemTextStrong>
							</ItemListWithIcon>
						</ItemList>
					</ContentBox>
				</ContentRow>
				{/*The below div is an "anchor" for the Header call to action link. The styles are to ensure that the user is taken to an area where the form is not blocked by the navbar.*/}
				<HeaderHashAnchor id="form" />
				{showFormFeedback ? (
					<ContentRow>
						<ContentBoxFeedback width="100%" background="#fafafa" padding={isMobile ? "20px 0px" : "50px"} align="center">
							<FontAwesomeIcon icon={faCheck} color="#E92224" size="2x" />
							<ContentTitleFeedback>You are already registered in the contest</ContentTitleFeedback>
							<ContentText size="small" margin="0 0 16px" padding={isMobile ? "0px" : "0px 15%"}>
								{upfrontTerms}
							</ContentText>
							<TermsModalLinkContainer>
								<TermsModalLink />
								<p>for the contest.</p>
							</TermsModalLinkContainer>
						</ContentBoxFeedback>
					</ContentRow>
				) : (
					<ContentRow>
						<ContentBox background="#fafafa" padding={isMobile ? "20px" : "40px 20px"}>
							<ContentTitle margin="0 0 16px">Enter your email for more information on next year's Golf Classic & Exclusive Promotions from SI Tickets</ContentTitle>
							<ContentText size="small">{upfrontTerms}</ContentText>
						</ContentBox>
						<ContentBox
							background="#fafafa"
							padding="40px 20px"
							style={
								isMobile
									? {
											paddingTop: 0,
									  }
									: undefined
							}
						>
							<Toaster position="bottom-center" />
							<Form
								onSubmit={onSubmit}
								initialValues={{ firstName: "", lastName: "", email: "" }}
								render={form => (
									<form onSubmit={form.handleSubmit}>
										<FormContainer>
											<SmallInputsContainer>
												<SmallInputWrapper>
													<Field name="firstName" component={StyledRFFInput} type="text" placeholder="First Name" validate={value => required(value)} />
												</SmallInputWrapper>
												<SmallInputWrapper>
													<Field name="lastName" component={StyledRFFInput} type="text" placeholder="Last Name" validate={value => required(value)} />
												</SmallInputWrapper>
											</SmallInputsContainer>
											<Field name="email" component={StyledRFFInput} type="email" placeholder="Your Email" validate={value => required(value) || validEmail(value)} />
											<Warning>
												By clicking Sign Up, you are agreeing to the <TermsModalLink /> for the contest.
											</Warning>
											<ButtonContainer>
												<ResponsiveLandingPageButton type="submit" disabled={form.invalid} loading={form.submitting}>
													Sign Up
												</ResponsiveLandingPageButton>
											</ButtonContainer>
										</FormContainer>
									</form>
								)}
							/>
						</ContentBox>
					</ContentRow>
				)}
			</MainContent>
		</CommonPage>
	);
};

const TermsModalLink = () => {
	const [showModal, setShowModal] = useState(false);
	const isMobile = useMediaQuery("(max-width: " + mobileScreenWidth + ")");
	return (
		<>
			{showModal && (
				<TermsModalPopup onDidClose={() => setShowModal(false)} rightHeaderElements={onClose => <ModalCloseButton onClick={onClose} />} fullscreen={isMobile}>
					{onClose => (
						<TermsContainer>
							<TermsTitle>Terms and Conditions</TermsTitle>
							<TermsContent>
								<p>
									<strong>
										SPORTS ILLUSTRATED &ldquo;2022 Golf Classic Giveaway&rdquo; Official Rules (&ldquo;<u>Official Rules</u>&rdquo;)&nbsp;
									</strong>
									<strong>NO PURCHASE IS NECESSARY TO ENTER OR WIN THIS CONTEST. A PURCHASE OR PAYMENT OF ANY KIND WILL NOT INCREASE YOUR CHANCES OF WINNING.</strong>
								</p>
								<p>
									<strong>&nbsp;</strong>
								</p>

								<ol>
									<li>
										<strong> ELIGIBILITY: </strong>This contest (&ldquo;<u>Contest</u>&rdquo;) is open only to legal residents of the fifty (50) United States and the District of Columbia who are eighteen (18) years of age or older at the time of entry. Void where prohibited by law. Employees (and their immediate family or household members) of Sponsor (as defined in Section 17 of these Official Rules) and its current and future parent, affiliates, subsidiaries, divisions, and related companies, licensees, suppliers, printers and advertising, promotional and judging agencies or any other company or individual involved with the design, production, execution or distribution of the Contest are not eligible. "Immediate family members" shall mean parents, stepparents, children, stepchildren, siblings, stepsiblings, or spouses, regardless of where they live. "Household members" shall mean people who share the same residence at least three (3) months a year, whether related or not. The Contest is subject to all applicable federal, state and local laws, codes, statutes and regulations. Participation constitutes each entrant&rsquo;s (each, an &ldquo;<u>Entrant</u>
										&rdquo;) full, unconditional and irrevocable agreement to be bound by these Official Rules and the decisions of the Sponsor, whose decisions shall be binding and final in all respects relating to this Contest. Winning a Grand Prize (as hereinafter defined) is contingent upon fulfilling all requirements set forth herein.
									</li>
								</ol>
								<p>
									<strong>&nbsp;</strong>
								</p>
								<ol>
									<li>
										<strong> CONTEST ENTRY PERIOD:</strong> The term of the Contest begins at&nbsp;
										<strong>12:00 a.m., Eastern Standard Time</strong> (&ldquo;<u>EST</u>&rdquo;) on&nbsp;
										<strong>August 2, 2021</strong> and ends at <strong>11:59 p.m. EST </strong>on
										<strong> September 2, 2021</strong> (&ldquo;<u>Contest Term</u>&rdquo;). Within the Contest Term, there is one (1) entry period (the &ldquo;
										<u>Contest Entry Period</u>&rdquo;) and one (1) corresponding random drawing (the &ldquo;<u>Drawing</u>&rdquo;) as outlined in the &ldquo;Contest Entry Period &amp; Drawing Schedule&rdquo; below. Entries must be received during the Contest Entry Period to be eligible for the Contest Entry Period&rsquo;s Drawing and Grand Prize.
										<u>&nbsp;Non-winning entries for a Contest Entry Period will not roll over to any subsequent Contest Entry Period, if any</u>. Eastern Standard Time shall control for all purposes of this Contest.
									</li>
								</ol>
								<p>&nbsp;</p>
								<table>
									<tbody>
										<Row>
											<Cell width="60">
												<p>
													<strong>Entry Period #</strong>
												</p>
											</Cell>
											<Cell width="158">
												<p>
													<strong>Entry Period</strong>
												</p>
												<p>
													<strong>Start Date and Time:</strong>
												</p>
											</Cell>
											<Cell width="162">
												<p>
													<strong>Entry Period</strong>
												</p>
												<p>
													<strong>End Date and Time:</strong>
												</p>
											</Cell>
											<Cell width="172">
												<p>
													<strong>
														Entry Period Drawing Date and Time:
														<br /> On or about
													</strong>
												</p>
											</Cell>
											<Cell width="72">
												<p>
													<strong># of Grand Prize(s) per Entry Period</strong>
												</p>
											</Cell>
										</Row>
										<Row>
											<Cell width="60">
												<p>1</p>
											</Cell>
											<Cell width="158">
												<p>8/2/21 @ 12:00 a.m. EST</p>
											</Cell>
											<Cell width="162">
												<p>9/2/21 @ 11:59 p.m. EST</p>
											</Cell>
											<Cell width="172">
												<p>9/4/21 @ 12:00 p.m. EST</p>
											</Cell>
											<Cell width="72">
												<p>1</p>
											</Cell>
										</Row>
									</tbody>
								</table>
								<p>
									<strong>&nbsp;</strong>
								</p>
								<ol>
									<li>
										<strong> HOW TO ENTER:</strong> NO PURCHASE NECESSARY. To enter the Contest, you must: sign-up for the SI Tickets by Sports Illustrated newsletter using your name, email address, and any other information reasonably requested at the following URL: <a href="http://www.sitickets.com/golf-classic">www.sitickets.com/golf-classic</a> (the &ldquo;Content&rdquo;) during the Contest Entry Period (each, an &ldquo;
										<u>Entry</u>&rdquo;). There is a limit of one (1) Entry per person during the Contest Entry Period. In order to be eligible for the Contest Entry Period&rsquo;s Drawing and Grand Prize, your Entry must comply with all terms and conditions of the Contest, including with respect to the applicable Contest Entry Period. By participating, you are indicating that you have read, understand and fully agree to these Official Rules. Sponsor is not responsible for lost, late, incomplete, invalid, or misdirected submissions, all of which will be disqualified. Entries must be manually keystroked by Entrant; use of programmed, robotic or like means of entry (including third party contest services) is prohibited. Sponsor will not acknowledge receipt of any Entry.
									</li>
								</ol>
								<p>&nbsp;</p>
								<ol>
									<li>
										<strong> TERMS OF SUBMISSION: </strong>By participating, you agree that Sponsor has the unrestricted right to use the Content contained in any such Entry, if Sponsor so chooses in its sole discretion, commercially or non-commercially, including the worldwide right to publish, display, exhibit and exploit such Content in any media now in existence or hereafter devised for any and all advertising and publicity purposes, all without additional review, approval, consent and/or compensation. By participating, you represent and warrant to Sponsor that (a) you have not copied or used other works in your Content without permission or right to do so; (b) no person, other than you has any right to, title or interest in any part of your Content; (c) your Content will not infringe or violate any rights of any third party or entity, including, without limitation, trade secret, copyright and trademark, defamation, privacy and publicity, false light, idea misappropriation or any contractual rights; and (d) you have all the rights, licenses, permissions and consents necessary to submit the comment in accordance with these Official Rules. You understand that Sponsor reserves the right to disqualify any Content at any time for any reason at its sole and absolute discretion. BY PARTICIPATING, THE ENTRANT REPRESENTS AND WARRANTS TO SPONSOR THAT THE ENTRANT HAS ALL RIGHT, TITLE AND INTEREST NECESSARY TO GRANT THE SPONSOR THE WORLDWIDE, ABSOLUTE, IRREVOCABLE AND UNRESTRICTED RIGHT AND LICENSE TO ADAPT, PUBLISH, USE, REUSE, EDIT, AND/OR MODIFY THE CONTENT CONTAINED IN THE ENTRY IN ANY WAY AND POST THE CONTENT ON THE INTERNET OR USE THE CONTENT IN ANY OTHER WAY AND AGREES TO INDEMNIFY, DEFEND AND HOLD SPONSOR HARMLESS FROM ANY AND ALL CLAIMS TO THE CONTRARY.
									</li>
								</ol>
								<p>
									<strong>&nbsp;</strong>
								</p>
								<ol>
									<li>
										<strong> SELECTION OF WINNER:</strong> On or about&nbsp;
										<strong>12:00 p.m. EST </strong>on<strong> September 4, 2021</strong>, Sponsor will select one (1) Entry from the Contest Entry Period for the Grand Prize described below in one (1) random Drawing of all valid Entries during the Contest Entry Period. For the avoidance of doubt, there will be a total of one (1) random Drawing for this Contest. Exact timing of the Grand Prize selection will be at the sole and absolute discretion of Sponsor. There is a limit of one (1) Grand Prize per person during the Contest Entry Period. The selected Entrant will be contacted at such Entrant&rsquo;s email address by the Sponsor. The potential Grand Prize winner(s) must follow the Grand Prize claim instructions provided as part of the notification and any subsequent claim instructions provided by Sponsor or the Grand Prize will be forfeited in its entirety. Sponsor&rsquo;s decisions as to the administration and operation of the Contest and the selection of the potential Grand Prize winner is final and binding in all matters related to the Contest. Grand Prize winner(s) will be notified within approximately one (1) business day after the Drawing. Failure to respond to the initial notification within one (1) business day will result in disqualification. Odds of winning the Grand Prize depend on the number of eligible Entries received during the Contest Entry Period.
									</li>
								</ol>
								<p>
									<strong>&nbsp;</strong>
								</p>
								<ol>
									<li>
										<strong>THE POTENTIAL CONTEST GRAND PRIZE WINNER(S) IS/ARE SUBJECT TO VERIFICATION BY SPONSOR WHOSE DECISIONS ARE FINAL AND BINDING IN ALL MATTERS RELATED TO THE CONTEST.</strong>
									</li>
								</ol>
								<p>
									<strong>&nbsp;</strong>
								</p>
								<ol>
									<li>
										<strong> VERIFICATION OF POTENTIAL GRAND PRIZE WINNER(S):</strong>
									</li>
								</ol>
								<p>The potential Grand Prize winner(s) must continue to comply with all terms and conditions of these Official Rules and winning is contingent upon fulfilling all requirements. The potential Grand Prize winner(s) may be required to sign and return to Sponsor, within five (5) days of the date notice is sent, an affidavit of eligibility, and liability/publicity release (except where prohibited) in order to claim the Grand Prize, if applicable. If any potential Grand Prize winner cannot be contacted, fails to sign and return the affidavit of eligibility and liability/publicity release within the required time period (if applicable), or if the Grand Prize or Grand Prize notification is returned as undeliverable, the potential Grand Prize winner forfeits the Grand Prize. Grand Prize winner(s) will be a selection made by Sponsor in its sole discretion.</p>
								<p>&nbsp;</p>
								<ol>
									<li>
										<strong> GRAND PRIZE AND APPROXIMATE RETAIL VALUE:</strong>
									</li>
								</ol>
								<p>
									One (1) Grand Prize will be awarded. The Grand Prize winner will be awarded one (1) set of foursome tickets to the 2022 Golf Classic and one (1) VIP merchandise bag, in the amount of Four Hundred Fifty United States Dollars ($450.00 USD) (collectively, the &ldquo;<u>Grand Prize</u>&rdquo;). There is a limit of one (1) Grand Prize per person during Contest Entry Period. No cash equivalent for any Grand Prize will be awarded. The Grand Prize is non-transferable, and no substitution will be made except as provided herein at the Sponsor&rsquo;s sole and absolute discretion. Sponsor reserves the right to substitute the listed Grand Prize for one (1) of equal or greater value for any reason or no reason whatsoever. The winner is responsible for all fees associated with Grand Prize receipt and/or use by the winner. All federal, state, and local tax liabilities are the sole and absolute responsibility of a winner.
								</p>
								<p>&nbsp;</p>
								<ol>
									<li>
										<strong> ODDS OF WINNING:</strong> Odds of winning the Grand Prize for any given Contest Entry Period depend on the total number of eligible Entries received during the Contest Entry Period.
									</li>
								</ol>
								<p>
									<strong>&nbsp;</strong>
								</p>
								<ol>
									<li>
										<strong> ENTRY CONDITIONS AND RELEASE: </strong>By participating, each Entrant fully and unconditionally agrees to: (a) comply with and be bound by these Official Rules and the decisions of the Sponsor which are binding and final in any and all matters relating to this Contest; (b) release and hold harmless the Sponsor and their respective parent, subsidiary, and affiliated companies, the Grand Prize supplier(s) and any other organizations responsible for sponsoring, fulfilling, administering, advertising or promoting the Contest, and all of their respective past, present and future officers, directors, shareholders, employees, agents, representatives and licensees (collectively, the &ldquo;
										<u>Released Parties</u>&rdquo;) from and against any and all claims, expenses, losses, damages and liability, including but not limited to negligence, damages of any kind to persons, animals and property, including but not limited to invasion of privacy (under appropriation, intrusion, public disclosure of private facts, false light in the public eye or other legal theory), defamation, slander, libel, violation of right of publicity, infringement of trademark, copyright or other intellectual property rights, property damages, or death or personal injury arising out of or relating to a participant&rsquo;s Entry, creation of an Entry or submission of an Entry, participation in the Contest, acceptance or use or misuse of the Grand Prize (including any travel or activity related thereto) and/or the broadcast, exploitation or use of Entry; (c) indemnify, defend and hold harmless the Sponsor from and against any and all claims, expenses, losses, damages and liabilities (including reasonable attorney&rsquo;s fees) arising out of or relating to an Entrant&rsquo;s participation in the Contest and/or Entrant&rsquo;s acceptance, use or misuse of the Grand Prize; and (d) consent as required by applicable law to Sponsor&rsquo;s use of information collected for purposes of operating the Contest and for statistical purposes.
									</li>
								</ol>
								<p>&nbsp;</p>
								<ol>
									<li>
										<strong> GENERAL CONDITIONS: </strong>Sponsor reserves the right to cancel, suspend and/or modify the Contest, or any part of it, if any fraud, technical failures or any other factor beyond Sponsor&rsquo;s reasonable control that impairs the integrity or proper functioning of the Contest, as determined by Sponsor in its sole and absolute discretion. Sponsor reserves the right to cancel the Contest if too few Entries are received. Sponsor reserves the right in its sole and absolute discretion to disqualify any Entrant acting in violation of these Official Rules or found tampering with the Entry process or with the operation of the Contest or any other promotion or in an unsportsmanlike or disruptive manner. Any attempt by any person to deliberately undermine the legitimate operation of the Contest may be a violation of criminal and civil law, and, should such an attempt be made, Sponsor hereby expressly reserves the right to seek damages from any such person to the fullest extent permitted by law. Sponsor&rsquo;s failure to enforce any term of these Official Rules shall not constitute a waiver of that provision. In the event the Contest is terminated early, a winner will be selected from all eligible Entries received at the time of termination. Notice of such action by Sponsor will be posted on the @sportsillustratedtix Instagram page located at: <a href="https://www.instagram.com/sportsillustratedtix/"> https://www.instagram.com/sportsillustratedtix/ </a>.
									</li>
								</ol>
								<p>
									<strong>&nbsp;</strong>
								</p>
								<ol>
									<li>
										<strong> LIMITATIONS OF LIABILITY:</strong> The Released Parties are not responsible for: (a) any incorrect or inaccurate information, whether caused by Entrant(s), printing errors or by any of the equipment or programming associated with or utilized in the Contest; (b) technical failures of any kind, including, but not limited to malfunctions, interruptions, or disconnections in phone lines or network hardware or software; (c) unauthorized human intervention in any part of the Entry process or the Contest; (d) technical or human error which may occur in the administration of the Contest or the processing of Entries; or (e) any injury or damage to persons, animals, or property which may be caused, directly or indirectly, in whole or in part, from Entrant&rsquo;s participation in the Contest or receipt or misuse of any Grand Prize. If for any reason an Entrant&rsquo;s Entry is confirmed to have been erroneously deleted, lost, or otherwise destroyed or corrupted, Entrant&rsquo;s sole remedy is another Entry in the Contest. No more than the stated number of Grand Prize(s) will be awarded.
									</li>
								</ol>
								<p>
									<strong>&nbsp;</strong>
								</p>
								<ol>
									<li>
										<strong> PUBLICITY:</strong> Except where prohibited by law, participation in the Contest constitutes participants consent to Sponsor and its agents&rsquo; use of winners&rsquo; names, images, likeness, photograph, voice, opinions and/or hometown and other indicia of his/her persona for advertising/publicity/trade or promotional purposes in any and all forms of media, worldwide, without notice, review, approval, or further payment or consideration.
									</li>
								</ol>
								<p>&nbsp;</p>
								<ol>
									<li>
										<strong> DISPUTES: </strong>Entrant agrees that: (a) any and all disputes, claims and causes of action arising out of or connected with this Contest, or any prizes (including the Grand Prize) awarded, other than those concerning the administration of the Contest or the determination of winners, shall be resolved individually, without resort to any form of class action; (b) any and all disputes, claims and causes of action arising out of or connected with this Contest, or any prizes (including the Grand Prize) awarded, shall be resolved exclusively by the United States District Court or the appropriate New York State Court located in the New York County, New York; (c) any and all claims, judgments and awards shall be limited to actual out-of-pocket costs incurred, including costs associated with entering this Contest, but in no event attorneys&rsquo; fees; and (d) under no circumstances will Entrant be permitted to obtain awards for, and Entrant hereby waives all rights to claim punitive, incidental and consequential damages and any other damages, other than for actual out-of-pocket expenses, and any and all rights to have damages multiplied or otherwise increased. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATIONS OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE MAY NOT APPLY TO YOU. All issues and questions concerning the construction, validity, interpretation and enforceability of these Official Rules, or the rights and obligations of the Entrant and Sponsor in connection with the Contest, shall be governed by, and construed in accordance with, the laws of the State of New York, without giving effect to any choice of law or conflict of law rules (whether of the State of New York or any other jurisdiction), which would cause the application of the laws of any jurisdiction other than the State of New York.
									</li>
								</ol>
								<p>
									<strong>&nbsp;</strong>
								</p>
								<ol>
									<li>
										<strong> WINNER&rsquo;S LIST:</strong> For an Official Winner&rsquo;s List (available on or after <strong>September 5, 2021</strong>) or a copy of these Official Rules, send a self-addressed, stamped envelope to: &ldquo;
										<strong>SPORTS ILLUSTRATED 2022 GOLF CLASSIC GIVEAWAY</strong>&rdquo; Contest - WINNERS - or - OFFICIAL RULES (please specify), Authentic Brands Group, LLC c/o Digital Innovation Group, 1411 Broadway, 21<sup>st</sup> Floor, New York, NY 10018. All requests must be received by <strong>September 10, 2021</strong>. Official Rules also available at: <a href="https://www.facebook.com/sportsillustrated"> https://www.facebook.com/sportsillustrated </a>. Vermont residents may omit postage.
									</li>
								</ol>
								<p>
									<strong>&nbsp;</strong>
								</p>
								<ol>
									<li>
										<strong> ENTRANT&rsquo;S PERSONAL INFORMATION:</strong> Sponsor may collect non-public personally identifiable information in connection with the operation of the Contest. Information collected on-line from Entrants is subject to the Sponsor&rsquo;s Privacy Policy, which is available at <a href={InternalRoutes.Privacy}>https://sitickets.com/privacy</a>. Sponsor is not responsible for disclosures made by any third party.
									</li>
								</ol>
								<p>&nbsp;</p>
								<ol>
									<li>
										<strong> SPONSOR:</strong> ABG-SI, LLC, c/o Authentic Brands Group, LLC, 1411 Broadway 21<sup>st </sup>Floor, New York, NY 10018.
									</li>
								</ol>
								<p>&nbsp;</p>
							</TermsContent>
							<TermsCloseButton onClick={onClose}>Close</TermsCloseButton>
						</TermsContainer>
					)}
				</TermsModalPopup>
			)}
			<TermsLink onClick={() => setShowModal(true)}>Terms and Conditions</TermsLink>
		</>
	);
};

export const Cell = styled.td`
	border-left: 1px solid #000;
	border-right: 1px solid #000;
	font-size: 12px;
	padding-left: 5px;
`;

export const Row = styled.tr`
	border-top: 1px solid #000;
	border-bottom: 1px solid #000;
`;

export const ResponsiveLandingPageButton = styled(LandingPageButton)`
	@media ${mediaQueries.max500} {
		width: 100%;
	}
`;

export const TermsCloseButton = styled(Button)`
	font-size: 18px;
`;

export const TermsContent = styled.div`
	font-family: Montserrat;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
	margin-bottom: 32px;
	text-align: left;
	width: 95%;
`;

export const TermsTitle = styled.div`
	font-family: Montserrat;
	font-size: 20px;
	text-align: center;
	color: #000000;
	margin-bottom: 25px;
	font-weight: bold;
`;

export const TermsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 530px;
	padding: 20px 20px;
	box-sizing: border-box;
	padding-bottom: 24px;
	a {
		text-decoration: underline;
		color: #0075ff;
	}
`;

export const TermsModalPopup = styled(ModalPopup)`
	@media (max-width: ${mobileScreenWidth}) {
		display: flex;
		justify-content: center;
	}
	img#sitix-modal-header {
		visibility: hidden;
	}
	div#modal-wrapper {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
`;

export const TermsModalLinkContainer = styled.div`
	display: flex;
	& > p {
		font-size: 14px;
		margin-left: 5px;
	}
`;

const MainContent = styled.div`
	${maxContentWidthStyles};
	margin-bottom: 40px;
`;

export const ContentRow = styled.div<{ border?: string }>`
	display: flex;
	margin-top: 60px;
	border: ${props => props.border || "none"};
	@media (max-width: ${mobileScreenWidth}) {
		flex-direction: column;
		&:first-child {
			margin-top: 22px;
		}
	}
`;

export const ContentBox = styled.div<{
	align?: string;
	background?: string;
	backgroundImage?: string;
	padding?: string;
	margin?: string;
	width?: string;
}>`
	width: ${props => props.width || "50%"};
	text-align: ${props => props.align || "left"};
	background: ${props => props.background || "#fff"};
	padding: ${props => props.padding || "0px"};
	margin: ${props => props.margin || "0px"};
	background-image: ${props => (props.backgroundImage ? `url("${props.backgroundImage}")` : "none")};
	background-size: ${props => (props.backgroundImage ? "cover" : "unset")};
	@media (max-width: ${mobileScreenWidth}) {
		width: auto;
		min-height: 140px;
	}
`;

export const ContentBoxFeedback = styled(ContentBox)`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const ContentTitle = styled.h2<{ size?: string; margin?: string }>`
	font-family: SolanoGothicMVB-Bd, sans-serif;
	font-size: 40px;
	line-height: 44px;
	color: #000;
	text-transform: uppercase;
	margin: ${props => props.margin || "0 0 24px"};
	@media (max-width: ${mobileScreenWidth}) {
		font-size: ${props => (props.size === "small" ? "24px" : "30px")};
		line-height: ${props => (props.size === "small" ? "26px" : "33px")};
	}
`;

export const ContentTitleFeedback = styled(ContentTitle)`
	max-width: 420px;
	margin: 16px 0px;
`;

export const ContentText = styled.p<{
	size?: string;
	margin?: string;
	padding?: string;
}>`
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
	color: #000;
	margin: ${props => props.margin || "0px"};
	padding: ${props => props.padding || "0px"};
	font-size: 16px;
	line-height: 1.5;
	${props =>
		props.size !== "small" &&
		`
    @media (min-width: ${mobileScreenWidth}) {
      font-size: 18px;
      line-height: 32px;
    }
  `}
`;

const Separator = styled.hr`
	border: 4px solid #fff;
	margin: auto;
	width: 80px;
	height: 0px;
	background: none;
	margin-bottom: 20px;
`;

export const ItemList = styled.div<{ padding?: string }>`
	div:first-child {
		margin-top: 0px;
	}
	padding: ${props => props.padding || "0px"};
`;

export const ItemListWithIcon = styled.div<{ direction?: string }>`
	display: flex;
	align-items: center;
	flex-direction: ${props => props.direction || "column"};
	margin-top: 20px;
	${props =>
		props.direction === "row" &&
		`
    & > span {
      margin-left: 10px;
    }
  `}
	@media (max-width: ${mobileScreenWidth}) {
		margin-top: 15px;
	}
`;

export const ItemText = styled.span`
	font-family: Montserrat;
	font-weight: 500;
	font-size: 20px;
	line-height: 40px;
	color: #000;
	@media (max-width: ${mobileScreenWidth}) {
		font-size: 18px;
		line-height: 24px;
		margin-top: 10px;
	}
`;

export const ItemTextStrong = styled.span`
	font-family: Montserrat;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #000;
`;

const HeaderContainer = styled.div`
	background-image: url(${golfClassicHero});
	background-position: bottom right;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: #000;
	@media (max-width: ${mobileScreenWidth}) {
		background-image: url(${golfClassicHeroMobile});
		background-position: top right;
	}
`;

export const Header = styled.div`
	${maxContentWidthStyles};
	display: flex;
	align-items: center;
	height: 542px;
	flex-direction: row;
	@media (max-width: ${mobileScreenWidth}) {
		height: auto;
		flex-direction: column-reverse;
	}
`;

export const HeaderTitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	@media (min-width: ${mobileScreenWidth}) {
		width: 50%;
	}
	@media (max-width: ${mobileScreenWidth}) {
		padding: 30px 0px;
	}
`;

const HeaderTitle = styled.h1`
	font-family: ${FontFamily.Solano};
	font-size: 60px;
	line-height: 66px;
	color: #fff;
	text-transform: uppercase;
	text-align: left;
	margin-bottom: 24px;
	@media (max-width: ${mobileScreenWidth}) {
		font-size: 40px;
		line-height: 44px;
	}
`;

const HeaderImage = styled.div`
	width: 50%;
	@media (min-width: ${mobileScreenWidth}) {
		text-align: center;
	}
	@media (max-width: ${mobileScreenWidth}) {
		display: flex;
		height: 300px;
		align-items: flex-end;
		width: 100%;
		& > img {
			width: 100px;
		}
	}
`;

export const SmallInputWrapper = styled.div`
	@media (min-width: ${mobileScreenWidth}) {
		width: 48%;
	}
`;

export const TermsLink = styled.a`
	text-decoration: underline;
	font-weight: 600;
	display: inline;
	cursor: pointer;
	font-family: Montserrat;
	color: #12284c;
	font-size: 13px;
`;

export const Warning = styled.div`
	font-family: Montserrat;
	color: #353535;
	font-size: 13px;
	line-height: 20px;
	text-align: left;
`;

export const ButtonContainer = styled.div`
	margin-top: 15px;
	display: flex;
`;

export const StyledRFFInput = styled(RFFInput)`
	border: 1px solid #e5e5e5;
	border-radius: 3px;
	height: 50px;
	padding-left: 10px !important;
	font-size: 16px;
	-webkit-appearance: none;
	background: #ffffff;
	font-family: Montserrat;
	&:hover {
		box-shadow: none;
	}
`;

export const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	box-sizing: content-box;
`;

export const SmallInputsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	@media (max-width: ${mobileScreenWidth}) {
		flex-direction: column;
	}
`;
