import { Tier } from "../../model/Cart";
import { TransactionFeeRule } from "../../model/optimizedModel/braintree";

export const getTotalTicketPrice = (data: { subtotal: number; transactionFee: number | undefined; serviceFee?: number; deliveryFee?: number }) => {
	return data.subtotal + (data.transactionFee || 0) + (data.serviceFee || 0) + (data.deliveryFee || 0);
};

export const getTransactionFee = (rule?: TransactionFeeRule, ticketPrice?: number, quantity?: number, source?: string | null, tiersInfo?: Tier[]) => {
	if (rule && ticketPrice && quantity) {
		switch (rule.type) {
			case "FLAT_FEE":
				return rule.value;
			case "FLAT_FEE_PER_TICKET":
				if (source === "boxoffice" && tiersInfo) {
					quantity = tiersInfo.reduce((accumulator, tier) => accumulator + tier.quantity, 0);
				}
				return rule.value * quantity;
			default: //DECIMAL case
				if (source === "boxoffice" && tiersInfo) {
					let transactionFee = 0;
					tiersInfo.forEach(tier => {
						transactionFee += rule.value * (tier.retailPrice * tier.quantity);
					});
					return transactionFee;
				}
				return rule.value * (ticketPrice * quantity);
		}
	}
	return 0;
};
