import React from "react";
import styled from "styled-components/macro";

enum FutureType {
	Team = "team",
	Game = "game",
}

export const FutureToggle = (props: { value: FutureType; type: FutureType; text: string; setSelection: (type: FutureType) => void }) => {
	const isSelected = props.type === props.value;
	return (
		<ArrowButton isSelected={isSelected} onClick={() => props.setSelection(props.type)}>
			{props.text}
		</ArrowButton>
	);
};

const ArrowButton = styled.div<{ isSelected: boolean }>`
	border-bottom: 2px solid;
	padding-bottom: 5px;
	color: ${props => (props.isSelected ? "#12284b" : "#726F6F")};
	border-color: ${props => (props.isSelected ? "#12284b" : "transparent")};
	&:not(:last-child) {
		margin-right: 19px;
	}
`;
