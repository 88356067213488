import React from "react";
import styled from "styled-components/macro";
import { AboutUsCompanyItemProps } from "./AboutUsData";
import { useIsMobileWeb } from "../../hooks/useIsMobileWeb";
import { mediaQueries } from "../../util/mediaQueries";

export const AboutUsCompanyItem = (props: AboutUsCompanyItemProps) => {
	const isMobile = useIsMobileWeb();
	return (
		<CompanyItem>
			<CompanyItemContent>
				<Title>{props.title}</Title>
				<Description>{props.description}</Description>
			</CompanyItemContent>
			<CompanyItemImage background={isMobile ? props.imageMobile : props.image} />
		</CompanyItem>
	);
};

const CompanyItem = styled.div`
	border: solid 1px #555;
	display: flex;
	margin-bottom: 16px;
	@media ${mediaQueries.max750} {
		flex-direction: column-reverse;
	}
`;

const CompanyItemContent = styled.div`
	width: 80%;
	padding: 22px 20px;
	text-align: left;
	@media ${mediaQueries.max750} {
		width: auto;
		padding: 15px 10px;
		align-self: center;
	}
`;

const Title = styled.h2`
	font-family: "SolanoGothicMVB-Bd";
	font-size: 30px;
	line-height: 36px;
	font-weight: 700;
	color: #000;
	text-transform: uppercase;
	margin-bottom: 12px;
	@media ${mediaQueries.max750} {
		font-size: 24px;
		line-height: 28px;
	}
`;

const Description = styled.div`
	p {
		font-family: "Montserrat", sans-serif;
		font-size: 16px;
		line-height: 24px;
		font-weight: 500;
		margin-bottom: 10px;
		color: #000;
	}
`;

const CompanyItemImage = styled.div<{ background: string }>`
	background-image: url("${props => props.background}");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	width: 256px;
	@media ${mediaQueries.max750} {
		height: 140px;
		width: 100%;
	}
`;
