import React from "react";
import styled from "styled-components/macro";
import { SkeletonProps } from "../../../model/optimizedModel/skeleton";
import { BaseButton, BaseButtonProps } from "../NewButton/BaseButton";
import { SkeletonWrapper } from "../SkeletonWrapper/SkeletonWrapper";

export const Chip = ({
	selected,
	loading,
	children,
	...restProps
}: Omit<BaseButtonProps, "variant" | "color"> & {
	selected?: boolean;
} & SkeletonProps) => {
	return (
		<SkeletonWrapper loading={loading}>
			<StyledBaseButton {...restProps} variant="outline" color={selected ? "dark" : "light"}>
				{loading ? children || "Loading" : children}
			</StyledBaseButton>
		</SkeletonWrapper>
	);
};

const StyledBaseButton = styled(BaseButton)`
	& > * {
		padding: 7px 8px;
		white-space: nowrap;
	}
`;
