import { DependencyList, EffectCallback, useEffect } from "react";

export const useWindowResizeEffect = (effect: EffectCallback, deps?: DependencyList) => {
	useEffect(() => {
		window.addEventListener("resize", effect);
		return () => {
			window.removeEventListener("resize", effect);
		};
	}, deps);
};
