import { Config } from "../../Config";
import { EventState } from "../../store/reducers/eventReducer";
import { Ticket } from "../../model/Cart";

declare global {
	interface Window {
		PushlySDK: any[];
		func: any;
	}
}

window.PushlySDK = window.PushlySDK || [];

export function pushly(event: string, payload?: any) {
	window.func =
		window.func ||
		function () {
			window.PushlySDK.push(arguments);
		};

	window.func(event, payload);
}

export function postPushlyCart(event: EventState, ticketQuantity: number) {
	pushly("add_to_cart", {
		events: [
			{
				event_id: event.eventData?.id,
				name: event.eventData?.name,
				url: `${window.location.origin}/event/${event.loadedEventSlug}/${event.selectedTicket?.tgID}/${event.selectedTicket?.section?.vfsUrl ? "vfs" : "novfs"}`,
				description: event.eventData?.name,
				quantity: ticketQuantity,
				image: Config.getCDNUrl(event.eventData?.image),
				date_start: event.eventData?.date,
				date_end: event.eventData?.date,
				price: event.selectedTicket?.tgPrice,
				price_currency: "USD",
				location_name: event.eventData?.venueName,
				location_city: event.eventData?.venueCity,
				location_region: event.eventData?.venueState,
				location_country: "US",
				performer_name: event.eventData?.performers[0].name,
			},
		],
	});
}

export function postPushlyPurchase(ticket: Ticket, event: EventState, orderId: string | number, totalPrice: string | number) {
	pushly("purchase", {
		events: [
			{
				event_id: ticket.event.id,
				name: ticket.event?.name,
				url: `${window.location.origin}/event/${event.loadedEventSlug}/${ticket?.ticketGroupId}/${event.selectedTicket?.section?.vfsUrl ? "vfs" : "novfs"}`,
				description: ticket.event?.name,
				quantity: ticket?.quantity,
				image: Config.getCDNUrl(event.eventData?.image),
				date_start: ticket.event.occursAt,
				date_end: ticket.event.occursAt,
				price: ticket.retailPrice,
				price_currency: "USD",
				location_name: ticket.venue.name,
				location_city: ticket.venue.city,
				location_region: ticket.venue.state,
				location_country: ticket.venue.country,
				performer_name: ticket.performers[0].name,
			},
		],
		purchase_id: orderId,
		price_currency: "USD",
		price_value: totalPrice || 0,
	});
}

export function updatePushlyCart(ticket: Ticket) {
	pushly("update_cart", {
		events: [
			{
				id: ticket.event.id,
				quantity: ticket.quantity,
			},
		],
	});
}

export function deletePushlyCart() {
	pushly("update_cart", {
		events: [],
	});
}
