import { ServiceCallAction } from "../../store/middleware/serviceCallMiddleware";
import { BetsResponse, BetsDecoder } from "../types/BetsResponse";
import { updateFeaturedBets, updateFailure } from "../../store/actions/betsActions";
import { ElegiblePlayersResponse, ElegiblePlayersResponseCodec, MBBiggestFavoritesResponseCodec, MBTeamFuturesResponseCodec, EventBetsResponseCodec, EventBetsResponse, BetSubscriptionResponse, BetSubscriptionResponseCodec } from "../types/MetaBetsResponseCodec";
import { MBBiggestFavoritesResponse, MBTeamFuturesResponse } from "../../model/MetaBets";
import * as t from "io-ts";

export const getFeaturedBets = (): ServiceCallAction<BetsResponse> => ({
	method: "GET",
	endpoint: `bets`,
	serviceKey: "getFeaturedBets",
	responseDecoder: BetsDecoder,
	type: "SERVICE_CALL",
	onSuccess: updateFeaturedBets,
	onFailure: updateFailure,
});

export const getBiggestFavoritesBets = (leagueCode: string): ServiceCallAction<MBBiggestFavoritesResponse> => ({
	method: "GET",
	endpoint: `bets/favorite-games/${leagueCode}`,
	serviceKey: "getBiggestFavorites",
	responseDecoder: MBBiggestFavoritesResponseCodec,
	type: "SERVICE_CALL",
});

export const getFutureBets = (leagueCode: string): ServiceCallAction<MBTeamFuturesResponse> => ({
	method: "GET",
	endpoint: `bets/team-futures/${leagueCode}`,
	serviceKey: "getSideOdds",
	responseDecoder: MBTeamFuturesResponseCodec,
	type: "SERVICE_CALL",
});

export const getEligiblePlayersBets = (sport: string): ServiceCallAction<ElegiblePlayersResponse> => ({
	method: "GET",
	endpoint: `bets/player-props/${sport.toLowerCase()}`,
	serviceKey: "getPlayerFutures",
	responseDecoder: ElegiblePlayersResponseCodec,
	type: "SERVICE_CALL",
});

const GetEventBetsResponseDecoder = t.union([EventBetsResponseCodec, t.array(t.any)]);

type GetEventBetsResponse = t.TypeOf<typeof GetEventBetsResponseDecoder>

export const getEventBets = (eventId: number): ServiceCallAction<GetEventBetsResponse> => ({
	method: "GET",
	endpoint: `bets/event/${eventId}`,
	serviceKey: "getEventBets",
	responseDecoder: GetEventBetsResponseDecoder,
	type: "SERVICE_CALL",
});

export const getEventBetsBySlug = (slug: string): ServiceCallAction<GetEventBetsResponse> => ({
	method: "GET",
	endpoint: `bets/event/${slug}`,
	serviceKey: "getEventBets",
	responseDecoder: GetEventBetsResponseDecoder,
	type: "SERVICE_CALL",
});

export const submitSubscription = (id: number, type: string, email: string): ServiceCallAction<BetSubscriptionResponse> => ({
	method: "POST",
	body: {
		id,
		type,
		email,
	},
	endpoint: `bets/subscription`,
	serviceKey: "getEventBets",
	responseDecoder: BetSubscriptionResponseCodec,
	type: "SERVICE_CALL",
});
