import React, { useState } from "react";
import { useCart } from "../../../services/hooks/useCart";
import { CartFuture } from "./Items/CartFuture";
import { CartTicket } from "./Items/CartTicket";
import { InlineStylesProps } from "../../../model/optimizedModel/styles";
import { DeleteConfirmationPopUp } from "../../components/Cart/DeleteConfirmationPopup";
import { deleteCartItem } from "../../../services/cart";
import styled from "styled-components/macro";
import { useCheckout } from "../../../services/hooks/useCheckout";
import { useAutoUpdateState } from "../../hooks/useAutoUpdateState";
import { Invoice, InvoiceComponentItem } from "../Invoices/Invoice";
import { formatPrice } from "../../util/formatPrice";

export interface BaseCartItemsProps {
	hideDelete?: boolean;
	expandedByDefault?: boolean;
	additionalTicketInvoiceItems?: InvoiceComponentItem[];
	additionalFutureInvoiceItems?: InvoiceComponentItem[];
	splitFeesSection?: boolean;
	hideInvoice?: boolean;
}

export const CartItems = ({ style, additionalTicketInvoiceItems, additionalFutureInvoiceItems, splitFeesSection, ...itemProps }: BaseCartItemsProps & InlineStylesProps) => {
	const { cart, ticket, cartFuturesKeys, flatRatePrice } = useCart();

	const checkoutState = useCheckout();

	//TODO: Consider finding a way to avoid using an array for params but still keep the dynamic/spread functionality that this state implementation has.
	const [targetedItemToDeleteArgs, setTargetedItemToDeleteArgs] = useState<Parameters<typeof deleteCartItem> | null>();

	const closeDeleteConfirmationPopup = () => {
		setTargetedItemToDeleteArgs(null);
	};

	const alwaysDisplayFees = useAutoUpdateState(() => flatRatePrice || !!cartFuturesKeys?.length, [cartFuturesKeys?.length, flatRatePrice]);
	return (
		<>
			{targetedItemToDeleteArgs && (
				<DeleteConfirmationPopUp
					onDidClose={() => {
						closeDeleteConfirmationPopup();
					}}
					onConfirmation={() => {
						deleteCartItem(...targetedItemToDeleteArgs).then(() => {
							closeDeleteConfirmationPopup();
						});
					}}
				/>
			)}
			<Items style={style}>
				{cart && (
					<>
						{ticket && (
							//TODO: Spread the ticket object when backend object schema is updated.
							<CartTicket
								tiersInfo={ticket.tiers}
								serviceFee={cart.serviceFee}
								eventId={ticket.event.id}
								quantity={ticket.quantity}
								transactionFeeRule={(alwaysDisplayFees || !!checkoutState.selectedPaymentMethodOption || checkoutState.walletFundsUsed) && !splitFeesSection ? ticket?.transactionFeeRules[checkoutState.walletFundsUsed ? "PromoWallet" : checkoutState.selectedPaymentMethodOption?.type || "CreditCard"] : undefined}
								deliveryFee={(alwaysDisplayFees || checkoutState.activeStep > 1) && !splitFeesSection ? cart.shippingFee : undefined}
								tax={!cartFuturesKeys?.length ? checkoutState.totalTax : undefined}
								serviceFeeRate={cart.serviceFeeRate && !splitFeesSection ? cart.serviceFeeRate : undefined}
								price={ticket.retailPrice}
								section={ticket.section}
								row={ticket.row}
								notes={ticket.notes}
								name={ticket.event.name}
								occursAt={ticket.event.occursAt}
								endDate={ticket.event.endDate}
								venue={{
									name: ticket.venue.name,
									address: {
										city: ticket.venue.city,
										country: ticket.venue.country,
										state: ticket.venue.state,
									},
								}}
								deliveryMethodTitle={ticket.delivery.method}
								onDeletePressed={() => {
									setTargetedItemToDeleteArgs([
										ticket.cartItemId?.toString(),
										{
											isTicket: true,
										},
									]);
								}}
								{...itemProps}
								expectedShipDate={ticket.expectedShipDate}
								additionalTicketInvoiceItems={additionalTicketInvoiceItems}
							/>
						)}
						{cartFuturesKeys?.reduce((result, key, index) => {
							//TODO: This cartFuture logic is here in order to prevent errors when the user removes a future from the cart. It seems like cartFuturesKeys is not updated correctly. Consider spending more time investigating why cartFuturesKeys is not properly updated in useCart.
							const cartFuture = cart.futures[key];
							if (cartFuture) {
								const { cartItemId, ...future } = cartFuture;

								const futureInventoryId = key;
								result.push(
									<React.Fragment key={index}>
										{future && (
											<CartFuture
												futureInventoryId={futureInventoryId}
												additionalFutureInvoiceItems={index === cartFuturesKeys.length - 1 ? additionalFutureInvoiceItems : undefined}
												taxAmount={!ticket && !splitFeesSection ? checkoutState.totalTax : undefined}
												onDeletePressed={() => {
													setTargetedItemToDeleteArgs([
														cartItemId?.toString(),
														{
															futureInventoryId,
														},
													]);
												}}
												{...future}
												{...itemProps}
											/>
										)}
									</React.Fragment>
								);
							}
							return result;
						}, [] as React.ReactNode[])}
						{!itemProps.hideInvoice && splitFeesSection && (
							<Invoice
								css={`
									margin-bottom: 14px;
								`}
								items={(() => {
									let ticketPrice = 0;
									if (cart?.source === "boxoffice") {
										cart?.tickets[0]?.tiers.forEach(tier => {
											ticketPrice += tier.retailPrice * tier.quantity;
										});
									} else {
										ticketPrice = !!ticket ? ticket.retailPrice * ticket.quantity : 0;
									}

									const reservationsPrice = Object.values(cart.futures).reduce((sum, current) => {
										return sum + current.quantity * current.zone.reservationPrice;
									}, 0);

									const arr: InvoiceComponentItem[] = [
										{
											key: "subtotal",
											value: formatPrice(checkoutState.discount && checkoutState.discount.type === "FROM_TOTAL" ? checkoutState.discount.newPrice : ticketPrice + reservationsPrice),
											title: "Subtotal",
										},
									];

									if (alwaysDisplayFees || !!checkoutState.selectedPaymentMethodOption || checkoutState.walletFundsUsed) {
										const transactionFeeValue = ticket?.transactionFeeRules[checkoutState.walletFundsUsed ? "PromoWallet" : checkoutState.selectedPaymentMethodOption?.type || "CreditCard"].value;
										if (transactionFeeValue !== undefined && transactionFeeValue > 0) {
											arr.push({
												key: "transactionFee",
												value: formatPrice(ticket?.transactionFeeRules[checkoutState.walletFundsUsed ? "PromoWallet" : checkoutState.selectedPaymentMethodOption?.type || "CreditCard"].value),
												title: "Transaction Fee",
											});
										}
									}
									if (!!cart.serviceFee) {
										arr.push({
											key: "serviceFee",
											value: formatPrice(cart.serviceFee),
											title: `CC Service Fee`,
										});
									}
									if (!!cart.serviceFeeRate && !cart.serviceFee) {
										arr.push({
											key: "serviceFee",
											value: formatPrice(cart.serviceFeeRate),
											title: "Service Fee",
										});
									}
									if (!!cart.shippingFee && alwaysDisplayFees) {
										arr.push({
											key: "deliveryFee",
											title: "Delivery Fee",
											value: formatPrice(cart.shippingFee),
										});
									}
									if (!!cart.tax || !!checkoutState.totalTax) {
										arr.push({
											key: "tax",
											value: formatPrice(cart.tax || checkoutState.totalTax),
											title: "Tax",
										});
									}
									if (checkoutState?.walletFundsUsed) {
										arr.push({
											key: "promotionalWalletFunds",
											value: formatPrice(checkoutState.walletFundsUsed),
											title: "Wallet Credit Applied",
											borderTop: true,
										});
									}
									return arr;
								})()}
							/>
						)}
					</>
				)}
			</Items>
		</>
	);
};

const Items = styled.div`
	& > *:not(:last-child) {
		margin-bottom: 14px;
	}
`;
