import React from "react";
import { useHeaderHeight } from "../../hooks/useHeaderHeight";
import { isMobileApp } from "../../util/isMobileApp";

export const HeaderHashAnchor = (props: { id?: string }) => {
	const headerHeight = useHeaderHeight();

	return (
		<div
			id={props.id}
			style={
				isMobileApp
					? undefined
					: {
							height: headerHeight,
							marginTop: -headerHeight,
					  }
			}
		/>
	);
};
