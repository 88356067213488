import { Config } from "../../Config";

/**
 * Embedding isMobileApp variable directly into styled-components literals
 * prevents us from override it for testing purposes.
 * forceMobile() was updated to store the variable in the local storage
 * and update the page applying correct styles.
 * To exit mobile mode use forceMobile(false).
 */

const forceMobile = window.localStorage.getItem("force_mobile") === "true";

let isMobileApp = Config.getPlatformOS() !== "web" || forceMobile;

(window as any).forceMobile = (enabled: boolean = true) => {
	window.localStorage.setItem("force_mobile", enabled.toString());
	window.location.reload();
};
if (forceMobile) {
	console.log("%cMobile mode is enabled", "color: yellow; font-size:18px;");
	console.log("%cYou can disable it using window.forceMobile(false)", "color:yellow");
}

export { isMobileApp };
