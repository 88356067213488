import { apiClient } from "./apiClient";
import { APIClientResponse } from "./types/APIClientResponse";

export function postRedeemWalletPromo(email: string, promoCode: string, referrerUrl: string, uId?: string | null): APIClientResponse<{ message: string }> {
	return apiClient.post("promo/redeem", {
		email,
		promoCode,
		referrerUrl,
		uId
	});
}
