import React from "react";
import styled from "styled-components/macro";
import { SkeletonProps } from "../../../model/optimizedModel/skeleton";
import { getAppColor } from "../../util/appColors";
import { BaseButton } from "../NewButton/BaseButton";
import { primaryBorderHeight, primaryBorderValue } from "../PrimaryBorder/PrimaryBorder";
import { SkeletonWrapper } from "../SkeletonWrapper/SkeletonWrapper";

export const Tabs = (
	props: {
		onTabSelect?: (slug: string, index: number) => void;
		selectedSlug?: string;
	} & SkeletonProps<{
		tabs: {
			name: string;
			//TODO: Consider renaming to "key".
			slug: string;
		}[];
	}>
) => {
	return (
		<SkeletonWrapper loading={props.loading}>
			<TabsRow>
				{props.loading ? (
					<TabButton>Loading</TabButton>
				) : (
					props.tabs.map((tab, index) => {
						const selected = tab.slug === props.selectedSlug;

						return (
							<TabButton
								key={index}
								onClick={() => {
									if (props.onTabSelect) {
										props.onTabSelect(tab.slug, index);
									}
								}}
								color={selected ? "dark" : "light"}
								style={
									selected
										? {
												borderBottom: primaryBorderValue,
												marginBottom: `-${primaryBorderHeight}`,
										  }
										: undefined
								}
							>
								{tab.name}
							</TabButton>
						);
					})
				)}
			</TabsRow>
		</SkeletonWrapper>
	);
};

const TabsRow = styled.div`
	display: flex;
	overflow-x: scroll;
	padding-bottom: ${primaryBorderHeight};
	& {
		scrollbar-width: none;
		-ms-overflow-style: none;
	}
	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
`;

const defaultTabButtonBorderBottomWidth = "1px";

const TabButton = styled(BaseButton)`
	flex: 1;
	margin-bottom: -${defaultTabButtonBorderBottomWidth};
	& > * {
		padding: 18px 13px;
		border-bottom: ${defaultTabButtonBorderBottomWidth} solid ${getAppColor("darkGrey", "dark")};
	}
`;
