import React, { useState } from "react";
import styled from "styled-components/macro";
import { CommonPage } from "../components";
import { isMobileApp } from "../util/isMobileApp";
import { MetaDecorator, MetaTagData } from "../components/SEOMetadecorator/MetaDecorator";
import { MainSearch } from "../components/Search/MainSearch";
import { useIsMobileWeb } from "../hooks/useIsMobileWeb";
import { SearchState } from "../components/Search/SearchState";
import { useHeaderHeight } from "../hooks/useHeaderHeight";

const styledMainSearchTopMargin = 20;

export const SearchRoute = () => {
	const [searchState, setSearchState] = useState(SearchState.None);
	const isMobile = useIsMobileWeb() || isMobileApp;

	const headerHeight = useHeaderHeight();

	return (
		<CommonPage isFooterHidden={true}>
			<MetaDecorator title={MetaTagData.Search.title} description={MetaTagData.Search.description} />
			<ContentContainer>
				<SearchRouteContainer>
					<SearchBarContainer>
						{isMobileApp && <BlackBackground />}
						<StyledMainSearch
							style={{
								maxHeight: "calc(100vh - " + (headerHeight + styledMainSearchTopMargin) + "px)",
							}}
							mobile={isMobile}
							fullWidthResults={true}
							placeholder="Artist, team, event, etc."
							allowCustomLocations={false}
							searchState={searchState}
							setSearchState={setSearchState}
						/>
					</SearchBarContainer>
				</SearchRouteContainer>
			</ContentContainer>
		</CommonPage>
	);
};

const BlackBackground = styled.div`
	background-color: #091c36;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 90px;
`;

const ContentContainer = styled.div`
	height: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const SearchRouteContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	overflow-y: auto;
`;

const SearchBarContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
`;

const StyledMainSearch = styled(MainSearch)<{
	mobile?: boolean;
}>`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: ${styledMainSearchTopMargin}px;
	z-index: 1;

	/*Searchbar*/
	& > div:first-child {
		width: 75vw;
		${isMobileApp && "margin-left: 35px;"}
	}
`;
