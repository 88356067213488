/**
 * Convert splits to a readable format
 * @param splits Event or Future splits
 */
export const getTicketQuantity = (splits: number[]) => {
	let humanReadable = "";
	let from: number | undefined;

	if (splits.length === 0) return "0 tickets";

	splits.reduce((prev: number, curr: number, index: number, array: number[]) => {
		from = from || prev;

		//intermediate quantities
		if (from && curr !== prev + 1) {
			humanReadable = humanReadable && humanReadable + ", ";
			humanReadable += from && from === prev ? from : `${from} - ${prev}`;
			from = undefined;
		}

		//last quantity
		if (array.length === index + 1) {
			humanReadable = humanReadable && humanReadable + " or ";
			humanReadable += from && from !== curr ? `${from} - ${curr} ` : `${curr} `;
		}
		return curr;
	}, 0);

	return humanReadable + "Tickets";
};
