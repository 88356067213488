import React, { useState } from "react";
import styled from "styled-components/macro";
import { RouteComponentProps } from "react-router-dom";
import { useDataService } from "../hooks/useServiceCall";
import { getTeamFutureListing } from "../../services/actions/futuresService";
import { useHistory } from "react-router-dom";
import { Zone } from "../../services/types/FuturesResponse";
import { Config } from "../../Config";
import { useDispatch } from "react-redux";
import { useServiceMonitor } from "../hooks";
import { postCartFuture } from "../../services/cart";
import { setCart } from "../../store/reducers/cartReducer";
import { resetCheckoutState } from "../../store/actions/checkoutActions";
import { useCart } from "../../services/hooks";
import { ListingsPage } from "../components/ListingsPage/ListingsPage";
import { maxContentWidthPadding } from "../util/maxContentWidth";
import { InvoiceItem } from "../components/Invoices/InvoiceItem";
import { shortFormatPrice } from "../util/formatPrice";
import { resetLoadingScreenData, setLoadingScreenData } from "../../store/actions/loadingScreenDataActions";
import { InternalRoutes } from "../Link";
import { getTicketQuantity } from "../util/formatQuantity";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { MetaDecorator, getFutureEventTagTitle, getFutureEventTagDescription } from "../components/SEOMetadecorator/MetaDecorator";
import { Button } from "../components/Button/Button";
import { listingsPageMobileWidth } from "../util/listingsPageMobileWidth";
import { FutureInvoice } from "../components/Invoices/FutureInvoice";
import { PayPalPayLaterMessage } from "../components/PayPalPayLaterMessage/PayPalPayLaterMessage";
import si_tickets_logo from "../resource/assets/si_tickets_logo_unstacked_cropped.svg";

import { verticalMarginObject, verticalPaddingObject } from "../util/optimized/styles";
import { FuturesTermsModalLink } from "../components/Futures/FuturesTermsModalLink";
import { FuturesTermsModal } from "../components/Futures/FuturesTermsModal";
import { useAutoUpdateState } from "../hooks/useAutoUpdateState";
import { hotelFuturesEventSlug } from "../util/hotelFutures";
import { numberToWord } from "../util/general";
import { getAppColor } from "../util/appColors";
import { FutureTotalInvoiceItem } from "../components/Invoices/FutureTotalInvoiceItem";

const getMaximumZoneQuantity = (zone: Zone) => {
	const value = Math.min(zone.availableQuantity, zone.maxQuantity);
	return value;
};

const getZoneQuantitySubtitle = (zone: Zone) => {
	const maximumZoneQuantity = getMaximumZoneQuantity(zone);
	const quantityOptions = zone.incrementQuantity.filter(quantity => {
		return quantity <= maximumZoneQuantity;
	});
	let value = getTicketQuantity(quantityOptions);
	if (quantityOptions[quantityOptions.length - 1] !== maximumZoneQuantity) {
		value += " (Max: " + maximumZoneQuantity + ")";
	}
	return value;
};

const getZoneImageUrl = (path: string | null | undefined) => {
	let value = null;
	if (path) {
		value = Config.getResourceUrl(path);
	}
	return value;
};

export const TeamFutureSelected = (
	props: RouteComponentProps<{
		team: string;
		event: string;
	}>
) => {
	const dispatch = useDispatch();

	const history = useHistory();

	//TODO: Consider re-writing this.
	const loading = useServiceMonitor(["getTeam", "getFutureListing", "addFutureToCart"]);

	const [selectedZone, setSelectedZone] = useState<Zone | undefined>();
	const [highlightedZone, setHighlightedZone] = useState<Zone | undefined>();
	const [showInfoModal, setShowInfoModal] = useState<boolean>();
	const [addingToCart, setAddingToCart] = useState(false);

	const { team, event } = props.match.params;

	const { result: futures } = useDataService(getTeamFutureListing, [team, event], {});

	//TODO: Consider integrating with some kind of a hook here.
	const eventData = futures?.data[0];

	const { cart } = useCart();

	const isMobile = useMediaQuery("(max-width: " + listingsPageMobileWidth + ")");
	const [showEducational, setShowEducational] = useState<boolean>(false);

	//TODO: Consider integrating with hooks here.
	const getDefaultZoneImage = () => {
		if (eventData && eventData.zones && eventData.zones.length > 0) {
			const [firstZone] = eventData.zones;
			return firstZone.zoneImage;
		}
		return undefined;
	};

	//TODO: Consider integrating with hooks here.
	const zoneImage = highlightedZone?.zoneImage || selectedZone?.zoneImage || getDefaultZoneImage();
	const zoneImageSrc = getZoneImageUrl(zoneImage);

	const [futureInvoiceFinalReservationPrice, setFutureInvoiceFinalReservationPrice] = useState<number>();

	const pageIsForHotelReservations = useAutoUpdateState(() => event === hotelFuturesEventSlug, [event]);

	return (
		<>
			<MetaDecorator title={getFutureEventTagTitle(eventData?.name, eventData?.venueLocation)} description={getFutureEventTagDescription(eventData?.name)} />
			{showInfoModal && (
				<FuturesTermsModal
					onDidClose={() => {
						setShowInfoModal(false);
					}}
					displayHotelTerms={pageIsForHotelReservations}
				/>
			)}
			<ListingsPage<Zone>
				onPageInfoClick={() => {
					setShowInfoModal(true);
				}}
				map={!isMobile && <ZoneImageMap src={zoneImageSrc} />}
				items={eventData?.zones.map(zone => {
					//TODO: Instead of this current approach, give a Item component to be integrated with in the callback.
					return {
						title: zone.zoneTitle,
						price: zone.tierPrice,
						priceSubtitle: "",
						subtitle: getZoneQuantitySubtitle(zone),
						data: zone,
						defaultQuantity: zone.incrementQuantity[0],
						additonalItemContent: zone.inventoryStatusId === 2 && (
							<ResaleContainer>
								<ResaleValue>Resale</ResaleValue>
							</ResaleContainer>
						),
					};
				})}
				topPanelContent={
					<TopPanelContent>
						<Steps>
							{[`Select the ${pageIsForHotelReservations ? "Room Type" : "zone"} you'd like to ${pageIsForHotelReservations ? "Reserve" : "sit in"} below.`, `Only Pay the Non-Refundable ${pageIsForHotelReservations ? "" : "Reservation"} Fee Today.`, "You Can Resell Your Reservations All Year Long.", `When Your Team Makes The Game, Pay The ${pageIsForHotelReservations ? "Room" : "Ticket"} Price You Lock In Today.`].map((step, index) => {
								//TODO: Consider adding logic to reuse the "Step" component in the Futures landing page route.
								return (
									<Step key={index}>
										<StepNumber>{index + 1}.</StepNumber>
										<StepContent>{step}</StepContent>
									</Step>
								);
							})}
							{!pageIsForHotelReservations && (
								<LearnMoreButton
									onClick={() => {
										setShowEducational(true);
									}}
								>
									Learn more
								</LearnMoreButton>
							)}
						</Steps>
					</TopPanelContent>
				}
				onItemMouseOver={item => {
					setHighlightedZone(item.data);
				}}
				onItemClick={item => {
					setSelectedZone(item.data);
				}}
				loading={loading || addingToCart}
				showEducational={showEducational}
				onHideEducationalClick={() => {
					setShowEducational(false);
				}}
				newSeatsProps={
					eventData && {
						eventTitle: eventData.name,
						eventDate: eventData.date,
						customEventDateString: pageIsForHotelReservations ? "Two Night Stay including January 9 & January 10, 2022" : undefined,
						//TODO: Add better string handling logic since the ListingsPage component should already take care of this.
						location: eventData.venueLocation || "",
						venue: eventData.venueName || "",
						teamTitle: eventData?.teamTitle,
						humanReadableDateOptions: {
							hideTime: true,
						},
					}
				}
				hideTopMobileMessage
				unpinTopPanelContent={["mobile"]}
				onAddToCartClick={(zone, quantity) => {
					if (quantity > 0) {
						dispatch(
							setLoadingScreenData({
								text: "Adding your ticket reservations to the cart.",
							})
						);
						setAddingToCart(true);
						postCartFuture(zone.tiersId, quantity, cart?.sessionId)
							.then(result => {
								//TODO: Consider checking if resetCheckoutState is needed.
								dispatch(resetCheckoutState);
								dispatch(setCart(result.data.cart));
								history.push(InternalRoutes.Cart);
							})
							.catch(error => {
								//TODO: Consider adding toast.error.
								console.error(error);
							})
							.finally(() => {
								setAddingToCart(false);
								dispatch(resetLoadingScreenData());
							});
					}
				}}
				selectedItemLayoutData={(zone, selectedQuantity) => {
					const amountInCart = cart?.futures[zone.tiersId]?.quantity || 0;
					const maximumQuantity = getMaximumZoneQuantity(zone);
					const quantityOptions = zone.incrementQuantity.filter(quantity => {
						return amountInCart + quantity <= maximumQuantity;
					});
					const maximumAmountInCart = !quantityOptions.length;
					return {
						topMobileContent: <SelectedZoneImage src={getZoneImageUrl(zone.zoneImage)} />,
						title: zone.zoneTitle,
						subtitle: getZoneQuantitySubtitle(zone),
						price: zone.tierPrice,
						quantityOptions,
						bottomContent: AddToCartButton => {
							const savings = (zone.secondaryPrice || 0) * selectedQuantity - ((zone.ticketCost || 0) + zone.tierPrice) * selectedQuantity;
							const showSavings = savings > 0;
							return (
								<>
									<StyledFutureInvoice
										quantity={selectedQuantity}
										reservationPrice={zone.tierPrice}
										ticketPrice={zone.ticketCost || 0}
										onUpdate={({ finalReservationPrice }) => {
											setFutureInvoiceFinalReservationPrice(finalReservationPrice);
										}}
										override={{
											reservationPrice: {
												title: (
													<>
														You're reserving the {pageIsForHotelReservations ? "option" : "right"} to
														{pageIsForHotelReservations ? (
															` buy ${numberToWord(selectedQuantity)} (${selectedQuantity}) `
														) : (
															<>
																{" "}
																{selectedQuantity}
																<br />
															</>
														)}
														{pageIsForHotelReservations ? "hotel room" : "ticket"}
														{selectedQuantity > 1 ? "s" : ""}
													</>
												),
												bottomContent: (
													<ReservationTermsUl>
														<li>Reservation Fee paid today</li>
														<li>Reservation Fee is non-refundable</li>
													</ReservationTermsUl>
												),
											},
											...(pageIsForHotelReservations && {
												ticketPrice: {
													title: <strong>Hotel Price</strong>,
												},
											}),
										}}
									/>
									<FutureTotalInvoiceItem total={futureInvoiceFinalReservationPrice || 0} css={verticalMarginObject(-maxContentWidthPadding)} />
									{showSavings && (
										<SavingsInvoiceItem
											title={
												<>
													<SiTicketsLogo src={si_tickets_logo} alt="SI Tickets Logo" />
													<SavingsInvoiceItemTitleContent>estimated savings</SavingsInvoiceItemTitleContent>
												</>
											}
											subtitle="vs buying the week of the game"
											value={<SavingsInvoiceItemValue>{shortFormatPrice(savings)}</SavingsInvoiceItemValue>}
											borderBottom
											style={{
												...verticalMarginObject(-maxContentWidthPadding),
												...verticalPaddingObject(maxContentWidthPadding),
											}}
										/>
									)}
									<AddToCartButton
										disabled={addingToCart || maximumAmountInCart}
										//Note: This component (AddToCartButton) can not be styled with styled components because it's passed as an argument in this function. This is why inline styles are used here instead.
										style={{
											marginTop: 17,
											marginBottom: 9,
										}}
									/>
									{maximumAmountInCart ? <MaximumAmountError>You've Reached The Maximum Quantity For This Zone In Your Cart.</MaximumAmountError> : <PayPalPayLaterMessage price={futureInvoiceFinalReservationPrice || 0} />}
									<StyledFuturesTermsModalLink displayHotelTerms={pageIsForHotelReservations} />
								</>
							);
						},
					};
				}}
			/>
		</>
	);
};

const SavingsInvoiceItemValue = styled.span`
	font-weight: 600;
`;

const SavingsInvoiceItemTitleContent = styled.span`
	font-weight: 600;
`;

const StyledFuturesTermsModalLink = styled(FuturesTermsModalLink)`
	margin: 0 auto;
	margin-top: 11px;
	text-align: center;
`;

const StyledFutureInvoice = styled(FutureInvoice)`
	margin-bottom: 17px;
`;

const SiTicketsLogo = styled.img`
	//TODO: Consider finding a more dynamic way to set the height of this image.
	height: 14px;
	margin-right: 6px;
`;

const LearnMoreButton = styled(Button)`
	width: 100%;
	background-color: #e63422;
	margin-top: 11px;
	border-radius: 0;
	font-size: 18px;
`;

const Steps = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	background: black;
`;

const Step = styled.div`
	display: flex;
	align-items: center;
	padding: 11px ${maxContentWidthPadding}px;
	line-height: normal;
	&:not(:last-child) {
		padding-bottom: 0;
	}
`;

const stepNumberFontSize = 21;

//TODO: Consider finding a better way to make each step number an equal width.
const StepNumber = styled.span`
	color: #e92224;
	font-size: ${stepNumberFontSize}px;
	margin-top: -3px;
	align-self: flex-start;
	font-family: SolanoGothicMVB-Bd;
	min-width: ${0.5667 * stepNumberFontSize}px;
`;

const StepContent = styled.span`
	color: white;
	font-size: 16px;
	font-weight: 600;
	margin-left: 11px;
`;

const MaximumAmountError = styled.span`
	text-align: center;
	font-size: 14px;
	font-weight: 600;
	color: #e92224;
	margin-top: 7px;
`;

const ReservationTermsUl = styled.ul`
	padding-left: ${maxContentWidthPadding + 16}px;
	margin-bottom: 12px;
	font-size: 13px;
	margin-top: -7px;
	li:not(:last-child) {
		margin-bottom: 3px;
	}
`;

const SavingsInvoiceItem = styled(InvoiceItem)`
	padding-top: 10px;
`;

const TopPanelContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	text-align: left;
	width: 100%;
`;

const ZoneImage = styled.div<{ src?: string | null }>`
	background-image: url("${props => props.src}");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
`;

const SelectedZoneImage = styled(ZoneImage)`
	height: 200px;
	border-bottom: 1px solid lightgray;
`;

const ZoneImageMap = styled(ZoneImage)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

const ResaleContainer = styled.div`
	display: flex;
	align-items: center;
`;

const ResaleValue = styled.div`
	background-color: ${getAppColor("purple", "subtle")};
	color: ${getAppColor("purple", "main")};
	padding: 8px 10px;
	font-family: SolanoGothicMVB-Bd, sans-serif;
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
	margin-right: 10px;
`;
