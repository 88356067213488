import { useEffect } from "react";
import { OnInvoiceUpdate } from "../../../../model/optimizedModel/invoice";

//TODO: Consider typing output.
export const useInvoiceOutputUpdate = (output: any, onUpdate: OnInvoiceUpdate | undefined) => {
	useEffect(() => {
		if (onUpdate) {
			onUpdate(output);
		}
	}, [output, onUpdate]);
};
