import React, { useEffect, useState } from "react";
import { FuturesOrder } from "../../../../model/optimizedModel/myAccount";
import { MyAccountFuturesPage } from "./MyAccountFuturesPage";
import { getMyAccountFuturesOrders } from "../../../../services/myAccount";
import { MyAccountItemsList } from "../MyAccountItemsList";
import { MyAccountFutureOrder } from "../Optimized/MyAccountFutureOrder";
import toast from "react-hot-toast";
import { useToastRemoval } from "../../../hooks/useToastRemoval";

export const FuturesOrders = () => {
	const [futures, setFutures] = useState<FuturesOrder[]>();
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		getMyAccountFuturesOrders()
			.then(result => {
				setFutures(result.data);
			})
			.catch(error => {
				if (typeof error === "string") {
					toast.error(error);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	useToastRemoval();

	return (
		<MyAccountFuturesPage mobileTitle="Postseason Reservations">
			<MyAccountItemsList<FuturesOrder>
				items={futures}
				loading={loading}
				itemsName="reservations orders"
				renderItem={props => {
					return <MyAccountFutureOrder {...props} />;
				}}
			/>
		</MyAccountFuturesPage>
	);
};
