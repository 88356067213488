import { useState, useEffect } from "react";
import { getRemoteIp } from "../location";

export const useRemoteIp = () => {
	const [remoteIp, setRemoteIp] = useState("");

	useEffect(() => {
		const fetchIp = async () => {
			try {
				const res = await getRemoteIp();
				setRemoteIp(res.data.remoteIp);
			} catch (error) {
				console.error(error);
			}
		};
		fetchIp();
	}, []);

	return remoteIp;
};
