import React from "react";
import styled from "styled-components/macro";
import aboutUsSitix from "../../resource/image/about-us-sistix.svg";
import aboutUsTrophy from "../../resource/image/about-us-trophy.svg";
import aboutUsSitixMobile from "../../resource/image/about-us-sistix-mobile.svg";
import aboutUsTrophyMobile from "../../resource/image/about-us-trophy-mobile.svg";
import { mediaQueries } from "../../util/mediaQueries";
import { InternalRoutes } from "../../Link";
import { ButtonLink } from "../Button/ButtonLink";

const CompanyItemButton = styled(ButtonLink)`
	margin-top: 14px;
	width: 200px;
	@media ${mediaQueries.max750} {
		width: 100%;
	}
`;

export interface AboutUsCompanyItemProps {
	image: string;
	imageMobile: string;
	title: string;
	description: React.ReactNode;
}

export const AboutUsCompanyData: AboutUsCompanyItemProps[] = [
	{
		image: aboutUsSitix,
		imageMobile: aboutUsSitixMobile,
		title: "SI Tickets is a fan-first ticketing marketplace by Sports Illustrated, one of the most iconic and trusted names in sports.",
		description: (
			<>
				<p>SI Tickets is a fan-first ticketing marketplace, offering millions of tickets to more than 175,000 concerts, theater and sporting events across the globe. SI Tickets puts the fan experience first - featuring transparent pricing and unparalleled access to the biggest events and a guaranteed 100% refund if an event is canceled for any reason. With the largest audience in the industry, the SI Tickets marketplace connects more buyers and sellers than any other ticketing platform around the world.</p>
			</>
		),
	},
	{
		image: aboutUsTrophy,
		imageMobile: aboutUsTrophyMobile,
		title: "SI Tickets Lets Fans reserve tickets to over 170 postseason, playoff and championship games across pro and college sports so you never miss seeing your team in the biggest games.",
		description: (
			<>
				<p>
					Choose the team, game, and zone you want to sit in. Simply pay the reservation fee upfront to lock in your low ticket price. When your team makes the game you pay for the ticket and you are going! SI Tickets guarantees your tickets so you don’t have to stress or scramble to find tickets.
					<strong> Fans can save up to 80% versus purchasing the tickets the week of the game!</strong>
				</p>
				<CompanyItemButton to={InternalRoutes.Futures()}>Learn More</CompanyItemButton>
			</>
		),
	},
];

export const AboutUsMeetTheTeamData = [
	{
		name: "David Lane",
		position: "Chief Executive Officer",
		description: (
			<>
				<strong>David Lane is the CEO of SI Tickets, overseeing all facets of the company. He has a proven track record of successful management. He has 20+ years of executive leadership experience, guiding teams to build and launch innovative products in highly competitive marketplaces.</strong>
				<br />
				Previously, David held Executive roles at FanDuel, OUTFRONT Media and Touchtunes where he was instrumental in driving growth in the technology and entertainment industry. He is also a founder of digital media companies with several patents on wireless transmission of multimedia.
			</>
		),
	},
	{
		name: "Galina Kho",
		position: "VP Marketing",
		description: (
			<>
				<strong>Over 12 years of marketing experience across life cycle marketing, brand communications, product marketing, creative, and research. Led marketing, social, and consumer insights for FanDuel and High 5 Games.</strong>
				<br />
				Experience transforming consumer behavior data into result-driven concepts and solutions for sporting properties, live music events, and the wider entertainment and gaming industry. Wide channel experience across offline, paid digital, owned, and earned media.
				<br />
				M.S. in Strategic Communications from Columbia University and M.A. in Communications &amp; Technology from Georgetown University.
			</>
		),
	},
	{
		name: "Nick Mazzei",
		position: "VP Partnerships & Corp Development",
		description: (
			<>
				<strong>Nicholas Mazzei has 10+ years of experience in the ticketing industry, from purchase to delivery on both primary and secondary markets. He has held prior executive roles at leading software provider 1Ticket, top inventory aggregator DTI, and co-founder of Periodic Tickets.</strong>
				<br />
				He is an expert in ticketing distribution channels and inventory management and created many of today’s standard automation processes at 1Ticket. At DTI, Nicholas spent three years refining systems that would allow DTI to offer what is now the most affordable and simplistic online resale solution for ticket brokers.
			</>
		),
	},
	{
		name: "Sophie Martin",
		position: "EVP Operations",
		description: (
			<>
				<strong>Sophie Martin has been a Business Operations Leader in the Event Ticketing Industry for over a decade. Prior Executive roles held at TicketNetwork and DTI Management. Experienced in establishing department needs including Internal &amp; External Procedures, Internal Resources, Budget Forecasting, Performance Evaluation, Products and Reporting.</strong>
				<br />
				Keen focus on Client Satisfaction and Product Optimization by identifying measurable KPIs across business teams that successfully improve value, optimizing overall business operations. Sophie is an integral business stakeholder on SI Tickets’ product development for both internal and client facing solutions. She oversees SI Tickets’ operational teams in the Virginia office and works very closely with SI Tickets’ Global Product and Engineering Teams.
			</>
		),
	},
	{
		name: "Joe Flores",
		position: "Chief Marketing Officer",
		description: (
			<>
				<strong>At SI Tickets, Joe oversees PR and all communication efforts.&nbsp;</strong>
				Joe spent the last two decades at powerhouse agencies MWWPR, ENDEAVOR and Taylor, shaping marketing communications strategy for the likes of Microsoft, DIAGEO, MasterCard, Kimberly-Clark, DICK’S Sporting Goods and FanDuel among others. From leading agency PR for the debut of Microsoft Surface on NFL sidelines to helping introduce FanDuel into legal sports betting, Joe has been at the forefront of the industry, earning a spot on PRWeek’s prestigious “40 Under 40” in 2014.
			</>
		),
	},
	{
		name: "Michael Gallina",
		position: "Chief Technical Officer",
		description: (
			<>
				<strong>As Chief Product and Technical Officer at SI Tickets, Mike is responsible for product strategy, implementation and delivery. Mike has 25 years of engineering and digital product leadership building and running technology teams for some of the worlds best brands including JetBlue, Stanley Black and Decker, The Food Network, HGTV, FCB, McCann Erickson & Intel.</strong>
				<br />
				Throughout Mike’s tenure as a tech leader he has received accolades in travel tech and had exclusive opportunities to partner with Apple and Microsoft piloting emerging technology like Apple Watch Beta and Azure Machine Learning Beta. As a serial startup founder and CTO Mike also has several years of experience building B2C and enterprise B2B products successfully executing SaaS agreements with Fortune 10 clients.
			</>
		),
	},
];
