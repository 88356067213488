import styled from "styled-components/macro";
import { Link } from "../../Link";
import { maxContentWidthStyles, maxContentWidthPadding, maxContentWidth } from "../../util/maxContentWidth";
import { mediaQueries } from "../../util/mediaQueries";

const featuredScrollShadowMargin = 20;

export const FeaturedContainer = styled.div<{
	height: number;
}>`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	/*fallback height*/
	height: 100px;
	height: ${props => props.height}px;
	${maxContentWidthStyles}
`;

export const FeaturedComponentContainer = styled.div<{
	ref: any;
}>`
	padding-top: 0.5625rem;
	padding-bottom: 0.35rem;
`;

export const FeaturedScroll = styled.div`
	display: flex;
	width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
	margin: -${featuredScrollShadowMargin}px;
	padding: 0 ${featuredScrollShadowMargin}px;
	padding-bottom: ${featuredScrollShadowMargin}px;

	/*hide scrollbar*/
	& {
		overflow-y: scroll;
		scrollbar-width: none;
		-ms-overflow-style: none;
	}
	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
	padding-top: 0.88rem;
`;

export const Fade = styled.div<{
	direction: "left" | "right";
	height: number;
	color?: string;
}>`
	/*fallback height*/
	height: 100px;
	height: ${props => props.height}px;
	width: ${maxContentWidthPadding * 3}px;
	${props => {
		const color = props.color ? props.color : "255, 255, 255";
		return "background: linear-gradient(to " + props.direction + ", rgba(" + color + ", 0), rgba(" + color + ") 90%);";
	}}
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	${props => props.direction + ": 0;"}
	z-index: 1;
	@media (max-width: ${maxContentWidth}) {
		display: none;
	}
`;

const subtitleBorderSpacing = "12px";
const subtitleMediaQuery = mediaQueries.max670;

export const FeaturedHeader = styled.h2<{ wrapTitle?: boolean; customSubtitle?: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1.4rem;
	line-height: normal;
	font-weight: bold;
	color: #12284b;
	text-transform: uppercase;
	font-family: Montserrat, sans-serif;
	${maxContentWidthStyles}
	span {
		text-align: left;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: flex;
		align-items: center;
		& > span {
			border-left: 1px solid grey;
			margin-left: ${subtitleBorderSpacing};
			padding-left: ${subtitleBorderSpacing};
			color: #555;
			font-size: 0.875rem;
			font-weight: 500;
		}
		${props => {
			if (typeof props.wrapTitle === "undefined" || props.wrapTitle) {
				if (props.customSubtitle) {
					return `
					flex-direction: column;
					align-items: flex-start;
					& > span {
						margin: 0;
						margin-top: 10px;
						margin-bottom: 10px;
						padding: 0;
						border: none;
					}`;
				}
				return `
				@media ${subtitleMediaQuery} {
				  flex-direction: column;
				  align-items: flex-start;
			    }
				& > span {
					@media ${subtitleMediaQuery} {
						margin: 0;
						margin-top: 3px;
						padding: 0;
						border: none;
					}
				}`;
			}
			return "";
		}}
	}
`;

export const FeatureHeaderAction = styled(Link)`
	z-index: 3;
	float: right;
	font-size: 13px;
	color: #555555 !important;
	transition: 0.2s cubic-bezier(0.22, 0.61, 0.36, 1) box-shadow;
	box-shadow: 2px 2px 7px 0px #ccc;
	background: #e2e4e7;
	user-select: none;
	cursor: pointer;
	border: 2px solid #fff;
	padding: 0.45rem 0.85rem;
	&:hover {
		box-shadow: 4px 4px 7px 0px #ccc;
	}
`;

export const FeaturedEmptyMessage = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
	min-height: 100px;
`;
