import * as t from "io-ts";
import { GlobalAssets } from "../../model/Asset";

const NulleableString = t.union([t.string, t.null]);

const StyledTextCodec = t.interface({
	text: t.string,
	link: NulleableString,
	style: t.unknown,
});

const ImageWithLink = t.interface({
	url: NulleableString,
	link: NulleableString,
});

export const CoverAssetsCodec = t.partial({
	primary_color: t.string,
	secondary_color: t.string,
	main_headline_1: StyledTextCodec,
	main_sub_headline_1: StyledTextCodec,
	main_headline_2: StyledTextCodec,
	main_sub_headline_2: StyledTextCodec,
	main_headline_3: StyledTextCodec,
	main_sub_headline_3: StyledTextCodec,
	thumbnail_image: NulleableString,
	banner_image: NulleableString,
	banner_style: t.any,
	vertical_adv_image_1: ImageWithLink,
	vertical_adv_image_2: ImageWithLink,
	vertical_adv_mobile_image_1: ImageWithLink,
	vertical_adv_mobile_image_2: ImageWithLink,
	vertical_adv_mobile_image_3: ImageWithLink,
	vertical_adv_mobile_image_4: ImageWithLink,
	horizontal_adv_title_desktop: ImageWithLink,
	horizontal_adv_title_mobile: ImageWithLink,
	header_image_1: ImageWithLink,
	header_image_2: ImageWithLink,
	header_image_3: ImageWithLink,
	header_image_4: ImageWithLink,
	header_image_5: ImageWithLink,
	header_image_6: ImageWithLink,
	header_image_7: ImageWithLink,
	bio: t.union([t.string, t.null]),
});

export const GlobalAssetsFromAPI = t.partial({
	main_headline_1: t.string,
	sub_headline_1: t.string,
	main_headline_2: t.string,
	sub_headline_2: t.string,
	main_headline_3: t.string,
	sub_headline_3: t.string,
	position: t.keyof({
		LEFT: null,
		RIGHT: null,
		CENTER: null,
	}),
	mainheader_color: t.string,
	subheader_color: t.string,
	bannerImage1: t.string,
	bannerImage2: t.string,
	bannerImage3: t.string,
	bannerImage4: t.string,
	bannerImage5: t.string,
	bannerImage6: t.string,
	bannerImage7: t.string,
	bannerImage8: t.string,
	bannerImage9: t.string,
	bannerImage10: t.string,
	selected: t.union([t.literal("bannerImage1"), t.literal("bannerImage2"), t.literal("bannerImage3"), t.literal("bannerImage4"), t.literal("bannerImage5"), t.literal("bannerImage6"), t.literal("bannerImage7"), t.literal("bannerImage8"), t.literal("bannerImage9"), t.literal("bannerImage10")]),
});

export const GlobalAssetsDecoder = new t.Type<GlobalAssets, GlobalAssets, t.OutputOf<typeof GlobalAssetsFromAPI>>(
	"GlobalAssetsDecoder",
	(u: unknown): u is GlobalAssets => GlobalAssetsFromAPI.is(u),
	input =>
		t.success({
			mainHeadline1: input.main_headline_1,
			mainSubHeadline1: input.sub_headline_1,
			mainHeadline2: input.main_headline_2,
			mainSubHeadline2: input.sub_headline_2,
			mainHeadline3: input.main_headline_3,
			mainSubHeadline3: input.sub_headline_3,
			position: input.position,
			mainHeaderColor: input.mainheader_color,
			subHeaderColor: input.subheader_color,
			bannerImage1: input.bannerImage1,
			bannerImage2: input.bannerImage2,
			bannerImage3: input.bannerImage3,
			bannerImage4: input.bannerImage4,
			bannerImage5: input.bannerImage5,
			bannerImage6: input.bannerImage6,
			bannerImage7: input.bannerImage7,
			bannerImage8: input.bannerImage8,
			bannerImage9: input.bannerImage9,
			bannerImage10: input.bannerImage10,
			selected: input.selected,
		}),
	t.identity
);

export type CoverAssets = t.OutputOf<typeof CoverAssetsCodec>;
export type GlobalAssetsResponse = t.OutputOf<typeof GlobalAssetsDecoder>;
