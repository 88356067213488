import styled from "styled-components/macro";
import React from "react";
import { LocationPicker } from "../LocationPicker/NewLocationPicker";
import { FontFamily, getTypographyStyles, Typography } from "../Typography/Typography";
import { breakpoints } from "../../util/breakpoints";
import { Button } from "../NewButton/Button";
import { SkeletonWrapper } from "../SkeletonWrapper/SkeletonWrapper";
import { useHistory } from "react-router-dom";
import filterToggle from "../../resource/assets/icons/settings_icon.svg";
import { getAppColor } from "../../util/appColors";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";

export const LocationPickerHeader = (
	props: {
		headerTitle: React.ReactNode;
		showLocationPicker?: boolean;
		viewAllLink?: string;
		headerTitleLoading?: boolean;
		viewAllLinkLoading?: boolean;
		newDesign?: boolean;
		showFilterToggle?: boolean;
		onFilterToggle?: () => void;
	} & StyledComponentProps & (
		| {
				displayNoEventsFoundMessage?: false;
		  }
		| {
				displayNoEventsFoundMessage: true;
				customNoEventsFoundMessage?: string;
		  }
	)
) => {
	const history = useHistory();

	return (
		<ComponentContent
			className={props.className}
		>
			<CarouselHeader newDesign={props.newDesign}>
				<SkeletonWrapper loading={props.headerTitleLoading} style={{ zIndex: props.headerTitleLoading ? 0 : "initial" }}>
					<HeaderLeftContent newDesign={props.newDesign}>
						{props.newDesign ? (
							<h2
								css={`
									font-family: ${FontFamily.Solano};
									font-size: 25px;
    							line-height: 27px;
									color: ${getAppColor("darkGrey", "dark")};
									text-transform: uppercase;
								`}
							>
								{props.headerTitle}
							</h2>
						) : (
							<StyledTitle type="heading2" color="dark">
								{props.headerTitle}
							</StyledTitle>
						)}

						{props.showLocationPicker && <LocationPicker newDesign={props.newDesign} />}
					</HeaderLeftContent>
				</SkeletonWrapper>
				{(!!props.viewAllLink || props.viewAllLinkLoading) && (
					<SkeletonWrapper loading={props.viewAllLinkLoading} style={{ zIndex: 0 }}>
						<ViewAllButton
							color="primary"
							size="small"
							onClick={() => {
								if (!!props.viewAllLink) {
									history.push(props.viewAllLink);
								}
							}}
						>
							View All
						</ViewAllButton>
					</SkeletonWrapper>
				)}
				{props.newDesign && props.showFilterToggle && (
					<img
						src={filterToggle}
						alt="Toggle Filter Visibility"
						onClick={() => {
							if (props.onFilterToggle) {
								props.onFilterToggle();
							}
						}}
						css={`
							margin-left: 5px;
						`}
					/>
				)}
			</CarouselHeader>
			{props.displayNoEventsFoundMessage && <NoEventsFoundMessage>{props.customNoEventsFoundMessage !== undefined ? props.customNoEventsFoundMessage : "No events found."}</NoEventsFoundMessage>}
		</ComponentContent>
	);
};

const ComponentContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const CarouselHeader = styled.div<{ newDesign?: boolean }>`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	${props => !props.newDesign && `
		margin-bottom: 20px;
		@media (max-width: ${breakpoints.mobile}) {
			align-items: flex-end;
		}
	`}
`;
LocationPickerHeader.CarouselHeader = CarouselHeader;

const HeaderLeftContent = styled.div<{ newDesign?: boolean }>`
	display: flex;
	${props => props.newDesign ? `
		flex-direction: column;
		align-items: flex-start;
		text-align: left;
	` : `
		align-items: center;
		@media (max-width: ${breakpoints.mobile}) {
			align-items: flex-start;
    	flex-flow: row wrap;
		}
	`}
`;
LocationPickerHeader.HeaderLeftContent = HeaderLeftContent;

const StyledTitle = styled(Typography)`
	margin-right: 16px;
	text-align: left;

	@media (max-width: ${breakpoints.tablet}) {
		margin-right: 8px;
	}

	@media (max-width: ${breakpoints.mobile}) {
		margin-bottom: 4px;
		margin-right: 10px;
	}
`;

const ViewAllButton = styled(Button)`
	width: 114px;

	@media (max-width: ${breakpoints.tablet}) {
		width: 82px;
		height: 32px;
	}
`;

const NoEventsFoundMessage = styled.span`
	max-width: 100%;
	margin-bottom: 20px;
	text-align: left;
	${getTypographyStyles("bodyNormal")}
	@media (max-width: ${breakpoints.mobile}) {
		${getTypographyStyles("bodySmall")}
	}
`;
LocationPickerHeader.NoEventsFoundMessage = NoEventsFoundMessage;
