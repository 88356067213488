const Chrome = !!window.navigator.userAgent.match(/CriOS/i) || !!window.navigator.userAgent.match(/Chrome/i);

export const Platform = {
	IOS: !!window.navigator.userAgent.match(/iPad/i) || !!window.navigator.userAgent.match(/iPhone/i),
	Android: !!window.navigator.userAgent.match(/android/i),
};

export const BrowserEngine = {
	Webkit: !!window.navigator.userAgent.match(/WebKit/i),
};

export const Browser = {
	SafariMobile: Platform.IOS && BrowserEngine.Webkit && !window.navigator.userAgent.match(/CriOS/i),
	ChromeIOS: Platform.IOS && BrowserEngine.Webkit && Chrome,
	Chrome,
};
