import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { setCheckoutShippingAddressIndex } from "../../../store/actions/checkoutActions";
import { NewAddressModal } from "../NewAddressModal/NewAddressModal";
import { useUser } from "../../../services/hooks/useUser";
import { useCheckout } from "../../../services/hooks/useCheckout";
import { renderAddress } from "../../util/address";
import { getTypographyStyles } from "../Typography/Typography";
import { Select, SelectItem } from "../Select/Select";
import { useGuest } from "../../../services/hooks/useGuest";
import { InternalRoutes } from "../../Link";
import { useHistory } from "react-router-dom";

export const ShippingInfo = () => {
	const dispatch = useDispatch();
	const user = useUser();
	const guest = useGuest();
	const checkoutState = useCheckout();
	const history = useHistory();

	const [showAddressForm, setShowAddressForm] = useState(false);

	const addresses = (user || guest)?.addresses;

	useEffect(() => {
		let path = "/delivery";
		if (showAddressForm) {
			path += "/new-address"	
		}
		history.replace(InternalRoutes.Checkout + path);
	}, [showAddressForm]);

	return (
		<Container>
			{showAddressForm && (
				<NewAddressModal
					addressAdded={() => {
						const length = addresses?.length;
						if (length !== undefined) {
							const index = length - 1;
							dispatch(setCheckoutShippingAddressIndex(index));
						}
						setShowAddressForm(false);
					}}
					onDidClose={() => {
						setShowAddressForm(false);
					}}
				/>
			)}
			<Title>Ship to</Title>

			<Select
				placeholder="Select an Address"
				value={checkoutState.shippingAddressIndex}
				items={(() => {
					const addresses = (user || guest)?.addresses;
					if (addresses?.length) {
						return addresses.map((address, index) => ({
							value: index,
							label: renderAddress(address) || "",
						}));
					}
					return [
						{
							value: "",
							label: "No Addresses Added",
							isDisabled: true,
						},
					];
				})()}
				onChange={(value: SelectItem["value"]) => {
					if (typeof value === "number") {
						dispatch(setCheckoutShippingAddressIndex(value));
					}
				}}
				isClearable={false}
			/>

			<NewAddressButton
				onClick={() => {
					setShowAddressForm(true);
				}}
			>
				+ Add New Address
			</NewAddressButton>
		</Container>
	);
};

const Container = styled.div`
	color: black;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-align: left;
	select {
		margin: 0;
	}
	button {
		margin-top: 11px;
	}
	&:not(:last-child) {
		margin-bottom: 21px;
	}
	> * {
		width: 100%;
	}
`;

const Title = styled.h1`
	${getTypographyStyles("heading4", {
		color: "darkGrey",
		colorType: "dark",
	})};
`;

const NewAddressButton = styled.div`
	${getTypographyStyles("bodySmall", {
		color: "dark",
		colorType: "dark",
		fontWeight: "bold",
	})};

	text-decoration: underline;
	text-align: center;
	margin-top: 21px;
	cursor: pointer;
`;
