import { PartialBy, Primitive } from "../model/optimizedModel/general";
import { apiClient } from "./apiClient";
import { APIClientResponse } from "./types/APIClientResponse";

const prefix = "bets/";

export function getBetsSports(): APIClientResponse<
	{
		background: string;
		name: string;
		slug: string;
	}[]
> {
	return apiClient.get(prefix + "sports");
}

export function getBetsSportCategories(sportSlug: string): APIClientResponse<
	{
		name: string;
		slug: string;
	}[]
> {
	return apiClient.get(prefix + sportSlug + "/categories");
}

export function getBetsSportCategorySubcategories(
	sportSlug: string,
	categorySlug: string
): APIClientResponse<
	{
		name: string;
		slug: string;
	}[]
> {
	return apiClient.get(prefix + sportSlug + "/" + categorySlug + "/subcategories");
}

export function getBetsFilters(
	sportSlug: string,
	subcategorySlug: string
): Promise<
	{
		key: string;
		title: string;
		options: {
			value: string;
			label: string;
		}[];
	}[]
> {
	return apiClient.get(prefix + "filters/" + sportSlug + "/" + subcategorySlug);
}

export function postBetsSubmitEmail(payload: { email: string; betId: string; betAmount: number; winAmount: number; type: "game" | "gameSpread" | "playerFuture" | "teamFuture" | "teamOverUnder" | "playerOverUnder" | "gameOverUnder" | "teamEvent"; details: string; selectedTeamId?: string; selectedPlayerId?: string; selectedOverUnderOption?: string; latitude?: number; longitude?: number }) {
	return apiClient.post(prefix + "submit-email", payload);
}

export function getBetsMetadata(): Promise<{
	bettingStates: Record<string, string>;
	filters: {
		key: string;
		title: string;
		options: {
			value: string;
			label: string;
		}[];
	}[];
}> {
	return apiClient.get(prefix + "metadata");
}

//TODO: Confirm if all the details from the below types are accurate. (Ex: optional props)
type GameBetTeam = {
	name: string;
	odds: number;
	id: string;
	performerSlug?: string;
} & (
	| {
			initials?: string;
	  }
	| {
			location?: string;
	  }
);

type BetSportsbook = {
	logo: string;
	name: string;
	incentive: string;
	shortIncentive?: string;
};

type GameBetData = {
	homeTeam: GameBetTeam;
	awayTeam: GameBetTeam;
	eventDate: string;
	sportsbook: BetSportsbook;
	id: string;
};

type BaseFutureBetData = {
	title?: string;
};

type BaseFutureBetOption = {
	name: string;
	odds: number;
	sportsbook: BetSportsbook;
	betId: string;
	performerSlug?: string;
};

type OverUnderBetTeam = {
	location: string;
	name: string;
	performerSlug?: string;
};

type GameOverUnderBetData = {
	eventDate?: string;
	homeTeam: OverUnderBetTeam;
	awayTeam: OverUnderBetTeam;
	sportsbook: BetSportsbook;
	overUnder: number;
	overOdds: number;
	underOdds: number;
	betId: string;
	units?: string;
};

//TODO: See if any of the types can be optimized further.
export function getBets(
	sportSlug: string,
	subcategorySlug: string,
	{ pageNumber, ...filters }: Partial<Record<string, Primitive | Primitive[]>> = {}
): Promise<{
	metadata: {
		pageNumber: number;
		pageSize: number;
		totalPages: number;
	};
	bets: (
		| {
				type: "game";
				data: GameBetData;
		  }
		| {
				type: "gameSpread";
				data: GameBetData & {
					spread: number;
				};
		  }
		| {
				type: "playerFuture";
				data: BaseFutureBetData & {
					players: (BaseFutureBetOption & {
						playerId: string;
						performerName?: string;
					})[];
				};
		  }
		| {
				type: "teamFuture";
				data: BaseFutureBetData & {
					teams: (BaseFutureBetOption & {
						initials?: string;
						teamId: string;
					})[];
				};
		  }
		| {
				type: "gameOverUnder";
				data: GameOverUnderBetData;
		  }
		| {
				type: "playerOverUnder";
				data: PartialBy<GameOverUnderBetData, "homeTeam" | "awayTeam"> & {
					performerSlug?: string;
					playerName: string;
				};
		  }
		| {
				type: "teamOverUnder";
				data: Omit<GameOverUnderBetData, "eventDate" | "homeTeam" | "awayTeam"> & {
					teamName: string;
					performerSlug?: string;
				};
		  }
		| {
				type: "teamEvent";
				data: {
					happeningOdds: number;
					notHappeningOdds: number;
					teamName: string;
					performerSlug?: string;
					sportsbook: BetSportsbook;
					betId: string;
					action: string;
				};
		  }
	)[];
}> {
	return apiClient.get(prefix + sportSlug + "/" + subcategorySlug, {
		params: {
			...filters,
			page_number: pageNumber,
		},
	});
}
