import React, { useState } from "react";
import styled from "styled-components/macro";
import { FuturesTermsModal } from "./FuturesTermsModal";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";

export const FuturesTermsModalLink = ({ className, ...futuresTermsModalProps }: StyledComponentProps & Omit<React.ComponentProps<typeof FuturesTermsModal>, "onDidClose">) => {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			{showModal && (
				<FuturesTermsModal
					{...futuresTermsModalProps}
					onDidClose={() => {
						setShowModal(false);
					}}
				/>
			)}
			<Link
				onClick={() => {
					setShowModal(true);
				}}
				className={className}
			>
				Details & Terms
			</Link>
		</>
	);
};

const Link = styled.span`
	text-decoration: underline;
	color: black;
	font-weight: 500;
	font-size: 15px;
	cursor: pointer;
`;
