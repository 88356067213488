import { Location } from "../../model/Location";
import { ServiceCallAction } from "../../store/middleware/serviceCallMiddleware";
import { PopularPerformersResponse, PopularPerformersCodec } from "../types/PopularResponseCodec";

export const getPopularPerformers = (category: string, location: Location | null, distance: number | null, pageSize: number | null): ServiceCallAction<PopularPerformersResponse> => ({
	method: "GET",
	endpoint: `events/popular`,
	param: {
		category_slug: category,
		page_number: 1,
		page_size: pageSize,

		...(location && {
			latitude: location.latitude,
			longitude: location.longitude,
		}),
		...(distance && { distance }),
	},
	serviceKey: "getPopularPerformers",
	responseDecoder: PopularPerformersCodec,
	type: "SERVICE_CALL",
});
