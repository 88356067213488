import React from "react";
import styled from "styled-components/macro";
import { getAppColor } from "../../util/appColors";

//TODO: Add hover, "onSelect", and "selected" animations.
export const RadioButton = ({ className, style, css, ...inputProps }: Omit<React.HTMLProps<HTMLInputElement>, "type">) => {
	return (
		<Container className={className} style={style} css={css}>
			<input {...inputProps} type="radio" />
			<RadioDot />
		</Container>
	);
};

const color = getAppColor("dark");

const radioDotSize = "60%";

const RadioDot = styled.div`
	background: ${color};
	width: ${radioDotSize};
	height: ${radioDotSize};
	border-radius: 50%;
`;

const borderSizeValue = 2;

const containerSizeValue = 20;

const inputSize = borderSizeValue * 2 + containerSizeValue + "px";

const containerSize = containerSizeValue + "px";

const Container = styled.div`
	width: ${containerSize};
	height: ${containerSize};
	min-width: ${containerSize};
	min-height: ${containerSize};
	border: ${borderSizeValue}px solid ${color};
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	& > input {
		all: initial;
		width: ${inputSize};
		height: ${inputSize};
		position: absolute;
		cursor: pointer;
		opacity: 0;
		&:not(:checked) ~ ${RadioDot} {
			display: none;
		}
	}
`;
