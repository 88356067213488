import React from "react";
import si_tickets_logo_white from "../../resource/assets/si_tickets_logo_unstacked_cropped_white.svg";
import { useSelector } from "react-redux";
import styled, { keyframes } from "styled-components/macro";
import { getTypographyStyles } from "../Typography/Typography";
import { FourDotLoader } from "./FourDotLoader";
import { getAppColor } from "../../util/appColors";
import { maxContentWidthPadding } from "../../util/maxContentWidth";
import { useWindowSize } from "../../hooks/useWindowSize";
import { Config } from "../../../Config";

const loaderSavingsBanner = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/events/loading-screen-banner.svg`);

export const FullScreenLoader = (props: { show: boolean }) => {
	const loadingScreenData = useSelector(state => state.transient.loadingScreenData);

	const { height } = useWindowSize();

	return (
		<LoaderContainer show={props.show}>
			<div
				css={`
					display: flex;
					flex-direction: column;
					justify-content: center;
					padding: 32px ${maxContentWidthPadding}px;
					box-sizing: border-box;
					align-items: center;
					max-height: 100%;
				`}
			>
				<div
					css={`
						display: flex;
						flex-direction: column;
						align-items: center;
						width: 250px;
					`}
				>
					<img
						alt="SI Tickets"
						src={si_tickets_logo_white}
						css={`
							margin: 0 20px;
							width: auto;
							height: auto;
							max-width: 100%;
							max-height: 100%;
						`}
					/>

					<FourDotLoader
						css={`
							margin: 30px 0;
						`}
					/>

					{loadingScreenData.text && height && height > 500 && (
						<>
							{!loadingScreenData.hideBanner && <Banner alt="Your Ticket to Every Game, Concert, or Show" src={loaderSavingsBanner} />}
							<Message
								css={`
									line-height: 1.3;
									margin-top: 10px;
									word-spacing: 1px;
								`}
							>
								{loadingScreenData.text} <br /> {loadingScreenData.textSecondLine}
							</Message>
						</>
					)}
				</div>
			</div>
		</LoaderContainer>
	);
};

const LoaderContainer = styled.div<{ show: boolean }>`
	position: fixed;
	z-index: 200;
	background-color: ${getAppColor("dark")};
	height: 100%;
	width: 100%;
	text-align: center;
	top: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	visibility: ${props => (props.show ? "visible" : "hidden")};
	animation: ${props => (props.show ? "none" : fadeOut)} 0.3s linear;
	transition: visibility 0.3s linear;
`;

const fadeOut = keyframes`
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
`;

const Message = styled.span`
	${getTypographyStyles("extraLargeSpecialBody", {
		color: "light",
	})}
	text-align: center;
	white-space: nowrap;
`;

const Banner = styled.img`
	display: block;
	margin: 2px 0;
	max-width: 100%;
`;

const HorizontalLine = styled.div`
	width: 100%;
	height: 3px;
	background-color: ${getAppColor("light")};
	margin: 3px 0 10px 0;
`;
