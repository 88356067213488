import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSubcategories } from "../../services/hooks";
import { CommonPage, NewEvents } from "../components";
import { VerticalAdContainer, VerticalAdFrame, FreeStarVerticalAd, StickyFooterContainer } from "../components/Advertisement/Advertisement";
import { useDataService } from "../hooks/useServiceCall";
import { getCategoryAssets } from "../../services/actions/assetsService";
import { getNearEvents } from "../../services/actions/eventsService";
import { Config } from "../../Config";
import { isRight } from "fp-ts/lib/These";
import { useServiceMonitor } from "../hooks";
import { Event } from "../../model/Event";
import { LocationPicker } from "../components/LocationPicker";
import reserve_postseason from "../resource/image/reserve-postseason.png";
import { getTeams } from "../../services/teams";
import styled from "styled-components/macro";
import { MetaDecorator, getCategoryTagDescription, getCategoryTagTitle } from "../components/SEOMetadecorator/MetaDecorator";
import { resetLoadingScreenData, setLoadingScreenData } from "../../store/actions/loadingScreenDataActions";
import { mediaQueries } from "../util/mediaQueries";
import { InternalRoutes } from "../Link";
import queryString from "query-string";
import { parseSearch } from "../util/parseSearch";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { DateRange } from "../components/Filters/DateRangeFilter/DateRangeFilter";
import { PageBio } from "../components/PageBio/PageBio";
import { getCategoryBioService, getSubcategories } from "../../services/actions/categoriesService";
import toast from "react-hot-toast";
import { useToastRemoval } from "../hooks/useToastRemoval";
import { NewMultiFilterContainer } from "../components/Filters/NewMultiFilterContainer";
import { NewDateRange } from "../components/DateRange/NewDateRange";
import { Hero } from "../components/Hero/Hero";
import { useInitiallyTransparentNavbar, useNewNavbar } from "../hooks/navbar";
import { ThumbnailCarouselRow } from "../components/Thumbnail/ThumbnailCarouselRow";
import { LocationPickerHeader } from "../components/LocationPickerHeader/LocationPickerHeader";
import { maxContentWidth, maxContentWidthPadding } from "../util/maxContentWidth";
import { breakpoints } from "../util/breakpoints";
import { SingleAd } from "../components/HomeAds/SingleAd";
import { getAppColor } from "../util/appColors";
import { getTypographyStyles } from "../components/Typography/Typography";
import { useAutoUpdateState } from "../hooks/useAutoUpdateState";
import { getDiscoverEvents } from "../../services/discover";
import fireIcon from "../resource/img/icons/fire-icon.svg";
import { DateTime } from "luxon";
import { GeneralSearch } from "../components/Search/GeneralSearch";
import { getHomeAssets } from "../../services/assets";
import { HomeAssets } from "../../model/Asset";
import { NewEventThumbnailCarouselRow } from "../components/Thumbnail/NewEventThumbnailCarouselRow";
import { NewHero } from "../components/Hero/NewHero";
import { useWindowSize } from "../hooks/useWindowSize";
import { HeaderTitle } from "../components/Category/HeaderTitle";
import { getNavBarAll } from "../../services/navigation";
import { setNavbarNavigationItems } from "../../store/reducers/navbarReducer";
import { IconRow, IconRowItems } from "../components/IconRow/IconRow";
import { FreeStarAd } from "../components/FreeStarAd";

interface CategoryRouteParams {
	category: string;
}

export interface CategorySearchParams {
	from: string | undefined;
	to: string | undefined;
}

const excludedFromGeo = ["virtual-events"];
const sportSubcategories = ["mlb", "nba", "nfl", "nhl", "mls-major-league-soccer", "wnba", "masl"];
const showFuturesThumbnail = ["sports"];
const categoryNames = ["Sports", "Theater", "Concerts"];

const reservationsThumbnailSlug = InternalRoutes.Futures("?sport=nfl");

const CategoryRouteContext = React.createContext<{
	isCategoryPage?: boolean;
	searchParams?: CategorySearchParams;
	onDateChange?: (date: DateRange) => void;
	dateRange?: string;
	parent?: string;
}>({});

const laptopBreakpoint = "1220px";

export const Category = (props: RouteComponentProps<CategoryRouteParams>) => {
	const searchParams = parseSearch<CategorySearchParams>(props.location.search);
	const history = useHistory();
	const parent = props.match.params.category;
	const dispatch = useDispatch();

	let categories = useSubcategories(parent)
		.map(cat => {
			return {
				...cat,
				background: cat.image ? Config.getResourceUrl(cat.image) : undefined,
				onClick: () => {
					history.push(cat.slug);
				},
			};
		})
		.filter(cat => !categoryNames.includes(cat.name));

	const [isCategoryPage, setIsCategoryPage] = useState(false);
	const sportCategory = useAutoUpdateState(() => parent === "sports", [parent]);
	const [rootCategory, setRootCategory] = useState<string | undefined>(undefined);

	useEffect(() => {
		if (!isCategoryPage) {
			setRootCategory(undefined);
			dispatch(getSubcategories(parent)).then(res => {
				if (isRight(res)) {
					setRootCategory(res.right[0]?.name);
				} else {
					setRootCategory(undefined);
				}
			});
		} else {
			setRootCategory(undefined);
		}
	}, [parent, isCategoryPage]);

	const userLocation = useSelector(state => state.persistent.location);
	const location = excludedFromGeo.includes(parent) ? null : userLocation;

	const loading = useServiceMonitor(["getEvents"]);

	const { result: categoryAssets, isLoading: loadingAssets } = useDataService(getCategoryAssets, [parent], {});

	const [allTeams, setAllTeams] = useState<any[]>([]);

	const showFutures = showFuturesThumbnail.includes(parent);

	const [trendingEvents, setTrendingEvents] = useState<any[]>([]);
	const [trendingEventsLoading, setTrendingEventsLoading] = useState<boolean>(true);

	useEffect(() => {
		setTrendingEventsLoading(true);

		const page = 1;
		const pageSize = 10;
		const distance = "100";

		getDiscoverEvents(page, parent, null, null, distance, location, pageSize, true)
			.then(events => {
				const localEvents = events.data.events.map(event => {
					const date = DateTime.fromJSDate(new Date(event.occurs_at));
					return {
						title: event.event_name,
						titleLink: `event/${event.event_slug}`,
						subtitle: event.venue_name + (event.venue_city ? `, ${event.venue_city}` : ""),
						buttonText: event.ticket_group_price ? `From $${event.ticket_group_price}` : "See Tickets",
						imageOverlayText: event.occurs_at ? `${date.monthShort} ${date.day}` : "TBD",
						background: Config.getResourceUrl(event.event_image),
						onClick: () => {
							history.push(InternalRoutes.Event(event.event_slug));
						},
						ticketGroupPrice: event.ticket_group_price,
						venueCity: event.venue_city,
					};
				});
				setTrendingEvents(localEvents);
			})
			.finally(() => {
				setTrendingEventsLoading(false);
			});
	}, [location, parent]);

	if (showFutures) {
		if (categories.length > 0) {
			categories = [
				{
					id: -1,
					name: "",
					slug: reservationsThumbnailSlug,
					background: reserve_postseason,
					onClick: () => {
						history.push(reservationsThumbnailSlug);
					},
				},
				...categories,
			];
		}
	}

	useEffect(() => {
		const newIsCategoryPage = ["sports", "theater", "concerts"].some(category => parent === category);
		setIsCategoryPage(newIsCategoryPage);
		if (sportSubcategories.includes(parent)) {
			getTeams(parent).then(result => {
				setAllTeams(
					result.data
						?.map(item => ({
							title: item.name,
							titleLink: "/performer/" + item.slug,
							background: item.image ? Config.getResourceUrl(item.image) : null,
							onClick: () => {
								history.push(`/performer/${item.slug}`);
							},
						}))
						.sort(alphabeticallyOrder("title"))
				);
			});
		}
	}, [parent, location]);
	const [bio, setBio] = useState("");

	useEffect(() => {
		if (allTeams) {
			setAllTeams([]);
		}
		if (bio) {
			setBio("");
		}
		getCategoryBioService(parent)
			.then(result => {
				setBio(result.data.bio);
			})
			.catch(error => {
				if (typeof error === "string") {
					toast.error(error);
				}
			});
	}, [parent]);

	useToastRemoval();

	const [eventsLoading, setEventsLoading] = useState(true);

	const [totalAmountOfEvents, setTotalAmountOfEvents] = useState(0);

	const eventsPage = useRef(1);

	const [events, setEvents] = useState<Event[]>([]);

	const [localEventsLoading, setLocalEventsLoading] = useState(true);

	const [totalAmountOfLocalEvents, setTotalAmountOfLocalEvents] = useState(0);

	const localEventsPage = useRef(1);

	const [localEvents, setLocalEvents] = useState<Event[]>([]);

	const [allEventsLoading, setAllEventsLoading] = useState(true);

	useEffect(() => {
		setAllEventsLoading(true);
		if (location || document.readyState === "complete") {
			eventsPage.current = 0;
			localEventsPage.current = 0;
			setEvents([]);
			setLocalEvents([]);
		}
	}, [parent, location, searchParams.from, searchParams.to]);

	useEffect(() => {
		if (allEventsLoading && !events.length && !localEvents.length) {
			loadLocalEvents();
			loadEvents();
			setAllEventsLoading(false);
		}
	}, [allEventsLoading, events.length, localEvents.length]);

	const loadEvents = async () => {
		setEventsLoading(true);
		eventsPage.current = eventsPage.current + 1;
		const results = await dispatch(getNearEvents(null, parent, eventsPage.current, searchParams.from, searchParams.to));
		if (isRight(results)) {
			const { data, metadata } = results.right;

			//TODO: Remove the following if. This one was added as a quick fix because there's an api bug where the total events count retrieved may
			//be higher than the actual existing events, and so the front-end keeps asking for the next page to get events that actually don't exist
			if (!data.length && events.length) {
				setTotalAmountOfEvents(events.length);
			} else {
				setTotalAmountOfEvents(metadata.totalRecords);
			}

			const newEvents = [...events, ...data];

			//TODO: Find a better way to do this, maybe an approach that involves two api calls to spread the arrays would be better
			if (localEvents) {
				setEvents(newEvents.filter(event => localEvents?.every(localEvent => event.id !== localEvent.id)));
			} else {
				setEvents(newEvents);
			}
		}
		setEventsLoading(false);
	};

	const loadLocalEvents = async () => {
		if (location) {
			setLocalEventsLoading(true);
			localEventsPage.current = localEventsPage.current + 1;

			const results = await dispatch(getNearEvents(location, parent, localEventsPage.current, searchParams.from, searchParams.to));

			if (isRight(results)) {
				const { data, metadata } = results.right;

				if (metadata.totalRecords > 0 && !data.length) {
					setTotalAmountOfLocalEvents(data.length);
				} else {
					setTotalAmountOfLocalEvents(metadata.totalRecords);
				}
				setLocalEvents([...localEvents, ...data]);
			}
			setLocalEventsLoading(false);
		}
	};

	useEffect(() => {
		dispatch(
			setLoadingScreenData({
				text: "Updating all of the events and prices",
			})
		);
		return () => {
			dispatch(resetLoadingScreenData());
		};
	}, []);

	const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
	const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);
	const hideGeolocation = window.location.href.includes("virtual-events");
	const wrapLocationPicker = useMediaQuery(mediaQueries.max750);

	const [dateRange, setDateRange] = useState<string | undefined>();

	const onDateChange = (date: DateRange) => {
		history.replace(InternalRoutes.Category(`${parent}?` + queryString.stringify({ ...searchParams, ...date })));
	};

	useEffect(() => {
		let text = "";
		if (searchParams.from) {
			text += searchParams.from;
		}

		if (searchParams.to && searchParams.to !== searchParams.from) {
			text += ` to ${searchParams.to}`;
		}
		setDateRange(text);
	}, [searchParams.from, searchParams.to]);

	useInitiallyTransparentNavbar(!isCategoryPage);
	useNewNavbar();

	const defaultLayoutState = {
		header: {
			headerImage: "",
			headerTitle: "",
			thumbnails: [{ image: "", url: "", title: "" }],
		},
		desktopContent: [],
		mobileContent: [],
	};

	const [homeLayoutAssets, setHomeLayoutAssets] = useState<HomeAssets>(defaultLayoutState);

	useEffect(() => {
		getHomeAssets({ preview: false }).then(result => {
			setHomeLayoutAssets(result.data);
		});
	}, []);

	const windowSize = useWindowSize();
	const wrapperRef = useRef<any>();
	const [carouselContainerOverrideWidth, setCarouselContainerOverrideWidth] = useState(0);

	useLayoutEffect(() => {
		if (wrapperRef.current) {
			setCarouselContainerOverrideWidth(wrapperRef.current.offsetWidth - 40);
		}
	}, [windowSize]);

	const navbarState = useSelector(st => st.transient.navbar);
	const [sportsRowIconItems, setSportsRowIconItems] = useState<IconRowItems[]>([]);

	useEffect(() => {
		if (!navbarState.navigationItems) {
			getNavBarAll().then(result => {
				dispatch(setNavbarNavigationItems(result.data));
			});
		}
	}, []);

	useEffect(() => {
		const items: IconRowItems[] | undefined = navbarState?.navigationItems
			?.find(navItem => navItem.name === "Sports")
			?.children?.filter(cat => cat.name !== "All Sports")
			.map(item => ({
				title: item.name,
				url: item.url || "",
			}));

		if (items) {
			setSportsRowIconItems(items);
		}
	}, [navbarState]);

	const { showAds, adPlacement } = useMemo(() => {
		return { showAds: ["sports", "concerts"].includes(parent), adPlacement: `sitickets.com_${parent}_rightrail` };
	}, [parent]);

	return (
		<CategoryRouteContext.Provider
			value={{
				isCategoryPage,
				searchParams,
				onDateChange,
				dateRange,
				parent,
			}}
		>
			<CommonPage isSitixLogoSelected={true}>
				{(() => {
					if (!isCategoryPage) {
						return (
							<>
								<Hero
									//TODO: Remove ts-ignore
									//@ts-ignore
									assets={categoryAssets}
									loading={loadingAssets}
								/>
								{isMobile && <BreadCrumbs categoryName={categoryAssets?.main_headline_1?.text} loading={loadingAssets} />}
							</>
						);
					}
					return isMobile ? (
						<>
							<CategorySearch />
							<BreadCrumbs categoryName={categoryAssets?.main_headline_1?.text} loading={loadingAssets} />
						</>
					) : (
						<NewHero assets={categoryAssets} loading={loadingAssets} />
					);
				})()}

				<MetaDecorator title={getCategoryTagTitle(props.match.params.category)} description={getCategoryTagDescription(props.match.params.category)} />
				<ContentContainer>
					<div
						css={`
							max-width: 100%;
						`}
					>
						<Wrapper ref={wrapperRef}>
							{(sportCategory || rootCategory === "Sports") && (
								<>
									<div
										css={`
											margin-bottom: 20px;
										`}
									>
										<IconRow items={sportsRowIconItems} staticIconSpacing={70} />
									</div>
								</>
							)}

							{!isCategoryPage && (
								<HorizontalAdContainer>
									<SingleAd image={isMobile ? (categoryAssets?.horizontal_adv_title_mobile?.url ? Config.getResourceUrl(categoryAssets?.horizontal_adv_title_mobile?.url) : undefined) : categoryAssets?.horizontal_adv_title_desktop?.url ? Config.getResourceUrl(categoryAssets?.horizontal_adv_title_desktop?.url) : undefined} url={isMobile ? categoryAssets?.horizontal_adv_title_mobile?.link || undefined : categoryAssets?.horizontal_adv_title_desktop?.link || undefined} loading={loadingAssets} contentFit="cover" />
								</HorizontalAdContainer>
							)}
							{categories.length > 0 && !sportCategory && (
								<div
									css={`
										margin-top: 20px;
									`}
								>
									<LocationPickerHeader headerTitle={categories.length ? "Categories" : ""} headerTitleLoading={!categories.length && loading} />
									<ThumbnailCarouselRow loading={!categories.length && loading} thumbnails={categories} rowsAmountPreference={2} />
								</div>
							)}
							{!!allTeams.length && (
								<div>
									<LocationPickerHeader headerTitle="All Teams" headerTitleLoading={!allTeams.length && loading} />
									<ThumbnailCarouselRow loading={!allTeams.length && loading} thumbnails={allTeams} rowsAmountPreference={2} />
								</div>
							)}
							{isCategoryPage && (
								<div
									css={`
										margin-top: 20px;
										${LocationPickerHeader.NoEventsFoundMessage} {
											margin-bottom: 0px;
										}
									`}
								>
									<div>
										<LocationPickerHeader
											headerTitle={
												<HeaderTitle
													mainText={
														<>
															<img
																src={fireIcon}
																alt="fire icon"
																css={`
																	margin-right: 5px;
																`}
															/>
															<span>Trending Events</span>
														</>
													}
													secondaryText="Near"
													theme="secondary"
												/>
											}
											showLocationPicker
											newDesign
											displayNoEventsFoundMessage={!trendingEventsLoading && !trendingEvents.length}
											css={`
												${LocationPickerHeader.HeaderLeftContent!} {
													flex-direction: row;
													flex-wrap: wrap;
												}
											`}
										/>
									</div>
									<NewEventThumbnailCarouselRow
										thumbnailsDisplayedPerRow={isMobile ? 1.33 : isTablet ? 2.33 : undefined}
										css={`
											margin-top: 20px;
										`}
										loading={trendingEventsLoading}
										thumbnails={trendingEvents}
										rowsAmountPreference={2}
										titlesAreLoading={trendingEventsLoading}
										subtitlesAreLoading={trendingEventsLoading}
										overrideContainerWidth={carouselContainerOverrideWidth}
									/>
								</div>
							)}

							{isCategoryPage && (
								<>
									{homeLayoutAssets[isMobile ? "mobileContent" : "desktopContent"]?.map((element, index) => {
										if (element.type === "singleAd" && index === 0) {
											return (
												<div>
													<HorizontalAdContainer
														css={`
															width: 100%;
															margin-top: 40px;
															margin-bottom: 20px;
														`}
													>
														<SingleAd {...element} key={index} loading={!homeLayoutAssets} image={Config.getCDNUrl(element.image)} />
													</HorizontalAdContainer>
												</div>
											);
										}
										return null;
									})}
								</>
							)}
						</Wrapper>

						{location && !!localEvents.length && (
							<div>
								<NewEvents
									title="Events Near"
									wrapSubtitle={wrapLocationPicker}
									subtitle={!hideGeolocation && <LocationPicker location={location} />}
									onLoadMore={() => {
										loadLocalEvents();
									}}
									loading={localEventsLoading || allEventsLoading}
									total={totalAmountOfLocalEvents}
									events={localEvents}
									ads={categoryAssets}
									filters={<Filters />}
									newDesign={isCategoryPage}
									paginatedScroll={isCategoryPage}
									adsMetadata={{ adCategory: parent, listingIdentifier: "events_near" }}
								/>

								<Divider
									css={`
										margin-bottom: 10px !important;
									`}
								/>
							</div>
						)}
						{(events.length > 0 || !totalAmountOfLocalEvents) && (
							<>
								<NewEvents
									title={location ? "Events in all Locations" : "Events"}
									wrapSubtitle={wrapLocationPicker}
									subtitle={!hideGeolocation && (!location || !totalAmountOfLocalEvents) && <LocationPicker location={location} />}
									onLoadMore={() => {
										loadEvents();
									}}
									loading={eventsLoading || allEventsLoading}
									total={totalAmountOfEvents}
									events={events}
									ads={categoryAssets}
									filters={<Filters />}
									newDesign={isCategoryPage}
									paginatedScroll={isCategoryPage}
									adsMetadata={{ adCategory: parent, listingIdentifier: "events" }}
								/>
							</>
						)}
					</div>
					{showAds && (
						<VerticalAdContainer
							css={`
								min-width: 160px;
								@media (max-width: ${laptopBreakpoint}) {
									display: none;
								}
							`}
						>
							<VerticalAdFrame>
								<FreeStarVerticalAd placement={`${adPlacement}1`} slotId={`${adPlacement}1_2`} />
							</VerticalAdFrame>
						</VerticalAdContainer>
					)}
				</ContentContainer>
				<PageBio htmlString={bio} />
				{showAds && (
					<StickyFooterContainer>
						<FreeStarAd placement="sitickets.com_sticky_footer" />
					</StickyFooterContainer>
				)}
			</CommonPage>
		</CategoryRouteContext.Provider>
	);
};

const Filters = () => {
	const { searchParams, onDateChange, dateRange, isCategoryPage, parent } = useContext(CategoryRouteContext);

	const history = useHistory();

	return (
		<>
			{searchParams && onDateChange !== undefined && (
				<NewMultiFilterContainer
					filters={[
						<NewDateRange
							{...{
								from: searchParams?.from,
								to: searchParams?.to,
								onChange: onDateChange,
								text: dateRange,
								...(isCategoryPage
									? {
											newDesign: true,
									  }
									: {
											title: "Dates",
									  }),
							}}
						/>,
					]}
					searchParams={searchParams}
					onReset={() => {
						if (parent !== undefined) {
							history.push(InternalRoutes.Category(parent));
						}
					}}
					newDesign={isCategoryPage}
				/>
			)}
		</>
	);
};

function alphabeticallyOrder<T>(field: keyof T) {
	return function (a: T, b: T) {
		return (a[field] as any).localeCompare(b[field] as any);
	};
}

const Divider = styled.div`
	margin-bottom: 25px;
`;

const Wrapper = styled.div`		
	width: min(calc(${maxContentWidth} - 2*${maxContentWidthPadding}px), calc(100vw - 2*${maxContentWidthPadding}px));
	margin: 0 auto;
	@media (max-width: ${breakpoints.mobile}) {
		max-width: calc(100vw - ${maxContentWidthPadding}px)
		align-self: flex-start;
	}
	& > {
		&:not(:first-child) {
			margin-top: 20px;
		}
	}
`;

const HorizontalAdContainer = styled.div`
	max-width: min(${maxContentWidth}, calc(100vw - 2 * ${maxContentWidthPadding}px));
	margin: 20px auto 0;
	display: flex;
	justify-content: center;
`;

const ContentContainer = styled.div`
	display: flex;
	justify-content: center;
`;

const CategorySearch = ({ categoryName }: { categoryName?: string }) => {
	return (
		<>
			<div
				css={`
					background-color: ${getAppColor("dark")};
					display: flex;
					flex-direction: column;
					justify-content: center;
					padding: 20px 20px 0px 20px;
					position: relative;
					z-index: 2;
				`}
			>
				<GeneralSearch />
			</div>
		</>
	);
};

const BreadCrumbs = ({ categoryName, loading }: { categoryName?: string; loading?: boolean }) => {
	return (
		<div
			css={`
				${getTypographyStyles("heading3")}
				color: ${getAppColor("light")};
				display: flex;
				justify-content: start;
				background-color: #212121;
				padding: 10px 0px 10px 20px;
				line-height: auto !important;
				min-height: 22px;
			`}
		>
			<span>{categoryName}</span>
		</div>
	);
};
