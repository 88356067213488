import { Reducer } from "redux";
import { Event } from "../../model/Event";
import { ServiceCallError } from "../middleware/serviceCallMiddleware";

interface DiscoverState {
	error: ServiceCallError | null;
	featuredLoaded: boolean;
	featuredDiscover: Event[];
}

const defaultState: DiscoverState = {
	error: null,
	featuredLoaded: false,
	featuredDiscover: [],
};

export const discoverReducer: Reducer<DiscoverState, DiscoverActions> = (state: DiscoverState = defaultState, action: DiscoverActions): DiscoverState => {
	switch (action.type) {
		case "UPDATE_FEATURED_DISCOVER":
			return { ...state, error: null, featuredLoaded: true, featuredDiscover: action.events };
		case "UPDATE_DISCOVER_ERROR":
			return { ...state, error: action.error };
		default:
			return state;
	}
};

export type DiscoverActions =
	| {
			type: "UPDATE_FEATURED_DISCOVER";
			events: Event[];
	  }
	| {
			type: "UPDATE_DISCOVER_ERROR";
			error: ServiceCallError;
	  };
