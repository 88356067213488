import { useEffect, useState } from "react";
import { getHubspotTracking } from "../../services/hubspotTracking";
import { useUser } from "../../services/hooks/useUser";
import { DateTime } from "luxon";
import { Nulleable } from "../../model/Utils";

export const useHubSpotTracker = ({ timeout, page, slug }: { timeout: number; page: string; slug: string }) => {
	const [remainingTime, setRemainingTime] = useState(timeout);
	const [timerId, setTimerId] = useState<Nulleable<number>>(null);
	const user = useUser();

	const startTimer = () => {
		const date = new Date(); //Timer start
		return setInterval(() => {
			const expirationTime = DateTime.fromJSDate(date).plus({ seconds: timeout });
			const diff = expirationTime.diff(DateTime.fromJSDate(new Date()), ["seconds"]);
			const remainingTimeInSeconds = Math.trunc(diff.seconds);

			setRemainingTime(remainingTimeInSeconds);
		}, 500);
	};

	const stopTimer = () => {
		if (!!timerId) {
			clearInterval(timerId);
			setTimerId(null);
		}
	};

	useEffect(() => {
		//If there was a previous timer running we stop it
		stopTimer();
		setTimerId(startTimer());
	}, [page, slug]);

	useEffect(() => {
		const track = async () => {
			await getHubspotTracking(page, slug);
		};
		if (!remainingTime && user) {
			stopTimer();
			track();
		}
	}, [remainingTime, user]);
};
