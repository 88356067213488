import { ServiceCallAction } from "../../store/middleware/serviceCallMiddleware";
import { ReminderSubscriptionCodec, ReminderSubscriptionResponse } from "../types/ReminderResponseCodec";

export const setEventReminder = (eventId: number, email: string): ServiceCallAction<ReminderSubscriptionResponse> => ({
	method: "POST",
	endpoint: "events/remindme",
	param: { event_id: eventId.toString(), email },
	serviceKey: "postRemindMe",
	responseDecoder: ReminderSubscriptionCodec,
	type: "SERVICE_CALL",
});
