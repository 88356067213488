import React from "react";
import styled from "styled-components/macro";
import { FutureEventTeam } from "../../../model/optimizedModel/future";
import { Primitive, ContentWithFutureSlug } from "../../../model/optimizedModel/general";
import { useMemoState } from "../../hooks/useMemoState";
import { flexGap } from "../../util/optimized/styles";
import { DateTime } from "luxon";
import { removeTimezoneFromDateString } from "../../util/optimized/dates";
import { getTypographyStyles } from "../Typography/Typography";
import { SantizedHTML, SantizedHTMLSpan } from "../SantizedHTML/SantizedHTML";
import { formatDate } from "../../util/dateFormat";

export interface BaseContentDisplayProps {
	quantity: number;
	disableSlugs?: boolean;
	additionalInfo?: AdditionalItemContentDisplayInfo[];
}

export interface AdditionalItemContentDisplayInfo {
	title?: string;
	content?: Primitive;
}

//TODO: Consider renaming to "EventDisplay", maybe.
//TODO: Consider creating a component to consume ContentWithSlugs?
//TODO: Add slug functionality.
export const ItemContentDisplay = (
	props: {
		title: ContentWithFutureSlug;
		//TODO: Consider revamping this prop name/functioanlity + type to be more "generic". The only thing this is being used for now is for futures teams.
		performers?: (ContentWithFutureSlug | FutureEventTeam)[];
		//TODO: Make this ContentWithSlug with optional slug when we switch back due to futures not having location slugs
		location: ContentWithFutureSlug;
		date?: string | null;
		displayHTML?: JSX.Element;
		endDate?: string | null;
	} & BaseContentDisplayProps
) => {
	const additionalInfo = useMemoState<AdditionalItemContentDisplayInfo[]>(() => {
		//TODO: Consider optimizing value reassignment?
		let value: AdditionalItemContentDisplayInfo[] = [
			{
				title: "QTY",
				content: props.quantity,
			},
		];
		if (props.additionalInfo) {
			value = [...value, ...props.additionalInfo];
		}
		return value;
	}, [props.additionalInfo]);

	/*
  const dateTimeFormat = useMemoState<Intl.DateTimeFormat>(() => {
    const value = new Intl.DateTimeFormat([], {
      //TODO: dateStyle is a valid property, consider contributing to TS to fix this.
      //@ts-ignore
      dateStyle: "full",
      //TODO: Consider using a diffrent way to detect if a date string contains time? 
      timeStyle: props.date?.includes("T") && props.date?.includes("Z") ? "short" : undefined,
    });
    return value;
  }, [props.date]);

  */

	const getHumanReadableDate = (eventDate?: Date | null) => {
		if (eventDate) {
			let date = DateTime.fromJSDate(eventDate).toFormat("ccc, LLL dd yyyy 'at' t");
			if (date.includes("3:30 AM")) {
				date = date.replace("3:30 AM", "TBD").replace("3:30", "TBD");
			}
			if (date.includes("0:00") && !date.includes("10:00") && !date.includes("20:00")) {
				date = date.replace("0:00", "TBD").replace("0:00", "TBD");
			}
			return date;
		} else {
			return "TBD";
		}
	};
	const date = useMemoState<Date | undefined>(() => {
		return props.date ? new Date(removeTimezoneFromDateString(props.date)) : undefined;
	}, [props.date]);

	const endDate = useMemoState<Date | undefined>(() => {
		return props.endDate ? new Date(removeTimezoneFromDateString(props.endDate)) : undefined;
	}, [props.endDate]);


	return (
		<Wrapper>
			<Title
			//to={props.title.slug}
			>
				{props.title.name && <SantizedHTMLSpan htmlString={props.title.name} />}
			</Title>
			{props.performers && (
				<Performers
				//to={props.performers[0].slug}
				>
					{props.performers.map((performer, index) => {
						let value = performer.name;
						if (index !== props.performers!.length - 1) {
							value += ", ";
						}
						return value ? <SantizedHTML htmlString={value} /> : null;
					})}
				</Performers>
			)}
			<Subtitle
			//to={props.location.slug}
			>
				{props.location.name && <SantizedHTMLSpan htmlString={props.location.name} />}
			</Subtitle>
			<Subtitle>{!!date && !props.endDate && getHumanReadableDate(date)}
			{!!date && !!endDate  && 
				`${formatDate(date, "ccc, LLL d y t")} to ${formatDate(endDate, "ccc, LLL d y t")}`
			}</Subtitle>
			<Info>
				{props.displayHTML
					? props.displayHTML
					: additionalInfo.map((obj, index) => {
							return (
								<React.Fragment key={index}>
									{obj.content !== undefined && obj.content !== null && obj.content !== "" && (
										<span key={index}>
											&#8226; {!!obj.title ? `${obj.title}:` : ""} {obj.content && <SantizedHTMLSpan htmlString={obj.content as string} />}
										</span>
									)}
								</React.Fragment>
							);
					  })}
			</Info>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
	color: #555555;
	font-weight: 500;
`;

const Title = styled.span`
	${getTypographyStyles("bodyMedium", { fontWeight: "bold" })};
	margin-bottom: 5px;
`;

const Performers = styled.span`
	${getTypographyStyles("bodySmall", { fontWeight: "bold" })};
	margin-bottom: 8px;
`;

const Subtitle = styled.span`
	${getTypographyStyles("bodySmall", { fontWeight: 500 })}
	&:not(:nth-last-of-type(2)) {
		margin-bottom: 4px;
	}
`;

const Info = styled.span`
	${getTypographyStyles("bodySmall", { fontWeight: "normal" })}
	padding-top: 6px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	${flexGap(["2.5px", "3.5px"])}
`;
