import React, { ChangeEvent } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useMemoState } from "../../hooks/useMemoState";
import { InternalRoutes } from "../../Link";
import { isMobileApp } from "../../util/isMobileApp";
import { useIsMobileWeb } from "../../hooks/useIsMobileWeb";
import { useHistory } from "react-router-dom";
import { Checkbox } from "../Checkbox/Checkbox";

const DEFAULT_TEXT = "I agree to {0} and {1}.";

export const TermsAndConditionsCheckbox = (props: { text?: string; checked?: boolean; onChange?: (event: ChangeEvent<HTMLInputElement>) => void; className?: string; openLinksInNewTab?: boolean }) => {
	const history = useHistory();
	const isMobileWeb = useIsMobileWeb();
	const linkProps = useMemoState(() => {
		const value = {
			target: "_blank",
			rel: "noopener noreferrer",
		};
		return props.openLinksInNewTab && !isMobileApp ? value : {};
	}, [props.openLinksInNewTab]);

	const text = props.text || DEFAULT_TEXT;
	const [start, terms, middle, conditions, end] = text.split(/(\{0\}|\{1\})/);
	const isMobile = isMobileApp || isMobileWeb;
	return (
		<Container className={props.className}>
			<Checkbox
				checked={props.checked}
				onChange={props.onChange}
				id="agree"
				label={
					<>
						{start}
						{!isMobile ? (
							<Link {...linkProps} to={InternalRoutes.Terms}>
								SI Tickets Terms Of Use
							</Link>
						) : (
							<MobileButton onClick={() => history.push(InternalRoutes.Terms)}>SI Tickets Terms Of Use</MobileButton>
						)}
						{middle}
						{!isMobileApp ? (
							<Link {...linkProps} to={InternalRoutes.Privacy}>
								Privacy Policy
							</Link>
						) : (
							<MobileButton onClick={() => history.push(InternalRoutes.Privacy)}>Privacy Policy</MobileButton>
						)}
						{end}
					</>
				}
			/>
		</Container>
	);
};

const MobileButton = styled.div`
	background: none;
	border: none;
	display: inline;
	font: inherit;
	margin: 0;
	padding: 0;
	outline: none;
	outline-offset: 0;
	color: #0075ff;
	text-decoration: underline;
	cursor: pointer;
`;
const Container = styled.div`
	display: flex;
	text-align: left;
	justify-content: flex-start;
	align-items: center;
	font-size: min(15px, 1rem);
	input {
		margin: 0;
	}
	label {
		margin-left: 15px;
		line-height: 19px;
	}
	a {
		color: #0075ff;
		text-decoration: underline;
	}

	${Checkbox.CheckboxElement} {
		margin-right: 0px;
	}
`;
