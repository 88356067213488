import { ModalPopup } from "../ModalPopup/ModalPopup";
import { ModalCloseButton } from "../Modal/ModalCloseButton";
import React from "react";
import styled from "styled-components/macro";
import { EventData } from "../../../model/EventPage";
import { Nulleable } from "../../../model/Utils";
import { ModalButton, ModalButtonContainter } from "../../util/ModalButton";
import { getHumanReadableDate } from "../../util/getHumanReadableDate";

interface EventInfoPopupProps {
	show: boolean;
	onClose: () => void;
	eventData: Nulleable<EventData>;
}

export const EventInfoPopup = ({ eventData, show, onClose }: EventInfoPopupProps) => {
	if (!show || !eventData) {
		return null;
	}

	const date = getHumanReadableDate(eventData.date);
	const location = eventData.venueLocation || "";

	return (
		<ModalPopup onDidClose={onClose} centerHeaderElements="Details" rightHeaderElements={(onClose: any) => <ModalCloseButton onClick={onClose} />}>
			<Container>
				<Body>
					<EventName>{eventData.name}</EventName>
					{eventData.venueName && `${eventData.venueName}, `} {location} on date: {date}
					<ModalButtonContainter>
						<ModalButton onClick={onClose}>Got It</ModalButton>
					</ModalButtonContainter>
				</Body>
			</Container>
		</ModalPopup>
	);
};

const Body = styled.div`
	font-family: Montserrat;
	font-size: 16px;
	line-height: 24px;
	color: #000;
	padding: 0px 20px;
	font-weight: 500;
`;

const EventName = styled.div`
	font-weight: 700;
	margin: 10px 0px;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 530px;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 20px 15px 24px;
`;
