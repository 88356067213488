import React, { CSSProperties, MouseEventHandler } from "react";
import styled from "styled-components/macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronUp, faChevronRight, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { getAppColor } from "../../util/appColors";

export const CarouselArrow = (
	{
		size = 14,
		weight = 1,
		...props
	}: {
	style?: CSSProperties,
	onClick?: MouseEventHandler<HTMLDivElement>,
	className?: string,
	size?: number,
	weight?: number,
	color?: string,
	inlineButtons?: boolean,
	direction?: string,
}) => {
	const icon = (props.direction &&
		(props.direction === "right" ? faChevronRight : props.direction === "left" ? faChevronLeft : props.direction === "up" ? faChevronUp : faChevronDown)) ||
		faChevronDown;

	return (
		<Arrow className={props.className}
					 onClick={props.onClick} size={size} inlineButtons={props.inlineButtons}>
			<FontAwesomeIcon icon={icon} color={props.color || getAppColor("darkGrey", "dark")}
											 style={{ ...props.style, fontWeight: weight }} />
		</Arrow>
	);
};

const Arrow = styled.div<
	{
		size: number,
		inlineButtons?: boolean
	}
	>`
padding: ${
	props => props.inlineButtons ? "" : props.size * 0.4 + "px " + props.size * 0.72 + "px !important"
};
cursor: pointer;
margin: 0;
display: flex;
justify-content: center;
align-items: center;
border: none;
outline: none;
background: none;
position: relative;
`;