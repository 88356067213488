import { Reducer } from "redux";
import { Category } from "../../model/Category";

interface CategoriesState {
	categories: Category[];
	subcategories: {
		[parent: string]: Category[];
	};
}

const defaultState: CategoriesState = {
	categories: [],
	subcategories: {},
};

export const categoriesReducer: Reducer<CategoriesState, CategoriesActions> = (state: CategoriesState = defaultState, action: CategoriesActions) => {
	switch (action.type) {
		case "UPDATE_CATEGORIES":
			return { ...state, categories: action.categories };
		case "UPDATE_SUBCATEGORIES":
			return {
				...state,
				subcategories: { ...state.subcategories, [action.parent]: action.categories },
			};
		default:
			return state;
	}
};

export type CategoriesActions =
	| {
			type: "UPDATE_CATEGORIES";
			categories: Category[];
	  }
	| {
			type: "UPDATE_SUBCATEGORIES";
			parent: string;
			categories: Category[];
	  };
