import React, { CSSProperties } from "react";
import styled from "styled-components/macro";
import { useCart } from "../../../services/hooks";
import { useMemoState } from "../../hooks/useMemoState";
import { getTypographyStyles } from "../Typography/Typography";

export const DeliveryNote = (props: { style?: CSSProperties }) => {
	const { delivery } = useCart();

	const notes = useMemoState<string | undefined>(() => {
		const value = delivery?.options[delivery.selected].notes;
		return value;
	}, [delivery?.options, delivery?.selected]);

	return (
		<>
			{notes && (
				<Note style={props.style}>
					<Title>Notes</Title>
					<Content>{notes}</Content>
				</Note>
			)}
		</>
	);
};

const Note = styled.span`
	display: flex;
	flex-direction: column;
	${getTypographyStyles("bodySmall", {
		fontWeight: 400,
	})}
	strong {
		margin-bottom: -1px;
	}
	text-align: left;
`;

const Title = styled.span`
	margin: 10px 0;
	text-transform: uppercase;

	${getTypographyStyles("bodySmall", {
		color: "dark",
		colorType: "dark",
		fontWeight: "bold",
	})};
`;

const Content = styled.span`
	${getTypographyStyles("bodySmall", {
		color: "darkGrey",
		colorType: "dark",
		fontWeight: "normal",
	})};
`;
