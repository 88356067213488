import React from "react";
import styled from "styled-components";
import { ButtonLink } from "../ButtonLink/ButtonLink";
import { FontFamily } from "../../Typography/Typography";
import { breakpoints } from "../../../util/breakpoints";
import { useMediaQuery } from "../../../hooks/useMediaQuery";

type Props = {
	image: string;
	title: string;
	dateLocation: string;
	link: string;
	text: string;
	index: number;
	button?: boolean;
	linkText?: string;
};

const Card = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 4em;
	width: 100%;
	max-width: 1150px;

	@media (max-width: ${breakpoints.tablet}) {
		flex-direction: column;
		padding: 0;
		margin-top: 2em;
		margin-bottom: 2em;
	}
	@media (max-width: ${breakpoints.mobile}) {
		flex-direction: column;
		margin-bottom: 2em;
		margin-top: 2em;
		padding: 0;
	}
`;

const Image = styled.img`
	width: 50%;
	max-width: 500px;
	height: auto;
	object-fit: contain;

	@media (max-width: ${breakpoints.tablet}) {
		width: 80%;
	}
	@media (max-width: ${breakpoints.mobile}) {
		width: 80%;
	}
`;

const Description = styled.div`
	width: 50%;
	max-width: 600px;
	margin-left: 20px;
	text-align: left;

	@media (max-width: ${breakpoints.tablet}) {
		width: 80%;
	}
	@media (max-width: ${breakpoints.mobile}) {
		width: 80%;
	}
`;

const Title = styled.h2`
	font-size: 24px;
	margin-bottom: 10px;
	text-transform: uppercase;
	font-size: 40px;
	font-family: ${FontFamily.Solano};
	font-weight: 700;
	margin-top: 0.5em;
	text-align: left;
	color: #000000;
`;

const Text = styled.p`
	font-size: 14px;
	font-family: ${FontFamily.Poppins};
	font-weight: normal;
	margin-top: 1em;
	line-height: 20px;
	margin-bottom: 2em;
	text-align: left;
	color: #000000;
`;

const DateLocation = styled.p`
	text-transform: uppercase;
	font-size: 20px;
	font-family: ${FontFamily.Solano};
	font-weight: 700;
	margin-top: 0;
	margin-bottom: 1em;
	text-align: left;
	color: #d94e41;
`;

const ButtonsDesktop = styled.div`
	display: flex;
	gap: 10px;
`;

const ButtonsMobile = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
`;

const EventCard = ({ image, title, text, dateLocation, link, index, button, linkText }: Props) => {
	const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);
	return (
		<Card>
			{isTablet ? (
				<>
					<Image src={image} alt={title} />
					<Description>
						<Title>{title}</Title>
						<DateLocation>{dateLocation}</DateLocation>
						<Text>{text}</Text>
						{index === 0 ? (
							<ButtonsMobile>
								<ButtonLink href={"/event/2-day-pass---hot-summer-classic-7v7-tackle-flag-tournament-may-20-21"}>TWO - DAY PASS</ButtonLink>
								<ButtonLink href={"/event/saturday-only---hot-summer-classic-7v7-tackle-flag-tournament"}>SATURDAY ONLY</ButtonLink>
								<ButtonLink href={"/event/sunday-only---hot-summer-classic-7v7-tackle-flag-tournament"}>SUNDAY ONLY</ButtonLink>
							</ButtonsMobile>
						) : index === 1 ? (
							button && <ButtonLink href={link}>{linkText || "GET TICKETS"}</ButtonLink>
						) : (
							button && <ButtonLink href={link}>{linkText || "Coming Soon"}</ButtonLink>
						)}
					</Description>
				</>
			) : (
				<>
					{index % 2 === 1 ? (
						<>
							<Description>
								<Title>{title}</Title>
								<DateLocation>{dateLocation}</DateLocation>
								<Text>{text}</Text>
								{index === 1 ? (
									button && <ButtonLink href={link}>{linkText || "GET TICKETS"}</ButtonLink>
								) : (
									button && <ButtonLink href={link}>{linkText || "Coming Soon"}</ButtonLink>
								)}
							</Description>
							<Image src={image} alt={title} />
						</>
					) : (
						<>
							<Image src={image} alt={title} />
							<Description>
								<Title>{title}</Title>
								<DateLocation>{dateLocation}</DateLocation>
								<Text>{text}</Text>
								{index === 0 ? (
									<ButtonsDesktop>
										<ButtonLink href={"/event/2-day-pass---hot-summer-classic-7v7-tackle-flag-tournament-may-20-21"}>TWO - DAY PASS</ButtonLink>
										<ButtonLink href={"/event/saturday-only---hot-summer-classic-7v7-tackle-flag-tournament"}>SATURDAY ONLY</ButtonLink>
										<ButtonLink href={"/event/sunday-only---hot-summer-classic-7v7-tackle-flag-tournament"}>SUNDAY ONLY</ButtonLink>
									</ButtonsDesktop>
								) : (
									button && <ButtonLink href={link}>{linkText || "Coming Soon"}</ButtonLink>
								)}
							</Description>
						</>
					)}
				</>
			)}
		</Card>
	);
};

export default EventCard;
