import React, { useEffect, useReducer, useState } from "react";
import { TicketTransaction, TicketTransactionOrderStatus } from "../../../model/optimizedModel/myAccount";
import { getMyAccountTickets, postAcceptTicketsTransfer } from "../../../services/myAccount";
import { MyAccountPage } from "./MyAccountPage";
import { MyAccountTicket } from "./Optimized/MyAccountTicket";
import { MyAccountItemsList } from "./MyAccountItemsList";
import toast from "react-hot-toast";
import { useToastRemoval } from "../../hooks/useToastRemoval";
import { Config } from "../../../Config";
import { useLocation } from "react-router-dom";

export const Tickets = () => {
	//TODO: Consider creating useAsyncMemoState or something along those lines to improve this code?
	const [tickets, setTickets] = useState<TicketTransaction[]>();
	const [loading, setLoading] = useState(true);
	const params = new URLSearchParams(useLocation().search);
	const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

	const getMyTickets = () => {
		getMyAccountTickets()
			.then(result => {
				const tickets = result.data.map(d => {
					const hasPendingTransfer: boolean = !!d.ticketGroup && !!d.ticketGroup.tickets && (d.order.total === undefined || d.order.total === null) && 
					d.ticketGroup.tickets.some(t => 
					(t.status && t.status === "pending"));
					return {
					...d,
					hasPendingTransfer
					}
				})
				setTickets(tickets);
			})
			.catch(error => {
				if (typeof error === "string") {
					toast.error(error);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	}

	const acceptTransfer = (transferId: number) => {
		postAcceptTicketsTransfer(+transferId).then(result => {
			params.delete("transferId")
			toast.success("Transfer Accepted")
			getMyTickets()
			//this is stupid we need to fix these components because they do not render properly
			window.location.reload();
		}).catch(error => {
			if (typeof error === "string") {
				toast.error(error);
			}
		})
	}
	
	useEffect(() => {	
		const transferId = params.get("transferId");
		if(transferId && !isNaN(+transferId)){
			acceptTransfer(+transferId)
		}
		getMyTickets()
	}, []);

	useToastRemoval();

	return (
		<MyAccountPage
			mobileTitle="Tickets"
			columns={[
				<MyAccountItemsList<TicketTransaction>
					items={tickets}
					itemsName="Tickets"
					loading={loading}
					renderItem={props => {
						if (props.orderStatus !== TicketTransactionOrderStatus.Failed && props.orderStatus !== TicketTransactionOrderStatus.Unconfirmed) {
							return <MyAccountTicket {...props} acceptTransfer={acceptTransfer} />;
						}
					}}
				/>,
			]}
		/>
	);
};
