import * as t from "io-ts";

export const ConferenceCodec = t.strict({
	id: t.number,
	title: t.string,
	sport_id: t.number,
	image: t.union([t.string, t.undefined, t.null]),
});

export const ConferencesCodec = t.array(ConferenceCodec);

export type Conference = t.OutputOf<typeof ConferenceCodec>;
export type ConferencesResponse = t.OutputOf<typeof ConferencesCodec>;
