import { PaymentMethodType } from "./model/optimizedModel/braintree";

declare global {
	interface Window {
		_env_?: {
			REACT_APP_API: string;
			REACT_APP_RESOURCES: string;
			REACT_APP_VERSION: string;
			GOOGLE_CLIENT_ID: string;
			FACEBOOK_APP_ID: string;
			APPLE_CLIENT_ID: string;
			APPLE_REDIRECT_URI: string;
			SEATICS_WEBSITE_CONFIG_ID: number;
			SEATICS_CONSUMER_KEY: string;
			SEATICS_API_URL: string;
			MIXPANEL_KEY: string;
			RISKIFIED_STORE_DOMAIN: string;
			PLATFORM_OS: "web" | "ios" | "android";
			RECAPTCHA_SITEKEY: string;
			EVENT_CUBE_KEY: string;
			//TODO: Remove ?
			APP_ENV?: "dev" | "qa" | "qa1" | "qa2" | "qa3" | "uat" | "prod";
			DOMAIN_KEY: string;
			DISABLED_PAYMENT_METHODS: Array<PaymentMethodType>;
		};
	}
}

export const Config = {
	getCDNUrl: (from?: string | null) => {
		return `https://cdn.sitickets.com${from}`;
	},

	// TO DO: Delete the following function if in the future the env variables change so that the APP_ENV actually matches the environment name, currently the mapping is as follows:
	// qa1, qa2 and qa3 all map to "qa"
	// uat maps to "uat"
	// staging maps to "prod" (this mapping is the one that is causing trouble when using the cdn)
	getCDNFolder: () => {
		switch (window._env_?.APP_ENV) {
			case "dev":
				return "dev";
			case "qa1":
			case "qa2":
			case "qa3":
				return "qa";
			case "uat":
			case "prod":
				return "staging";
			default:
				return "dev";
		}
	},

	getBoxOfficeUrl: () => {
		const env = () => {
			switch (window._env_?.APP_ENV) {
				case "dev":
					return "dev.";
				case "qa1":
				case "qa2":
				case "qa3":
					return "qa.";
				case "uat":
					return "uat.";
				case "prod":
					return "";
				default:
					return "qa1.";
			}
		};
		return `https://${env()}api.boxoffice.sitickets.com`;
	},

	getApiUrl: () => {
		return window._env_?.REACT_APP_API;
	},

	getServiceEnv: () => {
		const env = window._env_?.APP_ENV;
		if (env === "qa1" || env === "qa2" || env === "qa3") {
			return "qa";
		}
		return env || "qa";
	},

	getResourceUrl: (from?: string | null) => {
		if (window._env_) {
			return `${window._env_.REACT_APP_RESOURCES}${from}`;
		} else {
			return `https://api.qa1.sitickets.com${from}`;
		}
	},

	getServiceUrl: (from: string) => {
		if (window._env_) {
			return `${window._env_.REACT_APP_API}${from}`;
		} else {
			return `https://api.qa1.sitickets.com${from}`;
		}
	},

	getGoogleClientId: () => {
		return window._env_?.GOOGLE_CLIENT_ID || "";
	},

	getFacebookAppId: () => {
		return window._env_?.FACEBOOK_APP_ID || "";
	},
	getAppleClientId: () => {
		return window._env_?.APPLE_CLIENT_ID || "com.sitickets.sitix";
	},
	getAppleRedirectURI: () => {
		return window._env_?.APPLE_REDIRECT_URI || "https://qa-front.lunatix.com/login";
	},

	getSeaticsWebsiteConfigId: () => {
		return window._env_?.SEATICS_WEBSITE_CONFIG_ID || 23884;
	},

	getSeaticsConsumerKey: () => {
		return window._env_?.SEATICS_CONSUMER_KEY || "QgtuDr9cN1Vrt3VBRUv4_QMjrfAa";
	},
	getSeaticsAPI: () => {
		return window._env_?.SEATICS_API_URL || "https://www.tn-apis.com";
	},
	getMixPanelKey: () => {
		return window._env_?.MIXPANEL_KEY || "cc66bee59529019664fe57df60db1968";
	},
	getRiskifiedStoreDomain: () => {
		return window._env_?.RISKIFIED_STORE_DOMAIN || "sitickets.com";
	},
	getPlatformOS: () => {
		return window._env_?.PLATFORM_OS || "web";
	},
	getRecaptchaSiteKey: () => {
		return window._env_?.RECAPTCHA_SITEKEY || "6LezJq0dAAAAAPZX24Fj9YLpRlJtRErxJwqITZIa";
	},
	getDomainKey: () => {
		return window._env_?.DOMAIN_KEY || "XPnAH5bPgDKMZi2GlfSIFFlv03g9r5EJXLVj";
	},
	getDisabledPaymentMethods: () => {
		return window._env_?.DISABLED_PAYMENT_METHODS || [];
	},
};
