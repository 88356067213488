import React, { createContext, useEffect, useMemo, useRef, useState } from "react";
import { DateTime } from "luxon";
import { Search } from "../components/Search/Search";
import { Offer, CommonPage } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { setEventReminder } from "../../services/actions/reminderService";
import { ReminderModal } from "../components/Reminder";
import { home_offer } from "../../data";
import { Config } from "../../Config";
import styled, { css } from "styled-components/macro";
import { maxContentWidthPadding, maxContentWidthStyles, maxContentWidthValue } from "../util/maxContentWidth";
import { useHistory } from "react-router-dom";
import { getEventsPopular } from "../../services/popular";
import { getSiTailgatesEvents } from "../../services/siTailgates";
import { getAssetsHome } from "../../services/assets";
import { HomeAsset, HomeAssets } from "../../model/Asset";
import { isMobileApp } from "../util/isMobileApp";
import { discoverEvents } from "../../services/discover";
import { MetaDecorator, MetaTagData } from "../components/SEOMetadecorator/MetaDecorator";
import { getHomeAssets } from "../../services/assets";
import { SingleAd } from "../components/HomeAds/SingleAd";
import { MultiAd } from "../components/HomeAds/MultiAd";
import { Carousel } from "../components/NewCarousel/Carousel";
import download_app_ad_image from "../resource/image/download_app_ad.png";
import { breakpoints } from "../util/breakpoints";
import { Typography } from "../components/Typography/Typography";
import { MobileStoreButton } from "../components/MobileStoreButtons/MobileStoreButton";
import { getAppColor } from "../util/appColors";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { ThumbnailCarouselRow } from "../components/Thumbnail/ThumbnailCarouselRow";
import { LocationPickerHeader } from "../components/LocationPickerHeader/LocationPickerHeader";
import { SkeletonWrapper } from "../components/SkeletonWrapper/SkeletonWrapper";
import { useHiddenNavbarSearch, useInitiallyTransparentNavbar, useNewNavbar } from "../hooks/navbar";
import { ReactComponent as SITicketsLogo } from "../resource/assets/si_tickets_logo_unstacked_cropped.svg";
import { useOpenAdLink } from "../hooks/useOpenAdLink";
import { FullScreenLoader } from "../components/Loader";
import { fullPageWidthCarouselRowWithRightPadding } from "../components/CarouselRow/consts";
import { IconRow, IconRowItems } from "../components/IconRow/IconRow";

export const HomeSearchSelectedContext = createContext({
	homeSearchSelected: false,
	setHomeSearchSelected: (homeSearchSelected: boolean) => {},
});

const defaultLayoutState = {
	header: {
		headerImage: "",
		headerTitle: "",
		thumbnails: [{ image: "", url: "", title: "" }],
	},
	desktopContent: [],
	mobileContent: [],
};

export const Home = ({ preview = false }: { preview?: boolean }) => {
	const dispatch = useDispatch();

	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	const location = useSelector(state => state.persistent.location);

	const [homeAssets, setHomeAssets] = useState<HomeAsset[]>();
	const [homeLayoutAssets, setHomeLayoutAssets] = useState<HomeAssets>(defaultLayoutState);

	useEffect(() => {
		getAssetsHome().then(result => {
			setHomeAssets(result.data);
		});

		getHomeAssets({ preview: preview }).then(result => {
			setHomeLayoutAssets(result.data);
		});
	}, []);

	//Reminder modal
	const [reminder, setReminder] = useState({ open: false, eventId: 0 });

	const closeReminder = () => setReminder({ open: false, eventId: 0 });
	const submitReminder = (email: string) => dispatch(setEventReminder(reminder.eventId, email));

	const [mostPopular, setMostPopular] = useState<any[]>([]);
	const [localEvents, setLocalEvents] = useState<any[]>([]);
	const [categories, setCategories] = useState<any[]>([]);
	const [siTailgatesEvents, setSiTailgatesEvents] = useState<any[]>([]);
	const [localEventsFound, setLocalEventsFound] = useState(true);

	const [localEventsLoaded, setLocalEventsLoaded] = useState(false);
	const [mostPopularLoaded, setMostPopularLoaded] = useState(false);
	const [siTailgatesLoaded, setSiTailgatesLoaded] = useState(false);
	const [assetsLoaded, setAssetsLoaded] = useState(false);

	const { categories: categoriesState } = useSelector(state => state.persistent.categories);

	useEffect(() => {
		if (localEventsLoaded && mostPopularLoaded && categoriesState.length && siTailgatesLoaded) {
			setAssetsLoaded(true);
		}
	}, [localEventsLoaded, categoriesState, mostPopularLoaded, siTailgatesLoaded]);

	useEffect(() => {
		getEventsPopular()
			.then(result => {
				const newMostPopular = result.data?.map(item => ({
					title: item.name,
					titleLink: "performer/" + item.slug,
					background: item.image ? Config.getResourceUrl(item.image) : null,
					onClick: () => {
						history.push("performer/" + item.slug);
					},
				}));
				setMostPopular(newMostPopular);
			})
			.finally(() => {
				setMostPopularLoaded(true);
			});
	}, []);

	useEffect(() => {
		if ([...homeLayoutAssets.desktopContent, ...homeLayoutAssets.mobileContent].filter(layoutAsset => layoutAsset.type === "thumbnails").some(layoutAsset => layoutAsset.endpoint === "sitailgates")) {
			getSiTailgatesEvents()
				.then(events => {
					const newSiTailgates = events.data.events.map(event => ({
						title: event.event_name,
						titleLink: "event/" + event.event_slug,
						subtitle: event.venue_name,
						secondarySubtitle: event.occurs_at ? renderDate(new Date(event.occurs_at)) : "TBD",
						background: Config.getResourceUrl(event.event_image),
						onClick: () => {
							history.push("event/" + event.event_slug);
						},
					}));
					setSiTailgatesEvents(newSiTailgates);
				})
				.finally(() => {
					setSiTailgatesLoaded(true);
				});
		} else {
			setSiTailgatesLoaded(true);
		}
	}, [homeLayoutAssets]);

	useEffect(() => {
		if (categoriesState.length) {
			setCategories(
				categoriesState.map(category => {
					return {
						...category,
						onClick: () => {
							history.push(category.slug === "sports" ? "sport/sports" : `category/${category.slug}`);
						},
						background: category.image ? Config.getResourceUrl(category.image) : category.image,
					};
				})
			);
		}
	}, [categoriesState]);

	useEffect(() => {
		discoverEvents(location, true, "home")
			.then(events => {
				const localEvents = events.data.events.map(event => ({
					title: event.event_name,
					titleLink: `event/${event.event_slug}`,
					subtitle: event.venue_name,
					secondarySubtitle: event.occurs_at ? renderDate(new Date(event.occurs_at)) : "TBD",
					background: Config.getResourceUrl(event.event_image),
					onClick: () => {
						history.push(`event/${event.event_slug}`);
					},
				}));
				setLocalEvents(localEvents);
				if (!events.data.events.length) {
					setLocalEventsFound(false);
					discoverEvents(null, undefined, "home").then(events => {
						const localEvents = events.data.events.map(event => ({
							title: event.event_name,
							slug: `event/${event.event_slug}`,
							subtitle: event.venue_name,
							secondarySubtitle: event.occurs_at ? renderDate(new Date(event.occurs_at)) : "TBD",
							background: Config.getResourceUrl(event.event_image),
							onClick: () => {
								history.push(`event/${event.event_slug}`);
							},
						}));
						setLocalEvents(localEvents);
					});
				} else {
					setLocalEventsFound(true);
				}
				setLocalEvents(localEvents);
			})
			.finally(() => {
				setLocalEventsLoaded(true);
			});
	}, [location]);

	const carousel = useRef<HTMLDivElement>(null);

	const [homeSearchSelected, setHomeSearchSelected] = useState(false);

	let scrollingToTop = false;

	const onWindowUpdate = () => {
		const scrollPosition = document.documentElement.scrollTop;
		const carouselElement = carousel.current;
		const carouselHeight = carouselElement?.clientHeight;
		if (carouselHeight) {
			let newOpacity = (carouselHeight - scrollPosition) / carouselHeight;
			if (newOpacity < 0 && Number(carouselElement?.style?.opacity) > 0) {
				newOpacity = 0;
			}
			if (!(newOpacity < 0) && carouselElement?.style) {
				carouselElement.style.opacity = String(newOpacity);
			}
		}
		if (homeSearchSelected && !scrollingToTop && window.scrollY) {
			setHomeSearchSelected(false);
		}
	};

	useEffect(() => {
		const windowUpdateListeners = ["scroll", "resize"];
		windowUpdateListeners.forEach(eventListener => {
			window.addEventListener(eventListener, onWindowUpdate);
		});
		onWindowUpdate();
		return () => {
			windowUpdateListeners.forEach(eventListener => {
				window.removeEventListener(eventListener, onWindowUpdate);
			});
		};
	}, [homeSearchSelected]);

	const history = useHistory();

	const featuredContent = useMemo((): any => {
		return {
			discover: localEvents,
			popular: categories,
			sitailgates: siTailgatesEvents,
		};
	}, [localEvents, categories, siTailgatesEvents]);

	const assetsLoading = useMemo((): boolean => {
		return !assetsLoaded || homeLayoutAssets === defaultLayoutState;
	}, [assetsLoaded, homeLayoutAssets]);

	const isSmallScreen = useMediaQuery(`(max-width: ${breakpoints.extraSmall})`);

	useInitiallyTransparentNavbar();

	useHiddenNavbarSearch();

	useNewNavbar();

	const navbarHeight = useSelector(state => state.transient.navbar.height);

	const openAdLink = useOpenAdLink();

	const [homeRowIconItems, setHomeRowIconItems] = useState<IconRowItems[]>([]);

	useEffect(() => {
		const items: IconRowItems[] = homeLayoutAssets.header.thumbnails.map(thumbnail => ({
			title: thumbnail.title,
			url: thumbnail.url,
		}));
		if (items) {
			setHomeRowIconItems(items);
		}
	}, [homeLayoutAssets.header.thumbnails]);

	return (
		<CommonPage isSitixLogoSelected hideSearch preview={preview}>
			<MetaDecorator title={MetaTagData.Home.title} description={MetaTagData.Home.description} preventCrawl={preview} />
			<ReminderModal open={reminder.open} onClose={closeReminder} onSubmit={submitReminder} />
			<div
				css={`
					padding: 0 ${maxContentWidthPadding}px;
					padding-bottom: 36px;
					padding-top: ${isMobileApp ? "20px" : `${navbarHeight}px`};
					display: flex;
					flex-direction: column;
					align-items: center;
					background-image: linear-gradient(to bottom, rgb(0 0 0 / 40%) 0%, rgb(0 0 0)), url(${Config.getCDNUrl(homeLayoutAssets.header.headerImage)});
					background-position-x: center;
					background-position-y: top;
					background-repeat: no-repeat;
					background-size: cover;
				`}
			>
				<div
					css={`
						display: flex;
						flex-direction: column;
						align-items: center;
						max-width: ${maxContentWidthValue}px;
					`}
				>
					{isMobileApp && (
						<SITicketsLogo
							css={`
								fill: white;
								max-width: 150px;
								margin-bottom: 17px;
							`}
						/>
					)}
					<HeaderTitle type="display1" color="light" loading={!homeLayoutAssets.header}>
						{homeLayoutAssets.header && homeLayoutAssets.header.headerTitle}
					</HeaderTitle>
					<SearchContainer mobile={isMobile}>
						<StyledSearch isMobile={isMobile} placeholder="Search by team, artist, event or venue" hideLocation />
					</SearchContainer>
				</div>
			</div>
			<IconRow items={homeRowIconItems} maxContainerWidth={700} paddingX={20} />
			<FullScreenLoader show={!homeAssets} />
			<HomeContentContainer>
				{homeLayoutAssets[isMobile ? "mobileContent" : "desktopContent"]?.map((element, index) => {
					switch (element.type) {
						case "thumbnails":
							const eventsFound = element.endpoint ? featuredContent[element.endpoint].length > 0 : mostPopular.length > 0;
							return (
								<Wrapper key={index}>
									<CarouselHeaderWrapper>
										<LocationPickerHeader headerTitle={eventsFound && element.title ? element.title : ""} showLocationPicker={element.showLocationPicker} viewAllLink={eventsFound && element.endpoint && element.endpoint !== "popular" ? element.endpoint : undefined} headerTitleLoading={assetsLoading} viewAllLinkLoading={assetsLoading} displayNoEventsFoundMessage={!localEventsFound && localEvents.length > 0} customNoEventsFoundMessage="No events were found on the selected location, showing events in all locations:" />
									</CarouselHeaderWrapper>
									<StyledThumbnailCarouselRow
										//TO DO: improve the loading conditions to avoid duplicate code
										loading={assetsLoading}
										titlesAreLoading={assetsLoading}
										subtitlesAreLoading={assetsLoading}
										secondarySubtitlesAreLoading={assetsLoading}
										thumbnails={element.endpoint ? featuredContent[element.endpoint] : mostPopular}
										rowsAmountPreference={element.rows || 2}
									/>
								</Wrapper>
							);
						case "multiAd":
							return (
								<AdContainer>
									<MultiAd key={index} loading={assetsLoading} {...element} />
								</AdContainer>
							);
						case "singleAd":
							return (
								<AdContainer>
									<SingleAd {...element} key={index} loading={assetsLoading} image={Config.getCDNUrl(element.image)} />
								</AdContainer>
							);
						case "slideAd":
							return (
								<SlideContainer key={index}>
									<SkeletonWrapper loading={assetsLoading} style={{ zIndex: 0 }}>
										<Carousel autoplay hideIndicators interval={10000}>
											{element.items?.reduce((result, item, index) => {
												if (!isMobileApp || item?.url !== "/bestbets") {
													result.push(
														<img
															key={index}
															style={{ height: `calc(100vw - ${maxContentWidthPadding * 2}px)` }}
															src={Config.getCDNUrl(item.image)}
															alt="Ad slide"
															onClick={() => {
																openAdLink(item?.url || "");
															}}
														/>
													);
												}
												return result;
											}, [] as JSX.Element[])}
										</Carousel>
									</SkeletonWrapper>
								</SlideContainer>
							);
						default:
							return null;
					}
				})}
				{!isMobileApp && (
					<BottomAdSectionContainer>
						<DownloadTheAppImage src={download_app_ad_image} alt="Download the app" />
						<DownloadTheAppContainer>
							<DecorationLine />
							<DownloadTextContainer>
								<Typography type="heading1">Download our app today</Typography>
							</DownloadTextContainer>

							<MobileStoreButtonsContainer>
								<MobileStoreButton mobileStore="appStore" size={isSmallScreen ? "small" : "big"} />
								<MobileStoreButton mobileStore="googlePlay" size={isSmallScreen ? "small" : "big"} />
							</MobileStoreButtonsContainer>
						</DownloadTheAppContainer>
					</BottomAdSectionContainer>
				)}
			</HomeContentContainer>
			<Offer {...home_offer} />
		</CommonPage>
	);
};

const SearchContainer = styled.div<{
	mobile: boolean;
}>`
	padding-top: 28px;
	${props => {
		return `
      ${
				props.mobile &&
				`
	.custom-search-container {
	  margin-bottom: 0px !important;
	}
      `
			}
    `;
	}}
`;

const StyledSearch = styled(Search)<{
	isMobile?: boolean;
}>`
	${props =>
		props.isMobile
			? css`
					width: calc(100vw - 40px);
			  `
			: css`
					width: ${maxContentWidthValue * 0.66}px;
					max-width: 66vw;
					height: 61px;
			  `}
`;

const renderDate = (date: Date) => DateTime.fromJSDate(date).toUTC().toFormat("EEE. LLL d, yyyy h:mm a");

const HomeContentContainer = styled.div`
	display: flex;
	flex-direction: column;

	& > :first-child {
		padding-top: 60px;

		@media (max-width: ${breakpoints.mobile}) {
			padding-top: 40px;
		}
	}
`;

const BottomAdSectionContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	${maxContentWidthStyles};

	@media (max-width: ${breakpoints.tablet}) {
		flex-direction: column;
		align-items: center;
	}

	@media (max-width: ${breakpoints.mobile}) {
		align-items: initial;
	}
`;

const DownloadTheAppContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-grow: 1;
	max-width: 50%;

	@media (max-width: ${breakpoints.tablet}) {
		margin-top: 16px;
		max-width: 100%;
	}
`;

const MobileStoreButtonsContainer = styled.div`
	width: 100%;
	display: flex;
	margin-top: 24px;

	& > a:first-child {
		margin-right: 21px;

		@media (max-width: ${breakpoints.mobile}) {
			margin-right: 36px;
		}

		@media (max-width: 360px) {
			margin-right: 20px;
		}

		@media (max-width: 320px) {
			margin-right: 40px;
		}
	}
`;

const DecorationLine = styled.div`
	width: 72px;
	height: 4px;
	background: ${getAppColor("primary")};
	margin: 16px 0;
`;

const DownloadTextContainer = styled.div`
	display: flex;
	width: 100%;
	text-align: left;
`;

const horizontalPadding = "20px";
const Wrapper = styled.div`
	margin: 0px auto 40px;
	width: min(${maxContentWidthValue}px, calc(100vw - 2 * ${horizontalPadding}));
	overflow: hidden;
	padding: initial;

	@media (max-width: ${breakpoints.mobile}) {
		width: calc(100vw - ${horizontalPadding});
		padding-left: ${horizontalPadding};
	}
`;

const SlideContainer = styled.div`
	padding: 0 20px;
	margin-bottom: 40px;
`;

const CarouselHeaderWrapper = styled.div`
	@media (max-width: ${breakpoints.mobile}) {
		width: calc(100% - ${horizontalPadding});
	}
`;

const DownloadTheAppImage = styled.img`
	flex-grow: 1;
	max-width: 50% @media (max-width: ${breakpoints.tablet}) {
		max-width: 100%;
	}
`;

const HeaderTitle = styled(Typography)<{ loading: boolean }>`
	${props =>
		props.loading &&
		`
		width: 300px;
		align-self: center;
	`}
`;

const AdContainer = styled(Wrapper)`
	@media (max-width: ${breakpoints.mobile}) {
		width: min(${maxContentWidthValue}px, calc(100% - 2 * ${horizontalPadding}));
		padding: initial;
		margin: 0px auto 32px;
	}
`;

const StyledThumbnailCarouselRow = styled(ThumbnailCarouselRow)`
	@media (max-width: ${breakpoints.mobile}) {
		${fullPageWidthCarouselRowWithRightPadding};
	}
`;
