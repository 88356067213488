import React from "react";
import { Config } from "../../Config";
import { CommonPage } from "../components";
import { getAppColor } from "../util/appColors";
import { breakpoints } from "../util/breakpoints";
import { useMediaQuery } from "../hooks/useMediaQuery";
import LPEventCard from "../components/SwimLP/SwimLPCard/LPEventCard";
import { SwimLPLink } from "../components/SwimLP/SwimLPLink";
import { EventCardContent, SectionContainer, FashionSummitText } from "../components/SwimLP/StyledComponents";
import styled from "styled-components/macro";
import { InternalRoutes } from "../Link";
import { FontFamily } from "../components/Typography/Typography";

const paraisoLogo = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/swimLP/paraiso-logo.png`);
const asset1 = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/swimLP/asset-1.png`);
const asset2 = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/swimLP/asset-2.png`);
const asset3 = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/swimLP/asset-3.png`);
const asset4 = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/swimLP/asset-4.png`);
const asset6 = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/swimLP/asset-6.png`);
const asset7 = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/swimLP/asset-7.png`);
const tabletScreenAsset1 = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/swimLP/tablet-asset-1.png`);
const tabletScreenAsset2 = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/swimLP/tablet-asset-2.png`);
const tabletScreenAsset3 = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/swimLP/tablet-asset-3.png`);
const tabletScreenAsset4 = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/swimLP/tablet-asset-4.png`);
const tabletScreenAsset6 = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/swimLP/tablet-asset-6.png`);
const tabletScreenAsset7 = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/swimLP/tablet-asset-7.png`);
const SpacedDiv = styled.div`
	padding-bottom: 10px;
`;

const data = [
	{
		title: "PLATINUM VIP EXPERIENCE",
		subtitle: "4 DAY PASS",
		content: (
			<EventCardContent>
				FRONT ROW SEATING
				<br /> OPEN BAR
				<br /> VIP CONCIERGE
				<br /> VIP GIFT BAGS
				<br /> AFTERPARTY ACCESS
			</EventCardContent>
		),
		buttons: [<SwimLPLink href={InternalRoutes.Event("4-day-pass---paraiso-vip-experience-june-8-11")}>Get Tickets</SwimLPLink>],
		invert: false,
		desktopAsset: asset1,
		lgAsset: tabletScreenAsset1,
	},
	{
		title: "Gold VIP Experience",
		subtitle: "4 DAY PASS",
		content: (
			<EventCardContent>
				SECOND ROW SEATING
				<br /> OPEN BAR
				<br /> VIP CONCIERGE
			</EventCardContent>
		),
		buttons: [<SwimLPLink href={InternalRoutes.Event("4-day-pass---paraiso-vip-experience-june-8-11")}>Get Tickets</SwimLPLink>],
		invert: false,
		desktopAsset: asset2,
		lgAsset: tabletScreenAsset2,
	},
	{
		title: "BEACH BUNNY FASHION SHOW",
		subtitle: "JUNE 8",
		content: (
			<EventCardContent>
				FRONT ROW SEATING
				<br /> OPEN BAR
				<br /> VIP GIFT BAGS
			</EventCardContent>
		),
		buttons: [<SwimLPLink href={InternalRoutes.Event("beach-bunny-fashion-show-vip-experience")}>Get Tickets</SwimLPLink>],
		invert: false,
		desktopAsset: asset4,
		lgAsset: tabletScreenAsset4,
	},
	{
		title: "Summer Fashion Summit",
		subtitle: "JUNE 8-9",
		content: (
			<FashionSummitText>
				A two-day conference bringing together leading industry players from the swim and resort wear fields, top minds in marketing and e-commerce trends, and driving forces in sustainability solutions.
				<br />
				<br />
				Includes: <br />
				Two panel discussions (per day)
				<br />
				One masterclass (per day)
				<br />
				Networking luncheon
			</FashionSummitText>
		),
		buttons: [<SwimLPLink href={InternalRoutes.Event("-summer-fashion-summit")}>Get thursday pass</SwimLPLink>, <SwimLPLink href={InternalRoutes.Event("summer-fashion-summit---friday-only")}>Get Friday pass</SwimLPLink>, <SwimLPLink href={InternalRoutes.Event("2-day-pass---summer-fashion-summit-june-8-9")}>Get Two-Day pass</SwimLPLink>],
		invert: false,
		desktopAsset: asset3,
		lgAsset: tabletScreenAsset3,
	},
	{
		title: "LULI FAMA FASHION SHOW",
		subtitle: "JUNE 10",
		content: (
			<EventCardContent>
				FRONT ROW SEATING
				<br /> OPEN BAR
				<br /> VIP GIFT BAGS
				<br /> AFTERPARTY ACCESS
			</EventCardContent>
		),
		buttons: [<SwimLPLink href={InternalRoutes.Event("luli-fama-fashion-show-vip-experience")}>Get Tickets</SwimLPLink>],
		invert: true,
		desktopAsset: asset6,
		lgAsset: tabletScreenAsset6,
	},
	{
		title: "THE BLONDS FASHION SHOW",
		subtitle: "June 11",
		content: (
			<EventCardContent>
				<SpacedDiv> FRONT ROW SEATING </SpacedDiv>
				<SpacedDiv>OPEN BAR </SpacedDiv>
				<SpacedDiv> VIP GIFT BAGS </SpacedDiv>
				<SpacedDiv>
					{" "}
					$300 SHOPPING CREDIT AT{" "}
					<a
						css={`
							color: #0000ee;
							text-decoration: underline;
						`}
						href="https://theblondsny.com/"
					>
						WWW.THEBLONDSNY.COM
					</a>{" "}
				</SpacedDiv>
				<SpacedDiv> AFTERPARTY ACCESS </SpacedDiv>
			</EventCardContent>
		),
		buttons: [<SwimLPLink href={InternalRoutes.Event("the-blonds-fashion-show-vip-experience")}>Get Tickets</SwimLPLink>],
		invert: true,
		desktopAsset: asset7,
		lgAsset: tabletScreenAsset7,
	},
];

export const SwimRoute = () => {
	const isLargeScreen = useMediaQuery(`(max-width: ${breakpoints.large})`);
	const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);
	const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);

	return (
		<CommonPage>
			<div
				css={`
					position: relative;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					min-height: 65vh;
					max-height: 700px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
				`}
			>
				<div
					css={`
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
					`}
				>
					<video
						width="100%"
						height="100%"
						controls={false}
						autoPlay
						loop
						muted
						playsInline
						style={{
							objectFit: "cover",
						}}
					>
						<source src={Config.getCDNUrl(`/${Config.getCDNFolder()}/images/swimLP/background-video.mp4`)} type="video/mp4" />
					</video>
				</div>

				<div
					css={`
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						background: ${getAppColor("dark")};
						opacity: 0.3;
					`}
				/>
				<div
					css={`
						z-index: 1;
						padding: 0 10px;
					`}
				>
					<img
						src={paraisoLogo}
						alt="Paraiso"
						css={`
							max-width: min(300px, 100%);
						`}
					/>

					<h2
						css={`
							font-size: clamp(14px, 4vw, 24px);
							color: ${getAppColor("light")};
							text-transform: uppercase;
							font-weight: 400;
							line-height: clamp(24px, 4vw, 42px);
							font-family: ${FontFamily.AkzidenzGrotesk};
						`}
					>
						Join the ultimate swim week experience <br /> June 8-11, 2023
					</h2>
				</div>
			</div>

			<SectionContainer>
				<MultiCardContainer
					css={`
						@media (max-width: ${breakpoints.large}) {
							& > * {
								border-bottom: none;
							}
						}
					`}
				>
					{data.slice(0, 2).map((item, index) => {
						return (
							<LPEventCard src={isLargeScreen ? item.lgAsset : item.desktopAsset} displayAsRows={isTablet} key={index}>
								<LPEventCard.Content title={item.title} subtitle={item.subtitle} content={item.content} buttons={item.buttons} />
							</LPEventCard>
						);
					})}
				</MultiCardContainer>
			</SectionContainer>

			<SectionContainer>
				<MultiCardContainer
					css={`
						@media (max-width: ${breakpoints.large}) {
							grid-template-columns: repeat(1, 1fr);
							& > :nth-child(2) {
								border-left: 1px solid ${getAppColor("dark")};
							}

							@media (max-width: ${breakpoints.large}) {
								& > :nth-child(-n + 2) {
									border-bottom: 1px solid ${getAppColor("dark")};
								}

								& > *:not(:last-child) {
									border-bottom: none;
								}
							}
						}
					`}
				>
					{data.slice(2, 6).map((item, index) => {
						return (
							<LPEventCard src={isLargeScreen ? item.lgAsset : item.desktopAsset} displayAsRows={isTablet} key={index} invert={item.invert && !isLargeScreen}>
								<LPEventCard.Content title={item.title} subtitle={item.subtitle} content={item.content} buttons={item.buttons} />
							</LPEventCard>
						);
					})}
				</MultiCardContainer>
			</SectionContainer>
		</CommonPage>
	);
};

const MultiCardContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	& > :nth-child(2n) {
		border-left: none;
	}

	@media (max-width: ${breakpoints.large}) {
		grid-template-columns: repeat(1, 1fr);
		& > :nth-child(2n) {
			border-left: 1px solid ${getAppColor("dark")};
		}
	}
`;
