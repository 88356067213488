import styled from "styled-components/macro";
import { getAppColor } from "../../util/appColors";
import { FontFamily } from "../Typography/Typography";
import { breakpoints } from "../../util/breakpoints";

export const EventCardHeader = styled.p`
	font-size: clamp(24px, 2vw, 44px);
	text-transform: uppercase;
	font-weight: 700;
	line-height: clamp(26px, 2vw, 44px);
	font-family: ${FontFamily.AkzidenzGrotesk};
	text-align: start;
	color: ${getAppColor("dark")};
	margin-bottom: 10px;
`;

export const EventCardSubHeader = styled(EventCardHeader)`
	font-size: clamp(16px, 2vw, 20px);
	line-height: clamp(18px, 2vw, 20px);
	font-weight: 400;
`;

export const SectionContainer = styled.div`
	margin-top: 80px;

	@media (max-width: ${breakpoints.large}) {
		margin-top: 0;
	}
`;

export const EventCardContent = styled(EventCardSubHeader)`
	font-size: clamp(14px, 3vw, 18px);
	line-height: clamp(16px, 4vw, 22px);
	margin-top: 40px;
`;

export const FashionSummitText = styled(EventCardSubHeader)`
	text-transform: none;
	font-size: clamp(16px, 3vw, 22px);
	line-height: clamp(18px, 4vw, 26px);
	margin: 60px 0 20px;
`;
