import { store } from "../store/store";
import { apiClient } from "./apiClient";
import { BraintreeBillingAddress } from "../model/Braintree";
import { TokenizePayload } from "../model/Checkout";
import { setBraintreePaymentMethods, setBraintreeClientAuth } from "../store/actions/braintreeActions";
import { Address } from "../model/User";
import { braintreeGraphQLRequest } from "./braintreeGraphQLClient";
import { PaymentMethodWithToken, PaymentMethod, CardPaymentMethod, PayPalPaymentMethod, VenmoPaymentMethod } from "../model/optimizedModel/braintree";
import { PartialBy } from "../model/optimizedModel/general";

interface BillingAddress extends Address {
	firstName: string;
	lastName: string;
}

export function graphQLBraintreeTokenizeCreditCard({
	billingAddress,
	...creditCardInfo
}: {
	// Type definition comes from: https://graphql.braintreepayments.com/reference/#input_object--creditcardinput
	number: string;
	expirationYear: string;
	expirationMonth: string;
	cvv: string;
	cardholderName: string;
	billingAddress: BraintreeBillingAddress;
}) {
	const query = `
    mutation {
      tokenizeCreditCard(input: {
        creditCard: {
          number: "${creditCardInfo.number}",
          expirationYear: "${creditCardInfo.expirationYear}",
          expirationMonth: "${creditCardInfo.expirationMonth}",
          cvv: "${creditCardInfo.cvv}",
          cardholderName: "${creditCardInfo.cardholderName}",
          billingAddress: {
            streetAddress: "${billingAddress.streetAddress}",
            extendedAddress: "${billingAddress.extendedAddress}",
            locality: "${billingAddress.locality}",
            region: "${billingAddress.region}",
            postalCode: "${billingAddress.postalCode}",
            countryName: "${billingAddress.countryName}",
          },
        },
      }) {
        paymentMethod {
          id
          details {
            ... on CreditCardDetails {
              brandCode
              last4
              cardholderName
              billingAddress {
                addressLine1
                addressLine2
                fullName
                adminArea2
                adminArea1
                postalCode
                countryCode
                phoneNumber
                company
              }
            }
          }
        }
      }
    }
  `;

	console.log(query);

	return braintreeGraphQLRequest<{
		tokenizeCreditCard: {
			paymentMethod: {
				id: string;
				details: {
					//TODO: Correct billingAddress type when the endpoint is working, although it might be removed from the quey in the future.
					billingAddress: any;
					brandCode: string;
					cardholderName: string;
					last4: string;
				};
			};
		};
	}>(query);
}

export function graphQLBraintreeSupportedCardBrands() {
	return braintreeGraphQLRequest<{
		clientConfiguration: {
			creditCard: {
				supportedCardBrands: string[];
			};
		};
	}>(`
    query {
      clientConfiguration {
        creditCard {
          supportedCardBrands
        }
      }
    }
  `);
}

export function getBraintreeClientToken(): Promise<{
	token: string;
}> {
	return apiClient.get("braintree/client-token");
}

export function getBraintreePaymentMethods(): Promise<PaymentMethodWithToken[]> {
	return apiClient.get("braintree/payment-method");
}

export function getAndDispatchBraintreePaymentMethods() {
	getBraintreePaymentMethods().then(result => {
		store.dispatch(setBraintreePaymentMethods(result));
	});
}

export async function getAndDispatchBraintreeClientToken() {
	return getBraintreeClientToken().then(result => {
		store.dispatch(setBraintreeClientAuth(result.token));
		return result.token;
	});
}

export async function postBraintreePaymentMethodAndUpdate(tokenizePayload: TokenizePayload, billingAddress?: BillingAddress): Promise<PaymentMethodWithToken | void> {
	let paymentMethod: PaymentMethodWithToken;
	return postBraintreePaymentMethod(tokenizePayload, billingAddress)
		.then(result => {
			//@ts-ignore
			paymentMethod = result;
			return getAndDispatchBraintreePaymentMethods();
		})
		.then(() => {
			return paymentMethod;
		})
		.catch(error => {
			console.error(error);
		});
}

export async function postBraintreePaymentMethod(tokenizePayload: TokenizePayload, billingAddress?: BillingAddress): Promise<PaymentMethodWithToken> {
	return apiClient.post("braintree/payment-method", {
		deviceData: store.getState().persistent.braintree.dataCollector?.deviceData,
		...(window.location.pathname.includes("checkout/payment") && { refUrl: "checkout/payment", cartSessionId: store.getState().persistent.cart?.sessionId }),
		tokenizePayload,
		billingAddress,
	});
}

export function deleteBraintreePaymentMethod(token: string) {
	return apiClient.delete("braintree/payment-method/" + token);
}

//TODO: Consider undoing the change to make paymentMethod possibly undefined.
export function renderPaymentMethod(
	paymentMethod: PartialBy<PaymentMethod, "details"> | undefined,
	options?: {
		hideName?: boolean;
		hideParentheses?: boolean;
	}
): string | undefined {
	if (paymentMethod) {
		const getString = (
			name: string,
			text: string | undefined,
			getStringOptions?: {
				addParentheses: boolean;
			}
		) => {
			let value = text || "";
			if (text && !options?.hideParentheses && getStringOptions?.addParentheses) {
				value = "(" + text + ")";
			}
			if (!options?.hideName || !text) {
				value = name + " " + value;
			}
			return value;
		};
		switch (paymentMethod.type) {
			case "CreditCard":
				let paymentMethodDetails = (paymentMethod as CardPaymentMethod).details;
				return paymentMethodDetails ? getString(paymentMethodDetails.cardType, "ending in " + paymentMethodDetails.lastFour) : "";
			case "PayPalAccount":
				return getString("PayPal", (paymentMethod as PayPalPaymentMethod).details?.email, {
					addParentheses: true,
				});
			case "VenmoAccount":
				return getString("Venmo", "@" + (paymentMethod as VenmoPaymentMethod).details.username, {
					addParentheses: true,
				});
			default:
				return;
		}
	}
}
