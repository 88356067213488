import React from "react";
import styled from "styled-components/macro";
import { InlineStylesProps, StyledComponentProps } from "../../../model/optimizedModel/styles";
import { flexGap } from "../../util/optimized/styles";
import { Typography } from "../Typography/Typography";
import { BetOddsLabel, BetOddsLabelType } from "./BetOddsLabel";

export const BetEntity = ({
	layout = "row",
	...props
}: {
	name: React.ReactNode;
	initials?: React.ReactNode;
	oddsType?: BetOddsLabelType;
	layout?: "row" | "column";
	reverseItems?: boolean;
	customOddsLabelContent?: React.ReactNode;
} & StyledComponentProps &
	InlineStylesProps) => {
	return (
		<Wrapper
			className={props.className}
			style={{
				...props.style,
				textAlign: props.reverseItems ? "right" : "left",
				...(!!props.initials && {
					...(layout === "column" && {
						flexDirection: "column",
					}),
					...(layout === "row" && {
						alignItems: "center",
						...(props.reverseItems && {
							flexDirection: "row-reverse",
						}),
					}),
				}),
			}}
		>
			{!!props.initials && (
				<Typography
					type="heading1"
					style={
						layout === "row"
							? {
									["margin" + (props.reverseItems ? "Left" : "Right")]: 16,
							  }
							: undefined
					}
				>
					{props.initials}
				</Typography>
			)}
			<MainContent
				style={{
					...(layout === "column" && {
						flexDirection: "column",
						alignItems: props.reverseItems ? "flex-end" : "flex-start",
						height: "100%",
						justifyContent: "space-between",
					}),
					...(layout === "row" && {
						alignItems: "center",
						justifyContent: "space-between",
						...(props.reverseItems && {
							flexDirection: "row-reverse",
						}),
						...(!!props.initials && {
							flexWrap: "wrap",
						}),
					}),
				}}
			>
				<Typography type={props.initials ? "heading4" : "extraLargeSpecialBody"}>{props.name}</Typography>
				{props.oddsType && <BetOddsLabel type={props.oddsType} customContent={props.customOddsLabelContent} />}
			</MainContent>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
`;

const MainContent = styled.div`
	display: flex;
	${flexGap(["4px", "11.5px"])}
`;
