import { isMobileDevice } from "./isMobileDevice";
import { isMobileApp } from "./isMobileApp";
import { css } from "styled-components";

const conditionalMediaQuery = (condition: boolean, styles: string, width: string) => css`
	${condition
		? styles
		: `
    @media (max-width: ${width}) {
      ${styles}
    }
  `}
`;

export const mobileMediaQuery = (styles: string, width: string) => conditionalMediaQuery(isMobileDevice, styles, width);

export const mobileLayoutMediaQuery = (styles: string) => mobileMediaQuery(styles, "500px");

export const mobileWebOrAppMediaQuery = (styles: string, width: string) => conditionalMediaQuery(isMobileApp, styles, width);
