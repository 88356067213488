import styled, { css, keyframes } from "styled-components/macro";
import { ModalPopup } from "../ModalPopup/ModalPopup";
import eye from "../../resource/image/eye.svg";
import { getAppColor, hex2rgba } from "../../util/appColors";
import { breakpoints } from "../../util/breakpoints";
import { FontFamily } from "../Typography/Typography";

export const eventPageMobileMediaQuery = `(max-width: ${breakpoints.tablet})`;

//#region Map
export const PanelContainer = styled.div`
	position: relative;
	flex: 1;
	width: 100%;
	max-width: 526px;
	min-width: 440px;
	border-right: 1px solid lightgray;
	overflow-y: auto;

	@media ${eventPageMobileMediaQuery} {
		max-width: unset;
		border-right: none;
		min-width: unset;
	}
`;

export const Panel = styled.div`
	position: absolute;
	background: #fafafa;
	z-index: 1;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	height: 100%;
	transition: height 0.35s ease;
`;

export const Tab = styled.div<{ visible: boolean }>`
	display: ${props => (props.visible ? "block" : "none")};
`;

export const PanelBody = styled.div`
	flex: 1;
	overflow-y: scroll;
	width: 100%;
	overflow-x: hidden;
`;

export const PanelHeader = styled.div`
	height: auto;
	display: flex;
	flex-direction: row;
	background: #f5f5f5;
	border-bottom: 1px solid lightgray;
	z-index: 3;
	width: 100%;
	align-items: center;
	height: 56px;
	justify-content: flex-start;
	@media ${eventPageMobileMediaQuery} {
		border-top: 1px solid lightgray;
	}
`;

export const PanelTab = styled.div<{ selected: boolean }>`
	height: auto;
	color: #555555;
	font-family: Montserrat;
	font-size: 13px;
	font-weight: 500;
	line-height: 16px;
	text-align: center;
	cursor: pointer;
	margin-left: 20px;
	span {
		height: 18px;
		display: inline-block;
	}
	${props => {
		if (props.selected) {
			return `color: #12284B;
					font-weight: 600;
					span { border-bottom: 2px solid #12284B; };`;
		}
	}}
`;

export const FiltersButton = styled.div`
	height: auto;
	color: #555555;
	font-family: Montserrat;
	font-size: 13px;
	font-weight: 600;
	line-height: 16px;
	text-align: center;
	border: 1px solid #555555;
	box-sizing: border-box;
	border-radius: 3px;
	padding: 8px 16px;
	cursor: pointer;
	margin-right: 20px;
	margin-left: auto;
`;

export const FiltersHeader = styled.div`
	display: flex;
	margin-bottom: 20px;
`;

export const ToggleMapContainer = styled.div`
	display: none;
	@media ${eventPageMobileMediaQuery} {
		display: initial;
	}
`;
export const ToggleMapButton = styled.div`
	background: #f5f5f5;
	padding: 8px 15px;
	border-radius: 100px;
	border: 1px solid lightgray;
	color: ${getAppColor("dark")};
	margin: 15px 0 0 0;
	float: right;
	cursor: pointer;
	font-size: 12px;
`;

export const Filters = {
	Container: styled.div`
		display: flex;
		padding: 1rem 0;
		align-items: center;
	`,
	Field: styled.div`
		flex: 1;
	`,
	Spacer: styled.div`
		width: 0.5rem;
	`,
};

const fadeIn = keyframes`
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
`;

const PictureInPictureStyles = css`
	position: absolute;
	z-index: 1000;
	left: 20px;
	bottom: 20px;
	background: ${getAppColor("light")};
	height: 100%;
	width: 100%;
	transform: scale(0.25);
	transform-origin: bottom left;
	box-shadow: 0px 4px 16px rgb(0 0 0 / 30%);
	border: 8px solid ${getAppColor("light")};
	border-radius: 44px;
	cursor: pointer;
	transition: src 0.3s ease-in-out;
	& > * {
		pointer-events: none;
	}
`;

const MaximizedStyles = css`
	width: 100% !important;
	height: 100% !important;
	position: absolute !important;
	top: 0 !important;
	bottom: 0 !important;
	left: 0 !important;
	right: 0 !important;
	animation: ${fadeIn} ease 0.3s;
`;

export const PictureInPictureImage = styled.img<{ maximized: boolean }>`
	display: none;
	&[src] {
		display: block;
	}
	${props => (props.maximized ? MaximizedStyles : PictureInPictureStyles)}
	&:after {
		content: attr(alt);
		position: absolute;
		background: ${getAppColor("light")};
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 2rem;
		border-radius: 44px;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
	}
`;

export const MapContainer = styled.div`
	width: 100%;
	z-index: 0;
	flex: 2;
	position: relative;
`;

export const Map = styled.div<{ maximized: boolean }>`
	.sea-map-branding-link,
	.venue-map-zoom-controls,
	.venue-map-reset {
		margin: 10px;
	}
	.cm-reset:before {
		margin: 0.1em;
	}
	&.no-map .sea-map-inner > img {
		transform: none !important;
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
	overflow: hidden;

	${props => (props.maximized ? MaximizedStyles : PictureInPictureStyles)}

	.legend-submit-btn {
		display: none;
	}
	.sea-map-branding-link {
		img {
			//TODO: This is a hack to get the Seatics logo displayed on the mobile apps. Look into a better way to do this.
			content: url(https://mapwidget3.seatics.com/Images/seatics.svg);
		}
	}
`;

export const Layout = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`;

export const Content = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	overflow-y: hidden;

	@media ${eventPageMobileMediaQuery} {
		flex-direction: column-reverse;
		${MapContainer} {
			flex: 1;
		}
	}
`;

//#endregion

//#region Tickets

export const TicketCard = styled.div<{ selected: boolean }>`
	position: relative;
	overflow: hidden;
	background: ${props => (props.selected ? getAppColor("light") : "#e2e4e7")};
	border: 2px solid ${getAppColor("light")};
	border-radius: 20px;
	box-shadow: 2px 2px 7px 1px #ccc;
	margin: 1rem 0;
	cursor: pointer;
`;

export const TicketGroupContainer = styled.div`
	display: flex;
`;

export const TicketRow = styled.div<{ leftBorderColor?: string; backgroundColor?: string }>`
	display: flex;
	flex-direction: column;
	color: ${getAppColor("dark")};
	padding: 20px 10px;
	border-bottom: 1px solid ${getAppColor("lightGrey", "dark")};
	cursor: pointer;
	background-color: ${props => (props.backgroundColor ? props.backgroundColor : getAppColor("light"))};
	${props =>
		props.leftBorderColor &&
		`
    border-left: 12px solid ${props.leftBorderColor};
  `}
	&:hover {
		background-color: ${getAppColor("lightGrey")};
	}
`;

export const TicketContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: 500;
	line-height: normal;
`;

export const TicketBody = styled.div`
	display: flex;
	color: #555555;
	flex-direction: row;
	justify-content: space-between;
`;

export const TicketHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export const TicketSectionTitle = styled.div`
	margin: 1rem;
	text: 16px;
`;

export const ViewFromSeatButton = styled.img.attrs(() => ({
	src: eye,
	alt: "vfs",
}))`
	width: 16px;
	height: 16px;
`;

export const Title = styled.div`
	font-family: Montserrat;
	font-size: 16px;
	font-weight: bold;
	text-align: left;
	flex: 1;
`;

export const HeaderTitle = styled.div`
	color: #12284c;
	margin-right: 11px;
	font-family: Montserrat;
	font-size: 16px;
	font-weight: 700;
	text-align: left;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
`;

export const Subtitle = styled.div`
	font-family: Montserrat;
	font-size: 14px;
	margin-top: 2px;
	text-align: left;
`;

export const Text = styled.div`
	font-size: 14px;
	text-align: left;
`;

export const Price = styled.div`
	font-family: Montserrat;
	font-size: 14px;
	text-align: right;
	strong {
		font-weight: bold;
		font-size: 18px;
	}
`;

export const FullPrice = styled(Price)`
	display: flex;
	margin-right: unset;
`;

//#endregion

//#region View From Seat

export const ViewFromSeatModalPopup = styled(ModalPopup)`
	width: 800px;
	max-width: 100vw;
	overflow: hidden;
	/*Modal body selector*/
	& > :nth-child(2) {
		padding: 0px;
		overflow-y: initial;
	}
`;

export const ViewFromSeatImage = styled.img`
	width: 100%;
	height: 100%;
`;

//#endregion

export const TicketPrice = styled.div`
	font-family: Montserrat;
	font-size: 18px;
	text-align: right;
	strong {
		font-weight: bold;
		font-size: 25px;
	}
	align-self: center;
`;

export const LevelPrice = styled.button<{ sectionIsSelected: boolean; otherSectionIsSelected: boolean; sectionColor: string }>`
	border-radius: 30px;
	border-style: solid;
	font-family: Montserrat;
	font-size: 14px;
	font-weight: bold;
	text-align: center;
	margin: 3px;
	padding: 3px 5px;
	cursor: pointer;
	border-color: ${props => (props.sectionIsSelected ? props.sectionColor : getAppColor("darkGrey", "text"))};
	color: ${props => (props.sectionIsSelected ? getAppColor("dark") : getAppColor("darkGrey", "text"))};
	background-color: ${props => (props.sectionIsSelected ? props.sectionColor : hex2rgba(props.sectionColor, 0.5))};
	@media (any-hover: hover) {
		&:hover {
			background-color: ${props => hex2rgba(props.sectionColor, 0.7)};
			border-color: ${props => props.sectionColor};
			color: ${getAppColor("dark")};
		}
	}
`;

export const Spacer = styled.div`
	flex: 1;
`;

export const AltBackArrow = styled.img`
	margin-left: 20px;
`;

export const BackArrow = styled.img<{ mobileVisible?: boolean }>`
	transform: scaleX(-1);
	cursor: pointer;
	margin: auto 0;
	margin-right: 20px;
	@media ${eventPageMobileMediaQuery} {
		display: ${props => (props.mobileVisible ? "block" : "none")};
	}
`;

export const BackArrowCircle = styled.img<{ bg?: boolean | true; styles?: string }>`
	transform: scaleX(-1);
	cursor: pointer;
	position: absolute;
	top: 15px;
	left: 10px;
	width: 24px;
	height: 24px;
	background: ${props => (props.bg !== false ? "rgba(255, 255, 255, 0.8)" : "none")};
	padding: 7px 6px 7px 8px;
	border-radius: 100%;
	${props => props.styles}
`;

export const PanelModal = styled.div`
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10 !important;
	box-sizing: border-box;
	overflow-y: auto;
	animation: ${() => fadeInRTL} 0.25s linear;
	background-color: ${getAppColor("light")};
	position: absolute;
	@media ${eventPageMobileMediaQuery} {
		position: fixed;
	}
`;

const fadeInRTL = keyframes`
from {
        opacity: 0;
		transform: translateX(-20%);
}

to {
        opacity: 1;
		transform: translateX(0);
}
`;

export const VFSImage = styled.img`
	width: 100%;
	display: none;
	height: 250px;
	background: ${getAppColor("light")};
	&[src] {
		display: block;
	}

	@media (max-width: ${breakpoints.mobile}) {
		height: 211px;
	}
`;
export const VFSHeader = styled.div<{ showTopMessage?: boolean }>`
	position: relative;
	@media ${eventPageMobileMediaQuery} {
		display: block;
		${props =>
			props.showTopMessage &&
			`
		margin-top: 30px;
		`}
	}
`;

export const PrimaryEventImage = styled.img`
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
`;

export const PrimaryEventImageContainer = styled.div`s
	overflow: hidden;
	box-sizing: border-box;

	@media (min-width: ${breakpoints.tablet}) {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 20px 10px;
	}
`;

export const EventDescriptionContainer = styled.div`
	padding: 10px;
	@media ${eventPageMobileMediaQuery} {
		padding: 10px 0px 0px 0px;
	}
`;

export const SeatingZoneHeader = styled.div`
	margin: 1rem 0 0 0.3rem;
	padding: 5px 1rem 5px 1rem;
	font-size: 18px;
	font-weight: bold;
	display: inline-block;
	background-color: ${getAppColor("light")};
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	font-family: ${FontFamily.Poppins};
	@media ${eventPageMobileMediaQuery} {
		font-size: 14px;
		background-color: transparent;
		border-top-right-radius: unset;
		border-top-left-radius: unset;
		padding: 0 0 0 0;
		margin: 0 0 0 1.5rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
`;

export const ViewFromSeatPreview = styled.img`
	width: 189px;
	height: 126px;
	padding-left: 5px;
	z-index: 2;
	position: absolute;
`;

export const ViewFromSeatContainer = styled.div<{ height: number }>`
	@media ${eventPageMobileMediaQuery} {
		display: none;
	}
	position: absolute;
	background: ${getAppColor("darkGrey", "light")};
	width: 191px;
	height: ${props => `${props.height}px`};
	-webkit-background-clip: padding-box; /* for Safari */
	background-clip: padding-box; /* for IE9, Firefox 4, Opera, Chrome */
	:before {
		content: "";
		position: absolute;
		top: 50%;
		left: -10px;
		z-index: 1;
		height: 60px;
		width: 20px;
		margin-top: -30px;
		background: linear-gradient(-55deg, ${getAppColor("darkGrey", "light")} 17px, rgba(0, 0, 0, 0.2) 17px, rgba(0, 0, 0, 0.2) 17px, transparent 17px), linear-gradient(235deg, ${getAppColor("darkGrey", "light")} 17px, rgba(0, 0, 0, 0.2) 17px, rgba(0, 0, 0, 0.2) 17px, transparent 17px);
		background-position: left 0px, right bottom;
		background-size: 100% 50%;
		background-repeat: no-repeat;
	}
`;

export const FilterButton = styled.button`
	width: 36px;
	height: 36px;
	border-radius: 24px;
	background-color: ${getAppColor("darkGrey", "light")};
	border: none;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin: 0.5rem 0 0.5rem 0;
	@media ${eventPageMobileMediaQuery} {
		width: 32px;
		height: 32px;
		background-color: ${getAppColor("lightGrey", "dark")};
	}
`;

export const SeatingZoneRow = styled.div`
	display: inline-flex;
	background-color: ${getAppColor("lightGrey")};
	@media ${eventPageMobileMediaQuery} {
		background-color: transparent;
		border-bottom: 1px solid ${getAppColor("lightGrey", "dark")};
		border-top: 1px solid ${getAppColor("lightGrey", "dark")};
	}
`;

export const FilterButtonContent = styled.div`
	display: inline-flex;
	justify-content: end;
	align-items: center;
	padding: 0 1rem 0 1rem;
	margin-left: auto;
	margin-right: 0;
`;

export const FiltersTypography = styled.div`
	font-family: ${FontFamily.Poppins};
	padding: 0 0.5em;
	font-weight: bold;
	color: ${getAppColor("darkGrey", "dark")};
	font-size: 14px;
	@media ${eventPageMobileMediaQuery} {
		font-size: 12px;
	}
`;
