import React, { useRef } from "react";

interface OddsContextState {
	getSideOddVisibility: (key: number) => boolean;
	resetSideOdds: () => void;
}

export const OddsContext = React.createContext<OddsContextState>({
	getSideOddVisibility: () => true,
	resetSideOdds: () => {},
});

interface OddsContextProviderProps {
	children: React.ReactNode;
	maxSideOdds: number;
}

export const OddsContextProvider = (props: OddsContextProviderProps) => {
	const registered = useRef<number[]>([]);

	const getSideOddVisibility = (key: number) => {
		if (!registered.current.includes(key)) {
			registered.current.push(key);
		}
		const sortedRegisteredSideOdds = registered.current.sort((a, b) => a - b);
		const visibleSideOdds = sortedRegisteredSideOdds.slice(0, props.maxSideOdds);

		return visibleSideOdds.includes(key);
	};

	const resetSideOdds = () => (registered.current = []);

	return <OddsContext.Provider value={{ getSideOddVisibility, resetSideOdds }}>{props.children}</OddsContext.Provider>;
};
