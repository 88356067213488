import React from "react";
import { ModalPopup } from "../../ModalPopup/ModalPopup";
import { ModalCloseButton } from "../../Modal/ModalCloseButton";
import { ModalPosition } from "../../Modal";
import { MediumButtonWithSpinner } from "../../Button/ButtonWithSpinner";
import { postFuturesEditPrice, postFuturesListForSale } from "../../../../services/myAccount";
import toast from "react-hot-toast";
import { Field, Form } from "react-final-form";
import { RFFInput } from "../../Input/Input";
import styled from "styled-components/macro";
import { required } from "../../../util/optimized/finalFormUtil";
import { useHistory } from "react-router-dom";
import { MyAccountRoutes } from "../../../routes/MyAccountRoute";

type BaseFuturesListingFormModalProps = {
	modalOpen: boolean;
	setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	futureInventoryId: number;
	eventTitle: string;
	//TODO: Consider improving the form values type for this property type.
	onSuccess?: (values: Record<string, any>) => void;
};

//TODO: Will most likely re-write for optimized frontend.
export const FuturesListingFormModal = (
	props:
		| (BaseFuturesListingFormModalProps & {
				edit?: never;
				maxQuantity: number;
		  })
		| (BaseFuturesListingFormModalProps & {
				edit: true;
				currentPrice: number;
		  })
) => {
	const history = useHistory();

	return (
		<>
			{props.modalOpen && (
				<ModalPopup onDidClose={() => props.setModalOpen(false)} rightHeaderElements={(onClose: any) => <ModalCloseButton onClick={onClose} />} centerHeaderElements={props.edit ? "Edit Listing" : "Sell Reservations"} position={ModalPosition.Center}>
					{onClose => {
						return (
							<Form
								render={form => {
									return (
										<ListingForm onSubmit={form.handleSubmit}>
											<ListingFormFields>
												{!props.edit && (
													<Field<string>
														name="quantity"
														component="select"
														validate={value => {
															return required(value);
														}}
													>
														<option value="" disabled hidden>
															Quantity
														</option>
														{Array.from(Array(props.maxQuantity)).map((element, index) => {
															const value = index + 1;
															return (
																<option key={index} value={value}>
																	{value}
																</option>
															);
														})}
													</Field>
												)}
												<Field<string>
													name="price"
													cleaveOptions={{
														numeral: true,
														numeralPositiveOnly: true,
														stripLeadingZeroes: true,
														prefix: "$",
														noImmediatePrefix: true,
														numeralIntegerScale: 6,
													}}
													initialValue={props.edit ? String(props.currentPrice) : ""}
													placeholder="Price per Reservation"
													validate={value => {
														return required(value);
													}}
													component={RFFInput}
												/>
											</ListingFormFields>
											<MediumButtonWithSpinner type="submit" loading={form.submitting} disabled={form.invalid || form.form.getFieldState("price")?.pristine}>
												{props.edit ? "Update Listing" : "List For Sale"}
											</MediumButtonWithSpinner>
										</ListingForm>
									);
								}}
								onSubmit={async values => {
									const price = Number(values.price.substring(1).replace(",", ""));
									const callEndpoint = props.edit
										? () => {
												return postFuturesEditPrice(props.futureInventoryId, price);
										  }
										: () => {
												return postFuturesListForSale(props.futureInventoryId, Number(values.quantity), price);
										  };
									await callEndpoint()
										.then(() => {
											const redirectUserToListings = !props.edit;
											if (redirectUserToListings) {
												history.push(MyAccountRoutes.FuturesListings);
											}
											if (props.onSuccess) {
												props.onSuccess({
													...values,
													price,
												});
											}
											toast.success(props.edit ? 'Updated "' + props.eventTitle + '".' : 'Listed "' + props.eventTitle + '" for sale.');
											onClose();
										})
										.catch(result => {
											toast.error(result.error?.message);
										});
								}}
							/>
						);
					}}
				</ModalPopup>
			)}
		</>
	);
};

const ListingForm = styled.form`
	display: flex;
	flex-direction: column;
	max-width: 297px;
	width: 85vw;
	padding: 10px;
`;

const ListingFormFields = styled.div`
	margin-top: 15px;
	margin-bottom: 26px;
	display: flex;
	align-items: stretch;
	& > div {
		width: 100%;
	}
	select,
	input {
		color: #000;
		border-radius: 3px;
		border: solid 1px #767977;
	}
	select {
		max-width: 106px;
	}
	input::placeholder {
		color: #000;
	}
	& > * {
		margin: 0;
		&:not(:last-child) {
			margin-right: 12px;
		}
	}
`;
