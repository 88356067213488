import React from "react";
import { FutureEvent } from "../../../model/optimizedModel/future";
import { PartialBy } from "../../../model/optimizedModel/general";
import { useAutoUpdateState } from "../../hooks/useAutoUpdateState";
import { hotelFuturesEventSlug } from "../../util/hotelFutures";
import { ItemContentDisplay, BaseContentDisplayProps, AdditionalItemContentDisplayInfo } from "./ItemContentDisplay";

//TODO: Consider seeing if there's a better way to handle zones (this component only consumes zoneTitle).
export const FutureContentDisplay = ({
	stadium,
	title,
	slug,
	zoneTitle,
	team,
	additionalInfo,
	date,
	...spreadableProps
}: PartialBy<Omit<FutureEvent, "sportsTitle">, "date"> & {
	zoneTitle: string;
} & BaseContentDisplayProps) => {
	const isHotelFuture = useAutoUpdateState(() => slug === hotelFuturesEventSlug, [slug]);

	return (
		<ItemContentDisplay
			title={{
				slug,
				name: title,
			}}
			location={{
				name: stadium.location ? stadium.title + ", " + stadium.location : stadium.title,
			}}
			// Consider switching this type to ContentWithSlug[] or ContentWithSlug instead of just ContentWithSlug[]
			performers={[team]}
			additionalInfo={(() => {
				const arr: AdditionalItemContentDisplayInfo[] = [
					{
						title: "Zone",
						content: zoneTitle,
					},
				];
				return additionalInfo ? arr.concat(additionalInfo) : arr;
			})()}
			date={isHotelFuture ? undefined : date}
			{...spreadableProps}
		/>
	);
};
