import React, { useEffect, useState } from "react";
import { Event } from "../../../model/Event";
import { InternalRoutes, Link } from "../../Link";
import { DateTime } from "luxon";

import { EventCard, EventContent, EventTitle, ColumnBody, SideColumn, EventTable, MobileLayout, StyledBetSmall, Arrow } from "./styles";
import { getEventBets } from "../../../services/actions/betsService";
import { useDataService } from "../../hooks";
import { OddsContext } from "../Bets/BetContext";
import { isMobileApp } from "../../util/isMobileApp";
import arrow from "../../resource/image/arrow-event-listing.svg";
import { SantizedHTML } from "../SantizedHTML/SantizedHTML";

type BetType = "odd" | "all";
interface EventProps {
	event: Event;
	performer?: string;
	betType?: BetType;
}

const BaseEventItem = (props: { event: Event; to: string; hideDate?: boolean; columnBodyClassName?: string; children?: JSX.Element | ((date: DateTime | null) => JSX.Element); eventCardGradient?: boolean; additionalColumns?: JSX.Element; additionalContent?: JSX.Element | null }) => {
	const event = props.event;
	const date = event.date ? DateTime.fromJSDate(event.date) : null;
	const rescheduledIndicator = "(Rescheduled";
	const rescheduledIndex = event.name.indexOf(rescheduledIndicator);
	//TODO: Logic that manually removed Rescheduled content from event titles. Remove?
	if (rescheduledIndex >= 0) {
		const rescheduledLastIndex = event.name.indexOf(")", rescheduledIndex) + 1;
		const fullRescheduledMessage = event.name.substring(rescheduledIndex, rescheduledLastIndex);
		const eventNameWithoutRescheduledMessage = event.name.replace(fullRescheduledMessage, "");
		const formattedEventName = eventNameWithoutRescheduledMessage.replace(/\s{2,}/g, " ");
		event.name = formattedEventName;
	}

	const replaceOutOfFrameTime = (date: string) => {
		date = date.startsWith("3:30") ? "TBD" : date;
		date = date.startsWith("0:00") ? "TBD" : date;
		return date;
	};

	const getDisplayedYear = (year: number) => {
		const THRESHOLD = 5;
		const yearDisplayThreshold = new Date().getFullYear() + THRESHOLD;
		return year < yearDisplayThreshold ? year : "TBD";
	};

	const eventContent = (
		<EventContent>
			{date && !props.hideDate && (
				<SideColumn>
					<p>{date.weekdayShort}</p>
					<p>
						{date.monthShort.toUpperCase()} {date.day}
					</p>
					<p>{getDisplayedYear(date.year)}</p>
					<p>{replaceOutOfFrameTime(date.toLocaleString(DateTime.TIME_SIMPLE))}</p>
				</SideColumn>
			)}
			<ColumnBody className={props.columnBodyClassName}>
				<EventTitle><SantizedHTML htmlString={event.name}/> </EventTitle>
				{typeof props.children === "function" ? props.children(date) : props.children}
			</ColumnBody>
			{props.additionalColumns}
			<Arrow src={arrow} alt="arrow" />
		</EventContent>
	);

	const EventCardWrapper = (eventCardWrapperProps: { children: JSX.Element }) => <EventCard gradient={props.eventCardGradient}>{eventCardWrapperProps.children}</EventCard>;

	return props.additionalContent ? (
		<EventCardWrapper>
			<>
				<Link to={props.to}>{eventContent}</Link>
				{props.additionalContent}
			</>
		</EventCardWrapper>
	) : (
		<Link to={props.to}>
			<EventCardWrapper>
				<>{eventContent}</>
			</EventCardWrapper>
		</Link>
	);
};

//
//TODO: remove bet data from this type of event when the api allows to identify sports events
//
export const NormalEventItem = ({ event, performer }: EventProps) => {
	let venueContent = event?.venueCity ? event.venueCity : "";
	venueContent += event?.venueState ? `, ${event?.venueState}` : "";

	return (
		<BaseEventItem event={event} eventCardGradient={true} to={InternalRoutes.Event(event.slug, `?performer=${performer}`)}>
			<MobileLayout>
				<div>
					<EventTable.Table>
						<EventTable.Row>
							<EventTable.Cell>
								{event.venueName}
								{", "}
								{venueContent}
							</EventTable.Cell>
						</EventTable.Row>
						<EventTable.Row></EventTable.Row>
					</EventTable.Table>
				</div>
			</MobileLayout>
		</BaseEventItem>
	);
};

export const SportEventItem = ({ event, performer, betType }: EventProps) => {
	betType = betType || "all";

	const betText = useBetText(event, betType, performer);

	let venueContent = event?.venueCity ? event.venueCity : "";
	venueContent += event?.venueState ? `, ${event?.venueState}` : "";

	return (
		<BaseEventItem event={event} to={InternalRoutes.Event(event.slug)} eventCardGradient={true}>
			<div>
				<EventTable.Table>
					<EventTable.Row>
						<EventTable.Cell>
							{event.venueName}
							{", "}
							{venueContent}
						</EventTable.Cell>
					</EventTable.Row>
					<EventTable.Row>
						{!isMobileApp && betText && (
							<EventTable.Cell>
								<StyledBetSmall>{betText}</StyledBetSmall>
							</EventTable.Cell>
						)}
					</EventTable.Row>
				</EventTable.Table>
			</div>
		</BaseEventItem>
	);
};

const useBetText = (event: Event, type: BetType, performer?: string) => {
	const { result } = useDataService(getEventBets, [event.id], {});
	const [state, setState] = useState("");
	const context = React.useContext(OddsContext);

	useEffect(() => {
		const betData = getBetData(result, type, performer);

		// Always show game data
		if (betData.type === "odd") {
			setState(betData.message);
		}
		// side odds logic
		else if (betData.type === "sideodd") {
			// ignore if no date or no prediction
			if (typeof event.date !== "undefined" && betData.message) {
				// set bet only if
				if (context.getSideOddVisibility(event.date.getTime())) {
					setState(betData.message);
				}
			}
		}
	}, [result, performer, type]);

	return state;
};

type BetData = {
	type: "odd" | "sideodd";
	message: string;
};

const getBetData = (eventBets: any, type: BetType, performer?: string): BetData => {
	//
	// TODO: fix this on api
	//
	const game = !Array.isArray(eventBets?.game) && eventBets?.game !== null ? eventBets?.game : undefined;

	if (game) {
		const homeTeam = game.home_team;
		const awayTeam = game.away_team;
		const betTeam = homeTeam || "";
		const spread = parseFloat(game.spread);
		const overUnder = parseFloat(game.over_under);
		const hasSpread = !isNaN(spread);
		const hasOverUnder = !isNaN(overUnder);

		const hasMoneyLines = game.money_line_1 && game.money_line_2;
		const lowestMoneyLineValue = hasMoneyLines && game.money_line_1 < game.money_line_2 ? game.money_line_1 : game.money_line_2;
		const amountToWin = ""; //lowestMoneyLineValue && !isMobileApp ? ` | $${SAMPLE_AMOUNT_BET} wins $${Math.trunc(lowestMoneyLineValue * SAMPLE_AMOUNT_BET)}` : '';

		// show spread, over/under values
		let betText = betTeam;
		betText += hasSpread ? ` ${spread > 0 ? "+" : ""}${spread}` : "";
		betText += hasSpread && hasOverUnder ? " |" : "";
		betText += hasOverUnder ? ` Over/Under: ${overUnder}` : "";

		if (hasSpread || hasOverUnder) {
			return {
				message: betText + amountToWin,
				type: "odd",
			};
		}
		// neither A nor B, we try show "Team X favored to Win"
		if (hasMoneyLines) {
			const teamToWin = lowestMoneyLineValue === game.money_line_1 ? awayTeam : homeTeam;
			return {
				message: `${teamToWin} favored to Win ${amountToWin}`,
				type: "odd",
			};
		} else
			return {
				message: "",
				type: "odd",
			}; //prevent to show only the team name
	}
	// only show odds
	if (type === "odd") {
		return {
			message: "",
			type: "odd",
		};
	}

	//Hidding this section for now, as we don't want to display neither team nor player futures
	/*
	if (playerProp1 && playerProp2) {
		const homePlayer = playerProp1;
		const awayPlayer = playerProp2;
		const betPlayer = homePlayer?.team_name === performer ? homePlayer : awayPlayer;

		const amountToWin = "";//betPlayer.price && !isMobileApp ? `| $${SAMPLE_AMOUNT_BET} wins $${Math.trunc(betPlayer.price * SAMPLE_AMOUNT_BET)}` : '';

		const firstName = betPlayer?.first_name;
		const lastName = betPlayer?.last_name;
		const prediction = betPlayer?.title;
		const overUnder = betPlayer?.value ? `Over/Under ${betPlayer?.value}` : '';
		return {
			message: `${firstName} ${lastName} ${prediction} ${overUnder} ${amountToWin}`,
			type: 'sideodd'
		};
	}

	if (teamFuture1 && teamFuture2) {
		const homeTeam = teamFuture1?.team_name?.toLowerCase();
		const betTeam = homeTeam === performer ? teamFuture1 : teamFuture2;
		const amountToWin = "";//betTeam.price && !isMobileApp ? `| $${SAMPLE_AMOUNT_BET} wins $${Math.trunc(betTeam.price * SAMPLE_AMOUNT_BET)}` : '';
		const overUnder = betTeam?.value ? `Over/Under: ${betTeam?.value}` : '';
		const prediction = betTeam.title;

		return {
			message: `${betTeam.team_name} ${prediction} ${overUnder} ${amountToWin}`,
			type: 'sideodd'
		};
	}*/

	return {
		message: "",
		type: "sideodd",
	};
};
