import React from "react";
import styled from "styled-components/macro";
import { mediaQueries } from "../../util/mediaQueries";

interface MobileMenuIconProps {
	isMenuOpen: boolean;
	setMenuOpen: (isMenuOpen: boolean) => void;
	color?: string;
}

const MobileMenuIconContainer = styled.div`
	flex-shrink: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	&:hover {
		cursor: pointer;
	}
`;

const MobileMenuIconContent = styled.div`
	width: 27px;
	height: 20px;
	position: relative;
	user-select: none;
	@media ${mediaQueries.max500} {
		left: 0;
		right: initial;
	}
`;

const Line = styled.span<{ open: boolean }>`
	display: block;
	position: absolute;
	height: 4px;
	width: 100%;
	background: ${props => (props.color ? props.color : "#e92224")};
	border-radius: 4px;
	opacity: 1;
	right: 0;
	transition: 0.25s ease-in-out;
`;

const Line1 = styled(Line)`
	top: ${props => (props.open ? 8 : 0)}px;
	transform: rotate(${props => (props.open ? 135 : 0)}deg);
	left: 0;
	width: ${props => (props.open ? 100 : 70)}%;
`;

const Line2 = styled(Line)`
	top: 8px;
	transform: rotate(0deg);
	opacity: ${props => (props.open ? 0 : "initial")};
	right: ${props => (props.open ? -50 : 0)}px;

	@media ${mediaQueries.max500} {
		right: initial;
		left: ${props => (props.open ? -50 : 0)}px;
	}
`;

const Line3 = styled(Line)`
	top: ${props => (props.open ? 8 : 16)}px;
	transform: rotate(${props => (props.open ? -135 : 0)}deg);
	left: 0;
	width: ${props => (props.open ? 100 : 70)}%;
`;

export const MobileMenuIcon = (props: MobileMenuIconProps) => {
	const onClick = () => {
		props.setMenuOpen(!props.isMenuOpen);
	};
	return (
		<MobileMenuIconContainer onClick={onClick}>
			<MobileMenuIconContent>
				<Line1 color={props.color} open={props.isMenuOpen} />
				<Line2 color={props.color} open={props.isMenuOpen} />
				<Line3 color={props.color} open={props.isMenuOpen} />
			</MobileMenuIconContent>
		</MobileMenuIconContainer>
	);
};
