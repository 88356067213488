import { Location } from "../model/Location";
import { apiBaseUrl, apiClient } from "./apiClient";
import { APIClientResponse } from "./types/APIClientResponse";

export function getGeolocation(): APIClientResponse<Location> {
	return apiClient.get(`location`, {
		baseURL: apiBaseUrl("geo"),
	});
}

const geocoder = new google.maps.Geocoder();

export function geocode(): Promise<Location> {
	return new Promise((resolve, reject) => {
		const getPositionSuccess: PositionCallback = position => {
			const location = {
				lat: position.coords.latitude,
				lng: position.coords.longitude,
			};
			geocoder.geocode({ location }, result => {
				const [place] = result;
				const coordinates = place.geometry?.location;
				resolve({
					name: getLocationName(place),
					latitude: coordinates.lat(),
					longitude: coordinates.lng(),
				});
			});
		};

		const getPositionError: PositionErrorCallback = error => {
			reject(error.message);
		};
		navigator.geolocation.getCurrentPosition(getPositionSuccess, getPositionError);
	});
}

type GoogleMapsPlace = google.maps.GeocoderResult | google.maps.places.PlaceResult;

export const getGoogleMapsPlaceComponent = (place: GoogleMapsPlace, typeStrings: string[]) => {
	const component = place.address_components?.find((component: google.maps.GeocoderAddressComponent) => {
		const typesContainsType = typeStrings.find((typeString: string) => {
			return component.types.find((subType: string) => subType === typeString);
		});
		return typesContainsType;
	});
	return component;
};

export function getLocationName(place: GoogleMapsPlace): string {
	const countryComponent = getGoogleMapsPlaceComponent(place, ["country"]);
	const cityComponent = getGoogleMapsPlaceComponent(place, ["locality", "administrative_area_level_3", "sublocality"]);
	const stateComponent = getGoogleMapsPlaceComponent(place, ["administrative_area_level_1"]);

	const cityOrStateName = cityComponent?.long_name || stateComponent?.long_name;

	let name = cityOrStateName || countryComponent?.long_name;
	if (name === cityOrStateName) {
		let lastHalf;
		if (countryComponent?.short_name === "US" && name !== stateComponent?.long_name) {
			lastHalf = stateComponent?.short_name;
		} else {
			lastHalf = countryComponent?.short_name;
		}
		if (lastHalf) {
			name += ", " + lastHalf;
		}
	}
	if (!name && place.address_components) {
		name = place.address_components[0].long_name;
	}

	return name || "";
}

export const getRemoteIp = async () => {
	return await apiClient.get("/user/remote-ip");
};
