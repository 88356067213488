import React from "react";
import { FutureEvent, FutureZone } from "../../../../model/optimizedModel/future";
import { FutureContentDisplay } from "../../ContentDisplays/FutureContentDisplay";
import { FutureInvoice } from "../../Invoices/FutureInvoice";
import { CartItem } from "./CartItem";
import { BaseCartItemProps } from "../../../../model/optimizedModel/cartItem";
import { FuturesTermsModalLink } from "../../Futures/FuturesTermsModalLink";
import styled from "styled-components/macro";
import { hotelFuturesEventSlug } from "../../../util/hotelFutures";
import { useAutoUpdateState } from "../../../hooks/useAutoUpdateState";
import { getTypographyStyles } from "../../Typography/Typography";
import { getAppColor } from "../../../util/appColors";

const futureInvoiceItemsAlwaysDisplayed = ["ticketPrice"];

export const CartFuture = ({
	additionalFutureInvoiceItems,
	zone,
	event,
	futureInventoryId,
	onUpdate,
	notes,
	quantity,
	taxAmount,
	...cartItemProps
}: {
	zone: FutureZone;
	event: FutureEvent;
	futureInventoryId: string;
	taxAmount?: number | null;
	//TODO: The funcionality for the below props were done very quickly. Feel free to reimplement.
	onUpdate?: (output: any) => void;
} & BaseCartItemProps) => {
	const isHotelFuture = useAutoUpdateState(() => event.slug === hotelFuturesEventSlug, [event.slug]);

	const futureInvoiceProps = useAutoUpdateState(
		() => ({
			...zone,
			quantity,
			taxAmount,
			onUpdate,
			standardPriceFormatting: true,
			additionalItems: additionalFutureInvoiceItems,
			override: {
				ticketPrice: {
					style: {
						color: getAppColor("dark"),
					},
					...(isHotelFuture && {
						title: <strong>Room Price</strong>,
					}),
				},
			},
		}),
		[zone, quantity, onUpdate, isHotelFuture, taxAmount]
	);

	return (
		<CartItem
			notes={notes}
			itemInfo={
				<>
					<FutureContentDisplay {...event} zoneTitle={zone.title} quantity={quantity} />
					<StyledFuturesTermsModalLink displayHotelTerms={isHotelFuture} />
					{
						!cartItemProps.hideInvoice && (
							<FutureInvoice {...futureInvoiceProps} pick={futureInvoiceItemsAlwaysDisplayed} />
						)
					}
				</>
			}
			invoice={<FutureInvoice {...futureInvoiceProps} additionalItems={additionalFutureInvoiceItems} omit={futureInvoiceItemsAlwaysDisplayed} />}
			{...cartItemProps}
		/>
	);
};

const StyledFuturesTermsModalLink = styled(FuturesTermsModalLink)`
	${getTypographyStyles("bodySmall", { fontWeight: "normal" })}
	margin-top: 10px;
	margin-bottom: 15px;
`;
