import { css, FlattenSimpleInterpolation } from "styled-components/macro";

export const MyAccountMobileWidth = 900;

export const MyAccountMobile = (styles: FlattenSimpleInterpolation) => css`
	@media (max-width: ${MyAccountMobileWidth}px) {
		${styles}
	}
`;

export const myAccountCardBoxShadow = "4px 4px 7px -2px rgba(0,0,0,0.26)";
