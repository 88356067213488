import { Reducer } from "redux";

interface CaptchaBadgeState {
	hidden?: boolean;
}

export const captchaBadgeReducer: Reducer<CaptchaBadgeState, CaptchaBadgeAction> = (
	state: CaptchaBadgeState = {
		hidden: false,
	},
	action: CaptchaBadgeAction
): CaptchaBadgeState => {
	switch (action.type) {
		case "SET_IS_CAPTCHA_BADGE_HIDDEN":
			return {
				hidden: action.value,
			};
		default:
			return state;
	}
};

type CaptchaBadgeAction = {
	type: "SET_IS_CAPTCHA_BADGE_HIDDEN";
	value: boolean;
};

export const setIsCaptchaBadgeHidden = (value: boolean): CaptchaBadgeAction => ({
	type: "SET_IS_CAPTCHA_BADGE_HIDDEN",
	value,
});
