import React, { useState } from "react";
import styled, { CSSProp } from "styled-components/macro";
import { maxContentWidthStyles } from "../../util/maxContentWidth";
import { mediaQueries } from "../../util/mediaQueries";
import { Expandable } from "../Expandable/Expandable";
import { CollapseArrow } from "../CollapseArrow/CollapseArrow";

export interface CollapsibleCardProps {
	header: JSX.Element;
	children: React.ReactNode;
	controlColor?: string;
	background?: string;
	containerStyles?: CSSProp;
	contentStyles?: CSSProp;
}

export const CollapsibleCard = (props: CollapsibleCardProps) => {
	const [collapsed, setCollapsed] = useState(true);
	return (
		<Row css={props.containerStyles}>
			<CardMemberContainer>
				<CardMemberInner onClick={() => setCollapsed(!collapsed)} background={props.background}>
					{props.header}
					<CollapseArrow size={14} weight={2} color={props.controlColor ? props.controlColor : "#555"} expanded={!collapsed} />
				</CardMemberInner>
				<Expandable expanded={!collapsed}>
					<CardContent background={props.background} css={props.contentStyles}>
						{props.children}
					</CardContent>
				</Expandable>
			</CardMemberContainer>
		</Row>
	);
};

const CardMemberContainer = styled.div`
	height: auto;
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const Row = styled.div`
	${maxContentWidthStyles}
	display: flex;
	flex-direction: row;
	padding: 0;
	width: 100%;
	border: 1px solid #555555;
	margin-bottom: 0px;
	@media (min-width: 670px) {
		&:not(:last-child) {
			border-bottom: none;
		}
	}
	@media ${mediaQueries.max670} {
		border-top: none;
		border-right: none;
		border-left: none;
	}
`;

const CardMemberInner = styled.div<{ background?: string }>`
	flex: 1;
	background: ${props => (props.background ? props.background : "#ffffff")};
	text-align: left;
	padding: 14px 40px;
	color: white;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	h2 {
		display: flex;
		align-items: center;
	}
	@media ${mediaQueries.max670} {
		padding: 21px 25px 21px 15px;
		h2 {
			font-size: 42px;
			line-height: 42px;
			flex-direction: column;
			align-items: flex-start;
		}
	}
`;

const CardContent = styled.p<{ collapsed?: boolean; background?: string }>`
	${maxContentWidthStyles}
	width: 100%;
	background: ${props => (props.background ? props.background : "#ffffff")};
	padding: 34px;
	background-repeat: no-repeat;
	font-family: Montserrat;
	font-weight: 500;
	font-size: 16px;
	line-height: 1.7;
	text-align: left;
	color: rgba(0, 0, 0, 1);
	strong {
		font-weight: 600;
		font-size: 16px;
	}
	@media ${mediaQueries.max670} {
		padding: 0px 21px 24px 21px;
	}
`;
