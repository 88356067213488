import React, { useRef, useState } from "react";
import { CommonPage } from "../components";
import styled from "styled-components/macro";
import { getAppColor } from "../util/appColors";
import { FontFamily, Typography } from "../components/Typography/Typography";
import { breakpoints } from "../util/breakpoints";
import { Config } from "../../Config";
import { Button } from "../components/NewButton/Button";
import { Field, Form } from "react-final-form";
import { ButtonContainer, StyledRFFInput } from "./GolfClassicRoute";
import { required, validEmail } from "../util/optimized/finalFormUtil";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { Dialog } from "../components/Dialog/Dialog";
import { postReebokPromotion } from "../../services/reebokPromotion";
import toast, { Toaster } from "react-hot-toast";
import { InternalRoutes, Link } from "../Link";
import si_tickets_logo from "../resource/assets/si_tickets_logo_unstacked_cropped.svg";

const TableValues = [
	{ left: "$500 - $999", right: "$50" },
	{ left: "$1000 - $1499", right: "$100" },
	{ left: "$1500 - $1999", right: "$150" },
	{ left: "2000 +", right: "$200" },
];

const TermsValues = [
	{ spend: "$500", receive: "$50" },
	{ spend: "$1000", receive: "$100" },
	{ spend: "$1500", receive: "$150" },
	{ spend: "$2000", receive: "$200" },
];

const reebokFolder = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/reebokLP`);
const laptopBreakpoint = "1220px";

export const ReebokRoute = () => {
	const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
	const formRef = useRef<HTMLHeadingElement>(null);
	const [showModal, setShowModal] = useState(false);

	return (
		<CommonPage isFooterHidden>
			<TermsAndConditionsModal
				open={showModal}
				onClose={() => {
					setShowModal(false);
				}}
			/>
			<PageContainer>
				<BackgroundImage src={isMobile ? `${reebokFolder}/fireball-mobile.png` : `${reebokFolder}/fire_ball.png`} alt="Reebok background" />
				<Section>
					<HeaderImage src={`${reebokFolder}/MLB_texts.png`} alt="header" />
					<StyledButton
						onClick={() => {
							if (!!formRef.current) {
								window.scrollTo({ top: formRef.current.getBoundingClientRect().top, left: 0, behavior: "smooth" });
							}
						}}
						css={`
							margin-left: 16px;

							@media (max-width: ${breakpoints.tablet}) {
								margin-left: 0;
							}

							@media (max-width: ${breakpoints.tablet}) {
								max-width: 235px;
							}
						`}
					>
						CLAIM YOUR eGIFT CARD
					</StyledButton>
				</Section>
				<Section
					css={`
						margin-left: 16px;

						@media (max-width: ${breakpoints.tablet}) {
							margin-left: 0;
						}
					`}
				>
					<Title>BUY MLB TICKETS AND EARN REEBOK eGIFT CARDS ALL SEASON</Title>
					<Icons src={`${reebokFolder}/MLB_icons.png`} alt="icons" />

					<TableContainer>
						<Table>
							{TableValues.map(rowValues => {
								return (
									<Row>
										<RowText>Spend {rowValues.left}</RowText>
										<RowText>Earn {rowValues.right} eGift card</RowText>
									</Row>
								);
							})}
						</Table>
						<TableBottomText>For every $500 you spend, you'll receive $50 toward a future purchase at Reebok stores or Reebok.com. *</TableBottomText>
						<TableDisclaimer>* Multiple purchases on a single user account may be combined to redeem. Users can earn a max of $200 in Reebok eGift Card(s)</TableDisclaimer>
					</TableContainer>
				</Section>
				<Section>
					<FormTitle ref={formRef}>
						The promo will run from June 1, 2023 to October 1, 2023. <br />
						You must register during this date range to be eligible
					</FormTitle>
					<Toaster position="bottom-center" />

					<Form
						onSubmit={async (values: { firstName: string; lastName: string; email: string; phone: string; zipCode: string }) => {
							try {
								const result = await postReebokPromotion({ email: values.email });
								toast.success(result.data.message);
							} catch {
								toast.error("Failed to submit your claim. Please contact our customer service at +1-646-453-7400 or support@sitickets.com");
							}
						}}
						initialValues={{ firstName: "", lastName: "", email: "", phone: "", zipCode: "" }}
						render={form => (
							<FormContainer>
								<form onSubmit={form.handleSubmit}>
									<SmallInputsContainer>
										<SmallInputWrapper>
											<Field name="firstName" component={ReebokRFFInput} type="text" placeholder="First Name" />
										</SmallInputWrapper>
										<SmallInputWrapper>
											<Field name="lastName" component={ReebokRFFInput} type="text" placeholder="Last Name" />
										</SmallInputWrapper>
									</SmallInputsContainer>
									<Field name="email" component={ReebokRFFInput} type="email" placeholder="Your Email" validate={value => required(value) || validEmail(value)} />
									<SmallInputsContainer>
										<SmallInputWrapper>
											<Field name="phone" component={ReebokRFFInput} type="text" placeholder="Phone" />
										</SmallInputWrapper>
										<SmallInputWrapper>
											<Field name="zipCode" component={ReebokRFFInput} type="text" placeholder="Zip Code" />
										</SmallInputWrapper>
									</SmallInputsContainer>
									<TermsAndConditionsText>
										By clicking Claim now you agree to the{" "}
										<strong>
											<u>
												<a
													css={`
														cursor: pointer;
													`}
													onClick={() => {
														setShowModal(true);
													}}
												>
													Terms and Conditions
												</a>
											</u>
										</strong>
									</TermsAndConditionsText>
									<ButtonContainer
										css={`
											justify-content: center;
										`}
									>
										<StyledButton type="submit" loading={form.submitting}>
											Claim Now
										</StyledButton>
									</ButtonContainer>
								</form>
							</FormContainer>
						)}
					/>
					<BottomText>Reebok eGift Card Limited Time Offer. Valid 6/1/23 through 10/1/23. US legal residents only +18. See official rules.</BottomText>
				</Section>
			</PageContainer>
		</CommonPage>
	);
};

const TermsAndConditionsModal = (props: { open: boolean; onClose: () => void }) => {
	return (
		<Dialog
			open={props.open}
			onClose={props.onClose}
			type="information"
			fullscreenOnMobile
			headerChildren={
				<div
					css={`
						overflow: hidden;
					`}
				>
					<Typography type="largeSpecialBody">Terms and Conditions</Typography>
				</div>
			}
			css={`
				${Dialog.Wrapper} {
					flex-direction: column;
				}
			`}
		>
			<img
				alt="Home"
				css={`
					align-self: center;
				`}
				width={150}
				src={si_tickets_logo}
			/>
			<TermsTitle type="bodyLarge">Reebok eGift Card Limited Time Offer </TermsTitle> <br />
			<TermsTitle type="bodyMedium">PROMOTION TERMS AND CONDITIONS </TermsTitle>
			<TermsText type="bodyNormal">
				SI Tickets, Inc. (the “Sponsor”) has partnered with Reebok to offer SI Tickets customers (“Users”) with one or more Reebok eGift Card (up to $200) with one (1) or more eligible MLB ticket purchase throughout the 2023 MLB season. Limited time offer.
				<br />
				<br />
				This promotional offer (“Offer”) is available to all Eligible Users with Eligible Purchases placed between June 1, 2023 and October, 1 2023 (the “Offer Period”).
			</TermsText>
			<br />
			<TermsTitle type="bodyMedium">OFFER PERIOD: </TermsTitle>
			<TermsText type="bodyNormal">The Offer Period begins at 12:00AM EST on June 1, 2023 and ends at 11:59PM EDT on October 1, 2023. </TermsText>
			<br />
			<TermsTitle type="bodyMedium">ELIGIBLE USERS: </TermsTitle>
			<TermsText type="bodyNormal">
				The Offer is available only to legal residents of the 50 United States and the District of Columbia who are eighteen (18) years or age or older. <br />
				<br /> Employees, representatives, and agents of Sponsor and their respective affiliates, subsidiaries, advertising and promotion agencies, suppliers, and their immediate family members, and/or anyone domiciled with any of the above are not eligible to participate in the Offer. <br />
				<br /> Users must have or create an SI Tickets Account and complete the Offer Redemption Form during the Offer Period in order to be eligible. Users must have Eligible Orders (see below) to receive the Offer. Offer is limited to a maximum of $200 in Reebok eGift Card(s) per person. You must use your own email address to be eligible. You cannot use multiple email addresses and/or accounts.
			</TermsText>
			<br />
			<TermsTitle type="bodyMedium">ELIGIBLE ORDERS: </TermsTitle>
			<TermsText type="bodyNormal">
				Eligible Orders are defined as any successful Eligible Ticket Purchase placed on www.SITickets.com for MLB Regular Season tickets. Only MLB event ticket items purchased will be considered Eligible Tickets. Parking passes, VIP Packages, and Postseason Ticket Reservations for the MLB are not eligible items. <br />
				<br />
				An order is considered successful once it becomes (“Active”). An order will become Active only once SI Tickets has successfully pre-authorized the payment method for the full amount of the order, SI Tickets has successfully reviewed the transaction and verified it does not appear to befraudulent, and the respective seller has confirmed the order. If the order is not successful, the order is considered Failed. Failed orders cannot become Active and are not considered Eligible Orders for the purpose of this promotion. Learn more about Active Orders{" "}
				<Link to={InternalRoutes.Terms}>
					<strong> here</strong>
				</Link>
				. Orders purchased with SI Tickets Promotional Wallet Credit are not considered Eligible Orders. <br />
				<br />
				If an Order for MLB Tickets placed during the Offer Term becomes canceled by SI Tickets, either because the event is canceled or for any other reason, the Order is no longer considered an Eligible Order. If an Order for MLB Tickets placed during the Offer Term is disputed by you with your Credit Card company and/or with PayPal, the Order is no longer considered an Eligible Order. SI Tickets reserves the right to invalidate any Reebok eGift Card(s) that were issued for Eligible Orders but thereafter were no longer considered Eligible Order(s), at its sole discretion.
			</TermsText>
			<br />
			<TermsTitle type="bodyMedium">THE OFFER </TermsTitle>
			<TermsText type="bodyNormal">
				SI Tickets Users must successfully purchase $500.00 USD or more in MLB Tickets on www.SITickets.com between December 7, 2022 and April 9, 2023 in order to earn a promotional Reebok eGift Card to use on Reebok.com or at Reebok retail stores. Eligible Orders on a single SI Tickets User Account may be combined.
				<ol
					css={`
						padding: 10px 40px;
					`}
				>
					{TermsValues.map(rowValues => {
						return (
							<li>
								<TermsText type="bodyNormal">
									Spend {rowValues.spend}, receive a {rowValues.receive} Reebok eGift card
								</TermsText>
							</li>
						);
					})}
				</ol>{" "}
			</TermsText>
			<TermsText type="bodyNormal">
				Combined orders must be made on a single SI Tickets Account. Users who have purchased $500.00 USD or more in MLB tickets during the Offer Period must register with SI Tickets by creating an SI Tickets Account in order to redeem the Offer; Users may place their order(s) as a guest (unregistered User), but must create an SI Tickets Account after the purchase(s) are made in order to become eligible. For assistance creating an SI Tickets Account please contact {""}
				<strong>
					<u>support@sitickets.com</u>
				</strong>
				.
			</TermsText>
			<br />
			<TermsText type="bodyNormal">Reebok eGift Cards may only be redeemed on Reebok.com or at Reebok retail stores, and are not valid for cash or a cash equivalent.</TermsText>
			<br />
			<TermsText type="bodyNormal">
				Users with eligible purchases may redeem the Offer by completing the “Offer Redemption Form” located on <StyledLink to={InternalRoutes.Reebok}>www.sitickets.com/reebok</StyledLink>.{" "}
			</TermsText>
			<br />
			<TermsText type="bodyNormal">
				Users will be asked to provide the following information in order to redeem the offer:
				<ol
					css={`
						padding: 10px 40px;
					`}
				>
					<li>
						<TermsText type="bodyNormal">First Name</TermsText>
					</li>
					<li>
						<TermsText type="bodyNormal">Last Name</TermsText>
					</li>
					<li>
						<TermsText type="bodyNormal">Email Address</TermsText>
					</li>
					<li>
						<TermsText type="bodyNormal">Phone Number</TermsText>
					</li>
					<li>
						<TermsText type="bodyNormal">Address of Residence</TermsText>
					</li>
				</ol>{" "}
			</TermsText>
			<TermsText type="bodyNormal">By submitting the Redemption form you agree to provide valid, truthful, accurate, and complete information. Additionally, Users must provide the email address and phone number which matches the email address and phone number associated with the Users eligible MLB ticket orders and the Users SI Tickets Account. </TermsText> <br />
			<TermsText type="bodyNormal">SI Tickets will verify Users who have requested to redeem the Offer, confirming that the User is registered, meets the eligibility requirements, and has SI Ticket Orders that meet the requirements to redeem the Offer. SI Tickets will issue Reebok eGift Cards in the denominations outlined above, based on the total amount spent on Eligible MLB purchases.</TermsText> <br />
			<TermsText type="bodyNormal">If additional MLB ticket purchases are made by a User after the User has already redeemed this Offer, and the User becomes eligible to receive an additional Reebok eGift Card, Users may resubmit the Offer Redemption Form and SI Tickets, at its sole discretion, will verify the information received and again verify that the User is registered, meets the eligibility requirements, and has additional SI Ticket Orders that meet the requirements to redeem the Offer. </TermsText>
			<br />
			<TermsText type="bodyNormal">SI Tickets will distribute Reebok eGift Cards via email; Reebok eGift Cards may only be sent to the email address provided on the form.</TermsText>
			<br />
			<TermsTitle type="bodyMedium">EXAMPLE:</TermsTitle>
			<TermsText type="bodyNormal">An Eligible User may successfully purchase $550.00 in MLB Tickets on SI Tickets during the Offer Period. This User may redeem the Offer by completing the Redemption Form. SI Tickets will verify the information submitted on the form, and, if verified at the sole discretion of SI Tickets, SI Tickets will issue a $50 Reebok eGift Card and send this to the User via email.</TermsText>
			<br />
			<TermsText type="bodyNormal">Thereafter, the same User may purchase an additional $450.00 in MLB Tickets on SI Tickets during the Offer Period. This User may redeem the Offer (again) by completing the Redemption Form. SI Tickets will verify the information submitted on the form, and, determine if there are additional SI Tickets Orders that meet the requirements to redeem the offer, and if verified at the sole discretion of SI Tickets, SI Tickets will issue another $50 Reebok eGift Card and send this to the User via email.</TermsText>
			<br />
			<TermsText type="bodyNormal">Users are only eligible to receive up to $200 in Reebok eGift Cards. Users can complete the Redemption Form at any time during the Offer Period. Users who do not complete the Redemption Form during the Offer Period but who have Eligible Purchases will not be able to redeem the Offer.</TermsText>
			<br />
			<TermsText type="bodyNormal">Offer cannot be combined with any other offer; MLB ticket orders purchased with SI Tickets Promotional Credit are not considered Eligible Orders. Acceptance of the eGift Card constitutes the User’s full and unconditional agreement to these terms and conditions, and any decision reached by Sponsor which is final and binding in all matters related to this Promotion.</TermsText>
			<br />
			<TermsText type="bodyNormal">
				These terms and conditions supplement SI Tickets <StyledLink to={InternalRoutes.Terms}>Terms of Use</StyledLink> and <StyledLink to={InternalRoutes.PrivacyPolicy}>Privacy Policy</StyledLink>.
			</TermsText>
			<br />
			<TermsTitle type="bodyMedium">HOW TO REDEEM: </TermsTitle>
			<TermsText type="bodyNormal">
				ONLINE: To redeem the Offer, you must visit <StyledLink to={InternalRoutes.Reebok}>www.sitickets.com/reebok</StyledLink> (the “Online Website”) during the Offer Period and complete the Offer Redemption Form in its entirety including complete name, mailing address, email address and phone number. You may not attempt to redeem the Offer by using multiple email addresses, identities, or devices to circumvent these Terms and Conditions. If you use fraudulent methods or otherwise attempts to circumvent the terms your submission may be removed from eligibility at the sole discretion of Sponsor. Offer Eligibility forms submitted by anyone other than you are void.
			</TermsText>
			<br />
			<TermsText type="bodyNormal">Neither Sponsor nor any representative, agent, affiliate, subsidiary, advertising and promotion agency, or supplier (“Affiliates”) is responsible for any submission that is lost, late, incomplete, invalid, misdirected, undeliverable, whether due to system errors, omissions, interruption, deletions, defects, delay in operations or transmissions, theft or destruction or failures, faulty transmissions or telecommunications malfunctions, human error, forms not received resulting from hardware or software failures of any kind, loss of unavailable network connections, failed, incomplete or garbled computer or telephone transmissions, typographical or system errors and failure, faulty transmissions, technical malfunctions, or otherwise. Likewise, Sponsor and Affiliates are not responsible if any Offer is canceled, delayed, or postponed to a future date.</TermsText>
			<br />
			<TermsText type="bodyNormal">
				Sponsor is not responsible for any issues or errors the User may experience when Redeeming or attempting to Redeem the Reebok eGift Card on Reebok.com. Users should contact Reebok directly if experiencing any technical problems Redeeming the Reebok eGift Card on Reebok.com. Users should contact {""}
				<strong>
					<u>support@sitickets.com</u>
				</strong>{" "}
				with any questions about their SI Tickets Account, Eligibility, and how to redeem the Offer.
			</TermsText>
			<br />
			<TermsTitle type="bodyMedium">RIGHTS OF PUBLICITY & PRIVACY </TermsTitle>
			<TermsText type="bodyNormal">You agree that, by completing the Offer Redemption Form, Sponsor and its affiliates may use, display, publish, or broadcast, your name, image, or likeness in any manner and in any mediumfor its promotional and advertising purposes without additional compensation, notification, or permission, except where prohibited by law.</TermsText>
			<br />
			<TermsText type="bodyNormal">Upon Sponsor or its affiliate's request, you will sign any necessary documentation that may be required for Sponsor or its affiliate to make use of the rights granted herein.</TermsText>
			<br />
			<TermsTitle type="bodyMedium">ADDITIONAL TERMS </TermsTitle>
			<TermsText type="bodyNormal">Sponsor reserves the right, in its sole discretion to cancel, terminate, modify, or suspend the Offer should (in its sole discretion) a virus, bugs, non-authorized human intervention, fraud, or other causes beyond its control corrupt or affect the administration, security, fairness or proper conduct of the Offer. Sponsor reserves the right at its sole discretion to disqualify any individual who tampers or attempts to tamper with the Offer Redemption process or the operation of the Offer or website or violates these Terms & Conditions. By redeeming the Offer, you agree to receive email newsletters periodically from Sponsor. You can opt-out of receiving this communication at any time by clicking the unsubscribe link in the newsletter. You agree that Sponsor’s decisions related to the Offer, or any distribution of Reebok eGift Cards are final.</TermsText>
			<br />
			<TermsText type="bodyNormal">With the exception of liability which cannot be excluded by law, Sponsor is not liable for, and you release Sponsor from, liabilities relating to any direct or indirect loss or damage which is suffered as a result of this Promotion or accepting or using the eGift Card(s). </TermsText>
		</Dialog>
	);
};

const Row = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 15px 25px;

	@media (max-width: ${breakpoints.mobile}) {
		padding: 10px 20px;
	}
`;

const RowText = styled.p`
	font-size: 23px;
	font-weight: 700;
	font-family: ${FontFamily.Poppins};
	color: ${getAppColor("light")};

	@media (max-width: ${breakpoints.tablet}) {
		font-size: 20px;
	}

	@media (max-width: ${breakpoints.mobile}) {
		font-size: 12px;
	}
`;

const Table = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 32px;

	& > ${Row} {
		border: 2px solid ${getAppColor("light")};
	}

	${Row}:not(:first-child) {
		border-top: none;
	}
`;

const TableContainer = styled.div`
	max-width: 615px;

	@media (max-width: ${breakpoints.mobile}) {
		max-width: 300px;
	}
`;

const TableBottomText = styled.p`
	font-size: 21px;
	line-height: 27px;
	color: ${getAppColor("light")};
	font-weight: 700;
	text-align: left;
	font-family: ${FontFamily.Poppins};
	margin-bottom: 12px;

	@media (max-width: ${breakpoints.tablet}) {
		text-align: center;
	}
	@media (max-width: ${breakpoints.mobile}) {
		font-size: 9.5px;
		line-height: 12px;
	}
`;

const TableDisclaimer = styled.p`
	font-size: 13px;
	line-height: 17px;
	color: ${getAppColor("light")};
	font-weight: 400;
	text-align: left;
	font-family: ${FontFamily.Poppins};
	@media (max-width: ${breakpoints.tablet}) {
		text-align: center;
	}

	@media (max-width: ${breakpoints.mobile}) {
		text-align: center;
		font-size: 6px;
		line-height: 10px;
	}
`;

const Title = styled.h1`
	font-weight: 900;
	font-size: 38px;
	font-family: ${FontFamily.Poppins};
	max-width: 688px;
	line-height: 38px;
	color: ${getAppColor("light")};
	text-align: left;

	@media (max-width: ${breakpoints.tablet}) {
		font-size: 28px;
		line-height: 28px;
		text-align: center;
	}

	@media (max-width: ${breakpoints.mobile}) {
		width: 337px;
		font-size: 18px;
		line-height: 18px;
	}
`;

const StyledButton = styled(Button)`
	border: 1px solid ${getAppColor("light")};
	height: 56px;
	border-radius: 0;
	width: 275px;

	& > * {
		font-weight: 700;
		background: none;
		font-size: 24px;
		text-transform: none;

		&:not(:disabled):hover {
			background: #010b30;
		}
	}

	@media (max-width: ${breakpoints.mobile}) {
		max-width: 157px;

		& > * {
			font-size: 16px !important;
		}
	}
`;

const Section = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 55px;

	@media (max-width: ${breakpoints.tablet}) {
		align-items: center;
		margin-bottom: 50px;
	}

	@media (max-width: ${breakpoints.mobile}) {
		margin-bottom: 28px;
	}
`;

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 455px;
	align-self: center;
	margin-top: 65px;

	@media (max-width: ${breakpoints.tablet}) {
		margin-top: 25px;
	}

	@media (max-width: ${breakpoints.mobile}) {
		max-width: 330px;
	}
`;

const HeaderImage = styled.img`
	max-height: 350px;
	max-width: 512px;
	margin-bottom: 55px;

	@media (max-width: ${breakpoints.tablet}) {
		max-width: 400px;
		margin-bottom: 45px;
	}

	@media (max-width: ${breakpoints.mobile}) {
		max-width: 299px;
		height: 204px;
		margin-bottom: 28px;
	}
`;

const Icons = styled.img`
	max-height: 100px;
	max-width: 400px;
	margin: 48px 0;

	@media (max-width: ${breakpoints.mobile}) {
		max-width: 200px;
		margin: 16px 0;
		width: 231px;
		height: 65px;
	}
`;

const PageContainer = styled.div`
	background-color: ${getAppColor("dark")};
	position: relative;
	padding: 120px 150px 0;
	display: flex;
	flex-direction: column;
	z-index: 0;

	@media (max-width: ${laptopBreakpoint}) {
		padding: 120px 60px;
	}

	@media (max-width: ${breakpoints.tablet}) {
		padding: 60px 20px 0;
	}

	@media (max-width: ${breakpoints.mobile}) {
		padding: 60px 19px 0;
	}
`;

const BackgroundImage = styled.img`
	position: absolute;
	width: 100%;
	height: auto;
	top: -25%;
	left: 0;
	z-index: -1;

	@media (max-width: ${breakpoints.mobile}) {
		top: -5%;
	}
`;

const FormTitle = styled.h2`
	font-size: 26px;
	line-height: 36px;
	font-family: ${FontFamily.Poppins};
	font-weight: 700;
	color: ${getAppColor("light")};

	@media (max-width: ${laptopBreakpoint}) {
		font-size: 22px;
		line-height: 32px;
	}

	@media (max-width: ${breakpoints.tablet}) {
		font-size: 15px;
		line-height: 20px;
	}

	@media (max-width: ${breakpoints.mobile}) {
		font-size: 9px;
		line-height: 13px;
	}
`;

const ReebokRFFInput = styled(StyledRFFInput)`
	font-family: ${FontFamily.Poppins};
	border-radius: 0px !important;
	color: ${getAppColor("dark")};

	@media (max-width: ${breakpoints.mobile}) {
		font-size: 10px;
	}
`;

const SmallInputWrapper = styled.div`
	width: 48%;
`;

const SmallInputsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const TermsAndConditionsText = styled.p`
	font-size: 12px;
	line-height: 12px;
	font-family: ${FontFamily.Poppins};
	font-weight: 400;
	text-align: center;
	color: ${getAppColor("light")};
	margin: 15px 0 50px;

	@media (max-width: ${breakpoints.mobile}) {
		font-size: 8.5px;
		line-height: 9px;
		margin: 0 0 30px;
	}
`;

const BottomText = styled.p`
	font-size: 16px;
	line-height: 23px;
	font-weight: 500;
	font-family: ${FontFamily.Poppins};
	color: ${getAppColor("light")};
	text-align: center;
	margin-top: 65px;

	@media (max-width: ${breakpoints.mobile}) {
		margin-top: 23px;
		font-size: 8.5px;
		line-height: 10.5px;
		max-width: 289px;
	}
`;

const TermsText = styled(Typography)`
	text-align: left;
`;

const TermsTitle = styled(TermsText)`
	font-weight: bold;
	text-transform: uppercase;
`;

const StyledLink = styled(Link)`
	font-weight: bold;
`;
