import React, { useState } from "react";
import styled from "styled-components/macro";
import { useMemoState } from "../../../hooks/useMemoState";
import { CollapseArrow } from "../../CollapseArrow/CollapseArrow";
import { Expandable } from "../../Expandable/Expandable";
import { Card } from "../../Card/Card";
import { formatPrice } from "../../../util/formatPrice";
import { mediaQueries } from "../../../util/mediaQueries";
import { SmallButton } from "../../Button/Button";
import { Badge } from "../../Badge/Badge";
import { flexGap } from "../../../util/optimized/styles";
import { removeTimezoneFromDateString, toBrowserLocaleString } from "../../../util/optimized/dates";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useExtraSmallScreen } from "../../../hooks/useExtraSmallScreen";
import { PartialBy } from "../../../../model/optimizedModel/general";
import { MyAccountTransactionOrderInfo } from "../../../../model/optimizedModel/myAccount";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAppColor } from "../../../util/appColors";
import { getTypographyStyles, Typography } from "../../Typography/Typography";

export interface ExportedMyAccountItemProps {
	controls?: React.ReactNode;
	status?: string;
	statusColor?: string;
	id: number;
}

//TODO: Intergate with the skeleton loader in the optimized frontend
export const MyAccountItem = (
	props: {
		topContent: React.ReactNode;
		bottomContent?: React.ReactNode;
		expandableContent?: React.ReactNode;
		date?: string | null;
		//Note: The PartialBy will not be needed once the backend is updated
		orderInfo?: PartialBy<MyAccountTransactionOrderInfo, "id" | "item_id">;
		hideBottomContentOnMobile?: boolean;
		isTransferred?: boolean;
		ticketControls?: React.ReactNode;
	} & ExportedMyAccountItemProps
) => {
	//TODO: Consider making expanded logic more DRY across all optimized components?
	const [expanded, setExpanded] = useState(false);

	const date = useMemoState(() => {
		if (props.date) {
			const value = new Date(removeTimezoneFromDateString(props.date));
			return value;
		}
	}, [props.date]);

	//TODO: Replace with optimized frontend equivalent
	const isMobile = useMediaQuery(mediaQueries.popupMax);

	const desktopBottomContent = useMemoState(() => {
		const value = props.bottomContent && (!isMobile || !props.hideBottomContentOnMobile) && <DesktopBottomContent>{props.bottomContent}</DesktopBottomContent>;
		return value;
	}, [isMobile]);

	const mobileBottomContent = useMemoState(() => {
		const value = props.bottomContent && isMobile && props.hideBottomContentOnMobile && <MobileBottomContent>{props.bottomContent}</MobileBottomContent>;
		return value;
	}, [isMobile]);

	const isExtraSmallScreen = useExtraSmallScreen();

	const expandableContentAvailable = useMemoState(() => {
		const value = !!(mobileBottomContent || props.expandableContent);
		return value;
	}, [mobileBottomContent, props.expandableContent]);

	const controlsAvailable = useMemoState(() => {
		const value = !!(props.controls || expandableContentAvailable);
		return value;
	}, [props.controls, expandableContentAvailable]);
	return (
		<StyledCard
			titleContent={
				<Title>
					{!props.isTransferred ? (
						<span>
							ID: #{props.id} {props.orderInfo?.item_id && `- ${props.orderInfo.item_id}`}
						</span>
					) : (
						<span>
							Transfer ID: #{props.id} {props.orderInfo?.item_id && `- ${props.orderInfo.item_id}`}
						</span>
					)}
					{!props.isTransferred && props.orderInfo && (
						<>
							{props.orderInfo && (
								<>
									<span>
										Order Placed:{" "}
										{toBrowserLocaleString(
											new Date(removeTimezoneFromDateString(props.orderInfo.created)),
											{
												//@ts-ignore TODO: TS considers this to be an invalid property when it's not, consider looking into a solution
												dateStyle: "short",
											},
											"date"
										)}
									</span>
									<span>Order Total: {props.orderInfo.total ? formatPrice(props.orderInfo.total) : "Free"} </span>
								</>
							)}
						</>
					)}
				</Title>
			}
		>
			<Top>
				{!isExtraSmallScreen && date && (
					<DateBadge>
						<span>
							{toBrowserLocaleString(date, {
								month: "short",
							})}
						</span>
						<DateBorder />
						<Day>
							{toBrowserLocaleString(date, {
								day: "2-digit",
							})}
						</Day>
						<span>
							{toBrowserLocaleString(date, {
								weekday: "short",
							})}
						</span>
						<Year>
							{toBrowserLocaleString(date, {
								year: "numeric",
							})}
						</Year>
						{
							//TODO: Consider seeing if there's a better way to detect if a time is provided in the date string.
							props.date && props.date[props.date.length - 1] === "Z" && (
								<span>
									{toBrowserLocaleString(date, {
										hour: "numeric",
										minute: "2-digit",
									})}
								</span>
							)
						}
					</DateBadge>
				)}
				<Content>
					{props.topContent}
					{desktopBottomContent}
				</Content>
			</Top>

			{(props.status || controlsAvailable) && (
				<Bottom>
					{props.status && (
						<Status
							style={{
								color: props.statusColor,
							}}
						>
							{props.status}
						</Status>
					)}
					{controlsAvailable && (
						<Controls>
							{!props.isTransferred && expandableContentAvailable && (
								<CollapseArrowButton
									onClick={() => {
										//TODO: Expandable/expandableContent hook?
										setExpanded(!expanded);
									}}
								>
									{expanded ? "Hide" : "Show"} Order Details
									<CollapseArrow expanded={expanded} size={9} weight={2} color="#ffffff" />
								</CollapseArrowButton>
							)}
							{props.controls}
						</Controls>
					)}
				</Bottom>
			)}

			{expandableContentAvailable && (
				<Expandable expanded={expanded}>
					<ExpandableContent>
						{mobileBottomContent}
						{props.expandableContent}
					</ExpandableContent>
				</Expandable>
			)}

			{props.ticketControls}
		</StyledCard>
	);
};

const CollapseTrigger = ({ handleExpand, expanded, title }: { handleExpand: () => void; expanded: boolean; title: string }) => {
	return (
		<div
			css={`
				background: transparent;
				display: flex;
				align-items: center;
				margin-top: 1rem;
				cursor: pointer;
			`}
			onClick={handleExpand}
		>
			<Typography
				type="bodySmall"
				fontWeight={600}
				css={`
					text-decoration: underline;
				`}
			>
				{title}
			</Typography>
			<FontAwesomeIcon
				icon={expanded ? faChevronDown : faChevronRight}
				color={getAppColor("dark")}
				css={`
					margin-left: 2px;
					margin-bottom: 2px;
					max-height: 13px;
				`}
			/>
		</div>
	);
};

const CollapseArrowButton = styled(SmallButton)`
	display: flex;
	align-items: center;
	width: 160px !important;
	justify-content: space-between;
`;

const DesktopBottomContent = styled.div`
	margin-top: 14px;
`;

const Content = styled.div`
	//TODO: This CSS was very quickly written, consider figuring out a better way to implement this.
	&,
	& * {
		text-overflow: ellipsis;
		overflow: hidden;
	}
`;

//MUI Typography h6
const Status = styled.span`
	font-size: 16px;
	color: black;
	font-weight: bold;
	text-align: left;
	&:not(:last-child) {
		margin-bottom: 8px;
	}
`;

const StyledCard = styled(Card)`
	text-align: left;
	&:not(:last-child) {
		margin-bottom: 24px;
	}
`;

const expandableContentTopSpacing = "14px";

const MobileBottomContent = styled.div`
	margin-bottom: 14px;
`;

const ExpandableContent = styled.div`
	margin-top: ${expandableContentTopSpacing};
	border-top: 1px solid lightgray;
	padding-top: ${expandableContentTopSpacing};
`;

const Controls = styled.div`
	${flexGap(["5px", "6px"])}
	display: flex;
	flex-wrap: wrap-reverse;
	margin-top: 5px;
`;

const titleMobileMediaQuery = "@media " + mediaQueries.max670;

const Title = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	& > * {
		//TODO: Consider improving these styles by creating new styled components (OrderTotal + OrderPlaced)/
		&:last-child:not(:first-child) {
			margin-left: auto;
		}
		&:not(:first-child):not(:last-child) {
			margin-left: 8px;
			padding-left: 8px;
			border-left: 1px solid black;
			${titleMobileMediaQuery} {
				padding: 0;
				border: none;
			}
		}
		${titleMobileMediaQuery} {
			margin-left: 0 !important;
			&:not(:last-child) {
				margin-bottom: 4px;
			}
		}
	}
	${titleMobileMediaQuery} {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const DateBadge = styled(Badge)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	//MUI Secondary color
	color: #12284c;
	font-weight: 500;
	white-space: nowrap;
	padding: 9px 0;
	min-width: 81px;
	//TODO: Consider setting this as the defualt Badge border radius since it's used twice in the MyAccountTicket invoice
	border-radius: 5px;
	margin-right: 20px;
	& > * {
		&:nth-child(odd) {
			margin-bottom: 3px;
			&:not(:first-child):not(:only-child) {
				font-weight: 600;
			}
		}
		&:nth-child(even) {
			margin-bottom: 2px;
		}
		&:nth-last-child(2) {
			margin-bottom: 4px;
		}
		&:last-child {
			margin: 0;
		}
	}
	& > span {
		//MUI subtitle2/body2
		font-size: 14px;
	}
`;

const Day = styled.h4`
	//MUI h4
	font-size: 34px;
`;

const Year = styled.p`
	//MUI subtitle1/body1
	font-size: 16px;
`;

const DateBorder = styled.div`
	height: 1px;
	background: lightgray;
	width: 44px;
`;

const Top = styled.div`
	display: flex;
	align-items: flex-start;
`;

const Bottom = styled.div`
	flex-direction: column;
	display: flex;
	margin-top: 16px;
`;
