import { useState, useEffect } from "react";

export const useLoadEvents = (resetEvents: () => void, loadEvents: () => void) => {
	const [resetQueued, setResetQueued] = useState(false);
	const [onLoad, setOnLoad] = useState(true);

	useEffect(() => {
		if (resetQueued) {
			loadEvents();
			setResetQueued(false);
		} else {
			setOnLoad(true);
		}
	}, [resetQueued]);

	useEffect(() => {
		if (!onLoad) {
			resetEvents();
			if (!resetQueued) {
				setResetQueued(true);
			}
		}
	}, [onLoad]);

	return { onLoad, setOnLoad };
};
