import React from "react";
import styled from "styled-components/macro";
import { Spinner } from "../Loader/Spinner";

export const LandingPageButton = ({
	children,
	loading,
	disabled,
	style,
	...spreadableProps
}: React.ButtonHTMLAttributes<HTMLButtonElement> & {
	loading?: boolean;
}) => {
	return (
		<StyledButton
			{...spreadableProps}
			children={loading ? <Spinner color="#000000" /> : children}
			disabled={disabled || loading}
			style={{
				...style,
				...(loading && {
					padding: 0,
				}),
			}}
		/>
	);
};

export const StyledButton = styled.button`
	width: 200px;
	max-width: none;
	-webkit-appearance: none;
	border: none;
	border-radius: 3px;
	outline: 0 !important;
	box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.1);
	height: 50px;
	box-sizing: border-box;
	font-size: 16px;
	font-weight: 700;
	color: #fff;
	background: #e92224;
	padding: 15px 0;
	font-family: Montserrat;
	font-style: normal;
	font-size: 18px;
	cursor: pointer;
	transition-property: background-color, box-shadow;
	transition-duration: 0.1s;
	transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
	display: flex;
	justify-content: center;
	align-items: center;
	&:disabled {
		background: #cccccc;
		box-shadow: none;
		cursor: initial;
	}
`;
