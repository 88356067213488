import React from "react";
import DOMPurify from "dompurify";

export const SantizedHTML = (props: { htmlString: string }) => {
	const sanitizedHtml = props.htmlString ? DOMPurify.sanitize(props.htmlString, { ADD_TAGS: ["iframe"],  USE_PROFILES: { html: true } }) : "";
	return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
};

export const SantizedHTMLSpan = (props: { htmlString: string }) => {
	const sanitizedHtml = props.htmlString ? DOMPurify.sanitize(props.htmlString, { USE_PROFILES: { html: true } }) : "";
	return <span dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
};
