import { Reducer } from "redux";
import { Trend } from "../../model/Trend";
import { ServiceCallError } from "../middleware/serviceCallMiddleware";

interface TrendsState {
	error: ServiceCallError | null;
	featuredLoaded: boolean;
	featuredTrends: Trend[];
	categoryTrends: {
		[parent: string]: Trend[];
	};
}

const defaultState: TrendsState = {
	error: null,
	featuredLoaded: false,
	featuredTrends: [],
	categoryTrends: {},
};

export const trendsReducer: Reducer<TrendsState, TrendsActions> = (state: TrendsState = defaultState, action: TrendsActions): TrendsState => {
	switch (action.type) {
		case "UPDATE_FEATURED_TRENDS":
			return { ...state, error: null, featuredLoaded: true, featuredTrends: action.trends };
		case "UPDATE_CATEGORY_TRENDS":
			return {
				...state,
				error: null,
				categoryTrends: { ...state.categoryTrends, [action.category]: action.trends },
			};
		case "UPDATE_TRENDS_ERROR":
			return { ...state, error: action.error };
		default:
			return state;
	}
};

export type TrendsActions =
	| {
			type: "UPDATE_FEATURED_TRENDS";
			trends: Trend[];
	  }
	| {
			type: "UPDATE_CATEGORY_TRENDS";
			category: string;
			trends: Trend[];
	  }
	| {
			type: "UPDATE_TRENDS_ERROR";
			error: ServiceCallError;
	  };
