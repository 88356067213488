import { Reducer } from "redux";
import { PresaleEvent } from "../../model/Event";
import { ServiceCallError } from "../middleware/serviceCallMiddleware";

interface PresaleState {
	error: ServiceCallError | null;
	featuredLoaded: boolean;
	featuredPresale: PresaleEvent[];
}

const defaultState: PresaleState = {
	error: null,
	featuredLoaded: false,
	featuredPresale: [],
};

export const presaleReducer: Reducer<PresaleState, PresaleActions> = (state: PresaleState = defaultState, action: PresaleActions): PresaleState => {
	switch (action.type) {
		case "UPDATE_FEATURED_PRESALE":
			return { ...state, error: null, featuredLoaded: true, featuredPresale: action.events };
		case "UPDATE_PRESALE_ERROR":
			return { ...state, error: action.error };
		default:
			return state;
	}
};

export type PresaleActions =
	| {
			type: "UPDATE_FEATURED_PRESALE";
			events: PresaleEvent[];
	  }
	| {
			type: "UPDATE_PRESALE_ERROR";
			error: ServiceCallError;
	  };
