import React from "react";
import { getAppColor } from "../../../util/appColors";
import { breakpoints } from "../../../util/breakpoints";
import { EventCardHeader, EventCardSubHeader } from "../StyledComponents";
import styled from "styled-components/macro";

export const LPEventCard = (props: { src: string; invert?: boolean; displayAsRows?: boolean; children: React.ReactNode }) => {
	return (
		<div
			css={`
				display: flex;
				border: 1px solid ${getAppColor("dark")};
				flex-direction: ${props.displayAsRows ? "column" : "row"}${props.invert ? "-reverse" : ""};
			`}
		>
			<div
				css={`
					flex: 1;
					display: flex;
				`}
			>
				<img
					src={props.src}
					alt="Event Picture"
					css={`
						max-width: 100%;
						height: 100%;
						object-fit: cover;
					`}
				/>
			</div>
			<div
				css={`
					flex: 1;
				`}
			>
				{props.children}
			</div>
		</div>
	);
};

const verticalPadding = "50px";
const Content = ({ title, subtitle, content, buttons }: { title: string; subtitle: string; content: React.ReactNode; buttons: React.ReactNode[] }) => {
	return (
		<div
			css={`
				display: flex;
				flex-direction: column;
				padding: ${verticalPadding} 30px;
				justify-content: space-between;
				height: calc(100% - 2 * ${verticalPadding});
				@media (max-width: ${breakpoints.large}) {
					align-items: center;
				}
			`}
		>
			<div>
				<EventCardHeader>{title}</EventCardHeader>
				<EventCardSubHeader>{subtitle}</EventCardSubHeader>
				{content}
			</div>

			<div
				css={`
					display: grid;
					gap: 10px;
					grid-template-columns: repeat(auto-fit, minmax(220px, 0.1fr));
					row-gap: 20px;

					@media (max-width: ${breakpoints.large}) {
						margin-top: 20px;
						align-self: center;
					}
				`}
			>
				{buttons}
			</div>
		</div>
	);
};

LPEventCard.Content = Content;

export default LPEventCard;
