import React from "react";
import { SmallButton, MediumButtonStyles } from "../Button/Button";
import { Spinner } from "../Loader/Spinner";
import styled from "styled-components/macro";

const buttonSpinnerMargin = 9;

export const ButtonWithSpinner = ({
	loading,
	stayEnabledOnLoading,
	children,
	spinnerPosition = "left",
	...buttonProps
}: React.PropsWithChildren<
	{
		loading?: boolean;
		stayEnabledOnLoading?: boolean;
		spinnerPosition?: "left" | "right";
	} & React.ButtonHTMLAttributes<HTMLButtonElement>
>) => {
	const ButtonSpinner = (props: { style?: React.CSSProperties }) => {
		return <>{loading && <Spinner style={props.style} />}</>;
	};

	return (
		<ButtonContainer>
			{spinnerPosition === "left" && (
				<ButtonSpinner
					style={{
						marginRight: buttonSpinnerMargin,
					}}
				/>
			)}
			<SmallButton {...buttonProps} disabled={buttonProps.disabled || (loading && !stayEnabledOnLoading)}>
				{children}
			</SmallButton>
			{spinnerPosition === "right" && (
				<ButtonSpinner
					style={{
						marginLeft: buttonSpinnerMargin,
					}}
				/>
			)}
		</ButtonContainer>
	);
};

const ButtonContainer = styled.div`
	display: flex;
	margin-left: auto;
	align-items: center;
	& > ${Spinner} {
		font-size: 3px;
	}
	& > ${SmallButton} {
		margin: 0;
	}
`;

export const MediumButtonWithSpinner = styled(ButtonWithSpinner)`
	${MediumButtonStyles}
`;
