import React, { useRef, useEffect } from "react";
import { Typography, getTypographyStyles } from "../Typography/Typography";
import styled from "styled-components/macro";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";
import { PrimaryBorder } from "../PrimaryBorder/PrimaryBorder";
import { SkeletonWrapper } from "../SkeletonWrapper/SkeletonWrapper";
import { SkeletonProps } from "../../../model/optimizedModel/skeleton";
import { useAutoUpdateState } from "../../hooks/useAutoUpdateState";

const baseSubtitleProps: React.ComponentProps<typeof Typography> = {
	type: "bodySmall",
	color: "dark",
	colorType: "light",
};

export const Thumbnail = ({
	aspectRatio = [6, 11],
	...props
}: {
	selected?: boolean;
	onClick?: () => void;
	title?: string;
	subtitle?: string;
	secondarySubtitle?: string;
	name?: string;
	titleLoading?: boolean;
	subtitleLoading?: boolean;
	secondarySubtitleLoading?: boolean;
	setImageHeight?: React.Dispatch<React.SetStateAction<number>>;
	aspectRatio?: [number, number];
} & StyledComponentProps &
	SkeletonProps<
		{
			background: string;
		},
		"backgroundLoading"
	>) => {
	const displayTitleElement = useAutoUpdateState(() => props.title !== undefined || props.titleLoading, [props.title, props.titleLoading]);

	const displaySubtitleElement = useAutoUpdateState(() => props.subtitle !== undefined || props.subtitleLoading, [props.subtitle, props.subtitleLoading]);

	const displaySecondarySubtitleElement = useAutoUpdateState(() => props.secondarySubtitle !== undefined || props.secondarySubtitleLoading, [props.secondarySubtitle, props.secondarySubtitleLoading]);

	const thumbnailRef = useRef<HTMLDivElement>(null);

	const onResize = () => {
		if (thumbnailRef.current && props.setImageHeight) {
			props.setImageHeight(thumbnailRef.current.clientHeight);
		}
	};

	useEffect(() => {
		window.addEventListener("resize", onResize);
		onResize();
		return () => {
			window.removeEventListener("resize", onResize);
		};
	}, []);
	return (
		<ThumbnailContainer className={props.className} onClick={props.onClick}>
			<SkeletonWrapper
				loading={props.backgroundLoading}
				style={{
					...((displayTitleElement || displaySubtitleElement || displaySecondarySubtitleElement) && {
						marginBottom: 8,
					}),
					zIndex: 0,
				}}
			>
				<ThumbnailContent
					style={{
						...(props.name !== undefined &&
							!props.selected && {
								background: "linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, #000000 100%)",
							}),
					}}
				>
					<AspectRatioWrapper
						style={{
							paddingBottom: 100 * (aspectRatio[0] / aspectRatio[1]) + "%",
						}}
					>
						<ImageWrapper ref={thumbnailRef}>
							<ThumbImage src={props.background} alt="Thumbnail" />
							{props.selected && <SelectedBorder />}
						</ImageWrapper>
					</AspectRatioWrapper>
					{props.name !== undefined && <ThumbText>{props.name}</ThumbText>}
				</ThumbnailContent>
			</SkeletonWrapper>
			{displayTitleElement && (
				<Title type="heading3" loading={props.titleLoading}>
					{props.title}
				</Title>
			)}
			{displaySubtitleElement && (
				<Subtitle loading={props.subtitleLoading} {...baseSubtitleProps}>
					{props.subtitle}
				</Subtitle>
			)}
			{displaySecondarySubtitleElement && (
				<Subtitle loading={props.secondarySubtitleLoading} {...baseSubtitleProps}>
					{props.secondarySubtitle}
				</Subtitle>
			)}
		</ThumbnailContainer>
	);
};

const SelectedBorder = styled(PrimaryBorder)`
	position: absolute;
	width: 100%;
	bottom: 0;
`;

const ThumbnailContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const ThumbnailContent = styled.div`
	position: relative;
	cursor: pointer;
	&:hover {
		background: none !important;
	}
`;

const AspectRatioWrapper = styled.div`
	width: 100%;
	height: 0;
	position: relative;
`;

const ImageWrapper = styled.div`
	position: absolute;
	z-index: -1;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`;

const ThumbImage = styled.img`
	display: block;
	width: 100%;
	height: 100%;
`;

const ThumbText = styled.span`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	padding: 0px 21px;
	box-sizing: border-box;
	${getTypographyStyles("heading1", {
		color: "light",
	})}
	font-size: 157% !important;
	line-height: 1.1 !important;
`;

const Title = styled(Typography)`
	z-index: 0;
	text-align: left;
	margin-bottom: 8px;
	h3 {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;

const Subtitle = styled(Typography)`
	z-index: 0;
	text-align: left;
	p {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;
