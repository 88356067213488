const priceFormatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	//TODO: Consider adding internationalization integration.
	currency: "USD",
	minimumFractionDigits: 2,
});

const shortPriceFormatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	//TODO: Consider adding internationalization integration.
	currency: "USD",
	maximumFractionDigits: 0,
	minimumFractionDigits: 0,
});

export const formatPrice = (value: number | undefined | null) => {
	if (value !== undefined && value !== null) {
		return priceFormatter.format(value);
	}
};

export const shortFormatPrice = (price: number | undefined | null) => {
	if (price !== undefined && price !== null) {
		const value = shortPriceFormatter.format(price);
		return value;
	}
};
