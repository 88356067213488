import React from "react";
import { FuturePanel } from "./FuturePanel";

interface TeamGame {
	name: string;
	eventSlug: string;
	venue: string;
	date: Date | null;
	from: number | null;
}

interface Team {
	teamSlug: string;
	teamTitle: string;
	games: TeamGame[];
}
interface TeamFuturePanelProps {
	teams: Team[];
}

export const TeamFuturePanel = (props: TeamFuturePanelProps) => {
	return (
		<FuturePanel<Team, TeamGame>
			chooseItemName="Game"
			items={props.teams}
			subItemsKey="games"
			titleKey="teamTitle"
			renderSubItem={(data, parentItem) => {
				return {
					name: data.name,
					price: data.from,
					teamSlug: parentItem.teamSlug,
					eventSlug: data.eventSlug,
				};
			}}
		/>
	);
};
