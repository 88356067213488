import * as t from "io-ts";

function makeRecord<K extends keyof any, V>(key: K, value: V): Record<K, V> {
	return { [key]: value } as Record<K, V>;
}

export function RelabelCodec<Target extends keyof any, Source extends keyof any, Codec extends t.Mixed>(source: Source, target: Target, codec: Codec) {
	return t.strict(makeRecord(source, codec)).pipe(
		new t.Type<Record<Target, t.TypeOf<Codec>>, Record<Source, t.TypeOf<Codec>>, Record<Source, t.TypeOf<Codec>>>(
			`${source}`,
			(u): u is Record<Target, t.TypeOf<Codec>> => typeof u === "object" && codec.is((u as any)[target]),
			(i, c) => t.success(makeRecord(target, i[source])),
			a => makeRecord(source, a[target])
		)
	);
}
