import React from "react";
import { bottomDialogSpacingUnit, Dialog, verticalDialogSpacing, verticalDialogSpacingUnit, bottomDialogSpacing } from "../Dialog/Dialog";
import { getTypographyStyles, Typography } from "../Typography/Typography";
import styled from "styled-components/macro";
import { getAppColor } from "../../util/appColors";
import { primaryBorderValue } from "../PrimaryBorder/PrimaryBorder";
import { BetData } from "../../../model/SIBets";
import { BetHomeTeamAndAwayTeam } from "../NewBets/BetHomeTeamAndAwayTeam";
import { Button } from "../NewButton/Button";
import { shortFormatPrice } from "../../util/formatPrice";
import { BetDialogDisclaimer } from "../BetDialogDisclaimer/BetDialogDisclaimer";
import { breakpoints } from "../../util/breakpoints";

const baseBet = 25;

export const EndOfSessionPopup = ({
	betData,
	...dialogProps
}: {
	betData: BetData;
} & Pick<React.ComponentProps<typeof Dialog>, "open" | "onClose">) => {
	return (
		<StyledDialog {...dialogProps} type="information" fullscreenOnMobile>
			<ContentWrapper>
				<Title>Let's get you in the action!</Title>
				<Content>
					<Data>
						{betData.homeTeam && betData.awayTeam && (
							<StyledBetHomeTeamAndAwayTeam
								homeTeam={
									betData.homeTeam.odds && betData.awayTeam.odds
										? {
												...betData.homeTeam,
												oddsType: betData.homeTeam?.odds < betData.awayTeam?.odds ? "bestOdds" : "worstOdds",
										  }
										: betData.homeTeam
								}
								awayTeam={
									betData.homeTeam.odds && betData.awayTeam.odds
										? {
												...betData.awayTeam,
												oddsType: betData.awayTeam?.odds < betData?.homeTeam.odds ? "bestOdds" : "worstOdds",
										  }
										: betData.homeTeam
								}
								eventDate={betData.eventDate}
							/>
						)}
						<ChooseYourBetTitle type="heading3" color="darkGrey" colorType="dark">
							Choose your bet
						</ChooseYourBetTitle>
						<Bets>
							{betData.bets.map((bet, index) => {
								return (
									<Bet key={index}>
										<BetRow>
											<SportsbookLogoWrapper>
												<SportsbookLogo src={bet.sportsbook.logo} alt={bet.sportsbook.name} />
											</SportsbookLogoWrapper>
											<BetTitleWrapper>
												<BetTitle type="normalSpecialBody">
													{bet.title}
													{!!bet.value && (
														<>
															<br />
															Over {bet.value}
														</>
													)}
												</BetTitle>
											</BetTitleWrapper>
											<EarningsSectionWrapper>
												<EarningsSection>
													<BaseBet type="heading4">Bet {shortFormatPrice(baseBet)}</BaseBet>
													<Earnings type="extraLargeSpecialBody" color="success">
														Win {shortFormatPrice(baseBet * bet.odds)}
													</Earnings>
												</EarningsSection>
											</EarningsSectionWrapper>
										</BetRow>
										<IncentiveRow>
											<Incentive type="bodySmall">{bet.incentive}</Incentive>
											<Button
												//TODO: Remove the below line.
												//@ts-ignore
												forwardedAs="a"
												target="_blank"
												href={bet.url}
												size="small"
											>
												Get the offer
											</Button>
										</IncentiveRow>
									</Bet>
								);
							})}
						</Bets>
						<Footer>
							<FooterMainText type="bodySmall">
								<b>Can't do it now?</b> No worries, details are on your purchase confirmation.
							</FooterMainText>
							<FooterSecondaryText type="bodyTiny" color="darkGrey" colorType="dark">
								Offers available to new sportsbook users
							</FooterSecondaryText>
						</Footer>
					</Data>
					<BetDialogDisclaimer />
				</Content>
			</ContentWrapper>
		</StyledDialog>
	);
};

const Bets = styled.div`
	display: grid;
	grid-auto-rows: 1fr;
`;

const Data = styled.div`
	padding: 0 ${verticalDialogSpacing}${verticalDialogSpacingUnit};
	max-width: 100%;
	box-sizing: border-box;
`;

const Title = styled.h1`
	${getTypographyStyles("heading1", {
		color: "light",
	})}
	border-bottom: ${primaryBorderValue};
	padding: 0 5px;
	white-space: nowrap;
`;

const ContentWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-bottom: -${bottomDialogSpacing + bottomDialogSpacingUnit};
`;

const StyledDialog = styled(Dialog)`
	max-width: ${breakpoints.mobile};
	background: ${getAppColor("dark")};
`;

const StyledBetHomeTeamAndAwayTeam = styled(BetHomeTeamAndAwayTeam)`
	margin-top: 11px;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	background: ${getAppColor("light", "main")};
	margin: 0 -${verticalDialogSpacing}px;
	margin-top: 16px;
	align-items: center;
	max-width: calc(100% + ${verticalDialogSpacing * 2}px);
	height: 100%;
`;

const ChooseYourBetTitle = styled(Typography)`
	margin-top: 15px;
	margin-bottom: 6px;
`;

const Bet = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	border-top: 1px solid ${getAppColor("darkGrey", "light")};
`;

const BetRow = styled.div`
	display: flex;
	padding: 9px 0;
	margin: auto 0;
	align-items: stretch;
	width: 100%;
	& > * {
		padding: 0 15px;
		box-sizing: border-box;
		&:not(:first-child) {
			border-color: ${getAppColor("lightGrey", "dark")};
			border-left-width: 1px;
			border-style: solid;
		}
		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
		}
		&:first-child,
		&:last-child {
			flex: 1 1 0%;
		}
	}
`;

const IncentiveRow = styled.div`
	display: flex;
	margin: 9px 0;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

const BetTitle = styled(Typography)`
	font-weight: bold;
`;

const BetTitleWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 41%;
`;

const SportsbookLogoWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const SportsbookLogo = styled.img`
	max-width: 100%;
	max-height: 45px;
`;

const EarningsSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const EarningsSectionWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Earnings = styled(Typography)`
	white-space: nowrap;
`;

const BaseBet = styled(Typography)`
	white-space: nowrap;
`;

const Incentive = styled(Typography)`
	font-weight: 500;
	text-align: left;
	max-width: 250px;
	word-wrap: break-word;
	margin-right: 16px;
`;

const Footer = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
	align-items: center;
	padding-top: 12px;
	border-top: 1px solid ${getAppColor("darkGrey", "light")};
`;

const FooterMainText = styled(Typography)`
	& > b {
		font-weight: bold;
	}
`;

const FooterSecondaryText = styled(Typography)`
	text-align: left;
	width: 100%;
	margin-bottom: 5px;
`;
