import React, { useContext } from "react";

const ModalRouteContext = React.createContext({
	closeAllModals: () => {},
});

export const ModalRouteContextProvider = ModalRouteContext.Provider;

export const useModalRouteContext = () => {
	return useContext(ModalRouteContext);
};
