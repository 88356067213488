import React from "react";
import styled from "styled-components/macro";
import { getAppColor } from "../../util/appColors";
import { getTypographyStyles } from "../Typography/Typography";

export const EventTopMessage = (props: { message: string | JSX.Element }) => {
	return (
		<MobileTopMessageFixed>
			<HeaderMessage>{props.message}</HeaderMessage>
		</MobileTopMessageFixed>
	);
};

const MobileTopMessageFixed = styled.div`
	width: 100%;
	overflow: hidden;
	font-size: min(0.9rem, 12px);
	text-align: center;
	color: white;
	background-color: ${getAppColor("dark")};
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	height: 30px;
	z-index: 3;
`;

const HeaderMessage = styled.span`
	${getTypographyStyles("bodyTiny")};
	color: ${getAppColor("light")};
`;
