import React, { HTMLAttributes } from "react";
import styled from "styled-components/macro";
import { DateTime } from "luxon";
import { SearchItem, SearchResponse } from "../../../services/actions/mainSearchService";
import { CategorySearchResponse, EventSearchItem, PerformerSearchResponse, VenueSearchResponse } from "../../../services/types/MainSearchResponseCodec";
import { Link } from "../../Link";
import { maxContentWidthPadding } from "../../util/maxContentWidth";
import { Event } from "../../../model/Event";
import { EventsResponse } from "../../../services/types/EventsResponse";
import { isMobileApp } from "../../util/isMobileApp";
import { useIsMobileWeb } from "../../hooks/useIsMobileWeb";
import { ResultHeader, SearchResultsSection } from "./SearchResultsSection";
import { addRecentEventSearch, addRecentGenericSearch } from "../../../store/reducers/searchReducer";
import { store } from "../../../store/store";
import { useAutoUpdateState } from "../../hooks/useAutoUpdateState";
import { SantizedHTML } from "../SantizedHTML/SantizedHTML";

interface MainSearchResultsProps extends HTMLAttributes<HTMLDivElement> {
	events?: SearchResponse | null;
	venues?: VenueSearchResponse | null;
	performers?: PerformerSearchResponse | null;
	categories?: CategorySearchResponse | null;
	showSuggestions?: boolean;
	suggestions?: EventsResponse["data"];
	popularSubcategories?: CategorySearchResponse | null;
	recentGenericSearches?: SearchItem[];
	recentEventSearches?: EventSearchItem[];
}

export const MainSearchResults = React.forwardRef<HTMLDivElement, MainSearchResultsProps>(({ events, venues, performers, categories, showSuggestions, popularSubcategories, suggestions, recentGenericSearches, recentEventSearches, ...rest }, ref) => {
	const isMobileWeb = useIsMobileWeb();
	const isMobile = useAutoUpdateState(() => isMobileWeb || isMobileApp, [isMobileWeb, isMobileApp]);

	return (
		<ResultsContainer isMobile={isMobile} {...rest} ref={ref}>
			{showSuggestions ? (
				<SearchResultsSection title="Popular Events" items={suggestions} mappingFunction={mapSuggestionItemRow} />
			) : (
				<>
					<SearchResultsSection title="Categories" items={categories} mappingFunction={mapSearchItemRow} />

					<SearchResultsSection title="Performers" items={performers} mappingFunction={mapSearchItemRow} />

					<SearchResultsSection title="Events" items={events} mappingFunction={mapEventItemRow} />

					<SearchResultsSection title="Venues" items={venues} displayedItems={3} mappingFunction={mapSearchItemRow} />

					<RecentSearches title="Recent Searches" recentGenericSearches={recentGenericSearches} recentEventSearches={recentEventSearches} />

					<SearchResultsSection title="Popular Categories" items={popularSubcategories} mappingFunction={mapSearchItemRow} displayedItems={5} />
				</>
			)}
		</ResultsContainer>
	);
});

const RecentSearches = ({ title, recentGenericSearches, recentEventSearches }: { title: string; recentGenericSearches?: SearchItem[]; recentEventSearches?: EventSearchItem[] }) => {
	const isMobileWeb = useIsMobileWeb();
	const isMobile = useAutoUpdateState(() => isMobileWeb || isMobileApp, [isMobileWeb, isMobileApp]);

	return (
		<>
			{(!!recentGenericSearches?.length || !!recentEventSearches?.length) && (
				<>
					<ResultHeader isMobile={isMobile} title={title} />

					<SearchResultsSection title="Recent Event Searches" items={recentEventSearches} mappingFunction={mapEventItemRow} displayedItems={2} disableResultHeader />

					<SearchResultsSection title="Recent Searches" items={recentGenericSearches} mappingFunction={mapSearchItemRow} displayedItems={3} disableResultHeader />
				</>
			)}
		</>
	);
};

const resultsSideMargin = maxContentWidthPadding + "px";

const ResultsContainer = styled.div<{ isMobile: boolean }>`
	display: flex;
	flex-direction: column;
	font-weight: 600;
	color: #707372;
	font-size: 16px;
	overflow: auto;

	${props =>
		props.isMobile &&
		`
		order:3;
	`};
`;

const encodeUrl = (url:string) : string => {
	let encodedUrl = encodeURIComponent(url);
	return encodedUrl.replaceAll('%2F', '/');
}	

export const mapSearchItemRow = (values: SearchItem[], length: number) => 
	 values.slice(0, length).map((props, index) => {

		return <ResultsTab
			to={encodeUrl(props.url)}			
			key={index}
			onClick={() => {
				store.dispatch(addRecentGenericSearch(props));
			}}
		>
			<SantizedHTML htmlString={props.name} />
		</ResultsTab>
});

const ResultsTab = styled(Link)`
	padding: 15px ${resultsSideMargin};
	text-align: left;

	&:hover {
		background: #eee;
	}
`;

const mapSuggestionItemRow = (values: Event[], length: number) =>
	values.slice(0, length).map((props, index) => {
		return <EventResult key={index} url={`event/${props.slug}`} occursAt={props.date} name={props.name} venueName={props.venueName} venueLocation={props.venueLocation || ""} venueCity={props.venueCity || ""} venueState={props.venueState || ""} />;
	});

const mapEventItemRow = (values: (EventSearchItem & {occursAtDateEnd?:Date})[] , length: number) =>
	values.slice(0, length).map((props, index) => {
		return (
			<EventResult
				onClick={() => {
					store.dispatch(addRecentEventSearch(props));
				}}
				key={index}
				url={props.url}
				occursAt={props.occursAt}
				occursAtDateEnd={props.occursAtDateEnd}  
				name={props.name}
				venueName={props.venue.name}
				venueLocation={props.venue.location || ""}
				venueCity={props.venue.city || ""}
				venueState={props.venue.state || ""}
			/>
		);
	});

const EventResult = (props: { url: string; occursAt?: Date; occursAtDateEnd?: Date, name: string; venueName: string; venueLocation: string; venueCity?: string; venueState?: string; onClick?: () => void }) => {
	const date = (() => {
		if (props.occursAt) {
			let occursAt = props.occursAt;
			if (typeof props.occursAt === "string") {
				occursAt = new Date(props.occursAt);
			}
			return DateTime.fromJSDate(occursAt);
		}
		return null;
	})();
	const endDate = (() => {
		if (props.occursAtDateEnd) {
			let occursAtDateEnd = props.occursAtDateEnd;
			if (typeof props.occursAt === "string") {
				occursAtDateEnd = new Date(props.occursAtDateEnd);
			}

			return DateTime.fromJSDate(occursAtDateEnd);
		}
		return null;
	})();

	const getCityAndStateDisplay = () => {
		if (props.venueCity && props.venueState) {
			return `, ${props.venueCity}, ${props.venueState}`;
		} else if (props.venueCity) {
			return `, ${props.venueCity}`;
		} else if (props.venueState) {
			return `, ${props.venueState}`;
		}
		return "";
	};

	const replaceOutOfFrameTime = () => {
		if (date) {
			let time = date.toLocaleString(DateTime.TIME_SIMPLE);
			time = time.startsWith("3:30") ? "TBD" : time;
			time = time.startsWith("0:00") ? "TBD" : time;
			return time;
		}
	};

	return (
		<EventResultsTab
			to={encodeUrl(props.url)}
			onClick={() => {
				if (props.onClick) {
					props.onClick();
				}
			}}
		>
			<EventResultsTabContainer>
				{date && (
					<DatePartStart occursAtDateEnd={props.occursAtDateEnd}>
						{date.toLocaleString({ weekday: "short" })}
						<DateHighlight>
							{date.toLocaleString({ day: "numeric", month: "short" })} {date.toLocaleString({ year: "numeric" })}
						</DateHighlight>
						{replaceOutOfFrameTime()}
					</DatePartStart>
				)} 
				{endDate && (
					<> - <DatePartEnd >
						{endDate.toLocaleString({ weekday: "short" })}
						<DateHighlight>
							{endDate.toLocaleString({ day: "numeric", month: "short" })} {endDate.toLocaleString({ year: "numeric" })}
						</DateHighlight>
						{endDate?.toLocaleString(DateTime.TIME_SIMPLE)}
					</DatePartEnd>
					</>
				)}
				<div>
					<NameHighlight><SantizedHTML htmlString={props.name}/></NameHighlight>
					<Location>
						{[props.venueName, props.venueLocation].filter(x => x).join(" - ")}
						{getCityAndStateDisplay()}
					</Location>
				</div>
			</EventResultsTabContainer>
		</EventResultsTab>
	);
};

const EventResultsTab = styled(ResultsTab)`
	font-family: Montserrat;
	margin-bottom: 8px;
	&:last-child {
		margin-bottom: 0;
	}
	&:not(:last-child) {
		border-bottom: 1px solid #e5e5e5;
	}
`;

const EventResultsTabContainer = styled.div`
	display: flex;
	align-items: center;
`;

const DatePartStart = styled.div<{ occursAtDateEnd?: Date }>`
	color: black;
	border-right: ${props =>
		!props.occursAtDateEnd &&
		`
		1px solid #e5e5e5
	`};
	margin-right: ${props =>
		!props.occursAtDateEnd &&
		`10px`};
	padding-right: ${props =>
		!props.occursAtDateEnd &&
		`10px`};
	font-size: 15px;
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	min-width: 112px;
`;


const DatePartEnd = styled.div`
	color: black;
	border-right: 1px solid #e5e5e5;
	margin-right: 10px;
	padding-right: 10px;
	font-size: 15px;
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	min-width: 112px;
`;

const DateHighlight = styled.div`
	text-transform: uppercase;
	font-weight: 600;
	margin: 2px 0;
`;

const NameHighlight = styled.div`
	color: black;
	font-weight: 600;
	font-size: 16px;
	margin-bottom: 5px;
`;

const Location = styled.div`
	color: black;
	font-weight: 400;
	font-size: 14px;
`;
