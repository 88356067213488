import { useHistory } from "react-router-dom";

export const useOpenAdLink = () => {
	const history = useHistory();
	return (link: string) => {
		if (link[0] === "/") {
			history.push(link);
		} else {
			const protocol = "https://";
			let url = link;
			if (link.slice(0, protocol.length).toLowerCase() !== protocol) {
				url = protocol + url;
			}
			window.open(url, "_blank");
		}
	};
};
