import { DateTime } from "luxon";

export interface HumanReadableDateOptions {
	hideTime?: boolean;
}

export const getHumanReadableDate = (eventDate?: Date | null, options?: HumanReadableDateOptions) => {
	if (eventDate) {
		const date = DateTime.fromJSDate(eventDate).toFormat("t");

		if (date.startsWith("3:30") || date.startsWith("0:00")) {
			return `${DateTime.fromJSDate(eventDate).toFormat("ccc, LLL dd yyyy")}  ${options?.hideTime ? "" : "TBD"}`;
		}
		return DateTime.fromJSDate(eventDate).toFormat("ccc, LLL dd yyyy" + (options?.hideTime ? "" : " 'at' t"));
	} else {
		return "TBD";
	}
};
