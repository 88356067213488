import React from "react";
import styled from "styled-components/macro";
import { getAppColor } from "../../util/appColors";
import { Typography } from "../Typography/Typography";

export const BetDialogDisclaimer = () => {
	return (
		<DisclaimerSection>
			<Typography type="bodyTiny" color="light" colorType="light">
				Odds subject to change. Adult users only. If you or someone you know has a gambling problem and wants help, call <a href="tel:18004262537">1-800 GAMBLER</a> or visit{" "}
				<a href="https://800gambler.org/" target="_blank" rel="noopener noreferrer">
					800gambler.org
				</a>
			</Typography>
		</DisclaimerSection>
	);
};

const DisclaimerSection = styled.div`
	display: flex;
	align-items: center;
	padding: 4px 7px;
	background: ${getAppColor("darkGrey", "dark")};
	margin-top: auto;
`;
