import { useSelector } from "react-redux";

/**
 * Returns true if any of the specified service calls are running.
 * serviceKey parameter is optional. If no arguments are passed
 * It returns true if at least one service call is runing.
 */
export function useServiceMonitor(serviceKey: string[] = []): boolean {
	const calls = useSelector(state => state.transient.serviceCalls);

	if (serviceKey.length) {
		return serviceKey.some(service => calls[service] === true);
	} else {
		return Object.values(calls).some(call => call);
	}
}
