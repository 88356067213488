import React, { useEffect } from "react";
import styled from "styled-components/macro";
import queryString from "query-string";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { CommonPage, Events } from "../components";
import { parseSearch } from "../util/parseSearch";
import { InternalRoutes } from "../Link";
import { getDiscoverEvents } from "../../services/actions/discoverService";
import { maxContentWidthValue } from "../util/maxContentWidth";
import { useDispatch, useSelector } from "react-redux";
import { useObjectMergeReducer, useServiceMonitor } from "../hooks";
import { isRight } from "fp-ts/lib/These";
import { Event } from "../../model/Event";
import { NormalEventItem, SportEventItem } from "../components/Event/Event";
import localEventsHeader from "../resource/image/local-events-header.png";
import localEventsHeaderMobile from "../resource/image/local-events-header-mobile.png";
import { isMobileApp } from "../util/isMobileApp";
import { MetaDecorator, MetaTagData } from "../components/SEOMetadecorator/MetaDecorator";
import { useIsMobileWeb } from "../hooks/useIsMobileWeb";
import { LocationPicker } from "../components/LocationPicker";
import { DiscoverFilters, DiscoverFilterState } from "../components/Discover/DiscoverFilters";
import { DISTANCE_FILTER_OPTIONS } from "../components/Seatics/consts";
import { useLoadEvents } from "../hooks/useLoadEvents";

const HEADER_HEIGHT_PERCENT = 26.63;
const PAGE_SIZE = 10;
const DEFAULT_DISTANCE = DISTANCE_FILTER_OPTIONS[0];
interface DiscoverState {
	page: number;
	total: number;
	events: Event[];
}

export const Discover = (props: RouteComponentProps) => {
	const searchParams = parseSearch<DiscoverFilterState>(props.location.search);

	const history = useHistory();
	const dispatch = useDispatch();

	const isMobileWeb = useIsMobileWeb(500);
	const isMobile = isMobileWeb || isMobileApp;

	const location = useSelector(state => state.persistent.location);
	const loading = useServiceMonitor(["getDiscoverEvents"]);

	const onFilterChange = (field: keyof typeof searchParams, value: any) => {
		let nextFilters = { ...searchParams };
		// Reset subcategory on category change
		if (field === "category") {
			delete nextFilters["subcategory" as keyof typeof searchParams];
		}
		// Remove distance if default
		if (value === DEFAULT_DISTANCE.value) {
			delete nextFilters[field];
		} else {
			nextFilters[field] = value;
		}
		history.replace(InternalRoutes.Discover("?" + queryString.stringify(nextFilters)));
	};

	const onDateChange = (date: any) => {
		history.replace(InternalRoutes.Discover("?" + queryString.stringify({ ...searchParams, ...date })));
	};

	const [state, setState] = useObjectMergeReducer<DiscoverState>({
		page: 0,
		total: 0,
		events: [],
	});

	const { onLoad, setOnLoad } = useLoadEvents(
		() => {
			loadEvents(true);
		},
		() => {
			setState({ events: [], total: 0 });
		}
	);

	//reset events
	useEffect(() => {
		setOnLoad(false);
	}, [location, searchParams.category, searchParams.subcategory, searchParams.distance, searchParams.from, searchParams.to]);

	//update events
	const loadEvents = async (reset?: boolean) => {
		const page = reset ? 1 : ++state.page;
		const result = await dispatch(getDiscoverEvents(page, searchParams.subcategory ? searchParams.subcategory : searchParams.category, searchParams.from, searchParams.to, searchParams.distance, location, PAGE_SIZE));
		const events = isRight(result) ? result.right.data : [];
		const total = isRight(result) ? result.right.metadata.totalRecords : 0;

		setState({ page, total, events: reset ? events : [...state.events, ...events] });
	};

	const wrapLocationPicker = isMobile || !!location;

	return (
		<CommonPage isSitixLogoSelected={true}>
			<MetaDecorator title={MetaTagData.Discover.title} description={MetaTagData.Discover.description} />
			<Header />
			<Events
				title="Events"
				wrapSubtitle={wrapLocationPicker}
				subtitle={<LocationPicker location={location} />}
				events={state.events}
				loading={loading}
				emptyMessage="No events found. Use the filters to discover events."
				onLoadMore={() => {
					if (onLoad) {
						loadEvents(false);
					}
				}}
				total={state.total}
				render={event =>
					typeof event.sportId === "number" ? (
						// @ts-ignore
						<SportEventItem key={event.id} event={event} />
					) : (
						// @ts-ignore
						<NormalEventItem key={event.id} event={event} />
					)
				}
				filters={<DiscoverFilters location={location} filters={searchParams} onFilterChange={onFilterChange} onDateChange={onDateChange} />}
			/>
		</CommonPage>
	);
};

const Header = styled.img.attrs(() => ({
	src: useIsMobileWeb(1220) || isMobileApp ? localEventsHeaderMobile : localEventsHeader,
	alt: "",
}))`
	width: 100%;
	height: ${HEADER_HEIGHT_PERCENT}vw;
	max-height: ${HEADER_HEIGHT_PERCENT * 0.01 * maxContentWidthValue}px;
	object-fit: cover;
	object-position: bottom center;
`;
