import React, { AnchorHTMLAttributes, useState } from "react";
import styled, { css } from "styled-components/macro";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LogoutPanel } from "../../components/Login/LogoutPanel";
import { ExternalRoutes, InternalRoutes, Link } from "../../Link";
import { Button } from "../../components/Button/Button";
import { MyAccountRoutes } from "../../routes/MyAccountRoute";
import arrowMidGray from "../../resource/image/arrow-mid-gray.svg";
import { LocationPicker } from "../LocationPicker";
import { mobileWebOrAppMediaQuery } from "../../util/mobileMediaQuery";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";
import { Dialog } from "../Dialog/Dialog";
import { Typography } from "../Typography/Typography";
import { Button as NewButton } from "../NewButton/Button";
import { deleteAccount } from "../../../services/user";
import toast from "react-hot-toast";
import { useToastRemoval } from "../../hooks/useToastRemoval";
import { useUser } from "../../../services/hooks/useUser";
import {getAppColor} from "../../util/appColors";

export const MyAccountRoot = () => {
	const location = useSelector(state => state.persistent.location);
	const hasSessionToken = useSelector(st => st.persistent.session.sessionToken !== undefined);
	const history = useHistory();
	const user = useUser();
	const onLoginClick = () => history.push(InternalRoutes.Login);
	const [accountDeletionModalOpen, setAccountDeletionModalOpen] = useState(false);
	const [deletionSubmitted, setDeletionSubmitted] = useState(false);

	const LocationLink = () => (
		<LocationPicker
			location={location}
			renderAs={onClick => (
				<AccountLink
					title="Location"
					to={"#"}
					onClick={(e: any) => {
						e.preventDefault();
						onClick();
					}}
					className={"bottom-spaced"}
				/>
			)}
		/>
	);

	const SharedRoutes = () => (
		<>
			<LocationLink />
			{/* <AccountLink title="Venmo Promotions" to={InternalRoutes.Venmo} /> */}
			<AccountLink title="Reservations - How It Works" to={ExternalRoutes.ReservationsHelp} />
			<AccountLink title="Meet The Team" to={InternalRoutes.About("meet-the-team")} />
			<AccountLink title="SI Tickets Fan Guarantee" to={InternalRoutes.FanGuarantee} />
			<AccountLink title="Help Center" to={ExternalRoutes.HelpCenterHome} />
			<AccountLink title="Contact Us" to={ExternalRoutes.HelpCenterHome} />
			<AccountLink title="Terms Of Use" to={InternalRoutes.Terms} />
			<AccountLink title="Privacy Policy" to={InternalRoutes.Privacy} />
		</>
	);

	useToastRemoval();
	//Stack for logged in users
	if (hasSessionToken) {
		return (
			<div>
				<LinksWrapper>
					<AccountLink title="Profile" to={MyAccountRoutes.Profile} />
					<AccountLink title="My Tickets" to={MyAccountRoutes.Tickets} />
					<AccountLink title="Postseason Reservations" to={MyAccountRoutes.Futures} className={"bottom-spaced"} />
					<AccountLink title="Payment Options" to={MyAccountRoutes.Profile} />
					<SharedRoutes />
					<Dialog
						open={accountDeletionModalOpen}
						type="information"
						onClose={() => {
							setAccountDeletionModalOpen(false);
							setDeletionSubmitted(false);
						}}
						headerChildren={<Typography type="bodyNormal">Delete Account</Typography>}
					>
						<DeleteAccountModalContent>
							{!deletionSubmitted ? (
								<>
									<DeleteAccountModalText type="extraLargeSpecialBody">Deleting your account will remove all of your data from our system. Are you sure you want to proceed?</DeleteAccountModalText>
									<ButtonContainer>
										<NewButton
											size="large"
											disabled={false}
											onClick={async () => {
												try {
													if (user) {
														await deleteAccount(user?.email);
														setDeletionSubmitted(true);
													}
												} catch {
													toast.error("There was an error processing your request.");
												}
											}}
										>
											Continue
										</NewButton>
										<Typography
											type="bodyTiny"
											css={`
												margin-top: 20px;
												margin-bottom: 20px;
												text-align: left;
												a {
													font-weight: 500;
												}
											`}
										>
											By choosing continue you agree to our{" "}
											<Link to={InternalRoutes.Terms} target="_blank" rel="noopener noreferrer">
												Terms of Use
											</Link>{" "}
											and{" "}
											<Link to={InternalRoutes.Privacy} target="_blank" rel="noopener noreferrer">
												Privacy Policy
											</Link>
											.
										</Typography>
									</ButtonContainer>
								</>
							) : (
								<AccountDeletionSubmitText type="bodySmall">A request has been sent to our customer service team to remove your account. You will receive a confirmation email within 2 days confirming all of your data has been removed. If you have any questions please contact our customer service 646-453-7400 Or SUPPORT@SITICKETS.COM</AccountDeletionSubmitText>
							)}
						</DeleteAccountModalContent>
					</Dialog>
					<AccountLink
						title="Delete Account"
						onClick={() => {
							setAccountDeletionModalOpen(true);
						}}
					/>
				</LinksWrapper>
				<StyledLogoutPanel />
			</div>
		);
	}

	//Anonymous view
	return (
		<div>
			<LoginButton onClick={onLoginClick}>Sign up / Log in</LoginButton>
			<LinksWrapper>
				<AccountLink title="My Tickets" to={InternalRoutes.Login} />
				<AccountLink title="Postseason Reservations" to={InternalRoutes.Login} className={"bottom-spaced"} />
				<AccountLink title="Payment Options" to={InternalRoutes.Login} />
				<SharedRoutes />
			</LinksWrapper>
		</div>
	);
};

const AccountLink = (
	props: {
		title: string;
		onClick?: (event: any) => void;
	} & StyledComponentProps &
		(Pick<AnchorHTMLAttributes<HTMLAnchorElement>, "href"> | Pick<React.ComponentProps<typeof Link>, "to">)
) => {
	return (
		<>
			{/*@ts-ignore*/}
			<AccountLinkContainer
				style={
					props.className === "bottom-spaced"
						? {
								marginBottom: 20,
						  }
						: undefined
				}
				{...("href" in props
					? {
							href: props.href,
					  }
					: {
							forwarededAs: Link,
							//@ts-ignore
							to: props.to,
					  })}
				{...props}
			>
				<span>{props.title}</span>
				<img src={arrowMidGray} alt="Arrow" />
			</AccountLinkContainer>
		</>
	);
};

const mobileWidth = "880px";
const contentTopBottomPaddingValue = 37;

const loginButtonLeftRightMarginValue = 20;
const logoutButtonTopMargin = 60;

const loginStyles = css`
	width: 88%;
	margin-left: ${loginButtonLeftRightMarginValue}px;
	margin-right: ${loginButtonLeftRightMarginValue}px;
	margin-bottom: ${contentTopBottomPaddingValue}px;
	${mobileWebOrAppMediaQuery(
		`
    margin: 28px ${loginButtonLeftRightMarginValue}px;
  `,
		mobileWidth
	)}
	@media (max-width: ${mobileWidth}) {
		margin: 28px ${loginButtonLeftRightMarginValue}px;
	}
`;

const LoginButton = styled(Button)`
	background-color: ${getAppColor("dark")};
	${loginStyles}
`;
const StyledLogoutPanel = styled(LogoutPanel)`
	${loginStyles}
	margin-top: ${logoutButtonTopMargin}px;
`;

const AccountLinkContainer = styled(Link)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #ffffff;
	border: 1px solid #e2e4e7;
	span {
		font-family: Montserrat;
		font-size: 18px;
		font-weight: 500;
		line-height: 24px;
		text-align: left;
		color: black;
	}

	padding: 25px 28px 25px 60px;
	@media (max-width: ${mobileWidth}) {
		padding: 18px 27px 18px 32px;
	}
`;

const LinksWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	${AccountLinkContainer} {
		& :not(.bottom-spaced):not(:last-child) {
			border-bottom: 0;
		}
	}
`;

const DeleteAccountModalText = styled(Typography)`
	text-align: left;
	margin-bottom: 20px;
`;

const DeleteAccountModalContent = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 340px;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

const AccountDeletionSubmitText = styled(Typography)`
	text-align: left;
	padding-bottom: 40px;
`;
