import React from "react";
import styled from "styled-components";
import { SkeletonProps } from "../../../model/optimizedModel/skeleton";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";
import { CarouselRow } from "../CarouselRow/CarouselRow";
import { Chip } from "../Chip/Chip";

type ChipProps = React.ComponentProps<typeof Chip>;

type chipContentPropKey = "children";

//TODO: Alot of the logic here is very simmilar to the Items component & BetSportThumbnailRow component. Consider finding a way to reuse the logic.
export const ChipCarouselRow = (
	props: {
		onSelect?: (slug: string, index: number) => void;
		selectedSlug?: string;
	} & StyledComponentProps &
		SkeletonProps<{
			items: (Omit<ChipProps, chipContentPropKey> & {
				name: ChipProps[chipContentPropKey];
				//TODO: Consider renaming "slug" to "key".
				slug: string;
			})[];
		}>
) => {
	return (
		<>
			{(props.loading || props.items) && (
				<StyledCarouselRow
					disableScrolling={props.loading}
					className={props.className}
					css={
						props.loading
							? `
	      .carouselItem {
		flex: 1;
	      }
	      .content {
		width: 100%;
	      }
	    `
							: undefined
					}
					items={(props.loading ? Array.from(Array(7)) : props.items).map((item, index) => {
						return (
							<Chip
								{...item}
								key={index}
								children={item?.name}
								loading={props.loading}
								selected={item?.slug === props.selectedSlug}
								onClick={() => {
									if (props.onSelect) {
										props.onSelect(item?.slug, index);
									}
								}}
							/>
						);
					})}
				/>
			)}
		</>
	);
};

const StyledCarouselRow = styled(CarouselRow)`
	.carouselItem {
		&:not(:last-child) {
			margin-right: 8px;
		}
	}
`;
