import React, { useState } from "react";
import styled from "styled-components";
import { SearchState } from "./SearchState";
import { MainSearch } from "./MainSearch";
import { mediaQueries, popupMaxWidth } from "../../util/mediaQueries";
import { PredictiveSearchOverlay } from "./styles";
import { useIsMobileWeb } from "../../hooks/useIsMobileWeb";
import { isMobileApp } from "../../util/isMobileApp";

interface SearchProps {
	title?: string;
	onClick?: Function;
	className?: string;
	hideLocation?: boolean;
	onChange?: (next: SearchState) => void;
	allowCustomLocations?: boolean;
	placeholder?: string;
	fullWidthResults?: boolean;
}

export const Search = React.memo((props: SearchProps) => {
	const [searchState, setSearchState] = useState(SearchState.None);
	const onSetSearchState = (next: any) => {
		setSearchState(next);
		props.onChange && props.onChange(next);
	};

	const isMobile = useIsMobileWeb() || isMobileApp;

	return (
		<>
			{searchState === SearchState.Main || searchState === SearchState.Initial ? (
				<PredictiveSearchOverlay
					onClick={() => {
						setSearchState(SearchState.None);
					}}
				/>
			) : null}
			<StyledMainSearch
				mobile={isMobile}
				className={props.className}
				placeholder={props.placeholder}
				hideLocation={props.hideLocation}
				fullWidthResults={props.fullWidthResults}
				allowCustomLocations={props.allowCustomLocations}
				onClick={event => {
					if (document.body.clientWidth < popupMaxWidth) {
						event.stopPropagation();
					}
					if (props.onClick) {
						props.onClick();
					}
				}}
				searchState={searchState}
				setSearchState={onSetSearchState}
			></StyledMainSearch>
		</>
	);
});

const StyledMainSearch = styled(MainSearch)<{ mobile?: boolean }>`
	width: 100%;
	max-width: 800px;
	z-index: 2;
	border-radius: 0px;
	@media ${mediaQueries.max670} {
		width: 85%;
		min-width: 280px;
	}
`;
