import React from "react";
import styled from "styled-components";
import { Config } from "../../../Config";
import { SkeletonProps } from "../../../model/optimizedModel/skeleton";
import { CoverAssets } from "../../../services/types/AssetResponse";
import { getAppColor } from "../../util/appColors";
import { breakpoints } from "../../util/breakpoints";
import { maxContentWidthStyles } from "../../util/maxContentWidth";
import { SkeletonWrapper } from "../SkeletonWrapper/SkeletonWrapper";
import { FontFamily, getTypographyStyles } from "../Typography/Typography";

export const NewHero = (
	props: SkeletonProps<{
		assets: CoverAssets | null;
	}> & {
		performer?: string;
	}
) => {
	const title = props.assets?.main_headline_1;
	const heroImage = !props.assets?.banner_image ? undefined : props.assets.banner_image.startsWith("/uploads") ? Config.getResourceUrl(props.assets.banner_image) : props.assets.banner_image.startsWith("http") ? props.assets.banner_image : Config.getCDNUrl(props.assets.banner_image);
	return (
		<SkeletonWrapper loading={props.loading}>
			<HeroContainer>
				{heroImage && <HeroImage src={heroImage} alt="alt" />}
				<HeroContent>
					<Title>{title?.text}</Title>
				</HeroContent>
			</HeroContainer>
		</SkeletonWrapper>
	);
};

const HeroContainer = styled.div`
	position: relative;
	height: 175px;
	background-color: ${getAppColor("dark")};
	@media (max-width: ${breakpoints.mobile}) {
		height: 225px;
	}
`;

const HeroImage = styled.img`
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	object-fit: cover;
	object-position: 70% 50%;
	height: 100%;
	@media (max-width: ${breakpoints.tablet}) {
		display: none;
	}
`;

const HeroContent = styled.div`
	${maxContentWidthStyles}
	position: relative;
	z-index: 1;
	height: 100%;
	display: flex;
	align-items: center;
	@media (max-width: ${breakpoints.mobile}) {
		padding-bottom: 32px;
	}
`;

const Title = styled.h1`
	text-align: left;
	${getTypographyStyles("display1", {
		color: "light",
		fontWeight: 500,
	})}
	font-family: ${FontFamily.Poppins};
	text-transform: capitalize;
	font-size: 64px;
	line-height: 70px;
	margin-left: 60px;
`;
