import { useEffect, useState } from "react";
import { useCart } from "../../services/hooks/useCart";
import { formatDateToUTC } from "../util/dateFormat";

export const useElite24ConversionTracking = (orderId?: string) => {
	const eventIdsToTrack = [835921];
	const { cart, ticketSubtotal } = useCart();
    const [finishedTracking, setFinishedTracking] = useState(false);

	const trackConversion = (
		intersportScript: HTMLScriptElement, 
		elite24ConfirmationScript: HTMLScriptElement, 
		elite24ConfirmationNoScript: HTMLElement, 
		elite24ConfirmationImg: HTMLImageElement,
		facebookScript: HTMLScriptElement,
		facebookNoScript: HTMLElement,
		facebookNoScriptImg: HTMLImageElement) => {
			
		facebookScript.textContent = `
			!function(f,b,e,v,n,t,s)
			{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
			n.callMethod.apply(n,arguments):n.queue.push(arguments)};
			if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
			n.queue=[];t=b.createElement(e);t.async=!0;
			t.src=v;s=b.getElementsByTagName(e)[0];
			s.parentNode.insertBefore(t,s)}(window, document,'script',
			'https://connect.facebook.net/en_US/fbevents.js');
			fbq('init', '450735625346180');
			fbq('track', 'PageView');
			fbq('track', 'Purchase', {value: '0.00', currency:'USD'});
		`;

		facebookNoScriptImg.src = "https://www.facebook.com/tr?id=450735625346180&ev=PageView&noscript=1";
		facebookNoScriptImg.width = 1;
		facebookNoScriptImg.height = 1;
		facebookNoScriptImg.style.display = "none";

		intersportScript.textContent = `
			gtag('event', 'conversion', {
			'send_to': 'AW-697228446/Wz9xCIeS_rIBEJ65u8wC',
			'value': 1.0,
			'currency': 'USD',
			'transaction_id': ''
		});`;

		const revenue = ticketSubtotal;

		const quantity = cart?.tickets.reduce((total, ticket) => total + ticket.quantity, 0);
		const purchaseDate = formatDateToUTC(new Date(), "MM-dd-yyyy");
		const purchaseTime = formatDateToUTC(new Date(), "HH:mm:ss ZZZ");
		elite24ConfirmationScript.textContent = `
			gtag('event', 'purchase', {
				'allow_custom_scripts': true,
				'value': '${revenue}',
				'transaction_id': '${orderId}',
				'u1': '${quantity}',
				'u2': '${cart?.tickets[0].event.name}',
				'u3': '${purchaseDate}',
				'u4': '${purchaseTime}',
				'send_to': 'DC-8538166/saler0/elite0+transactions'
			});
		`;

		elite24ConfirmationImg.src = `https://ad.doubleclick.net/ddm/activity/src=8538166;type=saler0;cat=elite0;qty=1;cost=${revenue};u1=${quantity};u2=${cart?.tickets[0].event.name};u3=${purchaseTime};u4=${purchaseDate};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=\${GDPR};gdpr_consent=\${GDPR_CONSENT_755};ord=${orderId}?`;
		elite24ConfirmationImg.width = 1;
		elite24ConfirmationImg.height = 1;
		elite24ConfirmationImg.alt = "";

		facebookNoScript.appendChild(facebookNoScriptImg);
		document.head.appendChild(facebookScript);
		document.head.appendChild(facebookNoScript);
		document.head.appendChild(intersportScript);
		document.head.appendChild(elite24ConfirmationScript);
		elite24ConfirmationNoScript.appendChild(elite24ConfirmationImg);
		document.head.appendChild(elite24ConfirmationNoScript);
        setFinishedTracking(true);
	};

	useEffect(() => {
        if (!!orderId && !!cart?.tickets && cart.tickets.every(ticket => eventIdsToTrack.includes(ticket.event.id))) {
			const intersportScript: HTMLScriptElement = document.createElement("script");
			const elite24ConfirmationScript: HTMLScriptElement = document.createElement("script");
			const elite24ConfirmationNoScript: HTMLElement = document.createElement("noscript");
			const elite24ConfirmationImg: HTMLImageElement = document.createElement("img");
			const facebookScript: HTMLScriptElement = document.createElement("script");
			const facebookNoScript: HTMLElement = document.createElement("noscript");
			const facebookNoScriptImg: HTMLImageElement = document.createElement("img");

			trackConversion(intersportScript, elite24ConfirmationScript, elite24ConfirmationNoScript, elite24ConfirmationImg, facebookScript, facebookNoScript, facebookNoScriptImg);

			return () => {
				document.head.removeChild(intersportScript);
				document.head.removeChild(elite24ConfirmationScript);
				elite24ConfirmationNoScript.removeChild(elite24ConfirmationImg);
				document.head.removeChild(elite24ConfirmationNoScript);
				document.head.removeChild(facebookScript);
				facebookNoScript.removeChild(facebookNoScriptImg);
				document.head.removeChild(facebookNoScript);
			};
		} 
	}, []);

    return finishedTracking;
};
