import React from "react";
import styled from "styled-components/macro";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";
import { getTypographyStyles } from "../Typography/Typography";

export interface TitleAndContentProps extends StyledComponentProps {
	title: React.ReactNode;
	content: React.ReactNode | null | undefined;
	subtitle?: React.ReactNode;
}

//TODO: Consider renaming component? Name could be too generic/bland I guess
export const TitleAndContent = ({ title, content, subtitle, ...containerProps }: TitleAndContentProps) => {
	return (
		<>
			{content !== undefined && content !== null && content !== "" && (
				<Container {...containerProps}>
					<Title>{title}:</Title>
					{subtitle && <Subtitle>({subtitle})</Subtitle>}
					<Content>{content} </Content>
				</Container>
			)}
		</>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	color: black;
	text-align: left;

	//TODO: Consider adding better CSS to accomplish this. This code was done very quickly.
	//TODO: Consider adding this directly to TitleAndContent.
	&,
	& *:not(img) {
		text-overflow: ellipsis;
		overflow: hidden;
	}
`;

const Title = styled.span`
	${getTypographyStyles("bodySmall", { fontWeight: "bold" })}
`;

const Subtitle = styled.span`
	${getTypographyStyles("bodyTiny")}
	margin-top: -1px;
`;

//MUI body2
const Content = styled.span`
	${getTypographyStyles("bodySmall")}

	//Note: This was done for the PaymentMethodDisplay logo.
  margin-top: 2px;
`;
