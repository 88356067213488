import { Config } from "../Config";
import { apiClient } from "./apiClient";
import { APIClientResponse } from "./types/APIClientResponse";

interface Token {
	accessToken: string;
	token: {
		id: string;
		user_id: number;
		client_id: number;
		name: string;
		scopes: unknown; //TODO: Investigate the type of this
		revoked: boolean;
		created_at: string;
		updated_at: string;
		expires_at: string;
	};
}

export function postPreRegister(params: { email: string; firstName: string; lastName: string; remoteIp: string; captchaToken: string }): APIClientResponse<any> {
	return apiClient.post("user/pre-register", {
		email: params.email,
		fname: params.firstName,
		lname: params.lastName,
		remoteIp: params.remoteIp,
		deviceName: Config.getPlatformOS(),
		sessionCreatedAt: window.sessionStorage.getItem("created_at"),
		sessionId: window.sessionStorage.getItem("user_session_id"),
		captchaToken: params.captchaToken,
	});
}

export function postGuestCheckout(params: { email: string; remoteIp: string; captchaToken: string; cartSessionId?: string; refUrl: string }): APIClientResponse<{
	user: {
		email: string;
		updated_at: string;
		created_at: string;
		id: number;
	};
	token: Token;
}> {
	return apiClient.post("guest-checkout", {
		email: params.email,
		remoteIp: params.remoteIp,
		deviceName: Config.getPlatformOS(),
		sessionCreatedAt: window.sessionStorage.getItem("created_at"),
		sessionId: window.sessionStorage.getItem("user_session_id"),
		captchaToken: params.captchaToken,
		cartSessionId: params.cartSessionId,
		refUrl: params.refUrl,
	});
}

export function postEmailRegister(params: { email: string; password: string; firstName: string; lastName: string; phone: string; remoteIp: string; captchaToken: string }): APIClientResponse<{
	user: {
		fname: string;
		lname: string;
		email: string;
		phone: string;
		level: number;
		updated_at: string;
		created_at: string;
		id: number;
	};
	token: Token;
}> {
	return apiClient.post("user/register", {
		email: params.email,
		password: params.password,
		fname: params.firstName,
		lname: params.lastName,
		phone: params.phone,
		remoteIp: params.remoteIp,
		deviceName: Config.getPlatformOS(),
		sessionCreatedAt: window.sessionStorage.getItem("created_at"),
		sessionId: window.sessionStorage.getItem("user_session_id"),
		captchaToken: params.captchaToken,
	});
}
