import React from "react";
import styled from "styled-components/macro";
import { myAccountCardBoxShadow } from "../../util/myAccountConsts";

export const MyAccountCard = (
	props: React.PropsWithChildren<{
		header?: string;
		subheader?: string;
		id?: string;
		className?: string;
	}>
) => {
	return (
		<Card id={props.id} className={props.className}>
			{props.header !== undefined && props.header !== "" && <Header>{props.header}</Header>}
			{props.subheader !== undefined && props.subheader !== "" && <span>{props.subheader}</span>}
			<div>{props.children}</div>
		</Card>
	);
};

const Header = styled.h1`
	margin-bottom: 12px;
`;

export const cardInputBottomMargin = "10px";

export const cardBottomMarginValue = 24;

export const cardBottomMargin = cardBottomMarginValue + "px";

const Card = styled.div`
	box-shadow: ${myAccountCardBoxShadow};
	border-radius: 17px;
	padding: 20px;
	background: #f4f4f4;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-family: "Avenir", sans-serif;
	text-align: left;
	&:not(:last-child) {
		margin-bottom: ${cardBottomMargin};
	}
	&.addresses > h1 {
		max-width: 50vw;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	input {
		margin: 0;
		padding: 9px 10px;
		&:not(:last-of-type) {
			margin-bottom: ${cardInputBottomMargin};
		}
	}
	& > div {
		width: 100%;
		text-align: left;
		p {
			color: #000000;
			font-size: 15px;
			line-height: initial;
		}
		& > span {
			margin: 0;
			color: #3c3c3b;
			font-size: 13px;
		}
	}
	& > span {
		color: #797c7b;
		margin-top: 5px;
		font-size: 14px;
	}
`;
