import React, { useEffect, useState } from "react";
import { useOverflowListener } from "../../hooks/useOverflowListener";
import { Back, Forward, Page, PaginationContainer, Ellipsis } from "./styles";

const VISIBLE_PAGES = 10;
const COMPACT_VISIBLE_PAGES = 2;

export interface PaginationProps {
	page: number | null;
	pages: number | null;
	displayLimit?: number;
	route: string;
}

/**
 * Navigation based paging
 * @param props
 */
export const NavigationPaging = (props: PaginationProps) => {
	const pageNumber = props.page || 1;
	const totalPages = Math.ceil(props.pages ?? 1);
	const [displayLimit, setDisplayLimit] = useState(VISIBLE_PAGES);

	//arrow props
	const disableBack = pageNumber <= 1;
	const disableForward = pageNumber >= totalPages;

	let paramsSeparator = props.route.includes("?") ? "&" : "?";

	const backRoute = disableBack ? props.route : `${props.route}${paramsSeparator}page=${pageNumber - 1}`;
	const forwardRoute = disableForward ? props.route : `${props.route}${paramsSeparator}page=${pageNumber + 1}`;

	//visible pages
	let pages: React.ReactElement[] = [];

	const midRange = Math.round(displayLimit / 2);
	let from = pageNumber - midRange > 0 ? pageNumber - midRange : 1,
		to = from + displayLimit;

	if (to >= totalPages) {
		from -= to - totalPages;
		to = totalPages;
	}
	if (from <= 1) {
		from = 1;
	}

	for (let i = from; i <= to; i++) {
		pages.push(
			<Page disabled={i === pageNumber} to={`${props.route}${paramsSeparator}page=${i}`} key={i}>
				{i}
			</Page>
		);
	}

	//ellipsis
	const showLeftEllipsis = from > 2;
	const showRightEllipsis = to < totalPages;

	const { register, overflow } = useOverflowListener<HTMLDivElement>();

	useEffect(() => {
		setDisplayLimit(overflow !== "none" ? COMPACT_VISIBLE_PAGES : VISIBLE_PAGES);
	}, [overflow]);

	return (
		<PaginationContainer ref={register}>
			<Back to={backRoute} disabled={disableBack}>
				{"‹"}
			</Back>
			<Ellipsis show={showLeftEllipsis}>{"..."}</Ellipsis>
			{pages}
			<Ellipsis show={showRightEllipsis}>{"..."}</Ellipsis>
			<Forward to={forwardRoute} disabled={disableForward}>
				{"›"}
			</Forward>
		</PaginationContainer>
	);
};
