import { HTTPMethod } from "../types/util/BasicTypes";
import { FakeService } from "./FakeService";
import { fakeBetsService } from "./fakeBetsService";

export const fakes = {
	get: (method: HTTPMethod, endpoint: string): FakeService | null => {
		if (method === "GET" && endpoint === "bets") {
			return fakeBetsService;
		} else {
			return null;
		}
	},
};
