import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Header } from "./Header/Header";
import { MobileMenuOverlay } from "./MobileMenu/MobileMenuOverlay";
import { MobileMenu } from "./MobileMenu/MobileMenu";
import { Footer } from "./Footer";
import { Newsletter } from "./Newsletter";
import { useLocation } from "react-router-dom";
import { ErrorBoundary } from "../util/ErrorBoundary";
import { NotFoundView } from "../routes/NotFound";
import { isMobileApp } from "../util/isMobileApp";
import { useIsMobileWeb } from "../hooks/useIsMobileWeb";
import { getNavBarAll } from "../../services/navigation";
import { excludeItems, NavigationItem } from "../../model/Navigation";
import { Disclaimer } from "./Footer/Disclaimer";
import { useHeaderHeight } from "../hooks/useHeaderHeight";
import { useSelector } from "react-redux";
import { StyledComponentProps } from "../../model/optimizedModel/styles";

export const CommonPageContainer = styled.div<{ background?: string; center?: boolean }>`
	text-align: ${props => (props.center === false ? "unset" : "center")};
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: stretch;
	${props => (props.background ? `background: ${props.background}` : "")}
`;

export const CommonPage = ({ children, headerChildren, background, isSitixLogoSelected, isFooterHidden, hideAllNavigation, hideHeader, hideSearch, hideNewsletter, navOverridesMaxContentWidth, center, hideMobileAppNav, preview = false, className }: {
	center?: boolean;
	children: React.ReactNode;
	headerChildren?: React.ReactNode;
	background?: string;
	isSitixLogoSelected?: boolean;
	isFooterHidden?: boolean;
	hideAllNavigation?: boolean;
	hideHeader?: boolean;
	hideSearch?: boolean;
	hideNewsletter?: boolean;
	navOverridesMaxContentWidth?: boolean;
	hideMobileAppNav?: boolean;
	preview?: boolean;
} & StyledComponentProps) => {
	const [isMenuOpen, setMenuOpen] = useState(false);

	const { pathname } = useLocation();
	const [navigationTree, setNavigationTree] = useState<NavigationItem[]>([]);

	useEffect(() => {
		getNavBarAll().then(items => {
			let filteredItems = items.data;
			if (isMobileApp) {
				filteredItems = excludeItems(filteredItems, "Get In The Game");
			}
			setNavigationTree(filteredItems);
		});
	}, []);

	useEffect(() => {
		setMenuOpen(false);
	}, [pathname]);

	const headerHeight = useHeaderHeight();
	const isMobileWeb = useIsMobileWeb();

	const { displayNewNavbar } = useSelector(state => state.transient.navbar);

	return (
		<CommonPageContainer center={center} background={background} className={className}>
			{(!displayNewNavbar || isMobileApp) && (
				<>
					<Header navigationTree={navigationTree} isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} isSitixLogoSelected={isSitixLogoSelected} hideAllNavigation={hideAllNavigation} hide={isMobileApp ? hideMobileAppNav : hideHeader} hideSearch={hideSearch} overrideMaxContentWidth={navOverridesMaxContentWidth}>
						{headerChildren}
					</Header>
					<MobileMenu
						style={{
							[isMobileApp ? "bottom" : "top"]: headerHeight,
							...(isMobileApp && {
								top: 0,
							}),
						}}
						navigationTree={navigationTree}
						isOpen={isMenuOpen}
						onClose={() => {
							setMenuOpen(false);
						}}
						user={null}
					/>
					<MobileMenuOverlay
						style={{
							[isMobileApp || isMobileWeb ? "bottom" : "top"]: headerHeight,
							top: isMobileApp || isMobileWeb ? 0 : "",
						}}
						isVisible={isMenuOpen}
						onClick={() => {
							setMenuOpen(false);
						}}
					/>
				</>
			)}

			<ErrorBoundary failureView={<NotFoundView title="Error" message="There was an error while processing this page" />}>
				{children}
				{!isMobileApp && <div style={{ height: isMobileApp ? headerHeight : "" }} />}
			</ErrorBoundary>
			{isFooterHidden ? null : (
				<FooterContainer
					style={{
						...(isMobileApp && {
							marginBottom: headerHeight,
						}),
					}}
				>
					{!isMobileApp && (
						<>
							{!hideNewsletter && <Newsletter />}

							{/*<GetApp />*/}
							<Footer preview={preview} />
							<Disclaimer />
						</>
					)}
				</FooterContainer>
			)}
		</CommonPageContainer>
	);
};

export const FooterContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: ${!isMobileApp && "40px"};
`;

CommonPage.FooterContainer = FooterContainer;