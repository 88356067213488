import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setNavbarSearchHidden, setDisplayNewNavbar, updateAmountOfRequestsToDisplayInitiallyTransparentNavbar } from "../../store/reducers/navbarReducer";

export const useInitiallyTransparentNavbar = (enabled?: boolean) => {
	const dispatch = useDispatch();

	useEffect(() => {
		if (enabled !== false) {
			dispatch(updateAmountOfRequestsToDisplayInitiallyTransparentNavbar(1));
			return () => {
				dispatch(updateAmountOfRequestsToDisplayInitiallyTransparentNavbar(-1));
			};
		}
	}, [enabled]);
};

export const useHiddenNavbarSearch = () => {
	const dispatch = useDispatch();

	const unhideNavbarSearch = () => {
		dispatch(setNavbarSearchHidden(false));
	};

	useEffect(() => {
		dispatch(setNavbarSearchHidden(true));
		return unhideNavbarSearch;
	}, []);

	return unhideNavbarSearch;
};

export const useNewNavbar = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setDisplayNewNavbar(true));
		return () => {
			dispatch(setDisplayNewNavbar(false));
		};
	}, []);
};
