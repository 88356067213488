import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { CommonPage } from "../components";
import { ShieldBar } from "../components/Cart/ShieldBar";
import { ExternalRoutes, InternalRoutes, Link } from "../Link";
import appstore_button from "../resource/img/sitix/mobile_app/appstore_button.svg";
import googleplaystore_button from "../resource/img/sitix/mobile_app/googleplaystore_button.svg";
import { RouteComponentProps } from "react-router-dom";
import { Order } from "../../model/Order";
import { getCheckoutThanks } from "../../services/cart";
import { useDispatch } from "react-redux";
import { clearCart } from "../../store/reducers/cartReducer";
import { resetCheckoutState } from "../../store/actions/checkoutActions";
import iconTicketRed from "../resource/image/ticket-icon-red.svg";
import { isMobileApp } from "../util/isMobileApp";
import { MetaDecorator, MetaTagData } from "../components/SEOMetadecorator/MetaDecorator";
import si_footer_logo from "../resource/img/icons/si_footer_logo.svg";
import { EndOfSessionPopup } from "../components/EndOfSessionPopup/EndOfSessionPopup";
import { useHiddenZendeskChatWidgetBreakpoint } from "../hooks/useHiddenZendeskChatWidgetBreakpoint";
import { breakpoints } from "../util/breakpoints";
import { FontFamily, getTypographyStyles } from "../components/Typography/Typography";
import { FullScreenLoader } from "../components/Loader";
import { useElite24ConversionTracking } from "../hooks/useElite24ConversionTracking";
const SUPPORT_EMAIL = "Support@SITickets.com";

interface CheckoutThanksRouteParams {
	orderId: string;
}

export const CheckoutThanks = (props: RouteComponentProps<CheckoutThanksRouteParams, any, { performer: string | undefined }>) => {
	const orderId = props.match.params.orderId;
	const [isLoading, setIsLoading] = useState(false);

	const [data, setData] = useState<Order | undefined>();
	const dispatch = useDispatch();

	const finishedTracking = useElite24ConversionTracking(orderId);

	let orders = "";
	if (data?.ticketOrderId && data?.internalOrderId) {
		orders = `Your order numbers are ${data?.ticketOrderId} and ${data?.internalOrderId}.`;
	} else if (data?.ticketOrderId) {
		orders = `Your order number is ${data?.ticketOrderId}.`;
	} else if (data?.internalOrderId) {
		orders = `Your order number is ${data?.internalOrderId}.`;
	}

	useEffect(() => {
		if (finishedTracking) {
			dispatch(clearCart());
			dispatch(resetCheckoutState);
		}
		setIsLoading(true);
		getCheckoutThanks(orderId)
			.then(result => {
				setData(result.data);
			})
			.catch(error => {
				console.error(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [finishedTracking]);

	const [betsDialogWasClosed, setBetsDialogWasClosed] = useState(false);

	useHiddenZendeskChatWidgetBreakpoint(breakpoints.tablet, !data?.betData || betsDialogWasClosed);

	return (
		<CommonPage background="#f6f6f6" isFooterHidden={true}>
			<MetaDecorator title={MetaTagData.CheckoutThanks.title} description={MetaTagData.CheckoutThanks.description} />
			<ShieldBar />
			<FullScreenLoader show={isLoading} />
			{data?.betData && (
				<EndOfSessionPopup
					open
					betData={data.betData}
					onClose={() => {
						setBetsDialogWasClosed(true);
					}}
				/>
			)}
			<PageContainer>
				<Content>
					<Title>Thank You{data ? " " + data.firstName : ""}!</Title>
					<TicketImage src={iconTicketRed} />
					<Subtitle>Your payment has been processed and your Order Request has been received.</Subtitle>
					<Orders>{orders}</Orders>
					<OrderVerification>We are still verifying your order and securing the tickets you requested from the seller.</OrderVerification>
					<Text>
						<strong>Please look for an email from support@sitickets.com in the next 3-5 minutes regarding your Order Status</strong>. <DesktopNewLine /> Please check your Junk or Spam folders for important communications from SI Tickets.
					</Text>
					<Panel.Section>
						<Panel.Container>
							<Panel.Title>
								<span>
									<strong>PLEASE NOTE:</strong> This is not your event ticket.
								</span>
							</Panel.Title>
							<Panel.Content>
								Please view our <Link to={InternalRoutes.Terms}>Terms of Use</Link> to understand the policies surrounding your ticket purchase.
							</Panel.Content>
						</Panel.Container>
					</Panel.Section>
				</Content>

				<Footer
					style={
						!data
							? {
									background: "transparent",
									marginTop: -11,
							  }
							: {}
					}
				>
					{!isMobileApp && (
						<>
							<Text>Download the SI Tickets Mobile App to login to your account and access Digital Tickets &amp; More.</Text>
							<Links>
								<AppLogo>
									<img src={si_footer_logo} alt=" " />
								</AppLogo>
								<Link to={ExternalRoutes.AppAppleStore}>
									<img width="90%" height="auto" src={appstore_button} alt="App Store" />
								</Link>
								<Link to={ExternalRoutes.AppAndroidStore}>
									<img width="100%" height="auto" src={googleplaystore_button} alt="Google Play Store" />
								</Link>
							</Links>
						</>
					)}
					<Text className="footer">To review your account information please login to your SI Tickets account.</Text>
					<Text className="footer">To update any order details please navigate to our Support Page once you have signed in.</Text>
					<Text className="footer">For immediate assitance, please email {SUPPORT_EMAIL}.</Text>
				</Footer>
			</PageContainer>
		</CommonPage>
	);
};

const PageContainer = styled.div`
	font-family: ${FontFamily.Poppins};
`;

const AppLogo = styled.div`
	display: flex;
	height: 38px;
	width: 52px;
`;

/**
 * Common
 */

const Panel: any = {
	Section: styled.div`
		margin: 0 auto;
		max-width: 550px;
	`,
	Container: styled.div`
		margin: 30px 0;
		border: 1px solid #e7e8e8;
		border-radius: 11px;
	`,
	Title: styled.div`
		${getTypographyStyles("bodySmall", {
			color: "dark",
		})}
		margin-top: -7px;
		span {
			background-color: #fafafa;
		}
	`,
	Content: styled.div`
		padding: 20px;
		${getTypographyStyles("bodySmall", {
			color: "dark",
		})}
		a {
			text-decoration: underline;
		}
	`,
};

const Text = styled.p`
	${getTypographyStyles("bodyNormal", {
		color: "dark",
	})}
	&.footer {
		margin-top: 5px;
	}
`;

/**
 * Header
 */
const Content = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 20px;
	padding-right: 20px;
	background-image: linear-gradient(to right, #f6f6f6 0%, #ffffff 51.1264%, #f6f6f6 100%);
`;

const Title = styled.h2`
	font-style: normal;
	font-weight: 700;
	margin-top: 54px;
	text-transform: uppercase;
	${getTypographyStyles("specialBodyHeader", {
		color: "dark",
	})};
	font-family: ${FontFamily.Poppins};
`;

const Subtitle = styled.h3`
	font-weight: 700;
	margin-top: 30px;
	margin-bottom: 20px;
	${getTypographyStyles("heading3", {
		color: "dark",
	})};
	font-family: ${FontFamily.Poppins};
	text-transform: initial;
`;

const Orders = styled.p`
	margin-bottom: 20px;
	${getTypographyStyles("bodyMedium", {
		color: "dark",
	})};
`;

const OrderVerification = styled(Text)`
	margin-bottom: 20px;
`;

const TicketImage = styled.img`
	max-width: 120px;
	width: 100%;
	height: auto;
	margin-top: 40px;
	margin-left: auto;
	margin-right: auto;
`;

/**
 * Footer
 */
const Footer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-image: linear-gradient(270deg, #f6f6f6 0%, #ffffff 51.1264%, #f6f6f6 100%);
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: 20px;
	padding-right: 20px;
	margin-top: 20px;
`;

const Links = styled.div`
	display: flex;
	width: 100%;
	max-width: 300px;
	margin: 30px 0;

	a {
		text-decoration: none;
		color: inherit;
		flex: 1;
		text-align: left;
		display: block;
	}
`;

const DesktopNewLine = styled.br`
	@media (max-width: ${breakpoints.mobile}) {
		display: none;
	}
`;
