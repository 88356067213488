import styled from "styled-components";

export const borderRadius = "11px";

export const PredictiveSearchOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	background: rgb(10 21 41 / 0.7);
	width: 100vw;
	height: 100vh;
	z-index: 2;
`;
