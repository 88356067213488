import React from "react";
import styled from "styled-components";
import { Typography } from "../Typography/Typography";

export const FooterLinks = (props: {
	links: {
		linkText: string;
		linkUrl: string;
		newTab: boolean;
	}[];
}) => {
	return (
		<>
			{props.links.map((link, index) => {
				return (
					<a href={link.linkUrl} target={link.newTab ? "_blank" : "_self"} rel="noopener noreferrer" key={index}>
						<FooterLink type="bodyTiny" color="light">
							{link.linkText}
						</FooterLink>
					</a>
				);
			})}
		</>
	);
};

const FooterLink = styled(Typography)`
	text-align: left;
	height: 23px;
	&:hover {
		text-decoration: underline;
	}
`;
