import { PaymentMethod, PaymentMethodType } from "../../model/optimizedModel/braintree";
import { PartialBy } from "../../model/optimizedModel/general";
import { useAutoUpdateState } from "../../presentation/hooks/useAutoUpdateState";
import { useCart } from "./useCart";
import { useCheckout } from "./useCheckout";

export const useCheckoutPaymentStep = (
	paymentMethod: PartialBy<PaymentMethod, "details">,
	billingInfoContext?: {
		totalExceedsVenmoTransactionLimit: boolean;
		cartPrices: ReturnType<typeof useCart>["cartPrices"];
		isPaymentMethodTypeDisabled: (paymentMethodType: PaymentMethodType) => boolean;
	}
) => {
	const checkoutState = useCheckout();

	const selectedPaymentMethod = useAutoUpdateState(() => {
		const value = paymentMethod;
		return value;
	}, [paymentMethod]);

	const isRealPaymentMethod = useAutoUpdateState(() => {
		const value = !!paymentMethod.token;
		return value;
	}, [paymentMethod]);

	const disabledPaymentMethod = useAutoUpdateState(() => {
		return billingInfoContext?.isPaymentMethodTypeDisabled(paymentMethod.type);
	}, [paymentMethod.type, billingInfoContext?.isPaymentMethodTypeDisabled]);

	const price = useAutoUpdateState(() => {
		if (billingInfoContext?.cartPrices) {
			return billingInfoContext?.cartPrices[paymentMethod.type];
		}
	}, [billingInfoContext?.cartPrices, paymentMethod.type]);

	const paymentMethodIsSelected = useAutoUpdateState(() => {
		const value = isRealPaymentMethod ? checkoutState.paymentMethod?.token === paymentMethod.token : checkoutState.newPaymentMethodType === paymentMethod.type;
		return value;
	}, [isRealPaymentMethod, checkoutState, paymentMethod.token, paymentMethod.type]);

	const forceCreditCardSelection = useAutoUpdateState(() => {
		return checkoutState.newPaymentMethodType === "CreditCard" && paymentMethod.type === "CreditCard";
	}, [paymentMethod.type, checkoutState.newPaymentMethodType]);

	return { selectedPaymentMethod, isRealPaymentMethod, disabledPaymentMethod, price, paymentMethodIsSelected, forceCreditCardSelection };
};
