import React, { useState } from "react";
import { CommonPage } from "../components";
import { ParallelogramAccent } from "../components/Accents/ParallelogramAccent";
import { MetaDecorator, MetaTagData } from "../components/SEOMetadecorator/MetaDecorator";
import { useNewNavbar } from "../hooks/navbar";
import hero_bg from "../resource/assets/covers-lp/hero_bg.svg";
import { getAppColor } from "../util/appColors";
import { maxContentWidthPadding, maxContentWidthStyles, maxContentWidthValue } from "../util/maxContentWidth";
import styled from "styled-components/macro";
import hero_logo from "../resource/assets/covers-lp/hero_logo.png";
import hero_covers from "../resource/assets/covers-lp/hero_covers.png";
import instagram_example from "../resource/assets/covers-lp/instagram_example.png";
import { FontFamily, getTypographyStyles } from "../components/Typography/Typography";
import instagram_icon from "../resource/assets/covers-lp/instagram_icon.svg";
import facebook_icon from "../resource/assets/covers-lp/facebook_icon.svg";
import twitter_icon from "../resource/assets/covers-lp/twitter_icon.svg";
import medal_icon from "../resource/assets/covers-lp/medal_icon.svg";
import trophy_icon from "../resource/assets/covers-lp/trophy_icon.svg";
import { CarouselRow } from "../components/CarouselRow/CarouselRow";
import cover1 from "../resource/assets/covers-lp/covers/1.png";
import cover2 from "../resource/assets/covers-lp/covers/2.png";
import cover3 from "../resource/assets/covers-lp/covers/3.png";
import cover4 from "../resource/assets/covers-lp/covers/4.png";
import cover5 from "../resource/assets/covers-lp/covers/5.png";
import cover6 from "../resource/assets/covers-lp/covers/6.png";
import cover7 from "../resource/assets/covers-lp/covers/7.png";
import { useAutoUpdateState } from "../hooks/useAutoUpdateState";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { breakpoints } from "../util/breakpoints";
import { Dialog } from "../components/Dialog/Dialog";

const coverRowSpacing = 13;

export const CoversRoute = () => {
	useNewNavbar();

	const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);

	const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	const [showTermsModal, setShowTermsModal] = useState(false);

	const coversDisplayedPerRow = useAutoUpdateState(() => {
		if (isMobile) {
			return 1;
		}
		if (isTablet) {
			return 2;
		}
		return 4;
	}, [isMobile, isTablet]);

	return (
		<CommonPage
			css={`
				${CommonPage.FooterContainer} {
					margin-top: 0;
				}
			`}
		>
			<Dialog
				open={showTermsModal}
				onClose={() => {
					setShowTermsModal(false);
				}}
				type="information"
			>
				<div
					css={`
						color: black;
						font-family: ${FontFamily.Poppins};
						text-align: left;
						line-height: 1.15;
						.c1 {
							padding-bottom: 12pt;
						}
						.c8 {
							padding-bottom: 8pt;
						}
						.c16 {
							color: #1155cc;
							text-decoration: underline;
							text-decoration-skip-ink: none;
						}
						.c4 {
							padding-bottom: 11pt;
						}
						.c3 {
							font-weight: 700;
						}
						.c9 {
							font-weight: 700;
						}
						.c11 {
							margin-left: 36pt;
							padding-left: 0pt;
						}
					`}
				>
					<p className="c1">
						<span className="c5 c3">&ldquo;SI COVER&rdquo; CONTEST &nbsp;OFFICIAL RULES &amp; GENERAL TERMS AND CONDITIONS</span>
					</p>
					<p className="c1">
						<span className="c5 c0">NO PURCHASE IS NECESSARY TO ENTER OR WIN. A PURCHASE DOES NOT INCREASE THE CHANCES OF WINNING. VOID WHERE PROHIBITED, TAXED OR RESTRICTED BY LAW. &nbsp;OPEN ONLY TO LEGAL RESIDENTS OF THE 50 UNITED STATES (EXCLUDING RESIDENTS OF RHODE ISLAND) AND THE DISTRICT OF COLUMBIA WHO ARE EIGHTEEN (18) YEARS OF AGE OR OLDER OR THE AGE OF MAJORITY IN THEIR STATE OF RESIDENCE AS OF THE TIME OF ENTRY.</span>
					</p>
					<p className="c1">
						<span className="c5 c0">By entering the SI Cover Contest (&ldquo;Contest&rdquo;):</span>
					</p>
					<ul className="c19 lst-kix_x6m5t1fhxvy5-0 start">
						<li className="c1 c11 li-bullet-0">
							<span className="c5 c0">You will be bound by and comply with these Official Rules for the Contest (&ldquo;Official Rules&rdquo;), which include the terms for the applicable Contest (&ldquo;Contest Terms&rdquo;) as well as these General Terms and Conditions, and which together form a legally binding agreement between you and the Sponsor identified in the applicable Contest Terms;</span>
						</li>
						<li className="c1 c11 li-bullet-0">
							<span className="c5 c0">You represent that you satisfy all eligibility requirements; and</span>
						</li>
						<li className="c1 c11 li-bullet-0">
							<span className="c5 c0">you will comply with all applicable laws, rules, or regulations (collectively, &ldquo;Applicable Laws&rdquo;).</span>
						</li>
					</ul>
					<p className="c1">
						<span className="c5 c0">If you do not agree to these Official Rules, do not enter the Contest.</span>
					</p>
					<p className="c1">
						<span className="c5 c0">Residents outside the United States may receive a fan cover upon submission, but are not eligible for contest.</span>
					</p>
					<p className="c1">
						<span className="c3">Contest Terms. </span>
						<span className="c5 c0">The Contest Terms include various contest-specific terms, including the identity of the Sponsor(s) and any administrator, information about the prize(s) and the approximate retail value of prize(s), how to enter, when and how winners will be selected, and the Contest Period (the start and end dates/times for the Contest). &nbsp;In the event of any conflict between the applicable Contest Terms and these General Terms and Conditions, the General Terms and Conditions will control.</span>
					</p>
					<p className="c1">
						<span className="c5 c3">1. Eligibility.</span>
					</p>
					<p className="c1">
						<span className="c0">Contest</span>
						<span className="c5 c0">&nbsp;is only open to legal residents of the 50 United States (excluding residents of Rhode Island) and the District of Columbia who are 18 years old as of the date of entry. Employees, representatives, and agents of SI Tickets, Inc. (the &ldquo;Sponsor&rdquo;) and their respective affiliates, subsidiaries, advertising and promotion agencies, suppliers, and their immediate family members, and/or anyone domiciled with any of the above are not eligible to participate in the Contest.</span>
					</p>
					<p className="c1">
						<span className="c0">All entries must be received during the Contest Period in order to be eligible. &nbsp;You may submit multiple entries to the Contest; you may win a prize each month for the duration of the contest period, but you may only win </span>
						<span className="c0">the Grand Prize </span>
						<span className="c5 c0">once for the duration of the contest period. You must use your own email address to be eligible. All Contest entries become property of the Sponsor and will not be acknowledged or returned.</span>
					</p>
					<p className="c1">
						<span className="c5 c3">2. Agreement to Rules.</span>
					</p>
					<p className="c1">
						<span className="c5 c0">By participating, you agree to be fully unconditionally bound by these Terms, and you represent and warrant that you meet the eligibility requirements set forth herein. In addition, you agree to accept the decisions of the Sponsor, as final and binding as it relates to the Contest. The Contest is subject to all applicable federal, state, and local laws.</span>
					</p>
					<p className="c1">
						<span className="c5 c3">3. Contest Period.</span>
					</p>
					<p className="c1">
						<span className="c0">E</span>
						<span className="c0">ntries will be accepted online from </span>
						<span className="c0">December 20, 2022 </span>
						<span className="c0">&nbsp;through </span>
						<span className="c0">December</span>
						<span className="c0">&nbsp;31, 2023</span>
						<span className="c0">&nbsp;(the &ldquo;Contest Period&rdquo;)</span>
						<span className="c0">.</span>
						<span className="c0">The Sponsor may, in its sole discretion, adjust the Contest Period end date.</span>
					</p>
					<p className="c1">
						<span className="c5 c3">4. How to Enter.</span>
					</p>
					<p className="c8">
						<span className="c0">ONLINE: </span>
						<span className="c0">To enter, you must email </span>
						<span className="c16 c7">
							<a className="c17" href="mailto:covers@sitickets.com">
								covers@sitickets.com
							</a>
						</span>
						<span className="c0">&nbsp;a photograph formatted as a JPEG. The JPEG image included in the email will only be accepted if it is 10MB or smaller.</span>
						<span className="c0">I</span>
						<span className="c0">f the photograph you provided to SI Tickets meets the eligibility requirement</span>
						<span className="c0">s (see </span>
						<span className="c0">Photo Eligibility Requirements below)</span>
						<span className="c0">,</span>
						<span className="c0">&nbsp;SI Tickets will reply to the email with an approved personalized SI Cover image </span>
						<span className="c0">in the same format</span>
						<span className="c0">&nbsp;(&ldquo;SI Cover Image&rdquo;) within 5 to 7 business days. Fan will receive standard cover without individualized games or events, unless otherwise decided on by the SI Tickets team. Entries are limited to one entry per person per week.</span>
						<span className="c0 c6"> Upon receipt of the SI Cover Image, you must post the SI Cover Image on either Instagram or Twitter and </span>
						<span className="c0 c6">tag SI Ticke</span>
						<span className="c0 c6">ts</span>
						<span className="c0 c6">.</span>
						<span className="c5 c0">
							&nbsp;The appropriate tag to include on the social post on these respective platforms is provided below: <br />
						</span>
					</p>
					<ol className="c19 lst-kix_xunsvhkbeq45-0 start" start={1}>
						<li className="c8 c11 li-bullet-0">
							<span className="c3">Instagram</span>
							<span className="c5 c0">: @SI.Tickets</span>
						</li>
						<li className="c8 c11 li-bullet-0">
							<span className="c3">Twitter</span>
							<span className="c5 c0">: @SI_Tickets_</span>
						</li>
					</ol>
					<p className="c8">
						<span className="c0">One (1) post of your SI Cover Image on either of these social platforms including the appropriate tag will be considered an entry and will be reviewed for content by SI Tickets. </span>
						<span className="c5 c0">By tagging SI Tickets in a social post as described, you have effectively entered into the contest.</span>
					</p>
					<p className="c8">
						<span className="c5 c0">You may include comments in your post, but a comment will not improve your chances of winning. Any post with any comments that are deemed inappropriate, as determined at the sole discretion of SI Tickets, will be reported on the social platform; if reported, your entry will be considered invalid and SI Tickets reserves the right to revoke future entries by you in the contest. </span>
					</p>
					<p className="c8">
						<span className="c5 c0">You may tag other individuals in your post, but tagging other individuals will not improve your chances of winning. </span>
					</p>
					<p className="c8">
						<span className="c5 c3">Photo Eligibility Requirements: </span>
					</p>
					<p className="c8">
						<span className="c5 c0">Below is a non-comprehensive list of what is deemed an appropriate and acceptable image for the purpose of the contest: </span>
					</p>
					<ul className="c19 lst-kix_ejgjg58m133g-0 start">
						<li className="c8 c11 li-bullet-0">
							<span className="c5 c0">The image must be a JPEG (10MB or smaller) </span>
						</li>
						<li className="c8 c11 li-bullet-0">
							<span className="c5 c0">The image must be a photograph of you and may include other individuals. If the photograph includes the image of other individuals, you agree to have explicit consent from those individuals to be in a photograph provided to SI Tickets for the purpose of the contest. You further agree that each individual in any photograph you submit to SI Tickets is bound by these Contest Terms. </span>
						</li>
						<li className="c8 c11 li-bullet-0">
							<span className="c5 c0">The image should depict you, or you and other individuals, participating in a Sporting Event of your choice; this may include attending a sporting event or watching a sporting event. </span>
						</li>
						<li className="c8 c11 li-bullet-0">
							<span className="c0">The image may depict you (and any other individuals) in your favorite team gear, and may include other props that depict that you (and any other individuals) are a fan of the sporting event you are participating in. </span>
						</li>
					</ul>
					<p className="c8">
						<span className="c0">Users who submits photo agrees that the photo they are submitting is theirs and assumes liability</span>
					</p>
					<p className="c8">
						<span className="c0">Any images that are submitted and which depict crude or lewd behavior or messages, or that are otherwise deemed inappropriate, as determined at the sole discretion of the Sponsor, will not be accepted and an SI Cover will not be sent. &nbsp;Entries that are not complete or which do not adhere to the rules or specifications may be disqualified at the sole discretion of the Sponsor. </span>
					</p>
					<p className="c8">
						<span className="c5 c0">If you use fraudulent methods or otherwise attempts to circumvent the rules, your submission may be removed from eligibility at the sole discretion of Sponsor. Entries submitted by anyone other than you are void. &nbsp;In case of dispute as to the identity of any entrant, the entry will be declared by the registered name and address on such entry. &nbsp;Additionally, Sponsor may remove any registration with or subscription to SI Tickets without refund. </span>
					</p>
					<p className="c1">
						<span className="c5 c0">Neither Sponsor nor any representative, agent, affiliate, subsidiary, advertising and promotion agency, or supplier (&ldquo;Affiliates&rdquo;) is responsible for any entry that is lost, late, incomplete, invalid, misdirected, undeliverable, whether due to system errors, omissions, interruption, deletions, defects, delay in operations or transmissions, theft or destruction or failures, faulty transmissions or telecommunications malfunctions, human error, entries not received resulting from hardware or software failures of any kind, loss of unavailable network connections, failed, incomplete or garbled computer or telephone transmissions, typographical or system errors and failure, faulty transmissions, technical malfunctions, or otherwise. &nbsp;</span>
					</p>
					<p className="c1">
						<span className="c5 c3">5. Prize(s); Winner Selection &amp; Notification of Winners; Conditions.</span>
					</p>
					<p className="c1">
						<span className="c0">Subject to these Contest Terms, the Sponsor will award one (1) Grand Prize per quarter and up to five (5) Secondary Prize(s) per month through the duration of the Contest Period to one or more individuals (each a &ldquo;Winner&rdquo; and collectively &ldquo;Prize Winners&rdquo;) who correctly submit an entry</span>
						<span className="c0">.</span>
						<span className="c5 c0">&nbsp; Sponsor has sole discretion in judging submissions, determining the qualifications for creativity and in selecting the winners based on their creative elements. Sponsor may, but is not obligated, to provide full judging criteria to any contestant. In general images will be judged based on the inclusion of one or more of the following creative elements: </span>
					</p>
					<ol className="c19 lst-kix_840j5jmpyuq4-0 start" start={1}>
						<li className="c1 c11 li-bullet-0">
							<span className="c5 c0">Replication of an iconic SI Cover</span>
						</li>
						<li className="c1 c11 li-bullet-0">
							<span className="c5 c0">Demonstration of team fandom and excitement for a Sport</span>
						</li>
						<li className="c1 c11 li-bullet-0">
							<span className="c0">Displays of unique sporting experiences</span>
						</li>
					</ol>
					<p className="c1">
						<span className="c0">A Grand Prize Winner is eligible to receive</span>
						<span className="c0">&nbsp;$1,000</span>
						<span className="c0">&nbsp;in SI Tickets, Inc. promotional credit to use on </span>
						<span className="c7 c16">
							<a target="_blank" className="c17" href="https://www.google.com/url?q=http://www.sitickets.com&amp;sa=D&amp;source=editors&amp;ust=1670884447495746&amp;usg=AOvVaw2gV3XsW4n4TibLnNzKexTf">
								www.sitickets.com
							</a>
						</span>
						<span className="c0">&nbsp;or the SI Tickets Mobile App (collectively the &ldquo;Site&rdquo;)</span>
						<span className="c5 c0">.</span>
					</p>
					<p className="c1">
						<span className="c0">A Secondary Prize Winner(s) is eligible to receive</span>
						<span className="c0">&nbsp;$50</span>
						<span className="c0">&nbsp;in SI Tickets, Inc. promotional credit to use on the Site</span>
						<span className="c5 c0">. </span>
					</p>
					<p className="c1">
						<span className="c0">Prize Winners must have or must register an account with SI Tickets in order to receive the prize. </span>
						<span className="c5 c0">The maximum amount of promotional credit distributed by SI Tickets to contest winners per quarter is $1,750.</span>
					</p>
					<p className="c1">
						<span className="c0">If you are a Grand Prize Winner, an e-mail notification will be sent to you on or before Thursday at 11:59pm ET </span>
						<span className="c0">two weeks</span>
						<span className="c5 c0">&nbsp;following the end of the quarter of submission. </span>
					</p>
					<p className="c1">
						<span className="c0">If you are a Secondary Prize Winner, an e-mail notification will be sent to you on or before Thursday at 11:59pm ET </span>
						<span className="c0">two weeks</span>
						<span className="c5 c0">&nbsp;following the end of the month of submission. </span>
					</p>
					<p className="c1">
						<span className="c5 c0">Each Winner must respond within seven (7) days of the notification being sent to them. Each Winner must provide any information required by Sponsor for delivery of any prize, including, if applicable, Winner&rsquo;s shipping address. Each prize will be awarded only after (i) Sponsor&rsquo;s receipt of the selected winner&rsquo;s response and any required affidavit of eligibility, declaration of compliance, release and/or other documentation; (2) proper verification of Winner&rsquo;s identity; all subject to Winner&rsquo;s compliance with the General Terms and Conditions. Return of the prize notification as &ldquo;undeliverable&rdquo; or any failure to respond or to return any required affidavit or other documentation in the time period provided above, or any other failure to comply with the General Terms and Conditions, will result in disqualification of the Winner and the forfeiture of the prize. &nbsp;Sponsor is not responsible for any late, lost, stolen, delayed, or undelivered prizes.</span>
					</p>
					<p className="c4">
						<span className="c2">Each Winner will be solely responsible for paying any taxes and any other expenses incurred by the Winner in connection with receiving the prize or participating in the Contest, including any income taxes, sales or use taxes, value added taxes, goods and services taxes, or harmonized sales taxes. Throughout the term of a Contest, and as a condition to receiving any prize, you will provide Sponsor with an affidavit of eligibility, declaration of compliance, release, and any other forms, documents, or certifications, including any that may be required for Sponsor (or its affiliates, any third-party administrator, or promotional partners) to satisfy any information reporting or withholding tax obligations with respect to any payments related to a Contest.</span>
					</p>
					<p className="c4">
						<span className="c7">Each prize will be awarded &quot;as is&quot; and without warranty of any kind, express or implied. If you win a prize, you shall not transfer, assign, or redeem the prize for cash. The Sponsor may substitute a prize with a prize of similar or greater value. </span>
						<span className="c5 c0">No cash or other prize substitution is permitted except at the Sponsor&#39;s discretion. No substitution of prize or transfer/assignment of prize to others or request for the cash equivalent by winners is permitted. Acceptance of prize constitutes permission for Sponsor to use winner&#39;s name, likeness, and entry for purposes of advertising and trade without further compensation unless prohibited by law.</span>
					</p>
					<p className="c1">
						<span className="c3">6. </span>
						<span className="c9">Rights of Publicity</span>
						<span className="c2">.</span>
					</p>
					<p className="c4">
						<span className="c2">You agree that, by entering a Contest, Sponsor and its affiliates may use, display, publish, or broadcast, the winner&#39;s name, image, or likeness in any manner and in any medium for its promotional and advertising purposes without additional compensation, notification, or permission, including posting in a winner&#39;s list, except where prohibited by law.</span>
					</p>
					<p className="c4">
						<span className="c2">Upon Sponsor or its affiliate&#39;s request, you will sign any necessary documentation that may be required for Sponsor or its affiliate to make use of the rights granted herein.</span>
					</p>
					<p className="c1">
						<span className="c3 c5">7. Terms.</span>
					</p>
					<p className="c1">
						<span className="c5 c0">Sponsor reserves the right, in its sole discretion to cancel, terminate, modify, or suspend the Contest should (in its sole discretion) a virus, bugs, non-authorized human intervention, fraud, or other causes beyond its control corrupt or affect the administration, security, fairness or proper conduct of the Contest. In such case, Sponsor may select the recipients from all eligible entries received prior to and/or after (if appropriate) the action taken by Sponsor. Sponsor reserves the right at its sole discretion to disqualify any individual who tampers or attempts to tamper with the entry process or the operation of the Contest or website or violates these Terms &amp; Conditions. Sponsor has the right, in its sole discretion, to maintain the integrity of the Contest, to void votes for any reason, including, but not limited to; multiple entries from the same user from different IP addresses; multiple entries from the same computer in excess of that allowed by contest rules; or the use of bots, macros or scripts or other technical means for entering. Any attempt by an entrant to deliberately damage any website or undermine the legitimate operation of the contest may be a violation of criminal and civil laws and should such an attempt be made; Sponsor reserves the right to seek damages from any such person to the fullest extent permitted by law. By entering the Contest, you agree to receive email newsletters periodically from Sponsor. You can opt out of receiving this communication at any time by clicking the unsubscribe link in the newsletter. You agree that Sponsor&rsquo;s decisions related to the Contest, or any distribution of prizes are final.</span>
					</p>
					<p className="c1">
						<span className="c5 c3">8. Limitation of Liability.</span>
					</p>
					<p className="c4">
						<span className="c9">Release</span>
						<span className="c7">. Sponsor, its promotional partners, any third-party administrator, and any other entities involved in any aspect of the Contest, and their affiliates, officers, directors, employees, representatives, and agents (collectively, the &quot;</span>
						<span className="c9">Released Parties</span>
						<span className="c7">&quot;) are not responsible for and do not assume any liability for, and you agree to release and will hold them harmless from and against any and all liability, loss, injury, or damage to property or person, including death, rights, claims, and actions, and reasonable attorney&#39;s fees and court costs, of any kind (collectively, &quot;</span>
						<span className="c9">Losses</span>
						<span className="c2">&quot;) arising in whole or in part, directly or indirectly, from: (a) the administration of a Contest, including any modification or termination of the Contest, the selection or confirmation of winners or awarding of prizes, and any errors, omissions, or inaccuracies in connection with the foregoing or in any Contest-related materials; (b) the acceptance, possession, use, or misuse of a prize (or any product purchased with a prize) or participation in a Contest; (c) any lost, misdirected, late, or incomplete entries or for inaccurate entry information; (d) any operation or transmission error, failure of electronic communications, theft, unauthorized access to, or alteration of, entries; (e) any damage to any computer or device resulting from accessing or downloading Contest materials or otherwise participating in a Contest; (f) the security or privacy of information transmitted via computer networks, including any breaches of privacy due to interference by a third party, including third-party computer &quot;hackers&quot; or otherwise; or (g) any failure by you to make any disclosures in any social media post or activity in connection with the Contest as required by these Official Rules or Applicable Law. Sponsor may disqualify any individual found to be: (x) tampering with a Contest; (y) violating these Official Rules; or (z) acting in an unsportsmanlike or disruptive manner or with intent to threaten or harass any person, and each of the foregoing will be deemed a breach of these Terms and Conditions. Incomplete, robotic, automatic, programmed, or similar entries will be disqualified. The authorized subscriber of the account used to enter a Contest at the actual time of entry will be deemed to be the participant and must comply with these Terms and Conditions in the event of a dispute as to entries submitted by multiple users having the same e-mail or social media account.</span>
					</p>
					<p className="c4">
						<span className="c9">Limitation of Liability</span>
						<span className="c2">. Without limiting the foregoing or any other provision herein, the Released Parties&#39; total liability for any and all claims, judgements, and awards against the Released Parties shall not exceed the total out-of-pocket expenses incurred by you to enter the applicable Contest. In no event shall the Released Parties be liable for any attorneys&#39; fees, punitive, indirect, incidental, special, and consequential damages.</span>
					</p>
					<p className="c1">
						<span className="c5 c3">9. Disputes.</span>
					</p>
					<p className="c1">
						<span className="c5 c0">THIS CONTEST IS GOVERNED BY THE LAWS OF NEW YORK, WITHOUT RESPECT TO CONFLICT OF LAW DOCTRINES. As a condition of participating in this Contest, the participant agrees that any and all disputes which cannot be resolved between the parties and causes of action arising out of or connected with this Contest, shall be resolved individually, without resort to any form of class action, exclusively before a court located in New York having jurisdiction. Further, in any such dispute, under no circumstances will participant be permitted to obtain awards for, and hereby waives all rights to claim punitive, incidental, or consequential damages, including reasonable attorneys&#39; fees, other than participant&#39;s actual out-of-pocket expenses (i.e., costs associated with entering this Contest), and participant further waives all rights to have damages multiplied or increased.</span>
					</p>
					<p className="c1">
						<span className="c5 c3">10. WAIVER OF JURY TRIAL AND CLASS ACTIONS.</span>
					</p>
					<p className="c12">
						<span className="c2 c14">YOU HERETO IRREVOCABLY AND UNCONDITIONALLY WAIVE ALL RIGHT TO TRIAL BY JURY IN OR TO HAVE A JURY PARTICIPATE IN RESOLVING ANY DISPUTE, ACTION, PROCEEDING, OR COUNTERCLAIM, WHETHER SOUNDING IN CONTRACT, TORT, OR OTHERWISE, ARISING OUT OF OR RELATING TO OR IN CONNECTION WITH THESE OFFICIAL RULES, THE CONTEST, OR ANY PRIZE (OR ANY PRODUCT PURCHASED WITH A PRIZE). ALL CAUSES OF ACTION ARISING OUT OF OR CONNECTED WITH THIS CONTEST, OR ANY PRIZES AWARDED (OR ANY PRODUCTS PURCHASED WITH A PRIZE), SHALL BE RESOLVED INDIVIDUALLY, WITHOUT RESORT TO ANY FORM CLASS ACTION.</span>
					</p>
					<p className="c12">
						<span className="c2">&nbsp;</span>
					</p>
					<p className="c1">
						<span className="c5 c3">11. Privacy Policy.</span>
					</p>
					<p className="c8">
						<span className="c0">Information submitted with an entry is subject to the Privacy Policy stated on the Sponsor&rsquo;s Website. To read the Privacy Policy, click </span>
						<span className="c16 c7">
							<a target="_blank" className="c17" href="https://www.google.com/url?q=https://www.sitickets.com/privacy&amp;sa=D&amp;source=editors&amp;ust=1670884447500025&amp;usg=AOvVaw3_Kv6UCzAdf2gvgE6G3PpZ">
								here
							</a>
						</span>
						<span className="c5 c0">.</span>
					</p>
					<p className="c8">
						<span className="c2 c14">You agree that personal information shared in connection with a Contest may be (i) processed, shared, and otherwise used for the purposes of administering and within the context of a Contest; and (ii) used by the Sponsor to verify your identity, postal address, and telephone number or to otherwise verify your eligibility to participate in a Contest. You acknowledge that Sponsor may be required to disclose the winner&#39;s address to regulatory authorities, and you agree to any such disclosure.</span>
					</p>
					<p className="c1">
						<span className="c5 c3">&nbsp;</span>
					</p>
					<p className="c1">
						<span className="c5 c3">12. Winners List.</span>
					</p>
					<p className="c1">
						<span className="c0">To obtain a copy of the winners or a copy of these Official Rules, mail your request along with a stamped, self-addressed envelope to 234 10</span>
						<span className="c0 c15">th</span>
						<span className="c5 c0">&nbsp;Ave. #20273, New York, New York 10011, ATTN: SI Cover Contest. Requests must be received no later than 30 days after the Contest Period. </span>
					</p>
					<p className="c1">
						<span className="c5 c0">Additional Terms: If any term or provision of these Terms and Conditions is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other term or provision in any other jurisdiction. &nbsp;</span>
					</p>
					<p className="c1">
						<span className="c5 c0">If any term of these Terms and Conditions is held to be excessively broad or invalid, illegal, or unenforceable in any jurisdiction, it shall be reformed and construed by limiting and reducing it so as to be enforceable to the maximum extent permitted by Applicable Law in conformance with its original intent.</span>
					</p>
					<p className="c1">
						<span className="c3">13. Sponsor:</span>
					</p>
					<p className="c1">
						<span className="c0">The Sponsor of the Contest is SI Tickets, Inc., </span>
						<span className="c0">26 Broadway, Suite 934, New York, NY, 10004.</span>
					</p>
					<p className="c10">
						<span className="c5 c22"></span>
					</p>
					<p className="c10">
						<span className="c21"></span>
					</p>
					<p className="c10">
						<span className="c21"></span>
					</p>
				</div>
			</Dialog>
			<MetaDecorator {...MetaTagData.Covers} />
			<div
				css={`
					& > * {
						border-bottom: 5px solid ${getAppColor("primary")};
						padding: 72px 0;
						@media (max-width: ${breakpoints.mobile}) {
							padding: 43px 0;
						}
					}
					${getTypographyStyles("bodyNormal")}
					line-height: 1.35;
					h2 {
						font-family: ${FontFamily.Solano};
						font-size: 56px;
						margin-top: -28px;
						@media (max-width: ${breakpoints.mobile}) {
							margin-top: -10px;
						}
						line-height: 1;
					}
				`}
			>
				<div
					css={`
						background-image: url(${hero_bg});
						min-height: 13vh;
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;
						display: flex;
						align-items: center;
						justify-content: center;
						position: relative;
					`}
				>
					<ParallelogramAccent
						position="left"
						css={`
							position: absolute;
							left: 0;
							top: 28px;
							height: 150px;
							@media (max-width: ${breakpoints.tablet}) {
								display: none;
							}
						`}
					/>
					<div
						css={`
							${maxContentWidthStyles}
							z-index: 1;
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							gap: 20px 48px;
							flex-wrap: wrap;
							@media (min-width: ${breakpoints.mobile}) {
								padding: 0 60px;
							}
						`}
					>
						<img
							src={hero_logo}
							alt="Get your own SI fan cover from SI Tickets | Post for a chance to win!"
							css={`
								max-width: 100%;
								max-height: 250px;
							`}
						/>
						<img
							src={hero_covers}
							alt="Sports Illustrated Custom Covers"
							css={`
								max-width: 100%;
								max-height: 244px;
							`}
						/>
					</div>
					<ParallelogramAccent
						position="right"
						css={`
							position: absolute;
							right: 0;
							bottom: 28px;
							height: 150px;
							@media (max-width: ${breakpoints.tablet}) {
								display: none;
							}
						`}
					/>
				</div>
				<div
					css={`
						position: relative;
					`}
				>
					<ParallelogramAccent
						position="left"
						css={`
							position: absolute;
							left: 0;
							top: 28px;
							height: 200px;
							@media (max-width: ${maxContentWidthValue + 120}px) {
								display: none;
							}
						`}
					/>
					<div
						css={`
							${maxContentWidthStyles}
						`}
					>
						<h2
							css={`
								margin-bottom: 33px;
							`}
						>
							Check out some great covers from fans!
						</h2>
						<CarouselRow
							showButtonsOnMobile
							itemGap={[coverRowSpacing]}
							css={`
								.carouselItem {
									width: calc(((100vw - ${maxContentWidthPadding * 2}px) - ${coverRowSpacing * (coversDisplayedPerRow - 1)}px) / ${coversDisplayedPerRow});
									max-width: ${(maxContentWidthValue - maxContentWidthPadding * 2) / coversDisplayedPerRow}px;
									box-sizing: border-box;
									img {
										width: 100%;
									}
								}
							`}
							items={[<img src={cover1} />, <img src={cover2} />, <img src={cover3} />, <img src={cover4} />, <img src={cover5} />, <img src={cover6} />, <img src={cover7} />]}
							thumbnailsDisplayedPerRow={coversDisplayedPerRow}
						/>
					</div>
				</div>
				<div
					css={`
						background-image: url(${hero_bg});
						min-height: 13vh;
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;
						display: flex;
						align-items: center;
						justify-content: center;
						position: relative;
						padding-bottom: 0;
						flex-direction: column;
						color: white;
					`}
				>
					<ParallelogramAccent
						position="right"
						css={`
							position: absolute;
							right: 0;
							top: 28px;
							height: 150px;
							@media (max-width: 940px) {
								display: none;
							}
						`}
					/>
					<h2
						css={`
							margin-bottom: 8px;
						`}
					>
						Get Your Cover
					</h2>
					<p
						css={`
							margin-bottom: 20px;
						`}
					>
						It's simple. Just follow these easy steps:
					</p>
					<div
						css={`
							${maxContentWidthStyles}
							z-index: 1;
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							gap: 19px 48px;
							flex-wrap: wrap;
							@media (min-width: ${breakpoints.mobile}) {
								padding: 0 60px;
							}
						`}
					>
						<div
							css={`
								border: 5px solid ${getAppColor("primary")};
								background: white;
							`}
						>
							<ol
								type="1"
								css={`
									text-align: left;
									list-style: decimal;
									padding: 18px 26px;
									color: black;
									line-height: 1.85;
									list-style-position: inside;
									a {
										color: ${getAppColor("primary")};
										font-weight: bold;
									}
								`}
							>
								<li>
									Email your photo to <a href="mailto:covers@sitickets.com">covers@sitickets.com</a>.
									<p
										css={`
											padding-left: 13px;

											@media (max-width: ${breakpoints.mobile}) {
												padding-left: initial;
											}
										`}
									>
										Entries are limited to one entry per person per week.
									</p>
								</li>
								<li>
									The SI Tickets team will review your photo<sup>**</sup>
								</li>
								<li>Within 7 days, you'll receive your very own SI fan cover</li>
								<li>
									Post your cover on social and tag SI Tickets:
									<br />
									<div
										css={`
											display: flex;
											flex-direction: column;
											margin-top: 2px;
											& > *:not(:last-child) {
												margin-bottom: 8px;
											}
										`}
									>
										<SocialLink service="Instagram" />
										<SocialLink service="Twitter" />
										<SocialLink service="Facebook" />
									</div>
								</li>
							</ol>
						</div>
						<img
							src={instagram_example}
							alt="Custom Sports Illustrated Covers on Instagram"
							css={`
								max-width: 100%;
								max-height: 360px;
							`}
						/>
					</div>
				</div>
				<div
					css={`
						position: relative;
					`}
				>
					<ParallelogramAccent
						position="left"
						css={`
							position: absolute;
							left: 0;
							top: 28px;
							height: 250px;
							@media (max-width: ${maxContentWidthValue + 120}px) {
								display: none;
							}
						`}
					/>
					<div
						css={`
							${maxContentWidthStyles}
						`}
					>
						<h2>Win BIG with your SI fan cover Submission!</h2>
						<p
							css={`
								margin-top: 12px;
							`}
						>
							Post your SI fan cover for a chance to win up to &#36;1,000 in SI Tickets Wallet Credits
							<br />
							to use on any events of your choice.
						</p>
						<span
							css={`
								font-size: 10px;
							`}
						>
							No purchase necessary. US only except residence of RI. Residents outside the United States may receive a fan cover upon submission, but are not eligible for contest.{" "}
							<span
								css={`
									text-decoration: underline;
									cursor: pointer;
								`}
								onClick={() => setShowTermsModal(true)}
							>
								See official rules.
							</span>
							<sup>**</sup>
						</span>
						<div
							css={`
								margin-top: 25px;
								display: flex;
								justify-content: center;
								align-items: center;
								gap: 40px;
								flex-wrap: wrap;
							`}
						>
							<PrizeCard icon={trophy_icon} iconAlt="Trophy Icon" winner="1 Quarterly Winner" amount="1,000" />
							<PrizeCard icon={medal_icon} iconAlt="Medal Icon" winner="Winners at Anytime" amount="50" />
						</div>
					</div>
				</div>
			</div>
		</CommonPage>
	);
};

const PrizeCard = (props: { icon: string; iconAlt: string; winner: string; amount: string }) => {
	return (
		<div
			css={`
				background: #f8f9f8;
				display: flex;
				flex-direction: column;
				padding: 24px 40px;
				span {
					font-family: ${FontFamily.Solano};
					font-size: 36px;
				}
			`}
		>
			<img
				src={props.icon}
				alt={props.iconAlt}
				css={`
					height: 32px;
				`}
			/>
			<span>{props.winner}</span>
			<p
				css={`
					margin-top: -4px;
				`}
			>
				will receive
			</p>
			<span
				css={`
					color: ${getAppColor("primary")};
					font-size: 107px !important;
					line-height: 1;
					margin-top: -6px;
				`}
			>
				&#36;{props.amount}
			</span>
			<span
				css={`
					text-transform: uppercase;
				`}
			>
				In Free Tickets
			</span>
		</div>
	);
};

const socialHandles = {
	Instagram: "SI.Tickets",
	Twitter: "SI_Tickets_",
	Facebook: "SITickets",
};

const SocialLink = (props: { service: "Instagram" | "Twitter" | "Facebook" }) => {
	return (
		<a
			href={
				{
					Instagram: "https://www.instagram.com/",
					Twitter: "https://twitter.com/",
					Facebook: "https://www.facebook.com/",
				}[props.service] + socialHandles[props.service]
			}
			target="_blank"
			css={`
				text-decoration: none !important;
				display: flex;
				align-items: center;
				font-weight: initial !important;
			`}
		>
			<img
				src={
					{
						Instagram: instagram_icon,
						Twitter: twitter_icon,
						Facebook: facebook_icon,
					}[props.service]
				}
				alt={`${props.service} Icon`}
				css={`
					height: 26px;
					margin-right: 6px;
				`}
			/>
			<strong
				css={`
					margin-right: 5px;
				`}
			>
				{props.service}:
			</strong>
			<span
				css={`
					color: black;
				`}
			>
				{socialHandles[props.service]}
			</span>
		</a>
	);
};
