import React from "react";
import { CommonPage } from "../components";
import { maxContentWidthStyles } from "../util/maxContentWidth";
import { useIsMobileWeb } from "../hooks/useIsMobileWeb";
import styled from "styled-components/macro";
import { Link, Switch, Route } from "react-router-dom";
import { InternalRoutes, ExternalRoutes } from "../Link";
import { Company, MeetTheTeam } from "../components/AboutUs/AboutUs";
import { RouteComponentProps } from "react-router";
import { mediaQueries } from "../util/mediaQueries";

const getPath = (path: string) => InternalRoutes.About(path);

const AboutRoutes = {
	Company: getPath("company"),
	MeetTheTeam: getPath("meet-the-team"),
};

const tabsData = [
	{
		title: "Company",
		route: AboutRoutes.Company,
		component: Company,
		default: true,
	},
	{
		title: "Meet The Team",
		route: AboutRoutes.MeetTheTeam,
		component: MeetTheTeam,
		default: false,
	},
];

export const AboutRoute = (props: RouteComponentProps) => {
	const pathName = props.location.pathname;
	const isMobile = useIsMobileWeb();
	return (
		<CommonPage background="#fff">
			<PageContainer>
				<LinkTabs isMobile={isMobile}>
					{tabsData.map(tab => {
						return (
							<Link className={tab.route === pathName || (InternalRoutes.About() === pathName && tab.default) ? "active" : ""} to={tab.route}>
								{tab.title}
							</Link>
						);
					})}
					<a target="_blank" rel="noopener noreferrer" href={ExternalRoutes.Careers}>
						Careers
					</a>
				</LinkTabs>
				<TabsContent>
					<Switch>
						{tabsData.map(tab => {
							return <Route path={tab.route} component={tab.component} />;
						})}
						<Route component={Company} />
					</Switch>
				</TabsContent>
			</PageContainer>
		</CommonPage>
	);
};

const activeTabColor = "#12284C";

const LinkTabs = styled.div<{ isMobile: boolean }>`
    ${maxContentWidthStyles}
    display: flex;
    margin-top: ${props => (props.isMobile ? "0px" : "40px")};
    margin-bottom: ${props => (props.isMobile ? "22px" : "35px")};
    height: 60px;
    a {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        background-color: #fff;
        color: #555555;
        height: 60px;
        border: solid 1px #555555;
        border-right: none;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        font-size: 18px;
        width: 33%;
    }
    a:last-child {
        border-right: solid 1px #555555;
        width: 34%;
    }
    a.active {
        background-color: ${activeTabColor};
        color: #fff;
        font-weight: 700;
        border-color: ${activeTabColor};
    }
    ${props =>
			props.isMobile &&
			`
        max-width: auto;
        padding: 0px;
        box-sizing: border-box;
        a {
            border-top: none;
        }
        a:last-child {
            border-right: none;
        }
        a:first-child, a.active {
            border-left: none;
        }
    `}
}
`;

const PageContainer = styled.div`
	background: #fff;
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	padding-bottom: 25px;
	width: 100%;
`;

const TabsContent = styled.div`
	${maxContentWidthStyles}
	@media ${mediaQueries.max750} {
		padding: 0;
	}
`;
