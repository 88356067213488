import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { getTypographyStyles } from "../Typography/Typography";
import { getAppColor } from "../../util/appColors";
import { Config } from "../../../Config";

export interface IconRowItems {
	title: string;
	url: string;
}

const navbarFolder = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/navbaricons`);

const icons = {
	Sports: `${navbarFolder}/sports.svg`,
	Concerts: `${navbarFolder}/concerts.svg`,
	Theater: `${navbarFolder}/theater.svg`,
	Postseason: `${navbarFolder}/postseason.svg`,
	"Best Bets": `${navbarFolder}/bestbets.svg`,
	"Sports Betting with a Different Title": `${navbarFolder}/bestbets.svg`,
	NFL: `${navbarFolder}/football.svg`,
	Basketball: `${navbarFolder}/sports.svg`,
	"College Football": `${navbarFolder}/football.svg`,
	MLB: `${navbarFolder}/baseball.svg`,
	MLS: `${navbarFolder}/soccer.svg`,
	Fighting: `${navbarFolder}/boxing.svg`,
	Golf: `${navbarFolder}/golf.svg`,
	Tennis: `${navbarFolder}/tennis.svg`,
	"Auto Racing": `${navbarFolder}/racing.svg`,
	NBA: `${navbarFolder}/basketball.svg`,
	NHL: `${navbarFolder}/hockey.svg`,
	"College Basketball": `${navbarFolder}/basketball.svg`,
	MASL: `${navbarFolder}/masl.svg`,
};

export const IconRow = ({ items, ...props }: { items: IconRowItems[]; maxContainerWidth?: number; paddingX?: number; staticIconSpacing?: number }) => {
	const history = useHistory();
	return (
		<RowContainer>
			<Row maxWidth={props.maxContainerWidth} staticIconSpacing={props.staticIconSpacing} paddingX={props.paddingX}>
				{items.map(item => {
					if (item) {
						return (
							<IconContainer onClick={() => item.url && history.push(item.url)} staticIconSpacing={!!props.staticIconSpacing}>
								<Icon src={icons[item.title as keyof typeof icons] || `${navbarFolder}/trophy.svg`} />
								<IconDescription>{item.title}</IconDescription>
							</IconContainer>
						);
					}
				})}
			</Row>
		</RowContainer>
	);
};

const RowContainer = styled.div`
	width: 100%;
	max-width: 100vw;
	display: flex;
	justify-content: center;
	border-bottom: 1px solid ${getAppColor("darkGrey", "light")};
	padding-top: 3px;
`;

const Row = styled.div<{ maxWidth?: number; paddingX?: number; staticIconSpacing?: number }>`
	display: flex;
	width: 100%;
	${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
	${({ staticIconSpacing }) => !staticIconSpacing && `justify-content: space-between;`}
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
	${({ paddingX }) => `padding: 10px ${paddingX || 0}px;`}
	& > *:not(:first-child) {
		margin-left: ${({ staticIconSpacing }) => (staticIconSpacing ? `${staticIconSpacing}px` : `10px`)};
	}
`;

const IconContainer = styled.div<{ staticIconSpacing?: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	cursor: pointer;
	${({ staticIconSpacing }) => staticIconSpacing && `width: 25px;`}
`;

const Icon = styled.img`
	height: 25px;
`;

const IconDescription = styled.div`
	${getTypographyStyles("bodyTiny", {
		color: "darkGrey",
		colorType: "dark",
	})}
	margin-top: 5px;
	white-space: nowrap;
`;
