import { DependencyList, useEffect, useMemo, useState } from "react";

//TODO: OS?
//TODO: Add "setState" to allow factory overide?

export const useMemoState = <T>(factory: () => T, deps?: DependencyList): T => {
	const value = useMemo(factory, deps);
	const [state, setState] = useState(value);
	useEffect(() => {
		setState(value);
	}, [value]);

	return state;
};
