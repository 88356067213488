import React, { useEffect } from "react";
import si_tickets_logo from "../../resource/assets/si_tickets_logo_unstacked_cropped.svg";
import search_icon from "../../resource/img/icons/search.svg";
import account_icon from "../../resource/img/icons/account-icon.svg";
import account_icon_red from "../../resource/img/icons/account-icon-red.svg";
import home_icon from "../../resource/img/icons/home-icon.svg";
import cart_icon from "../../resource/img/icons/cart-icon.svg";
import cart_icon_red from "../../resource/img/icons/cart-icon-red.svg";
import { HeaderTopMessage, TOP_MESSAGE_HEIGHT } from "./HeaderTopMessage";
import { InternalRoutes, Link } from "../../Link";
import { MobileMenuIcon } from "./MobileMenuIcon";
import styled, { css } from "styled-components/macro";
import { mediaQueries } from "../../util/mediaQueries";
import { useCart } from "../../../services/hooks/useCart";
import { SecondaryNav } from "./SecondaryNav";
import { maxContentWidthStyles } from "../../util/maxContentWidth";
import { isMobileApp } from "../../util/isMobileApp";
import { isMobileWeb } from "../../util/isMobileWeb";
import { useDispatch } from "react-redux";
import { setHeaderHeight } from "../../../store/actions/headerHeightActions";
import { baseHeaderHeight } from "../../../store/reducers/headerHeightReducer";
import { NavigationItem } from "../../../model/Navigation";
import { useMemoState } from "../../hooks/useMemoState";
import { useHeaderHeight } from "../../hooks/useHeaderHeight";
import { mobileMediaQuery, mobileLayoutMediaQuery } from "../../util/mobileMediaQuery";

interface HeaderProps {
	isMenuOpen: boolean;
	setMenuOpen: (open: boolean) => void;
	children?: React.ReactNode;
	disableBoxShadow?: boolean;
	isSitixLogoSelected?: boolean;
	hideAllNavigation?: boolean;
	overrideMaxContentWidth?: boolean;
	navigationTree: NavigationItem[];
	hide?: boolean;
	hideSearch?: boolean;
}

export const Header = ({ isMenuOpen, setMenuOpen, isSitixLogoSelected, children, disableBoxShadow, hideAllNavigation, overrideMaxContentWidth, navigationTree, hide, hideSearch }: HeaderProps) => {
	const menuIcon = <MobileMenuIcon isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />;

	const dispatch = useDispatch();

	const headerHeight = useHeaderHeight();

	useEffect(() => {
		dispatch(setHeaderHeight(baseHeaderHeight));
	}, []);

	if (hide) {
		return null;
	}

	return (
		<HeaderContainer height={isMobileApp || isMobileWeb ? 0 : headerHeight}>
			<HeaderFixed
				style={{
					boxShadow: disableBoxShadow || isMobileApp ? "none" : "0 2px 5px -3px #000",
					borderTop: isMobileApp || isMobileWeb ? "1px solid lightgray" : "",
					[isMobileApp || isMobileWeb ? "bottom" : "top"]: 0,
				}}
				height={headerHeight}
			>
				<HeaderMain className={overrideMaxContentWidth ? "" : "max-content-width"}>
					<Link to={InternalRoutes.Home}>
						<img alt="Home" width={150} src={si_tickets_logo} />
					</Link>
					<MiddleContentContainer>{children || <>{!hideAllNavigation && <SecondaryNav className="secondary-nav" items={navigationTree} hideSearch={hideSearch} />}</>}</MiddleContentContainer>
					{!hideAllNavigation && (
						<IconContainer>
							<IconLink to={InternalRoutes.MyAccount()}>
								<IconImage alt="Account" src={account_icon} />
							</IconLink>
							<IconLink to={InternalRoutes.Cart}>
								<CartQuantity />
								<IconImage alt="Cart" src={cart_icon} />
							</IconLink>
							<MenuIconContainer className={children ? "" : "hide-mobile"}>{menuIcon}</MenuIconContainer>
						</IconContainer>
					)}
				</HeaderMain>
				<HeaderNarrow>
					<HeaderNarrowContent>
						<MenuButtonContainer>
							{menuIcon}
							<IconText>Menu</IconText>
						</MenuButtonContainer>

						<IconLink to={InternalRoutes.Search}>
							<IconImage alt="" src={search_icon} />
							<IconText>Search</IconText>
						</IconLink>

						<LogoContainer>
							<IconLink to={InternalRoutes.Home}>
								{isMobileApp ? <IconImage alt="" src={home_icon} /> : <MobileWebLogo alt="Home" src={si_tickets_logo} />}
								<IconText>Home</IconText>
							</IconLink>
						</LogoContainer>

						<IconLink to={InternalRoutes.Cart}>
							<IconImage alt="" src={cart_icon_red} />
							<IconText>Cart</IconText>
							<CartQuantity />
						</IconLink>

						<IconLink to={InternalRoutes.MyAccount()}>
							<IconImage alt="" src={account_icon_red} />
							<IconText>Profile</IconText>
						</IconLink>
					</HeaderNarrowContent>
				</HeaderNarrow>
			</HeaderFixed>
		</HeaderContainer>
	);
};

const menuIconMediaQuery = (styles: string) => mobileMediaQuery(styles, "820px");

const mobileIconLinkStylesChildrenMargin = "10px";

const LogoContainer = styled.div`
	${!isMobileApp &&
	`
		flex: 1;
	`}
`;

const MobileWebLogo = styled.img`
	max-width: 150px;
`;

const MenuIconContainer = styled.div`
	&.hide-mobile {
		display: none;
		${menuIconMediaQuery("display: initial;")}
	}
`;

const MiddleContentContainer = styled.div`
	height: 100%;
	display: flex;
	overflow: hidden;
	width: 100%;
	& > .secondary-nav {
		${menuIconMediaQuery("display: none;")}
	}
`;

const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	& > *:not(:first-child) {
		margin-left: 15px;
	}
`;

const HeaderContainer = styled.div<{ height: number }>`
	height: ${props => props.height}px;
`;

const HeaderFixed = styled.div<{ height: number }>`
	position: fixed;
	left: 0;
	right: 0;
	height: ${props => props.height}px;
	z-index: 110;
	background: #ffffff;
`;

const HeaderCommon = styled.div`
	height: ${baseHeaderHeight}px;
	background-color: #fff;
	align-items: center;
`;

const iconLinkStyles = css`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	${mobileLayoutMediaQuery(`
		&:first-child {
			margin-left: ${mobileIconLinkStylesChildrenMargin}; 
		}
		&:last-child {
			margin-right: ${mobileIconLinkStylesChildrenMargin}; 
		}
		& > *:first-child {
			margin: auto ${mobileIconLinkStylesChildrenMargin};
		}
	`)}
`;

const IconLink = styled(Link)`
	${iconLinkStyles}
`;

const MenuButtonContainer = styled.div`
	${iconLinkStyles};
`;

const HeaderMain = styled(HeaderCommon)`
	display: flex;
	position: relative;
	justify-content: stretch;
	padding: 0 20px;
	justify-content: flex-start;
	align-items: center;
	&.max-content-width {
		${maxContentWidthStyles}
	}
	${mobileLayoutMediaQuery(`
    display:none;
  `)}
`;

const headerNarrowChildrenWidthHeight = "50px";

const HeaderNarrow = styled(HeaderCommon)`
	display: none;
	justify-content: space-evenly;

	& > * {
		width: ${headerNarrowChildrenWidthHeight};
	}

	${mobileLayoutMediaQuery(`
		display: flex;
		align-items: center;
	`)}
`;

const HeaderNarrowContent = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: space-evenly;
	width: 100%;
`;

const IconImage = styled.img`
	height: 25px;
	width: auto;
`;

const IconText = styled.div`
	display: none;
	font-size: 11px;
	margin-top: 5px;
	${mobileLayoutMediaQuery(`
		display: none;
	`)}
`;

const CartQuantityContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 12px;
	font-weight: bold;
	width: 18px;
	height: 18px;
	background: #e92224;
	border-radius: 100%;
`;

const CartQuantityMargin = styled.div`
	position: absolute;
	height: 0px;
	padding-left: 4px;
	padding-bottom: 36px;
	@media ${mediaQueries.max500} {
		padding-bottom: ${isMobileApp ? "33px" : "38px"};
	}
`;

const CartQuantity = () => {
	const { cart } = useCart();

	//TODO: Return total qunatity instead of total items
	const quantity = useMemoState(() => {
		if (cart) {
			const value = cart.tickets.length + Object.values(cart.futures).length;
			if (value) {
				return value;
			}
		}
		return null;
	}, [cart?.tickets, cart?.futures]);

	if (!cart || !quantity) {
		return null;
	}

	return (
		<CartQuantityMargin>
			<CartQuantityContainer>{quantity}</CartQuantityContainer>
		</CartQuantityMargin>
	);
};
