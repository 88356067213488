import { Location } from "../../model/Location";
import { ServiceCallAction, ServiceCallActionParams } from "../../store/middleware/serviceCallMiddleware";
import { EventsResponse, SeaticsEventResponse, EventsCodec, SeaticsEventCodec } from "../types/EventsResponse";

export const getPerformerEvents = (performer: string, location: Location | null, page: number | null, pageSize: number, dateFrom?: string, dateTo?: string, gameVenue?: string | null): ServiceCallAction<EventsResponse> => ({
	method: "GET",
	param: {
		performer_slug: decodeURIComponent(performer),
		...(location && {
			latitude: location.latitude,
			longitude: location.longitude,
		}),
		...(page && {
			page_number: page,
		}),
		page_size: pageSize,
		...(dateFrom && { date_from: dateFrom }),
		...(dateTo && { date_to: dateTo }),
		...(gameVenue && { game_venue: gameVenue }),
	},
	endpoint: "events/performers",
	serviceKey: "getPerformerEvents",
	responseDecoder: EventsCodec,
	type: "SERVICE_CALL",
});

export const getTeamEvents = getPerformerEvents;

export const getVenueEvents = (venue: string | null, page: number | null, pageSize: number | null): ServiceCallAction<EventsResponse> => {
	let params: ServiceCallActionParams = {
		venue_slug: venue,
		...(page && { page_number: page }),
		...(pageSize && { page_size: pageSize }),
	};
	return {
		method: "GET",
		param: params,
		endpoint: "events/near",
		serviceKey: "getVenueEvents",
		responseDecoder: EventsCodec,
		type: "SERVICE_CALL",
	};
};

export const getVenueTrendingEvents = (venue: string | null, page: number | null, pageSize: number | null): ServiceCallAction<EventsResponse> => {
	let params: ServiceCallActionParams = {
		venue_slug: venue,
		...(page && { page_number: page }),
		...(pageSize && { page_size: pageSize }),
	};
	return {
		method: "GET",
		param: params,
		endpoint: "events/trending",
		serviceKey: "getVenueTrendingEvents",
		responseDecoder: EventsCodec,
		type: "SERVICE_CALL",
	};
};

export const getPopularEvents = (page: number | null, category: string | null, from: string | null, to: string | null, distance: string | null, location: Location | null, pageSize: number | null): ServiceCallAction<EventsResponse> => {
	let params: ServiceCallActionParams = {
		...(page && { page_number: page }),
		...(pageSize && { page_size: pageSize }),
		...(category && { category_slug: category }),
		...(from && { date_from: from }),
		...(to && { date_to: to }),
		...(distance && { distance }),
		...(location && {
			latitude: location.latitude,
			longitude: location.longitude,
		}),
	};
	return {
		method: "GET",
		param: params,
		endpoint: "events/trending",
		serviceKey: "getPopularEvents",
		responseDecoder: EventsCodec,
		type: "SERVICE_CALL",
	};
};

export const getNearEvents = (location: Location | null, category: string, page: number | null, dateFrom?: string, dateTo?: string, shortNames?: boolean): ServiceCallAction<EventsResponse> => ({
	method: "GET",
	param: {
		...(page && {
			page_number: page,
		}),
		category_slug: category,
		...(location && {
			latitude: location.latitude,
			longitude: location.longitude,
		}),
		...(dateFrom && { date_from: dateFrom }),
		...(dateTo && { date_to: dateTo }),
		...(shortNames && { short_names: "" }),
	},
	endpoint: "events/near",
	serviceKey: "getEvents",
	responseDecoder: EventsCodec,
	type: "SERVICE_CALL",
});

export const searchEvents = (query: string | null, page: number | null): ServiceCallAction<EventsResponse> => ({
	method: "GET",
	param: {
		...(page && {
			page_number: page,
		}),
		...(query && {
			query,
		}),
	},
	endpoint: "events/near",
	serviceKey: "searchEvents",
	responseDecoder: EventsCodec,
	type: "SERVICE_CALL",
});

export const getEventData = (eventSlug: string): ServiceCallAction<SeaticsEventResponse> => {
	return {
	method: "GET",
	param: { event_slug: decodeURIComponent(eventSlug) },
	endpoint: "events",
	serviceKey: "getEventData",
	responseDecoder: SeaticsEventCodec,
	type: "SERVICE_CALL",
}};
