import { Reducer } from "redux";
import { Discount } from "../../model/Cart";
import { CreditCardInfo } from "../../model/Checkout";
import { PaymentMethodType, PaymentMethodWithToken } from "../../model/optimizedModel/braintree";

export interface CheckoutState {
	creditCardInfo?: CreditCardInfo;
	paymentMethod?: PaymentMethodWithToken;
	newPaymentMethodType?: PaymentMethodType;
	shippingAddressIndex: number;
	agreedToTermsAndPrivacy?: boolean;
	activeStep: number;
	stepsValid: {
		[key: number]: boolean;
	};
	ticketTimerInitialTime?: string;
	walletFundsUsed: number;
	phoneNumber?: string;
	discount?: Discount;
	totalTax: number | null;
}

const defaultCheckoutState: CheckoutState = {
	activeStep: 0,
	stepsValid: {},
	shippingAddressIndex: -1,
	walletFundsUsed: 0,
	totalTax: null,
};

export const checkoutReducer: Reducer<CheckoutState, CheckoutActions> = (state: CheckoutState = defaultCheckoutState, action: CheckoutActions) => {
	switch (action.type) {
		case "SET_CHECKOUT_PAYMENT_METHOD":
			return {
				...state,
				newPaymentMethodType: undefined,
				paymentMethod: action.paymentMethod,
				creditCardInfo: action.creditCardInfo,
			};
		case "SET_CHECKOUT_NEW_PAYMENT_METHOD_TYPE":
			return {
				...state,
				paymentMethod: undefined,
				creditCardInfo: undefined,
				newPaymentMethodType: action.paymentMethodType,
			};
		case "SET_CHECKOUT_SHIPPING_ADDRESS_INDEX":
			return {
				...state,
				shippingAddressIndex: action.shippingAddressIndex,
			};
		case "SET_CHECKOUT_AGREED_TO_TERMS_AND_PRIVACY":
			return {
				...state,
				agreedToTermsAndPrivacy: action.agreedToTermsAndPrivacy,
			};
		case "SET_CHECKOUT_ACTIVE_STEP":
			return {
				...state,
				activeStep: action.activeStep,
			};
		case "SET_CHECKOUT_STEP_VALIDITY":
			return {
				...state,
				stepsValid: {
					...state.stepsValid,
					[action.step]: action.valid,
				},
			};
		case "RESET_CHECKOUT_STATE":
			return {
				...defaultCheckoutState,
				ticketTimerInitialTime: state.ticketTimerInitialTime,
			};
		case "SET_CHECKOUT_TIMER":
			return {
				...state,
				ticketTimerInitialTime: action.time,
			};
		case "SET_WALLET_FUNDS_USED":
			return {
				...state,
				walletFundsUsed: action.walletFundsUsed,
			};
		case "RESET_PAYMENT_METHOD_TYPE":
			return {
				...state,
				paymentMethod: undefined,
				newPaymentMethodType: undefined,
				creditCardInfo: undefined,
			};
		case "SET_CHECKOUT_PHONE_NUMBER":
			return {
				...state,
				phoneNumber: action.phoneNumber,
			};
		case "SET_CHECKOUT_DISCOUNT": {
			return {
				...state,
				discount: action.discount,
			};
		}
		case "SET_CHECKOUT_TOTAL_TAX":
			return {
				...state,
				totalTax: action.totalTax,
			};
		default:
			return state;
	}
};

export type CheckoutActions =
	| {
			type: "SET_CHECKOUT_PAYMENT_METHOD";
			paymentMethod: PaymentMethodWithToken;
			creditCardInfo?: CreditCardInfo;
	  }
	| {
			type: "SET_CHECKOUT_NEW_PAYMENT_METHOD_TYPE";
			paymentMethodType: PaymentMethodType;
	  }
	| {
			type: "SET_CHECKOUT_SHIPPING_ADDRESS_INDEX";
			shippingAddressIndex: number;
	  }
	| {
			type: "SET_CHECKOUT_AGREED_TO_TERMS_AND_PRIVACY";
			agreedToTermsAndPrivacy: boolean;
	  }
	| {
			type: "SET_CHECKOUT_ACTIVE_STEP";
			activeStep: number;
	  }
	| {
			type: "SET_CHECKOUT_STEP_VALIDITY";
			valid: boolean;
			step: number;
	  }
	| {
			type: "RESET_CHECKOUT_STATE";
	  }
	| {
			type: "SET_CHECKOUT_TIMER";
			time?: string;
	  }
	| {
			type: "SET_WALLET_FUNDS_USED";
			walletFundsUsed: number;
	  }
	| {
			type: "RESET_PAYMENT_METHOD_TYPE";
	  }
	| {
			type: "SET_CHECKOUT_PHONE_NUMBER";
			phoneNumber: string;
	  }
	| {
			type: "SET_CHECKOUT_DISCOUNT";
			discount?: Discount;
	  }
	| {
			type: "SET_CHECKOUT_TOTAL_TAX";
			totalTax: number | null;
		};
