import React from "react";
import { Event } from "../../../model/Event";
import { NormalEventItem } from "./Event";
import { AdProps, HorizontalAd } from "../Advertisement/Advertisement";
import { maxContentWidthPadding, maxContentWidthStyles } from "../../util/maxContentWidth";
import useInfiniteScroll from "react-infinite-scroll-hook";
import styled from "styled-components/macro";
import { mediaQueries } from "../../util/mediaQueries";
import { eventContentLeftRightPadding } from "./styles";
import { Spinner } from "../Loader/Spinner";

const ADVERTISMENT_INTERVAL = 10;

export interface EventAdvertisementProps {
	vertical_adv_mobile_image_1?: AdProps | null;
	vertical_adv_mobile_image_2?: AdProps | null;
	vertical_adv_mobile_image_3?: AdProps | null;
	vertical_adv_mobile_image_4?: AdProps | null;
}

export type EventsProps<T> = {
	title: string;
	subtitle?: React.ReactNode;
	noLocalResultsMessage?: React.ReactNode;
	className?: string;
	children?: JSX.Element;
	emptyMessage?: React.ReactNode;
	hideOnEmpty?: boolean;
	total?: number | null | undefined;
	loading?: boolean;
	ads?: EventAdvertisementProps | null;
	onLoadMore?: () => void;
	performer?: string;
	wrapSubtitle?: boolean;
	filters?: React.ReactNode;
} & (
	| {
			events: T[] | null | undefined;
			render: (event: T, index: number) => React.ReactNode;
	  }
	| {
			events: Event[] | null | undefined;
			render?: undefined;
	  }
);

//Defaults
const defaultRender = (event: Event, index: number, performer?: string) => <NormalEventItem key={index} event={event} performer={performer} />;

export const Events = function <T>(props: EventsProps<T>) {
	const loadedEvents = props.events?.length || 0;
	const totalEvents = props.total || 0;
	const hasNextPage = loadedEvents < totalEvents;

	const { loading = false, onLoadMore } = props;

	const infiniteRef = useInfiniteScroll<HTMLDivElement>({
		loading,
		hasNextPage,
		onLoadMore: onLoadMore as any,
		scrollContainer: "window",
	});

	if (props.hideOnEmpty && props.events && !props.events.length) {
		return null;
	}

	return (
		<EventsSection className={props.className || ""}>
			<EventsHeader>
				<MainEventsHeaderContent wrapSubtitle={props.wrapSubtitle}>
					<span>{props.title}</span>
					{props.subtitle}
				</MainEventsHeaderContent>
				{props.noLocalResultsMessage}
			</EventsHeader>
			<EventContent>
				<EventItemContainer hasChildren={!!props.children}>
					{props.filters}
					<div ref={infiniteRef}>{renderEvents(props)}</div>
					{renderStatusIndicator(props)}
				</EventItemContainer>
				{props.children}
			</EventContent>
		</EventsSection>
	);
};

//Renders
function renderStatusIndicator<T>(props: EventsProps<T>) {
	return props.loading ? <StyledSpinner /> : props.events && !props.events.length ? <EmptyMessage>{props.emptyMessage ? props.emptyMessage : "No events found."}</EmptyMessage> : null;
}

function renderEvents<T>(props: EventsProps<T>) {
	if (props.events && props.events.length) {
		const render = props.render || defaultRender;

		const ads = [props.ads?.vertical_adv_mobile_image_1, props.ads?.vertical_adv_mobile_image_2, props.ads?.vertical_adv_mobile_image_3, props.ads?.vertical_adv_mobile_image_4].filter(ad => ad && ad.url);

		return (props.events as Array<T>).map((...args: any) => {
			const [event, index] = args;
			const offset = (Math.trunc(index / ADVERTISMENT_INTERVAL) % ads.length) - 1;
			const selectedAd = offset >= 0 ? offset : ads.length - 1;
			const showAdd = !!index && !(index % ADVERTISMENT_INTERVAL);
			const shouldRenderAd = showAdd && !isNaN(selectedAd);

			return (
				<React.Fragment key={`fragment-${index}`}>
					{shouldRenderAd && (
						<HorizontalAdContainer>
							<HorizontalAd key={`ad-${index}`} {...ads[selectedAd]} />
						</HorizontalAdContainer>
					)}
					{render(event, index, props.performer)}
				</React.Fragment>
			);
		});
	}
	return null;
}

const EventContent = styled.div`
	display: flex;
	padding: 0 ${maxContentWidthPadding}px;
	@media ${mediaQueries.max750} {
		padding: 0;
	}
`;

const EventItemContainer = styled.div<{ hasChildren?: boolean }>`
	flex: 1;
`;

const HorizontalAdContainer = styled.div`
	@media ${mediaQueries.max750} {
		margin-left: ${eventContentLeftRightPadding};
		margin-right: ${eventContentLeftRightPadding};
	}
`;

const EventsSection = styled.div`
	${maxContentWidthStyles}
	padding:0;
	width: 100%;
	&:not(:first-child) {
		margin-top: 1.25rem;
	}
`;

const MainEventsHeaderContent = styled.div<{ wrapSubtitle?: boolean }>`
	display: flex;
	justify-content: start;
	align-items: center;
	text-transform: uppercase;
	justify-content: space-between;
	max-width: 1100px;
	text-align: left;
	line-height: normal;
	font-weight: bold;
	color: #12284b;
	box-sizing: border-box;
	font-family: Montserrat, sans-serif;
	font-size: 1rem;
	margin-bottom: 0.5rem;
	font-size: 1.15rem;
	${props =>
		props.wrapSubtitle
			? `
   		flex-direction:column;
		align-items:flex-start;
		&>:last-child{
			margin-top:10px;
			margin-bottom:10px;
			padding-left: 0px;
			margin-left: 0px;
			border-left: none;
		}
		
   `
			: ""}
`;

const EventsHeader = styled.div`
	padding: 0 ${maxContentWidthPadding}px;
	flex-direction: column;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
`;

const EmptyMessage = styled.p`
	margin-top: 30px;
	margin-bottom: 30px;
	font-size: 18px;
	padding: 0px 20px;
	font-weight: 500;
	@media ${mediaQueries.max750} {
		font-size: 16px;
	}
`;

const StyledSpinner = styled(Spinner)`
	margin-top: 20px;
	margin-bottom: 20px;
`;
