import { css } from "styled-components";
import { maxContentWidthPadding } from "../../util/maxContentWidth";

export const fullPageWidthCarouselRowStyles = css`
	.content {
		padding: 0 ${maxContentWidthPadding}px;
	}
`;

export const fullPageWidthCarouselRowWithRightPadding = css`
	.content {
		padding-right: ${maxContentWidthPadding}px;
	}
`;
