import React from "react";
import { FontFamily, Typography } from "../../Typography/Typography";
import { Button } from "../../NewButton/Button";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styled from "styled-components/macro";

export const WalletSection = (props: { disclaimer: string }) => {
	return (
		<div
			css={`
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			`}
		>
			<Button
				disabled
				variant="solid"
				color="primary"
				css={`
					& > * {
						border-radius: 20px;
						font-family: ${FontFamily.Poppins};
						text-transform: none;
						font-weight: 700;
						font-size: 16px;
					}
				`}
			>
				Connect Wallet
			</Button>
			<div
				css={`
					margin-top: 15px;
					min-width: 310px;
					max-width: 400px;
				`}
			>
				<Typography
					type="bodySmall"
					fontWeight={700}
					css={`
						text-align: left;

						& > * {
							color: #c7c7c7;
						}
					`}
				>
					{props.disclaimer}
				</Typography>
			</div>
		</div>
	);
};
