import React, { useState, useEffect } from "react";
import { Event } from "../../../model/Event";
import { EventItem } from "./NewEvent";
import { AdProps } from "../Advertisement/Advertisement";
import { maxContentWidthPadding, maxContentWidthStyles } from "../../util/maxContentWidth";
import useInfiniteScroll from "react-infinite-scroll-hook";
import styled from "styled-components/macro";
import { getAppColor } from "../../util/appColors";
import { breakpoints } from "../../util/breakpoints";
import { SkeletonWrapper } from "../SkeletonWrapper/SkeletonWrapper";
import { LocationPickerHeader } from "../LocationPickerHeader/LocationPickerHeader";
import { getTypographyStyles } from "../Typography/Typography";
import { LocationPicker } from "../LocationPicker/NewLocationPicker";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { Button } from "../NewButton/Button";
import { useAutoUpdateState } from "../../hooks/useAutoUpdateState";
import { FreeStarAd } from "../FreeStarAd";

const ADVERTISMENT_INTERVAL = 10;
const NEW_ADVERTISMENT_INTERVAL = 5;

export interface EventAdvertisementProps {
	vertical_adv_mobile_image_1?: AdProps | null;
	vertical_adv_mobile_image_2?: AdProps | null;
	vertical_adv_mobile_image_3?: AdProps | null;
	vertical_adv_mobile_image_4?: AdProps | null;
}

interface FreeStarAdProps {
	adCategory?: string;
	listingIdentifier: string; // Since the freestar slot ids cannot be repeated the user has to provide a way of identifying two ads, this will get appended to the slotId to create unique tags for the ads
}

type NewEventsProps<T> = React.PropsWithChildren<{
	title: React.ReactNode;
	subtitle?: React.ReactNode;
	displayNoEventsFoundMessage?: boolean;
	customNoEventsFoundMessage?: string;
	className?: string;
	emptyMessage?: React.ReactNode;
	hideOnEmpty?: boolean;
	total?: number | null | undefined;
	loading?: boolean;
	ads?: EventAdvertisementProps | null;
	onLoadMore?: () => void;
	performer?: string;
	wrapSubtitle?: boolean;
	filters?: React.ReactNode;
	newDesign?: boolean;
	paginatedScroll?: boolean;
	events: Event[] | null | undefined;
	adsMetadata?: FreeStarAdProps;
}>;

export const NewEvents = function <T>(props: NewEventsProps<T>) {
	const loadedEvents = props.events?.length || 0;
	const totalEvents = props.total || 0;
	const hasNextPage = loadedEvents < totalEvents;
	const [firstLoad, setFirstLoad] = useState(true);

	useEffect(() => {
		if (!props.loading) {
			setFirstLoad(false);
		}
	}, [props.loading]);

	const { loading = false, onLoadMore } = props;

	const infiniteRef = useInfiniteScroll<HTMLDivElement>({
		loading,
		hasNextPage,
		onLoadMore: onLoadMore as any,
		scrollContainer: "window",
	});

	const [showNewDesignFilters, setShowNewDesignFilters] = useState(true);
	const [filtersToggled, setFiltersToggled] = useState(false);

	const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);

	useEffect(() => {
		if (props.newDesign) {
			if (!isMobile) {
				setShowNewDesignFilters(true);
			} else {
				if (filtersToggled) {
					setShowNewDesignFilters(true);
				} else {
					setShowNewDesignFilters(false);
				}
			}
		}
	}, [filtersToggled, props.newDesign, isMobile]);

	const adInterval = useAutoUpdateState(() => (props.newDesign ? NEW_ADVERTISMENT_INTERVAL : ADVERTISMENT_INTERVAL), [props.newDesign]);

	if (props.hideOnEmpty && props.events && !props.events.length) {
		return null;
	}

	return (
		<EventsSection className={props.className || ""}>
			<SkeletonWrapper
				loading={firstLoad}
				css={`
					${props.newDesign &&
					`
						margin-bottom: 10px;
						& > *, & > * > * {
							border-radius: 6px;
						}

						@media (max-width: ${breakpoints.mobile}) {
							margin: 0 ${maxContentWidthPadding}px;
						}
					`}
				`}
			>
				<EventsHeader newDesign={props.newDesign}>
					<EventsHeaderContents newDesign={props.newDesign}>
						<div
							css={`
								${LocationPicker.NewLocationSubtitle} {
									${getTypographyStyles("bodyNormal", {
										color: "dark",
									})};
								}
								${LocationPickerHeader.CarouselHeader} {
									margin-bottom: 0;
								}
							`}
						>
							<LocationPickerHeader
								headerTitle={props.title}
								showLocationPicker
								headerTitleLoading={!props.newDesign && firstLoad}
								viewAllLinkLoading={!props.newDesign && firstLoad}
								displayNoEventsFoundMessage={props.displayNoEventsFoundMessage}
								customNoEventsFoundMessage={props.customNoEventsFoundMessage}
								newDesign={props.newDesign}
								onFilterToggle={() => {
									setFiltersToggled(prev => !prev);
								}}
								showFilterToggle={isMobile}
							/>
						</div>
						{showNewDesignFilters && (
							<FilterWrapper isMobile={isMobile} showNewDesignFilters={showNewDesignFilters} newDesign={props.newDesign}>
								{props.filters}
							</FilterWrapper>
						)}
					</EventsHeaderContents>
				</EventsHeader>
			</SkeletonWrapper>

			<EventContent>
				<SkeletonWrapper
					css={`
						${firstLoad &&
						`
							min-height: 100vh;
						`}
						width: 100%;
						& > * {
							border-radius: 6px;
						}
					`}
					loading={firstLoad}
				>
					<div
						css={`
							display: flex;
						`}
					>
						<EventItemContainer>
							{!!loadedEvents && (
								<>
									<div
										ref={!props.paginatedScroll ? infiniteRef : null}
										css={`
											display: flex;
											flex-direction: column;
											& > * {
												margin: 5px 0;
											}
										`}
									>
										{props.events?.map((event, index) => {
											const showAdd = !((index + 1) % adInterval);
											const slotId = Math.trunc(index / adInterval) + 1;

											return (
												<React.Fragment key={index}>
													<EventItem
														event={event}
														newDesign={props.newDesign}
														css={`
															height: ${isMobile ? 75 : 88.5}px;
														`}
													/>
													{showAdd && props.adsMetadata?.adCategory && ["sports", "concerts"].includes(props.adsMetadata.adCategory) && <FreeStarAd placement={`sitickets.com_${props.adsMetadata.adCategory}_incontent_dynamic`} slotId={`sitickets.com_${props.adsMetadata.adCategory}_incontent_dynamic_${slotId.toString()}_${props.adsMetadata.listingIdentifier}`} />}
												</React.Fragment>
											);
										})}
										{props.loading &&
											Array.from(Array(7)).map((item, index) => {
												return (
													<SkeletonWrapper
														key={index}
														loading
														css={`
															height: ${isMobile ? 75 : 88.5}px;
															& > * {
																border-radius: 6px;
															}
														`}
													/>
												);
											})}
									</div>
									{props.paginatedScroll && hasNextPage && !props.loading && (
										<ButtonContainer>
											<Button
												onClick={props.onLoadMore}
												variant="outline"
												color="light"
												css={`
													margin-top: 20px;
												`}
											>
												View More
											</Button>
										</ButtonContainer>
									)}
								</>
							)}
						</EventItemContainer>
						{props.children}
					</div>
				</SkeletonWrapper>
			</EventContent>
		</EventsSection>
	);
};

const FilterWrapper = styled.div<{ newDesign?: boolean; isMobile?: boolean; showNewDesignFilters?: boolean }>`
	${props =>
		!props.newDesign &&
		`
width: 100%;
margin-top: 20px;
`}
	${props =>
		props.newDesign && props.isMobile
			? `
	margin-top: 10px;
	border-top: 1px solid ${getAppColor("darkGrey", "light")};
	padding-top: 16px;
	`
			: ``};
`;

const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const EventContent = styled.div`
	@media (max-width: ${breakpoints.mobile}) {
		padding: 0 ${maxContentWidthPadding}px;
	}
`;

const EventItemContainer = styled.div`
	flex: 1;
	width: 100%;
`;

const EventsSection = styled.div`
	${maxContentWidthStyles}
	padding: 0;
	width: 100%;
	&:not(:first-child) {
		margin-top: 1.25rem;

		@media (max-width: ${breakpoints.mobile}) {
			margin-top: 16px;
		}
	}
	margin-top: 20px;
`;

const EventsHeader = styled.div<{ newDesign?: boolean }>`
	flex-direction: column;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;

	@media (max-width: ${breakpoints.mobile}) {
		${props =>
			!props.newDesign &&
			`
			padding: 0 ${maxContentWidthPadding}px;
		`}
	}
`;

const EmptyMessage = styled.p`
	${getTypographyStyles("bodyLarge")}
	margin-top: 30px;
	margin-bottom: 30px;
	padding: 0px 20px;
	& strong {
		text-decoration: underline;
		color: ${getAppColor("dark")};
	}
	@media (max-width: ${breakpoints.mobile}) {
		${getTypographyStyles("bodyMedium")}
	}
	color: ${getAppColor("darkGrey", "dark")};
`;

const EventsHeaderContents = styled.div<{ newDesign?: boolean }>`
	width: 100%;
	${props =>
		props.newDesign &&
		`
		padding: 20px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		background-color: ${getAppColor("lightGrey")};
		justify-content: space-between;
		box-sizing: border-box;
		@media (max-width: ${breakpoints.mobile}) {
			flex-direction: column;
			align-items: stretch;
		}
	`}
`;
