import { useEffect, useRef } from "react";

export const useTicketListScroll = (ticketQuantities: number[], countIndex: number) => {
	const orderSummaryRef = useRef<HTMLDivElement>(null);
	const scrollableContainerRef = useRef<HTMLDivElement>(null);
	const ticketsContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (orderSummaryRef.current) {
			window.scrollTo({ top: document.documentElement.scrollHeight });
		}

		if (ticketsContainerRef?.current?.children[countIndex] && ticketQuantities[countIndex] > 0 && scrollableContainerRef.current) {
			const selectedTicket = ticketsContainerRef.current.children[countIndex] as HTMLElement;
			const scrollPosition = selectedTicket.offsetTop;
			scrollableContainerRef.current.scrollTo({ top: scrollPosition, behavior: "smooth" });
		}
	}, [ticketQuantities, countIndex]);

	return { orderSummaryRef, scrollableContainerRef, ticketsContainerRef };
};
