import React from "react";
import styled from "styled-components";
import { FontFamily } from "../Typography/Typography";

export const RoundedButton = (props: { icon?: string; text: string; onClick: () => void }) => {
	return (
		<ButtonWrapper onClick={props.onClick}>
			{props.icon && <img src={props.icon} alt="" />}
			<ButtonText>{props.text}</ButtonText>
		</ButtonWrapper>
	);
};

const ButtonWrapper = styled.button`
	display: flex;
	padding: 12px 16px;
	border: 1px solid #e5e5e5;
	border-radius: 30px;
	background: #fff;
	font-size: 14px;
	cursor: pointer;
	&:hover {
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	}
	img {
		width: 20px;
		margin-top: 2px;
	}
`;

const ButtonText = styled.div`
	color: #e02000;
	font-weight: 500;
	font-size: 14px;
	font-family: ${FontFamily.Poppins};
	padding-left: 5px;
`;
