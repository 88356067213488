import React from "react";
import styled from "styled-components/macro";
import { isMobileApp } from "../../util/isMobileApp";
import backArrow from "../../resource/image/ticket-group-arrow.svg";
import backArrowWhite from "../../resource/image/back-arrow.svg";

import { useHistory, useLocation } from "react-router";
import { InternalRoutes } from "../../Link";
import { getAppColor } from "../../util/appColors";

const allowedRoutes = [InternalRoutes.Category("*"), InternalRoutes.Performer("*"), InternalRoutes.Venue("*"), InternalRoutes.Team("*"), InternalRoutes.About(), InternalRoutes.Terms, InternalRoutes.Privacy, InternalRoutes.Discover(), InternalRoutes.Popular(), InternalRoutes.Sports("*"), InternalRoutes.MyAccount("*/*"), InternalRoutes.Search, InternalRoutes.SearchResults(), InternalRoutes.Venmo, InternalRoutes.Login];

const customStyles: any = {
	[InternalRoutes.Category("*")]: "smallArrow reduced",
	[InternalRoutes.Sports("*")]: "smallArrow reduced",
	[InternalRoutes.Performer("*")]: "smallArrow reduced",
	[InternalRoutes.Venue("*")]: "smallArrow reduced",
	[InternalRoutes.Team("*")]: "smallArrow",
	[InternalRoutes.Terms]: "fixed",
	[InternalRoutes.Privacy]: "fixed",
	[InternalRoutes.Discover()]: "smallArrow Local",
	[InternalRoutes.MyAccount("*/*")]: "smallArrow MyAccount",
	[InternalRoutes.Search]: "search",
	[InternalRoutes.Venmo]: "venmo",
};

export const compare = (pathA: string, pathB: string) => {
	const normalize = (path: string) => {
		let [base] = path.split("?");
		base = base.startsWith("/") ? base.substring(1) : base;
		base = base.endsWith("/") ? base.substring(0, base.length - 1) : base;
		return base;
	};
	const aEntries = normalize(pathA).split("/");
	const bEntries = normalize(pathB).split("/");
	return aEntries.every((a, i) => a === bEntries[i] || a === "*" || bEntries[i] === "*");
};

export const MobileNavigation = () => {
	const { pathname } = useLocation();
	const navigation = useHistory();

	const showBackButton = allowedRoutes.some(route => {
		return compare(pathname, route);
	});

	let customClassName: any = "";

	Object.keys(customStyles).forEach((routeWithCustomStyles, indx) => {
		if (compare(pathname, routeWithCustomStyles)) {
			customClassName = customStyles[routeWithCustomStyles];
		}
	});

	return isMobileApp && showBackButton ? (
		<MobileNavigationContainer>
			<BackButton className={customClassName} onClick={() => navigation.go(-1)}>
				<img src={backArrow} alt="back" />
			</BackButton>
		</MobileNavigationContainer>
	) : null;
};

const MobileNavigationContainer = styled.div`
	width: 100%;
	position: relative;
`;

const BackButton = styled.div`
	color: #e2e2e2;
	top: 0;
	left: 0;
	height: 14px;
	position: absolute;
	z-index: 10;
	padding: 1rem;
	cursor: pointer;
	img {
		transform: scaleX(-1);
		cursor: pointer;
		position: absolute;
		top: 15px;
		left: 10px;
		width: 24px;
		height: 24px;
		background-color: rgba(255, 255, 255, 0.8);
		padding: 7px 6px 7px 8px;
		border-radius: 100%;
		content: url("${backArrow}");
	}
	&.smallArrow {
		padding-top: 9px;
		/*
        img{
            height: 17px;
            width: 17px;
        }
        */
	}
	&.reduced {
		padding-bottom: 2px;
	}
	&.search > img {
		padding-top: 15px;
		background-color: unset;
		transform: unset;
		content: url("${backArrowWhite}");
	}
	&.MyAccount {
		display: none;
	}
	&.Futures > img {
		left: 8px;
		top: 8px;
	}
	&.Local > img {
		background: none;
		content: url("${backArrowWhite}");
		transform: scaleX(1) !important;
		top: 25px;
		left: 0px;
	}
	&.venmo > img {
		transform: scaleX(1) !important;
		background-color: ${getAppColor("venmo")};
		content: url("${backArrowWhite}");
		border: solid 2px ${getAppColor("light")};
		padding: 7px 8px 8px 5px;
		width: 26px;
		position: fixed;
		top: 5px;
		left: 5px;
	}
`;
