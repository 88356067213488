import React, { useState } from "react";
import { CarouselRow } from "../CarouselRow/CarouselRow";
import { Thumbnail } from "./Thumbnail";
import styled from "styled-components/macro";
import { maxContentWidthValue, maxContentWidthPadding } from "../../util/maxContentWidth";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";
import { SkeletonProps } from "../../../model/optimizedModel/skeleton";

export const ThumbnailCarouselRow = ({
	thumbnailAspectRatio,
	thumbnailsDisplayedPerRow = 4,
	thumbnailSpacing = 8,
	minThumbnailWidth = 149,
	...props
}: {
	onSelect?: (slug: string, index: number) => void;
	selectedSlug?: string;
	titlesAreLoading?: boolean;
	subtitlesAreLoading?: boolean;
	secondarySubtitlesAreLoading?: boolean;
	thumbnailSpacing?: number;
	thumbnailsDisplayedPerRow?: number;
	minThumbnailWidth?: number;
	thumbnailAspectRatio?: [number, number];
} & StyledComponentProps &
	SkeletonProps<{
		thumbnails: (Omit<React.ComponentProps<typeof Thumbnail>, "onSelect" | "selected"> & {
			slug: string;
		})[];
	}> &
	Pick<React.ComponentProps<typeof CarouselRow>, "rowsAmountPreference">) => {
	const [thumbImgHeight, setThumbImgHeight] = useState(0);

	return (
		<>
			{(props.loading || props.thumbnails) && (
				<CarouselRow
					itemHeight={thumbImgHeight}
					className={props.className}
					disableScrolling={props.loading}
					itemGap={[13, thumbnailSpacing]}
					rowsAmountPreference={props.rowsAmountPreference}
					thumbnailsDisplayedPerRow={thumbnailsDisplayedPerRow}
					items={(props.loading ? Array.from(Array(10)) : props.thumbnails).map((item, index) => {
						const slug = item?.slug;

						return (
							<StyledThumbnail
								setImageHeight={index === 0 && setThumbImgHeight}
								thumbsPerRow={thumbnailsDisplayedPerRow}
								thumbnailSpacing={thumbnailSpacing}
								minWidth={minThumbnailWidth}
								backgroundLoading={props.loading}
								titleLoading={props.titlesAreLoading}
								subtitleLoading={props.subtitlesAreLoading}
								secondarySubtitleLoading={props.secondarySubtitlesAreLoading}
								aspectRatio={thumbnailAspectRatio}
								key={index}
								onClick={() => {
									if (props.onSelect) {
										props.onSelect(slug, index);
									}
								}}
								selected={props.selectedSlug !== undefined && slug === props.selectedSlug}
								{...item}
							/>
						);
					})}
				/>
			)}
		</>
	);
};

const StyledThumbnail = styled(Thumbnail)<{ thumbnailSpacing: number; minWidth: number; thumbsPerRow: number }>`
	width: calc(${props => 100 / props.thumbsPerRow}vw - ${props => props.thumbnailSpacing}px);
	max-width: ${props => (maxContentWidthValue - maxContentWidthPadding * 2) / props.thumbsPerRow}px;
	min-width: ${props => props.minWidth}px;
`;
