import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * If mounted, route changes cause window to scroll to top.
 */
export const ScrollToTop = (props: { pathname?: string }) => {
	const { pathname: hookPathname } = useLocation();

	const effectivePathname = props.pathname ?? hookPathname;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [effectivePathname]);

	return null;
};
