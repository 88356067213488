import React, { useEffect, useState } from "react";
import { CitySearch } from "../Search/LocationSearch";
import { SearchState } from "../Search/SearchState";
import { LocationSubTitle } from "./styles";
import { Location, renderLocation } from "../../../model/Location";
import localEventsLocation from "../../resource/image/local-events-location.svg";
import localEventsLocationArrow from "../../resource/image/arrow-down-event-picker.svg";
import ReactDOM from "react-dom";

interface LocationPickerProps {
	location: Location | null;
	text?: string;
	className?: string;
	renderAs?: (onClick: () => void) => React.ReactNode;
}

export const LocationPicker = (props: LocationPickerProps) => {
	const [showLocationSelector, setShowLocationSelector] = useState(false);
	const text = props.text || "Add Your Location For Local Events";

	const openLocationPicker = () => {
		setShowLocationSelector(true);
	};

	const renderPicker = () => {
		if (typeof props.renderAs !== "undefined") {
			return props.renderAs(openLocationPicker);
		} else {
			return (
				<LocationSubTitle className={props.className} empty={!props.location} onClick={() => setShowLocationSelector(true)}>
					{!!props.location && <img className="arrow" src={localEventsLocationArrow} alt="" />}
					<div>{props.location ? renderLocation(props.location) : text}</div>
					<img className="location" src={localEventsLocation} alt="" />
				</LocationSubTitle>
			);
		}
	};

	return (
		<React.Fragment>
			{renderPicker()}
			{showLocationSelector && (
				<ModalPortal>
					<CitySearch
						location={props.location}
						searchState={SearchState.Location}
						openDialog={showLocationSelector}
						setSearchState={next => {
							if (next === SearchState.None) {
								setShowLocationSelector(false);
							}
						}}
					/>
				</ModalPortal>
			)}
		</React.Fragment>
	);
};

// Helper to render modal component outside the DOM hierarchy
const ModalPortal = (props: React.PropsWithChildren<{}>) => {
	const [modalRoot, setModalRoot] = useState<Element | null>(null);
	useEffect(() => {
		let modalRootRef = document.body.querySelector("#location-modal-root");
		if (modalRootRef == null) {
			modalRootRef = document.createElement("div");
			modalRootRef.id = "location-modal-root";
			document.body.appendChild(modalRootRef);
		}
		setModalRoot(modalRootRef);
	}, []);

	if (modalRoot === null) {
		return null;
	}
	return ReactDOM.createPortal(props.children, modalRoot);
};
