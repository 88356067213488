import styled, { css, keyframes } from "styled-components/macro";
import { isMobileApp } from "../../util/isMobileApp";
import { mediaQueries } from "../../util/mediaQueries";
import { ModalPosition } from "./ModalPosition";

export enum ModalOverlayState {
	Visible,
	Hiding,
	Hidden,
	Showing,
}

const flexParams = (position?: ModalPosition): string | undefined => {
	if (position === undefined) {
		return undefined;
	}
	switch (position) {
		case ModalPosition.Bottom:
			return "stretch";
		case ModalPosition.Center:
			return "center";
	}
};

export const ModalOverlay = styled.div<{
	animationLength: number;
	backdropColor: string;
	position?: ModalPosition;
	state: ModalOverlayState;
}>`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 300 !important;

	display: flex;
	flex-direction: column-reverse;
	justify-content: ${props => flexParams(props.position) ?? "center"};
	align-items: ${props => flexParams(props.position) ?? "center"};
	box-sizing: border-box;

	background-color: ${props => props.backdropColor};

	${props =>
		props.state === ModalOverlayState.Hiding
			? css`
					animation: ${fadeOut} ${props.animationLength}s linear;
			  `
			: props.state === ModalOverlayState.Showing
			? css`
					animation: ${fadeIn} ${props.animationLength}s linear;
			  `
			: ""}

	@media ${mediaQueries.popupMax} {
		justify-content: ${props => flexParams(props.position) ?? "stretch"};
		align-items: ${props => flexParams(props.position) ?? "stretch"};
	}
`;

const mobileStyles = (styles: string) => {
	if (isMobileApp) {
		return styles;
	} else {
		return `
        @media (max-width: 900px) {
            ${styles}
        }
        `;
	}
};

export const FullscreenModalHeader = styled.div`
	display: none;
	border-bottom: 1px solid #ddd;
	background: #ffffff;
	padding: 15px 15px 10px;
	position: relative;

	p {
		flex: 1;
		font-family: "Montserrat";
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: center;
		font-weight: 700;
		font-size: 16px;
		color: #12284c;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 22px;
		margin-right: 56px;
	}
`;

export const FullscreenModalOverlay = styled.div<{
	backdropColor?: string;
	headerHeight?: number;
}>`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10 !important;
	box-sizing: border-box;
	overflow-y: auto;
	animation: ${() => fadeInBottomTop} 0.25s linear;
	background-color: ${props => props.backdropColor};
	margin-top: ${props => props.headerHeight}px;

	${mobileStyles(`
		${FullscreenModalHeader}{
			display:flex;
		}
		margin-top:unset;
	`)}
`;

const fadeInBottomTop = keyframes`
from {
        opacity: 0;
		transform: translateY(20%);
}

to {
        opacity: 1;
		transform: translateY(0);
}
`;

const fadeIn = keyframes`
from {
        opacity: 0;
}

to {
        opacity: 1;
}
`;

const fadeOut = keyframes`
from {
        opacity: 1;
}

to {
        opacity: 0;
}
`;
