import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import selectArrowGray from "../../resource/image/ticket-group-arrow.svg";

export const ModalBackButton = (props: { onClick?: () => void; customIcon?: string }) => {
	const history = useHistory();
	return <BackButton src={props.customIcon || selectArrowGray} onClick={props.onClick || (() => history.go(-1))} />;
};

const BackButton = styled.img`
	cursor: pointer;
	padding: 10px;
	transform: rotate(180deg);
`;
