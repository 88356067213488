import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MyAccountItem } from "./MyAccountItem";
import { TicketContentDisplay } from "../../ContentDisplays/TicketContentDisplay";
import { TicketTransaction, TicketTransactionDeliveryStatus, TicketTransactionEventStatus, TicketTransactionOrderStatus } from "../../../../model/optimizedModel/myAccount";
import { TicketInvoice } from "../../Invoices/TicketInvoice";
import { PaymentMethodDisplay } from "./PaymentMethodDisplay";
import { renderAddress } from "../../../util/address";
import styled from "styled-components/macro";
import { useMemoState } from "../../../hooks/useMemoState";
import { Badge } from "../../Badge/Badge";
import { mediaQueries } from "../../../util/mediaQueries";
import { removeTimezoneFromDateString, toBrowserLocaleString } from "../../../util/optimized/dates";
import { colors } from "../../../resource/colors";
import { postMyAccountTicketsAccessed } from "../../../../services/myAccount";
import { ButtonWithSpinner } from "../../Button/ButtonWithSpinner";
import { apiClient } from "../../../../services/apiClient";
import { TitleAndContentGrid } from "../../TitleAndContentGrid/TitleAndContentGrid";
import { useExtraSmallScreen } from "../../../hooks/useExtraSmallScreen";
import { isMobileApp } from "../../../util/isMobileApp";
import { saveAs } from "file-saver";
import { renderPhoneNumber } from "../../../util/renderPhoneNumber";
import { formatPrice } from "../../../util/formatPrice";
import { SITixWalletBadge } from "../../SITixWalletBadge/SITixWalletBadge";
import { InvoiceComponentItem } from "../../Invoices/Invoice";
import { useAutoUpdateState } from "../../../hooks/useAutoUpdateState";
import { Button } from "../../NewButton/Button";
import { breakpoints } from "../../../util/breakpoints";
import { FontFamily, Typography } from "../../Typography/Typography";
import { TicketModal } from "../TicketModal/TicketModal";
import { faRightLeft, faEye, faMoneyBillTransfer, IconDefinition, faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAppColor } from "../../../util/appColors";
import { SantizedHTML } from "../../SantizedHTML/SantizedHTML";
import { keyframes, css } from "styled-components";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { MyAccountBOItem } from "./MyAccountBOItem";
//TODO: See if a nested component is an acceptable practice.
const FulfillmentButton: React.FC<
	React.PropsWithChildren<{
		link?: string;
		onClick?: () => void;
		loading?: boolean;
		disablePostMyAccountTicketsAccessed?: boolean;
		ticketTransaction: TicketTransaction;
	}>
> = fulfillmentButtonProps => {
	const postMyAccountTicketsAccessedWithOrderId = useCallback(() => {
		if (!fulfillmentButtonProps.disablePostMyAccountTicketsAccessed) {
			postMyAccountTicketsAccessed(String(fulfillmentButtonProps.ticketTransaction.order.id));
		}
	}, [fulfillmentButtonProps.ticketTransaction.order.id, fulfillmentButtonProps.disablePostMyAccountTicketsAccessed]);

	const link = useMemoState(() => {
		let value = fulfillmentButtonProps.link;
		if (value && !value.startsWith("//") && !value.startsWith("http")) {
			value = "https://" + value;
		}
		return value;
	}, [fulfillmentButtonProps.link]);

	return (
		<a
			href={link}
			onClick={() => {
				//TODO: Improve types
				if (fulfillmentButtonProps.onClick) {
					//TODO: Consider spending more effort to have a more "clean" solution for handling async and sync functions.
					return Promise.resolve(fulfillmentButtonProps.onClick()).then(() => {
						postMyAccountTicketsAccessedWithOrderId();
						return;
					});
				} else {
					postMyAccountTicketsAccessedWithOrderId();
				}
			}}
			target="_blank"
			rel="noopener noreferrer"
		>
			<ButtonWithSpinner loading={fulfillmentButtonProps.loading} spinnerPosition="right">
				{fulfillmentButtonProps.children}
			</ButtonWithSpinner>
		</a>
	);
};

//TODO: Figure out what props can be spread when appropriate
export const MyAccountTicket = (props: TicketTransaction) => {
	const isPrimaryBoxOfficeTicket = useAutoUpdateState(() => props.source === "boxoffice", [props.source]);
	const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	const [pdfLoading, setPdfLoading] = useState(false);
	const orderCancelled = useMemoState(() => {
		let value = false;
		switch (props.orderStatus) {
			case TicketTransactionOrderStatus.Cancelled:
			case TicketTransactionOrderStatus.CancelledByChargeback:
				value = true;
		}
		return value;
	}, [props.orderStatus]);

	const isExtraSmallScreen = useExtraSmallScreen();

	const [invoiceSubtotal, setInvoiceSubtotal] = useState(0);
	const [ticketModalVariant, setTicketModalVariant] = useState<"view" | "transfer" | "resell" | "superticket">("view");
	const [ticketModalOpen, setTicketModalOpen] = useState(false);
	const [ticketsAmount, setTicketsAmount] = useState(0);
	const [isPendingTransfer, setIsPendingTransfer] = useState(false);

	useEffect(() => {
		if (props.ticketGroups) {
			const statusesToShow = [null, "cancelled"];
			const filteredTickets = [];
			props.ticketGroups.forEach(ticketGroup => {
				const tickets = ticketGroup.tickets?.filter(ticket => (ticket.qrCode && ticket.transferStatus && statusesToShow.includes(ticket.transferStatus)) || !ticket.transferStatus);
				filteredTickets.push(...tickets||[])
			});
			setTicketsAmount(filteredTickets.length);
			const hasPendingTransfer = (props.order.total === undefined || props.order.total === null) && props.ticketGroups.some(ticketGroup=>ticketGroup.tickets?.some(ticket => ticket.status && ticket.status === "pending"));
			setIsPendingTransfer(hasPendingTransfer);
		}
	}, [props.ticketGroups]);

	interface controls {
		icon: IconDefinition;
		text: string;
		onClick: () => void;
		subText?: string;
		highlightButton?: boolean;
	}

	const ticketControls: controls[] = useMemo(() => {
		const acceptTransferControls: controls[] =
			ticketsAmount > 0 && isPendingTransfer
				? [
						{
							icon: faMoneyBillTransfer,
							text: "Accept Transfer",
							onClick: () => {
								props.acceptTransfer(props.order.id);
							},
						},
				  ]
				: [];
		const viewControls: controls[] =
			ticketsAmount > 0 && !isPendingTransfer
				? [
						{
							icon: faArrowUpFromBracket,
							text: "SuperTicket ™ Content",
							subText: "updates and exclusive content directly from the host",
							highlightButton: true,
							onClick: () => {
								setTicketModalOpen(true);
								setTicketModalVariant("superticket");
							},
						},
						{
							icon: faEye,
							text: "View Tickets",
							subText: "2x VIP",
							onClick: () => {
								setTicketModalOpen(true);
								setTicketModalVariant("view");
							},
						},
				  ]
				: [];
		const transferAndResellControls: controls[] =
			!props.isTransfer && ticketsAmount > 0 && !isPendingTransfer
				? [
						{
							icon: faRightLeft,
							text: "Transfer Tickets",
							subText: "2x VIP",
							onClick: () => {
								setTicketModalOpen(true);
								setTicketModalVariant("transfer");
							},
						},
						{
							icon: faMoneyBillTransfer,
							text: "Resell Tickets",
							subText: "2x VIP",
							onClick: () => {
								setTicketModalOpen(true);
								setTicketModalVariant("resell");
							},
						},
				  ]
				: ticketsAmount > 0 && !isPendingTransfer ? 
				[
					{
						icon: faRightLeft,
						text: "Transfer Tickets",
						subText: "2x VIP",
						onClick: () => {
							setTicketModalOpen(true);
							setTicketModalVariant("transfer");
						},
					}
				]
				: [];
		return viewControls.concat(transferAndResellControls).concat(acceptTransferControls);
	}, [ticketsAmount]);

	const expandedContent = useMemo(() => {
		return {
			invoiceBadge: (
				<TicketInvoiceBadge>
					<TicketInvoice
						eventId={props.event.eventId}
						price={props.ticketGroup.price}
						quantity={props.quantity}
						deliveryFee={props.deliveryMethod.price}
						onUpdate={({ subtotal }) => {
							setInvoiceSubtotal(subtotal);
						}}
						additionalItems={(() => {
							const additionalItems: InvoiceComponentItem[] = [];

							additionalItems.push({
								key: "transactionFee",
								value: formatPrice(props.transactionFee),
								title: !!props.order.discountCode && !props.order.discountAmount ? "Promotional Fee" : "Transaction Fee",
							});
							if (!!props.order.tax) {
								additionalItems.push({
									key: "tax",
									value: formatPrice(props.order.tax),
									title: "Tax",
								});
							}
							if (props.promoWalletFundsUsed > 0) {
								additionalItems.push({
									key: "promotionalWalletFunds",
									value: formatPrice(props.promoWalletFundsUsed),
									title: "Wallet Credit Applied",
									borderTop: true,
								});
							}

							if (props.order.discountAmount) {
								additionalItems.push({
									key: "discountCodeApplied",
									value: formatPrice(props.order.discountAmount),
									title: "Discount Code Applied",
									borderTop: props.promoWalletFundsUsed > 0 ? false : true,
								});
							}

							additionalItems.push({
								key: "total",
								value: props.order.total ? formatPrice(props.order.total) : "Free",
								title: "Total",
								borderTop: true,
								css: {
									fontWeight: "bold",
								},
							});
							return additionalItems;
						})()}
					/>
				</TicketInvoiceBadge>
			),

			invoiceGrid: (
				<TitleAndContentGrid
					maxChildrenWidth="230px"
					flexGap={["9px", "10px"]}
					items={[
						{
							key: "paymentMethod",
							title: "Payment Method",
							content: (
								<>
									{!!props.order.total && props.promoWalletFundsUsed < props.order.total && (
										<PaymentMethodDisplay
											paymentMethod={props.paymentMethod}
											badgeHeight={18}
											css={
												props.promoWalletFundsUsed > 0
													? `
										 margin-top: 10px;
`
													: undefined
											}
										/>
									)}
									{props.promoWalletFundsUsed > 0 && (
										<WalletBadgeContainer>
											<SITixWalletBadge />
										</WalletBadgeContainer>
									)}
								</>
							),
						},
						{
							key: "email",
							title: "Order Email",
							content: props.email,
						},
						{
							key: "phone",
							title: "Order Phone Number",
							content: props.phone !== null ? renderPhoneNumber(props.phone) : null,
						},
						{
							key: "shippingAddress",
							title: "Shipping Address",
							content: props.shippingAddress ? (
								<span>
									{props.shippingAddress?.name}
									<br />
									{renderAddress(props.shippingAddress)}
									<br />
									{props.shippingAddress?.email}
									<br />
									{renderPhoneNumber(props.shippingAddress?.phone)}
								</span>
							) : null,
						},
					]}
				/>
			),
		};
	}, [props]);

	return (
		<>
			<TicketModal open={ticketModalOpen} variant={ticketModalVariant} onClose={() => setTicketModalOpen(false)} ticketTransaction={{ ...props }} />
			{isPrimaryBoxOfficeTicket ? (
				<MyAccountBOItem
					orderData={props}
					orderDetails={
						<StyledExpandableContent>
							<SantizedHTML htmlString={props.event.long_description || ""} />
						</StyledExpandableContent>
					}
					eventDetails={
						<StyledExpandableContent>
							<div
								css={`
									display: flex;
									flex-direction: column;
								`}
							>
								<div
									css={`
										display: flex;
										border-bottom: 1px solid #d3d3d3;
										padding-bottom: 15px;
										gap: 15px;

										& > * {
											min-width: 160px;
										}

										@media (max-width: ${breakpoints.tablet}) {
											flex-direction: column;
										}
									`}
								>
									<div
										css={`
											display: flex;
											flex-direction: column;
											row-gap: 9px;
											flex: 1;
										`}
									>
										<Typography type="bodySmall" fontWeight={400}>
											<MyAccountTicketLabel>Order ID:&nbsp;</MyAccountTicketLabel>#{props.order.id}
										</Typography>
										<Typography type="bodySmall" fontWeight={400}>
											<MyAccountTicketLabel>Placed on:&nbsp;</MyAccountTicketLabel>
											{toBrowserLocaleString(
												new Date(removeTimezoneFromDateString(props.order.created)),
												{
													//@ts-ignore TODO: TS considers this to be an invalid property when it's not, consider looking into a solution
													dateStyle: "short",
												},
												"date"
											)}
										</Typography>
										<Typography type="bodySmall" fontWeight={400}>
											<MyAccountTicketLabel>Description:</MyAccountTicketLabel>
											<br />
											{props.ticketGroup.tierDescription}
										</Typography>
									</div>
									<div
										css={`
											flex: 1;
										`}
									>
										<Typography type="bodySmall" fontWeight={400}>
											<MyAccountTicketLabel>Seller Notes:</MyAccountTicketLabel>
											<br />
											{props.ticketGroup.notes}
										</Typography>
									</div>
									<div
										css={`
											flex: 2;
										`}
									>
										<Typography type="bodySmall" fontWeight={400}>
											<MyAccountTicketLabel>Delivery:</MyAccountTicketLabel>
											<br />
											{props.deliveryMethod.notes}
										</Typography>
									</div>
								</div>
								{/*TODO: Consider just having a StyledTicketInvoice instead of a TicketInvoiceBadge parent element*/}
								<div
									css={`
										padding-top: 15px;
										display: flex;
										flex-wrap: wrap;

										& > * {
											flex: 1;
										}
									`}
								>
									<div
										css={`
											margin-bottom: 15px;
											min-width: min(500px, 100%);
										`}
									>
										{expandedContent.invoiceGrid}
									</div>
									<div>{expandedContent.invoiceBadge}</div>
								</div>
							</div>
						</StyledExpandableContent>
					}
					ticketControls={
						<TicketControlsContainer>
							{ticketControls.map((control, index) => {
								return (
									<Button
										key={index}
										style={isTablet ? { display: "flex", justifyContent: "start" } : undefined}
										size="small"
										css={`
											width: min(100%, 220px);
											font-family: ${FontFamily.Poppins};
											white-space: nowrap;
											height: 38px;

											@media (max-width: ${breakpoints.tablet}) {
												width: 100%;
												height: 55px;
											}
											${!!control.highlightButton ? highlightButtonStyling : ""}
										`}
										onClick={() => {
											control.onClick();
										}}
									>
										{isTablet ? (
											<div
												css={`
													display: flex;
													align-items: center;
												`}
											>
												<FontAwesomeIcon
													css={`
														height: 32px;
														width: 27px;
														margin-right: 15px;
													`}
													icon={control.icon}
													color={getAppColor("light")}
												/>
												<div
													css={`
														text-align: left;
														max-width: 300px;
														white-space: wrap;
													`}
												>
													<Typography type="bodyTiny" fontWeight={600}>
														{control.text}
													</Typography>
													<Typography
														type="bodyExtraTiny"
														css={`
															white-space: pre-wrap;
														`}
													>
														{control.subText}
													</Typography>
												</div>
											</div>
										) : (
											<>
												<FontAwesomeIcon
													css={`
														height: 15px;
														margin-right: 15px;
													`}
													icon={control.icon}
													color={getAppColor("light")}
												/>
												{control.text}
											</>
										)}
									</Button>
								);
							})}
						</TicketControlsContainer>
					}
				/>
			) : (
				<MyAccountItem
					topContent={
						<TopContent>
							<TicketContentDisplay eventId={props.event.eventId} row={props.ticketGroup.row} section={props.ticketGroup.section} venue={props.event.venue} name={props.event.name} slug={props.event.slug} quantity={props.quantity} availableQuantity={ticketsAmount} occursAt={isExtraSmallScreen ? props.event.occursAt : undefined} />
							{(() => {
								if (orderCancelled) {
									return <OrderCancelled>Order Cancelled</OrderCancelled>;
								}
								switch (props.eventStatus) {
									case TicketTransactionEventStatus.Postponed:
									case TicketTransactionEventStatus.Rescheduled:
										return (
											<EventStatus>
												Event{" "}
												{
													(
														{
															[TicketTransactionEventStatus.Postponed]: "Postponed",
															[TicketTransactionEventStatus.Rescheduled]: "Rescheduled",
														} as Record<TicketTransactionEventStatus, string>
													)[props.eventStatus]
												}
											</EventStatus>
										);
								}
							})()}
						</TopContent>
					}
					bottomContent={
						!orderCancelled && (
							<TitleAndContentGrid
								flexGap={["4px", "18px"]}
								items={(() => {
									const items = [];
									if (!props.isTransfer) {
										items.push({
											key: "ticketGroupNotes",
											title: "Seller Note",
											content: <SantizedHTML htmlString={props.ticketGroup.notes} />,
										});
									}

									if (props.ticketGroup.tierDescription) {
										items.push({
											key: "tierDescription",
											title: "Description",
											content: <SantizedHTML htmlString={props.ticketGroup.tierDescription} />,
										});
									}
									items.push({
										key: "delivery",
										title: "Delivery",
										content: (
											<>
												{props.deliveryMethod.name}
												<br />
												{props.deliveryMethod.notes}
											</>
										),
									});
									return items;
								})()}
							/>
						)
					}
					//TODO: Consider cleaning up the conditional logic below
					controls={
						!orderCancelled &&
						(props.fedexTrackingNumber || props.mobileTransferLink || props.pdfUrl) && (
							<>
								{props.fedexTrackingNumber && (
									<FulfillmentButton ticketTransaction={props} link={"https://www.fedex.com/fedextrack/?tracknumbers=" + props.fedexTrackingNumber} disablePostMyAccountTicketsAccessed>
										Track With FedEx
									</FulfillmentButton>
								)}
								{props.mobileTransferLink && (
									<FulfillmentButton
										//TODO: Test different scenarios to make sure that the forward slashes fully work
										ticketTransaction={props}
										link={props.mobileTransferLink}
									>
										Access Mobile Tickets
									</FulfillmentButton>
								)}
								{props.pdfUrl && (
									<FulfillmentButton
										loading={pdfLoading}
										ticketTransaction={props}
										onClick={async () => {
											//TODO: This if statement is needed for what seems like a TS bug. props.pdfUrl is considered to be string | null in the code below.
											if (props.pdfUrl) {
												setPdfLoading(true);
												//TODO: Feel free to see if there's a better way to generate and display the PDF. Also consider looking into a way to hide "blob" from the generated URL.
												return apiClient
													.get(props.pdfUrl, {
														responseType: "blob",
													})
													.then(result => {
														//TODO: Improve type for result
														const pdf = new Blob([result as unknown as BlobPart], {
															type: "application/pdf",
														});
														if (isMobileApp) {
															saveAs(pdf, "test.pdf");
														} else {
															const hiddenLink = document.createElement("a");
															document.body.appendChild(hiddenLink);
															hiddenLink.style.display = "none";
															hiddenLink.href = URL.createObjectURL(pdf);
															hiddenLink.target = "_blank";
															hiddenLink.click();
															hiddenLink.remove();
														}
													})
													.catch(error => {
														console.error(error);
													})
													.finally(() => {
														setPdfLoading(false);
													});
											}
										}}
									>
										{"View PDF"}
									</FulfillmentButton>
								)}
							</>
						)
					}
					status={(() => {
						if (!orderCancelled && props.deliveryMethod.name !== "Will Call" && props.deliveryMethod.name !== "Guest List") {
							switch (props.deliveryStatus) {
								case TicketTransactionDeliveryStatus.Unshipped:
									if (props.expectedShipDate) {
										const expectedShipDateString = removeTimezoneFromDateString(props.expectedShipDate);
										const expectedShipDate = new Date(expectedShipDateString);
										return (
											"Tickets expected to ship by " +
											toBrowserLocaleString(expectedShipDate, {
												//@ts-ignore TODO: TS considers this to be an invalid property when it's not, consider looking into a solution
												dateStyle: "short",
											})
										);
									} else {
										return "Not shipped";
									}
								case TicketTransactionDeliveryStatus.Shipped:
									return "Shipped";
								case TicketTransactionDeliveryStatus.Delivered:
									return "Delivered";
							}
						}
					})()}
					statusColor={(() => {
						//TODO: DRY Colors
						switch (props.deliveryStatus) {
							case TicketTransactionDeliveryStatus.Shipped:
							case TicketTransactionDeliveryStatus.Delivered:
								return "#0c842b";
						}
					})()}
					expandableContent={
						<ExpandableContent>
							{/*TODO: Consider just having a StyledTicketInvoice instead of a TicketInvoiceBadge parent element*/}
							{expandedContent.invoiceBadge}
							{expandedContent.invoiceGrid}
						</ExpandableContent>
					}
					date={props.event.occursAt}
					id={props.order.id}
					orderInfo={props.order}
					hideBottomContentOnMobile
					isTransferred={props.isTransfer}
				/>
			)}
		</>
	);
};

const shine = keyframes`
  from {
    opacity: 0;
    left: 0%;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
    left: 100%;
  }
`;

// Old color hash (most likely going to return): #ffd700
const highlightButtonStyling = css`
	overflow: hidden;
	box-shadow: 0 0 0 0 transparent;
	transition: all 0.2s ease-in;

	&:hover {
		box-shadow: 0 0 30px 5px #e5e5e5;
		transition: all 0.2s ease-out;

		&:before {
			animation: ${shine} 2s linear infinite;
		}
	}

	&:active {
		box-shadow: 0 0 0 0 transparent;
		transition: box-shadow 0.2s ease-in;
	}

	&:before {
		content: "";
		display: block;
		width: 0px;
		height: 86%;
		position: absolute;
		top: 7%;
		left: 0%;
		opacity: 0;
		background: #e5e5e5;
		box-shadow: 0 0 60px 20px #e5e5e5;
		transform: skewX(-20deg);
	}

	&:after {
		line-height: inherit !important;
	}
`;
const TicketControlsContainer = styled.div`
	padding: 15px 5px 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
	gap: 15px 10px;

	@media (max-width: ${breakpoints.mobile}) {
		align-items: center;
	}
`;

const TopContent = styled.div`
	display: flex;
	flex-direction: column;
`;

const EventStatus = styled.span`
	font-weight: bold;
	font-size: 16px;
	margin-top: 10px;
	color: ${colors.orange};
`;

const OrderCancelled = styled(EventStatus)`
	color: #e92224;
`;

const mobileExpandableContentMediaQuery = mediaQueries.max670;

//TODO: For frontend optization use improved media query system to use min-width instead of max width for code like below
const TicketInvoiceBadge = styled(Badge)`
	//TODO: Consider adding the first two properties as defaults for the base Badge component. Also consider remaing Badge to BaseBadge maybe?
	box-sizing: border-box;
	border-radius: 5px;
	padding: 14px;
	margin-right: 24px;
	min-width: min(100%, 294px);
	@media ${mobileExpandableContentMediaQuery} {
		margin: 0;
		margin-bottom: 14px;
	}
`;

const ExpandableContent = styled.div`
	display: flex;
	align-items: flex-start;
	@media ${mobileExpandableContentMediaQuery} {
		flex-direction: column;
		& > * {
			width: 100%;
			min-width: initial;
		}
	} ;
`;

const WalletBadgeContainer = styled.div`
	margin-top: 10px;
`;

const MyAccountTicketLabel = styled.span`
	font-weight: 700;
`;

const StyledExpandableContent = styled(ExpandableContent)`
	background: #fafafa;
	border: 1px solid #d3d3d3;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	padding: 21px 24px;
`;
