import * as t from "io-ts";
import { DateTime } from "luxon";
import { either } from "fp-ts/lib/Either";

export const DateTimeCodec = new t.Type(
	"DateTime",
	(u): u is Date => u instanceof Date,
	(i, c) =>
		either.chain(t.string.validate(i, c), str => {
			/**
			 * The api returns the local time of the event as a UTC date.
			 * Since we don't know the real timezone, we just ignore it
			 */
			let date = DateTime.fromFormat(str, "yyyy-MM-dd'T'HH:mm:ss'.000000Z'");

			// try alternative date and datetime format
			/*
				TODO: Search if there's a way to merge these ifs by doing something like:  
				date = DateTime.fromFormat(str, "yyyy-MM-dd'T'HH:mm:ss'Z'") || DateTime.fromFormat(str, "yyyy-MM-dd HH:mm:ss'+00'");
			*/
			if (!date.isValid) {
				date = DateTime.fromFormat(str, "yyyy-MM-dd'T'HH:mm:ss'Z'");
			}
			if (!date.isValid) {
				date = DateTime.fromFormat(str, "yyyy-MM-dd HH:mm:ss'+00'");
			}
			if (!date.isValid) {
				date = DateTime.fromFormat(str, "yyyy-MM-dd HH:mm:ss");
			}
			if (!date.isValid) {
				date = DateTime.fromFormat(str, "yyyy-MM-dd");
			}

			if (date.isValid) {
				return t.success(date.toJSDate());
			} else {
				return t.failure(i, c, date.invalidReason + ": " + date.invalidExplanation);
			}
		}),
	a => DateTime.fromJSDate(a).toISODate()
);
