import React from "react";
import styled from "styled-components/macro";
import { mediaQueries } from "../../util/mediaQueries";
import { CollapseArrow } from "../CollapseArrow/CollapseArrow";

export const NavControls = ({ onPreviousClick, onNextClick }: { onPreviousClick: () => void; onNextClick: () => void }) => {
	return (
		<ControlsContainer>
			<StyledCollapseArrow
				weight={2}
				size={20}
				direction={"left"}
				onClick={() => {
					onPreviousClick();
				}}
			/>
			<StyledCollapseArrow
				weight={2}
				size={20}
				direction={"right"}
				onClick={() => {
					onNextClick();
				}}
			/>
		</ControlsContainer>
	);
};

const ControlsContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	position: absolute;
	top: 55%;
`;

const StyledCollapseArrow = styled(CollapseArrow)<{
	direction: "left" | "right";
}>`
	transform: rotate(${props => (props.direction === "left" ? "" : "-")}90deg);
	@media ${mediaQueries.max480} {
		transform: scale(0.8) rotate(${props => (props.direction === "left" ? "" : "-")}90deg);
	}
`;
