import React, { InputHTMLAttributes, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components/macro";
import { usePopper } from "react-popper";
import { useMainSearch } from "../Search/useMainSearch";
import { MainSearchResults } from "../Search/MainSearchResults";
import lens_icon from "../../resource/img/icons/lens_icon.svg";
import { getTypographyStyles } from "../Typography/Typography";
import { getAppColor } from "../../util/appColors";
import { FieldRenderProps } from "react-final-form";
import clearIcon from "../../resource/image/close-icon.svg";
import Cleave from "cleave.js/react";
import { CleaveOptions } from "cleave.js/options";
import { useMemoState } from "../../hooks/useMemoState";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	error?: string;
	clearable?: boolean;
	onClear?: () => void;
	cleaveOptions?: CleaveOptions;
	containerStyle?: any;
	containerClassName?: string;
	untouchedMessage?: string;
	rightIcon?: React.ReactNode;
	leftIcon?: React.ReactNode;
}

const MAIN_SEARCH_CONFIG = {
	debounceSearchMs: 200,
	minCharactersSearch: 3,
};

export const GeneralSearch = (props: { hide?: boolean }) => {
	const { events, venues, performers, categories, shouldShowResult, searchText, setSearchText, popularSubcategories, recentGenericSearches, recentEventSearches } = useMainSearch(null, MAIN_SEARCH_CONFIG);
	const [focused, setFocused] = useState(false);
	const [show, setShow] = useState(false);
	const referenceRef = useRef<any>(null);
	const popperRef = useRef<any>(null);

	const { styles, attributes } = usePopper(referenceRef.current, popperRef.current, {
		placement: "bottom-start",
		modifiers: [
			{
				name: "offset",
				enabled: true,
				options: {
					offset: [0, 10],
				},
			},
		],
	});

	const wrapperStyles = {
		maxWidth: "500px",
		width: "100%",
	};

	const [mainSearchVisible, setMainSearchVisible] = useState(false);
	const [initialSearchVisible, setInitialSearchVisible] = useState(false);
	const [initialSearchHasBeenDisplayed, setInitialSearchHasBeenDisplayed] = useState(false);

	useEffect(() => {
		if (focused || show) {
			switch (true) {
				case shouldShowResult:
					setMainSearchVisible(true);
					break;
				case !searchText.length && !initialSearchHasBeenDisplayed:
					setInitialSearchVisible(true);
					setInitialSearchHasBeenDisplayed(true);
					break;
				case !!searchText.length:
					setInitialSearchVisible(false);
					break;
			}
		} else {
			setMainSearchVisible(false);
			setInitialSearchVisible(false);
			setInitialSearchHasBeenDisplayed(false);
		}
	}, [focused, show, searchText, shouldShowResult]);

	if (props.hide || false) {
		return null;
	}

	return (
		<>
			<Input
				css={`
					${getTypographyStyles("bodySmall")}
				`}
				ref={referenceRef}
				clearable={focused}
				value={searchText}
				placeholder={"Search by team, artist, event or venue"}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
				onClear={() => setSearchText("")}
				onChange={e => setSearchText(e.target.value)}
				rightIcon={<img src={lens_icon} alt="search icon" />}
				style={{ border: `2px solid ${focused ? "#5F6368" : "#E5E5E5"}` }}
				containerClassName="search-container"
				className="search-input"
			/>
			<div ref={popperRef} style={{ ...styles.popper, ...wrapperStyles }} {...attributes.popper} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
				<MenuContainer style={styles.offset} visible={!!initialSearchVisible}>
					<StyledMainSearchResults popularSubcategories={popularSubcategories} recentEventSearches={recentEventSearches} recentGenericSearches={recentGenericSearches} showSuggestions={false} />
				</MenuContainer>
				<MenuContainer style={styles.offset} visible={mainSearchVisible}>
					<StyledMainSearchResults events={events} performers={performers} categories={categories} venues={venues} showSuggestions={false} />
				</MenuContainer>
			</div>
		</>
	);
};

const StyledMainSearchResults = styled(MainSearchResults)`
	width: 100%;
	max-height: 80vh;
	overflow-y: auto;
`;

const MenuContainer = styled.div<{ visible: boolean }>`
	display: ${props => (props.visible ? "flex" : "none")};
	overflow: hidden;
	flex-direction: row;
	background: #ffffff;
	box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.15);
	color: #707372;
	border-bottom-left-radius: ${props => (props.visible ? "4px" : "0px")};
	border-bottom-right-radius: ${props => (props.visible ? "4px" : "0px")};
	margin: 0 20px;
`;

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ error, leftIcon, rightIcon, clearable, onClear, cleaveOptions, containerStyle, containerClassName, untouchedMessage, ...inputProps }, ref: any) => {
	const [touched, setTouched] = useState(false);

	const errorVisible = useMemoState(() => {
		const value = !!error && touched;
		return value;
	}, [error, touched]);

	return (
		<Container leftIcon={!!leftIcon} rightIcon={!!rightIcon} clearable={!!clearable} style={containerStyle} className={errorVisible ? `${containerClassName} error` : containerClassName}>
			{clearable && (
				<Clear onMouseDown={onClear}>
					<CloseButtonContainer>
						<img src={clearIcon} alt="" />
					</CloseButtonContainer>
				</Clear>
			)}
			{cleaveOptions ? (
				<Cleave
					{...inputProps}
					options={cleaveOptions}
					htmlRef={htmlRef => {
						ref = htmlRef;
					}}
					//TODO: See if there's a way to DRY onBlur for <Cleave /> and <input />. Some strange state stuff was happening when attempting to do so before.
					onBlur={event => {
						setTouched(true);
						if (inputProps.onBlur) {
							inputProps.onBlur(event);
						}
					}}
				/>
			) : (
				<input
					ref={ref}
					{...inputProps}
					onBlur={event => {
						setTouched(true);
						if (inputProps.onBlur) {
							inputProps.onBlur(event);
						}
					}}
				/>
			)}
			<BottomMessage visible={!errorVisible && !touched}>{untouchedMessage}</BottomMessage>
			<InputError visible={errorVisible}>{error}</InputError>
			{rightIcon && <Icon>{rightIcon}</Icon>}
		</Container>
	);
});

export const RFFInput = ({ input, meta, ...rest }: FieldRenderProps<string>) => {
	return <Input {...input} {...rest} error={meta.error} />;
};

export const StyledRFFInput = styled(RFFInput)`
	border: 1px solid ${getAppColor("darkGrey", "light")};
	border-radius: 0;
	height: 50px;
	padding-left: 10px !important;
	-webkit-appearance: none;
	background: ${getAppColor("light")};
	${getTypographyStyles("bodyNormal")};
	&:hover {
		box-shadow: none;
	}
`;

const CloseButtonContainer = styled.div`
	align-self: center;
`;

const bottomMarginValue = 23;
const bottomMargin = bottomMarginValue + "px";

export const InputTransitionStyles = css({
	"transition-duration": "0.2s",
	"transition-timing-function": "ease",
});

const errorLeft = "10px";

const Icon = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	padding: 0px 11px;
	height: 100%;
	display: flex;
	align-items: center;

	img {
		width: 20px;
		height: 80%;
		padding-left: 10px;
		border-left: 1px solid ${getAppColor("darkGrey", "light")};
	}
`;

const Clear = styled.div`
	color: #e92224 !important;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: auto;
	display: flex;
	padding: 0 15px;
	cursor: pointer;
`;

const BottomMessage = styled.span<{
	visible?: boolean;
}>`
	${getTypographyStyles("bodyTiny")}
	color: ${getAppColor("darkGrey", "dark")} !important;
	margin: 0 !important;
	position: absolute;
	bottom: -10px;
	left: ${errorLeft};
	opacity: 0;
	transition-property: bottom, opacity;
	white-space: nowrap;
	max-width: calc(100% - ${errorLeft});
	overflow: hidden;
	text-overflow: ellipsis;
	${InputTransitionStyles}
	${props =>
		props.visible &&
		`
    bottom: -${bottomMarginValue * 0.826}px;
    opacity: 1;
  `}
`;

const InputError = styled(BottomMessage)`
	color: #e92224 !important;
`;

const InputErrorStyles = {
	color: "#e92224",
	"border-color": "#e92224",
};

const Container = styled.div<{
	leftIcon?: boolean;
	rightIcon?: boolean;
	clearable?: boolean;
}>`
	position: relative;
	margin-bottom: ${bottomMargin};
	overflow: hidden;
	input {
		margin: 0;
		width: 100%;
		transition-property: border-color, color;
		padding-left: ${props => (props.clearable ? "35px" : "0")};
		${InputTransitionStyles}
	}
	[placeholder] {
		text-overflow: ellipsis;
		padding-right: ${props => (props.rightIcon ? "50px" : "8px")};
		&:not(:focus) {
			padding-left: 10px;
		}
	}
	::-webkit-input-placeholder {
		text-overflow: ellipsis;
	}
	::-moz-placeholder {
		text-overflow: ellipsis;
	}
	:-ms-input-placeholder {
		text-overflow: ellipsis;
	}
	:-moz-placeholder {
		text-overflow: ellipsis;
	}
	&.error {
		input {
			${InputErrorStyles}
		}
	}
`;
