import { Reducer } from "redux";
import { EventSearchItem, SearchItem } from "../../services/types/MainSearchResponseCodec";

interface SearchState {
	recentGenericSearches: SearchItem[];
	recentEventSearches: EventSearchItem[];
}

const defaultState: SearchState = {
	recentGenericSearches: [],
	recentEventSearches: [],
};

//TODO: Make this logic DRY (as presently written the exact logic flow is duplicated for generic and event searches)

export const searchReducer: Reducer<SearchState, SearchActions> = (state: SearchState = defaultState, action: SearchActions) => {
	switch (action.type) {
		case "ADD_RECENT_GENERIC_SEARCH":
			const recentGenericSearchIndex = state.recentGenericSearches.findIndex(recentSearch => recentSearch.name === action.searchItem.name);
			if (recentGenericSearchIndex !== -1) {
				const recentGenericSearches = state.recentGenericSearches;
				const duplicateItem = recentGenericSearches[recentGenericSearchIndex];
				recentGenericSearches.splice(recentGenericSearchIndex, 1);
				recentGenericSearches.splice(0, 0, duplicateItem);
				return {
					...state,
					recentGenericSearches: recentGenericSearches.slice(0, 5),
				};
			} else {
				return {
					...state,
					recentGenericSearches: [action.searchItem, ...state.recentGenericSearches].slice(0, 5),
				};
			}
		case "ADD_RECENT_EVENT_SEARCH":
			const recentEventSearchIndex = state.recentEventSearches.findIndex(recentSearch => recentSearch === action.eventSearchItem);
			if (recentEventSearchIndex !== -1) {
				const recentEventSearches = state.recentEventSearches;
				const duplicateItem = recentEventSearches[recentEventSearchIndex];
				recentEventSearches.splice(recentEventSearchIndex, 1);
				recentEventSearches.splice(0, 0, duplicateItem);

				return {
					...state,
					recentEventSearches: recentEventSearches.slice(0, 5),
				};
			} else {
				return {
					...state,
					recentEventSearches: [action.eventSearchItem, ...state.recentEventSearches].slice(0, 5),
				};
			}
		default:
			return state;
	}
};

export const addRecentGenericSearch = (searchItem: SearchItem): SearchActions => ({
	type: "ADD_RECENT_GENERIC_SEARCH",
	searchItem,
});

export const addRecentEventSearch = (eventSearchItem: EventSearchItem): SearchActions => ({
	type: "ADD_RECENT_EVENT_SEARCH",
	eventSearchItem,
});

export type SearchActions =
	| {
			type: "ADD_RECENT_GENERIC_SEARCH";
			searchItem: SearchItem;
	  }
	| {
			type: "ADD_RECENT_EVENT_SEARCH";
			eventSearchItem: EventSearchItem;
	  };
