import React, { HTMLAttributes, useContext, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { Spinner } from "../Loader/Spinner";
import { HomeSearchSelectedContext } from "../../routes/Home";
import { SearchState } from "./SearchState";
import { borderRadius } from "./styles";
import { isMobileApp } from "../../util/isMobileApp";
import { useHistory } from "react-router-dom";
import { useIsMobileWeb } from "../../hooks/useIsMobileWeb";
import { InternalRoutes } from "../../Link";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";
import { getTypographyStyles } from "../Typography/Typography";
import { getAppColor } from "../../util/appColors";

interface MainSearchBarProps extends HTMLAttributes<HTMLDivElement>, StyledComponentProps {
	isLoading: boolean;
	searchText: string;
	setSearchText: (value: string) => void;
	searchState?: SearchState;
	placeholder?: string;
	hasSearchResults: boolean;
}

export const MainSearchBar = ({ isLoading, searchText, setSearchText, children, searchState, placeholder, hasSearchResults, ...rest }: React.PropsWithChildren<MainSearchBarProps>) => {
	const { homeSearchSelected, setHomeSearchSelected } = useContext(HomeSearchSelectedContext);
	const history = useHistory();
	const autofocusSearch = history.location.pathname === InternalRoutes.Search;

	const [isSearchBarBottomRounded, setIsSearchBarBottomRounded] = React.useState(false);

	useEffect(() => {
		if ((hasSearchResults && searchState === SearchState.Main) || searchState === SearchState.Initial) {
			setIsSearchBarBottomRounded(true);
		} else {
			setIsSearchBarBottomRounded(false);
		}
	}, [hasSearchResults, searchState]);

	const mainSearchInput = useRef<HTMLInputElement>(null);

	useEffect(() => {
		const mainSearchInputSelected = mainSearchInput.current?.id === document.activeElement?.id;
		if (mainSearchInputSelected) {
			if (!homeSearchSelected) {
				mainSearchInput.current?.blur();
			}
		} else if (homeSearchSelected && searchState !== SearchState.Location) {
			mainSearchInput.current?.focus();
		}
	}, [homeSearchSelected]);

	const isMobile = useIsMobileWeb() || isMobileApp;

	useEffect(() => {
		if (autofocusSearch) mainSearchInput?.current?.focus();
	}, []);

	return (
		<PredictiveSearchContainer
			{...rest}
			isMobile={isMobile}
			isSearchBarBottomRounded={isSearchBarBottomRounded}
			onFocus={e => {
				setHomeSearchSelected(true);
			}}
		>
			<MainSearchInputWrapper isMobile={isMobile}>
				<MainSearchInput
					id="main-search"
					autoCapitalize="none"
					autoComplete="off"
					autoCorrect="off"
					name="search"
					type="text"
					spellCheck="false"
					aria-label="Search"
					aria-autocomplete="list"
					role="combobox"
					ref={mainSearchInput}
					placeholder={placeholder ? placeholder : "Search by team, artist, event or venue"}
					value={searchText}
					onFocus={e => {
						if (typeof rest.onFocus === "function") {
							rest.onFocus(e);
						}
					}}
					onChange={event => setSearchText(event.target.value)}
					autoFocus={autofocusSearch}
					isMobile={isMobile}
				/>
			</MainSearchInputWrapper>
			{isLoading && !isMobile ? (
				<SpinnerContainer isVisible={isLoading}>
					<Spinner />
				</SpinnerContainer>
			) : null}
			{children}
		</PredictiveSearchContainer>
	);
};

const MainSearchInputWrapper = styled.div<{ isMobile: boolean }>`
	border-radius: ${borderRadius} 0 0 ${borderRadius};
	flex: 1;
	overflow: hidden;
	order: 1;
	height: ${props => (props.isMobile ? "none" : "40px")};
`;

const PredictiveSearchContainer = styled.div<{ isMobile: boolean; isSearchBarBottomRounded?: boolean }>`
	display: flex;
	font-size: 15px;
	background: white;
	min-height: fit-content;
	align-items: center;
	height: 100%;
	border-radius: ${props => (props.isSearchBarBottomRounded ? "4px 4px 0 0" : "4px")};
`;

const SpinnerContainer = styled.div<{ isVisible: boolean }>`
	background: #ffffff;
	display: flex;
	visibility: ${props => (props.isVisible ? "initial" : "hidden")};
	align-items: center;
	padding: 0px 10px;
	order: 2;
`;

const MainSearchInput = styled.input<{ isMobile: boolean }>`
	padding: ${props => (props.isMobile ? "15px 15px 15px 0" : "5px 5px 5px 0")};
	margin: 0;
	border: 0;
	height: ${props => (props.isMobile ? "none" : "40px")};
	${getTypographyStyles("heading3", {
		color: "dark",
	})}

	::placeholder {
		color: ${getAppColor("darkGrey")};
	}
`;
