import React, { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import styled from "styled-components/macro";
import arrow from "../../resource/image/arrow-control.svg";
import { autoPlay } from "react-swipeable-views-utils";
import { getAppColor } from "../../util/appColors";
import { useAutoUpdateState } from "../../hooks/useAutoUpdateState";
import { ArrowControl } from "./ArrowControl";

export const Carousel = ({
	children,
	hideIndicators,
	hideArrows,
	onSlideChange,
	...spreadableSwipeableViewsProps
}: Partial<Pick<React.ComponentProps<typeof StyledAutoPlaySwipeableViews>, "animateHeight" | "autoplay" | "direction" | "interval" | "disabled" | "index">> & {
	hideIndicators?: boolean;
	hideArrows?: boolean;
	children: React.ReactNode | React.ReactNode[];
	onSlideChange?: (currentSlide: number) => void;
}) => {
	const [currentIndex, setCurrentIndex] = useState(0);

	const hideAllControls = useAutoUpdateState(() => {
		return !Array.isArray(children) || children.length < 2;
	}, [children]);

	useEffect(() => {
		if (onSlideChange) {
			onSlideChange(currentIndex);
		}
	}, [currentIndex]);

	return (
		<SliderWrapper>
			{/*TODO: Implement this component so it doesn't depend on an external package*/}
			{children && (
				<StyledAutoPlaySwipeableViews
					{...spreadableSwipeableViewsProps}
					index={spreadableSwipeableViewsProps.index ? spreadableSwipeableViewsProps.index : currentIndex} // If the index is forced from outside, we give it priority
					onChangeIndex={event => {
						setCurrentIndex(event);
					}}
				>
					{children}
				</StyledAutoPlaySwipeableViews>
			)}
			{!hideAllControls && !hideArrows && (
				<>
					<ArrowControl
						src={arrow}
						alt="Left Control"
						direction="left"
						disabled={currentIndex === 0}
						onClick={() => {
							if (currentIndex > 0) {
								setCurrentIndex(currentIndex - 1);
							} else {
								//TODO: Find a better way to do the following check. This was added because ReactNode[] doesn't support single element arrays
								setCurrentIndex(Array.isArray(children) ? children.length - 1 : 0);
							}
						}}
					/>
					<ArrowControl
						src={arrow}
						alt="Right Control"
						direction="right"
						disabled={currentIndex === (Array.isArray(children) && children.length - 1)}
						onClick={() => {
							if (Array.isArray(children) && currentIndex < children.length - 1) {
								setCurrentIndex(currentIndex + 1);
							} else {
								setCurrentIndex(0);
							}
						}}
					/>
				</>
			)}
			{!hideAllControls && !hideIndicators && (
				<IndicatorsContainer>
					<Indicators>
						{Array.isArray(children) &&
							children.map((child, index) => {
								return (
									<Indicator
										key={index}
										active={index === currentIndex}
										onClick={() => {
											setCurrentIndex(index);
										}}
									/>
								);
							})}
					</Indicators>
				</IndicatorsContainer>
			)}
		</SliderWrapper>
	);
};

const SliderWrapper = styled.div`
	position: relative;
`;

const StyledAutoPlaySwipeableViews = styled(autoPlay(SwipeableViews))`
	.react-swipeable-view-container {
		div {
			overflow: hidden !important;
		}
		img {
			width: 100%;
			height: 100%;
		}
	}
`;

const IndicatorsContainer = styled.div`
	justify-content: center;
	display: flex;
	align-items: center;
	width: 100%;
	bottom: 10%;
	position: absolute;
`;

const Indicators = styled.div`
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	div:not(:last-child) {
		margin-right: 12px;
	}
`;

const Indicator = styled.div<{ active: boolean }>`
	height: 12px;
	width: 12px;
	border-radius: 12px;
	cursor: pointer;
	background-color: ${props => (props.active ? getAppColor("primary") : getAppColor("darkGrey", "light"))};
`;
