import React from "react";
import styled, { CSSProp } from "styled-components/macro";
import { getTypographyStyles } from "../Typography/Typography";
import { getAppColor } from "../../util/appColors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

export const TicketPurchaseInfo = (props: { icon?: string; title: string; content?: React.ReactNode; additionalData?: string; onClick?: () => void; textStyles?: CSSProp }) => {
	return (
		<Container>
			{props.icon && (
				<IconContainer>
					<Icon src={props.icon} alt="icon" />
				</IconContainer>
			)}
			<RightSection
				onClick={props.onClick}
				css={`
					${props.textStyles}
					${props.onClick &&
					`	&, & * {
							color: ${getAppColor("primary")} !important;
							text-decoration: underline;
							cursor: pointer;
						}`}
				`}
			>
				<Title>
					{props.title}
					{props.onClick && (
						<FontAwesomeIcon
							icon={faChevronRight}
							css={`
								margin-left: 6px;
							`}
						/>
					)}
				</Title>
				{props.content && <Content>{props.content}</Content>}
				<Content>
					<strong>{props.additionalData && props.additionalData}</strong>
				</Content>
			</RightSection>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	margin: 11px 0;
`;

const IconContainer = styled.div`
	display: flex;
	align-items: flex-start;
	margin-right: 14px;
`;

const RightSection = styled.div`
	display: flex;
	flex-direction: column;
`;

const Title = styled.div`
	${getTypographyStyles("bodyNormal")};
	font-weight: 600;
	text-align: left;
	margin-top: 2px;
`;

const Content = styled.div`
	${getTypographyStyles("bodySmall")};
	font-weight: 300;
	text-align: left;
`;

const Icon = styled.img`
	width: 20px;
	height: 22px;
`;
