import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components/macro";
import { InternalRoutes } from "../../Link";
import { maxContentWidthStyles } from "../../util/maxContentWidth";
import { cardBottomMargin } from "./MyAccountCard";
import { MobileHeader } from "./MyAccountHeader";
import { useHeaderHeight } from "../../hooks/useHeaderHeight";
import { isMobileApp } from "../../util/isMobileApp";

export const MyAccountPage = (props: { columns: React.ReactNode[]; mobileTitle: string }) => {
	const history = useHistory();

	const onBackClick = () => {
		history.replace(InternalRoutes.MyAccount());
	};

	const headerHeight = useHeaderHeight();

	return (
		<div>
			<MobileHeader title={props.mobileTitle} onBackClick={onBackClick} />
			<ContentContainer
				style={{
					...(isMobileApp && {
						marginBottom: headerHeight,
					}),
				}}
			>
				{props.columns.map((column, index) => (
					<div key={index}>{column}</div>
				))}
			</ContentContainer>
		</div>
	);
};

const switchToColumnWidth = "890px";
const topBottomPadding = "20px";

const ContentContainer = styled.div`
	display: flex;
	width: 100%;

	${maxContentWidthStyles}
	padding-top: ${topBottomPadding};
	padding-bottom: ${topBottomPadding};

	h1 {
		color: #000000;
		font-weight: 700;
		font-size: 20px;
		font-family: "Avenir", sans-serif;
		text-align: left;
	}
	@media (max-width: ${switchToColumnWidth}) {
		flex-direction: column;
	}
	& > div {
		display: flex;
		flex-direction: column;
		flex: 1;
		@media (max-width: ${switchToColumnWidth}) {
			width: 100%;
			&:not(:last-child) {
				margin-bottom: ${cardBottomMargin};
			}
		}
		&:not(:last-child) {
			margin-right: 23px;
		}
	}
`;
