import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";
import { useBraintree } from "../../../services/hooks/useBraintree";
import { Button } from "../Button/Button";
import { Spinner } from "../Loader/Spinner";
import { PayPalButton } from "../Checkout/PayPalButton";
import { VenmoButton } from "../Checkout/VenmoButton";
import { PaymentMethodType, PaymentMethodWithToken } from "../../../model/optimizedModel/braintree";
import { getAndDispatchBraintreeClientToken } from "../../../services/braintree";
import { NewCreditCardFormDialog } from "../Forms/NewCreditCardForm";

export const AddPaymentMethod = (props: { paymentMethodAdded?: (payload: PaymentMethodWithToken) => void; hiddenPaymentMethodTypes?: PaymentMethodType[] }) => {
	const { braintreeState } = useBraintree();

	const [newCreditCardDialogOpen, setNewCreditCardDialogOpen] = useState(false);

	const storedPaymentMethods = useMemo(() => {
		return braintreeState.paymentMethods;
	}, [braintreeState.paymentMethods]);

	const [payPalButtonLoading, setPayPalButtonLoading] = useState(true);

	const loadingValue = useMemo(() => {
		return !braintreeState.clientAuth || !storedPaymentMethods || (payPalButtonLoading && !props.hiddenPaymentMethodTypes?.includes("PayPalAccount")) || (braintreeState.venmoSupported === undefined && !props.hiddenPaymentMethodTypes?.includes("VenmoAccount"));
	}, [storedPaymentMethods, braintreeState.venmoSupported, braintreeState.clientAuth, payPalButtonLoading, props.hiddenPaymentMethodTypes?.includes("PayPalAccount"), props.hiddenPaymentMethodTypes?.includes("VenmoAccount")]);

	const [loading, setLoading] = useState(loadingValue);

	useEffect(() => {
		if (loadingValue !== loading) {
			setLoading(loadingValue);
		}
	}, [loadingValue]);

	return (
		<>
			<NewCreditCardFormDialog
				open={newCreditCardDialogOpen}
				onClose={() => {
					setNewCreditCardDialogOpen(false);
				}}
				onCardAdded={payload => {
					setNewCreditCardDialogOpen(false);
					if (props.paymentMethodAdded) {
						props.paymentMethodAdded(payload);
					}
				}}
			/>
			<Content>
				{loading && <Spinner />}
				<NewPaymentMethods
					style={{
						...(loading && {
							display: "none",
						}),
					}}
				>
					{!props.hiddenPaymentMethodTypes?.includes("VenmoAccount") && braintreeState.venmoSupported && <VenmoButton paymentMethodAdded={props.paymentMethodAdded} />}
					{!props.hiddenPaymentMethodTypes?.includes("PayPalAccount") && (
						<PayPalButton
							onComponentReady={() => {
								setPayPalButtonLoading(false);
							}}
							paymentMethodAdded={paymentMethod => {
								if (props.paymentMethodAdded) {
									props.paymentMethodAdded(paymentMethod);
								}
								//TODO: Consider including this logic directly in the PayPalButton component instead.
								getAndDispatchBraintreeClientToken();
							}}
							flow="vault"
						/>
					)}
					{!props.hiddenPaymentMethodTypes?.includes("CreditCard") && (
						<CreditCardButton
							onClick={() => {
								setNewCreditCardDialogOpen(true);
							}}
						>
							Credit Card
						</CreditCardButton>
					)}
				</NewPaymentMethods>
			</Content>
		</>
	);
};

const CreditCardButton = styled(Button)`
	width: 100%;
	border-radius: 3px;
`;

const Content = styled.div`
	width: 320px;
	max-width: 85vw;
`;

const NewPaymentMethods = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	& > * {
		&:not(:last-child) {
			margin-bottom: 17px;
		}
	}
`;
