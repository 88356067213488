import React from "react";
import { ModalPopup } from "../components/ModalPopup/ModalPopup";
import { ModalBackButton } from "../components/Modal/ModalBackButton";
import styled from "styled-components/macro";
import { MetaDecorator, MetaTagData } from "../components/SEOMetadecorator/MetaDecorator";
import { Link } from "../Link";

export const PrivacyPolicyModalRoute = () => {
	return (
		<ModalPopup backgroundColor="#F7F7F7" leftHeaderElements={<ModalBackButton />} centerHeaderElements="Privacy Policy" hideLogo={true}>
			<MetaDecorator title={MetaTagData.Privacy.title} description={MetaTagData.Privacy.description} />
			<Section>
				<Warning>You are now leaving the app to view our Privacy Policy in your web browser</Warning>
				<ButtonContainer>
					<ButtonLink to="https://sitickets.com/privacy" target="_blank">
						Continue
					</ButtonLink>
				</ButtonContainer>
			</Section>
		</ModalPopup>
	);
};

export const TermsAndConditionsModalRoute = () => {
	return (
		<ModalPopup backgroundColor="#F7F7F7" leftHeaderElements={<ModalBackButton />} centerHeaderElements="Terms Of Use" hideLogo={true}>
			<MetaDecorator title={MetaTagData.Terms.title} description={MetaTagData.Terms.description} />
			<Section>
				<Warning>You are now leaving the app to view our Terms Of Use in your web browser</Warning>
				<ButtonContainer>
					<ButtonLink to="https://sitickets.com/terms" target="_blank">
						Continue
					</ButtonLink>
				</ButtonContainer>
			</Section>
		</ModalPopup>
	);
};

const ButtonContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-around;
`;

export const ButtonLink = styled(Link)`
	width: initial !important;
	height: initial !important;
	padding: 13px 35px;
	font-size: 12px !important;
	border-radius: 4px !important;
	font-weight: bold !important;
	white-space: nowrap;
	svg {
		margin-right: 7px;
	}
	text-decoration: none;
	background-color: #12284b;
	color: #fff;
	border-top: 1px solid #cccccc;
	border-right: 1px solid #333333;
	border-bottom: 1px solid #333333;
	border-left: 1px solid #cccccc;
`;

const Section = styled.div`
	max-width: 88ch;
	margin-bottom: 20px;
`;

const Warning = styled.h3`
	font-size: 1.25rem;
	color: #000;
	text-align: center;
	margin-top: 8%;
	margin-bottom: 38px;
	line-height: 18px;
`;
