import React from "react";
import { useSelector } from "react-redux";

export type PropsWithAuth<Props = {}> = {
	authenticated: boolean;
} & Props;

/**
 * With Authentication
 * HOC which passes the session prop into a wrapped component
 * and listen for status changes in authentication.
 */
export function withAuthentication<Props>(Child: React.ComponentType<PropsWithAuth<Props>>) {
	return function (props: Props): React.ReactElement<Props> {
		const session = useSelector(state => state.persistent.session);
		// 👇 pass down props to change the route depending on the current status
		return <Child {...{ ...props, authenticated: !!session.sessionToken }} />;
	};
}
