import React, { useState } from "react";
import { CommonPage } from "../components";
import { maxContentWidthStyles } from "../util/maxContentWidth";
import styled from "styled-components/macro";
import { RouteComponentProps } from "react-router";
import { MetaDecorator, MetaTagData } from "../components/SEOMetadecorator/MetaDecorator";
import { CollapseBehavior, EducationalRow } from "../components/Futures/EducationalPanel";
import { Nulleable } from "../../model/Utils";
import { mediaQueries } from "../util/mediaQueries";
import { getAppColor } from "../util/appColors";

const fullwidth = false;
const collapseBehavior: CollapseBehavior = "header";

export const TermsRoute = (props: RouteComponentProps) => {
	const [selected, setSelection] = useState<Nulleable<string>>(null);
	const sectionProps = { selected, setSelection, fullwidth, collapseBehavior };

	return (
		<CommonPage isSitixLogoSelected={true} background="#fff">
			<MetaDecorator title={MetaTagData.Terms.title} description={MetaTagData.Terms.description} />
			<PageContainer>
				<Section>
					<Title>Terms Of Use</Title>
					<Date>Last Updated: Feb, 23 2022</Date>
					<Text>SI Tickets is a digital platform and secondary ticketing marketplace (“Services”) that connects sellers and buyers of event tickets, including but not limited to, sporting events, concerts, theater and virtual events (“Tickets”). SI Tickets may make these Services available via it our website (www.sitickets.com), our mobile application (SI Tickets), and/or associated services (collectively, the “Site”). By accessing or using our Site and/or by using our Services, you agree to be bound by the terms of this Terms Of Use and all other policies applicable to your use of the Site (collectively, the “Additional Policies”) each of which are incorporated by reference into this Terms Of Use, as well as all applicable laws, ordinances, and regulations. You represent that you are legally able to enter into a binding contract. If you are under the age of 18, then you may only use the Site in conjunction with, and under the supervision of, a parent or guardian.</Text>
					<Text>SI Tickets reserves the right, in its sole discretion, to change these Terms of Use at any time. If SI Tickets changes any term or condition, said modification, revision and additional information shall be posted here and shall automatically replace the Terms of Use and become binding on all users of the Site. Your continued use of the Site following SI Tickets’ posting of revised Terms of Use constitute your acceptance of the revised agreement.</Text>
					<Text>The revised Terms Of Use will become effective upon publication on the governing location of these terms which is on www.sitickets.com. The update to the terms that are published in the mobile app might be delayed to the need for review by third party (such as Apple and Google) and therefore the governing version of the terms of this agreement would be found on www.sitickets.com. Your continued use of our Site and our Services will constitute acceptance of the revised Terms Of Use.</Text>

					<Text uppercase>
						<Strong>NOTICE OF ARBITRATION AGREEMENT</Strong>
						<br />
						FOR ALL USERS RESIDING IN THE UNITED STATES AND CANADA PLEASE BE ADVISED: SECTION 15 OF THIS AGREEMENT CONTAINS AN ARBITRATION AGREEMENT, WHICH WILL, WITH LIMITED EXCEPTIONS, REQUIRE YOU TO SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING AND FINAL ARBITRATION, YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST SI TICKETS ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, AND YOU WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS.
					</Text>

					<EducationalSectionContainer fullwidth={fullwidth}>
						<EducationalRow title="1. SI Tickets Marketplace" {...sectionProps}>
							<ItemContent>
								<ItemNumber>1.1</ItemNumber>
								<ItemText>Ticket Marketplace. SI Tickets is a ticket marketplace that offers tickets and postseason ticket reservations (“Reservations”). SI Tickets is an intermediary between the seller and the buyer, and SI Tickets neither owns nor sets the price of Tickets. Rather, the sellers own and set the prices for the Tickets listed on our Site. Because SI Tickets is a secondary ticket marketplace, prices may exceed the “Face Value” of the Ticket. The “Face Value” is the original price of the ticket (exclusive of any additional fees or costs the seller may encounter in obtaining the Tickets). SI Tickets does set the price for Reservations and is responsible for the fulfillment of the tickets to owners of ticket reservations whose team has qualified for the game.</ItemText>
							</ItemContent>

							<ItemContent>
								<ItemNumber>1.2</ItemNumber>
								<ItemText>Informational Only. The information SI Tickets provides on our Site, such as pricing, and method, and other guidance on the Site, is solely informational and SI Ticket does not warrant the accuracy of the information the seller provides. Also, while we provide the SI Tickets Fan Guarantee, SI Tickets has no control over and does not guarantee the existence, quality, safety or legality of the Tickets; the truth or accuracy of the sellers content or ticket listings (a “Listing” or “Listings” is defined as a single or group of Tickets or Reservations listed for sale on the Site); the ability of sellers to sell tickets or Reservations or Buyers to pay for them; or that a Buyer or seller will complete a transaction.</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="2. Your SI Tickets Account" {...sectionProps}>
							<ItemContent>
								<ItemNumber>2.1</ItemNumber>
								<ItemText>
									Registered Account. To list, sell, or buy Tickets or Reservations through our Site, you must register an account ("Account") with SI Tickets. You must be at least 18 years old and must have the capacity to enter into a legally binding contract. If you are under the age of 18, then you may only use our Site or our customer call center in conjunction with, and under the supervision of, a parent or guardian.
									<br />
									If you are registering an SI Tickets account on behalf of a business entity, you represent that you have the authority (express or implied) to legally bind that entity.
								</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>2.2</ItemNumber>
								<ItemText>Payment Information. When opening an Account, you must provide complete and accurate identifying information (e.g., name and address), contact information (e.g., phone and email), ("Payment Method"). If your registration or payment information changes at any time for any reasons (e.g., you move, you get a new credit card), you must promptly update your details in your Account through our Site. If you provide more than one Payment Method, you acknowledge that SI Tickets may charge any one of your registered Payment Methods for amounts you owe us, costs we incur, or other losses arising out of or relating to your violation of this agreement.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>2.3</ItemNumber>
								<ItemText>Suspended Accounts. SI Tickets reserves the right to temporarily or permanently suspend Accounts, in our sole discretion, with incomplete, inaccurate, false, fraudulent, or misleading information, or for any reason or no reason at any time without notice.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>2.4</ItemNumber>
								<ItemText>Account Activity. You are solely responsible for any activity relating to your Account.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>2.5</ItemNumber>
								<ItemText>Confidentiality. You are solely responsible for maintaining the confidentiality of your Account information and password.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>2.6</ItemNumber>
								<ItemText>Non-Transferable. Your SI Ticket Account is not transferable to another party without prior written consent of SI Tickets.</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="3. Fees & Other Charges" {...sectionProps}>
							<ItemContent>
								<ItemNumber>3.1</ItemNumber>
								<ItemText>Fees Generally. SI Tickets may charge fees for selling and/or buying Tickets through our Site, and/or otherwise using our Services, as well as sell fees, delivery fees or fulfillment fees (collectively referred to as "Service Fees"). Our Service Fees may vary. SI Tickets may in its sole and absolute discretion change its Service Fees at any time, including after you list your Tickets or Reservations if applicable. Any applicable Service Fees, including any taxes if applicable, will be disclosed to you prior to listing or buying a Ticket or a Reservation. SI Tickets may charge and/or retain Service Fees if you do not fulfill your contractual obligations under this Terms Of Use (including the Additional Policies).</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>3.2</ItemNumber>
								<ItemText>Listing/Selling/Buying Fees. SI Tickets fees may vary and are set forth in our Help Center which may be amended from time to time.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>3.3</ItemNumber>
								<ItemText>Delivery Fees. SI Tickets charges customers a fee for all FedEx Deliveries. All domestic FedEx deliveries and some international FedEx deliveries may require a signature and may be shipped to the billing address of the credit card used to make the purchase. It is your responsibility to provide a valid and secure address and have an adult available to accept and sign for delivery when the shipping provider makes their delivery attempts. Shipping and delivery times are subject to change and vary by destination. If for any reason FedEx fails to deliver your tickets in time for your event or indicates that the shipment is lost, you agree not to hold SI Tickets responsible for any costs associated with your tickets, shipping charges or additional financial costs related to the missing of your event. A Lost Claim will be filed by sitickets.com with FedEx on your behalf.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>3.4</ItemNumber>
								<ItemText>Fees Associated With Buyer’s Failure to Fulfill Obligations. If you in your capacity as a Buyer change your postal address after you have purchased your Ticket(s) and SI Tickets is required to deliver or re-deliver your Tickets to this new postal address, or if you have specified an incorrect or incomplete postal address, or if the tickets were returned to sender for any reason, SI Tickets may charge you for any additional delivery costs which arise out of or relate to re-arranging the delivery of your Tickets to the original or new or corrected postal address. Such additional delivery costs may be charged to any one of your registered Payment Methods.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>3.5</ItemNumber>
								<ItemText>Retransfer Fees. SI Tickets may charge you a Retransfer Fee for any electronic deliveries that were not able to be retrieved after the initial fulfillment of the Ticket has been completed by the seller and which requires the seller to cancel and then retransfer or redeliver the tickets.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>3.6</ItemNumber>
								<ItemText>
									Collection Agency. SI Tickets may collect amounts owed to it by using a collection agency or other collection mechanism. To the extent permissible by law, you may be charged fees associated with collecting such delinquent payments. SI Tickets, or the collection agency it retains, may also report information about your account to one or more credit bureaus, and as a result, defaults in your account may be reflected in your credit report. If you wish to dispute the information SI Tickets reported to a credit bureau, please contact SI Tickets at <a href="mailto:support@sitickets.com">support@sitickets.com</a>. If you wish to dispute the information a collection agency reported, contact the collection agency directly.
								</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="4. Buying Tickets" {...sectionProps}>
							<ItemContent>
								<ItemNumber>4.1</ItemNumber>
								<ItemText>Buyer’s Responsibilities. In your capacity as a Buyer, you are responsible for reading the complete listing before making a commitment to buy Tickets.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>4.2</ItemNumber>
								<ItemText>Binding Contract. When placing an order, you are entering into a binding contract with the seller to purchase those Tickets.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>4.3</ItemNumber>
								<ItemText>All Sales are final. In your capacity as a Buyer, you cannot change or cancel any orders after the sale is complete.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>4.4</ItemNumber>
								<ItemText>Payments Due Immediately. Payments from Buyers are due and payable immediately to Sellers. A Buyer must pay the full order amount which includes any applicable Service Fees and taxes using one of the accepted payment methods.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>4.5</ItemNumber>
								<ItemText>An order is not complete (“Active”) until (A) SI Tickets has successfully pre-authorized the payment method for the full amount of the order, (B) SI Tickets has successfully reviewed the transaction and verified it does not appear to be fraudulent, and (C) the respective seller has confirmed the order. In the event that any of these steps [4.5. A - C] is not successful, the order is considered Failed (“Failed”). Failed orders cannot become Active. If your order Failed, you will receive an email to notify you that the order Failed along with the reason and you will receive instructions if additional verification is necessary in order for you to place a new order on the Site. Any pre-authorized payments are immediately voided if the order is Failed. Only Active orders are protected by the SI Tix Fan Guarantee.</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="5. Event Cancellations, Postponement & Other Event Changes" {...sectionProps}>
							<ItemContent>
								<ItemNumber>5.1</ItemNumber>
								<ItemText>Event Cancellation. The Buyer will receive a full refund or credit for use on a Reservation purchase, as determined in SI Tickets sole discretion of SI Tickets (unless a refund is required by applicable law).</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>5.2</ItemNumber>
								<ItemText>Postponed Event. If an event is postponed without a rescheduled date and if more than 30 days has passed since the notification or announcement of the event postponement, SI Tickets will agree to cancel any Ticket Order whereby a Buyer has contacted SI Tickets to request Order Cancellation. If you would like to request Order Cancellation due to an event being postponed without a rescheduled date and more than 30 days has passed since the postponement notification or announcement, please contact SI Tickets at support@sitickets.com and SI Tickets will process the cancellation requests. If the request is received after a rescheduled date has been announced, SI Tickets will decide in its sole discretion whether a cancellation request will be processed.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>5.3</ItemNumber>
								<ItemText>Rescheduled Events. If an event is rescheduled, and a new date has been announced, the tickets purchased are valid for entry at the time of the rescheduled event, and therefore your order will not qualify for a refund or other compensation unless the event is ultimately canceled. SI Tickets will work with Buyers and Sellers on a case-by-case basis attempting to resolve any Ticket issues between the two parties.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>5.4</ItemNumber>
								<ItemText>Other event changes. SI Tickets is not responsible for partial performances, venue, line-up, or time changes. SI Tickets has no obligation to notify the Buyer of any such changes. No refunds will be issued in these instances.</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="6. International User Access & Unlawful Activity/Acceptable Use" {...sectionProps}>
							<ItemContent>
								<ItemNumber>6.1</ItemNumber>
								<ItemText>
									<strong>International User Access</strong>
									<br />
									The Site may be accessed by you from countries around the world and may contain references to Services that may not be available in your country. These references do not imply that SI Tickets intends to announce such Services in your country. The Site is controlled and offered by SI Tickets from facilities located within the United States of America. SI Tickets makes no representations that the Site is appropriate or available for use in other locations. Those who access or use the Site from other countries do so at their own volition and are responsible for compliance with local law.
								</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>6.2</ItemNumber>
								<ItemText>
									<strong>Unlawful Activity; Acceptable Use</strong>
									<br />
									SI Tickets prohibits the use of its Site and/or Services for unlawful conduct. All users must comply with all local, state, federal and international laws, ordinances and regulations. By using the Site, you agree not to use any false personal information or use an invalid or unauthorized credit or debit card or other payment account (such as a PayPal or Venmo account). You agree not to use or permit anyone to use information provided through www.sitickets.com for any unlawful or unauthorized purpose.
									<br />
									We are the sole interpreter of this site's intended and acceptable use. This site is intended to be used by individuals or companies seeking to buy or sell tickets for use by an individual to attend an event and for no other purpose. If you are a venue, promoter or anyone else responsible for controlling admission to an event, you may not access our site or purchase tickets on this site for purposes of (i) identifying tickets available on our site or the sellers of those tickets; or (ii) revoking or voiding any tickets offered by sellers on this site or otherwise penalizing any sellers for offering tickets on this site.
								</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemText>SI Tickets reserves the right to report to appropriate law enforcement authorities or other relevant third parties any activity that it believes, in its sole discretion, may in any way violate any local, state, federal or international law.</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="7. Term and Termination of Terms Of Use" {...sectionProps}>
							<ItemContent>
								<ItemNumber>7.1</ItemNumber>
								<ItemText>Term. This Terms Of Use shall commence on the date that you register an Account with SI Tickets and will continue for an indefinite period unless terminated in accordance with this agreement.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>7.2</ItemNumber>
								<ItemText>
									Termination. If you request that we close your Account by sending a written request via email to <a href="mailto:support@sitickets.com">support@sitickets.com</a>, SI Tickets will treat your request as termination of this Terms Of Use. SI Tickets may terminate this Terms Of Use, and thereby close your account, at any time and for any reason without notice. If your email has changed you are required to update your account.
								</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>7.3</ItemNumber>
								<ItemText>Effects of Termination. Termination of this Terms Of Use shall not affect the rights or liabilities of either party accrued prior to and including the date of termination or expiry and/or any terms intended expressly or by implication to survive termination or expiry.</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="8. Site Changes and Availability" {...sectionProps}>
							<ItemContent>
								<ItemText>SI Tickets reserves the right at any time to modify or discontinue, temporarily, or permanently, the Site, the Services or any part of the Site or Services at its sole discretion and with or without notice for any reason. SI Tickets performs regularly scheduled maintenance of the Site. SI Tickets does not have an obligation to notify its users when this maintenance is scheduled to occur. While SI Tickets endeavors to avoid or minimize customer impact, the Site or our Services may be temporarily unavailable during such maintenance periods.</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="9. Abusing SI Tickets" {...sectionProps}>
							<ItemContent>
								<ItemText>
									When using our Site and Services, you agree that you will not do any of the following:
									<ul>
										<li>Contact or invite contact with other SI Tickets users for any reason other than the purpose for which you received the SI Tickets user’s contact information or solicit sales outside of SI Tickets.</li>
										<li>Behave in an abusive manner to any SI Tickets employee or other user.</li>
										<li>Violate any venue or event promoter rules at events or violate any applicable third-party terms of service (e.g., when using our mobile applications).</li>
										<li>Breach or circumvent any laws (including, where Tickets are sold internationally, the laws of the destination country), third-party rights or our Additional Policies.</li>
										<li>Post false, inaccurate, incomplete, misleading, defamatory or libelous content.</li>
										<li>Fail to fulfill your contractual obligations regarding the sale or purchase of a Ticket.</li>
										<li>Use SI Ticket's trademarks without our prior written permission.</li>
										<li>Copy, reproduce, reverse engineer, modify, create derivative works from, distribute or publicly display any content (except for your information) or software from our Site or Services without the prior express written permission of SI Tickets and the appropriate third party, as applicable.</li>
										<li>Use any robot, spider, scraper or other automated means to access our Site or Services for any purpose without our express written permission.</li>
										<li>Take any action that imposes or may impose (to be determined in our sole discretion) an unreasonable or disproportionately large load on our infrastructure.</li>
										<li>Interfere or attempt to interfere with the proper working of our Site or Services or any activities conducted on or with our Site or Services.</li>
										<li>Commercialize any SI Tickets application or any information or software associated with such application.</li>
										<li>Export or re-export any SI Tickets application or tool except in compliance with the export control laws of any relevant jurisdiction and in accordance with posted rules and restrictions.</li>
										<li>Do anything else that SI Tickets determines, in its sole reasonable discretion, misuses the Site or Services or otherwise negatively impacts our marketplace.</li>
									</ul>
								</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="10. Mobile Device Terms" {...sectionProps}>
							<ItemContent>
								<ItemText>If you are accessing the Site or Services through a mobile application (“App”), the following additional terms apply:</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>10.1</ItemNumber>
								<ItemText>App Use. SI Tickets grants you the right to use the App only for your personal use. You must comply with all applicable laws and third-party terms of agreement when using the App (e.g., your wireless data service agreement). The App may not contain the same functionality available on the SI Tickets website.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>10.2</ItemNumber>
								<ItemText>Intellectual Property – App. SI Tickets owns, or is the licensee to, all right, title, and interest in and to its App, including all rights under patent, copyright, trade secret, trademark, or unfair competition law, and all other proprietary rights, including all applications, renewals, extensions, and restorations thereof. You will not modify, adapt, translate, prepare derivative works from, decompile, reverse-engineer, disassemble, or otherwise attempt to derive source code from any App and you will not remove, obscure, or alter SI Ticket's copyright notice, trademarks or other proprietary rights notices affixed to, contained within, or accessed in conjunction with or by the App.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>10.3</ItemNumber>
								<ItemText>Prohibited Countries Policy and Foreign Trade Regulation - Applications. The App or its underlying technology may not be downloaded to or exported or re-exported: (a) into (or to a resident or national of) Burma (Myanmar), Cuba, Iraq, Iran, Libya, North Korea, Sudan, Syria, or any other country subject to United States embargo; (b) to anyone on the US Treasury Department's list of Specially Designated Nationals or on the US Commerce Department's Denied Party or Entity List; and (c) to any prohibited country, person, end-user, or entity specified by US Export Laws. When using the App, you are responsible for complying with trade regulations and both foreign and domestic laws (e.g., you are not located in a country that is subject to a US Government embargo, or that has been designated by the US Government as a "terrorist supporting" country, and you are not listed on any US Government list of prohibited or restricted parties).</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="11. Protecting Intellectual Property Rights" {...sectionProps}>
							<ItemContent>
								<ItemText>
									<p>SI Tickets respects intellectual property rights and we respond to notices of alleged infringement.</p>
									<p>If you reside in the United States, and you find material on our Site or Services that you believe infringes your copyright or other intellectual property rights, please notify us and we will investigate.</p>
									<p>Notice of Infringement: If you have a good faith belief that your intellectual property rights have been violated in content appearing on the site, you can submit a Notice of Claimed Infringement to SI Ticket’s designated agent with the following information: 1) The physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed; 2) Identification or description of the copyrighted work or other intellectual property you claim has been infringed. If you are asserting infringement of an intellectual property right other than copyright, please specify the right at issue (for example, trademark or patent); 3) Identification or description of where the material that you claim is infringing is located on SI Tickets, with enough detail that we may find it on the Site including, whenever possible, the URL; 4) Brief description of how the challenged content infringes the owner’s intellectual property rights; 5) Your address, telephone number, and email address; 6) A statement by you that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright or intellectual property owner, its agent, or the law; and 7) A statement, under penalty of perjury, that the information in the notification is accurate and that you are the copyright or intellectual property owner or authorized to act on the owner’s behalf.</p>
									<p>
										SI Ticket's designated agent can be contacted as follows: by mail to SI Ticket's Designated Agent, SI Tickets, 26 Broadway, Suite 934, New York, NY 10004, by email to <a href="mailto:support@sitickets.com">support@sitickets.com</a>; or by phone at <a href="tel:6464537600">646-453-7400</a>.
									</p>
								</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="12. Violations of the Terms Of Use" {...sectionProps}>
							<ItemContent>
								<ItemNumber>12.1</ItemNumber>
								<ItemText>We may investigate any potential or suspected violations of the Terms Of Use, Additional Policies, security protocols or best practices, third-party rights or applicable law; any actions or any other misconduct or potential abuse on or through the Services or attempts. When assessing whether and which measure(s) to take against a user, we will take the legitimate interests of the user into account, and shall in particular consider if the user is responsible for the misconduct.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>12.2</ItemNumber>
								<ItemText>We may take any actions we deem appropriate in our reasonable discretion for the conduct described in 19.1.Without limiting other remedies, these actions may include: limit, or temporarily or permanently suspend, or terminate our services and Accounts, restrict or prohibit access to, and your activities on, our Site and/or Services, remove listings, require you to edit listings, cancel sales, require you to send Tickets to a Buyer within a specified time, delay or remove hosted content, remove any special status associated with an Account, reduce or eliminate any discounts, withhold payment, charge the Payment Method on file for amounts you owe or costs we incur due to your misconduct (including, without limitation, any costs associated with collection of delinquent Accounts or chargebacks and any replacement or restocking costs), and take technical and legal steps to keep you from using our Site and/or Services.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>12.3</ItemNumber>
								<ItemText>We reserve the right to report any activity that we believe to be illegal or otherwise in contravention of this Terms Of Use and we will respond to any verified requests relating to a criminal investigation (i.e. a subpoena, court order or substantially similar legal procedure) from local and foreign law enforcement or regulatory agencies, other government officials or authorized third-parties.</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="13. Disclaimer of Warranties; Limitation of Liability" {...sectionProps}>
							<ItemContent>
								<ItemNumber>13.1</ItemNumber>
								<ItemText>YOU UNDERSTAND AND AGREE THAT YOU ARE MAKING USE OF THE SITE AND SERVICES ON AN ‘AS IS’ AND ‘AS AVAILABLE’ BASIS. SI TICKETS MAKES NO WARRANTY WITH RESPECT TO ITS SOFTWARE, SERVICES, SITE, ANY TICKETS, ANY EVENT, ANY USER CONTENT, OR THAT SELLERS OR BUYERS WILL PERFORM AS PROMISED. ACCORDINGLY, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW AND EXCEPT AS SPECIFICALLY PROVIDED IN THIS TERMS OF USE AND IN OUR FANPROTECT GUARANTEE, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>13.2</ItemNumber>
								<ItemText>IN ADDITION, TO THE MAXIMUM EXTENT PERMITTED BY LAW, SI TICKETS (INCLUSIVE OF ANY OF ITS SERVICE PROVIDERS AND LICENSORS) IS NOT LIABLE FOR: (A) ANY SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES; (B) LOSS OF: PROFITS, GOODWILL OR REPUTATION, OR OTHER INTANGIBLE LOSSES; (C) DAMAGES RELATING TO: (I) YOUR ACCESS TO, USE OF, OR INABILITY TO ACCESS OR USE THE SITE OR SERVICES; (II) VIRUSES OR OTHER MALICIOUS SOFTWARE OBTAINED BY ACCESSING OUR SITE OR SERVICES OR THOSE OF ANY SITE, SERVICES, OR TOOLS LINKED TO OUR SITE OR SERVICES; (III) ANY USER CONTENT OR CONTENT OF THIRD PARTIES, OR (IV) THE DURATION OR MANNER IN WHICH TICKETS YOU LIST APPEAR ON THE SITE OR SERVICES.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>13.3</ItemNumber>
								<ItemText>SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF WARRANTIES OR EXCLUSION OF DAMAGES, SO SUCH DISCLAIMERS AND EXCLUSIONS MAY NOT APPLY TO YOU AND YOU MAY HAVE RIGHTS IN ADDITION TO THOSE CONTAINED IN THIS TERMS OF USE. THIS IS ESPECIALLY SO IF YOU ARE A CONSUMER.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>13.4</ItemNumber>
								<ItemText>REGARDLESS OF THE PREVIOUS CLAUSES, OUR LIABILITY TO YOU OR TO ANY THIRD PARTY IS LIMITED TO THE GREATER OF (A) THE AMOUNT OF FEES IN DISPUTE NOT TO EXCEED THE TOTAL FEES WHICH YOU PAID TO US IN THE 12 MONTHS PRIOR TO THE ACTION GIVING RISE TO THE LIABILITY, OR (B) $200.</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="14. Applicable Laws and Jurisdiction" {...sectionProps}>
							<ItemContent>
								<ItemNumber>14.1</ItemNumber>
								<ItemText>Choice of Law. Any claim, dispute or matter arising under or in connection with this Terms Of Use shall be governed and construed according to the laws of the State of New York. For consumers resident in the EU, please note that the mandatory consumer protection provisions of the law of the member state in which you reside will also apply.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>14.2</ItemNumber>
								<ItemText>Arbitration Agreement. If you reside in the United States, you agree to resolve your disputes with SI Tickets as specified herein. If you reside anywhere else in the world: if you are registered as a business you agree to submit to the exclusive jurisdiction, and if you are a consumer you submit to the non-exclusive jurisdiction, of the courts of the State of New York. This means that you as a consumer have the right to bring an action either in the State of New York (United States) or at your place of residence. SI Tickets may only initiate legal proceedings against consumers before the courts in their place of residence.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>14.3</ItemNumber>
								<ItemText>European Union Consumers. If you are a consumer residing in the European Union, please note that we do not (and are not legally obliged to) participate in any alternative dispute resolution (ADR) procedures or services, unless otherwise (i) specified in the country-specific additional provisions below, and/or (ii) provided by law.</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="15. Legal Disputes" {...sectionProps}>
							<ItemContent>
								<ItemNumber>15.1</ItemNumber>
								<ItemText>
									If you reside in the United States or Canada, You and SI Tickets each agree, except where prohibited by law, that any and all disputes or claims that have arisen or may arise between you and SI Tickets relating in any way to or arising out of this or previous versions of the Terms Of Use (including this Agreement to Arbitrate, as the term is defined below) or the breach or validity thereof, your use of or access to the Site or Services, or any tickets or related passes sold or purchased through the Site or Services shall be resolved exclusively through final and binding arbitration administered by the American Arbitration Association (“AAA”) in accordance with its Consumer Arbitration Rules (“Rules”), rather than in court, except that you may assert claims in small claims court, if your claims qualify and so long as the matter remains in such court and advances only on an individual (non-class, non-representative) basis (together with subsections <strong>15.1(A-E)</strong>, the “Agreement to Arbitrate”). This Agreement to Arbitrate is intended to be broadly interpreted. The Federal Arbitration Act governs the interpretation and enforcement of this Agreement to Arbitrate.
									<br />
									<br />
									<p>
										<strong>A. Prohibition of Class and Representative Actions and Non-Individualized Relief</strong>
									</p>
									<ol>
										<li>
											<p>
												<strong>Prohibition of Class and Representative Actions</strong>
											</p>
											<p>EXCEPT WHERE PROHIBITED BY LAW, YOU AND SI TICKETS AGREE THAT EACH OF US MAY BRING CLAIMS PURSUANT TO THIS AGREEMENT TO ARBITRATE AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, OR REPRESENTATIVE OR PRIVATE ATTORNEY GENERAL ACTION OR PROCEEDING. UNLESS BOTH YOU AND SI TICKETS AGREE OTHERWISE, THE ARBITRATOR SHALL NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON'S OR PARTY'S CLAIMS, AND SHALL NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, CLASS, OR PRIVATE ATTORNEY GENERAL ACTION OR PROCEEDING.</p>
										</li>
										<li>
											<p>
												<strong>Non-Individualized Relief</strong>
											</p>
											<p>YOU AND SI TICKETS AGREE THAT THE ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY'S INDIVIDUAL CLAIM(S). ANY RELIEF AWARDED CANNOT AFFECT OTHER USERS OR THE GENERAL PUBLIC. If a court decides that applicable law precludes enforcement of any of this paragraph's limitations as to a particular claim for relief, then subject to your and SI Ticket’s right to appeal the court’s decision, that claim (and only that claim) must be severed from the arbitration and may be brought in court. All other claims will be arbitrated.</p>
										</li>
									</ol>
									<br />
									<p>
										<strong>B. Arbitration Procedures</strong>
									</p>
									<IndentInText>
										<p>Arbitration is less formal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury, and court review of an arbitration award is very limited. However, an arbitrator can award the same damages and relief on an individual basis that a court can award to an individual; and an arbitrator must also follow the terms of the Terms Of Use, as a court would.</p>
										<p>All issues are for the arbitrator to decide, except those issues relating to arbitrability, the scope or enforceability of this Agreement to Arbitrate, or the interpretation of Section 1 of this Agreement to Arbitrate ("Prohibition of Class and Representative Actions and Non-Individualized Relief"), shall be for a court of competent jurisdiction to decide.</p>
										<p>The arbitrator, and not any federal, state, or local court or agency, shall have exclusive authority to resolve any dispute arising out of or relating to the interpretation, applicability, enforceability or formation of this Agreement to Arbitrate, any part of it, or of the Terms Of Use including, but not limited to, any claim that all or any part of this Agreement to Arbitrate or the Terms Of Use is void or voidable.</p>
										<p>
											The arbitration will be conducted by the AAA under the Rules, as modified by this Agreement to Arbitrate. The Rules are available at{" "}
											<a href="https://adr.org" target="_blank" rel="noopener noreferrer">
												www.adr.org
											</a>{" "}
											The language of the arbitration shall be English.
										</p>
										<p>A party who intends to seek arbitration must first send to the other, by certified mail, a completed form Notice of Dispute (“Notice”). A Notice to SI Tickets should be sent to SI Tickets, 26 Broadway. Suite 934, New York, NY 10004 Attn: General Counsel. SI Tickets will send any Notice to you to the physical address we have on file associated with your SI Tickets account; it is your responsibility to keep your physical address up to date. All information called for in the Notice must be provided, including a description of the nature and basis of the claims the party is asserting and the relief sought.</p>
										<p>
											If you and SI Tickets are unable to resolve the claims described in the Notice within 30 days after the Notice is sent, you or SI Tickets may initiate arbitration proceedings pursuant to the Rules. A form for initiating arbitration proceedings is available on the AAA's website at{" "}
											<a href="https://adr.org" target="_blank" rel="noopener noreferrer">
												www.adr.org
											</a>
											. In addition to filing this form with the AAA in accordance with its rules and procedures, the party initiating the arbitration must mail a copy of the completed form to the opposing party. You may send a copy to SI Tickets at the following address: SI Tickets, 26 Broadway, Suite 934, New York, NY 10004 In the event SI Tickets initiates arbitration against you, it will send a copy of the completed form to the physical address that you provided and we have on file associated with your SI Tickets account. Any settlement offer made by you or SI Tickets shall not be disclosed to the arbitrator. The arbitration shall be held in the county in which you reside or at another mutually-agreed location. If the value of the relief sought is $10,000 or less, you or SI Tickets may elect to have the arbitration conducted by telephone, by remote means, or based solely on written submissions, which election shall be binding on you and SI Tickets subject to the arbitrator's discretion to require an in-person hearing, if the circumstances warrant. In cases where an in-person hearing is held, you and/or SI Tickets may attend by telephone, unless the arbitrator requires otherwise.
										</p>
										<p>The arbitrator will decide the substance of all claims in accordance with the laws of the State of New York, including recognized principles of equity, and will honor all claims of privilege recognized by law. The arbitrator shall not be bound by rulings in prior arbitrations involving different SI Tickets users, but is bound by rulings in prior arbitrations involving the same SI Tickets user to the extent required by applicable law. The arbitrator's award shall be final and binding, and judgment on the award rendered by the arbitrator may be entered in any court having jurisdiction thereof.</p>
									</IndentInText>
									<br />
									<p>
										<strong>C. Costs of Arbitration</strong>
									</p>
									<IndentInText>Payment of all filing, administration, and arbitrator fees will be governed by the Rules, unless otherwise stated in this Agreement to Arbitrate. If the value of the relief sought is $10,000 or less, at your request, SI Tickets will pay all filing, administration, and arbitrator fees associated with the arbitration. Any request for payment of fees by SI Tickets should be submitted by mail to the AAA along with your Demand for Arbitration and SI Tickets will make arrangements to pay all necessary fees directly to the AAA. If the value of the relief sought is more than $10,000 and you are able to demonstrate that the costs of accessing arbitration will be prohibitive as compared to the costs of accessing a court for purposes of pursuing litigation on an individual basis, SI Tickets will pay as much of the filing, administration, and arbitrator fees as the arbitrator deems necessary to prevent the costs of accessing arbitration from being prohibitive. If (a) you willfully fail to comply with the Notice of Dispute requirement discussed in subsection 22(B) above, or (b) in the event the arbitrator determines the claim(s) you assert in the arbitration to be frivolous, you agree to reimburse SI Tickets for all fees associated with the arbitration paid by SI Tickets on your behalf that you otherwise would be obligated to pay under the Rules.</IndentInText>
									<br />
									<p>
										<strong>D. Severability</strong>
									</p>
									<IndentInText>With the exception of any of the provisions in subsection A.1 of this Agreement to Arbitrate (“Prohibition of Class and Representative Actions”), if an arbitrator or court decides that any part of this Agreement to Arbitrate is invalid or unenforceable, the other parts of this Agreement to Arbitrate shall still apply. If an arbitrator or court decides that subsection A.1 of this Agreement to Arbitrate is invalid or unenforceable, then if SI Tickets so elects, the entirety of this Agreement to Arbitrate shall be null and void. If a court decides that applicable law precludes enforcement of any of the provisions in subsection A.2 of this Agreement to Arbitrate (“Non-Individualized Relief”) as to a particular claim for relief, then subject to your and SI Tickets’s right to appeal the court’s decision, that claim (and only that claim) must be severed from the arbitration and litigated in in court. All other claims and disputes subject to arbitration under this Agreement to Arbitrate, including any and all claims for monetary damages of any kind, shall be arbitrated.</IndentInText>
									<br />
									<p>
										<strong>E. Reservation Amendments to the Agreement to Arbitrate</strong>
									</p>
									<IndentInText>Notwithstanding any provision in the Terms Of Use to the contrary, you and SI Tickets agree that if we make any amendment to this Agreement to Arbitrate (other than a change to any notice address or website link provided herein) in the Reservation, that amendment shall not apply to any claim that was filed in a legal proceeding between you and SI Tickets prior to the effective date of the change. The amendment shall apply to all other disputes or claims governed by the Agreement to Arbitrate that have arisen or may arise between you and SI Tickets. We will notify you of amendments to this Agreement to Arbitrate by posting the amended terms on sitickets.com at least thirty (30) days before the effective date of the amendments and by sending notice via email to your email address on file with us. If you do not agree to the amended terms, you may close your account within the thirty (30) day period and you will not be bound by the amended terms.</IndentInText>
								</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>15.2</ItemNumber>
								<ItemText>
									If you reside outside of the United States or Canada and a dispute arises between you and SI Tickets, we strongly encourage you to first contact us directly to seek a resolution by contacting Customer Service at <a href="tel:6464537300">(646) 453-7400</a>. For the avoidance of doubt, if you are a consumer residing in the European Union, please note that we do not (and are not legally obliged to) participate in any ADR procedures or services, unless otherwise (i) specified in the country-specific additional provisions below, and/or (ii) provided by law.
								</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="16. Release and Indemnification" {...sectionProps}>
							<ItemContent>
								<ItemNumber>16.1</ItemNumber>
								<ItemText>To the fullest extent permitted by law, you release and covenant not to sue SI Tickets, its affiliated companies, and our and their respective officers, directors, agents, joint venturers, employees, legal representatives, and suppliers from any and all claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with disputes between users. In entering into this release you expressly waive any protections (whether statutory or otherwise) that would otherwise limit the coverage of this release to include only those claims, which you may know or suspect to exist in your favor at the time of agreeing to this release.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>16.2</ItemNumber>
								<ItemText>You agree to indemnify and hold SI Tickets and (if applicable) our parent, subsidiaries, affiliates, and our and their respective officers, directors, attorneys, agents, employees, licensors and suppliers (the "SI Tickets Indemnitees") harmless against any claim or demand and all liabilities, costs and expenses (including reasonable attorneys' fees) incurred by us and (if applicable) any SI Tickets Indemnitee resulting from or arising out of your breach of this Terms Of Use, your improper use of our Site or Services, and/or your violation of any law or the rights of a third party.</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="17. Country-Specific Additional Provisions" {...sectionProps}>
							<ItemContent>
								<ItemText>Applicable to US residents.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>17.1</ItemNumber>
								<ItemText>
									If you are a California resident, in accordance with Cal. Civ. Code §1789.3, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by contacting them in writing at 400 R Street, Sacramento, CA 95814, or by telephone at (800) 952-5210.
									<br />
									Applicable to events located in the State of Illinois (USA).
								</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>17.2</ItemNumber>
								<ItemText>
									For any user that is selling a Ticket to an event located in the state of Illinois, the following applies: pursuant to 815 ILCS 414/1.5(c), for transactions involving tickets to events in Illinois, Buyers may elect to submit complaints against one another to the American Arbitration Association (‘AAA’) under its rules and procedures. The AAA's rules are available at www.adr.org. Such complaints shall be decided by an independent arbitrator in accordance with this Terms Of Use and all incorporated Policies. Buyers further agree to submit to the jurisdiction of the State of Illinois for complaints involving a ticketed event held in Illinois.
									<br />
									Applicable to consumers resident in certain other countries.
								</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>17.3</ItemNumber>
								<ItemText>Please see the appendices to this Terms Of Use for any provisions that may apply to you in addition to or in place of certain provisions of this Terms Of Use if you are resident in a particular country. The provisions of the country appendices will take precedence over the provisions of this Terms Of Use and the Additional Policies, to the extent there is a conflict.</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="18. General Provisions" {...sectionProps}>
							<ItemContent>
								<ItemNumber>18.1</ItemNumber>
								<ItemText>This Terms Of Use (and all documents incorporated by reference) constitutes the entire agreement between the parties pertaining to the subject matter hereof and supersedes all prior agreements and understandings between the parties hereto with respect to the subject matter hereof, whether written or oral. No amendment, modification or supplement of any provision of this Terms Of Use will be valid or effective unless made in accordance with the express terms of this Terms Of Use.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>18.2</ItemNumber>
								<ItemText>If any provision of this Terms Of Use is held to be invalid or unenforceable under any circumstances, its application in any other circumstances and the remaining provisions of this Terms Of Use shall not be affected.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>18.3</ItemNumber>
								<ItemText>We may assign or transfer our rights and obligations under this Terms Of Use at any time, provided that we assign the Terms Of Use on the same terms or terms that are no less advantageous to you. You may not assign or transfer this Terms Of Use, or any of its rights or obligations, without our prior written consent, which we can refuse in our sole discretion.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>18.4</ItemNumber>
								<ItemText>No agency, partnership, joint venture, employer-employee or franchisor-franchisee relationship is intended or created by this Terms Of Use, your use of the Site, or the provision of our Services. A person who is not a party to this Terms Of Use has no right to enforce any term of this Terms Of Use.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>18.5</ItemNumber>
								<ItemText>Nothing in this Terms Of Use is intended to confer benefits, rights or remedies unto any person or entity other than the parties hereto and their permitted successors and assigns.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>18.6</ItemNumber>
								<ItemText>The subject heading at the beginning of each paragraph of this Terms Of Use is for reference purposes only and in no way defines, limits, construes or describes the scope or extent of such paragraph. The following sections survive any termination of this Terms Of Use: Contracting entity, fees and other charges, taxes, payments, abusing SI Tickets, content, liability, applicable laws, release and indemnification, additional provisions applicable to US residents and events in the USA, general provisions.</ItemText>
							</ItemContent>
							<ItemContent>
								<ItemNumber>18.7</ItemNumber>
								<ItemText>Our failure to act with respect to a breach by you or others does not waive our right to act with respect to subsequent or similar breaches. We do not guarantee we will take action against all breaches of this Terms Of Use.</ItemText>
							</ItemContent>
						</EducationalRow>
						<EducationalRow title="19. Force Majeure" {...sectionProps}>
							<ItemContent>
								<ItemText>SI Tickets shall not be in default or otherwise liable under this Terms Of Use due to its inability to perform its obligations by reason of any fire, earthquake, flood, substantial snowstorm, epidemic, pandemic, other public health crisis, accident, explosion, casualty, strike, lockout, labor controversy, riot, civil disturbance, act of public enemy, terrorism, cyber-terrorism, embargo, war, act of God, natural disaster, or any municipal, county, state or national ordinance or law, or any executive, administrative or judicial order (which order is not the result of any act or omission which would constitute a default by SI Tickets hereunder), or any failure or delay of any transportation, power, or communications system or any other or similar cause not under SI Ticket’s control (“Force Majeure”).</ItemText>
							</ItemContent>
						</EducationalRow>
					</EducationalSectionContainer>
					<br />
					<br />
					<Text>
						<strong>The entity you are contracting with is SI Tickets, Inc.</strong>
					</Text>
				</Section>
			</PageContainer>
		</CommonPage>
	);
};

const Section = styled.div`
	margin-top: 24px;
`;

const PageContainer = styled.div`
	${maxContentWidthStyles}
	padding: 40px 15px 24px;
	text-align: left;
	color: #000;
`;

const Title = styled.h1`
	font-size: 40px;
	font-weight: 700;
	font-family: SolanoGothicMVB-Bd, sans-serif;
	text-transform: uppercase;
`;

const Date = styled.p`
	font-family: Montserrat;
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	margin-top: 24px;
	margin-bottom: 10px;
`;

const Text = styled.p<{ uppercase?: boolean }>`
	font-family: Montserrat;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 25px;
	font-weight: 500;
	text-transform: ${props => props.uppercase && "uppercase"};
	a {
		text-decoration: underline;
		color: ${getAppColor("primary")};
	}
`;

const Strong = styled.span`
	font-weight: 700;
`;

const ItemContent = styled.div`
	display: flex;
	margin-bottom: 20px;
	&:last-child {
		margin-bottom: 0px;
	}
`;

const ItemNumber = styled.div`
	font-family: Montserrat;
	font-size: 16px;
	line-height: 24px;
	margin-right: 8px;
	font-weight: 500;
`;

const ItemText = styled.div`
	width: 100%;
	font-family: Montserrat;
	font-size: 16px;
	line-height: 24px;
	margin-right: 8px;
	font-weight: 500;
	ul {
		padding-left: 30px;
		li {
			margin-top: 5px;
		}
	}
	p {
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0px;
		}
	}
	ol {
		padding-left: 30px;
		li {
			margin-top: 5px;
			list-style: decimal;
		}
	}
	a {
		text-decoration: underline;
		color: ${getAppColor("primary")};
	}
`;

const IndentInText = styled.div`
	padding-left: 30px;
	@media ${mediaQueries.max480} {
		padding-left: 15px;
	}
`;

const EducationalSectionContainer = styled.div<{ fullwidth?: boolean }>`
	margin-top: ${props => (props.fullwidth ? "0px" : "24px")};
`;
