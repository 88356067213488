import React from "react";
import { FuturesListing, FuturesSale, FuturesOrder } from "../../../../model/optimizedModel/myAccount";
import { formatPrice } from "../../../util/formatPrice";
import { FutureContentDisplay } from "../../ContentDisplays/FutureContentDisplay";
import { TitleAndContentGrid, TitleAndContentGridConfigProps, TitleAndContentGridItem } from "../../TitleAndContentGrid/TitleAndContentGrid";
import { ExportedMyAccountItemProps, MyAccountItem } from "./MyAccountItem";
import styled from "styled-components/macro";
import { useExtraSmallScreen } from "../../../hooks/useExtraSmallScreen";
import { useMemoState } from "../../../hooks/useMemoState";
import { PartialBy } from "../../../../model/optimizedModel/general";
import { hotelFuturesEventSlug } from "../../../util/hotelFutures";

type Future = FuturesOrder | FuturesListing | FuturesSale;

export interface BaseMyAccountFutureProps<T extends Future> extends ExportedMyAccountItemProps {
	//Note: PartialBy will not be needed once backend changes are implemented.
	future: PartialBy<T, "order">;
	titleAndContentGridConfigOverride?: TitleAndContentGridConfigProps;
	additionalTitleAndContentGridItems?: TitleAndContentGridItem[];
}

//TODO: Look into a way to make T required.
export const BaseMyAccountFuture = <T extends Future>({ future, additionalTitleAndContentGridItems, titleAndContentGridConfigOverride, ...myAccountItemProps }: BaseMyAccountFutureProps<T>) => {
	const isExtraSmallScreen = useExtraSmallScreen();

	const titleAndContentGridItems = useMemoState(() => {
		let value: TitleAndContentGridItem[] = [
			{
				key: "reservationPrice",
				title: "Reservation Price",
				subtitle: "Already paid",
				content: formatPrice(future.zone.reservationPrice),
			},
			{
				key: "ticketPrice",
				title: `${future.event.slug === hotelFuturesEventSlug ? "Hotel" : "Ticket"} Price`,
				subtitle: "Paid when your team makes the game",
				content: formatPrice(future.zone.ticketPrice),
			},
		];
		if (additionalTitleAndContentGridItems) {
			value = [...value, ...additionalTitleAndContentGridItems];
		}
		return value;
	}, [additionalTitleAndContentGridItems, future.event.slug]);

	return (
		<MyAccountItem
			topContent={
				<>
					<FutureContentDisplay
						eventId={future.event.eventId}
						stadium={future.event.stadium}
						title={future.event.title}
						zoneTitle={future.zone.title}
						slug={future.event.slug}
						team={future.event.team}
						quantity={future.quantity}
						//TODO: Rename "date" to "occursAt" for all futures in order to have better logic for displaying the date on extraSmallScreens or rename "occursAt" to "date" for tickets. An alternative is to figure out a better way to handle this logic in general aside from the idea proposed in the first sentence.

						date={isExtraSmallScreen ? future.event.date : undefined}
					/>
					<StyledTitleAndContentGrid flexGap={["4px", "17px"]} items={titleAndContentGridItems} {...titleAndContentGridConfigOverride} />
				</>
			}
			date={future.event.date}
			orderInfo={future.order}
			{...myAccountItemProps}
		/>
	);
};

const StyledTitleAndContentGrid = styled(TitleAndContentGrid)`
	margin-top: 8px;
`;
