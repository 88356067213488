import React from "react";
import Carousel from "react-grid-carousel";
import { CommonPage } from "../components";
import { breakpoints } from "../util/breakpoints";
import { useMediaQuery } from "../hooks/useMediaQuery";
import styled from "styled-components/macro";
import { FontFamily } from "../components/Typography/Typography";
import EventCard from "../components/AppEventCard/EventCard";
import Item from "../components/CarouselApp/Item";
import { ButtonLink } from "../components/ButtonLinkApp/ButtonLink";
import { Config } from "../../Config";

const headerLogo = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/appLP/AI-Image-3C.png`);
const USAPickeball = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/appLP/AI-Image-1.png`);
const heroBg = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/appLP/AI-Image-2.png`);

const carouselEvents = [
	{
		dateLocation: "August 9-13 - St Louis, MO",
		title: "APP St. louis Open",
		link: "venue/dwight-davis-tennis-center",
		imageCarousel: Config.getCDNUrl(`/${Config.getCDNFolder()}/images/appLP/car02.png`),
		linkText: "Get Tickets",
	},
	{
		dateLocation: "August 23-27 - Philadelphia, PA",
		title: "APP philadelphia Open",
		link: "#",
		imageCarousel: Config.getCDNUrl(`/${Config.getCDNFolder()}/images/appLP/car01.png`),
		linkText: "Coming Soon",
	},
	{
		dateLocation: "AUG 30 - SEP 3 - Chicago, IL",
		title: "APP Chicago Open",
		link: "#",
		imageCarousel: Config.getCDNUrl(`/${Config.getCDNFolder()}/images/appLP/car20.png`),
		linkText: "Coming Soon",
	},
];

const upcomingEvents = [
	{
		dateLocation: "June 28 - JUL 3 - Newport Beach, CA",
		title: "APP Newport Beach Open",
		description: "Five days of world-class pickleball action at the historic Tennis and Pickleball Club at Newport Beach, Southern California’s finest pickleball venue. Join us at the APP Newport Beach Open from June 28 – July 3.",
		link: "#",
		image: Config.getCDNUrl(`/${Config.getCDNFolder()}/images/appLP/Event/NewportBeachOpen.png`),
		buttonLink: "Coming Soon",
		button: false,
	},
	{
		dateLocation: "aug 9-13 - st. louis, mo",
		title: "APP St. louis Open",
		description: "Experience the ultimate pickleball thrill as the APP Tour brings the 2023 St. Louis Open to the Dwight Davis Tennis Center from August 9 to 13. Enjoy five action-packed days of intense pickleball battles, where top players will compete for gold in the heart of the city.",
		link: "venue/dwight-davis-tennis-center",
		image: Config.getCDNUrl(`/${Config.getCDNFolder()}/images/appLP/Event/StLouisOpen.png`),
		button: true
	},
	{
		dateLocation: "aug 23-27 - philadelphia, pa",
		title: "APP philadelphia Open",
		description: "The 2023 APP Philadelphia Open descends upon the City of Brotherly Love from August 23-27! Join us at the Upper Main Line YMCA as pro and amateur pickleball players of all levels go head-to-head for five days of exhilarating pickleball action.",
		link: "#",
		image: Config.getCDNUrl(`/${Config.getCDNFolder()}/images/appLP/Event/PhiladelphiaOpenOpen.png`),
		buttonLink: "Coming Soon",
		button: true
	},
	{
		dateLocation: "AUG 30 - SEP 3 - Chicago, IL",
		title: "APP Chicago Open",
		description: "The 2023 APP Chicago Open is here to take the Windy City by storm! From August 30 to September 3, prepare for a week of pickleball excellence as top pro and amateur players from around the world gather to compete in one of the most anticipated tournaments of the year.",
		link: "#",
		image: Config.getCDNUrl(`/${Config.getCDNFolder()}/images/appLP/Event/ChicagoOpen.png`),
		buttonLink: "Coming Soon",
		button: true
	},
	{
		dateLocation: "SEP 6-10 - Avalon, NJ",
		title: "APP Sunmed New Jersey Open",
		description: "The 2023 New Jersey Open is set to make waves in the picturesque town of Avalon, NJ, from September 6 to 10. Prepare for an incredible showcase of pickleball talent as top pro and amateur players from near and far gather to compete in this highly anticipated tournament.",
		link: "#",
		image: Config.getCDNUrl(`/${Config.getCDNFolder()}/images/appLP/Event/NewJerseyOpen.png`),
		buttonLink: "Coming Soon",
		button: true
	},
	{
		dateLocation: "SEP 27 - OCT 1 - Atlanta, GA",
		title: "APP Sunmed Atlanta Metro Open",
		description: "The 2023 APP Atlanta Metro Open is taking place from September 27 to October 1. Get ready for an extraordinary week of pickleball excitement as top pro and amateur players from around the world converge on Atlanta, Georgia.",
		link: "#",
		image: Config.getCDNUrl(`/${Config.getCDNFolder()}/images/appLP/Event/AtlantaOpen.png`),
		buttonLink: "Coming Soon",
		button: true
	},
	{
		dateLocation: "OCT 4-8 - Dallas, TX",
		title: "APP Dallas Open",
		description: "From October 4 to 8, the city of Dallas will be buzzing with excitement as top pro and amateur pickleball players from around the world come together for an unforgettable tournament. Join us at The Oasis to catch all the action.",
		link: "#",
		image: Config.getCDNUrl(`/${Config.getCDNFolder()}/images/appLP/Event/DallasOpen.png`),
		buttonLink: "Coming Soon",
		button: true
	},
];

export const APPUSARoute = () => {
	const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);

	return (
		<CommonPage>
			<div
				css={`
					background: url(${heroBg});
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					min-height: 80vh;
					max-height: 800px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: flex-start;

					@media (max-width: ${breakpoints.tablet}) {
						min-height: 30vh;
						max-height: 300px;
					}
					@media (max-width: ${breakpoints.mobile}) {
						min-height: 30vh;
						max-height: 300px;
					}
				`}
			>
				<img
					src={headerLogo}
					alt="logo"
					css={`
						max-width: 60%;

					`}
				/>
				<TitleHero>USA PICKLEBALL SANCTIONED EVENTS & OFFICIAL PRO TOUR</TitleHero>
				<img
					src={USAPickeball}
					alt="pickeball"
					css={`
						max-width: 10%;
						margin-top: 0.5em;
						padding-left: 1.2em;

						@media (max-width: ${breakpoints.tablet}) {
							max-width: 25%;
						}
						@media (max-width: ${breakpoints.mobile}) {
							max-width: 25%;
						}
					`}
				/>
			</div>

			<SectionEventsContainer>
				<MultiCardContainer>
					<TitleContainer>Upcoming Events</TitleContainer>

					{isMobile ? (
						<Carousel hideArrow={true}>
							{carouselEvents.map(event => (
								<Carousel.Item key={`mobile_carousel_${event.title}`}>
									<ItemInside
										css={`
											background-image: url(${event.imageCarousel});
										`}
									>
										<Index>
											<ButtonLink
												css={`
													align-items: center;
													justify-content: center;
													display: flex;
												`}
												href={event.link}
											>
												{event.linkText}
											</ButtonLink>
										</Index>
										<Detail>
											<Title>{event.title}</Title>
											<Comment>{event.dateLocation}</Comment>
										</Detail>
									</ItemInside>
								</Carousel.Item>
							))}
						</Carousel>
					) : (
						<EventsCarouselContainer>
							<Carousel cols={3} rows={1} gap={50} loop hideArrow={true}>
								{carouselEvents.map(event => (
									<Carousel.Item key={`desktop_carousel_${event.title}`}>
										<Item image={event.imageCarousel} dateLocation={event.dateLocation} title={event.title} link={event.link} linkText={event.linkText} />
									</Carousel.Item>
								))}
							</Carousel>
						</EventsCarouselContainer>
					)}
				</MultiCardContainer>

				<MultiCardContainer>
					<div
						css={`
							align-items: center;
							justify-content: center;
							display: flex;
							flex-direction: column;
						`}
					>
						{upcomingEvents.map((item, index) => (
							<EventCard image={item.image} key={`upcoming_${item.title}`} link={item.link} title={item.title} dateLocation={item.dateLocation} text={item.description} index={index} buttonLink={item.buttonLink} button={item.button} />
						))}
					</div>
				</MultiCardContainer>
			</SectionEventsContainer>

			<TwoColumnDiv>
				<LeftColumn>
					<img
						src={Config.getCDNUrl(`/${Config.getCDNFolder()}/images/appLP/AI-Image-9.png`)}
						alt="sports ilustraded"
						css={`
							max-width: 100%;

							@media (max-width: ${breakpoints.tablet}) {
								max-width: 90%;
							}
							@media (max-width: ${breakpoints.mobile}) {
								max-width: 90%;
							}
						`}
					/>
				</LeftColumn>
				<RightColumn>
					<TitleWhite>Get your own sports illustrated</TitleWhite>
					<SubtitleDark>FAN cover</SubtitleDark>

					<NumberList>
						<li>
							Email your photo to covers@sitickets.com. <br />
							Entries are limited to one entry per person per week.
						</li>
						<li>The SI Tickets team will review your photo**</li>
						<li>Within 7 days, you'll receive your very own SI fan cover.</li>
						<li>
							Post your cover on social and tag SI Tickets: <br />
							Instagram: SI.Tickets <br />
							Twitter: SI_Tickets_ <br />
							Facebook: SITickets
						</li>
					</NumberList>

					<ButtonLink
						css={`
							align-items: center;
							justify-content: center;
							display: flex;
						`}
						href={"covers"}
					>
						Learn more
					</ButtonLink>
				</RightColumn>
			</TwoColumnDiv>
		</CommonPage>
	);
};

const NumberList = styled.ol`
	text-align: left;
	font-weight: 600;
	font-size: 16px;
	line-height: 21px;
	color: #ffffff;
	font-family: ${FontFamily.Nunito};
	list-style-type: none;
	counter-reset: list;
	text-indent: -22px;
    margin-left: 25px;
	padding: 0;
	margin: 0;
	& > li {
		counter-increment: list;
		margin-bottom: 2em;
	}
	& > li:before {
		content: counter(list) ".";
		margin-right: 0.5em;
	}
`;

const TwoColumnDiv = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	background: linear-gradient(to bottom, #64bece, #4491a8);
	gap: 20px;
	padding: 4em;
	justify-content: center;

	@media (max-width: ${breakpoints.tablet}) {
		grid-template-columns: 1fr;
		gap: 0;
	  }
`;

const LeftColumn = styled.div`
	padding: 20px;
`;

const RightColumn = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
	justify-content: center;
	align-items: flex-start;
`;

const TitleHero = styled.h1`
	text-transform: uppercase;
	font-family: ${FontFamily.Venus};
	font-style: italic;
	font-weight: 800;
	font-size: 38px;
	line-height: 53px;
	text-align: left;
	letter-spacing: 0.03em;
	color: #ffffff;
	width: 45%;
	margin-top: 0.5em;
	padding-left: 1.2em;

	@media (max-width: ${breakpoints.tablet}) {
		width: 90%;
		font-size: 18px;
	}
	@media (max-width: ${breakpoints.mobile}) {
		font-size: 18px;
		width: 90%;
		line-height: 20px;
	}
`;

const TitleContainer = styled.h1`
	text-transform: uppercase;
	font-family: ${FontFamily.Venus};
	margin-top: 1em;
	margin-bottom: 1em;
	font-style: normal;
	font-weight: 600;
	font-size: 48px;
	line-height: 53px;
	text-align: center;
	letter-spacing: 0.03em;
	color: #000000;

	@media (max-width: ${breakpoints.tablet}) {
		font-size: 25px;
	}
	@media (max-width: ${breakpoints.mobile}) {
		font-size: 25px;
		margin-top: 0.5em;
		margin-bottom: 0.5em;
		line-height: 30px;
		letter-spacing: 0px;
	}
`;

const TitleWhite = styled.h1`
	text-transform: uppercase;
	font-family: ${FontFamily.Venus};
	font-style: normal;
	font-size: 31px;
	text-align: left;
	font-weight: 600;
	font-size: 31px;
	line-height: 40px;
	letter-spacing: 0.015em;
	color: #ffffff;

	@media (max-width: ${breakpoints.tablet}) {
		font-size: 25px;
		line-height: 30px;
	}
	@media (max-width: ${breakpoints.mobile}) {
		font-size: 25px;
		line-height: 30px;
		letter-spacing: 0px;
	}
`;

const SubtitleDark = styled.h1`
	text-transform: uppercase;
	font-family: ${FontFamily.Venus};
	font-style: italic;
	font-weight: 900;
	font-size: 56px;
	line-height: 145%;
	letter-spacing: 0.015em;
	color: #2D374;
	margin-bottom: 0.5em;

	@media (max-width: ${breakpoints.tablet}) {
		font-size: 25px;
		line-height: 30px;
	}
	@media (max-width: ${breakpoints.mobile}) {
		font-size: 25px;
		line-height: 30px;
		letter-spacing: 0px;
	}
`;

const MultiCardContainer = styled.div`
	@media (max-width: ${breakpoints.large}) {
		display: grid;
	}
`;

export const SectionEventsContainer = styled.div`
	margin-top: 80px;
	align-items: center;
	align-content: center;
	justify-content: center;

	@media (max-width: ${breakpoints.large}) {
		margin-top: 0;
	}

	@media (max-width: ${breakpoints.large}) {
		& > * {
			border-bottom: none;
		}
	}
`;

const Detail = styled.div`
	position: absolute;
	bottom: 0;
	color: #fff;
	padding: 15px;
	width: 100%;
	box-sizing: border-box;
	background: linear-gradient(0deg, black, transparent);
	padding-top: 120px;
	border-radius: 8px;
`;

const Title = styled.div`
	font-size: 20px;
	font-weight: bold;
`;
const Comment = styled.div`
	font-size: 16px;
	font-weight: bold;
`;

const ItemInside = styled.div`
	position: relative;
	height: 205px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: 8px;
`;

const Index = styled.div`
	position: absolute;
	color: #ffffffc9;
	padding: 2px 8px;
	font-size: 12px;
	border-radius: 20px;
	top: 5px;
	right: 5px;
`;

const EventsCarouselContainer = styled.div`
	display: flex;
	justify-content: center;
`;
