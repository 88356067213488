import React from "react";
import { CoverAssets } from "../../../services/types/AssetResponse";
import { MainHeadlineContainer, SecondaryHeadlineContainer, SecondaryHeadline, PerformerHeadline, Children, Container, Banner, Overlay, StyledLink, StyledText, Images } from "./styles";
import { Config } from "../../../Config";
import styled from "styled-components/macro";
import { isMobileApp } from "../../util/isMobileApp";
import { useIsMobileWeb } from "../../hooks/useIsMobileWeb";

export interface CoverProps {
	assets: CoverAssets | null;
	className?: string;
}

export const Cover = ({ children, className, assets }: React.PropsWithChildren<CoverProps>) => {
	const getImageProps = (key: keyof CoverAssets) => {
		if (assets) {
			const image = assets[key];
			if (image) {
				return {
					src: image.url || undefined,
					hasLink: !!image.link,
					onClick: () => image.link && window.location.replace(image.link),
				};
			}
		}
		return {};
	};

	const isMobileWeb = useIsMobileWeb(540);
	return (
		<Container className={className}>
			<PrimaryColor
				style={{
					background: assets?.primary_color === "#ffffff" ? undefined : assets?.primary_color,
				}}
			/>
			<Banner
				src={Config.getResourceUrl(assets?.banner_image) || undefined}
				style={{
					backgroundPosition: assets?.banner_style.objectPosition + " top",
				}}
			/>
			<Overlay>
				<Images.Image1 {...getImageProps("header_image_1")} />
				<Images.Image2 {...getImageProps("header_image_2")} />
				<Images.Image3 {...getImageProps("header_image_3")} />
				<Images.Image4 {...getImageProps("header_image_4")} />
				<Images.Image5 {...getImageProps("header_image_5")} />
				<Images.Image6 {...getImageProps("header_image_6")} />
				<Images.Image7 {...getImageProps("header_image_7")} />
				<MainHeadlineContainer>
					{!isMobileApp && renderStyledText(assets?.main_headline_1)}
					{renderStyledText(assets?.main_sub_headline_1)}
				</MainHeadlineContainer>
				<SecondaryHeadlineContainer>
					<PerformerHeadline isTablet={isMobileWeb}>
						{renderStyledText(assets?.main_headline_2)}
						{renderStyledText(assets?.main_sub_headline_2)}
						{isMobileApp && renderStyledText(assets?.main_headline_1)}
					</PerformerHeadline>
					<Children>{children}</Children>
					<SecondaryHeadline>
						{renderStyledText(assets?.main_headline_3)}
						{renderStyledText(assets?.main_sub_headline_3)}
					</SecondaryHeadline>
				</SecondaryHeadlineContainer>
			</Overlay>
		</Container>
	);
};

interface HeaderText {
	text: string;
	link: string | null;
	style: any;
}

const renderStyledText = (asset?: HeaderText) =>
	asset &&
	asset.text && (
		<StyledText fontSize={asset.style.fontSize} style={asset.style}>
			<StyledLink to={asset.link || "#"}>{asset.text}</StyledLink>
		</StyledText>
	);

const PrimaryColor = styled.div`
	height: 100%;
	position: absolute;
	width: 100%;
`;
