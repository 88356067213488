import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAppColor } from "../../../util/appColors";
import { removeTimezoneFromDateString, toBrowserLocaleString } from "../../../util/optimized/dates";
import { Typography } from "../../Typography/Typography";
import { faCalendarDays, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { useMemoState } from "../../../hooks/useMemoState";
import { TicketTransaction } from "../../../../model/optimizedModel/myAccount";
import { QRCodeCanvas } from "qrcode.react";
import { Carousel } from "../../NewCarousel/Carousel";
import arrow from "../../../resource/image/arrow-control.svg";
import { ArrowControl } from "../../NewCarousel/ArrowControl";
import { TopSectionText } from "./TopSectionText";
import { EventInfoItemContainer } from "./EventInfoItemContainer";
import { SantizedHTMLSpan } from "../../SantizedHTML/SantizedHTML";
import { BoxOfficeTicketInfo } from "../../../../model/optimizedModel/ticket";
import { breakpoints } from "../../../util/breakpoints";
import { WalletSection } from "./WalletSection";

export type TicketModalViewProps = {
	ticketTransaction: Pick<TicketTransaction, "event" | "ticketGroup" | "ticketGroups">;
};

// TODO: Moving TicketModalView and TicketModalTransfer to separate files. It currently breaks the Dialog styling.
export const TicketModalView: React.FC<TicketModalViewProps> = ({ ticketTransaction }) => {
	const formattedDate = useMemoState(() => {
		if (ticketTransaction.event.occursAt) {
			return new Date(removeTimezoneFromDateString(ticketTransaction.event.occursAt));
		}
	}, [ticketTransaction.event.occursAt]);

	const [ticketsAmount, setTicketsAmount] = useState(0);
	const [filteredTickets, setFilteredTickets] = useState<BoxOfficeTicketInfo[]>();
	const [seatNumbers, setSeatNumbers] = useState<(number | undefined)[]>();
	const [currentTicket, setCurrentTicket] = useState<BoxOfficeTicketInfo>();

	useEffect(() => {
		if (ticketTransaction.ticketGroups.some(tg => tg.tickets)) {
			const statusesToShow = [null, "cancelled", "accepted"];
			const allFilteredTickets = ticketTransaction.ticketGroups.map(tg => (tg.tickets ||[]).filter(t => (t.qrCode && ((t.transferStatus && t.transferStatus === "cancelled") || (t.status && statusesToShow.includes(t.status)))) || t.transferStatus === "pending" || !t.transferStatus)).flat();

			setTicketsAmount(allFilteredTickets.length);

			let sortedTickets = allFilteredTickets.sort((a, b) => {
				if (a.seatNumber && b.seatNumber) {
					return a.seatNumber - b.seatNumber;
				} else {
					return a.ticketId - b.ticketId;
				}
			});
			setSeatNumbers(sortedTickets.map(ticket => ticket.seatNumber));

			setFilteredTickets(sortedTickets);
		}
	}, [ticketTransaction.ticketGroups]);

	const [currentIndex, setCurrentIndex] = useState(1);
	const [generationDate, setGenerationDate] = useState(new Date());
	const ONE_MINUTE_MS = 60000;
	const currentTicketGroup = ticketTransaction.ticketGroups.find(tg => tg.id === (filteredTickets ?? [])[currentIndex]?.ticketGroupId)

	useEffect(() => {
		const interval = setInterval(() => {
			setGenerationDate(new Date());
		}, ONE_MINUTE_MS);
		return () => clearInterval(interval);
	}, []);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				width: "100%",
			}}
		>
			{currentTicketGroup && currentTicketGroup.tierImage && currentTicketGroup.tierImage.startsWith("http") && (
				<img
					src={currentTicketGroup.tierImage}
					alt="TicketGroup Tier"
					style={{
						maxHeight: "200px",
						margin: "0 -20px",
					}}
				/>
			)}
			<div
				css={`
					display: flex;
					flex-direction: column;
					justify-content: space-between;
				`}
			>
				<div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							marginTop: "10px",
							alignItems: "flex-start",
						}}
					>
						<TopSectionText type="bodyLarge" color="light" fontWeight={500}>
							{ticketTransaction.event.name && <SantizedHTMLSpan htmlString={ticketTransaction.event.name} />}
						</TopSectionText>
					</div>
					<div
						style={{
							display: "grid",
							gridTemplateColumns: "repeat(2, 1fr)",
							marginTop: "20px",
							flexWrap: "wrap",
							gap: "15px",
						}}
					>
						{formattedDate && (
							<>
								<EventInfoItemContainer>
									<FontAwesomeIcon
										style={{
											height: "15px",
										}}
										icon={faCalendarDays}
										color={getAppColor("light")}
									/>
									<Typography
										type="bodySmall"
										color="light"
										fontWeight={500}
										style={{
											textAlign: "left",
											whiteSpace: "pre",
										}}
									>
										{toBrowserLocaleString(formattedDate, {
											month: "short",
										})}
										,{" "}
										{toBrowserLocaleString(formattedDate, {
											day: "2-digit",
										})}
										, {formattedDate?.getFullYear()} <br />
										{toBrowserLocaleString(formattedDate, {
											hour: "numeric",
											minute: "2-digit",
										})}
									</Typography>
								</EventInfoItemContainer>
								<EventInfoItemContainer style={{justifyContent: "end"}}>
									<FontAwesomeIcon
										style={{
											height: "15px",
										}}
										icon={faLocationDot}
										color={getAppColor("light")}
									/>
									<Typography
										type="bodySmall"
										color="light"
										fontWeight={500}
										style={{
											textAlign: "left",
											whiteSpace: "pre",
										}}
									>
										{ticketTransaction.event.venue.name} <br />
										{ticketTransaction.event.venue.address.city}, {ticketTransaction.event.venue.address.state}
									</Typography>
								</EventInfoItemContainer>
							</>
						)}
						<Typography
							type="bodySmall"
							color="light"
							fontWeight={500}
							style={{
								textAlign: "left",
								whiteSpace: "pre",
							}}
						>
							{currentTicketGroup && currentTicketGroup.section && <SantizedHTMLSpan htmlString={currentTicketGroup.section} />}
						</Typography>
						<Typography
							type="bodySmall"
							color="light"
							fontWeight={500}
							style={{
								textAlign: "left",
								whiteSpace: "pre",
								paddingLeft: "26px",
							}}
						>
							{currentTicketGroup && currentTicketGroup.assignedSeating && (
								<>
									{seatNumbers?.length && seatNumbers.length > 1 ? "Seats " : "Seat "}

									{seatNumbers?.map((seatNumber, index) => (
										<span>
											{seatNumber}
											{index !== seatNumbers.length - 1 && ", "}
										</span>
									))}
								</>
							)}
						</Typography>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							margin: "25px -20px 5px -20px",
						}}
					>
						<Typography type="bodyLarge" color="light" fontWeight={600}>
							Super Ticket
						</Typography>
						{filteredTickets && (
							<div
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "center",
									position: "relative",
								}}
							>
								<div
									style={{
										position: "absolute",
										top: "40%",
										width: "100%",
										height: "50px",
									}}
								>
									<ArrowControl
										src={arrow}
										alt="Left Control"
										direction="left"
										disabled={currentIndex === 0}
										onClick={() => {
											if (currentIndex > 0) {
												setCurrentIndex(currentIndex - 1);
											} else {
												//TODO: Find a better way to do the following check. This was added because ReactNode[] doesn't support single element arrays
												setCurrentIndex(Array.isArray(filteredTickets) ? filteredTickets?.length - 1 : 0);
											}
										}}
									/>
									<ArrowControl
										src={arrow}
										alt="Right Control"
										direction="right"
										disabled={currentIndex === (Array.isArray(filteredTickets) && filteredTickets?.length - 1)}
										onClick={() => {
											if (Array.isArray(filteredTickets) && currentIndex < filteredTickets?.length - 1) {
												setCurrentIndex(currentIndex + 1);
											} else {
												setCurrentIndex(0);
											}
										}}
									/>
								</div>
								<div
									style={{
										maxWidth: "280px",
									}}
								>
									<div
										style={{
											background: "white",
											padding: "10px",
										}}
									>
										<Carousel
											index={currentIndex}
											autoplay={false}
											hideIndicators
											disabled
											hideArrows
											onSlideChange={currentIndex => {
												setCurrentIndex(currentIndex);
											}}
										>
											{filteredTickets?.map((ticket, index) => {
												return (
													<QRCodeCanvas
														key={index}
														level="M"
														size={256}
														value={JSON.stringify({
															qrCode: ticket.qrCode,
															eventSlug: ticketTransaction.event.slug,
															childrenEventsSlugs: ticketTransaction.event.childrenEventsSlugs,
															generationDate: generationDate,
														})}
														imageSettings={{
															src: "https://theme.zdassets.com/theme_assets/2445692/cd54bdf315b70c3ba545d5c8b4968caadff08a30.png",
															x: undefined,
															y: undefined,
															height: 40,
															width: 100,
															excavate: true,
														}}
													/>
												);
											})}
										</Carousel>
									</div>
									<div
										style={{
											background: getAppColor("primary"),
										}}
									>
										<Typography type="bodyMedium" color="light" fontWeight={700}>
											Ticket {currentIndex + 1}/{ticketsAmount}
											{currentTicketGroup && currentTicketGroup.assignedSeating && <span> - Seat {seatNumbers && seatNumbers[currentIndex]} </span>}
										</Typography>
									</div>
								</div>
							</div>
						)}
						<div
							style={{
								marginTop: "10px",
								padding: "15px",
							}}
						>
							<Typography type="bodySmall" color="light" style={{ maxWidth: "400px" }}>
								This QR code will only be applicable in this account view. Screenshots of the QR code will not be accepted.
							</Typography>
						</div>
					</div>
				</div>

				<div
					css={`
						padding: 10px 20px;
						margin-top: 40px;

						@media (max-width: ${breakpoints.mobile}) {
							margin-top: 60px;
						}
					`}
				>
					<WalletSection disclaimer="This NFT ticket is currently not transferable to personal wallet or available for resale on NFT marketplaces." />
				</div>
			</div>
		</div>
	);
};
