import { useEffect, useState } from "react";
import { Optional } from "../../../../model/Utils";

const TIME_THRESHOLD = 24;
export const useEventDateCheck = (occursAt: Optional<Date>, endTime: Optional<Date>) => {
	const [eventIsOld, setEventIsOld] = useState(false);

	useEffect(() => {
		// Here we compare if the event date is older than the current, in that case we redirect the user
		if (!!endTime) {
			setEventIsOld(new Date(endTime) < new Date());
		} else if (!!occursAt) {
			const timeLimit = new Date(occursAt);
			timeLimit.setHours(timeLimit.getHours() + TIME_THRESHOLD);

			setEventIsOld(timeLimit < new Date());
		} else {
			setEventIsOld(false);
		}
	}, [endTime, occursAt]);

	return eventIsOld;
};
