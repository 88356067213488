import React from "react";
import styled from "styled-components/macro";
import { SearchItem } from "../../../services/actions/mainSearchService";
import { EventSearchItem } from "../../../services/types/MainSearchResponseCodec";
import { useIsMobileWeb } from "../../hooks/useIsMobileWeb";
import { isMobileApp } from "../../util/isMobileApp";
import { maxContentWidthPadding } from "../../util/maxContentWidth";
import { Event } from "../../../model/Event";

export const SearchResultsSection = ({ displayedItems = 5, ...props }: { title: string; items: SearchItem[] | EventSearchItem[] | Event[] | null | undefined; displayedItems?: number; mappingFunction: (value: any[], length: number) => JSX.Element[]; disableResultHeader?: boolean }) => {
	const isMobileWeb = useIsMobileWeb();
	const isMobile = isMobileWeb || isMobileApp;

	return (
		<>
			{!!props.items?.length && (
				<>
					{!props.disableResultHeader && <ResultHeader isMobile={isMobile} title={props.title} />}
					{props.mappingFunction(props.items, displayedItems)}
				</>
			)}
		</>
	);
};

export const ResultHeader = ({ isMobile, title }: { isMobile: boolean; title: string }) => {
	return (
		<ResultTitle isMobile={isMobile}>
			<Title>{title}</Title>
		</ResultTitle>
	);
};

const resultsSideMargin = maxContentWidthPadding + "px";

const Title = styled.div`
	text-transform: uppercase;
`;

const ResultTitle = styled.div<{ isMobile: boolean }>`
	display: ${props => (props.isMobile ? "flex" : "table")};
	width: 100%;
	padding: 15px ${resultsSideMargin};
	text-align: center;
	background: #eee;
	font-familiy: Montserrat;
	font-size: 16px;
	font-weight: 700;
	color: #050f1a;
	text-align: left;
	box-sizing: border-box;
	align-items: center;

	margin-bottom: 10px;
	&:not(:first-child) {
		margin-top: 10px;
	}

	${props =>
		props.isMobile &&
		`
		padding-left:12px;
		position: relative;
	`};
`;
