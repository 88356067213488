import React, { useEffect, useState } from "react";
import { DateUtils } from "react-day-picker";
import { DateTime } from "luxon";
import { DateRangeModalContent } from "../Filters/styles";
import "react-day-picker/lib/style.css";
import styled from "styled-components/macro";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { NavControls } from "../NavControls/NavControls";
import { breakpoints } from "../../util/breakpoints";
import { Typography, FontFamily, getTypographyStyles } from "../Typography/Typography";
import { Button } from "../NewButton/Button";
import { getAppColor } from "../../util/appColors";
import { Dialog } from "../Dialog/Dialog";
import { ResetFiltersControl, StyledResetIcon, DialogTitle, FiltersDialogContent, ApplyFiltersButton } from "../Filters/NewMultiFilterContainer";
import { maxContentWidthPadding } from "../../util/maxContentWidth";
import DayPicker from "react-day-picker";
import { maxContentWidth } from "../../util/maxContentWidth";
import { formatDate } from "../../util/dateFormat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { Chip } from "../Chip/Chip";

/**
 * Date range represented as ISO Date string (yyyy/MM/dd).
 */
const defaultRangeState = { from: undefined, to: undefined };

export interface DateRange {
	from?: string;
	to?: string;
}

export const NewDateRange = (props: { title?: string; text?: string; from: string | null | undefined; to: string | null | undefined; onChange: (dates: DateRange) => void; newDesign?: boolean }) => {
	const [{ from, to }, setRange] = useState<{
		from?: Date;
		to?: Date;
	}>({});
	let selectedDays = [];
	if (from) {
		selectedDays.push(from);
	}
	if (from && to) {
		selectedDays.push({ from, to });
	}

	// Update local state on props change
	useEffect(() => {
		setRange({
			from: props.from ? DateTime.fromISO(props.from).toJSDate() : undefined,
			to: props.to ? DateTime.fromISO(props.to).toJSDate() : undefined,
		});
	}, [props.from, props.to]);

	// Actions
	const onDayClick = (day: Date) => {
		//Have to remove the hour and minutes because otherwise user cannot select today as a valid date
		const now = new Date().setHours(0, 0, 0, 0);
		const selected = day.setHours(0, 0, 0, 0);

		const validDate = selected >= now;
		if (validDate) setRange(DateUtils.addDayToRange(day, { from, to } as any));
	};

	const closeModal = () => {
		const fromISO = from && DateTime.fromJSDate(from).toISODate();
		const toISO = to && DateTime.fromJSDate(to).toISODate();
		setShowDateModal(false);
		props.onChange({
			from: fromISO,
			to: toISO !== fromISO ? toISO : undefined,
		});
	};

	const isMobileScreen = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
	const isMediumScreen = useMediaQuery(`(max-width: ${maxContentWidth})`);
	const [showDateModal, setShowDateModal] = useState(false);

	const getAmountOfMonthsToShow = () => {
		if (isMobileScreen) {
			return 60;
		} else if (isMediumScreen) {
			return 1;
		}
		return 2;
	};

	return (
		<>
			<DateRangeContainer>
				<Typography type="heading3">{props.title}</Typography>
				{!props.newDesign ? (
					<DateButton variant="outline" onClick={() => setShowDateModal(true)} color="light" style={{ height: 41, fontFamily: FontFamily.Poppins, fontSize: 16, textTransform: "none", borderColor: getAppColor("darkGrey", "dark") }}>
						<DateRangeText>{props.text ? props.text : "All Dates"}</DateRangeText>
					</DateButton>
				) : (
					<Chip 
						onClick={() => setShowDateModal(true)}
						selected={!!(from && to)}
					>
						<FontAwesomeIcon icon={faCalendar} css={`height: 15px; margin-right: 6px;`} /> <span>{from && to ? `${formatDate(from, "MM/dd")} - ${formatDate(to, "MM/dd")}` : "Date"}</span>
					</Chip>
				)}
			</DateRangeContainer>
			{showDateModal && (
				<DateDialog
					open={showDateModal}
					onClose={() => {
						setShowDateModal(false);
					}}
					type="utility"
					fullscreenOnMobile
					headerChildren={
						<DateDialogHeader>
							<ResetFiltersControl
								onClick={() => {
									setRange(defaultRangeState);
								}}
							>
								Reset
								<StyledResetIcon />
							</ResetFiltersControl>
							<DialogTitle type="heading3">Choose Date</DialogTitle>
						</DateDialogHeader>
					}
				>
					<FiltersDialogContent>
						<DateRangeModalContent>
							<NewDayPicker numberOfMonths={getAmountOfMonthsToShow()} selectedDays={selectedDays} modifiers={{ start: from, end: to }} onDayClick={onDayClick} disabledDays={{ before: new Date() }} navbarElement={props => <NavControls {...props} />} canChangeMonth={!isMobileScreen} />
							<ButtonContainer>
								<MobileDivider />
								<ApplyFiltersButton color="primary" onClick={closeModal}>
									Apply
								</ApplyFiltersButton>
							</ButtonContainer>
						</DateRangeModalContent>
					</FiltersDialogContent>
				</DateDialog>
			)}
		</>
	);
};

const SingleDateButton = styled(Button)`
	display: flex;
	justify-content: space-between;
	border: 1px solid ${getAppColor("darkGrey")};
	border-radius: 6px;

	& > * {
		background-color: ${getAppColor("light")};
		color: ${getAppColor("darkGrey", "dark")} !important;
		padding: 10px 6px !important;
		border-radius: 6px;

		&:hover {
			background-color: ${getAppColor("lightGrey")} !important;
		}
	}

	span {
		${getTypographyStyles("bodySmall")};

		&:first-child {
			text-transform: capitalize;
			margin-left: 10px;
		}

		&:last-child {
			font-weight: 700 !important;
			margin: 0 20px;
		}
	}
`;

const DateDialog = styled(Dialog)`
	@media (max-width: ${breakpoints.mobile}) {
		& img.close-button {
			position: fixed;
			right: ${maxContentWidthPadding}px;
			z-index: 1;
			margin-top: 3px;
		}
	}
`;

const DateRangeContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const DateButton = styled(Button)`
	width: 100%;
	margin-top: 11px;
	& > * {
		padding: 3.5px 7px !important;
		text-align: left;
	}
`;

const DateRangeText = styled.span`
	overflow: hidden;
	flex: 1;
`;

const DateDialogHeader = styled.div`
	display: flex;
	overflow: hidden;
	top: 0px;
	background: #fff;
	z-index: 1;
	@media (max-width: ${breakpoints.mobile}) {
		position: fixed;
		width: calc(100% - ${2 * maxContentWidthPadding}px);
		padding: ${maxContentWidthPadding}px 0px;
		border-bottom: solid 1px ${getAppColor("lightGrey", "dark")};
	}
`;

const ButtonContainer = styled.div`
	@media (max-width: ${breakpoints.mobile}) {
		position: fixed;
		top: 84%;
		height: 150px;
		width: calc(100% - 60px);
		background: #ffffff;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
`;

const MobileDivider = styled.div`
	width: 100%;
	@media (max-width: ${breakpoints.mobile}) {
		border-bottom: 1px solid ${getAppColor("lightGrey", "dark")};
	}
`;

const NewDayPicker = styled(DayPicker)`
	& .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
		background-color: ${getAppColor("primary", "subtle")} !important;
		color: ${getAppColor("darkGrey", "dark")};
	}
	& .DayPicker-Day {
		border-radius: 0 !important;
		color: ${getAppColor("darkGrey", "dark")};
	}
	& .DayPicker-Day--disabled {
		color: ${getAppColor("darkGrey", "light")};
	}
	& .DayPicker-Day--start:not(.DayPicker-Day--outside),
	.DayPicker-Day--end:not(.DayPicker-Day--outside) {
		background-color: ${getAppColor("primary")} !important;
		font-weight: 700;
	}
	& .DayPicker-wrapper {
		padding-bottom: 0;
	}
	& .DayPicker-Month {
		padding: 0 1px;
		margin: 1em 2.5em;
		font-family: ${FontFamily.Poppins};
	}
	& .DayPicker-Day--today {
		color: ${getAppColor("primary")};
	}
	font-size: 18px;
	border-bottom: 1px solid ${getAppColor("primary", "subtle")};

	@media (max-width: ${breakpoints.mobile}) {
		border-bottom: none;
	}
	padding: 0 20px;
`;
