import React, { useMemo, useRef } from "react";
import { EventData, EventSearchParams } from "../../../model/EventPage";
import { Nulleable } from "../../../model/Utils";
import { SeaticsLegendItem, SeaticsTicketGroup, SeaticsTicketSegment } from "../Seatics/types";
import { Panel, PanelBody, SeatingZoneHeader, SeatingZoneRow, FilterButton, FilterButtonContent, FiltersTypography } from "./styles";
import { TicketSegment } from "./TicketSegment";
import { VenueLevel } from "./VenueLevel"
import styled from "styled-components/macro";
import { getAppColor } from "../../util/appColors";
import { useSelector } from "react-redux";
import { Typography } from "../Typography/Typography";
import { EventItem } from "../Event/NewEvent";
import { CarouselRow } from "../CarouselRow/CarouselRow";
import { NewSeats } from "../Event/NewSeatsHeader";
import { createResponsiveLayout, MOBILE_BREAKPOINT_PX } from "../../util/ResponsiveLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders } from "@fortawesome/free-solid-svg-icons";
import { isMobileApp } from "../../util/isMobileApp";
import { useIsMobileWeb } from "../../hooks/useIsMobileWeb";
import { Config } from "../../../Config";
const ticketListBanner = Config.getCDNUrl(`/${Config.getCDNFolder()}/images/events/view-seats-banner.png`);

const ListHeaderLayout = createResponsiveLayout({
	mobile: MOBILE_BREAKPOINT_PX,
});

interface TicketListProps {
	filters: EventSearchParams;
	legendItems: Nulleable<SeaticsLegendItem[]>;
	visibleTickets: Nulleable<SeaticsTicketSegment[]>;
	onFilterClick: () => void;
	onTicketClick: (ticket: SeaticsTicketGroup) => void;
	onTicketHover: (ticket: Nulleable<SeaticsTicketGroup>) => void;
	onSectionHover: (section: Nulleable<SeaticsLegendItem>) => void;
	onSelectSection: (section: SeaticsLegendItem) => void;
	enableFilters: boolean;
	selectedSection: Nulleable<SeaticsLegendItem>;
	eventData: Nulleable<EventData>;
	showMap: boolean;
	setShowMap: (hideMap: boolean) => void;
	showMapClicked: boolean;
	vfsDisabled?: boolean;
}

export const TicketList = React.memo((props: TicketListProps) => {
	const panelRef = useRef<HTMLDivElement>(null);
	const isMobileWeb = useIsMobileWeb();
	const isMobile = isMobileWeb || isMobileApp;

	const panelOffset = panelRef?.current?.getBoundingClientRect() || { top: 0 };
	const tickets = useMemo(() => props.visibleTickets && props.visibleTickets.filter(ticketFilter).map((segment, index) => <TicketSegment key={index} segment={segment} ticketClick={props.onTicketClick} ticketHover={props.onTicketHover} panelWidth={panelRef.current ? panelRef.current.offsetWidth : 0} panelTop={panelOffset.top} vfsDisabled={props.vfsDisabled} />), [props.visibleTickets, props.onTicketClick, props.onTicketHover, props.vfsDisabled]);

	const levelFilters = useMemo(() => {
		if (props.legendItems) {
			return props.legendItems.sort((a, b) => a.getFromPrice() - b.getFromPrice() || a.id - b.id).map((section, index) => <VenueLevel key={index} section={section} onSelectSection={props.onSelectSection} legendItems={props.legendItems} onSectionHover={props.onSectionHover} />);
		}
		return null;
	}, [props.legendItems, props.onSelectSection]);

	const { eventData } = useSelector(state => state.transient.event);

	return (
		<Panel ref={panelRef}>
			{props.eventData && (
				<ListHeaderLayout.Container>
					<ListHeaderLayout.Layout breakpoint="default">
						<NewSeats eventTitle={props.eventData.name} eventDate={props.eventData.date} location={props.eventData.venueLocation ?? "TBD"} venue={props.eventData.venueName || ""} noMargin />
					</ListHeaderLayout.Layout>
					<ListHeaderLayout.Layout breakpoint="mobile">{null}</ListHeaderLayout.Layout>
				</ListHeaderLayout.Container>
			)}
			{props.legendItems && props.legendItems.length > 0 && (
				<>
					<SeatingZoneRow>
						<SeatingZoneHeader>Seating Zones</SeatingZoneHeader>
						<FilterButtonContent>
							<FiltersTypography>FILTERS</FiltersTypography>
							<FilterButton onClick={props.onFilterClick}>
								<FontAwesomeIcon icon={faSliders} size={"lg"} />
							</FilterButton>
						</FilterButtonContent>
					</SeatingZoneRow>
					<StyledCarouselRow inlineButtons={true} showButtonsOnMobile={true} items={levelFilters || []} />
				</>
			)}
			<img 
				src={ticketListBanner} 
				alt="Your Ticket to Every Game, Concert, or Show | Backed by Our Fan Guarantee" 
				css={`
					width: 100%;
					height: auto;
					max-width: 100%;
					max-height: 100%;
					object-fit: contain;
				`}
			/>

			<PanelBody onScroll={() => isMobile && !props.showMapClicked && props.setShowMap(false)}>
				<div>
					{tickets}
					{eventData && eventData.id === 467188 && (
						<>
							<Typography
								type="largeSpecialBody"
								css={`
									padding: 0 20px;
									margin-top: 16px;
									margin-bottom: 9px;
								`}
							>
								Purchase Event Tickets:
							</Typography>
							<EventItem
								event={{
									id: 359208,
									name: "UFC 276: Adesanya vs. Cannonier",
									slug: "ufc-276",
									image: "",
									venueName: "T-Mobile Arena",
									venueState: "NV",
									venueCity: "Las Vegas",
									venueId: 0,
									date: new Date("2022-07-02T19:00:00.000Z"),
									performers: [],
									venueSlug: "",
									ticketGroupPrice: 0,
									sportId: 1,
									homeVenueId: null,
									primary: false,
								}}
								css={`
									border-width: 1px 0;
									border-color: lightgray;
									border-style: solid;
								`}
								forceMobileDesign
								target="_blank"
							/>
						</>
					)}
				</div>
			</PanelBody>
		</Panel>
	);
});

const PARKING_PASS_TYPE = 3;

const ticketFilter = (segment: SeaticsTicketSegment) => {
	return segment.tickets.length > 0 && segment.type !== PARKING_PASS_TYPE;
};

const TenFlatBanner = styled.img`
	width: 100%;
	height: auto;
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
`;

const StyledCarouselRow = styled(CarouselRow)`
	background-color: ${getAppColor("light")};
	align-items: center;
	.content {
		margin: 0.5rem 0 0.5rem 1rem;
	}
`;
