import React, { useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import { CommonPage } from "../components";
import { RouteComponentProps } from "react-router-dom";
import { didLogIn, didLogOut } from "../../store/reducers/sessionReducer";
import { parseSearch } from "../util/parseSearch";
import { MetaDecorator, MetaTagData } from "../components/SEOMetadecorator/MetaDecorator";
import { useLoginRoute } from "../hooks/useLoginRoute";
import { isMobileApp } from "../util/isMobileApp";
import { AccountForm, AccountFormRef } from "../components/Forms/AccountForm";
import { getTypographyStyles, Typography } from "../components/Typography/Typography";
import { maxContentWidthPadding } from "../util/maxContentWidth";
import { useQueryStringState } from "../hooks/useQueryStringState";
import { setWalletPromo } from "../../store/reducers/promoReducer";

/**
 *  Web Login:
 *  If from prop exist, the user is redirected to the same page he was trying to access before login.
 *  Otherwise, this screen is rendered.
 */
export const LoginRoute = (props: RouteComponentProps<{}, any, { from?: Location }>) => {
	const dispatch = useDispatch();

	const { token } = parseSearch<{ token?: string }>(props.location.search);

	// allows administrators to access and operate as an user
	const impersonate = (token: string) => {
		dispatch(didLogOut());
		dispatch(didLogIn("email", token, "", false));
	};

	useEffect(() => {
		if (typeof token !== "undefined") {
			impersonate(token);
		}
	}, [token]);

	useLoginRoute(props);

	const accountForm = useRef<AccountFormRef>(null);

	const [queryStringState] = useQueryStringState<{
		promoCode?: string;
		newUser?: string;
		registerEmail?: string;
		UId?: string;
	}>();

	useEffect(() => {
		if (!!queryStringState?.registerEmail){
			accountForm.current?.setActiveForm("register");
		}

		if (!!queryStringState?.promoCode) {
			if (queryStringState?.newUser === "true") {
				accountForm.current?.setActiveForm("register");
				dispatch(setWalletPromo({ newUser: true, promoCode: queryStringState.promoCode, referrerUrl: window.location.href, uId: queryStringState.UId }));
			} else {
				dispatch(setWalletPromo({ newUser: false, promoCode: queryStringState.promoCode, referrerUrl: window.location.href, uId: queryStringState.UId }));
			}
		}
	}, [queryStringState]);

	return (
		<CommonPage background="#f7f7f7">
			<MetaDecorator title={MetaTagData.Login.title} description={MetaTagData.Login.description} />
			<div
				style={
					isMobileApp
						? {
								paddingTop: "6.5rem",
						  }
						: undefined
				}
				css={`
					width: 100%;
					max-width: 700px;
					box-sizing: border-box;
					margin: 0 auto;
					display: flex;
					flex-direction: column;
					padding: 3rem ${maxContentWidthPadding}px;
				`}
			>
				{!!accountForm.current?.activeForm && accountForm.current?.activeForm !== "guest" && (
					<div
						css={`
							margin-bottom: 20px;
							display: flex;
							flex-direction: column;
							align-items: flex-start;
						`}
					>
						<span
							css={`
								${getTypographyStyles("extraLargeSpecialBody")};
								margin-bottom: 5px;
							`}
						>
							{
								{
									register: "Sign Up",
									login: "Welcome Back",
								}[accountForm.current?.activeForm]
							}
						</span>
						<span
							css={`
								${getTypographyStyles("bodySmall")};
								margin-bottom: 5px;
								text-align: left;
							`}
						>
							{
								{
									register: (
										<Typography
											type="bodySmall"
											css={`
												display: flex;
												text-align: left;
												a {
													cursor: pointer;
												}
											`}
										>
											Already have an account?{" "}
											<strong>
												<a
													onClick={() => {
														accountForm.current?.setActiveForm("login");
													}}
												>
													Login
												</a>
												.
											</strong>
										</Typography>
									),
									login: "Please login to continue",
								}[accountForm.current?.activeForm]
							}
						</span>
					</div>
				)}
				<AccountForm defaultForm="login" ref={accountForm}>
					{accountForm.current?.activeForm === "login" && (
						<TextLink type="bodySmall" onClick={() => accountForm.current?.setActiveForm("register")}>
							Sign Up
						</TextLink>
					)}
				</AccountForm>
			</div>
		</CommonPage>
	);
};

const TextLink = styled(Typography)<{ onClick: React.MouseEventHandler<HTMLElement> }>`
	text-decoration: underline;
	cursor: pointer;
	margin-bottom: auto;
`;
