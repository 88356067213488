import * as t from "io-ts";
import { PageMetadataCodec } from "./MetadataResponseCodec";
import { StringifiedNumberCodec, StringifiedArrayCodec, NulleableStringifiedNumberCodec } from "./util/StringifiedNumberCodec";
import { RelabelCodec } from "./util/RelabelCodec";
import { DateTimeCodec } from "./util/DateTimeCodec";

const ZoneCodec = t.intersection([RelabelCodec("zone_id", "zoneId", t.number), RelabelCodec("zone_title", "zoneTitle", t.string), RelabelCodec("event_zone_id", "eventZoneId", t.number), RelabelCodec("event_zone_ticket_cost", "ticketCost", NulleableStringifiedNumberCodec), t.intersection([RelabelCodec("event_zone_max_qty", "maxQuantity", t.number), RelabelCodec("event_zone_min_qty", "minQuantity", t.number), RelabelCodec("event_zone_increment_qty", "incrementQuantity", StringifiedArrayCodec), RelabelCodec("event_zone_tiers_id", "tiersId", t.number), t.intersection([RelabelCodec("event_zone_tiers_tier_price", "tierPrice", StringifiedNumberCodec), RelabelCodec("event_zone_tiers_initial_quantity", "initialQuantity", t.number), RelabelCodec("event_zone_tiers_available_quantity", "availableQuantity", t.number), RelabelCodec("zone_image", "zoneImage", t.union([t.string, t.null])), t.intersection([RelabelCodec("event_zone_tiers_secondary_ticket_price", "secondaryPrice", NulleableStringifiedNumberCodec), RelabelCodec("event_inventory_status_id", "inventoryStatusId", t.number)])])])]);

export const FutureEventCodec = t.intersection([RelabelCodec("event_id", "id", t.number), RelabelCodec("event_title", "name", t.string), RelabelCodec("slug", "slug", t.string), RelabelCodec("event_date", "date", t.union([DateTimeCodec, t.null])), t.intersection([RelabelCodec("team_title", "teamTitle", t.string), RelabelCodec("team_slug", "teamSlug", t.string), RelabelCodec("conference_id", "conferenceId", t.number), RelabelCodec("conference_title", "conferenceName", t.string), t.intersection([RelabelCodec("stadium_title", "venueName", t.string), RelabelCodec("stadium_location", "venueLocation", t.union([t.string, t.null])), RelabelCodec("reservation_price", "price", t.union([StringifiedNumberCodec, t.null, t.number])), RelabelCodec("sortorder", "sortOrder", t.union([t.number, t.null, t.undefined]))])])]);

export const ZonedEventCodec = t.intersection([
	RelabelCodec("event_id", "id", t.number),
	RelabelCodec("event_title", "name", t.string),
	RelabelCodec("slug", "slug", t.string),
	RelabelCodec("event_date", "date", t.union([DateTimeCodec, t.null])),
	t.intersection([
		RelabelCodec("event_image", "image", t.union([t.null, t.string])),
		RelabelCodec("event_average_ticket_last_year", "averageTicketLastYear", NulleableStringifiedNumberCodec),
		RelabelCodec("event_shoowin_ticket_last_year", "shoowinTicketLastYear", NulleableStringifiedNumberCodec),
		//RelabelCodec("event_buyer_fee", "buyerFee", NulleableStringifiedNumberCodec ),
		t.intersection([
			//RelabelCodec("event_seller_fee", "sellerFee", NulleableStringifiedNumberCodec),
			RelabelCodec("team_title", "teamTitle", t.string),
			RelabelCodec("team_slug", "teamSlug", t.string),
			RelabelCodec("stadium_location", "venueLocation", t.union([t.string, t.null])),
			t.intersection([RelabelCodec("stadium_location", "venueLocation", t.union([t.string, t.null])), RelabelCodec("stadium_venue", "venueName", t.union([t.string, t.null])), RelabelCodec("stadium_image", "venueImage", t.union([t.string, t.null])), RelabelCodec("event_zone_tiers_id", "tiersId", t.union([t.number, t.null])), t.strict({ zones: t.array(ZoneCodec) })]),
		]),
	]),
]);

export const ZonedEventsCodec = t.intersection([t.strict({ metadata: t.union([PageMetadataCodec, t.null]) }), RelabelCodec("events", "data", t.array(ZonedEventCodec))]);

export const FutureEventsCodec = t.intersection([t.strict({ metadata: PageMetadataCodec }), RelabelCodec("events", "data", t.array(FutureEventCodec))]);

export type Zone = t.TypeOf<typeof ZoneCodec>;

export type ZonedEvent = t.TypeOf<typeof ZonedEventCodec>;

export type ZonedEventsResponse = t.TypeOf<typeof ZonedEventsCodec>;

export type FutureEvent = t.TypeOf<typeof FutureEventCodec>;

export type FutureEventsResponse = t.TypeOf<typeof FutureEventsCodec>;
