import React, { useState } from "react";
import { getAppColor } from "../../../util/appColors";
import { Dialog } from "../../Dialog/Dialog";
import { TicketTransaction } from "../../../../model/optimizedModel/myAccount";
import { postMyAccountTicketsTransfer } from "../../../../services/myAccount";
import toast from "react-hot-toast";
import { TicketModalView } from "./TicketModalView";
import { TicketModalTransfer } from "./TicketModalTransfer";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { SuperTicketModal } from "./SuperTicketModal";

type TicketModalProps = {
	open: boolean;
	ticketTransaction: Pick<TicketTransaction, "event" | "ticketGroup" | "ticketGroups">;
	variant: "view" | "transfer" | "resell" | "superticket";
	onClose: () => void;
};

export const TicketModal: React.FC<TicketModalProps> = ({ open, ticketTransaction, variant, onClose }) => {
	const [transferUIState, setTransferUIState] = useState<"initial" | "sending" | "transferred">("initial");

	const handleClose = () => {
		if (variant === "transfer") {
			setTransferUIState("initial");
		}

		onClose();
	};

	const handleClickTransfer = ({ email, ticketIds }: { email: string; ticketIds: number[] }) => {
		setTransferUIState("sending");

		postMyAccountTicketsTransfer({ email, ticketIds })
			.then(() => {
				setTransferUIState("transferred");
				//this is stupid we need to fix these components because they do not render properly
				window.location.reload();
			})
			.catch(errorResponse => {
				if (errorResponse.error?.message) {
					console.error(errorResponse.error?.message);
				}
				toast.error("There was an issue transferring your ticket, please try again or contact customer service at +1234567890", {});
				setTransferUIState("initial");
			});
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			type="utility"
			fullscreenOnMobile
			css={`
				background: ${getAppColor("dark")};
			`}
		>
			{variant === "view" && <TicketModalView ticketTransaction={ticketTransaction} />}
			{variant === "transfer" && <TicketModalTransfer ticketTransaction={ticketTransaction} transferUIState={transferUIState} onClickTransfer={handleClickTransfer} />}
			{variant === "resell" && <>Ticket Resell Modal Placeholder</>}
			{variant === "superticket" && <SuperTicketModal ticketTransaction={ticketTransaction} />}
		</Dialog>
	);
};
