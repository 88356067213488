import React from "react";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";
import { ReactComponent as AccentLeft } from "../../resource/assets/accents/parallelogram_accent_left.svg";
import { ReactComponent as AccentRight } from "../../resource/assets/accents/parallelogram_accent_right.svg";
import { getAppColor } from "../../util/appColors";

export const ParallelogramAccent = (props: {
	position: "left" | "right",
} & StyledComponentProps) => {
	return React.createElement({
		left: AccentLeft,
		right: AccentRight,
	}[props.position], {
		style: {
			fill: getAppColor("primary"),
			width: "auto",
			[`margin${{
				left: "Left",
				right: "Right",
			}[props.position]}`]: -3,
		},
		className: props.className,
	});
};