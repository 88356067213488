import { validEmailRegex, validPOBoxRegex } from "./regexes";
import { parsePhoneNumberFromString } from "libphonenumber-js/max";

export const defaultRequiredErrorMessage = "This value is required.";

export const required = (value: string | number | null | undefined, message = defaultRequiredErrorMessage) => {
	if (value === "" || value === null || value === undefined) {
		return message;
	}
};

export const combineValidators =
	(...args: any[]) =>
	(value = "") =>
		args.reduce((error, validator) => error || validator(value), undefined);

export const checkWhiteSpace = (value = "") => {
	return /^\s+|\s+$/g.test(value) ? "Please remove space." : undefined;
};

export const onlyWholeNumbers = (value = "") => {
	return value.replace(/\D+/g, "");
};

/*
//TODO: Create a working version of this.
export const onlyNumbers = (value = "") => {
  return value.replace(/[a-z] + [^0-9\s.]+|.(?!\d)/, "");
};
*/

export const noSpaces = (value = "") => {
	return value.replace(/ /g, "");
};

export const validEmail = (value: string, message = "Please enter a valid email address.") => {
	if (!validEmailRegex.test(value)) {
		return message;
	}
};

export const validPhoneNumber = (value: string, message = "Please enter a valid phone number.") => {
	if (!parsePhoneNumberFromString(value, "US")?.isValid()) {
		return message;
	}
};

export const validPassword = (value: string) => {
	if (!value) {
		return "Please enter a password";
	}

	if (value.length < 8) {
		return "Please use at least 8 characters";
	}

	// This regex checks for at least one letter & number
	if (!(/\d/.test(value) && /[a-zA-Z]/.test(value))) {
		return "Please use at least one letter & number";
	}
};

export const passwordsMatch = (password?: string, passwordConfirmation?: string, message = "Please make sure your passwords match") => {
	if (password && password !== passwordConfirmation) {
		return message;
	}
};

export const isNotAPOBox = (value: string, message = "Tickets cannot be delivered to a P.O. Box") => {
	if (value && validPOBoxRegex.test(value)) {
		return message;
	}
};
