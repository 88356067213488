import { APIClientResponse } from "./types/APIClientResponse";
import { apiClient } from "./apiClient";

export function getTeams(category_slug?: string): APIClientResponse<
	{
		id: number;
		name: string;
		image: string | null;
		slug: string;
		home_venue_id: number;
		category_id: number;
	}[]
> {
	return apiClient.get("/teams", {
		params: {
			category_slug,
		},
	});
}
