import React from "react";
import styled from "styled-components";
import { getAppColor } from "../../util/appColors";
import checkmark from "../../resource/image/checkmarkUnrounded.png";
import { Checkbox as PrettyCheckBox, CheckboxProps } from "pretty-checkbox-react";

export const Checkbox = ({
	label,
	...props
}: {
	label?: React.ReactNode | string;
	id?: string;
	labelStyle?: React.CSSProperties;
	checkboxStyle?: React.CSSProperties;
} & Pick<CheckboxProps, "checked" | "onChange">) => {
	return (
		<CheckboxContainer>
			<CheckboxElement plain icon={<img src={checkmark} alt="checkmark" />} {...props} style={props.checkboxStyle} />
			{label && (
				<label
					htmlFor={props.id}
					style={{
						...(!props.id && {
							cursor: "default",
						}),
						...(props.labelStyle ?? {}),
					}}
				>
					{label}
				</label>
			)}
		</CheckboxContainer>
	);
};

const CheckboxElement = styled(PrettyCheckBox)`
	font-size: 22px;
`;

const CheckboxContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: left;

	.pretty label:before {
		border: 1px solid ${getAppColor("dark")};
	}
`;

Checkbox.CheckboxElement = CheckboxElement;
