import React from "react";
import { AppColorKey, AppColorType, getAppColorTextColor, getAppColor } from "../../util/appColors";
import { breakpoints } from "../../util/breakpoints";
import styled from "styled-components/macro";
import { SkeletonProps } from "../../../model/optimizedModel/skeleton";
import { SkeletonWrapper } from "../SkeletonWrapper/SkeletonWrapper";
import { cssStringToCSSObject } from "../../util/optimized/styles";

export enum FontFamily {
	Solano = "solano-gothic-pro-mvb, sans-serif",
	Poppins = "Poppins, sans-serif",
	Formula = "Formula, sans-serif",
	Titilium = "Titilium, sans-serif",
	AkzidenzGrotesk = "Akzidenz Grotesk",
	Industry = "Industry, sans-serif",
	Nunito = "Nunito, sans-serif",
	Venus = "Venus, sans-serif",
}

type FontWeight = React.CSSProperties["fontWeight"];

const typographyOptions: Record<
	string,
	{
		baseStyles: string;
		element: keyof JSX.IntrinsicElements;
		//TODO: Consider finding a way to restrict the array size of fontFamilyOptions and fontWeightOptions to the maximum amount of options in their individual types.
		fontWeightOptions?: FontWeight[];
	}
> = {
	display1: {
		element: "header",
		baseStyles: `
      text-transform: uppercase;
      font-size: 60px;
      line-height: 66px;
      font-family: ${FontFamily.Solano};
      @media (max-width: ${breakpoints.mobile}) {
        font-size: 40px;
        line-height: 44px;
      }
    `,
	},
	heading1: {
		element: "h1",
		baseStyles: `
      text-transform: uppercase;
      font-size: 40px;
      line-height: 44px;
      font-family: ${FontFamily.Solano};
      @media (max-width: ${breakpoints.mobile}) {
        font-size: 30px;
        line-height: 33px;
      }
    `,
	},
	heading2: {
		element: "h2",
		baseStyles: `
      text-transform: uppercase;
      font-size: 30px;
      line-height: 33px;
      font-family: ${FontFamily.Solano};
      @media (max-width: ${breakpoints.mobile}) {
        font-size: 24px;
        line-height: 26px;
      }
    `,
	},
	heading3: {
		element: "h3",
		baseStyles: `
      text-transform: uppercase;
      font-size: 20px;
      line-height: 22px;
      font-family: ${FontFamily.Solano};
      @media (max-width: ${breakpoints.mobile}) {
        font-size: 18px;
        line-height: 20px;
      }
    `,
	},
	heading4: {
		element: "h4",
		baseStyles: `
      text-transform: uppercase;
      font-size: 18px;
      line-height: 20px;
      font-family: ${FontFamily.Solano};
      @media (max-width: ${breakpoints.mobile}) {
        font-size: 16px;
        line-height: 18px;
      }
    `,
	},
	specialBodyHeader: {
		element: "h1",
		baseStyles: `
      text-transform: uppercase;
      font-size: 40px;
      line-height: 32px;
      font-family: ${FontFamily.Solano};
    `,
	},
	leadParagraph: {
		element: "p",
		baseStyles: `
      font-size: 24px;
      line-height: 36px;
      @media (max-width: ${breakpoints.mobile}) {
        font-size: 18px;
        line-height: 22px;
      }
    `,
		fontWeightOptions: [500],
	},
	extraLargeSpecialBody: {
		element: "p",
		baseStyles: `
      text-transform: uppercase;
      font-size: 24px;
      line-height: 26px;
      font-family: ${FontFamily.Solano};
    `,
	},
	bodyLarge: {
		element: "p",
		baseStyles: `
      font-size: 20px;
      line-height: 34px;
    `,
	},
	largeSpecialBody: {
		element: "p",
		baseStyles: `
      text-transform: uppercase;
      font-size: 20px;
      line-height: 22px;
      font-family: ${FontFamily.Solano};
    `,
	},
	bodyMedium: {
		element: "p",
		baseStyles: `
      font-size: 18px;
      line-height: 32px;
    `,
	},
	bodyNormal: {
		element: "p",
		baseStyles: `
      font-size: 16px;
      line-height: 24px;
    `,
	},
	normalSpecialBody: {
		element: "p",
		baseStyles: `
      text-transform: uppercase;
      font-size: 18px;
      line-height: 18px;
      font-family: ${FontFamily.Solano};
    `,
	},
	bodySmall: {
		element: "p",
		baseStyles: `
      font-size: 14px;
      line-height: 20px;
    `,
	},
	smallSpecialBody: {
		element: "p",
		baseStyles: `
      text-transform: uppercase;
      font-size: 16px;
      line-height: 18px;
      font-family: ${FontFamily.Solano};
    `,
	},
	extraSmallSpecialBody: {
		element: "p",
		baseStyles: `
      text-transform: uppercase;
      font-size: 12px;
      line-height: 13px;
      font-family: ${FontFamily.Solano};
    `,
	},
	bodyTiny: {
		element: "p",
		baseStyles: `
      font-size: 12px;
      line-height: 18px;
    `,
	},
	bodyExtraTiny: {
		element: "p",
		baseStyles: `
      font-size: 10px;
      line-height: 16px;
    `,
	},
};

export type TypographyStyleOptions = {
	fontWeight?: FontWeight;
	color?: AppColorKey | "inherit";
	colorType?: AppColorType;
	highlight?: boolean;
};

export type TypographyType = keyof typeof typographyOptions;

export const getTypographyStyles = (
	type: TypographyType,
	{
		highlight,
		color = "dark",
		colorType,
		fontWeight,
		outputType = "string",
	}: TypographyStyleOptions & {
		outputType?: "string" | "object";
	} = {}
) => {
	const displayedColor = color !== "inherit" ? getAppColor(color, colorType) : color;

	const fontWeightOptions = typographyOptions[type].fontWeightOptions;

	let displayedFontWeight = fontWeight;

	if (fontWeightOptions?.length && !fontWeightOptions.includes(fontWeight)) {
		displayedFontWeight = fontWeightOptions[0];
	}

	const value = `
    a {
      text-decoration: underline;
    }
    font-family: ${FontFamily.Poppins};
    ${typographyOptions[type].baseStyles}
    ${highlight && color !== "inherit"
			? `
      background: ${displayedColor};
      color: ${getAppColorTextColor(color)};
    `
			: `
      color: ${displayedColor};
    `
		}
    ${!!displayedFontWeight
			? `
      font-weight: ${displayedFontWeight};
    `
			: ``
		}
  `;

	return outputType === "object" ? cssStringToCSSObject(value) : value;
};

interface TextProps extends TypographyStyleOptions {
	//TODO: The 'type' prop currently does not properly infer the keys from typographyOptions since typographyOptions is typed with 'Record'. Consider finding a way to type typographyOptions that will allow the 'type' prop to properly infer the keys from typographyOptions.
	type: TypographyType;
}

export const Typography = ({ loading, children, className, ...spreadableProps }: React.PropsWithChildren<TextProps & React.HTMLAttributes<HTMLElement> & SkeletonProps>) => {
	return (
		<SkeletonWrapper loading={loading} className={className}>
			<Text as={typographyOptions[spreadableProps.type].element} {...spreadableProps} children={loading ? "|" : children} />
		</SkeletonWrapper>
	);
};

const Text = styled.span<TextProps>`
	${({ type, ...typographyStyleOptions }) => getTypographyStyles(type, typographyStyleOptions)}
`;
