import React, { CSSProperties, ReactNode, useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components/macro";
import { Config } from "../../../Config";
import { HomeAsset } from "../../../model/Asset";
import { HomeSearchSelectedContext } from "../../routes/Home";
import { maxContentWidthStyles, maxContentWidthValue } from "../../util/maxContentWidth";
import { mediaQueries } from "../../util/mediaQueries";
import { responsiveFontSizeFromString } from "../../util/responsiveBanner";

const INTERVAL = 7000;
export interface CarouselProps {
	assets?: HomeAsset[];
	children?: ReactNode;
	additionalImageHeight?: number;
	id?: string;
	pixar?: boolean;
}

const carouselContentHeight = 26.63;
const heightUnits = "vw";
const getMaxHeight = (height: number) => height * 0.01 * maxContentWidthValue + "px";
const maxCarouselContentHeight = getMaxHeight(carouselContentHeight);

export const HomeCarousel = React.forwardRef(({ children, assets, additionalImageHeight, id, pixar }: CarouselProps, ref) => {
	const [currentAssetIndex, setCurrentAssetIndex] = useState(0);

	const { homeSearchSelected } = useContext(HomeSearchSelectedContext);

	useEffect(() => {
		if (assets?.length) {
			const interval = setInterval(() => {
				const assetsLength = assets.length;
				const nextAssetIndex = currentAssetIndex + 1;
				if (!homeSearchSelected) {
					if (nextAssetIndex === assetsLength) {
						setCurrentAssetIndex(0);
					} else {
						setCurrentAssetIndex(nextAssetIndex);
					}
				}
			}, INTERVAL);

			return () => {
				clearInterval(interval);
			};
		}
		return;
	}, [assets, currentAssetIndex, homeSearchSelected]);

	const carouselImageHeight = carouselContentHeight + (additionalImageHeight ? additionalImageHeight : 0);
	const maxCarouselImageHeight = getMaxHeight(carouselImageHeight);

	return (
		<CarouselContainer id={id} ref={ref as any}>
			{assets?.map((asset, index) => {
				return (
					<Asset pixar={pixar} opacity={currentAssetIndex === index ? 1 : 0} key={index} asset={asset} carouselImageHeight={carouselImageHeight} maxCarouselImageHeight={maxCarouselImageHeight}>
						{children}
					</Asset>
				);
			})}
		</CarouselContainer>
	);
});

const Asset = (
	props: React.PropsWithChildren<{
		asset: HomeAsset;
		carouselImageHeight: number;
		maxCarouselImageHeight: string;
		opacity: number;
		pixar?: boolean;
	}>
) => {
	const { opacity, asset, pixar } = props;

	const [style, setStyle] = useState<CSSProperties>();

	useEffect(() => {
		setStyle({
			pointerEvents: opacity ? "auto" : "none",
			opacity,
		});
	}, [opacity]);

	return (
		<>
			<div style={style} className="fadeable">
				<CarouselImage
					source={asset.backgroundIsLocal ? asset.background : Config.getResourceUrl(asset.background)}
					style={{
						//height: carouselImageHeight + heightUnits,
						//TODO: Switch back to dynamic heght (see the line above)
						height: pixar ? "42.1017vw" : carouselContentHeight + heightUnits,
						maxHeight: pixar ? "none" : maxCarouselContentHeight,
						backgroundPositionX: asset.backgroundPosition,
						//maxHeight: maxCarouselImageHeight,
					}}
				/>
			</div>
			<CarouselContentContainer style={style} className="fadeable">
				<Wrapper
					style={{
						position: pixar ? "fixed" : "initial",
					}}
				>
					<MainContentContainer>
						<Headline style={asset.headline1.style} fontSize={asset.headline1.style.fontSize as string}>
							<a href={asset.headline1.link} target="_blank" rel="noopener noreferrer">
								{asset.headline1.text}
							</a>
						</Headline>
						<SubHeadline fontSize={asset.subHeadline1.style.fontSize as string} style={asset.subHeadline1.style}>
							<a href={asset.subHeadline1.link} target="_blank" rel="noopener noreferrer">
								{asset.subHeadline1.text}
							</a>
						</SubHeadline>
					</MainContentContainer>
					<SecondaryContentContainer>
						<SecondaryHeadline textAlign="left">
							<HeadlineSmall style={asset.headline2.style} fontSize={asset.headline2.style.fontSize as string}>
								<a href={asset.headline2.link} target="_blank" rel="noopener noreferrer">
									{asset.headline2.text}
								</a>
							</HeadlineSmall>
							<SubHeadlineSmall fontSize={asset.subHeadline2.style.fontSize as string} style={asset.subHeadline2.style}>
								<a href={asset.subHeadline2.link} target="_blank" rel="noopener noreferrer">
									{asset.subHeadline2.text}
								</a>
							</SubHeadlineSmall>
						</SecondaryHeadline>
						{!asset.headline2.text && !asset.headline3.text && !asset.subHeadline2.text && !asset.subHeadline3.text && <Children>{props.children}</Children>}
						<SecondaryHeadline textAlign="right">
							<HeadlineSmall style={asset.headline3.style} fontSize={asset.headline3.style.fontSize as string}>
								<a href={asset.headline3.link} target="_blank" rel="noopener noreferrer">
									{asset.headline3.text}
								</a>
							</HeadlineSmall>
							<SubHeadlineSmall fontSize={asset.subHeadline3.style.fontSize as string} style={asset.subHeadline3.style}>
								<a href={asset.subHeadline3.link} target="_blank" rel="noopener noreferrer">
									{asset.subHeadline3.text}
								</a>
							</SubHeadlineSmall>
						</SecondaryHeadline>
					</SecondaryContentContainer>
				</Wrapper>
				<AssetHyperlink style={style} href={asset.link} target="_blank" rel="noopener noreferrer" className="fadeable" />
			</CarouselContentContainer>
		</>
	);
};

const fontFamily = `"Avenir", sans-serif`;

const AssetHyperlink = styled.a`
	width: 100%;
	height: 26.63vw;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	margin: auto;
	height: 100%;
`;

const carouselContentHeightStyles = css`
	height: ${carouselContentHeight}${heightUnits};
	max-height: ${maxCarouselContentHeight};
`;

const CarouselContainer = styled.div`
	position: relative;
	${carouselContentHeightStyles}
	& .fadeable {
		transition: opacity 0.4s ease-out 0s;
	}
`;

const CarouselImage = styled.div<{
	source?: string;
}>`
	background: url(${props => props.source});
	background-repeat: no-repeat;
	background-size: cover;
	background-position-x: center;
	width: 100vw;
`;

const CarouselContentContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Wrapper = styled.div`
	${carouselContentHeightStyles}
	${maxContentWidthStyles}
  @media ${mediaQueries.max900} {
		padding-left: 80px;
	}
	@media ${mediaQueries.max500} {
		padding-left: 70px;
	}
	width: 100%;
	/* this was breaking overlays 
  pointer-events: none;
  z-index: 1;
  & > * {
    pointer-events: all;
  }*/
`;

const MainContentContainer = styled.div<{
	foreground?: string;
}>`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 50%;
	@media ${mediaQueries.popupMax} {
		& > div {
			font-size: 1.82rem;
		}
	}
	@media ${mediaQueries.max500} {
		justify-content: center;
		height: 100%;
	}
`;

const headlineStyles = `
  color: #fff;
  letter-spacing: 1px;
	font-family: ${fontFamily};
`;

const headlineMediaQueryStyles = `
  @media ${mediaQueries.max500} {
    font-size: 18px !important;
  }
`;

const Headline = styled.div<{ fontSize?: string }>`
	${props => responsiveFontSizeFromString(props.fontSize)}
	font-weight: 800;
	${headlineStyles}
	${headlineMediaQueryStyles}
`;

const HeadlineSmall = styled.div<{ fontSize?: string }>`
	${props => responsiveFontSizeFromString(props.fontSize)}
	font-weight: 800;
	margin: 0 1rem;
	${headlineStyles}
`;

const SubHeadline = styled.div<{ fontSize?: string }>`
	${props => responsiveFontSizeFromString(props.fontSize)}
	margin: 0 1rem;
	margin-top: 8px;
	${headlineStyles}
	${headlineMediaQueryStyles}
`;

const SubHeadlineSmall = styled.div<{ fontSize?: string }>`
	${props => responsiveFontSizeFromString(props.fontSize)}
	margin: 0 1rem;
	${headlineStyles}
`;

const SecondaryContentContainer = styled.div<{
	foreground?: string;
}>`
	display: flex;
	flex-direction: row;
	height: 50%;
	@media ${mediaQueries.popupMax} {
		height: 0%;
		justify-content: space-between;
	}
`;

const SecondaryHeadline = styled.div<{ textAlign: "left" | "right" }>`
	text-align: ${props => props.textAlign};
	flex: 2;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media ${mediaQueries.max500} {
		flex: initial;
	}
`;

const Children = styled.div`
	flex: 6;
	display: flex;
	align-items: center;
	justify-content: center;
	@media ${mediaQueries.max500} {
		display: none;
	}
`;
