import { useState, useEffect } from "react";

//Taken from https://www.30secondsofcode.org/react/s/use-media-query with some changes
export const useMediaQuery = (query: string) => {
	const mediaQuery = window.matchMedia ? window.matchMedia(query) : undefined;
	const [match, setMatch] = useState(!!mediaQuery?.matches);

	useEffect(() => {
		if (mediaQuery) {
			const listener = () => setMatch(!!mediaQuery.matches);
			if (mediaQuery.addListener) {
				//Safari
				mediaQuery.addListener(listener);
				return () => mediaQuery.removeListener(listener);
			} else if (mediaQuery.addEventListener) {
				//Chrome & Firefox
				mediaQuery.addEventListener("change", listener);
				return () => mediaQuery.removeEventListener("change", listener);
			}
		}
	}, []);

	return match;
};
