import React, { FormEvent, useState } from "react";
import styled from "styled-components/macro";
import { ModalPopup } from "../ModalPopup/ModalPopup";
import remind_me_alt from "../../resource/image/remind-me-alt.svg";
import { ModalCloseButton } from "../Modal/ModalCloseButton";
import { ModalPosition } from "../Modal/ModalPosition";
import { mediaQueries } from "../../util/mediaQueries";
import checkmark_black from "../../resource/img/icons/checkmark_black.svg";
import { Button } from "../Button/Button";

export interface ReminderModalProps {
	open: boolean;
	onClose: () => void;
	onSubmit: (email: string) => void;
}

export const ReminderModal = (props: ReminderModalProps) => {
	const [submitted, setSubmitted] = useState(false);

	const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const { value: email }: HTMLInputElement = event.currentTarget["email"];
		props.onSubmit(email);
		setSubmitted(true);
	};

	if (!props.open) {
		return null;
	}

	return (
		<StyledModalPopup
			backgroundColor="#f7f7f7"
			onDidClose={() => {
				setSubmitted(false);
				props.onClose();
			}}
			centerHeaderElements={<RemindMeImage src={remind_me_alt} height={40} />}
			rightHeaderElements={(onClose: any) => <ModalCloseButton onClick={onClose} />}
			position={ModalPosition.Center}
		>
			{onClose => (
				<ModalContent>
					<div className={submitted ? "open" : ""}>
						<ReminderMessage>
							<img src={checkmark_black} alt="checkmark" />
							Thank you, you will be notified once tickets are available.
						</ReminderMessage>
						<ExitButton onClick={onClose}>Exit</ExitButton>
					</div>
					<div className={submitted ? "" : "open"}>
						<ReminderMessage>We'll notify you as soon as tickets become available!</ReminderMessage>
						<SubscribeForm onSubmit={onSubmit}>
							<div>
								<MailInput required name="email" type="email" placeholder="Enter Email" />
								<SubscribeButton type="submit" value="Subscribe" name="subscribe" />
							</div>
						</SubscribeForm>
						<Terms>By subscribing with your email you agree to receive communications from SI Tickets.</Terms>
					</div>
				</ModalContent>
			)}
		</StyledModalPopup>
	);
};

const modalContentTransitionLength = 0.2;
const modalContentTransitionType = "ease-in-out";
const modalContentTransition = modalContentTransitionLength + "s " + modalContentTransitionType;

const StyledModalPopup = styled(ModalPopup)`
	margin: 20px;
`;

const ModalContent = styled.div`
	display: grid;
	grid-template: 1fr / 1fr;
	place-items: center;
	& > div {
		opacity: 0;
		grid-column: 1 / 1;
		grid-row: 1 / 1;
		padding: 0 10px;
		pointer-events: none;
		height: 100%;
		transition: ${modalContentTransition} opacity;
		&:first-child {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			transition-delay: ${modalContentTransitionLength * 0.5}s;
		}
		&.open {
			opacity: 1;
			pointer-events: initial;
		}
	}
`;

const ExitButton = styled(Button)`
	max-width: 158px;
	height: 45px;
	margin-top: 15px;
	margin-bottom: 2px;
`;

const RemindMeImage = styled.img`
	float: left;
	height: 40px;
	@media ${mediaQueries.popupMax} {
		height: 30px;
	}
`;

const ReminderMessage = styled.p`
	margin-top: 13px;
	font-size: 16px;
	line-height: 21px;
	display: flex;
	align-items: center;
	justify-content: center;
	& > img {
		margin-right: 11px;
	}
	@media ${mediaQueries.popupMax} {
		font-size: 14px;
	}
`;

const SubscribeForm = styled.form`
	flex-direction: column;
	align-items: center;
	display: flex;
	width: 100%;
	margin-top: 9px;
`;

const MailInput = styled.input`
	border-radius: 0;
	border-top-left-radius: 11px;
	border-bottom-left-radius: 11px;
	width: calc(100% - 120px);
	float: left;
	border: 0;
	padding: 10px 15px;
	-webkit-appearance: none;
	box-shadow: inset 3px 3px 9px rgba(0, 0, 0, 0.5);
	font-size: 16px;
	&:hover {
		box-shadow: none;
	}
`;

const SubscribeButton = styled.input`
	float: left;
	width: 120px;
	max-width: none;
	-webkit-appearance: none;
	border: 0;
	border-radius: 0;
	border-top-right-radius: 11px;
	border-bottom-right-radius: 11px;
	outline: 0 !important;
	box-shadow: inset -3px 3px 9px rgba(0, 0, 0, 0.5);
	font-size: 16px;
	font-weight: 500;
	color: #fff;
	background: #e92224;
	padding: 10px 0;

	&:hover {
		cursor: pointer;
	}
`;

const Terms = styled.p`
	text-align: left;
	font-size: 11px;
	line-height: normal;
	margin-top: 8px;
`;
