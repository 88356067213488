import React, { AnchorHTMLAttributes } from "react";
import { Link as RouterLink } from "react-router-dom";

export const InternalRoutes = {
	Home: "/",
	Test: "/test",
	TestHome: "/new-home-tests",
	Search: "/search",
	Cart: "/cart",
	Checkout: "/checkout",
	NotFound: "/404",
	Error: "/error",
	Login: "/login",
	SearchResults: (params: string = "") => `/search-results${params}`,
	CheckoutThanks: (slug: string) => `/checkout-thanks/${slug}`,
	Bets: "/bestbets",
	Trending: (params: string = "") => `/trending${params}`,
	Discover: (params: string = "") => `/discover${params}`,
	//EarlyAccess: (params: string = "") => `/early-access${params}`,
	Popular: (params: string = "") => `/popular${params}`,
	Category: (slug: string, params: string = "") => `/category/${slug}${params}`,
	Sports: (slug: string, params: string = "") => `/sport/${slug}${params}`,
	Event: (slug: string, params: string = "") => `/event/${slug}${params}`,
	Venue: (slug: string) => `/venue/${slug}`,
	Performer: (slug: string) => `/performer/${slug}`,
	Futures: (params: string = "") => `/reservations${params}`,
	Team: (slug: string) => `/team/${slug}`,
	TeamFutureSelected: (teamSlug: string, eventSlug: string) => `/reservations/${teamSlug}/${eventSlug}`,
	Maintenance: (from?: string) => `/maintenance${from ? "?from=" + from : ""}`,
	News: "/news",
	DraftKings: "/draftkings",
	Affiliates: "/affiliates",
	FanGuarantee: "/fan-guarantee",
	Venmo: "/venmo",
	OurTeam: "/meet-the-team",
	GolfClassic: "/golf-classic",
	SITixChampionship: "/sitix-championship",
	SITixChampionshipLegal: "/sitix-championship/legal",
	Formula1: "/formula1",
	Formula1Event: "/event/us-grand-prix-3-day-pass",
	BigGameWeekend: "/big-game-weekend",
	Terms: "/terms",
	Privacy: "/privacy",
	TermsOfUse: "/terms-of-use",
	PrivacyPolicy: "/privacy-policy",
	Tailgates: "/tailgates",
	Perfect10: "/perfect10",
	MASL: "/masl",
	APPUSA: "/app",
	Reebok: "/reebok",
	ClubSI: "/clubsi",
	SoccerEx: "/soccerex",
	Covers: "/covers",
	SuperBowlLVII: "/big-game-weekend",
	Swim: "/paraiso",
	Siprospects: "/prospectsbysi",
	PredraftShowcase: "/predraftshowcase",
	MyAccount: (slug?: string) => `/my-account${slug ? "/" + slug : ""}`,
	PasswordReset: (token: string) => `/password/reset/${token}`,
	About: (slug?: string) => `/about-us${slug ? "/" + slug : ""}`,
	ShowMyTickets: (qr: string) => `/show-my-tickets/${qr}`,
	Logout: "/logout",
} as const;

export const ExternalRoutes = {
	FAQ: "https://support.sitickets.com/hc/en-us/sections/1500000179221-FAQ",
	HelpCenter: "https://support.sitickets.com/",
	BuyTickets: "https://tickets.lunatix.com",
	Careers: "https://www.linkedin.com/company/si-tickets/jobs/",
	ContactSupport: "https://support.sitickets.com/hc/en-us/requests/new",

	AppAppleStore: "https://apps.apple.com/us/app/si-tix/id1538764730",
	AppAndroidStore: "https://play.google.com/store/apps/details?id=com.sitix.app",

	SportsIllustrated: "https://www.si.com",
	facebook: "https://www.facebook.com/sitickets/",
	twitter: "https://twitter.com/si_tickets_/",
	instagram: "https://www.instagram.com/si.tickets/",
	SI_Shop: "https://sishop.com/",
	SI_Sportsbook: "https://www.sisportsbook.com",

	TicketInsuranceTerms: "#",
	TicketInsuranceCovid: "#",
	//Help Links
	ReservationsHelp: "https://support.sitickets.com/hc/en-us/articles/4402173983891-Postseason-Ticket-Reservations",
	HelpCenterHome: "https://support.sitickets.com/hc/en-us",

	//Afilliates links
	VenmoCreateAccountFromHome: "https://smart.link/96tuxmrcimrey",
	VenmoCreateAccountFromFooterLogo: "https://www.sitickets.com/venmo",
	VenmoCreateAccountFrom10FeeBadge: "https://www.sitickets.com/venmo",
	VenmoCreateAccountFromMenu: "https://www.sitickets.com/venmo",
	VenmoCreateAccountFromCheckout: "https://smart.link/hi0tsbi8idq0x",
} as const;

export interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
	children: React.ReactNode;
	to: string;
	target?: string;
}

const isInternalURL = (to: string) => {
	try {
		const url = new URL(to, window.location.origin);
		return url.hostname === window.location.hostname;
	} catch {
		return false;
	}
};

export const Link = (props: LinkProps) => {
	const target = props.to;
	if (isInternalURL(target)) {
		return <RouterLink {...props} to={target} />;
	} else {
		// eslint-disable-next-line
		return <a {...props} href={target} target={props.target || "_self"} />;
	}
};
