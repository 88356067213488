import React, { useContext, useEffect, useState } from "react";
//TODO: Confirm that the bottom imported logo is accurate to our "real logo".
import { ReactComponent as SITicketsLogo } from "../../resource/assets/si_tickets_logo_unstacked_cropped.svg";
import { ReactComponent as LeftChevronIcon } from "../../resource/assets/icons/left_chevron_icon.svg";
import { ReactComponent as RightChevronIcon } from "../../resource/assets/icons/right_chevron_icon.svg";
import { getNavBarAll } from "../../../services/navigation";
import { NavigationItem } from "../../../model/Navigation";
import { getTypographyStyles, Typography, TypographyType } from "../Typography/Typography";
import styled from "styled-components/macro";
import { getAppColor } from "../../util/appColors";
import { NavSearch } from "../Header/SecondaryNav";
import { ReactComponent as CartIcon } from "../../resource/assets/icons/cart_icon.svg";
import { ReactComponent as SearchIcon } from "../../resource/assets/icons/search_icon.svg";
import { InternalRoutes } from "../../Link";
import { ReactComponent as UserIcon } from "../../resource/assets/icons/user_icon.svg";
import { maxContentWidthPadding } from "../../util/maxContentWidth";
import { selectableTransitionDuration, selectableTransitionTimingFunction } from "../../util/transitions";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { breakpoints } from "../../util/breakpoints";
import { setNavbarNavigationItems, setNavbarHeight } from "../../../store/reducers/navbarReducer";
import ReactDOM from "react-dom";
import { isMobileApp } from "../../util/isMobileApp";
import { Transition, TransitionStatus } from "react-transition-group";
import { Link, LinkProps, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FrozenPageScrollOverlay, overlayTransitionDuration, overlayTransitionTimingFunction } from "../FrozenPageScrollOverlay/FrozenPageScrollOverlay";
import { useCart } from "../../../services/hooks";
import { useAutoUpdateState } from "../../hooks/useAutoUpdateState";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";

//TODO: Componentize elements that can be organized into their own components once code is more finalized.

const brandAffiliates = [
	{
		name: "SI.COM",
		url: "https://si.com",
	},
	{
		name: "SI SPORTSBOOK",
		url: "https://www.sisportsbook.com/",
	},
	{
		name: "SI SHOP",
		url: "https://sishop.com/",
	},
];

const mobileNavigationItemsTitleChevronWidth = "16px";

const hamburgerMenuTransitionDuration = overlayTransitionDuration;

const hamburgerMenuTransitionTimingFunction = overlayTransitionTimingFunction;

const verticalNavbarPadding = maxContentWidthPadding + "px";

const maxNavbarHamburgerMenuWidth = "250px";

const NavbarHamburgerMenuNavigationItem = ({
	children,
	typographyType = "bodyMedium",
	showRightChevronIcon,
	...spreadableProps
}: React.ComponentProps<ReturnType<typeof styled.div>> & {
	typographyType?: TypographyType;
	showRightChevronIcon?: boolean;
}) => {
	return (
		<div
			{...spreadableProps}
			css={`
				padding: 11px ${verticalNavbarPadding};
				display: flex;
				align-items: center;
				justify-content: space-between;
				cursor: pointer;
			`}
		>
			<Typography
				type={typographyType}
				css={`
					white-space: nowrap;
					&,
					& > * {
						overflow: hidden;
					}
					& > * {
						text-overflow: ellipsis;
					}
				`}
			>
				{children}
			</Typography>
			{showRightChevronIcon && (
				<RightChevronIcon
					css={`
						height: 12px;
						margin-left: 24px;
					`}
				/>
			)}
		</div>
	);
};

const NavbarHamburgerMenuNavigationItems = (
	props: {
		items: NavigationItem[];
		subitems?: {
			name: string;
			url: string;
		}[];
	} & (
		| {
				title?: undefined;
		  }
		| {
				title: string;
				onBackIconClick: () => void;
		  }
	)
) => {
	const history = useHistory();

	const [selectedItemIndex, setSelectedItemIndex] = useState<number>();

	const [selectedItem, setSelectedItem] = useState<NavigationItem>();

	const { displayMobileUI } = useContext(NavbarContext);

	return (
		<>
			<Transition
				in={selectedItemIndex === undefined}
				timeout={hamburgerMenuTransitionDuration}
				onExit={() => {
					if (selectedItemIndex !== undefined) {
						setSelectedItem(props.items[selectedItemIndex]);
					}
				}}
				onEntered={() => {
					setSelectedItem(undefined);
				}}
			>
				{transitionState => {
					return (
						<div
							css={`
								min-width: 100%;
								transition-property: margin-left;
								transition-duration: ${hamburgerMenuTransitionDuration}ms;
								transition-timing-function: ${hamburgerMenuTransitionTimingFunction};
								margin-left: max(-${maxNavbarHamburgerMenuWidth}, -100vw);
								display: flex;
								flex-direction: column;
								overflow: hidden;
							`}
							style={
								(
									{
										entered: {
											marginLeft: 0,
										},
										entering: {
											marginLeft: 0,
										},
									} as Partial<Record<TransitionStatus, React.CSSProperties>>
								)[transitionState]
							}
						>
							{props.title !== undefined && (
								<div
									css={`
										padding: 8px ${verticalNavbarPadding};
										text-align: center;
										border-bottom: 1px solid ${getAppColor("lightGrey", "dark")};
										display: flex;
										align-items: center;
									`}
								>
									<div
										css={`
											width: ${mobileNavigationItemsTitleChevronWidth};
											display: flex;
										`}
									>
										<LeftChevronIcon
											css={`
												cursor: pointer;
											`}
											onClick={props.onBackIconClick}
										/>
									</div>
									<Typography
										type="bodyMedium"
										fontWeight={500}
										css={`
											width: 100%;
											padding-right: ${mobileNavigationItemsTitleChevronWidth};
											white-space: nowrap;
											&,
											& > * {
												overflow: hidden;
											}
											& > * {
												text-overflow: ellipsis;
											}
										`}
									>
										{props.title}
									</Typography>
								</div>
							)}
							<div
								css={`
									overflow-y: scroll;
								`}
							>
								{props.items.map((item, index) => {
									const itemContainsChildren = item.children && item.children.length > 0;
									return (
										<NavbarHamburgerMenuNavigationItem
											key={index}
											onClick={() => {
												if (itemContainsChildren) {
													setSelectedItemIndex(index);
												} else if (item.url !== undefined) {
													//TODO: Fix bug with this.
													if (item.url.startsWith("http")) {
														window.location.href = item.url;
													} else {
														history.push(item.url);
													}
												}
											}}
											showRightChevronIcon={itemContainsChildren}
										>
											{item.name}
										</NavbarHamburgerMenuNavigationItem>
									);
								})}
								{displayMobileUI &&
									props?.subitems?.map((subItem, index) => {
										return (
											<NavbarHamburgerMenuNavigationItem typographyType="bodySmall" as="a" href={subItem.url} target="_blank" rel="noopener noreferrer" key={index}>
												{subItem.name}
											</NavbarHamburgerMenuNavigationItem>
										);
									})}
							</div>
						</div>
					);
				}}
			</Transition>
			{selectedItem !== undefined && selectedItem.children?.length && (
				<NavbarHamburgerMenuNavigationItems
					items={selectedItem.children}
					title={selectedItem.name}
					onBackIconClick={() => {
						setSelectedItemIndex(undefined);
					}}
				/>
			)}
		</>
	);
};

const NavbarContext = React.createContext<{
	displayTransparentBackground?: boolean;
	hamburgerMenuIsOpen?: boolean;
	setHamburgerMenuIsOpen: React.Dispatch<React.SetStateAction<boolean | undefined>>;
	displayMobileUI?: boolean;
}>({
	setHamburgerMenuIsOpen: () => {},
});

const NavbarHamburgerMenu = (
	props: {
		position: "left" | "right";
	} & StyledComponentProps
) => {
	const {
		navigationItems,
		//TODO: Consider renaming "height" to "navbarHeight" in the navbarReducer.
		height: navbarHeight,
	} = useSelector(state => state.transient.navbar);

	const { hamburgerMenuIsOpen, setHamburgerMenuIsOpen, ...navbarContext } = useContext(NavbarContext);

	return (
		<div
			className={props.className}
			css={`
				position: relative;
			`}
		>
			<NavbarHamburgerMenuIconContent
				onClick={() => {
					setHamburgerMenuIsOpen(!hamburgerMenuIsOpen);
				}}
				css={`
					cursor: pointer;
					& > * {
						background: ${getAppColor(navbarContext.displayTransparentBackground ? "light" : "dark")};
					}
				`}
			>
				<NavbarHamburgerMenuIconLine1 open={hamburgerMenuIsOpen} />
				<NavbarHamburgerMenuIconLine2 open={hamburgerMenuIsOpen} />
				<NavbarHamburgerMenuIconLine3 open={hamburgerMenuIsOpen} />
			</NavbarHamburgerMenuIconContent>
			<FrozenPageScrollOverlay
				in={hamburgerMenuIsOpen && !!navigationItems?.length}
				zIndex={2}
				onClick={() => {
					setHamburgerMenuIsOpen(false);
				}}
			>
				{transitionState => {
					return ReactDOM.createPortal(
						<div
							css={`
		  display: flex;
		  position: fixed;
		  height: 100vh;
		  top: 0;
		  max-height: calc(100vh - ${navbarHeight}px);
		  padding-${isMobileApp ? "bottom" : "top"}: ${navbarHeight}px;
		  //TODO: Make this container contrast with the main navbar container from a design perspective.
		  background: ${getAppColor("light")};
		  overflow-x: hidden;
		  transition-property: ${props.position};
		  transition-duration: ${hamburgerMenuTransitionDuration}ms;
		  transition-timing-function: ${hamburgerMenuTransitionTimingFunction};
		  z-index: 2;
		  width: min(${maxNavbarHamburgerMenuWidth}, 100vw);
		  ${props.position}: max(-${maxNavbarHamburgerMenuWidth}, -100vw);
		`}
							style={
								transitionState === "entered"
									? {
											[props.position]: 0,
									  }
									: undefined
							}
						>
							{
								//TODO: Add skeleton loading functionality
								navigationItems !== undefined && <NavbarHamburgerMenuNavigationItems items={navigationItems} subitems={brandAffiliates} />
							}
						</div>,
						document.getElementById("root")!
					);
				}}
			</FrozenPageScrollOverlay>
		</div>
	);
};

const SecondaryNavbarLink = (props: Pick<React.HTMLProps<HTMLAnchorElement>, "href" | "children">) => {
	return (
		<a
			href={props.href}
			target="_blank"
			rel="noopener noreferrer"
			css={`
				white-space: nowrap;
				&:hover {
					text-decoration: underline;
				}
			`}
		>
			<Typography type="bodyTiny" color="light">
				{props.children}
			</Typography>
		</a>
	);
};

const NavbarIconLink = (
	props: {
		Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
	} & Pick<LinkProps, "to"> &
		StyledComponentProps
) => {
	const navbarContext = useContext(NavbarContext);

	return (
		<Link
			to={props.to}
			css={`
				fill: ${getAppColor(navbarContext.displayTransparentBackground ? "light" : "dark")};
				& svg {
					height: 30px;
					transition: fill ${selectableTransitionTimingFunction} ${selectableTransitionDuration};
				}
			`}
			className={props.className}
		>
			<props.Icon />
		</Link>
	);
};

const StandardNavbarNavigationItemChildren = (
	props: {
		items: Required<NavigationItem["children"]>;
	} & StyledComponentProps
) => {
	const [itemIndexUserIsHoveringOver, setItemIndexUserIsHoveringOver] = useState<number>();

	const navbarState = useSelector(state => state.transient.navbar);

	return (
		<>
			<div
				style={{
					maxHeight: `calc(100vh - ${navbarState.height}px)`,
				}}
				css={`
					display: flex;
					flex-direction: column;
					border: 1px solid ${getAppColor("lightGrey", "dark")};
					overflow-y: scroll;
					margin-top: 9px;
					&:nth-child(odd) {
						background: ${getAppColor("light")};
					}
					&:nth-child(even) {
						background: ${getAppColor("lightGrey", "subtle")};
					}
					&:not(:last-child) {
						border-right: none;
					}
				`}
				className={props.className}
			>
				{props.items?.map((child, index) => {
					return (
						<div
							onMouseEnter={() => {
								setItemIndexUserIsHoveringOver(index);
							}}
							css={`
								display: flex;
							`}
							key={index}
						>
							<Link
								to={child.url}
								css={`
									display: flex;
									width: 100%;
									justify-content: space-between;
									align-items: center;
									padding: 13px 22px;
									&:hover {
										background: ${getAppColor("lightGrey")}e6;
									}
								`}
							>
								<Typography
									type="bodyNormal"
									css={`
										white-space: nowrap;
										display: flex;
										align-items: center;
									`}
								>
									{child.name}
								</Typography>
								{child.children?.length && (
									<RightChevronIcon
										css={`
											margin-left: 9px;
											height: 41%;
											margin-left: 23px;
										`}
									/>
								)}
							</Link>
						</div>
					);
				})}
			</div>
			{props.items && itemIndexUserIsHoveringOver !== undefined && props.items[itemIndexUserIsHoveringOver]?.children?.length && (
				<StandardNavbarNavigationItemChildren
					items={props.items[itemIndexUserIsHoveringOver].children!}
					css={`
						position: absolute;
						left: 100%;
						top: 0;
						bottom: 0;
					`}
				/>
			)}
		</>
	);
};

const StandardNavbarNavigationItem = (props: NavigationItem) => {
	const [userHovering, setUserHovering] = useState(false);

	const navbarContext = useContext(NavbarContext);

	return (
		<div
			css={`
				position: relative;
			`}
			onMouseEnter={() => {
				setUserHovering(true);
			}}
			onMouseLeave={() => {
				setUserHovering(false);
			}}
			onClick={() => {
				setUserHovering(false);
			}}
		>
			<Link to={props.url} target={props?.url && props.url.startsWith("http") ? "_blank" : "_self"}>
				<Typography
					type="bodyNormal"
					fontWeight={500}
					css={`
						text-transform: uppercase;
						cursor: pointer;
						white-space: nowrap;
						* {
							${navbarContext.displayTransparentBackground &&
							`
		color: ${getAppColor("light")};
	      `}
							transition: color ${selectableTransitionTimingFunction} ${selectableTransitionDuration};
						}
						&:hover * {
							color: ${getAppColor("primary")};
						}
					`}
				>
					{props?.name}
				</Typography>
			</Link>
			{props.children?.length && userHovering && (
				<div
					css={`
						position: absolute;
						z-index: 1;
					`}
				>
					<StandardNavbarNavigationItemChildren items={props.children} />
				</div>
			)}
		</div>
	);
};

export const Navbar = () => {
	const navbarState = useSelector(state => state.transient.navbar);

	const dispatch = useDispatch();

	useEffect(() => {
		if (!navbarState.navigationItems) {
			getNavBarAll()
				.then(result => {
					dispatch(setNavbarNavigationItems(result.data));
				})
				.catch(error => {
					//TODO: Add error logic.
				});
		}
	}, []);

	//TODO: Consider using the pre-built "useIsMobile" hook if it exists in another branch.
	const displayMobileUI = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	const [displayTransparentBackground, setDisplayTransparentBackground] = useState<boolean>();

	const [hamburgerMenuIsOpen, setHamburgerMenuIsOpen] = useState<boolean>();

	const [userHasScrolledPastStartOfPage, setUserHasScrolledPastStartOfPage] = useState<boolean>();

	const [displaySearch, setDisplaySearch] = useState<boolean>();

	useEffect(() => {
		const onScroll = () => {
			setUserHasScrolledPastStartOfPage(window.scrollY > 0);
		};
		const eventListenerParams: Parameters<typeof document.addEventListener> = ["scroll", onScroll];
		onScroll();
		document.addEventListener(...eventListenerParams);
		return () => {
			document.removeEventListener(...eventListenerParams);
		};
	}, []);

	useEffect(() => {
		setDisplayTransparentBackground(!hamburgerMenuIsOpen && !!navbarState.amountOfRequestsToDisplayInitiallyTransparentNavbar && !userHasScrolledPastStartOfPage);
	}, [navbarState.amountOfRequestsToDisplayInitiallyTransparentNavbar, hamburgerMenuIsOpen, userHasScrolledPastStartOfPage]);

	useEffect(() => {
		setDisplaySearch(userHasScrolledPastStartOfPage || !navbarState.hideSearch);
	}, [userHasScrolledPastStartOfPage, navbarState.hideSearch]);

	const [secondaryLinksHeight, setSecondaryLinksHeight] = useState<number>();

	const hideStandardNavigationItems = useMediaQuery("(max-width: 1100px), (hover: none)");

	const { cart } = useCart();

	const amountOfItemsInCart = useAutoUpdateState(() => {
		if (cart) {
			return cart.tickets.length + Object.values(cart.futures).length;
		}
	}, [cart?.tickets.length, cart?.futures]);

	const location = useLocation();

	useEffect(() => {
		setHamburgerMenuIsOpen(false);
	}, [location]);

	useEffect(() => {
		if (!hideStandardNavigationItems && hamburgerMenuIsOpen) {
			setHamburgerMenuIsOpen(false);
		}
	}, [hideStandardNavigationItems]);

	//TODO: Remove "displayNewNavbar" functionality in this component & the navbarState reducer once the legacy navbar is no longer needed.
	return navbarState.displayNewNavbar ? (
		<NavbarContext.Provider
			value={{
				displayTransparentBackground,
				hamburgerMenuIsOpen,
				setHamburgerMenuIsOpen,
				displayMobileUI,
			}}
		>
			<div
				css={`
					position: relative;
					z-index: 100;
					height: ${!navbarState.amountOfRequestsToDisplayInitiallyTransparentNavbar ? navbarState.height : secondaryLinksHeight}px;
				`}
			>
				<div
					ref={ref => {
						const clientHeight = ref?.clientHeight || 0;
						//TODO: Test if this works when the navbar height changes.
						if (navbarState.height !== clientHeight) {
							dispatch(setNavbarHeight(clientHeight));
						}
					}}
					css={`
						top: 0;
						width: 100vw;
						position: fixed;
						z-index: 3;
					`}
				>
					{!displayMobileUI && (
						<div
							ref={ref => {
								const clientHeight = ref?.clientHeight || 0;
								//TODO: Test if this works when the navbar height changes.
								setSecondaryLinksHeight(clientHeight);
							}}
							css={`
								background: ${getAppColor("dark")};
								display: flex;
								padding: 9px ${verticalNavbarPadding};
								& > *:not(:last-child) {
									margin-right: 16px;
								}
							`}
						>
							{brandAffiliates.map((brandAffiliate, index) => (
								<SecondaryNavbarLink href={brandAffiliate.url} key={index}>
									{brandAffiliate.name}
								</SecondaryNavbarLink>
							))}
						</div>
					)}
					<div
						css={`
							transition-duration: ${selectableTransitionDuration};
							transition-timing-function: ${selectableTransitionTimingFunction};
							transition-property: background;
							background: ${displayTransparentBackground ? "linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgb(0 0 0 / 50%) 100%)" : getAppColor("light")};
							display: flex;
							align-items: center;
							padding: 16px ${displayMobileUI ? verticalNavbarPadding : `calc(2*${verticalNavbarPadding})`};
							justify-content: center;
							& > * {
								&:first-child,
								&:last-child {
									flex: 1 1 0%;
								}
								&:last-child {
									justify-content: flex-end;
								}
							}
						`}
					>
						{displayMobileUI && (
							<NavbarIconsContainer>
								<NavbarHamburgerMenu
									position="left"
									css={`
										margin-right: 15px !important;
									`}
								/>
								<NavbarIconLink
									Icon={SearchIcon}
									to={InternalRoutes.Search}
									css={`
										${displaySearch
											? `
            opacity: 1;
          `
											: `
            z-index: 2;
            opacity: ${hamburgerMenuIsOpen ? 1 : 0};
		      `}
										transition-duration: ${selectableTransitionDuration};
										transition-timing-function: ${selectableTransitionTimingFunction};
										transition-property: opacity;
									`}
								/>
							</NavbarIconsContainer>
						)}
						<div
							css={`
								width: 100%;
								display: flex;
								align-items: center;
								${displayMobileUI &&
								`
		  margin: 0 25px;
		  justify-content: center;
		`}
							`}
						>
							<Link to={InternalRoutes.Home}>
								<SITicketsLogo
									css={`
										max-width: 150px;
										width: 100%;
										transition-duration: ${selectableTransitionDuration};
										transition-timing-function: ${selectableTransitionTimingFunction};
										transition-property: fill;
									`}
									style={
										displayTransparentBackground
											? {
													fill: getAppColor("light"),
											  }
											: undefined
									}
								/>
							</Link>
						</div>
						{!displayMobileUI && (
							<div
								css={`
									display: flex;
									align-items: center;
									max-width: ${hideStandardNavigationItems ? "55%" : "60%"};
								`}
							>
								{!hideStandardNavigationItems && (
									<div
										css={`
											display: flex;
											& > *:not(:last-child) {
												margin-right: 17px;
											}
										`}
									>
										{!!navbarState.navigationItems &&
											navbarState.navigationItems.map((item, index) => {
												//TODO: Implement skeleton loading functionality.
												return <StandardNavbarNavigationItem key={index} {...item} />;
											})}
									</div>
								)}
								<div
									css={`
										${!hideStandardNavigationItems &&
										`
			margin-left: 41px;
		      `}
										overflow: hidden;
										width: 500px;
										${!displaySearch &&
										`
			opacity: 0;
			pointer-events: none;
			margin: 0;
			width: 0;
		      `}
										& > * {
											margin-bottom: 0;
										}
										.search-input {
											border-radius: 0px;
											${getTypographyStyles("bodySmall")}
											font-weight: 500;
										}
									`}
								>
									<NavSearch />
								</div>
							</div>
						)}
						<NavbarIconsContainer>
							<NavbarIconLink Icon={UserIcon} to={InternalRoutes.MyAccount()} />
							<NavbarIconLink
								Icon={() => {
									return (
										<div
											css={`
												position: relative;
											`}
										>
											{!!amountOfItemsInCart && (
												<div
													css={`
														background: ${getAppColor("primary")};
														width: 18px;
														height: 18px;
														border-radius: 100%;
														display: flex;
														text-align: center;
														align-items: center;
														justify-content: center;
														position: absolute;
														left: 0;
														right: 0;
														margin-left: 14.4px;
														top: -3px;
													`}
												>
													<Typography type="bodySmall" color={"light"} fontWeight={500}>
														{amountOfItemsInCart}
													</Typography>
												</div>
											)}
											<CartIcon />
										</div>
									);
								}}
								to={InternalRoutes.Cart}
							/>
							{hideStandardNavigationItems && !displayMobileUI && (
								<NavbarHamburgerMenu
									position="right"
									css={`
										margin-left: 6px;
									`}
								/>
							)}
						</NavbarIconsContainer>
					</div>
				</div>
			</div>
		</NavbarContext.Provider>
	) : (
		<></>
	);
};

const NavbarHamburgerMenuIconLine = styled.span<{
	open?: boolean;
}>`
	display: block;
	position: absolute;
	height: 4px;
	width: 100%;
	border-radius: 4px;
	opacity: 1;
	transition-duration: ${selectableTransitionDuration};
	transition-timing-function: ${selectableTransitionTimingFunction};
`;

const NavbarHamburgerMenuIconLine1 = styled(NavbarHamburgerMenuIconLine)`
	top: ${props => (props.open ? 8 : 0)}px;
	transform: rotate(${props => (props.open ? 45 : 0)}deg);
	width: ${props => (props.open ? 100 : 70)}%;
`;

const NavbarHamburgerMenuIconLine2 = styled(NavbarHamburgerMenuIconLine)`
	top: 8px;
	transform: rotate(0deg);
	opacity: ${props => (props.open ? 0 : "initial")};
	width: 100%;
	${props =>
		props.open &&
		`
    width: 0;
  `}
`;

const NavbarHamburgerMenuIconLine3 = styled(NavbarHamburgerMenuIconLine)`
	top: ${props => (props.open ? 8 : 16)}px;
	transform: rotate(${props => (props.open ? -45 : 0)}deg);
	width: ${props => (props.open ? 100 : 70)}%;
`;

const NavbarHamburgerMenuIconContent = styled.div`
	width: 27px;
	height: 20px;
	position: relative;
	user-select: none;

	@media ${breakpoints.mobile} {
		left: 0;
		right: initial;
	}
`;

const NavbarIconsContainer = styled.div`
	display: flex;
	align-items: center;
	padding-right: 10px;
	& > *:not(:last-child) {
		margin-right: 10px;
	}

	@media (max-width: ${breakpoints.tablet}) {
		padding-right: 0;
	}
`;
