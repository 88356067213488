import { APIClientResponse } from "./types/APIClientResponse";
import { apiClient } from "./apiClient";
import { Location } from "../model/Location";

const FIRST_ATTEMPT_DISTANCE = "50";
const SECOND_ATTEMPT_DISTANCE = "100";
const THIRD_ATTEMPT_DISTANCE = "500";

export function getDiscoverEvents(
	page: number | null,
	category: string | null | undefined,
	from: string | null,
	to: string | null,
	distance: string | null,
	location: Location | null,
	pageSize: number | null,
	shortNames?: boolean,
	source?: string
): APIClientResponse<{
	metadata: {
		page_number: number;
		page_size: number;
		total_records: number;
	};
	events: {
		event_id: number;
		event_name: string;
		event_slug: string;
		occurs_at: string;
		event_image: string;
		venue_id: number;
		venue_name: string;
		venue_slug: string;
		primary?: boolean;
		ticket_group_price: string;
		venue_city: string;
	}[];
}> {
	let params = {
		...(page && { page_number: page }),
		...(pageSize && { page_size: pageSize }),
		...(category && { category_slug: category }),
		...(from && { date_from: from }),
		...(to && { date_to: to }),
		...(distance && { distance }),
		...(location && {
			latitude: location.latitude,
			longitude: location.longitude,
		}),
		...(shortNames && {
			short_names: "",
		}),
		...(source && {
			source,
		}),
	};

	return apiClient.get("/events/discover", {
		params,
	});
}

export async function discoverEvents(location: Location | null, shortNames?: boolean, source?: string) {
	const page = 1;
	const pageSize = 10;

	type ArgumentsOf<F extends Function> = F extends (...args: infer A) => any ? A : never;

	let firstAttemptArgs: ArgumentsOf<typeof getDiscoverEvents>, secondAttemptArgs: ArgumentsOf<typeof getDiscoverEvents>, thirdAttemptArgs: ArgumentsOf<typeof getDiscoverEvents>;

	firstAttemptArgs = [page, null, null, null, FIRST_ATTEMPT_DISTANCE, location, pageSize, shortNames, source];
	secondAttemptArgs = [page, null, null, null, SECOND_ATTEMPT_DISTANCE, location, pageSize, shortNames, source];
	thirdAttemptArgs = [page, null, null, null, THIRD_ATTEMPT_DISTANCE, location, pageSize, shortNames, source];

	let result = await getDiscoverEvents(...firstAttemptArgs);
	result = result.data.metadata.total_records < 8 ? await getDiscoverEvents(...secondAttemptArgs) : result;
	result = result.data.metadata.total_records < 8 ? await getDiscoverEvents(...thirdAttemptArgs) : result;

	return result;
}
