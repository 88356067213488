export const popupMaxWidth = 500;

export const mediaQueries = {
	max480: "(max-width: 480px)",
	max500: "(max-width: 500px)",
	max670: "(max-width: 670px)",
	max750: "(max-width: 750px)",
	max900: "(max-width: 900px)",
	max1000: "(max-width: 1000px)",

	popupMax: `(max-width: ${popupMaxWidth}px)`,
} as const;
