import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import lock from "../../resource/img/icons/lock.svg";
import tick from "../../resource/img/icons/tick.svg";
import { getAppColor } from "../../util/appColors";
import { Typography } from "../Typography/Typography";
import { breakpoints } from "../../util/breakpoints";
import { MobileStoreButton } from "../MobileStoreButtons/MobileStoreButton";
import { SocialLink } from "../SocialLink/SocialLink";
import { CollapsibleCard } from "../CollapsibleCard/CollapsibleCard";
import { FooterLinks } from "./FooterLinks";
import { useIsMobileBreakpoint } from "../../hooks/useIsMobileBreakpoint";
import { maxContentWidth } from "../../util/maxContentWidth";
import { getFooterLinksService } from "../../../services/footer";
import { FooterLinksSection } from "../../../model/optimizedModel/footer";

export const Footer = (props: { preview?: boolean }) => {
	const isMobile = useIsMobileBreakpoint();

	const [footerLinkSections, setFooterLinksSections] = useState<FooterLinksSection[]>([]);

	useEffect(() => {
		getFooterLinksService({
			preview: !!props.preview,
		}).then(response => {
			setFooterLinksSections(response.data);
		});
	}, []);

	return (
		<FooterContainer>
			<FooterRow>
				<RowInnerContainer>
					<Title type="heading3" color="light">
						Sports Illustrated Tickets
					</Title>
					<ItemContainer>
						<img src={tick} alt="Tick icon" />
						<BaseText type="bodySmall" color="light">
							Fan Guarantee
						</BaseText>
					</ItemContainer>

					<ItemContainer>
						<img src={lock} alt="Lock icon" />
						<BaseText type="bodySmall" color="light">
							Verified safe and secure checkout
						</BaseText>
					</ItemContainer>
				</RowInnerContainer>
			</FooterRow>

			<FooterBody>
				<BodyInnerContainer>
					{footerLinkSections?.map((linkSection, key) => {
						return isMobile ? (
							<CollapsibleContainer key={key}>
								<CollapsibleCard
									header={
										<DropdownTitle>
											<Title type="normalSpecialBody" color="light">
												{linkSection.columnTitle}
											</Title>
										</DropdownTitle>
									}
									controlColor={getAppColor("light", "main")}
									background={getAppColor("dark", "main")}
								>
									<DropdownContent>
										<FooterLinks links={linkSection.links} />
									</DropdownContent>
								</CollapsibleCard>
							</CollapsibleContainer>
						) : (
							<FooterColumn key={key}>
								<Title type="heading4" color="light">
									{linkSection.columnTitle}
								</Title>
								<FooterLinks links={linkSection.links} />
							</FooterColumn>
						);
					})}

					<FooterColumn>
						<Title type="heading4" color="light">
							Download Our App
						</Title>
						<DownloadButtonContainer>
							<MobileStoreButton mobileStore="appStore" />
							<MobileStoreButton mobileStore="googlePlay" />
						</DownloadButtonContainer>
					</FooterColumn>
				</BodyInnerContainer>
			</FooterBody>

			<FooterRow>
				<ConnectWithUs>
					<BaseText type={!isMobile ? "extraLargeSpecialBody" : "normalSpecialBody"} color="light">
						Connect With Us
					</BaseText>
					<SocialLinksContainer>
						<SocialLink platform="twitter" color="light" />
						<SocialLink platform="facebook" color="light" />
						<SocialLink platform="instagram" color="light" />
					</SocialLinksContainer>
				</ConnectWithUs>
			</FooterRow>
		</FooterContainer>
	);
};

const BodyInnerContainer = styled.div`
	display: flex;
	justify-content: space-evenly;
	width: 100%;
	max-width: ${maxContentWidth};

	@media (max-width: ${breakpoints.mobile}) {
		flex-direction: column;
		align-items: center;
		border: none;
	}
`;

const RowInnerContainer = styled(BodyInnerContainer)`
	@media (max-width: ${breakpoints.tablet}) {
		flex-direction: column;
		align-items: center;
		& > :not(:last-child) {
			margin-bottom: 16px;
		}
	}
`;

const FooterBody = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	border-top: 1px solid ${getAppColor("darkGrey", "main")};

	@media (max-width: ${breakpoints.mobile}) {
		flex-direction: column;
		align-items: center;
		border: none;
	}
`;

const FooterColumn = styled.div`
	display: flex;
	flex-direction: column;
	padding: 50px 0;

	@media (max-width: ${breakpoints.mobile}) {
		padding-bottom: 20px;
	}
`;

const FooterContainer = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${getAppColor("dark", "main")};
	margin: auto;
	width: 100%;
	justify-content: center;
`;

const BaseText = styled(Typography)`
	text-align: left;
`;

const Title = styled(BaseText)`
	margin-bottom: 6px;
	@media (max-width: ${breakpoints.mobile}) {
		text-align: center;
	}
`;

const ItemContainer = styled.div`
	display: flex;
	margin-bottom: 5px;
	align-items: center;

	img {
		padding-right: 14px;
	}
`;

const FooterRow = styled.div`
	padding: 30px 0;
	display: flex;
	justify-content: center;
`;

const ConnectWithUs = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	gap: 13px;

	@media (max-width: ${breakpoints.mobile}) {
		flex-direction: column;
		align-items: center;
	}
`;

const DropdownTitle = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	padding-left: 30px;
`;

const DropdownContent = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	& > :not(:last-child) {
		margin-bottom: 10px;
	}
`;

const CollapsibleContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 90%;
	border-top: 1px solid ${getAppColor("darkGrey", "dark")};
`;

const DownloadButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	@media (max-width: ${breakpoints.mobile}) {
		margin-top: 16px;
		flex-direction: row;

		& > :not(:last-child) {
			margin-right: 48px;
		}
	}
`;

const SocialLinksContainer = styled.div`
	display: flex;

	& > :not(:last-child) {
		margin-right: 13px;
	}
`;
