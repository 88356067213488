import React, { useEffect, useState } from "react";
import { FuturesSale } from "../../../../model/optimizedModel/myAccount";
import { getMyAccountFuturesSold } from "../../../../services/myAccount";
import { MyAccountItemsList } from "../MyAccountItemsList";
import { MyAccountFutureSale } from "../Optimized/MyAccountFutureSale";
import { MyAccountFuturesPage } from "./MyAccountFuturesPage";
import toast from "react-hot-toast";
import { useToastRemoval } from "../../../hooks/useToastRemoval";

export const FuturesSold = () => {
	const [futures, setFutures] = useState<FuturesSale[]>();
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		getMyAccountFuturesSold()
			.then(result => {
				setFutures(result.data);
			})
			.catch(error => {
				if (typeof error === "string") {
					toast.error(error);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	useToastRemoval();

	return (
		<MyAccountFuturesPage mobileTitle="Reservations Sold">
			<MyAccountItemsList<FuturesSale>
				items={futures}
				itemsName="sales"
				loading={loading}
				renderItem={props => {
					return <MyAccountFutureSale {...props} />;
				}}
			/>
		</MyAccountFuturesPage>
	);
};
