import { ModalPopup } from "../ModalPopup/ModalPopup";
import { ModalCloseButton } from "../Modal/ModalCloseButton";
import React from "react";
import styled from "styled-components/macro";
import { Button } from "../Button/Button";
import { ResetButton } from "../ResetButton/ResetButton";
import { BackArrow } from "../BackArrow/BackArrow";
import { ModalReactNode } from "../../../model/Modal";

export const MobileFiltersModal = ({ onDidClose, onApply, filters, onReset, centerHeaderElements }: { onDidClose: () => void; onApply: () => void; filters: React.ReactNode[]; onReset: () => void; centerHeaderElements?: ModalReactNode }) => {
	return (
		<ModalPopup onDidClose={onDidClose} rightHeaderElements={onClose => <ModalCloseButton onClick={onClose} />} fullscreen centerHeaderElements={centerHeaderElements}>
			{onClose => (
				<>
					<ClearAllContainer>
						<BackArrow onClick={onClose} />
						<ResetButton text="RESET" onClick={onReset} />
					</ClearAllContainer>
					<FiltersContainer>
						{filters.map((filter, index) => {
							return <Filter key={index}>{filter}</Filter>;
						})}
					</FiltersContainer>

					<ButtonContainer>
						<Button
							onClick={() => {
								onApply();
								onClose();
							}}
						>
							Apply
						</Button>
					</ButtonContainer>
				</>
			)}
		</ModalPopup>
	);
};

const Filter = styled.div`
	width: 100%;
	margin: 16px 0;
	:last-child {
		margin-bottom: 32px;
	}
`;

const FiltersContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	border-bottom: 1px solid #e5e5e5;
	margin-bottom: 32px;
`;

const ClearAllContainer = styled.div`
	height: 38px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	margin-top: 26px;
`;

const ButtonContainer = styled.div`
	display: flex;
	max-width: 100%;
	justify-content: center;
	align-items: flex-start;
	height: 65vh;

	button {
		width: 100%;
		height: 50px;
	}
`;
