import React, { createContext, Dispatch, useContext, useEffect, useReducer, useRef, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import si_icon from "../resource/assets/si_icon.svg";
import { CommonPage, NewEvents } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { getPerformerEvents } from "../../services/actions/eventsService";
import { useDataService } from "../hooks/useServiceCall";
import { getPerformerAssets } from "../../services/actions/assetsService";
import { Hero } from "../components/Hero/Hero";
import { FreeStarVerticalAd, StickyFooterContainer, VerticalAdContainer, VerticalAdFrame } from "../components/Advertisement/Advertisement";
import { useHubSpotTracker } from "../hooks";
import { isRight } from "fp-ts/lib/These";
import { Event } from "../../model/Event";
import { LocationPicker } from "../components/LocationPicker";
import { MetaDecorator, getPerformerTagTitle, getPerformerTagDescription } from "../components/SEOMetadecorator/MetaDecorator";
import styled from "styled-components/macro";
import { InternalRoutes } from "../Link";
import { mediaQueries } from "../util/mediaQueries";
import { NewMultiFilterContainer } from "../components/Filters/NewMultiFilterContainer";
import queryString from "query-string";
import { parseSearch } from "../util/parseSearch";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { DateRange } from "../components/Filters/DateRangeFilter/DateRangeFilter";
import { PageBio } from "../components/PageBio/PageBio";
import { getPerformerBioService } from "../../services/actions/performersService";
import toast from "react-hot-toast";
import { useToastRemoval } from "../hooks/useToastRemoval";
import { getDiscoverEvents } from "../../services/discover";
import { Config } from "../../Config";
import { firstLetterToUpperCase, removeHyphens } from "../util/stringFormat";
import { formatDateToUTC } from "../util/dateFormat";
import { NewDateRange } from "../components/DateRange/NewDateRange";
import { useInitiallyTransparentNavbar, useNewNavbar } from "../hooks/navbar";
import { SkeletonWrapper } from "../components/SkeletonWrapper/SkeletonWrapper";
import { maxContentWidthStyles, maxContentWidthValue, maxContentWidthPadding, maxContentWidth } from "../util/maxContentWidth";
import { ThumbnailCarouselRow } from "../components/Thumbnail/ThumbnailCarouselRow";
import { Typography, FontFamily } from "../components/Typography/Typography";
import { breakpoints } from "../util/breakpoints";
import { getAppColor } from "../util/appColors";
import { selectableTransitionTimingFunction, selectableTransitionDuration } from "../util/transitions";
import axios from "axios";
import { useAutoUpdateState } from "../hooks/useAutoUpdateState";
import { Chip } from "../components/Chip/Chip";
import { FreeStarAd } from "../components/FreeStarAd";

const PERFORMER_EVENTS_PAGE_SIZE = 20;

const NEW_HOME_AWAY_FILTER_OPTIONS: {
	value: VenueFilterKey | null;
	label: string;
}[] = [
	{
		value: null,
		label: "Home/Away",
	},
	{
		value: "home",
		label: "Home",
	},
	{
		value: "away",
		label: "Away",
	},
];

interface PerformerRouteParams {
	performer: string;
}

interface PerformerSearchParams {
	from: string | undefined;
	to: string | undefined;
	gameVenue: string | null;
}

type NewsArticle = {
	title: string;
	pubDate: string;
	creator: string;
	externalLink: string;
	encodedContent: string;
	decodedContent: string;
	snippet: string;
	banner: string;
};

type VenueFilterStateAction =
	| {
			type: "reset";
	  }
	| {
			type: "toggle";
			key: VenueFilterKey;
	  };

const PerformerRouteContext = createContext<{
	newsArticles?: NewsArticle[];
	eventsLoaded?: boolean;
	searchParams?: PerformerSearchParams;
	onDateChange?: (date: DateRange) => void;
	dateRange?: string;
	hideVenueFilter?: boolean;
	venueFilterState?: VenueFilterState;
	dispatchVenueFilterStateUpdate?: Dispatch<VenueFilterStateAction>;
	performer?: string;
}>({});

type VenueFilterKey = "home" | "away";

type VenueFilterState = Partial<Record<VenueFilterKey, boolean>>;

const laptopBreakpoint = "1220px";

export const Performer = (props: RouteComponentProps<PerformerRouteParams>) => {
	const searchParams = parseSearch<PerformerSearchParams>(props.location.search);
	const history = useHistory();

	const performer = props.match.params.performer;
	const location = useSelector(state => state.persistent.location);
	const [hideVenueFilter, setHideVenueFilter] = useState<boolean>(true);
	//The following state variable is used to determine if the home/away filter was already rendered
	const [filtersSet, setFiltersSet] = useState(false);
	const [eventsLoaded, setEventsLoaded] = useState(false);

	useHubSpotTracker({ timeout: 15, page: "performer", slug: performer });

	useEffect(() => {
		gameVenue.current = searchParams.gameVenue === "all" ? null : searchParams.gameVenue;
	}, [searchParams.gameVenue]);

	const [relatedEvents, setRelatedEvents] = useState<
		{
			background: string;
			title: string;
			subtitle: string;
			secondarySubtitle: string;
			slug: string;
		}[]
	>([]);
	const dispatch = useDispatch();

	const [loadingAssets, setLoadingAssets] = useState(true);

	const { result: performerAssets, isLoading: isLoadingAssets } = useDataService(getPerformerAssets, [performer], {});

	useEffect(() => {
		setLoadingAssets(isLoadingAssets);
	}, [isLoadingAssets]);

	const [allEventsLoading, setAllEventsLoading] = useState(true);

	const [events, setEvents] = useState<Event[]>([]);

	const [localEvents, setLocalEvents] = useState<Event[]>([]);

	useEffect(() => {
		setAllEventsLoading(true);
		if (location || document.readyState === "complete") {
			eventsPage.current = 0;
			localEventsPage.current = 0;
			setEvents([]);
			setLocalEvents([]);
		}
	}, [performer, searchParams.gameVenue, searchParams.from, searchParams.to, location]);

	useEffect(() => {
		if (allEventsLoading && !events.length && !localEvents.length) {
			loadLocalEvents();
			loadEvents();
			setAllEventsLoading(false);
		}
	}, [allEventsLoading, events.length, localEvents.length]);

	const [totalEvents, setTotalEvents] = useState(0);

	const [totalLocalEvents, setTotalLocalEvents] = useState(0);

	const [performerCategoryName, setPerformerCategoryName] = useState<string>();

	const [performerCategorySlug, setPerformerCategorySlug] = useState<string>();

	const [performerRootCategory, setPerformerRootCategory] = useState<string | undefined>();

	const [eventsLoading, setEventsLoading] = useState(true);

	const [localEventsLoading, setLocalEventsLoading] = useState(true);

	const eventsPage = useRef(1);

	const localEventsPage = useRef(1);

	const gameVenue = useRef<string | null>(null);

	const loadEvents = async () => {
		setEventsLoading(true);

		eventsPage.current = eventsPage.current + 1;

		let results = await dispatch(getPerformerEvents(performer, null, eventsPage.current, PERFORMER_EVENTS_PAGE_SIZE, searchParams.from, searchParams.to, gameVenue.current));

		if (isRight(results)) {
			const { data, metadata } = results.right;

			setPerformerCategoryName(metadata.categoryName);
			setPerformerCategorySlug(metadata.categorySlug);
			setPerformerRootCategory(metadata.rootCategory);
			setEvents(eventsPage.current > 1 ? [...events, ...data] : data);
			setTotalEvents(metadata.totalRecords);
		}
		setEventsLoading(false);
		setEventsLoaded(true);
	};

	const loadLocalEvents = async () => {
		if (location) {
			setLocalEventsLoading(true);

			localEventsPage.current = localEventsPage.current + 1;

			let results = await dispatch(getPerformerEvents(performer, gameVenue.current ? null : location, localEventsPage.current, PERFORMER_EVENTS_PAGE_SIZE, searchParams.from, searchParams.to, gameVenue.current));

			if (isRight(results)) {
				const { data: newLocalEvents, metadata } = results.right;

				let newTotalLocalEvents = metadata.totalRecords;

				//TODO: Remove the following if. This one was added as a quick fix because there's an api bug where the total events count retrieved may
				//be higher than the actual existing events, and so the front-end keeps asking for the next page to get events that actually don't exist
				if (newTotalLocalEvents > 0 && !newLocalEvents.length) {
					newTotalLocalEvents = localEvents.length;
				}

				setTotalLocalEvents(newTotalLocalEvents);
				setLocalEvents(localEventsPage.current > 1 ? [...localEvents, ...newLocalEvents] : newLocalEvents);
			}

			setLocalEventsLoading(false);
		}
	};

	const wrapLocationPicker = useMediaQuery(mediaQueries.max750);

	const onFilterChange = (field: keyof typeof searchParams, value: any) => {
		let nextFilters = { ...searchParams };
		if (value) {
			//@ts-ignore
			nextFilters[field] = value;
		} else {
			delete nextFilters[field];
		}
		history.replace(InternalRoutes.Performer(`${performer}?` + queryString.stringify(nextFilters)));
	};

	const [dateRange, setDateRange] = useState<string | undefined>();
	const onDateChange = (date: DateRange) => {
		history.replace(InternalRoutes.Performer(`${performer}?` + queryString.stringify({ ...searchParams, ...date })));
	};

	useEffect(() => {
		let text = "";
		if (searchParams.from) {
			text += searchParams.from;
		}
		if (searchParams.to && searchParams.to !== searchParams.from) {
			text += ` to ${searchParams.to}`;
		}
		setDateRange(text);
	}, [searchParams.from, searchParams.to]);

	const [venueFilterState, dispatchVenueFilterStateUpdate] = useReducer(
		(state: VenueFilterState, action: VenueFilterStateAction) => {
			switch (action.type) {
				case "reset":
					return {};
				case "toggle":
					return {
						...state,
						[action.key]: !state[action.key],
					} as VenueFilterState;
			}
		},
		{
			home: searchParams.gameVenue === "home" || searchParams.gameVenue === "all",
			away: searchParams.gameVenue === "away" || searchParams.gameVenue === "all",
		}
	);

	const gameVenueFilterInitialized = useRef(false);

	useEffect(() => {
		if (gameVenueFilterInitialized.current) {
			if (venueFilterState.home) {
				if (venueFilterState.away) {
					onFilterChange("gameVenue", "all");
					return;
				} else {
					onFilterChange("gameVenue", "home");
				}
			} else if (venueFilterState.away) {
				onFilterChange("gameVenue", "away");
			} else {
				onFilterChange("gameVenue", null);
			}
		} else {
			gameVenueFilterInitialized.current = true;
		}
	}, [venueFilterState.away, venueFilterState.home]);

	const getRelatedEvents = async () => {
		const discoverPage = 1;
		const pageSize = 10;
		const distance = "100";
		try {
			const result = await getDiscoverEvents(discoverPage, performerCategorySlug, null, null, distance, null, pageSize, false);
			setRelatedEvents(
				result?.data?.events.map(event => ({
					background: Config.getResourceUrl(event.event_image),
					title: event.event_name,
					subtitle: event.venue_name,
					secondarySubtitle: event.occurs_at ? formatDateToUTC(new Date(event.occurs_at), "EEE. LLL d, yyyy h:mm a") : "TBD",
					slug: event.event_slug,
					onClick: () => {
						history.push(`/event/${event.event_slug}`);
					},
				}))
			);
		} catch (error) {
			console.error(error);
		}
	};

	const displayRelatedEvents = useAutoUpdateState(() => !totalLocalEvents && !totalEvents && !allEventsLoading && !eventsLoading && !localEventsLoading && performerCategorySlug, [allEventsLoading, eventsLoading, localEventsLoading, totalLocalEvents, totalEvents]);

	useEffect(() => {
		const allEvents = [...events, ...localEvents];
		if (!filtersSet && allEvents.length > 0) {
			setFiltersSet(true);
			setHideVenueFilter(allEvents.every(event => (event.homeVenueId ? !event?.homeVenueId : true)));
		}

		if (displayRelatedEvents) {
			getRelatedEvents();
		}
	}, [events, localEvents, displayRelatedEvents, performerCategorySlug]);

	const [bio, setBio] = useState("");
	const [loadingBio, setLoadingBio] = useState(true);
	useEffect(() => {
		setHideVenueFilter(true);
		setFiltersSet(false);
		if (bio) {
			setBio("");
		}
		getPerformerBioService(performer)
			.then(result => {
				setBio(result.data.bio);
				setLoadingBio(false);
			})
			.catch(error => {
				if (typeof error === "string") {
					toast.error(error);
				}
			});
	}, [performer]);

	useToastRemoval();

	useInitiallyTransparentNavbar();

	useNewNavbar();

	useEffect(() => {
		if (performer === "si-tickets-tailgate-by-gameday-hospitality") {
			history.replace(InternalRoutes.Performer("si-tickets-tailgate"));
		}
	}, [performer]);

	const [newsArticles, setNewsArticles] = useState<NewsArticle[]>([]);

	const [newsArticlesLoading, setNewsArticlesLoading] = useState(true);

	React.useEffect(() => {
		if (performer) {
			setNewsArticlesLoading(true);
			setNewsArticles([]);
			setSelectedHeroTab(0);
			axios
				.get(`https://feed.${Config.getServiceEnv()}.sitickets.com/performer/${performer}/news`)
				.then(result => {
					setNewsArticles(result.data.data);
				})
				.finally(() => {
					setNewsArticlesLoading(false);
				});
		}
	}, [performer]);

	const [selectedHeroTab, setSelectedHeroTab] = useState(0);

	const [showAds, setShowAds] = useState(false);
	const [adPlacement, setAdPlacement] = useState("");

	useEffect(() => {
		if (performerRootCategory) {
			setShowAds(["sports", "concerts"].includes(performerRootCategory));
			setAdPlacement(`sitickets.com_${performerRootCategory}_rightrail`);
		}
	}, [performerRootCategory]);

	return (
		<PerformerRouteContext.Provider
			value={{
				newsArticles,
				eventsLoaded,
				onDateChange,
				dateRange,
				hideVenueFilter,
				venueFilterState,
				dispatchVenueFilterStateUpdate,
				performer,
				searchParams,
			}}
		>
			<CommonPage isSitixLogoSelected={true}>
				<MetaDecorator title={getPerformerTagTitle(performer)} description={getPerformerTagDescription(performer)} />

				<Hero
					//TODO: Remove ts-ignore
					//@ts-ignore
					assets={performerAssets}
					loading={loadingAssets || newsArticlesLoading}
					performer={performer}
					tabs={
						!!newsArticles?.length
							? [
									{
										title: "Events",
										onSelect: setSelectedHeroTab,
									},
									{
										title: "SI News",
										onSelect: setSelectedHeroTab,
									},
							  ]
							: undefined
					}
					selectedTab={selectedHeroTab}
				/>
				<ContentContainer>
					<div
						css={`
							max-width: 100%;
							min-width: min(calc(${maxContentWidth} - 2*${maxContentWidthPadding}px), calc(100vw - 2*${maxContentWidthPadding}px));
x
						`}
					>
						{
							[
								<>
									{location && !!localEvents.length && (
										<>
											<NewEvents
												title={gameVenue.current ? `${gameVenue.current} GAMES` : "Events Near"}
												wrapSubtitle={wrapLocationPicker}
												subtitle={!gameVenue.current && <LocationPicker className="subtitle" location={location} />}
												onLoadMore={() => {
													loadLocalEvents();
												}}
												loading={localEventsLoading || allEventsLoading}
												events={localEvents}
												total={totalLocalEvents}
												ads={performerAssets}
												performer={props.match.params.performer.replace("-", " ")}
												filters={<Filters />}
												newDesign
												paginatedScroll
												adsMetadata={{ adCategory: performerRootCategory, listingIdentifier: "events_near" }}
											/>
										</>
									)}
									{(events.length > 0 || !localEvents) && (
										<NewEvents
											title={location ? "Events in all Locations" : "Events"}
											//For some reason, the next usage of removeHyphens shows a ts error. I believe this might be a ts bug because performer can never be undefined
											emptyMessage={
												<>
													<p>Sorry, there are no events for {firstLetterToUpperCase(removeHyphens(performer))} at this time.</p>
													<p>
														Please see below for some of SI Tickets's top selling <strong>{performerCategoryName}</strong> events:
													</p>
												</>
											}
											subtitle={!gameVenue.current && (!location || !localEvents) && <LocationPicker className="subtitle" location={location} />}
											wrapSubtitle={wrapLocationPicker}
											onLoadMore={() => {
												loadEvents();
											}}
											loading={eventsLoading || allEventsLoading}
											events={events}
											total={totalEvents}
											performer={props.match.params.performer.replace("-", " ")}
											filters={<Filters />}
											newDesign
											paginatedScroll
											adsMetadata={{ adCategory: performerRootCategory, listingIdentifier: "events" }}
										/>
									)}
									{displayRelatedEvents && (
										<RelatedEventsContainer>
											<RelatedEventsTitle type="heading2">Related Events</RelatedEventsTitle>
											<ThumbnailCarouselRow thumbnails={relatedEvents} loading={!relatedEvents.length} titlesAreLoading={!relatedEvents.length} subtitlesAreLoading={!relatedEvents.length} secondarySubtitlesAreLoading={!relatedEvents.length} />
										</RelatedEventsContainer>
									)}
									<NewsArticles />
								</>,
								<NewsArticles />,
							][selectedHeroTab]
						}
					</div>
					{showAds && (
						<VerticalAdContainer
							css={`
								min-width: 160px;
								@media (max-width: ${laptopBreakpoint}) {
									display: none;
								}
							`}
						>
							<VerticalAdFrame>
								<FreeStarVerticalAd placement={`${adPlacement}1`} slotId={`${adPlacement}1_2`} />
							</VerticalAdFrame>
						</VerticalAdContainer>
					)}
				</ContentContainer>

				{loadingBio ? <PageBioSkeletonWrapper loading /> : <PageBio htmlString={bio} />}
				{showAds && (
					<StickyFooterContainer>
						<FreeStarAd placement="sitickets.com_sticky_footer" />
					</StickyFooterContainer>
				)}
			</CommonPage>
		</PerformerRouteContext.Provider>
	);
};

const Filters = () => {
	const history = useHistory();

	const { searchParams, onDateChange, dateRange, hideVenueFilter, venueFilterState, dispatchVenueFilterStateUpdate, performer } = useContext(PerformerRouteContext);

	return (
		<>
			{searchParams && onDateChange && (
				<NewMultiFilterContainer
					filters={[
						<NewDateRange from={searchParams.from} to={searchParams.to} onChange={onDateChange} text={dateRange} newDesign />,
						...(hideVenueFilter
							? []
							: [
									<div
										css={`
											display: flex;
											& > *:not(:last-child) {
												margin-right: 8px;
											}
										`}
									>
										{NEW_HOME_AWAY_FILTER_OPTIONS.map((option, index) => {
											if (option.value !== null && venueFilterState !== undefined) {
												return (
													<Chip
														key={index}
														selected={venueFilterState[option.value]}
														onClick={() => {
															if (dispatchVenueFilterStateUpdate) {
																dispatchVenueFilterStateUpdate({
																	type: "toggle",
																	key: option.value!,
																});
															}
														}}
													>
														{option.label}
													</Chip>
												);
											}
										})}
									</div>,
							  ]),
					]}
					searchParams={searchParams}
					onReset={() => {
						if (performer !== undefined) {
							history.push(InternalRoutes.Performer(performer));
						}
					}}
					newDesign
				/>
			)}
		</>
	);
};

const NewsArticles = () => {
	const { newsArticles, eventsLoaded } = useContext(PerformerRouteContext);

	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	return (
		<>
			{eventsLoaded && !!newsArticles?.length && (
				<div
					css={`
						${maxContentWidthStyles}
						@media (max-width: ${breakpoints.mobile}) {
							padding: 0;
						}
						font-family: ${FontFamily.Poppins};
					`}
				>
					<h3
						css={`
							display: flex;
							align-items: center;
							justify-content: center;
							gap: 3.5px;
							font-size: 36px;
							font-weight: 600;
							color: black;
							margin-top: 38px;
							text-transform: uppercase;
							font-family: ${FontFamily.Solano};
							img {
								display: inline-block;
								object-fit: scale-down;
								object-position: center;
								width: 42.1px;
								margin-top: 2.34px;
								margin-right: 2px;
							}
							@media (max-width: ${breakpoints.mobile}) {
								font-size: 35px;
								text-align: left;
								padding: 0 ${maxContentWidthPadding}px;
								margin-top: 30px;
								margin-bottom: 0px;
							}
						`}
					>
						<img src={si_icon} />
						<span>News</span>
					</h3>
					{newsArticles.map((article, index) => {
						return (
							<a
								css={`
									display: flex;
									padding: 28px 27px;
									cursor: pointer;
									border-bottom: 1px solid ${getAppColor("darkGrey", "light")};
									@media (max-width: ${breakpoints.mobile}) {
										padding: ${maxContentWidthPadding}px;
									}
								`}
								key={index}
								href={article.externalLink}
								target="_blank"
								rel="noreferrer"
							>
								<img
									src={article.banner}
									css={`
										width: 28%;
										margin-right: 18px;
										object-fit: cover;
										border-radius: 10px;
									`}
								/>
								<div
									css={`
										padding-top: 17px;
										padding-bottom: 9px;
										text-align: left;
										display: flex;
										flex-direction: column;
										justify-content: space-between;
										width: 100%;
									`}
								>
									<div>
										<h4
											css={`
												color: black;
												font-size: 21px;
												line-height: 24px;
												font-weight: 600;
												transition: color ${selectableTransitionTimingFunction} ${selectableTransitionDuration};
												&:hover {
													color: ${getAppColor("primary")};
												}
											`}
										>
											{article.title}
										</h4>
										{!isMobile && (
											<p
												css={`
													font-size: 16px;
													line-height: 24px;
													font-weight: 400;
													margin-top: 2px;
												`}
											>
												{article.snippet}
											</p>
										)}
									</div>
									<div
										css={`
											padding-top: 12px;
											font-weight: 600;
											color: ${getAppColor("darkGrey", "dark")};
											font-size: 14px;
											line-height: 24px;
											display: flex;
											margin-top: 17px;
											justify-content: space-between;
											align-items: center;
											padding-right: 10px;
											white-space: nowrap;
										`}
									>
										<span
											css={`
												overflow: hidden;
												text-overflow: ellipsis;
												flex: 0.6;
												min-width: 0;
											`}
										>
											By {article.creator}
										</span>
										<span
											css={`
												width: 100%;
												text-align: right;
												flex: 0.4;
											`}
										>
											{new Date(article.pubDate).toLocaleDateString("en-US", {
												month: "numeric",
												day: "numeric",
												year: "2-digit",
											})}
										</span>
									</div>
								</div>
							</a>
						);
					})}
				</div>
			)}
		</>
	);
};

const PageBioSkeletonWrapper = styled(SkeletonWrapper)`
	max-width: ${maxContentWidthValue - 2 * maxContentWidthPadding}px;
	margin: 0 auto;
	height: 150px;
	margin-top: 20px;
	width: calc(100% - 2 * ${maxContentWidthPadding}px);
	padding: 0px;
`;

const RelatedEventsContainer = styled.div`
	${maxContentWidthStyles}
	width: 100%;
`;

const RelatedEventsTitle = styled(Typography)`
	text-align: left;
	margin-bottom: 20px;
`;

const ContentContainer = styled.div`
	display: flex;
	justify-content: center;
`;
