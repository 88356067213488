import { CancelToken } from "axios";
import { E164Number } from "libphonenumber-js/max";
import { Config } from "../Config";
import { User } from "../model/User";
import { apiBaseUrl, apiClient } from "./apiClient";
import { APIClientResponse } from "./types/APIClientResponse";

export function getMyUser(): APIClientResponse<User> {
	return apiClient.get("user/me");
}

export function postUserResetPassword(email: string, forcedReset: boolean, remoteIp: string): APIClientResponse<boolean | string> {
	return apiClient.post("user/reset-password", {
		email,
		forcedReset,
		remoteIp,
		sessionCreatedAt: window.sessionStorage.getItem("created_at"),
		sessionId: window.sessionStorage.getItem("user_session_id"),
		deviceName: Config.getPlatformOS(),
	});
}

export function putUserPhone(phoneNumber: E164Number): APIClientResponse<string> {
	return apiClient.put("user/phone", {
		number: phoneNumber,
	});
}

export function deleteUserAddress(id: number): APIClientResponse<string> {
	return apiClient.delete("user/address/" + id);
}

export const getWalletInfo = (): APIClientResponse<{
	wallet: {
		promotionalWallet: number;
		userWallet: number;
	};
}> => {
	return apiClient.get("/wallet/info");
};

export const deleteAccount = async (email: string) => {
	return apiClient.post(`${apiBaseUrl("csemail")}/deleteAccount`, {
		email,
	});
};

export const getUserProvider = (provider: "Google" | "Facebook", redirectURI: string, cancelToken?: CancelToken): APIClientResponse<{
	url: string,
}> => {
	return apiClient.post(`/user/${{
		Google: "google",
		Facebook: "facebook",
	}[provider]}`, {
		redirectURI,
	}, {
		cancelToken,
	});
};

export const postUserProvider = (provider: "google" | "facebook", queryString: string, redirectURI: string): APIClientResponse<{
	token: {
		accessToken: string;
		token: string;
	};
	user: User;
}> => {
	return apiClient.post(`/user/${provider}/callback${queryString}`, {
		deviceName: Config.getPlatformOS(),
		sessionCreatedAt: window.sessionStorage.getItem("created_at"),
		sessionId: window.sessionStorage.getItem("user_session_id"),
		redirectURI,
	});
};