import React, { useState, useEffect, useRef } from "react";
import { usePopper } from "react-popper";
import { NavigationItem } from "../../../model/Navigation";
import styled from "styled-components";
import arrowMidGray from "../../resource/image/arrow-mid-gray.svg";
import arrowRed from "../../resource/image/arrow-red.svg";

export const NavLinks = (props: { items: NavigationItem[] }) => (
	<>
		{props.items?.sort(sortItems).map(item => (
			<NavLink key={item.id} item={item} />
		))}
	</>
);

const COLUMN_BREAKPOINT = 10;
export function NavLink(props: { item: NavigationItem }) {
	const [hover, setHover] = useState(false);
	const [show, setShow] = useState(false);
	const visible = show || hover;

	const referenceRef = useRef<HTMLAnchorElement | null>(null);
	const popperRef = useRef<HTMLDivElement | null>(null);

	const [firstItem] = props.item.children || [];
	const defaultItem = firstItem?.children && firstItem.children?.length > 0 ? firstItem : null;
	const [selectedItem, selectItem] = useState<NavigationItem | null>(defaultItem);

	useEffect(() => {
		if (visible === false) {
			selectItem(defaultItem);
		}
	}, [visible]);

	const { styles, attributes } = usePopper(referenceRef.current, popperRef.current, {
		placement: "bottom-start",
		modifiers: [
			{
				name: "offset",
				enabled: true,
				options: {
					offset: [0, -5],
				},
			},
		],
	});

	const splitInTwoColumns = props.item.name === "Concerts";

	if (!props.item.children) {
		return (
			<ToggleButton ref={referenceRef} role="menu" href={props.item.url ?? undefined}>
				{props.item.name}
			</ToggleButton>
		);
	}

	return (
		<>
			<ToggleButton ref={referenceRef} role="menu" href={props.item.url ?? undefined} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
				{props.item.name}
			</ToggleButton>
			<div ref={popperRef} style={styles.popper} {...attributes.popper} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
				<MenuContainer style={styles.offset} visible={show || hover}>
					<LeftColumn twoColumns={splitInTwoColumns} amountOfColumns={Math.floor(props.item.children.length / COLUMN_BREAKPOINT) + 1}>
						{props.item.children.sort(sortItems).map(item => (
							<Item key={item.id} href={item.url || undefined} active={item.id === selectedItem?.id} onMouseEnter={() => selectItem(item)} twoColumns={splitInTwoColumns}>
								<span>{item.name}</span>
								{item.children?.length && item.children?.length > 0 && <Arrow active={item.id === selectedItem?.id} />}
							</Item>
						))}
					</LeftColumn>
					{selectedItem?.children && selectedItem?.children?.length > 0 && (
						<RightColumn>
							<div>
								{selectedItem?.children?.sort(sortItems).map(item => (
									<SubItem key={item.id} href={item.url || undefined}>
										{item.name}
									</SubItem>
								))}
							</div>
						</RightColumn>
					)}
				</MenuContainer>
			</div>
		</>
	);
}

const sortItems = (a: NavigationItem, b: NavigationItem) => {
	return a.order - b.order;
};

const MenuContainer = styled.div<{ visible: boolean }>`
	display: ${props => (props.visible ? "flex" : "none")};
	overflow: hidden;
	flex-direction: row;
	background: #ffffff;
	box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	color: #707372;
	margin-left: -26px;
`;

const RightColumn = styled.div`
	position: relative;
	background-color: #f6f6f6;
	min-width: 250px;
	& > div {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		overflow-y: auto;
		flex-direction: column;
		& > a:first-child {
			margin-top: 20px;
		}
		& > a {
			margin-bottom: 20px;
		}
	}
`;

const LeftColumn = styled.div<{ twoColumns?: boolean; amountOfColumns: number }>`
	min-width: 200px;
	padding: 0px 15px 20px 15px;
	max-height: 75vh !important;
	overflow-y: auto;

	${props =>
		props.twoColumns
			? `
		display: grid;
		grid-template-columns: repeat(${props.amountOfColumns}, auto);
		grid-column-gap: 15px;
		
		& > a:nth-child(-n + ${props.amountOfColumns}) {
			margin-top: 20px;
		}
	`
			: `display: flex;
		flex-direction: column;
	
		& > a {
			margin-top: 20px;
		}`}
`;

const Item = styled.a<{ active: boolean; twoColumns?: boolean }>`
	font-family: Montserrat;
	font-size: 15px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px;
	letter-spacing: 0.02em;
	text-align: left;
	margin-bottom: ${props => (props.twoColumns ? "20px" : "0px")};
	display: flex;
	width: 100%;
	align-items: center;
	white-space: nowrap;
	justify-content: space-between;
	background: ${props => (props.active ? "#F6F6F6" : "white")};
	color: ${props => (props.active ? "#E92224" : "#707372")};
	border-radius: 3px;
	cursor: pointer;
	span {
		padding-left: 12px;
		padding-top: 3px;
		padding-bottom: 3px;
	}
	svg {
		padding: 3px 12px;
		width: 7px;
		height: auto;
	}
`;
const ToggleButton = styled.a`
	text-transform: uppercase;
	margin: 0 0.5em;
	padding: 20px 0;
	color: #000000;
	font-size: 14px;
	font-weight: bold;
	font-family: "Montserrat", sans-serif;
	letter-spacing: 0.1em;
	flex: 1;
	white-space: nowrap;
	cursor: pointer;
	&:hover,
	&.active {
		color: #e92224;
	}
`;

const SubItem = styled.a`
	font-family: Montserrat;
	font-size: 15px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px;
	text-align: left;
	cursor: pointer;
	padding: 3px 12px;
	&:hover,
	&.active {
		color: #e92224;
	}
`;

const Arrow = (props: { className?: string; active: boolean }) => <img style={{ width: 21, height: 10 }} className={props.className} src={props.active ? arrowRed : arrowMidGray} alt="More" />;
