import React, { useRef } from "react";
import styled from "styled-components/macro";
import { InlineStylesProps, StyledComponentProps } from "../../../model/optimizedModel/styles";
//import { useResizeWatch } from "../../hooks";
import { useAutoUpdateState } from "../../hooks/useAutoUpdateState";
import { flexGap } from "../../util/optimized/styles";
import { PrimaryBorder } from "../PrimaryBorder/PrimaryBorder";
import { getTypographyStyles } from "../Typography/Typography";
import { BetDate } from "./BetDate";
import { BetEntity } from "./BetEntity";
import { betTeamComparisonSymbol } from "./util/betConsts";
import { getGameSpreadOdds } from "./util/getGameSpreadOddsMessage";

type BaseTeamProps = Omit<React.ComponentProps<typeof BetEntity>, "layout" | "className">;

type TeamProps = (
	| BaseTeamProps
	| (Omit<BaseTeamProps, "initials"> & {
			location?: string;
	  })
) & {
	icon?: React.ReactNode;
	iconPosition?: "left" | "right";
	layout?: "column" | "row";
	spread?: number;
	isHomeTeam: boolean;
};

const Team = ({ iconPosition = "left", layout = "column", ...props }: TeamProps & InlineStylesProps) => {
	const location = ("location" in props && props.location) || undefined;
	const initials = ("initials" in props && props.initials) || undefined;

	const name = useAutoUpdateState(
		() => (
			<>
				{location && (
					<>
						{location}
						<br />
					</>
				)}
				{props.name}
			</>
		),
		[props.name, location]
	);
	return (
		<TeamWrapper
			style={{
				...props.style,
				...(iconPosition === "right" && {
					flexDirection: "row-reverse",
				}),
			}}
		>
			{!!props.icon && (
				<TeamIcon
					style={{
						["margin" + (iconPosition === "right" ? "Left" : "Right")]: layout === "column" ? "3vw" : 10,
					}}
				>
					{props.icon}
				</TeamIcon>
			)}
			<BetEntity
				oddsType={props.oddsType}
				reverseItems={props.reverseItems}
				name={name}
				initials={initials}
				layout={layout}
				customOddsLabelContent={props.oddsType !== undefined && props.spread !== undefined ? getGameSpreadOdds(props.spread, props.isHomeTeam) : undefined}
				style={{
					...(layout === "row" && {
						maxWidth: "min-content",
					}),
				}}
			/>
		</TeamWrapper>
	);
};

type PublicTeamProps = Omit<TeamProps, "iconPosition" | "layout" | "style" | "reverseItems" | "spread" | "isHomeTeam">;

const horizontalContentContainerFlexGap = 8;

export const BetHomeTeamAndAwayTeam = (
	props: {
		homeTeam: PublicTeamProps;
		awayTeam: PublicTeamProps;
		eventDate?: string;
		spread?: number;
	} & StyledComponentProps
) => {
	const rowContentRef = useRef(null);
	//TODO: Maybe consider moving this outside of this component for performance reasons. Keep in mind that a "sharedProps" const will have to be created. Maybe context can be used instead?
	const Content = React.forwardRef(
		(
			contentProps: {
				layout: "row" | "column";
				hidden?: boolean;
			},
			ref
		) => {
			const sharedTeamProps = useAutoUpdateState<Partial<React.ComponentProps<typeof Team>>>(() => {
				return {
					layout: contentProps.layout === "column" ? "row" : "column",
					style:
						contentProps.layout === "row"
							? {
									justifyContent: "flex-end",
									flex: 1,
							  }
							: undefined,
					spread: props.spread,
				};
			}, [contentProps.layout]);
			return (
				<ContentContainer
					className={props.className}
					style={{
						flexDirection: contentProps.layout,
						...(contentProps.hidden && {
							opacity: 0,
							pointerEvents: "none",
							position: "absolute",
						}),
						...(contentProps.layout === "row" &&
							{
								//flexWrap: "wrap",
							}),
					}}
					// Note: The below "as any" is used here due to strange type definitions for styled-components. Consider finding a way to remove it in the future if possible.
					ref={ref as any}
				>
					<Team {...props.awayTeam} {...sharedTeamProps} reverseItems={contentProps.layout === "row"} isHomeTeam={false} />
					<BetTeamComparisonSymbol>{betTeamComparisonSymbol}</BetTeamComparisonSymbol>
					<Team {...props.homeTeam} {...sharedTeamProps} iconPosition={contentProps.layout === "row" ? "right" : "left"} isHomeTeam />
				</ContentContainer>
			);
		}
	);

	/*
  useResizeWatch(rowContentRef, entry => {
    const firstChild = entry.target.firstChild;
    const lastChild = entry.target.lastChild;
    if (firstChild instanceof HTMLElement && lastChild instanceof HTMLElement) {
      const tallestTeamElementHeight = Math.max(firstChild?.offsetHeight, lastChild?.offsetHeight);
      if (entry.contentRect.height !== tallestTeamElementHeight + (horizontalContentContainerFlexGap * 2)) {
	if (!rowContentIsWrapped) {
	  setRowContentIsWrapped(true);
	}
      } else if (rowContentIsWrapped) {
	setRowContentIsWrapped(false);
      }
    }
  });
   */

	// Note: In order to accomplish the desired UI there are two instances of "Content" rendered to the DOM in order to easily detect when the component should switch to the desired route.
	return (
		<Container>
			<Content layout="row" ref={rowContentRef} />
			<Content layout="column" hidden />
			{!!props.eventDate && (
				<>
					<TopDateBorder
						style={{
							marginTop: 17,
							width: 45,
						}}
					/>
					<BetDate date={props.eventDate} />
				</>
			)}
		</Container>
	);
};

const TeamIcon = styled.div`
	align-self: center;
`;

const TopDateBorder = styled(PrimaryBorder)`
	margin: 0 auto;
	margin-bottom: 8px;
`;

const ContentContainer = styled.div`
	display: flex;
	justify-content: center;
	${flexGap([horizontalContentContainerFlexGap + "px", "20px"])}
`;

const TeamWrapper = styled.div`
	display: flex;
`;

const BetTeamComparisonSymbol = styled.span`
	${getTypographyStyles("extraLargeSpecialBody")}
	margin: auto;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
`;
