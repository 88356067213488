import { client, HostedFieldFieldOptions, HostedFields, hostedFields, HostedFieldsTokenizePayload } from "braintree-web";
import React, { useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { useBraintree } from "../../../services/hooks/useBraintree";
import { Input, RFFInput } from "../NewBets/Input";
import { FontFamily, Typography, TypographyType } from "../Typography/Typography";
import { BaseAddressForm } from "./AddressForm";
import { Button } from "../NewButton/Button";
import { graphQLBraintreeTokenizeCreditCard, postBraintreePaymentMethodAndUpdate, graphQLBraintreeSupportedCardBrands } from "../../../services/braintree";
import { isMobileApp } from "../../util/isMobileApp";
import { InlineCSSProps } from "../../../model/optimizedModel/styles";
import toast from "react-hot-toast";
import { TokenizePayload } from "../../../model/Checkout";
import { Address } from "../../../model/User";
import { Dialog } from "../Dialog/Dialog";
import { PaymentMethodWithToken } from "../../../model/optimizedModel/braintree";
import { useHiddenZendeskChatWidgetBreakpoint } from "../../hooks/useHiddenZendeskChatWidgetBreakpoint";
import { breakpoints } from "../../util/breakpoints";
import { paymentMethodBadges } from "../../util/paymentMethodLogos";
import styled from "styled-components/macro"; //This line is for some reason fixing a conflict with a global style sheet
import { onlyWholeNumbers, required } from "../../util/optimized/finalFormUtil";
import cardValidator from "card-validator";
import { CardNumberVerification } from "card-validator/dist/card-number";
import { ExpirationDateVerification } from "card-validator/dist/expiration-date";

const getShortFieldCSS = (position: "left" | "right") => `
  width: 50%;
  box-sizing: border-box;
  padding-${
		{
			right: "left",
			left: "right",
		}[position]
	}: 5px;
`;

const hostedFieldObjects: ({
	id: keyof HostedFieldFieldOptions;
	placeholder: string;
} & InlineCSSProps)[] = [
	{
		id: "number",
		placeholder: "Card Number",
	},
	{
		id: "expirationDate",
		placeholder: "MM/YY",
		css: getShortFieldCSS("left"),
	},
	{
		id: "cvv",
		placeholder: "CVV",
		css: getShortFieldCSS("right"),
	},
	{
		id: "cardholderName",
		placeholder: "Name on Card",
	},
];

const hostedFieldObjectsMap = hostedFieldObjects.reduce((result, field) => {
	result[field.id] = {
		selector: "#" + field.id,
		placeholder: field.placeholder,
	};
	return result;
}, {} as HostedFieldFieldOptions);

let appHostedFieldsInstance: HostedFields | undefined;

const useHostedFields = !isMobileApp;

const fieldTypographyType: TypographyType = "bodySmall";

export const NewCreditCardForm = (
	props: {
		onCardAdded?: (payload: PaymentMethodWithToken) => void;
		customButton?: (disabled: boolean) => JSX.Element;
	} & Pick<React.ComponentProps<typeof BaseAddressForm>, "countryMenuPortalPosition">
) => {
	const { braintreeState } = useBraintree();

	const [supportedCardBrands, setSupportedCardBrands] = useState<string[]>([]);
	const [hostedFieldsAreLoading, setHostedFieldsAreLoading] = useState(useHostedFields);

	useEffect(() => {
		if (braintreeState.clientAuth) {
			graphQLBraintreeSupportedCardBrands().then(result => {
				setSupportedCardBrands(result.data.data.clientConfiguration.creditCard.supportedCardBrands);
			});
			if (useHostedFields && braintreeState.clientAuth) {
				client
					.create({
						authorization: braintreeState.clientAuth,
					})
					.then(clientInstance => {
						return hostedFields
							.create({
								client: clientInstance,
								fields: hostedFieldObjectsMap,
								styles: {
									//TODO: Look into a way to reuse the below styles from the main Input component.
									input: {
										"font-family": FontFamily.Poppins,
										height: "initial",
										"font-size": "14px",
										"line-height": "20px",
										"font-weight": "400",
									},
								},
							})
							.then(hostedFieldsInstance => {
								appHostedFieldsInstance = hostedFieldsInstance;
								setHostedFieldsAreLoading(false);
							});
					});
			}
		}
	}, [braintreeState.clientAuth]);

	const mobileAppCardNumberRef = useRef<CardNumberVerification>();

	const mobileAppExpirationDateRef = useRef<ExpirationDateVerification>();

	const [hostedFieldsAreFilled, setHostedFieldsAreFilled] = useState(false);
	useEffect(() => {
		const fieldsState = appHostedFieldsInstance?.getState();
		if (fieldsState && useHostedFields) {
			setHostedFieldsAreFilled(
				Object.keys(fieldsState.fields).every(fieldKey => {
					return !fieldsState.fields[fieldKey].isEmpty;
				})
			);
		}
	}, [appHostedFieldsInstance?.getState(), useHostedFields]);

	useEffect(() => {
		return () => {
			appHostedFieldsInstance?.teardown(() => {
				appHostedFieldsInstance = undefined;
			});
		};
	}, []);

	return (
		<>
			<Form
				onSubmit={async values => {
					const billingAddress = {
						postalCode: values.zip,
						streetAddress: values.address1,
						extendedAddress: values.address2,
						locality: values.city,
						region: values.state,
						countryName: values.country,
					};
					let tokenizePayload: Partial<HostedFieldsTokenizePayload> | undefined;
					let address: Address;
					try {
						if (useHostedFields) {
							try {
								tokenizePayload = await appHostedFieldsInstance?.tokenize({
									vault: true,
									billingAddress,
								});
							} catch (error) {
								throw (
									{
										HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR: "An error occured. Please try again or contact Customer Service for support: (646) 453 - 7400.",
										HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE: "The Credit Card you are attempting to add has already been added to an SI Tickets Account. Please review the Credit Card details or contact customer service for support: (646) 453 - 7400.",
										HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED: "Either the CVV or ZIP/Postal Code you have entered is not valid.",
										HOSTED_FIELDS_FAILED_TOKENIZATION: "The Credit Card you have entered is not accepted. Please enter another Credit Card or contact Customer Service for support: (646) 453 - 7400.",
										HOSTED_FIELDS_FIELDS_EMPTY: "Please input data for all required fields.",
										HOSTED_FIELDS_FIELDS_INVALID: "Some of the data you provided is not valid. Please review the information and try again.",
									} as Record<string, string>
								)[error.code];
							}
							address = values;
						} else {
							const { number, cvv, cardholderName, expirationDate, ...addressValues } = values;
							const expirationMonth = expirationDate.slice(0, 2);
							const expirationYear = expirationDate.slice(3);
							const tokenizationResult = await graphQLBraintreeTokenizeCreditCard({
								number,
								cvv,
								cardholderName,
								expirationYear,
								expirationMonth,
								billingAddress,
							});
							const tokenizationData = tokenizationResult.data;
							if (tokenizationData.errors?.length) {
								throw (
									{
										"81703": "The Credit Card type you have entered is not accepted.",
										"81706": "CVV is required.",
										"81707": "Either the CVV or ZIP/Postal Code you have entered is not valid.",
										"81709": "Expiration date is required.",
										"81710": "The expiration date you have entered is not valid.",
										"81711": "The expiration year you have entered is not valid.",
										"81712": "The expiration month you have entered is not valid.",
										"81713": "The expiration year you have entered is not valid.",
										"81714": "Credit Card number is required.",
										"81715": "The Credit Card number you have entered is not valid.",
										"81716": "The Credit Card number must be 12 - 19 digits.",
										"81724": "The Credit Card you are attempting to add has already been added to an SI Tickets Account. Please review the Credit Card details or contact customer service for support: (646) 453 - 7400.",
										"81736": "Either the CVV or ZIP/Postal Code you have entered is not valid.",
										"81737": "The Postal code you have entered could not be verified.",
										"81750": "An error has occured. Please contact Customer Service for support: (646) 453 - 7400.",
										"91726": "The Credit Card type you have entered is not accepted.",
										"91732": "Unknown or expired payment method nonce.",
										"91733": "Payment method nonce locked.",
										"91734": "The Credit Card type you have entered is not accepted.",
										"91742": "An error has occured. Please contact Customer Service for support: (646) 453 - 7400.",
										"91744": "The Billing Address format is invalid.",
									} as Record<string, string>
								)[tokenizationData.errors[0].extensions.legacyCode];
							}
							const paymentMethod = tokenizationData.data.tokenizeCreditCard.paymentMethod;
							const paymentMethodDetails = paymentMethod.details;
							tokenizePayload = {
								nonce: paymentMethod.id,
								type: "CreditCard",
								details: {
									cardType: paymentMethodDetails.brandCode,
									lastFour: paymentMethodDetails.last4,
									//@ts-ignore
									cardholderName: paymentMethodDetails.cardholderName,
								},
							};
							address = addressValues;
						}
						if (tokenizePayload === undefined) {
							throw null;
						}
						//@ts-ignore
						const fullName = tokenizePayload.details?.cardholderName;
						if (fullName === undefined) {
							throw null;
						}

						//TODO: The below logic to get the first & last names were done very quickly. Consider finding a better way to implement below logic if possible.
						const firstName = fullName.replace(/ .*/, "");
						let lastName = fullName.match(/\b\w+\b/g).pop();
						if (lastName === firstName) {
							lastName = "";
						}

						const paymentMethodWithToken = await postBraintreePaymentMethodAndUpdate(tokenizePayload as TokenizePayload, {
							...address,
							firstName,
							lastName,
						});
						if (paymentMethodWithToken && props.onCardAdded) {
							props.onCardAdded(paymentMethodWithToken);
						}
					} catch (error) {
						toast.error(error || "Something went wrong. Please check your card details & try again or contact Customer Service for support: (646) 453 - 7400.");
					}
				}}
				initialValues={{
					country: "United States",
				}}
				render={form => (
					<form
						onSubmit={form.handleSubmit}
						css={`
							display: flex;
							flex-wrap: wrap;
							justify-content: center;
							align-items: flex-start;
							height: max-content;
						`}
					>
						<Typography
							type="bodyNormal"
							color="darkGrey"
							colorType="dark"
							loading={hostedFieldsAreLoading}
							css={`
								text-transform: uppercase;
								text-align: left;
								font-weight: 600;
								width: 100%;
								margin-bottom: 7px;
							`}
						>
							Billing Information
						</Typography>
						{hostedFieldObjects.map((field, index) => {
							let sharedCSS = `
		width: 100%;
		`;
							if (field.css !== undefined) {
								sharedCSS += field.css;
							}
							const rightChildren =
								field.id === "number" ? (
									<div
										css={`
											display: flex;
											& > img {
												max-height: 15px;
												&:not(:last-child) {
													margin-right: 12px;
												}
											}
										`}
									>
										{supportedCardBrands.map((brand, brandIndex) => {
											if (brand !== "UNION_PAY") {
												return <img key={brandIndex} alt={brand} src={paymentMethodBadges[brand]} />;
											}
										})}
									</div>
								) : undefined;
							return (
								<React.Fragment key={index}>
									{useHostedFields ? (
										<Input
											id={field.id}
											css={`
												${sharedCSS}
												.input {
													//TODO: Look into adding improved style logic for the below style instead of hardcoding the height.
													height: 20px;
													border-radius: 5px;
												}
											`}
											forwardedAs="div"
											rightChildren={rightChildren}
											loading={hostedFieldsAreLoading}
										/>
									) : (
										<Field<string>
											name={field.id}
											component={RFFInput}
											placeholder={field.placeholder}
											css={sharedCSS}
											typographyType={fieldTypographyType}
											rightChildren={rightChildren}
											enableCursorPositionCorrection
											type={field.id !== "cardholderName" ? "tel" : undefined}
											parse={(value, name) => {
												let output = value;
												switch (name) {
													case "number":
														output = onlyWholeNumbers(output);
														mobileAppCardNumberRef.current = cardValidator.number(value);
														if (mobileAppCardNumberRef.current) {
															const possibleCardLengths = mobileAppCardNumberRef.current.card?.lengths;
															if (possibleCardLengths?.length) {
																const maxLength = possibleCardLengths[possibleCardLengths?.length - 1];
																output = output.slice(0, maxLength);
															}
														}
														break;
													case "expirationDate":
														output = onlyWholeNumbers(output);
														if (output.length >= 2) {
															output = output.slice(0, 2) + "/" + output.slice(2);
														}
														if (output.length < 4 && form.form.getFieldState("expirationDate")?.value?.slice(-1) === "/") {
															output = output.slice(0, 1);
														}
														mobileAppExpirationDateRef.current = cardValidator.expirationDate(output);
														break;
													case "cvv":
														output = onlyWholeNumbers(output);
														output = output.slice(0, 4);
														break;
												}
												return output;
											}}
											format={(value, name) => {
												let output = value === undefined ? "" : value;
												switch (name) {
													case "number":
														const cardGapIndices = mobileAppCardNumberRef.current?.card?.gaps;
														if (cardGapIndices?.length) {
															output = "";
															for (let i = 0; i <= cardGapIndices.length; i++) {
																const gapEndIndex = cardGapIndices[i];
																const gapStartIndex = !i ? 0 : cardGapIndices[i - 1];
																if (value.length - 1 >= gapEndIndex) {
																	output += value.substring(gapStartIndex, gapEndIndex);
																	output += " ";
																} else {
																	output += value.substring(gapStartIndex);
																	break;
																}
															}
														}
														break;
												}
												return output;
											}}
											validate={(value, allValues, meta) =>
												((
													{
														number: required(value) || (!mobileAppCardNumberRef.current?.isValid && "Please enter a valid card number."),
														expirationDate: required(value) || (!mobileAppExpirationDateRef.current?.isValid && "Please enter a valid date."),
														cvv: required(value),
														cardholderName: required(value) || (!cardValidator.cardholderName(value) && "Please enter a valid cardholder name."),
													} as Record<string, string>
												)[meta?.name || ""])
											}
										/>
									)}
								</React.Fragment>
							);
						})}
						<Typography
							type="bodyNormal"
							color="darkGrey"
							colorType="dark"
							loading={hostedFieldsAreLoading}
							css={`
								text-transform: uppercase;
								text-align: left;
								font-weight: 600;
								width: 100%;
								margin-bottom: 7px;
							`}
						>
							Billing Address
						</Typography>
						<BaseAddressForm
							css={`
								width: 100%;

								input {
									border-radius: 5px;
								}
							`}
							form={form}
							fieldTypographyType={fieldTypographyType}
							loading={hostedFieldsAreLoading}
							countryMenuPortalPosition={props.countryMenuPortalPosition}
						/>

						{props.customButton ? (
							props.customButton(form.invalid || form.submitting || (useHostedFields && !hostedFieldsAreFilled))
						) : (
							<Button type="submit" disabled={form.invalid || form.submitting || (useHostedFields && !hostedFieldsAreFilled)} loading={hostedFieldsAreLoading}>
								Add Card
							</Button>
						)}
					</form>
				)}
			/>
		</>
	);
};

export const NewCreditCardFormDialog = ({ onCardAdded, ...dialogProps }: Pick<React.ComponentProps<typeof Dialog>, "open" | "onClose"> & Pick<React.ComponentProps<typeof NewCreditCardForm>, "onCardAdded">) => {
	useHiddenZendeskChatWidgetBreakpoint(breakpoints.tablet);

	return (
		<Dialog
			{...dialogProps}
			type="utility"
			fullscreenOnMobile
			headerChildren={
				<Typography
					type="smallSpecialBody"
					css={`
						text-align: center;
					`}
				>
					Add New Credit Card
				</Typography>
			}
			css={`
				max-width: ${breakpoints.mobile};
			`}
		>
			<NewCreditCardForm onCardAdded={onCardAdded} countryMenuPortalPosition="fixed" />
		</Dialog>
	);
};
