import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { postCartShippingMethod, renderCartDeliveryMethod } from "../../../services/cart";
import { useCart } from "../../../services/hooks";
import { setCart } from "../../../store/reducers/cartReducer";
import { FullScreenLoader } from "../Loader";
import { Select, SelectItem } from "../Select/Select";
import { getTypographyStyles } from "../Typography/Typography";

export const DeliveryMethodSelection = () => {
	const { delivery, optionsArray } = useCart();

	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);

	return (
		<>
			<FullScreenLoader show={loading} />
			<Container>
				<Title>Delivery Method</Title>
				{optionsArray.length > 1 ? (
					<Select
						value={delivery?.selected}
						items={optionsArray.map(opt => ({
							value: opt.id,
							label: renderCartDeliveryMethod(opt),
						}))}
						onChange={(value: SelectItem["value"]) => {
							if (value) {
								setLoading(true);
								postCartShippingMethod(value).then(result => {
									dispatch(setCart(result.data.cart));
									setLoading(false);
								});
							}
						}}
						isClearable={false}
					/>
				) : (
					<SingleDeliveryOption>{renderCartDeliveryMethod(optionsArray[0])}</SingleDeliveryOption>
				)}
			</Container>
		</>
	);
};

const SingleDeliveryOption = styled.p`
	${getTypographyStyles("bodySmall", {
		color: "darkGrey",
		colorType: "dark",
		fontWeight: "normal",
	})};
`;

const Container = styled.div`
	select {
		margin: 0;
	}
`;

const Title = styled.h1`
	${getTypographyStyles("heading4", {
		color: "darkGrey",
		colorType: "dark",
	})};
`;
