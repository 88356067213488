import { ServiceCallAction } from "../../store/middleware/serviceCallMiddleware";
import { ZonedEventsResponse, ZonedEventsCodec, FutureEventsResponse, FutureEventsCodec } from "../types/FuturesResponse";

export const getFuturesByConference = (conference: number | null, sport: string | null): ServiceCallAction<FutureEventsResponse> => ({
	method: "GET",
	endpoint: `events/futures`,
	param: {
		...(conference && { conference_id: conference }),
		sport_slug: sport,
		page_number: 1,
		page_size: 1000,
	},
	serviceKey: "getFuturesByConference",
	responseDecoder: FutureEventsCodec,
	type: "SERVICE_CALL",
});

export const getTeamFutures = (team: string, page: number | null, pageSize: number | null): ServiceCallAction<FutureEventsResponse> => ({
	method: "GET",
	endpoint: `events/futures`,
	param: {
		team_slug: team,
		page_number: page || 1,
		page_size: pageSize || 10,
	},
	serviceKey: "getFutures",
	responseDecoder: FutureEventsCodec,
	type: "SERVICE_CALL",
});

export const getTeamFutureListing = (teamSlug: string, eventSlug: string): ServiceCallAction<ZonedEventsResponse> => ({
	method: "GET",
	endpoint: `events/futures/listing`,
	param: {
		team_slug: teamSlug,
		event_slug: eventSlug,
	},
	serviceKey: "getFutureListing",
	responseDecoder: ZonedEventsCodec,
	type: "SERVICE_CALL",
});
