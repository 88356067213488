import React from "react";
import { SeaticsLegendItem } from "../Seatics/types";
import { LevelPrice } from "./styles";
import { formatPrice } from "../../util/formatPrice";
import { Nulleable } from "../../../model/Utils";
import { isMobileApp } from "../../util/isMobileApp";
import { useIsMobileWeb } from "../../hooks/useIsMobileWeb";

export const VenueLevel = (props: { section: SeaticsLegendItem; onSelectSection: (section: SeaticsLegendItem) => void; legendItems: Nulleable<SeaticsLegendItem[]>; onSectionHover: (section: Nulleable<SeaticsLegendItem>) => void; }) => {

	const isMobileWeb = useIsMobileWeb();
	const isMobile = isMobileWeb || isMobileApp;

	const handleMouseEnter = () => {
		if(!isMobile){
			props.onSectionHover(props.section);
		}
	};
	const handleMouseLeave = () => {
		if(!isMobile) {
			props.onSectionHover(null);
		}
	};

	const selectedSections = (props.legendItems || []).filter(l => l.isSelected);

	const sectionIsSelected = selectedSections.includes(props.section);

	const otherSectionIsSelected = !!selectedSections.length && !sectionIsSelected

	return (
		<>
			{
				!!props.section.hasTickets() && (
					<LevelPrice
						sectionIsSelected={sectionIsSelected}
						otherSectionIsSelected={otherSectionIsSelected}
						sectionColor={props.section.color}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						onClick={() => props.onSelectSection(props.section)}
					>
						{formatPrice(props.section.getFromPrice())}{"+"}
					</LevelPrice>
				)
			}
		</>
	);
};
