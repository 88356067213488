import React from "react";
import { formatPrice, shortFormatPrice } from "../../util/formatPrice";
import { Invoice, InvoiceComponentItem } from "./Invoice";
import { BaseInvoiceComponentProps } from "../../../model/optimizedModel/invoice";
import { CSSObject } from "styled-components";
import { FutureZone } from "../../../model/optimizedModel/future";
import { BaseInvoiceHookInput } from "../../../model/optimizedModel/invoice";
import { useMemoState } from "../../hooks/useMemoState";
import { useInvoiceOutputUpdate } from "./hooks/useInvoiceOutputUpdate";
import { useCheckout } from "../../../services/hooks/useCheckout";

export interface FutureInvoiceInput extends Pick<FutureZone, "reservationPrice">, BaseInvoiceHookInput {
	//TODO: Consider creating an interface for FutureInvoiceInput and TicketInvoiceInput to extend that contains quantity.
	quantity: number;
	taxAmount?: number | null;
}

export const useFutureInvoiceOutput = (input: FutureInvoiceInput) => {
	const finalReservationPrice = useMemoState(() => {
		const value = input.reservationPrice * input.quantity;
		return value;
	}, [input.reservationPrice, input.quantity]);

	//TODO: Consider putting all of the below code into it's own hook.
	const output = useMemoState(() => {
		const value = {
			finalReservationPrice,
		};
		return value;
	}, [finalReservationPrice]);

	useInvoiceOutputUpdate(output, input.onUpdate);

	return output;
};

const baseInvoiceItemCSS: CSSObject = {
	alignItems: "flex-start",
};

export const FutureInvoice = ({
	quantity,
	reservationPrice,
	ticketPrice,
	taxAmount,
	onUpdate,
	standardPriceFormatting,
	...invoiceProps
}: //TODO: Consider adding a required generic to BaseInvoiceComponentProps for InvoiceInput types.
BaseInvoiceComponentProps &
	FutureInvoiceInput &
	Pick<Partial<FutureZone>, "ticketPrice"> & {
		standardPriceFormatting?: boolean;
	}) => {
	const { finalReservationPrice } = useFutureInvoiceOutput({
		quantity,
		reservationPrice,
		onUpdate,
	});

	const renderPrice = standardPriceFormatting ? formatPrice : shortFormatPrice;
	return (
		<Invoice
			{...invoiceProps}
			items={(() => {
				const arr: InvoiceComponentItem[] = [
					{
						key: "reservationPrice",
						title: "Reservation Price",
						value: renderPrice(finalReservationPrice),
						css: {
							fontSize: 15,
							fontWeight: 500,
							...baseInvoiceItemCSS,
						},
					},
				];

				if (ticketPrice) {
					arr.push({
						key: "ticketPrice",
						title: "Ticket Cost",
						subtitle: "Only paid when your team makes the game (100% Fulfillment Guaranteed)",
						value: renderPrice(ticketPrice) + "/ea",
						css: {
							...baseInvoiceItemCSS,
							color: "#555555",
							fontSize: 15,
							fontWeight: 400,
						},
					});
				}

				// We only want to display the tax in the confirmation step
				if (!!taxAmount) {
					arr.push({
						key: "tax",
						value: formatPrice(taxAmount),
						title: "Tax",
					});
				}
				return arr;
			})()}
		/>
	);
};
