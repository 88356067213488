import React from "react";
import styled, { keyframes } from "styled-components/macro";
import { InlineStylesProps, StyledComponentProps } from "../../../model/optimizedModel/styles";

export const SkeletonWrapper = React.forwardRef<
	HTMLDivElement,
	React.PropsWithChildren<
		{
			loading: boolean | undefined;
		} & StyledComponentProps &
			InlineStylesProps
	>
>((props, ref) => {
	return (
		<Wrapper
			className={props.className}
			style={{
				...props.style,
				...(props.loading && {
					pointerEvents: "none",
				}),
			}}
			// Note: The below "as any" is used here due to strange type definitions. Consider finding a way to remove it in the future if possible.
			ref={ref as any}
		>
			{props.loading && <Skeleton />}
			{props.children}
		</Wrapper>
	);
});

//TODO: "Question"/revisit animation code
const loading = keyframes`
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
`;

const Wrapper = styled.div`
	position: relative;
`;

const Skeleton = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  //TODO: Match this with the concept video.
  background 0.25s ease;
/*
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 80%
  ),
  lightgray;
  */
  background-repeat: repeat-y;
  //background-size: 50px 500px;
  //background-position: 0 0;
  //animation: ${loading} 1s infinite;
  z-index: 1;
//TODO: App colors and DRY
  background: linear-gradient(112.47deg, #F1F1F1 29.16%, #E5E5E5 63.35%, #F1F1F1 87.25%);
`;
