import React from "react";
import ReactSelectComponent, { components, IndicatorProps, OptionTypeBase, GroupTypeBase } from "react-select";
import styled, { CSSObject } from "styled-components/macro";
import { Typography, TypographyType, getTypographyStyles } from "../Typography/Typography";
import selectArrowUp from "../../resource/image/select-arrow-up.svg";
import selectArrowDown from "../../resource/image/select-arrow-down.svg";
import selectClear from "../../resource/image/select-clear.svg";
import { getAppColor } from "../../util/appColors";
import { useAutoUpdateState } from "../../hooks/useAutoUpdateState";
import { SkeletonProps } from "../../../model/optimizedModel/skeleton";
import { SkeletonWrapper } from "../SkeletonWrapper/SkeletonWrapper";
import { FieldRenderProps } from "react-final-form";
import { InlineStylesProps } from "../../../model/optimizedModel/styles";

type IndicatorType = IndicatorProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>;

const ClearIndicator = (provided: IndicatorType) => {
	const clearValue = () => {
		provided.clearValue();
		provided.selectProps.onClear && provided.selectProps.onClear();
	};

	const innerProps = {
		...provided.innerProps,
		onTouchEnd: clearValue,
	};

	return (
		components.ClearIndicator && (
			<components.ClearIndicator {...provided} innerProps={innerProps}>
				<ClearButton src={selectClear} alt="Clear" />
			</components.ClearIndicator>
		)
	);
};

const DropdownIndicator = (provided: IndicatorType) => {
	return (
		components.DropdownIndicator && (
			<components.DropdownIndicator {...provided}>
				<Dropdown src={provided.selectProps.menuIsOpen ? selectArrowUp : selectArrowDown} />
			</components.DropdownIndicator>
		)
	);
};

const baseSelectProps = {
	isSearchable: false,
	blurInputOnSelect: false,
	captureMenuScroll: true,
	hideSelectedOptions: false,
	backspaceRemovesValue: false,
	allowSelectAll: true,
};

interface SelectItemOptions {
	isDisabled?: boolean;
}

export interface SelectItem {
	value: string | number | null;
	label: string;
}

export const selectOptionStyles = {
	marginBottom: 2,
	cursor: "pointer",
	"&:active": {
		background: getAppColor("dark"),
		color: getAppColor("light"),
	},
	
	"@media (hover: hover)": {
		"&:hover": {
			background: getAppColor("dark"),
			color: getAppColor("light"),
		},
	},
};

export const selectControlStyles = {
	borderRadius: 0,
	boxShadow: "none",
	cursor: "pointer",
	minHeight: "initial",
	borderWidth: 1,
	borderStyle: "solid",
	height: 41,
	"&--menu-is-open": {
		borderBottom: `3px solid ${getAppColor("primary")}`,
		"&:hover, &:active": {
			borderBottomColor: getAppColor("primary"),
		},
	},
};

export const Select = ({
	isClearable = true,
	typographyType = "bodyNormal",
	style,
	...props
}: {
	placeholder?: string;
	disabled?: boolean;
	typographyType?: TypographyType;
} & (
	| {
			multiple?: false;
			onChange?: (value: SelectItem["value"]) => void;
			value?: SelectItem["value"];
	  }
	| {
			multiple: true;
			onChange?: (value: SelectItem["value"][]) => void;
			value?: SelectItem["value"][];
	  }
) &
	SkeletonProps<{
		items: (SelectItem & SelectItemOptions)[];
	}> &
	SkeletonProps<
		{
			title?: string;
		},
		"titleLoading"
	> &
	Pick<React.ComponentProps<typeof ReactSelectComponent>, "menuPortalTarget" | "styles" | "menuShouldScrollIntoView" | "isClearable"> &
	InlineStylesProps) => {
	const value = useAutoUpdateState<OptionTypeBase | undefined>(() => {
		if (!props.loading) {
			if (props.multiple) {
				return props.value === undefined ? [] : props.items.filter(item => props.value!.includes(item.value));
			} else {
				return props.value === undefined ? undefined : props.items.find(item => item.value === props.value);
			}
		}
	}, [props.value, props.multiple, props.loading]);

	const typographyTypeStyles = useAutoUpdateState(
		() =>
			getTypographyStyles(typographyType, {
				outputType: "object",
			}) as CSSObject,
		[typographyType]
	);

	return (
		<SelectContainer>
			{(props.title !== undefined || props.titleLoading) && (
				<Typography
					type="heading3"
					loading={props.titleLoading}
					css={`
						margin-bottom: 11px;
					`}
				>
					{props.title}
				</Typography>
			)}
			<SkeletonWrapper loading={props.loading}>
				<ReactSelectComponent
					//TODO: Replace the "hardcoded" typography styles with "getTypographyStyles" if it becomes possible to use the function with react-select's styling system.
					styles={{
						placeholder: provided => ({
							...provided,
							...typographyTypeStyles,
						}),
						input: provided => ({
							...provided,
							...typographyTypeStyles,
						}),
						valueContainer: provided => ({
							...provided,
							position: "initial",
							width: "calc(100% - 100px)",
							padding: 0,
							paddingRight: 8,
							paddingLeft: 6,
							borderRadius: 0,
							height: typographyTypeStyles.lineHeight,
						}),
						control: provided => ({
							...provided,
							...selectControlStyles,
							...style,
						}),
						singleValue: provided => ({
							...provided,
							...typographyTypeStyles,
						}),
						multiValue: provided => ({
							...provided,
							borderRadius: 0,
							...typographyTypeStyles,
							"&:hover, &:active": {
								background: getAppColor("lightGrey"),
							},
						}),
						menu: provided => ({
							...provided,
							border: `1px solid ${getAppColor("lightGrey", "dark")}`,
							borderRadius: 0,
							marginTop: 0,
							background: getAppColor("lightGrey", "subtle"),
							boxShadow: "none",
						}),
						option: (provided, state) => ({
							...provided,
							...typographyTypeStyles,
							...selectOptionStyles,
							...(state.isSelected && {
								background: getAppColor("dark"),
								color: getAppColor("light")
							}),
						}),
						dropdownIndicator: provided => ({
							...provided,
							padding: 0,
						}),
						indicatorSeparator: provided => ({
							...provided,
							margin: "1px 0 !important",
						}),
						...props.styles,
					}}
					isDisabled={props.disabled}
					value={value}
					placeholder={props.placeholder}
					options={props.items}
					onChange={value => {
						if (props.onChange) {
							if (props.multiple && Array.isArray(value)) {
								props.onChange(value.map(item => item.value));
							}
							if (!props.multiple) {
								props.onChange(value !== null && "value" in value ? value.value : null);
							}
						}
					}}
					components={{ DropdownIndicator, ClearIndicator }}
					menuPortalTarget={props.menuPortalTarget}
					menuShouldScrollIntoView={props.menuShouldScrollIntoView}
					isMulti={props.multiple}
					closeMenuOnSelect={!props.multiple}
					isClearable={isClearable}
					{...baseSelectProps}
				/>
			</SkeletonWrapper>
		</SelectContainer>
	);
};

export const RFFSelect = ({ input, meta, ...rest }: FieldRenderProps<string | string[]> & Omit<React.ComponentProps<typeof Select>, "multiple">) => {
	return (
		<>
			{/*@ts-ignore*/}
			<Select
				{...rest}
				{...input}
				style={{
					padding: "9px 0",
					height: "initial",
				}}
			/>
		</>
	);
};

const SelectContainer = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
`;

const Dropdown = styled.img`
	padding: 0px 13px;
`;

const ClearButton = styled.img`
	margin: 0 8px 0 0;
`;
