import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { CommonPage, Events } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { getTeamEvents } from "../../services/actions/eventsService";
import { useDataService } from "../hooks/useServiceCall";
import { getPerformerAssets } from "../../services/actions/assetsService";
import { Cover } from "../components/Cover";
import { SportEventItem } from "../components/Event/Event";
import { CoverAssets } from "../../services/types/AssetResponse";
import { VerticalAd, VerticalAdContainer } from "../components/Advertisement/Advertisement";
import { isRight } from "fp-ts/lib/These";
import { useObjectMergeReducer, useServiceMonitor } from "../hooks";
import { Event } from "../../model/Event";
import { FullScreenLoader } from "../components/Loader";

const NEAR_TEAM_EVENTS_PAGE = 0;
const NEAR_TEAM_EVENTS_QUANTITY = 20;
const TEAM_EVENTS_PAGE_SIZE = 50;

interface TeamRouteParams {
	team: string;
}

interface TeamState {
	page: number;
	total: number;
	events: Event[];
}

export const Team = (props: RouteComponentProps<TeamRouteParams>) => {
	const team = props.match.params.team;
	const location = useSelector(state => state.persistent.location);
	const loading = useServiceMonitor(["getPerformerEvents"]);
	const dispatch = useDispatch();

	const { result: nearEvents } = useDataService(getTeamEvents, [team, location, NEAR_TEAM_EVENTS_PAGE, NEAR_TEAM_EVENTS_QUANTITY], {});
	const { result: performerAssets, isLoading: loadingAssets } = useDataService(getPerformerAssets, [team], {});

	const getAssets = (assets: CoverAssets | null) =>
		assets && {
			...assets,
			banner_image: `/api/assets/performers/${team}/banner_image`,
		};

	const [state, setState] = useObjectMergeReducer<TeamState>({
		page: 1,
		total: 0,
		events: [],
	});

	//reset events
	useEffect(() => {
		setState({ events: [], total: 0 });
		loadEvents(true);
	}, [team, location]);

	//update events
	const loadEvents = async (reset?: boolean) => {
		const page = reset ? 1 : ++state.page;
		const result = await dispatch(getTeamEvents(team, null, page, TEAM_EVENTS_PAGE_SIZE));

		const events = isRight(result) ? result.right.data : [];
		const total = isRight(result) ? result.right.metadata.totalRecords : 0;

		setState({ page, total, events: reset ? events : [...state.events, ...events] });
	};

	return (
		<CommonPage isSitixLogoSelected={true}>
			<FullScreenLoader show={loadingAssets} />
			<Cover assets={getAssets(performerAssets)} />

			{location && <Events title={`EVENTS NEAR YOU`} hideOnEmpty={true} events={nearEvents?.data ?? null} render={event => <SportEventItem event={event} />} />}
			{!!performerAssets?.vertical_adv_image_1?.url ||
				(!!performerAssets?.vertical_adv_image_2?.url && (
					<Events title="UPCOMING EVENTS" onLoadMore={loadEvents} loading={loading} total={state.total} events={state.events} render={event => <SportEventItem event={event} />} ads={performerAssets}>
						<VerticalAdContainer>
							<VerticalAd sticky={!performerAssets?.vertical_adv_image_2?.url} {...performerAssets?.vertical_adv_image_1} />
							<VerticalAd sticky={!!performerAssets?.vertical_adv_image_2?.url} {...performerAssets?.vertical_adv_image_2} />
						</VerticalAdContainer>
					</Events>
				))}
		</CommonPage>
	);
};
