import { ServiceCallAction } from "../../store/middleware/serviceCallMiddleware";
import { SearchResponse, SearchResponseCodec, MainSearchResponse, MainSearchResponseCodec, PerformerSearchResponse, VenueSearchResponse, VenueSearchResponseCodec, PerformerSearchResponseCodec, CategorySearchResponse, CategorySearchResponseCodec } from "../types/MainSearchResponseCodec";

export type { MainSearchResponse, SearchItem, SearchResponse } from "../types/MainSearchResponseCodec";

export const mainSearchService = (query: string): ServiceCallAction<MainSearchResponse> => ({
	method: "GET",
	endpoint: "mainSearch",
	param: { query },
	serviceKey: "getMainSearch",
	responseDecoder: MainSearchResponseCodec,
	type: "SERVICE_CALL",
});

export const searchService = (query: string, type: SearchServiceType) => baseSearchService<SearchResponse>(query, type, SearchResponseCodec);

export const categorySearchService = (query: string) => baseSearchService<CategorySearchResponse>(query, "category", CategorySearchResponseCodec);

export const venueSearchService = (query: string) => baseSearchService<VenueSearchResponse>(query, "venue", VenueSearchResponseCodec);

export const performerSearchService = (query: string) => baseSearchService<PerformerSearchResponse>(query, "performer", PerformerSearchResponseCodec);

type SearchServiceType = "event" | "category" | "venue" | "performer";

const baseSearchService = <T>(query: string, type: SearchServiceType, responseDecoder: ServiceCallAction<T>["responseDecoder"]): ServiceCallAction<T> => ({
	method: "GET",
	endpoint: "search/main",
	param: { query, type },
	serviceKey: "getMainSearch",
	type: "SERVICE_CALL",
	responseDecoder,
});
