import React from "react";
import styled from "styled-components/macro";
import { MyAccountCard } from "./MyAccountCard";
import { MyAccountPage } from "./MyAccountPage";
import contact from "../../resource/img/myaccount/contact.svg";
import topfaqs from "../../resource/img/myaccount/topfaqs.svg";
import purchaseres from "../../resource/img/myaccount/purchaseres.svg";
import youraccount from "../../resource/img/myaccount/youraccount.svg";
import tickets from "../../resource/img/myaccount/tickets.svg";
import bidsell from "../../resource/img/myaccount/bidsell.svg";
import share from "../../resource/img/myaccount/share.svg";
import billing from "../../resource/img/myaccount/billing.svg";
import community from "../../resource/img/myaccount/community.svg";
//import { PresentedOrderType } from "./TicketOrderTable";
//import { OrderTicket } from "./OrderTicket";
import { SmallButton } from "../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentAlt, faEnvelope, faFileAlt, faLock, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { mediaQueries } from "../../util/mediaQueries";

export const Support = () => {
	return (
		<MyAccountPage
			mobileTitle="Support"
			columns={[
				<>
					<MyAccountCard header="FAQ">
						<>
							<input placeholder="How may we help you?" />
							<FAQButtonContainer>
								<FAQButton icon={topfaqs}>Top FAQs</FAQButton>
								<FAQButton icon={purchaseres}>Purchasing Reservations</FAQButton>
								<FAQButton icon={youraccount}>Your Account</FAQButton>
								<FAQButton icon={tickets}>Tickets</FAQButton>
								<FAQButton icon={bidsell}>Bid/Sell Reservations</FAQButton>
								<FAQButton icon={share}>Share Us</FAQButton>
								<FAQButton icon={billing}>Billing</FAQButton>
								<FAQButton icon={community}>Community</FAQButton>
							</FAQButtonContainer>
						</>
					</MyAccountCard>

					<MyAccountCard header="Contact Us" subheader="Can't find the answer to your question?">
						<ContactUs>
							<div>
								<SmallButton onClick={() => (window.location.href = "mailto:support@sitickets.com")}>
									<FontAwesomeIcon icon={faEnvelope} />
									support@sitickets.com
								</SmallButton>
								<SmallButton onClick={() => (window.location.href = "tel:16419928572")}>
									<FontAwesomeIcon icon={faPhoneAlt} />
									+1 (641) 992 8572
								</SmallButton>
								<SmallButton onClick={() => (window.location.href = "tel:16419928572")}>
									<FontAwesomeIcon icon={faCommentAlt} />
									Chat
								</SmallButton>
							</div>
							<img alt="Contact Us Icon" src={contact} />
						</ContactUs>
					</MyAccountCard>

					<BottomButtons>
						<SmallButton>
							<FontAwesomeIcon icon={faFileAlt} /> View Terms & Conditions
						</SmallButton>
						<SmallButton>
							<FontAwesomeIcon icon={faLock} /> View Privacy Policy
						</SmallButton>
					</BottomButtons>
				</>,
			]}
		/>
	);
};

const BottomButtons = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	button {
		&:not(:last-child) {
			margin-right: 15px;
		}
	}
	@media ${mediaQueries.max670} {
		flex-direction: column;
		max-width: fit-content;
		align-items: stretch;
		margin: 0 auto;
		button {
			&:not(:last-child) {
				margin: 0;
				margin-bottom: 13px;
			}
		}
	}
`;

const FAQButtonContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
	margin-top: 22px;
`;

const FAQButton = (
	props: React.PropsWithChildren<{
		icon: string;
	}>
) => {
	return (
		<FAQButtonElement>
			<div>
				<img src={props.icon} alt="FAQ icon" />
			</div>
			<p>{props.children}</p>
		</FAQButtonElement>
	);
};

const FAQCircleWidthHeight = "95px";
const FAQIconHeight = "32%";

const FAQButtonElement = styled.button`
	margin: 0 24px;
	margin-bottom: 20px;
	padding: 0 !important;
	outline: none;
	border: none;
	background: transparent;
	max-width: ${FAQCircleWidthHeight};
	text-align: center;
	cursor: pointer;
	&:hover {
		& > div {
			box-shadow: 5px 5px 16px 0px rgba(0, 0, 0, 0.26);
		}
	}
	& > div {
		transition: 0.2s cubic-bezier(0.22, 0.61, 0.36, 1) box-shadow;
		box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.26);
		border-radius: 100%;
		background: linear-gradient(135deg, #ffffff, #cdcdcd);
		width: ${FAQCircleWidthHeight};
		height: ${FAQCircleWidthHeight};
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 11px;
		& > img {
			min-height: ${FAQIconHeight};
			height: auto;
			max-height: ${FAQIconHeight};
		}
	}
`;

const ContactUs = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	img {
		height: 65px;
	}
	& > div {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 6px;
		& > button {
			&:not(:last-child) {
				margin-bottom: 12px;
			}
		}
	}
`;
