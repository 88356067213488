import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAutoUpdateState } from "../../presentation/hooks/useAutoUpdateState";
import { deletePushlyCart } from "../../presentation/util/pushlyService";
import { setCheckoutTimer } from "../../store/actions/checkoutActions";
import { clearCart } from "../../store/reducers/cartReducer";

const timerLimit = 900; // 15 minutes

export const useCheckout = () => {
	const checkoutState = useSelector(state => state.persistent.checkout);
	const [remainingTime, setRemainingTime] = useState(timerLimit);
	const dispatch = useDispatch();

	useEffect(() => {
		if (checkoutState.ticketTimerInitialTime) {
			const date = new Date(checkoutState.ticketTimerInitialTime);
			let intervalId: number | null = setInterval(() => {
				const expirationTime = DateTime.fromJSDate(date).plus({ seconds: timerLimit });
				const diff = expirationTime.diff(DateTime.fromJSDate(new Date()), ["seconds"]);
				const remainingTimeInSeconds = Math.trunc(diff.seconds);

				setRemainingTime(remainingTimeInSeconds);

				if (!remainingTimeInSeconds) {
					if (intervalId) {
						clearInterval(intervalId);
					}
					intervalId = null;
				}
			}, 500);
		}
	}, [checkoutState.ticketTimerInitialTime]);

	useEffect(() => {
		if (!remainingTime) {
			dispatch(setCheckoutTimer(""));
			dispatch(clearCart());
			deletePushlyCart();
		}
	}, [remainingTime]);

	const selectedPaymentMethodOption = useAutoUpdateState(() => {
		if (checkoutState.newPaymentMethodType) {
			return {
				type: checkoutState.newPaymentMethodType,
			};
		} else if (checkoutState.paymentMethod) {
			return checkoutState.paymentMethod;
		}
	}, [checkoutState.newPaymentMethodType, checkoutState.paymentMethod]);

	return {
		...checkoutState,
		selectedPaymentMethodOption,
		remainingTime,
		timerLimit,
	};
};
