import React from "react";
import styled from "styled-components/macro";
import { SkeletonWrapper } from "../SkeletonWrapper/SkeletonWrapper";
import { SkeletonProps } from "../../../model/optimizedModel/skeleton";
import { isMobileApp } from "../../util/isMobileApp";
import localEventsAd from "../../resource/assets/ads/local-events-ad.png";
import { InternalRoutes } from "../../Link";
import { useAutoUpdateState } from "../../hooks/useAutoUpdateState";
import { useHistory } from "react-router-dom";
import { maxContentWidthValue } from "../../util/maxContentWidth";
import { breakpoints } from "../../util/breakpoints";
import { useOpenAdLink } from "../../hooks/useOpenAdLink";

export const SingleAd = ({
	loading,
	contentFit = "contain",
	...props
}: SkeletonProps<{
	image?: string;
	url?: string;
	title?: string;
	contentFit?: "cover" | "contain";
}>) => {
	const replaceProvidedPropsWithLocalEventsAdProps = useAutoUpdateState(() => props.url === "/bestbets" && isMobileApp, [isMobileApp, props.url]);
	const { url, title } = replaceProvidedPropsWithLocalEventsAdProps
		? {
				url: InternalRoutes.Discover("?distance=50"),
				title: "Local Events",
		  }
		: props;

	const openAdLink = useOpenAdLink();

	return (
		<SingleAdContainer
			contentFit={contentFit}
			onClick={() => {
				if (!loading && url !== undefined) {
					openAdLink(url);
				}
			}}
		>
			<StyledSkeletonWrapper loading={loading}>{(replaceProvidedPropsWithLocalEventsAdProps || props.image) && <SingleAddImage src={replaceProvidedPropsWithLocalEventsAdProps ? localEventsAd : props.image} alt={title} />}</StyledSkeletonWrapper>
		</SingleAdContainer>
	);
};

const SingleAddImage = styled.img`
	object-fit: contain;
	width: 100%;
`;

const StyledSkeletonWrapper = styled(SkeletonWrapper)`
	z-index: 0;
`;

const desktopPadding = (100 * 160) / maxContentWidthValue; // 1060:160 image aspect ratio for desktop  when containFit is cover
const mobilePadding = (100 * 90) / 350; // 350:90 aspect ratio for mobile when containFit is cover
const SingleAdContainer = styled.div<{ contentFit: "contain" | "cover" }>`
	cursor: pointer;
	${props =>
		props.contentFit === "cover" &&
		`
    height: 0;
    padding-bottom: ${desktopPadding}%;
    overflow-y: hidden;
    
    @media (max-width: ${breakpoints.mobile}) {
      padding-bottom: ${mobilePadding}%;
    }
  `}
	z-index: 0;
`;
