import React from "react";
import styled from "styled-components";
import { ButtonLink } from "../ButtonLinkApp/ButtonLink";
import { FontFamily } from "../Typography/Typography";
import { breakpoints } from "../../util/breakpoints";
import { useMediaQuery } from "../../hooks/useMediaQuery";

type EventCardProps = {
	image: string;
	title: string;
	dateLocation: string;
	link: string;
	text: string;
	index: number;
	buttonLink?: string;
	button?: boolean;
};

const Card = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 2.5em;
	padding: 3em;
	width: 100%;
	max-width: 1200px;

	@media (max-width: ${breakpoints.tablet}) {
		flex-direction: column;
		padding: 0;
		margin-top: 2em;
		margin-bottom: 2em;
	}
	@media (max-width: ${breakpoints.mobile}) {
		flex-direction: column;
		margin-bottom: 2em;
		margin-top: 2em;
		padding: 0;
	}
`;

const Image = styled.img`
	width: 50%;
	max-width: 600px;
	height: auto;
	object-fit: contain;

	@media (max-width: ${breakpoints.tablet}) {
		width: 80%;
	}
	@media (max-width: ${breakpoints.mobile}) {
		width: 80%;
	}
`;

const Description = styled.div`
	width: 50%;
	max-width: 600px;
	margin-left: 15px;
	text-align: left;

	@media (max-width: ${breakpoints.tablet}) {
		width: 80%;
	}
	@media (max-width: ${breakpoints.mobile}) {
		width: 80%;
	}
`;

const Title = styled.h2`
	font-size: 24px;
	margin-bottom: 10px;
	text-transform: uppercase;
	font-size: 38px;
	font-family: ${FontFamily.Venus};
	font-weight: 700;
	text-align: left;
	color: #000000;

	@media (max-width: ${breakpoints.tablet}) {
		font-size: 20px;
	}
	@media (max-width: ${breakpoints.mobile}) {
		font-size: 20px;
	}
`;

const Text = styled.p`
	font-size: 16px;
	font-family: ${FontFamily.Nunito};
	font-weight: 500;
	margin-top: 1em;
	line-height: 20px;
	letter-spacing: -0.03em;
	margin-bottom: 2em;
	text-align: left;
	color: #2D3740;
`;

const DateLocation = styled.p`
	text-transform: uppercase;
	font-style: italic;
	font-size: 18px;
	font-family: ${FontFamily.Venus};
	font-weight: 700;
	text-align: left;
	color: #54B9D6;
	line-height: 20px;
`;

const EventCard = ({ image, title, text, dateLocation, link, index, buttonLink, button }: EventCardProps) => {
	const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);
	return (
		<Card>
			{isTablet ? (
				<>
					<Image src={image} alt={title} />
					<Description>
						<Title>{title}</Title>
						<DateLocation>{dateLocation}</DateLocation>
						<Text>{text}</Text>
						{button && <ButtonLink href={link}>{buttonLink || "GET TICKETS"}</ButtonLink>}
					</Description>
				</>
			) : (
				<>
					{index % 2 === 1 ? (
						<>
							<Description>
								<Title>{title}</Title>
								<DateLocation>{dateLocation}</DateLocation>
								<Text>{text}</Text>
								{button && <ButtonLink href={link}>{buttonLink || "GET TICKETS"}</ButtonLink>}
							</Description>
							<Image src={image} alt={title} />
						</>
					) : (
						<>
							<Image src={image} alt={title} />
							<Description>
								<Title>{title}</Title>
								<DateLocation>{dateLocation}</DateLocation>
								<Text>{text}</Text>
								{button && <ButtonLink href={link}>{buttonLink || "GET TICKETS"}</ButtonLink>}
							</Description>
						</>
					)}
				</>
			)}
		</Card>
	);
};

export default EventCard;
