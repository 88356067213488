import React, { CSSProperties, MouseEventHandler, useMemo } from "react";
import styled from "styled-components/macro";

const useDefaultValue = (prop: any, defaultValue: any) => {
	return useMemo(() => {
		return prop === undefined ? defaultValue : prop;
	}, [prop]);
};

export const CollapseArrow = (props: { style?: CSSProperties; onClick?: MouseEventHandler<HTMLDivElement>; expanded?: boolean; className?: string; size?: number; weight?: number; color?: string; customExpansionArrowSpanPos?: number }) => {
	const size = useDefaultValue(props.size, 14);
	const weight = useDefaultValue(props.weight, 1);

	return (
		<ExpansionArrow color={props.color} expansionArrowSpanPos={size * (props.customExpansionArrowSpanPos || 0.24) + "px"} size={size} weight={weight} style={props.style} className={props.expanded ? "expanded " + props.className : "" + props.className} onClick={props.onClick}>
			<span />
			<span />
		</ExpansionArrow>
	);
};

const ExpansionArrow = styled.div<{
	expansionArrowSpanPos: string;
	weight: number;
	size: number;
	color?: string;
}>`
	padding: ${props => props.size * 0.4 + "px " + props.size * 0.72 + "px !important"};
	cursor: pointer;
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	outline: none;
	background: none;
	position: relative;
	&.expanded {
		span {
			&:first-child {
				transform: ${props => `rotate(135deg) translate(${props.expansionArrowSpanPos}, ${props.expansionArrowSpanPos})`};
			}
			&:last-child {
				transform: ${props => `rotate(45deg) translate(${props.expansionArrowSpanPos}, -${props.expansionArrowSpanPos})`};
			}
		}
	}
	span {
		display: block;
		height: ${props => props.weight + "px"};
		width: ${props => props.size + "px"};
		//Note: Default color is MUI secondary text color. Consider creating a MUI color prop to consume MUI colors for optimized frontend?
		background-color: ${props => props.color || "#757575"};
		transition: transform 0.2s ease;
		position: absolute;
		&:first-child {
			transform: ${props => `rotate(225deg) translate(${props.expansionArrowSpanPos}, -${props.expansionArrowSpanPos})`};
		}
		&:last-child {
			transform: ${props => `rotate(-45deg) translate(${props.expansionArrowSpanPos}, ${props.expansionArrowSpanPos})`};
		}
	}
`;
