import React from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { ButtonLink } from "../components/Button/ButtonLink";
import { CommonPage } from "../components/CommonPage";
import { InternalRoutes } from "../Link";
import { Button } from "../components/Button/Button";
import { useCart } from "../../services/hooks/useCart";
import { maxContentWidthPadding, maxContentWidthStyles } from "../util/maxContentWidth";
import { CartItems } from "../components/Cart/CartItems";
import { MetaDecorator, MetaTagData } from "../components/SEOMetadecorator/MetaDecorator";
import { mediaQueries } from "../util/mediaQueries";
import { TicketTimer } from "../components/Checkout/TicketTimer";
import { useHeaderHeight } from "../hooks/useHeaderHeight";
import { isMobileApp } from "../util/isMobileApp";
import { FutureTotalInvoiceItem } from "../components/Invoices/FutureTotalInvoiceItem";
import { useAutoUpdateState } from "../hooks/useAutoUpdateState";
import { priceDoesNotExceedVenmoTransactionLimit, siThePartyEventId } from "../util/general";
import { getAppColor } from "../util/appColors";

const Cart = () => {
	const history = useHistory();
	const { cart, cartFuturesKeys, cartPrices, ticket } = useCart();

	const displayVenmoPricing = useAutoUpdateState(() => ticket?.event.id !== siThePartyEventId && cartPrices && priceDoesNotExceedVenmoTransactionLimit(cartPrices.VenmoAccount) && cartPrices.VenmoAccount < cartPrices.PayPalAccount, [cartPrices?.VenmoAccount, cartPrices?.PayPalAccount, ticket?.event.id]);
	return (
		<CartContent>
			{cart?.tickets.length || (cart?.futures && Object.values(cart.futures).length) ? (
				<>
					<CartItems />
					{!cart.tickets.length && !!cartFuturesKeys?.length && cartPrices?.VenmoAccount && cartPrices?.PayPalAccount ? (
						<FutureTotalInvoiceItem total={cartPrices[displayVenmoPricing ? "VenmoAccount" : "PayPalAccount"]} priceFormat="standard" />
					) : (
						<div
							css={`
								width: 100%;
								border-bottom: 1px solid ${getAppColor("darkGrey", "light")};
							`}
						/>
					)}
					<Buttons>
						<BackButton
							onClick={() => {
								history.go(-1);
							}}
							initialAppearance="WhiteGray"
						>
							Continue Shopping
						</BackButton>
						<StyledCheckoutButton to={InternalRoutes.Checkout}>Checkout</StyledCheckoutButton>
					</Buttons>
				</>
			) : (
				<NoItemsWrapper>
					<NoItems>Nothing to see here yet</NoItems>
					<StyledButton
						onClick={() => {
							history.go(-1);
						}}
					>
						Continue Shopping
					</StyledButton>
				</NoItemsWrapper>
			)}
		</CartContent>
	);
};

export const CartRoute = () => {
	const headerHeight = useHeaderHeight();
	return (
		<CommonPage isFooterHidden>
			<MetaDecorator title={MetaTagData.Cart.title} description={MetaTagData.Cart.description} />
			<CartRouteContent
				style={
					isMobileApp
						? {
								paddingBottom: maxContentWidthPadding,
								marginBottom: headerHeight,
						  }
						: undefined
				}
			>
				<DesktopTicketTimer />
				<Cart />
			</CartRouteContent>
		</CommonPage>
	);
};

const mobileMediaQuery = (css: string) => `
  @media ${mediaQueries.max500} {
    ${css}
  }
`;

const CartContent = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const CartRouteContent = styled.div`
	${maxContentWidthStyles}
	padding-top: ${maxContentWidthPadding}px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	margin-bottom: 25px;
`;

const NoItemsWrapper = styled.div`
	display: flex;
	align-content: center;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
	min-height: 40vh;
`;

const DesktopTicketTimer = styled(TicketTimer)`
	margin-bottom: 11px;
`;

const BackButton = styled(Button)`
	${mobileMediaQuery(`
    display: none;
  `)}
`;

const NoItems = styled.span`
	text-align: center;
	color: black;
	font-family: "Montserrat";
	margin-bottom: 60px;
	font-size: 20px;
	line-height: 30px;
	font-weight: bold;
	width: 100%;
	display: inline-block;
`;

const StyledButton = styled(Button)`
	margin-top: 10px;
	margin-bottom: 20px;
	width: auto;
	padding: 0px 25px;
	font-size: 16px;
	border-radius: 3px;
	background-color: ${getAppColor("dark")}
`;

const StyledCheckoutButton = styled(ButtonLink)`
	background-color: ${getAppColor("dark")};
`;

const Buttons = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 40px;
	& > * {
		padding: 0 50px;
		&:not(:last-of-type) {
			margin-right: 20px;
		}
	}
	button {
		font-size: 18px;
		${mobileMediaQuery(`
      width: 100%;
    `)}
	}
`;
