import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { MobileFiltersModal } from "./MobileFiltersModal";
import { mediaQueries } from "../../util/mediaQueries";

export const MultiFilterContainer = ({ filters, onReset, searchParams }: { filters: React.ReactNode[]; onReset: () => void; searchParams: Object }) => {
	const isMobile = useMediaQuery(mediaQueries.max750);
	const [activeFilters, setActiveFilters] = useState(0);
	useEffect(() => {
		const filtersLength = "to" in searchParams ? Object.values(searchParams).length - 1 : Object.values(searchParams).length;
		setActiveFilters(filtersLength - Object.values(searchParams).filter(e => e === null).length);
	}, [searchParams]);

	return isMobile ? (
		<FilterButton filters={filters} onReset={onReset} activeFilters={activeFilters} />
	) : (
		<Container filtersAmount={filters.length}>
			{filters.map((filter, index) => {
				return (
					<Filter key={index} filtersAmount={filters.length}>
						{filter}
					</Filter>
				);
			})}
		</Container>
	);
};

const FilterButton = ({ filters, onReset, activeFilters }: { filters: React.ReactNode[]; onReset: () => void; activeFilters: number }) => {
	const [showModal, setShowModal] = useState(false);
	return (
		<>
			{showModal && (
				<MobileFiltersModal
					onApply={() => {}}
					onDidClose={() => {
						setShowModal(false);
					}}
					filters={filters}
					onReset={onReset}
				/>
			)}
			<ButtonContainer>
				<Button
					activeFilters={activeFilters}
					onClick={() => {
						setShowModal(true);
					}}
				>
					<Text activeFilters={activeFilters}>Filters ({activeFilters})</Text>
				</Button>
			</ButtonContainer>
		</>
	);
};

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	margin-bottom: 16px;
`;

const Filter = styled.div<{ filtersAmount: number }>`
	width: 50%;
	:not(:last-child) {
		margin-right: calc(2px + (10px / ${props => props.filtersAmount}));
	}
`;

const Container = styled.div<{ filtersAmount: number }>`
	display: flex;
	justify-content: ${props => (props.filtersAmount > 1 ? "center" : "flex-start")};
	width: 100%;
	margin-bottom: 24px;
`;

const Button = styled.div<{ activeFilters: number }>`
	height: 42px;
	color: #555555;
	font-family: Montserrat;
	font-size: 13px;
	font-weight: 600;
	line-height: 16px;
	text-align: center;
	border: 1px solid #555555;
	box-sizing: border-box;
	border-radius: 3px;
	padding: 8px 16px;
	cursor: pointer;
	width: 90%;
	align-self: center;
	margin-top: 24px;
	display: flex;
	justify-content: center;
	align-items: center;

	${props =>
		props.activeFilters &&
		`
		background: #12284C;
	`}
`;

const Text = styled.div<{ activeFilters: number }>`
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	color: #555555;

	${props =>
		props.activeFilters &&
		`
		color: #FFFFFF;
		font-weight: bold;
	`}
`;
