import { setIsCaptchaBadgeHidden } from "../../store/reducers/captchaBadgeReducer";
import { useDispatch } from "react-redux";
import { useScript } from "../../presentation/hooks";
import { useEffect } from "react";
import { Config } from "../../Config";
import { useRemoteIp } from "./useRemoteIp";

export const useLogin = () => {
	const dispatch = useDispatch();
	const remoteIp = useRemoteIp();

	useScript(`https://www.google.com/recaptcha/api.js?render=${Config.getRecaptchaSiteKey()}&badge=bottomleft`);

	useEffect(() => {
		dispatch(setIsCaptchaBadgeHidden(false));
		return () => {
			dispatch(setIsCaptchaBadgeHidden(true));
		};
	}, []);

	return remoteIp;
};
