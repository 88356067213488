import React from "react";
import styled from "styled-components/macro";
import { StyledComponentProps } from "../../../../model/optimizedModel/styles";
import { renderPaymentMethod } from "../../../../services/braintree";
import { paymentMethodBadges } from "../../../util/paymentMethodLogos";

//TODO: Consider moving this component to a diffrent folder/location
export const PaymentMethodDisplay = ({
	paymentMethod,
	className,
	badgeHeight,
	...paymentMethodRenderOptions
}: {
	paymentMethod: Exclude<Parameters<typeof renderPaymentMethod>[0], undefined>;
	badgeHeight?: number | string;
} & StyledComponentProps &
	Parameters<typeof renderPaymentMethod>[1]) => {
	return (
		<Content className={className}>
			<img
				//TODO: Consider seeing if there's a better way to implement this conditional
				src={paymentMethodBadges[paymentMethod.details !== undefined && "cardType" in paymentMethod.details ? paymentMethod.details.cardType : paymentMethod.type]}
				alt={paymentMethod.type}
				style={{
					height: badgeHeight !== undefined ? badgeHeight : "",
				}}
			/>
			<span>
				{
					//TODO: Remove type cast once braintree token integration is complete and merged in
					renderPaymentMethod(paymentMethod, paymentMethodRenderOptions)
				}
			</span>
		</Content>
	);
};

const Content = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	//TODO: Keeping this in case it's needed in the future. For now this component just follows the styles of the parent element/component
	//MUI Caption
	/*
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
 */
	img {
		//TODO: Experiment with these properties
		height: 20px;
		margin-right: 6px;
	}
	span {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: min(70vw, 300px);
	}
`;
