import { CSSProperties } from "react";
import postcss from "postcss";
import postcssJs from "postcss-js";

type FlexGapValue = string | number;

type FlexGapArray = FlexGapValue[];

export type FlexGap = FlexGapValue | FlexGapArray;

const stringifyFlexGapArray = (arr: FlexGapArray) => {
	const value = arr.join(" ");
	return value;
};

const getParentFlexGapMargin = (gap: FlexGapValue) => {
	const value = "-" + gap;
	return value;
};

export const flexGap = (gap: FlexGap) => {
	let childMargin: FlexGapValue;
	let parentMargin: FlexGapValue;
	if (Array.isArray(gap)) {
		const parentMarginArr = gap.map(item => {
			const value = getParentFlexGapMargin(item);
			return value;
		});
		childMargin = stringifyFlexGapArray(gap);
		parentMargin = stringifyFlexGapArray(parentMarginArr);
	} else {
		childMargin = gap;
		parentMargin = getParentFlexGapMargin(gap);
	}

	return `
    margin: ${parentMargin};
    & > * {
      margin: ${childMargin};
    }
  `;
};

//TODO: Consider finding a method to only accept valid types that the CSSProperties type accepts.
type MappableCSSPropertyValue = any;

const mapValueToCSSProperties = (keys: (keyof CSSProperties)[], value: MappableCSSPropertyValue) => {
	//Note: "output" cannot be typed with CSSProperties due to typescript throwing the following error on the line where "output[key]" is set to "value": "Expression produces a union type that is too complex to represent". This is why "output" is typed the way it is.
	let output: Partial<Record<keyof CSSProperties, any>> = {};
	keys.forEach(key => {
		output[key] = value;
	});
	return output;
};

export const verticalMarginObject = (value: MappableCSSPropertyValue) => mapValueToCSSProperties(["marginLeft", "marginRight"], value);

export const verticalPaddingObject = (value: MappableCSSPropertyValue) => mapValueToCSSProperties(["paddingLeft", "paddingRight"], value);

export const horizontalMarginObject = (value: MappableCSSPropertyValue) => mapValueToCSSProperties(["marginTop", "marginBottom"], value);

export const horizontalPaddingObject = (value: MappableCSSPropertyValue) => mapValueToCSSProperties(["paddingTop", "paddingBottom"], value);

export const cssStringToCSSObject = (cssString: string) => {
	const root = postcss.parse(cssString);
	return postcssJs.objectify(root);
};
