import { isRight } from "fp-ts/lib/These";
import { store } from "../store/store";
import { getEventData } from "./actions/eventsService";
import { getSeaticsEventAndVenue } from "./actions/seaticsService";
import { setEventData, setTicketsData, resetAll, setSeaticsData, setUnavailableError, setShowReplacementModal, setLoadingStatus, setEventBets, setSeaticsTimeout } from "../store/actions/eventPageActions";
import { getJsonp } from "../presentation/hooks";
import { Config } from "../Config";
import { DateTime } from "luxon";
import { SeaticsEventResponse } from "./types/EventsResponse";
import { resetCheckoutState, setCheckoutTimer } from "../store/actions/checkoutActions";
import { setCart } from "../store/reducers/cartReducer";
import { deleteCartItem, postBOCartTicket, postCartTicket } from "./cart";
import { Ticket } from "../model/Cart";
import { resetLoadingScreenData, setLoadingScreenData } from "../store/actions/loadingScreenDataActions";
import { getEventBetsBySlug } from "./actions/betsService";
import { getTicketData } from "./event";
import { postPushlyCart } from "../presentation/util/pushlyService";
import { EventData } from "../model/EventPage";

/**
 * Workaround to make sure previous timer is canceled
 */
let timeoutInProgress: number | null = null;

const cancelTimeoutInProgress = () => {
	if (timeoutInProgress !== null) {
		clearTimeout(timeoutInProgress);
		timeoutInProgress = null;
	}
};

export const loadEventData = (eventSlug: string) => {
	return new Promise((resolve, reject) => {
		store.dispatch(resetAll());
		cancelTimeoutInProgress();

		timeoutInProgress = setTimeout(() => {
			cancelTimeoutInProgress();
			const state = store.getState();
			if (!state.transient.event.loadedEventSlug) {
				store.dispatch(setLoadingStatus(false));
				store.dispatch(setSeaticsTimeout(true));
			}
		}, 15000);

		store.dispatch(getEventBetsBySlug(eventSlug)).then(result => {
			store.dispatch(setEventBets(isRight(result) && !(result.right instanceof Array) ? result.right : {
				"game": null,
				"teamFuture1": [],
				"teamFuture2": [],
				"playerProp1": [],
				"playerProp2": []
			}));
		});

		store.dispatch(getEventData(eventSlug)).then(eventData => {
			if (isRight(eventData)) {
				// This call will bring an event cube id (in case the event is stored there). it must be added to the store later
				store.dispatch(setEventData(eventData.right as unknown as EventData));
				loadMapData(eventData.right).then(resolve).catch(reject);
				//@ts-ignore
				getTicketData(eventData.right.id || eventData.right.primary_event_id)
					.then(ticketData => {
						//Filtering out TG's with tgDeliveryMethods having null value is a temporary frontend solution. This logic should be removed once these TG's are filtered on the backend, or TG's with tgDeliveryMethods are corrected to no longer have null as their value.
						const filteredTicketData = ticketData.data.filter(tg => tg.tgDeliveryMethods !== null);
						store.dispatch(setTicketsData(filteredTicketData));
					})
					.catch(reject);
			}
		});
	});
};

const loadMapData = (eventData: SeaticsEventResponse) => {
	return new Promise(resolve => {
		let params: any = {};
		params["venue"] = eventData.venueName;
		params["eventName"] = eventData.name;
		params["websiteConfigId"] = Config.getSeaticsWebsiteConfigId();
		params["consumerKey"] = Config.getSeaticsConsumerKey();

		//Optional params
		if (eventData.date) {
			params["dateTime"] = DateTime.fromJSDate(eventData.date).toFormat("yyyyMMddHHmm");
		}
		if (eventData.tnEventId) {
			params["eventId"] = eventData.tnEventId;
		}
		getJsonp(getSeaticsEventAndVenue(params))
			.then(result => {
				store.dispatch(setSeaticsData(result));
				resolve(true);
			})
			.catch(() => {
				//not mandatory, we can continue without this.
				resolve(false);
			});
	});
};

export const processCheckout = async (ticketQuantity: number) => {
	const state = store.getState();
	const event = state.transient.event;
	const cart = state.persistent.cart;
	const cartFull = !!cart?.tickets[0];
	const sessionId = cart?.sessionId;
	await store.dispatch(resetLoadingScreenData());

	if (cartFull) {
		const currentTicket = cart?.tickets[0] as Ticket;
		await clearCart(currentTicket);
	}

	let result: any = null;
	if (event && event.eventData && event.selectedTicket) {
		const tgSource = event.tickets?.find(ticket => ticket.tgID === event.selectedTicket?.tgID)?.tgSource;
		const tgPrice = event.tickets?.find(ticket => ticket.tgID === event.selectedTicket?.tgID)?.tgPrice;

		try {
			store.dispatch(setLoadingStatus(true));
			store.dispatch(
				setLoadingScreenData({
					text: "Locking in your tickets",
					hideBanner: true,
				})
			);

			result = await postCartTicket(event.eventData.id, event.selectedTicket.tgID, ticketQuantity, sessionId, tgSource, tgPrice, `${event.loadedEventSlug}/${event.selectedTicket.tgID}`);
			store.dispatch(setCart({ ...result.data.cart, source: tgSource }));

			store.dispatch(resetCheckoutState);
			store.dispatch(setCheckoutTimer(new Date().toISOString()));

			if (result.data.cart) {
				postPushlyCart(event, ticketQuantity);
			}
		} catch (error: any) {
			if (error.status === 410) {
				store.dispatch(setUnavailableError(null));
				store.dispatch(setUnavailableError(error.errors.message));
				store.dispatch(setCheckoutTimer());
			}
		} finally {
			store.dispatch(setLoadingStatus(false));
		}
	}
	return !!result;
};

export const processCheckoutBoxOffice = async (ticketSelectionList: { quantity: number; tierId: number }[]) => {
	const state = store.getState();
	const event = state.transient.event;
	const cart = state.persistent.cart;
	const cartFull = !!cart?.tickets[0];
	const sessionId = cart?.sessionId;
	await store.dispatch(resetLoadingScreenData());

	if (cartFull) {
		const currentTicket = cart?.tickets[0] as Ticket;
		await clearCart(currentTicket);
	}

	let result: any = null;
	if (event && event.eventData && event.selectedBOTickets) {
		let ticketList = ticketSelectionList.map(selectedEvent => {
			const tgPrice = event.tickets?.find(ticket => ticket.tgID === selectedEvent?.tierId)?.tgPrice;

			return {
				ticketGroupId: selectedEvent.tierId,
				quantity: selectedEvent.quantity,
				price: tgPrice,
				refUrl: `${event?.eventData?.slug}/${selectedEvent?.tierId}`,
			};
		});

		try {
			store.dispatch(setLoadingStatus(true));
			store.dispatch(
				setLoadingScreenData({
					text: "Locking in your tickets",
					hideBanner: true,
				})
			);

			result = await postBOCartTicket(event.eventData.id, sessionId, ticketList, "boxoffice");
			result.data.cart.tickets = [result.data.cart.tickets];

			store.dispatch(setCart({ ...result.data.cart, source: "boxoffice" }));
			store.dispatch(resetCheckoutState);
			store.dispatch(setCheckoutTimer(new Date().toISOString()));

		} catch (error: any) {
			if (error.status === 410) {
				store.dispatch(setUnavailableError(null));
				store.dispatch(setUnavailableError(error.errors.message));
				store.dispatch(setCheckoutTimer());
			}
		} finally {
			store.dispatch(setLoadingStatus(false));
		}
	}
	return !!result;
};

const clearCart = async (currentTicket: Ticket) => {
	let cartItemIds = currentTicket?.tiers?.map(tier => tier.cartItemId).join("_");
	const secondaryTicketCartItemId = currentTicket.cartItemId;

	if (secondaryTicketCartItemId) {
		cartItemIds = cartItemIds ? cartItemIds += `_${secondaryTicketCartItemId}` : secondaryTicketCartItemId.toString();
	}

	await deleteCartItem(cartItemIds, {
		isTicket: true,
	});
};
