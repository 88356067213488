import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";
import { useCheckout } from "../../../services/hooks/useCheckout";
import { setCheckoutAgreedToTermsAndPrivacy } from "../../../store/actions/checkoutActions";
import { TermsAndConditionsCheckbox } from "../TermsAndConditionsCheckbox/TermsAndConditionsCheckbox";

export const CheckoutTermsAndConditionsCheckbox = (props: StyledComponentProps) => {
	const checkoutState = useCheckout();
	const dispatch = useDispatch();

	useEffect(() => {
		return () => {
			if (checkoutState.agreedToTermsAndPrivacy) {
				dispatch(setCheckoutAgreedToTermsAndPrivacy(false));
			}
		};
	}, []);

	return (
		<TermsAndConditionsCheckbox
			{...props}
			openLinksInNewTab
			checked={checkoutState.agreedToTermsAndPrivacy}
			onChange={event => {
				dispatch(setCheckoutAgreedToTermsAndPrivacy(event.target.checked));
			}}
		/>
	);
};
