import React from "react";
import { ModalPosition } from "../Modal";
import { ModalPopup } from "../ModalPopup/ModalPopup";
import { ModalCloseButton } from "../Modal/ModalCloseButton";
import { Button } from "../Button/Button";
import styled from "styled-components";
import { Nulleable } from "../../../model/Utils";

interface UnavailableTicketsModalProps {
	visible: Nulleable<boolean>;
	message: Nulleable<string>;
	onClose: () => void;
}

export const UnavailableTicketsModal = (props: UnavailableTicketsModalProps) => {
	if (!props.visible) {
		return null;
	}

	return (
		<ModalPopup onDidClose={props.onClose} rightHeaderElements={(onClose: any) => <ModalCloseButton onClick={onClose} />} centerHeaderElements="Just Missed Out!" position={ModalPosition.Center} hideLogo={true}>
			{(onClose: () => void) => (
				<UnavailableTickets>
					<span>{props.message}</span>
					<Button onClick={onClose}>Close</Button>
				</UnavailableTickets>
			)}
		</ModalPopup>
	);
};

const UnavailableTickets = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 15px;
	max-width: 63vw;
	span {
		font-weight: 500;
		color: black;
		margin: 15px 10px;
		font-size: 16px;
		line-height: normal;
		max-width: 450px;
	}
	button {
		margin: 15px 0px;
	}
`;
