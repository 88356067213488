import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import { StyledComponentProps } from "../../../model/optimizedModel/styles";
import { useResizeWatch } from "../../hooks";
import { useAutoUpdateState } from "../../hooks/useAutoUpdateState";
import { getTypographyStyles } from "../Typography/Typography";

// Note: A bit of a hack/trick is used in the logic to hide the date's separator in the BetDate component. There's two instances of the Separator component rendered to the DOM but with styles that hide them to the user when the component wants to hide the "main separator". The reason why this is done is to prevent the component resizing and showing the main separator again. Consider implementing a less hacky method in the future if possible.
const Separator = (props: { hidden: boolean }) => {
	return (
		<span
			style={
				props.hidden
					? {
							opacity: 0,
							pointerEvents: "none",
					  }
					: undefined
			}
		>
			&nbsp;-&nbsp;
		</span>
	);
};

export const BetDate = (
	props: {
		date: string;
	} & StyledComponentProps
) => {
	const element = useRef(null);

	const [hideMainSeparator, setHideMainSeparator] = useState(false);

	useResizeWatch(element, entry => {
		const firstChild = entry.target.firstChild;
		const lastChild = entry.target.lastChild;
		if (firstChild instanceof HTMLElement && lastChild instanceof HTMLElement) {
			const firstChildOffsetTop = firstChild?.offsetTop;
			const lastChildOffsetTop = lastChild?.offsetTop;
			if (firstChildOffsetTop !== undefined && lastChildOffsetTop !== undefined) {
				setHideMainSeparator(firstChildOffsetTop !== lastChildOffsetTop);
			}
		}
	});

	const date = useAutoUpdateState(() => {
		const value = new Date(props.date);
		return value;
	}, [props.date]);

	return (
		<Content ref={element} className={props.className}>
			<span>
				{hideMainSeparator && <Separator hidden />}
				{date.toLocaleDateString([], {
					month: "2-digit",
					day: "2-digit",
				})}
				<Separator hidden={hideMainSeparator} />
			</span>
			<span>
				{date.toLocaleTimeString([], {
					hour: "numeric",
					minute: "numeric",
					timeZoneName: "short",
				})}
			</span>
		</Content>
	);
};

const Content = styled.span`
	${getTypographyStyles("bodySmall")}
	text-align: center;
	& > span {
		white-space: nowrap;
		display: inline-block;
	}
`;
