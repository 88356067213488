import React, { useEffect, useState } from "react";
import { ModalPopup } from "../../ModalPopup/ModalPopup";
import { DateUtils } from "react-day-picker";
import { DateTime } from "luxon";
import { DateRangeModalContent, FilterButton, StyledDayPicker } from "../styles";
import "react-day-picker/lib/style.css";
import { ModalCloseButton } from "../../Modal/ModalCloseButton";
import { Browser } from "../../../util/UserAgent";
import styled from "styled-components/macro";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { NavControls } from "../../NavControls/NavControls";
import { ResetButton } from "../../ResetButton/ResetButton";
import { ModalButton } from "../../../util/ModalButton";
import { maxContentWidth } from "../../../util/maxContentWidth";

const IOS_BROWSER_FONT_SIZE = 16;
const NORMAL_FONT_SIZE = 11;
const mobileScreenWidth = "500px";

/**
 * Date range represented as ISO Date string (yyyy/MM/dd).
 */
export interface DateRange {
	from?: string;
	to?: string;
}

export interface DateRangeFilterProps {
	className?: string;
	/**
	 * From date. ISO Date string (yyyy/MM/dd).
	 */
	from: string | null | undefined;
	/**
	 * To date. ISO Date string (yyyy/MM/dd).
	 */
	to: string | null | undefined;
	onChange: (dates: DateRange) => void;
	renderAs?: (props: any) => any;
}

const defaultRangeState = { from: undefined, to: undefined };

export const getDateFilterText = (from?: string | null, to?: string | null) => {
	let text = "";
	if (from) {
		text += from;
	}
	if (to) {
		text += ` to ${to}`;
	}
	return text;
};

export const DateRangeFilter = (props: DateRangeFilterProps) => {
	const [open, setOpen] = useState(false);
	const [{ from, to }, setRange] = useState<{
		from?: Date;
		to?: Date;
	}>({});
	let selectedDays = [];
	if (from) {
		selectedDays.push(from);
	}
	if (from && to) {
		selectedDays.push({ from, to });
	}

	// Update local state on props change
	useEffect(() => {
		setRange({
			from: props.from ? DateTime.fromISO(props.from).toJSDate() : undefined,
			to: props.to ? DateTime.fromISO(props.to).toJSDate() : undefined,
		});
	}, [props.from, props.to]);

	// Actions
	const onDayClick = (day: Date) => {
		//Have to remove the hour and minutes because otherwise user cannot select today as a valid date
		const now = new Date().setHours(0, 0, 0, 0);
		const selected = day.setHours(0, 0, 0, 0);

		const validDate = selected >= now;
		if (validDate) setRange(DateUtils.addDayToRange(day, { from, to } as any));
	};

	const closeModal = () => {
		const fromISO = from && DateTime.fromJSDate(from).toISODate();
		const toISO = to && DateTime.fromJSDate(to).toISODate();
		setOpen(false);
		props.onChange({
			from: fromISO,
			to: toISO !== fromISO ? toISO : undefined,
		});
	};

	const isMobileScreen = useMediaQuery(`(max-width: ${mobileScreenWidth})`);
	const isMediumScreen = useMediaQuery(`(max-width: ${maxContentWidth})`);

	const getAmountOfMonthsToShow = () => {
		if (isMobileScreen) {
			return 60;
		} else if (isMediumScreen) {
			return 1;
		}
		return 2;
	};
	return (
		<>
			{props.renderAs ? (
				props.renderAs({ onClick: () => setOpen(true) })
			) : (
				<FilterButton
					className={props.className}
					onClick={() => setOpen(true)}
					style={{
						fontSize: Browser.SafariMobile || Browser.ChromeIOS ? IOS_BROWSER_FONT_SIZE : NORMAL_FONT_SIZE,
					}}
				>
					<SelectedDateRange>{props.from ? props.from : "Date"}</SelectedDateRange>
				</FilterButton>
			)}
			{open && (
				<ModalPopup onDidClose={closeModal} centerHeaderElements="Date Range" rightHeaderElements={onClose => <ModalCloseButton onClick={onClose} />} fullscreen={isMobileScreen}>
					<DateRangeModalContent>
						<ClearAllContainer>
							<ResetButton
								text="RESET"
								onClick={() => {
									setRange(defaultRangeState);
								}}
							/>
						</ClearAllContainer>

						<StyledDayPicker numberOfMonths={getAmountOfMonthsToShow()} selectedDays={selectedDays} modifiers={{ start: from, end: to }} onDayClick={onDayClick} disabledDays={{ before: new Date() }} navbarElement={props => <NavControls {...props} />} canChangeMonth={!isMobileScreen} />
						<ButtonContainer>
							<MobileDivider />
							<ModalButton onClick={closeModal}>Apply</ModalButton>
						</ButtonContainer>
					</DateRangeModalContent>
				</ModalPopup>
			)}
		</>
	);
};

const SelectedDateRange = styled.span`
	overflow: hidden;
	flex: 1;
`;

const ClearAllContainer = styled.div`
	height: 38px;
	display: flex;
	justify-content: flex-end;
	width: 100%;
	margin-top: 15px;
`;

const ButtonContainer = styled.div`
	@media (max-width: ${mobileScreenWidth}) {
		position: fixed;
		top: 84%;
		height: 150px;
		width: 100%;
		background: #ffffff;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
`;

const MobileDivider = styled.div`
	width: 80%;
	border-bottom: 1px solid #e5e5e5;
`;
