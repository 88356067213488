import React from "react";
import styled from "styled-components/macro";
import { ExternalRoutes, InternalRoutes, Link } from "../../Link";
import iconHeadphones from "../../resource/image/icon-headphones-red.svg";
import iconPrivacy from "../../resource/image/icon-privacy-red.svg";
import iconClock from "../../resource/image/icon-clock-red.svg";
import iconTicket from "../../resource/image/icon-ticket-red.svg";
import iconSeats from "../../resource/image/icon-seats-red.svg";
import iconMoneyBack from "../../resource/image/icon-money-back-red.svg";
import iconTrophie from "../../resource/image/icon-trophie-red.svg";
import { FanGuaranteeCardProps } from "./FanGuaranteeCards";

export const CardLink = styled(Link)`
	color: #e63422;
	text-decoration: underline;
	font-weight: 700;
	display: inline-block;
`;

export const FanGuaranteeData: FanGuaranteeCardProps[] = [
	{
		icon: iconHeadphones,
		title: "Best in class Customer Experience, from Shop to Showtime.",
		description: <>SI Tickets is happy to help at any point along your way, from shop to showtime, so that you and your family and friends can create once in a lifetime memories, hassle free.</>,
	},
	{
		icon: iconPrivacy,
		title: "Customer privacy is taken seriously.",
		description: (
			<>
				SI Tickets will not, under any circumstances, share your personal information without your consent and we strictly follow all e-commerce security standards and regulations.
				<br />
				<CardLink to={InternalRoutes.Privacy}>Read Our Privacy Policy</CardLink>
			</>
		),
	},
	{
		icon: iconClock,
		title: "Tickets are Guaranteed to arrive in time for your event.",
		description: <>No matter where your event is taking place or what your travel plans may be, SI Tickets will ensure you receive your tickets in time for the event. Whether you are waiting for mobile tickets to be transferred to you on game day or wristbands were shipped to your home, SI Tickets will make sure you receive your tickets in time for your event.</>,
	},
	{
		icon: iconTicket,
		title: "Rest assured: The tickets you purchase on SI Tickets are valid.",
		description: (
			<>
				We have partnered with licensed professional ticket sellers to ensure the tickets listed on our platform are valid. Sellers are held to strict measures to make sure that all available tickets on SI Tickets are authentic. If you are experiencing a misunderstanding at the venue,{" "}
				<CardLink target="_blank" to={ExternalRoutes.ContactSupport}>
					Contact Support
				</CardLink>
				immediately so we can assist in identifying and resolving the matter on the spot.
			</>
		),
	},
	{
		icon: iconSeats,
		title: "Tickets are Guaranteed to be Comparable or Better.",
		description: <>The tickets you receive will be comparable or better than those you ordered. Should you receive tickets that are not comparable or better than the tickets you’ve ordered, please notify SI Tickets immediately so we can correct the order. "Comparable or better" tickets are determined by SI Tickets in its sole discretion based on price, quality, availability and other factors.</>,
	},
	{
		icon: iconMoneyBack,
		title: "Event Cancellations Happen, and We Protect You when They Do.",
		description: <>If your event is canceled, and not postponed or rescheduled, SI Tickets will provide you with a full refund of the purchase price or, subject to applicable state laws, issue you a credit for use on a future purchase. We will notify you that the event was canceled and provide instructions on how to obtain a refund or credit.</>,
	},
	{
		icon: iconTrophie,
		title: "Reserve tickets to the postseason all year long with confidence.",
		description: <>The SI Tickets Fan Guarantee protects you from hidden charges and ensures that you can reserve postseason tickets all year long at the best price. Pay the reservation fee upfront to secure your seat to the biggest postseason games. You will only pay the ticket price IF your team makes the game and the ticket price will be a fraction of what you would pay for the same tickets elsewhere.</>,
	},
];
