import React from "react";
import { ButtonLink } from "../ButtonLink/ButtonLink";
import styled from "styled-components";
import { FontFamily } from "../../Typography/Typography";
import { breakpoints } from "../../../util/breakpoints";

type Props = {
	image: string;
	dateLocation: string;
	title: string;
	link: string;
	linkText: string;
};

const StyledCard = styled.div`
	width: 18rem;
	height: 100%;
	text-align: center;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media (max-width: ${breakpoints.tablet}) {
		width: 200px;
	}
	@media (max-width: ${breakpoints.mobile}) {
		width: 200px;
	}
`;

const StyledCardImg = styled.img`
	width: 100%;
	height: 50%;
	object-fit: cover;
	padding: 0;
	margin: 0;
`;

const StyledCardBody = styled.div`
	background-color: #012e40;
	color: #f2f2f2;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: -3px;
	position: relative;
	height: 40%;
`;

const StyledCardTitle = styled.h3`
	text-transform: uppercase;
	font-size: 28px;
	font-family: ${FontFamily.Solano};
	font-weight: 700;
	margin-bottom: 15px;
	color: #ffffff;
	padding-top: 20px;
`;

const StyledCardDateLocation = styled.p`
	font-size: 13px;
	font-family: ${FontFamily.Poppins};
	font-weight: 600;
	color: #ffffff;
	margin-bottom: 15px;
`;

const Item = ({ image, dateLocation, title, link, linkText }: Props) => {
	return (
		<StyledCard>
			<StyledCardImg src={image} />
			<StyledCardBody>
				<StyledCardTitle>{title}</StyledCardTitle>
				<StyledCardDateLocation>{dateLocation}</StyledCardDateLocation>
				<ButtonLink styles={{ position: "absolute", bottom: "15px" }} children={linkText} href={link} />
			</StyledCardBody>
		</StyledCard>
	);
};

export default Item;
