import React from "react";
import styled from "styled-components/macro";
import { getAppColor } from "../../util/appColors";
import { Button } from "../NewButton/Button";
import { FontFamily } from "../Typography/Typography";

export const SwimLPLink = ({ href, children, disabled }: { href: string; children: React.ReactNode; disabled?: boolean }) => {
	return (
		<div
			css={
				disabled
					? `
		
		pointer-events: none;
			cursor: default;`
					: undefined
			}
		>
			<StyledLink onClick={() => (window.location.href = `https://sitickets.com${href}`)}>{children}</StyledLink>
		</div>
	);
};

const StyledLink = styled(Button)`
	height: 60px;
	max-width: 220px;
	opacity: 0.85;
	background: ${getAppColor("dark")};
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${getAppColor("light")};
	font-size: 18px;

	button {
		font-family: ${FontFamily.Industry};
	}

	text-transform: uppercase;
	&:hover {
		opacity: 0.6;
	}
`;
