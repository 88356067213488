import React, { useState } from "react";
import styled from "styled-components";

const TestPage = styled.div`
	background-color: #282c34;
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(14px + 2vmin);
	color: white;
`;

const Action = styled.div`
	cursor: pointer;
`;

const Response = styled.div<{ error: boolean }>`
	color: ${({ error }) => (error ? "red" : "green")};
`;

export const Test = () => {
	const [response, setResponse] = useState<{ error: boolean; message: string }>({
		error: false,
		message: "",
	});

	const callService = (env: string) =>
		fetch(env, {
			method: "GET",
			credentials: "include",
		})
			.then(res => res.text())
			.then(res => setResponse({ error: false, message: res }))
			.catch(error => setResponse({ error: true, message: `${error.name}:${error.message}` }));

	const testMetaBets = () => callService("https://lunatix.api.areyouwatchingthis.com/api/odds.json?leagueCode=fbp&apiKey=1e0f57a6d00db2798bb1644be8475e6a");

	return (
		<div>
			<TestPage>
				<Action onClick={testMetaBets}>Test MetaBets</Action>
			</TestPage>
			<Response error={response.error}>{response.message}</Response>
		</div>
	);
};
