import React from "react";
import { EventCheckoutHeader } from "../Event/EventCheckoutHeader";
import { NewSeatsMobile } from "../Event/NewSeatsHeader";
import { createResponsiveLayout } from "../../util/ResponsiveLayout";
import { EventData } from "../../../model/EventPage";
import { Nulleable } from "../../../model/Utils";

const MOBILE_BREAKPOINT_PX = 768;

const EventHeaderLayout = createResponsiveLayout({ mobile: MOBILE_BREAKPOINT_PX });

interface EventHeaderProps {
	eventData: Nulleable<EventData>;
	onBackClick: () => void;
	onShowEventInfo: () => void;
}

export const EventMobileHeader = (props: EventHeaderProps) => {
	return (
		<EventHeaderLayout.Container>
			<EventHeaderLayout.Layout breakpoint="mobile">
				<EventCheckoutHeader onBack={props.onBackClick} onPageInfoClick={props.onShowEventInfo} showPageInfo={true}>
					{props.eventData && <NewSeatsMobile eventTitle={props.eventData.name} eventDate={props.eventData.date} location={props.eventData.venueLocation ?? "TBD"} venue={props.eventData.venueName || ""} />}
				</EventCheckoutHeader>
			</EventHeaderLayout.Layout>
			<EventHeaderLayout.Layout breakpoint="default">{null}</EventHeaderLayout.Layout>
		</EventHeaderLayout.Container>
	);
};
