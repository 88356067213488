import React from "react";
import styled from "styled-components/macro";
import { getHumanReadableDate, HumanReadableDateOptions } from "../../util/getHumanReadableDate";

export interface NewSeatsProps {
	venue: string;
	eventTitle: string;
	eventDate?: Date | null;
	teamTitle?: string;
	location?: string;
	humanReadableDateOptions?: HumanReadableDateOptions;
	customEventDateString?: string;
	noMargin?: boolean;
}

export const NewSeats = (props: NewSeatsProps) => {
	const date = getHumanReadableDate(props.eventDate, props.humanReadableDateOptions);
	const location = (props.location && props.location !== "TBD") || "";

	const getDisplayedYear = (year: number | null | undefined, date: string) => {
		if (year) {
			const THRESHOLD = 5;
			const yearDisplayThreshold = new Date().getFullYear() + THRESHOLD;
			return year < yearDisplayThreshold ? date : date.replace(year.toString(), "TBD");
		}
		return date;
	};

	return (
		<Details noMargin={props.noMargin}>
			<DetailsText>
				<Title>
					{props.teamTitle && `${props.teamTitle} - `}
					{props.eventTitle}
				</Title>
				<Subtitle>
					{props.venue && `${props.venue}, `} {location} {props.customEventDateString || getDisplayedYear(props.eventDate?.getFullYear(), date)}
				</Subtitle>
			</DetailsText>
		</Details>
	);
};

//TODO: Combine NewSeats and NewSeatsMobile in order to improve code quality.
export const NewSeatsMobile = (props: NewSeatsProps) => {
	const date = getHumanReadableDate(props.eventDate, props.humanReadableDateOptions);
	const location = (props.location && props.location !== "TBD") || "";

	return (
		<SeatsDetailTextMobile>
			<SeatsDetailTitle>
				{props.teamTitle && `${props.teamTitle} - `}
				{props.eventTitle}
			</SeatsDetailTitle>
			<SeatsDetailSubtitle>
				{props.venue && `${props.venue}, `} {location} {date}
			</SeatsDetailSubtitle>
		</SeatsDetailTextMobile>
	);
};

const SeatsDetailTextMobile = styled.div`
	flex: 1;
	text-align: left;
	padding: 0 1rem;
	color: black;
	overflow: hidden;
`;

const SeatsDetailTitle = styled.div`
	font-size: 13px;
	font-weight: bold;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	line-height: normal;
	position: relative;
`;

const SeatsDetailSubtitle = styled.div`
	font-size: 12px;
	line-height: normal;
	font-weight: 500;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	line-height: normal;
`;

const detailsSpacingStyle = (spacing: number) => `
  margin-left: ${spacing}px;
  padding: 0 ${spacing}px;
`;

const Details = styled.div<{ noMargin?: boolean }>`
	width: 100%;
  height: 50px;
	text-align: left;
	border-left: ${props => props.noMargin ? "" : "1px solid black"};
	color: black;
	display: flex;
	margin: auto 0;
	white-space: nowrap;
	overflow: hidden;
	margin-left: ${props => props.noMargin ? "" : "26px"};
	@media (max-width: 755px) {
		${detailsSpacingStyle(17)}
	}
  ${props => props.noMargin ? "" : detailsSpacingStyle(17)}
`;

const DetailsText = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: 0.5rem 0 0.5rem 1rem;
	& > * {
		max-width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
	}
`;

const Title = styled.div`
	font-size: 16px;
	font-weight: bold;
	margin: auto;
	width: 100%;
	line-height: normal;
`;

const Subtitle = styled.div`
	font-size: 15px;
	font-weight: 500;
	line-height: normal;
`;
