import styled from "styled-components";
import { mediaQueries } from "../../util/mediaQueries";

interface MobileMenuOverlayProps {
	isVisible: boolean;
}

export const MobileMenuOverlay = styled.div<MobileMenuOverlayProps>`
	background-color: rgb(10 21 41 / 0.7);
	position: fixed;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 101;
	display: ${props => (props.isVisible ? "block" : "none")};
	transition: all 1s ease-in-out;

	@media ${mediaQueries.max480} {
		top: 115px;
	}
`;
