import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import map_pin_white from "../../resource/img/icons/map_pin_white.svg";
import map_pin_orange from "../../resource/img/icons/map_pin_orange.svg";
import { SearchState } from "./SearchState";
import { Location, renderLocation } from "../../../model/Location";
import { mediaQueries } from "../../util/mediaQueries";
import { GooglePlacesAutocomplete } from "../GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import { getLocationName } from "../../../services/location";
import { borderRadius } from "./styles";
import { setLocation } from "../../../store/reducers/locationReducer";
import { isMobileApp } from "../../util/isMobileApp";
import { useIsMobileWeb } from "../../hooks/useIsMobileWeb";
import { Input } from "../NewBets/Input";
import { Dialog } from "../Dialog/Dialog";
import { Button } from "../NewButton/Button";
import { Typography } from "../Typography/Typography";

interface LocationSearchProps {
	searchState: SearchState;
	setSearchState: (next: SearchState) => void;
	allowCustomLocations?: boolean;
}

export const LocationSearch = ({ searchState, setSearchState, allowCustomLocations }: LocationSearchProps) => {
	const chosenLocation = useSelector(st => st.persistent.location);

	const onClick = useCallback(() => {
		const nextState = searchState === SearchState.Location ? SearchState.None : SearchState.Location;
		setSearchState(nextState);
	}, [searchState, setSearchState]);

	return (
		<>
			<LocationSearchButton isActive={searchState === SearchState.Location} chosenLocation={chosenLocation} onClick={onClick} />

			{searchState === SearchState.Location ? (
				<SearchResultContainer>
					<CitySearch location={chosenLocation} searchState={searchState} allowCustomLocations={allowCustomLocations} setSearchState={setSearchState} openDialog={searchState === SearchState.Location} />
				</SearchResultContainer>
			) : null}
		</>
	);
};

const LocationSearchButton = (props: LocationSearchButtonProps) => {
	const isMobileWeb = useIsMobileWeb();
	const isWeb = isMobileWeb || isMobileApp;
	const map_pin = isWeb ? map_pin_orange : map_pin_white;

	return (
		<PredictiveSearchButton isWeb={isWeb} onClick={props.onClick}>
			<PredictiveSearchButtonPin isWeb={isWeb} alt="" src={map_pin} />
			<PredictiveSearchButtonText isWeb={isWeb}>{props.chosenLocation ? renderLocation(props.chosenLocation) : "Choose Location"}</PredictiveSearchButtonText>
		</PredictiveSearchButton>
	);
};

interface LocationSearchButtonProps {
	isActive: boolean;
	chosenLocation: Location | null;
	onClick: () => void;
}

interface CitySearchProps {
	location: Location | null;
	searchState: SearchState;
	allowCustomLocations?: boolean;
	setSearchState: (next: SearchState) => void;
	openDialog: boolean;
}

export const CitySearch = ({ location, searchState, setSearchState, allowCustomLocations, openDialog }: CitySearchProps) => {
	const dispatch = useDispatch();
	const [newLocation, setNewLocation] = useState<Partial<typeof location>>(location);

	// Apply new location
	const ApplyLocation = () => {
		if (newLocation?.name === "") {
			dispatch(setLocation(null));
			setSearchState(SearchState.None);
		} else if (newLocation === null || (typeof newLocation.name !== "undefined" && typeof newLocation.latitude !== "undefined" && typeof newLocation.longitude !== "undefined")) {
			dispatch(setLocation(newLocation as Location | null));
			setSearchState(SearchState.None);
		}
	};

	// Set new location name from autocomplete
	const onAutocompleteTextChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewLocation({
			...newLocation,
			name: event.target.value,
		});
	};
	// Set new location coords from autocomplete
	const onAutocompletePlaceChanged = (autocomplete: google.maps.places.Autocomplete) => {
		const place = autocomplete.getPlace();
		const location = place.geometry?.location;

		if (typeof location !== "undefined") {
			setNewLocation({
				name: getLocationName(place),
				latitude: location.lat(),
				longitude: location.lng(),
			});
		}
	};

	// Clear new location
	const clearNewLocation = () => {
		setNewLocation(null);
	};

	return (
		<Dialog
			open={openDialog}
			type="information"
			headerChildren={
				<CityDialogHeaderContainer>
					<Typography type="largeSpecialBody">Choose Location</Typography>
				</CityDialogHeaderContainer>
			}
			onClose={() => setSearchState(SearchState.None)}
		>
			<PredictiveSearchCityContainer>
				<GooglePlacesAutocomplete
					options={{
						types: ["(regions)"],
					}}
					value={newLocation?.name || ""}
					onChange={onAutocompleteTextChanged}
					onClear={clearNewLocation}
					onPlaceChanged={onAutocompletePlaceChanged}
					fields={["geometry.location", "address_components"]}
					placeholder="Enter City or Zip code"
					as={Input}
				/>
				<ButtonContainer>
					<PredictiveSearchDismiss onClick={ApplyLocation}>Apply</PredictiveSearchDismiss>
				</ButtonContainer>
			</PredictiveSearchCityContainer>
		</Dialog>
	);
};

const PredictiveSearchCityContainer = styled.div`
	width: 375px;
	max-width: 80vw;
	max-height: 80vh;
	padding: 10px 10px 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	overflow: auto;
	gap: 20px;

	input {
		border-radius: 0px;
	}
`;

const PredictiveSearchButton = styled.div<{ isWeb: boolean }>`
	border-radius: 0 ${borderRadius} ${borderRadius} 0;
	width: 300px;
	background: #e92224;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 17px;
	color: #fff;
	user-select: none;

	border: 0;

	&:hover {
		cursor: pointer;
	}

	@media ${mediaQueries.max900} {
		width: ${props => (props.isWeb ? "200px" : "auto")};
		min-width: 44px;
	}

	${props =>
		props.isWeb &&
		`
		order:2;
		background:#ffffff;
		color:#726F6F;
		border:1px solid #E5E5E5;
		border-radius: 40px;
		width:200px;
		margin:auto;
	`}
`;
const PredictiveSearchButtonText = styled.span<{ isWeb: boolean }>`
	display: inline-block;
	max-width: 220px;

	${props =>
		!props.isWeb &&
		`
		@media ${mediaQueries.max900} {
			display: none;
		} 
	`}
`;
const PredictiveSearchButtonPin = styled.img<{ isWeb: boolean }>`
	display: inline-block;
	margin: 8px;
	width: auto;
	height: ${props => (props.isWeb ? "15px" : "20px")};
`;

const SearchResultContainer = styled.div`
	height: 0;
	position: absolute;
	right: 0;
	bottom: 0;
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
`;

const PredictiveSearchDismiss = styled(Button)`
	width: 100%;
	height: 51px;
`;

const CityDialogHeaderContainer = styled.div`
	display: flex;
	overflow: hidden;
	justify-content: center;
`;
