import jquery from "jquery";
window.jQuery = jquery;
window.$ = jquery;

declare global {
	interface Window {
		jQuery: any;
		$: any;
	}
}
