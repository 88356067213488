import * as t from "io-ts";

export const CategoryCodec = t.strict({
	id: t.number,
	name: t.string,
	slug: t.string,
	image: t.union([t.string, t.undefined, t.null]),
});

export const CategoriesCodec = t.array(CategoryCodec);

export type CategoriesResponse = t.OutputOf<typeof CategoriesCodec>;
