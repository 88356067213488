import React from "react";
import styled from "styled-components/macro";
import { ShieldIcon } from "../ShieldIcon/ShieldIcon";
import { colors } from "../../resource/colors";
import { maxContentWidthStyles } from "../../util/maxContentWidth";

export const ShieldBar = () => {
	return (
		<Container>
			<Content>
				<ShieldIcon />
			</Content>
		</Container>
	);
};

const Content = styled.div`
	${maxContentWidthStyles}
	min-height: 100%;
	width: 100%;
	display: flex;
	justify-content: flex-end;
`;

const Container = styled.div`
	display: flex;
	background: ${colors.black};
	padding: 4px 0;
`;
