import React from "react";
import close_button from "../../resource/img/icons/close_button.svg";
import styled from "styled-components";

export const ModalCloseButton = (props: { onClick: () => void }) => {
	return <CloseButton src={close_button} onClick={props.onClick} height={28} />;
};

const CloseButton = styled.img`
	cursor: pointer;
`;
