import React from "react";
import styled from "styled-components/macro";
import { getTypographyStyles } from "../Typography/Typography";
import { getAppColor } from "../../util/appColors";

export const SITixWalletBadge = () => {
	return (
		<SitixWalletTitle>
			<b>SI</b>&nbsp;Tickets Wallet
		</SitixWalletTitle>
	);
};

const SitixWalletTitle = styled.div`
	margin-bottom: 4px;
	width: 146px;
	display: flex;
	${getTypographyStyles("bodyNormal", {
		fontWeight: 700,
	})};

	& > b {
		display: inline-block;
		color: ${getAppColor("primary")};
	}
`;
