import { Reducer } from "redux";
import { NavigationItem } from "../../model/Navigation";

export interface NavbarState {
	hideSearch: boolean;
	navigationItems?: NavigationItem[];
	height?: number;
	displayNewNavbar?: boolean;
	amountOfRequestsToDisplayInitiallyTransparentNavbar: number;
}

const defaultNavbarState: NavbarState = {
	hideSearch: false,
	amountOfRequestsToDisplayInitiallyTransparentNavbar: 0,
};

export const navbarReducer: Reducer<NavbarState, NavbarActions> = (state: NavbarState = defaultNavbarState, action: NavbarActions) => {
	switch (action.type) {
		case "SET_NAVBAR_SEARCH_HIDDEN":
			return {
				...state,
				hideSearch: action.value,
			};
		case "SET_NAVBAR_NAVIGATION_ITEMS":
			return {
				...state,
				navigationItems: action.value,
			};
		case "SET_NAVBAR_HEIGHT":
			return {
				...state,
				height: action.value,
			};
		case "SET_DISPLAY_NEW_NAVBAR":
			return {
				...state,
				displayNewNavbar: action.value,
			};
		case "UPDATE_AMOUNT_OF_REQUESTS_TO_DISPLAY_INITIALLY_TRANSPARENT_NAVBAR":
			const newAmountOfRequestsToDisplayInitiallyTransparentNavbar = state.amountOfRequestsToDisplayInitiallyTransparentNavbar + action.value;
			return {
				...state,
				amountOfRequestsToDisplayInitiallyTransparentNavbar: newAmountOfRequestsToDisplayInitiallyTransparentNavbar < 0 ? 0 : newAmountOfRequestsToDisplayInitiallyTransparentNavbar,
			};
		default:
			return state;
	}
};

export type NavbarActions =
	| {
			type: "SET_NAVBAR_SEARCH_HIDDEN";
			value: boolean;
	  }
	| {
			type: "SET_NAVBAR_NAVIGATION_ITEMS";
			value: NavigationItem[];
	  }
	| {
			type: "SET_NAVBAR_HEIGHT";
			value: number;
	  }
	| {
			type: "SET_DISPLAY_NEW_NAVBAR";
			value: boolean;
	  }
	| {
			type: "UPDATE_AMOUNT_OF_REQUESTS_TO_DISPLAY_INITIALLY_TRANSPARENT_NAVBAR";
			value: number;
	  };

export const updateAmountOfRequestsToDisplayInitiallyTransparentNavbar = (value: number): NavbarActions => ({
	type: "UPDATE_AMOUNT_OF_REQUESTS_TO_DISPLAY_INITIALLY_TRANSPARENT_NAVBAR",
	value,
});

export const setNavbarSearchHidden = (value: boolean): NavbarActions => ({
	type: "SET_NAVBAR_SEARCH_HIDDEN",
	value,
});

export const setNavbarNavigationItems = (value: NavigationItem[]) => ({
	type: "SET_NAVBAR_NAVIGATION_ITEMS",
	value,
});

export const setNavbarHeight = (value: number) => ({
	type: "SET_NAVBAR_HEIGHT",
	value,
});

export const setDisplayNewNavbar = (value: boolean): NavbarActions => ({
	type: "SET_DISPLAY_NEW_NAVBAR",
	value,
});
