import { useEffect } from "react";
import toast from "react-hot-toast";

export const useToastRemoval = () => {
	useEffect(() => {
		return () => {
			toast.remove();
		};
	}, []);
};
