import { E164Number } from "libphonenumber-js/max";
import { Reducer } from "redux";
import { User, UserAddress } from "../../model/User";
import { SessionAction } from "./sessionReducer";

export const userReducer: Reducer<User | null, UserActions | SessionAction> = (state = null, action) => {
	switch (action.type) {
		case "SET_USER":
			return action.user;
		case "DID_LOG_IN":
			if (action.email === state?.email) {
				return state;
			}
			return null;
		case "DID_LOG_OUT":
			return null;
		case "ADD_USER_ADDRESS":
			if (state) {
				const addresses = state?.addresses;
				state?.addresses.push(action.address);
				return {
					...state,
					addresses,
				};
			}
			return null;
		case "REMOVE_USER_ADDRESS":
			if (state) {
				const arr = state.addresses;
				arr?.splice(action.index, 1);
				return {
					...state,
					addresses: arr,
				};
			}
			return null;
		case "UPDATE_USER_PHONE_NUMBER":
			if (state) {
				return {
					...state,
					phone: action.phone,
				};
			}
			return null;
		case "SET_PRIMARY_SHIPPING_ADDRESS_ID":
			if (state) {
				return {
					...state,
					primaryShippingAddressID: action.id,
				};
			}
			return null;
		case "UPDATE_USER_PROMOTIONAL_WALLET":
			if (state) {
				return {
					...state,
					promotionalWalletCredit: action.promotionalWalletCredit,
				};
			}
			return null;
		default:
			return state;
	}
};

export const updateUser = (user: User | null): UserActions => ({
	type: "SET_USER",
	user,
});

export const addUserAddress = (address: UserAddress): UserActions => ({
	type: "ADD_USER_ADDRESS",
	address,
});

export const removeUserAddress = (index: number): UserActions => ({
	type: "REMOVE_USER_ADDRESS",
	index,
});

export const setPrimaryShippingAddressId = (id: number): UserActions => ({
	type: "SET_PRIMARY_SHIPPING_ADDRESS_ID",
	id,
});

export const updateUserPhoneNumber = (phoneNumber: E164Number): UserActions => {
	const phoneNumberString = String(phoneNumber);
	return {
		phone: phoneNumberString,
		type: "UPDATE_USER_PHONE_NUMBER",
	};
};

export const updatePromotionalWallet = (promotionalWalletCredit: number): UserActions => {
	return {
		promotionalWalletCredit,
		type: "UPDATE_USER_PROMOTIONAL_WALLET",
	};
};

export type UserActions =
	| {
			type: "SET_USER";
			user: User | null;
	  }
	| {
			type: "ADD_USER_ADDRESS";
			address: UserAddress;
	  }
	| {
			type: "REMOVE_USER_ADDRESS";
			index: number;
	  }
	| {
			type: "UPDATE_USER_PHONE_NUMBER";
			phone: string;
	  }
	| {
			type: "SET_PRIMARY_SHIPPING_ADDRESS_ID";
			id: number;
	  }
	| {
			type: "UPDATE_USER_PROMOTIONAL_WALLET";
			promotionalWalletCredit: number;
	  };
