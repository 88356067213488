import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { getAppColor } from "../../util/appColors";
import { Typography, getTypographyStyles } from "../Typography/Typography";
import { maxContentWidthPadding } from "../../util/maxContentWidth";
import { ReactComponent as ResetIcon } from "../../resource/assets/reset_icon.svg";
import { breakpoints } from "../../util/breakpoints";
import { Button } from "../NewButton/Button";
import { Dialog } from "../Dialog/Dialog";

export const NewMultiFilterContainer = ({ filters, onReset, searchParams, newDesign }: { filters: React.ReactNode[]; onReset: () => void; searchParams: Object; newDesign?: boolean }) => {
	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);
	const [activeFilters, setActiveFilters] = useState(0);
	useEffect(() => {
		const filtersLength = "to" in searchParams ? Object.values(searchParams).length - 1 : Object.values(searchParams).length;
		setActiveFilters(filtersLength - Object.values(searchParams).filter(e => e === null).length);
	}, [searchParams]);

	return !newDesign && isMobile ? (
		<FilterButton filters={filters} onReset={onReset} activeFilters={activeFilters} />
	) : (
		<Container filtersAmount={filters.length} newDesign={newDesign}>
			{filters.map((filter, index) => {
				return (
					<Filter key={index} filtersAmount={filters.length} newDesign={newDesign}>
						{filter}
					</Filter>
				);
			})}
		</Container>
	);
};

const FilterButton = ({ filters, onReset, activeFilters }: { filters: React.ReactNode[]; onReset: () => void; activeFilters: number }) => {
	const [showModal, setShowModal] = useState(false);
	return (
		<>
			{showModal && (
				<Dialog
					open={showModal}
					onClose={() => {
						setShowModal(false);
					}}
					type="utility"
					fullscreenOnMobile
					headerChildren={
						<DialogHeader>
							<ResetFiltersControl onClick={onReset}>
								Reset
								<StyledResetIcon />
							</ResetFiltersControl>
							<DialogTitle type="heading3">Filters</DialogTitle>
						</DialogHeader>
					}
				>
					<FiltersDialogContent>
						{filters.map((filter, index) => {
							return <FilterContainerMobile key={index}>{filter}</FilterContainerMobile>;
						})}

						<ApplyFiltersButton
							color="primary"
							onClick={() => {
								setShowModal(false);
							}}
						>
							Apply
						</ApplyFiltersButton>
					</FiltersDialogContent>
				</Dialog>
			)}
			<ButtonContainer>
				<FiltersButton
					onClick={() => {
						setShowModal(true);
					}}
				>
					<Typography type="heading3">Filters ({activeFilters})</Typography>
				</FiltersButton>
			</ButtonContainer>
		</>
	);
};

const DialogHeader = styled.div`
	display: flex;
	overflow: hidden;
`;

export const DialogTitle = styled(Typography)`
	width: 100%;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	margin-bottom: 30px;

	@media (max-width: ${breakpoints.mobile}) {
		margin-bottom: 6px;
	}
`;

const Filter = styled.div<{ filtersAmount: number; newDesign?: boolean }>`
	${props => !props.newDesign && `
		width: 50%;
	`}
`;

const FilterContainerMobile = styled.div`
	margin-bottom: 20px;
`;

const Container = styled.div<{ filtersAmount: number; newDesign?: boolean }>`
	display: flex;
	justify-content: ${props => (props.filtersAmount > 1 && !props.newDesign ? "center" : "flex-start")};
	width: 100%;
	align-items: center;
	${props => props.newDesign ? `
		gap: 20px 15px;
		flex-wrap: wrap;
		@media (max-width: ${breakpoints.mobile}) {
			justify-content: space-between;
		}
	` : `
		margin-bottom: 24px; 
		margin-top: 5px;
	`}
`;

const FiltersButton = styled.div`
	border: 1px solid ${getAppColor("dark")};
	box-sizing: border-box;
	padding: 10px;
	cursor: pointer;
	width: calc(100% - ${2 * maxContentWidthPadding}px);
	margin-top: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const StyledResetIcon = styled(ResetIcon)`
	margin-left: 8px;
`;

export const ResetFiltersControl = styled.span`
	${getTypographyStyles("extraSmallSpecialBody")}
	display: flex;
	align-items: center;
	position: absolute;
	z-index: 1;
	&:hover {
		color: ${getAppColor("primary")};
		cursor: pointer;
	}
`;

export const FiltersDialogContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	@media (max-width: ${breakpoints.mobile}) {
		padding-top: 20px;
	}
`;

export const ApplyFiltersButton = styled(Button)`
	width: 100%;
	margin: 0 auto;
	margin-top: 24px;
	min-width: 300px;
	@media (min-width: ${breakpoints.mobile}) {
		max-width: 335px;
	}
`;
