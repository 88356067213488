import React from "react";
import { StyledSelect } from "./styles";
import Select from "react-select";
import { FilterTitle } from "./styles";

export const selectStyles: any = {
	input: (provided: any) => {
		return {
			...provided,
			height: 27,
			fontSize: 16,
		};
	},
	valueContainer: (provided: any) => ({
		...provided,
		height: 40,
		paddingLeft: 6,
		fontSize: 16,
		borderRadius: 0,
		display: "flex",
		justifyContent: "center",
	}),
	control: (provided: any) => ({
		...provided,
		borderRadius: 3,
		fontSize: 16,
		border: "1px solid #767977",
	}),
	singleValue: (provided: any) => ({
		...provided,
		fontSize: 18,
		fontFamily: "Montserrat",
		fontWeight: 500,
		lineHeight: 22,
		color: "#555555",
		display: "inline-block",
	}),
	menu: (provided: any) => ({
		...provided,
		fontSize: 16,
		zIndex: 2,
	}),
	option: (provided: any, { isSelected }: any) => ({
		...provided,
		background: isSelected ? "#12284C" : "#FFFFFFF",
		":hover, :active": {
			background: "#12284C",
			color: "#FFFFFF",
		},
	}),
};

const baseSelectProps = {
	placeholder: "",
	isMulti: false,
	isSearchable: false,
	blurInputOnSelect: false,
	captureMenuScroll: true,
	isClearable: false,
	closeMenuOnSelect: true,
	hideSelectedOptions: false,
	backspaceRemovesValue: false,
	allowSelectAll: true,
	styles: selectStyles,
};

export interface SelectItem {
	label: string;
	value: string | null;
}

export const SelectFilter = ({ onChange, items, title, value, isDisabled = false }: { onChange: (e: any) => void; items: SelectItem[]; title?: string | null; value?: SelectItem; isDisabled?: boolean }) => {
	return (
		<StyledSelect>
			<FilterTitle>{title}</FilterTitle>
			<Select isDisabled={isDisabled} value={value} defaultValue={items[0]} options={items} onChange={onChange} {...baseSelectProps} />
		</StyledSelect>
	);
};
