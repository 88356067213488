import React from "react";
import { CommonPage } from "../components";
import { maxContentWidthStyles, maxContentWidthValue, maxContentWidthPadding } from "../util/maxContentWidth";
import { mediaQueries } from "../util/mediaQueries";
import styled from "styled-components/macro";
import si_tickets_logo from "../resource/assets/si_tickets_logo_unstacked_cropped.svg";
import venmoLogo from "../resource/image/venmo-logo.svg";
import venmoLogoBlue from "../resource/image/venmo_logo_blue.svg";
import venmoHeader from "../resource/image/venmo-header.svg";
import venmoBanner from "../resource/image/venmo-banner.svg";
import venmoPhone from "../resource/image/venmo-phone.png";
import venmoPhoneMobile from "../resource/image/venmo-phone-mobile.svg";
import venmoPlusIcon from "../resource/image/venmo-plus.svg";
import venmoMoneyIcon from "../resource/image/venmo-money.svg";
import venmoEyeIcon from "../resource/image/venmo-eye.svg";
import venmoTickIcon from "../resource/image/venmo-tick.svg";
import { MetaDecorator, MetaTagData } from "../components/SEOMetadecorator/MetaDecorator";
import { InternalRoutes } from "../../presentation/Link";
import { Link } from "../Link";
import { isMobileApp } from "../util/isMobileApp";
import { getAppColor } from "../util/appColors";
import { Redirect } from "react-router-dom";

export const VenmoRoute = () => {
	const venmoSignupPage = "https://smart.link/54eg0t5v4moe4";

	return <Redirect to={InternalRoutes.Home} />;
	// return (
	// 	<CommonPage isSitixLogoSelected={true}>
	// 		<MetaDecorator title={MetaTagData.Venmo.title} description={MetaTagData.Venmo.description} />
	// 		<Header>
	// 			<ImgDesktop src={venmoHeader} />
	// 			<ImgPhone src={venmoPhone} />
	// 			<HeaderOverlay>
	// 				<HeaderContainer>
	// 					<VenmoTitleImage src={venmoLogoBlue} />
	// 					<h1>$10 Flat Fee</h1>
	// 					<p>
	// 						<strong>Create your free account today and pay only a $10 flat fee on select SI Tickets purchases!</strong>
	// 					</p>
	// 					<p>More than 60 million people use Venmo as a fast and safe payment option and money management tool.</p>
	// 					<VenmoLinkHeader to={venmoSignupPage}>Sign Up</VenmoLinkHeader>
	// 				</HeaderContainer>
	// 			</HeaderOverlay>
	// 			<ImgMobile src={venmoPhoneMobile} />
	// 		</Header>
	// 		<Content>
	// 			<Column.Container>
	// 				<Column.Left>
	// 					<div>
	// 						<h2>Us vs. Other Sites</h2>
	// 						<Table>
	// 							<Row>
	// 								<Cell />
	// 								<Cell left>Tickets</Cell>
	// 								<Cell left>Transaction Fees</Cell>
	// 								<Cell strong left>
	// 									Total
	// 								</Cell>
	// 							</Row>
	// 							<Row>
	// 								<Cell>
	// 									<img src={si_tickets_logo} alt="SI Tickets" />
	// 								</Cell>
	// 								<CellNumeric>$720</CellNumeric>
	// 								<CellNumeric>
	// 									<span>$10</span>
	// 								</CellNumeric>
	// 								<CellNumeric>$730</CellNumeric>
	// 							</Row>
	// 							<Row>
	// 								<Cell strong>Other sites</Cell>
	// 								<CellNumeric>$720</CellNumeric>
	// 								<CellNumeric>$216</CellNumeric>
	// 								<CellNumeric>$936</CellNumeric>
	// 							</Row>
	// 						</Table>
	// 						<YouSaveSection>
	// 							You’re saving <strong>22%</strong>
	// 						</YouSaveSection>
	// 						<SavingDetails>
	// 							<li>Represents the total price for 4 tickets to a concert. Other sites’ transaction fees are typically 30% of the ticket price.</li>
	// 						</SavingDetails>
	// 					</div>
	// 				</Column.Left>
	// 				<Column.Right>
	// 					<div>
	// 						<h2>Why Venmo?</h2>
	// 						<Fact.Container>
	// 							<Fact.Icon src={venmoTickIcon} />
	// 							<Fact.Text>
	// 								<strong>Fast &amp; Safe Social Payments:</strong> Avoid the stress and pay friends and businesses alike instantly.
	// 							</Fact.Text>
	// 						</Fact.Container>
	// 						<Fact.Container>
	// 							<Fact.Icon src={venmoEyeIcon} />
	// 							<Fact.Text>
	// 								<strong>No Hidden Fees:</strong> send or receive money using what’s in your Venmo account or bank account with no service charges.
	// 							</Fact.Text>
	// 						</Fact.Container>
	// 						<Fact.Container>
	// 							<Fact.Icon src={venmoMoneyIcon} />
	// 							<Fact.Text>
	// 								<strong>Your Money, Your Way:</strong> Instant transfers &amp; direct deposit options make Venmo better than your own bank.
	// 							</Fact.Text>
	// 						</Fact.Container>
	// 						<Fact.Container>
	// 							<Fact.Icon src={venmoPlusIcon} />
	// 							<Fact.Text>
	// 								<strong>App Payment Integration:</strong> SI Tickets gives you the ability to pay for tickets using Venmo in a single click.
	// 							</Fact.Text>
	// 						</Fact.Container>
	// 					</div>
	// 				</Column.Right>
	// 			</Column.Container>
	// 			<Banner>
	// 				<BannerContent>
	// 					<img src={venmoLogo} alt="Venmo" />
	// 					<p>Already have tickets in your cart? Get the best deal on transaction fees by paying with Venmo.</p>
	// 					<VenmoLink to={InternalRoutes.Cart}>
	// 						Checkout <span>with Venmo</span>
	// 					</VenmoLink>
	// 				</BannerContent>
	// 			</Banner>
	// 		</Content>
	// 	</CommonPage>
	// );
};

const VenmoTitleImage = styled.img`
	height: 36px;
	display: inline-block;
	margin-right: 14px;
`;

const BannerContent = styled.div`
	${maxContentWidthStyles}
	display: flex;
	align-items: center;
	min-height: 145px;
	padding-top: 70px;
	padding-bottom: 55px;
	& > p {
		flex: 1;
		color: ${getAppColor("light")};
		font-family: Montserrat;
		font-size: 18px;
		font-weight: 600;
		line-height: 24px;
		text-align: left;
		margin: 0 50px;
	}
	& > a {
		display: block;
	}
	@media ${mediaQueries.max1000} and (min-width: 751px) {
		& > p {
			margin: 0 30px;
		}
	}
	@media ${mediaQueries.max750} {
		flex-direction: column;
		& > p {
			text-align: center;
			margin: 30px 0px;
		}
		& > a {
			width: calc(100% - ${2 * maxContentWidthPadding}px);
			max-width: 300px;
		}
	}
`;

const Banner = styled.div`
	width: 100%;
	background-color: ${getAppColor("venmo")};
	background-image: url(${venmoBanner});
	background-repeat: no-repeat;
	background-position: right bottom;
	margin-bottom: ${isMobileApp ? 0 : -2 * maxContentWidthPadding}px;
`;

const columnsAmount = 2;
const paddingAmount = 3;

const Column = {
	Container: styled.div`
		position: inherit;
		min-height: 500px;
		display: flex;
		font-family: Montserrat;
		font-style: normal;
		text-align: left;
		@media ${mediaQueries.max750} {
			flex-direction: column;
			& > div > div {
				margin-right: auto;
				margin-left: auto;
			}
		}
		h2 {
			color: ${getAppColor("venmo")};
			font-size: 38px;
			line-height: 48px;
			font-weight: 700;
			margin-bottom: 34px;
			@media ${mediaQueries.max900} {
				font-size: 24px;
				line-height: 30px;
				margin-bottom: 20px;
			}
		}
		a {
			margin-top: 45px;
		}
	`,
	Left: styled.div`
		width: 100%;
		background-color: ${getAppColor("light")};
		& > div {
			max-width: ${maxContentWidthValue / columnsAmount - paddingAmount * maxContentWidthPadding}px;
			margin-left: auto;
			padding: 65px ${columnsAmount * maxContentWidthPadding}px 65px 0;
			@media ${mediaQueries.max1000} {
				padding: 32px ${maxContentWidthPadding}px;
			}
		}
	`,
	Right: styled.div`
		width: 100%;
		background-color: #f6f4f4;
		& > div {
			max-width: ${maxContentWidthValue / columnsAmount - paddingAmount * maxContentWidthPadding}px;
			margin-right: auto;
			padding: 65px 0 65px ${columnsAmount * maxContentWidthPadding}px;
			@media ${mediaQueries.max1000} {
				padding: 32px ${maxContentWidthPadding}px;
			}
		}
	`,
};

const Table = styled.div`
	display: flex;
	flex-direction: column;
	@media ${mediaQueries.max750} {
		flex-direction: row;
	}
`;

const Row = styled.div`
	display: flex;
	height: 70px;
	&:not(:last-child) {
		border-bottom: solid 0.5px ${getAppColor("dark")};
	}
	@media ${mediaQueries.max750} {
		flex-direction: column;
		width: 33%;
		height: auto;
		border: none !important;
	}
`;

const Cell = styled.div<{ strong?: boolean; left?: boolean }>`
	width: 25%;
	display: flex;
	align-items: center;
	font-weight: ${props => (props.strong ? 700 : 500)};
	text-align: center;
	justify-content: center;
	padding: 15px 10px;
	color: ${getAppColor("dark")};
	font-family: Montserrat;
	font-size: 16px;
	line-height: 20px;
	@media ${mediaQueries.max750} {
		text-align: ${props => (props.left ? "left" : "center")};
		justify-content: ${props => (props.left ? "flex-start" : "center")};
		height: 50px;
		padding: 0px 10px;
		width: auto;
		&:not(:last-child) {
			border-bottom: solid 0.5px ${getAppColor("dark")};
		}
	}
	& > img {
		width: 100%;
	}
`;

const CellNumeric = styled(Cell)`
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	span {
		background: ${getAppColor("venmo")};
		color: ${getAppColor("light")};
		padding: 6px 18px;
	}
`;

const YouSaveSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${getAppColor("venmo")};
	color: ${getAppColor("light")};
	padding: 15px;
	font-family: Montserrat;
	font-size: 24px;
	line-height: 28px;
	font-style: normal;
	font-weight: 700;
	& > strong {
		margin-left: 8px;
		font-weight: 900;
		font-size: 30px;
	}
`;

const SavingDetails = styled.ul`
	font-family: Montserrat;
	font-weight: 500;
	font-style: italic;
	font-size: 13px;
	line-height: 18px;
	color: #3c3c3b;
	margin-top: 25px;
	padding-left: ${maxContentWidthPadding}px;
`;

const Fact = {
	Container: styled.div`
		display: flex;
	`,
	Icon: styled.img`
		width: 17px;
		height: 17px;
		align-self: start;
		margin-top: 4px;
		margin-bottom: 4px;
		margin-right: 17px;
	`,
	Text: styled.p`
		flex: 1;
		font-size: 18px;
		line-height: 26px;
		margin-bottom: 10px;
		font-weight: 400;
		& > strong {
			color: ${getAppColor("venmo")};
			font-weight: 700;
		}
		@media ${mediaQueries.max750} {
			font-size: 16px;
			line-height: 24px;
		}
	`,
};

const Content = styled.div`
	width: 100%;
`;

const HeaderOverlay = styled.div`
	${maxContentWidthStyles}
	padding: 0 30px;
	position: inherit;
`;

const VenmoLink = styled(Link)`
	font-family: Montserrat;
	font-weight: 700;
	color: ${getAppColor("light")};
	font-size: 18px;
	line-height: 22px;
	background: ${getAppColor("primary")};
	box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	display: inline-block;
	cursor: pointer;
	padding: 14px 24px;
	& > span {
		display: inline-block;
		@media ${mediaQueries.max1000} and (min-width: 751px) {
			display: block;
		}
	}
`;

const VenmoLinkHeader = styled(VenmoLink)`
	padding: 14px 48px;
`;

const HeaderContainer = styled.div`
	padding-top: 180px;
	padding-bottom: 180px;
	position: inherit;
	max-width: 50%;
	text-align: left;
	@media ${mediaQueries.max750} {
		max-width: 100%;
		padding-top: 55px;
		padding-bottom: 10px;
		text-align: center;
	}
	& > h1 {
		font-family: Montserrat;
		font-weight: 700;
		font-size: 48px;
		color: ${getAppColor("venmo")};
		font-style: normal;
		line-height: 60px;
		display: inline-block;
		margin-bottom: 25px;
		@media ${mediaQueries.max480} {
			font-size: 42px;
			line-height: 52px;
		}
	}
	& > p {
		font-family: Montserrat;
		font-size: 18px;
		line-height: 28px;
		font-style: normal;
		font-weight: 400;
		color: #18234c;
		@media ${mediaQueries.max480} {
			font-size: 16px;
			line-height: 24px;
		}
	}
	& > p > strong {
		font-weight: 700;
	}
	& > a {
		margin-top: 25px;
	}
`;

const ImgDesktop = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	object-fit: cover;
	object-position: center;
	@media ${mediaQueries.max750} {
		display: none;
	}
`;

const ImgPhone = styled.img`
	position: absolute;
	left: auto;
	right: 0;
	width: 50%;
	top: ${maxContentWidthPadding}px;
	height: 600px;
	object-position: center bottom;
	object-fit: contain;
	min-width: 200px !important;
	@media ${mediaQueries.max750} {
		display: none;
	}
`;

const ImgMobile = styled.img`
	display: none;
	width: 100%;
	@media ${mediaQueries.max750} {
		margin-top: -25vw;
		display: block;
	}
`;

const Header = styled.div`
	width: 100%;
	position: relative;
	background: #f6f4f4;
	max-height: 640px;
	@media ${mediaQueries.max750} {
		max-height: none;
	}
`;
