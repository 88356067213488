import React, { useEffect, useRef, useState } from "react";
import { FeaturedActionItem, FeaturedItem } from "./FeaturedItem";
import { FeaturedContainer, FeaturedHeader, FeatureHeaderAction, FeaturedScroll, FeaturedEmptyMessage, FeaturedComponentContainer } from "./styles";
import { useResizeWatch } from "../../hooks";
import styled from "styled-components/macro";
import { maxContentWidthPadding, maxContentWidthValue } from "../../util/maxContentWidth";
import { CollapseArrow } from "../CollapseArrow/CollapseArrow";
import smoothscroll from "smoothscroll-polyfill";
import { mediaQueries } from "../../util/mediaQueries";
import { isMobileApp } from "../../util/isMobileApp";

type FeaturedProps<T> = {
	title?: string;
	action?: {
		to: string;
		text: string;
	};
	className?: string;
	useFallback?: boolean;
	listEmptyMessage?: string;
	arrowPosition?: "centered";
	maxItems?: number;
	backgroundColor?: string;
	hideThumbnailText?: boolean;
	hideItemsTitle?: boolean;
	selectedSlug?: string;
	subtitle?: string | React.ReactNode;
	noLocalEventsFound?: React.ReactNode;
	wrapTitle?: boolean;
	notSelectedOverlay?: boolean;
	rounded?: boolean;
	id?: string;
	lastElementAction?: {
		title: string;
		background: string;
		link: string;
	};
} & (
	| {
			items: any[];
			renderItem?: undefined;
	  }
	| {
			items: T[];
			renderItem?: (data: T, index: number, title: string) => React.ReactNode;
	  }
);

export const Featured = React.forwardRef((props: FeaturedProps<any>, ref) => {
	const container = useRef<HTMLDivElement>(null);
	const [height, setHeight] = useState(0);
	const [width, setWidth] = useState(0);

	useResizeWatch(container as React.RefObject<HTMLDivElement>, entry => {
		setHeight(entry.target.getBoundingClientRect().height);
		setWidth(entry.target.getBoundingClientRect().width);
	});

	const [showLeftArrow, setShowLeftArrow] = useState(false);
	const [showRightArrow, setShowRightArrow] = useState(false);

	const onScroll = () => {
		const containerElement = container.current;
		if (containerElement) {
			const newShowLeftArrowValue = !!containerElement.scrollLeft;
			const newShowRightArrowValue = containerElement.scrollWidth - containerElement.clientWidth !== containerElement.scrollLeft;
			setShowLeftArrow(newShowLeftArrowValue);
			setShowRightArrow(newShowRightArrowValue);
		}
	};

	useEffect(() => {
		if (container.current) {
			smoothscroll.polyfill();
			container.current?.addEventListener("scroll", onScroll);
			onScroll();
			return () => {
				container.current?.removeEventListener("scroll", onScroll);
			};
		}
	}, [container.current]);

	useEffect(() => {
		onScroll();
	}, [props.items.length]);

	if (!props.items?.length) {
		return typeof props.listEmptyMessage === "string" ? (
			<FeaturedComponentContainer id={props.id} className={props.className} ref={ref}>
				<FeaturedHeader>{props.title && <span>{props.title}</span>}</FeaturedHeader>
				<FeaturedEmptyMessage>{props.listEmptyMessage}</FeaturedEmptyMessage>
			</FeaturedComponentContainer>
		) : null;
	}

	const paddingTop = 16;
	const ratio = 0.566062569606156;
	const visibleItems = width < 670 ? 3 : 4;
	const currentPadding = width < 670 ? 28 : 32;
	const visiblePadding = (visibleItems - 1) * currentPadding;
	const itemWidth = (width - visiblePadding) / visibleItems;
	const itemHeight = itemWidth * ratio;
	const arrowPosition = props.arrowPosition !== "centered" ? itemHeight / 2 + paddingTop : undefined;

	const scrollItemsTo = (direction: "left" | "right") => {
		const clientWidth = container.current?.clientWidth;
		if (clientWidth) {
			const scrollWidth = itemWidth;
			container.current?.scrollBy({
				left: direction === "left" ? -scrollWidth : scrollWidth,
				behavior: "smooth",
			});
		}
	};

	const hasCustomSubtitle = typeof props.subtitle !== "string" && React.isValidElement(props.subtitle);

	return (
		<FeaturedComponentContainer id={props.id} ref={ref} className={props.className}>
			{props.title && (
				<FeaturedHeader wrapTitle={props.wrapTitle} customSubtitle={hasCustomSubtitle}>
					<span>
						{props.title}
						{hasCustomSubtitle && props.subtitle}
						{props.subtitle && typeof props.subtitle === "string" && (
							<>
								<br />
								<span>{props.subtitle}</span>
							</>
						)}
					</span>
					{props.action && <FeatureHeaderAction to={props.action.to}>{props.action.text}</FeatureHeaderAction>}
				</FeaturedHeader>
			)}
			{props.noLocalEventsFound && props.noLocalEventsFound}
			<FeaturedContainer height={height}>
				{showLeftArrow && !isMobileApp && (
					<PaddleButton
						top={arrowPosition}
						direction="left"
						onClick={() => {
							scrollItemsTo("left");
						}}
					/>
				)}
				<FeaturedScroll ref={container}>
					{props.renderItem
						? // @ts-ignore
						  props.items.map((item, index) => props.renderItem(item, index, props.title))
						: // @ts-ignore
						  props.items.map((item, index) => ((props.maxItems && index < props.maxItems) || props.maxItems === undefined) && <FeaturedItem altTitleLink={item.slug} itemText={item.name && !props.hideThumbnailText ? item.name.toUpperCase() : null} key={index} data={item} rounded={props.rounded} useFallback={props.useFallback} containerTitle={props.title} selected={!!props.selectedSlug && item.slug === props.selectedSlug} notSelectedOverlay={props.notSelectedOverlay} hideTitle={props.hideItemsTitle} />)}
					{props.lastElementAction && <FeaturedActionItem {...props.lastElementAction} />}
				</FeaturedScroll>
				{showRightArrow && !isMobileApp && (
					<PaddleButton
						top={arrowPosition}
						onClick={() => {
							scrollItemsTo("right");
						}}
						direction="right"
					/>
				)}
			</FeaturedContainer>
		</FeaturedComponentContainer>
	);
});

const PaddleButton = (props: { top?: number; direction: "left" | "right"; onClick?: () => void }) => {
	return (
		<CollapseArrowButton {...props}>
			<StyledCollapseArrow direction={props.direction} weight={3} size={9} customExpansionArrowSpanPos={0.17} />
		</CollapseArrowButton>
	);
};

const collapseArrowButtonWidthHeightValue = 28;
const mobileCollapseArrowButtonWidthHeightValue = 20;
const negativeDesktopArrowSpacingValue = 30;

const collapseArrowButtonWidthHeight = collapseArrowButtonWidthHeightValue + "px";

const CollapseArrowButton = styled.button<{
	top?: number;
	direction: "left" | "right";
}>`
	${props => (props.top ? `top:${props.top - collapseArrowButtonWidthHeightValue / 2}px;` : "")}
	${props => props.direction}: -${negativeDesktopArrowSpacingValue}px;
	@media (max-width: ${maxContentWidthValue + maxContentWidthPadding * 2 + negativeDesktopArrowSpacingValue * 2}px) {
		${props => props.direction}: ${collapseArrowButtonWidthHeightValue / 4}px;
	}
	z-index: 2;

	position: absolute;
	height: ${collapseArrowButtonWidthHeight};
	border-radius: 100%;
	min-width: ${collapseArrowButtonWidthHeight};

	outline: none;
	cursor: pointer;
	border: none;
	box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 26%);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;

	background: #ffffff;
	@media ${mediaQueries.max480} {
		display: none;
		/*background: #ffffff6b;*/
		/*height: ${mobileCollapseArrowButtonWidthHeightValue}px;*/
		/*min-width: ${mobileCollapseArrowButtonWidthHeightValue}px;*/
	}
`;

const StyledCollapseArrow = styled(CollapseArrow)<{
	direction: "left" | "right";
}>`
	transform: rotate(${props => (props.direction === "left" ? "" : "-")}90deg);
	@media ${mediaQueries.max480} {
		transform: scale(0.8) rotate(${props => (props.direction === "left" ? "" : "-")}90deg);
	}
	span {
		background-color: #e92224;
	}
`;
